import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedUserTransactions, setSelectedUserTransactions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/transactions.php`);
                if (response.data.status === 'success') {
                    setTransactions(response.data.data);
                    setFilteredTransactions(response.data.data);
                } else {
                    setError(response.data.message);
                }
            } catch (err) {
                setError('Failed to fetch data. Please try again.');
            }
        };
        fetchTransactions();
    }, []);

    useEffect(() => {
        const filtered = transactions.filter(trans =>
            trans.user_email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredTransactions(filtered);
    }, [searchTerm, transactions]);

    const openModal = async (userEmail) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/get_transaction_details.php?user_email=${userEmail}`);
            if (response.data.status === 'success') {
                setSelectedUserTransactions(response.data.data);
                setModalIsOpen(true);
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            setError('Failed to fetch user details.');
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedUserTransactions([]);
    };

    return (
        <div style={styles.container}>
            {/* <h1 style={styles.title}>TRANSACTIONS</h1> */}

            {error ? (
                <p style={styles.error}>{error}</p>
            ) : filteredTransactions.length > 0 ? (
                <div className="emaildiv">
                    <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {/* <div className="col-6"> */}
                        <input
                            type="text"
                            style={Searchinputfield}
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {/* </div> */}
                    </div>
                    <div className="table-responsive" style={tablecontainer}>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={styles.headerCell}>#</th>
                                    <th style={styles.headerCell}>User Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTransactions.map((trans, index) => (
                                    <tr key={index} onClick={() => openModal(trans.user_email)} style={{ cursor: 'pointer' }}>
                                        <td style={styles.cell}>{index + 1}</td>
                                        <td style={styles.cell}>{trans.user_email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div>
                <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {/* <div className="col-6"> */}
                        <input
                            type="text"
                            style={Searchinputfield}
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {/* </div> */}
                    </div>
                <p style={styles.noData}>No transactions found.</p>
                </div>
            )}

            {modalIsOpen && <Modal isOpen={modalIsOpen} onClose={closeModal} transactions={selectedUserTransactions} />}
        </div>
    );
};

const Modal = ({ isOpen, onClose, transactions }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // const filteredTransactions = transactions.filter(trans =>
    //     trans.course_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     trans.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     trans.amount.toString().includes(searchTerm) ||
    //     trans.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     trans.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     trans.reference_id.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const filteredTransactions = transactions.filter(trans =>
        (trans.course_name ? trans.course_name.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
        (trans.type ? trans.type.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
        (trans.amount ? trans.amount.toString() : "").includes(searchTerm.toLowerCase()) ||
        (trans.status ? trans.status.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
        (trans.transaction_id ? trans.transaction_id.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
        (trans.reference_id ? trans.reference_id.toLowerCase() : "").includes(searchTerm.toLowerCase()) ||
        (trans.created_at ? new Date(trans.created_at).toLocaleString().toLowerCase() : "").includes(searchTerm.toLowerCase())
    );


    if (!isOpen || transactions.length === 0) return null;

    return (
        <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
                <button onClick={onClose} style={modalStyles.closeBtn}><i className="fa-solid fa-xmark"></i></button>
                <h2>User Transactions</h2>
                <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="col-md-6">
                        <input
                            type="text"
                            style={Searchinputfield}
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <select
                            style={filteroption.selectContainer}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        >
                           <option style={filteroption.option} value="">All</option>
                            <option style={filteroption.option} value="pending">Pending</option>
                            <option style={filteroption.option} value="success">Success</option>
                        </select>
                    </div>
                </div>
                <div className="table-responsive" style={tablecontainer}>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.headerCell}>#</th>
                                <th style={styles.headerCell}>Course Name</th>
                                <th style={styles.headerCell}>Type</th>
                                <th style={styles.headerCell}>Amount</th>
                                <th style={styles.headerCell}>Status</th>
                                <th style={styles.headerCell}>Reference ID</th>
                                <th style={styles.headerCell}>Transaction ID</th>
                                <th style={styles.headerCell}>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTransactions.map((trans, index) => (
                                <tr key={index}>
                                    <td style={styles.cell}>{index + 1}</td>
                                    <td style={styles.cell}>{trans.course_name}</td>
                                    <td style={styles.cell}>{trans.type}</td>
                                    <td style={styles.cell}>{trans.amount}</td>
                                    <td style={styles.cell}>{trans.status}</td>
                                    <td style={styles.cell}>{trans.transaction_id}</td>
                                    <td style={styles.cell}>{trans.reference_id}</td>
                                    <td style={styles.cell}>{new Date(trans.created_at).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: { padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9', borderRadius: '8px', margin: 'auto', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' },
    title: { textAlign: 'center', marginBottom: '20px', color: '#333' },
    error: { color: 'red', textAlign: 'center' },
    table: { width: '100%', borderCollapse: 'collapse', backgroundColor: '#fff', borderRadius: '8px' },
    headerCell: { border: '1px solid #ddd', padding: '10px', backgroundColor: '#007bff', color: 'white', textAlign: 'left' },
    cell: { border: '1px solid #ddd', padding: '10px' },
    noData: { textAlign: 'center', padding: '20px', color: '#555' },
};

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '1100px',
        width: '100%',
        maxHeight: '90vh', 
        overflow: 'auto', 
        position: 'relative'
    },
    closeBtn: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '20px',
        cursor: 'pointer'
    }
};

const Searchinputfield = {
    width: "100%",
    padding: "8px 12px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    outline: "none",
    marginBottom: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
};

// const filteroption = {
//     width: "100%",
//     padding: "8px 12px",
//     fontSize: "16px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     outline: "none",
//     marginBottom: "10px",
//     boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// };

const filteroption = {
    selectContainer: {
        width: "50%", 
        padding: "8px 12px",
        fontSize: "16px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#f8f8f8",
        appearance: "none", 
        WebkitAppearance: "none", 
        MozAppearance: "none", 
        outline: "none",
        marginBottom: "10px",
        cursor: "pointer", 
    },
    option: {
        padding: "10px",
        // width: "50%",
        backgroundColor: "white",
        color: "#333",
        cursor: "pointer", 
    },
    optionHover: {
        backgroundColor: "#f0f0f0", 
    },
    selectFocus: {
        borderColor: "#007bff", 
    },
};

const tablecontainer = {
    maxHeight: "290px",
    overflowY: "auto",
    scrollbarWidth: "thin", 
    scrollbarColor: "#007bff transparent", 
    option : {}
};



export default Transactions;
