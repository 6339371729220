import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import style from "./../Css/Iitsadminlogin.module.css";
import axios from "axios"; // Import Axios
import API_BASE_URL from '../apiConfig';
// import adminLoginBg from '../path/to/adminloginbg.jpg';

const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate(); // Initialize useNavigate

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/iitsadmin_login.php`, {
        username,
        password,
      });

      const data = response.data;

      if (data.status === "success") {
        setMessage("Login successful");

        // Save admin info to localStorage
        localStorage.setItem("adminInfo", JSON.stringify(data.data)); 

        // Navigate to admin panel
        navigate('/Iitscode_adminpannel');
      } else {
        setMessage(data.message); // Display specific error message from the server
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className={`${style.adminloginmaincontainer} container-fluid`}>
      <div className="container">
        <div className={`${style.adminloginmainrow} row`}>
          <div className={`${style.leftside} col-md`}>
            <div className="row">
              <div className={`${style.leftheading1} col`}>
                <h1 className={`${style.leftsideheading1}`}>IITS</h1>
              </div>
              <div className={`${style.leftheading2} col`}>
                <h1 className={`${style.leftsideheading2}`}>CODE</h1>
              </div>
            </div>
          </div>
          <div className={`${style.rightside} col-md`}>
            <div className={style.loginbox}>
              <div className={style.container}>
                <form className={style.form} onSubmit={handleLogin}>
                  <p className={style.title}>Admin Login</p>
                  {message && <div className={`${style.message} ${style.error}`}>{message}</div>}
                  <input
                    placeholder="Username"
                    className={`${style.username} ${style.inputu}`}
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <div className={style.loginiconsx}>
                    <input
                      placeholder="Password"
                      className={`${style.password} ${style.inputp}`}
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className={`${style.showPasswordBtnlogin} ${style.inputi}`}
                    >
                      {showPassword ? (
                        <i className="fa-regular fa-eye"></i>
                      ) : (
                        <i className="fa-regular fa-eye-slash"></i>
                      )}
                    </button>
                  </div>
                  <button className={style.btn} type="submit">
                    Login
                  </button>
                  <div className={style.resetpassword}>
                    <a href="/reset-password">Forgot Password?</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
