import React, { useEffect, useState } from 'react';
import Select from "react-select";
import style from "./../Css/Availableinternship.module.css";
import { Link } from "react-router-dom";
import Applyforinternship from './Applyforinternship'
import API_BASE_URL from "../apiConfig";

const Availableinternship = () => {
  const [selectedCategory, setSelectedCategory] = useState({ value: "All", label: "All" });
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  const [internships, setInternships] = useState([]); // State to hold internship data

  const categories = [
    { value: "All", label: "All" },
    { value: "E-Commerce-Platform", label: "E-Commerce Platform" },
    { value: "Travel-Booking-System", label: "Travel Booking System" },
    { value: "Inventory-Management-System", label: "Inventory Management System" },
    { value: "Booking/On-Demand-Services", label: "Booking/On-Demand Services" },
    { value: "Corporate/B2B-Services", label: "Corporate/B2B Services" },
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  // Custom styles for the react-select dropdown
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Make sure the dropdown menu has a high z-index
    }),
  };

  useEffect(() => {
    const fetchInternships = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get_internship.php`);
        const data = await response.json();
        setInternships(data);
      } catch (error) {
        console.error("Failed to fetch Packages:", error);
      }
    };

    fetchInternships();
  }, []);



  const handlePreview = (images) => {
    setModalImages(images);
    setModalVisible(true);
  };

  const handleClosePreview = () => {
    setModalVisible(false);
    setModalImages([]);
  };

  // Filter internships based on the selected category
  const filteredInternships = selectedCategory.value === "All"
    ? internships
    : internships.filter((intern) =>
      intern.projectName.replace(/\s+/g, '-').toLowerCase() === selectedCategory.value.toLowerCase()
    );

  return (
    <>
      <Applyforinternship />
      <div className={`${style.internshipfluid} container-fluid`}>
        <div className="container">
          <div className={`${style.headerrow} row d-flex`}>
            <h1 className={style.internshipheading}>Available Internship</h1>
            <div className={style.dropdownContainer}>
              <Select
                options={categories}
                value={selectedCategory}
                onChange={handleCategoryChange}
                className={style.droupdown}
                placeholder="Select a category"
                isSearchable
                styles={customStyles}
              />
            </div>
          </div>

          <div className={`${style.internshiprow} row`}>
            {filteredInternships
              .filter((intern) => Number(intern.hidden) === 0) // Exclude hidden internships
              .map((intern) => (
                <div key={intern.id} className="col">
                  <div className={`${style.coursesbuyrowmain} ${style.liveprojectcard}`}>
                    <div className={`${style.coursesbuymain}`}>
                      <div className={style.courseslogomain}>
                        {/* <img className={style.coursesbuylogo} src={card.images[0]} alt="Preview 1" /> */}
                        {/* <img className={style.coursesbuylogo} src={card.images[1]} alt="Preview 2" /> */}
                        {/* <img className={style.coursesbuylogo} src={card.images[2]} alt="Preview 3" /> */}
                        <img
                          className={style.coursesbuylogo}
                          src={`${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage1}`}
                          alt={intern.projectName}
                        />
                        <img
                          className={style.coursesbuylogo}
                          src={`${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage2}`}
                          alt={intern.projectName}
                        />
                        <img
                          className={style.coursesbuylogo}
                          src={`${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage3}`}
                          alt={intern.projectName}
                        />



                      </div>
                      <div className={style.coursesdetail}>
                        <h4 className={style.nameheading}>{intern.projectName}</h4>
                        <span className={style.coursesdata}>{intern.projectDetails}</span>
                        <h5 className={style.learnmorebuy}>{intern.projectSubDetails}</h5>
                      </div>
                      <div className={style.buttonz}>
                        <button
                          className={style.coursesbuybutton}
                          onClick={() =>
                            handlePreview([
                              `${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage1}`,
                              `${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage2}`,
                              `${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage3}`,
                            ])
                          }
                        >
                          Preview
                        </button>
                        {/* <a
                          className={style.learnmorebuy}
                          href={`${API_BASE_URL}/internshipimgpdf/pdf/${intern.projectPreviewPDF}`}
                          target="_blank"
                          rel="noopener noreferrer" 
                        >
                          Learn more
                        </a> */}
                        <Link
                          to={"/Apply_internshipfoam"}
                          style={{ textDecoration: "none" }}
                          className={style.coursesbuybutton}
                        >
                          Apply for Internship
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {modalVisible && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={handleClosePreview}>
              &times;
            </button>
            <div className={style.imageContainer}>
              {modalImages.map((img, index) => (
                <img key={index} src={img} alt={`Preview ${index}`} className={style.modalImage} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Availableinternship;
