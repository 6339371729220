import React, { useState } from 'react';
import style from './../../Css/Courses.module.css'

function MySQLcourse() {
    // State to keep track of the selected chapter
    const [selectedChapter, setSelectedChapter] = useState('chapter1')

    // Function to handle chapter selection
    const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter)
    }

    // State to track the active chapter
    const [activeChapter, setActiveChapter] = useState('chapter1');

    // Function to handle chapter click
    const handleChapterActive = (chapter) => {
        setActiveChapter(chapter);
    };
    const handleClick = (chapter) => {
        handleChapterActive(chapter);
        handleChapterClick(chapter);
        window.scrollTo(0,0);
    };


    return (
        <div className={`${style.firstcontainer} container-fluid`}>

            <div className={`${style.therow} row`}>
            <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    MySQL
                    </button>
                    
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>

                    

                        <li onClick={() => handleClick('chapter1')}
                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`} >
                       Introduction 
                        </li>


            
                <li
                    onClick={() => handleClick('chapter2')}
                    className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                   Features and Benefits
                </li>


                <li onClick={() => handleClick('chapter3')}
                     className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} dropdown-item `}>
                   MySQL Editions
                </li>
    
                <li onClick={() => handleClick('chapter4')} 
                  className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''} dropdown-item `}>
                    Installing and Setting Up MySQL
                </li>


                <li onClick={() => handleClick('chapter5')} 
                className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''} dropdown-item `}>
                  Connecting to MySQL Server  
                </li>



                <li onClick={() => handleClick('chapter6')}
                className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} dropdown-item `}>
                  MySQL Workbench 
                </li>

                <li onClick={() => handleClick('chapter7')} 
                className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} dropdown-item `}>
                  Basic MySQL Commands
                </li>

<h5> Database Basics And datatypes </h5>

                <li onClick={() => handleClick('chapter8')}
                className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} dropdown-item `}>
             Understanding Databases  
                </li>

                <li onClick={() => handleClick('chapter9')}
                  className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} dropdown-item `}>
                    Tables and Relationships  
                </li>

                <li onClick={() => handleClick('chapter10')}
                className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} dropdown-item `}>
                  Numeric Data Types  
                </li>

                <li onClick={() => handleClick('chapter11')} 
                className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} dropdown-item `}>
                  String Data Types  
                    </li>


                <li onClick={() => handleClick('chapter12')}
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item `}>
                  Date and Time Types  
                </li>


            <li onClick={() => handleClick('chapter13')} 
                className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} dropdown-item `}>
                  Binary Large Object Data Types (BLOB)
                </li>

                <li onClick={() => handleClick('chapter14')} 
                className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''} dropdown-item `}>
                  Spatial Data Types
                </li>

                <li onClick={() => handleClick('chapter15')} 
                  className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''} dropdown-item `}>
                    JSON Data Type
                </li>


<h5>Database Management</h5>
                <li onClick={() => handleClick('chapter16')} 
                className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''} dropdown-item `}>
                  Creating Databases  
                </li>

                <li onClick={() => handleClick('chapter17')}
                  className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''} dropdown-item `}>
                    Dropping Databases  
                </li>


                <li onClick={() => handleClick('chapter18')} 
                className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''} dropdown-item `}>
                  Selecting Databases  
                </li>


                <li onClick={() => handleClick('chapter19')}
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} dropdown-item `}>
                  Backing Up and Restoring Databases  
                </li>

<h5>Table Management</h5>

                <li onClick={() => handleClick('chapter20')} 
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''} dropdown-item `}>
                 Creating Tables  
                </li>

                <li onClick={() => handleClick('chapter21')}
                className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} dropdown-item `}>
                  Altering Tables  
                </li>


                <li onClick={() => handleClick('chapter22')} 
                className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''} dropdown-item `}>
                  Dropping Tables  
                </li>

                <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} dropdown-item `}>
                  Temporary Tables  
                </li>


                <li onClick={() => handleClick('chapter24')} 
                className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''} dropdown-item `}>
                  Copying Tables  
                </li>

<h5>Data Manipulation (DML) </h5>
                
                <li onClick={() => handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} dropdown-item `}>
                  Inserting Data  
                </li>

                <li onClick={() => handleClick('chapter26')} 
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} dropdown-item `}>
                  Updating Data  
                </li>

                <li onClick={() => handleClick('chapter27')} 
                className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''} dropdown-item `}>
                  Deleting Data  
                </li>

                <li onClick={() => handleClick('chapter28')} 
                 className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''} dropdown-item `}>
                  Replacing Data  
                </li>

                <h5>Data Integrity and Constraints</h5>
                <li onClick={() => handleClick('chapter63')} 
                className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''} dropdown-item `}>
                  Primary Key  
                </li>

                <li onClick={() => handleClick('chapter64')} 
                className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''} dropdown-item `}>
                  Foreign Key  
                </li>

                <li onClick={() => handleClick('chapter65')} 
                className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''} dropdown-item `}>
                  Unique Constraint  
                </li>

                <li onClick={() => handleClick('chapter66')} 
                className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''} dropdown-item `}>
                  Not Null Constraint  
                </li>

                <li onClick={() => handleClick('chapter67')} 
                className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''} dropdown-item `}>
                  Default Values  
                </li>


                <li onClick={() => handleClick('chapter68')} 
                className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''} dropdown-item `}>
                  Check Constraint  
                </li>

<h5>Querying Data (SQL Basics)</h5>

                <li onClick={() => handleClick('chapter29')} 
                className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} dropdown-item `}>
                  Select Data
                </li>


                <li onClick={() => handleClick('chapter30')} 
                className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} dropdown-item `}>
                  WHERE Clause  
                </li>


                <li onClick={() => handleClick('chapter31')} 
                className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} dropdown-item `}>
                    Sorting Data with ORDER BY
                </li>

                <li onClick={() => handleClick('chapter32')} 
                className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} dropdown-item `}>
                    Limiting Results with LIMIT
                </li>


                <li onClick={() => handleClick('chapter33')} 
                className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} dropdown-item `}>
                    Using Logical Operators (AND, OR, NOT)
                </li>

                <li onClick={() => handleClick('chapter34')} 
                className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} dropdown-item `}>
                    Eliminating Duplicates with DISTINCT
                </li>

<h5>Joins and Relationships</h5>
                <li onClick={() => handleClick('chapter35')} 
                className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} dropdown-item `}>
                    Introduction to Joins  
                </li>

                <li onClick={() => handleClick('chapter36')} 
                className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} dropdown-item `}>
                  INNER JOIN  
                </li>

              
                <li onClick={() => handleClick('chapter37')} 
                className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''} dropdown-item `}>
                  LEFT JOIN  
                </li>

                <li onClick={() => handleClick('chapter38')} 
                className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} dropdown-item `}>
                  RIGHT JOIN  
                </li>

                <li onClick={() => handleClick('chapter39')} 
                className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''} dropdown-item `}>
                  FULL OUTER JOIN  
                </li>

                <li onClick={() => handleClick('chapter40')} 
                className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''} dropdown-item `}>
                  CROSS JOIN  
                </li>


                <li onClick={() => handleClick('chapter41')} 
                className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''} dropdown-item `}>
                  Self Join  
                </li>


<h5>Subqueries</h5>
                <li onClick={() => handleClick('chapter42')} 
                className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''} dropdown-item `}>
                  What are Subqueries
                </li>

                <li onClick={() => handleClick('chapter43')} 
                className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''} dropdown-item `}>
                  Single-row Subqueries  
                </li>

                <li onClick={() => handleClick('chapter44')} 
                className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''} dropdown-item `}>
                  Multi-row Subqueries  
                </li>

                <li onClick={() => handleClick('chapter45')} 
                className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''} dropdown-item `}>
                  Correlated Subqueries  
                </li>

<h5>Grouping and Aggregate Functions</h5>
                <li onClick={() => handleClick('chapter46')} 
                className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''} dropdown-item `}>
                  GROUP BY Clause  
                </li>


                <li onClick={() => handleClick('chapter47')} 
                className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''} dropdown-item `}>
                  HAVING Clause  
                </li>



                <li onClick={() => handleClick('chapter48')} 
                className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''} dropdown-item `}>
                  COUNT
                </li>

                <li onClick={() => handleClick('chapter49')} 
                className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''} dropdown-item `}>
                  SUM
                </li>


                <li onClick={() => handleClick('chapter50')} 
                className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''} dropdown-item `}>
                  AVG  
                </li>

                <li onClick={() => handleClick('chapter51')} 
                className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''} dropdown-item `}>
                  MAX 
                </li>

                <li onClick={() => handleClick('chapter52')} 
                className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''} dropdown-item `}>
                  MIN
                </li>

<h5>MySQL Functions</h5>
                <li onClick={() => handleClick('chapter53')} 
                className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''} dropdown-item `}>
                  String Functions 
                </li>

                <li onClick={() => handleClick('chapter54')} 
                className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''} dropdown-item `}>
                  Numeric Functions 
                </li>

                <li onClick={() => handleClick('chapter55')} 
                className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''} dropdown-item `}>
                  Date and Time Functions 
                </li>


                <li onClick={() => handleClick('chapter56')} 
                className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''} dropdown-item `}>
                  Conversion Functions 
                </li>


                <li onClick={() => handleClick('chapter57')} 
                className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''} dropdown-item `}>
                  Control Flow Functions  
                </li>

<h5>Indexing</h5>
                <li onClick={() => handleClick('chapter58')} 
                className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''} dropdown-item `}>
                  What is an Index
                </li>

                <li onClick={() => handleClick('chapter59')} 
                className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''} dropdown-item `}>
                  Types of Indexes (Primary, Unique, Full-text)  
                </li>


                <li onClick={() => handleClick('chapter60')} 
                className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''} dropdown-item `}>
                  Creating Indexes  
                </li>

                <li onClick={() => handleClick('chapter61')} 
                className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''} dropdown-item `}>
                  Dropping Indexes
                </li>

                <li onClick={() => handleClick('chapter62')} 
                className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''} dropdown-item `}>
                  Performance Impact of Indexing  
                </li>




<h5>Views</h5>
                <li onClick={() => handleClick('chapter69')} 
                className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''} dropdown-item `}>
                  Introduction to Views  
                </li>

                <li onClick={() => handleClick('chapter70')} 
                className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''} dropdown-item `}>
                  Creating Views  
                </li>

                <li onClick={() => handleClick('chapter71')} 
                className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''} dropdown-item `}>
                  Altering and Dropping Views  
                </li>

                <li onClick={() => handleClick('chapter72')} 
                className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''} dropdown-item `}>
                  Advantages of Using Views  
                </li>

<h5>Stored Procedures and Functions </h5>

                <li onClick={() => handleClick('chapter73')} 
                className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''} dropdown-item `}>
                  Introduction to Stored Procedures  
                </li>

                <li onClick={() => handleClick('chapter74')} 
                className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''} dropdown-item `}>
                  Creating Stored Procedures  
                </li>

                <li onClick={() => handleClick('chapter75')} 
                className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''} dropdown-item `}>
                  Using Parameters in Procedures  
                </li>

                <li onClick={() => handleClick('chapter76')} 
                className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''} dropdown-item `}>
                  Creating and Using Functions  
                </li>

                <li onClick={() => handleClick('chapter77')} 
                className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''} dropdown-item `}>
                  Advantages of Stored Procedures  
                </li>

<h5>Triggers</h5>

                <li onClick={() => handleClick('chapter78')} 
                className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''} dropdown-item `}>
                  What are Triggers
                </li>

                <li onClick={() => handleClick('chapter79')} 
                className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''} dropdown-item `}>
                  BEFORE and AFTER Triggers  
                </li>

                <li onClick={() => handleClick('chapter80')} 
                className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''} dropdown-item `}>
                  Creating Triggers  
                </li>

                <li onClick={() => handleClick('chapter81')} 
                className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''} dropdown-item `}>
                  Dropping Triggers  
                </li>


                <li onClick={() => handleClick('chapter82')} 
                className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''} dropdown-item `}>
                  Practical Applications of Triggers  
                </li>

<h5>Events and Scheduling</h5>

                <li onClick={() => handleClick('chapter83')} 
                className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''} dropdown-item `}>
                  Introduction to Events  
                </li>

                <li onClick={() => handleClick('chapter84')} 
                className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''} dropdown-item `}>
                  Creating Events  
                </li>

                <li onClick={() => handleClick('chapter85')} 
                className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''} dropdown-item `}>
                  Modifying Events  
                </li>

                <li onClick={() => handleClick('chapter86')} 
                className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''} dropdown-item `}>
                  Dropping Events  
                </li>

<h5>User Management and Security</h5>
                <li onClick={() => handleClick('chapter87')} 
                className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''} dropdown-item `}>
                  Creating and Managing Users  
                </li>

                <li onClick={() => handleClick('chapter88')} 
                className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''} dropdown-item `}>
                  Privileges and Roles  
                </li>

                <li onClick={() => handleClick('chapter89')} 
                className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''} dropdown-item `}>
                  Granting and Revoking Permissions  
                </li>

                <li onClick={() => handleClick('chapter90')} 
                className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''} dropdown-item `}>
                  Securing User Accounts  
                </li>

                <li onClick={() => handleClick('chapter91')} 
                className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''} dropdown-item `}>
                  SQL Injection Prevention  
                </li>

<h5>Backup and Recovery</h5>
                <li onClick={() => handleClick('chapter92')} 
                className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''} dropdown-item `}>
                  Logical Backup with mysqldump  
                </li>


                <li onClick={() => handleClick('chapter93')} 
                className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} dropdown-item `}>
                  Physical Backups  
                </li>

                <li onClick={() => handleClick('chapter94')} 
                className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''} dropdown-item `}>
                  Restoring Databases  
                </li>

                <li onClick={() => handleClick('chapter95')} 
                className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''} dropdown-item `}>
                  Exporting and Importing Data  
                </li>

<h5>Advanced</h5>
                <li onClick={() => handleClick('chapter96')} 
                className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''} dropdown-item `}>
                  Partitioning Tables
                </li>

                <li onClick={() => handleClick('chapter97')} 
                className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''} dropdown-item `}>
                  Replication   
                </li>

                <li onClick={() => handleClick('chapter98')} 
                className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''} dropdown-item `}>
                  MySQL Cluster Concepts  
                </li>

                <li onClick={() => handleClick('chapter99')} 
                className={`${style.chapter99} ${activeChapter === 'chapter99' ? style.active : ''} dropdown-item `}>
                  JSON Data  
                </li>

                <li onClick={() => handleClick('chapter100')} 
                className={`${style.chapter100} ${activeChapter === 'chapter100' ? style.active : ''} dropdown-item `}>
                  NoSQL Features  
                </li>






              </ul>
                </div>





                <div className={`${style.leftcolumn} col-2`}>

                    <ul className={`${style.chapters} `}>
                    <h5 className={style.stickyheading} > MySQL </h5>
                
                <li
                    onClick={() => handleClick('chapter1')}
                    className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} `}>
                    Intoduction
                </li>

               


                <li
                    onClick={() => handleClick('chapter2')}
                    className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}  `}>
                   Features and Benefits
                </li>


                <li onClick={() => handleClick('chapter3')}
                     className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} `}>
                   MySQL Editions
                </li>
    
                <li onClick={() => handleClick('chapter4')} 
                  className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}  `}>
                    Installing and Setting Up MySQL
                </li>


                <li onClick={() => handleClick('chapter5')} 
                className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}  `}>
                  Connecting to MySQL Server  
                </li>



                <li onClick={() => handleClick('chapter6')}
                className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} `}>
                  MySQL Workbench 
                </li>

                <li onClick={() => handleClick('chapter7')} 
                className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} `}>
                  Basic MySQL Commands
                </li>

<h5> Database Basics And datatypes </h5>

                <li onClick={() => handleClick('chapter8')}
                className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}  `}>
             Understanding Databases  
                </li>

                <li onClick={() => handleClick('chapter9')}
                  className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} `}>
                    Tables and Relationships  
                </li>

                <li onClick={() => handleClick('chapter10')}
                className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}  `}>
                  Numeric Data Types  
                </li>

                <li onClick={() => handleClick('chapter11')} 
                className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} `}>
                  String Data Types  
                    </li>


                <li onClick={() => handleClick('chapter12')}
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} `}>
                  Date and Time Types  
                </li>


            <li onClick={() => handleClick('chapter13')} 
                className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}  `}>
                  Binary Large Object Data Types (BLOB)
                </li>

                <li onClick={() => handleClick('chapter14')} 
                className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}  `}>
                  Spatial Data Types
                </li>

                <li onClick={() => handleClick('chapter15')} 
                  className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}  `}>
                    JSON Data Type
                </li>


<h5>Database Management</h5>
                <li onClick={() => handleClick('chapter16')} 
                className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}  `}>
                  Creating Databases  
                </li>

                <li onClick={() => handleClick('chapter17')}
                  className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}  `}>
                    Dropping Databases  
                </li>


                <li onClick={() => handleClick('chapter18')} 
                className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}  `}>
                  Selecting Databases  
                </li>


                <li onClick={() => handleClick('chapter19')}
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''}  `}>
                  Backing Up and Restoring Databases  
                </li>

<h5>Table Management</h5>

                <li onClick={() => handleClick('chapter20')} 
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}  `}>
                 Creating Tables  
                </li>

                <li onClick={() => handleClick('chapter21')}
                className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}  `}>
                  Altering Tables  
                </li>


                <li onClick={() => handleClick('chapter22')} 
                className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}  `}>
                  Dropping Tables  
                </li>

                <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}  `}>
                  Temporary Tables  
                </li>


                <li onClick={() => handleClick('chapter24')} 
                className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}  `}>
                  Copying Tables  
                </li>

<h5>Data Manipulation (DML) </h5>
                
                <li onClick={() => handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}  `}>
                  Inserting Data  
                </li>

                <li onClick={() => handleClick('chapter26')} 
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}  `}>
                  Updating Data  
                </li>

                <li onClick={() => handleClick('chapter27')} 
                className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}  `}>
                  Deleting Data  
                </li>

                <li onClick={() => handleClick('chapter28')} 
                 className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}  `}>
                  Replacing Data  
                </li>

                <h5>Data Integrity and Constraints</h5>
                <li onClick={() => handleClick('chapter63')} 
                className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''}  `}>
                  Primary Key  
                </li>

                <li onClick={() => handleClick('chapter64')} 
                className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''}  `}>
                  Foreign Key  
                </li>

                <li onClick={() => handleClick('chapter65')} 
                className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''}  `}>
                  Unique Constraint  
                </li>

                <li onClick={() => handleClick('chapter66')} 
                className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''}  `}>
                  Not Null Constraint  
                </li>

                <li onClick={() => handleClick('chapter67')} 
                className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''}  `}>
                  Default Values  
                </li>


                <li onClick={() => handleClick('chapter68')} 
                className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''}  `}>
                  Check Constraint  
                </li>

<h5>Querying Data (SQL Basics)</h5>

                <li onClick={() => handleClick('chapter29')} 
                className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} `}>
                  Select Data
                </li>


                <li onClick={() => handleClick('chapter30')} 
                className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} `}>
                  WHERE Clause  
                </li>


                <li onClick={() => handleClick('chapter31')} 
                className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} `}>
                    Sorting Data with ORDER BY
                </li>

                <li onClick={() => handleClick('chapter32')} 
                className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} `}>
                    Limiting Results with LIMIT
                </li>


                <li onClick={() => handleClick('chapter33')} 
                className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} `}>
                    Using Logical Operators (AND, OR, NOT)
                </li>

                <li onClick={() => handleClick('chapter34')} 
                className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} `}>
                    Eliminating Duplicates with DISTINCT
                </li>

<h5>Joins and Relationships</h5>
                <li onClick={() => handleClick('chapter35')} 
                className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''}  `}>
                    Introduction to Joins  
                </li>

                <li onClick={() => handleClick('chapter36')} 
                className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''}  `}>
                  INNER JOIN  
                </li>

              
                <li onClick={() => handleClick('chapter37')} 
                className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''}  `}>
                  LEFT JOIN  
                </li>

                <li onClick={() => handleClick('chapter38')} 
                className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''}  `}>
                  RIGHT JOIN  
                </li>

                <li onClick={() => handleClick('chapter39')} 
                className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''}  `}>
                  FULL OUTER JOIN  
                </li>

                <li onClick={() => handleClick('chapter40')} 
                className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''}  `}>
                  CROSS JOIN  
                </li>


                <li onClick={() => handleClick('chapter41')} 
                className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''}  `}>
                  Self Join  
                </li>


<h5>Subqueries</h5>
                <li onClick={() => handleClick('chapter42')} 
                className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''}  `}>
                  What are Subqueries
                </li>

                <li onClick={() => handleClick('chapter43')} 
                className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''}  `}>
                  Single-row Subqueries  
                </li>

                <li onClick={() => handleClick('chapter44')} 
                className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''}  `}>
                  Multi-row Subqueries  
                </li>

                <li onClick={() => handleClick('chapter45')} 
                className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''}  `}>
                  Correlated Subqueries  
                </li>

<h5>Grouping and Aggregate Functions</h5>
                <li onClick={() => handleClick('chapter46')} 
                className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''}  `}>
                  GROUP BY Clause  
                </li>


                <li onClick={() => handleClick('chapter47')} 
                className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''}  `}>
                  HAVING Clause  
                </li>



                <li onClick={() => handleClick('chapter48')} 
                className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''}  `}>
                  COUNT
                </li>

                <li onClick={() => handleClick('chapter49')} 
                className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''}  `}>
                  SUM
                </li>


                <li onClick={() => handleClick('chapter50')} 
                className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''}  `}>
                  AVG  
                </li>

                <li onClick={() => handleClick('chapter51')} 
                className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''}  `}>
                  MAX 
                </li>

                <li onClick={() => handleClick('chapter52')} 
                className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''}  `}>
                  MIN
                </li>

<h5>MySQL Functions</h5>
                <li onClick={() => handleClick('chapter53')} 
                className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''}  `}>
                  String Functions 
                </li>

                <li onClick={() => handleClick('chapter54')} 
                className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''}  `}>
                  Numeric Functions 
                </li>

                <li onClick={() => handleClick('chapter55')} 
                className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''}  `}>
                  Date and Time Functions 
                </li>


                <li onClick={() => handleClick('chapter56')} 
                className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''}  `}>
                  Conversion Functions 
                </li>


                <li onClick={() => handleClick('chapter57')} 
                className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''}  `}>
                  Control Flow Functions  
                </li>

<h5>Indexing</h5>
                <li onClick={() => handleClick('chapter58')} 
                className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''}  `}>
                  What is an Index
                </li>

                <li onClick={() => handleClick('chapter59')} 
                className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''}  `}>
                  Types of Indexes (Primary, Unique, Full-text)  
                </li>


                <li onClick={() => handleClick('chapter60')} 
                className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''}  `}>
                  Creating Indexes  
                </li>

                <li onClick={() => handleClick('chapter61')} 
                className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''}  `}>
                  Dropping Indexes
                </li>

                <li onClick={() => handleClick('chapter62')} 
                className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''}  `}>
                  Performance Impact of Indexing  
                </li>




<h5>Views</h5>
                <li onClick={() => handleClick('chapter69')} 
                className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''}  `}>
                  Introduction to Views  
                </li>

                <li onClick={() => handleClick('chapter70')} 
                className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''}  `}>
                  Creating Views  
                </li>

                <li onClick={() => handleClick('chapter71')} 
                className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''}  `}>
                  Altering and Dropping Views  
                </li>

                <li onClick={() => handleClick('chapter72')} 
                className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''}  `}>
                  Advantages of Using Views  
                </li>

<h5>Stored Procedures and Functions </h5>

                <li onClick={() => handleClick('chapter73')} 
                className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''}  `}>
                  Introduction to Stored Procedures  
                </li>

                <li onClick={() => handleClick('chapter74')} 
                className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''}  `}>
                  Creating Stored Procedures  
                </li>

                <li onClick={() => handleClick('chapter75')} 
                className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''}  `}>
                  Using Parameters in Procedures  
                </li>

                <li onClick={() => handleClick('chapter76')} 
                className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''}  `}>
                  Creating and Using Functions  
                </li>

                <li onClick={() => handleClick('chapter77')} 
                className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''}  `}>
                  Advantages of Stored Procedures  
                </li>

<h5>Triggers</h5>

                <li onClick={() => handleClick('chapter78')} 
                className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''}  `}>
                  What are Triggers
                </li>

                <li onClick={() => handleClick('chapter79')} 
                className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''}  `}>
                  BEFORE and AFTER Triggers  
                </li>

                <li onClick={() => handleClick('chapter80')} 
                className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''}  `}>
                  Creating Triggers  
                </li>

                <li onClick={() => handleClick('chapter81')} 
                className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''}  `}>
                  Dropping Triggers  
                </li>


                <li onClick={() => handleClick('chapter82')} 
                className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''}  `}>
                  Practical Applications of Triggers  
                </li>

<h5>Events and Scheduling</h5>

                <li onClick={() => handleClick('chapter83')} 
                className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''}  `}>
                  Introduction to Events  
                </li>

                <li onClick={() => handleClick('chapter84')} 
                className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''}  `}>
                  Creating Events  
                </li>

                <li onClick={() => handleClick('chapter85')} 
                className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''}  `}>
                  Modifying Events  
                </li>

                <li onClick={() => handleClick('chapter86')} 
                className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''}  `}>
                  Dropping Events  
                </li>

<h5>User Management and Security</h5>
                <li onClick={() => handleClick('chapter87')} 
                className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''}  `}>
                  Creating and Managing Users  
                </li>

                <li onClick={() => handleClick('chapter88')} 
                className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''}  `}>
                  Privileges and Roles  
                </li>

                <li onClick={() => handleClick('chapter89')} 
                className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''}  `}>
                  Granting and Revoking Permissions  
                </li>

                <li onClick={() => handleClick('chapter90')} 
                className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''}  `}>
                  Securing User Accounts  
                </li>

                <li onClick={() => handleClick('chapter91')} 
                className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''}  `}>
                  SQL Injection Prevention  
                </li>

<h5>Backup and Recovery</h5>
                <li onClick={() => handleClick('chapter92')} 
                className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''}  `}>
                  Logical Backup with mysqldump  
                </li>


                <li onClick={() => handleClick('chapter93')} 
                className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} `}>
                  Physical Backups  
                </li>

                <li onClick={() => handleClick('chapter94')} 
                className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''}  `}>
                  Restoring Databases  
                </li>

                <li onClick={() => handleClick('chapter95')} 
                className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''}  `}>
                  Exporting and Importing Data  
                </li>

<h5>Advanced</h5>
                <li onClick={() => handleClick('chapter96')} 
                className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''}  `}>
                  Partitioning Tables  
                </li>

                <li onClick={() => handleClick('chapter97')} 
                className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''}  `}>
                  Replication   
                </li>

                <li onClick={() => handleClick('chapter98')} 
                className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''}  `}>
                  MySQL Cluster Concepts  
                </li>

                <li onClick={() => handleClick('chapter99')} 
                className={`${style.chapter99} ${activeChapter === 'chapter99' ? style.active : ''}  `}>
                  JSON Data  
                </li>

                <li onClick={() => handleClick('chapter100')} 
                className={`${style.chapter100} ${activeChapter === 'chapter100' ? style.active : ''}  `}>
                  NoSQL Features  
                </li>


               
                    </ul>
                </div>




                <div className={`${style.rightcolumn} col`}>


                    {/* Chapter Content */}


       
  
  
      {selectedChapter === 'chapter1' && (
        <div className={style.chaptercontent}>
          <h1 className={style.heading}>Introduction</h1>
          <p>
            <strong>MySQL</strong> is an open-source relational database management system (RDBMS) that uses Structured Query Language (SQL) to manage and interact with databases. It is one of the most popular databases used for web applications, particularly for storing and retrieving data in a structured way. MySQL is widely known for its reliability, flexibility, speed, and ease of use. It is often used in conjunction with other technologies in software stacks such as the <strong>LAMP</strong> stack (Linux, Apache, MySQL, PHP/Perl/Python).
          </p><br/>

          <h2 style={{paddingBottom:"6px"}}>Key Features of MySQL:</h2>
          <ul>
            <li>
              <strong>Open-source:</strong> MySQL is free to use, and its source code is publicly available. This means anyone can contribute to its development, customize it, or use it without licensing fees.
            </li><br/>
            <li>
              <strong>Relational Database:</strong> MySQL is based on the relational model, meaning it stores data in tables with predefined relationships between them. This helps in organizing data in a structured way and allows efficient querying.
            </li><br/>
            <li>
              <strong>SQL-based:</strong> MySQL uses <strong>SQL (Structured Query Language)</strong>, a standard language used to interact with relational databases. SQL is used for querying data, inserting records, updating, and deleting data in the database.
            </li><br/>
            <li>
              <strong>ACID Compliance:</strong> MySQL is designed to support the ACID (Atomicity, Consistency, Isolation, Durability) properties, ensuring that database transactions are processed reliably and securely.
            </li><br/>
            <li>
              <strong>Cross-Platform Support:</strong> MySQL runs on various operating systems, including Linux, Windows, macOS, and more, making it versatile for different development environments.
            </li><br/>
            <li>
              <strong>High Performance:</strong> MySQL is known for being fast, especially in read-heavy applications. It supports indexing, query caching, and other optimization techniques that improve query performance.
            </li><br/>
            <li>
              <strong>Scalability:</strong> MySQL can handle large databases and can scale up from small applications to large, enterprise-level systems. It supports replication, clustering, and partitioning to handle large amounts of data and traffic.
            </li><br/>
            <li>
              <strong>Security:</strong> MySQL provides various security features such as encrypted connections (SSL), user privileges, and data access control to protect sensitive data.
            </li><br/>
            <li>
              <strong>Replication:</strong> MySQL allows for <strong>master-slave replication</strong>, meaning you can have one main server (master) and several backup servers (slaves), which helps in distributing load and ensuring high availability.
            </li><br/>
            <li>
              <strong>Data Types:</strong> MySQL supports various data types, including integers, floating-point numbers, strings, dates, and binary data, allowing for flexibility in how data is stored and retrieved.
            </li>
          </ul><br/>

          <h2 style={{paddingBottom:"6px"}}>Common Uses of MySQL:</h2>
          <ul>
            <li><strong>Web Development:</strong> MySQL is often used in web applications for managing user data, content, and transactional information. It works well with server-side scripting languages like PHP.</li><br/>
            <li><strong>Content Management Systems (CMS):</strong> Popular platforms like WordPress, Joomla, and Drupal use MySQL to store and manage content.</li><br/>
            <li><strong>E-commerce:</strong> Online stores use MySQL to handle product inventories, customer data, orders, and transactions.</li><br/>
            <li><strong>Data Warehousing and Analytics:</strong> MySQL can be used for data storage in smaller-scale analytics systems and reporting applications.</li>
          </ul><br/>

          <h2 style={{paddingBottom:"6px"}}>Example of a MySQL Database:</h2>
          <p>Consider a simple database for an online store. It might have tables like:</p>
          <ul>
            <li><strong>Customers:</strong> Stores customer information such as name, email, address.</li><br/>
            <li><strong>Products:</strong> Stores details about products like name, description, price.</li><br/>
            <li><strong>Orders:</strong> Stores customer orders, which may have many products.</li><br/>
            <li><strong>Payments:</strong> Stores payment details for transactions.</li>
          </ul>
          <p>
            MySQL uses <strong>primary keys</strong> to uniquely identify records in each table and <strong>foreign keys</strong> to link records across tables (e.g., linking an order to a customer).
          </p><br/>

          <h2 style={{paddingBottom:"6px"}}>MySQL Versions:</h2>
          <ul>
            <li><strong>Community Edition:</strong> Open-source and free to use.</li><br/>
            <li><strong>Enterprise Edition:</strong> Offers advanced features like better support, security, and scalability (usually used by large organizations).</li>
          </ul><br/>

          <p>
            In summary, MySQL is a versatile and powerful RDBMS that helps developers manage data for applications, ranging from small websites to large enterprise-level systems. Its popularity is due to its ease of use, performance, and extensive support.
          </p>
        </div>
      )}


 



      {selectedChapter === 'chapter2' && (
        <div className={style.chaptercontent}>
          <h1 className={style.heading}>Features and Benefits</h1>

          <h2 style={{paddingBottom:"6px"}}>Features of MySQL (Simple Explanation)</h2>
          <ul>
            <li>
              <strong>Open-Source:</strong> MySQL is free to use and its source code is available for everyone. You can customize it according to your needs without any licensing cost.
            </li><br/>
            <li>
              <strong>Relational Database:</strong> MySQL organizes data in tables, making it easier to store, manage, and retrieve structured data.
            </li><br/>
            <li>
              <strong>SQL-Based:</strong> MySQL uses SQL (Structured Query Language), which is simple to learn and widely used for interacting with databases.
            </li><br/>
            <li>
              <strong>High Speed:</strong> MySQL is optimized for fast performance, especially for applications that require a lot of data reading.
            </li><br/>
            <li>
              <strong>Cross-Platform Support:</strong> MySQL works on many operating systems like Windows, Linux, and macOS, allowing developers to use it in diverse environments.
            </li><br/>
            <li>
              <strong>ACID Compliance:</strong> It ensures reliable transactions by maintaining <strong>Atomicity, Consistency, Isolation, and Durability</strong>, which are critical for banking and e-commerce systems.
            </li><br/>
            <li>
              <strong>Scalability:</strong> MySQL can handle small databases for personal projects and large databases for big businesses, making it flexible for various needs.
            </li><br/>
            <li>
              <strong>Security:</strong> MySQL offers robust security features like password encryption and user privileges to protect your data from unauthorized access.
            </li><br/>
            <li>
              <strong>Replication:</strong> MySQL allows you to copy data from one server to another (master-slave setup), which helps with load balancing and data backup.
            </li><br/>
            <li>
              <strong>Support for Multiple Data Types:</strong> MySQL can store numbers, text, dates, and even files, making it versatile for many applications.
            </li>
          </ul><br/>

          <h2 style={{paddingBottom:"6px"}}>Benefits of Using MySQL</h2>
          <ul>
            <li>
              <strong>Easy to Learn and Use:</strong> Its straightforward commands and user-friendly interface make it accessible to beginners and professionals alike.
            </li><br/>
            <li>
              <strong>Cost-Effective:</strong> Since it's open-source, you save on licensing costs, making it a great option for startups and small businesses.
            </li><br/>
            <li>
              <strong>Reliable Performance:</strong> MySQL is stable and efficient, ensuring your application runs smoothly even with large amounts of data.
            </li><br/>
            <li>
              <strong>Flexibility:</strong> Works well with many programming languages like PHP, Python, Java, and platforms like WordPress and Joomla.
            </li><br/>
            <li>
              <strong>Community Support:</strong> MySQL has a large user community, so you can find solutions to issues easily through forums and documentation.
            </li>
          </ul><br/>

          <h2 style={{paddingBottom:"6px"}}>Advantages of MySQL</h2>
          <ul>
            <li><strong>Free and Open Source:</strong> No licensing fees; the source code is customizable.</li><br/>
            <li><strong>High Speed and Performance:</strong> Handles complex queries and large databases quickly.</li><br/>
            <li><strong>Secure:</strong> Features like user authentication and data encryption protect against unauthorized access.</li><br/>
            <li><strong>Scalable:</strong> Grows with your application, whether it’s a small website or a large enterprise system.</li><br/>
            <li><strong>Cross-Platform:</strong> Works on different operating systems and integrates with popular technologies.</li><br/>
            <li><strong>Regular Updates:</strong> Frequent updates bring new features and improve performance.</li><br/>
            <li><strong>Rich Features:</strong> Includes tools for backups, replication, and clustering, making it enterprise-ready.</li>
          </ul><br/>

          <h2 style={{paddingBottom:"6px"}}>Disadvantages of MySQL</h2>
          <ul>
            <li><strong>Limited for Large-Scale Applications:</strong> While great for medium and small applications, it may not handle extremely large systems as effectively as databases like Oracle or PostgreSQL.</li><br/>
            <li><strong>Community Version Limitations:</strong> Some advanced features are only available in the paid Enterprise edition.</li><br/>
            <li><strong>Complex Features Require Expertise:</strong> Features like replication, clustering, and performance optimization need experienced professionals.</li><br/>
            <li><strong>Limited Built-In Analytics:</strong> MySQL isn't ideal for heavy data analytics or business intelligence tasks without third-party tools.</li><br/>
            <li><strong>Locking Issues:</strong> Table locking can occur in some situations, which might slow down operations in write-heavy applications.</li><br/>
            <li><strong>No Full SQL Standard Compliance:</strong> MySQL doesn't fully adhere to all SQL standards, which might cause compatibility issues with certain systems.</li>
          </ul><br/>

          <h2 style={{paddingBottom:"6px"}}>Summary</h2>
          <p>
            <strong>Why Choose MySQL?</strong> MySQL is great for web development, startups, and medium-sized applications due to its ease of use, affordability, and reliability.
          </p>
          <p>
            <strong>When to Avoid?</strong> For massive enterprise systems requiring highly advanced features or analytics, alternatives like PostgreSQL, Oracle, or Microsoft SQL Server might be better.
          </p>
        </div>
      )}
    



{selectedChapter === "chapter3" && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>MySQL Editions</h1>

    <p>
      MySQL is offered in several editions tailored to different user needs, from individual developers to large-scale enterprise organizations. Each edition provides unique features, benefits, and tools, making MySQL versatile for various use cases.
    </p><br/>

   

    <h2 style={{paddingBottom:"6px"}}>1. MySQL Community Edition</h2>
    <p>
      <strong>Description:</strong> The Community Edition is the free and open-source version of MySQL. It is ideal for developers, small businesses, and startups that require a reliable database without licensing costs.
    </p>
    <ul>
      <li>Open-source and free to use.</li><br/>
      <li>Cross-platform compatibility (Windows, Linux, macOS).</li><br/>
      <li>Support for SQL standards and essential database functions.</li><br/>
      <li>Basic security features like user authentication and data encryption.</li>
    </ul><br/>
    <p>
      <strong>Best For:</strong> Individual developers, small-scale projects, learning, and prototyping.
    </p>
    <p>
      <strong>Limitations:</strong> Lacks advanced features like high-performance tools, analytics, and advanced security.
    </p><br/>

    

    <h2 style={{paddingBottom:"6px"}}>2. MySQL Enterprise Edition</h2>
    <p>
      <strong>Description:</strong> This is a commercial version of MySQL designed for medium to large-scale enterprises. It provides enhanced security, performance, and management tools.
    </p>
    <ul>
      <li>Data Security: Includes data masking, encryption, and firewall.</li><br/>
      <li>High Availability: Tools for replication, clustering, and backup.</li><br/>
      <li>Performance Tuning: Query performance optimization with advanced monitoring tools.</li><br/>
      <li>Support: Comes with 24/7 technical support from Oracle.</li><br/>
      <li>Scalability: Advanced features for handling large databases and distributed systems.</li>
    </ul><br/>
    <p>
      <strong>Best For:</strong> Enterprise-level applications and businesses with high availability and data security requirements.
    </p>
    <p>
      <strong>Limitations:</strong> Licensing fees, which may not suit small organizations.
    </p><br/>

 

    <h2 style={{paddingBottom:"6px"}}>3. MySQL Cluster Edition</h2>
    <p>
      <strong>Description:</strong> A specialized edition for high-performance, high-availability, and distributed applications. MySQL Cluster is designed for applications requiring real-time responsiveness and scalability.
    </p>
    <ul>
      <li>Distributed Database: Enables clustering across multiple servers for load balancing and redundancy.</li><br/>
      <li>Real-Time Performance: Designed for applications like telecom systems, financial transactions, and IoT.</li><br/>
      <li>Fault Tolerance: Automatically recovers from failures.</li><br/>
      <li>High Scalability: Handles millions of transactions per second.</li>
    </ul>
    <p>
      <strong>Best For:</strong> Telecommunications, e-commerce, and financial applications.
    </p>
    <p>
      <strong>Limitations:</strong> Complex setup and maintenance, higher resource requirements.
    </p><br/>

    

    <h2 style={{paddingBottom:"6px"}}>4. MySQL Embedded (OEM/ISV) Edition</h2>
    <p>
      <strong>Description:</strong> The Embedded Edition is designed for Independent Software Vendors (ISVs) and Original Equipment Manufacturers (OEMs) who want to integrate MySQL into their products.
    </p>
    <ul>
      <li>Small memory footprint, optimized for embedded systems.</li><br/>
      <li>Customizable to meet specific application needs.</li><br/>
      <li>Supports seamless integration into software and devices.</li><br/>
      <li>Reliable and efficient for resource-constrained environments.</li>
    </ul><br/>
    <p>
      <strong>Best For:</strong> Software vendors integrating MySQL into their applications, hardware manufacturers embedding MySQL in devices.
    </p>
    <p>
      <strong>Limitations:</strong> Requires expertise in integration.
    </p>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>5. MySQL HeatWave</h2>
    <p>
      <strong>Description:</strong> A specialized edition combining OLTP (Online Transaction Processing) and OLAP (Online Analytical Processing) capabilities for advanced data analytics on MySQL.
    </p>
    <ul>
      <li>In-Memory Processing: Accelerates complex analytics queries.</li><br/>
      <li>Integration with Oracle Cloud: Optimized for Oracle Cloud Infrastructure (OCI).</li><br/>
      <li>Real-Time Insights: Combines transactional and analytical workloads seamlessly.</li>
    </ul><br/>
    <p>
      <strong>Best For:</strong> Businesses requiring real-time analytics and decision-making.
    </p>
    <p>
      <strong>Limitations:</strong> Requires Oracle Cloud Infrastructure for full functionality.
    </p>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>Comparison of MySQL Editions</h2>
    <table className={style.table}>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Community Edition</th>
          <th>Enterprise Edition</th>
          <th>Cluster Edition</th>
          <th>Embedded Edition</th>
          <th>HeatWave</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Cost</td>
          <td>Free</td>
          <td>Paid (Subscription)</td>
          <td>Paid</td>
          <td>Paid</td>
          <td>Paid</td>
        </tr>
        <tr>
          <td>Security Features</td>
          <td>Basic</td>
          <td>Advanced</td>
          <td>Advanced</td>
          <td>Customizable</td>
          <td>Advanced</td>
        </tr>
        <tr>
          <td>High Availability</td>
          <td>Basic Replication</td>
          <td>Advanced Tools</td>
          <td>Fully Distributed</td>
          <td>Limited</td>
          <td>Advanced</td>
        </tr>
        <tr>
          <td>Scalability</td>
          <td>Moderate</td>
          <td>High</td>
          <td>Very High</td>
          <td>Limited</td>
          <td>High</td>
        </tr>
        <tr>
          <td>Best For</td>
          <td>Beginners/Startups</td>
          <td>Enterprises</td>
          <td>Telecom/IoT</td>
          <td>ISVs/OEMs</td>
          <td>Cloud Apps</td>
        </tr>
      </tbody>
    </table>

      </div>
)}




{selectedChapter === 'chapter4' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Installing and Setting Up MySQL</h1>

    <h2 style={{paddingBottom:"6px"}}>Installing and Setting Up MySQL: A Step-by-Step Guide</h2>
    <p>
      Setting up MySQL involves downloading, installing, and configuring the
      MySQL Server on your system. Here’s a detailed guide to help you through
      the process on various operating systems:
    </p>
    
    <br/>

    <h3 style={{paddingBottom:"6px"}}>1. Installing MySQL on Windows</h3>
    <h4>Step 1: Download MySQL</h4>
    <ol>
      <li>
        Go to the <a href="https://dev.mysql.com/downloads/" target="_blank" rel="noopener noreferrer">MySQL official website</a>.
      </li><br/>
      <li>Download the MySQL Installer for Windows (Community Edition is free).</li>
    </ol><br/>

    <h4>Step 2: Run the Installer</h4>
    <ul>
      <li>Open the installer file.</li><br/>
      <li>Select the setup type:</li>
      <ul><br/>
        <li>
          <strong>Developer Default</strong>: Includes MySQL Server, Workbench,
          Shell, and other tools.
        </li><br/>
        <li>
          <strong>Server Only</strong>: Only installs MySQL Server.
        </li><br/>
        <li>
          <strong>Custom</strong>: Allows you to choose components manually.
        </li>
      </ul><br/>
    </ul><br/>

    <h4 style={{paddingBottom:"6px"}}>Step 3: Configure MySQL</h4>
    <ol>
      <li>
        <strong>Server Configuration:</strong> Choose a Standalone MySQL Server
        or a Clustered Instance. Set the configuration type (Development,
        Server, or Dedicated).
      </li><br/>
      <li>
        <strong>Authentication:</strong> Choose an authentication method
        (default: SHA-256). Set a strong root password and optionally create
        additional user accounts.
      </li><br/>
      <li>
        <strong>Connectivity:</strong> Set the default port (3306) and enable
        networking.
      </li><br/>
      <li>Finish configuration and start the MySQL Server.</li>
    </ol><br/>

    <h4 style={{paddingBottom:"6px"}}>Step 4: Test Installation</h4>
    <ol>
      <li>Open the MySQL Command Line Client or MySQL Workbench.</li><br/>
      <li>
        Connect using the root account credentials to ensure MySQL is running.
      </li>
    </ol>
    <br/>

    <h3 style={{paddingBottom:"6px"}}>2. Installing MySQL on macOS</h3>
    <h4>Step 1: Download MySQL</h4>
    <ol>
      <li>
        Visit the <a href="https://dev.mysql.com/downloads/" target="_blank" rel="noopener noreferrer">MySQL Downloads page</a>.
      </li><br/>
      <li>Download the DMG archive for macOS.</li>
    </ol><br/>

    <h4>Step 2: Install MySQL</h4>
    <ol>
      <li>Open the downloaded DMG file.</li><br/>
      <li>Follow the installation prompts to install MySQL.</li><br/>
      <li>At the end of the installation, you'll be prompted to set up a root password.</li>
    </ol><br/>

    <h4>Step 3: Configure MySQL</h4>
    <ol>
      <li>Go to System Preferences and look for the MySQL pane.</li><br/>
      <li>Start the MySQL Server and configure it to start at system boot if desired.</li>
    </ol><br/>

    <h4>Step 4: Test Installation</h4>
    <pre>
      <code>mysql -u root -p</code>
    </pre>
    <p>Enter your root password and test MySQL commands.</p>

    <br/>

    <h3 style={{paddingBottom:"6px"}}>3. Installing MySQL on Linux</h3>
    <h4>Step 1: Update System Packages</h4>
    <pre>
      <code>
        sudo apt update
        <br />
        sudo apt upgrade
      </code>
    </pre><br/>

    <h4>Step 2: Install MySQL</h4>
    <pre>
      <code>sudo apt install mysql-server</code>
    </pre>
    <p>For RPM-based systems (like CentOS/RHEL), use:</p>
    <pre>
      <code>sudo yum install mysql-server</code>
    </pre><br/>

    <h4>Step 3: Secure the Installation</h4>
    <pre>
      <code>sudo mysql_secure_installation</code>
    </pre><br/>

    <h4>Step 4: Start MySQL</h4>
    <pre>
      <code>
        sudo systemctl start mysql
        <br />
        sudo systemctl enable mysql
      </code>
    </pre><br/>

    <h4>Step 5: Test Installation</h4>
    <pre>
      <code>mysql -u root -p</code>
    </pre>

    <br/>

    <h3 style={{paddingBottom:"6px"}}>Setting Up MySQL</h3>
    <h4>1. Create a Database</h4>
    <pre>
      <code>CREATE DATABASE your_database_name;</code>
    </pre><br/>

    <h4>2. Create a User</h4>
    <pre>
      <code>
        CREATE USER 'username'@'localhost' IDENTIFIED BY 'password';
      </code>
    </pre><br/>

    <h4>3. Grant Privileges</h4>
    <pre>
      <code>
        GRANT ALL PRIVILEGES ON your_database_name.* TO 'username'@'localhost';
        <br />
        FLUSH PRIVILEGES;
      </code>
    </pre><br/>

    <h4>4. Test the User</h4>
    <pre>
      <code>mysql -u username -p</code>
    </pre>

    <br/>

    <h3>Tools to Use with MySQL</h3>
    <ul>
      <li><strong>MySQL Workbench:</strong> A GUI tool for database design and administration.</li><br/>
      <li><strong>phpMyAdmin:</strong> A web-based interface for managing MySQL databases.</li><br/>
      <li><strong>MySQL Shell:</strong> An advanced tool for executing SQL queries and managing MySQL instances.</li>
    </ul>

    <br/>

    <h3>Tips for Beginners</h3>
    <ul>
      <li>Always secure the MySQL root user with a strong password.</li><br/>
      <li>Use a GUI tool like MySQL Workbench for easier database management.</li><br/>
      <li>Regularly backup your databases using:</li>
      <pre>
        <code>{`mysqldump -u root -p your_database_name > backup.sql`}</code>
      </pre><br/>
      <li>Monitor and tune performance for larger databases using MySQL's performance schema.</li>
    </ul>
  </div>
)}




{selectedChapter === 'chapter5' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Connecting to a MySQL Server </h1>


        <p>
          Connecting to a MySQL server allows you to interact with databases, execute queries, and manage the server. Below is a comprehensive guide on how to connect to a MySQL server using various methods:
        </p>

        <br/>

        <h2>1. Connecting via MySQL Command Line Client</h2>
        <p>
          The MySQL Command Line Client is a lightweight tool provided with the MySQL installation.
        </p>
        <ol>
          <li>Open the Command Line.</li><br/>
          <li>
            Use the following command to connect:
            <pre>
              <code>mysql -u username -p</code>
            </pre>
            Replace <code>username</code> with your MySQL username (e.g., <code>root</code>).
          </li><br/>
          <li>When prompted, enter the password associated with the user account.</li>
        </ol><br/>

        <p><strong>Example:</strong></p>
        <pre>
          <code>mysql -u root -p</code>
        </pre>
        <p>
          After entering the password, you’ll see the MySQL prompt (<code>mysql&gt;</code>), where you can run SQL commands.
        </p>

        <br/>
        <h2>2. Connecting via MySQL Workbench</h2>
        <p>
          MySQL Workbench is a graphical user interface tool that simplifies database management.
        </p><br/>
        <ol>
          <li>
            <strong>Install MySQL Workbench:</strong> Download and install from the{' '}
            <a href="https://dev.mysql.com/downloads/workbench/" target="_blank" rel="noopener noreferrer">
              MySQL Workbench official website
            </a>.
          </li><br/>
          <li>
            <strong>Create a Connection:</strong>
            <ul>
              <li>Open MySQL Workbench.</li><br/>
              <li>Click on <strong>+</strong> under "MySQL Connections."</li><br/>
              <li>Fill in the connection details:
                <ul>
                  <li><strong>Connection Name:</strong> A friendly name for your connection.</li><br/>
                  <li><strong>Hostname:</strong> The server address (e.g., <code>localhost</code> or an IP address).</li><br/>
                  <li><strong>Port:</strong> Default is <code>3306</code>.</li><br/>
                  <li><strong>Username:</strong> Your MySQL username.</li><br/>
                  <li><strong>Password:</strong> Click "Store in Vault" to save the password securely.</li>
                </ul>
              </li>
            </ul>
          </li><br/>
          <li>
            <strong>Test Connection:</strong> Click "Test Connection" to ensure everything is set up correctly.
          </li><br/>
          <li>
            <strong>Connect:</strong> Once the connection is tested, click "OK" to save. Double-click the connection to open it.
          </li>
        </ol><br/>

        

        <h2>3. Connecting Programmatically</h2>
        <p style={{paddingBottom:"6px"}}>
          You can connect to a MySQL server using programming languages. Below are examples for popular languages:
        </p>

        <h3>a. Python</h3>
        <pre>
          <code>
{`import mysql.connector

connection = mysql.connector.connect(
    host="localhost",  # Replace with your host
    user="root",       # Replace with your username
    password="your_password",  # Replace with your password
    database="your_database"   # Optional: specify the database
)

if connection.is_connected():
    print("Connected to MySQL Server")
    connection.close()`}
          </code>
        </pre><br/>

        <h3>b. PHP</h3>
        <pre>
          <code>
{`<?php
$servername = "localhost";
$username = "root";
$password = "your_password";
$dbname = "your_database";

// Create connection
$conn = new mysqli($servername, $username, $password, $dbname);

// Check connection
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}
echo "Connected successfully";
?>`}
          </code>
        </pre><br/>

        <h3>c. Java</h3>
        <pre>
          <code>
{`import java.sql.Connection;
import java.sql.DriverManager;

public class Main {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/your_database";
        String username = "root";
        String password = "your_password";

        try {
            Connection connection = DriverManager.getConnection(url, username, password);
            System.out.println("Connected to MySQL Server");
            connection.close();
        } catch (Exception e) {
            e.printStackTrace();
        }
    }
}`}
          </code>
        </pre>

        <br/>

        <h2>4. Connecting via Remote Server</h2>
        <p>
          If your MySQL server is hosted remotely, follow these steps:
        </p>
        <ol>
          <li>Ensure the server allows remote connections.</li><br/>
          <li>
            Update the MySQL configuration (<code>my.cnf</code> or <code>my.ini</code>):
            <pre>
              <code>bind-address = 0.0.0.0</code>
            </pre>
          </li><br/>
          <li>Restart the MySQL server:
            <pre>
              <code>sudo systemctl restart mysql</code>
            </pre>
          </li><br/>
          <li>
            Grant remote access to a user:
            <pre>
              <code>
{`GRANT ALL PRIVILEGES ON *.* TO 'username'@'%' IDENTIFIED BY 'password';
FLUSH PRIVILEGES;`}
              </code>
            </pre>
          </li><br/>
        </ol>

        <br/>

        <h2>5. Troubleshooting Common Issues</h2>
        <ul>
          <li><strong>"Access denied for user":</strong> Check the username and password.</li><br/>
          <li>
            <strong>"Can’t connect to MySQL server":</strong> Verify the server is running:
            <pre>
              <code>sudo systemctl status mysql</code>
            </pre>
          </li>
        </ul>
      </div>
    )}



{selectedChapter === 'chapter6' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  MySQL Workbench (Overview)  </h1>

      <p>MySQL Workbench is a powerful and versatile visual tool for database management and design. It simplifies interacting with MySQL databases through an intuitive graphical interface, making it a popular choice among developers, database administrators, and students learning MySQL.</p>

      <br/>

      <h3>Key Features of MySQL Workbench</h3>
      <p>MySQL Workbench is designed to meet the needs of database professionals in various roles. Here’s an overview of its main features:</p>

      <ul>
        <li><strong>Database Design and Modeling:</strong>
          <ul><br/>
            <li><strong>Visual Data Modeling:</strong> Allows users to create, modify, and design database schemas visually.</li><br/>
            <li><strong>Forward and Reverse Engineering:</strong> Generate SQL scripts from models or generate models from an existing database.</li><br/>
            <li><strong>ER Diagrams:</strong> Design and visualize relationships between tables in an Entity-Relationship Diagram format.</li>
          </ul><br/>
        </li>
        
        <li><strong>SQL Development:</strong>
          <ul><br/>
            <li><strong>Query Editor:</strong> Write, execute, and optimize SQL queries.</li><br/>
            <li><strong>Syntax Highlighting:</strong> Highlights SQL syntax to improve readability.</li><br/>
            <li><strong>Result Panel:</strong> Displays query results in a tabular format, making it easier to analyze data.</li><br/>
            <li><strong>Error Reporting:</strong> Provides detailed feedback for syntax or execution errors.</li>
          </ul><br/>
        </li>

        <li><strong>Server Administration:</strong>
          <ul><br/>
            <li><strong>User Management:</strong> Manage database users, roles, and permissions.</li><br/>
            <li><strong>Performance Monitoring:</strong> View server health, analyze slow queries, and monitor CPU/memory usage.</li><br/>
            <li><strong>Backup and Restore:</strong> Schedule and manage database backups and restore from backup files.</li>
          </ul><br/>
        </li>

        <li><strong>Data Import and Export:</strong>
          <ul><br/>
            <li>Import data from CSV, JSON, or SQL files.</li><br/>
            <li>Export tables or entire databases to various formats.</li>
          </ul><br/>
        </li>

        <li><strong>Database Migration:</strong>
          <ul><br/>
            <li>Migrate databases from other database systems (e.g., Oracle, SQL Server) to MySQL using the migration wizard.</li><br/>
            <li>Analyze and fix compatibility issues automatically.</li>
          </ul><br/>
        </li>

        <li><strong>Scripting and Automation:</strong>
          <ul><br/>
            <li>Supports Python and Lua scripts for automating repetitive tasks.</li><br/>
            <li>Execute custom scripts to extend MySQL Workbench functionalities.</li>
          </ul><br/>
        </li>

        <li><strong>Extensibility:</strong>
          <ul><br/>
            <li>Plugin support for additional tools and utilities.</li><br/>
            <li>Customize the interface to suit your workflow.</li>
          </ul><br/>
        </li>
      </ul>

      <br/>

      <h3>Why Use MySQL Workbench?</h3>
      <p>MySQL Workbench offers several advantages, making it an essential tool for anyone working with MySQL databases:</p><br/>
      <ul>
        <li><strong>User-Friendly Interface:</strong> The graphical interface makes it easier to manage complex databases.</li><br/>
        <li><strong>Multi-Platform Support:</strong> Available for Windows, macOS, and Linux.</li><br/>
        <li><strong>Efficiency:</strong> Simplifies database design, query execution, and server management tasks.</li><br/>
        <li><strong>Free Edition:</strong> The Community Edition is free and provides most features needed for small to medium-sized projects.</li>
      </ul>

      <br/>

      <h3>How to Get Started with MySQL Workbench</h3><br/>
      <h4 style={{paddingBottom:"6px"}}>1. Installation</h4>
      <p>Download MySQL Workbench from the <a href="https://dev.mysql.com/downloads/workbench/" target="_blank" rel="noopener noreferrer">MySQL official website</a>.</p>
      <p>Install it on your system (available for Windows, macOS, and Linux).</p>
      <br/>
      <h4 style={{paddingBottom:"6px"}}>2. Creating a Connection</h4>
      <p>Open MySQL Workbench and click the <strong>+</strong> button under "MySQL Connections." Provide the connection details:</p>
      <ul>
        <li><strong>Hostname:</strong> Server address (e.g., `localhost` or an IP address).</li><br/>
        <li><strong>Port:</strong> Default is `3306`.</li><br/>
        <li><strong>Username:</strong> Your MySQL username.</li><br/>
        <li><strong>Password:</strong> Optional; can be stored securely.</li>
      </ul><br/>
      <p>Test the connection and save it.</p>

      <h4 style={{paddingBottom:"6px"}}>3. Basic Tasks</h4>
      <ul>
        <li><strong>Design:</strong> Create tables and define relationships between them.</li><br/>
        <li><strong>Query:</strong> Use the query editor to run SQL commands.</li><br/>
        <li><strong>Administration:</strong> Manage users, monitor server performance, and configure settings.</li>
      </ul>

      <br/>

      <h3>Use Cases of MySQL Workbench</h3><br/>
      <h4 style={{paddingBottom:"6px"}}>1. For Developers:</h4>
      <ul>
        <li>Quickly design databases for applications.</li><br/>
        <li>Test and debug SQL queries during development.</li>
      </ul><br/>

      <h4 style={{paddingBottom:"6px"}}>2. For Database Administrators:</h4>
      <ul>
        <li>Manage user permissions.</li><br/>
        <li>Monitor server performance and identify optimization opportunities.</li>
      </ul><br/>

      <h4 style={{paddingBottom:"6px"}}>3. For Students:</h4>
      <ul>
        <li>Learn SQL using an interactive tool.</li><br/>
        <li>Visualize database concepts through diagrams and live queries.</li>
      </ul>

      <br/>

      <h3 style={{paddingBottom:"6px"}}>Tips for Beginners</h3>
      <ul>
        <li>Explore the <strong>Sample Database</strong>: Many installations include a sample database (e.g., Sakila or World) for practice.</li><br/>
        <li>Use <strong>Shortcuts</strong>: Familiarize yourself with keyboard shortcuts to navigate quickly.</li><br/>
        <li>Refer to the <strong>Workbench Manual</strong>: The official documentation offers detailed guidance on all features.</li>
      </ul>

      <br/>

      <h3>Conclusion</h3>
      <p>MySQL Workbench is a feature-rich tool that simplifies database management, design, and administration. Whether you are a beginner learning MySQL or a professional managing enterprise databases, MySQL Workbench provides all the tools needed for efficient database workflows.</p>
    </div>
  )}





{selectedChapter === 'chapter7' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Basic MySQL Commands</h1>

    <p>
      Here is a list of basic MySQL commands that will help you interact with MySQL databases. These commands are useful for performing common database operations like querying, creating, updating, and deleting records.
    </p><br/>

    <h3>1. Connecting to MySQL</h3>
    <pre><code>
      mysql -u username -p
    </code></pre>
    <p>
      - Replace `username` with your MySQL username (e.g., `root`).
      - You will be prompted to enter the password.
    </p><br/>

    <h3>2. Show Databases</h3>
    <pre><code>
      SHOW DATABASES;
    </code></pre><br/>

    <h3>3. Create a Database</h3>
    <pre><code>
      CREATE DATABASE database_name;
    </code></pre>
    <p>Replace `database_name` with the name of the new database you want to create.</p><br/>

    <h3>4. Use a Database</h3>
    <pre><code>
      USE database_name;
    </code></pre>
    <p>Replace `database_name` with the name of the database you want to work with.</p><br/>

    <h3>5. Show Tables</h3>
    <pre><code>
      SHOW TABLES;
    </code></pre><br/>

    <h3>6. Create a Table</h3>
    <pre><code>{`
CREATE TABLE table_name (
column1 datatype,
column2 datatype,...);`}
    </code></pre>
    <p>Define each column with a name and a data type (e.g., `INT`, `VARCHAR(255)`, `DATE`).</p><br/>

    <h3>7. Describe a Table</h3>
    <pre><code>
      DESCRIBE table_name;
    </code></pre><br/>

    <h3>8. Insert Data into a Table</h3>
    <pre><code>{`
INSERT INTO table_name (column1, column2, ...)
VALUES (value1, value2, ...);`}
    </code></pre><br/>

    <h3>9. Select Data from a Table</h3>
    <pre><code>{`
SELECT column1, column2, ...
FROM table_name;`}
    </code></pre>
    <p>Use `*` to select all columns:</p>
    <pre><code>
      SELECT * FROM table_name;
    </code></pre><br/>

    <h3>10. Filter Data (WHERE Clause)</h3>
    <pre><code>{`
SELECT column1, column2, ...
FROM table_name
WHERE condition;`}
    </code></pre>
    <p>Example to filter records where the `age` column is greater than 30:</p>
    <pre><code>{`
SELECT * FROM users
WHERE age > 30;
    `}</code></pre><br/>

    <h3>11. Update Data in a Table</h3>
    <pre><code>{`
UPDATE table_name
SET column1 = value1, column2 = value2, ...
WHERE condition;
    `}</code></pre><br/>

    <h3>12. Delete Data from a Table</h3>
    <pre><code>{`
DELETE FROM table_name
WHERE condition;
    `}</code></pre>
    <p>Be careful, as omitting the `WHERE` clause will delete all rows from the table.</p><br/>

    <h3>13. Drop a Table</h3>
    <pre><code>
      DROP TABLE table_name;
    </code></pre><br/>

    <h3>14. Alter Table (Add a Column)</h3>
    <pre><code>{`
ALTER TABLE table_name
ADD column_name datatype;`}
    </code></pre><br/>

    <h3>15. Drop a Column</h3>
    <pre><code>{`
ALTER TABLE table_name
DROP COLUMN column_name;`}
    </code></pre><br/>

    <h3>16. Join Tables</h3>
    <pre><code>{`
SELECT column1, column2, ...
FROM table1
JOIN table2
ON table1.column_name = table2.column_name;
    `}</code></pre>
    <p>You can also use `LEFT JOIN`, `RIGHT JOIN`, `INNER JOIN`, etc., depending on the type of join you need.</p><br/>

    <h3>17. Grouping Data (GROUP BY)</h3>
    <pre><code>{`
SELECT column, COUNT(*)
FROM table_name
GROUP BY column;`}
    </code></pre><br/>

    <h3>18. Sort Data (ORDER BY)</h3>
    <pre><code>{`
SELECT column1, column2, ...
FROM table_name
ORDER BY column1 [ASC | DESC];`}
    </code></pre>
    <p>`ASC` sorts in ascending order (default), and `DESC` sorts in descending order.</p><br/>

    <h3>19. Limit the Number of Results</h3>
    <pre><code>{`
SELECT column1, column2, ...
FROM table_name
LIMIT 10;`}
    </code></pre>
    <p>This will return only the first 10 rows.</p><br/>

    <h3>20. Drop a Database</h3>
    <pre><code>
      DROP DATABASE database_name;
    </code></pre><br/>

    <p>These are some of the most basic and commonly used MySQL commands. They will help you perform the essential tasks of creating, querying, and managing databases and tables in MySQL.</p>
  </div>
)}



{selectedChapter === 'chapter8' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Understanding Databases</h1>

    <p>A <strong>database</strong> in MySQL is a structured collection of data that is organized in a way that makes it easy to access, manage, and update. MySQL is a <strong>Relational Database Management System (RDBMS)</strong>, meaning it uses structured query language (SQL) to manage data stored in tables with relationships between them.</p>

    <p>Here’s an overview of how databases work in MySQL and how they can be managed:</p>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>1. What is a Database?</h2>
    <ul>
      <li>A database is a container that stores data in an organized way, usually in the form of <strong>tables</strong>.</li><br/>
      <li>A <strong>table</strong> is made up of rows (records) and columns (fields). Each row contains a record, and each column represents a field of data, such as `name`, `age`, `address`, etc.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>2. Database Structure</h2>
    <p>In MySQL, a database contains the following key components:</p>

    <ul>
      <li><strong>Tables</strong>: Data is stored in tables. Each table consists of columns and rows. Columns are the fields (attributes), and rows are the records (entries). Example: A `students` table might have columns like `student_id`, `first_name`, `last_name`, and `age`.</li><br/>
      <li><strong>Columns</strong>: Columns define the types of data you can store in a table. Each column has a name and a data type (e.g., `VARCHAR`, `INT`, `DATE`). Example: In the `students` table, `student_id` could be an integer, `first_name` a string (`VARCHAR`), and `age` an integer.</li><br/>
      <li><strong>Rows</strong>: A row in a table is a single record. For example, a single student's information in the `students` table. Example: A row in the `students` table could look like: <code>1, 'John', 'Doe', 22</code>.</li><br/>
      <li><strong>Primary Key</strong>: A primary key is a unique identifier for a row. No two rows in a table can have the same primary key. It ensures data integrity. Example: `student_id` might be the primary key in the `students` table.</li><br/>
      <li><strong>Foreign Key</strong>: A foreign key is a field in one table that links to the primary key in another table. This establishes relationships between tables. Example: In an `orders` table, `student_id` could be a foreign key that links to the `student_id` in the `students` table.</li>
    </ul>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>3. Types of Databases</h2>
    <p>MySQL allows the creation of different databases for storing different sets of data. For example:</p>
    <ul>
      <li><strong>Transactional Databases</strong>: These are used to store data for applications where consistency and reliability are critical, such as financial applications.</li><br/>
      <li><strong>Data Warehouses</strong>: These databases are optimized for read-heavy operations and are often used for analytical purposes.</li>
    </ul>
    <p>You can manage multiple databases in MySQL on the same server, making it easy to separate concerns (e.g., one database for users and one for orders).</p>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>4. Common MySQL Database Operations</h2>
    <p>Here are some basic operations you can perform on databases in MySQL:</p>

    <ul>
      <li><strong>Creating a Database</strong>: To create a new database, use the <code>CREATE DATABASE</code> command:
        <pre><code>CREATE DATABASE my_database;</code></pre>
      </li><br/>

      <li><strong>Selecting a Database</strong>: Before you can perform operations on a database, you need to select it:
        <pre><code>USE my_database;</code></pre>
      </li><br/>

      <li><strong>Listing Databases</strong>: To view all available databases, use the <code>SHOW DATABASES</code> command:
        <pre><code>SHOW DATABASES;</code></pre>
      </li><br/>

      <li><strong>Deleting a Database</strong>: To delete a database and all its data:
        <pre><code>DROP DATABASE my_database;</code></pre>
      </li><br/>

      <li><strong>Renaming a Database</strong>: MySQL does not directly support renaming a database, but you can do this by creating a new database and copying all data from the old one:
        <pre><code>{`CREATE DATABASE new_database;
USE old_database;
SHOW TABLES;`}</code></pre>
      </li>
    </ul>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>5. Working with Tables in a Database</h2>
    <p>Once you’ve selected a database, you can create and manage tables within it. For example:</p>

    <ul>
      <li><strong>Creating a Table</strong>:
        <pre><code>{`CREATE TABLE students (
  student_id INT PRIMARY KEY,
  first_name VARCHAR(50),
  last_name VARCHAR(50),
  age INT
);`}</code></pre>
      </li><br/>

      <li><strong>Listing Tables</strong>: To view all tables in a selected database:
        <pre><code>SHOW TABLES;</code></pre>
      </li><br/>

      <li><strong>Dropping a Table</strong>: To remove a table from the database:
        <pre><code>DROP TABLE students;</code></pre>
      </li>
    </ul>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>6. Types of Database Relationships</h2>
    <p>In relational databases like MySQL, tables can be related to one another using <strong>keys</strong>:</p>
    <ul>
      <li><strong>One-to-One Relationship</strong>: Each row in one table is related to exactly one row in another table. Example: A `students` table and a `student_details` table, where each student has one set of detailed information.</li><br/>
      <li><strong>One-to-Many Relationship</strong>: One row in a table is related to many rows in another table. Example: A `students` table and an `orders` table, where each student can place many orders, but each order is associated with only one student.</li><br/>
      <li><strong>Many-to-Many Relationship</strong>: Rows in one table are related to many rows in another table, and vice versa. Example: A `students` table and a `courses` table, where students can enroll in many courses, and each course can have many students. This would require an <strong>intermediate table</strong> (e.g., `student_courses`).</li>
    </ul>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>7. Normalization</h2>
    <p>Normalization is the process of organizing data in a database to reduce redundancy and improve data integrity. There are several normal forms (1NF, 2NF, 3NF) that dictate how to organize the tables:</p>

    <ul>
      <li><strong>First Normal Form (1NF)</strong>: Ensures that each column contains atomic values, meaning no multiple values in a single column.</li><br/>
      <li><strong>Second Normal Form (2NF)</strong>: Removes partial dependencies, i.e., attributes that depend only on part of the primary key.</li><br/>
      <li><strong>Third Normal Form (3NF)</strong>: Ensures that no transitive dependencies exist, i.e., non-key attributes do not depend on other non-key attributes.</li>
    </ul>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>8. Advantages of Using MySQL Databases</h2>
    <ul>
      <li><strong>Data Integrity</strong>: MySQL enforces rules like primary keys and foreign keys, ensuring data consistency and accuracy.</li><br/>
      <li><strong>Scalability</strong>: MySQL databases can handle large amounts of data and can be scaled horizontally or vertically.</li><br/>
      <li><strong>Security</strong>: MySQL offers user authentication and role-based access controls, ensuring that only authorized users can access sensitive data.</li>
    </ul>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>Conclusion</h2>
    <p>Understanding databases in MySQL is essential for efficiently managing, querying, and ensuring the integrity of your data. By leveraging MySQL’s relational database features, you can build robust applications that store and retrieve data efficiently. Whether you're designing a small application or managing enterprise-level data, mastering MySQL database operations will greatly improve your ability to work with databases.</p>
  </div>
)}



{selectedChapter === 'chapter9' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Tables and Relationships   </h1>
      
      <section>
        <h2 style={{paddingBottom:"6px"}}>1. Tables in MySQL</h2>
        <p>
          A <strong>table</strong> is the basic structure for storing data in MySQL. It consists of columns (fields) and rows (records),
          where each row represents an entry, and each column represents an attribute of that entry.
        </p><br/>
        
        <ul>
          <li>
            <strong>Columns</strong>: Each column defines the type of data that can be stored (e.g., integer, string, date). Each column has a data type (e.g., <code>VARCHAR</code>, <code>INT</code>, <code>DATE</code>), and the values stored in that column must be consistent with the data type.
          </li><br/>
          <li>
            <strong>Rows</strong>: A row represents a single record or entry. For example, in the <code>students</code> table, each row might represent a different student, with specific values for each column.
            <pre>
              {`1, 'John', 'Doe', 22
2, 'Jane', 'Smith', 19`}
            </pre>
          </li><br/>
          <li>
            <strong>Primary Key</strong>: A primary key uniquely identifies each row in a table. It is a column (or set of columns) that must contain unique values and cannot be <code>NULL</code>. It ensures that each record can be accessed unambiguously.
          </li><br/>
          <li>
            <strong>Foreign Key</strong>: A foreign key is a field in one table that links to the primary key of another table. It creates a relationship between the two tables and ensures referential integrity. The foreign key ensures that records in one table match those in another table, maintaining the integrity of data across tables.
          </li>
        </ul>
      </section><br/>
      
      <section>
        <h2 style={{paddingBottom:"6px"}}>2. Types of Relationships Between Tables in MySQL</h2>
        
        <h3 style={{paddingBottom:"6px"}}>a. One-to-One Relationship</h3>
        <p>
          In a one-to-one relationship, each row in the first table is linked to exactly one row in the second table, and vice versa. 
          This type of relationship is rare and is typically used when there is a need to separate data into two tables for security or organizational reasons.
        </p>
        <pre>
          {`CREATE TABLE students (
  student_id INT PRIMARY KEY,
  first_name VARCHAR(50),
  last_name VARCHAR(50)
);

CREATE TABLE student_details (
  student_id INT PRIMARY KEY,
  address VARCHAR(100),
  phone_number VARCHAR(15),
  FOREIGN KEY (student_id) REFERENCES students(student_id)
);`}
        </pre><br/>
        
        <h3 style={{paddingBottom:"6px"}}>b. One-to-Many Relationship</h3>
        <p>
          In a one-to-many relationship, one row in the first table is related to many rows in the second table. This is the most common type of relationship. 
          It is used when one entity can have multiple related entries in another table.
        </p>
        <pre>
          {`CREATE TABLE students (
  student_id INT PRIMARY KEY,
  first_name VARCHAR(50),
  last_name VARCHAR(50)
);

CREATE TABLE orders (
  order_id INT PRIMARY KEY,
  student_id INT,
  order_date DATE,
  FOREIGN KEY (student_id) REFERENCES students(student_id)
);`}
        </pre><br/>

        <h3 style={{paddingBottom:"6px"}}>c. Many-to-Many Relationship</h3>
        <p>
          In a many-to-many relationship, many rows in the first table are related to many rows in the second table. 
          This type of relationship requires the use of an intermediate (or junction) table to link the two tables.
        </p>
        <pre>
          {`CREATE TABLE students (
  student_id INT PRIMARY KEY,
  first_name VARCHAR(50),
  last_name VARCHAR(50)
);

CREATE TABLE courses (
  course_id INT PRIMARY KEY,
  course_name VARCHAR(100)
);

CREATE TABLE student_courses (
  student_id INT,
  course_id INT,
  PRIMARY KEY (student_id, course_id),
  FOREIGN KEY (student_id) REFERENCES students(student_id),
  FOREIGN KEY (course_id) REFERENCES courses(course_id)
);`}
        </pre>
      </section><br/>
      
      <section>
        <h2 style={{paddingBottom:"6px"}}>3. Referential Integrity and Constraints</h2>
        <p>
          When establishing relationships between tables, <strong>referential integrity</strong> must be maintained. This ensures that relationships between tables are consistent.
          MySQL enforces referential integrity through <strong>foreign key constraints</strong>.
        </p><br/>

        <ul>
          <li>
            <strong>ON DELETE CASCADE</strong>: If a row in the parent table (e.g., <code>students</code>) is deleted, all related rows in the child table (e.g., <code>orders</code> or <code>student_courses</code>) are also deleted automatically.
            <pre>
              {`FOREIGN KEY (student_id) REFERENCES students(student_id) ON DELETE CASCADE`}
            </pre>
          </li><br/>
          <li>
            <strong>ON UPDATE CASCADE</strong>: If a primary key value in the parent table is updated, the foreign key values in the child table are updated automatically.
            <pre>
              {`FOREIGN KEY (student_id) REFERENCES students(student_id) ON UPDATE CASCADE`}
            </pre>
          </li><br/>
          <li>
            <strong>ON DELETE SET NULL</strong>: If a row in the parent table is deleted, the foreign key in the child table is set to <code>NULL</code>.
            <pre>
              {`FOREIGN KEY (student_id) REFERENCES students(student_id) ON DELETE SET NULL`}
            </pre>
          </li>
        </ul>
      </section><br/>

      <section>
        <h2 style={{paddingBottom:"6px"}}>4. Conclusion</h2>
        <p>
          <strong>Tables</strong> in MySQL store data in rows and columns and can be related to each other using <strong>primary keys</strong> and <strong>foreign keys</strong>.
        </p>
        <p>
          <strong>Relationships</strong> between tables are crucial for organizing and retrieving data efficiently:
        </p>
        <ul>
          <li><strong>One-to-One Relationship</strong>: Each row in one table corresponds to exactly one row in another.</li><br/>
          <li><strong>One-to-Many Relationship</strong>: One row in a table relates to many rows in another.</li><br/>
          <li><strong>Many-to-Many Relationship</strong>: Many rows in one table relate to many rows in another through an intermediate table.</li>
        </ul><br/>
        <p>
          MySQL enforces referential integrity through foreign key constraints, ensuring that relationships between tables remain consistent.
        </p>
      </section>
    </div>
  )}




{selectedChapter === 'chapter10' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Numeric Data Types </h1>


      <p>
        In MySQL, <strong>numeric data types</strong> are used to store numerical values, such as integers, floating-point numbers, and fixed-point numbers. These data types are essential for performing calculations, storing quantities, and representing numerical data in a database. Let’s break down the main numeric data types available in MySQL:
      </p><br/>

      <h3>1. Integer Types</h3>
      <p>Integer types are used to store whole numbers without decimal points. MySQL provides several variations depending on the size and range of the integer values.</p>
      <br/>
      <ul>
        <li>
          <strong>TINYINT</strong>
          <br />
          Range: `-128 to 127` (signed) or `0 to 255` (unsigned)
          <br />
          Storage: 1 byte
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
age TINYINT
);
            `}
          </pre>
        </li><br/>

        <li>
          <strong>SMALLINT</strong>
          <br />
          Range: `-32,768 to 32,767` (signed) or `0 to 65,535` (unsigned)
          <br />
          Storage: 2 bytes
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
population SMALLINT
);
            `}
          </pre>
        </li><br/>

        <li>
          <strong>MEDIUMINT</strong>
          <br />
          Range: `-8,388,608 to 8,388,607` (signed) or `0 to 16,777,215` (unsigned)
          <br />
          Storage: 3 bytes
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
code MEDIUMINT
);
            `}
          </pre>
        </li><br/>

        <li>
          <strong>INT (or INTEGER)</strong>
          <br />
          Range: `-2,147,483,648 to 2,147,483,647` (signed) or `0 to 4,294,967,295` (unsigned)
          <br />
          Storage: 4 bytes
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
population INT
);
            `}
          </pre>
        </li><br/>

        <li>
          <strong>BIGINT</strong>
          <br />
          Range: `-9,223,372,036,854,775,808 to 9,223,372,036,854,775,807` (signed) or `0 to 18,446,744,073,709,551,615` (unsigned)
          <br />
          Storage: 8 bytes
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
world_population BIGINT
);
            `}
          </pre>
        </li>
      </ul><br/>

      <h3>2. Fixed-Point Types</h3>
      <p>Fixed-point types are used to store numbers with a specific number of decimal places, often used for values like monetary amounts or precise measurements.</p>

      <ul>
        <li>
          <strong>DECIMAL (NUMERIC)</strong>
          <br />
          Syntax: `DECIMAL(M, D)` or `NUMERIC(M, D)`
          <br />
          `M` = Total number of digits (precision)
          <br />
          `D` = Number of digits after the decimal point (scale)
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
salary DECIMAL(10, 2)  -- up to 8 digits before decimal and 2 digits after
);
            `}
          </pre>
        </li>
      </ul><br/>

      <h3 style={{paddingBottom:"6px"}}>3. Floating-Point Types</h3>
      <p>Floating-point types are used to store approximate numbers, useful when precision is not the most critical factor but performance is important.</p>

      <ul>
        <li>
          <strong>FLOAT</strong>
          <br />
          Syntax: `FLOAT(M, D)`
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
temperature FLOAT(5, 2)  -- up to 3 digits before decimal and 2 digits after
);
            `}
          </pre>
        </li><br/>

        <li>
          <strong>DOUBLE (DOUBLE PRECISION)</strong>
          <br />
          Syntax: `DOUBLE(M, D)`
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
weight DOUBLE(10, 3)  -- up to 7 digits before decimal and 3 digits after
);
            `}
          </pre>
        </li>
      </ul><br/>

      <h3 style={{paddingBottom:"6px"}}>4. BIT Type</h3>
      <p>The <strong>BIT</strong> type is used to store binary values (0 or 1). It is commonly used to store boolean flags or small binary data.</p>
      <ul>
        <li>
          Syntax: `BIT(M)` where `M` specifies the number of bits.
          <br />
          Example:
          <pre>
            {`
CREATE TABLE example (
is_active BIT(1)  -- 1 bit to store boolean value (0 or 1)
);
            `}
          </pre>
        </li>
      </ul><br/>

      <h3 style={{paddingBottom:"6px"}}>Comparison of Numeric Data Types</h3>
      <table>
        <thead>
          <tr>
            <th>Data Type</th>
            <th>Storage Size</th>
            <th>Range</th>
            <th>Use Case</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TINYINT</td>
            <td>1 byte</td>
            <td>-128 to 127 (signed), 0 to 255 (unsigned)</td>
            <td>Small integer values</td>
          </tr>
          <tr>
            <td>SMALLINT</td>
            <td>2 bytes</td>
            <td>-32,768 to 32,767 (signed), 0 to 65,535 (unsigned)</td>
            <td>Small integer values</td>
          </tr>
          <tr>
            <td>MEDIUMINT</td>
            <td>3 bytes</td>
            <td>-8,388,608 to 8,388,607 (signed), 0 to 16,777,215 (unsigned)</td>
            <td>Larger integer values</td>
          </tr>
          <tr>
            <td>INT</td>
            <td>4 bytes</td>
            <td>-2,147,483,648 to 2,147,483,647 (signed), 0 to 4,294,967,295 (unsigned)</td>
            <td>Common integer values</td>
          </tr>
          <tr>
            <td>BIGINT</td>
            <td>8 bytes</td>
            <td>-9,223,372,036,854,775,808 to 9,223,372,036,854,775,807 (signed), 0 to 18,446,744,073,709,551,615 (unsigned)</td>
            <td>Large integer values</td>
          </tr>
          <tr>
            <td>DECIMAL/NUMERIC</td>
            <td>Variable</td>
            <td>Customizable range based on precision (M) and scale (D)</td>
            <td>Exact numeric values like currency</td>
          </tr>
          <tr>
            <td>FLOAT</td>
            <td>4 bytes</td>
            <td>Approximate range, less precision than DOUBLE</td>
            <td>Floating-point numbers with less precision</td>
          </tr>
          <tr>
            <td>DOUBLE</td>
            <td>8 bytes</td>
            <td>Approximate range, higher precision than FLOAT</td>
            <td>Floating-point numbers with higher precision</td>
          </tr>
          <tr>
            <td>BIT</td>
            <td>Variable (based on size)</td>
            <td>0 or 1</td>
            <td>Store boolean or binary data</td>
          </tr>
        </tbody>
      </table><br/><br/>

      <h3 style={{paddingBottom:"6px"}}>Choosing the Right Numeric Data Type</h3>
      <ul>
        <li>For exact numeric values, such as monetary amounts or precise measurements, use <strong>DECIMAL</strong> or <strong>NUMERIC</strong> to avoid precision loss.</li><br/>
        <li>For integer values, select the appropriate type based on the range of numbers you need to store (e.g., <strong>TINYINT</strong>, <strong>SMALLINT</strong>, <strong>INT</strong>, <strong>BIGINT</strong>).</li><br/>
        <li>For approximate numeric values, use <strong>FLOAT</strong> or <strong>DOUBLE</strong>, depending on the required precision.</li><br/>
        <li>For binary or boolean data, use <strong>BIT</strong> to store binary values (0 or 1).</li>
      </ul><br/>

      <p>By selecting the correct numeric data type, you can optimize the storage space, improve performance, and ensure accuracy in your MySQL database.</p>
    </div>
  )}



{selectedChapter === 'chapter11' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String Data Types </h1>
    
      <p>
      MySQL provides a variety of <strong>string data types</strong> to store text-based data. These types differ in terms of storage size, character limit, and intended use cases.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Types of String Data Types</h2>

    <h3>1. CHAR</h3>
    <ul>
      <li>
        <strong>Fixed-length</strong> string storage.
      </li><br/>
      <li>
        Ideal for storing data with consistent length, such as codes or abbreviations.
      </li><br/>
      <li>
        Automatically pads values with spaces to match the defined length.
      </li><br/>
      <li>
        <strong>Syntax:</strong>
        <pre>
          <code>{`CHAR(length)`}</code>
        </pre>
      </li>
    </ul><br/>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`CREATE TABLE codes (
code CHAR(5)
);`}
      </code>
    </pre>
    <p>
      In this example, values like `'ABC'` will be stored as `'ABC  '` (padded to 5 characters).
    </p><br/>

    <h3 style={{paddingBottom:"6px"}}>2. VARCHAR</h3>
    <ul>
      <li>
        <strong>Variable-length</strong> string storage.
      </li><br/>
      <li>
        Ideal for storing text with varying lengths.
      </li><br/>
      <li>
        Requires an explicit length value.
      </li><br/>
      <li>
        <strong>Syntax:</strong>
        <pre>
          <code>{`VARCHAR(length)`}</code>
        </pre>
      </li>
    </ul><br/>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`CREATE TABLE users (
username VARCHAR(50)
);`}
      </code>
    </pre>
    <p>
      In this example, usernames up to 50 characters can be stored efficiently.
    </p><br/>

    <h3 style={{paddingBottom:"6px"}}>3. TEXT</h3>
    <ul>
      <li>
        Stores large text data, such as comments or descriptions.
      </li><br/>
      <li>
        Cannot have a default value.
      </li><br/>
      <li>
        Comes in four types based on size:
        <ul>
          <li>
            <strong>TINYTEXT:</strong> Up to 255 characters.
          </li><br/>
          <li>
            <strong>TEXT:</strong> Up to 65,535 characters.
          </li><br/>
          <li>
            <strong>MEDIUMTEXT:</strong> Up to 16,777,215 characters.
          </li><br/>
          <li>
            <strong>LONGTEXT:</strong> Up to 4,294,967,295 characters.
          </li>
        </ul>
      </li>
    </ul><br/>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`CREATE TABLE articles (
content TEXT
);`}
      </code>
    </pre>
    <p>
      Use the appropriate type of <code>TEXT</code> depending on the expected size of the data.
    </p><br/>

    <h3 style={{paddingBottom:"6px"}}>4. BLOB</h3>
    <ul>
      <li>
        Similar to <code>TEXT</code> but designed for binary data like images or files.
      </li><br/>
      <li>
        Comes in four types based on size:
        <ul>
          <li>
            <strong>TINYBLOB:</strong> Up to 255 bytes.
          </li><br/>
          <li>
            <strong>BLOB:</strong> Up to 65,535 bytes.
          </li><br/>
          <li>
            <strong>MEDIUMBLOB:</strong> Up to 16,777,215 bytes.
          </li><br/>
          <li>
            <strong>LONGBLOB:</strong> Up to 4,294,967,295 bytes.
          </li>
        </ul>
      </li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>5. ENUM</h3>
    <ul>
      <li>
        Allows storing predefined values as a list.
      </li><br/>
      <li>
        Stores one value from the list, making it useful for categorical data like status or roles.
      </li><br/>
      <li>
        <strong>Syntax:</strong>
        <pre>
          <code>{`ENUM(value1, value2, ...)`}</code>
        </pre>
      </li>
    </ul><br/>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`CREATE TABLE users (
role ENUM('admin', 'editor', 'viewer')
);`}
      </code>
    </pre>
    <p>
      Values for the <code>role</code> column must be one of <code>'admin'</code>, <code>'editor'</code>, or <code>'viewer'</code>.
    </p><br/>

    <h3 style={{paddingBottom:"6px"}}>6. SET</h3>
    <ul>
      <li>
        Stores multiple values from a predefined list.
      </li><br/>
      <li>
        Ideal for tags or attributes that allow multiple selections.
      </li><br/>
      <li>
        <strong>Syntax:</strong>
        <pre>
          <code>{`SET(value1, value2, ...)`}</code>
        </pre>
      </li>
    </ul><br/>
    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`CREATE TABLE products (
tags SET('electronics', 'furniture', 'clothing')
);`}
      </code>
    </pre>
    <p>
      A product can have tags like <code>'electronics'</code> and <code>'clothing'</code>.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Comparison of String Data Types</h2>
    <table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Max Length</th>
          <th>Key Features</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>CHAR</td>
          <td>255</td>
          <td>Fixed-length, space-padded</td>
        </tr>
        <tr>
          <td>VARCHAR</td>
          <td>65,535</td>
          <td>Variable-length, efficient for text</td>
        </tr>
        <tr>
          <td>TEXT</td>
          <td>Various</td>
          <td>Large text storage</td>
        </tr>
        <tr>
          <td>ENUM</td>
          <td>255</td>
          <td>Single value from predefined list</td>
        </tr>
        <tr>
          <td>SET</td>
          <td>64</td>
          <td>Multiple values from predefined list</td>
        </tr>
      </tbody>
    </table><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>Choose the appropriate type based on data size and use case.</li><br/>
      <li>Use <code>CHAR</code> for fixed-length data and <code>VARCHAR</code> for variable-length data.</li><br/>
      <li>Limit the use of <code>TEXT</code> to cases requiring large text storage.</li>
    </ul>
  </div>
)}

 


{selectedChapter === 'chapter12' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Date and Time Types </h1>

      <p>
        In MySQL, <strong>Date and Time types</strong> are used to store temporal values, such as dates, times, or both. These data types are crucial for handling timestamps, durations, and scheduling information in a database.
      </p><br/>

      <h2>1. DATE</h2>
      <ul>
        <li><strong>Description:</strong> Stores date values (year, month, and day).</li><br/>
        <li><strong>Format:</strong> <code>YYYY-MM-DD</code></li><br/>
        <li><strong>Storage:</strong> 3 bytes</li><br/>
        <li><strong>Range:</strong> 1000-01-01 to 9999-12-31</li><br/>
        <li><strong>Example:</strong>
          <pre><code>
            CREATE TABLE events (
              event_date DATE
            );
          </code></pre>
        </li>
      </ul><br/>

      <h2>2. DATETIME</h2>
      <ul>
        <li><strong>Description:</strong> Stores date and time values (year, month, day, hour, minute, second).</li><br/>
        <li><strong>Format:</strong> <code>YYYY-MM-DD HH:MM:SS</code></li><br/>
        <li><strong>Storage:</strong> 8 bytes</li><br/>
        <li><strong>Range:</strong> 1000-01-01 00:00:00 to 9999-12-31 23:59:59</li><br/>
        <li><strong>Example:</strong>
          <pre><code>{`
CREATE TABLE appointments (
appointment_time DATETIME
);
         `} </code></pre>
        </li>
      </ul><br/>

      <h2>3. TIMESTAMP</h2>
      <ul>
        <li><strong>Description:</strong> Stores date and time values, typically used for recording when a row was created or last updated. It is similar to <code>DATETIME</code> but is stored as the number of seconds since the Unix epoch (1970-01-01 00:00:00 UTC).</li><br/>
        <li><strong>Format:</strong> <code>YYYY-MM-DD HH:MM:SS</code></li><br/>
        <li><strong>Storage:</strong> 4 bytes</li><br/>
        <li><strong>Range:</strong> 1970-01-01 00:00:01 UTC to 2038-01-19 03:14:07 UTC</li><br/>
        <li><strong>Note:</strong> The <code>TIMESTAMP</code> value is automatically adjusted according to the server's time zone.</li><br/>
        <li><strong>Example:</strong>
          <pre><code>{`
CREATE TABLE users (
created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
);
         `} </code></pre>
        </li>
      </ul><br/>

      <h2>4. TIME</h2>
      <ul>
        <li><strong>Description:</strong> Stores time values (hour, minute, second). It is typically used for durations or times of day without a date.</li><br/>
        <li><strong>Format:</strong> <code>HH:MM:SS</code></li><br/>
        <li><strong>Storage:</strong> 3 bytes</li><br/>
        <li><strong>Range:</strong> '-838:59:59' to '838:59:59'</li><br/>
        <li><strong>Example:</strong>
          <pre><code>{`
CREATE TABLE events (
duration TIME
);
        `}  </code></pre>
        </li>
      </ul><br/>

      <h2>5. YEAR</h2>
      <ul>
        <li><strong>Description:</strong> Stores a year value. It is a 4-digit year, but MySQL allows it to be stored as a 2-digit or 4-digit value.</li><br/>
        <li><strong>Format:</strong> <code>YYYY</code> or <code>YY</code></li><br/>
        <li><strong>Storage:</strong> 1 byte</li><br/>
        <li><strong>Range:</strong> 1901 to 2155</li><br/>
        <li><strong>Example:</strong>
          <pre><code>{`
CREATE TABLE projects (
project_year YEAR
);`}  </code></pre>
        </li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Comparison of Date and Time Types</h2>
      <table>
        <thead>
          <tr>
            <th>Data Type</th>
            <th>Storage Size</th>
            <th>Format</th>
            <th>Range</th>
            <th>Use Case</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>DATE</td>
            <td>3 bytes</td>
            <td>YYYY-MM-DD</td>
            <td>1000-01-01 to 9999-12-31</td>
            <td>To store date values without time</td>
          </tr>
          <tr>
            <td>DATETIME</td>
            <td>8 bytes</td>
            <td>YYYY-MM-DD HH:MM:SS</td>
            <td>1000-01-01 00:00:00 to 9999-12-31 23:59:59</td>
            <td>To store both date and time values</td>
          </tr>
          <tr>
            <td>TIMESTAMP</td>
            <td>4 bytes</td>
            <td>YYYY-MM-DD HH:MM:SS</td>
            <td>1970-01-01 00:00:01 UTC to 2038-01-19 03:14:07 UTC</td>
            <td>To store date and time with automatic time zone adjustment</td>
          </tr>
          <tr>
            <td>TIME</td>
            <td>3 bytes</td>
            <td>HH:MM:SS</td>
            <td>'-838:59:59' to '838:59:59'</td>
            <td>To store time values or durations without date</td>
          </tr>
          <tr>
            <td>YEAR</td>
            <td>1 byte</td>
            <td>YYYY or YY</td>
            <td>1901 to 2155</td>
            <td>To store year values (e.g., for birth year, year of event)</td>
          </tr>
        </tbody>
      </table><br/>

      <h3>Key Points to Remember:</h3>
      <ul>
        <li><strong>DATE</strong> is used when you only need the date (no time).</li><br/>
        <li><strong>DATETIME</strong> stores both date and time, useful for tracking events or timestamps.</li><br/>
        <li><strong>TIMESTAMP</strong> is similar to <code>DATETIME</code>, but it is stored as a Unix timestamp (seconds since 1970-01-01) and is often used for automatic timestamping with UTC time zone.</li><br/>
        <li><strong>TIME</strong> is useful for durations or times without the date component.</li><br/>
        <li><strong>YEAR</strong> is used specifically for storing year values, and it can be used in two formats: 4-digit or 2-digit year.</li>
      </ul><br/>

      <h3>Time Zone Considerations:</h3>
      <ul>
        <li><strong>TIMESTAMP</strong> values are stored in UTC, but MySQL automatically converts them to the server's time zone when retrieved, which is helpful for applications that deal with users across multiple time zones.</li><br/>
        <li><strong>DATETIME</strong> does not convert to the server’s time zone and stores the exact value as provided.</li>
      </ul><br/>

      <h3>Example Use Case:</h3>
      <pre><code>{`
CREATE TABLE events (
event_id INT AUTO_INCREMENT PRIMARY KEY,
event_name VARCHAR(100),
start_time DATETIME,
end_time DATETIME,
duration TIME);`}
      </code></pre>
      <p>
        This structure would allow you to store both the date and time of the event, along with its duration.
      </p>
      
      <p>If you have any specific questions or need further clarification on how to use these types in your projects, feel free to ask!</p>
    </div>
  )}




{selectedChapter === 'chapter13' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Binary Large Object (BLOB) Data Types </h1>
    
      
      <p>
        In MySQL, <strong>Binary Large Object (BLOB)</strong> data types are used to store large binary data, such as images, audio files, or other types of non-textual data. BLOB types are designed to handle data that is typically too large to store in regular <code>VARCHAR</code> or <code>TEXT</code> fields.
      </p><br/>
      
      <h2 style={{paddingBottom:"6px"}}>Types of BLOB Data Types in MySQL</h2>

      <h3 style={{paddingBottom:"6px"}}>1. TINYBLOB</h3>
      <ul>
        <li><strong>Description:</strong> A very small binary object that can store up to 255 bytes of data.</li><br/>
        <li><strong>Storage Size:</strong> 1 byte for storage of length (size) + up to 255 bytes of data.</li><br/>
        <li><strong>Use Case:</strong> Suitable for very small binary data such as small image thumbnails or small media files.</li><br/>
        <li><strong>Example:</strong>
          <pre>
            {`
CREATE TABLE images (
image_id INT PRIMARY KEY,
image_data TINYBLOB );
            `}
          </pre>
        </li>
      </ul><br/>

      <h3>2. BLOB</h3>
      <ul>
        <li><strong>Description:</strong> A general-purpose binary large object that can store up to 65,535 bytes (64 KB) of data.</li><br/>
        <li><strong>Storage Size:</strong> 2 bytes for length (size) + up to 65,535 bytes of data.</li><br/>
        <li><strong>Use Case:</strong> Used for moderate-sized binary files, such as a medium-resolution image or a short video clip.</li><br/>
        <li><strong>Example:</strong>
          <pre>
            {`
CREATE TABLE files (
file_id INT PRIMARY KEY,
file_data BLOB );
            `}
          </pre>
        </li>
      </ul><br/>

      <h3>3. MEDIUMBLOB</h3>
      <ul>
        <li><strong>Description:</strong> A medium-sized binary object that can store up to 16,777,215 bytes (16 MB) of data.</li><br/>
        <li><strong>Storage Size:</strong> 3 bytes for length (size) + up to 16 MB of data.</li><br/>
        <li><strong>Use Case:</strong> Suitable for larger files, such as high-resolution images, audio files, or small video clips.</li><br/>
        <li><strong>Example:</strong>
          <pre>
            {`
CREATE TABLE videos (
video_id INT PRIMARY KEY,
video_data MEDIUMBLOB);
            `}
          </pre>
        </li>
      </ul><br/>

      <h3>4. LONGBLOB</h3>
      <ul>
        <li><strong>Description:</strong> A very large binary object that can store up to 4,294,967,295 bytes (4 GB) of data.</li><br/>
        <li><strong>Storage Size:</strong> 4 bytes for length (size) + up to 4 GB of data.</li><br/>
        <li><strong>Use Case:</strong> Used for very large files, such as full-length videos, large datasets, or large audio files.</li><br/>
        <li><strong>Example:</strong>
          <pre>
            {`
CREATE TABLE large_files (
file_id INT PRIMARY KEY,
file_data LONGBLOB);
            `}
          </pre>
        </li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Comparison of BLOB Types</h2>
      <table>
        <thead>
          <tr>
            <th>Data Type</th>
            <th>Storage Size</th>
            <th>Max Length</th>
            <th>Use Case</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>TINYBLOB</strong></td>
            <td>1 byte + 255 bytes</td>
            <td>255 bytes</td>
            <td>Small binary data, e.g., image thumbnails</td>
          </tr>
          <tr>
            <td><strong>BLOB</strong></td>
            <td>2 bytes + 65,535 bytes</td>
            <td>64 KB</td>
            <td>General-purpose binary data, e.g., small images or videos</td>
          </tr>
          <tr>
            <td><strong>MEDIUMBLOB</strong></td>
            <td>3 bytes + 16,777,215 bytes</td>
            <td>16 MB</td>
            <td>Medium-sized files, e.g., larger images or audio files</td>
          </tr>
          <tr>
            <td><strong>LONGBLOB</strong></td>
            <td>4 bytes + 4,294,967,295 bytes</td>
            <td>4 GB</td>
            <td>Very large files, e.g., full-length videos or datasets</td>
          </tr>
        </tbody>
      </table><br/>

      <h2>Key Points to Remember:</h2>
      <ul>
        <li><strong>BLOB types</strong> are for binary data. If you need to store images, audio files, or large files, you would use one of the BLOB data types.</li><br/>
        <li><strong>The size of the BLOB types</strong> depends on how large the data is that you intend to store. Use <code>TINYBLOB</code> for small files, <code>BLOB</code> for medium-sized files, <code>MEDIUMBLOB</code> for large files, and <code>LONGBLOB</code> for very large files.</li><br/>
        <li><strong>Storage Efficiency:</strong> The BLOB types are stored in binary format, and their storage is efficient for large data.</li><br/>
        <li><strong>Binary Data:</strong> Unlike <code>TEXT</code> types, which store string data, BLOBs store raw binary data, meaning that MySQL does not interpret the content as characters or text.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Example of Storing and Retrieving a BLOB:</h2>
      <p>To store binary data (like an image or a file) into a BLOB field, you'd typically do it using a prepared statement or programmatically in your application.</p>

      <h3>SQL Example:</h3>
      <pre>
        {`
-- Insert binary data into a BLOB field
INSERT INTO images (image_id, image_data)
VALUES (1, LOAD_FILE('/path/to/your/image.jpg'));
`}
      </pre>
      <p>To retrieve the data:</p>
      <pre>
        {`
-- Retrieve binary data from a BLOB field
SELECT image_data FROM images WHERE image_id = 1;
`}
      </pre><br/>

      <h3>How to Use BLOBs in Programming (PHP Example):</h3>
      <pre>
        {`
// Insert image into BLOB field
$image = file_get_contents('image.jpg');
$stmt = $pdo->prepare("INSERT INTO images (image_data) VALUES (:image_data)");
$stmt->bindParam(':image_data', $image, PDO::PARAM_LOB);
$stmt->execute();
  
// Retrieve image from BLOB field
$stmt = $pdo->prepare("SELECT image_data FROM images WHERE image_id = ?");
$stmt->execute([1]);
$image = $stmt->fetchColumn();
header('Content-Type: image/jpeg');
echo $image;
`}
      </pre><br/>

      <h2>Summary:</h2>
      <ul>
        <li><strong>BLOB types</strong> are ideal for storing large amounts of binary data.</li><br/>
        <li>They can store a wide variety of binary content, such as images, audio, or any other file type.</li><br/>
        <li>Choose the appropriate size (<code>TINYBLOB</code>, <code>BLOB</code>, <code>MEDIUMBLOB</code>, <code>LONGBLOB</code>) based on the size of the data you're working with.</li>
      </ul>
    </div>
  )}

   



{selectedChapter === 'chapter14' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Spatial Data Types  </h1>
      
      <p>
        Spatial data types in MySQL are used to store geometric or geographic data that represents spatial objects, such as points, lines, and polygons. These types are commonly used in applications like geographic information systems (GIS), mapping, and geospatial analysis. MySQL provides a set of spatial data types that allow you to store and manipulate spatial data efficiently.
      </p><br/>

      <h2>Spatial Data Types in MySQL</h2>

      <p>
        MySQL supports a set of spatial data types based on the Open Geospatial Consortium (OGC) standards. These types are used to represent different kinds of geometric and geographic objects. Here are the main spatial data types:
      </p><br/>

      <ol>
        <li >
          <strong>POINT</strong>
          <ul>
            <li><strong>Description</strong>: Represents a single point in a two-dimensional space using coordinates (X, Y). It is used to store a location on a map, such as a city, store, or a GPS coordinate.</li><br/>
            <li><strong>Syntax</strong>: <code>POINT(X, Y)</code></li><br/>
            <li><strong>Example</strong>: 
              <pre>
                {`CREATE TABLE locations (
location_id INT PRIMARY KEY,
coordinates POINT
);`}
              </pre>
            </li>
          </ul>
        </li><br/>
        <li>
          <strong>LINESTRING</strong><br/>
          <ul>
            <li><strong>Description</strong>: Represents a series of connected points (a path or line). A <code>LINESTRING</code> stores a sequence of points that defines a line in two-dimensional space.</li><br/>
            <li><strong>Syntax</strong>: <code>LINESTRING(X1, Y1, X2, Y2, ...)</code></li><br/>
            <li><strong>Example</strong>: 
              <pre>
                {`CREATE TABLE routes (
route_id INT PRIMARY KEY,
path LINESTRING
);`}
              </pre>
            </li>
          </ul>
        </li><br/>
        <li>
          <strong>POLYGON</strong><br/>
          <ul>
            <li><strong>Description</strong>: Represents a polygon, which is a closed shape defined by multiple points (vertices). The first point in the list of coordinates is also the last one, which closes the shape. A <code>POLYGON</code> can represent areas such as a city boundary, a park, or any enclosed space.</li><br/>
            <li><strong>Syntax</strong>: <code>POLYGON((X1, Y1), (X2, Y2), ..., (X1, Y1))</code></li><br/>
            <li><strong>Example</strong>: 
              <pre>
                {`CREATE TABLE parks (
park_id INT PRIMARY KEY,
boundary POLYGON
);`}
              </pre>
            </li>
          </ul>
        </li>
        {/* Add other data types (GEOMETRY, MULTIPOINT, etc.) similarly */}
      </ol><br/>

      <h2 style={{paddingBottom:"6px"}}>Spatial Indexing in MySQL</h2>

      <p>
        To improve performance when querying spatial data, MySQL uses <strong>spatial indexes</strong>. These indexes are similar to regular indexes but are specifically designed for geometric data. A spatial index helps speed up searches for spatial objects, like finding points within a certain area or calculating distances between locations.
      </p>

      <p>
        To create a spatial index in MySQL, you can use the following syntax:
      </p>
      <pre>
        {`CREATE SPATIAL INDEX index_name ON table_name(column_name);`}
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Common Operations on Spatial Data Types</h2>

      <ul>
        <li><strong>ST_Distance</strong>: Calculates the distance between two spatial objects.
          <pre>{`SELECT ST_Distance(POINT(1,1), POINT(2,2));`}</pre>
        </li><br/>
        <li><strong>ST_Contains</strong>: Checks if one spatial object contains another.
          <pre>{`SELECT ST_Contains(POLYGON((0,0), (0,5), (5,5), (5,0), (0,0)), POINT(3,3));`}</pre>
        </li>
        {/* Add other operations similarly */}
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Important Notes</h2>

      <ul>
        <li><strong>Coordinate Systems</strong>: MySQL's spatial data types assume the use of the <strong>Euclidean coordinate system</strong> by default, where distances are measured in a flat, 2D plane. For geographic data, such as latitude and longitude, you may need to use the <strong>SRID (Spatial Reference ID)</strong> to define the coordinate system for more accurate calculations.</li><br/>
        <li><strong>Support for Geographic Data</strong>: In MySQL, spatial types are based on the <strong>MyISAM</strong> storage engine, and there are certain limitations when using them with <strong>InnoDB</strong>. However, newer versions of MySQL provide better support for spatial operations with InnoDB.</li>
      </ul><br/>

      <h2>Conclusion</h2>
      <p>
        Spatial data types in MySQL provide a powerful way to store and manipulate geographic and geometric information. Whether you need to store points, lines, polygons, or more complex geometries, MySQL offers a flexible set of data types and functions that are useful in GIS applications, mapping software, and geospatial analysis.
      </p>
    </div>
  )}


{selectedChapter === 'chapter15' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  JSON Data Type </h1>

        <p>
          In MySQL, the <strong>JSON</strong> data type is used to store JSON (JavaScript Object Notation) data, which is a lightweight, human-readable format for representing structured data. MySQL introduced the <code>JSON</code> data type in version 5.7, allowing for the storage, querying, and manipulation of JSON-formatted data directly within MySQL tables. This is particularly useful for storing semi-structured data that does not fit neatly into traditional relational table structures.
        </p><br/>

        <h2 style={{paddingBottom:"6px"}}>Key Features of the JSON Data Type:</h2>
        <ul>
          <li><strong>Storage Format</strong>: MySQL stores the JSON data in an optimized binary format. This allows efficient storage and retrieval of data, while still ensuring the integrity of the JSON structure.</li><br/>
          <li><strong>Validation</strong>: When you insert data into a column with the <code>JSON</code> data type, MySQL ensures that the data is a valid JSON document. Invalid JSON data will cause an error.</li><br/>
          <li><strong>Manipulation</strong>: MySQL provides a set of functions and operators to manipulate JSON data, such as extracting values, updating keys, or adding new elements.</li>
        </ul><br/>

        <h2 style={{paddingBottom:"6px"}}>Creating a Table with a JSON Column:</h2>
        <pre>
          <code>
            {`CREATE TABLE user_profiles (
user_id INT PRIMARY KEY,
user_data JSON
);`}
          </code>
        </pre><br/>

        <h2 style={{paddingBottom:"6px"}}>Inserting JSON Data:</h2>
        <p>You can insert JSON data into a <code>JSON</code> column using a standard <code>INSERT</code> statement. The JSON data must be passed as a valid JSON string.</p>
        <pre>
          <code>
            {`INSERT INTO user_profiles (user_id, user_data)
VALUES
(1, '{"name": "John Doe", "age": 30, "email": "johndoe@example.com"}');`}
          </code>
        </pre><br/>

        <h2 style={{paddingBottom:"6px"}}>Retrieving JSON Data:</h2>
        <p>You can retrieve JSON data directly from a <code>JSON</code> column using a <code>SELECT</code> statement. The data will be returned as a JSON string:</p>
        <pre>
          <code>
            {`SELECT user_data
FROM user_profiles
WHERE user_id = 1;`}
          </code>
        </pre><br/>

        <h2 style={{paddingBottom:"6px"}}>JSON Functions and Operators in MySQL:</h2>
        <p>MySQL provides several functions to query and manipulate JSON data. Here are some commonly used JSON functions:</p>
        <h3>JSON_EXTRACT()</h3>
        <p>Extracts a value from a JSON document at a specified path.</p>
        <pre>
          <code>
            {`SELECT JSON_EXTRACT(user_data, '$.name') AS name
FROM user_profiles
WHERE user_id = 1;`}
          </code>
        </pre><br/>

        <h3>JSON_UNQUOTE()</h3>
        <p>Returns the value of a JSON string without quotes.</p>
        <pre>
          <code>
            {`SELECT JSON_UNQUOTE(JSON_EXTRACT(user_data, '$.email')) AS email
FROM user_profiles
WHERE user_id = 1;`}
          </code>
        </pre><br/>

        <h3>JSON_SET()</h3>
        <p>Updates a JSON document with a new value at the specified path.</p>
        <pre>
          <code>
            {`UPDATE user_profiles
SET user_data = JSON_SET(user_data, '$.age', 31)
WHERE user_id = 1;`}
          </code>
        </pre><br/>

        <h3>JSON_ARRAY()</h3>
        <p>Creates a JSON array from given values.</p>
        <pre>
          <code>
            {`SELECT JSON_ARRAY('apple', 'banana', 'cherry') AS fruits;`}
          </code>
        </pre><br/>

        <h3>JSON_OBJECT()</h3>
        <p>Creates a JSON object from key-value pairs.</p>
        <pre>
          <code>
            {`SELECT JSON_OBJECT('name', 'John Doe', 'age', 30) AS user_info;`}
          </code>
        </pre><br/>

        <h3>JSON_CONTAINS()</h3>
        <p>Checks if a JSON document contains a specified value.</p>
        <pre>
          <code>
            {`SELECT JSON_CONTAINS(user_data, '{"email": "johndoe@example.com"}') AS has_email
FROM user_profiles
WHERE user_id = 1;`}
          </code>
        </pre><br/>

        <h3>JSON_ARRAY_APPEND()</h3>
        <p>Appends a value to an existing JSON array.</p>
        <pre>
          <code>
            {`UPDATE user_profiles
SET user_data = JSON_ARRAY_APPEND(user_data, '$.friends', 'Jane Doe')
WHERE user_id = 1;`}
          </code>
        </pre><br/>

        <h2>Indexing JSON Data:</h2>
        <p>Although MySQL does not allow indexing directly on JSON values, you can create indexes on expressions that extract values from JSON columns. For example, you can index a JSON key for faster searches:</p>
        <pre>
          <code>
            {`CREATE INDEX idx_name ON user_profiles ((JSON_UNQUOTE(JSON_EXTRACT(user_data, '$.name'))));`}
          </code>
        </pre><br/>

        <h2>JSON Path Syntax:</h2>
        <p>When querying JSON data, MySQL uses a specific syntax called <strong>JSONPath</strong> to navigate the JSON structure. Here are a few examples:</p>
        <ul>
          <li><code>$</code>: Refers to the root of the JSON document.</li><br/>
          <li><code>.</code>: Used to access an element in a JSON object or array (similar to dot notation in JavaScript).</li><br/>
          <li><code>[]</code>: Used to access elements in a JSON array.</li>
        </ul><br/>
        <p>For example:</p>
        <ul>
          <li><code>$.name</code> refers to the <code>name</code> key in the root of the JSON document.</li><br/>
          <li><code>$.friends[0]</code> refers to the first element in the <code>friends</code> array.</li>
        </ul><br/>

        <h2>Advantages of Using the JSON Data Type:</h2>
        <ul>
          <li><strong>Flexible Schema</strong>: The JSON data type allows you to store data that doesn’t fit into a rigid, predefined schema. This is particularly useful for applications that deal with dynamic or evolving data structures.</li><br/>
          <li><strong>Efficiency</strong>: Storing JSON data in a binary format improves performance and reduces the overhead of storing it as plain text.</li><br/>
          <li><strong>Rich Functionality</strong>: MySQL provides a comprehensive set of functions and operators to extract, modify, and query JSON data, making it easy to work with semi-structured data directly in the database.</li>
        </ul><br/>

        <h2>Example Usage:</h2>
        <pre>
          <code>
            {`-- Creating a table with a JSON column
CREATE TABLE products (
product_id INT PRIMARY KEY,
product_info JSON
);

-- Inserting JSON data into the table
INSERT INTO products (product_id, product_info)
VALUES
(1, '{"name": "Laptop", "brand": "BrandX", "price": 999.99, "features": ["Touchscreen", "4GB RAM", "512GB SSD"]}');

-- Extracting data from the JSON column
SELECT JSON_EXTRACT(product_info, '$.name') AS product_name,
       JSON_EXTRACT(product_info, '$.price') AS product_price
FROM products
WHERE product_id = 1;

-- Updating data inside the JSON column
UPDATE products
SET product_info = JSON_SET(product_info, '$.price', 899.99)
WHERE product_id = 1;

-- Adding a new feature to the product
UPDATE products
SET product_info = JSON_ARRAY_APPEND(product_info, '$.features', 'Fingerprint Scanner')
WHERE product_id = 1;`}
          </code>
        </pre><br/>

        <h2>Conclusion:</h2>
        <p>The <code>JSON</code> data type in MySQL offers a powerful way to store, query, and manipulate semi-structured data. It’s particularly useful for applications that need to store flexible, evolving, or nested data structures, such as user preferences, product catalogs, or configuration settings. With a set of functions designed specifically for JSON, MySQL makes it easier to work with JSON data while still maintaining efficient storage and query performance.</p>
      </div>
    )}




{selectedChapter === 'chapter16' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Creating Database  </h1>


      <p>
        In MySQL, a <strong>database</strong> is a container used to store tables, views, procedures, and other database objects. The <code>CREATE DATABASE</code> command is used to create a new database in the MySQL server.
      </p>

      <br/>

      <h2>Syntax of <code>CREATE DATABASE</code></h2>
      <pre><code>{`CREATE DATABASE [IF NOT EXISTS] database_name;`}</code></pre>
      <ul>
        <li><strong><code>IF NOT EXISTS</code></strong>: This optional clause ensures that MySQL does not throw an error if a database with the same name already exists.</li><br/>
        <li><strong><code>database_name</code></strong>: The name of the database to be created. It should be unique within the MySQL server.</li>
      </ul>

      <br/>

      <h2 style={{paddingBottom:"6px"}}>Steps to Create a Database</h2>

      <ol>
        <li>
          <strong>Connect to MySQL Server:</strong>
          <p>
            Before you can create a database, you need to connect to your MySQL server. You can use the MySQL client or any GUI tool like phpMyAdmin, MySQL Workbench, or DBeaver.
          </p>
          <pre><code>{`mysql -u root -p`}</code></pre>
          <p>After entering the password, you will be connected to the MySQL shell.</p>
        </li><br/>
        <li>
          <strong>Create a Database:</strong>
          <p>To create a database, use the <code>CREATE DATABASE</code> statement. Here’s the basic syntax:</p>
          <pre><code>{`CREATE DATABASE school;`}</code></pre>
        </li><br/>
        <li>
          <strong>Verify the Database:</strong>
          <p>To verify that the database has been created, you can list all databases:</p>
          <pre><code>{`SHOW DATABASES;`}</code></pre>
        </li><br/>
        <li>
          <strong>Select the Database:</strong>
          <p>Once the database is created, you need to select it before you can start creating tables and inserting data. You do this with the <code>USE</code> command:</p>
          <pre><code>{`USE school;`}</code></pre>
        </li>
      </ol>

      <br/>

      <h2>Example: Creating a Database and Using It</h2>
      <p>Let’s say we want to create a database for a <strong>School Management System</strong>.</p>

      <ol>
        <li>
          <strong>Create the Database:</strong>
          <pre><code>{`CREATE DATABASE school;`}</code></pre>
        </li><br/>
        <li>
          <strong>Select the Database:</strong>
          <pre><code>{`USE school;`}</code></pre>
        </li><br/>
        <li>
          <strong>Create Tables:</strong>
          <p>Once the database is selected, you can start creating tables. For example, create a <code>students</code> table:</p>
          <pre><code>{`
CREATE TABLE students (
    student_id INT AUTO_INCREMENT PRIMARY KEY,
    first_name VARCHAR(100),
    last_name VARCHAR(100),
    age INT
);
`}</code></pre>
        </li><br/>
        <li>
          <strong>Insert Data:</strong>
          <p>You can then insert data into the <code>students</code> table:</p>
          <pre><code>{`
INSERT INTO students (first_name, last_name, age)
VALUES ('John', 'Doe', 20);
`}</code></pre>
        </li>
      </ol>

      <br/>

      <h2 style={{paddingBottom:"6px"}}>Key Points</h2>
      <ul>
        <li>The <code>CREATE DATABASE</code> command is used to create a new database.</li><br/>
        <li>You must have proper privileges (like <code>CREATE</code> privilege) to create a database.</li><br/>
        <li>After creating the database, you must use the <code>USE</code> command to select it.</li><br/>
        <li>You can use <code>IF NOT EXISTS</code> to avoid errors when the database already exists.</li>
      </ul>

      <br/>

      <h2 style={{paddingBottom:"6px"}}>Conclusion</h2>
      <p>
        The <code>CREATE DATABASE</code> command in MySQL is a simple yet essential tool for setting up databases where you can store your data. After creating the database, you can define tables, insert data, and perform operations on your data.
      </p>
    </div>
  )}



{selectedChapter === 'chapter17' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Dropping Databases </h1>

    <p>
      In MySQL, the <strong>DROP DATABASE</strong> command is used to permanently delete an existing database and all of its objects (tables, views, indexes, etc.) from the server. Once a database is dropped, all data stored within it is lost, so it's important to use this command with caution.
    </p>

    <br/>

    <h2>Syntax of `DROP DATABASE`</h2>
    <pre>
      <code>DROP DATABASE [IF EXISTS] database_name;</code>
    </pre>

    <ul>
      <li><strong>IF EXISTS</strong>: This optional clause prevents an error from occurring if the specified database does not exist. It will simply do nothing in such cases.</li><br/>
      <li><strong>database_name</strong>: The name of the database you want to drop.</li>
    </ul>

    <br/>

    <h2 style={{paddingBottom:"6px"}}>Steps to Drop a Database</h2>
    <ol>
      <li><strong>Connect to MySQL Server:</strong> Before you can drop a database, you need to be connected to the MySQL server. You can use the MySQL client or any GUI tool like phpMyAdmin, MySQL Workbench, or DBeaver.</li>
      <pre>
        <code>mysql -u root -p</code>
      </pre><br/>
      <li><strong>Check Available Databases:</strong> Before dropping a database, you may want to list all databases to verify the one you want to delete:</li>
      <pre>
        <code>SHOW DATABASES;</code>
      </pre><br/>
      <li><strong>Drop the Database:</strong> Use the <code>DROP DATABASE</code> command to remove the database:</li>
      <pre>
        <code>DROP DATABASE school;</code>
      </pre><br/>
      <li><strong>Verify the Database is Dropped:</strong> After dropping the database, you can verify it by listing the databases again:</li>
      <pre>
        <code>SHOW DATABASES;</code>
      </pre>
    </ol>

    <br/>

    <h2 >Example: Dropping a Database</h2>
    <p>Let's first create a database and a table in it:</p>
    <pre>
      <code>{`
CREATE DATABASE school;
USE school;

CREATE TABLE students (
  student_id INT AUTO_INCREMENT PRIMARY KEY,
  first_name VARCHAR(100),
  last_name VARCHAR(100),
  age INT
);
     `} </code>
    </pre>

    <p>Now, let's drop the <code>school</code> database:</p>
    <pre>
      <code>DROP DATABASE school;</code>
    </pre>

    <p>This will remove the <code>school</code> database and everything inside it, including the <code>students</code> table.</p>

    <p>After running the command, you can check that the database no longer exists:</p>
    <pre>
      <code>SHOW DATABASES;</code>
    </pre>

    <br/>

    <h2>Key Points to Remember</h2>
    <ul>
      <li><strong>Irreversible:</strong> The <code>DROP DATABASE</code> command is irreversible. Once executed, all data within the database is lost permanently. There is no built-in recovery feature unless you have a backup.</li><br/>
      <li><strong>Privileges Required:</strong> To drop a database, the user must have the <code>DROP</code> privilege for that database.</li><br/>
      <li><strong>Use with Caution:</strong> Because the operation is irreversible, always double-check the database name before dropping it.</li>
    </ul>

    <br/>

    <h2>Example with `IF EXISTS`</h2>
    <p>To prevent errors when dropping a database that may not exist, you can use the <code>IF EXISTS</code> clause:</p>
    <pre>
      <code>DROP DATABASE IF EXISTS school;</code>
    </pre>
    <p>If the <code>school</code> database does not exist, MySQL will not throw an error and will proceed without making any changes.</p>

    <br/>

    <h2>Conclusion</h2>
    <p>
      The <code>DROP DATABASE</code> command in MySQL is a powerful command used to delete a database and its contents. It should be used with caution as it permanently removes all the data in the database. Always ensure that you have backups of important data before using this command.
    </p>
  </div>
)}



{selectedChapter === 'chapter18' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Selecting Databases  </h1>

      <p>
        In MySQL, <strong>selecting a database</strong> means choosing the database in which you want to perform operations like creating tables, inserting data, querying data, etc. After selecting a database, all the SQL queries will be executed on that particular database unless you specify a different database.
      </p>

      <p>
        To select a database in MySQL, the <code>USE</code> statement is used. It tells MySQL which database to operate on.
      </p><br/>

      <h3>Syntax of <code>USE</code> Statement</h3>
      <pre>
        <code>USE database_name;</code>
      </pre>

      <ul>
        <li><strong>database_name</strong>: The name of the database you want to use.</li>
      </ul><br/>

      <h3 style={{paddingBottom:"6px"}}>Steps to Select a Database</h3>
      <ol>
        <li>
          <strong>Connect to the MySQL Server</strong>: Before selecting a database, you need to be connected to the MySQL server. You can connect using the MySQL command-line client or any GUI tool like phpMyAdmin or MySQL Workbench.
          <pre>
            <code>mysql -u root -p</code>
          </pre>
          After entering the password, you will be connected to the MySQL shell.
        </li><br/>

        <li>
          <strong>List Available Databases</strong>: If you are unsure of the databases available, you can list them using the <code>SHOW DATABASES</code> command. This will show all the databases that are present in the MySQL server.
          <pre>
            <code>SHOW DATABASES;</code>
          </pre>
        </li><br/>

        <li>
          <strong>Select a Database</strong>: Once you've identified the database you want to work with, use the <code>USE</code> statement to select it.
          <pre>
            <code>USE school;</code>
          </pre>
          This command selects the <code>school</code> database, so any further operations will be carried out on this database.
        </li><br/>

        <li>
          <strong>Verify the Current Database</strong>: After selecting the database, you can verify that the database is selected using the <code>DATABASE()</code> function. This will return the name of the currently selected database.
          <pre>
            <code>SELECT DATABASE();</code>
          </pre>
          This query will return the name of the database that is currently in use.
        </li>
      </ol><br/>

      <h3 style={{paddingBottom:"6px"}}>Example: Selecting a Database</h3>

      <p>Let's say you have multiple databases on your MySQL server, and you want to work with a database called <code>school</code>.</p><br/>

      <h4>1. List Databases</h4>
      <pre>
        <code>SHOW DATABASES;</code>
      </pre>
      <p>Output might look like this:</p>
      <pre>
        <code>
          +--------------------+<br />
          | Database           |<br />
          +--------------------+<br />
          | information_schema |<br />
          | school             |<br />
          | test               |<br />
          +--------------------+
        </code>
      </pre><br/>

      <h4>2. Select the <code>school</code> Database</h4>
      <pre>
        <code>USE school;</code>
      </pre><br/>

      <h4>3. Verify the Current Database</h4>
      <pre>
        <code>SELECT DATABASE();</code>
      </pre>
      <p>Output:</p>
      <pre>
        <code>
          +------------------+<br />
          | DATABASE()       |<br />
          +------------------+<br />
          | school           |<br />
          +------------------+
        </code>
      </pre><br/>

      <h3 style={{paddingBottom:"6px"}}>Key Points to Remember</h3>
      <ul>
        <li><strong>Only One Database at a Time</strong>: In MySQL, you can only work with one database at a time in a session. You can use <code>USE</code> to switch between databases within a session.</li><br/>
        <li><strong>Privileges</strong>: You must have the <code>USE</code> privilege on the database in order to select it.</li><br/>
        <li><strong>Default Database</strong>: Some MySQL users may be configured with a default database that is automatically selected when you log in. If not, you need to explicitly use the <code>USE</code> statement to select the database.</li><br/>
        <li><strong>Selecting Database in Queries</strong>: If you don't use <code>USE</code>, you can still specify the database in your queries by prefixing the table name with the database name (e.g., <code>database_name.table_name</code>).</li>
      </ul><br/>

      <h3>Conclusion</h3>
      <p>
        The <code>USE</code> statement in MySQL allows you to select a specific database to work with in a session. It's an essential command for navigating between multiple databases and ensuring that your queries are executed on the correct dataset.
      </p>
    </div>
  )}



{selectedChapter === 'chapter19' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Backing Up and Restoring Databases </h1>

    <p>Backing up and restoring databases are crucial operations to ensure that your data is safe from loss due to hardware failures, accidental deletions, or other unforeseen events. In MySQL, you can back up a database using various tools like the <code>mysqldump</code> utility or by copying database files directly, and you can restore a backup using the same tool.</p>
    <br/>
    <h2 style={{paddingBottom:"6px"}}>1. <strong>Backing Up a Database in MySQL</strong></h2>

    <h3>Using <code>mysqldump</code> to Backup a Database</h3>
    <p><code>mysqldump</code> is a command-line utility that allows you to export the contents of a MySQL database into a file, typically in SQL format, which can later be used for restoration.</p>

    <p><strong>Syntax:</strong></p>
    <pre>
      <code>
        mysqldump -u username -p database_name &gt; backup_file.sql
      </code>
    </pre>
    <ul>
      <li><strong><code>-u username</code></strong>: The MySQL username.</li><br/>
      <li><strong><code>-p</code></strong>: Prompt for the password of the MySQL user.</li><br/>
      <li><strong><code>database_name</code></strong>: The name of the database you want to back up.</li><br/>
      <li><strong><code>backup_file.sql</code></strong>: The output file where the backup will be stored.</li>
    </ul><br/>

    <h3>Example</h3>
    <pre>
      <code>
        mysqldump -u root -p school &gt; school_backup.sql
      </code>
    </pre>
    <p>This will back up the <code>school</code> database into a file named <code>school_backup.sql</code>. After running the command, you will be prompted to enter the MySQL root user password.</p>
    <br/>
    <h3>Backing Up All Databases</h3>
    <p>To back up all databases on the MySQL server, use the <code>--all-databases</code> option:</p>
    <pre>
      <code>
        mysqldump -u root -p --all-databases &gt; all_databases_backup.sql
      </code>
    </pre><br/>

    <h3>Backing Up a Database with Compression</h3>
    <p>To compress the backup file (e.g., <code>.gz</code> format), use:</p>
    <pre>
      <code>
        mysqldump -u root -p school | gzip &gt; school_backup.sql.gz
      </code>
    </pre><br/>

    <h2 style={{paddingBottom:"6px"}}>2. <strong>Restoring a Database in MySQL</strong></h2>

    <h3>Restoring from a Backup File</h3>
    <p>To restore a database from a backup file (e.g., <code>school_backup.sql</code>), you would use the <code>mysql</code> command as follows:</p>

    <p><strong>Syntax:</strong></p>
    <pre>
      <code>
        mysql -u username -p database_name &lt; backup_file.sql
      </code>
    </pre>
    <ul>
      <li><strong><code>-u username</code></strong>: The MySQL username.</li><br/>
      <li><strong><code>-p</code></strong>: Prompt for the password of the MySQL user.</li><br/>
      <li><strong><code>database_name</code></strong>: The name of the database where you want to restore the backup.</li><br/>
      <li><strong><code>backup_file.sql</code></strong>: The backup file that you want to restore from.</li>
    </ul><br/>

    <h3>Example</h3>
    <pre>
      <code>
        mysql -u root -p school &lt; school_backup.sql
      </code>
    </pre>
    <p>This command will restore the contents of the <code>school_backup.sql</code> file into the <code>school</code> database. You will be prompted to enter the MySQL root user password.</p>
    <br/>
    <h3>Restoring to a New Database</h3>
    <p>If you want to restore the backup to a new database (instead of overwriting an existing one), first create the new database and then restore the backup.</p>

    <p>1. Create a new database:</p>
    <pre>
      <code>
        CREATE DATABASE new_school;
      </code>
    </pre>
    <br/>
    <p>2. Restore the backup:</p>
    <pre>
      <code>
        mysql -u root -p new_school &lt; school_backup.sql
      </code>
    </pre><br/>

    <h3>Restoring a Compressed Backup</h3>
    <p>To restore from a compressed backup file (e.g., <code>.gz</code> format), you can use the <code>gunzip</code> or <code>zcat</code> command along with <code>mysql</code>:</p>
    <pre>
      <code>
        gunzip &lt; school_backup.sql.gz | mysql -u root -p school
      </code>
    </pre>

    <p>Alternatively:</p>
    <pre>
      <code>
        zcat school_backup.sql.gz | mysql -u root -p school
      </code>
    </pre><br/>

    <h2>3. <strong>Automating Backups</strong></h2>

    <p>To automate backups, you can create a cron job (on Linux/Unix systems) to regularly back up your databases. A sample cron job for daily backups might look like this:</p>

    <p>1. Open the crontab editor:</p>
    <pre>
      <code>
        crontab -e
      </code>
    </pre><br/>

    <p>2. Add a line for daily backups at 2:00 AM:</p>
    <pre>
      <code>
        0 2 * * * mysqldump -u root -p'password' school &gt; /path/to/backup/school_backup_$(date +\%F).sql
      </code>
    </pre>

    <h2 style={{paddingBottom:"6px"}}>4. <strong>Key Points to Remember</strong></h2>
    <ul>
      <li><strong>Backup Location:</strong> Store your backup files in a secure and reliable location. Consider using external storage or cloud solutions for additional safety.</li><br/>
      <li><strong>Backup Frequency:</strong> Regular backups are important for minimizing data loss. The frequency of backups depends on how frequently your data changes.</li><br/>
      <li><strong>Test Your Backups:</strong> Regularly test your backups to ensure they can be restored properly. You don’t want to discover a corrupted or invalid backup when you need it most.</li><br/>
      <li><strong>Use <code>--single-transaction</code> for InnoDB:</strong> When backing up databases using the InnoDB storage engine, it is recommended to use the <code>--single-transaction</code> flag to ensure consistent backups without locking tables:
        <pre><code>mysqldump -u root -p --single-transaction school &gt; school_backup.sql</code></pre>
      </li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>Backing up and restoring databases are critical practices for protecting your data in MySQL. The <code>mysqldump</code> utility is commonly used for both backup and restoration, and understanding how to manage these operations ensures that you can safeguard your data and recover it in case of failure. Always ensure to keep a secure, tested backup of your important data.</p>
  </div>
)}




      {selectedChapter === "chapter20" && (
        <div className={style.chaptercontent}>
          <h1 className={style.heading}>Creating Tables </h1>
          <p>
            Tables are the core structure in a database where data is stored in rows and columns. In MySQL, creating tables is a fundamental step in database management. You define the table's structure, including column names, data types, constraints, and other properties.
          </p>
          <br/>

          <h2>1. Syntax for Creating a Table</h2>
          <p>
            To create a table in MySQL, you use the <code>CREATE TABLE</code> statement.
          </p>
          <pre>
            <code>
{`CREATE TABLE table_name (
    column_name1 data_type constraints,
    column_name2 data_type constraints,
    ...
    column_nameN data_type constraints
);`}
            </code>
          </pre>
          <ul>
            <li><strong>table_name</strong>: The name of the table.</li><br/>
            <li><strong>column_name</strong>: The name of each column in the table.</li><br/>
            <li><strong>data_type</strong>: The type of data the column will store (e.g., INT, VARCHAR, DATE).</li><br/>
            <li><strong>constraints</strong>: Optional rules applied to the column (e.g., NOT NULL, PRIMARY KEY).</li>
          </ul>
          <br/>

          <h2 style={{paddingBottom:"6px"}}>2. Steps to Create a Table</h2>
          <h3>Step 1: Choose the Database</h3>
          <p>Before creating a table, make sure you've selected the database where the table will reside.</p>
          <pre>
            <code>USE database_name;</code>
          </pre><br/>

          <h3 style={{paddingBottom:"6px"}}>Step 2: Create the Table</h3>
          <p>Define the structure of the table using the <code>CREATE TABLE</code> statement.</p>
          <pre>
            <code>
{`CREATE TABLE students (
    student_id INT AUTO_INCREMENT PRIMARY KEY,
    name VARCHAR(50) NOT NULL,
    age INT,
    enrollment_date DATE,
    is_active BOOLEAN DEFAULT TRUE
);`}
            </code>
          </pre>

          <h4 style={{paddingBottom:"6px"}}>Explanation:</h4>
          <ul>
            <li><code>student_id INT AUTO_INCREMENT PRIMARY KEY</code>: An integer column that auto-increments and acts as the primary key.</li><br/>
            <li><code>name VARCHAR(50) NOT NULL</code>: A string column up to 50 characters, not allowing null values.</li><br/>
            <li><code>age INT</code>: An optional column for the student's age.</li><br/>
            <li><code>enrollment_date DATE</code>: A column to store the enrollment date.</li><br/>
            <li><code>is_active BOOLEAN DEFAULT TRUE</code>: A column to indicate if the student is active, defaulting to <code>TRUE</code>.</li>
          </ul>
          <br/>

          <h2 style={{paddingBottom:"6px"}}>3. Common Data Types for Columns</h2>
          <table className={style.table}>
            <thead>
              <tr>
                <th>Data Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><code>INT</code></td>
                <td>Integer numbers (e.g., 1, 100).</td>
              </tr>
              <tr>
                <td><code>VARCHAR(size)</code></td>
                <td>Variable-length string (e.g., names, emails).</td>
              </tr>
              <tr>
                <td><code>CHAR(size)</code></td>
                <td>Fixed-length string.</td>
              </tr>
              <tr>
                <td><code>TEXT</code></td>
                <td>Long text strings (e.g., descriptions).</td>
              </tr>
              <tr>
                <td><code>DATE</code></td>
                <td>Date values (e.g., 2024-11-19).</td>
              </tr>
              <tr>
                <td><code>DATETIME</code></td>
                <td>Date and time values (e.g., 2024-11-19 15:30:00).</td>
              </tr>
              <tr>
                <td><code>BOOLEAN</code></td>
                <td>True or false values (<code>1</code> or <code>0</code>).</td>
              </tr>
            </tbody>
          </table>
          <br/>

          <h2 style={{paddingBottom:"6px"}}>4. Adding Constraints to Columns</h2>
          <p>Constraints ensure data integrity in your table. Common constraints include:</p>
          <ul>
            <li><code>PRIMARY KEY</code>: Uniquely identifies each record in the table.</li><br/>
            <li><code>NOT NULL</code>: Ensures that the column cannot have null values.</li><br/>
            <li><code>UNIQUE</code>: Ensures all values in a column are distinct.</li><br/>
            <li><code>AUTO_INCREMENT</code>: Automatically generates unique numbers for a column.</li><br/>
            <li><code>DEFAULT</code>: Sets a default value for the column when no value is provided.</li><br/>
            <li><code>FOREIGN KEY</code>: Links the column to a column in another table.</li>
          </ul>
          <br/>

          <h2 style={{paddingBottom:"6px"}}>5. Creating a Table with a Foreign Key</h2>
          <pre>
            <code>
{`CREATE TABLE courses (
    course_id INT AUTO_INCREMENT PRIMARY KEY,
    course_name VARCHAR(100) NOT NULL
);

CREATE TABLE enrollments (
    enrollment_id INT AUTO_INCREMENT PRIMARY KEY,
    student_id INT,
    course_id INT,
    enrollment_date DATE NOT NULL,
    FOREIGN KEY (student_id) REFERENCES students(student_id),
    FOREIGN KEY (course_id) REFERENCES courses(course_id)
);`}
            </code>
          </pre>
          <br/>

          <h2 style={{paddingBottom:"6px"}}>6. Verifying Table Creation</h2>
          <p>To confirm that the table has been created successfully:</p>
          <pre>
            <code>SHOW TABLES;</code>
          </pre>
          <p>To view the table structure:</p>
          <pre>
            <code>DESCRIBE table_name;</code>
          </pre>
          <br/>

          <h2 style={{paddingBottom:"6px"}}>7. Best Practices for Table Creation</h2>
          <ul>
            <li>Use meaningful names for tables and columns (e.g., <code>students</code>, <code>orders</code>).</li><br/>
            <li>Avoid data redundancy by normalizing data into related tables.</li><br/>
            <li>Define primary keys, foreign keys, and constraints to ensure data integrity.</li><br/>
            <li>Choose appropriate data types to optimize storage.</li><br/>
            <li>Plan for scalability to accommodate future growth.</li>
          </ul>
          <br/>

          <h2 style={{paddingBottom:"6px"}}>Conclusion</h2>
          <p>
            Creating tables in MySQL is a fundamental skill for database management. By mastering syntax, data types, and constraints, you can design robust and efficient database structures tailored to your application's needs.
          </p>
        </div>
      )}
   

{selectedChapter === 'chapter21' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Altering Tables   </h1>

        <h2>Altering Tables in MySQL: A Guide to Table Management</h2>
        <p>
          Altering tables in MySQL involves modifying the structure of an existing table. This includes adding,
          modifying, or deleting columns, as well as changing constraints or other table properties. The
          <code>ALTER TABLE</code> statement is used to perform these operations.
        </p>

        <br/>

        <h3>1. Syntax for Altering Tables</h3>
        <p>The basic syntax of the <code>ALTER TABLE</code> statement is:</p>
        <pre>
          <code>
{`ALTER TABLE table_name 
    action;`}
          </code>
        </pre>
        <ul>
          <li><b>table_name</b>: The name of the table you want to modify.</li>
          <li><b>action</b>: The specific change to apply (e.g., add a column, drop a column).</li>
        </ul>

        <br/>

        <h3 style={{paddingBottom:"6px"}}>2. Common Operations for Altering Tables</h3>

        <h4>a. Adding a Column</h4>
        <p>To add a new column to a table:</p>
        <pre>
          <code>
{`ALTER TABLE table_name
ADD column_name data_type constraints;`}
          </code>
        </pre>
        <p><b>Example:</b></p>
        <pre>
          <code>
{`ALTER TABLE students
ADD phone_number VARCHAR(15);`}
          </code>
        </pre><br/>

        <h4>b. Modifying a Column</h4>
        <p>To modify the data type or constraints of an existing column:</p>
        <pre>
          <code>
{`ALTER TABLE table_name
MODIFY column_name new_data_type constraints;`}
          </code>
        </pre>
        <p><b>Example:</b></p>
        <pre>
          <code>
{`ALTER TABLE students
MODIFY phone_number VARCHAR(20) NOT NULL;`}
          </code>
        </pre><br/>

        <h4>c. Renaming a Column</h4>
        <p>To rename an existing column:</p>
        <pre>
          <code>
{`ALTER TABLE table_name
CHANGE old_column_name new_column_name data_type constraints;`}
          </code>
        </pre>
        <p><b>Example:</b></p>
        <pre>
          <code>
{`ALTER TABLE students
CHANGE phone_number contact_number VARCHAR(20);`}
          </code>
        </pre><br/>

        <h4>d. Dropping a Column</h4>
        <p>To delete a column from a table:</p>
        <pre>
          <code>
{`ALTER TABLE table_name
DROP COLUMN column_name;`}
          </code>
        </pre>
        <p><b>Example:</b></p>
        <pre>
          <code>
{`ALTER TABLE students
DROP COLUMN contact_number;`}
          </code>
        </pre>

        <br/>

        <h3 style={{paddingBottom:"6px"}}>3. Modifying Table Properties</h3>

        <h4>a. Renaming a Table</h4>
        <p>To rename an entire table:</p>
        <pre>
          <code>
{`ALTER TABLE old_table_name
RENAME TO new_table_name;`}
          </code>
        </pre>
        <p><b>Example:</b></p>
        <pre>
          <code>
{`ALTER TABLE students
RENAME TO learners;`}
          </code>
        </pre><br/>

        <h4 style={{paddingBottom:"6px"}}>b. Adding or Dropping Constraints</h4>
        <p><b>Adding a Primary Key:</b></p>
        <pre>
          <code>
{`ALTER TABLE table_name
ADD PRIMARY KEY (column_name);`}
          </code>
        </pre>
        <p><b>Example:</b></p>
        <pre>
          <code>
{`ALTER TABLE students
ADD PRIMARY KEY (student_id);`}
          </code>
        </pre><br/>

        <p><b>Dropping a Primary Key:</b></p>
        <pre>
          <code>
{`ALTER TABLE table_name
DROP PRIMARY KEY;`}
          </code>
        </pre><br/>

        <p><b>Adding a Foreign Key:</b></p>
        <pre>
          <code>
{`ALTER TABLE table_name
ADD CONSTRAINT fk_name FOREIGN KEY (column_name) REFERENCES referenced_table(referenced_column);`}
          </code>
        </pre>
        <p><b>Example:</b></p>
        <pre>
          <code>
{`ALTER TABLE enrollments
ADD CONSTRAINT fk_student FOREIGN KEY (student_id) REFERENCES students(student_id);`}
          </code>
        </pre><br/>

        <p><b>Dropping a Foreign Key:</b></p>
        <pre>
          <code>
{`ALTER TABLE table_name
DROP FOREIGN KEY fk_name;`}
          </code>
        </pre>

        <br/>

        <h3>4. Combining Multiple Alterations</h3>
        <p>You can combine multiple alterations in a single <code>ALTER TABLE</code> statement:</p>
        <pre>
          <code>
{`ALTER TABLE students
ADD email VARCHAR(100),
MODIFY age TINYINT,
DROP COLUMN is_active;`}
          </code>
        </pre>

        <br/>

        <h3>5. Best Practices for Altering Tables</h3>
        <ul>
          <li><b>Backup Your Data</b>: Always create a backup before altering tables to avoid accidental data loss.</li><br/>
          <li><b>Plan Changes Carefully</b>: Ensure that changes won't break existing queries, relationships, or applications.</li><br/>
          <li><b>Minimize Downtime</b>: Perform alterations during low-traffic times to reduce the impact on users.</li><br/>
          <li><b>Test in a Development Environment</b>: Before making changes to a production database, test them in a staging or development environment.</li>
        </ul>

        <br/>

        <h3>Conclusion</h3>
        <p>
          Altering tables in MySQL is a flexible and powerful way to manage and modify your database structure.
          Whether you’re adding new columns, updating constraints, or renaming tables, understanding the <code>ALTER TABLE</code>
          statement ensures that your database can adapt to changing requirements while maintaining data integrity.
          Always follow best practices to minimize risks and disruptions.
        </p>
      </div>
    )}



   
{selectedChapter === 'chapter22' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Dropping Tables  </h1>

        <h2>Introduction</h2>
        <p>
          Dropping a table in MySQL means permanently removing the table and all of its data from the database. This
          operation is irreversible, so it should be used with caution. The <code>DROP TABLE</code> statement is used
          to perform this action.
        </p>

        <br/>

        <h3>1. Syntax for Dropping Tables</h3>
        <p>To drop a table, use the following syntax:</p>
        <pre>
          <code>{`DROP TABLE table_name;`}</code>
        </pre>
        <ul>
          <li><b>table_name</b>: The name of the table to be dropped.</li>
        </ul>
        <p>To drop multiple tables at once, list their names separated by commas:</p>
        <pre>
          <code>{`DROP TABLE table_name1, table_name2, ...;`}</code>
        </pre>

        <br/>

        <h3>2. Examples of Dropping Tables</h3>
        <h4>Example 1: Dropping a Single Table</h4>
        <pre>
          <code>{`DROP TABLE employees;`}</code>
        </pre>
        <p>This command removes the <code>employees</code> table from the database.</p>

        <h4>Example 2: Dropping Multiple Tables</h4>
        <pre>
          <code>{`DROP TABLE orders, products;`}</code>
        </pre>
        <p>This command removes both the <code>orders</code> and <code>products</code> tables.</p>

        <br/>

        <h3>3. Using IF EXISTS</h3>
        <p>
          If the specified table does not exist, the <code>DROP TABLE</code> statement generates an error. To avoid
          this, use the <code>IF EXISTS</code> clause:
        </p>
        <pre>
          <code>{`DROP TABLE IF EXISTS table_name;`}</code>
        </pre>
        <p><b>Example:</b></p>
        <pre>
          <code>{`DROP TABLE IF EXISTS temp_data;`}</code>
        </pre>
        <p>This command ensures that no error is raised if <code>temp_data</code> does not exist.</p>

        <br/>

        <h3>4. Precautions When Dropping Tables</h3>
        <ul>
          <li><b>Irreversible Action:</b> Once a table is dropped, all its data is permanently lost unless a backup exists.</li><br/>
          <li><b>Check Dependencies:</b> Ensure the table is not referenced by foreign key constraints in other tables.</li><br/>
          <li><b>Backup Data:</b> Always create a backup of critical tables before dropping them.</li><br/>
          <li><b>Test Before Executing:</b> Test the <code>DROP TABLE</code> command in a non-production environment first.</li>
        </ul><br/>

        

        <h3>5. Dropping Tables with Foreign Key Constraints</h3>
        <p>
          If a table has foreign key dependencies, you may need to drop the dependent constraints or tables first.
          MySQL does not support the <code>CASCADE</code> keyword for this purpose. You must explicitly remove the
          constraints or delete dependent records.
        </p>
        <p><b>Example:</b> Removing a foreign key before dropping the table:</p>
        <pre>
          <code>{`ALTER TABLE orders DROP FOREIGN KEY fk_customer;
DROP TABLE orders;`}</code>
        </pre>

        <br/>

        <h3>6. Verifying Remaining Tables</h3>
        <p>
          After dropping a table, you can check the remaining tables in the database using the <code>SHOW TABLES</code> command:
        </p>
        <pre>
          <code>{`SHOW TABLES;`}</code>
        </pre>

        <br/>

        <h3>7. Best Practices</h3>
        <ul>
          <li><b>Use the IF EXISTS Clause:</b> Prevent unnecessary errors when the table might not exist.</li><br/>
          <li><b>Backup Important Data:</b> Always ensure that data can be recovered if dropped accidentally.</li><br/>
          <li><b>Check Constraints:</b> Understand the relationships and dependencies of the table being dropped.</li>
        </ul>

        <br/>

        <h3>Conclusion</h3>
        <p>
          The <code>DROP TABLE</code> statement in MySQL is a straightforward way to remove tables from your database.
          However, it must be used with caution, as it permanently deletes the table and its data. Always follow best
          practices and double-check dependencies before executing the command.
        </p>
      </div>
    )}


{selectedChapter === 'chapter23' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Temporary Tables   </h1>

        <h2>Introduction</h2>
        <p>
          Temporary tables in MySQL are special tables that exist only during the session in which they were created.
          They are automatically dropped when the session ends, making them useful for storing intermediate data
          without affecting the permanent database structure.
        </p>

        <br/>

        <h3>1. Syntax for Creating Temporary Tables</h3>
        <p>To create a temporary table, use the <code>TEMPORARY</code> keyword with the <code>CREATE TABLE</code> statement:</p>
        <pre>
          <code>{`CREATE TEMPORARY TABLE temp_table_name (
    column1 data_type constraints,
    column2 data_type constraints,
    ...
);`}</code>
        </pre>
        <ul>
          <li><b>temp_table_name:</b> The name of the temporary table.</li><br/>
          <li><b>column1, column2:</b> Column definitions, including data types and optional constraints.</li>
        </ul>

        <br/>

        <h3>2. Characteristics of Temporary Tables</h3>
        <ul>
          <li><b>Session-Specific:</b> Temporary tables are visible only to the session that created them. Other sessions cannot access them.</li><br/>
          <li><b>Automatic Cleanup:</b> Temporary tables are automatically dropped when the session ends.</li><br/>
          <li><b>Name Conflict Avoidance:</b> If a temporary table and a regular table have the same name, the temporary table takes precedence within the session.</li>
        </ul>

        <br/>

        <h3>3. Examples of Using Temporary Tables</h3>
        <h4>Example 1: Creating and Using a Temporary Table</h4>
        <pre>
          <code>{`CREATE TEMPORARY TABLE temp_orders (
    order_id INT,
    customer_id INT,
    order_date DATE
);

INSERT INTO temp_orders VALUES
(1, 101, '2024-11-19'),
(2, 102, '2024-11-20');

SELECT * FROM temp_orders;`}</code>
        </pre>
        <p>
          In this example, a temporary table <code>temp_orders</code> is created, populated with data, and queried within
          the same session.
        </p><br/>

        <h4>Example 2: Temporary Table for Intermediate Calculations</h4>
        <p>
          Temporary tables are often used in complex queries to store intermediate results for further processing.
        </p>
        <pre>
          <code>{`CREATE TEMPORARY TABLE temp_sales AS
SELECT product_id, SUM(quantity) AS total_quantity
FROM sales
GROUP BY product_id;

SELECT * FROM temp_sales
WHERE total_quantity > 100;`}</code>
        </pre>
        <p>
          This example creates a temporary table <code>temp_sales</code> to store aggregated sales data, which is then
          filtered and queried.
        </p>

        <br/>

        <h3>4. Managing Temporary Tables</h3>
        <h4>Dropping Temporary Tables</h4>
        <p>
          Temporary tables can be dropped manually using the <code>DROP TABLE</code> statement:
        </p>
        <pre>
          <code>{`DROP TEMPORARY TABLE temp_table_name;`}</code>
        </pre>
        <p>
          If not dropped manually, they are automatically dropped when the session ends.
        </p>

        <h4>Checking Temporary Tables</h4>
        <p>
          Use <code>SHOW TABLES</code> to view the available tables. Temporary tables are not listed by default.
        </p>
        <pre>
          <code>{`SHOW TABLES;`}</code>
        </pre>

        <br/>

        <h3>5. Limitations of Temporary Tables</h3>
        <ul>
          <li><b>Session Scope:</b> Temporary tables cannot be shared across sessions.</li><br/>
          <li><b>Replication:</b> Temporary tables are not replicated to slave servers in a master-slave setup.</li><br/>
          <li><b>Storage Engine:</b> Temporary tables use the default storage engine unless specified explicitly.</li>
        </ul>

        <br/>

        <h3>6. Best Practices</h3>
        <ul>
          <li><b>Use Meaningful Names:</b> Although temporary tables are session-specific, descriptive names help avoid confusion.</li><br/>
          <li><b>Optimize Usage:</b> Use temporary tables to simplify complex queries and improve performance.</li><br/>
          <li><b>Clean Up Explicitly:</b> While automatic cleanup occurs, explicitly dropping temporary tables ensures clarity in your scripts.</li>
        </ul>

        <br/>

        <h3>Conclusion</h3>
        <p>
          Temporary tables in MySQL are a powerful tool for handling intermediate data in a session-specific manner.
          They provide flexibility and isolation for temporary computations, improving the efficiency of complex
          queries and applications.
        </p>
      </div>
    )}



{selectedChapter === 'chapter24' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Copying Tables </h1>

    
    <p>
      Copying tables in MySQL can involve duplicating the structure, data, or both, depending on the requirement. 
      This is useful for creating backups, testing, or creating similar tables for new purposes.
    </p>

    <br/>

    <h3>1. Copying Only the Table Structure</h3>
    <p>
      To copy just the structure (schema) of a table without copying its data, you can use the <code>CREATE TABLE ... LIKE</code> statement.
    </p>
    <pre>
      <code>{`CREATE TABLE new_table LIKE existing_table;`}</code>
    </pre>
    <p>
      <strong>Example:</strong>
    </p>
    <pre>
      <code>{`CREATE TABLE backup_students LIKE students;`}</code>
    </pre>
    <p>This creates a new table <code>backup_students</code> with the same structure as <code>students</code>, but without any data.</p>

    <br/>

    <h3>2. Copying Table Structure and Data</h3>
    <p>
      To copy both the structure and the data from one table to another, use the <code>CREATE TABLE ... SELECT</code> statement.
    </p>
    <pre>
      <code>{`CREATE TABLE new_table AS SELECT * FROM existing_table;`}</code>
    </pre>
    <p>
      <strong>Example:</strong>
    </p>
    <pre>
      <code>{`CREATE TABLE archived_students AS SELECT * FROM students;`}</code>
    </pre>
    <p>This creates a new table <code>archived_students</code> with the same structure as <code>students</code> and copies all the data.</p>

    <br/>

    <h3>3. Copying with Filters (Partial Data Copy)</h3>
    <p>
      You can copy only specific rows from a table by adding a <code>WHERE</code> clause to the <code>SELECT</code> statement.
    </p>
    <pre>
      <code>
        {`CREATE TABLE graduated_students AS
SELECT * FROM students
WHERE graduation_year = 2024;`}
      </code>
    </pre>
    <p>This creates a new table <code>graduated_students</code> that includes only rows where <code>graduation_year</code> is 2024.</p>

    <br/>

    <h3>4. Copying Data into an Existing Table</h3>
    <p>
      If the target table already exists and you want to copy data into it, use the <code>INSERT INTO ... SELECT</code> statement.
    </p>
    <pre>
      <code>
        {`INSERT INTO target_table (column1, column2, ...)
SELECT column1, column2, ...
FROM source_table;`}
      </code>
    </pre>
    <p>
      <strong>Example:</strong>
    </p>
    <pre>
      <code>
        {`INSERT INTO archived_students (student_id, name, age, enrollment_date)
SELECT student_id, name, age, enrollment_date
FROM students
WHERE graduation_year = 2024;`}
      </code>
    </pre>
    <p>This copies specific columns and rows from <code>students</code> to the existing table <code>archived_students</code>.</p>

    <br/>

    <h3>5. Copying a Table with Indexes and Constraints</h3>
    <p>
      If you need to copy a table along with its indexes and constraints, use the following steps:
    </p>
    <ol>
      <li>Copy the structure using <code>CREATE TABLE ... LIKE</code>.</li><br/>
      <li>Copy the data using <code>INSERT INTO ... SELECT</code>.</li>
    </ol>
    <p>
      <strong>Example:</strong>
    </p>
    <pre>
      <code>
        {`CREATE TABLE copy_students LIKE students;
INSERT INTO copy_students SELECT * FROM students;`}
      </code>
    </pre>
    <p>
      This ensures that the new table <code>copy_students</code> includes indexes and constraints defined in <code>students</code>.
    </p>

    <br/>

    <h3>6. Copying Only Specific Columns</h3>
    <p>
      You can copy only certain columns by specifying them in the <code>SELECT</code> statement.
    </p>
    <pre>
      <code>
        {`CREATE TABLE student_names AS
SELECT student_id, name
FROM students;`}
      </code>
    </pre>
    <p>
      This creates a table <code>student_names</code> with only the <code>student_id</code> and <code>name</code> columns from the <code>students</code> table.
    </p>

    <br/>

    <h3>7. Best Practices for Copying Tables</h3>
    <ul>
      <li>
        <strong>Use Descriptive Names:</strong> Name copied tables clearly to indicate their purpose (e.g., <code>backup_students</code>, <code>archived_students</code>).
      </li><br/>
      <li>
        <strong>Check Storage Requirements:</strong> Ensure sufficient storage space is available before copying large tables.
      </li><br/>
      <li>
        <strong>Filter Data When Possible:</strong> Copy only the necessary data to minimize resource usage.
      </li><br/>
      <li>
        <strong>Test in a Development Environment:</strong> When copying tables for testing, use a development or staging environment to avoid affecting the production database.
      </li><br/>
      <li>
        <strong>Backup Important Data:</strong> Always create backups before performing operations on critical tables.
      </li>
    </ul>

    <br/>

    <h3>Conclusion</h3>
    <p>
      Copying tables in MySQL is a versatile operation that can be tailored to different needs, such as duplicating structures, archiving data, or transforming information. 
      By understanding the various methods and their use cases, you can effectively manage your database and ensure data integrity and efficiency.
    </p>
  </div>
)}




{selectedChapter === 'chapter25' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   Inserting Data   </h1>

      <p>
        In MySQL, inserting data involves adding records (rows) to a table. This is typically done using the <code>INSERT</code> statement, which allows you to specify values for columns in a table. MySQL also provides advanced options to handle different use cases like inserting multiple rows, inserting data with default values, and handling duplicate records.
      </p>

      <br/>

      <h2>1. Basic Syntax</h2>
      <p>
        The <code>INSERT</code> statement's basic syntax is as follows:
      </p>
      <pre>
        <code>
          {`INSERT INTO table_name (column1, column2, ...) 
VALUES (value1, value2, ...);`}
        </code>
      </pre>
      <p>
        <strong>table_name</strong>: The name of the table where you want to insert data.
        <br />
        <strong>column1, column2, ...</strong>: The columns for which you want to insert values.
        <br />
        <strong>value1, value2, ...</strong>: The values corresponding to the specified columns.
      </p>

      <br/>

      <h2>2. Inserting Data into All Columns</h2>
      <p>
        If you want to insert values for all columns, you can omit the column names in the <code>INSERT</code> statement. The values must be in the same order as the table's columns.
      </p>
      <pre>
        <code>
          {`INSERT INTO students
VALUES (1, 'John Doe', 20, '2024-01-01');`}
        </code>
      </pre>
      <p>This inserts data into all columns of the <code>students</code> table.</p>

      <br/>

      <h2>3. Inserting Data into Specific Columns</h2>
      <p>
        If you want to insert data into specific columns, you can specify the column names explicitly.
      </p>
      <pre>
        <code>
          {`INSERT INTO students (student_id, name)
VALUES (2, 'Jane Doe');`}
        </code>
      </pre>
      <p>
        In this case: <br />
        <strong>student_id</strong> is set to <code>2</code>, <br />
        <strong>name</strong> is set to <code>'Jane Doe'</code>.
        Columns not specified (e.g., <code>age</code> or <code>enrollment_date</code>) will use their default values or remain <code>NULL</code> if no default is defined.
      </p>

      <br/>

      <h2>4. Inserting Multiple Rows</h2>
      <p>
        You can insert multiple rows into a table in a single query by separating each set of values with a comma.
      </p>
      <pre>
        <code>
          {`INSERT INTO students (student_id, name, age)
VALUES 
  (3, 'Alice Smith', 22),
  (4, 'Bob Johnson', 19),
  (5, 'Charlie Brown', 21);`}
        </code>
      </pre>
      <p>This adds three rows to the <code>students</code> table.</p>

      <br/>

      <h2>5. Using Default Values</h2>
      <p>
        You can use the <code>DEFAULT</code> keyword to insert default values for specific columns.
      </p>
      <pre>
        <code>
          {`INSERT INTO students (student_id, name, age, enrollment_date)
VALUES (6, 'David Lee', DEFAULT, DEFAULT);`}
        </code>
      </pre>
      <p>
        In this case: <br />
        The <strong>age</strong> and <strong>enrollment_date</strong> columns will use their defined default values.
      </p>

      <br/>

      <h2>6. Inserting Data from Another Table</h2>
      <p>
        You can insert data into a table by selecting data from another table using the <code>INSERT INTO ... SELECT</code> statement.
      </p>
      <pre>
        <code>
          {`INSERT INTO archived_students (student_id, name)
SELECT student_id, name
FROM students
WHERE graduation_year = 2024;`}
        </code>
      </pre>
      <p>
        This copies data from the <code>students</code> table to the <code>archived_students</code> table for students graduating in 2024.
      </p>

      <br/>

      <h2>7. Handling Duplicate Records</h2>
      <p>MySQL provides options for handling duplicate key errors during insertion:</p>

      <h3>a. <code>INSERT IGNORE</code></h3>
      <p>Ignores rows that would cause duplicate key errors.</p>
      <pre>
        <code>
          {`INSERT IGNORE INTO students (student_id, name)
VALUES (1, 'John Doe');`}
        </code>
      </pre>
      <p>If <code>student_id = 1</code> already exists, this statement does nothing.</p><br/>

      <h3>b. <code>REPLACE INTO</code></h3>
      <p>Deletes the existing row with the same key and inserts the new one.</p>
      <pre>
        <code>
          {`REPLACE INTO students (student_id, name)
VALUES (1, 'John Smith');`}
        </code>
      </pre>
      <p>If <code>student_id = 1</code> exists, it will be replaced with this new record.</p><br/>

      <h3>c. <code>ON DUPLICATE KEY UPDATE</code></h3>
      <p>Updates specified columns if a duplicate key error occurs.</p>
      <pre>
        <code>
          {`INSERT INTO students (student_id, name, age)
VALUES (1, 'John Doe', 25)
ON DUPLICATE KEY UPDATE age = VALUES(age);`}
        </code>
      </pre>
      <p>If <code>student_id = 1</code> exists, only the <code>age</code> column will be updated to <code>25</code>.</p>

      <br/>

      <h2>8. Best Practices</h2>
      <ul>
        <li><strong>Match Columns and Values:</strong> Ensure the number of columns matches the number of values to avoid errors.</li><br/>
        <li><strong>Use Prepared Statements:</strong> For dynamic queries, use prepared statements to prevent SQL injection attacks.</li><br/>
        <li><strong>Define Default Values:</strong> Define meaningful default values for columns where applicable.</li><br/>
        <li><strong>Test Queries:</strong> Test your <code>INSERT</code> queries in a development or staging environment before applying them to production.</li>
      </ul>

      <br/>

      <h2 style={{paddingBottom:"6px"}}>9. Examples</h2>
      <h3>Inserting Complete Data</h3>
      <pre>
        <code>
          {`INSERT INTO students (student_id, name, age, enrollment_date)
VALUES (10, 'Emma Watson', 22, '2024-01-15');`}
        </code>
      </pre><br/>

      <h3>Inserting Data with Default Values</h3>
      <pre>
        <code>
          {`INSERT INTO students (student_id, name)
VALUES (11, 'Oliver Twist');`}
        </code>
      </pre><br/>

      <h3>Bulk Insert</h3>
      <pre>
        <code>
          {`INSERT INTO students (student_id, name, age)
VALUES 
  (12, 'Sophia King', 20),
  (13, 'Liam Brown', 19);`}
        </code>
      </pre><br/>

      <h3>Insert Using Select</h3>
      <pre>
        <code>
          {`INSERT INTO archived_students
SELECT * FROM students
WHERE graduation_year = 2023;`}
        </code>
      </pre>

      <br/>

      <h2>Conclusion</h2>
      <p>
        The <code>INSERT</code> statement in MySQL is a versatile tool for adding data to tables. With options for bulk insertion, handling duplicates, and inserting data conditionally, MySQL provides robust mechanisms for managing data effectively. Understanding the various use cases ensures smooth and efficient database operations.
      </p>
    </div>
  )}




{selectedChapter === 'chapter26' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Updating Data   </h1>
      <p>
        Updating data in MySQL involves modifying existing records in a table. The <code>UPDATE</code> statement is used to change data in specific columns of one or more rows that meet certain conditions. This operation is crucial for maintaining accurate and up-to-date information in the database.
      </p>

      <br/>

      <h3>1. Basic Syntax</h3>
      <pre>
        <code>
          {`UPDATE table_name
SET column1 = value1, column2 = value2, ...
WHERE condition;`}
        </code>
      </pre>
      <ul>
        <li><strong>table_name</strong>: The name of the table you want to update.</li><br/>
        <li><strong>column1, column2, ...</strong>: The columns to update.</li><br/>
        <li><strong>value1, value2, ...</strong>: The new values to assign to the columns.</li><br/>
        <li><strong>WHERE condition</strong>: The condition that identifies the rows to update. Without this condition, all rows in the table will be updated.</li>
      </ul>

      <br/>

      <h3>2. Updating Specific Columns</h3>
      <p>You can update one or more columns of a row by setting the column(s) to a new value.</p>
      <pre>
        <code>
          {`UPDATE students
SET age = 21
WHERE student_id = 1;`}
        </code>
      </pre>
      <p>This query updates the <code>age</code> of the student with <code>student_id = 1</code> to <code>21</code>.</p>

      <br/>

      <h3>3. Updating Multiple Columns</h3>
      <p>You can update multiple columns in a single query by separating the column-value pairs with commas.</p>
      <pre>
        <code>
          {`UPDATE students
SET age = 22, enrollment_date = '2024-01-01'
WHERE student_id = 1;`}
        </code>
      </pre>
      <p>This query updates the <code>age</code> and <code>enrollment_date</code> of the student with <code>student_id = 1</code>.</p>

      <br/>

      <h3>4. Updating Multiple Rows</h3>
      <p>If you want to update multiple rows based on a certain condition, you can do so by ensuring that the <code>WHERE</code> clause matches multiple rows.</p>
      <pre>
        <code>
          {`UPDATE students
SET age = age + 1
WHERE graduation_year = 2023;`}
        </code>
      </pre>
      <p>This query increments the <code>age</code> by 1 for all students who graduated in 2023.</p>

      <br/>

      <h3>5. Updating with Expressions or Calculations</h3>
      <p>You can update columns using expressions or calculations. For example, you might want to add or subtract from an existing value.</p>
      <pre>
        <code>
          {`UPDATE students
SET age = age + 1
WHERE student_id = 2;`}
        </code>
      </pre>
      <p>This query increases the <code>age</code> by 1 for the student with <code>student_id = 2</code>.</p>

      <br/>

      <h3>6. Using Subqueries in UPDATE</h3>
      <p>You can use a subquery to update a column with values derived from another table or another query.</p>
      <pre>
        <code>
          {`UPDATE students
SET age = (SELECT age FROM teachers WHERE teacher_id = 1)
WHERE student_id = 1;`}
        </code>
      </pre>
      <p>This query sets the <code>age</code> of the student with <code>student_id = 1</code> to the <code>age</code> of the teacher with <code>teacher_id = 1</code>.</p>

      <br/>

      <h3>7. Updating with JOINs</h3>
      <p>You can update a table based on values from another table by using a <code>JOIN</code> in the <code>UPDATE</code> query.</p>
      <pre>
        <code>
          {`UPDATE students s
JOIN teachers t ON s.teacher_id = t.teacher_id
SET s.age = t.age
WHERE s.student_id = 1;`}
        </code>
      </pre>
      <p>This query updates the <code>age</code> of a student based on the corresponding <code>age</code> of their teacher.</p>

      <br/>

      <h3>8. Best Practices</h3>
      <ul>
        <li><strong>Use a WHERE Clause:</strong> Always use a <code>WHERE</code> clause unless you intend to update all rows in the table. Forgetting the <code>WHERE</code> clause will update every row, which could cause unwanted changes.</li><br/>
        <li><strong>Backup Data:</strong> Before performing large updates or complex queries, especially in a production environment, it's a good idea to back up the data to avoid data loss in case of errors.</li><br/>
        <li><strong>Transaction Control:</strong> Use <code>BEGIN TRANSACTION</code>, <code>COMMIT</code>, and <code>ROLLBACK</code> to ensure that the update operations are atomic and can be reverted if something goes wrong.</li><br/>
        <li><strong>Test Updates:</strong> In development or staging environments, test your update queries to ensure they behave as expected before running them in production.</li>
      </ul>

      <br/>

      <h3 style={{paddingBottom:"6px"}}>9. Example Queries</h3>

      <h4>Updating a Single Row</h4>
      <pre>
        <code>
          {`UPDATE students
SET name = 'John Doe', age = 25
WHERE student_id = 3;`}
        </code>
      </pre><br/>

      <h4>Updating Multiple Rows</h4>
      <pre>
        <code>
          {`UPDATE students
SET graduation_year = 2025
WHERE graduation_year = 2024;`}
        </code>
      </pre><br/>

      <h4>Updating with Arithmetic Operations</h4>
      <pre>
        <code>
          {`UPDATE students
SET age = age + 2
WHERE student_id = 4;`}
        </code>
      </pre><br/>

      <h4>Updating Using Subqueries</h4>
      <pre>
        <code>
          {`UPDATE students
SET age = (SELECT age FROM teachers WHERE teacher_id = 2)
WHERE student_id = 5;`}
        </code>
      </pre><br/>

      <h4>Updating with a JOIN</h4>
      <pre>
        <code>
          {`UPDATE students s
JOIN teachers t ON s.teacher_id = t.teacher_id
SET s.age = t.age
WHERE s.student_id = 1;`}
        </code>
      </pre>

      <br/>

      <h3>Conclusion</h3>
      <p>
        The <code>UPDATE</code> statement is essential for modifying data in MySQL. Whether you're updating a single column, multiple columns, or multiple rows, it provides flexibility to handle a wide variety of data manipulation tasks. Always ensure you're updating the right rows by using appropriate <code>WHERE</code> clauses to prevent unintentional data changes.
      </p>
    </div>
  )}


{selectedChapter === 'chapter27' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Deleting Data  </h1>

      <p>
        Deleting data in MySQL involves removing records (rows) from a table using the
        <code>DELETE</code> statement. This is a powerful operation that permanently removes data, and it’s essential to use it with care, especially when working with production databases.
      </p><br/>

      <h2>1. Basic Syntax</h2>
      <pre><code>
        {`DELETE FROM table_name\nWHERE condition;`}
      </code></pre>
      <p>
        - <strong>table_name</strong>: The name of the table from which you want to delete records.<br />
        - <strong>condition</strong>: The condition that determines which rows to delete. If no condition is specified, all rows in the table will be deleted (this can be very dangerous).
      </p>
      <p><strong>Note:</strong> Always include a <code>WHERE</code> clause to avoid unintentionally deleting all rows from the table.</p>
      <br/>
      <h2>2. Deleting a Single Row</h2>
      <pre><code>
        {`DELETE FROM students\nWHERE student_id = 1;`}
      </code></pre>
      <p>This query deletes the student with <code>student_id = 1</code>.</p>
      <br/>
      <h2>3. Deleting Multiple Rows</h2>
      <pre><code>
        {`DELETE FROM students\nWHERE graduation_year = 2024;`}
      </code></pre>
      <p>This query deletes all students who graduated in 2024.</p>
      <br/>
      <h2>4. Deleting All Rows</h2>
      <pre><code>
        {`DELETE FROM students;`}
      </code></pre>
      <p>This query deletes all rows from the <code>students</code> table, but the table structure (columns) remains intact.</p>
      <br/>
      <h2>5. Using LIMIT with DELETE</h2>
      <pre><code>
        {`DELETE FROM students\nWHERE graduation_year = 2024\nLIMIT 10;`}
      </code></pre>
      <p>This query deletes only 10 students from the class of 2024.</p>
      <br/>
      <h2>6. Deleting Using Subqueries</h2>
      <pre><code>
        {`DELETE FROM students\nWHERE student_id IN (SELECT student_id FROM archived_students);`}
      </code></pre>
      <p>This query deletes students whose <code>student_id</code> is present in the <code>archived_students</code> table.</p>
      <br/>
      <h2>7. Deleting with JOINs</h2>
      <pre><code>
        {`DELETE s FROM students s\nJOIN teachers t ON s.teacher_id = t.teacher_id\nWHERE t.department = 'Science';`}
      </code></pre>
      <p>This query deletes all students who have a teacher from the Science department.</p>
      <br/>
      <h2>8. Best Practices</h2>
      <ul>
        <li><strong>Always use a WHERE clause:</strong> Without a <code>WHERE</code> clause, all rows will be deleted, which is irreversible. Always make sure the <code>WHERE</code> condition is specific.</li><br/>
        <li><strong>Backup Data:</strong> Before performing any <code>DELETE</code> operation, especially in a production environment, consider creating a backup of the data in case something goes wrong.</li><br/>
        <li><strong>Use Transactions:</strong> If your deletion involves multiple steps or tables, use transactions (<code>BEGIN TRANSACTION</code>, <code>COMMIT</code>, <code>ROLLBACK</code>) to ensure that the deletion process is atomic and can be reverted if needed.</li><br/>
        <li><strong>Test Queries:</strong> Test your <code>DELETE</code> queries in a staging or development environment before running them in production to ensure they work as expected.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>9. Example Queries</h2>

      <h3>Deleting a Single Row</h3>
      <pre><code>
        {`DELETE FROM students\nWHERE student_id = 3;`}
      </code></pre><br/>

      <h3>Deleting Multiple Rows</h3>
      <pre><code>
        {`DELETE FROM students\nWHERE graduation_year = 2025;`}
      </code></pre><br/>

      <h3>Deleting All Rows</h3>
      <pre><code>
        {`DELETE FROM students;`}
      </code></pre><br/>

      <h3>Deleting with a Subquery</h3>
      <pre><code>
        {`DELETE FROM students\nWHERE student_id IN (SELECT student_id FROM archived_students);`}
      </code></pre><br/>

      <h3>Deleting with a JOIN</h3>
      <pre><code>
        {`DELETE s FROM students s\nJOIN teachers t ON s.teacher_id = t.teacher_id\nWHERE t.department = 'Math';`}
      </code></pre><br/>

      <h2>10. Conclusion</h2>
      <p>
        The <code>DELETE</code> statement in MySQL is used to remove data from a table. Whether you are deleting a single row, multiple rows, or all rows, the process is flexible but requires caution. Always use a <code>WHERE</code> clause to avoid deleting unintended data, and consider using backup and transaction controls when performing critical deletions in a production environment.
      </p>
    </div>
  )}



{selectedChapter === 'chapter28' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Replacing Data   </h1>

      <p>
        Replacing data in MySQL refers to modifying existing records in a table, either by updating certain values or replacing them with new ones. There are several methods for replacing data depending on the use case. The most common methods are using the <code>REPLACE</code> statement and the <code>INSERT ... ON DUPLICATE KEY UPDATE</code> statement.
      </p><br/>

      <h2>1. Using the <code>REPLACE</code> Statement</h2>

      <p>
        The <code>REPLACE</code> statement in MySQL works similarly to an <code>INSERT</code> statement, but with one key difference: if a record with the same primary key or unique index already exists in the table, it will be deleted and replaced with the new row. If the record does not exist, it will be inserted.
      </p><br/>

      <h3>Syntax:</h3>
      <pre>
        <code>
          {`REPLACE INTO table_name (column1, column2, ...)
VALUES (value1, value2, ...);`}
        </code>
      </pre>

      <p>
        - <strong>table_name</strong>: The name of the table where you want to replace data.<br />
        - <strong>column1, column2, ...</strong>: The columns in the table to update or insert.<br />
        - <strong>value1, value2, ...</strong>: The new values for the columns.
      </p><br/>

      <h3>How It Works:</h3>
      <ul>
        <li>If there is already a row in the table with the same primary key or unique index, that row is deleted and replaced by the new row.</li><br/>
        <li>If no row exists with that key, a new row is inserted.</li>
      </ul><br/>

      <h3>Example:</h3>
      <pre>
        <code>
          {`REPLACE INTO students (student_id, name, age)
VALUES (1, 'John Doe', 22);`}
        </code>
      </pre>

      <p>
        In this example:
        <ul>
          <li>If a student with <code>student_id = 1</code> already exists, the entire row will be replaced with the new values for <code>name</code> and <code>age</code>.</li><br/>
          <li>If no student with <code>student_id = 1</code> exists, a new row will be inserted into the <code>students</code> table.</li>
        </ul>
      </p><br/>

      <h3>Key Points to Remember with <code>REPLACE</code>:</h3>
      <ul>
        <li><strong>Primary Key or Unique Key</strong>: The table should have a primary key or a unique key. MySQL uses this key to identify the record to replace.</li><br/>
        <li><strong>Deleting Rows</strong>: <code>REPLACE</code> deletes the existing row before inserting the new row, which may activate any associated foreign key constraints or triggers. Be cautious when using <code>REPLACE</code> in tables with related foreign keys.</li>
      </ul><br/>

      <h2>2. Using <code>INSERT ... ON DUPLICATE KEY UPDATE</code></h2>

      <p>
        While <code>REPLACE</code> is useful for replacing a row, the <code>INSERT ... ON DUPLICATE KEY UPDATE</code> statement provides more flexibility. It allows you to update specific columns when a duplicate key violation occurs (i.e., when a record with the same primary or unique key already exists).
      </p><br/>

      <h3>Syntax:</h3>
      <pre>
        <code>
          {`INSERT INTO table_name (column1, column2, ...)
VALUES (value1, value2, ...)
ON DUPLICATE KEY UPDATE column1 = value1, column2 = value2, ...;`}
        </code>
      </pre>

      <p>
        - <strong>table_name</strong>: The table where you want to insert or replace data.<br />
        - <strong>column1, column2, ...</strong>: The columns that you want to insert or update.<br />
        - <strong>value1, value2, ...</strong>: The values to insert into the columns.<br />
        - <strong>ON DUPLICATE KEY UPDATE</strong>: If a duplicate key exists, this clause updates the existing record with the new values.
      </p><br/>

      <h3>How It Works:</h3>
      <ul>
        <li>If a row with the same primary key or unique index exists, the specified columns are updated with the new values.</li><br/>
        <li>If no such row exists, a new row is inserted.</li>
      </ul>

      <h3>Example:</h3>
      <pre>
        <code>
          {`INSERT INTO students (student_id, name, age)
VALUES (1, 'John Doe', 22)
ON DUPLICATE KEY UPDATE name = 'John Doe', age = 22;`}
        </code>
      </pre>

      <p>
        In this example:
        <ul>
          <li>If a student with <code>student_id = 1</code> exists, only the <code>name</code> and <code>age</code> columns will be updated with the new values.</li><br/>
          <li>If no student with <code>student_id = 1</code> exists, a new row will be inserted into the table.</li>
        </ul>
      </p>

      <h3 style={{paddingBottom:"6px"}}>Key Points:</h3>
      <ul>
        <li><strong>Flexibility</strong>: <code>INSERT ... ON DUPLICATE KEY UPDATE</code> allows you to specify exactly which columns to update, rather than replacing the entire row as in <code>REPLACE</code>.</li><br/>
        <li><strong>No Row Deletion</strong>: Unlike <code>REPLACE</code>, <code>INSERT ... ON DUPLICATE KEY UPDATE</code> does not delete existing rows, it only updates them.</li>
      </ul><br/>

      <h2>3. When to Use <code>REPLACE</code> vs. <code>INSERT ... ON DUPLICATE KEY UPDATE</code></h2>

      <h3>Use <code>REPLACE</code> when:</h3>
      <ul>
        <li>You want to completely replace a record with new values.</li><br/>
        <li>You are certain that the table has a primary key or unique index that will identify the record for replacement.</li><br/>
        <li>You want to delete the old record and insert the new one in a single operation.</li>
      </ul><br/>

      <h3>Use <code>INSERT ... ON DUPLICATE KEY UPDATE</code> when:</h3>
      <ul>
        <li>You need to update specific columns while preserving the rest of the record.</li><br/>
        <li>You want more control over the update logic (i.e., you can apply different update logic to different columns).</li><br/>
        <li>You do not want to delete the existing record but only modify it.</li>
      </ul><br/>

      <h2>4. Example Queries for Replacing Data</h2>

      <h3>Using <code>REPLACE</code> Statement</h3>
      <pre>
        <code>
          {`-- Replace the student with student_id 1
REPLACE INTO students (student_id, name, age)
VALUES (1, 'Jane Doe', 23);`}
        </code>
      </pre><br/>

      <h3>Using <code>INSERT ... ON DUPLICATE KEY UPDATE</code></h3>
      <pre>
        <code>
          {`-- Insert a new student or update if the student_id already exists
INSERT INTO students (student_id, name, age)
VALUES (1, 'Jane Doe', 23)
ON DUPLICATE KEY UPDATE name = 'Jane Doe', age = 23;`}
        </code>
      </pre><br/>

      <h2>5. Best Practices</h2>

      <ul>
        <li><strong>Transactions</strong>: When replacing or updating data, use transactions (<code>BEGIN TRANSACTION</code>, <code>COMMIT</code>, <code>ROLLBACK</code>) to ensure that the data changes are atomic and can be rolled back if something goes wrong.</li><br/>
        <li><strong>Backups</strong>: Before performing replacement operations, especially in a production environment, always back up the data to avoid unintentional data loss.</li><br/>
        <li><strong>Indexes</strong>: Ensure that your table has the appropriate primary or unique keys to support the replacement or duplicate key operations.</li>
      </ul><br/>

      <h2>6. Conclusion</h2>

      <p>
        In MySQL, replacing data can be done using the <code>REPLACE</code> statement or the <code>INSERT ... ON DUPLICATE KEY UPDATE</code> statement. The choice between the two depends on your requirements:
        <ul>
          <li>Use <code>REPLACE</code> if you want to fully replace a row.</li><br/>
          <li>Use <code>INSERT ... ON DUPLICATE KEY UPDATE</code> for more flexible updates without deleting rows.</li>
        </ul><br/>
        Both methods are powerful tools for managing and maintaining data in a MySQL database.
      </p>
    </div>
  )}




{selectedChapter === 'chapter29' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Select Data   </h1>
      
      <p>
        In MySQL, selecting data from a database is done using the <code>SELECT</code> statement. The <code>SELECT</code> statement allows you to query and retrieve data from one or more tables based on specified criteria.
      </p><br/>

      <h2>Basic <code>SELECT</code> Syntax</h2>
      <pre>
        <code>
{`SELECT column1, column2, ...
FROM table_name;`}
        </code>
      </pre>
      <p>
        - <strong>column1, column2, ...</strong>: The columns you want to retrieve from the table.<br />
        - <strong>table_name</strong>: The name of the table from which you want to retrieve data.
      </p>

      <h3>Example</h3>
      <pre>
        <code>
{`SELECT name, age FROM students;`}
        </code>
      </pre>
      <p>This query retrieves the <code>name</code> and <code>age</code> columns from the <code>students</code> table.</p>

      <br/>
      
      <h2>1. Selecting All Columns (<code>*</code>)</h2>
      <p>If you want to select all columns from a table, you can use the asterisk (<code>*</code>) wildcard instead of specifying individual column names.</p>
      <pre>
        <code>
{`SELECT * FROM students;`}
        </code>
      </pre>
      <p>This will retrieve all columns from the <code>students</code> table.</p>

      <br/>

      <h2>2. Filtering Data with <code>WHERE</code></h2>
      <p>You can filter the data returned by the <code>SELECT</code> statement using the <code>WHERE</code> clause. This allows you to specify conditions that the rows must meet to be included in the result.</p>
      <pre>
        <code>
{`SELECT column1, column2, ...
FROM table_name
WHERE condition;`}
        </code>
      </pre>
      <h3>Example</h3>
      <pre>
        <code>
{`SELECT name, age FROM students WHERE age > 18;`}
        </code>
      </pre>
      <p>This query retrieves the <code>name</code> and <code>age</code> columns from the <code>students</code> table where the <code>age</code> is greater than 18.</p>

      <br/>

      <h2>3. Ordering Results with <code>ORDER BY</code></h2>
      <p>You can sort the results using the <code>ORDER BY</code> clause. By default, <code>ORDER BY</code> sorts data in ascending order (<code>ASC</code>), but you can specify descending order (<code>DESC</code>) if needed.</p>
      <pre>
        <code>
{`SELECT column1, column2, ...
FROM table_name
ORDER BY column1 [ASC|DESC], column2 [ASC|DESC], ...;`}
        </code>
      </pre>
      <h3>Example</h3>
      <pre>
        <code>
{`SELECT name, age FROM students ORDER BY age DESC;`}
        </code>
      </pre>
      <p>This query retrieves the <code>name</code> and <code>age</code> columns from the <code>students</code> table, ordered by <code>age</code> in descending order.</p>

      <br/>

      <h2>4. Limiting the Number of Results (<code>LIMIT</code>)</h2>
      <p>You can limit the number of rows returned by the query using the <code>LIMIT</code> clause. This is particularly useful when you only want a subset of the data.</p>
      <pre>
        <code>
{`SELECT column1, column2, ...
FROM table_name
LIMIT number_of_rows;`}
        </code>
      </pre>
      <h3>Example</h3>
      <pre>
        <code>
{`SELECT name, age FROM students LIMIT 5;`}
        </code>
      </pre>
      <p>This query retrieves the first 5 rows from the <code>students</code> table.</p>

      <br/>

      <h2>5. Selecting Distinct Values (<code>DISTINCT</code>)</h2>
      <p>If you want to retrieve unique (non-duplicate) values from a column, you can use the <code>DISTINCT</code> keyword. This eliminates duplicate rows from the result set.</p>
      <pre>
        <code>
{`SELECT DISTINCT column1
FROM table_name;`}
        </code>
      </pre>
      <h3>Example</h3>
      <pre>
        <code>
{`SELECT DISTINCT age FROM students;`}
        </code>
      </pre>
      <p>This query retrieves the unique ages from the <code>students</code> table.</p>

      <br/>

      <h2>6. Using Aggregate Functions</h2>
      <p>MySQL provides aggregate functions like <code>COUNT()</code>, <code>SUM()</code>, <code>AVG()</code>, <code>MIN()</code>, and <code>MAX()</code> that allow you to perform calculations on your data.</p>

      <h3>Example of <code>COUNT()</code></h3>
      <pre>
        <code>
{`SELECT COUNT(*) FROM students;`}
        </code>
      </pre>
      <p>This query returns the total number of rows in the <code>students</code> table.</p>

      <h3>Example of <code>AVG()</code></h3>
      <pre>
        <code>
{`SELECT AVG(age) FROM students;`}
        </code>
      </pre>
      <p>This query returns the average age of all students.</p>

      <br/>

      <h2>7. Joining Multiple Tables (<code>JOIN</code>)</h2>
      <p>In many cases, you need to retrieve data from multiple tables. You can achieve this by using <code>JOIN</code> to combine rows from two or more tables based on a related column.</p>
      <h3>Syntax for <code>INNER JOIN</code></h3>
      <pre>
        <code>
{`SELECT column1, column2, ...
FROM table1
INNER JOIN table2 ON table1.column = table2.column;`}
        </code>
      </pre>
      <h3>Example</h3>
      <pre>
        <code>
{`SELECT students.name, students.age, courses.course_name
FROM students
INNER JOIN enrollments ON students.student_id = enrollments.student_id
INNER JOIN courses ON enrollments.course_id = courses.course_id;`}
        </code>
      </pre>
      <p>This query retrieves the names and ages of students along with the courses they are enrolled in by joining the <code>students</code>, <code>enrollments</code>, and <code>courses</code> tables.</p>

      <br/>

      <h2>8. Grouping Data with <code>GROUP BY</code></h2>
      <p>The <code>GROUP BY</code> clause groups rows that have the same values into summary rows, like "total count" or "average." This is often used with aggregate functions.</p>
      <h3>Example</h3>
      <pre>
        <code>
{`SELECT age, COUNT(*) FROM students GROUP BY age;`}
        </code>
      </pre>
      <p>This query groups the students by age and counts how many students are in each age group.</p>

      <br/>

      <h2>9. Using <code>HAVING</code> with Aggregates</h2>
      <p>The <code>HAVING</code> clause is used to filter results based on aggregate functions (it works like the <code>WHERE</code> clause but for groups).</p>
      <h3>Example</h3>
      <pre>
        <code>
{`SELECT age, COUNT(*) 
FROM students 
GROUP BY age
HAVING COUNT(*) > 2;`}
        </code>
      </pre>
      <p>This query groups the students by age and only returns the age groups that have more than two students.</p>

      <br/>

      <h2>Conclusion</h2>
      <p>The <code>SELECT</code> statement in MySQL is a powerful tool for retrieving data. By combining various clauses like <code>WHERE</code>, <code>ORDER BY</code>, <code>LIMIT</code>, <code>GROUP BY</code>, and <code>JOIN</code>, you can create complex queries to retrieve exactly the data you need. Understanding how to use these clauses effectively will help you interact with your MySQL database efficiently.</p>
    </div>
  )}





{selectedChapter === 'chapter30' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> WHERE Clause</h1>

    <p>
      The <code>WHERE</code> clause in MySQL is used to filter records in a database query. It allows you to specify
      conditions that rows must meet to be included in the result set. The <code>WHERE</code> clause is often used in
      conjunction with <code>SELECT</code>, <code>UPDATE</code>, <code>DELETE</code>, and other SQL statements.
    </p><br/>

    <h2>Basic Syntax</h2>
    <pre>
      <code>
        SELECT column1, column2, ...{'\n'}
        FROM table_name{'\n'}
        WHERE condition;
      </code>
    </pre>
    <ul>
      <li><strong>column1, column2, ...</strong>: The columns you want to retrieve.</li><br/>
      <li><strong>table_name</strong>: The table from which data will be fetched.</li><br/>
      <li><strong>condition</strong>: The condition that each row must satisfy to be included in the result.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>Key Operators in the WHERE Clause</h2>
    <ol>
      <li>
        <strong>Comparison Operators:</strong>
        <ul>
          <li><code>=</code>: Equal to</li><br/>
          <li><code>&lt;&gt;</code> or <code>!=</code>: Not equal to</li><br/>
          <li><code>&lt;</code>: Less than</li><br/>
          <li><code>&lt;=</code>: Less than or equal to</li><br/>
          <li><code>&gt;</code>: Greater than</li><br/>
          <li><code>&gt;=</code>: Greater than or equal to</li>
        </ul><br/>
        <pre>
          <code>
            SELECT name, age FROM students WHERE age &gt; 18;
          </code>
        </pre>
      </li><br/>

      <li>
        <strong>Logical Operators:</strong>
        <ul>
          <li><code>AND</code>: Combines conditions; all must be true.</li><br/>
          <li><code>OR</code>: Combines conditions; at least one must be true.</li><br/>
          <li><code>NOT</code>: Negates a condition.</li>
        </ul><br/>
        <pre>
          <code>
            SELECT name, age FROM students WHERE age &gt; 18 AND grade = 'A';{'\n'}
            SELECT name, age FROM students WHERE age &lt; 18 OR grade = 'B';
          </code>
        </pre>
      </li><br/>

      <li>
        <strong>Pattern Matching with LIKE:</strong>
        <p>
          Use <code>LIKE</code> for pattern matching. Combine it with wildcards:
          <ul>
            <li><code>%</code>: Matches zero or more characters.</li><br/>
            <li><code>_</code>: Matches exactly one character.</li>
          </ul>
        </p>
        <pre>
          <code>
            SELECT name FROM students WHERE name LIKE 'A%';
          </code>
        </pre>
      </li><br/>

      <li>
        <strong>Range with BETWEEN:</strong>
        <pre>
          <code>
            SELECT name, age FROM students WHERE age BETWEEN 18 AND 25;
          </code>
        </pre>
      </li><br/>

      <li>
        <strong>Set Membership with IN:</strong>
        <pre>
          <code>
            SELECT name FROM students WHERE grade IN ('A', 'B', 'C');
          </code>
        </pre>
      </li><br/>

      <li>
        <strong>Null Values with IS NULL and IS NOT NULL:</strong>
        <pre>
          <code>
            SELECT name FROM students WHERE address IS NULL;
          </code>
        </pre>
      </li>
    </ol><br/>

    <h2>Using WHERE with Aggregate Functions</h2>
    <p>
      When filtering results of aggregate functions like <code>COUNT</code>, <code>SUM</code>, <code>AVG</code>, use the{' '}
      <code>HAVING</code> clause instead of <code>WHERE</code>.
    </p>
    <pre>
      <code>
        SELECT department, COUNT(*){'\n'}
        FROM employees{'\n'}
        GROUP BY department{'\n'}
        HAVING COUNT(*) &gt; 5;
      </code>
    </pre><br/>

    <h2>Combining WHERE with Other Clauses</h2>
    <ul>
      <li>
        <strong>Using WHERE with ORDER BY:</strong>
        <pre>
          <code>
            SELECT name, age {'\n'}
            FROM students {'\n'}
            WHERE age &gt; 18 {'\n'}
            ORDER BY age DESC;
          </code>
        </pre>
      </li><br/>
      <li>
        <strong>Using WHERE with LIMIT:</strong>
        <pre>
          <code>
            SELECT name, age {'\n'}
            FROM students {'\n'}
            WHERE age &gt; 18 {'\n'}
            LIMIT 10;
          </code>
        </pre>
      </li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      The <code>WHERE</code> clause is a powerful tool for filtering data in MySQL. By using various operators, you can
      create highly specific conditions to retrieve exactly the data you need. Mastering the <code>WHERE</code> clause
      is essential for efficient database querying and manipulation.
    </p>
  </div>
)}



{selectedChapter === 'chapter31' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Sorting Data with ORDER BY  </h1>

    

    <p>
      The <code>ORDER BY</code> clause in MySQL is used to sort the rows retrieved by a query. Sorting can be done in 
      ascending (<code>ASC</code>) or descending (<code>DESC</code>) order, based on one or more columns.
    </p>

    <h2>Basic Syntax</h2>
    <pre>
      <code>
        {`SELECT column1, column2, ...
FROM table_name
ORDER BY column1 [ASC|DESC], column2 [ASC|DESC], ...;`}
      </code>
    </pre>
    <p>
      - <code>column1, column2, ...</code>: The columns by which the results should be sorted.<br />
      - <code>ASC</code>: Sorts in ascending order (default).<br />
      - <code>DESC</code>: Sorts in descending order.
    </p><br/>

    <h2>Sorting in Ascending Order</h2>
    <p>
      By default, the <code>ORDER BY</code> clause sorts results in ascending order.
    </p>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
ORDER BY age;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names and ages of students sorted by their age in ascending 
      order.
    </p><br/>

    <h2>Sorting in Descending Order</h2>
    <p>
      To sort results in descending order, use the <code>DESC</code> keyword.
    </p>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
ORDER BY age DESC;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names and ages of students sorted by their age in descending 
      order.
    </p><br/>

    <h2>Sorting by Multiple Columns</h2>
    <p>
      You can sort by multiple columns by listing them in the <code>ORDER BY</code> clause. The rows are sorted by the first 
      column, and if there are ties, by the second column, and so on.
    </p>
    <pre>
      <code>{`
SELECT name, age, grade 
FROM students 
ORDER BY grade ASC, age DESC;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names, ages, and grades of students sorted by grade in ascending 
      order and, within the same grade, by age in descending order.
    </p><br/>

    <h2>Sorting with Aliases or Calculated Columns</h2>
    <p>
      You can sort by aliases or calculated columns in your query.
    </p>
    <pre>
      <code>{`
SELECT name, marks, (marks * 0.2) AS bonus 
FROM students 
ORDER BY bonus DESC;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names, marks, and calculated bonuses of students sorted by their 
      bonus in descending order.
    </p><br/>

    <h2>Sorting with NULL Values</h2>
    <p>
      By default, MySQL places <code>NULL</code> values first when sorting in ascending order and last when sorting in 
      descending order. This behavior can vary depending on the database engine.
    </p>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
ORDER BY age ASC;`}</code>
    </pre>
    <p>
      <strong>Note:</strong> You can use additional clauses or functions to customize the placement of <code>NULL</code> values.
    </p><br/>

    <h2>Conclusion</h2>
    <p>
      The <code>ORDER BY</code> clause is essential for organizing query results in a meaningful way. Whether sorting by a 
      single column, multiple columns, or calculated values, mastering <code>ORDER BY</code> is crucial for effective data 
      analysis and presentation.
    </p>
  </div>
)}




{selectedChapter === 'chapter32' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Limiting Results with LIMIT  </h1>

    
    <p>
      The <code>LIMIT</code> clause in MySQL is used to restrict the number of rows returned by a query. It is particularly useful 
      when working with large datasets where only a subset of data is needed.
    </p>

    <h2>Basic Syntax</h2>
    <pre>
      <code>{`
SELECT column1, column2, ...
FROM table_name
LIMIT number_of_rows;`}</code>
    </pre>
    <p>
      - <code>number_of_rows</code>: Specifies the maximum number of rows to return.
    </p>

    <h2>Example: Retrieve a Fixed Number of Rows</h2>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
LIMIT 5;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the first 5 rows from the <code>students</code> table.
    </p><br/>

    <h2>Using LIMIT with OFFSET</h2>
    <p>
      The <code>LIMIT</code> clause can be combined with an optional <code>OFFSET</code> to skip a specific number of rows before 
      returning the result.
    </p>

    <h3>Syntax</h3>
    <pre>
      <code>{`
SELECT column1, column2, ...
FROM table_name
LIMIT offset, number_of_rows;`}</code>
    </pre>
    <p>
      - <code>offset</code>: The number of rows to skip.<br />
      - <code>number_of_rows</code>: The number of rows to return after skipping the offset.
    </p>

    <h3>Example: Pagination</h3>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
LIMIT 10, 5;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query skips the first 10 rows and retrieves the next 5 rows from the 
      <code>students</code> table. This is useful for implementing pagination.
    </p><br/>

    <h2>Sorting Before Applying LIMIT</h2>
    <p>
      To get meaningful subsets of data, it is often useful to sort the results using <code>ORDER BY</code> before applying 
      <code>LIMIT</code>.
    </p>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
ORDER BY age DESC 
LIMIT 3;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the 3 oldest students by sorting the results in descending order of 
      age before limiting the rows.
    </p>

    <h2>Practical Use Cases</h2>
    <ul>
      <li>Top Results: Retrieve the top N records (e.g., top 10 scores).</li><br/>
      <li>Pagination: Break large datasets into smaller, manageable chunks.</li><br/>
      <li>Testing Queries: Quickly preview results without retrieving all rows.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      The <code>LIMIT</code> clause is a powerful tool for controlling the volume of data returned by a query. Whether you need 
      a few rows for testing or want to implement efficient pagination, mastering <code>LIMIT</code> is essential for working 
      with large datasets in MySQL.
    </p>
  </div>
)}

  


{selectedChapter === 'chapter33' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Using Logical Operators (AND, OR, NOT)  </h1>

    <p>
      Logical operators in MySQL—<code>AND</code>, <code>OR</code>, and <code>NOT</code>—are used to combine or modify 
      conditions in the <code>WHERE</code> clause. These operators help refine the result set by adding complexity to 
      queries, ensuring they meet multiple conditions or exclude certain data.
    </p><br/>

    <h2>Logical Operators Overview</h2>
    <ul>
      <li>
        <strong><code>AND</code></strong>: Combines two or more conditions; all conditions must evaluate to <code>TRUE</code> 
        for a row to be included.
      </li><br/>
      <li>
        <strong><code>OR</code></strong>: Combines two or more conditions; at least one condition must evaluate to 
        <code>TRUE</code> for a row to be included.
      </li><br/>
      <li>
        <strong><code>NOT</code></strong>: Negates a condition, including rows where the condition evaluates to 
        <code>FALSE</code>.
      </li>
    </ul><br/>

    <h2>Using <code>AND</code></h2>
    <p>
      The <code>AND</code> operator ensures that all specified conditions must be <code>TRUE</code> for a row to be included 
      in the result.
    </p>
    <pre>
      <code>{`
SELECT name, age, grade 
FROM students 
WHERE age > 18 AND grade = 'A';`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names, ages, and grades of students who are older than 18 
      <em>and</em> have a grade of 'A'.
    </p><br/>

    <h2>Using <code>OR</code></h2>
    <p>
      The <code>OR</code> operator includes rows that satisfy at least one of the specified conditions.
    </p>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
WHERE age < 18 OR grade = 'B';`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names and ages of students who are younger than 18 
      <em>or</em> have a grade of 'B'.
    </p><br/>

    <h2>Using <code>NOT</code></h2>
    <p>
      The <code>NOT</code> operator negates a condition, excluding rows where the condition evaluates to <code>TRUE</code>.
    </p>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
WHERE NOT grade = 'C';`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names and ages of students whose grade is <em>not</em> 'C'.
    </p><br/>

    <h2>Combining Logical Operators</h2>
    <p>
      Logical operators can be combined to create complex conditions. Use parentheses to ensure the correct order of 
      evaluation.
    </p>
    <pre>
      <code>{`
SELECT name, age 
FROM students 
WHERE (age > 18 AND grade = 'A') OR grade = 'B';`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names and ages of students who are older than 18 with a 
      grade of 'A' <em>or</em> have a grade of 'B'.
    </p><br/>

    <h2>Conclusion</h2>
    <p>
      Logical operators <code>AND</code>, <code>OR</code>, and <code>NOT</code> are powerful tools for refining query 
      results. Understanding how to combine these operators effectively can help you extract precise data from your 
      database.
    </p>
 
  </div>
)}



{selectedChapter === 'chapter34' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Eliminating Duplicates with DISTINCT  </h1>

    
    <p>
      In MySQL, the <code>DISTINCT</code> keyword is used to remove duplicate rows from the result set. This ensures that the 
      output contains only unique values for the specified column(s).
    </p><br/>

    <h2>Basic Syntax</h2>
    <pre>
      <code>{`SELECT DISTINCT column1, column2, ...
FROM table_name;`}</code>
    </pre>
    <p>
      - <code>column1, column2, ...</code>: The columns for which you want unique values.<br />
      - <code>table_name</code>: The table from which data will be fetched.
    </p>

    <h2>Example: Eliminating Duplicates</h2>
    <pre>
      <code>{`SELECT DISTINCT age 
FROM students;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves a list of unique ages from the <code>students</code> table.
    </p><br/>

    <h2>Using DISTINCT with Multiple Columns</h2>
    <p>
      The <code>DISTINCT</code> keyword can also be applied to multiple columns. In such cases, it considers combinations of 
      values across the specified columns for uniqueness.
    </p>

    <h3>Example</h3>
    <pre>
      <code>{`SELECT DISTINCT first_name, last_name 
FROM students;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves unique combinations of <code>first_name</code> and <code>last_name</code>.
    </p><br/>

    <h2>Sorting Results with DISTINCT</h2>
    <p>
      You can combine <code>DISTINCT</code> with <code>ORDER BY</code> to sort the results. The sorting is applied after 
      duplicates are eliminated.
    </p>

    <h3>Example</h3>
    <pre>
      <code>{`SELECT DISTINCT age 
FROM students 
ORDER BY age DESC;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves unique ages, sorted in descending order.
    </p><br/>

    <h2>Combining DISTINCT with Aggregate Functions</h2>
    <p>
      While <code>DISTINCT</code> is not typically used directly with aggregate functions, you can apply it inside functions like 
      <code>COUNT</code> to count unique values.
    </p>

    <h3>Example</h3>
    <pre>
      <code>{`SELECT COUNT(DISTINCT age) 
FROM students;`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query counts the number of unique ages in the <code>students</code> table.
    </p><br/>

    <h2>Practical Use Cases</h2>
    <ul>
      <li>**Data Deduplication**: Ensuring reports contain unique values.</li><br/>
      <li>**Analysis**: Identifying distinct categories, ages, or regions in a dataset.</li><br/>
      <li>**Optimization**: Reducing redundant data in large queries.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      The <code>DISTINCT</code> keyword is a simple yet powerful tool in MySQL for removing duplicates and ensuring unique 
      results in your queries. By combining it with other clauses like <code>ORDER BY</code> or functions like 
      <code>COUNT</code>, you can perform efficient and meaningful data analysis.
    </p>
  </div>
)}


 

{selectedChapter === 'chapter35' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to Joins  </h1>


    <p>
      In MySQL, a 'JOIN' is a SQL operation used to combine rows from two or more tables based on a related column. Joins are essential for working with relational databases, where data is often distributed across multiple tables. They enable you to retrieve meaningful information by linking data through relationships.
    </p><br/>

    <h2>Basic Types of Joins</h2>
    <p>
      MySQL supports several types of joins, each serving a specific purpose in data retrieval. Below is an overview of the most common joins:
    </p>

    <ol>
      <li>
        <strong>INNER JOIN</strong>: Returns only the rows with matching values in both tables.
      </li><br/>
      <li>
        <strong>LEFT JOIN (or LEFT OUTER JOIN)</strong>: Returns all rows from the left table and matching rows from the right table. If no match exists, <code>NULL</code> is returned for columns from the right table.
      </li><br/>
      <li>
        <strong>RIGHT JOIN (or RIGHT OUTER JOIN)</strong>: Returns all rows from the right table and matching rows from the left table. If no match exists, <code>NULL</code> is returned for columns from the left table.
      </li><br/>
      <li>
        <strong>FULL JOIN (or FULL OUTER JOIN)</strong>: Returns rows when there is a match in either table. (Note: MySQL does not natively support <code>FULL JOIN</code> but you can emulate it using a combination of <code>LEFT JOIN</code> and <code>UNION</code>.)
      </li><br/>
      <li>
        <strong>CROSS JOIN</strong>: Returns the Cartesian product of both tables (all possible combinations of rows).
      </li>
    </ol><br/>

    <h2>Syntax for Joins</h2>
    <p>
      The general syntax for a join query is:
    </p>
    <pre>
      <code>{`
SELECT column1, column2, ...
FROM table1
[JOIN_TYPE] JOIN table2
ON table1.common_column = table2.common_column;`} </code>
    </pre>
    <p>
      - <code>JOIN_TYPE</code>: The type of join (e.g., <code>INNER</code>, <code>LEFT</code>, <code>RIGHT</code>).<br />
      - <code>common_column</code>: The column in both tables that defines the relationship.
    </p>

    <h2>Example: INNER JOIN</h2>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
INNER JOIN enrollments ON students.student_id = enrollments.student_id
INNER JOIN courses ON enrollments.course_id = courses.course_id;`} </code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names of students and the courses they are enrolled in by linking the <code>students</code>, <code>enrollments</code>, and <code>courses</code> tables.
    </p><br/>

    <h2>Relationships in Tables</h2>
    <p>
      Relationships in relational databases define how tables are connected to each other. These are typically established using primary and foreign keys:
    </p>
    <ul>
      <li>
        One-to-One Relationship: A single row in Table A relates to a single row in Table B.
      </li><br/>
      <li>
        One-to-Many Relationship: A single row in Table A relates to multiple rows in Table B.
      </li><br/>
      <li>
        Many-to-Many Relationship: Multiple rows in Table A relate to multiple rows in Table B, typically implemented through a junction table.
      </li>
    </ul><br/>

    <h2>Visualizing Relationships</h2>
    <p>
      Imagine two tables: <code>employees</code> and <code>departments</code>.
    </p>
    <pre>
      <code>{`
        employees:
        +-------------+-----------+-------------+
        | employee_id | name      | department_id |
        +-------------+-----------+-------------+
        | 1           | Alice     | 101         |
        | 2           | Bob       | 102         |
        +-------------+-----------+-------------+

        departments:
        +-------------+-----------------+
        | department_id | department_name |
        +-------------+-----------------+
        | 101         | HR              |
        | 102         | IT              |
        +-------------+-----------------+
      `}</code>
    </pre>
    <p>
      A query using an <code>INNER JOIN</code> would look like this:
    </p>
    <pre>
      <code>{`
SELECT employees.name, departments.department_name
FROM employees
INNER JOIN departments ON employees.department_id = departments.department_id;`}</code>
    </pre>
    <p>
      This query returns a list of employee names and their corresponding department names.
    </p><br/>

    <h2>Conclusion</h2>
    <p>
      Joins are fundamental to working with relational databases, enabling you to combine and analyze data spread across multiple tables. Understanding different types of joins and relationships between tables is essential for effective database querying.
    </p>

  </div>
)}


{selectedChapter === 'chapter36' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> INNER JOIN  </h1>

    <p>
      The <strong>INNER JOIN</strong> in MySQL is one of the most commonly used types of joins. It retrieves records from two or more tables that have matching values in a related column. If no match is found, the rows are excluded from the result set.
    </p>
    <br/>
    <h2>Syntax for INNER JOIN</h2>
    <pre>
      <code>{`
SELECT column1, column2, ...
FROM table1
INNER JOIN table2
ON table1.common_column = table2.common_column;
`}</code>
    </pre>
    <p>
      - <code>table1</code> and <code>table2</code>: The tables you want to join.<br />
      - <code>common_column</code>: The column used to match rows between the two tables.
    </p>

    <h2>Example: INNER JOIN</h2>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
INNER JOIN enrollments ON students.student_id = enrollments.student_id
INNER JOIN courses ON enrollments.course_id = courses.course_id;
`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves the names of students and the courses they are enrolled in. It links the <code>students</code>, <code>enrollments</code>, and <code>courses</code> tables using their related columns.
    </p><br/>

    <h2>Practical Table Example</h2>
    <p>
      Consider the following tables:
    </p>

    <pre>
      <code>{`
        students:
        +-------------+-----------+
        | student_id  | name      |
        +-------------+-----------+
        | 1           | Alice     |
        | 2           | Bob       |
        | 3           | Charlie   |
        +-------------+-----------+

        enrollments:
        +-------------+-----------+
        | student_id  | course_id |
        +-------------+-----------+
        | 1           | 101       |
        | 2           | 102       |
        | 3           | 101       |
        +-------------+-----------+

        courses:
        +-----------+-------------+
        | course_id | course_name |
        +-----------+-------------+
        | 101       | Math        |
        | 102       | Science     |
        +-----------+-------------+
      `}</code>
    </pre>

    <p>
      Query:
    </p>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
INNER JOIN enrollments ON students.student_id = enrollments.student_id
INNER JOIN courses ON enrollments.course_id = courses.course_id;
`}</code>
    </pre>
    <p>
      Result:
    </p>
    <pre>
      <code>{`
        +-----------+-------------+
        | name      | course_name |
        +-----------+-------------+
        | Alice     | Math        |
        | Bob       | Science     |
        | Charlie   | Math        |
        +-----------+-------------+
      `}</code>
    </pre><br/>

    <h2>Key Points About INNER JOIN</h2>
    <ul>
      <li>
        Only rows with matching values in the specified columns are included in the result set.
      </li><br/>
      <li>
        If a row in one table does not have a corresponding match in the other table, it is excluded from the results.
      </li><br/>
      <li>
        You can join multiple tables by chaining multiple <code>INNER JOIN</code> clauses.
      </li>
    </ul><br/>

    <h2>Advantages of INNER JOIN</h2>
    <ul>
      <li>
        Efficiently retrieves matching data across related tables.
      </li><br/>
      <li>
        Ensures data integrity by excluding unmatched rows.
      </li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      The <code>INNER JOIN</code> is a powerful and versatile tool for combining data from multiple tables in a relational database. By mastering its syntax and functionality, you can effectively work with complex data relationships and retrieve meaningful insights.
    </p>

  </div>
)}





{selectedChapter === 'chapter37' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> LEFT JOIN   </h1>

    <p>
      The <strong>LEFT JOIN</strong> (also known as <strong>LEFT OUTER JOIN</strong>) is used in MySQL to retrieve all records from the left table (the first table listed in the query) and the matching records from the right table (the second table listed). If there is no match, NULL values are returned for columns from the right table.
    </p><br/>

    <h2>Syntax for LEFT JOIN</h2>
    <pre>
      <code>{`
SELECT column1, column2, ...
FROM table1
LEFT JOIN table2
ON table1.common_column = table2.common_column;
`}</code>
    </pre>
    <p>
      - <code>table1</code>: The left table from which all records are returned.<br />
      - <code>table2</code>: The right table from which matching records are retrieved.<br />
      - <code>common_column</code>: The column used to join the two tables.
    </p>

    <h2>Example: LEFT JOIN</h2>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
LEFT JOIN enrollments ON students.student_id = enrollments.student_id
LEFT JOIN courses ON enrollments.course_id = courses.course_id;
`} </code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves all student names and the courses they are enrolled in, including students who are not enrolled in any course. For students with no enrollments, the course name will be returned as NULL.
    </p><br/>

    <h2>Practical Table Example</h2>
    <p>
      Consider the following tables:
    </p>

    <pre>
      <code>{`
        students:
        +-------------+-----------+
        | student_id  | name      |
        +-------------+-----------+
        | 1           | Alice     |
        | 2           | Bob       |
        | 3           | Charlie   |
        +-------------+-----------+

        enrollments:
        +-------------+-----------+
        | student_id  | course_id |
        +-------------+-----------+
        | 1           | 101       |
        | 2           | 102       |
        +-------------+-----------+

        courses:
        +-----------+-------------+
        | course_id | course_name |
        +-----------+-------------+
        | 101       | Math        |
        | 102       | Science     |
        +-----------+-------------+
      `}</code>
    </pre>

    <p>
      Query:
    </p>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
LEFT JOIN enrollments ON students.student_id = enrollments.student_id
LEFT JOIN courses ON enrollments.course_id = courses.course_id;
`}</code>
    </pre>
    <p>
      Result:
    </p>
    <pre>
      <code>{`
        +-----------+-------------+
        | name      | course_name |
        +-----------+-------------+
        | Alice     | Math        |
        | Bob       | Science     |
        | Charlie   | NULL        |
        +-----------+-------------+
      `}</code>
    </pre>
    <p>
      In this result, notice that Charlie has no course enrollment, so the <code>course_name</code> is NULL for this student.
    </p><br/>

    <h2>Key Points About LEFT JOIN</h2>
    <ul>
      <li>
        Retrieves all rows from the left table, even if there is no match in the right table.
      </li><br/>
      <li>
        For unmatched rows, the result will show NULL values for the right table's columns.
      </li><br/>
      <li>
        Useful when you want to include all rows from the left table and find related data from the right table, even if no match exists.
      </li>
    </ul><br/>

    <h2>Advantages of LEFT JOIN</h2>
    <ul>
      <li>
        Ensures that no records from the left table are excluded, even if there is no corresponding match in the right table.
      </li><br/>
      <li>
        Useful for finding "missing" or unmatched data in the right table.
      </li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      The <code>LEFT JOIN</code> is essential when you want to return all rows from the left table and include related data from the right table, even if the relationship is not fully populated. It is particularly useful when dealing with optional relationships or when you need to find records that do not have corresponding matches in another table.
    </p>


  </div>
)}


{selectedChapter === 'chapter38' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   RIGHT JOIN    </h1>

   
    <p>
      The <strong>RIGHT JOIN</strong> (also known as <strong>RIGHT OUTER JOIN</strong>) in MySQL is used to retrieve all records from the right table (the second table listed in the query) and the matching records from the left table (the first table listed). If there is no match, NULL values are returned for columns from the left table.
    </p><br/>

    <h2>Syntax for RIGHT JOIN</h2>
    <pre>
      <code>{`
SELECT column1, column2, ...
FROM table1
RIGHT JOIN table2
ON table1.common_column = table2.common_column;
`}</code>
    </pre>
    <p>
      - <code>table1</code>: The left table from which matching records are retrieved.<br />
      - <code>table2</code>: The right table from which all records are returned.<br />
      - <code>common_column</code>: The column used to join the two tables.
    </p>

    <h2>Example: RIGHT JOIN</h2>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
RIGHT JOIN enrollments ON students.student_id = enrollments.student_id
RIGHT JOIN courses ON enrollments.course_id = courses.course_id;
`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves all course names and the names of the students who are enrolled in them, including courses that may not have any students enrolled. For courses with no enrolled students, the student name will be returned as NULL.
    </p><br/>

    <h2>Practical Table Example</h2>
    <p>
      Consider the following tables:
    </p>

    <pre>
      <code>{`
        students:
        +-------------+-----------+
        | student_id  | name      |
        +-------------+-----------+
        | 1           | Alice     |
        | 2           | Bob       |
        | 3           | Charlie   |
        +-------------+-----------+

        enrollments:
        +-------------+-----------+
        | student_id  | course_id |
        +-------------+-----------+
        | 1           | 101       |
        | 2           | 102       |
        +-------------+-----------+

        courses:
        +-----------+-------------+
        | course_id | course_name |
        +-----------+-------------+
        | 101       | Math        |
        | 102       | Science     |
        | 103       | History     |
        +-----------+-------------+
      `}</code>
    </pre>

    <p>
      Query:
    </p>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
RIGHT JOIN enrollments ON students.student_id = enrollments.student_id
RIGHT JOIN courses ON enrollments.course_id = courses.course_id;
`}</code>
    </pre>
    <p>
      Result:
    </p>
    <pre>
      <code>{`
        +-----------+-------------+
        | name      | course_name |
        +-----------+-------------+
        | Alice     | Math        |
        | Bob       | Science     |
        | NULL      | History     |
        +-----------+-------------+
      `}</code>
    </pre>
    <p>
      In this result, notice that "History" is a course with no enrolled students, so the <code>name</code> for that course is NULL.
    </p><br/>

    <h2>Key Points About RIGHT JOIN</h2>
    <ul>
      <li>
        Retrieves all rows from the right table, even if there is no match in the left table.
      </li><br/>
      <li>
        For unmatched rows, the result will show NULL values for the left table's columns.
      </li><br/>
      <li>
        Useful when you want to include all rows from the right table and find related data from the left table, even if no match exists.
      </li>
    </ul><br/>

    <h2>Advantages of RIGHT JOIN</h2>
    <ul>
      <li>
        Ensures that no records from the right table are excluded, even if there is no corresponding match in the left table.
      </li><br/>
      <li>
        Useful for finding "missing" or unmatched data in the left table.
      </li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      The <code>RIGHT JOIN</code> is an essential tool in SQL when you need to retrieve all rows from the right table and include matching data from the left table, even if some records from the left table do not have a corresponding match. This join is often used to ensure that no records from the right table are excluded.
    </p>


  </div>
)}





{selectedChapter === 'chapter39' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> FULL OUTER JOIN   </h1>
  
    <p>
      MySQL does not have a built-in <strong>FULL OUTER JOIN</strong> operation. However, you can simulate it by using a combination of <strong>LEFT JOIN</strong> and <strong>RIGHT JOIN</strong> along with the <strong>UNION</strong> operator. This approach allows you to retrieve all rows from both tables, including unmatched rows.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Simulating FULL OUTER JOIN</h2>
    <p>
      A <strong>FULL OUTER JOIN</strong> returns all records from both the left and right tables. If there is no match, the result will contain <strong>NULL</strong> for the columns of the table with no match. The simulation combines <strong>LEFT JOIN</strong> and <strong>RIGHT JOIN</strong> with a <strong>UNION</strong> to include all records from both tables.
    </p><br/>

    <h3>Syntax for Simulating FULL OUTER JOIN</h3>
    <pre>
      <code>{`
(SELECT column1, column2, ...
FROM table1
LEFT JOIN table2 ON table1.common_column = table2.common_column)
UNION
(SELECT column1, column2, ...
FROM table1
RIGHT JOIN table2 ON table1.common_column = table2.common_column);
`}</code>
    </pre>
    <p>
      - The first query uses a <strong>LEFT JOIN</strong> to get all rows from the left table and matching rows from the right table.<br /><br />
      - The second query uses a <strong>RIGHT JOIN</strong> to get all rows from the right table and matching rows from the left table.<br /><br />
      - The <strong>UNION</strong> operator combines the results of both queries and eliminates duplicates.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Example: Simulating FULL OUTER JOIN</h3>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
LEFT JOIN enrollments ON students.student_id = enrollments.student_id
LEFT JOIN courses ON enrollments.course_id = courses.course_id
UNION
SELECT students.name, courses.course_name
FROM students
RIGHT JOIN enrollments ON students.student_id = enrollments.student_id
RIGHT JOIN courses ON enrollments.course_id = courses.course_id;
      `}</code>
    </pre>
    <p>
      <strong>Explanation:</strong> This query retrieves all student names and their corresponding course names. It includes students who are not enrolled in any courses and courses that have no students enrolled.
    </p><br/>

    <h3>Practical Table Example</h3>
    <p>
      Consider the following tables:
    </p>

    <pre>
      <code>{`
        students:
        +-------------+-----------+
        | student_id  | name      |
        +-------------+-----------+
        | 1           | Alice     |
        | 2           | Bob       |
        | 3           | Charlie   |
        | 4           | Dave      |
        +-------------+-----------+

        enrollments:
        +-------------+-----------+
        | student_id  | course_id |
        +-------------+-----------+
        | 1           | 101       |
        | 2           | 102       |
        +-------------+-----------+

        courses:
        +-----------+-------------+
        | course_id | course_name |
        +-----------+-------------+
        | 101       | Math        |
        | 102       | Science     |
        | 103       | History     |
        +-----------+-------------+
      `}</code>
    </pre>

    <p>
      Query:
    </p>
    <pre>
      <code>{`
SELECT students.name, courses.course_name
FROM students
LEFT JOIN enrollments ON students.student_id = enrollments.student_id
LEFT JOIN courses ON enrollments.course_id = courses.course_id
UNION
SELECT students.name, courses.course_name
FROM students
RIGHT JOIN enrollments ON students.student_id = enrollments.student_id
RIGHT JOIN courses ON enrollments.course_id = courses.course_id;
      `}</code>
    </pre>

    <p>
      Result:
    </p>
    <pre>
      <code>{`
        +-----------+-------------+
        | name      | course_name |
        +-----------+-------------+
        | Alice     | Math        |
        | Bob       | Science     |
        | Charlie   | NULL        |
        | Dave      | NULL        |
        | NULL      | History     |
        +-----------+-------------+
      `}</code>
    </pre>

    <p>
      In this result:
      <ul>
        <li>"Alice" and "Bob" are enrolled in "Math" and "Science" respectively,</li><br />
        <li>"Charlie" and "Dave" are not enrolled in any course,</li><br />
        <li>"History" is a course with no enrolled students, so the student name is NULL.</li>
      </ul>
    </p><br/>

    <h2>Key Points About FULL OUTER JOIN (Simulated)</h2>
    <ul>
      <li>Simulates a FULL OUTER JOIN by combining <strong>LEFT JOIN</strong> and <strong>RIGHT JOIN</strong> with <strong>UNION</strong>.</li><br/>
      <li>Includes all rows from both tables, even if there is no match between them.</li><br/>
      <li>NULL values are returned for columns from the table with no matching rows.</li>
    </ul><br/>

    <h2>Advantages of Using FULL OUTER JOIN</h2>
    <ul>
      <li>Ensures that all records from both tables are included in the result, even when there are no matching records.</li><br/>
      <li>Useful when you need to find all unmatched records from both tables.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      While MySQL does not natively support <strong>FULL OUTER JOIN</strong>, you can simulate it by combining a <strong>LEFT JOIN</strong> and a <strong>RIGHT JOIN</strong> with the <strong>UNION</strong> operator. This method ensures that all records from both tables are included, and NULL values are returned for columns where there is no match.
    </p>

  </div>
)}



{selectedChapter === 'chapter40' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  CROSS JOIN   </h1>

    <p>
      A <strong>CROSS JOIN</strong> in MySQL returns the Cartesian product of two tables. It combines every row from the first table with every row from the second table, resulting in all possible combinations of rows from both tables.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Syntax of CROSS JOIN</h2>
    <pre>
      <code>{`
SELECT column1, column2, ...
FROM table1
CROSS JOIN table2;
`}</code>
    </pre>
    <p>
      - `table1`: The first table that you want to combine.<br />
      - `table2`: The second table that you want to combine.<br />
      The result will include every combination of rows from these two tables.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Example of CROSS JOIN</h3>
    <pre>
      <code>{`
SELECT name, course_name
FROM students
CROSS JOIN courses;
`}</code>
    </pre>
    <p>
      This query will return all possible combinations of student names and course names. Each student will be paired with every course in the courses table.
    </p><br/>

    <h3>Practical Table Example</h3>
    <p>Consider the following sample tables:</p>

    <pre>
      <code>{`
        students:
        +-------------+-----------+
        | student_id  | name      |
        +-------------+-----------+
        | 1           | Alice     |
        | 2           | Bob       |
        | 3           | Charlie   |
        +-------------+-----------+

        courses:
        +-----------+-------------+
        | course_id | course_name |
        +-----------+-------------+
        | 101       | Math        |
        | 102       | Science     |
        +-----------+-------------+
      `}</code>
    </pre><br/>

    <h3>Result of the CROSS JOIN</h3>
    <pre>
      <code>{`
SELECT name, course_name
FROM students
CROSS JOIN courses;
`}</code>
    </pre>

    <p>The result of this query would be:</p>
    <pre>
      <code>{`
        +-----------+-------------+
        | name      | course_name |
        +-----------+-------------+
        | Alice     | Math        |
        | Alice     | Science     |
        | Bob       | Math        |
        | Bob       | Science     |
        | Charlie   | Math        |
        | Charlie   | Science     |
        +-----------+-------------+
      `}</code>
    </pre>
    <p>
      In this example, the CROSS JOIN results in 6 rows (3 students * 2 courses), showing all combinations of students and courses.
    </p><br/>

    <h2>Characteristics of CROSS JOIN</h2>
    <ul>
      <li>It returns all possible combinations of rows from both tables.</li><br/>
      <li>There is no condition to specify the join (no ON or WHERE clause).</li><br/>
      <li>It can generate large result sets, especially when the tables involved are large.</li>
    </ul><br/>

    <h2>Use Cases of CROSS JOIN</h2>
    <ul>
      <li>When you need to generate a combination of all possible values from two or more tables. For example, creating a report that lists all possible combinations of products and colors.</li><br/>
      <li>Simulating combinations of items, like matching customers to all available product options.</li>
    </ul><br/>

    <h2>Important Notes</h2>
    <ul>
      <li>CROSS JOINs can be computationally expensive for large tables since they generate a Cartesian product, which can be very large.</li><br/>
      <li>It is generally not used as often as other types of joins because of the size of the result set.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      A <strong>CROSS JOIN</strong> in MySQL creates a Cartesian product between two tables, combining each row from the first table with each row from the second table. While useful in specific cases, be cautious when using it with large tables, as it can lead to very large result sets.
    </p>


  </div>
)}


{selectedChapter === 'chapter41' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Self Join   </h1>

    <p>
      A <strong>Self Join</strong> is a join where a table is joined with itself. This is commonly used to compare rows within the same table or to find relationships between rows that share a certain attribute, such as employees and their managers.
    </p><br/>

    <h2>Syntax of Self Join</h2>
    <pre>
      <code>{`
SELECT A.column1, B.column2
FROM table_name A
JOIN table_name B ON A.common_column = B.common_column;
`}</code>
    </pre>
    <p>
      - `A` and `B`: These are aliases for the same table.<br /><br />
      - `common_column`: This is the column used to compare the rows in the self join.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Example of Self Join</h3>
    <p>Consider a table of employees where each employee has a manager. To find the employees along with their managers, we can use a self join on the `employees` table.</p>

    <pre>
      <code>{`
        employees:
        +-------------+------------+-----------+
        | employee_id | name       | manager_id|
        +-------------+------------+-----------+
        | 1           | Alice      | NULL      |
        | 2           | Bob        | 1         |
        | 3           | Charlie    | 1         |
        | 4           | David      | 2         |
        +-------------+------------+-----------+
      `}</code>
    </pre><br/>

    <h3>Self Join Query</h3>
    <pre>
      <code>{`
SELECT A.name AS Employee, B.name AS Manager
FROM employees A
LEFT JOIN employees B ON A.manager_id = B.employee_id;`}
      </code>
    </pre>

    <h3>Result of the Self Join</h3>
    <pre>
      <code>{`
        +-----------+-----------+
        | Employee  | Manager  |
        +-----------+-----------+
        | Alice     | NULL      |
        | Bob       | Alice     |
        | Charlie   | Alice     |
        | David     | Bob       |
        +-----------+-----------+
      `}</code>
    </pre>
    <p>
      In this example, the employees are paired with their managers using a self join on the `employees` table. Notice that Alice, the manager of Bob and Charlie, does not have a manager (NULL), and David's manager is Bob.
    </p><br/>

    <h2>Use Cases of Self Join</h2>
    <ul>
      <li>To retrieve hierarchical data, such as employees and their managers, or parts and sub-parts in a product assembly.</li><br/>
      <li>When you need to compare rows within the same table based on a specific condition, like comparing sales for different regions in the same table.</li>
    </ul><br/>

    <h2>Important Notes</h2>
    <ul>
      <li>A self join can be an expensive operation when the table has a large number of rows, as it essentially joins the table to itself.</li><br/>
      <li>Using aliases (e.g., `A` and `B`) is essential to distinguish between the two instances of the same table in the self join.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      A <strong>Self Join</strong> in MySQL is a powerful technique for querying hierarchical relationships or comparing rows within the same table. By using aliases and appropriate join conditions, you can retrieve useful data relationships even when the data resides in a single table.
    </p>


  </div>
)}



{selectedChapter === 'chapter42' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Subqueries   </h1>

      <p>
        In MySQL, a <strong>subquery</strong> is a query that is nested inside another query. Subqueries are useful when you need to perform an operation that requires a result from a separate query before proceeding with the main query. Subqueries allow you to break complex queries into simpler parts, making them easier to understand and manage.
      </p>

      <p>Subqueries can be used in various SQL clauses such as <code>SELECT</code>, <code>FROM</code>, <code>WHERE</code>, and <code>HAVING</code>. They can return a single value, a list of values, or even an entire table of results.</p>
      <br/>
      <h3>Key Concepts of Subqueries:</h3>
      <ul>
        <li><strong>Inner Query (Subquery)</strong>: The query inside the parentheses.</li><br/>
        <li><strong>Outer Query</strong>: The main query that contains the subquery.</li>
      </ul><br/>

      <h3>Types of Subqueries in MySQL:</h3>
      <p>There are three primary types of subqueries in MySQL:</p>
      <ol>
        <li><strong>Single-row Subquery</strong>: Returns a single value (one row and one column).</li><br/>
        <li><strong>Multi-row Subquery</strong>: Returns multiple rows.</li><br/>
        <li><strong>Multi-column Subquery</strong>: Returns multiple columns and multiple rows.</li>
      </ol><br/>
      <p>Subqueries can also be classified based on their usage:</p>
      <ul>
        <li><strong>Scalar Subqueries</strong>: Return a single value (often used in <code>SELECT</code>, <code>WHERE</code>, or <code>HAVING</code> clauses).</li><br/>
        <li><strong>Correlated Subqueries</strong>: Reference a column from the outer query, making them dependent on the outer query.</li><br/>
        <li><strong>Non-Correlated Subqueries</strong>: Do not reference columns from the outer query.</li>
      </ul><br/>

      <h3>Syntax of a Subquery:</h3>
      <pre>
        <code>
          {`SELECT column1, column2, ... FROM table_name WHERE column1 = (SELECT column1 FROM table_name WHERE condition);`}
        </code>
      </pre><br/>

      <h3>Example of a Subquery in the WHERE Clause:</h3>
      <p>Suppose you have two tables: <code>employees</code> and <code>departments</code>. You want to find the names of employees who work in the "Sales" department.</p>
      <pre>
        <code>
          {`SELECT name 
FROM employees 
WHERE department_id = (SELECT department_id FROM departments WHERE department_name = 'Sales');`}
        </code>
      </pre>
      <p>
        The inner query <code>(SELECT department_id FROM departments WHERE department_name = 'Sales')</code> retrieves the <code>department_id</code> for the "Sales" department. The outer query selects employees whose <code>department_id</code> matches the result of the inner query.
      </p><br/>

      <h3>Single-row Subquery:</h3>
      <p>A single-row subquery returns a single value (one row and one column). This type of subquery is often used with comparison operators such as <code>=</code>, <code>&lt;</code>, <code>&gt;</code>, <code>&gt;=</code>, etc.</p>
      <pre>
        <code>
          {`SELECT name 
FROM employees 
WHERE salary = (SELECT MAX(salary) FROM employees);`}
        </code>
      </pre><br/>

      <h3>Multi-row Subquery:</h3>
      <p>A multi-row subquery returns multiple rows. When used in the <code>WHERE</code> clause, it is typically paired with the <code>IN</code> operator.</p>
      <pre>
        <code>
          {`SELECT name 
FROM employees 
WHERE department_id IN (SELECT department_id FROM departments WHERE location = 'New York');`}
        </code>
      </pre><br/>

      <h3>Multi-column Subquery:</h3>
      <p>A multi-column subquery returns more than one column. This is typically used when comparing multiple columns from the outer query with the columns returned by the subquery.</p>
      <pre>
        <code>
          {`SELECT name 
FROM employees 
WHERE (department_id, job_title) = (SELECT department_id, job_title FROM employees WHERE name = 'Alice');`}
        </code>
      </pre><br/>

      <h3>Correlated Subquery:</h3>
      <p>A <strong>correlated subquery</strong> references a column from the outer query. Unlike a non-correlated subquery (which is independent of the outer query), a correlated subquery is evaluated once for each row processed by the outer query.</p>
      <pre>
        <code>
          {`SELECT name 
FROM employees e
WHERE salary > (SELECT AVG(salary) 
                FROM employees 
                WHERE department_id = e.department_id);`}
        </code>
      </pre><br/>

      <h3>Using Subqueries in the SELECT Clause:</h3>
      <p>Subqueries can also be used in the <code>SELECT</code> clause to retrieve additional computed data.</p>
      <pre>
        <code>
          {`SELECT name, 
       (SELECT COUNT(*) FROM projects WHERE employee_id = e.employee_id) AS project_count
FROM employees e;`}
        </code>
      </pre><br/>

      <h3>Performance Considerations:</h3>
      <ul>
        <li>Subqueries can be inefficient if they are not well optimized, especially correlated subqueries which are executed for each row of the outer query.</li><br/>
        <li>Using <strong>JOINs</strong> instead of subqueries can sometimes be more efficient, as it allows MySQL to process the data in a more optimized way.</li><br/>
        <li>In some cases, subqueries can be rewritten as <strong>JOIN</strong> operations, which may provide better performance.</li>
      </ul><br/>

      <h3>Conclusion:</h3>
      <p>Subqueries are a powerful tool in MySQL for breaking down complex queries into simpler, more manageable parts. They allow you to retrieve results from one query to use in another, making it easier to write sophisticated queries. However, it's important to be mindful of performance when using subqueries, especially correlated subqueries, which can be less efficient. Understanding when and how to use subqueries effectively can greatly improve your ability to query and manipulate data in MySQL.</p>
    </div>
  )}




{selectedChapter === 'chapter43' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Single-row Subqueries </h1>

    <p>
      A <strong>single-row subquery</strong> in MySQL returns a single value — specifically, one row with one column. These subqueries are often used in the <code>WHERE</code> clause with comparison operators like <code>=</code>, <code>&lt;</code>, <code>&gt;</code>, <code>&lt;=</code>, <code>&gt;=</code>, or <code>&lt;&gt;</code> to match a condition.
    </p>
    <p>
      When a subquery is used in this way, it allows the outer query to filter rows based on a single value or expression returned by the subquery. Single-row subqueries are useful when you need to compare the results of a query to a single value, such as finding the maximum, minimum, or average value in a set of data.
    </p><br/>

    <h3>Syntax of Single-row Subquery</h3>
    <pre>
      <code>
        {`SELECT column1, column2, ...
FROM table_name
WHERE column1 = (SELECT column1 FROM table_name WHERE condition);`}
      </code>
    </pre>
    <p>
      Here, the subquery <code>(SELECT column1 FROM table_name WHERE condition)</code> returns a single value, which is then compared to <code>column1</code> in the outer query.
    </p><br/>

    <h3>Example 1: Finding the Employee with the Highest Salary</h3>
    <p>
      Suppose you want to find the name of the employee who has the highest salary. You can use a single-row subquery to retrieve the maximum salary, and then compare that value with the salary of each employee.
    </p>
    <pre>
      <code>
        {`SELECT name 
FROM employees 
WHERE salary = (SELECT MAX(salary) FROM employees);`}
      </code>
    </pre>
    <p>
      - The inner query <code>(SELECT MAX(salary) FROM employees)</code> finds the highest salary in the <code>employees</code> table.
    </p>
    <p>
      - The outer query <code>SELECT name FROM employees WHERE salary =</code> compares each employee's salary to the maximum salary and returns the name of the employee who has that salary.
    </p>
    <p>
      This type of subquery is often paired with comparison operators (<code>=</code>, <code>&lt;</code>, <code>&gt;</code>, etc.) to filter results based on a single value.
    </p><br/>

    <h3>Example 2: Finding an Employee Earning More Than the Average Salary</h3>
    <p>
      You can use a single-row subquery to find employees who earn more than the average salary.
    </p>
    <pre>
      <code>
        {`SELECT name
FROM employees
WHERE salary > (SELECT AVG(salary) FROM employees);`}
      </code>
    </pre>
    <p>
      - The inner query <code>(SELECT AVG(salary) FROM employees)</code> calculates the average salary of all employees.
    </p>
    <p>
      - The outer query then selects the names of employees whose salary is greater than the average salary.
    </p><br/>

    <h3>Limitations of Single-row Subqueries</h3>
    <ul>
      <li><strong>Only one result</strong>: A single-row subquery can only return one row and one column. If the subquery returns more than one row, MySQL will throw an error.</li><br/>
      <li><strong>Comparison with scalar values</strong>: This type of subquery is useful for comparisons with scalar values, such as a single number or string, but not for comparing multiple values.</li>
    </ul><br/>

    <h3>Conclusion</h3>
    <p>
      Single-row subqueries are a powerful tool in MySQL for performing comparisons based on single values, such as finding the maximum, minimum, or average of a set of data. They are commonly used in <code>WHERE</code> clauses to filter results from the outer query based on the result of the inner subquery. Understanding how to use these subqueries effectively will help you handle complex queries and refine your database queries.
    </p>
  </div>
)}




{selectedChapter === "chapter44" && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Multi-row Subqueries</h1>

    <p>
      <strong>Multi-row subqueries</strong> in MySQL are subqueries that return{" "}
      <strong>multiple rows</strong> of data. These are useful when you need to
      compare a value or a column in the main query with multiple values
      returned by a subquery. Multi-row subqueries are often used in{" "}
      <code>WHERE</code> or <code>HAVING</code> clauses to filter data based on
      a set of results.
    </p><br/> 

    <h3>Key Operators for Multi-row Subqueries</h3>
    <p>The most common operators used with multi-row subqueries are:</p>
    <ol>
      <li>
        <strong>IN</strong>: Matches if a value exists in the subquery result.
      </li><br/>
      <li>
        <strong>ANY</strong> or <strong>SOME</strong>: Checks if a condition is
        true for <strong>any</strong> value in the subquery result.
      </li><br/>
      <li>
        <strong>ALL</strong>: Checks if a condition is true for{" "}
        <strong>all</strong> values in the subquery result.
      </li>
    </ol><br/>

    <h3>Syntax</h3>
    <pre>
      <code>{`SELECT column1, column2
FROM table1
WHERE column1 [operator] (SELECT column
                          FROM table2
                          WHERE condition);`}</code>
    </pre><br/>

    <h3>Examples</h3>

    <h4>1. Using <code>IN</code></h4>
    <p>
      The <code>IN</code> operator is used to check if a value matches any value
      in the subquery result.
    </p>
    <pre>
      <code>{`-- Find employees who work in departments located in 'New York'
SELECT employee_id, name
FROM employees
WHERE department_id IN (
    SELECT department_id
    FROM departments
    WHERE location = 'New York'
);`}</code>
    </pre>
    <ul>
      <li>
        <strong>Subquery</strong>: Fetches <code>department_id</code> for
        departments located in New York.
      </li><br/>
      <li>
        <strong>Main Query</strong>: Retrieves employees whose{" "}
        <code>department_id</code> is in the list returned by the subquery.
      </li>
    </ul><br/>

    <h4>2. Using <code>ANY</code> or <code>SOME</code></h4>
    <p>
      The <code>ANY</code> operator evaluates whether a condition is true for{" "}
      <strong>any</strong> value in the subquery result set.
    </p>
    <pre>
      <code>{`-- Find employees earning more than the lowest salary in the 'Sales' department
SELECT employee_id, name, salary
FROM employees
WHERE salary > ANY (
    SELECT salary
    FROM employees
    WHERE department_id = (
        SELECT department_id
        FROM departments
        WHERE department_name = 'Sales'
    )
);`}</code>
    </pre><br/>

    <h4>3. Using <code>ALL</code></h4>
    <p>
      The <code>ALL</code> operator checks if a condition is true for{" "}
      <strong>all</strong> values in the subquery result.
    </p>
    <pre>
      <code>{`-- Find employees earning more than the highest salary in the 'Marketing' department
SELECT employee_id, name, salary
FROM employees
WHERE salary > ALL (
    SELECT salary
    FROM employees
    WHERE department_id = (
        SELECT department_id
        FROM departments
        WHERE department_name = 'Marketing'
    )
);`}</code>
    </pre><br/>

    <h3>Differences Between Single-row and Multi-row Subqueries</h3>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Single-row Subquery</th>
          <th>Multi-row Subquery</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Returns</td>
          <td>A single value (one row, one column)</td>
          <td>Multiple rows (one column)</td>
        </tr>
        <tr>
          <td>Operators Used</td>
          <td>= , &lt;, &gt;, &lt;=, &gt;=</td>
          <td>IN, ANY, ALL</td>
        </tr>
        <tr>
          <td>Common Use</td>
          <td>Compare with a single value</td>
          <td>Compare with a list of values</td>
        </tr>
      </tbody>
    </table><br/>

    <h3>Performance Considerations</h3>
    <ul>
      <li>
        <strong>Indexing</strong>: Ensure the columns used in the subquery are
        indexed for faster execution.
      </li><br/>
      <li>
        <strong>Joins vs. Subqueries</strong>: In some cases, <strong>JOINs</strong>{" "}
        are more efficient than subqueries, especially for large datasets.
      </li><br/>
      <li>
        <strong>Execution Cost</strong>: Subqueries may need to execute multiple
        times (once per row in the main query), potentially impacting
        performance.
      </li>
    </ul><br/>

    <h3>Best Practices</h3>
    <ul>
      <li>
        Use <code>IN</code> for simple comparisons with a list of values.
      </li><br/>
      <li>
        Use <code>ANY</code> or <code>ALL</code> for more advanced comparisons
        involving multiple conditions.
      </li><br/>
      <li>
        Avoid subqueries in the <code>SELECT</code> clause for
        performance-critical queries; consider using <strong>Common Table
        Expressions (CTEs)</strong> or <strong>JOINs</strong> instead.
      </li>
    </ul><br/>

    <h3>Summary</h3>
    <p>
      Multi-row subqueries allow you to compare a column or value in the main
      query with multiple rows returned by a subquery. Operators like{" "}
      <code>IN</code>, <code>ANY</code>, and <code>ALL</code> make it easy to
      perform flexible and powerful data comparisons. However, for complex
      scenarios, alternative approaches like <strong>JOINs</strong> or{" "}
      <strong>CTEs</strong> might be more efficient.
    </p>
  </div>
)}




{selectedChapter === 'chapter45' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Correlated Subqueries </h1>

    <h3><strong>Correlated Subqueries in MySQL</strong></h3>

    <p>
      A <strong>correlated subquery</strong> is a subquery that depends on the outer query for its value. Unlike a non-correlated subquery, which is independent and can be executed on its own, a correlated subquery references columns from the outer query. This means that for each row processed by the outer query, the correlated subquery is executed once, and its result can change depending on the values of the current row.
    </p><br/>

    <h4><strong>Key Characteristics of Correlated Subqueries:</strong></h4>
    <ul>
      <li><strong>Dependency on Outer Query</strong>: A correlated subquery references one or more columns from the outer query. This makes the subquery dependent on the outer query.</li><br/>
      <li><strong>Reevaluation for Each Row</strong>: The subquery is evaluated for each row processed by the outer query, which can lead to performance issues if the dataset is large.</li><br/>
      <li><strong>Execution Flow</strong>: For each row in the outer query, MySQL will execute the correlated subquery to obtain a result, making it slower than non-correlated subqueries.</li>
    </ul><br/>

    <h4><strong>Syntax of a Correlated Subquery:</strong></h4>
    <pre>
      <code>
        {`SELECT column1, column2, ... 
FROM table_name AS outer_table
WHERE column1 operator (SELECT column1 
                        FROM table_name AS inner_table 
                        WHERE inner_table.column_name = outer_table.column_name);`}
      </code>
    </pre>

    <p>
      - <strong>outer_table.column_name</strong>: Refers to a column from the outer query.<br />
      - <strong>inner_table.column_name</strong>: Refers to a column from the subquery, which is compared with the value from the outer query.
    </p><br/>

    <h4><strong>Example of a Correlated Subquery:</strong></h4>
    <p>Let’s say you want to find employees who earn more than the average salary of their respective department. In this case, the subquery is correlated because it uses the <strong>department_id</strong> from the outer query to calculate the average salary for each department.</p>

    <pre>
      <code>
        {`SELECT name, salary
FROM employees e
WHERE salary > (SELECT AVG(salary)
                FROM employees
                WHERE department_id = e.department_id);`}
      </code>
    </pre>

    <p>
      - The inner query <code>(SELECT AVG(salary) FROM employees WHERE department_id = e.department_id)</code> calculates the average salary for the department of the current row being processed in the outer query.<br />
      - The outer query then retrieves the names and salaries of employees who earn more than the average salary of their respective department.
    </p>

    <h4><strong>Explanation:</strong></h4>
    <p>
      For each row in the <code>employees</code> table, the outer query checks if the salary is greater than the average salary of the department to which the employee belongs.<br />
      The subquery is reevaluated for each row because the <code>department_id</code> of the current row (<code>e.department_id</code>) is used to calculate the average salary.
    </p><br/>

    <h4><strong>Performance Considerations:</strong></h4>
    <ul>
      <li><strong>Inefficiency</strong>: Since the correlated subquery is executed for each row in the outer query, it can become inefficient, especially for large datasets.</li><br/>
      <li><strong>Optimization</strong>: Sometimes, you can rewrite a correlated subquery as a <strong>JOIN</strong> operation to improve performance. For example, the above query can be rewritten using a <strong>JOIN</strong> with aggregation:</li>
    </ul><br/>

    <pre>
      <code>
        {`SELECT e.name, e.salary
FROM employees e
JOIN (
    SELECT department_id, AVG(salary) AS avg_salary
    FROM employees
    GROUP BY department_id
) AS avg_salaries ON e.department_id = avg_salaries.department_id
WHERE e.salary > avg_salaries.avg_salary;`}
      </code>
    </pre><br/>

    <h4><strong>Use Cases for Correlated Subqueries:</strong></h4>
    <ul>
      <li><strong>Finding Records Based on Related Values</strong>: Correlated subqueries are useful when you need to filter records based on values calculated in relation to each row of the outer query, like comparing individual records against group statistics (e.g., average, maximum).</li><br/>
      <li><strong>Row-by-Row Comparisons</strong>: When you need to perform row-by-row comparisons, a correlated subquery can be used effectively.</li>
    </ul><br/>

    <h4><strong>Conclusion:</strong></h4>
    <p>
      A correlated subquery is a powerful tool in MySQL for performing complex row-by-row comparisons where the result of the subquery depends on the current row of the outer query. While they are useful, they can be inefficient on large datasets because the subquery is re-executed for each row of the outer query. In such cases, consider using <strong>JOIN</strong> operations or other optimizations to improve performance.
    </p>

  </div>
)}




{selectedChapter === 'chapter46' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> GROUP BY Clause  </h1>

      <h3 style={{paddingBottom:"6px"}}>GROUP BY Clause in MySQL</h3>
      <p>
        The <code>GROUP BY</code> clause in MySQL is used to group rows that have the same values in specified columns into summary rows. This is commonly used with aggregate functions such as <code>COUNT()</code>, <code>SUM()</code>, <code>AVG()</code>, <code>MIN()</code>, or <code>MAX()</code> to perform operations on each group of data.
      </p>
      <p>
        When you need to analyze data in chunks or categories, the <code>GROUP BY</code> clause is an essential tool. It allows you to group data into subsets based on one or more columns and then perform aggregate functions on those subsets.
      </p><br/>

      <h4 style={{paddingBottom:"6px"}}>Basic Syntax of GROUP BY:</h4>
      <pre>
        <code>
{`SELECT column1, column2, AGGREGATE_FUNCTION(column3)
FROM table_name
WHERE condition
GROUP BY column1, column2;`}
        </code>
      </pre>
      <ul>
        <li><strong>column1, column2</strong>: These are the columns by which the result set will be grouped.</li><br/>
        <li><strong>AGGREGATE_FUNCTION(column3)</strong>: This is an aggregate function like <code>COUNT()</code>, <code>SUM()</code>, <code>AVG()</code>, <code>MAX()</code>, or <code>MIN()</code> that operates on a column in each group.</li><br/>
        <li><strong>table_name</strong>: The table from which you are selecting data.</li><br/>
        <li><strong>condition</strong>: A filter condition (optional) to narrow down the rows before grouping.</li>
      </ul><br/>

      <h4 style={{paddingBottom:"6px"}}>Key Points to Remember:</h4>
      <ol>
        <li><strong>Grouping by One or More Columns</strong>: You can group rows based on one or multiple columns. If you group by multiple columns, the result set will include unique combinations of those columns.</li><br/>
        <li><strong>Using Aggregate Functions</strong>: The <code>GROUP BY</code> clause is often used with aggregate functions like <code>SUM()</code>, <code>COUNT()</code>, <code>MAX()</code>, <code>MIN()</code>, and <code>AVG()</code>. These functions allow you to calculate summary statistics for each group.</li><br/>
        <li><strong>ORDER BY with GROUP BY</strong>: You can use the <code>ORDER BY</code> clause to order the result set after grouping the data. By default, groups are returned in an arbitrary order unless specified.</li><br/>
        <li><strong>HAVING Clause</strong>: The <code>HAVING</code> clause is used to filter groups based on the result of aggregate functions. Unlike the <code>WHERE</code> clause, which filters rows before grouping, <code>HAVING</code> filters after the grouping has been performed.</li>
      </ol><br/>

      <h4 style={{paddingBottom:"6px"}}>Examples of GROUP BY Clause:</h4>

      <h5>Example 1: Simple GROUP BY with COUNT()</h5>
      <p>Find the number of employees in each department:</p>
      <pre>
        <code>
{`SELECT department, COUNT(*) AS num_employees
FROM employees
GROUP BY department;`}
        </code>
      </pre>
      <p>Explanation: This query groups the <code>employees</code> by their <code>department</code> and counts how many employees belong to each department.</p>
      <br/>
      <h5>Example 2: GROUP BY with SUM()</h5>
      <p>Find the total sales in each store:</p>
      <pre>
        <code>
{`SELECT store_id, SUM(sales_amount) AS total_sales
FROM sales
GROUP BY store_id;`}
        </code>
      </pre>
      <p>Explanation: This query groups the <code>sales</code> data by <code>store_id</code> and calculates the total sales for each store using the <code>SUM()</code> function.</p>
      <br/>
      <h5>Example 3: GROUP BY with Multiple Columns</h5>
      <p>Find the total sales for each product in each store:</p>
      <pre>
        <code>
{`SELECT store_id, product_id, SUM(sales_amount) AS total_sales
FROM sales
GROUP BY store_id, product_id;`}
        </code>
      </pre>
      <p>Explanation: This query groups the sales data by both <code>store_id</code> and <code>product_id</code>, and then calculates the total sales for each product in each store.</p>
      <br/>
      <h5>Example 4: Using GROUP BY with HAVING</h5>
      <p>Find departments that have more than 10 employees:</p>
      <pre>
        <code>
{`SELECT department, COUNT(*) AS num_employees
FROM employees
GROUP BY department
HAVING COUNT(*) > 10;`}
        </code>
      </pre>
      <p>Explanation: This query groups employees by department, counts how many employees are in each department, and then filters the results to show only those departments with more than 10 employees.</p>
      <br/>
      <h5>Example 5: GROUP BY with MAX()</h5>
      <p>Find the highest salary in each department:</p>
      <pre>
        <code>
{`SELECT department, MAX(salary) AS highest_salary
FROM employees
GROUP BY department;`}
        </code>
      </pre>
      <p>Explanation: This query groups the <code>employees</code> by <code>department</code> and finds the highest salary in each department using the <code>MAX()</code> function.</p>
      <br/>
      <h4>Important Notes:</h4>
      <ul>
        <li><strong>All non-aggregated columns in the SELECT statement must be part of the GROUP BY clause</strong>: For example, if you are selecting <code>department</code> and <code>salary</code> but only using <code>MAX(salary)</code> as an aggregate function, then <code>department</code> must be included in the <code>GROUP BY</code> clause.</li><br/>
        <li><strong>Using ORDER BY with GROUP BY</strong>: To order the result of grouped data, you can use the <code>ORDER BY</code> clause. For instance, to order departments by the total number of employees in descending order:</li>
      </ul>
      <pre>
        <code>
{`SELECT department, COUNT(*) AS num_employees
FROM employees
GROUP BY department
ORDER BY num_employees DESC;`}
        </code>
      </pre><br/>

      <h4>HAVING vs. WHERE</h4>
      <p>Remember that <code>WHERE</code> is used to filter rows before grouping, and <code>HAVING</code> is used to filter groups after they have been formed by <code>GROUP BY</code>.</p>
      <br/>
      <h4>Performance Considerations:</h4>
      <ul>
        <li><strong>Indexing</strong>: Indexes on the columns used in the <code>GROUP BY</code> clause can improve performance, especially for large datasets.</li><br/>
        <li><strong>Large Result Sets</strong>: If your data set is large, be mindful of performance when using <code>GROUP BY</code> since it may require sorting and grouping large volumes of data.</li>
      </ul><br/>

      <h4>Conclusion:</h4>
      <p>The <code>GROUP BY</code> clause is a powerful tool in MySQL that allows you to group data and perform aggregate calculations on those groups. It is commonly used for summary reports, analyzing trends, and generating statistical insights from your data. Combining <code>GROUP BY</code> with other SQL clauses such as <code>HAVING</code>, <code>ORDER BY</code>, and aggregate functions makes it a flexible and essential tool for data analysis.</p>
    </div>
  )}





{selectedChapter === 'chapter47' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  HAVING Clause  </h1>

      <p>
        The <strong>HAVING</strong> clause in MySQL is used to filter records after the <strong>GROUP BY</strong> operation has been applied.
        It is often used in combination with aggregate functions to filter the results based on the aggregated data. While the <strong>WHERE</strong> clause filters rows before grouping,
        the <strong>HAVING</strong> clause filters the grouped rows after they have been processed.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Points about <strong>HAVING</strong> Clause:</h2>
      <ul>
        <li><strong>Works with Aggregate Functions:</strong> The <strong>HAVING</strong> clause is specifically used with aggregate functions like <code>COUNT()</code>, <code>SUM()</code>, <code>AVG()</code>, <code>MAX()</code>, and <code>MIN()</code>. It allows you to apply conditions on the result of these functions.</li><br/>
        <li><strong>Follows GROUP BY:</strong> The <strong>HAVING</strong> clause is used after the <strong>GROUP BY</strong> clause and is typically used to filter groups that result from the <strong>GROUP BY</strong> operation.</li><br/>
        <li><strong>Cannot be Used Without GROUP BY:</strong> While the <strong>WHERE</strong> clause can be used without <strong>GROUP BY</strong>, the <strong>HAVING</strong> clause is intended to be used in conjunction with <strong>GROUP BY</strong> to filter aggregated results.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Syntax of the <strong>HAVING</strong> Clause:</h2>
      <pre>
        {`SELECT column1, column2, AGGREGATE_FUNCTION(column3)
FROM table_name
WHERE condition
GROUP BY column1, column2
HAVING aggregate_condition;`}
      </pre>
      <ul>
        <li><strong>column1, column2:</strong> Columns that you are grouping by.</li><br/>
        <li><strong>AGGREGATE_FUNCTION(column3):</strong> The aggregate function that operates on a column.</li><br/>
        <li><strong>aggregate_condition:</strong> The condition that is applied on the aggregated data. It is usually a comparison or logical operation using the result of the aggregate function.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Example Usage of <strong>HAVING</strong>:</h2>

      <h3>1. Filtering Groups with Aggregate Functions</h3>
      <p>Find departments that have more than 10 employees:</p>
      <pre>
        {`SELECT department, COUNT(*) AS num_employees
FROM employees
GROUP BY department
HAVING COUNT(*) > 10;`}
      </pre><br/>

      <h3>2. Using <strong>HAVING</strong> with <code>SUM()</code></h3>
      <p>Find stores with total sales greater than $50,000:</p>
      <pre>
        {`SELECT store_id, SUM(sales_amount) AS total_sales
FROM sales
GROUP BY store_id
HAVING SUM(sales_amount) > 50000;`}
      </pre><br/>

      <h3>3. Using <strong>HAVING</strong> with <code>AVG()</code></h3>
      <p>Find products with an average rating greater than 4:</p>
      <pre>
        {`SELECT product_id, AVG(rating) AS average_rating
FROM product_reviews
GROUP BY product_id
HAVING AVG(rating) > 4;`}
      </pre><br/>

      <h2>Difference Between <strong>WHERE</strong> and <strong>HAVING</strong>:</h2>
      <ul>
        <li><strong>WHERE:</strong> Filters rows before the <strong>GROUP BY</strong> operation (applies to individual rows).</li><br/>
        <li><strong>HAVING:</strong> Filters groups after the <strong>GROUP BY</strong> operation (applies to the result of aggregate functions).</li>
      </ul><br/>

      <h3>Example:</h3>
      <p>If you wanted to find all employees who are part of departments that have more than 10 employees, you could do it as follows:</p>
      <pre>
        {`SELECT department, COUNT(*) AS num_employees
FROM employees
WHERE job_title = 'Manager'  -- Filters rows before grouping
GROUP BY department
HAVING COUNT(*) > 10;  -- Filters after grouping`}
      </pre><br/>

      <h2>Performance Considerations:</h2>
      <ul>
        <li><strong>Efficiency:</strong> Since <strong>HAVING</strong> is applied after the grouping, it can be less efficient than <strong>WHERE</strong> when you can filter the data before grouping. It's generally a good idea to use <strong>WHERE</strong> whenever possible to reduce the number of rows before performing aggregation.</li><br/>
        <li><strong>Indexes:</strong> Indexes can help improve performance, especially when the <strong>HAVING</strong> clause is applied to indexed columns.</li>
      </ul><br/>

      <h2>Conclusion:</h2>
      <p>The <strong>HAVING</strong> clause is an essential tool for filtering grouped data based on aggregate values. It is commonly used alongside <strong>GROUP BY</strong> to filter results after aggregation, and it works in conjunction with aggregate functions like <code>COUNT()</code>, <code>SUM()</code>, <code>AVG()</code>, <code>MAX()</code>, and <code>MIN()</code>. It allows more fine-grained control over your queries, especially when you're dealing with grouped data.</p>
    </div>
  )}




   
{selectedChapter === 'chapter48' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>COUNT() Function </h1>

    <p>
      The <code>COUNT()</code> function in MySQL is one of the aggregate functions used to return the number of rows that match a specific condition or the number of non-NULL values in a specified column. It's commonly used to calculate how many rows are present in a database table or how many rows match a particular condition.
    </p><br/>

    <h3>Syntax of <code>COUNT()</code>:</h3>
    <pre>
      <code>
        {`SELECT COUNT(expression)
FROM table_name
WHERE condition;`}
      </code>
    </pre>

    <ul>
      <li><strong>expression</strong>: This is the column or expression on which the count is performed. You can use <code>*</code> to count all rows (including NULL values) or a specific column to count non-NULL values.</li><br/>
      <li><strong>table_name</strong>: The name of the table from which you are retrieving data.</li><br/>
      <li><strong>condition</strong>: An optional <code>WHERE</code> clause to filter rows before counting.</li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>Key Points about <code>COUNT()</code>:</h3>
    <ol>
      <li><strong>COUNT(*)</strong>: This counts all rows in the result set, including rows with NULL values in any column.</li><br/>
      <li><strong>COUNT(column_name)</strong>: This counts all non-NULL values in the specified column. It excludes rows where the column has NULL values.</li><br/>
      <li><strong>COUNT(DISTINCT column_name)</strong>: This counts the number of distinct non-NULL values in a specified column.</li>
    </ol><br/>

    <h3 style={{paddingBottom:"6px"}}>Example Usage:</h3>

    <h4>1. Counting All Rows (Including NULL values)</h4>
    <pre>
      <code>
        {`SELECT COUNT(*) AS total_employees
FROM employees;`}
      </code>
    </pre>
    <p>Explanation: This query counts all rows in the <code>employees</code> table.</p><br/>

    <h4>2. Counting Non-NULL Values in a Column</h4>
    <pre>
      <code>
        {`SELECT COUNT(salary) AS num_employees_with_salary
FROM employees;`}
      </code>
    </pre>
    <p>Explanation: This query counts only the rows where the <code>salary</code> column is not NULL.</p><br/>

    <h4>3. Counting Distinct Values</h4>
    <pre>
      <code>
        {`SELECT COUNT(DISTINCT department) AS num_unique_departments
FROM employees;`}
      </code>
    </pre>
    <p>Explanation: This query counts the number of unique departments in the <code>employees</code> table.</p><br/>

    <h4>4. Using <code>COUNT()</code> with <code>WHERE</code> Clause</h4>
    <pre>
      <code>
        {`SELECT COUNT(*) AS num_employees_in_sales
FROM employees
WHERE department = 'Sales';`}
      </code>
    </pre>
    <p>Explanation: This query counts the number of employees who work in the 'Sales' department.</p><br/>

    <h4 style={{paddingBottom:"6px"}}>5. Counting Grouped Results</h4>
    <pre>
      <code>
        {`SELECT department, COUNT(*) AS num_employees
FROM employees
GROUP BY department;`}
      </code>
    </pre>
    <p>Explanation: This query groups employees by department and counts how many employees are in each department.</p><br/>

    <h3>Performance Considerations:</h3>
    <ul>
      <li><strong>Indexing</strong>: Using <code>COUNT(*)</code> with an indexed column can improve performance when counting rows, especially for large tables.</li><br/>
      <li><strong>NULL Handling</strong>: <code>COUNT()</code> does not count NULL values (unless you're using <code>COUNT(*)</code>), so it's essential to consider this when designing queries.</li>
    </ul><br/>

    <h3>Conclusion:</h3>
    <p>
      The <code>COUNT()</code> function is an essential tool in MySQL for performing aggregation and getting summaries of data. Whether you're counting all rows, counting only non-NULL values, or counting distinct values, <code>COUNT()</code> allows for flexible and efficient querying of your data.
    </p>
  </div>
)}





{selectedChapter === 'chapter49' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> SUM() Function  </h1>

      <h3>SUM() Function in MySQL</h3>
      <p>
        The <code>SUM()</code> function in MySQL is an aggregate function that calculates the total sum of values in a specified column.
        It is commonly used to perform arithmetic aggregation over numerical data, such as calculating the total sales, total expenses, or total quantity of items sold.
      </p><br/>

      <h4>Syntax of <code>SUM()</code>:</h4>
      <pre>
        <code>
          {`SELECT SUM(expression)`}
          <br />
          {`FROM table_name`}
          <br />
          {`WHERE condition;`}
        </code>
      </pre>
      <ul>
        <li><strong>expression</strong>: This is the column or expression on which the sum is performed. Typically, it is a column containing numeric values.</li><br/>
        <li><strong>table_name</strong>: The name of the table from which you are retrieving data.</li><br/>
        <li><strong>condition</strong>: An optional <code>WHERE</code> clause to filter rows before performing the sum.</li>
      </ul><br/>

      <h4 style={{paddingBottom:"6px"}}>Key Points about <code>SUM()</code>:</h4>
      <ul>
        <li><strong><code>SUM(column_name)</code></strong>: This calculates the sum of all non-<code>NULL</code> values in the specified column. It excludes rows where the column has <code>NULL</code> values.</li><br/>
        <li><strong><code>SUM(DISTINCT column_name)</code></strong>: This calculates the sum of distinct non-<code>NULL</code> values in a specified column, ignoring duplicate values.</li>
      </ul><br/>

      <h4 style={{paddingBottom:"6px"}}>Example Usage:</h4>
      
      <h5>1. Summing Values in a Column</h5>
      <pre>
        <code>
          {`SELECT SUM(salary) AS total_salary`}
          <br />
          {`FROM employees;`}
        </code>
      </pre>
      <p>
        This query calculates the sum of all the <code>salary</code> values from the <code>employees</code> table. It excludes any <code>NULL</code> values.
      </p><br/>

      <h5>2. Summing Values with <code>WHERE</code> Clause</h5>
      <pre>
        <code>
          {`SELECT SUM(sales_amount) AS total_sales`}
          <br />
          {`FROM sales`}
          <br />
          {`WHERE store_id = 1;`}
        </code>
      </pre>
      <p>
        This query calculates the total sales for store <code>1</code> from the <code>sales</code> table.
      </p><br/>

      <h5>3. Summing Distinct Values</h5>
      <pre>
        <code>
          {`SELECT SUM(DISTINCT order_amount) AS distinct_order_sum`}
          <br />
          {`FROM orders;`}
        </code>
      </pre>
      <p>
        This query calculates the sum of distinct values in the <code>order_amount</code> column, ignoring duplicate order amounts.
      </p><br/>

      <h5>4. Summing Grouped Values with <code>GROUP BY</code></h5>
      <pre>
        <code>
          {`SELECT store_id, SUM(sales_amount) AS total_sales`}
          <br />
          {`FROM sales`}
          <br />
          {`GROUP BY store_id;`}
        </code>
      </pre>
      <p>
        This query groups the sales data by <code>store_id</code> and calculates the total sales for each store.
      </p><br/>

      <h4>Performance Considerations:</h4>
      <ul>
        <li><strong>NULL Handling</strong>: The <code>SUM()</code> function excludes <code>NULL</code> values, so it is crucial to understand how <code>NULL</code> values are handled in your dataset.</li><br/>
        <li><strong>Indexing</strong>: Indexes on the columns involved in the <code>SUM()</code> function (especially numeric columns) can help improve performance, particularly for large datasets.</li><br/>
        <li><strong>Grouping</strong>: When using <code>SUM()</code> with <code>GROUP BY</code>, the query may involve sorting the data, which could affect performance for large datasets.</li>
      </ul><br/>

      <h4>Conclusion:</h4>
      <p>
        The <code>SUM()</code> function is a powerful tool in MySQL for performing aggregation over numeric data. It is essential for calculating totals, aggregating financial data, and performing various other summation operations in MySQL queries. Whether you are summing all values, distinct values, or applying conditions with <code>WHERE</code> or <code>GROUP BY</code>, the <code>SUM()</code> function provides an efficient way to aggregate data.
      </p>
    </div>
  )}




{selectedChapter === 'chapter50' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  AVG() Function   </h1>

        <p>
          The <code>AVG()</code> function in MySQL is an aggregate function used to calculate the average value of a numeric column. 
          It computes the mean (average) of a set of values, excluding <code>NULL</code> values, making it useful for calculating averages 
          such as the average salary, average score, or average sales in a table.
        </p><br/>

        <h3>Syntax of <code>AVG()</code>:</h3>
        <pre>
          <code>
            {`SELECT AVG(expression)
FROM table_name
WHERE condition;`}
          </code>
        </pre>

        <ul>
          <li><strong>expression</strong>: The column or expression on which the average is calculated. It is typically a numeric column.</li><br/>
          <li><strong>table_name</strong>: The name of the table from which the data is selected.</li><br/>
          <li><strong>condition</strong>: An optional <code>WHERE</code> clause that filters rows before calculating the average.</li>
        </ul><br/>

        <h3 style={{paddingBottom:"6px"}}>Key Points about <code>AVG()</code>:</h3>
        <ol>
          <li><strong>Excludes <code>NULL</code> values:</strong> <code>AVG()</code> only considers non-<code>NULL</code> values when calculating the average.</li><br/>
          <li><strong>Works with numeric data types:</strong> The <code>AVG()</code> function is typically used with numeric columns (e.g., <code>INT</code>, <code>FLOAT</code>, <code>DECIMAL</code>).</li><br/>
          <li><strong>Uses with <code>GROUP BY</code>:</strong> You can use <code>AVG()</code> with the <code>GROUP BY</code> clause to calculate the average for different groups.</li>
        </ol><br/>

        <h3 style={{paddingBottom:"6px"}}>Example Usage:</h3>

        <h4>1. Calculating the Average of a Single Column</h4>
        <pre>
          <code>
            {`SELECT AVG(salary) AS average_salary
FROM employees;`}
          </code>
        </pre>
        <p>Explanation: This query calculates the average salary of all employees in the <code>employees</code> table.</p><br/>

        <h4>2. Calculating the Average with <code>WHERE</code> Clause</h4>
        <pre>
          <code>
            {`SELECT AVG(salary) AS average_salary_in_sales
FROM employees
WHERE department = 'Sales';`}
          </code>
        </pre>
        <p>Explanation: This query calculates the average salary of employees who work in the 'Sales' department.</p><br/>

        <h4>3. Calculating the Average with <code>GROUP BY</code></h4>
        <pre>
          <code>
            {`SELECT department, AVG(salary) AS average_salary
FROM employees
GROUP BY department;`}
          </code>
        </pre>
        <p>Explanation: This query groups employees by department and calculates the average salary for each department.</p><br/>

        <h4>4. Using <code>AVG()</code> with <code>DISTINCT</code></h4>
        <pre>
          <code>
            {`SELECT AVG(DISTINCT salary) AS average_distinct_salary
FROM employees;`}
          </code>
        </pre>
        <p>Explanation: This query calculates the average salary considering only distinct salary values (ignoring duplicates).</p><br/>

        <h3>Performance Considerations:</h3>
        <ul>
          <li><strong>NULL Values:</strong> <code>AVG()</code> ignores <code>NULL</code> values, so if a column has <code>NULL</code> values, they won’t affect the average calculation.</li><br/>
          <li><strong>Indexing:</strong> If the column being averaged is indexed, it can improve the performance of the query, especially on larger datasets.</li><br/>
          <li><strong>Grouping:</strong> When using <code>AVG()</code> with <code>GROUP BY</code>, the query may involve sorting and aggregating data, which can impact performance for large datasets.</li>
        </ul><br/>

        <h3>Conclusion:</h3>
        <p>
          The <code>AVG()</code> function in MySQL is an essential tool for calculating averages over numerical data. 
          It is commonly used for analyzing data sets, generating reports, and summarizing values such as total sales or employee salaries. 
          It works well with filtering conditions (<code>WHERE</code>), grouping (<code>GROUP BY</code>), and can also be used to calculate averages over distinct values in a column.
        </p>
      </div>
    )}



{selectedChapter === 'chapter51' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>MAX() Function </h1>

    <p>
      The <code>MAX()</code> function in MySQL is an aggregate function that returns the maximum value from a specified column in a table. It is commonly used to find the largest or highest value in a numeric column or the latest date in a date column. The <code>MAX()</code> function can be very useful in various scenarios, such as finding the highest salary, the most recent order, or the maximum sales in a specific time period.
    </p><br/>

    <h3>Syntax of <code>MAX()</code>:</h3>
    <pre>
      <code>
        {`SELECT MAX(expression) FROM table_name WHERE condition;`}
      </code>
    </pre>
    <ul>
      <li><strong>expression</strong>: This is the column or expression on which the maximum value is calculated. It could be a numeric column, date column, or any other column that can have a defined maximum value.</li><br/>
      <li><strong>table_name</strong>: The name of the table from which the data is selected.</li><br/>
      <li><strong>condition</strong>: An optional <code>WHERE</code> clause to filter the rows before calculating the maximum value.</li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>Key Points about <code>MAX()</code>:</h3>
    <ol>
      <li><strong>Works with Numeric, Date, and String Data</strong>: <code>MAX()</code> can be used on numeric, date, and even string columns. It returns the largest value based on the data type:
        <ul>
          <li><strong>Numeric Columns</strong>: Returns the highest numeric value.</li><br/>
          <li><strong>Date Columns</strong>: Returns the latest date.</li><br/>
          <li><strong>String Columns</strong>: Returns the lexicographically largest value (based on the character set).</li>
        </ul><br/>
      </li>
      <li><strong>Excludes <code>NULL</code> values</strong>: <code>MAX()</code> ignores <code>NULL</code> values. If the column has <code>NULL</code> values, they will not affect the calculation.</li><br/>
      <li><strong>No <code>DISTINCT</code> in <code>MAX()</code></strong>: Unlike functions like <code>SUM()</code> or <code>AVG()</code>, <code>MAX()</code> does not support the <code>DISTINCT</code> keyword because the maximum value is already a unique value.</li>
    </ol><br/>

    <h3 style={{paddingBottom:"6px"}}>Example Usage:</h3>

    <h4>1. Finding the Maximum Value in a Numeric Column</h4>
    <pre>
      <code>
        {`SELECT MAX(salary) AS max_salary FROM employees;`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> This query finds the highest salary in the <code>employees</code> table.</p><br/>

    <h4>2. Finding the Most Recent Date</h4>
    <pre>
      <code>
        {`SELECT MAX(order_date) AS most_recent_order FROM orders;`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> This query finds the most recent <code>order_date</code> in the <code>orders</code> table.</p><br/>

    <h4>3. Finding the Maximum Value with <code>WHERE</code> Clause</h4>
    <pre>
      <code>
        {`SELECT MAX(salary) AS max_salary_in_sales FROM employees WHERE department = 'Sales';`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> This query finds the highest salary among employees who work in the 'Sales' department.</p><br/>

    <h4>4. Finding the Maximum Value with <code>GROUP BY</code></h4>
    <pre>
      <code>
        {`SELECT department, MAX(salary) AS max_salary FROM employees GROUP BY department;`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> This query groups employees by department and finds the maximum salary for each department.</p>

    <h3>Performance Considerations:</h3>
    <ul>
      <li><strong>Indexing</strong>: Indexing on the column being evaluated by <code>MAX()</code> can improve performance, especially for large tables.</li><br/>
      <li><strong>NULL Handling</strong>: <code>MAX()</code> ignores <code>NULL</code> values, so it will only return the largest non-<code>NULL</code> value.</li><br/>
      <li><strong>Grouping</strong>: If you are using <code>MAX()</code> with <code>GROUP BY</code>, be aware that it might require sorting, which can affect performance with large datasets.</li>
    </ul><br/>

    <h3>Conclusion:</h3>
    <p>The <code>MAX()</code> function in MySQL is a powerful tool to find the maximum value from a set of data. Whether you are looking for the largest number, the latest date, or the lexicographically largest string, <code>MAX()</code> simplifies the process. It works well in combination with filtering conditions (<code>WHERE</code>), grouping (<code>GROUP BY</code>), and aggregation.</p>
  </div>
)}




{selectedChapter === 'chapter52' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>MIN() Function </h1>

    

    <p>
      The <code>MIN()</code> function in MySQL is an aggregate function that returns the minimum value from a specified column in a table. 
      It is commonly used to find the smallest value in a numeric column, the earliest date in a date column, or the lexicographically smallest string in a text column. 
      The <code>MIN()</code> function can be very useful for determining the lowest salary, the earliest order date, or the minimum score in a dataset.
    </p><br/>

    <h4>Syntax of <code>MIN()</code>:</h4>
    <pre>
      <code>
{`SELECT MIN(expression)
FROM table_name
WHERE condition;`}
      </code>
    </pre>

    <ul>
      <li><strong>expression</strong>: This is the column or expression on which the minimum value is calculated. It could be a numeric column, date column, or string column.</li><br/>
      <li><strong>table_name</strong>: The name of the table from which the data is selected.</li><br/>
      <li><strong>condition</strong>: An optional <code>WHERE</code> clause that filters the rows before calculating the minimum value.</li>
    </ul><br/>

    <h4 style={{paddingBottom:"6px"}}>Key Points about <code>MIN()</code>:</h4>
    <ol>
      <li><strong>Works with Numeric, Date, and String Data:</strong>
        <ul>
          <li><strong>Numeric Columns:</strong> Returns the smallest numeric value.</li><br/>
          <li><strong>Date Columns:</strong> Returns the earliest (smallest) date.</li><br/>
          <li><strong>String Columns:</strong> Returns the lexicographically smallest value (based on the character set).</li>
        </ul><br/>
      </li>
      <li><strong>Excludes <code>NULL</code> values:</strong> <code>MIN()</code> ignores <code>NULL</code> values. If the column has <code>NULL</code> values, they will not affect the result.</li><br/>
      <li><strong>No <code>DISTINCT</code> in <code>MIN()</code>:</strong> Similar to the <code>MAX()</code> function, <code>MIN()</code> does not support the <code>DISTINCT</code> keyword because the minimum value is already unique.</li>
    </ol><br/>

    <h4>Example Usage:</h4>

    <h5>1. Finding the Minimum Value in a Numeric Column</h5>
    <pre>
      <code>
{`SELECT MIN(salary) AS min_salary
FROM employees;`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> This query finds the lowest salary in the <code>employees</code> table.</p><br/>

    <h5>2. Finding the Earliest Date</h5>
    <pre>
      <code>
{`SELECT MIN(order_date) AS earliest_order
FROM orders;`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> This query finds the earliest <code>order_date</code> in the <code>orders</code> table.</p><br/>

    <h5>3. Finding the Minimum Value with <code>WHERE</code> Clause</h5>
    <pre>
      <code>
{`SELECT MIN(salary) AS min_salary_in_hr
FROM employees
WHERE department = 'HR';`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> This query finds the lowest salary among employees who work in the 'HR' department.</p><br/>

    <h5>4. Finding the Minimum Value with <code>GROUP BY</code></h5>
    <pre>
      <code>
{`SELECT department, MIN(salary) AS min_salary
FROM employees
GROUP BY department;`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> This query groups employees by department and finds the minimum salary for each department.</p><br/>

    <h4>Performance Considerations:</h4>
    <ul>
      <li><strong>Indexing:</strong> Indexing on the column being evaluated by <code>MIN()</code> can improve the performance, especially for large tables.</li><br/>
      <li><strong>NULL Handling:</strong> <code>MIN()</code> ignores <code>NULL</code> values, so it will only return the smallest non-<code>NULL</code> value.</li><br/>
      <li><strong>Grouping:</strong> If you are using <code>MIN()</code> with <code>GROUP BY</code>, the query may require sorting, which could affect performance for large datasets.</li>
    </ul><br/>

    <h4>Conclusion:</h4>
    <p>
      The <code>MIN()</code> function in MySQL is a useful tool for retrieving the smallest value in a set of data. 
      It works well with numeric, date, and string columns, and is commonly used in reporting, filtering, and aggregation tasks. 
      It can be used with <code>WHERE</code> clauses, <code>GROUP BY</code>, and other filtering conditions to extract the minimum values from datasets effectively.
    </p>
  </div>
)}




{selectedChapter === 'chapter53' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String Functions </h1>
    
    <p>
      In MySQL, string functions are used to manipulate or operate on string data types. These functions allow you to perform various operations such as extracting parts of a string, converting string case, searching, replacing, and more.
    </p><br/>
    
    <h3>1. <strong>CONCAT()</strong>: Concatenate Strings</h3>
    <p>
      The <code>CONCAT()</code> function is used to combine two or more strings into one.
    </p>
    <pre><code>SELECT CONCAT(string1, string2, ..., stringN);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT CONCAT('Hello', ' ', 'World') AS greeting;</code></pre>
    <p>Explanation: This will return "Hello World".</p><br/>

    <h3>2. <strong>CONCAT_WS()</strong>: Concatenate with Separator</h3>
    <p>
      The <code>CONCAT_WS()</code> function concatenates strings with a specified separator.
    </p>
    <pre><code>SELECT CONCAT_WS(separator, string1, string2, ..., stringN);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT CONCAT_WS('-', '2024', '11', '20') AS formatted_date;</code></pre>
    <p>Explanation: This will return "2024-11-20".</p><br/>

    <h3>3. <strong>LENGTH()</strong>: Get Length of String</h3>
    <p>
      The <code>LENGTH()</code> function returns the number of characters in a string, including spaces.
    </p>
    <pre><code>SELECT LENGTH(string);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT LENGTH('Hello World') AS length;</code></pre>
    <p>Explanation: This will return <strong>11</strong>, as the string "Hello World" has 11 characters.</p><br/>

    <h3>4. <strong>CHAR_LENGTH() or CHARACTER_LENGTH()</strong>: Character Length</h3>
    <p>
      <code>CHAR_LENGTH()</code> (or <code>CHARACTER_LENGTH()</code>) returns the number of characters in a string, similar to <code>LENGTH()</code>, but it counts multi-byte characters correctly.
    </p>
    <pre><code>SELECT CHAR_LENGTH(string);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT CHAR_LENGTH('Hello World') AS char_length;</code></pre>
    <p>Explanation: This will return <strong>11</strong> as well, because each character is counted individually.</p><br/>

    <h3>5. <strong>UPPER()</strong>: Convert to Uppercase</h3>
    <p>
      The <code>UPPER()</code> function converts a string to uppercase.
    </p>
    <pre><code>SELECT UPPER(string);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT UPPER('hello world') AS uppercase_text;</code></pre>
    <p>Explanation: This will return "HELLO WORLD".</p><br/>

    <h3>6. <strong>LOWER()</strong>: Convert to Lowercase</h3>
    <p>
      The <code>LOWER()</code> function converts a string to lowercase.
    </p>
    <pre><code>SELECT LOWER(string);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT LOWER('HELLO WORLD') AS lowercase_text;</code></pre>
    <p>Explanation: This will return "hello world".</p><br/>

    <h3>7. <strong>TRIM()</strong>: Remove Leading and Trailing Spaces</h3>
    <p>
      The <code>TRIM()</code> function removes leading and trailing spaces from a string.
    </p>
    <pre><code>SELECT TRIM(string);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT TRIM('  Hello World  ') AS trimmed_text;</code></pre>
    <p>Explanation: This will return "Hello World", with spaces removed.</p><br/>

    <h3>8. <strong>LEFT()</strong>: Extract Left Part of String</h3><br/>
    <p>
      The <code>LEFT()</code> function extracts a specified number of characters from the left (beginning) of a string.
    </p>
    <pre><code>SELECT LEFT(string, length);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT LEFT('Hello World', 5) AS left_text;</code></pre>
    <p>Explanation: This will return "Hello".</p><br/>

    <h3>9. <strong>RIGHT()</strong>: Extract Right Part of String</h3>
    <p>
      The <code>RIGHT()</code> function extracts a specified number of characters from the right (end) of a string.
    </p>
    <pre><code>SELECT RIGHT(string, length);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT RIGHT('Hello World', 5) AS right_text;</code></pre>
    <p>Explanation: This will return "World".</p><br/>

    <h3>10. <strong>SUBSTRING() or SUBSTR()</strong>: Extract Substring</h3>
    <p>
      The <code>SUBSTRING()</code> (or <code>SUBSTR()</code>) function extracts a substring from a string.
    </p>
    <pre><code>SELECT SUBSTRING(string, start_position, length);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT SUBSTRING('Hello World', 7, 5) AS substring_text;</code></pre>
    <p>Explanation: This will return "World".</p><br/>

    <h3>11. <strong>REPLACE()</strong>: Replace Substring</h3>
    <p>
      The <code>REPLACE()</code> function replaces occurrences of a substring within a string.
    </p>
    <pre><code>SELECT REPLACE(string, old_substring, new_substring);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT REPLACE('Hello World', 'World', 'MySQL') AS replaced_text;</code></pre>
    <p>Explanation: This will return "Hello MySQL".</p><br/>

    <h3>12. <strong>INSTR()</strong>: Find Position of Substring</h3>
    <p>
      The <code>INSTR()</code> function returns the position of the first occurrence of a substring in a string.
    </p>
    <pre><code>SELECT INSTR(string, substring);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT INSTR('Hello World', 'World') AS position;</code></pre>
    <p>Explanation: This will return <strong>7</strong>, as "World" starts at the 7th position in "Hello World".</p><br/>

    <h3>13. <strong>LOCATE()</strong>: Find Substring Position</h3>
    <p>
      <code>LOCATE()</code> is similar to <code>INSTR()</code>, but it starts counting from 1, and you can specify the start position.
    </p>
    <pre><code>SELECT LOCATE(substring, string, start_position);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT LOCATE('World', 'Hello World') AS position;</code></pre>
    <p>Explanation: This will return <strong>7</strong>.</p><br/>

    <h3>14. <strong>FORMAT()</strong>: Format Numbers as Strings</h3>
    <p>
      The <code>FORMAT()</code> function is often used to format numbers with a specific number of decimal places.
    </p>
    <pre><code>SELECT FORMAT(number, decimal_places);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT FORMAT(12345.6789, 2) AS formatted_number;</code></pre>
    <p>Explanation: This will return "12,345.68" (formatted with commas and 2 decimal places).</p><br/>

    <h3>15. <strong>SOUNDEX()</strong>: Soundex of a String</h3>
    <p>
      The <code>SOUNDEX()</code> function returns a four-character code that represents how a string sounds.
    </p>
    <pre><code>SELECT SOUNDEX(string);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT SOUNDEX('Smith') AS soundex_code;</code></pre>
    <p>Explanation: This will return the soundex code for "Smith", such as "S530".</p><br/>

    <h3>16. <strong>ASCII()</strong>: Get ASCII Value of First Character</h3>
    <p>
      The <code>ASCII()</code> function returns the ASCII value of the first character of a string.
    </p>
    <pre><code>SELECT ASCII(string);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT ASCII('A') AS ascii_value;</code></pre>
    <p>Explanation: This will return <strong>65</strong>, the ASCII value for the character 'A'.</p><br/>

    <h3>17. <strong>CHAR()</strong>: Convert ASCII Value to Character</h3>
    <p>
      The <code>CHAR()</code> function converts an ASCII value into its corresponding character.
    </p>
    <pre><code>SELECT CHAR(number);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT CHAR(65) AS character;</code></pre>
    <p>Explanation: This will return "A".</p><br/>

    <h3>18. <strong>REVERSE()</strong>: Reverse a String</h3>
    <p>
      The <code>REVERSE()</code> function returns the reverse of a string.
    </p>
    <pre><code>SELECT REVERSE(string);</code></pre>
    <p><strong>Example:</strong></p>
    <pre><code>SELECT REVERSE('Hello World') AS reversed_text;</code></pre>
    <p>Explanation: This will return "dlroW olleH".</p><br/>

    <h3>Conclusion:</h3>
    <p>
      These are some of the key string functions in MySQL that allow you to manipulate and extract information from text data. They are especially useful for tasks like formatting, extracting substrings, and transforming the case or structure of string data. Understanding these functions will greatly enhance your ability to work with text and string data in MySQL.
    </p>
  </div>
)}



{selectedChapter === 'chapter54' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Numeric Functions   </h1>

          <p>
            Numeric functions in MySQL allow you to perform mathematical operations and manipulate numerical data. These functions are useful for a variety of tasks, such as rounding numbers, calculating square roots, obtaining the highest or lowest value, and more.
          </p><br/>

          <h2 style={{paddingBottom:"6px"}}>Here’s a detailed explanation of some of the most commonly used numeric functions in MySQL:</h2>

          <h3>1. <strong>ABS()</strong>: Absolute Value</h3>
          <p>
            The <code>ABS()</code> function returns the absolute value of a number. It converts negative numbers to positive and leaves positive numbers unchanged.
          </p>
          <pre><code>SELECT ABS(number);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT ABS(-10) AS absolute_value;</code></pre>
          <p>Explanation: This will return 10 because the absolute value of -10 is 10.</p><br/>

          <h3>2. <strong>CEILING() or CEIL()</strong>: Ceiling Value</h3>
          <p>
            The <code>CEILING()</code> (or <code>CEIL()</code>) function returns the smallest integer greater than or equal to the given number.
          </p>
          <pre><code>SELECT CEILING(number);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT CEILING(5.3) AS ceiling_value;</code></pre>
          <p>Explanation: This will return 6, because 6 is the smallest integer greater than or equal to 5.3.</p><br/>

          <h3>3. <strong>FLOOR()</strong>: Floor Value</h3>
          <p>
            The <code>FLOOR()</code> function returns the largest integer less than or equal to the given number.
          </p>
          <pre><code>SELECT FLOOR(number);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT FLOOR(5.7) AS floor_value;</code></pre>
          <p>Explanation: This will return 5, as 5 is the largest integer less than or equal to 5.7.</p><br/>

          <h3>4. <strong>ROUND()</strong>: Round a Number</h3>
          <p>
            The <code>ROUND()</code> function rounds a number to the specified number of decimal places.
          </p>
          <pre><code>SELECT ROUND(number, decimals);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT ROUND(5.567, 2) AS rounded_value;</code></pre>
          <p>Explanation: This will return 5.57, rounding 5.567 to two decimal places.</p><br/>

          <h3>5. <strong>MOD()</strong>: Modulus (Remainder)</h3>
          <p>
            The <code>MOD()</code> function returns the remainder of a division (i.e., the modulus of two numbers).
          </p>
          <pre><code>SELECT MOD(dividend, divisor);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT MOD(10, 3) AS remainder;</code></pre>
          <p>Explanation: This will return 1, as the remainder when dividing 10 by 3 is 1.</p><br/>

          <h3>6. <strong>POW() or POWER()</strong>: Power Function</h3>
          <p>
            The <code>POW()</code> (or <code>POWER()</code>) function returns the result of a number raised to the power of another number.
          </p>
          <pre><code>SELECT POW(number, exponent);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT POW(2, 3) AS power_value;</code></pre>
          <p>Explanation: This will return 8, because 2 raised to the power of 3 is 8.</p><br/>

          <h3>7. <strong>SQRT()</strong>: Square Root</h3>
          <p>
            The <code>SQRT()</code> function returns the square root of a number.
          </p>
          <pre><code>SELECT SQRT(number);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT SQRT(16) AS square_root;</code></pre>
          <p>Explanation: This will return 4, as the square root of 16 is 4.</p><br/>

          <h3>8. <strong>RAND()</strong>: Random Number</h3>
          <p>
            The <code>RAND()</code> function generates a random floating-point value between 0 and 1.
          </p>
          <pre><code>SELECT RAND();</code></pre>
          <p>Example:</p>
          <pre><code>SELECT RAND() AS random_number;</code></pre>
          <p>Explanation: This will return a random floating-point number between 0 and 1.</p><br/>

          <h3>9. <strong>SIGN()</strong>: Sign of a Number</h3>
          <p>
            The <code>SIGN()</code> function returns the sign of a number: 1 for positive numbers, 0 for zero, and -1 for negative numbers.
          </p>
          <pre><code>SELECT SIGN(number);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT SIGN(-15) AS sign_value;</code></pre>
          <p>Explanation: This will return -1, since -15 is a negative number.</p><br/>

          <h3>10. <strong>GREATEST()</strong>: Largest of a List of Numbers</h3>
          <p>
            The <code>GREATEST()</code> function returns the largest value from a list of values.
          </p>
          <pre><code>SELECT GREATEST(number1, number2, ..., numberN);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT GREATEST(5, 10, 2, 8) AS max_value;</code></pre>
          <p>Explanation: This will return 10, as it is the largest value among the provided numbers.</p><br/>

          <h3>11. <strong>LEAST()</strong>: Smallest of a List of Numbers</h3>
          <p>
            The <code>LEAST()</code> function returns the smallest value from a list of values.
          </p>
          <pre><code>SELECT LEAST(number1, number2, ..., numberN);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT LEAST(5, 10, 2, 8) AS min_value;</code></pre>
          <p>Explanation: This will return 2, as it is the smallest value among the provided numbers.</p><br/>

          <h3>12. <strong>PI()</strong>: Return the Value of Pi</h3>
          <p>
            The <code>PI()</code> function returns the value of π (Pi), approximately 3.14159265358979.
          </p>
          <pre><code>SELECT PI();</code></pre>
          <p>Example:</p>
          <pre><code>SELECT PI() AS pi_value;</code></pre>
          <p>Explanation: This will return 3.14159265358979.</p><br/>

          <h3>13. <strong>BIT_LENGTH()</strong>: Number of Bits</h3>
          <p>
            The <code>BIT_LENGTH()</code> function returns the number of bits required to store a string.
          </p>
          <pre><code>SELECT BIT_LENGTH(string);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT BIT_LENGTH('Hello') AS bit_length;</code></pre>
          <p>Explanation: This will return 40, as the string "Hello" consists of 5 characters, each requiring 8 bits, resulting in a total of 40 bits.</p><br/>

          <h3>14. <strong>CONV()</strong>: Convert Numbers Between Different Bases</h3>
          <p>
            The <code>CONV()</code> function converts a number from one base to another.
          </p>
          <pre><code>SELECT CONV(number, from_base, to_base);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT CONV('A', 16, 10) AS decimal_value;</code></pre>
          <p>Explanation: This will return 10, as 'A' in hexadecimal (base 16) is equal to 10 in decimal (base 10).</p><br/>

          <h3>15. <strong>EXTRACT()</strong>: Extract Part of a Date or Time</h3>
          <p>
            Though primarily used for date and time extraction, the <code>EXTRACT()</code> function can also be used with numeric values for precision calculations.
          </p>
          <pre><code>SELECT EXTRACT(unit FROM value);</code></pre>
          <p>Example:</p>
          <pre><code>SELECT EXTRACT(YEAR FROM '2024-11-20') AS year_part;</code></pre>
          <p>Explanation: This will return 2024, extracting the year from the given date.</p><br/>

          <h2>Conclusion:</h2>
          <p>
            These numeric functions in MySQL are essential for performing calculations and manipulating numbers. Whether you're working with basic arithmetic, rounding, finding the absolute value, or performing more complex operations like generating random numbers or extracting parts of data, these functions provide a wide range of capabilities to suit your needs in SQL queries. Understanding and utilizing these functions will greatly enhance your ability to work with numerical data in MySQL.
          </p>
        </div>
      )}
   




   {selectedChapter === 'chapter55' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  MySQL Date and Time Functions  </h1>

      <p>
        MySQL provides several date and time functions to handle and manipulate date and time values. These functions can be used to perform operations such as extracting parts of a date, comparing dates, formatting, and performing arithmetic with dates.
      </p><br/>

      <h2>1. <strong>NOW()</strong>: Current Date and Time</h2>
      <p>The <code>NOW()</code> function returns the current date and time based on the system's clock.</p>
      <pre>
        <code>
          SELECT NOW();
        </code>
      </pre>
      <p>Example:</p>
      <pre>
        <code>
          SELECT NOW();
        </code>
      </pre>
      <p>Explanation: Returns the current date and time, e.g., <code>2024-11-20 15:30:00</code>.</p>

      <br/>

      <h2>2. <strong>CURDATE()</strong>: Current Date</h2>
      <p>The <code>CURDATE()</code> function returns the current date without the time part.</p>
      <pre>
        <code>
          SELECT CURDATE();
        </code>
      </pre>
      <p>Example:</p>
      <pre>
        <code>
          SELECT CURDATE();
        </code>
      </pre>
      <p>Explanation: Returns only the current date, e.g., <code>2024-11-20</code>.</p>

      <br/>

      <h2>3. <strong>CURTIME()</strong>: Current Time</h2>
      <p>The <code>CURTIME()</code> function returns the current time without the date part.</p>
      <pre>
        <code>
          SELECT CURTIME();
        </code>
      </pre>
      <p>Example:</p>
      <pre>
        <code>
          SELECT CURTIME();
        </code>
      </pre>
      <p>Explanation: Returns the current time, e.g., <code>15:30:00</code>.</p>

      <br/>

      <h2>4. <strong>DATE()</strong>: Extract Date Part</h2>
      <p>The <code>DATE()</code> function extracts the date part from a datetime or timestamp value, removing the time part.</p>
      <pre>
        <code>
          SELECT DATE('2024-11-20 15:30:00');
        </code>
      </pre>
      <p>Explanation: This will return <code>2024-11-20</code>, extracting the date from the datetime value.</p>

      <br/>

      {/* Repeat for other functions... */}
      <h2>5. <strong>TIME()</strong>: Extract Time Part</h2>
      <p>The <code>TIME()</code> function extracts the time part from a datetime or timestamp value.</p>
      <pre>
        <code>
          SELECT TIME('2024-11-20 15:30:00');
        </code>
      </pre>
      <p>Explanation: This will return <code>15:30:00</code>, extracting the time from the datetime value.</p>

      <br/>

      {/* Add additional date and time functions like YEAR(), MONTH(), ADDDATE() here */}

      <h2>Conclusion</h2>
      <p>These date and time functions are crucial for handling temporal data in MySQL. They allow you to manipulate, extract, and format dates and times efficiently. Whether you need to add or subtract time intervals, extract specific components of a date or time, or perform complex date arithmetic, these functions provide powerful tools to work with date and time in SQL queries.</p>
    </div>
  )}




{selectedChapter === 'chapter56' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Conversion Functions   </h1>
      
      <p>
        In MySQL, conversion functions allow you to convert one data type to another. These functions are essential when you need to manipulate data that is stored in different formats or when you're working with data that needs to be transformed for compatibility with other operations.
      </p><br/>

      <h2>1. <strong>CAST()</strong>: Convert Data from One Type to Another</h2>
      <p>The <code>CAST()</code> function is used to convert an expression from one data type to another. You can convert values to types like <code>CHAR</code>, <code>DATE</code>, <code>DATETIME</code>, <code>DECIMAL</code>, <code>SIGNED</code>, <code>UNSIGNED</code>, and others.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>CAST(expression AS target_data_type)</code></pre>
      <ul>
        <li><strong>expression</strong>: The value you want to convert.</li><br/>
        <li><strong>target_data_type</strong>: The data type to which you want to convert the expression.</li>
      </ul>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT CAST('2024-11-20' AS DATETIME);</code></pre>
      <p><strong>Explanation:</strong> This converts the string <code>'2024-11-20'</code> into a <code>DATETIME</code> value.</p>

      <pre><code>SELECT CAST(123.45 AS UNSIGNED);</code></pre>
      <p><strong>Explanation:</strong> This converts the decimal value <code>123.45</code> to an <code>UNSIGNED</code> integer, which will return <code>123</code>.</p>
      <br/>
      <h2>2. <strong>CONVERT()</strong>: Convert Between Different Data Types</h2>
      <p>The <code>CONVERT()</code> function is similar to <code>CAST()</code>, but with a more flexible syntax. It is primarily used for character set conversions, but you can also use it for other types.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>CONVERT(expression, target_data_type)</code></pre>
      <ul>
        <li><strong>expression</strong>: The value to be converted.</li>
        <li><strong>target_data_type</strong>: The data type to convert the expression to.</li>
      </ul>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT CONVERT('2024-11-20' USING utf8);</code></pre>
      <p><strong>Explanation:</strong> This converts the string <code>'2024-11-20'</code> into a different character set, <code>utf8</code>.</p>

      <pre><code>SELECT CONVERT(123.45, SIGNED);</code></pre>
      <p><strong>Explanation:</strong> This converts the value <code>123.45</code> into a <code>SIGNED</code> integer (which will result in <code>123</code>).</p>
      <br/>
      <h2>3. <strong>BINARY</strong>: Convert to Binary</h2>
      <p>The <code>BINARY</code> function forces a string expression to be treated as a binary string, meaning it interprets the string using the binary encoding rather than any character set.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>BINARY(expression)</code></pre>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT BINARY 'abc';</code></pre>
      <p><strong>Explanation:</strong> This forces the string <code>'abc'</code> to be interpreted as a binary string.</p>
      <br/>
      <h2>4. <strong>DATE()</strong>: Extract Date Part from a DateTime or Timestamp</h2>
      <p>The <code>DATE()</code> function extracts the date part (year, month, day) from a <code>DATETIME</code> or <code>TIMESTAMP</code> value, ignoring the time part.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>DATE(expression)</code></pre>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT DATE('2024-11-20 15:30:00');</code></pre>
      <p><strong>Explanation:</strong> This extracts the date <code>2024-11-20</code> from the given <code>DATETIME</code> value.</p>
      <br/>
      <h2>5. <strong>TIME()</strong>: Extract Time Part from DateTime or Timestamp</h2>
      <p>The <code>TIME()</code> function extracts the time part (hour, minute, second) from a <code>DATETIME</code> or <code>TIMESTAMP</code> value.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>TIME(expression)</code></pre>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT TIME('2024-11-20 15:30:00');</code></pre>
      <p><strong>Explanation:</strong> This extracts the time <code>15:30:00</code> from the given <code>DATETIME</code> value.</p>
      <br/>
      <h2>6. <strong>UNHEX()</strong>: Convert Hexadecimal to ASCII</h2>
      <p>The <code>UNHEX()</code> function is used to convert a hexadecimal string back to its ASCII representation. It is the reverse of the <code>HEX()</code> function.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>UNHEX(hex_string)</code></pre>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT UNHEX('68656C6C6F');</code></pre>
      <p><strong>Explanation:</strong> The hexadecimal string <code>'68656C6C6F'</code> will be converted to the string <code>'hello'</code>.</p>
      <br/>
      <h2>7. <strong>HEX()</strong>: Convert ASCII to Hexadecimal</h2>
      <p>The <code>HEX()</code> function converts a string to its hexadecimal representation.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>HEX(expression)</code></pre>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT HEX('hello');</code></pre>
      <p><strong>Explanation:</strong> The string <code>'hello'</code> is converted to its hexadecimal representation <code>'68656C6C6F'</code>.</p>
      <br/>
      <h2>8. <strong>SIGNED / UNSIGNED</strong>: Convert to Signed or Unsigned Integer</h2>
      <p>MySQL allows you to convert values to either signed or unsigned integers using the <code>SIGNED</code> and <code>UNSIGNED</code> keywords.</p>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT CAST('123.45' AS SIGNED);</code></pre>
      <p><strong>Explanation:</strong> This converts the string <code>'123.45'</code> into a signed integer, which results in <code>123</code>.</p>

      <pre><code>SELECT CAST('123.45' AS UNSIGNED);</code></pre>
      <p><strong>Explanation:</strong> This converts the string <code>'123.45'</code> into an unsigned integer, which results in <code>123</code>.</p>
      <br/>
      <h2>9. <strong>CHAR()</strong>: Convert Integer to Character</h2>
      <p>The <code>CHAR()</code> function converts an integer value to its corresponding ASCII character.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>CHAR(N)</code></pre>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT CHAR(65);</code></pre>
      <p><strong>Explanation:</strong> The integer <code>65</code> is converted to its corresponding ASCII character <code>'A'</code>.</p>
      <br/>
      <h2>10. <strong>CONVERT_TZ()</strong>: Convert Timezone</h2>
      <p>The <code>CONVERT_TZ()</code> function converts a <code>DATETIME</code> or <code>TIMESTAMP</code> value from one timezone to another.</p>
      <p><strong>Syntax:</strong></p>
      <pre><code>CONVERT_TZ(datetime, from_tz, to_tz)</code></pre>
      <p><strong>Example:</strong></p>
      <pre><code>SELECT CONVERT_TZ('2024-11-20 15:30:00', 'UTC', 'America/New_York');</code></pre>
      <p><strong>Explanation:</strong> This converts the <code>DATETIME</code> value <code>'2024-11-20 15:30:00'</code> from UTC to the 'America/New_York' timezone.</p>
      <br/>
      <h2>11. <strong>CAST() vs CONVERT()</strong></h2>
      <p>Both <code>CAST()</code> and <code>CONVERT()</code> functions are used to convert data types, but there are slight differences:</p>
      <ul>
        <li><strong>CAST()</strong>: More commonly used for standard conversions between numerical types, strings, and date/time types.</li>
        <li><strong>CONVERT()</strong>: More versatile, supporting character set conversions (e.g., converting from one character encoding to another) and a wider range of conversions in general.</li>
      </ul>
      <br/>
      <h2>Conclusion:</h2>
      <p>MySQL's conversion functions are incredibly useful for handling different data types and manipulating them as needed. Whether you're converting between date and time formats, working with hexadecimal data, or changing character encodings, these functions provide the flexibility to ensure your data is in the right format for your application. The most commonly used functions like <code>CAST()</code>, <code>CONVERT()</code>, and <code>BINARY()</code> will likely cover most of your needs, but more specialized functions like <code>UNHEX()</code> and <code>HEX()</code> are useful for specific scenarios.</p>
    </div>
  )}



{selectedChapter === 'chapter57' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   Control Flow Functions   </h1>
      <p>
        In MySQL, <strong>Control Flow Functions</strong> allow you to execute conditional logic within SQL queries. These functions help manage program flow by evaluating conditions and returning results based on the evaluations. These are especially useful for creating dynamic queries, simplifying logic, and handling edge cases.
      </p>
      <br/>
      <section>
        <h2>1. <code>IF()</code></h2>
        <p>
          The <code>IF()</code> function is a conditional function that returns one value if a condition is true and another value if the condition is false.
        </p>
        <pre>
          <code>
            IF(condition, value_if_true, value_if_false)
          </code>
        </pre>
        <ul>
          <li><strong>condition:</strong> A Boolean expression that evaluates to TRUE or FALSE.</li><br/>
          <li><strong>value_if_true:</strong> The value to return if the condition is TRUE.</li><br/>
          <li><strong>value_if_false:</strong> The value to return if the condition is FALSE.</li>
        </ul>
        <p><strong>Example:</strong></p>
        <pre>
          <code>
            SELECT IF(1 &gt; 0, 'Yes', 'No');
          </code>
        </pre>
        <p><em>Explanation:</em> Returns <code>'Yes'</code> because <code>1 &gt; 0</code> is TRUE.</p>
      </section>
      <br/>
      <section>
        <h2>2. <code>IFNULL()</code></h2>
        <p>
          The <code>IFNULL()</code> function returns a specified value if the given expression is <code>NULL</code>. Otherwise, it returns the expression itself.
        </p>
        <pre>
          <code>
            IFNULL(expression, value_if_null)
          </code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>
            SELECT IFNULL(NULL, 'Default Value');
          </code>
        </pre>
        <p><em>Explanation:</em> Returns <code>'Default Value'</code> because the input is <code>NULL</code>.</p>
      </section>
      <br/>
      {/* Add similar sections for other control flow functions like NULLIF(), CASE, COALESCE(), GREATEST(), LEAST(), and ISNULL() */}

      <section>
        <h2>Conclusion</h2>
        <p>
          Control Flow Functions in MySQL are powerful tools for managing data logic within queries. They allow you to:
        </p>
        <ul>
          <li><strong>Perform conditional logic</strong> with <code>IF()</code> and <code>CASE</code>.</li><br/>
          <li>
            <strong>Handle NULL values</strong> effectively using <code>IFNULL()</code>, <code>NULLIF()</code>, and <code>COALESCE()</code>.
          </li><br/>
          <li><strong>Compare values</strong> using <code>GREATEST()</code> and <code>LEAST()</code>.</li><br/>
          <li><strong>Check for NULL</strong> using <code>ISNULL()</code>.</li>
        </ul><br/>
        <p>
          These functions simplify query logic, make queries more dynamic, and help manage edge cases effectively.
        </p>
      </section>
    </div>
  )}



{selectedChapter === 'chapter58' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> MySQL Indexing   </h1>
      <p>
        In MySQL, <strong>an index</strong> is a data structure that improves the speed of data retrieval operations on
        a table by providing a fast way to look up rows. It is like a book's index, which helps you quickly find the
        content you need without scanning the entire book.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Features of an Index:</h2>
      <ol>
        <li>
          <strong>Improves Query Performance:</strong> Speeds up SELECT queries and WHERE clause conditions.
        </li><br/>
        <li>
          <strong>Organized Storage:</strong> Provides efficient organization of rows for faster access.
        </li><br/>
        <li>
          <strong>Cost of Maintenance:</strong> Can slow down INSERT, UPDATE, and DELETE operations because the index
          also needs to be updated.
        </li>
      </ol><br/>

      <h2 style={{paddingBottom:"6px"}}>Types of Indexes in MySQL:</h2>
      <ul>
        <li>
          <strong>Primary Index:</strong> Automatically created when a primary key is defined. Ensures unique and
          non-null values in the key column(s).
        </li><br/>
        <li>
          <strong>Unique Index:</strong> Ensures all values in the indexed column(s) are unique. Can be applied to
          non-primary key columns.
        </li><br/>
        <li>
          <strong>Full-Text Index:</strong> Used for full-text searches in TEXT columns. Commonly applied for natural
          language searches.
        </li><br/>
        <li>
          <strong>Spatial Index:</strong> Designed for spatial data types like geometry and geography.
        </li><br/>
        <li>
          <strong>Composite Index:</strong> An index on multiple columns to optimize queries involving those columns.
        </li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Creating an Index:</h2>
      <p>You can create an index using the <strong>CREATE INDEX</strong> or <strong>ALTER TABLE</strong> command:</p>
      <pre>
        <code>{`CREATE INDEX index_name ON table_name (column_name);`}</code>
      </pre>
      <p>Or:</p>
      <pre>
        <code>{`ALTER TABLE table_name ADD INDEX index_name (column_name);`}</code>
      </pre><br/>

      <h2>Viewing Existing Indexes:</h2>
      <p>
        To see the indexes of a table, use the <strong>SHOW INDEX</strong> command:
      </p>
      <pre>
        <code>{`SHOW INDEX FROM table_name;`}</code>
      </pre><br/>

      <h2>Example:</h2>
      <p>Suppose we have a table <code>employees</code>:</p>
      <pre>
        <code>{`CREATE TABLE employees (
  id INT PRIMARY KEY,
  name VARCHAR(100),
  department VARCHAR(50),
  salary INT
);`}</code>
      </pre>
      <p>
        If queries often filter by <code>department</code>, you can create an index:
      </p>
      <pre>
        <code>{`CREATE INDEX idx_department ON employees(department);`}</code>
      </pre>
      <p>This index speeds up queries like:</p>
      <pre>
        <code>{`SELECT * FROM employees WHERE department = 'HR';`}</code>
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>When to Use an Index:</h2>
      <ul>
        <li>When you frequently query or filter data using a specific column.</li><br/>
        <li>For columns used in JOIN, WHERE, or ORDER BY clauses.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Trade-offs of Using Indexes:</h2>
      <ul>
        <li>
          <strong>Advantages:</strong> Faster SELECT queries.
        </li><br/>
        <li>
          <strong>Disadvantages:</strong> Increased storage usage and slower write operations.
        </li>
      </ul><br/>
    </div>
  )}



{selectedChapter === 'chapter59' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Types of Indexes : Primary, Unique, and Full-Text  </h1>
   
      <h2>1. Primary Index</h2>
      <p>
        <strong>Definition:</strong> A primary index is automatically created when a <strong>primary key</strong> is
        defined on a table. It uniquely identifies each row in the table and ensures the indexed columns contain unique
        and non-null values.
      </p>
      <p>
        <strong>Purpose:</strong> To enforce data integrity and optimize row lookups.
      </p>
      <p>
        <strong>Behavior:</strong>
      </p>
      <ul>
        <li>Only one primary index is allowed per table.</li><br/>
        <li>Combines uniqueness and sorting of the indexed columns.</li>
      </ul><br/>
      <pre>
        <code>
{`CREATE TABLE users (
    id INT PRIMARY KEY,   -- Primary index created on 'id'
    name VARCHAR(100)
);

-- Example query
SELECT * FROM users WHERE id = 1;`}
        </code>
      </pre><br/>

      <h2>2. Unique Index</h2>
      <p>
        <strong>Definition:</strong> A unique index ensures that all values in the indexed column(s) are unique. It is
        similar to a primary index but allows multiple NULL values (if supported by the storage engine).
      </p>
      <p>
        <strong>Purpose:</strong> To enforce uniqueness in specific columns without making them the primary key.
      </p>
      <p>
        <strong>Behavior:</strong>
      </p>
      <ul>
        <li>Can be created on columns that aren't part of the primary key.</li><br/>
        <li>Multiple unique indexes can exist on a single table.</li>
      </ul><br/>
      <pre>
        <code>
{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    email VARCHAR(100),
    UNIQUE (email)        -- Unique index on 'email'
);

-- Example query
SELECT * FROM employees WHERE email = 'john.doe@example.com';`}
        </code>
      </pre><br/>

      <h2>3. Full-Text Index</h2>
      <p>
        <strong>Definition:</strong> A full-text index is used for efficient full-text searches, primarily in{' '}
        <strong>TEXT</strong>, <strong>CHAR</strong>, or <strong>VARCHAR</strong> columns.
      </p>
      <p>
        <strong>Purpose:</strong> To enable searches on large text fields, such as finding words or phrases within
        documents or descriptions.
      </p>
      <p>
        <strong>Behavior:</strong>
      </p>
      <ul>
        <li>Optimized for natural language search.</li><br/>
        <li>Not suited for small fields or exact matches (use a regular index instead).</li><br/>
        <li>Available only with specific storage engines (e.g., MyISAM and InnoDB).</li>
      </ul><br/>
      <pre>
        <code>
{`CREATE TABLE articles (
    id INT PRIMARY KEY,
    title VARCHAR(255),
    content TEXT,
    FULLTEXT (title, content) -- Full-text index on 'title' and 'content'
);

-- Example query
SELECT * FROM articles 
WHERE MATCH(title, content) 
AGAINST('MySQL indexing' IN NATURAL LANGUAGE MODE);`}
        </code>
      </pre>
      <p>
        Modes in <code>AGAINST</code>:
      </p>
      <ol>
        <li>
          <strong>Natural Language Mode:</strong> Searches as plain text.
        </li><br/>
        <li>
          <strong>Boolean Mode:</strong> Allows logical operators (<code>+</code>, <code>-</code>, <code>*</code>).
        </li><br/>
        <li>
          <strong>Query Expansion:</strong> Finds additional related results.
        </li>
      </ol><br/>

      <h2>Summary Table:</h2>
      <table>
        <thead>
          <tr>
            <th>Index Type</th>
            <th>Key Characteristics</th>
            <th>Best Use Case</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Primary</td>
            <td>Unique, non-null values; 1 per table; defines table structure</td>
            <td>Ensuring unique row identifiers (IDs)</td>
          </tr>
          <tr>
            <td>Unique</td>
            <td>Unique values; allows NULLs; multiple per table</td>
            <td>Enforcing uniqueness of emails, etc.</td>
          </tr>
          <tr>
            <td>Full-Text</td>
            <td>Optimized for large text searches; supports special queries</td>
            <td>Searching documents, articles, etc.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )}



{selectedChapter === 'chapter60' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Creating Indexes   </h1>
      <p>
        Indexes in MySQL are essential for optimizing database performance, especially for speeding up data retrieval
        operations. Here’s how you can create and manage indexes in MySQL.
      </p><br/>

      <section>
        <h2>1. Creating Indexes Using <code>CREATE INDEX</code></h2>
        <p>
          The <code>CREATE INDEX</code> statement allows you to add an index to an existing table.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`CREATE INDEX index_name ON table_name (column_name);`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    name VARCHAR(100),
    department VARCHAR(50)
);

-- Create an index on the 'department' column
CREATE INDEX idx_department ON employees(department);

-- Optimized query
SELECT * FROM employees WHERE department = 'HR';`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>2. Creating Indexes Using <code>ALTER TABLE</code></h2>
        <p>
          You can also create indexes on an existing table using the <code>ALTER TABLE</code> statement.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`ALTER TABLE table_name ADD INDEX index_name (column_name);`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`ALTER TABLE employees ADD INDEX idx_name (name);

-- Optimized query
SELECT * FROM employees WHERE name = 'John';`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>3. Creating Unique Indexes</h2>
        <p>
          A unique index ensures that all values in the indexed column(s) are unique.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`CREATE UNIQUE INDEX index_name ON table_name (column_name);`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`CREATE UNIQUE INDEX idx_email ON employees(email);

-- Alternatively
ALTER TABLE employees ADD UNIQUE (email);`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>4. Creating Composite Indexes</h2>
        <p>
          A composite index is an index on multiple columns, useful for optimizing queries with multiple conditions.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`CREATE INDEX index_name ON table_name (column1, column2);`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`CREATE INDEX idx_department_name ON employees(department, name);

-- Optimized query
SELECT * FROM employees WHERE department = 'HR' AND name = 'John';`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>5. Creating Full-Text Indexes</h2>
        <p>
          Full-text indexes are designed for efficient searches in large text columns.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`CREATE FULLTEXT INDEX index_name ON table_name (column_name);`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`CREATE TABLE articles (
    id INT PRIMARY KEY,
    title VARCHAR(255),
    content TEXT
);

CREATE FULLTEXT INDEX idx_content ON articles(content);

-- Full-text search example
SELECT * FROM articles
WHERE MATCH(content) AGAINST('indexing in MySQL' IN NATURAL LANGUAGE MODE);`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>6. Creating Spatial Indexes</h2>
        <p>
          Spatial indexes are used for geospatial data types like geometry.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`CREATE SPATIAL INDEX index_name ON table_name (geometry_column);`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`CREATE TABLE locations (
    id INT PRIMARY KEY,
    coordinates GEOMETRY NOT NULL,
    SPATIAL INDEX idx_coordinates (coordinates)
);`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>Viewing Existing Indexes</h2>
        <p>To list all indexes on a table:</p>
        <pre>
          <code>{`SHOW INDEX FROM table_name;`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>Summary Table of Index Creation Commands</h2>
        <table>
          <thead>
            <tr>
              <th>Index Type</th>
              <th>Command</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Regular Index</td>
              <td><code>CREATE INDEX idx_name ON table(column);</code></td>
            </tr>
            <tr>
              <td>Unique Index</td>
              <td><code>CREATE UNIQUE INDEX idx_name ON table(column);</code></td>
            </tr>
            <tr>
              <td>Composite Index</td>
              <td><code>CREATE INDEX idx_name ON table(column1, column2);</code></td>
            </tr>
            <tr>
              <td>Full-Text Index</td>
              <td><code>CREATE FULLTEXT INDEX idx_name ON table(column);</code></td>
            </tr>
            <tr>
              <td>Spatial Index</td>
              <td><code>CREATE SPATIAL INDEX idx_name ON table(geometry_column);</code></td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  )}

   



{selectedChapter === 'chapter61' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Dropping Indexes   </h1>

    
      <p>
        In MySQL, indexes can be removed if they are no longer needed, which helps reduce storage usage and improve performance during write operations (INSERT, UPDATE, DELETE).
      </p><br/>

      <section>
        <h2 style={{paddingBottom:"6px"}}>1. Dropping an Index</h2>
        <p>
          Use the <code>DROP INDEX</code> statement to delete an index from a table. This removes the index without affecting the table's data.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`DROP INDEX index_name ON table_name;`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    name VARCHAR(100),
    department VARCHAR(50),
    INDEX idx_department (department)
);

-- Drop the 'idx_department' index
DROP INDEX idx_department ON employees;`}</code>
        </pre>
      </section><br/>

      <section>
        <h2 style={{paddingBottom:"6px"}}>2. Dropping an Index Using <code>ALTER TABLE</code></h2>
        <p>
          Another way to drop an index is with the <code>ALTER TABLE</code> statement. This method is particularly useful when working with unique or composite indexes.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`ALTER TABLE table_name DROP INDEX index_name;`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`ALTER TABLE employees DROP INDEX idx_department;`}</code>
        </pre>
      </section><br/>

      <section>
        <h2 style={{paddingBottom:"6px"}}>3. Dropping a Primary Index</h2>
        <p>
          The primary index is dropped by removing the primary key constraint. Since a primary index is created automatically with a primary key, it cannot be dropped directly using <code>DROP INDEX</code>.
        </p>
        <p><strong>Syntax:</strong></p>
        <pre>
          <code>{`ALTER TABLE table_name DROP PRIMARY KEY;`}</code>
        </pre>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    name VARCHAR(100)
);

-- Drop the primary key (and its index)
ALTER TABLE employees DROP PRIMARY KEY;`}</code>
        </pre>
        <p>
          <strong>Note:</strong> If the primary key is dropped, you may need to define another primary key or unique index depending on your application's requirements.
        </p>
      </section><br/>

      <section>
        <h2 style={{paddingBottom:"6px"}}>4. Dropping Full-Text or Spatial Indexes</h2>
        <p>
          The process to drop a full-text or spatial index is the same as for regular indexes.
        </p>
        <p><strong>Example for Full-Text Index:</strong></p>
        <pre>
          <code>{`DROP INDEX idx_content ON articles;`}</code>
        </pre>
        <p><strong>Example for Spatial Index:</strong></p>
        <pre>
          <code>{`DROP INDEX idx_coordinates ON locations;`}</code>
        </pre>
      </section><br/>

      <section>
        <h2 style={{paddingBottom:"6px"}}>5. Viewing Existing Indexes</h2>
        <p>Before dropping an index, you may want to list all existing indexes for a table:</p>
        <pre>
          <code>{`SHOW INDEX FROM table_name;`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>Summary of Commands</h2>
        <table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Command</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Drop a Regular Index</td>
              <td><code>DROP INDEX index_name ON table_name;</code></td>
            </tr>
            <tr>
              <td>Drop an Index Using ALTER TABLE</td>
              <td><code>ALTER TABLE table_name DROP INDEX index_name;</code></td>
            </tr>
            <tr>
              <td>Drop a Primary Index</td>
              <td><code>ALTER TABLE table_name DROP PRIMARY KEY;</code></td>
            </tr>
            <tr>
              <td>View Existing Indexes</td>
              <td><code>SHOW INDEX FROM table_name;</code></td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  )}



{selectedChapter === 'chapter62' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Performance Impact of Indexing    </h1>

    
      <p>
        Indexing in MySQL is a double-edged sword. While it significantly improves query performance, it also comes with some trade-offs. Understanding the impact of indexing is crucial for optimizing database operations.
      </p><br/>

      <section>
        <h2 style={{paddingBottom:"6px"}}>1. Positive Impacts of Indexing</h2>
        <ul>
          <li>
            <strong>Faster Data Retrieval:</strong> 
            Indexes act like a lookup table, enabling MySQL to quickly locate rows without scanning the entire table.
          </li><br/>
          <li>
            <strong>Efficient Filtering:</strong> 
            Improves performance for queries using <code>WHERE</code>, <code>JOIN</code>, and <code>GROUP BY</code> clauses.
          </li><br/>
          <li>
            <strong>Improved Sorting:</strong> 
            Optimizes <code>ORDER BY</code> operations by avoiding full-table scans.
          </li><br/>
          <li>
            <strong>Enforces Constraints:</strong> 
            Unique and primary indexes ensure data integrity by preventing duplicate or null values.
          </li>
        </ul><br/>
      </section>

      <section>
        <h2 style={{paddingBottom:"6px"}}>2. Negative Impacts of Indexing</h2>
        <ul>
          <li>
            <strong>Increased Storage Requirements:</strong> 
            Indexes consume additional disk space proportional to the number of indexed columns and rows.
          </li><br/>
          <li>
            <strong>Slower Write Operations:</strong> 
            Operations like <code>INSERT</code>, <code>UPDATE</code>, and <code>DELETE</code> become slower because indexes must also be updated.
          </li><br/>
          <li>
            <strong>Maintenance Overhead:</strong> 
            Regular maintenance is required to keep indexes optimized, especially with frequent data modifications.
          </li>
        </ul><br/>
      </section>

      <section>
        <h2 style={{paddingBottom:"6px"}}>3. Impact on Query Execution</h2>
        <p>
          Indexes improve query performance by reducing the number of rows scanned. However, improper use or excessive indexing can negate these benefits.
        </p>
        <p><strong>Example of Performance Gain:</strong></p>
        <pre>
          <code>{`-- Without an index
SELECT * FROM employees WHERE department = 'HR'; 
-- Scans all rows in the table.

-- With an index on 'department'
CREATE INDEX idx_department ON employees(department);
SELECT * FROM employees WHERE department = 'HR'; 
-- Scans only matching rows.`}</code>
        </pre>
      </section><br/>

      <section>
        <h2 style={{paddingBottom:"6px"}}>4. Best Practices for Indexing</h2>
        <ul>
          <li>
            <strong>Index Frequently Queried Columns:</strong> 
            Focus on columns used in <code>WHERE</code>, <code>JOIN</code>, or <code>ORDER BY</code> clauses.
          </li><br/>
          <li>
            <strong>Use Composite Indexes Wisely:</strong> 
            Create multi-column indexes for queries involving multiple conditions.
          </li><br/>
          <li>
            <strong>Avoid Over-Indexing:</strong> 
            Too many indexes can degrade performance during write operations.
          </li><br/>
          <li>
            <strong>Monitor Query Performance:</strong> 
            Use the <code>EXPLAIN</code> command to analyze how indexes are used in queries.
          </li>
        </ul><br/>
      </section>

      <section>
        <h2 style={{paddingBottom:"6px"}}>5. Analyzing Index Performance</h2>
        <p>
          Use MySQL tools to monitor and analyze index usage:
        </p>
        <ul>
          <li>
            <strong>EXPLAIN Statement:</strong> 
            Provides details on how MySQL executes a query and uses indexes.
          </li><br/>
          <li>
            <strong>SHOW INDEX:</strong> 
            Lists all indexes in a table, helping identify unused or redundant indexes.
          </li>
        </ul>
        <p><strong>Example:</strong></p>
        <pre>
          <code>{`EXPLAIN SELECT * FROM employees WHERE department = 'HR';`}</code>
        </pre>
      </section><br/>

      <section>
        <h2>6. Summary of Trade-Offs</h2>
        <table>
          <thead>
            <tr>
              <th>Advantages</th>
              <th>Disadvantages</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Speeds up SELECT queries</td>
              <td>Slows down INSERT/UPDATE/DELETE operations</td>
            </tr>
            <tr>
              <td>Improves filtering and sorting</td>
              <td>Increases storage requirements</td>
            </tr>
            <tr>
              <td>Supports unique constraints</td>
              <td>Requires regular maintenance</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  )}



{selectedChapter === 'chapter63' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Primary Key  </h1>

    <p>
      A <strong>Primary Key</strong> is a fundamental concept in MySQL that enforces the uniqueness and integrity of rows within a table. It ensures that each record in a table is uniquely identifiable.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Key Characteristics of a Primary Key</h2>
    <ul>
      <li>
        <strong>Uniqueness:</strong> 
        <ul>
          <li>The values in a primary key column must be unique across the entire table.</li><br/>
          <li>No two rows can have the same primary key value.</li>
        </ul><br/>
      </li>
      <li>
        <strong>Non-Null:</strong>
        <ul>
          <li>A primary key column cannot have <code>NULL</code> values.</li><br/>
          <li>This ensures that every row has a valid identifier.</li>
        </ul><br/>
      </li>
      <li>
        <strong>Implicit Index:</strong> 
        <ul>
          <li>MySQL automatically creates a unique index for the primary key column(s), improving query performance.</li>
        </ul><br/>
      </li>
      <li>
        <strong>Single Primary Key per Table:</strong> 
        <ul>
          <li>Each table can have only one primary key.</li><br/>
          <li>The primary key can consist of one or multiple columns (composite key).</li>
        </ul>
      </li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>Defining a Primary Key</h2>
    <h3>1. At Table Creation</h3>
    <p>You can define a primary key during table creation:</p>
    <pre>
      <code>
{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    name VARCHAR(100),
    department VARCHAR(50)
);`}
      </code>
    </pre>
    <p>In this example, the <code>id</code> column is the primary key, ensuring uniqueness for each employee.</p><br/>

    <h3>2. Composite Primary Key</h3>
    <p>A primary key can consist of multiple columns:</p>
    <pre>
      <code>
{`CREATE TABLE orders (
    order_id INT,
    product_id INT,
    PRIMARY KEY (order_id, product_id)
);`}
      </code>
    </pre>
    <p>Here, the combination of <code>order_id</code> and <code>product_id</code> serves as the primary key.</p><br/>

    <h2 style={{paddingBottom:"6px"}}>Altering a Table to Add or Remove a Primary Key</h2>
    <h3>1. Adding a Primary Key</h3>
    <pre>
      <code>
{`ALTER TABLE employees ADD PRIMARY KEY (id);`}
      </code>
    </pre><br/>
    <h3>2. Removing a Primary Key</h3>
    <pre>
      <code>
{`ALTER TABLE employees DROP PRIMARY KEY;`}
      </code>
    </pre><br/>

    <h2 style={{paddingBottom:"6px"}}>Advantages of Using a Primary Key</h2>
    <ul>
      <li><strong>Enforces Data Integrity:</strong> Ensures that no duplicate or null values are present in the primary key column.</li><br/>
      <li><strong>Optimizes Query Performance:</strong> MySQL uses the primary key’s implicit index to speed up data retrieval.</li><br/>
      <li><strong>Supports Relational Integrity:</strong> Allows other tables to reference the primary key using foreign keys, creating relationships between tables.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Primary Keys</h2>
    <ul>
      <li>Use an <strong>integer-based</strong> primary key (e.g., <code>INT</code>) for better performance.</li><br/>
      <li>Avoid using <strong>business-critical data</strong> (e.g., email, phone) as a primary key.</li><br/>
      <li>Consider using <code>AUTO_INCREMENT</code> for automatically generating unique values:</li>
    </ul><br/>
    <pre>
      <code>
{`CREATE TABLE users (
    user_id INT AUTO_INCREMENT PRIMARY KEY,
    username VARCHAR(50),
    email VARCHAR(100)
);`}
      </code>
    </pre><br/>

    <h2 style={{paddingBottom:"6px"}}>Real-World Example</h2>
    <p>A table for a school database:</p>
    <pre>
      <code>
{`CREATE TABLE students (
    student_id INT AUTO_INCREMENT PRIMARY KEY,
    name VARCHAR(100) NOT NULL,
    grade_level INT
);`}
      </code>
    </pre>
    <p>
      In this example:
      <ul>
        <li><code>student_id</code> serves as a unique identifier for each student.</li><br/>
        <li>The <code>AUTO_INCREMENT</code> feature automatically generates sequential values for new rows.</li>
      </ul>
    </p>
  </div>
)}




{selectedChapter === 'chapter64' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Foreign Key    </h1>

    <p>
      A <strong>Foreign Key</strong> is a key used to establish and enforce a link between the data in two tables in a relational database. It acts as a constraint, ensuring data integrity by defining rules about the relationship between the parent table (referenced table) and the child table (referencing table).
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Key Characteristics of a Foreign Key</h2>
    <ul>
      <li>
        <strong>Referential Integrity:</strong> Ensures that the value in the foreign key column(s) of the child table matches a value in the primary key column(s) of the parent table.
      </li><br/>
      <li>
        <strong>Enforces Relationships:</strong> Links two tables by referencing a primary key or unique key in another table.
      </li><br/>
      <li>
        <strong>Maintains Consistency:</strong> Prevents invalid data by ensuring related data exists in the parent table.
      </li>
    </ul><br/>

    <h2>Defining a Foreign Key</h2>
    <h3>1. At Table Creation</h3>
    <p>
      You can define a foreign key during table creation by using the <code>FOREIGN KEY</code> keyword.
    </p>
    <pre>
      <code>
{`CREATE TABLE orders (
    order_id INT PRIMARY KEY,
    customer_id INT,
    FOREIGN KEY (customer_id) REFERENCES customers(customer_id)
);`}
      </code>
    </pre>
    <p>
      In this example, the <code>customer_id</code> column in the <code>orders</code> table references the <code>customer_id</code> column in the <code>customers</code> table.
    </p><br/>

    <h3>2. Adding a Foreign Key to an Existing Table</h3>
    <p>
      You can use the <code>ALTER TABLE</code> command to add a foreign key to an existing table.
    </p>
    <pre>
      <code>
{`ALTER TABLE orders 
ADD CONSTRAINT fk_customer 
FOREIGN KEY (customer_id) REFERENCES customers(customer_id);`}
      </code>
    </pre><br/>

    <h2>Cascading Actions</h2>
    <p>
      Foreign keys in MySQL support cascading actions to maintain referential integrity when changes occur in the parent table.
    </p>
    <ul>
      <li>
        <strong>ON DELETE CASCADE:</strong> Deletes rows in the child table when the corresponding row in the parent table is deleted.
      </li><br/>
      <li>
        <strong>ON UPDATE CASCADE:</strong> Updates rows in the child table when the corresponding row in the parent table is updated.
      </li>
    </ul><br/>
    <pre>
      <code>
{`CREATE TABLE orders (
    order_id INT PRIMARY KEY,
    customer_id INT,
    FOREIGN KEY (customer_id) REFERENCES customers(customer_id)
    ON DELETE CASCADE
    ON UPDATE CASCADE
);`}
      </code>
    </pre><br/>

    <h2 style={{paddingBottom:"6px"}}>Advantages of Using Foreign Keys</h2>
    <ul>
      <li>
        <strong>Ensures Data Integrity:</strong> Guarantees that data remains consistent across related tables.
      </li><br/>
      <li>
        <strong>Reduces Data Redundancy:</strong> Promotes normalization by linking tables instead of duplicating data.
      </li><br/>
      <li>
        <strong>Facilitates Database Relationships:</strong> Enables the creation of parent-child relationships between tables.
      </li>
    </ul><br/>

    <h2>Example Use Case</h2>
    <p>
      A database with <code>customers</code> and <code>orders</code> tables:
    </p>
    <pre>
      <code>
{`CREATE TABLE customers (
    customer_id INT PRIMARY KEY,
    name VARCHAR(100)
);

CREATE TABLE orders (
    order_id INT PRIMARY KEY,
    customer_id INT,
    FOREIGN KEY (customer_id) REFERENCES customers(customer_id)
    ON DELETE CASCADE
);`}
      </code>
    </pre>
    <p>
      - Deleting a customer from the <code>customers</code> table will automatically delete their orders from the <code>orders</code> table due to <code>ON DELETE CASCADE</code>.
    </p><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>
        Ensure the referenced column in the parent table is a <code>PRIMARY KEY</code> or <code>UNIQUE KEY</code>.
      </li><br/>
      <li>
        Use cascading actions judiciously to prevent unintended data loss.
      </li><br/>
      <li>
        Avoid circular references to maintain clarity and avoid complexity.
      </li>
    </ul>


  </div>
)}



{selectedChapter === 'chapter65' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Unique Constraint   </h1>

    <p>
      A <strong>Unique Constraint</strong> in MySQL ensures that all the values in a column (or a combination of columns) are distinct. This constraint is used to prevent duplicate entries in a table for the specified column(s), maintaining data integrity.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Key Characteristics of Unique Constraint</h2>
    <ul>
      <li>
        <strong>Ensures Uniqueness:</strong> No two rows can have the same value in the unique column(s), but <code>NULL</code> values are allowed (with some storage engines).
      </li><br/>
      <li>
        <strong>Allows Multiple Unique Constraints:</strong> A table can have multiple unique constraints applied to different columns.
      </li><br/>
      <li>
        <strong>Creates an Implicit Index:</strong> When a unique constraint is added, MySQL automatically creates an index on the constrained column(s).
      </li>
    </ul><br/>

    <h2>Defining a Unique Constraint</h2>
    <h3>1. At Table Creation</h3>
    <p>
      You can define a unique constraint during table creation using the <code>UNIQUE</code> keyword.
    </p>
    <pre>
      <code>
{`CREATE TABLE employees (
    employee_id INT PRIMARY KEY,
    email VARCHAR(100) UNIQUE
);`}
      </code>
    </pre>
    <p>
      In this example, the <code>email</code> column is constrained to have unique values.
    </p><br/>

    <h3>2. Adding a Unique Constraint to an Existing Table</h3>
    <p>
      Use the <code>ALTER TABLE</code> statement to add a unique constraint to an existing table.
    </p>
    <pre>
      <code>
{`ALTER TABLE employees 
ADD CONSTRAINT unique_email UNIQUE (email);`}
      </code>
    </pre><br/>

    <h2>Composite Unique Constraint</h2>
    <p>
      A composite unique constraint ensures the combination of values in multiple columns is unique.
    </p>
    <pre>
      <code>
{`CREATE TABLE orders (
    order_id INT,
    product_id INT,
    UNIQUE (order_id, product_id)
);`}
      </code>
    </pre>
    <p>
      In this example, the combination of <code>order_id</code> and <code>product_id</code> must be unique, even if individual values are not.
    </p><br/>

    <h2>Removing a Unique Constraint</h2>
    <p>
      To drop a unique constraint, use the <code>DROP INDEX</code> command (since unique constraints create indexes).
    </p>
    <pre>
      <code>
{`ALTER TABLE employees 
DROP INDEX unique_email;`}
      </code>
    </pre><br/>

    <h2>Advantages of Unique Constraint</h2>
    <ul>
      <li>
        <strong>Ensures Data Integrity:</strong> Prevents duplicate data entries.
      </li><br/>
      <li>
        <strong>Supports Query Optimization:</strong> The implicit index improves query performance for the constrained column(s).
      </li>
    </ul><br/>

    <h2>Example Use Case</h2>
    <p>
      A table for managing user accounts:
    </p>
    <pre>
      <code>
{`CREATE TABLE users (
    user_id INT AUTO_INCREMENT PRIMARY KEY,
    username VARCHAR(50) UNIQUE,
    email VARCHAR(100) UNIQUE
);`}
      </code>
    </pre>
    <p>
      - The <code>username</code> and <code>email</code> columns are constrained to have unique values, ensuring that no two users can have the same username or email.
    </p><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>
        Use unique constraints on columns where duplicate values are not allowed, such as emails, usernames, or identifiers.
      </li><br/>
      <li>
        Avoid using <code>NULL</code> in unique columns unless necessary, as it can lead to unexpected behavior.
      </li><br/>
      <li>
        Choose meaningful column(s) when defining composite unique constraints.
      </li>
    </ul>

  </div>
)}



{selectedChapter === 'chapter66' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Not Null Constraint    </h1>

    <p>
      The <strong>NOT NULL</strong> constraint in MySQL is used to ensure that a column cannot have <code>NULL</code> values. It enforces data integrity by requiring that every row in the table must have a value for the specified column.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Key Characteristics of NOT NULL Constraint</h2>
    <ul>
      <li>
        <strong>Prevents NULL Values:</strong> Ensures a column always has a value.
      </li><br/>
      <li>
        <strong>Default Behavior:</strong> If no value is provided during insertion, a default value (if defined) will be used.
      </li><br/>
      <li>
        <strong>Column-Specific:</strong> Applied to individual columns during table creation or modification.
      </li><br/>
    </ul>

    <h2>Defining a NOT NULL Constraint</h2>
    <h3>1. At Table Creation</h3>
    <p>
      You can define the <code>NOT NULL</code> constraint when creating a table.
    </p>
    <pre>
      <code>
{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    name VARCHAR(100) NOT NULL,
    department VARCHAR(50) NOT NULL
);`}
      </code>
    </pre>
    <p>
      In this example, the <code>name</code> and <code>department</code> columns cannot have <code>NULL</code> values.
    </p><br/>

    <h3>2. Adding NOT NULL Constraint to an Existing Table</h3>
    <p>
      Use the <code>ALTER TABLE</code> statement to add a <code>NOT NULL</code> constraint to an existing column.
    </p>
    <pre>
      <code>
{`ALTER TABLE employees 
MODIFY COLUMN name VARCHAR(100) NOT NULL;`}
      </code>
    </pre><br/>

    <h3>3. Removing NOT NULL Constraint</h3>
    <p>
      To remove the <code>NOT NULL</code> constraint, modify the column to allow <code>NULL</code> values.
    </p>
    <pre>
      <code>
{`ALTER TABLE employees 
MODIFY COLUMN name VARCHAR(100) NULL;`}
      </code>
    </pre><br/>

    <h2>Default Values with NOT NULL</h2>
    <p>
      When defining a <code>NOT NULL</code> column, you can specify a default value to ensure valid data insertion even if the user does not provide a value.
    </p>
    <pre>
      <code>
{`CREATE TABLE users (
    user_id INT AUTO_INCREMENT PRIMARY KEY,
    username VARCHAR(50) NOT NULL,
    created_at TIMESTAMP NOT NULL DEFAULT CURRENT_TIMESTAMP
);`}
      </code>
    </pre>
    <p>
      In this example, the <code>created_at</code> column will automatically store the current timestamp if no value is provided.
    </p><br/>

    <h2>Advantages of NOT NULL Constraint</h2>
    <ul>
      <li>
        <strong>Ensures Data Integrity:</strong> Prevents incomplete or missing data in critical columns.
      </li><br/>
      <li>
        <strong>Simplifies Queries:</strong> Eliminates the need to handle <code>NULL</code> values in queries.
      </li><br/>
      <li>
        <strong>Improves Application Logic:</strong> Reduces potential errors caused by unexpected <code>NULL</code> values.
      </li>
    </ul><br/>

    <h2>Example Use Case</h2>
    <p>
      A table for managing student records:
    </p>
    <pre>
      <code>
{`CREATE TABLE students (
    student_id INT AUTO_INCREMENT PRIMARY KEY,
    name VARCHAR(100) NOT NULL,
    enrollment_date DATE NOT NULL
);`}
      </code>
    </pre>
    <p>
      - The <code>name</code> and <code>enrollment_date</code> columns must always have values, ensuring that every student record is complete.
    </p><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>
        Use <code>NOT NULL</code> for columns where data is mandatory, such as identifiers or essential fields.
      </li><br/>
      <li>
        Combine <code>NOT NULL</code> with default values to simplify data entry and avoid errors.
      </li><br/>
      <li>
        Avoid overusing <code>NOT NULL</code> for optional or less critical fields.
      </li>
    </ul>


  </div>
)}

{selectedChapter === 'chapter67' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Default Values   </h1>

    <p>
      The <strong>DEFAULT</strong> value in MySQL is a feature used to define a pre-set value for a column when no specific value is provided during an insert operation. It helps ensure data consistency and simplifies data entry.
    </p><br/>

    <h2>Key Features of Default Values</h2>
    <ul>
      <li>
        <strong>Automatic Value Assignment:</strong> If no value is specified, the column automatically takes the default value.
      </li><br/>
      <li>
        <strong>Data Consistency:</strong> Ensures consistent and meaningful values in the database.
      </li><br/>
      <li>
        <strong>Applicable to Most Data Types:</strong> Default values can be assigned to columns with numeric, string, date, or time data types.
      </li>
    </ul><br/>

    <h2>Defining Default Values</h2>
    <h3>1. At Table Creation</h3>
    <p>
      You can specify a default value while creating a table.
    </p>
    <pre>
      <code>
{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    name VARCHAR(100),
    status VARCHAR(20) DEFAULT 'active',
    created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
);`}
      </code>
    </pre>
    <p>
      In this example:
      <ul>
        <li>The <code>status</code> column defaults to <code>'active'</code> if no value is provided.</li><br/>
        <li>The <code>created_at</code> column stores the current timestamp automatically.</li>
      </ul><br/>
    </p>

    <h3>2. Adding a Default Value to an Existing Column</h3>
    <p>
      You can add a default value to an existing column using the <code>ALTER TABLE</code> statement.
    </p>
    <pre>
      <code>
{`ALTER TABLE employees 
MODIFY COLUMN status VARCHAR(20) DEFAULT 'active';`}
      </code>
    </pre><br/>

    <h3>3. Removing a Default Value</h3>
    <p>
      To remove a default value, use the <code>ALTER TABLE</code> statement without specifying a default value.
    </p>
    <pre>
      <code>
{`ALTER TABLE employees 
MODIFY COLUMN status VARCHAR(20);`}
      </code>
    </pre><br/>

    <h2>Examples of Using Default Values</h2>
    <h3>1. Numeric Columns</h3>
    <pre>
      <code>
{`CREATE TABLE orders (
    order_id INT PRIMARY KEY,
    quantity INT DEFAULT 1
);`}
      </code>
    </pre>
    <p>
      The <code>quantity</code> column defaults to <code>1</code> if no value is provided.
    </p><br/>

    <h3>2. Date and Time Columns</h3>
    <pre>
      <code>
{`CREATE TABLE logs (
    log_id INT PRIMARY KEY,
    log_time TIMESTAMP DEFAULT CURRENT_TIMESTAMP
);`}
      </code>
    </pre>
    <p>
      The <code>log_time</code> column automatically stores the current timestamp.
    </p><br/>

    <h2>Advantages of Using Default Values</h2>
    <ul>
      <li>
        <strong>Streamlined Data Entry:</strong> Reduces the need for explicit values during insert operations.
      </li><br/>
      <li>
        <strong>Prevents Null Values:</strong> Ensures a meaningful value is always stored.
      </li><br/>
      <li>
        <strong>Improves Query Simplicity:</strong> Simplifies queries by reducing the need to handle missing values.
      </li>
    </ul><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>
        Use default values for columns where data is predictable or commonly repeated, such as status flags or timestamps.
      </li><br/>
      <li>
        Combine <code>DEFAULT</code> with the <code>NOT NULL</code> constraint to avoid null entries.
      </li><br/>
      <li>
        Be cautious with dynamic defaults like <code>CURRENT_TIMESTAMP</code> to ensure they align with your application's logic.
      </li>
    </ul><br/>

    <h2>Example Use Case</h2>
    <p>
      A table for managing user registrations:
    </p>
    <pre>
      <code>
{`CREATE TABLE users (
    user_id INT AUTO_INCREMENT PRIMARY KEY,
    username VARCHAR(50),
    role VARCHAR(20) DEFAULT 'user',
    created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
);`}
      </code>
    </pre>
    <p>
      In this table:
      <ul>
        <li>The <code>role</code> column defaults to <code>'user'</code>.</li><br/>
        <li>The <code>created_at</code> column automatically records the creation timestamp.</li>
      </ul>
    </p>

  </div>
)}


{selectedChapter === 'chapter68' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Check Constraint    </h1>

    <p>
      The <strong>CHECK</strong> constraint in MySQL is used to specify conditions that the values in a column must satisfy. It ensures that only valid data, as defined by the constraint, is inserted into the column.
    </p><br/>

    <h2>Key Features of CHECK Constraint</h2>
    <ul>
      <li>
        <strong>Data Validation:</strong> Enforces specific rules for data entered into a table.
      </li><br/>
      <li>
        <strong>Applied at Column or Table Level:</strong> Can be defined for a single column or multiple columns.
      </li><br/>
      <li>
        <strong>Custom Conditions:</strong> Allows conditions such as ranges, comparisons, and logical expressions.
      </li>
    </ul><br/>

    <h2>Defining a CHECK Constraint</h2>
    <h3>1. At Table Creation</h3>
    <p>
      You can define a CHECK constraint when creating a table.
    </p>
    <pre>
      <code>
{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    age INT CHECK (age >= 18),
    salary DECIMAL(10, 2) CHECK (salary > 0)
);`}
      </code>
    </pre>
    <p>
      In this example:
      <ul>
        <li>The <code>age</code> column must have a value of 18 or higher.</li><br/>
        <li>The <code>salary</code> column must have a positive value.</li>
      </ul>
    </p><br/>

    <h3>2. Adding a CHECK Constraint to an Existing Table</h3>
    <p>
      You can add a CHECK constraint to an existing table using the <code>ALTER TABLE</code> statement.
    </p>
    <pre>
      <code>
{`ALTER TABLE employees 
ADD CONSTRAINT chk_age CHECK (age >= 18);`}
      </code>
    </pre><br/>

    <h2>Examples of CHECK Constraint Usage</h2>
    <h3>1. Ensuring Data Ranges</h3>
    <p>
      To enforce that product prices are within a certain range:
    </p>
    <pre>
      <code>
{`CREATE TABLE products (
    product_id INT PRIMARY KEY,
    price DECIMAL(10, 2) CHECK (price BETWEEN 1 AND 1000)
);`}
      </code>
    </pre>
    <p>
      The <code>price</code> column must have a value between 1 and 1000.
    </p><br/>

    <h3>2. Combining Conditions</h3>
    <p>
      To enforce complex rules for user roles:
    </p>
    <pre>
      <code>
{`CREATE TABLE users (
    user_id INT PRIMARY KEY,
    role VARCHAR(50),
    CHECK (role IN ('admin', 'editor', 'viewer'))
);`}
      </code>
    </pre>
    <p>
      The <code>role</code> column must contain one of the specified values.
    </p>

    <h2>Limitations of CHECK Constraint in MySQL</h2>
    <ul>
      <li>
        CHECK constraints are supported starting from MySQL 8.0.16.
      </li><br/>
      <li>
        Prior versions of MySQL ignore CHECK constraints silently, so ensure compatibility.
      </li>
    </ul><br/>

    <h2>Advantages of CHECK Constraint</h2>
    <ul>
      <li>
        <strong>Data Integrity:</strong> Prevents invalid data from being entered.
      </li><br/>
      <li>
        <strong>Customizable Rules:</strong> Allows tailored constraints for specific columns or conditions.
      </li><br/>
      <li>
        <strong>Reduced Application Logic:</strong> Moves validation logic to the database layer.
      </li>
    </ul><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>
        Use CHECK constraints to enforce rules that are critical to the integrity of your data.
      </li><br/>
      <li>
        Combine CHECK constraints with other constraints like <code>NOT NULL</code> and <code>UNIQUE</code> for robust validation.
      </li><br/>
      <li>
        Always test your constraints to ensure they behave as expected.
      </li>
    </ul><br/>

    <h2>Example Use Case</h2>
    <p>
      A table for storing exam results:
    </p>
    <pre>
      <code>
{`CREATE TABLE exam_results (
    student_id INT PRIMARY KEY,
    score INT CHECK (score BETWEEN 0 AND 100)
);`}
      </code>
    </pre>
    <p>
      The <code>score</code> column ensures valid exam scores between 0 and 100.
    </p>

  </div>
)}



{selectedChapter === 'chapter69' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Views  </h1>

    <p>
      A <strong>view</strong> in MySQL is a virtual table based on the result set of a SQL query. It doesn't store data physically but provides a dynamic representation of data from one or more tables.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Key Features of Views</h2>
    <ul>
      <li>
        <strong>Virtual Table:</strong> A view is not stored in the database. Instead, the underlying query is executed each time the view is accessed.
      </li><br/>
      <li>
        <strong>Data Abstraction:</strong> Views can simplify complex queries by abstracting the logic and exposing only the relevant data.
      </li><br/>
      <li>
        <strong>Read-Only or Updatable:</strong> Depending on how a view is defined, it can be read-only or allow data modifications.
      </li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>Advantages of Using Views</h2>
    <ul>
      <li>
        <strong>Simplifies Complex Queries:</strong> Encapsulates complex joins and calculations, making them reusable and easier to use.
      </li><br/>
      <li>
        <strong>Data Security:</strong> Restricts user access to specific columns or rows by exposing only the necessary data.
      </li><br/>
      <li>
        <strong>Improved Maintainability:</strong> Allows changes to the view’s underlying query without affecting dependent applications.
      </li><br/>
    </ul>

    <h2 style={{paddingBottom:"6px"}}>Creating a View</h2>
    <p>
      Views are created using the <code>CREATE VIEW</code> statement. The syntax is:
    </p>
    <pre>
      <code>
{`CREATE VIEW view_name AS
SELECT columns
FROM table
WHERE condition;`}
      </code>
    </pre>

    <p><strong>Example:</strong></p>
    <pre>
      <code>
{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    name VARCHAR(100),
    salary DECIMAL(10, 2),
    department VARCHAR(50)
);

CREATE VIEW high_earners AS
SELECT name, salary
FROM employees
WHERE salary > 50000;`}
      </code>
    </pre>
    <p>
      The <code>high_earners</code> view exposes only the names and salaries of employees earning more than $50,000.
    </p><br/>

    <h2>Using a View</h2>
    <p>
      Once a view is created, you can query it like a regular table:
    </p>
    <pre>
      <code>{`SELECT * FROM high_earners;`}</code>
    </pre><br/>

    <h2>Modifying a View</h2>
    <p>
      Use the <code>CREATE OR REPLACE VIEW</code> statement to update an existing view:
    </p>
    <pre>
      <code>
{`CREATE OR REPLACE VIEW high_earners AS
SELECT name, salary, department
FROM employees
WHERE salary > 50000;`}
      </code>
    </pre><br/>

    <h2>Dropping a View</h2>
    <p>
      To delete a view, use the <code>DROP VIEW</code> statement:
    </p>
    <pre>
      <code>{`DROP VIEW high_earners;`}</code>
    </pre><br/>

    <h2>Limitations of Views</h2>
    <ul>
      <li>
        <strong>Performance:</strong> Since views are virtual tables, complex views can have performance overhead due to query execution.
      </li><br/>
      <li>
        <strong>Restrictions on Modifications:</strong> Some views are read-only and cannot be updated directly.
      </li>
    </ul><br/>

    <h2>Best Practices for Using Views</h2>
    <ul>
      <li>
        Use views for data abstraction and to simplify repetitive queries.
      </li><br/>
      <li>
        Avoid overly complex views to prevent performance issues.
      </li><br/>
      <li>
        Ensure proper indexing on underlying tables to optimize view performance.
      </li>
    </ul>
  </div>
)}



{selectedChapter === 'chapter70' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Creating Views    </h1>

  
    <p>
      A <strong>view</strong> in MySQL is a virtual table based on a SQL query. It provides an abstraction layer over the underlying table data, simplifying complex queries and enhancing data security.
    </p><br/>

    <h2>Steps to Create a View</h2>
    <p>
      The <code>CREATE VIEW</code> statement is used to define a view in MySQL. You can include a SELECT query to determine the data the view will expose.
    </p>

    <h3>Basic Syntax</h3>
    <pre>
      <code>
{`CREATE VIEW view_name AS
SELECT columns
FROM table
WHERE condition;`}
      </code>
    </pre>

    <h3>Example</h3>
    <p>Let’s create a view to display employees earning more than $50,000:</p>
    <pre>
      <code>
{`CREATE TABLE employees (
    id INT PRIMARY KEY,
    name VARCHAR(100),
    salary DECIMAL(10, 2),
    department VARCHAR(50)
);

-- Create the view
CREATE VIEW high_earners AS
SELECT name, salary
FROM employees
WHERE salary > 50000;`}
      </code>
    </pre>
    <p>
      The view <code>high_earners</code> shows only the names and salaries of employees who earn more than $50,000.
    </p><br/>

    <h2>Querying a View</h2>
    <p>
      Once a view is created, you can query it like a table:
    </p>
    <pre>
      <code>{`SELECT * FROM high_earners;`}</code>
    </pre><br/>

    <h2>Advanced View Creation</h2>
    <p>MySQL supports additional options when creating views:</p>

    <h3 style={{paddingBottom:"6px"}}>1. View with Aliases</h3>
    <pre>
      <code>
{`CREATE VIEW dept_summary AS
SELECT department AS dept, COUNT(*) AS total_employees
FROM employees
GROUP BY department;`}
      </code>
    </pre>
    <p>
      Here, the view <code>dept_summary</code> renames columns using aliases.
    </p><br/>

    <h3>2. View with JOIN</h3>
    <pre>
      <code>
{`CREATE TABLE departments (
    id INT PRIMARY KEY,
    name VARCHAR(100)
);

-- Create a view to join employees and departments
CREATE VIEW employee_department AS
SELECT employees.name, departments.name AS department_name
FROM employees
JOIN departments ON employees.department = departments.id;`}
      </code>
    </pre>
    <p>
      This view combines data from multiple tables using a join.
    </p><br/>

    <h2>Modifying a View</h2>
    <p>To update an existing view, use the <code>CREATE OR REPLACE VIEW</code> statement:</p>
    <pre>
      <code>
{`CREATE OR REPLACE VIEW high_earners AS
SELECT name, salary, department
FROM employees
WHERE salary > 50000;`}
      </code>
    </pre><br/>

    <h2>Best Practices for Creating Views</h2>
    <ul>
      <li>
        Use views to encapsulate complex queries for better readability and reusability.
      </li><br/>
      <li>
        Avoid overly complex views to maintain performance.
      </li><br/>
      <li>
        Ensure proper indexing on underlying tables to optimize view execution.
      </li>
    </ul><br/>

    <h2>Dropping a View</h2>
    <p>If a view is no longer needed, it can be removed using the <code>DROP VIEW</code> statement:</p>
    <pre>
      <code>{`DROP VIEW high_earners;`}</code>
    </pre>

    <p>
      This deletes the view <code>high_earners</code>, but the underlying table remains unaffected.
    </p><br/>

    <h2>Limitations</h2>
    <ul>
      <li>
        Views cannot include certain constructs like subqueries in the FROM clause or variables.
      </li><br/>
      <li>
        Some views may be read-only, depending on their definition.
      </li>
    </ul><br/>

    
  </div>
)}
  

 

{selectedChapter === 'chapter71' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Altering and Dropping Views    </h1>

   

    <h2>1. Altering Views</h2>
    <p>
      In MySQL, you cannot directly modify a view. Instead, you use the <code>CREATE OR REPLACE VIEW</code> statement to redefine the view with updated logic or structure.
    </p><br/>

    <h3>Syntax for Altering a View</h3>
    <pre>
      <code>
{`CREATE OR REPLACE VIEW view_name AS
SELECT columns
FROM table
WHERE condition;`}
      </code>
    </pre>

    <h3>Example</h3>
    <p>
      Suppose you have an existing view <code>high_earners</code> that selects employees with salaries over $50,000:
    </p>
    <pre>
      <code>
{`CREATE VIEW high_earners AS
SELECT name, salary
FROM employees
WHERE salary > 50000;`}
      </code>
    </pre>
    <p>
      To update the view to include the department column, use:
    </p>
    <pre>
      <code>
{`CREATE OR REPLACE VIEW high_earners AS
SELECT name, salary, department
FROM employees
WHERE salary > 50000;`}
      </code>
    </pre><br/>

    <h3 style={{paddingBottom:"6px"}}>Key Points</h3>
    <ul>
      <li>The <code>CREATE OR REPLACE</code> statement completely overwrites the existing view definition.</li><br/>
      <li>The view retains its name but adopts the new query structure.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>2. Dropping Views</h2>
    <p>
      If a view is no longer needed, you can remove it using the <code>DROP VIEW</code> statement.
    </p>

    <h3>Syntax for Dropping a View</h3>
    <pre>
      <code>
{`DROP VIEW view_name;`}
      </code>
    </pre>

    <h3>Example</h3>
    <p>
      To delete the <code>high_earners</code> view:
    </p>
    <pre>
      <code>
{`DROP VIEW high_earners;`}
      </code>
    </pre><br/>

    <h3>Dropping Multiple Views</h3>
    <p>
      You can drop multiple views in a single statement by separating their names with commas:
    </p>
    <pre>
      <code>
{`DROP VIEW view1, view2, view3;`}
      </code>
    </pre><br/>

    <h3 style={{paddingBottom:"6px"}}>Key Points</h3>
    <ul>
      <li>Dropping a view does not affect the underlying tables.</li><br/>
      <li>Ensure no other views or applications depend on the view before dropping it.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>3. Viewing Existing Views</h2>
    <p>
      Use the <code>SHOW FULL TABLES</code> command to list all views in the current database:
    </p>
    <pre>
      <code>
{`SHOW FULL TABLES WHERE TABLE_TYPE = 'VIEW';`}
      </code>
    </pre><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>
        Always verify dependencies before altering or dropping a view to avoid breaking applications or other views.
      </li><br/>
      <li>
        Use descriptive names for views to reflect their purpose and make management easier.
      </li>
    </ul><br/>

   
  </div>
)}




{selectedChapter === 'chapter72' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Advantages of Using Views    </h1>

    

    <p>
      Views in MySQL provide a virtual table derived from a query result. They offer various advantages that simplify database management and improve performance in certain scenarios. Below are the key benefits of using views:
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>1. Simplified Query Complexity</h2>
    <p>
      Views allow you to encapsulate complex queries into a single virtual table. This simplifies complex joins, aggregations, and calculations, making it easier to work with the data.
    </p>
    <pre>
      <code>
{`CREATE VIEW employee_summary AS
SELECT department, AVG(salary) AS avg_salary
FROM employees
GROUP BY department;`}
      </code>
    </pre>
    <p>
      In this example, the view <code>employee_summary</code> simplifies retrieving average salaries by department without needing to write the aggregation query every time.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>2. Enhanced Security</h2>
    <p>
      Views can be used to provide restricted access to certain columns or rows in a table. By creating a view that only exposes specific data, you can control access while keeping sensitive information hidden.
    </p>
    <pre>
      <code>
{`CREATE VIEW department_info AS
SELECT department, name
FROM employees;`}
      </code>
    </pre>
    <p>
      In this case, users can only see the department and name of employees, without accessing sensitive details like salaries or personal information.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>3. Data Abstraction</h2>
    <p>
      Views provide an abstraction layer over the database schema. Users can interact with the view as though it were a real table, without needing to understand the underlying data structures or complex queries.
    </p>
    <p>
      This abstraction allows for flexibility in changing the underlying schema without affecting the users of the view. For example, you can change table structures or relationships, and the view will continue to provide the same results, preserving application compatibility.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>4. Reusability and Maintainability</h2>
    <p>
      Once created, views can be reused in multiple queries, reducing redundancy in your SQL code. This helps maintain cleaner, more maintainable code.
    </p>
    <pre>
      <code>
{`SELECT * FROM employee_summary WHERE avg_salary > 50000;`}
      </code>
    </pre>
    <p>
      Instead of writing the aggregation query each time, you can simply refer to the view <code>employee_summary</code> in your SELECT statement, improving code reusability.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>5. Improved Performance (In Some Cases)</h2>
    <p>
      Views can sometimes improve performance by pre-aggregating or pre-filtering data. If the underlying query involves complex joins or calculations, storing the result in a view can speed up data retrieval, especially if the view is indexed.
    </p>
    <p>
      However, keep in mind that the performance benefits depend on the complexity of the view and the storage engine being used. In some cases, especially for complex views, performance may degrade if the query is not optimized.
    </p><br/>

    <h2>6. Logical Data Independence</h2>
    <p>
      Views provide logical data independence by allowing the database schema to change without affecting how users interact with the data. For example, you can change the underlying tables (e.g., renaming or splitting columns) without breaking existing applications that rely on the view.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Summary Table of Advantages</h2>
    <table>
      <thead>
        <tr>
          <th>Advantage</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Simplified Query Complexity</td>
          <td>Encapsulate complex queries into a virtual table, making data retrieval easier.</td>
        </tr>
        <tr>
          <td>Enhanced Security</td>
          <td>Control access to sensitive data by exposing only the necessary columns or rows.</td>
        </tr>
        <tr>
          <td>Data Abstraction</td>
          <td>Hide the complexity of database schema from users and provide a simplified interface.</td>
        </tr>
        <tr>
          <td>Reusability and Maintainability</td>
          <td>Reduce redundancy by reusing views across multiple queries.</td>
        </tr>
        <tr>
          <td>Improved Performance</td>
          <td>Pre-aggregate or pre-filter data to improve query performance (in certain cases).</td>
        </tr>
        <tr>
          <td>Logical Data Independence</td>
          <td>Allow schema changes without affecting applications using the view.</td>
        </tr>
      </tbody>
    </table><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>Use views for simplifying complex queries and improving readability.</li><br/>
      <li>Implement views for controlling access to sensitive data.</li><br/>
      <li>Ensure that views do not negatively impact performance, especially with large datasets.</li><br/>
      <li>Use indexing on views where applicable to improve query performance.</li>
    </ul><br/>

  </div>
)}


  

{selectedChapter === 'chapter73' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   Introduction to Stored Procedures  </h1>

   

    <p>
      A 'stored procedure' in MySQL is a set of SQL statements that are precompiled and stored in the database. Stored procedures allow you to encapsulate complex logic or repetitive tasks into a single executable routine that can be called and reused multiple times.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >Key Features of Stored Procedures</h2>
    <ul>
      <li><strong>Encapsulation of Logic</strong>: Stored procedures allow you to define complex SQL logic once and execute it whenever needed, without having to rewrite it each time.</li><br/>
      <li><strong>Performance Optimization</strong>: Stored procedures are precompiled, meaning the SQL statements inside them are optimized and executed more efficiently.</li><br/>
      <li><strong>Reusable Code</strong>: Once defined, a stored procedure can be executed multiple times from different locations, improving code reusability and reducing redundancy.</li><br/>
      <li><strong>Improved Security</strong>: By using stored procedures, you can grant users access to specific operations without exposing the underlying tables or sensitive data.</li><br/>
      <li><strong>Reduced Client-Server Traffic</strong>: Because stored procedures run on the server, they can reduce the amount of data transferred between the application and the database, improving performance in certain cases.</li>
    </ul><br/>

    <h2>Syntax for Creating a Stored Procedure</h2>
    <p>The basic syntax for creating a stored procedure in MySQL is:</p>
    <pre>
      <code>
{`CREATE PROCEDURE procedure_name (parameters)
BEGIN
   -- SQL statements
END;`}
      </code>
    </pre>

    <p>Here’s an example of creating a simple stored procedure:</p>
    <pre>
      <code>
{`CREATE PROCEDURE GetEmployeeDetails(IN emp_id INT)
BEGIN
   SELECT * FROM employees WHERE id = emp_id;
END;`}
      </code>
    </pre>
    <p>
      In this example, the procedure <code>GetEmployeeDetails</code> takes an employee ID as an input parameter and returns the corresponding employee details from the <code>employees</code> table.
    </p><br/>

    <h2>Executing a Stored Procedure</h2>
    <p>Once the stored procedure is created, you can execute it using the following syntax:</p>
    <pre>
      <code>
{`CALL procedure_name(arguments);`}
      </code>
    </pre>
    <p>
      For example, to execute the <code>GetEmployeeDetails</code> procedure for an employee with ID 101, you would run:
    </p>
    <pre>
      <code>
{`CALL GetEmployeeDetails(101);`}
      </code>
    </pre><br/>

    <h2 style={{paddingBottom:"6px"}} >Advantages of Stored Procedures</h2>
    <ul>
      <li><strong>Modularity</strong>: Stored procedures allow for a modular approach to database operations, making maintenance easier.</li><br/>
      <li><strong>Performance Benefits</strong>: They reduce the overhead associated with repeatedly parsing and optimizing the same SQL statements.</li><br/>
      <li><strong>Consistency</strong>: Stored procedures ensure that the same logic is used consistently, improving reliability and reducing errors.</li><br/>
      <li><strong>Access Control</strong>: Stored procedures can provide a controlled way of accessing and modifying data in the database.</li>
    </ul><br/>

    <h2>Limitations of Stored Procedures</h2>
    <ul>
      <li><strong>Complexity in Debugging</strong>: Stored procedures can be harder to debug compared to regular SQL queries, especially when dealing with errors in logic.</li><br/>
      <li><strong>Portability Issues</strong>: Stored procedures are often specific to the database system, meaning they may not be easily portable between different database platforms.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}} >Real-World Example</h2>
    <p>Consider a scenario where we need to calculate the total salary for an employee across multiple departments. Using a stored procedure, we can encapsulate the logic for this calculation:</p>
    <pre>
      <code>
{`CREATE PROCEDURE CalculateTotalSalary(IN emp_id INT)
BEGIN
   SELECT SUM(salary) AS total_salary FROM salaries
   WHERE employee_id = emp_id;
END;`}
      </code>
    </pre>
    <p>This procedure can now be called with any employee's ID to calculate their total salary across departments.</p><br/>

    <h2>Conclusion</h2>
    <p>Stored procedures are a powerful tool for managing complex logic in MySQL databases. They help improve performance, maintainability, and security by providing a centralized way to execute repetitive tasks.</p>

   
  </div>
)}



{selectedChapter === 'chapter74' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Creating Stored Procedures </h1>

    <p>
      A 'stored procedure' is a set of SQL statements that are stored and executed on the MySQL server. It allows you to encapsulate logic and business rules in the database, making your database operations more efficient and easier to maintain.
    </p><br/>

    <h2>Basic Syntax for Creating a Stored Procedure</h2>
    <p>The basic syntax for creating a stored procedure is:</p>
    <pre>
      <code>
{`CREATE PROCEDURE procedure_name (parameters)
BEGIN
   -- SQL statements
END;`}
      </code>
    </pre>
    <p>Here’s what the components mean:</p>
    <ul>
      <li><strong>CREATE PROCEDURE</strong>: This keyword defines the start of the procedure.</li><br/>
      <li><strong>procedure_name</strong>: The name of the stored procedure you're creating.</li><br/>
      <li><strong>parameters</strong>: Optional parameters that the procedure will accept when called (can be input, output, or both).</li><br/>
      <li><strong>BEGIN ... END</strong>: The body of the procedure where SQL statements are written to define the logic.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}} >Example 1: Simple Stored Procedure</h2>
    <p>Here’s an example of creating a simple stored procedure to fetch employee details based on their ID:</p>
    <pre>
      <code>
{`CREATE PROCEDURE GetEmployeeDetails(IN emp_id INT)
BEGIN
   SELECT * FROM employees WHERE id = emp_id;
END;`}
      </code>
    </pre>
    <p>In this example:
      - The procedure is named <code>GetEmployeeDetails</code>.
      - It takes one parameter, <code>emp_id</code>, which is the employee's ID.
      - The procedure executes a SELECT query to retrieve the details of the employee with the specified ID.
    </p><br/>

    <h2>Example 2: Procedure with Multiple Parameters</h2>
    <p>You can create a stored procedure with multiple parameters, such as updating an employee’s salary:</p>
    <pre>
      <code>
{`CREATE PROCEDURE UpdateEmployeeSalary(IN emp_id INT, IN new_salary DECIMAL(10, 2))
BEGIN
   UPDATE employees SET salary = new_salary WHERE id = emp_id;
END;`}
      </code>
    </pre>
    <p>This procedure updates the salary of an employee based on the provided <code>emp_id</code> and <code>new_salary</code> parameters.</p><br/>

    <h2 style={{paddingBottom:"6px"}} >Parameter Types</h2>
    <p>There are three types of parameters you can use when creating a stored procedure:</p>
    <ul>
      <li><strong>IN</strong>: Used for input parameters, which are passed when the procedure is called. These cannot be modified inside the procedure.</li><br/>
      <li><strong>OUT</strong>: Used for output parameters, which return values to the caller after the procedure executes.</li><br/>
      <li><strong>INOUT</strong>: Used for parameters that can both accept input values and return modified output values.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}} >Creating Stored Procedures with Output Parameters</h2>
    <p>If you want a stored procedure to return a value, you can use an <code>OUT</code> parameter. Here’s an example that returns the total salary of an employee:</p>
    <pre>
      <code>
{`CREATE PROCEDURE GetTotalSalary(IN emp_id INT, OUT total_salary DECIMAL(10, 2))
BEGIN
   SELECT SUM(salary) INTO total_salary FROM salaries WHERE employee_id = emp_id;
END;`}
      </code>
    </pre>
    <p>This procedure will calculate the total salary for an employee and store the result in the <code>total_salary</code> output parameter.</p><br/>

    <h2>Executing a Stored Procedure</h2>
    <p>Once the stored procedure is created, you can execute it using the <code>CALL</code> statement:</p>
    <pre>
      <code>
{`CALL procedure_name(arguments);`}
      </code>
    </pre>
    <p>For example, to execute the <code>GetEmployeeDetails</code> procedure for employee ID 101:</p>
    <pre>
      <code>
{`CALL GetEmployeeDetails(101);`}
      </code>
    </pre><br/>

    <h2>Best Practices for Creating Stored Procedures</h2>
    <ul>
      <li><strong>Use meaningful names</strong>: Choose descriptive names for procedures to make their purpose clear.</li><br/>
      <li><strong>Limit the size of stored procedures</strong>: Avoid creating procedures that are too large or complex. Break down logic into smaller, reusable procedures.</li><br/>
      <li><strong>Handle exceptions and errors</strong>: Use error handling within the procedure to ensure that the program can recover gracefully from any issues.</li><br/>
      <li><strong>Document the procedure</strong>: Always document the purpose and expected parameters of the stored procedure to make it easier for others to understand.</li>
    </ul><br/>

    <h2>Real-World Example</h2>
    <p>Let’s consider a procedure that calculates an employee’s bonus based on performance reviews:</p>
    <pre>
      <code>
{`CREATE PROCEDURE CalculateBonus(IN emp_id INT, IN review_score DECIMAL(3, 2), OUT bonus DECIMAL(10, 2))
BEGIN
   IF review_score >= 4.5 THEN
       SET bonus = 1000;
   ELSE
       SET bonus = 500;
   END IF;
END;`}
      </code>
    </pre>
    <p>This procedure takes an employee ID and review score as input, and calculates a bonus based on the score. It returns the bonus amount as an output parameter.</p>
    <br/>
    <h2>Conclusion</h2>
    <p>Creating stored procedures in MySQL is a powerful way to optimize and organize your SQL code. They enable you to reuse code, enforce business logic at the database level, and improve performance by reducing repetitive query processing.</p>

  </div>
)}



{selectedChapter === 'chapter75' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Using Parameters in Stored Procedures   </h1>

    

    <p>
      Stored procedures in MySQL allow you to use parameters to pass values to and from the procedure. Parameters are essential for making stored procedures dynamic and reusable.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >Types of Parameters in MySQL Stored Procedures</h2>
    <p>MySQL supports three types of parameters:</p>
    <ul>
      <li><strong>IN</strong>: Input parameter. It is used to pass data into the procedure when it is called. The value of an <code>IN</code> parameter cannot be modified within the procedure.</li><br/>
      <li><strong>OUT</strong>: Output parameter. It allows the procedure to return a value back to the caller. The value of an <code>OUT</code> parameter is set inside the procedure.</li><br/>
      <li><strong>INOUT</strong>: Input and Output parameter. It can be used to pass a value into the procedure and return a modified value to the caller.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}} >Defining Parameters in a Stored Procedure</h2>
    <p>When creating a stored procedure, parameters are defined after the procedure name inside parentheses. The syntax for defining parameters is:</p>
    <pre>
      <code>
{`CREATE PROCEDURE procedure_name (param1 datatype, param2 datatype, ...)
BEGIN
   -- SQL statements
END;`}
      </code>
    </pre>
    <p>You can specify the type of the parameter (`IN`, `OUT`, or `INOUT`) followed by the parameter name and its data type.</p><br/>

    <h2>Example 1: Using Input Parameters</h2>
    <p>Let’s start by defining a simple procedure with an <code>IN</code> parameter. This procedure fetches employee details based on the employee ID:</p>
    <pre>
      <code>
{`CREATE PROCEDURE GetEmployeeDetails(IN emp_id INT)
BEGIN
   SELECT * FROM employees WHERE id = emp_id;
END;`}
      </code>
    </pre>
    <p>In this example, the <code>emp_id</code> parameter is an input parameter, meaning the caller provides the value when the procedure is invoked. The procedure fetches the employee details where the <code>id</code> matches the passed <code>emp_id</code> value.</p>

    <br/>
    <h2>Example 2: Using Output Parameters</h2>
    <p>In this example, we define a stored procedure with an <code>OUT</code> parameter to calculate the total salary of a specific employee:</p>
    <pre>
      <code>
{`CREATE PROCEDURE GetEmployeeSalary(IN emp_id INT, OUT total_salary DECIMAL(10,2))
BEGIN
   SELECT SUM(salary) INTO total_salary FROM salaries WHERE employee_id = emp_id;
END;`}
      </code>
    </pre>
    <p>In this case:
      - The <code>emp_id</code> parameter is an <code>IN</code> parameter, passed into the procedure to filter by employee ID.
      - The <code>total_salary</code> is an <code>OUT</code> parameter, used to store and return the total salary for the employee.
    </p>
    <p>After executing this procedure, the <code>total_salary</code> will hold the computed value, which can be retrieved by the caller.</p>
    <br/>
    <h2>Example 3: Using Input and Output Parameters (INOUT)</h2>
    <p>In the case where you need both input and output functionality, you can use the <code>INOUT</code> parameter type. Here’s an example where an employee's bonus is calculated based on performance and the bonus is modified:</p>
    <pre>
      <code>
{`CREATE PROCEDURE CalculateBonus(INOUT emp_bonus DECIMAL(10, 2))
BEGIN
   IF emp_bonus >= 1000 THEN
      SET emp_bonus = emp_bonus + 500;
   ELSE
      SET emp_bonus = emp_bonus + 100;
   END IF;
END;`}
      </code>
    </pre>
    <p>In this example:
      - The <code>emp_bonus</code> parameter is an <code>INOUT</code> parameter. The caller passes the initial bonus value when calling the procedure.
      - Inside the procedure, the value of <code>emp_bonus</code> is modified based on the logic of the bonus calculation.
    </p>
    <p>The modified value of <code>emp_bonus</code> is returned to the caller once the procedure is executed.</p><br/>

    <h2>Calling Procedures with Parameters</h2>
    <p>Once you have defined a procedure with parameters, you can call it using the <code>CALL</code> statement. Here's how to pass parameters when calling a procedure:</p>
    <pre>
      <code>
{`CALL procedure_name(param1_value, param2_value, ...);`}
      </code>
    </pre>
    <p>For example, to call the <code>GetEmployeeDetails</code> procedure with <code>emp_id = 101</code>:</p>
    <pre>
      <code>
{`CALL GetEmployeeDetails(101);`}
      </code>
    </pre>
    <p>For calling a procedure with an <code>OUT</code> parameter, you'll need to declare a variable to hold the output value. Here’s how to do that:</p>
    <pre>
      <code>
{`DECLARE total DECIMAL(10, 2);
CALL GetEmployeeSalary(101, total);
SELECT total;`}
      </code>
    </pre>
    <p>This will call the <code>GetEmployeeSalary</code> procedure with <code>emp_id = 101</code> and retrieve the output value into the <code>total</code> variable.</p>
    <br/>

    <h2>Best Practices for Using Parameters</h2>
    <ul>
      <li><strong>Use clear and meaningful parameter names</strong>: Name parameters descriptively so that the purpose of the parameter is obvious.</li><br/>
      <li><strong>Validate input parameters</strong>: Ensure that input parameters are validated within the procedure to prevent errors or unexpected results.</li><br/>
      <li><strong>Handle output parameters properly</strong>: Make sure that output parameters are correctly assigned within the procedure before returning them.</li><br/>
      <li><strong>Use INOUT parameters for complex logic</strong>: INOUT parameters are useful when the caller needs to modify the value and retrieve the updated value after the procedure execution.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>Using parameters in stored procedures enables flexibility and reusability of SQL code. By allowing the passing of values in and out of the procedure, you can build dynamic and robust database applications.</p>
  
  </div>
)}



  

{selectedChapter === 'chapter76' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Creating and Using Functions   </h1>

   
    <p>
      In MySQL, a function is a stored routine that returns a single value. Functions are similar to stored procedures but are primarily used to return a value based on the input parameters, and they can be used within SQL statements.
    </p><br/>

    <h2>Creating Functions in MySQL</h2>
    <p>Functions in MySQL are created using the <code>CREATE FUNCTION</code> statement. Here’s the basic syntax:</p>
    <pre>
      <code>
{`CREATE FUNCTION function_name (param1 datatype, param2 datatype, ...)
RETURNS return_datatype
BEGIN
   -- function logic
   RETURN return_value;
END;`}
      </code>
    </pre>
    <p>The function can accept parameters, perform some logic, and return a result. It is important to specify the <code>RETURNS</code> clause to define the data type of the returned value.</p>

    <br/>
    <h2>Example 1: Creating a Simple Function</h2>
    <p>This example demonstrates how to create a simple function that takes two integers as input and returns their sum:</p>
    <pre>
      <code>
{`CREATE FUNCTION AddNumbers(a INT, b INT)
RETURNS INT
BEGIN
   RETURN a + b;
END;`}
      </code>
    </pre>
    <p>In this function:
      - The function name is <code>AddNumbers</code>.
      - It takes two parameters, <code>a</code> and <code>b</code>, both of type <code>INT</code>.
      - The function returns an <code>INT</code> that represents the sum of the two parameters.
    </p>

    <h2>Using the Function</h2>
    <p>Once the function is created, you can use it in SQL queries just like any other expression. For example, to use the <code>AddNumbers</code> function:</p>
    <pre>
      <code>
{`SELECT AddNumbers(5, 10);`}
      </code>
    </pre>
    <p>This query calls the <code>AddNumbers</code> function and returns <code>15</code>.</p>

    <br/>
    <h2>Example 2: Function with Conditional Logic</h2>
    <p>Let’s create a function that determines if a number is even or odd:</p>
    <pre>
      <code>
{`CREATE FUNCTION IsEvenOrOdd(num INT)
RETURNS VARCHAR(10)
BEGIN
   IF num % 2 = 0 THEN
      RETURN 'Even';
   ELSE
      RETURN 'Odd';
   END IF;
END;`}
      </code>
    </pre>
    <p>This function:
      - Takes an integer <code>num</code> as input.
      - Returns <code>'Even'</code> if the number is divisible by 2, otherwise it returns <code>'Odd'</code>.
    </p>


    <h2>Using the Conditional Function</h2>
    <p>To use the <code>IsEvenOrOdd</code> function, you can call it in a query like this:</p>
    <pre>
      <code>
{`SELECT IsEvenOrOdd(7);`}
      </code>
    </pre>
    <p>This would return <code>'Odd'</code> because 7 is not divisible by 2.</p>

    <br/>
    <h2>Example 3: Function with Multiple Parameters</h2>
    <p>Let’s create a function that calculates the total price of an item including tax:</p>
    <pre>
      <code>
{`CREATE FUNCTION CalculateTotalPrice(price DECIMAL(10,2), tax_rate DECIMAL(5,2))
RETURNS DECIMAL(10,2)
BEGIN
   RETURN price + (price * tax_rate / 100);
END;`}
      </code>
    </pre>
    <p>This function:
      - Takes two parameters: <code>price</code> and <code>tax_rate</code>.
      - Returns the total price including tax by calculating <code>price + (price * tax_rate / 100)</code>.
    </p>

    <h2>Using the CalculateTotalPrice Function</h2>
    <p>To use this function, you can pass the price and tax rate as arguments:</p>
    <pre>
      <code>
{`SELECT CalculateTotalPrice(100, 10);`}
      </code>
    </pre>
    <p>This will return <code>110.00</code>, as it adds 10% tax to the base price of 100.</p>

    <br/>
    <h2>Best Practices for Functions in MySQL</h2>
    <ul>
      <li><strong>Limit Side Effects:</strong> Functions should not modify data (e.g., using <code>INSERT</code>, <code>UPDATE</code>, or <code>DELETE</code> statements). They are intended to return values based on inputs.</li><br/>
      <li><strong>Use Functions for Reusable Logic:</strong> Functions are great for encapsulating commonly used logic, such as calculations or transformations that can be used across multiple queries.</li><br/>
      <li><strong>Keep Functions Simple:</strong> Try to keep your functions focused on one task to make them easier to maintain and debug.</li><br/>
      <li><strong>Performance Considerations:</strong> While functions can improve reusability, overusing them in large datasets or complex operations might affect performance. Be mindful of this when designing your schema.</li>
    </ul><br/>

    <h2>Dropping Functions</h2>
    <p>If you no longer need a function, you can drop it using the <code>DROP FUNCTION</code> statement:</p>
    <pre>
      <code>
{`DROP FUNCTION function_name;`}
      </code>
    </pre>
    <p>For example, to drop the <code>CalculateTotalPrice</code> function:</p>
    <pre>
      <code>
{`DROP FUNCTION CalculateTotalPrice;`}
      </code>
    </pre><br/>

    <h2>Conclusion</h2>
    <p>Functions in MySQL provide a powerful way to encapsulate logic that can be reused in queries. By creating functions, you can simplify complex operations and ensure that common logic is consistent across your application.</p>
   
  </div>
)}


 

{selectedChapter === 'chapter77' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Advantages of Stored Procedures  </h1>



    <p>
      Stored procedures are a powerful feature in MySQL that can significantly enhance the performance, security, and maintainability of your database applications. They encapsulate SQL logic into reusable routines that can be executed on the server side, reducing the amount of data transferred between the application and the database.
    </p><br/>

    <h2>Key Advantages of Stored Procedures</h2>

    <h3>1. Improved Performance</h3>
    <p>
      - Reduced Network Traffic: When using stored procedures, you send only the call to the procedure, rather than sending multiple queries from the application. This can reduce the amount of network traffic and latency, especially for complex operations.
      - Execution Plan Optimization: MySQL caches the execution plan of stored procedures. Once compiled, the procedure is executed directly, without needing to reparse and reoptimize the SQL statements, thus improving the execution speed.
    </p><br/>

    <h3>2. Code Reusability</h3>
    <p>
      Stored procedures allow you to encapsulate commonly used SQL logic into a single unit. This means you can reuse the procedure across multiple applications or queries, reducing the need to duplicate code in various parts of your application.
    </p>
    <p>
      For example, a stored procedure that calculates the total sales for a given period can be called from different parts of the application without rewriting the SQL each time.
    </p><br/>

    <h3>3. Enhanced Security</h3>
    <p>
      Stored procedures can be used to restrict direct access to sensitive tables. By allowing only specific stored procedures to interact with the database, you can control which operations users can perform.
    </p>
    <p>
      - Granular Permissions: You can grant users permission to execute specific stored procedures while restricting direct access to the underlying tables.
      - SQL Injection Protection: Since stored procedures encapsulate SQL logic and handle input parameters internally, they can help prevent SQL injection attacks, making applications more secure.
    </p><br/>

    <h3>4. Maintainability</h3>
    <p>
      By encapsulating SQL logic within stored procedures, the code becomes more maintainable. If business logic changes, you only need to modify the stored procedure, rather than updating SQL statements across multiple application files or scripts.
    </p>
    <p>
      This centralization of logic makes the codebase cleaner and easier to manage, especially in large applications where SQL queries are reused in many different places.
    </p><br/>

    <h3>5. Better Error Handling</h3>
    <p>
      Stored procedures in MySQL provide built-in error handling using the <code>DECLARE ... HANDLER</code> statement. You can handle various types of errors gracefully, such as constraints violations, data type mismatches, or division by zero errors.
    </p>
    <p>
      For example, if a stored procedure tries to insert a duplicate value, you can catch the error and return a custom message or perform an alternative action instead of just failing.
    </p><br/>

    <h3>6. Transaction Control</h3>
    <p>
      Stored procedures allow you to manage transactions within the procedure itself. This gives you fine-grained control over commit and rollback operations, ensuring that a group of related statements either all succeed or fail as a unit.
    </p><br/>
    <p>
      - Atomic Operations: Stored procedures can encapsulate multiple SQL statements that should either complete together or not at all, making your database operations more reliable.
    </p><br/>

    <h3>7. Database Abstraction</h3>
    <p>
      By using stored procedures, the application interacts with the database through a predefined interface. This abstraction layer allows changes to the database schema or structure without affecting the application code.
    </p>
    <p>
      For example, if you need to modify how data is fetched (e.g., changing a join or the table structure), you can do so within the stored procedure without changing any application code that uses the procedure.
    </p><br/>

    <h3>8. Reduced Application Complexity</h3>
    <p>
      Complex SQL operations, such as aggregations or multiple JOINs, can be encapsulated within stored procedures, reducing the complexity of SQL code in your application logic. This makes it easier for developers to write and maintain code, especially in larger systems.
    </p><br/>

    <h3>9. Centralized Business Logic</h3>
    <p>
      By placing the business logic in stored procedures, the database becomes the central point for processing data. This is particularly useful for large-scale applications where business rules need to be enforced consistently across multiple applications and environments.
    </p><br/>

    <h3>10. Improved Data Integrity</h3>
    <p>
      Stored procedures can help enforce data integrity by ensuring that data is processed according to the rules defined in the procedure. This reduces the chances of incorrect or inconsistent data being entered into the database.
    </p><br/>

    <h2>Summary Table of Stored Procedure Advantages</h2>

    <table>
      <thead>
        <tr>
          <th>Advantage</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Performance</td>
          <td>Reduces network traffic and improves execution time with cached plans.</td>
        </tr>
        <tr>
          <td>Reusability</td>
          <td>Encapsulates logic for use across multiple applications or queries.</td>
        </tr>
        <tr>
          <td>Security</td>
          <td>Enhances security by restricting direct access and preventing SQL injection.</td>
        </tr>
        <tr>
          <td>Maintainability</td>
          <td>Centralizes logic for easier maintenance and reduces code duplication.</td>
        </tr>
        <tr>
          <td>Error Handling</td>
          <td>Provides built-in mechanisms for error management.</td>
        </tr>
        <tr>
          <td>Transaction Control</td>
          <td>Allows precise control over commits and rollbacks within transactions.</td>
        </tr>
        <tr>
          <td>Abstraction</td>
          <td>Offers an abstraction layer that isolates the application from direct database changes.</td>
        </tr>
        <tr>
          <td>Reduced Complexity</td>
          <td>Simplifies application code by offloading complex operations to the database.</td>
        </tr>
        <tr>
          <td>Centralized Business Logic</td>
          <td>Enforces consistent rules across multiple applications through the database.</td>
        </tr>
        <tr>
          <td>Data Integrity</td>
          <td>Ensures correct data processing according to predefined rules.</td>
        </tr>
      </tbody>
    </table><br/>

    <h2>Conclusion</h2>
    <p>
      Stored procedures provide a wide range of benefits in MySQL, including improved performance, security, maintainability, and error handling. By encapsulating logic and reducing dependency on application-side SQL, stored procedures help create more efficient, secure, and manageable database applications.
    </p>
   
  </div>
)}





{selectedChapter === 'chapter78' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  What are Triggers   </h1>

  
    <p>
      A trigger in MySQL is a special type of stored procedure that automatically executes or "fires" when a specific event occurs in a database table. Triggers are useful for enforcing business rules, auditing data changes, or automating certain actions whenever data modifications (INSERT, UPDATE, DELETE) happen in the database.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >Key Features of Triggers</h2>

    <h3>1. Automatic Execution</h3>
    <p>
      Triggers are automatically executed in response to changes in a table. They are tied to specific events (INSERT, UPDATE, DELETE) and are defined to occur either before or after these events.
    </p><br/>

    <h3>2. Event-Driven</h3>
    <p>
      A trigger is executed when a specific event takes place in a table. The event can be:
      - 'INSERT': When a new row is added to the table.
      - 'UPDATE': When an existing row is modified.
      - 'DELETE: When a row is deleted from the table.
    </p><br/>

    <h3>3. Before or After Events</h3>
    <p>
      Triggers can be set to execute:
      - 'BEFORE': The trigger will execute before the event takes place (for example, before inserting or updating data).
      - 'AFTER': The trigger will execute after the event takes place (for example, after inserting or updating data).
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >Types of Triggers</h2>
    <p><ul>
      <h5>There are several types of triggers in MySQL based on the timing of execution:</h5>
      <li> 'BEFORE INSERT': Triggered before a new row is inserted into the table.</li><br/>
      <li> 'AFTER INSERT': Triggered after a new row has been inserted into the table.</li><br/>
      <li> 'BEFORE UPDATE': Triggered before an existing row is updated.</li><br/>
      <li> 'AFTER UPDATE': Triggered after an existing row has been updated.</li><br/>
      <li> 'BEFORE DELETE': Triggered before a row is deleted.</li><br/>
      <li> 'AFTER DELETE': Triggered after a row has been deleted.</li>
      </ul>
      </p><br/>

    <h2>How Triggers Work</h2>
    <p>
      Triggers are associated with a table and are automatically invoked when the specified event occurs. Triggers can access the values of the row being affected (through `NEW` for INSERT/UPDATE or `OLD` for DELETE/UPDATE). This allows for data validation or modification before or after the event.
    </p><br/>

    <h2>Creating a Trigger</h2>
    <p>
      The syntax for creating a trigger in MySQL is as follows:
    </p>
    <pre>
      <code>{`
CREATE TRIGGER trigger_name
{BEFORE | AFTER} {INSERT | UPDATE | DELETE}
ON table_name
FOR EACH ROW
BEGIN
-- Trigger logic here
END;`}</code>
    </pre>
    <p>
      For example, a trigger that logs changes made to the `employees` table could be created as follows:
    </p>
    <pre>
      <code>{`
CREATE TRIGGER log_employee_update
AFTER UPDATE ON employees
FOR EACH ROW
BEGIN
 INSERT INTO employee_audit_log (employee_id, old_salary, new_salary, change_time)
 VALUES (OLD.id, OLD.salary, NEW.salary, NOW());
END;
`}</code>
    </pre><br/>

    <h2 style={{paddingBottom:"6px"}} >Use Cases for Triggers</h2>

    <h3>1. Data Integrity and Validation</h3>
    <p>
      Triggers can enforce business rules and data integrity by automatically validating data before it is inserted or updated. For example, you can create a trigger to ensure that a value in a column falls within a specified range.
    </p><br/>

    <h3>2. Auditing Changes</h3>
    <p>
      Triggers are often used for auditing purposes. They can log changes made to a table, such as insertions, updates, and deletions, by capturing the old and new values and saving them to an audit table.
    </p><br/>

    <h3>3. Synchronizing Data</h3>
    <p>
      Triggers can help maintain data consistency between related tables. For example, you can create a trigger to update a "last modified" timestamp whenever a row in a table is updated.
    </p><br/>

    <h3>4. Automating Actions</h3>
    <p>
      Triggers can be used to automatically perform certain actions in response to data changes. For example, a trigger could automatically update the inventory count after a sale in an e-commerce system.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >Limitations of Triggers</h2>
    <p>
      While triggers are powerful, they also come with certain limitations:
      - Cannot call stored procedures: Triggers cannot directly call stored procedures or functions.
      - Performance Impact: Overuse of triggers can lead to performance issues, as they add overhead to the database operations.
      - Limited error handling: MySQL triggers do not support complex error handling or transaction control.
    </p><br/>

    <h2>Summary Table of Trigger Types</h2>
    <table>
      <thead>
        <tr>
          <th>Trigger Type</th>
          <th>Event</th>
          <th>Timing</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>BEFORE INSERT</td>
          <td>Insert</td>
          <td>Before</td>
        </tr>
        <tr>
          <td>AFTER INSERT</td>
          <td>Insert</td>
          <td>After</td>
        </tr>
        <tr>
          <td>BEFORE UPDATE</td>
          <td>Update</td>
          <td>Before</td>
        </tr>
        <tr>
          <td>AFTER UPDATE</td>
          <td>Update</td>
          <td>After</td>
        </tr>
        <tr>
          <td>BEFORE DELETE</td>
          <td>Delete</td>
          <td>Before</td>
        </tr>
        <tr>
          <td>AFTER DELETE</td>
          <td>Delete</td>
          <td>After</td>
        </tr>
      </tbody>
    </table><br/>

    <h2>Conclusion</h2>
    <p>
      Triggers in MySQL offer a powerful mechanism for automating database actions, enforcing data integrity, and auditing changes. However, they should be used thoughtfully to avoid performance pitfalls and limitations. By understanding when and how to use triggers effectively, you can leverage their benefits to maintain a more secure, efficient, and consistent database system.
    </p>

    
  </div>
)}

 

{selectedChapter === 'chapter79' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> BEFORE and AFTER Triggers </h1>

  
    <p>
      In MySQL, triggers can be defined to execute before or after a specified event occurs on a table. These triggers are classified as 'BEFORE triggers' and 'AFTER triggers' based on their execution timing relative to the event (INSERT, UPDATE, or DELETE).
    </p><br/>

    <h2>BEFORE Triggers</h2>
    <p>
      A **BEFORE trigger** is executed before the event is performed on the database. This allows you to modify the data before it is inserted, updated, or deleted, giving you the ability to perform validation or changes on the data.
    </p><br/>

    <h3>Use Cases for BEFORE Triggers</h3>
    <ul>
      <li><strong>Data Validation:</strong> Ensuring that the data meets certain criteria before being committed to the database.</li><br/>
      <li><strong>Pre-processing Data:</strong> Modifying the data (e.g., adjusting values) before inserting or updating it in the database.</li><br/>
      <li><strong>Preventing Invalid Operations:</strong> You can cancel an operation by modifying data or raising an error within the trigger, preventing it from proceeding.</li>
    </ul><br/>

    <h3>Example of BEFORE Trigger</h3>
    <p>
      Let's say you have a table of `employees`, and you want to ensure that a negative salary cannot be inserted:
    </p>
    <pre>
      <code>{`
CREATE TRIGGER before_insert_employee
BEFORE INSERT ON employees
FOR EACH RO  IF NEW.salary &lt;    SIGNAL SQLSTATE     SET MESSAGE_TEXT = 'Salary cannot be neg  END;`}
      </code>
    </pre>
    <p>
      In this example, if a negative salary value is attempted to be inserted, the trigger prevents the operation and raises an error with a message.
    </p><br/>

    <h2>AFTER Triggers</h2>
    <p>
      An 'AFTER trigger' is executed after the event has been completed. This type of trigger is useful for logging changes, synchronizing data, or performing actions that should only occur after the data has been committed to the database.
    </p><br/>

    <h3>Use Cases for AFTER Triggers</h3>
    <ul>
      <li><strong>Logging Changes:</strong> After an update or deletion, you can log the change in an audit table.</li><br/>
      <li><strong>Maintaining Related Data:</strong> Synchronizing or updating related data in other tables after an update or insert operation.</li><br/>
      <li><strong>Sending Notifications:</strong> You can trigger notifications or other external actions after changes to important data.</li>
    </ul><br/>

    <h3>Example of AFTER Trigger</h3>
    <p>
      In the case of the `employees` table, an AFTER trigger can be used to log changes to the employee salary in an audit table:
    </p>
    <pre>
      <code>{`
CREATE TRIGGER after_update_employee
AFTER UPDATE ON employees
FOR EACH ROW
BEGIN
  INSERT INTO salary_audit_log (employee_id, old_salary, new_salary, change_time)
  VALUES (OLD.id, OLD.salary, NEW.salary, NOW());
END;`}
      </code>
    </pre>
    <p>
      Here, whenever the salary of an employee is updated, the trigger logs the old and new salary in the `salary_audit_log` table.
    </p><br/>

    <h2>Comparison Between BEFORE and AFTER Triggers</h2>
    <table>
      <thead>
        <tr>
          <th>Trigger Type</th>
          <th>Execution Timing</th>
          <th>Use Case</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>BEFORE Trigger</td>
          <td>Executed before the event (INSERT, UPDATE, DELETE)</td>
          <td>Data validation, modifying values before data is committed</td>
        </tr>
        <tr>
          <td>AFTER Trigger</td>
          <td>Executed after the event (INSERT, UPDATE, DELETE)</td>
          <td>Logging changes, synchronizing related data, or performing post-processing actions</td>
        </tr>
      </tbody>
    </table><br/>

    <h2>Considerations When Using BEFORE and AFTER Triggers</h2>
    <ul>
      <li><strong>Performance Impact:</strong> Both BEFORE and AFTER triggers add overhead to database operations. Overuse of triggers can impact performance, especially if they involve complex logic.</li><br/>
      <li><strong>Order of Execution:</strong> In the case of multiple triggers of the same type (BEFORE or AFTER), the order in which they are executed is not guaranteed unless explicitly set in the table schema (with `ORDER` in `CREATE TRIGGER` in some databases, though MySQL does not support this feature directly).</li><br/>
      <li><strong>Trigger Limitations:</strong> Triggers cannot call other triggers directly and have limitations when performing complex actions (e.g., error handling or transaction control).</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      Both 'BEFORE' and 'AFTER triggers' are powerful tools in MySQL, each serving distinct use cases. BEFORE triggers allow you to validate or modify data before it's committed, while AFTER triggers help with tasks that should occur after the data is finalized, such as logging or synchronizing related data. Choosing between BEFORE and AFTER triggers depends on the specific requirements of your use case and the timing of your data actions.
    </p>

    
  </div>
)}




{selectedChapter === 'chapter80' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Creating Triggers  </h1>

    
    <p>
      Triggers in MySQL are special types of stored procedures that automatically execute when certain events occur on a table. You can create triggers to respond to actions like 'INSERT', 'UPDATE', or 'DELETE' on a table, allowing you to implement business rules, audit changes, and maintain data consistency.
    </p><br/>

    <h2>Basic Syntax for Creating a Trigger</h2>
    <p>
      The syntax for creating a trigger in MySQL is as follows:
    </p>
    <pre>
      <code>{`
CREATE TRIGGER trigger_name
trigger_time trigger_event
ON table_name
FOR EACH ROW
BEGIN
  -- Trigger logic here
END;`}
      </code>
    </pre>

    <ul>
      <li><strong>trigger_name:</strong> The name of the trigger.</li><br/>
      <li><strong>trigger_time:</strong> The timing of the trigger (either BEFORE or AFTER the event).</li><br/>
      <li><strong>trigger_event:</strong> The event that triggers the action (INSERT, UPDATE, or DELETE).</li><br/>
      <li><strong>table_name:</strong> The table on which the trigger operates.</li><br/>
      <li><strong>FOR EACH ROW:</strong> Specifies that the trigger will execute once for each row affected by the event.</li><br/>
      <li><strong>Trigger logic:</strong> The actual SQL statements or actions that the trigger performs.</li>
    </ul><br/>

    <h2>Example 1: Creating a BEFORE Trigger</h2>
    <p>
      Let's create a 'BEFORE INSERT' trigger to prevent negative salaries from being inserted into an `employees` table:
    </p>
    <pre>
      <code>{`
CREATE TRIGGER before_insert_employee
BEFORE INSERT ON employees
FOR EACH ROW
BEGIN
  IF NEW.salary &lt; 0 THEN
    SIGNAL SQLSTATE '45000'
    SET MESSAGE_TEXT = 'Salary cannot be negative';
  END IF;
END;`}
      </code>
    </pre>
    <p>
      In this example:
      <ul>
        <li>The trigger is named `before_insert_employee`.</li><br/>
        <li>The trigger fires before an `INSERT` operation on the `employees` table.</li><br/>
        <li>If a negative salary is being inserted (i.e., `NEW.salary {`<`} 0`), the trigger will raise an error and prevent the insertion.</li>
      </ul>
    </p><br/>

    <h2>Example 2: Creating an AFTER Trigger</h2>
    <p>
      Now, let’s create an 'AFTER UPDATE' trigger to log changes to the employee salary in a separate audit table called `salary_audit_log`:
    </p>
    <pre>
      <code>{`
CREATE TRIGGER after_update_employee
AFTER UPDATE ON employees
FOR EACH ROW
BEGIN
  INSERT INTO salary_audit_log (employee_id, old_salary, new_salary, change_time)
  VALUES (OLD.id, OLD.salary, NEW.salary, NOW());
END;`}
      </code>
    </pre>
    <p>
      In this case:
      <ul>
        <li>The trigger is named `after_update_employee`.</li><br/>
        <li>The trigger fires after an `UPDATE` operation on the `employees` table.</li><br/>
        <li>For each row updated, the old and new salary values are logged in the `salary_audit_log` table.</li><br/>
        <li>`OLD.salary` refers to the value of the salary before the update, and `NEW.salary` refers to the updated value.</li>
      </ul>
    </p><br/>

    <h2>Trigger Limitations</h2>
    <p>
      When creating triggers, keep in mind the following limitations:
    </p>
    <ul>
      <li>Triggers cannot modify the same table they are triggered by (except for the `AFTER` triggers, where the changes are already committed).</li><br/>
      <li>Triggers can only execute SQL statements, and complex logic may need to be handled outside the trigger.</li><br/>
      <li>There are performance considerations: triggers add overhead, especially if there are multiple triggers on frequently modified tables.</li>
    </ul><br/>

    <h2>Viewing Existing Triggers</h2>
    <p>
      To see all triggers associated with a table, you can use the following command:
    </p>
    <pre>
      <code>
        SHOW TRIGGERS FROM database_name;
      </code>
    </pre><br/>

    <h2>Dropping a Trigger</h2>
    <p>
      If you no longer need a trigger, you can drop it using the following command:
    </p>
    <pre>
      <code>
        DROP TRIGGER trigger_name;
      </code>
    </pre><br/>

    <h2>Summary</h2>
    <p>
      Triggers in MySQL are a powerful tool for automatically enforcing business rules, logging changes, and maintaining data integrity. You can create triggers using the 'CREATE TRIGGER' statement, specify their timing (BEFORE or AFTER), and define actions to execute when the trigger is fired. It's important to consider the performance impact of triggers, especially on frequently updated tables, and to use them judiciously.
    </p>

   
  </div>
)}


 

{selectedChapter === 'chapter81' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Dropping Triggers   </h1>


    <p>
      In MySQL, 'dropping a trigger' means removing an existing trigger from the database. This is useful when a trigger is no longer needed, or if you need to modify the logic of your database operations and want to remove the current trigger first.
    </p><br/>

    <h2>Basic Syntax for Dropping a Trigger</h2>
    <p>
      The syntax for dropping a trigger is straightforward:
    </p>
    <pre>
      <code>
        DROP TRIGGER [IF EXISTS] trigger_name;
      </code>
    </pre>

    <ul>
      <li><strong>trigger_name:</strong> The name of the trigger that you want to drop.</li><br/>
      <li><strong>IF EXISTS:</strong> This is an optional clause. If provided, MySQL will only attempt to drop the trigger if it exists, preventing an error if the trigger is missing.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}} >Example: Dropping a Trigger</h2>
    <p>
      Let’s say we want to drop a trigger named `before_insert_employee`, which prevents negative salaries from being inserted into the `employees` table. We can drop this trigger as follows:
    </p>
    <pre>
      <code>
        DROP TRIGGER before_insert_employee;
      </code>
    </pre>

    <p>
      Alternatively, using the `IF EXISTS` clause:
    </p>
    <pre>
      <code>
        DROP TRIGGER IF EXISTS before_insert_employee;
      </code>
    </pre>
    <p>
      In this case, if the `before_insert_employee` trigger does not exist, MySQL will not throw an error and will simply not perform any action.
    </p><br/>

    <h2>Considerations When Dropping a Trigger</h2>
    <ul>
      <li>Once a trigger is dropped, it no longer executes automatically in response to the events it was created for.</li><br/>
      <li>Dropping a trigger does not affect the data in the table, but it may affect the behavior of data operations, especially if the trigger was enforcing business rules, validation, or logging.</li><br/>
      <li>Ensure that any dependent applications or processes are adjusted if a trigger is dropped, especially if the trigger was integral to enforcing rules or maintaining integrity.</li>
    </ul><br/>

    <h2>Viewing Existing Triggers Before Dropping</h2>
    <p>
      To avoid accidental removal of a non-existing trigger, you can first list the triggers in your database:
    </p>
    <pre>
      <code>
        SHOW TRIGGERS;
      </code>
    </pre>
    <p>
      This will display all the triggers that exist within the current database, along with their associated tables and events.
    </p><br/>

    <h2>Summary</h2>
    <p>
      Dropping triggers in MySQL is a straightforward process that involves using the `DROP TRIGGER` statement. It's a useful operation when you no longer need a trigger or want to replace it with a different implementation. Remember to check for the existence of a trigger before dropping it and understand the implications of removing a trigger from your system.
    </p>

    
  </div>
)}





{selectedChapter === 'chapter82' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Practical Applications of Triggers    </h1>

    <p>
      Triggers in MySQL provide a powerful way to automatically execute specific actions in response to certain events on a table. These events could include operations like INSERT, UPDATE, or DELETE. Triggers help in maintaining data integrity, enforcing business rules, and automating various database-related tasks. Below are some practical applications of triggers in MySQL.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >1. Enforcing Data Integrity</h2>
    <p>
      Triggers are commonly used to enforce data integrity rules. For example, if you want to ensure that certain columns always contain valid data, a trigger can automatically prevent invalid entries.
    </p>
    <pre>
      <code>{`
CREATE TRIGGER prevent_negative_salary
BEFORE INSERT ON employees
FOR EACH ROW
BEGIN
  IF NEW.salary &lt; 0 THEN
    SIGNAL SQLSTATE '45000' SET MESSAGE_TEXT = 'Salary cannot be negative';
  END IF;
END;`}
      </code>
    </pre>
    <p>
      In the above example, the trigger ensures that no employee record is inserted with a negative salary. If someone tries to insert such data, the trigger will raise an error with a custom message.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >2. Auditing Data Changes</h2>
    <p>
      Triggers are useful for auditing changes to your data. For example, you might want to keep track of every update made to a critical table, such as logging who made the change and when.
    </p>
    <pre>
      <code>{`
CREATE TRIGGER audit_employee_changes
AFTER UPDATE ON employees
FOR EACH ROW
BEGIN
  INSERT INTO employee_audit (employee_id, old_salary, new_salary, change_date)
  VALUES (OLD.id, OLD.salary, NEW.salary, NOW());
END;`}
      </code>
    </pre>
    <p>
      In this example, an audit entry is created every time an employee’s salary is updated. This helps in tracking changes and can be useful for compliance and debugging purposes.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >3. Maintaining Referential Integrity</h2>
    <p>
      While foreign keys handle basic referential integrity, triggers can provide more complex integrity rules, such as cascading changes that are not directly supported by MySQL’s foreign key constraints.
    </p>
    <pre>
      <code>{`
CREATE TRIGGER cascade_delete_orders
AFTER DELETE ON customers
FOR EACH ROW
BEGIN
  DELETE FROM orders WHERE customer_id = OLD.id;
END;`}
      </code>
    </pre>
    <p>
      In this example, when a customer is deleted from the `customers` table, the trigger ensures that all related orders in the `orders` table are also deleted. This can be helpful when foreign keys are not available or the logic needs to be more customized.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >4. Automatically Updating Timestamps</h2>
    <p>
      Triggers can be used to automatically update a timestamp field whenever a record is inserted or updated. This is common in scenarios where you need to track the last modification time for a record.
    </p>
    <pre>
      <code>{`
CREATE TRIGGER update_last_modified
BEFORE UPDATE ON products
FOR EACH ROW
BEGIN
  SET NEW.last_modified = NOW();
END;`}
      </code>
    </pre>
    <p>
      Here, every time a product record is updated, the `last_modified` timestamp field is automatically updated to the current date and time.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >5. Preventing Duplicates or Invalid Data</h2>
    <p>
      Triggers can help prevent duplicates or invalid data from being inserted into tables, especially in cases where unique constraints or checks are complex and need custom logic.
    </p>
    <pre>
      <code>{`
CREATE TRIGGER prevent_duplicate_email
BEFORE INSERT ON users
FOR EACH ROW
BEGIN
  DECLARE email_count INT;
  SELECT COUNT(*) INTO email_count FROM users WHERE email = NEW.email;
  IF email_count &gt; 0 THEN
    SIGNAL SQLSTATE '45000' SET MESSAGE_TEXT = 'Email already exists';
  END IF;
END;`}
      </code>
    </pre>
    <p>
      In this example, the trigger ensures that no two users can have the same email address, even if a unique constraint is not in place.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}} >6. Automatically Populating Derived Columns</h2>
    <p>
      Sometimes, you may want to automatically populate a derived column based on the values of other columns. Triggers can help in such scenarios.
    </p>
    <pre>
      <code>{`
CREATE TRIGGER update_full_name
BEFORE INSERT ON employees
FOR EACH ROW
BEGIN
  SET NEW.full_name = CONCAT(NEW.first_name, ' ', NEW.last_name);
END;`}
      </code>
    </pre>
    <p>
      In this example, the trigger automatically creates the `full_name` column by concatenating the `first_name` and `last_name` whenever a new record is inserted.
    </p><br/>

    <h2>Summary of Practical Applications of Triggers</h2>
    <ul>
      <li><strong>Enforcing data integrity:</strong> Ensures that only valid data is entered into the database.</li><br/>
      <li><strong>Auditing data changes:</strong> Tracks changes to critical tables for reporting and auditing purposes.</li><br/>
      <li><strong>Maintaining referential integrity:</strong> Ensures the consistency of related data in multiple tables.</li><br/>
      <li><strong>Automatically updating timestamps:</strong> Tracks the last modified date for each record.</li><br/>
      <li><strong>Preventing duplicates or invalid data:</strong> Ensures data uniqueness and prevents unwanted records.</li><br/>
      <li><strong>Automatically populating derived columns:</strong> Automates the population of calculated fields in your tables.</li>
    </ul><br/>

    <p>
      Triggers are incredibly versatile and can be used to handle complex logic and automate tasks that would otherwise require manual intervention or additional application code. By using triggers effectively, you can improve the integrity, consistency, and automation of your database operations.
    </p>

   
  </div>
)}



{selectedChapter === 'chapter83' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to Events    </h1>

    <p>
      In MySQL, an <strong>event</strong> is a task that the database server executes automatically at a specified interval or specific time. Events allow you to schedule repetitive tasks like updating records, backing up tables, or cleaning up outdated data without manual intervention.
    </p><br/>

    <h3>Key Characteristics of MySQL Events</h3>
    <ul>
      <li>
        MySQL events are part of the <strong>event scheduler</strong>, which must
        be enabled for events to run.
      </li><br/>
      <li>
        Events can be set to execute once at a specific time or repeatedly at
        regular intervals.
      </li><br/>
      <li>
        They are useful for automating routine database tasks, reducing the need
        for external scripts or cron jobs.
      </li>
    </ul><br/>

    <h3>Syntax for Creating Events</h3>
    <pre>
      <code>{`CREATE EVENT event_name
ON SCHEDULE AT timestamp
DO
BEGIN
  -- SQL statements to execute
END;`}</code>
    </pre>

    <p>Alternatively, for recurring tasks:</p>
    <pre>
      <code>{`CREATE EVENT event_name
ON SCHEDULE EVERY interval_value INTERVAL interval_unit
DO
BEGIN
  -- SQL statements to execute
END;`}</code>
    </pre><br/>

    <h3>Examples</h3>

    <h4 style={{paddingBottom:"6px"}}>1. One-Time Event</h4>
    <p>
      This example creates an event to delete records older than one year from
      the <code>logs</code> table:
    </p>
    <pre>
      <code>{`CREATE EVENT clean_old_logs
ON SCHEDULE AT CURRENT_TIMESTAMP + INTERVAL 1 DAY
DO
DELETE FROM logs WHERE log_date < NOW() - INTERVAL 1 YEAR;`}</code>
    </pre><br/>

    <h4>2. Recurring Event</h4>
    <p>
      This example creates an event to back up a table every day at midnight:
    </p>
    <pre>
      <code>{`CREATE EVENT daily_backup
ON SCHEDULE EVERY 1 DAY
STARTS CURRENT_TIMESTAMP + INTERVAL 1 DAY
DO
BEGIN
  INSERT INTO backups SELECT * FROM important_table;
END;`}</code>
    </pre><br/>

    <h3>Managing Events</h3>
    <ul>
      <li>
        Enable or disable the <strong>event scheduler</strong> with the following
        command:
      </li>
      <pre>
        <code>{`SET GLOBAL event_scheduler = ON; -- Enable
SET GLOBAL event_scheduler = OFF; -- Disable`}</code>
      </pre>
      <li>
        View existing events with:
        <pre>
          <code>{`SHOW EVENTS;`}</code>
        </pre>
      </li>
      <li>
        Drop an event with:
        <pre>
          <code>{`DROP EVENT event_name;`}</code>
        </pre>
      </li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>Advantages of MySQL Events</h3>
    <ul>
      <li>
        Automates repetitive tasks such as data cleanup, archiving, or
        maintenance.
      </li><br/>
      <li>
        Reduces dependency on external scheduling tools like cron jobs or
        third-party scripts.
      </li><br/>
      <li>Improves database management efficiency.</li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>Limitations</h3>
    <ul>
      <li>
        Events may not be supported in all MySQL storage engines (e.g., MyISAM
        may lack certain features).
      </li><br/>
      <li>Improper use can lead to performance overhead.</li><br/>
      <li>Requires the event scheduler to remain enabled for events to run.</li>
    </ul><br/>

    <h3>Best Practices</h3>
    <ul>
      <li>
        Test your event scripts thoroughly before deploying them in a
        production environment.
      </li><br/>
      <li>
        Monitor performance to ensure that events do not consume excessive
        resources.
      </li><br/>
      <li>Document scheduled events for easy maintenance.</li>
    </ul><br/>

    <h3>Conclusion</h3>
    <p>
      Events in MySQL are powerful tools for automating repetitive database
      tasks. Proper use of events can streamline database operations and reduce
      reliance on external tools. However, careful planning and monitoring are
      essential to avoid performance issues.
    </p>
  </div>
)}


  

{selectedChapter === 'chapter84' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Creating Events    </h1>

   
    <p>
      In MySQL, an <strong>event</strong> is a scheduled task that is executed automatically by the database server at a specified time or interval. Creating events is a key feature of the MySQL 'Event Scheduler', enabling database automation for tasks like data maintenance, backups, and cleanups.
    </p><br/>

    <h3>Basic Syntax for Creating Events</h3>
    <pre>
      <code>{`CREATE EVENT event_name
ON SCHEDULE schedule
DO
sql_statement;`}</code>
    </pre>

    <p>
      Here:
      <ul>
        <li><strong>event_name</strong>: The name of the event.</li><br/>
        <li>
          <strong>schedule</strong>: Specifies when and how often the event runs.
        </li><br/>
        <li>
          <strong>sql_statement</strong>: The SQL commands to be executed by the
          event.
        </li>
      </ul><br/>
    </p>

    <h3>Schedule Types</h3>
    <p>
      Events can be executed:
      <ul>
        <li><strong>Once:</strong> Executes at a specific time.</li><br/>
        <li>
          <strong>Repeatedly:</strong> Executes at regular intervals (e.g., every
          hour, day, or week).
        </li>
      </ul>
    </p><br/>

    <h3>Examples</h3>

    <h4>1. One-Time Event</h4>
    <p>
      This example creates an event to delete old records from the `logs` table
      at a specific time:
    </p>
    <pre>
      <code>{`CREATE EVENT delete_old_logs
ON SCHEDULE AT '2024-11-17 00:00:00'
DO
DELETE FROM logs WHERE log_date < NOW() - INTERVAL 1 YEAR;`}</code>
    </pre><br/>

    <h4>2. Recurring Event</h4>
    <p>
      This example creates an event to back up a table daily at midnight:
    </p>
    <pre>
      <code>{`CREATE EVENT daily_backup
ON SCHEDULE EVERY 1 DAY
STARTS '2024-11-17 00:00:00'
DO
INSERT INTO backups SELECT * FROM important_table;`}</code>
    </pre><br/>

    <h3>Managing Events</h3>
    <ul>
      <li>
        Enable the <strong>Event Scheduler</strong> if it's not already active:
        <pre>
          <code>{`SET GLOBAL event_scheduler = ON;`}</code>
        </pre>
      </li><br/>
      <li>
        To disable an event:
        <pre>
          <code>{`ALTER EVENT event_name DISABLE;`}</code>
        </pre>
      </li><br/>
      <li>
        Drop an event when it's no longer needed:
        <pre>
          <code>{`DROP EVENT event_name;`}</code>
        </pre>
      </li><br/>
      <li>
        View all events in the current database:
        <pre>
          <code>{`SHOW EVENTS;`}</code>
        </pre>
      </li>
    </ul><br/>

    <h3>Advanced Options</h3>
    <ul>
      <li>
        Use the <strong>ENDS</strong> clause to specify an end date for
        recurring events:
        <pre>
          <code>{`CREATE EVENT recurring_event
ON SCHEDULE EVERY 1 DAY
STARTS '2024-11-17 00:00:00'
ENDS '2024-12-17 00:00:00'
DO
-- SQL statements here;`}</code>
        </pre>
      </li><br/>
      <li>
        Modify an existing event:
        <pre>
          <code>{`ALTER EVENT event_name
ON SCHEDULE EVERY 1 WEEK;`}</code>
        </pre>
      </li>
    </ul><br/>

    <h3>Performance Considerations</h3>
    <ul>
      <li>
        Events execute within the database server, so ensure they are
        lightweight to avoid performance issues.
      </li><br/>
      <li>
        Use indexes on tables accessed by event queries to enhance performance.
      </li>
    </ul><br/>

    <h3>Conclusion</h3>
    <p>
      Creating events in MySQL is a straightforward way to automate repetitive
      tasks. By carefully scheduling and managing events, you can optimize your
      database operations and reduce manual workload.
    </p>
  </div>
)}



{selectedChapter === 'chapter85' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Modifying Events </h1>

    <p>
      In MySQL, you can modify existing events using the <strong>ALTER EVENT</strong> statement. This is useful when you need to update the schedule, SQL commands, or other properties of an event without dropping and recreating it.
    </p><br/>

    <h3>Basic Syntax</h3>
    <pre>
      <code>{`ALTER EVENT event_name
[ON SCHEDULE schedule]
[RENAME TO new_event_name]
[DO sql_statement];`}</code>
    </pre>

    <p>
      Here:
      <ul>
        <li>
          <strong>event_name:</strong> The name of the event you want to modify.
        </li><br/>
        <li>
          <strong>ON SCHEDULE:</strong> Updates the timing or frequency of the
          event.
        </li><br/>
        <li>
          <strong>RENAME TO:</strong> Changes the name of the event.
        </li><br/>
        <li>
          <strong>DO:</strong> Updates the SQL statement executed by the event.
        </li>
      </ul><br/>
    </p>

    <h3>Examples</h3>

    <h4>1. Changing the Schedule</h4>
    <p>Modify an event to change its execution schedule:</p>
    <pre>
      <code>{`ALTER EVENT daily_backup
ON SCHEDULE EVERY 2 DAY
STARTS '2024-11-17 00:00:00';`}</code>
    </pre>
    <p>
      This changes the `daily_backup` event to run every 2 days, starting from
      the specified date and time.
    </p><br/>

    <h4>2. Renaming an Event</h4>
    <p>Change the name of an existing event:</p>
    <pre>
      <code>{`ALTER EVENT daily_backup
RENAME TO weekly_backup;`}</code>
    </pre>
    <p>This renames the `daily_backup` event to `weekly_backup`.</p><br/>

    <h4>3. Updating the SQL Statement</h4>
    <p>
      Modify the SQL statement executed by an event without changing its
      schedule:
    </p>
    <pre>
      <code>{`ALTER EVENT weekly_backup
DO
DELETE FROM logs WHERE log_date < NOW() - INTERVAL 6 MONTH;`}</code>
    </pre>
    <p>
      This updates the `weekly_backup` event to delete logs older than 6 months.
    </p><br/>

    <h4>4. Combining Modifications</h4>
    <p>Make multiple changes to an event in a single command:</p>
    <pre>
      <code>{`ALTER EVENT weekly_backup
ON SCHEDULE EVERY 1 WEEK
DO
INSERT INTO archive_logs SELECT * FROM logs WHERE log_date < NOW() - INTERVAL 1 WEEK;`}</code>
    </pre>
    <p>
      This updates the `weekly_backup` event to run weekly and changes its SQL
      statement to archive old logs.
    </p><br/>

    <h3>Managing Event Status</h3>
    <ul>
      <li>
        <strong>Enable an Event:</strong>
        <pre>
          <code>{`ALTER EVENT event_name ENABLE;`}</code>
        </pre>
      </li>
      <li>
        <strong>Disable an Event:</strong>
        <pre>
          <code>{`ALTER EVENT event_name DISABLE;`}</code>
        </pre>
      </li>
    </ul>
    <p>
      Disabling an event prevents it from executing until it is enabled again.
    </p><br/>

    <h3>Viewing Modified Events</h3>
    <p>
      To confirm modifications, use the <strong>SHOW EVENTS</strong> statement:
    </p>
    <pre>
      <code>{`SHOW EVENTS WHERE Name = 'event_name';`}</code>
    </pre><br/>

    <h3>Best Practices</h3>
    <ul>
      <li>
        Test modifications on a development or staging database before applying
        them to production.
      </li><br/>
      <li>
        Use descriptive event names to make them easier to manage and modify.
      </li><br/>
      <li>
        Document changes made to events for future reference.
      </li>
    </ul><br/>

    <h3>Conclusion</h3>
    <p>
      Modifying events in MySQL using the <strong>ALTER EVENT</strong> statement
      allows you to adapt your scheduled tasks as requirements change. Whether
      it's updating schedules, SQL logic, or event names, this flexibility
      ensures efficient database automation.
    </p>
  </div>
)}

 
 

{selectedChapter === 'chapter86' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Dropping Events  </h1>


    <p>
      Dropping events in MySQL is the process of permanently removing scheduled tasks from the database. Once dropped, the event and its associated schedule or SQL commands are deleted and cannot be recovered.
    </p><br/>

    <h3>Syntax</h3>
    <pre>
      <code>{`DROP EVENT [IF EXISTS] event_name;`}</code>
    </pre>
    <p>
      - `IF EXISTS`: Ensures that no error is thrown if the event does not exist.
      <br />
      - `event_name`: The name of the event to be dropped.
    </p><br/>

    <h3>Examples</h3>

    <h4 style={{paddingBottom:"6px"}}>1. Dropping an Existing Event</h4>
    <p>Remove an event named `daily_cleanup`:</p>
    <pre>
      <code>{`DROP EVENT daily_cleanup;`}</code>
    </pre>
    <p>
      This deletes the `daily_cleanup` event and all associated scheduling and SQL logic.
    </p><br/>

    <h4>2. Using `IF EXISTS`</h4>
    <p>
      Avoid errors when trying to drop an event that may not exist in the
      database:
    </p>
    <pre>
      <code>{`DROP EVENT IF EXISTS weekly_backup;`}</code>
    </pre>
    <p>
      If the `weekly_backup` event does not exist, the query runs without any
      error or effect.
    </p><br/>

    <h3>Verifying Event Removal</h3>
    <p>
      After dropping an event, you can confirm its removal using the
      <strong>SHOW EVENTS</strong> statement:
    </p>
    <pre>
      <code>{`SHOW EVENTS WHERE Name = 'event_name';`}</code>
    </pre>
    <p>If the event was successfully dropped, it will no longer appear in the results.</p><br/>

    <h3>Impact of Dropping Events</h3>
    <ul>
      <li>
        Dropped events are permanently removed and cannot be recovered unless
        re-created manually.
      </li><br/>
      <li>Existing data in the database remains unaffected.</li><br/>
      <li>
        Scheduled tasks associated with the dropped event will no longer
        execute.
      </li>
    </ul><br/>

    <h3>Common Use Cases</h3>
    <ul>
      <li>
        Deleting outdated or unused events to maintain a clean database
        environment.
      </li><br/>
      <li>
        Replacing an event with an updated version by dropping the old event
        first.
      </li>
    </ul><br/>

    <h3>Best Practices</h3>
    <ul>
      <li>
        Confirm the event’s purpose and schedule before dropping it to avoid
        unintended consequences.
      </li><br/>
      <li>Use `IF EXISTS` to prevent errors in automated scripts.</li>
      <li>
        Regularly review and clean up unnecessary events to optimize database
        performance.
      </li>
    </ul><br/>

    <h3>Conclusion</h3>
    <p>
      The <strong>DROP EVENT</strong> statement is a straightforward way to
      delete scheduled events in MySQL. By carefully managing and dropping
      unused or outdated events, you can ensure your database remains organized
      and efficient.
    </p>
  </div>
)}



{selectedChapter === 'chapter87' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Creating and Managing Users  </h1>


    <p>
      Managing user accounts in MySQL is essential for controlling database
      access and ensuring security. MySQL allows administrators to create, manage,
      and grant specific privileges to users to protect data integrity and
      confidentiality.
    </p><br/>

    <h3>Creating Users</h3>
    <p>
      To create a new user in MySQL, use the <strong>`CREATE USER`</strong> statement. A user account is associated with a unique username and host.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>
        {`CREATE USER 'username'@'host' IDENTIFIED BY 'password';`}
      </code>
    </pre>
    <p>
      - `username`: The name of the user.
      <br />
      - `host`: The hostname or IP address from which the user can connect.
      Use `%` for any host.
      <br />
      - `password`: The password for the user account.
    </p><br/>

    <h4>Example</h4>
    <pre>
      <code>
        {`CREATE USER 'john'@'localhost' IDENTIFIED BY 'securepassword';`}
      </code>
    </pre>
    <p>
      This command creates a user `john` who can connect only from `localhost`.
    </p><br/>

    <h3>Granting Privileges</h3>
    <p>
      To assign privileges to a user, use the <strong>`GRANT`</strong> statement. Privileges determine the operations a user can perform.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>
        {`GRANT privilege_type ON database_name.table_name TO 'username'@'host';`}
      </code>
    </pre>
    <p>
      - `privilege_type`: The specific privilege (e.g., `SELECT`, `INSERT`,
      `ALL PRIVILEGES`).
      <br />
      - `database_name.table_name`: The database and table to which the
      privilege applies. Use `.` for all databases and tables.
    </p><br/>

    <h4>Example</h4>
    <pre>
      <code>
        {`GRANT SELECT, INSERT ON mydb.* TO 'john'@'localhost';`}
      </code>
    </pre>
    <p>
      This grants `john` the ability to `SELECT` and `INSERT` data in all tables
      within the `mydb` database.
    </p><br/>

    <h3>Viewing User Privileges</h3>
    <p>To view a user's privileges, use the `SHOW GRANTS` command.</p>
    <pre>
      <code>{`SHOW GRANTS FOR 'username'@'host';`}</code>
    </pre>
    <p>Example:</p>
    <pre>
      <code>{`SHOW GRANTS FOR 'john'@'localhost';`}</code>
    </pre><br/>

    <h3>Modifying Users</h3>
    <p>
      Use the <strong>`ALTER USER`</strong> statement to modify user
      attributes, such as changing passwords or authentication methods.
    </p>
    <h4>Example: Changing Password</h4>
    <pre>
      <code>
        {`ALTER USER 'john'@'localhost' IDENTIFIED BY 'newsecurepassword';`}
      </code>
    </pre><br/>

    <h3>Removing Users</h3>
    <p>
      To delete a user account, use the <strong>`DROP USER`</strong> statement.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`DROP USER 'username'@'host';`}</code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>{`DROP USER 'john'@'localhost';`}</code>
    </pre><br/>

    <h3>Best Practices</h3>
    <ul>
      <li>
        Always assign the minimum required privileges to users to maintain
        security.
      </li><br/>
      <li>Use strong, unique passwords for all user accounts.</li><br/>
      <li>Regularly review and revoke unnecessary privileges.</li><br/>
      <li>
        Use `localhost` for users who only need local database access to reduce
        potential attack vectors.
      </li>
    </ul><br/>

    <h3>Common User Privileges</h3>
    <ul>
      <li>
        <strong>`ALL PRIVILEGES`</strong>: Grants all permissions to the user.
      </li><br/>
      <li>
        <strong>`SELECT`</strong>: Allows reading data from tables.
      </li><br/>
      <li>
        <strong>`INSERT`</strong>: Allows inserting data into tables.
      </li><br/>
      <li>
        <strong>`UPDATE`</strong>: Allows modifying existing data in tables.
      </li><br/>
      <li>
        <strong>`DELETE`</strong>: Allows deleting data from tables.
      </li>
    </ul><br/>

    <h3>Conclusion</h3>
    <p>
      Creating and managing users in MySQL is a foundational aspect of database
      security. Properly managing user accounts and privileges ensures that only
      authorized users can access or modify sensitive data.
    </p>
  </div>
)}

 


{selectedChapter === 'chapter88' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Privileges and Roles   </h1>



    <p>
      In MySQL, 'Privileges' and 'Roles' are essential for managing user access and ensuring database security. Privileges control what operations a user can perform, while roles are a collection of privileges that can be assigned to multiple users for easier management.
    </p><br/>

    <h2>Privileges in MySQL</h2>
    <p>
      Privileges in MySQL define the actions that a user can perform on a database, table, or other database objects. These include basic operations like reading and writing data, as well as administrative tasks like creating databases or managing user accounts.
    </p><br/>

    <h3>Types of Privileges</h3>
    <ul>
      <li>
        Global Privileges: Apply across all databases and tables (e.g., `CREATE USER`, `SHUTDOWN`).
      </li><br/>
      <li>
        Database Privileges: Apply to all objects within a specific database (e.g., `SELECT`, `INSERT`).
      </li><br/>
      <li>
        Table Privileges: Apply to individual tables within a database (e.g., `UPDATE`, `DELETE`).
      </li><br/>
      <li>
        Column Privileges: Apply to specific columns in a table (e.g., `SELECT(column_name)`).
      </li><br/>
      <li>
        Administrative Privileges: Allow users to manage server operations (e.g., `SUPER`, `RELOAD`).
      </li>
    </ul><br/>

    <h3>Granting Privileges</h3>
    <p>
      Use the <strong>`GRANT`</strong> statement to assign privileges to a user.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>
        {`GRANT privilege_type ON database_name.table_name TO 'username'@'host';`}
      </code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>
        {`GRANT SELECT, INSERT ON mydb.* TO 'jane'@'localhost';`}
      </code>
    </pre><br/>

    <h3>Revoking Privileges</h3>
    <p>
      To remove privileges from a user, use the <strong>`REVOKE`</strong> statement.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>
        {`REVOKE privilege_type ON database_name.table_name FROM 'username'@'host';`}
      </code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>
        {`REVOKE SELECT ON mydb.* FROM 'jane'@'localhost';`}
      </code>
    </pre><br/>

    <h2>Roles in MySQL</h2>
    <p>
      'Roles' are a way to simplify privilege management by grouping multiple privileges into a single entity. A role can be assigned to one or more users, making it easier to manage permissions for users with similar responsibilities.
    </p><br/>

    <h3>Creating Roles</h3>
    <p>
      Use the <strong>`CREATE ROLE`</strong> statement to define a new role.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>
        {`CREATE ROLE 'role_name';`}
      </code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>
        {`CREATE ROLE 'developer';`}
      </code>
    </pre><br/>

    <h3>Granting Privileges to a Role</h3>
    <p>
      Assign privileges to a role using the <strong>`GRANT`</strong> statement.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`GRANT SELECT, INSERT, UPDATE ON mydb.* TO 'developer';`}
      </code>
    </pre><br/>

    <h3>Assigning Roles to Users</h3>
    <p>
      Use the <strong>`GRANT`</strong> statement to assign a role to a user.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>
        {`GRANT 'role_name' TO 'username'@'host';`}
      </code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>
        {`GRANT 'developer' TO 'jane'@'localhost';`}
      </code>
    </pre><br/>

    <h3>Activating Roles</h3>
    <p>
      A user can activate a role for the current session using the <strong>`SET ROLE`</strong> statement.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>
        {`SET ROLE 'role_name';`}
      </code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>
        {`SET ROLE 'developer';`}
      </code>
    </pre><br/>

    <h3>Dropping Roles</h3>
    <p>
      To remove a role, use the <strong>`DROP ROLE`</strong> statement.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>
        {`DROP ROLE 'role_name';`}
      </code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>
        {`DROP ROLE 'developer';`}
      </code>
    </pre><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>Use roles to simplify privilege management for large teams.</li><br/>
      <li>Regularly audit user privileges to ensure compliance with security policies.</li><br/>
      <li>Grant the minimum privileges necessary for a user to perform their tasks.</li><br/>
      <li>Use strong passwords for all user accounts to enhance security.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      Privileges and roles in MySQL provide robust mechanisms for managing user
      access and securing databases. Roles simplify privilege assignment for
      groups of users, while granular privileges allow fine-tuned control over
      database operations.
    </p>
  </div>
)}




{selectedChapter === 'chapter89' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Granting and Revoking Permissions  </h1>


    <p>
      In MySQL, 'Granting' and 'Revoking Permissions' are key to managing user access and ensuring database security. These operations define what actions a user can or cannot perform on the database and its objects.
    </p><br/>

    <h2>Granting Permissions</h2>
    <p>
      The <strong>`GRANT`</strong> statement is used to assign specific privileges to a user. You can grant permissions globally, at the database level, or for specific tables, columns, or routines.
    </p>

    <h3>Syntax</h3>
    <pre>
      <code>
        {`GRANT privilege_type [(column_list)] 
ON database_name.table_name 
TO 'username'@'host'
[WITH GRANT OPTION];`}
      </code>
    </pre><br/>

    <h3>Parameters</h3>
    <ul>
      <li>
      `privilege_type`: The permission to be granted (e.g., `SELECT`, `INSERT`, `UPDATE`).
      </li><br/>
      <li>
        `database_name.table_name`: The scope of the permission (e.g., a specific table or database).
      </li><br/>
      <li>
        `username`@`host`: The user receiving the permission, with the host defining their access location.
      </li><br/>
      <li>
        `WITH GRANT OPTION`: Allows the user to grant the same permissions to others.
      </li>
    </ul><br/>

    <h3>Examples</h3>

    <h4>Granting Privileges to a User</h4>
    <pre>
      <code>
        {`-- Allow 'jane' to SELECT and INSERT on the 'employees' table
GRANT SELECT, INSERT 
ON company.employees 
TO 'jane'@'localhost';`}
      </code>
    </pre><br/>

    <h4>Granting All Privileges</h4>
    <pre>
      <code>
        {`-- Allow 'admin' full control over the 'company' database
GRANT ALL PRIVILEGES 
ON company.* 
TO 'admin'@'%';`}
      </code>
    </pre><br/>

    <h4>Granting with the Ability to Delegate</h4>
    <pre>
      <code>
        {`-- Allow 'manager' to update the 'salaries' table and grant the privilege to others
GRANT UPDATE 
ON company.salaries 
TO 'manager'@'localhost' 
WITH GRANT OPTION;`}
      </code>
    </pre><br/>

    <h2>Revoking Permissions</h2>
    <p>
      The <strong>`REVOKE`</strong> statement is used to remove permissions from a user. Like granting, revoking can also be performed at various levels of granularity.
    </p>

    <h3>Syntax</h3>
    <pre>
      <code>
        {`REVOKE privilege_type [(column_list)] 
ON database_name.table_name 
FROM 'username'@'host';`}
      </code>
    </pre><br/>

    <h3>Parameters</h3>
    <ul>
      <li>
        `privilege_type`: The permission to be revoked (e.g., `SELECT`, `UPDATE`).
      </li><br/>
      <li>
        `database_name.table_name`: The scope of the permission.
      </li><br/>
      <li>
        `username`@`host`: The user losing the permission.
      </li>
    </ul><br/>

    <h3>Examples</h3>

    <h4>Revoking Specific Privileges</h4>
    <pre>
      <code>
        {`-- Remove SELECT permission from 'jane' on the 'employees' table
REVOKE SELECT 
ON company.employees 
FROM 'jane'@'localhost';`}
      </code>
    </pre><br/>

    <h4>Revoking All Privileges</h4>
    <pre>
      <code>
        {`-- Revoke all permissions from 'admin' on the 'company' database
REVOKE ALL PRIVILEGES 
ON company.* 
FROM 'admin'@'%';`}
      </code>
    </pre><br/>

    <h3>Grant Option Revocation</h3>
    <p>
      If a user has been granted privileges with the <strong>`WITH GRANT OPTION`</strong>, you can revoke this ability separately using <strong>`REVOKE GRANT OPTION FOR`</strong>.
    </p>
    <pre>
      <code>
        {`-- Remove the ability to delegate UPDATE privilege from 'manager'
REVOKE GRANT OPTION FOR UPDATE 
ON company.salaries 
FROM 'manager'@'localhost';`}
      </code>
    </pre><br/>

    <h2>Common Privileges</h2>
    <ul>
      <li><strong>SELECT</strong>: Read data from tables.</li><br/>
      <li><strong>INSERT</strong>: Add new rows to tables.</li><br/>
      <li><strong>UPDATE</strong>: Modify existing rows in tables.</li><br/>
      <li><strong>DELETE</strong>: Remove rows from tables.</li><br/>
      <li><strong>CREATE</strong>: Create databases, tables, or other objects.</li><br/>
      <li><strong>DROP</strong>: Delete databases or tables.</li><br/>
      <li><strong>EXECUTE</strong>: Run stored procedures and functions.</li><br/>
      <li><strong>ALL PRIVILEGES</strong>: Grant all available permissions.</li>
    </ul><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>
        Grant the 'minimum necessary privileges' to ensure least privilege access.
      </li><br/>
      <li>
        Regularly 'audit user permissions' to maintain security and compliance.
      </li><br/>
      <li>
        Use 'roles' to simplify permission management for groups of users.
      </li><br/>
      <li>
        Avoid using `ALL PRIVILEGES` unless absolutely necessary.
      </li><br/>
      <li>
        Use strong passwords and limit access to trusted hosts.
      </li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      Granting and revoking permissions are essential components of MySQL's security model. By carefully assigning and managing privileges, you can control user access to ensure database security and efficiency.
    </p>
  </div>
)}



{selectedChapter === 'chapter90' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Securing User Accounts   </h1>

    <p>
      Securing user accounts in MySQL is essential to prevent unauthorized access and ensure the confidentiality, integrity, and availability of your database. MySQL provides several features and best practices for securing user accounts effectively.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Key Steps to Secure User Accounts</h2>

    <h3>1. Use Strong Passwords</h3>
    <p>
      Always assign strong, unique passwords to MySQL user accounts. Enforce password policies to ensure complexity and prevent weak passwords.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`CREATE USER 'user_secure'@'localhost' 
IDENTIFIED BY 'StrongP@ssw0rd!';`}
      </code>
    </pre><br/>

    <h3>2. Enforce Password Policies</h3>
    <p>
      MySQL provides the <strong>validate_password</strong> plugin to enforce password complexity rules. You can configure it to meet your organization's security requirements.
    </p>
    <h4>Steps</h4>
    <ol>
      <li>Enable the <strong>validate_password</strong> plugin:</li><br/>
      <pre>
        <code>INSTALL PLUGIN validate_password SONAME 'validate_password.so';</code>
      </pre>
      <li>Set password policy variables:</li>
      <pre>
        <code>
          {`SET GLOBAL validate_password_policy = 'STRONG';
SET GLOBAL validate_password_length = 12;`}
        </code>
      </pre>
    </ol><br/>

    <h3>3. Use Principle of Least Privilege</h3>
    <p>
      Grant users only the permissions they need. Avoid using `ALL PRIVILEGES` unless absolutely necessary.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`GRANT SELECT, INSERT 
ON database_name.table_name 
TO 'user_read_write'@'localhost';`}
      </code>
    </pre><br/>

    <h3>4. Restrict User Access by Host</h3>
    <p>
      Limit user connections to trusted hosts only. This minimizes the risk of unauthorized access from untrusted sources.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`CREATE USER 'user_local'@'127.0.0.1' IDENTIFIED BY 'password';`}
      </code>
    </pre><br/>

    <h3>5. Enable Account Locking</h3>
    <p>
      MySQL allows locking user accounts to prevent unauthorized logins temporarily.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`ALTER USER 'user_secure'@'localhost' ACCOUNT LOCK;`}
      </code>
    </pre>
    <p>Unlock the account when access needs to be restored:</p>
    <pre>
      <code>
        {`ALTER USER 'user_secure'@'localhost' ACCOUNT UNLOCK;`}
      </code>
    </pre><br/>

    <h3>6. Monitor and Limit Resource Usage</h3>
    <p>
      Use <strong>resource limits</strong> to prevent excessive use of resources by a single user, such as connections, queries, or bandwidth.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`CREATE USER 'user_limited'@'localhost' 
WITH MAX_QUERIES_PER_HOUR 100 
     MAX_UPDATES_PER_HOUR 50 
     MAX_CONNECTIONS_PER_HOUR 10 
     MAX_USER_CONNECTIONS 5;`}
      </code>
    </pre><br/>

    <h3>7. Use Secure Connections</h3>
    <p>
      Configure MySQL to use SSL/TLS for secure communication between clients and the server. This ensures that sensitive data, like credentials, is encrypted during transmission.
    </p>
    <h4>Steps</h4>
    <ol>
      <li>Enable SSL in MySQL:</li><br/>
      <pre>
        <code>
          {`ALTER USER 'secure_user'@'%' 
REQUIRE SSL;`}
        </code>
      </pre>
      <li>Verify SSL is enabled for the connection:</li>
      <pre>
        <code>SHOW VARIABLES LIKE 'have_ssl';</code>
      </pre>
    </ol><br/>

    <h3>8. Regularly Audit User Accounts</h3>
    <p>
      Periodically review user accounts and their privileges. Remove unused or unnecessary accounts to reduce the attack surface.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`-- View all user accounts
SELECT user, host FROM mysql.user;

-- Drop an unused user account
DROP USER 'old_user'@'localhost';`}
      </code>
    </pre><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>Regularly update MySQL to the latest version for security patches.</li><br/>
      <li>Use firewall rules to limit database access to trusted networks.</li><br/>
      <li>Monitor logs for suspicious activity (e.g., failed login attempts).</li><br/>
      <li>Implement multi-factor authentication (MFA) if supported by your environment.</li><br/>
      <li>Use roles to manage permissions for groups of users efficiently.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      Securing user accounts in MySQL is a continuous process involving proper configuration, monitoring, and adherence to security best practices. By following these steps, you can significantly enhance the security of your MySQL database.
    </p>
  </div>
)}




{selectedChapter === 'chapter91' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  SQL Injection Prevention  </h1>

  
    <p>
      SQL injection is a common security vulnerability that occurs when an attacker injects malicious SQL code into a query, potentially gaining unauthorized access to the database or manipulating its data. Preventing SQL injection is essential for safeguarding sensitive information and ensuring the integrity of your database.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Key Techniques for SQL Injection Prevention</h2>

    <h3>1. Use Prepared Statements</h3>
    <p>
      Prepared statements separate SQL code from user input, preventing malicious input from being executed as part of the query.
    </p>
    <h4>Example Using MySQLi (PHP)</h4>
    <pre>
      <code>
        {`// Example of a secure query using prepared statements
$mysqli = new mysqli("localhost", "user", "password", "database");

$stmt = $mysqli->prepare("SELECT * FROM users WHERE username = ? AND password = ?");
$stmt->bind_param("ss", $username, $password);
$stmt->execute();
$result = $stmt->get_result();`}
      </code>
    </pre><br/>

    <h3>2. Use Stored Procedures</h3>
    <p>
      Stored procedures are precompiled SQL code that limit the possibility of injection when used correctly.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`-- Create a stored procedure
DELIMITER //
CREATE PROCEDURE GetUser(IN username VARCHAR(255))
BEGIN
    SELECT * FROM users WHERE username = username;
END //
DELIMITER ;

-- Call the stored procedure
CALL GetUser('user123');`}
      </code>
    </pre><br/>

    <h3>3. Validate and Sanitize User Input</h3>
    <p>
      Ensure all user input is validated against expected formats, lengths, and types. Sanitize input to remove potentially harmful characters.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`// PHP example of input sanitization
$username = filter_input(INPUT_POST, 'username', FILTER_SANITIZE_STRING);
$password = filter_input(INPUT_POST, 'password', FILTER_SANITIZE_STRING);`}
      </code>
    </pre><br/>

    <h3>4. Use Least Privileged Database Users</h3>
    <p>
      Configure your database to use accounts with only the necessary permissions. Avoid granting administrative privileges to application accounts.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`-- Grant only SELECT permission to a user
GRANT SELECT ON database_name.* TO 'app_user'@'localhost';`}
      </code>
    </pre><br/>

    <h3>5. Escape User Input</h3>
    <p>
      Escape special characters in user input to prevent them from being interpreted as part of the SQL query.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`// PHP example using mysqli_real_escape_string
$username = $mysqli->real_escape_string($_POST['username']);
$password = $mysqli->real_escape_string($_POST['password']);`}
      </code>
    </pre><br/>

    <h3>6. Disable Error Messages for End Users</h3>
    <p>
      Avoid displaying detailed error messages to users, as they might reveal database structure and vulnerabilities. Log errors internally instead.
    </p>
    <h4>Example</h4>
    <pre>
      <code>
        {`// Example of error handling
try {
    $mysqli->query("INVALID QUERY");
} catch (Exception $e) {
    error_log($e->getMessage()); // Log the error
    echo "An error occurred. Please try again later."; // User-friendly message
}`}
      </code>
    </pre><br/>

    <h3>7. Regularly Update MySQL and Dependencies</h3>
    <p>
      Keep your MySQL server and related software up-to-date to benefit from security patches and fixes.
    </p>

    <h2>Best Practices</h2>
    <ul>
      <li>Use a Web Application Firewall (WAF) to detect and block SQL injection attempts.</li><br/>
      <li>Avoid dynamically constructing SQL queries from user input.</li><br/>
      <li>Use hashing algorithms (e.g., bcrypt) for storing passwords instead of plain text.</li><br/>
      <li>Regularly audit and test your application for SQL injection vulnerabilities.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      SQL injection is a critical security risk that can have severe consequences for your database and application. By following best practices such as using prepared statements, sanitizing input, and adhering to the principle of least privilege, you can significantly reduce the risk of SQL injection attacks.
    </p>
  </div>
)}




{selectedChapter === 'chapter92' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Logical Backup with mysqldump </h1>


    <p>
      A 'logical backup' in MySQL refers to exporting the database's data and structure as a series of SQL statements. This is useful for migrating databases, version control, or creating portable backups. The most common tool for performing logical backups in MySQL is the `mysqldump` utility.
    </p><br/>

    <h2>What is `mysqldump`?</h2>
    <p>
      `mysqldump` is a command-line tool that creates logical backups by generating SQL scripts to recreate databases, tables, and their data. These backups can be easily restored by executing the SQL scripts.
    </p>

    <h2>Basic Syntax</h2>
    <pre>
      <code>
        {`mysqldump -u [username] -p [options] [database_name] > [backup_file.sql]`}
      </code>
    </pre>
    <p>
      - `-u`: Specifies the MySQL username.  <br />
      - `-p`: Prompts for the password.  <br />
      - `[options]`: Additional options to customize the backup.  <br />
      - `[database_name]`: The name of the database to back up.  <br />
      - `[backup_file.sql]`: The output file containing the backup.
    </p><br/>

    <h2>Examples</h2>

    <h3>1. Backing Up a Single Database</h3>
    <p>
      Use the following command to back up a specific database:
    </p>
    <pre>
      <code>
        {`mysqldump -u root -p my_database > my_database_backup.sql`}
      </code>
    </pre><br/>

    <h3>2. Backing Up Multiple Databases</h3>
    <p>
      You can back up multiple databases by specifying them in the command:
    </p>
    <pre>
      <code>
        {`mysqldump -u root -p --databases database1 database2 > multi_database_backup.sql`}
      </code>
    </pre><br/>

    <h3>3. Backing Up All Databases</h3>
    <p>
      To back up all databases on the MySQL server:
    </p>
    <pre>
      <code>
        {`mysqldump -u root -p --all-databases > all_databases_backup.sql`}
      </code>
    </pre><br/>

    <h3>4. Backing Up Only Table Structures</h3>
    <p>
      Use the `--no-data` option to back up only the table structures (schema) without the data:
    </p>
    <pre>
      <code>
        {`mysqldump -u root -p --no-data my_database > my_database_schema.sql`}
      </code>
    </pre><br/>

    <h3>5. Compressing the Backup File</h3>
    <p>
      You can compress the backup file using `gzip` to save space:
    </p>
    <pre>
      <code>
        {`mysqldump -u root -p my_database | gzip > my_database_backup.sql.gz`}
      </code>
    </pre><br/>

    <h2>Restoring a Logical Backup</h2>
    <p>
      To restore a logical backup, use the `mysql` command to execute the SQL script:
    </p>
    <pre>
      <code>
        {`mysql -u [username] -p [database_name] < [backup_file.sql]`}
      </code>
    </pre>

    <h3>Example</h3>
    <pre>
      <code>
        {`mysql -u root -p my_database < my_database_backup.sql`}
      </code>
    </pre><br/>

    <h2>Key Options for `mysqldump`</h2>
    <ul>
      <li>
        add-drop-database: Adds a `DROP DATABASE` statement before each database in the backup.
      </li><br/>
      <li>
      add-drop-table: Includes a `DROP TABLE` statement before each table in the backup.
      </li><br/>
      <li>
      routines: Includes stored procedures and functions in the backup.
      </li><br/>
      <li>
      triggers: Backs up triggers along with tables.
      </li><br/>
      <li>
      no-create-info: Skips creating table structures and only backs up data.
      </li><br/>
      <li>
      lock-tables: Locks tables during the backup for consistency.
      </li>
    </ul><br/>

    <h2>Advantages of Logical Backups</h2>
    <ul>
      <li>Portable and platform-independent.</li><br/>
      <li>Easy to view and modify since the backup is in plain text.</li><br/>
      <li>Supports partial backups of specific databases or tables.</li>
    </ul><br/>

    <h2>Limitations of Logical Backups</h2>
    <ul>
      <li>Slower for large datasets compared to physical backups.</li><br/>
      <li>Consumes more storage space for large databases.</li><br/>
      <li>Restoration can take a significant amount of time for big backups.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      The `mysqldump` utility is a powerful and flexible tool for performing logical backups in MySQL. While it is ideal for small to medium-sized databases and migration tasks, consider physical backups for faster performance and scalability in large systems.
    </p>
  </div>
)}



{selectedChapter === 'chapter93' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Physical Backups  </h1>

    
    <p>
      'Physical backups in MySQL involve copying the actual database files (data files, log files, and configuration files) directly from the disk. These backups are faster and more efficient for large databases compared to logical backups. Physical backups are often used for disaster recovery or high-performance environments.
    </p><br/>

    <h2>What is a Physical Backup?</h2>
    <p>
      A physical backup is a direct copy of MySQL's database files, such as:
    </p>
    <ul>
      <li>Data files (`.ibd`, `.myd`, `.frm`, etc.)</li><br/>
      <li>Log files (`ib_logfile*` for InnoDB)</li><br/>
      <li>Configuration files (e.g., `my.cnf`)</li><br/>
      <li>Binary logs for replication or point-in-time recovery</li>
    </ul><br/>
    <p>
      These backups are typically created using file-copying tools or specialized utilities like 'MySQL Enterprise Backup' or 'Percona XtraBackup.
    </p>

    <h2>Tools for Physical Backups</h2>
    <ul>
      <li>
        'MySQL Enterprise Backup': A commercial solution provided by Oracle for hot backups.
      </li><br/>
      <li>
        'Percona XtraBackup': A free and open-source tool for creating non-blocking physical backups.
      </li><br/>
      <li>
        'File Copying Tools': Tools like `rsync`, `cp`, or `tar` can be used for cold backups (requires stopping the MySQL server).
      </li>
    </ul><br/>

    <h2>Types of Physical Backups</h2>
    <ul>
      <li>
        'Cold Backups': Performed when the MySQL server is stopped. The database files are copied while the server is offline to ensure consistency.
      </li><br/>
      <li>
        'Hot Backups': Performed while the MySQL server is running, allowing continuous operations. Tools like MySQL Enterprise Backup or Percona XtraBackup are required.
      </li>
    </ul><br/>

    <h2>Cold Backup Example</h2>
    <p>
      To perform a cold backup:
    </p>
    <ol>
      <li>Stop the MySQL server:</li><br/>
      <pre>
        <code>{`sudo systemctl stop mysql`}</code>
      </pre>
      <li>Copy the data directory:</li><br/>
      <pre>
        <code>{`sudo cp -r /var/lib/mysql /backup/mysql-backup`}</code>
      </pre><br />
      <li>Restart the MySQL server:</li>
      <pre>
        <code>{`sudo systemctl start mysql`}</code>
      </pre>
    </ol><br/>

    <h2>Hot Backup Example with Percona XtraBackup</h2>
    <p>
      Percona XtraBackup allows hot backups without stopping the MySQL server:
    </p>
    <ol>
      <li>Install Percona XtraBackup:</li><br/>
      <pre>
        <code>{`sudo apt-get install percona-xtrabackup`}</code>
      </pre>
      <li>Create a backup:</li><br/>
      <pre>
        <code>{`xtrabackup --backup --target-dir=/backup/mysql-backup`}</code>
      </pre>
      <li>Prepare the backup for restoration:</li><br/>
      <pre>
        <code>{`xtrabackup --prepare --target-dir=/backup/mysql-backup`}</code>
      </pre>
      <li>Restore the backup:</li>
      <pre>
        <code>{`xtrabackup --copy-back --target-dir=/backup/mysql-backup`}</code>
      </pre>
    </ol><br/>

    <h2>Advantages of Physical Backups</h2>
    <ul>
      <li>Faster for large databases compared to logical backups.</li><br/>
      <li>Preserves database consistency, especially for InnoDB tables.</li><br/>
      <li>Supports point-in-time recovery when combined with binary logs.</li><br/>
      <li>Can back up entire databases or specific files/directories.</li>
    </ul><br/>

    <h2>Limitations of Physical Backups</h2>
    <ul>
      <li>Not portable across different MySQL versions or architectures.</li><br/>
      <li>Requires additional tools for hot backups.</li><br/>
      <li>Backup files may consume significant storage space.</li><br/>
      <li>Restoration requires precise replication of the file structure.</li>
    </ul><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>Use hot backups for high-availability environments.</li><br/>
      <li>Combine physical backups with binary logs for point-in-time recovery.</li><br/>
      <li>Validate and test backups regularly to ensure reliability.</li><br/>
      <li>Encrypt backup files to ensure data security.</li><br/>
      <li>Use incremental backups to save time and storage.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      Physical backups in MySQL are essential for efficiently backing up and restoring large databases. Tools like Percona XtraBackup and MySQL Enterprise Backup provide robust solutions for both hot and incremental backups. Proper implementation and regular testing of physical backups ensure the reliability and availability of critical data.
    </p>
  </div>
)}




{selectedChapter === 'chapter94' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Restoring Databases  </h1>


    <p>
      Restoring a database in MySQL involves recovering data from a backup file and applying it back to the database. The restoration process depends on the type of backup used, such as logical or physical backups. Proper restoration ensures data integrity and minimizes downtime in case of failures.
    </p><br/>

    <h2>Types of Database Restorations</h2>
    <p>The restoration method varies depending on the backup type:</p>
    <ul>
      <li>
        Logical Backup Restoration: Involves importing SQL scripts generated by tools like `mysqldump`.
      </li><br/>
      <li>
        Physical Backup Restoration: Involves copying and replacing raw database files.
      </li>
    </ul><br/>

    <h2>1. Restoring from Logical Backups</h2>
    <p>
      Logical backups, created with tools like `mysqldump`, produce SQL dump files. These can be restored using MySQL client commands.
    </p>

    <h3>Steps to Restore from a SQL Dump File</h3>
    <ol>
      <li>Log in to MySQL:</li><br/>
      <pre>
        <code>{`mysql -u [username] -p`}</code>
      </pre>
      <li>Restore the database:</li><br/>
      <pre>
        <code>{`mysql -u [username] -p [database_name] < /path/to/backup.sql`}</code>
      </pre>
      <li>
        If the database doesn't exist, create it first:
      </li>
      <pre>
        <code>{`CREATE DATABASE [database_name];`}</code>
      </pre>
      <p>Then run the restore command.</p>
    </ol><br/>

    <h3>Example</h3>
    <pre>
      <code>
        {`mysql -u root -p my_database < /backups/my_database_backup.sql`}
      </code>
    </pre><br/>

    <h2>2. Restoring from Physical Backups</h2>
    <p>
      Physical backups require replacing database files. This method is faster for large databases but requires the server to be stopped during the restoration.
    </p>

    <h3>Steps to Restore Physical Backups</h3>
    <ol>
      <li>Stop the MySQL server:</li><br/>
      <pre>
        <code>{`sudo systemctl stop mysql`}</code>
      </pre>
      <li>Replace the data directory:</li><br/>
      <pre>
        <code>{`sudo cp -r /path/to/backup/mysql-data /var/lib/mysql`}</code>
      </pre>
      <li>Set appropriate permissions:</li><br/>
      <pre>
        <code>{`sudo chown -R mysql:mysql /var/lib/mysql`}</code>
      </pre>
      <li>Start the MySQL server:</li>
      <pre>
        <code>{`sudo systemctl start mysql`}</code>
      </pre>
    </ol><br/>

    <h3>Restoring with Percona XtraBackup</h3>
    <ol>
      <li>Prepare the backup:</li><br/>
      <pre>
        <code>{`xtrabackup --prepare --target-dir=/backup/mysql-backup`}</code>
      </pre>
      <li>Restore the files:</li><br/>
      <pre>
        <code>{`xtrabackup --copy-back --target-dir=/backup/mysql-backup`}</code>
      </pre>
      <li>Set ownership and permissions:</li><br/>
      <pre>
        <code>{`sudo chown -R mysql:mysql /var/lib/mysql`}</code>
      </pre>
      <li>Restart the server:</li>
      <pre>
        <code>{`sudo systemctl start mysql`}</code>
      </pre>
    </ol><br/>

    <h2>3. Restoring with Binary Logs</h2>
    <p>
      For point-in-time recovery, combine backups with binary logs to restore up to a specific point.
    </p>
    <h3>Steps</h3>
    <ol>
      <li>Restore the base backup (logical or physical).</li><br/>
      <li>Apply binary logs using the `mysqlbinlog` utility:</li>
      <pre>
        <code>
          {`mysqlbinlog /var/lib/mysql/binlog.000001 | mysql -u [username] -p`}
        </code>
      </pre>
    </ol><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>Test backups regularly to ensure they can be restored successfully.</li><br/>
      <li>Document the backup and restoration procedures for your team.</li><br/>
      <li>Ensure backups are encrypted and stored securely.</li><br/>
      <li>Use replication and failover strategies to minimize downtime during restoration.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      Restoring databases in MySQL is a critical process for recovering from data loss or corruption. By following the appropriate steps for logical or physical backups, you can ensure data integrity and minimize downtime. Regular testing and documentation of your restoration process are essential for effective recovery.
    </p>
  </div>
)}



{selectedChapter === 'chapter95' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Exporting and Importing Data   </h1>


    <p>
      Exporting and importing data in MySQL involves transferring data between MySQL and external sources, such as files or other databases. These operations are essential for backups, migrations, and integrating data across systems.
    </p><br/>

    <h2>Exporting Data</h2>
    <p>
      Data export in MySQL involves extracting database content and saving it into an external file, such as a SQL script or a CSV file.
    </p><br/>

    <h3 style={{paddingBottom:"6px"}}>1. Exporting Using `mysqldump`</h3>
    <p>
      The `mysqldump` tool exports database content as a SQL script. This is a common method for backups or transferring data between MySQL instances.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`mysqldump -u [username] -p [database_name] > /path/to/export.sql`}</code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>{`mysqldump -u root -p my_database > /backups/my_database.sql`}</code>
    </pre>
    <p>
      This command creates a SQL dump file of the `my_database` database. The file can later be used for restoration.
    </p><br/>

    <h3 style={{paddingBottom:"6px"}}>2. Exporting as CSV</h3>
    <p>
      Use the `SELECT INTO OUTFILE` statement to export data into a CSV file.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`SELECT * FROM [table_name] INTO OUTFILE '/path/to/export.csv' FIELDS TERMINATED BY ',' ENCLOSED BY '"' LINES TERMINATED BY '\\n';`}</code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>{`SELECT * FROM employees INTO OUTFILE '/tmp/employees.csv' FIELDS TERMINATED BY ',' ENCLOSED BY '"' LINES TERMINATED BY '\\n';`}</code>
    </pre>
    <p>
      This command exports the `employees` table to a CSV file located at `/tmp/employees.csv`.
    </p>
    <h4>Note:</h4>
    <p>
      Ensure MySQL has write permissions to the specified path. The file must not already exist.
    </p><br/>

    <h2>Importing Data</h2>
    <p>
      Data import in MySQL involves loading external data into a database or table.
    </p>

    <h3>1. Importing Using SQL Dump</h3>
    <p>
      Use the MySQL client to import a SQL dump file created by `mysqldump`.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`mysql -u [username] -p [database_name] < /path/to/export.sql`}</code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>{`mysql -u root -p my_database < /backups/my_database.sql`}</code>
    </pre>
    <p>
      This command restores the `my_database` database from the SQL dump file.
    </p><br/>

    <h3>2. Importing from a CSV File</h3>
    <p>
      Use the `LOAD DATA INFILE` statement to import CSV data into a MySQL table.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`LOAD DATA INFILE '/path/to/import.csv' INTO TABLE [table_name] FIELDS TERMINATED BY ',' ENCLOSED BY '"' LINES TERMINATED BY '\\n';`}</code>
    </pre>
    <h4>Example</h4>
    <pre>
      <code>{`LOAD DATA INFILE '/tmp/employees.csv' INTO TABLE employees FIELDS TERMINATED BY ',' ENCLOSED BY '"' LINES TERMINATED BY '\\n';`}</code>
    </pre>
    <p>
      This command imports data from the `employees.csv` file into the `employees` table.
    </p>
    <h4>Note:</h4>
    <p>
      Ensure the `secure_file_priv` setting in MySQL allows reading files from the specified path.
    </p><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>
        Validate the imported data to ensure it aligns with the target schema.
      </li><br/>
      <li>
        Use transaction management to ensure data integrity during imports.
      </li><br/>
      <li>
        Back up existing data before importing, especially for large or critical datasets.
      </li><br/>
      <li>
        Configure proper permissions for file operations when using `SELECT INTO OUTFILE` or `LOAD DATA INFILE`.
      </li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      Exporting and importing data in MySQL are essential for backups, migrations, and data integration. Tools like `mysqldump` and commands like `SELECT INTO OUTFILE` and `LOAD DATA INFILE` provide flexibility for handling various data transfer scenarios. Proper planning and validation are key to successful data operations.
    </p>
  </div>
)}



{selectedChapter === 'chapter96' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Partitioning Tables    </h1>

    
    <p>
      Partitioning in MySQL allows you to divide large tables into smaller, more manageable pieces, while keeping the logical structure of the table intact. Each piece is called a 'partition'. Partitioning helps improve performance and manageability of large tables by allowing queries to operate on only relevant partitions.
    </p><br/>

    <h2>Types of Partitioning</h2>
    <p>
      MySQL supports several types of partitioning. The choice of partitioning type depends on the data and the types of queries being executed.
    </p><br/>

    <h3>1. Range Partitioning</h3>
    <p>
      In range partitioning, rows are distributed into partitions based on a range of values. Typically, this is used for date or numeric ranges.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`CREATE TABLE table_name (
  id INT,
  name VARCHAR(50),
  date DATE
)
PARTITION BY RANGE (YEAR(date)) (
  PARTITION p0 VALUES LESS THAN (2020),
  PARTITION p1 VALUES LESS THAN (2021),
  PARTITION p2 VALUES LESS THAN (2022)
);`}</code>
    </pre>
    <p>
      In this example, the table is partitioned by year, with each partition containing data for a specific year.
    </p><br/>

    <h3>2. List Partitioning</h3>
    <p>
      List partitioning is used when the column values are discrete and fall into predefined groups. Each partition contains rows with values from a specific set.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`CREATE TABLE table_name (
  id INT,
  name VARCHAR(50),
  country VARCHAR(50)
)
PARTITION BY LIST (country) (
  PARTITION p1 VALUES IN ('USA', 'Canada'),
  PARTITION p2 VALUES IN ('Mexico', 'Brazil'),
  PARTITION p3 VALUES IN ('India', 'China')
);`}</code>
    </pre>
    <p>
      In this example, data is partitioned based on the `country` column. Each partition contains rows for specific countries.
    </p><br/>

    <h3>3. Hash Partitioning</h3>
    <p>
      Hash partitioning distributes the rows evenly across partitions based on a hash function. This is useful when you want to ensure that the data is evenly distributed but don't have a natural range or list to partition by.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`CREATE TABLE table_name (
  id INT,
  name VARCHAR(50)
)
PARTITION BY HASH(id)
PARTITIONS 4;`}</code>
    </pre>
    <p>
      In this example, the table is partitioned into 4 equal partitions based on the hash value of the `id` column.
    </p><br/>

    <h3>4. Key Partitioning</h3>
    <p>
      Key partitioning is similar to hash partitioning but uses MySQL’s internal key function to distribute rows across partitions.
    </p>
    <h4>Syntax</h4>
    <pre>
      <code>{`CREATE TABLE table_name (
  id INT,
  name VARCHAR(50)
)
PARTITION BY KEY()
PARTITIONS 4;`}</code>
    </pre>
    <p>
      In this example, data is evenly distributed across 4 partitions using MySQL's internal key function.
    </p><br/>

    <h2>Benefits of Table Partitioning</h2>
    <ul>
      <li><strong>Improved Query Performance:</strong> Queries that only need data from a specific partition can be faster, as they skip irrelevant partitions.</li><br/>
      <li><strong>Better Manageability:</strong> You can manage partitions individually, such as archiving or purging older partitions without affecting the rest of the table.</li><br/>
      <li><strong>Improved Data Loading:</strong> Data loading operations like `LOAD DATA INFILE` can be performed on individual partitions, improving performance.</li>
    </ul><br/>

    <h2>Considerations and Limitations</h2>
    <ul>
      <li><strong>Partitioning Overhead:</strong> Partitioning adds complexity to table management. Ensure that the performance gain justifies the overhead.</li><br/>
      <li><strong>Partition Pruning:</strong> MySQL will only scan partitions relevant to a query, but the partitioning column(s) must be involved in the query’s `WHERE` clause for this optimization to work.</li><br/>
      <li><strong>ALTER Operations:</strong> Some operations, like `ALTER TABLE`, can be more complex or slower with partitioned tables.</li><br/>
      <li><strong>Foreign Key Constraints:</strong> MySQL does not support foreign key constraints on partitioned tables.</li>
    </ul><br/>

    <h2>Best Practices</h2>
    <ul>
      <li>Use 'range' partitioning for date or numerical ranges to improve time-based query performance.</li><br/>
      <li>Use 'hash' or 'key' partitioning for evenly distributed data that doesn't have a natural range or list.</li><br/>
      <li>Ensure that queries involve partitioning columns in their `WHERE` clause for partition pruning to be effective.</li><br/>
      <li>Consider the performance implications of partitioning, especially for write-heavy applications.</li>
    </ul><br/>

    <h2>Summary</h2>
    <p>
      Table partitioning in MySQL allows for splitting large tables into smaller, manageable pieces, improving query performance and manageability. MySQL supports several partitioning types, including range, list, hash, and key. Understanding when and how to partition a table can significantly improve performance and simplify data management, but it should be used carefully to avoid unnecessary complexity.
    </p>
  </div>
)}



{selectedChapter === 'chapter97' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Replication    </h1>


    <p>
      Replication in MySQL is a process that allows data from one MySQL server (the 'master' or 'primary') to be copied to one or more other MySQL servers (the 'slaves' or 'replicas'). This helps to improve availability, scalability, and data redundancy. Replication is widely used for backup, load balancing, and disaster recovery.
    </p><br/>

    <h2>How MySQL Replication Works</h2>
    <p>
      MySQL replication works by copying the changes made to the master server to its slaves. Each time a change (such as an insert, update, or delete) is made to the master, the slave servers replicate those changes in the same order. The replication process is asynchronous, meaning that the slave does not need to immediately apply the changes but does so eventually.
    </p><br/>

    <h3>Key Concepts</h3>
    <ul>
      <li><strong>Master Server:</strong> The main server where all the data changes occur. It writes data to the binary log.</li><br/>
      <li><strong>Slave Server:</strong> The server that copies data from the master by reading the binary log and applying changes.</li><br/>
      <li><strong>Binary Log:</strong> A file on the master that records all changes to the database. The slave uses this log to replicate changes.</li><br/>
      <li><strong>Replication Thread:</strong> The slave has a replication thread that connects to the master and pulls changes from the binary log.</li>
    </ul><br/>

    <h2>Types of Replication</h2>
    <p>
      There are two main types of replication in MySQL:
    </p><br/>

    <h3>1. Asynchronous Replication</h3>
    <p>
      In asynchronous replication, the master sends data changes to the slave, but it does not wait for the slave to confirm that the changes have been applied. This means that there may be a delay between when the master writes data and when the slave applies it. However, it is efficient and doesn't affect the master server’s performance.
    </p><br/>

    <h3>2. Semi-Synchronous Replication</h3>
    <p>
      In semi-synchronous replication, the master server waits for at least one slave to acknowledge receipt of the changes before it commits the transaction. This provides a balance between performance and data consistency. It ensures that data changes are not lost if the master crashes before the slave has applied the changes.
    </p><br/>

    <h3>3. Synchronous Replication</h3>
    <p>
      Synchronous replication ensures that all replicas have confirmed the change before the master commits a transaction. This guarantees data consistency but can introduce latency and performance overhead.
    </p><br/>

    <h2>Setting Up MySQL Replication</h2>
    <p>
      Setting up MySQL replication involves configuring both the master and slave servers. Here’s a step-by-step overview:
    </p><br/>

    <h3>1. Configuring the Master Server</h3>
    <p>
      On the master server, enable binary logging and set a unique server ID. The configuration should include the following in the `my.cnf` file:
    </p>
    <pre>
      <code>{`[mysqld]
      log-bin=mysql-bin
      server-id=1
      binlog-do-db=my_database;`}</code>
    </pre>
    <p>
      The `log-bin` option enables binary logging, and `server-id` sets a unique ID for the server. The `binlog-do-db` option specifies which databases to replicate.
    </p><br/>

    <h3>2. Creating a Replication User on the Master</h3>
    <p>
      Create a user with replication privileges on the master server:
    </p>
    <pre>
      <code>{`CREATE USER 'replica_user'@'%' IDENTIFIED BY 'password';
      GRANT REPLICATION SLAVE ON *.* TO 'replica_user'@'%';`}</code>
    </pre>
    <p>
      This user will be used by the slave to authenticate with the master server.
    </p><br/>

    <h3>3. Configuring the Slave Server</h3>
    <p>
      On the slave server, set the unique server ID and configure it to start replication. The configuration file (`my.cnf`) on the slave server should include:
    </p>
    <pre>
      <code>{`[mysqld]
      server-id=2
      relay-log=relay-bin
      log-bin=mysql-bin;`}</code>
    </pre>
    <p>
      Set the `server-id` to a unique value different from the master’s server ID.
    </p><br/>

    <h3>4. Starting the Replication on the Slave</h3>
    <p>
      On the slave, execute the following commands to start replication:
    </p>
    <pre>
      <code>{`CHANGE MASTER TO
      MASTER_HOST='master_host',
      MASTER_USER='replica_user',
      MASTER_PASSWORD='password',
      MASTER_LOG_FILE='mysql-bin.000001',
      MASTER_LOG_POS=107;
      
      START SLAVE;`}</code>
    </pre>
    <p>
      The `MASTER_LOG_FILE` and `MASTER_LOG_POS` values can be obtained from the master server using the `SHOW MASTER STATUS` command.
    </p><br/>

    <h2>Monitoring and Managing Replication</h2>
    <p>
      After setting up replication, you can monitor and manage the replication status:
    </p>

    <h3>Checking Slave Status</h3>
    <p>
      On the slave, you can use the following command to check the status of the replication:
    </p>
    <pre>
      <code>{`SHOW SLAVE STATUS\G`}</code>
    </pre>
    <p>
      This will show important information such as whether the slave is running correctly and whether it is synchronized with the master.
    </p><br/>

    <h3>Stopping Replication</h3>
    <p>
      If you need to stop replication on the slave for any reason, use the following command:
    </p>
    <pre>
      <code>{`STOP SLAVE;`}</code>
    </pre><br/>

    <h2>Use Cases for MySQL Replication</h2>
    <ul>
      <li><strong>Load Balancing:</strong> Distribute read queries across multiple slave servers to reduce the load on the master server.</li><br/>
      <li><strong>Data Redundancy:</strong> Create a backup system by replicating data across multiple servers, ensuring high availability.</li><br/>
      <li><strong>Disaster Recovery:</strong> Replication allows for automatic failover in the event of a master server failure.</li><br/>
      <li><strong>Geographically Distributed Databases:</strong> Replication allows for creating copies of a database in different geographical locations to reduce latency for users in different regions.</li>
    </ul><br/>

    <h2>Considerations and Limitations</h2>
    <ul>
      <li><strong>Replication Lag:</strong> There may be a delay between the changes being made on the master and when they appear on the slave, especially under high load.</li><br/>
      <li><strong>Write Scalability:</strong> MySQL replication is primarily for read scalability. If you need to scale writes, you may need to look into sharding or other strategies.</li><br/>
      <li><strong>Master-Slave Dependency:</strong> The failure of the master server can impact the entire replication system, though tools like automatic failover can mitigate this.</li>
    </ul><br/>

    <h2>Summary</h2>
    <p>
      Replication in MySQL is a powerful feature that provides redundancy, scalability, and high availability. By configuring a master-slave setup, you can improve performance and data consistency across multiple MySQL servers. However, it's essential to monitor replication carefully and understand the potential limitations, such as replication lag and the complexity of managing a distributed environment.
    </p>
  </div>
)}




{selectedChapter === 'chapter98' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  MySQL Cluster Concepts  </h1>


    <p>
      MySQL Cluster is a technology that provides high availability and scalability for MySQL databases by distributing data across multiple nodes. It is designed to handle large-scale, mission-critical applications with minimal downtime and high performance. MySQL Cluster uses a shared-nothing architecture, where data is partitioned across multiple nodes, and each node is independent of the others.
    </p><br/>

    <h2>Key Features of MySQL Cluster</h2>
    <ul>
      <li><strong>High Availability:</strong> Data is replicated across multiple nodes to ensure that the system remains available even if one node fails.</li><br/>
      <li><strong>Scalability:</strong> MySQL Cluster can scale horizontally by adding more data nodes, allowing you to handle growing data and traffic.</li><br/>
      <li><strong>Real-Time Performance:</strong> It is optimized for low-latency, high-throughput operations, making it ideal for real-time applications.</li><br/>
      <li><strong>Automatic Failover:</strong> If a node fails, the cluster automatically reconfigures itself and continues to operate without downtime.</li><br/>
      <li><strong>Shared-Nothing Architecture:</strong> Each node is independent, with its own memory and storage, minimizing the risk of single points of failure.</li>
    </ul><br/>

    <h2>MySQL Cluster Architecture</h2>
    <p>
      The MySQL Cluster architecture consists of several key components, each of which plays an important role in ensuring high availability, performance, and scalability. These components include:
    </p><br/>

    <h3>1. Data Nodes</h3>
    <p>
      Data nodes are responsible for storing and managing the actual data in the cluster. They hold both the data and indexes, and they replicate the data to ensure high availability. Data nodes use in-memory storage and a distributed, shared-nothing model. You can scale the cluster by adding more data nodes.
    </p><br/>

    <h3>2. Management Nodes</h3>
    <p>
      Management nodes control the cluster’s operations, including configuration, monitoring, and failure recovery. They also handle the coordination of cluster nodes, such as data node startup and shutdown. Management nodes do not store any data themselves but manage the overall cluster topology.
    </p><br/>

    <h3>3. SQL Nodes</h3>
    <p>
      SQL nodes (also called MySQL Cluster API nodes) are responsible for handling client connections and executing SQL queries. These nodes interface with the data nodes to perform read and write operations. SQL nodes run the MySQL server software and can be replicated for load balancing and fault tolerance.
    </p><br/>

    <h3>4. Node Groups</h3>
    <p>
      A node group is a logical group of data nodes that holds a partition of the database's data. Each node group contains replicas of data for fault tolerance. Typically, MySQL Cluster uses two copies of data per partition, meaning that each node group contains two data nodes holding replicas of the same data.
    </p><br/>

    <h3>5. Network Partitioning</h3>
    <p>
      MySQL Cluster is designed to handle network partitions efficiently. Each data node has a communication layer that allows it to detect and resolve network partitions. This ensures that the cluster can continue functioning even if certain nodes become temporarily unreachable.
    </p><br/>

    <h2>How MySQL Cluster Works</h2>
    <p>
      MySQL Cluster uses a distributed, in-memory data storage model. Data is partitioned across multiple data nodes, and each partition is replicated across different nodes to ensure high availability and fault tolerance. Here's an overview of how MySQL Cluster works:
    </p><br/>

    <h3>1. Data Distribution</h3>
    <p>
      Data in MySQL Cluster is automatically partitioned into smaller chunks called **partitions**. Each partition is stored on one or more data nodes. The partitions are distributed evenly across the data nodes to balance the load. Each partition has a replica on another node to provide redundancy.
    </p><br/>

    <h3>2. Data Replication</h3>
    <p>
      MySQL Cluster uses a replication mechanism that ensures that each piece of data is available on multiple nodes. The data is replicated between nodes using the **NDB** (Network DataBase) engine. NDB ensures that data changes made on one node are reflected on its replica, providing fault tolerance and ensuring high availability.
    </p><br/>

    <h3>3. Query Processing</h3>
    <p>
      When a query is sent to the MySQL Cluster, the SQL node processes the query and communicates with the appropriate data nodes to retrieve or modify the data. Since the data is distributed across multiple nodes, the query is executed in parallel across the data nodes, which ensures that the system can handle large-scale queries efficiently.
    </p><br/>

    <h2>Setting Up MySQL Cluster</h2>
    <p>
      To set up MySQL Cluster, you need to configure the following components:
    </p>

    <h3>1. Configure the Management Node</h3>
    <p>
      The management node is responsible for overseeing the cluster’s operation. You will need to configure the `config.ini` file to define the nodes in your cluster, the node groups, and the number of replicas.
    </p>
    <pre>
      <code>{`[ndbd default]
      NoOfReplicas=2

      [ndb_mgmd]
      NodeId=1
      HostName=192.168.1.1

      [ndbd]
      NodeId=2
      HostName=192.168.1.2

      [ndbd]
      NodeId=3
      HostName=192.168.1.3

      [mysqld]
      NodeId=4
      HostName=192.168.1.4`}</code>
    </pre><br/>

    <h3>2. Configure Data Nodes</h3>
    <p>
      Configure the data nodes to store and replicate data. You need to define each data node’s configuration in the `my.cnf` file and ensure it points to the management node.
    </p>
    <pre>
      <code>{`[mysqld]
      ndbcluster
      cluster-name=mycluster
`}</code>
    </pre><br/>

    <h3>3. Configure SQL Nodes</h3>
    <p>
      SQL nodes are responsible for handling queries from clients. You need to configure the SQL nodes to connect to the data nodes and set up replication between them.
    </p><br/>

    <h3>4. Start the Cluster</h3>
    <p>
      Once all the components are configured, start the management, data, and SQL nodes in the correct order to bring the cluster online.
    </p>

    <h2>Use Cases for MySQL Cluster</h2>
    <ul>
      <li><strong>High Availability:</strong> MySQL Cluster is designed to offer 99.999% availability, making it ideal for mission-critical applications that cannot afford downtime.</li><br/>
      <li><strong>Real-Time Data Processing:</strong> Applications that require real-time data processing, such as telecommunications, gaming, and IoT applications, benefit from the low-latency architecture of MySQL Cluster.</li><br/>
      <li><strong>Large-Scale Web Applications:</strong> Web applications that experience high traffic volumes can use MySQL Cluster to scale horizontally and handle large amounts of data efficiently.</li>
    </ul><br/>

    <h2>Considerations and Limitations</h2>
    <ul>
      <li><strong>Complexity:</strong> MySQL Cluster is more complex to set up and manage compared to standard MySQL configurations. Proper planning and configuration are essential for optimal performance.</li><br/>
      <li><strong>Memory Requirements:</strong> Since MySQL Cluster stores data in memory, it requires a significant amount of RAM. Ensure that your hardware can handle the demands of the cluster.</li><br/>
      <li><strong>Write Scalability:</strong> MySQL Cluster is optimized for read scalability. However, write-heavy applications may require special tuning to achieve optimal performance.</li>
    </ul><br/>

    <h2>Summary</h2>
    <p>
      MySQL Cluster is a high-availability, distributed database solution that provides real-time performance, scalability, and fault tolerance. By partitioning and replicating data across multiple nodes, MySQL Cluster ensures that large-scale applications can run efficiently and reliably. While setting up and managing a MySQL Cluster can be complex, it offers significant benefits for applications that require high performance and availability.
    </p>
  </div>
)}



{selectedChapter === 'chapter99' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  JSON Data   </h1>



    <p>
      MySQL introduced support for JSON (JavaScript Object Notation) data in version 5.7, enabling users to store, query, and manipulate JSON-formatted data directly within MySQL tables. This feature allows for more flexible and dynamic storage of data, as JSON can represent complex, hierarchical structures like arrays and nested objects.
    </p><br/>

    <h2>Why Use JSON in MySQL?</h2>
    <ul>
      <li><strong>Flexible Schema:</strong> JSON allows you to store data without a predefined schema. You can store complex, nested data structures within a single column.</li><br/>
      <li><strong>Dynamic Data:</strong> JSON is ideal for applications where the structure of the data may change over time, such as user preferences or configuration settings.</li><br/>
      <li><strong>Integration with NoSQL-like Features:</strong> JSON provides some features similar to NoSQL databases, such as the ability to store non-relational data in a relational database.</li><br/>
      <li><strong>Indexing and Querying:</strong> MySQL provides functions to index and efficiently query JSON data, making it suitable for use in relational environments while offering flexibility akin to NoSQL solutions.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>Working with JSON in MySQL</h2>
    <p>
      To store and manipulate JSON data, MySQL provides a <code>JSON</code> data type. This type stores data in a binary format for efficiency but can be interacted with using standard JSON functions.
    </p>

    <h3>Creating a Table with JSON Data</h3>
    <p>
      You can create a table that includes a column with the <code>JSON</code> data type. Here’s an example of how to create a table with a JSON column:
    </p>
    <pre>
      <code>{`CREATE TABLE users (
id INT AUTO_INCREMENT PRIMARY KEY,
name VARCHAR(255),
preferences JSON
);`}</code>
    </pre>
    <p>
      In this example, the <code>preferences</code> column is used to store user-specific settings in JSON format.
    </p><br/>

    <h3>Inserting JSON Data</h3>
    <p>
      You can insert JSON data into a table using standard SQL <code>INSERT</code> statements. MySQL will validate the data to ensure it’s in proper JSON format:
    </p>
    <pre>
      <code>{`INSERT INTO users (name, preferences) 
VALUES ('Alice', '{"theme": "dark", "notifications": {"email": true, "sms": false}}');`}</code>
    </pre>
    <p>
      In this example, a user's preferences are stored as a JSON object containing a theme setting and nested notification preferences.
    </p><br/>

    <h2>Querying JSON Data</h2>
    <p>
      MySQL provides various functions for extracting and manipulating JSON data stored in columns. You can use these functions to access specific elements within a JSON document or perform operations like filtering, updating, or transforming the data.
    </p><br/>

    <h3>Accessing JSON Data</h3>
    <p>
      You can use the <code>JSON_EXTRACT()</code> function to retrieve specific parts of a JSON document:
    </p>
    <pre>
      <code>{`SELECT JSON_EXTRACT(preferences, '$.theme') AS theme
FROM users
WHERE name = 'Alice';`}</code>
    </pre>
    <p>
      This query extracts the value of the "theme" field from the <code>preferences</code> column for the user named Alice.
    </p><br/>

    <h3>Using the Arrow Operator</h3>
    <p>
      MySQL also supports a shorthand for accessing JSON data with the <code>{"->"}</code> operator, which simplifies the syntax:
    </p>
    <pre>
      <code>{`SELECT preferences->'$.theme' AS theme
FROM users
WHERE name = 'Alice';`}</code>
    </pre>
    <p>
      This query is equivalent to the previous one but uses the more concise arrow operator to access the "theme" field from the JSON data.
    </p><br/>

    <h3>Filtering with JSON Data</h3>
    <p>
      You can use JSON data in the <code>WHERE</code> clause to filter results based on specific values in the JSON column:
    </p>
    <pre>
      <code>{`SELECT name
FROM users
WHERE JSON_UNQUOTE(JSON_EXTRACT(preferences, '$.notifications.email')) = 'true';`}</code>
    </pre>
    <p>
      This query filters users based on the value of the "email" notification setting within the JSON column.
    </p><br/>

    <h2>Modifying JSON Data</h2>
    <p>
      MySQL also provides functions for modifying JSON data directly. You can update, add, or remove keys within a JSON document stored in a column.
    </p><br/>

    <h3>Updating JSON Data</h3>
    <p>
      To update JSON data, you can use the <code>JSON_SET()</code> function:
    </p>
    <pre>
      <code>{`UPDATE users
SET preferences = JSON_SET(preferences, '$.theme', 'light')
WHERE name = 'Alice';`}</code>
    </pre>
    <p>
      This query updates the "theme" field in the <code>preferences</code> JSON column for Alice to "light."
    </p><br/>

    <h3>Adding or Removing JSON Keys</h3>
    <p>
      You can add new keys to the JSON document using the <code>JSON_SET()</code> function or remove keys with the <code>JSON_REMOVE()</code> function:
    </p>
    <pre>
      <code>{`UPDATE users
SET preferences = JSON_SET(preferences, '$.newKey', 'value')
WHERE name = 'Alice';`}</code>
    </pre>
    <pre>
      <code>{`UPDATE users
SET preferences = JSON_REMOVE(preferences, '$.oldKey')
WHERE name = 'Alice';`}</code>
    </pre>
    <p>
      These functions modify the existing JSON data by either adding or removing keys.
    </p><br/>

    <h2>Indexing JSON Data</h2>
    <p>
      MySQL provides the ability to index specific JSON values to improve query performance. You can create indexes on JSON data using generated columns:
    </p>
    <pre>
      <code>{`ALTER TABLE users
ADD COLUMN theme VARCHAR(255) AS (JSON_UNQUOTE(JSON_EXTRACT(preferences, '$.theme'))) STORED,
ADD INDEX idx_theme (theme);`}</code>
    </pre>
    <p>
      In this example, a generated column <code>theme</code> is created from the JSON data, and an index is added on that column to improve search performance.
    </p><br/>

    <h2>Limitations of JSON in MySQL</h2>
    <ul>
      <li><strong>No Full-Text Search:</strong> While you can query and index JSON fields, MySQL does not support full-text search on JSON columns.</li><br/>
      <li><strong>Performance Considerations:</strong> Storing large or highly nested JSON documents in a relational database may affect performance due to the overhead of parsing and indexing JSON data.</li><br/>
      <li><strong>Not Fully NoSQL:</strong> While JSON allows you to store flexible data, MySQL is still a relational database, and complex, schema-less data models may be better suited for NoSQL databases.</li>
    </ul><br/>

    <h2>Summary</h2>
    <p>
      MySQL’s support for JSON data enables developers to store and manipulate semi-structured data directly in relational tables. Using functions like <code>JSON_EXTRACT()</code>, <code>JSON_SET()</code>, and <code>JSON_UNQUOTE()</code>, you can easily query, update, and modify JSON data within MySQL. While JSON support in MySQL adds flexibility and is useful for modern web applications, it should be used thoughtfully to avoid performance issues and maintain data integrity.
    </p>
  </div>
)}





{selectedChapter === 'chapter100' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  NoSQL Features    </h1>

  
    <p>
      MySQL, traditionally a relational database, has incorporated several features that make it capable of handling some NoSQL-like use cases. Although MySQL is fundamentally designed for structured, relational data, it has introduced features that allow for more flexible, dynamic, and schema-less data storage, similar to what is typically seen in NoSQL databases. These features are especially beneficial for modern applications that require scalability, flexibility, and dynamic data models.
    </p><br/>

    <h2>Key NoSQL-Like Features in MySQL</h2>
    <p>
      Here are some of the key NoSQL-like features that MySQL offers:
    </p><br/>

    <h3 style={{paddingBottom:"6px"}}>1. JSON Support</h3>
    <p>
      MySQL 5.7 introduced support for the <code>JSON</code> data type, which allows for storing and manipulating JSON (JavaScript Object Notation) data directly within relational tables. JSON is a flexible, human-readable format for representing hierarchical data, and MySQL’s support for JSON provides NoSQL-like capabilities by allowing users to store and query semi-structured data.
    </p>
    <ul>
      <li><strong>Storage:</strong> JSON data is stored in a binary format for efficiency.</li><br/>
      <li><strong>Manipulation:</strong> MySQL offers functions such as <code>JSON_EXTRACT()</code>, <code>JSON_SET()</code>, and <code>JSON_ARRAY()</code> to query and manipulate JSON data.</li><br/>
      <li><strong>Indexing:</strong> MySQL allows indexing JSON data using generated columns, improving performance on queries involving JSON fields.</li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>2. Dynamic and Schema-less Data</h3>
    <p>
      While MySQL is a relational database, the use of the <code>JSON</code> type (and other flexible data storage options) allows it to support dynamic data that doesn't require a fixed schema. This is a key characteristic of NoSQL databases, where data can evolve over time without requiring changes to the underlying schema.
    </p>
    <ul>
      <li><strong>Flexible Data Structure:</strong> You can store varying attributes and data types in a single column without having to modify the table schema.</li><br/>
      <li><strong>Complex Data Models:</strong> JSON allows for representing hierarchical data, such as arrays and objects, without needing to normalize data into separate relational tables.</li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>3. Full-Text Search Support</h3>
    <p>
      Full-text search is another feature that aligns MySQL with some NoSQL databases. Full-text search allows you to search for words or phrases in text-based columns, and it’s especially useful when dealing with large amounts of unstructured text data.
    </p>
    <ul>
      <li><strong>Indexing:</strong> MySQL provides full-text indexes on <code>TEXT</code> and <code>CHAR</code> columns, making it easier to perform fast text searches over large datasets.</li><br/>
      <li><strong>Search Features:</strong> MySQL supports search functions like <code>MATCH()...</code> and <code>AGAINST()</code> to perform natural language and Boolean searches.</li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>4. Horizontal Scaling with Partitioning</h3>
    <p>
      While MySQL is primarily a vertically scalable database, it also offers some horizontal scaling features that are typically associated with NoSQL databases. One of these features is **partitioning**, which allows you to split large tables into smaller, more manageable parts (partitions). Each partition can be stored on different physical devices, enabling better distribution of data and improving performance for large-scale applications.
    </p>
    <ul>
      <li><strong>Partitioning Types:</strong> MySQL supports range, list, hash, and key partitioning methods.</li><br/>
      <li><strong>Improved Query Performance:</strong> By distributing data across multiple partitions, query performance is improved because only relevant partitions need to be accessed.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>5. NoSQL Interfaces: MySQL with NDB Cluster</h3>
    <p>
      MySQL Cluster, which uses the NDB (Network DataBase) storage engine, is an example of a NoSQL-like feature within MySQL. It provides a distributed database that offers high availability, scalability, and fault tolerance. MySQL Cluster allows for horizontal scaling and supports real-time applications, providing features commonly seen in NoSQL databases like automatic sharding and replication.
    </p>
    <ul>
      <li><strong>Distributed Architecture:</strong> Data is distributed across multiple nodes, improving availability and fault tolerance.</li><br/>
      <li><strong>Real-Time Applications:</strong> MySQL Cluster is optimized for low-latency access, making it suitable for real-time applications.</li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>6. Built-in Caching</h3>
    <p>
      MySQL offers various caching mechanisms that improve performance, especially for read-heavy workloads. While caching isn't a characteristic unique to NoSQL databases, it’s important in scenarios where quick access to data is critical, similar to how some NoSQL databases handle data retrieval with low latency.
    </p>
    <ul>
      <li><strong>Query Cache:</strong> MySQL provides a query cache that stores the result set of SELECT queries, reducing the need to repeat expensive queries.</li><br/>
      <li><strong>In-Memory Storage Engines:</strong> MySQL supports in-memory storage engines such as <code>MEMORY</code>, which stores data in RAM, providing very fast access times.</li>
    </ul><br/>

    <h2>When to Use MySQL for NoSQL-like Features</h2>
    <p>
      While MySQL offers several NoSQL-like features, it's important to choose the right tool for the job. MySQL is best suited for applications that require strong consistency, transactional integrity (ACID properties), and complex relational queries. If your application requires large-scale, schema-less, and highly distributed data models, you may want to consider a dedicated NoSQL database like MongoDB or Cassandra. However, MySQL’s NoSQL features are beneficial when you need the flexibility of NoSQL with the power and reliability of a relational database.
    </p><br/>

    <h2 style={{paddingBottom:"6px"}}>Advantages of NoSQL Features in MySQL</h2>
    <ul>
      <li><strong>Flexibility:</strong> Allows for dynamic, schema-less data storage, making it easier to handle evolving data models.</li><br/>
      <li><strong>Full-text Search:</strong> Provides built-in support for text searching and indexing, useful for large unstructured text datasets.</li><br/>
      <li><strong>Horizontal Scaling:</strong> Features like partitioning and MySQL Cluster provide the ability to scale MySQL horizontally, similar to NoSQL databases.</li><br/>
      <li><strong>Integration:</strong> MySQL’s JSON support allows for easy integration with web applications and modern programming languages that use JSON as a primary data format.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>Challenges and Limitations</h2>
    <ul>
      <li><strong>Performance for Large NoSQL Workloads:</strong> While MySQL can handle some NoSQL use cases, it may not perform as well as specialized NoSQL databases in scenarios involving very large or complex datasets.</li><br/>
      <li><strong>Data Structure Limitations:</strong> MySQL still relies on relational concepts, which may not be as flexible as NoSQL databases for handling highly dynamic or unstructured data models.</li><br/>
      <li><strong>Complexity:</strong> For users accustomed to fully relational database models, the introduction of NoSQL-like features may require additional complexity when designing and optimizing schemas and queries.</li>
    </ul><br/>

    <h2>Summary</h2>
    <p>
      MySQL has evolved to incorporate several features that align with NoSQL databases, such as JSON support, flexible and schema-less data storage, horizontal scaling, and full-text search capabilities. These features allow MySQL to handle use cases that would typically require NoSQL solutions, making it a more versatile option for modern web applications. However, while MySQL offers NoSQL-like features, it is still fundamentally a relational database, and in some cases, a specialized NoSQL database might be a better choice for certain workloads.
    </p>
  </div>
)}
 




                </div>
            </div>
        </div>

    );

}


export default MySQLcourse;


