import React from 'react'
import style from './../Css/Certification.module.css'

const Certification = () => {
  return (
    <>
     <div className={style.certificationcard}>
      <div className={style.certificationheader}>
        <img
          src="Images/certification.png"
          alt="Certificate Badge"
          className={style.certificatebadge}
        />
      </div>
      <div className={style.certificationbody}>
        <h3 className={style.certificatename}>Full-Stack Web Development</h3>
        <p className={style.organization}>Issued by: ABC Tech Academy</p>
        <p className={style.issueddate}>Issued on: September 1, 2024</p>
        <p className={style.certificatedescription}>
          This certification recognizes the completion of the Full-Stack Web Development course, covering technologies like React, Node.js, and MongoDB.
        </p>
      </div>
      <div className={style.certificationfooter}>
        <button className={style.viewbutton}>View Certificate</button>
        <a href="https://example.com/fullstack-certificate.pdf" download>
          <button className={style.downloadbutton}>Download Certificate</button>
        </a>
      </div>
    </div>
    
    
    </>
  )
}

export default Certification