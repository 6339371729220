import React, { useRef, useState } from 'react';
import axios from 'axios';
import style from './../Css/jobApplicationform.module.css';
import API_BASE_URL from '../apiConfig';
import { showCustomAlert } from '../customalert';

const JobApplicationform = () => {
    const fileInputRef = useRef(null); // Reference for the file input
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        jobPosition: '',
        experience: '',
        education: '',
        skills: '',
        linkedin: '',
        portfolio: '',
        coverLetter: '',
        resume: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: files[0], // Only get the first file in case of multiple files
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if resume is uploaded
        if (!formData.resume) {
            showCustomAlert('Please upload your resume.');
            return;
        }

        // Create a new FormData object to handle file uploads along with other form data
        const formDataToSend = new FormData();
        formDataToSend.append('fullName', formData.fullName);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('jobPosition', formData.jobPosition);
        formDataToSend.append('experience', formData.experience);
        formDataToSend.append('education', formData.education);
        formDataToSend.append('skills', formData.skills);
        formDataToSend.append('linkedin', formData.linkedin);
        formDataToSend.append('portfolio', formData.portfolio);
        formDataToSend.append('coverLetter', formData.coverLetter);
        formDataToSend.append('resume', formData.resume); // Append the file

        // Send the data to the PHP backend using axios
        axios
            .post(`${API_BASE_URL}/job_application.php`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                // console.log('Success:', response.data);
                showCustomAlert('Application submitted successfully!');
                // Reset the form data to clear the fields after successful submission
                setFormData({
                    fullName: '',
                    email: '',
                    phoneNumber: '',
                    jobPosition: '',
                    experience: '',
                    education: '',
                    skills: '',
                    linkedin: '',
                    portfolio: '',
                    coverLetter: '',
                    resume: null,
                });
                // Reset the file input manually
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            })
            .catch((error) => {
                // console.error('Error:', error.response ? error.response.data : error.message);
                showCustomAlert('Error submitting the application.');
            });
    };

    return (
        <div className="container">
            <div className={style.applicationFormWrapper}>
                <div className={style.jobHeader}>
                    <h2 className={style.heading}>Job Application Form</h2>
                    <div>
                        <h4>Job details</h4>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={style.applicationForm}>
                    {/* Full Name and Email Row */}
                    <div className={style.inputsjobrows}>
                        <div className={style.formGroup}>
                            <input
                                className={style.inputField}
                                type="text"
                                name="fullName"
                                placeholder=" "
                                value={formData.fullName}
                                onChange={handleChange}
                                required
                            />
                            <label className={style.floatingLabel}>Full Name</label>
                        </div>

                        <div className={style.formGroup}>
                            <input
                                className={style.inputField}
                                type="email"
                                name="email"
                                placeholder=" "
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label className={style.floatingLabel}>Email</label>
                        </div>
                    </div>

                    <div className={style.inputsjobrows}>
                        <div className={style.formGroup}>
                            <input
                                className={style.inputField}
                                type="tel"
                                name="phoneNumber"
                                placeholder=" "
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                            <label className={style.floatingLabel}>Phone Number</label>
                        </div>
                        <div className={style.formGroup}>
                            <input
                                className={style.inputField}
                                type="txt"
                                name="linkedin"
                                placeholder=" "
                                value={formData.linkedin}
                                onChange={handleChange}
                            />
                            <label className={style.floatingLabel}>LinkedIn Profile</label>
                        </div>
                    </div>

                    {/* Job Position and Education Row */}
                    <div className={style.inputsjobrows}>
                        <div className={style.formGroup}>
                            <select
                                className={style.selectField}
                                name="jobPosition"
                                value={formData.jobPosition}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Job Position</option>
                                <option value="Frontend Developer">Frontend Developer</option>
                                <option value="Backend Developer">Backend Developer</option>
                                <option value="Full Stack Developer">Full Stack Developer</option>
                                <option value="UI/UX Designer">UI/UX Designer</option>
                            </select>
                        </div>

                        <div className={style.formGroup}>
                            <select
                                className={style.selectField}
                                name="education"
                                value={formData.education}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Education</option>
                                <option value="High School">High School</option>
                                <option value="Bachelor's Degree">Bachelor's Degree</option>
                                <option value="Master's Degree">Master's Degree</option>
                                <option value="PhD">PhD</option>
                            </select>
                        </div>
                    </div>

                    <div className={style.formGroup}>
                        <input
                            className={style.inputField}
                            type="text"
                            name="skills"
                            placeholder=" "
                            value={formData.skills}
                            onChange={handleChange}
                            required
                        />
                        <label className={style.floatingLabel}>Key Skills</label>
                    </div>

                    <div className={style.formGroup}>
                        <input
                            className={style.inputField}
                            type="txt"
                            name="portfolio"
                            placeholder=" "
                            value={formData.portfolio}
                            onChange={handleChange}
                        />
                        <label className={style.floatingLabel}>Portfolio Website</label>
                    </div>

                    <div className={style.formGroup}>
                        <textarea
                            className={style.textArea}
                            name="coverLetter"
                            placeholder=" "
                            rows="4"
                            value={formData.coverLetter}
                            onChange={handleChange}
                            required
                        />
                        <label className={style.floatingLabel}>Cover Letter</label>
                    </div>

                    <div className={style.formGroup}>
                        <label className={style.fileLabel}>
                            Upload Resume
                            <input
                                className={style.fileInput}
                                type="file"
                                name="resume"
                                onChange={handleFileChange}
                                ref={fileInputRef} // Attach the ref
                                required
                            />
                        </label>
                    </div>

                    <button type="submit" className={style.submitButton}>
                        Submit Application
                    </button>
                </form>
            </div>
        </div>
    );
};

export default JobApplicationform;
