import { useRef, useState, useEffect } from "react";
import React from "react";
import style from "./../Css/Liveproject.module.css";
import { Link } from "react-router-dom";
import Applyforinternship from './Applyforinternship'
import API_BASE_URL from "../apiConfig";

const Liveproject = () => {
  // State for managing which section is active
  const [activeSection, setActiveSection] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [projects, setProjects] = useState([]); // State to store fetched projects

    // Fetch data from backend
    useEffect(() => {
      const fetchProjects = async () => {
        try {
          const response = await fetch
             (`${API_BASE_URL}/fetch_live_projects.php`
          );
          const data = await response.json();
          setProjects(data); // Set the fetched data
        } catch (error) {
          console.error("Error fetching projects:", error);
        }
      };
      fetchProjects();
    }, []);

  // Handle section change (for navigation bar)
  const handleSectionChange = (section) => {
    setActiveSection(section || null);
  };


  // Scroll container for horizontal scrolling
  const scrollContainerRef = useRef(null);
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  // Modal handlers for multiple images preview
  const handlePreview = (images) => {
    setModalImages(images);
    setModalVisible(true);
  };

  const handleClosePreview = () => {
    setModalVisible(false);
    setModalImages([]);
  };

  return (
    <>
       <Applyforinternship/>
     
      {/* Modal for multiple images */}
      {modalVisible && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={handleClosePreview}>
              &times;
            </button>
            <div className={style.imageContainer}>
              {modalImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Preview ${index}`}
                  className={style.modalImage}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      

      <div className={`${style.liveprojectcategories} container-fluid`}>
        <div className="container">
          {/* Categories bar */}
          <div className={style.scrollitemcategories}>
            <ul className={style.scrollitem}>
              <li className="nav-item">
                <a
                  className={`${style.navitem} ${style.navbackbtn} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("")}
                >
                  <i className="fa-solid fa-left-long"></i>
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("webDevelopment")}
                >
                  Web Development
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("dataAnalyst")}
                >
                  Data Analyst
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("frontEnd")}
                >
                  Front-end
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("backEnd")}
                >
                  Back-end
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="container-fluid">
        <div className="container">
          {/* Landing view content */}
          {activeSection === null && (
            <div className={`${style.landingview} row`}>
           <div className={`${style.ourproject} col-6`}>
           <h2 className={style.mainheading}>Our Projects</h2>
           {/* <h2 className={style.mainheading}>
             {projects[0]?.project_type || "Default Project Type"} Our
           </h2> */}
           {projects
           .filter((project) => Number(project.hidden) === 0) // Exclude hidden project
             .filter((project) => project.project_type === "ourProjects")
             .sort(
               (a, b) => new Date(b.created_at) - new Date(a.created_at)
             ) // Sort by created_at, newest first
             .slice(0, 2) // Get the first two projects
             .map((project, index) => (
               <div key={index} className={`${style.coursesbuyrowmain}`}>
                 <div className={`${style.coursesbuymain}`}>
                   <div className={style.courseslogomain}>
                     <img
                       className={style.coursesbuylogo}
                       // src={project.project_image1}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                   </div>
                   <div className={style.coursesdetail}>
                     {/* <h3 className={style.nameheading}>
                       {project.package_type}
                     </h3> */}
                     <h4 className={style.nameheading}>
                       {project.project_name}
                     </h4>
                     <span className={style.coursesdata}>
                       {project.project_details}
                     </span>
                     <h5 className={style.learnmorebuy}>
                       {project.project_subdetails}
                     </h5>
                   </div>
                   <div className={style.buttonz}>
                     {/* Preview button triggers modal for multiple images */}
                     {/* <button className={style.coursesbuybutton}>
                       {project.preview_pdf}
                     </button> */}
                     <button
                   className={style.coursesbuybutton}
                   onClick={() =>
                     handlePreview([
                      `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                      `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                      `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                     ])
                   }
                 >
                   Preview
                 </button>
                     {/* <button className={style.coursesbuybutton}>
                       {project.sourcecode_pdf}{" "}
                       <i className="fa-solid fa-download"></i>
                     </button> */}

                     <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`}  // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>


                   </div>
                 </div>
               </div>
             ))}
         </div>

         {/* Live project section */}
         <div className={`${style.ourproject} col-6`}>
           <h2 className={style.mainheading}>Live Projects</h2>
           {/* <h2 className={style.mainheading}>
             {projects[0]?.project_type || "Default Project Type"} Live
           </h2> */}
           {projects
           .filter((project) => Number(project.hidden) === 0) // Exclude hidden project
             .filter((project) => project.project_type === "liveProjects")
             .sort(
               (a, b) => new Date(b.created_at) - new Date(a.created_at)
             ) // Sort by created_at, newest first
             .slice(0, 2) // Get the first two projects
             .map((project, index) => (
               <div key={index} className={`${style.coursesbuyrowmain}`}>
                 <div className={`${style.coursesbuymain}`}>
                   <div className={style.courseslogomain}>
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                   </div>
                   <div className={style.coursesdetail}>
                     {/* <h3 className={style.nameheading}>
                       {project.package_type}
                     </h3> */}
                     <h4 className={style.nameheading}>
                       {project.project_name}
                     </h4>
                     <span className={style.coursesdata}>
                       {project.project_details}
                     </span>
                     <h5 className={style.learnmorebuy}>
                       {project.project_subdetails}
                     </h5>
                   </div>
                   <div className={style.buttonz}>
                     {/* <button className={style.coursesbuybutton}>
                       {project.preview_pdf}
                     </button> */}
                     <button
                   className={style.coursesbuybutton}
                   onClick={() =>
                     handlePreview([
                      `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                      `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                      `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                     ])
                   }
                 >
                   Preview
                 </button>
                     {/* <button className={style.coursesbuybutton}>
                       {project.sourcecode_pdf}{" "}
                       <i className="fa-solid fa-download"></i>
                     </button> */}
                     <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`}  // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                   </div>
                 </div>
               </div>
             ))}
         </div>
       </div>
          )}
        </div>
      </div>

      {/* Additional sections (hidden when inactive) */}
      <div className="container-fluid">
        {activeSection === "webDevelopment" && (
          <div className={`${style.webDevlopment} ${style.d} container`}>
            <div
              className={`${style.scrollContainer}`}
              ref={scrollContainerRef}
            >
              {projects
              .filter((project) => Number(project.hidden) === 0) // Exclude hidden project
                .filter((project) => project.package_type === "webDevelopment")
                .map((project, index) => (
                  <div key={index} className={`${style.coursesbuyrow}`}>
                    <div className={`${style.coursesbuy}`}>
                      <div className={style.courseslogo}>
                      <img
                       className={style.coursesbuylogo}
                       // src={project.project_image1}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                      </div>
                      <div className={style.coursesdetail}>
                        {/* <h3 className={style.nameheading}>
                          {project.package_type}
                        </h3> */}
                        <h4 className={style.nameheading}>
                          {project.project_name}
                        </h4>
                        <span className={style.coursesdata}>
                          {project.project_details}
                        </span>
                        <h5 className={style.learnmorebuy}>
                          {project.project_subdetails}
                        </h5>
                      </div>
                      <div className={style.buttonz}>
                        {/* <button className={style.coursesbuybutton}>
                          {project.preview_pdf}
                        </button> */}
                        <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                          ])
                        }
                      >
                        Preview
                      </button>
                       

                        <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`} // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className={style.arrowContainer}>
              <button onClick={scrollLeft} className={style.leftArrow}>
                &lt;
              </button>
              <button onClick={scrollRight} className={style.rightArrow}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="container-fluid">
        {activeSection === "dataAnalyst" && (
          <div className={`${style.dataAnalyst} ${style.d} container`}>
            <div
              className={`${style.scrollContainer}`}
              ref={scrollContainerRef}
            >
              {projects
              .filter((project) => Number(project.hidden) === 0) // Exclude hidden project
                .filter((project) => project.package_type === "dataAnalyst")
                .map((project, index) => (
                  <div key={index} className={`${style.coursesbuyrow}`}>
                    <div className={`${style.coursesbuy}`}>
                      <div className={style.courseslogo}>
                      <img
                       className={style.coursesbuylogo}
                       // src={project.project_image1}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                      </div>
                      <div className={style.coursesdetail}>
                        {/* <h3 className={style.nameheading}>
                          {project.package_type}
                        </h3> */}
                        <h4 className={style.nameheading}>
                          {project.project_name}
                        </h4>
                        <span className={style.coursesdata}>
                          {project.project_details}
                        </span>
                        <h5 className={style.learnmorebuy}>
                          {project.project_subdetails}
                        </h5>
                      </div>
                      <div className={style.buttonz}>
                        {/* <button className={style.coursesbuybutton}>
                          {project.preview_pdf}
                        </button> */}
                        <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                          ])
                        }
                      >
                        Preview
                      </button>
                      <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`}  // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={style.arrowContainer}>
              <button onClick={scrollLeft} className={style.leftArrow}>
                &lt;
              </button>
              <button onClick={scrollRight} className={style.rightArrow}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="container-fluid">
        {activeSection === "frontEnd" && (
          <div className={`${style.frontEnd} ${style.d} container`}>
            <div
              className={`${style.scrollContainer}`}
              ref={scrollContainerRef}
            >
              {projects
              .filter((project) => Number(project.hidden) === 0) // Exclude hidden project
                .filter((project) => project.package_type === "frontEnd")
                .map((project, index) => (
                  <div key={index} className={`${style.coursesbuyrow}`}>
                    <div className={`${style.coursesbuy}`}>
                      <div className={style.courseslogo}>
                      <img
                       className={style.coursesbuylogo}
                       // src={project.project_image1}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                      </div>
                      <div className={style.coursesdetail}>
                        {/* <h3 className={style.nameheading}>
                          {project.package_type}
                        </h3> */}
                        <h4 className={style.nameheading}>
                          {project.project_name}
                        </h4>
                        <span className={style.coursesdata}>
                          {project.project_details}
                        </span>
                        <h5 className={style.learnmorebuy}>
                          {project.project_subdetails}
                        </h5>
                      </div>
                      <div className={style.buttonz}>
                        {/* <button className={style.coursesbuybutton}>
                          {project.preview_pdf}
                        </button> */}
                        <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                          ])
                        }
                      >
                        Preview
                      </button>
                      <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`}  // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={style.arrowContainer}>
              <button onClick={scrollLeft} className={style.leftArrow}>
                &lt;
              </button>
              <button onClick={scrollRight} className={style.rightArrow}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="container-fluid">
        {activeSection === "backEnd" && (
          <div className={`${style.frontEnd} ${style.d} container`}>
            <div
              className={`${style.scrollContainer}`}
              ref={scrollContainerRef}
            >
              {projects
              .filter((project) => Number(project.hidden) === 0) // Exclude hidden project
                .filter((project) => project.package_type === "backEnd")
                .map((project, index) => (
                  <div key={index} className={`${style.coursesbuyrow}`}>
                    <div className={`${style.coursesbuy}`}>
                      <div className={style.courseslogo}>
                      <img
                       className={style.coursesbuylogo}
                       // src={project.project_image1}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                      </div>
                      <div className={style.coursesdetail}>
                        {/* <h3 className={style.nameheading}>
                          {project.package_type}
                        </h3> */}
                        <h4 className={style.nameheading}>
                          {project.project_name}
                        </h4>
                        <span className={style.coursesdata}>
                          {project.project_details}
                        </span>
                        <h5 className={style.learnmorebuy}>
                          {project.project_subdetails}
                        </h5>
                      </div>
                      <div className={style.buttonz}>
                        {/* <button className={style.coursesbuybutton}>
                          {project.preview_pdf}
                        </button> */}
                        <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                            `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                          ])
                        }
                      >
                        Preview
                      </button>
                      <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`} // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={style.arrowContainer}>
              <button onClick={scrollLeft} className={style.leftArrow}>
                &lt;
              </button>
              <button onClick={scrollRight} className={style.rightArrow}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Liveproject;
