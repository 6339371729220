import React from 'react'
import style from './../Css/Livelecture.module.css'

const LiveLectures = () => {
  return (
    <div>

      <div className="courses-container">
       

          <div className={style.livelecturecard}>
            <div className={style.livelectureheader}>
              <img
                src="Images/Abhishek.jpg"
                alt="Instructor"
                className={style.instructorimage}
              />
            </div>
            <div className={style.livelecturebody}>
              <h3 className={style.lecturetitle}>React Advanced Concepts</h3>
              <p className={style.instructordetails}>Instructor: John Doe</p>
              <p className={style.lecturetime}>
                <strong>Start Time:</strong> September 26, 2024 - 5:00 PM
              </p>
              <p className={style.lecturedescription}>
                This lecture will dive deep into advanced topics in React, including performance optimization, hooks, and state management.
              </p>
            </div>
            <div className={style.livelecturefooter}>
              <button className={style.joinbutton}>Join Now</button>
              {/* <button className={style.viewdetailsbutton}>View Details</button> */}
            </div>
          </div>



      </div>

    </div>
  )
}

export default LiveLectures