import React, { useState, useEffect, useRef } from "react";
import style from "./../Css/Editrecentjobs.module.css"; // Adjust CSS import if needed
import styles from './../Css/Updatebasicdesign.module.css';
import { Link } from "react-router-dom";
import API_BASE_URL from "../apiConfig";
// Dropdown Component with Search
const DropdownWithSearch = ({
  items,
  placeholder,
  label,
  isOpen,
  toggleDropdown,
  onSelect,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  // Filter items based on search query
  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(); // Close the dropdown if clicking outside
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  return (
    <div ref={dropdownRef} className={style.droupdownmenu}>
      <button onClick={toggleDropdown} className={style.dropdownbutton}>
        {label}
      </button>

      {isOpen && (
        <div className={style.droupdowncontent}>
          <input
            type="text"
            placeholder={placeholder || "Search..."}
            value={searchQuery}
            onChange={handleSearchChange}
            className={style.dropdownsearchbar}
          />
          <ul className={style.droupdownul}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <li
                  key={index}
                  className={style.dropdownitemname}
                  onClick={() => onSelect(item)}
                >
                  {item}
                </li>
              ))
            ) : (
              <li className={style.dropdownitemname}>No items found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

// Main Recent Job Component
const Editrecentjobs = () => {
  const [recentjob, setRecentJob] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [editingJob, setEditingJob] = useState(null);
  const [editForm, setEditForm] = useState({});
  const [message, setMessage] = useState(""); // State for popup message
  const [modalVisible, setModalVisible] = useState(false);

  const jobCategories = ['All', 'Web Development', 'Frontend Developer', 'Backend Developer', 'Developer', 'Full Stack Developer', 'Data Analyst', 'Data Scientist', 'UI/UX Designer'];
  const locations = ['All', 'Pune', 'Hyderabad', 'Bangalore', 'Chennai', 'Mumbai'];


  // Filter jobs based on selected category and location
  const filteredJobs = recentjob.filter(job => {
    // Category Filter: If selectedCategory is not empty, filter by category
    const matchesCategory = selectedCategory && selectedCategory !== 'All' ? job.category.toLowerCase().replace(/\s+/g, '') === selectedCategory.toLowerCase().replace(/\s+/g, '') : true;

    // Location Filter: If selectedLocation is not empty, filter by location
    const matchesLocation = selectedLocation && selectedLocation !== 'All'
      ? job.jobLocation.toLowerCase().replace(/\s+/g, '') === selectedLocation.toLowerCase().replace(/\s+/g, '')
      : true;

    return matchesCategory && matchesLocation;
  });
  

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const fetchRecentJob = async () => {
    try {
      const response = await fetch
        (`${API_BASE_URL}/get_recentjob.php`
        );
      const data = await response.json();
      setRecentJob(data);
    } catch (error) {
      console.error("Failed to fetch Recent Jobs:", error);
    }
  };

  useEffect(() => {
    fetchRecentJob();
  }, []);

  const openEditForm = (job) => {
    setEditingJob(job);
    setEditForm(job);
    setModalVisible(true); // Show modal
  };

  const closeModal = () => {
    setModalVisible(false);
    setEditingJob(null); // Clear the edit state
  };

  const handleEditSubmit = async () => {
    try {
      const response = await fetch
        (`${API_BASE_URL}/edit_recentjob.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editForm),
          }
        );
      const result = await response.json();
      if (result.message) {
        fetchRecentJob();
        setEditingJob(null);  // Clear edit mode
        setMessage("Changes saved successfully!"); // Set success message
        setTimeout(() => setMessage(""), 1500); // Hide message after 3 seconds
      }
    } catch (error) {
      console.error("Error editing job:", error);
    }
  };

  const handleDeleteJob = async (jobId) => {
    const showCustomAlert = (message, onConfirm, onCancel) => {
      const alertBox = document.createElement('div');
      alertBox.style.position = 'fixed';
      alertBox.style.top = '20%';
      alertBox.style.left = '50%';
      alertBox.style.transform = 'translate(-50%, -50%)';
      alertBox.style.padding = '20px';
      alertBox.style.backgroundColor = '#ACAF50';
      alertBox.style.color = 'white';
      alertBox.style.fontSize = '16px';
      alertBox.style.borderRadius = '5px';
      alertBox.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
      alertBox.style.zIndex = '9999';
      alertBox.style.textAlign = 'center';

      // Add the message
      const messageElement = document.createElement('p');
      messageElement.innerText = message;
      alertBox.appendChild(messageElement);

      // Add the Yes button
      const yesButton = document.createElement('button');
      yesButton.innerText = 'Yes';
      yesButton.style.margin = '10px';
      yesButton.style.padding = '5px 15px';
      yesButton.style.backgroundColor = '#5cb85c';
      yesButton.style.color = 'white';
      yesButton.style.border = 'none';
      yesButton.style.borderRadius = '3px';
      yesButton.style.cursor = 'pointer';

      yesButton.addEventListener('click', () => {
        alertBox.remove();
        onConfirm();
      });

      // Add the Cancel button
      const cancelButton = document.createElement('button');
      cancelButton.innerText = 'Cancel';
      cancelButton.style.margin = '10px';
      cancelButton.style.padding = '5px 15px';
      cancelButton.style.backgroundColor = '#d9534f';
      cancelButton.style.color = 'white';
      cancelButton.style.border = 'none';
      cancelButton.style.borderRadius = '3px';
      cancelButton.style.cursor = 'pointer';

      cancelButton.addEventListener('click', () => {
        alertBox.remove();
        if (onCancel) onCancel();
      });

      alertBox.appendChild(yesButton);
      alertBox.appendChild(cancelButton);

      document.body.appendChild(alertBox);
    };

    showCustomAlert(
      'Are you sure you want to delete this JOB?',
      async () => {


        try {
          const response = await fetch
            (`${API_BASE_URL}/delete_recentjob.php`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: jobId }),
              }
            );

          if (!response.ok) {
            throw new Error("Failed to delete job. Server responded with error.");
          }

          const result = await response.json(); // Handle valid JSON response

          if (result.message) {
            fetchRecentJob(); // Refresh the job list
          } else if (result.error) {
            console.error("Error:", result.error);
          }
        } catch (error) {
          console.error("Error deleting job:", error);
        }
      }
    );
  };

  const handleHideToggle = async (job) => {
    const newStatus = Number(job.hidden) === 1 ? 0 : 1; // Toggle status

    try {
      const response = await fetch
        (`${API_BASE_URL}/toggle_hide_recentjob.php`, // Update with the correct API endpoint
          {
            method: "POST",
            body: JSON.stringify({ id: job.id, hidden: newStatus }),
            headers: { "Content-Type": "application/json" },
          }
        );

      const result = await response.json();
      console.log("Response from toggle_hide_job.php:", result); // Add this log

      if (result.status === "success") {
        setRecentJob(
          recentjob.map((p) =>
            p.id === job.id ? { ...p, hidden: newStatus.toString() } : p
          )
        );
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error toggling package visibility:", error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className={`${style.recentrowhead} row`}>
            <div className="col">
              <h4 className={style.recentjobmainheading}>JOB UPDATES</h4>
            </div>
            <div className={`${style.jobserchbutton} col`}>
              <div className={style.droupdownwithsearch}>
                <DropdownWithSearch
                  items={jobCategories}
                  placeholder="Search job categories..."
                  label={
                    <span>
                      Job Categories{" "}
                      <i className="fa-solid fa-chevron-down mx-2"></i>
                    </span>
                  }
                  isOpen={openDropdown === "categories"}
                  toggleDropdown={() => toggleDropdown("categories")}
                  onSelect={(item) => {
                    setSelectedCategory(item);
                    toggleDropdown(null);
                  }}
                />
                <DropdownWithSearch
                  items={locations}
                  placeholder="Search locations..."
                  label={
                    <span>
                      Location <i className="fa-solid fa-chevron-down mx-2"></i>
                    </span>
                  }
                  isOpen={openDropdown === "locations"}
                  toggleDropdown={() => toggleDropdown("locations")}
                  onSelect={(item) => {
                    setSelectedLocation(item);
                    toggleDropdown(null);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className={`${style.recentjobcontainer} container`}>
          {filteredJobs.length > 0 ? (
            filteredJobs.map((job, index) => (
              <div key={index} className={`${style.row4}`}>
                <div className={`${style.colimg} col-2`}>
                  <img src="Images/jobicon (5).png" alt="Job Icon" />
                </div>
                <div className={`${style.content} col-10`}>
                  <h3 className={style.coursename}>{job.title}</h3>
                  <h4 className={style.coursetime}> {job.category}</h4>
                  <h5>{job.companyName}</h5>
                  <h4 className={style.coursetime}>
                    Location: {job.jobLocation}
                  </h4>
                  <h5 className={style.courseduration}>
                    Qualification: {job.qualificationRequired}
                  </h5>
                  <h5 className={style.courseduration}>
                    Experience: {job.experience}
                  </h5>
                  <h5 className={style.courseduration}>
                    Last date: {job.lastDateToApply}
                  </h5>
                  <h5 className={style.courseduration}>
                    Profile: {job.jobProfile}
                  </h5>
                  <h5 className={style.courseduration}>
                    Available Positions: {job.numberOfJobs}
                  </h5>
                  <h5 className={style.courseduration}>
                    Update date: {job.updateDate}
                  </h5>
                  <h5 className={style.courseduration}>
                    Apply link: {job.joblink}
                  </h5>
                  <div className={style.footcol2nd}>
                    <a className={style.btn} href={job.link}>
                      Apply here
                    </a>
                  </div>

                  <div className={style.editbtns}>
                    <Link className={style.editbtnone} onClick={() => openEditForm(job)}>Edit</Link>
                    <Link className={style.editbtntwo} onClick={() => handleDeleteJob(job.id)}>
                      Delete
                    </Link>
                    <Link className={style.editbtnthree} onClick={() => handleHideToggle(job)}>
                      {Number(job.hidden) === 1 ? "Unhide" : "Hide"}
                    </Link>
                  </div>








                </div>
              </div>
            ))
          ) : (
            <h4>No jobs found for the selected filters</h4>
          )}
        </div>
      </div>

      {modalVisible && editingJob && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={closeModal}>
              &times;
            </button>

            <div className={`${style.editformrow} ${style.editformheading}row`}>
              <h3>Edit Job</h3>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                {" "}
                <label>Update job title:</label>
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editForm.title || ""}
                  onChange={(e) =>
                    setEditForm({ ...editForm, title: e.target.value })
                  }
                />
              </div>



              <div className="col-6">
                {" "}
                <label>Upate job Category:</label>
                <select
                  className={styles.basicinputdesign}
                  type="text"
                  value={editForm.category || ""}
                  onChange={(e) =>
                    setEditForm({ ...editForm, category: e.target.value })
                  }
                >
                  <option value="">-- Please choose an option --</option>
                  <option value="Web Development">Web Development</option>
                  <option value="Data Analyst">Data Analyst</option>
                  <option value="Frontend Developer">Frontend Developer</option>
                  <option value="Backend Developer">Backend Developer</option>
                  <option value="UI/UX Designer">UI/UX Designer</option>
                  <option value="Data Scientist">Data Scientist</option>
                  <option value="Developer">Developer</option>
                  <option value="Full Stack Developer">Full Stack Developer</option>
                </select>

              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                {" "}
                <label>Update Company Name:</label>
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editForm.companyName || ""}
                  onChange={(e) =>
                    setEditForm({ ...editForm, companyName: e.target.value })
                  }
                />
              </div>
              <div className="col-6">
                {" "}
                <label>Update Job Location:</label>
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editForm.jobLocation || ""}
                  onChange={(e) =>
                    setEditForm({ ...editForm, jobLocation: e.target.value })
                  }
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                {" "}
                <label>Update Qualification Required:</label>
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editForm.qualificationRequired || ""}
                  onChange={(e) =>
                    setEditForm({
                      ...editForm,
                      qualificationRequired: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                {" "}
                <label>Update Experience:</label>
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editForm.experience || ""}
                  onChange={(e) =>
                    setEditForm({ ...editForm, experience: e.target.value })
                  }
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                {" "}
                <label>Update Number of Jobs:</label>
                <input
                  className={styles.basicinputdesign}
                  type="number"
                  value={editForm.numberOfJobs || ""}
                  onChange={(e) =>
                    setEditForm({ ...editForm, numberOfJobs: e.target.value })
                  }
                />
              </div>
              <div className="col-6">
                {" "}
                <label>Update Job Profile:</label>
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editForm.jobProfile || ""}
                  onChange={(e) =>
                    setEditForm({ ...editForm, jobProfile: e.target.value })
                  }
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>

              <div className="col-6">
                {" "}
                <label>Update Last Date to Apply:</label>
                <input
                  className={styles.basicinputdesign}
                  type="date"
                  value={editForm.lastDateToApply || ""}
                  onChange={(e) =>
                    setEditForm({
                      ...editForm,
                      lastDateToApply: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                {" "}
                <label>Update Date:</label>
                <input
                  className={styles.basicinputdesign}
                  type="date"
                  value={editForm.updateDate || ""}
                  onChange={(e) =>
                    setEditForm({ ...editForm, updateDate: e.target.value })
                  }
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              {/* <div className="col-9"> */}
              <label>Link:</label>
              <input
                className={styles.basicinputdesign}
                type="text"
                value={editForm.joblink || ""}
                onChange={(e) =>
                  setEditForm({ ...editForm, joblink: e.target.value })
                }
              />
            </div>
            {/* </div> */}

            {/* Add other fields */}

            <div className={`${style.editformrow} row`}>
              <div className={` ${style.savechangesbtn} col-6`}>
                <Link className={style.editbtnfour} onClick={handleEditSubmit}>Save Changes</Link>
              </div>
              <div className={` ${style.cancelbtn} col-6`}>
                <Link className={style.editbtnfour} onClick={() => setEditingJob(null)}>Cancel</Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Render Popup Message */}
      {message && (
        <div className={style.popupMessage}>
          <p>{message}</p>
        </div>
      )}
    </>
  );
};

export default Editrecentjobs;
