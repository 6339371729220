import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';

const ViewNewsletter = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/get_subscribers.php`); 
      
        if (response.data.status === 'success') {
          setSubscribers(response.data.data);
          
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError('Failed to fetch data. Please try again.');
      }
    };

    fetchSubscribers();
  }, []);


//   send mail 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message.trim()) {
      setStatus({ type: 'error', message: 'Message cannot be empty' });
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/send_newsletter.php`, { message });
      setStatus({ type: 'success', message: response.data.message });
    //   console.log(response.data)
    } catch (error) {
      setStatus({ type: 'error', message: 'Failed to send the newsletter. Please try again.' });
    }
  };

  return (
<>
    <div style={{ padding: '20px' }}>
    <h1>Send Newsletter</h1>
    {status && <p style={{ color: status.type === 'error' ? 'red' : 'green' }}>{status.message}</p>}
    <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Write your message here..."
        rows="5"
        style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
        />
      <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none' }}>
        Send Newsletter
      </button>
    </form>
  </div>
    <div style={{ padding: '20px' }}>
      <h1>Newsletter Subscribers</h1>
      {error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
            <table
            style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginTop: '20px',
                textAlign: 'left',
            }}
            >
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>ID</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Email</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                Subscribed Date
              </th>
            </tr>
          </thead>
          <tbody>
            {subscribers.length > 0 ? (
                subscribers.map((subscriber,index) => (
                    <tr key={index}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {index + 1 }
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {subscriber.email}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {subscriber.created_at }
                  </td>
                </tr>
              ))
            ) : (
                <tr>
                <td
                  colSpan="3"
                  style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}
                  >
                  No subscribers found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

    </div>
      </>
  );
};

export default ViewNewsletter;
