import React, { useState, useRef, useEffect } from "react";
import style from "./../Css/Updatepackages.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import API_BASE_URL from "../apiConfig";
import axios from 'axios';

const Updatepackages = () => {
  const [packageName, setPackageName] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [packageDetails, setPackageDetails] = useState("");
  const [packageBenefit1, setPackageBenefit1] = useState("");
  const [packageBenefit2, setPackageBenefit2] = useState("");
  const [packageBenefit3, setPackageBenefit3] = useState("");
   const fileInputRefviewpdf = useRef(null); // Ref for file input
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("packageName", packageName);
    formData.append("packagePrice", packagePrice);
    formData.append("packageDetails", packageDetails);
    formData.append("packageBenefit1", packageBenefit1);
    formData.append("packageBenefit2", packageBenefit2);
    formData.append("packageBenefit3", packageBenefit3);
    if (fileInputRefviewpdf.current.files[0]) {
      formData.append("viewpdf", fileInputRefviewpdf.current.files[0]);
    }

    try {
          const response = await axios.post(`${API_BASE_URL}/update_package.php`, formData, {
        headers: {
            "Content-Type": "multipart/form-data", // Important for sending FormData
        },
    });

    if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    setMessage(data.message); // Set the message returned from the server

      // Clear form after submission
      setPackageName("");
      setPackagePrice("");
      setPackageDetails("");
      setPackageBenefit1("");
      setPackageBenefit2("");
      setPackageBenefit3("");
      fileInputRefviewpdf.current.value = ""; // Clear the file input

      // Clear message after 5 seconds
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred while updating the package.");
    }
  };

  return (
    <div>
      <div className="Create&Update">
        <div className="packagedataform">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className={`${styles.basicfieldsdesign} packagenameinputwithlables`}>
                  <label htmlFor="packageName">Package heading:</label>
                  <input
                  className={styles.basicinputdesign}
                    type="text"
                    id="packageName"
                    name="packageName"
                    value={packageName}
                    onChange={(e) => setPackageName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className={`${styles.basicfieldsdesign} packagepriceinputwithlables`}>
                  <label htmlFor="packagePrice">Package Price:</label>
                  <input
                  className={styles.basicinputdesign}
                    type="text"
                    id="packagePrice"
                    name="packagePrice"
                    value={packagePrice}
                    onChange={(e) => setPackagePrice(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className={`${styles.basicfieldsdesign} packagedetalisinputwithlables`}>
              <label htmlFor="packageDetails">Package Details:</label>
              <textarea
              className={styles.basicinputdesign}
                id="packageDetails"
                name="packageDetails"
                value={packageDetails}
                onChange={(e) => setPackageDetails(e.target.value)}
                required
              ></textarea>
            </div>

            <div className={`${styles.basicfieldsdesign} packagePackagebenefit1inputwithlables`}>
              <label htmlFor="Packagebenefit1">Package benefit 1:</label>
              <input
              className={styles.basicinputdesign}
                type="text"
                id="Packagebenefit1"
                name="Packagebenefit1"
                value={packageBenefit1}
                onChange={(e) => setPackageBenefit1(e.target.value)}
                required
              />
            </div>

            <div className={`${styles.basicfieldsdesign} packagePackagebenefit2inputwithlables`}>
              <label htmlFor="Packagebenefit2">Package benefit 2:</label>
              <input
              className={styles.basicinputdesign}
                type="text"
                id="Packagebenefit2"
                name="Packagebenefit2"
                value={packageBenefit2}
                onChange={(e) => setPackageBenefit2(e.target.value)}
                required
              />
            </div>

            <div className={`${styles.basicfieldsdesign} packagePackagebenefit3inputwithlables`}>
              <label htmlFor="Packagebenefit3">Package benefit 3:</label>
              <input
              className={styles.basicinputdesign}
                type="text"
                id="Packagebenefit3"
                name="Packagebenefit3"
                value={packageBenefit3}
                onChange={(e) => setPackageBenefit3(e.target.value)}
                required
              />
            </div>

            <div className={`${styles.basicfieldsdesign} packagePackagebenefit3inputwithlables`}>
            <label htmlFor="viewpdf">
              Upload pdf (pdf):
            </label>
            <input 
              type="file"
              id="viewpdf"
              className={`${styles.basicinputimgdesign} ${style.inputprojectimage} viewpdf`}
              accept="application/pdf"
              ref={fileInputRefviewpdf} // Assign ref to the file input
            />
            </div>

            <div className={`${styles.adminsubminbtn} row`}>
              {/* <button className={style.adminsubmitbtnbtn} type="submit">
                Update Package
              </button> */}

              <button className={`${styles.btncss} ${styles.fullrounded}`}>
              <span>Update Package</span>
              <div className={`${styles.border} ${styles.fullrounded}`}></div>
            </button>
            </div>

            {/* Message alert centered on the screen */}
            {message && (
              <div className={styles.alertMessage}>
                <p>{message}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Updatepackages;
