// import React from "react";
import React, { useState, useEffect } from "react";
import style from "./../Css/Editsubliveclasses.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import { Link } from "react-router-dom";
import API_BASE_URL from "../apiConfig";

const Editsubliveclasses = () => {
  // State to store live class data
  const [liveClasses, setLiveClasses] = useState([]);
  const [message, setMessage] = useState(""); // State for popup message
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // Fetch live class data from the backend
    const fetchLiveClasses = async () => {
      try {
        const response = await fetch
          (`${API_BASE_URL}/get_live_classes.php`
        ); // Backend API endpoint
        const data = await response.json();
        if (data.message) {
          console.error(data.message); // Handle any errors from backend
        } else {
          setLiveClasses(data); // Set the fetched live classes in state
        }
      } catch (error) {
        console.error("Error fetching live classes:", error);
      }
    };

    fetchLiveClasses(); // Call the function when the component mounts
  }, []); // Empty dependency array means it will run once when the component mounts

  const handleDelete = async (classId) => {
    const showCustomAlert = (message, onConfirm, onCancel) => {
      const alertBox = document.createElement('div');
      alertBox.style.position = 'fixed';
      alertBox.style.top = '20%';
      alertBox.style.left = '50%';
      alertBox.style.transform = 'translate(-50%, -50%)';
      alertBox.style.padding = '20px';
      alertBox.style.backgroundColor = '#ACAF50';
      alertBox.style.color = 'white';
      alertBox.style.fontSize = '16px';
      alertBox.style.borderRadius = '5px';
      alertBox.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
      alertBox.style.zIndex = '9999';
      alertBox.style.textAlign = 'center';
  
      // Add the message
      const messageElement = document.createElement('p');
      messageElement.innerText = message;
      alertBox.appendChild(messageElement);
  
      // Add the Yes button
      const yesButton = document.createElement('button');
      yesButton.innerText = 'Yes';
      yesButton.style.margin = '10px';
      yesButton.style.padding = '5px 15px';
      yesButton.style.backgroundColor = '#5cb85c';
      yesButton.style.color = 'white';
      yesButton.style.border = 'none';
      yesButton.style.borderRadius = '3px';
      yesButton.style.cursor = 'pointer';
  
      yesButton.addEventListener('click', () => {
        alertBox.remove();
        onConfirm();
      });
  
      // Add the Cancel button
      const cancelButton = document.createElement('button');
      cancelButton.innerText = 'Cancel';
      cancelButton.style.margin = '10px';
      cancelButton.style.padding = '5px 15px';
      cancelButton.style.backgroundColor = '#d9534f';
      cancelButton.style.color = 'white';
      cancelButton.style.border = 'none';
      cancelButton.style.borderRadius = '3px';
      cancelButton.style.cursor = 'pointer';
  
      cancelButton.addEventListener('click', () => {
        alertBox.remove();
        if (onCancel) onCancel();
      });
  
      alertBox.appendChild(yesButton);
      alertBox.appendChild(cancelButton);
  
      document.body.appendChild(alertBox);
    };
  
    showCustomAlert(
      'Are you sure you want to delete this LIVE CLASS?',
      async () => {
    try {
      const response = await fetch
        (`${API_BASE_URL}/delete_classes.php`,
        {
          method: "POST",
          body: JSON.stringify({ id: classId }),
          headers: { "Content-Type": "application/json" },
        }
      );

      const result = await response.json();

      if (result.status === "success") {
        setLiveClasses(
          liveClasses.filter((liveClass) => liveClass.id !== classId)
        );
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  }
);
  };

  const [editClasses, setEditClasses] = useState(null); // State to manage edit mode
  const [files, setFiles] = useState({ logo: null }); // To hold uploaded files

  const handleEdit = (liveClass) => {
    setEditClasses(liveClass);
    setFiles({ logo: null }); // Reset file inputs
    setModalVisible(true); // Show modal
  };

  const closeModal = () => {
    setModalVisible(false);
    setEditClasses(null); // Clear the edit state
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    setFiles((prev) => ({ ...prev, [fileType]: file }));
  };

  const handleUpdate = async () => {
    if (editClasses) {
      const formData = new FormData();
      formData.append("id", editClasses.id);
      formData.append("className", editClasses.className);
      formData.append("detailedTiming", editClasses.detailedTiming);
      formData.append("tutorName", editClasses.tutorName);
      formData.append("tutorDescription", editClasses.tutorDescription);
      // formData.append("courseTechnology", editClasses.courseTechnology);

      // Append files if they exist
      if (files.logo) formData.append("courseIcon", files.logo);

      try {
        const response = await fetch
          (`${API_BASE_URL}/edit_classes.php`,
          {
            method: "POST",
            body: formData,
          }
        );

        const result = await response.json();

        if (result.status === "success") {
          setLiveClasses(
            liveClasses.map((liveClass) =>
              liveClass.id === editClasses.id
                ? { ...editClasses, ...files }
                : liveClass
            )
          );
          setEditClasses(null); // Clear edit mode
          setMessage("Changes saved successfully!"); // Set success message
          setTimeout(() => setMessage(""), 3000); // Hide message after 3 seconds
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error("Error updating course:", error);
      }
    }
  };

  const handleHideToggle = async (liveClass) => {
    const newStatus = Number(liveClass.hidden) === 1 ? 0 : 1; // Toggle status

    try {
      const response = await fetch
        (`${API_BASE_URL}/toggle_hide_classes.php`,
        {
          method: "POST",
          body: JSON.stringify({ id: liveClass.id, hidden: newStatus }),
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const result = await response.json();
      console.log("Backend response:", result);

      if (result.status === "success") {
        setLiveClasses(
          liveClasses.map((c) =>
            c.id === liveClass.id ? { ...c, hidden: newStatus.toString() } : c
          )
        );
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error toggling course visibility:", error);
    }
  };

  return (
    <>
      <div className="container">
        {/* <div className={`${style.row4}`}> */}
        {liveClasses.length > 0 ? (
          liveClasses.map((liveClass, index) => (
            <div className={`${style.row4}`} key={index}>
              <div className={`${style.colimg} `}>
                {/* <img src="Images/htmlicon.png" alt="" /> */}
                <img
                  src={`${API_BASE_URL}/liveclassesimg/${liveClass.courseIcon}`} // Path to the course icon
                  alt={liveClass.className}
                />
              </div>

              <div className={`${style.content} col-10`}>
                <h3 className={style.coursename}>{liveClass.className}</h3>
                <h5 className={style.courseduration}>
                  {liveClass.detailedTiming}
                </h5>
                <div className={`${style.foot}`}>
                  <div>
                    <h5 className={style.courseduration}>
                      Tutor Name: {liveClass.tutorName}
                    </h5>
                    <h5 className={style.courseduration}>
                      Tutor Profile: {liveClass.tutorDescription}
                    </h5>
                  </div>
                  <div className={`${style.footcol2nd} `}>
                    <button className={style.btn}>Enroll</button>
                  </div>
                </div>
                <div className={style.editbtns}>
                <Link className={style.editbtnone} onClick={() => handleEdit(liveClass)}>Edit</Link>
                <Link className={style.editbtntwo} onClick={() => handleDelete(liveClass.id)}>
                    Delete
                  </Link>
                  <Link className={style.editbtnthree} onClick={() => handleHideToggle(liveClass)}>
                    {Number(liveClass.hidden) === 1 ? "Unhide" : "Hide"}
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
           <div className={style.delayedMessage}>
                  🚧 The "Live Classes" feature is comming soon. Please stay tuned for updates! 📅
                 </div>
        )}
      </div>

      {/* Edit Form */}
      {modalVisible && editClasses && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={closeModal}>
              &times;
            </button>

            <div className={`${style.editformrow} ${style.editformheading}row`}>
              <h2>Edit Class</h2>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                <label>class name</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editClasses.className}
                  onChange={(e) =>
                    setEditClasses({
                      ...editClasses,
                      className: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                {" "}
                <label>class Timing</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editClasses.classTiming}
                  onChange={(e) =>
                    setEditClasses({
                      ...editClasses,
                      classTiming: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                <label>tutor Name</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editClasses.detailedTiming}
                  onChange={(e) =>
                    setEditClasses({
                      ...editClasses,
                      detailedTiming: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                {" "}
                <label>tutor Description</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editClasses.tutorDescription}
                  onChange={(e) =>
                    setEditClasses({
                      ...editClasses,
                      tutorDescription: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                {" "}
                <div>
                  <label>Course Logo</label>
                  <input
                  className={styles.basicinputdesign}
                    type="file"
                    onChange={(e) => handleFileChange(e, "logo")}
                  />
                </div>
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
            <div className={` ${style.savechangesbtn} col-6`}>
            <Link className={style.editbtnfour} onClick={handleUpdate}>Save Changes</Link>
              </div>
              <div className={` ${style.cancelbtn} col-6`}>
              <Link className={style.editbtnfour} onClick={() => setEditClasses(null)}>Cancel</Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Render Popup Message */}
      {message && (
        <div className={style.popupMessage}>
          <p>{message}</p>
        </div>
      )}
    </>
  );
};

export default Editsubliveclasses;
