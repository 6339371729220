import React from 'react'
import style from './../Css/Recorderlecture.module.css'

const RecordedLectures = () => {
  return (
    <div>


      <div className="courses-container">
        <div className={style.card}>
        
        <div className={style.recordedlecturecard}>
      <div className={style.recordedlectureheader}>
        <img
          src="Images/Online learning-amico.png"
          alt="Lecture Thumbnail"
          className={style.lecturethumbnail}
        />
      </div>
      <div className={style.recordedlecturebody}>
        <h3 className={style.lecturetitle}>React Basics Tutorial</h3>
        <p className={style.instructordetails}>Instructor: Jane Smith</p>
        <p className={style.lecturetime}>Recorded on: September 20, 2024</p>
        <p className={style.lecturedescription}>
          Learn the core concepts of React, including components, props, state, and lifecycle methods.
        </p>
        
        <button className={style.watchnowbutton}>Watch Now</button>
      
      </div>
     
    </div>

   

        </div>

      </div>



    </div>
  )
}

export default RecordedLectures