import React from "react";
import style from "./../Css/Mycourses.module.css";

const Mycourses = () => {
  return (
    <div>
      <div className="coursescontainer ">
        <div className={style.coursecard}>
          <div className={style.coursecardheader}>
            <img
              src="Images/javaicon.png"
              alt="Course"
              className={style.courseimage}
            />
          </div>
          
          <div className={style.coursecardbody}>
            <h3 className={style.coursetitle}>JavaScript Essentials</h3>
            <p className={style.coursedescription}>
              Learn the fundamentals of JavaScript, including variables,
              functions, and DOM manipulation.
            </p>
            <ul className={style.coursedetails}>
              <li>Duration: 4 weeks</li>
              <li>Level: Beginner</li>
              <li>Enrolled on: September 25, 2024</li>
            </ul>
            <div className={style.coursecardfooter}>
              <button className={style.previewbutton}>Preview Course</button>
              <button className={style.viewcertificatebutton}>
                View Certificate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mycourses;
