

// import React from "react";
import React, { useState, useEffect } from "react";
import style from "./../Css/Subliveclasses.module.css";
import API_BASE_URL from "../apiConfig";

const Subliveclasses = () => {

    // State to store live class data
    const [liveClasses, setLiveClasses] = useState([]);
  
    useEffect(() => {
      // Fetch live class data from the backend
      const fetchLiveClasses = async () => {
        try {
          const response = await fetch
          (`${API_BASE_URL}/get_live_classes.php`
        ); // Backend API endpoint
          const data = await response.json();
          if (data.message) {
            console.error(data.message); // Handle any errors from backend
          } else {
            setLiveClasses(data); // Set the fetched live classes in state
          }
        } catch (error) {
          console.error("Error fetching live classes:", error);
        }
      };
  
      fetchLiveClasses(); // Call the function when the component mounts
    }, []); // Empty dependency array means it will run once when the component mounts
    


  return (
    <>
      <div className="container">
        {/* <div className={`${style.row4}`}> */}
        {liveClasses.length > 0 ? (
        liveClasses.filter((liveClass) => Number(liveClass.hidden) === 0) // Exclude hidden liveClass
        .map((liveClass, index) => (
          <div className={`${style.row4}`} key={index}>

          <div className={`${style.colimg} `}>
            {/* <img src="Images/htmlicon.png" alt="" /> */}
            <img
                src={`http://localhost/iits_backend/liveclassesimg/${liveClass.courseIcon}`} // Path to the course icon
                alt={liveClass.className}
              />
          </div>

          <div className={`${style.content} col-10`}>
            
            {/* <h3 className={style.coursename}>Hypertext Markup Language</h3>
            <h4 className={style.coursetime}>10:00AM Mon-Fri</h4>
            <h5 className={style.courseduration}>
              1st Sept-20th Sept/Mon-Fri/10:00AM-12:00PM
            </h5>
            <div className={`${style.foot}`}>
              <div className=''>
                <h5 className={style.courseduration}>
                  Tutor Name :- harsh sir
                </h5>
                <h5 className={style.courseduration}>
                  Tutor Profile :- "Passionate educator dedicated to fostering
                  growth and understanding in every student."
                </h5>
              </div> */}
              <h3 className={style.coursename}>{liveClass.className}</h3>
              <h4 className={style.coursetime}>{liveClass.classTiming}</h4>
              <h5 className={style.courseduration}>{liveClass.detailedTiming}</h5>
              <div className={`${style.foot}`}>
                <div>
                  <h5 className={style.courseduration}>
                    Tutor Name: {liveClass.tutorName}
                  </h5>
                  <h5 className={style.courseduration}>
                    Tutor Profile: {liveClass.tutorDescription}
                  </h5>
                </div>
              <div className={`${style.footcol2nd} `}>
                <button className={style.btn}>Enroll</button>
              </div>
            </div>
          </div>
        </div>

))
) : (
     <div className={style.delayedMessage}>
                  🚧 The "Live Classes" feature is comming soon. Please stay tuned for updates! 📅
                 </div>
)}

      </div>


     



    </>
  );
};

export default Subliveclasses;
