// import React from "react";

import React, { useState, useEffect } from "react";
import style from "./../Css/Subupcomingclasses.module.css";
import API_BASE_URL from "../apiConfig";

const Subupcomingclasses = () => {

    // State to store upcoming class data
    const [upcomingClasses, setUpcomingClasses] = useState([]);
  
    useEffect(() => {
      // Fetch upcoming class data from the backend
      const fetchUpcomingClasses = async () => {
        try {
          const response = await fetch
          (`${API_BASE_URL}/get_upcoming_classes.php`
        ); // Backend API endpoint
          const data = await response.json();
          if (data.message) {
            console.error(data.message); // Handle any errors from backend
          } else {
            setUpcomingClasses(data); // Set the fetched upcoming classes in state
          }
        } catch (error) {
          console.error("Error fetching upcoming classes:", error);
        }
      };
  
      fetchUpcomingClasses(); // Call the function when the component mounts
    }, []); // Empty dependency array means it will run once when the component mounts
    


  return (
    <>
      <div className="container">
        {/* <div className={`${style.row4}`}> */}

        {upcomingClasses.length > 0 ? (
        upcomingClasses.filter((upcomingClass) => Number(upcomingClass.hidden) === 0) // Exclude hidden upcomingClass
        .map((upcomingClass, index) => (
          <div className={`${style.row4}`} key={index}>
          <div className={`${style.colimg} col-2`}>
            {/* <img src="Images/reacticon (1).png" alt="" /> */}
            <img
                src={`http://localhost/iits_backend/liveclassesimg/${upcomingClass.courseIcon}`} // Path to the course icon
                alt={upcomingClass.className}
              />
          </div>
          <div className={`${style.content} col-10`}>
           

<h3 className={style.coursename}>{upcomingClass.className}</h3>
              <h4 className={style.coursetime}>{upcomingClass.classTiming}</h4>
              <h5 className={style.courseduration}>{upcomingClass.detailedTiming}</h5>
              <div className={`${style.foot}`}>
                <div>
                  <h5 className={style.courseduration}>
                    Tutor Name: {upcomingClass.tutorName}
                  </h5>
                  <h5 className={style.courseduration}>
                    Tutor Profile: {upcomingClass.tutorDescription}
                  </h5>
                </div>
              <div className={`${style.footcol2nd} `}>
                <button className={style.btn}>Enroll</button>
              </div>
            </div>
          </div>
        </div>
              ))
            ) : (
                <div className={style.delayedMessage}>
                  🚧 The "Upcoming Classes" feature is comming soon. Please check back soon for updates! 🚀
                 </div>
            )}
      </div>


      

    </>
  );
};

export default Subupcomingclasses;
