import React from 'react'
import style from './../Css/Practicetest.module.css'

const PracticeTest = () => {
  return (
  <>
     <div className={style.practicetestcard}>
      <div className={style.testinfo}>
        <h3 className={style.testname}>JavaScript Fundamentals</h3>
        <p className={style.testdescription}>
          Test your knowledge of JavaScript basics, including variables, functions, and loops.
        </p>
        <p className={style.testdetails}>
          <span>Questions: 50</span> 
        </p>
      </div>
      <div className={style.testactions}>
        <button className={style.starttestbutton}>Start Test</button>
        <button className={style.viewresultsbutton}>View Results</button>
      </div>
    </div></>  
  )
}

export default PracticeTest