import React, { useState, useRef } from "react";
import style from "./../Css/Updateinternship.module.css";
import styles from "./../Css/Updatebasicdesign.module.css";
import API_BASE_URL from "../apiConfig";
import axios from 'axios';

const Updateinternship = () => {
  const [projectName, setProjectName] = useState("");
  const [projectDetails, setProjectDetails] = useState("");
  const [projectSubDetails, setProjectSubDetails] = useState("");
  const [message, setMessage] = useState("");

  // File input refs for images and PDF
  const projectImage1Ref = useRef(null);
  const projectImage2Ref = useRef(null);
  const projectImage3Ref = useRef(null);
  const projectPreviewPDFRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("projectName", projectName);
    formData.append("projectDetails", projectDetails);
    formData.append("projectSubDetails", projectSubDetails);

    if (projectImage1Ref.current.files[0]) {
      formData.append("projectImage1", projectImage1Ref.current.files[0]);
    }
    if (projectImage2Ref.current.files[0]) {
      formData.append("projectImage2", projectImage2Ref.current.files[0]);
    }
    if (projectImage3Ref.current.files[0]) {
      formData.append("projectImage3", projectImage3Ref.current.files[0]);
    }
    if (projectPreviewPDFRef.current.files[0]) {
      formData.append(
        "projectPreviewPDF",
        projectPreviewPDFRef.current.files[0]
      );
    }

    try {
         const response = await axios.post(`${API_BASE_URL}/update_internship.php`, formData, {
        headers: {
            "Content-Type": "multipart/form-data", // Important for sending FormData
        },
    });

    if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    setMessage(data.message); // Set the message returned from the server

      // Clear the form fields after successful submission
      setProjectName("");
      setProjectDetails("");
      setProjectSubDetails("");
      projectImage1Ref.current.value = "";
      projectImage2Ref.current.value = "";
      projectImage3Ref.current.value = "";
      projectPreviewPDFRef.current.value = "";

      // Hide the message after 4-5 seconds
      setTimeout(() => {
        setMessage("");
      }, 4000);
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred while updating the internship.");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };

  return (
    <>
      <div className="Create&Update">
        <form className="AvailableInternshipdataform" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`${styles.basicimgfielddesign} uploadprojectimage1withlables`}
              >
                <label htmlFor="projectImage1">Upload Project Image 1:</label>
                <input
                  className={styles.basicinputimgdesign}
                  type="file"
                  id="projectImage1"
                  ref={projectImage1Ref}
                  accept="image/*"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div
                className={`${styles.basicimgfielddesign} uploadprojectimage2withlables`}
              >
                <label htmlFor="projectImage2">Upload Project Image 2:</label>
                <input
                  className={styles.basicinputimgdesign}
                  type="file"
                  id="projectImage2"
                  ref={projectImage2Ref}
                  accept="image/*"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div
                className={`${styles.basicimgfielddesign} uploadprojectimage3withlables`}
              >
                <label htmlFor="projectImage3">Upload Project Image 3:</label>
                <input
                  className={styles.basicinputimgdesign}
                  type="file"
                  id="projectImage3"
                  ref={projectImage3Ref}
                  accept="image/*"
                />
              </div>
            </div>
          </div>

          <div className={`${styles.basicfieldsdesign} projectnameinputwithlables`}>
            <label htmlFor="projectName">Project Name:</label>
            <input className={styles.basicinputdesign}
              type="text"
              id="projectName"
              name="projectName"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              required
            />
          </div>

          <div className={`${styles.basicfieldsdesign} projectdetalisinputwithlables`}>
            <label htmlFor="projectDetails">Project Details:</label>
            <textarea className={styles.basicinputdesign}
              id="projectDetails"
              name="projectDetails"
              value={projectDetails}
              onChange={(e) => setProjectDetails(e.target.value)}
              required
            ></textarea>
          </div>

          <div className={`${styles.basicfieldsdesign} projectsubdetailswithlables`}>
            <label htmlFor="projectSubDetails">Sub-details:</label>
            <textarea className={styles.basicinputdesign}
              id="projectSubDetails"
              name="projectSubDetails"
              value={projectSubDetails}
              onChange={(e) => setProjectSubDetails(e.target.value)}
            ></textarea>
          </div>

          <div
            className={`${styles.basicimgfielddesign} uploadpdfforpreviewpurpose`}
          >
            <label htmlFor="projectPreviewPDF">Upload PDF for Preview:</label>
            <input
              className={styles.basicinputimgdesign}
              type="file"
              id="projectPreviewPDF"
              ref={projectPreviewPDFRef}
              accept="application/pdf"
            />
          </div>

          <div className={`${styles.adminsubminbtn} row`}>
            {/* <button className={style.adminsubmitbtnbtn} type="submit">
              Update Internship
            </button> */}

            <button className={`${styles.btncss} ${styles.fullrounded}`}>
              <span>Update Internship</span>
              <div className={`${styles.border} ${styles.fullrounded}`}></div>
            </button>
          </div>
        </form>

        {message && (
          <div className={styles.alertMessage}>
            <p>{message}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Updateinternship;
