import React, { useState } from 'react';
import axios from 'axios';
import style from "./../Css/Login.module.css";
import API_BASE_URL from '../apiConfig';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    fullName: '',
    mobileNumber: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(''); // State for message

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    try {
      const response = await axios.post(`${API_BASE_URL}/login.php`, {
        email: formData.email,
        password: formData.password,
        fullName: formData.fullName,
        mobileNumber: formData.mobileNumber,
      });

      // Check response status
      if (response.status !== 200) {
        throw new Error(response.data.message || 'Login failed');
      }

      const data = response.data;
      // console.log('Login successful:', data);
      setMessage("Login successful"); // Set success message

      // Store the email and other details in localStorage
      localStorage.setItem('userEmail', formData.email);
      localStorage.setItem('fullName', data.fullName);
      localStorage.setItem('mobilenumber', data.mobileNumber);

      // Clear input fields after successful login
      setFormData({ email: '', password: '' });

      // Clear the message after 1 second
      setTimeout(() => {
        setMessage('');
      }, 1000);

      // window.location.href = '/Dashboard'; 
      window.location.href = '/'; 
    } catch (error) {
      // console.error('Error during login:', error);
      setMessage(`Login failed: ${error.response?.data?.message || error.message}`); // Set error message
    }
  };

  return (
    <>
      <div className="modal fade" id="loginModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className={`${style.loginmodalbox} modal-body`}>
              <h1 className={`${style.loginheading} top`}>Login</h1>

              {message && <div className={style.message}>{message}</div>} {/* Display message */}

              <label className={style.logincontent} htmlFor="username">
                User name
                <div className={style.loginicons}>
                  <i className={`${style.loginIcons} fa-solid fa-at`}></i>
                  <input
                    className={style.loginmodalinputemail}
                    type="email"
                    placeholder="enter your email"
                    id="username"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </label>

              <label className={style.logincontent} htmlFor="password">
                Password
                <div className={style.loginicons}>
                  <i className={`${style.loginIcons} fa-solid fa-lock`}></i>
                  <input
                    className={style.loginmodalinputpass}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="your password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className={style.showPasswordBtnlogin}
                  >
                    {showPassword ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                  </button>
                </div>
              </label>

              <button
                type="button"
                className={`${style.loginmodalbtn} btn`}
                onClick={handleLogin}
              >
                Login
              </button>

              <div className="loginfooter d-flex justify-content-between">
                <p className={style.footertextlogin}>
                  Don't have an account? <a href="#" className="text-decoration-none" data-bs-toggle="modal" data-bs-target="#signupModal">Get registered</a>
                </p>
                <p className={style.footertextlogin}>
                  Forgot your password? <a href="#" className="text-decoration-none" data-bs-toggle="modal" data-bs-target="#ResetpassModal">Reset</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
