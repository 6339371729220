export const showCustomAlert = (message) => {
    const alertBox = document.createElement('div');
    alertBox.innerText = message;
    alertBox.style.position = 'fixed';
    alertBox.style.top = '5%';
    alertBox.style.left = '50%';
    alertBox.style.transform = 'translate(-50%, -50%)'; 
    alertBox.style.padding = '10px 20px';
    alertBox.style.backgroundColor = '#4CAF50';
    alertBox.style.color = 'white';
    alertBox.style.fontSize = '16px';
    alertBox.style.borderRadius = '5px';
    alertBox.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    alertBox.style.zIndex = '9999';
    
    document.body.appendChild(alertBox);
  
    // Remove the alert after 1 second
    setTimeout(() => {
      alertBox.remove();
    }, 1000);
  };
  