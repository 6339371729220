import React from 'react';
import style from './../Css/NotificationBar.module.css';

const NotificationBar = ({ isOpen, onClose, notifications }) => {
  if (!isOpen) return null;

  return (
    <div className={style.offcanvas}>
      <div className={style.offcanvasHeader}>
        <h2>Notifications</h2>
        <button onClick={onClose} className={style.closeBtn}>&times;</button>
      </div>
      <div className={style.offcanvasBody}>
        {notifications.length === 0 ? (
          <p>No new notifications</p>
        ) : (
          notifications.map(notification => (
            <div key={notification.id} className={style.notificationItem}>
              {notification.message}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default NotificationBar;
