import React, { useEffect, useState } from "react";
import Select from "react-select";
import style from "./../Css/Editinternship.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import { Link } from "react-router-dom";
import API_BASE_URL from "../apiConfig";

const Editinternship = () => {
  const [selectedCategory, setSelectedCategory] = useState({ value: "All", label: "All" });
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [message, setMessage] = useState(""); // State for popup message
  const [modalVisibleef, setModalVisibleef] = useState(false); // ef is edit form
  const [files, setFiles] = useState({
    image1: null,
    image2: null,
    image3: null,
    previewPDF: null,
  }); // State to manage file uploads


  const [internships, setInternships] = useState([]); // State to hold internship data

  const categories = [
    { value: "All", label: "All" },
    { value: "E-Commerce-Platform", label: "E-Commerce Platform" },
    { value: "Travel-Booking-System", label: "Travel Booking System" },
    { value: "Inventory-Management-System", label: "Inventory Management System" },
    { value: "Booking/On-Demand-Services", label: "Booking/On-Demand Services" },
    { value: "Corporate/B2B-Services", label: "Corporate/B2B Services" },
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  // Custom styles for the react-select dropdown
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Make sure the dropdown menu has a high z-index
    }),
  };


  useEffect(() => {
    const fetchInternships = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get_internship.php`);
        const data = await response.json();
        setInternships(data);
      } catch (error) {
        console.error("Failed to fetch Packages:", error);
      }
    };

    fetchInternships();
  }, []);


  const handlePreview = (images) => {
    setModalImages(images);
    setModalVisible(true);
  };

  const handleClosePreview = () => {
    setModalVisible(false);
    setModalImages([]);
  };

  // Filter internships based on the selected category
  const filteredInternships = selectedCategory.value === "All"
    ? internships
    : internships.filter((intern) =>
      intern.projectName.replace(/\s+/g, '-').toLowerCase() === selectedCategory.value.toLowerCase()
    );

  // Handle deleting a
  const handleDelete = async (internId) => {

    const showCustomAlert = (message, onConfirm, onCancel) => {
      const alertBox = document.createElement('div');
      alertBox.style.position = 'fixed';
      alertBox.style.top = '20%';
      alertBox.style.left = '50%';
      alertBox.style.transform = 'translate(-50%, -50%)';
      alertBox.style.padding = '20px';
      alertBox.style.backgroundColor = '#ACAF50';
      alertBox.style.color = 'white';
      alertBox.style.fontSize = '16px';
      alertBox.style.borderRadius = '5px';
      alertBox.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
      alertBox.style.zIndex = '9999';
      alertBox.style.textAlign = 'center';

      // Add the message
      const messageElement = document.createElement('p');
      messageElement.innerText = message;
      alertBox.appendChild(messageElement);

      // Add the Yes button
      const yesButton = document.createElement('button');
      yesButton.innerText = 'Yes';
      yesButton.style.margin = '10px';
      yesButton.style.padding = '5px 15px';
      yesButton.style.backgroundColor = '#5cb85c';
      yesButton.style.color = 'white';
      yesButton.style.border = 'none';
      yesButton.style.borderRadius = '3px';
      yesButton.style.cursor = 'pointer';

      yesButton.addEventListener('click', () => {
        alertBox.remove();
        onConfirm();
      });

      // Add the Cancel button
      const cancelButton = document.createElement('button');
      cancelButton.innerText = 'Cancel';
      cancelButton.style.margin = '10px';
      cancelButton.style.padding = '5px 15px';
      cancelButton.style.backgroundColor = '#d9534f';
      cancelButton.style.color = 'white';
      cancelButton.style.border = 'none';
      cancelButton.style.borderRadius = '3px';
      cancelButton.style.cursor = 'pointer';

      cancelButton.addEventListener('click', () => {
        alertBox.remove();
        if (onCancel) onCancel();
      });

      alertBox.appendChild(yesButton);
      alertBox.appendChild(cancelButton);

      document.body.appendChild(alertBox);
    };

    showCustomAlert(
      'Are you sure you want to delete this INTERNSHIP?',
      async () => {
        // const confirmDelete = window.confirm(
        //   "Are you sure you want to delete this INTERNSHIP?"
        // );

        // if (!confirmDelete) return; 
        // Exit if the user cancels

        try {
          const response = await fetch
            (`${API_BASE_URL}/delete_internship.php`,
              {
                method: "POST",
                body: JSON.stringify({ id: internId }),
                headers: { "Content-Type": "application/json" },
              }
            );

          const result = await response.json();

          if (result.status === "success") {
            setInternships(
              filteredInternships.filter((intern) => intern.id !== internId)
            );
          } else {
            alert(result.message);
          }
        } catch (error) {
          console.error("Error deleting Internship:", error);
        }
      }
    );
  };

  const [editInternship, setEditInternship] = useState(null); // State to manage edit mode

  // Handle editing a project
  const handleEdit = (intern) => {
    setEditInternship(intern);
    setFiles({
      image1: null,
      image2: null,
      image3: null,
      previewPDF: null,
    }); // Reset file inputs
    setModalVisibleef(true); // Show modal
  };

  const closeModal = () => {
    setModalVisibleef(false);
    setEditInternship(null); // Clear the edit state
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    setFiles((prev) => ({ ...prev, [fileType]: file }));
  };

  const handleUpdate = async () => {
    if (editInternship) {
      const formData = new FormData();
      formData.append("id", editInternship.id);
      formData.append("projectName", editInternship.projectName);
      formData.append("projectDetails", editInternship.projectDetails);
      formData.append("projectSubDetails", editInternship.projectSubDetails);

      if (files.image1) formData.append("projectImage1", files.image1);
      if (files.image2) formData.append("projectImage2", files.image2);
      if (files.image3) formData.append("projectImage3", files.image3);
      if (files.previewPDF)
        formData.append("projectPreviewPDF", files.previewPDF);

      // console.log([...formData]);
      try {
        const response = await fetch
          (`${API_BASE_URL}/edit_internship.php`,
            { method: "POST", body: formData }
          );
        const result = await response.json();
        // console.log(result);
        if (result.status === "success") {
          // Update state to reflect changes
          setInternships(
            internships.map((intern) =>
              intern.id === editInternship.id ? { ...editInternship } : intern
            )
          );
          setEditInternship(null); // Clear edit mode
          setMessage("Changes saved successfully!"); // Set success message
          setTimeout(() => setMessage(""), 3000); // Hide message after 3 seconds
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error("Error updating Internship:", error);
      }
    }
  };

  const handleHideToggle = async (intern) => {
    const newStatus = Number(intern.hidden) === 1 ? 0 : 1;

    try {
      const response = await fetch
        (`${API_BASE_URL}/toggle.hide.internship.php`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id: intern.id, hidden: newStatus }),
          }
        );

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const result = await response.json();
      console.log("Backend response:", result);

      if (result.status === "success") {
        setInternships(
          internships.map((c) =>
            c.id === intern.id ? { ...c, hidden: newStatus.toString() } : c
          )
        );
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error toggling course visibility:", error);
    }
  };

  return (
    <>
      <div className={`${style.internshipfluid} container-fluid`}>
        <div className="container">
          <div className={`${style.headerrow} row d-flex`}>
            <h1 className={style.internshipheading}>Available Internship</h1>
            <div className={style.dropdownContainer}>
              <Select
                options={categories}
                value={selectedCategory}
                onChange={handleCategoryChange}
                className={style.droupdown}
                placeholder="Select a category"
                isSearchable
                styles={customStyles}
              />
            </div>
          </div>

          <div className={`${style.internshiprow} row`}>
            {filteredInternships.map((intern) => (
              <div key={intern.id} className="col">
                <div
                  className={`${style.coursesbuyrowmain} ${style.liveprojectcard}`}
                >
                  <div className={`${style.coursesbuymain}`}>
                    <div className={style.courseslogomain}>
                      {/* <img className={style.coursesbuylogo} src={card.images[0]} alt="Preview 1" /> */}
                      {/* <img className={style.coursesbuylogo} src={card.images[1]} alt="Preview 2" /> */}
                      {/* <img className={style.coursesbuylogo} src={card.images[2]} alt="Preview 3" /> */}
                      <img
                        className={style.coursesbuylogo}
                        src={`${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage1}`}
                        alt={intern.projectName}
                      />
                      <img
                        className={style.coursesbuylogo}
                        src={`${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage2}`}
                        alt={intern.projectName}
                      />
                      <img
                        className={style.coursesbuylogo}
                        src={`${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage3}`}
                        alt={intern.projectName}
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>
                        {intern.projectName}
                      </h4>
                      <span className={style.coursesdata}>
                        {intern.projectDetails}
                      </span>
                      <h5 className={style.learnmorebuy}>
                        {intern.projectSubDetails}
                      </h5>
                    </div>
                    <div className={style.buttonz}>
                      <button
                        className={style.coursesbuybutton}
                        onClick={() =>
                          handlePreview([
                            `${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage1}`,
                            `${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage2}`,
                            `${API_BASE_URL}/internshipimgpdf/images/${intern.projectImage3}`,
                          ])
                        }
                      >
                        Preview
                      </button>
                      <Link
                        to={"/Apply_internshipfoam"}
                        style={{ textDecoration: "none" }}
                        className={style.coursesbuybutton}
                      >
                        Apply for Internship
                      </Link>
                    </div>

                    <div className={style.editbtns}>
                      <Link className={style.editbtnone} onClick={() => handleEdit(intern)}>Edit</Link>
                      <Link className={style.editbtntwo} onClick={() => handleDelete(intern.id)}>
                        Delete
                      </Link>
                      <Link className={style.editbtnthree} onClick={() => handleHideToggle(intern)}>
                        {Number(intern.hidden) === 1 ? "Unhide" : "Hide"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {modalVisible && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={handleClosePreview}>
              &times;
            </button>
            <div className={style.imageContainer}>
              {modalImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Preview ${index}`}
                  className={style.modalImage}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Edit Form */}
      {modalVisibleef && editInternship && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={closeModal}>
              &times;
            </button>

            <div className={`${style.editformrow} ${style.editformheading}row`}>
              <h2>Edit Internship</h2>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                <label>Update Project Name:</label>
                {" "}
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editInternship.projectName}
                  onChange={(e) =>
                    setEditInternship({
                      ...editInternship,
                      projectName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                <label>Update Project Details:</label>
                {" "}
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editInternship.projectDetails}
                  onChange={(e) =>
                    setEditInternship({
                      ...editInternship,
                      projectDetails: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                <label>Update Project Sub-details:</label>
                {" "}
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  value={editInternship.projectSubDetails}
                  onChange={(e) =>
                    setEditInternship({
                      ...editInternship,
                      projectSubDetails: e.target.value,
                    })
                  }
                />
              </div>
              {/* File Inputs for Images */}

            </div>
            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                <label>Update Project Image 1:</label>
                {" "}
                <input
                  className={styles.basicinputdesign}
                  type="file"
                  name="image1"
                  onChange={(e) => handleFileChange(e, "image1")}
                // onChange={handleFileChange}
                />
              </div>
              <div className="col-6">
                <label>Update Project Image 2:</label>
                <input
                  className={styles.basicinputdesign}
                  type="file"
                  name="image2"
                  onChange={(e) => handleFileChange(e, "image2")}
                // onChange={handleFileChange}
                />
              </div>

            </div>
            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                <label>Update Project Image 3:</label>
                {" "}
                <input
                  className={styles.basicinputdesign}
                  type="file"
                  name="image3"
                  onChange={(e) => handleFileChange(e, "image3")}
                // onChange={handleFileChange}
                />
              </div>
              {/* File Inputs for PDFs */}

              <div className="col-6">
                <label>Update PDF for Preview (pdf):</label>
                <input
                  className={styles.basicinputdesign}
                  type="file"
                  name="previewPDF"
                  onChange={(e) => handleFileChange(e, "previewPDF")}
                // onChange={handleFileChange}
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className={` ${style.savechangesbtn} col-6`}>
                <Link className={style.editbtnfour} onClick={handleUpdate}>Save Changes</Link>
              </div>
              <div className={` ${style.cancelbtn} col-6`}>
                {" "}
                <Link className={style.editbtnfour} onClick={() => setEditInternship(null)}>Cancel</Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Render Popup Message */}
      {message && (
        <div className={style.popupMessage}>
          <p>{message}</p>
        </div>
      )}
    </>
  );
};

export default Editinternship;
