import React, { useState, useEffect, useRef } from "react";
import style from "./../Css/Recentjob.module.css"; // Adjust CSS import if needed
import Applyforinternship from './Applyforinternship'
import API_BASE_URL from "../apiConfig";

// Dropdown Component with Search
const DropdownWithSearch = ({ items, placeholder, label, isOpen, toggleDropdown, onSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);

  // Filter items based on search query
  const filteredItems = items.filter(item => item.toLowerCase().includes(searchQuery.toLowerCase()));

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(); // Close the dropdown if clicking outside
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  return (
    <div ref={dropdownRef} className={style.droupdownmenu}>
      <button onClick={toggleDropdown} className={style.dropdownbutton}>
        {label}
      </button>

      {isOpen && (
        <div className={style.droupdowncontent}>
          <input
            type="text"
            placeholder={placeholder || "Search..."}
            value={searchQuery}
            onChange={handleSearchChange}
            className={style.dropdownsearchbar}
          />
          <ul className={style.droupdownul}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <li
                  key={index}
                  className={style.dropdownitemname}
                  onClick={() => onSelect(item)}
                >
                  {item}
                </li>
              ))
            ) : (
              <li className={style.dropdownitemname}>No items found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

// Main Recent Job Component
const Recentjob = () => {
  const [recentjob, setRecentJob] = useState([]); // Correct initialization
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  const jobCategories = ['All', 'Web Development', 'Frontend Developer', 'Backend Developer', 'Developer', 'Full Stack Developer', 'Data Analyst', 'Data Scientist', 'UI/UX Designer'];
  const locations = ['All', 'Pune', 'Hyderabad', 'Bangalore', 'Chennai', 'Mumbai'];

  // Job listings data


  // Filter jobs based on selected category and location
  const filteredJobs = recentjob.filter(job => {
    // Category Filter: If selectedCategory is not empty, filter by category
    const matchesCategory = selectedCategory && selectedCategory !== 'All' ? job.category.toLowerCase().replace(/\s+/g, '') === selectedCategory.toLowerCase().replace(/\s+/g, '') : true;

    // Location Filter: If selectedLocation is not empty, filter by location
    const matchesLocation = selectedLocation && selectedLocation !== 'All'
      ? job.jobLocation.toLowerCase().replace(/\s+/g, '') === selectedLocation.toLowerCase().replace(/\s+/g, '')
      : true;

    return matchesCategory && matchesLocation;
  });



  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  useEffect(() => {
    const fetchRecentJob = async () => {
      try {
        const response = await fetch
          (`${API_BASE_URL}/get_recentjob.php`
          );
        const data = await response.json();
        console.log(data); // Check the structure of the fetched data
        setRecentJob(data);
      } catch (error) {
        console.error("Failed to fetch Recent Jobs:", error);
      }
    };

    fetchRecentJob();
  }, []);


  return (
    <>
      <Applyforinternship />
      <div className="container-fluid">
        <div className="container">
          <div className={`${style.recentrowhead} row`}>
            <div className="col">
              <h4 className={style.recentjobmainheading}>JOB UPDATES</h4>
            </div>
            <div className={`${style.jobserchbutton} col`}>
              <div className={style.droupdownwithsearch}>
                <DropdownWithSearch
                  items={jobCategories}
                  placeholder="Search job categories..."
                  label={<span>Job Categories <i className="fa-solid fa-chevron-down mx-2"></i></span>}
                  isOpen={openDropdown === 'categories'}
                  toggleDropdown={() => toggleDropdown('categories')}
                  onSelect={(item) => { setSelectedCategory(item); toggleDropdown(null); }}
                />
                <DropdownWithSearch
                  items={locations}
                  placeholder="Search locations..."
                  label={<span>Location <i className="fa-solid fa-chevron-down mx-2"></i></span>}
                  isOpen={openDropdown === 'locations'}
                  toggleDropdown={() => toggleDropdown('locations')}
                  onSelect={(item) => { setSelectedLocation(item); toggleDropdown(null); }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className={`${style.recentjobcontainer} container`}>
          {filteredJobs.length > 0 ? (
            filteredJobs.filter((job) => Number(job.hidden) === 0) // Exclude hidden courses
              .map((job, index) => (
                <div key={index} className={`${style.row4}`}>
                  <div className={`${style.colimg} col-2`}>
                    <img src="Images/jobicon (5).png" alt="Job Icon" />
                  </div>
                  <div className={`${style.content} col-10`}>
                    <h3 className={style.coursename}>{job.title}</h3>
                    {/* <h4 className={style.coursetime}> {job.category}</h4> */}
                    <h5>{job.companyName}</h5>
                    <h4 className={style.coursetime}>Location: {job.jobLocation}</h4>
                    <h5 className={style.courseduration}>Qualification: {job.qualificationRequired}</h5>
                    <h5 className={style.courseduration}>Experience: {job.experience}</h5>
                    <h5 className={style.courseduration}>Last date: {job.lastDateToApply}</h5>
                    <h5 className={style.courseduration}>Profile: {job.jobProfile}</h5>
                    {/* <h5 className={style.courseduration}>Available Positions: {job.numberOfJobs}</h5> */}
                    <h5 className={style.courseduration}>Update date: {job.updateDate}</h5>
                    {/* <h5 className={style.courseduration}>Apply link: {job.joblink}</h5> */}
                    <div className={style.footcol2nd}>
                      <a className={style.btn} href={job.joblink} target="_blank" rel="noopener noreferrer">
                        Apply here
                      </a>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <h4>No jobs found for the selected filters</h4>
          )}
        </div>
      </div>
    </>
  );
};

export default Recentjob;
