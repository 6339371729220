import React, { useState, useRef, useEffect } from "react";
import style from "./../Css/Updatecourses.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import API_BASE_URL from "../apiConfig";
import axios from 'axios';

const Updatecourses = () => {
  const [courseName, setCourseName] = useState("");
  const [courseFullName, setCourseFullName] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [courseTechnology, setCourseTechnology] = useState("");
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null); // Ref for file input
  const fileInputRefviewpdf = useRef(null); // Ref for file input
  const topRef = useRef(null); // Ref to target top of the page

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("courseName", courseName);
    formData.append("courseFullName", courseFullName);
    formData.append("coursePrice", coursePrice);
    formData.append("courseDuration", courseDuration);
    formData.append("courseTechnology", courseTechnology);
    if (fileInputRef.current.files[0]) {
      formData.append("courseLogo", fileInputRef.current.files[0]);
    }
    if (fileInputRefviewpdf.current.files[0]) {
      formData.append("viewpdf", fileInputRefviewpdf.current.files[0]);
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/update_course.php`, formData, {
        headers: {
            "Content-Type": "multipart/form-data", // Important for sending FormData
        },
    });

    if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    setMessage(data.message); // Set the message returned from the server

      // Clear the form fields after successful submission
      setCourseName("");
      setCourseFullName("");
      setCoursePrice("");
      setCourseDuration("");
      setCourseTechnology("");
      fileInputRef.current.value = ""; // Clear the file input
      fileInputRefviewpdf.current.value = ""; // Clear the file input
  }catch (error) {
    console.error("Error:", error);
    setMessage("An error occurred while updating the course.");
  }
};

      // Scroll to top of the page after submission
      useEffect(() => {
        if (message) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });

   
      // Hide the message after 2 seconds
      setTimeout(() => {
        setMessage(""); // Clear the message
      }, 2000);
    }
  }, [message]);

  return (
    <>
      {/* Element to scroll to the top */}
      <div ref={topRef}></div>
      
      <div className="coursedataform">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md">
              <div className={`${styles.basicfieldsdesign} coursenameinputwithlables`}>
                <label htmlFor="courseName">Course Name:</label>
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  id="courseName"
                  name="courseName"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="col-md">
              <div className={`${styles.basicfieldsdesign} coursefullnameinputwithlables`}>
                <label htmlFor="courseFullName">Full Course Name:</label>
                <input
                  className={styles.basicinputdesign}
                  type="text"
                  id="courseFullName"
                  name="courseFullName"
                  value={courseFullName}
                  onChange={(e) => setCourseFullName(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>

          <div className={`${styles.basicfieldsdesign} coursepriceinputwithlables`}>
            <label htmlFor="coursePrice">Course Price:</label>
            <input
              className={styles.basicinputdesign}
              type="number"
              id="coursePrice"
              name="coursePrice"
              value={coursePrice}
              onChange={(e) => setCoursePrice(e.target.value)}
              required
            />
          </div>

          <div className={`${styles.basicfieldsdesign} coursedurationinputwithlables`}>
            <label htmlFor="courseDuration">Course Duration:</label>
            <input
              className={styles.basicinputdesign}
              type="text"
              id="courseDuration"
              name="courseDuration"
              value={courseDuration}
              onChange={(e) => setCourseDuration(e.target.value)}
              required
            />
          </div>

          <div className={`${styles.basicfieldsdesign} coursetechnologyinputwithlables`}>
            <label htmlFor="courseTechnology">Course Technology:</label>
            <input
              className={styles.basicinputdesign}
              type="text"
              id="courseTechnology"
              name="courseTechnology"
              value={courseTechnology}
              onChange={(e) => setCourseTechnology(e.target.value)}
            />
          </div>

          <div className={styles.basicimgfielddesign}>
            <label htmlFor="courseLogo">
              Upload Course Logo (JPEG, PNG, GIF only):
            </label>
            <input 
              type="file"
              id="courseLogo"
              className={`${styles.basicinputimgdesign} ${style.inputprojectimage} courseLogo`}
              accept="image/jpeg, image/png, image/gif"
              ref={fileInputRef} // Assign ref to the file input
            />
          </div>

          <div className={styles.basicimgfielddesign}>
            <label htmlFor="viewpdf">
              Upload pdf (pdf):
            </label>
            <input 
              type="file"
              id="viewpdf"
              className={`${styles.basicinputimgdesign} ${style.inputprojectimage} viewpdf`}
              accept="application/pdf"
              ref={fileInputRefviewpdf} // Assign ref to the file input
            />
          </div>

          <div className={`${styles.adminsubminbtn} row`}>
            <button className={`${styles.btncss} ${styles.fullrounded}`}>
              <span>Update Course</span>
              <div className={`${styles.border} ${styles.fullrounded}`}></div>
            </button>
          </div>
        </form>

        {/* Message alert centered on the screen */}
        {message && (
          <div className={styles.alertMessage}>
            <p>{message}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Updatecourses;
