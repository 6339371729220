import React, { useEffect, useState } from 'react'
import style from './../Css/DashboardLanding.module.css'
import { useNavigate } from 'react-router-dom';

const DashboardLanding = () => {

    const [fullName, setFullName] = useState('');

    useEffect(() => {
        // Retrieve the stored user name from localStorage
        const storedFullName = localStorage.getItem('fullName');
      
        if (storedFullName) {
          setFullName(storedFullName);
        }
      }, []);

       // Adjust scroll position to account for fixed navbar

       const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop - 150,  // Adjust the offset to match your navbar height
          behavior: 'smooth',
        });
      }
    }, 10);
  };
 
  const scrolltocourses = (e) => {
    e.preventDefault();
    navigate('/');
    scrollToSection('coursesbuyy');
    
  };



    return (
        <>



            <div className={style.dashboardLanding}>
                <div className={style.welcomeSection}>
                    <h1>Welcome ,    {fullName} </h1>
                    <p>You have [4] courses enrolled and [2] certifications completed.</p>
                </div>

                <div className={style.overviewCards}>
                    {/* Overview Cards */}
                    
                    <div className={style.card}>
                        <h3>Courses Enrolled</h3>
                        <p>3 Courses</p>
                    </div>
                    <div className={style.card}>
                        <h3>Certifications</h3>
                        <p>1 Completed</p>
                    </div>
                    <div className={style.card}>
                        <h3>Practice Tests</h3>
                        <p>2 Completed</p>
                    </div>
                    <div className={style.card}>
                        <h3>Live Classes</h3>
                        <p>Next: 12:00 PM, Sept 26</p>
                    </div>
                </div>

                <div className={style.recentActivity}>
                    <h2>Your Progress</h2>
                    <div className={style.courseProgress}>
                        <h4>JavaScript Fundamentals</h4>
                        <div className={style.progressBar}>
                            <div className={style.progress} style={{ width: '60%' }}></div>
                        </div>
                        <p>60% Completed</p>
                    </div>
                </div>

                <div className={style.events}>
                    <div className={style.upcomingEvents}>
                        <h2>Upcoming Events</h2>
                        <ul>
                            <li>Live Class: React Basics (Tomorrow, 2:00 PM)</li>
                            <li>Practice Test: JavaScript (Friday, 3:00 PM)</li>
                            <li>Project Deadline: E-commerce Site (Sunday)</li>
                        </ul>
                    </div>

                    <div className={style.actionButtons}>
                        <div className={`${style.piece} ${style.piece1}`}></div>
                        <div className={`${style.piece} ${style.piece2}`}></div>
                        <button onClick={scrolltocourses} className={style.actionButton}>
                            Enroll in New Course
                        </button>
                    </div>





                </div>

                <div className={style.certificationsSection}>
                    <h2>Your Certifications</h2>

                    <div className={style.certificationCard}>
                        <div className="">
                        <img src="Images/certificatelogo.jpg" alt="Certificate Badge" />
                        <p className={style.certificationname}>Full-Stack Web Development</p>
                        </div>
                     <div className="col text-end">
                     <button className={style.viewCertificate}>View Certificate</button>
                     </div>
                        
                    </div>

                </div>


            </div>


        </>
    )
}

export default DashboardLanding