import React, { useState, useRef, useEffect } from "react";
import style from "./../Css/Iitscodeadminpannel.module.css";

import Updatecourses from "./Updatecourses";
import Updatepackages from "./Updatepackages";
import Updateliveproject from "./Updateliveproject";
import Updateliveconnect from "./Updateliveconnect";
import Updateinternship from "./Updateinternship";
import Updaterecentjobs from "./Updaterecentjobs";
import Editcourses from "./Editcourses";
import Editpackages from "./Editpackages";
import Editliveproject from "./Editliveproject";
import Editliveconnect from "./Editliveconnect";
import Editinternship from "./Editinternship";
import Editrecentjobs from "./Editrecentjobs";
import ContactTable from "./ContactTable";
import InternshipTable from "./InternshipTable";
import JobsTable from "./JobsTable";
import ViewNewsletter from "./ViewNewsletter";
import Transactions from "./Transactions";

function Iitsadminpannel() {
  const [selectedChapter, setSelectedChapter] = useState("chapter11");
  const [adminInfo, setAdminInfo] = useState({ name: "", image: "" });
  const rightColumnRef = useRef(null);
  const [courseView, setCourseView] = useState("update"); // Tracks which component to show


  // Scroll to the top when selectedChapter changes
  useEffect(() => {
    if (rightColumnRef.current) {
      rightColumnRef.current.scrollTop = 0;
    }
  }, [selectedChapter]);


  // Retrieve admin info from localStorage
  useEffect(() => {
    const storedAdminInfo = JSON.parse(localStorage.getItem("adminInfo"));
    if (storedAdminInfo) {
      setAdminInfo(storedAdminInfo);
    }
  }, []);


  // Function to handle chapter selection
  const handleClick = (chapter) => {
    setSelectedChapter(chapter);
  };


  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      localStorage.clear();
      window.location.href = "/iitsadmin";
    }
  };



  return (
    <>
      <div className={`${style.nav} container-fluid`}>
        <div className={`${style.navbar}`}>
          <h2 className={style.adminpannelheading}>Admin Pannel</h2>
          <div className={style.logoutbtndiv}>
            <button
              className={`${style.Btn} ${style.adminlogoutbtn2}`}
              onClick={handleLogout}
            >
              <div className={style.sign}>
                <svg viewBox="0 0 512 512">
                  <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                </svg>
              </div>
              <div className={style.text}>LOGOUT</div>
            </button>
          </div>
        </div>
      </div>


      <div className={`${style.firstcontainer} container-fluid`}>
        <div className={`${style.therow} row`}>
          <div className={`${style.droupdownbtnbar} dropdown`}>
            <button
              className={`${style.droupdownbtn} btn dropdown-toggle`}
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <h4>CREATE & UPDATE</h4>
            </button>
            <ul className={`${style.dropdownmenu} dropdown-menu`}>


              <li
                onClick={() => handleClick("chapter11")}
                className={`${style.chapter11} ${selectedChapter === "chapter11" ? style.active : ""
                  } text-decoration-none dropdown-item`}
              >
                Transactions
              </li>

              <li
                onClick={() => handleClick("chapter1")}
                className={`${style.chapter1} ${selectedChapter === "chapter1" ? style.active : ""
                  } text-decoration-none dropdown-item`}
              >
                Courses
              </li>

              <li
                onClick={() => handleClick("chapter2")}
                className={`${style.chapter2} ${selectedChapter === "chapter2" ? style.active : ""
                  } dropdown-item`}
              >
                Packages
              </li>

              <li
                onClick={() => handleClick("chapter3")}
                className={`${style.chapter3} ${selectedChapter === "chapter3" ? style.active : ""
                  } dropdown-item`}
              >
                Live Projects
              </li>

              <li
                onClick={() => handleClick("chapter4")}
                className={`${style.chapter4} ${selectedChapter === "chapter4" ? style.active : ""
                  } dropdown-item`}
              >
                Live Connects
              </li>

              <li
                onClick={() => handleClick("chapter5")}
                className={`${style.chapter5} ${selectedChapter === "chapter5" ? style.active : ""
                  } dropdown-item`}
              >
                Internships
              </li>

              <li
                onClick={() => handleClick("chapter6")}
                className={`${style.chapter6} ${selectedChapter === "chapter6" ? style.active : ""
                  } dropdown-item`}
              >
                Recent Jobs
              </li>

              <li
                onClick={() => handleClick("chapter7")}
                className={`${style.chapter7} ${selectedChapter === "chapter7" ? style.active : ""
                  } dropdown-item`}
              >
                Contact Table
              </li>

              <li
                onClick={() => handleClick("chapter8")}
                className={`${style.chapter8} ${selectedChapter === "chapter8" ? style.active : ""
                  } dropdown-item`}
              >
                Internship Table
              </li>

              <li
                onClick={() => handleClick("chapter9")}
                className={`${style.chapter9} ${selectedChapter === "chapter9" ? style.active : ""
                  } dropdown-item`}
              >
                Job Table
              </li>

              <li
                onClick={() => handleClick("chapter10")}
                className={`${style.chapter10} ${selectedChapter === "chapter10" ? style.active : ""
                  } dropdown-item`}
              >
                News letter
              </li>


            </ul>
          </div>



          <div className={`${style.leftcolumn} col-2`}>
            <div className={`${style.adminprofile} row`}>
              {/* <div className={style.profileicondiv}>
                   {adminInfo.image ? (
                  <img
                    src={`http://localhost/iits_backend/Adminimage/${adminInfo.image}`}
                    alt="Admin Profile"
                    className={`${style.abcd} ${style.profileImagex}`}
                  />
                ) : (
                  <div className={`${style.placeholderImage}`}>
                    <p>No Profile Image</p>
                  </div>
                )}
              </div> */}
              <h5 className={style.adminusername}>{adminInfo.name}</h5>
              {/* <h4 className={style.createupdate}>CREATE & UPDATE</h4> */}
            </div>

            <ul className={`${style.chapters}`}>


              <li
                onClick={() => handleClick("chapter11")}
                className={`${style.chapter11} ${selectedChapter === "chapter11" ? style.active : ""
                  } text-decoration-none`}
              >
                Transactions
              </li>

              <li
                onClick={() => handleClick("chapter1")}
                className={`${style.chapter1} ${selectedChapter === "chapter1" ? style.active : ""
                  } text-decoration-none`}
              >
                Courses
              </li>

              <li
                onClick={() => handleClick("chapter2")}
                className={`${style.chapter2} ${selectedChapter === "chapter2" ? style.active : ""
                  }`}
              >
                Packages
              </li>

              <li
                onClick={() => handleClick("chapter3")}
                className={`${style.chapter3} ${selectedChapter === "chapter3" ? style.active : ""
                  }`}
              >
                Live Projects
              </li>

              <li
                onClick={() => handleClick("chapter4")}
                className={`${style.chapter4} ${selectedChapter === "chapter4" ? style.active : ""
                  }`}
              >
                Live Connects
              </li>

              <li
                onClick={() => handleClick("chapter5")}
                className={`${style.chapter5} ${selectedChapter === "chapter5" ? style.active : ""
                  }`}
              >
                Internships
              </li>

              <li
                onClick={() => handleClick("chapter6")}
                className={`${style.chapter6} ${selectedChapter === "chapter6" ? style.active : ""
                  }`}
              >
                Recent Jobs
              </li>

              <li
                onClick={() => handleClick("chapter7")}
                className={`${style.chapter7} ${selectedChapter === "chapter7" ? style.active : ""
                  }`}
              >
                Contact Table
              </li>

              <li
                onClick={() => handleClick("chapter8")}
                className={`${style.chapter8} ${selectedChapter === "chapter8" ? style.active : ""
                  }`}
              >
                Internship Table
              </li>

              <li
                onClick={() => handleClick("chapter9")}
                className={`${style.chapter9} ${selectedChapter === "chapter9" ? style.active : ""
                  }`}
              >
                Job Table
              </li>

              <li
                onClick={() => handleClick("chapter10")}
                className={`${style.chapter10} ${selectedChapter === "chapter10" ? style.active : ""
                  }`}
              >
                News letter
              </li>


            </ul>
          </div>

          <div
            className={`${style.rightcolumn} col`}
            ref={rightColumnRef}
            style={{ overflowY: "auto", maxHeight: "90vh" }}
          >
            {useEffect(() => {
              // Reset to "update" view whenever the selected chapter changes
              setCourseView("update");
            }, [selectedChapter])}

            {/* Chapter Content */}
            {selectedChapter === "chapter1" && (
              // <div className={style.chaptercontent}>
              //   <div className={style.headingdiv}>
              //     <h1 className={style.heading}> Manage Course</h1>
              //     <h1 className={style.heading}> View Courses </h1>

              //   </div>
              //   <Updatecourses />
              //   <Editcourses/>
              // </div>

              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Manage Course
                  </h1>
                  {/* Set state to show Editcourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("edit")}
                  >
                    View Courses
                  </h1>
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <Updatecourses />}
                {courseView === "edit" && <Editcourses />}
              </div>
            )}

            {selectedChapter === "chapter2" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Manage Packages Plans
                  </h1>
                  {/* Set state to show Editcourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("edit")}
                  >
                    View Packages
                  </h1>
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <Updatepackages />}
                {courseView === "edit" && <Editpackages />}
              </div>
            )}

            {selectedChapter === "chapter3" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Manage Live Projects
                  </h1>
                  {/* Set state to show Editcourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("edit")}
                  >
                    View Projects
                  </h1>
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <Updateliveproject />}
                {courseView === "edit" && <Editliveproject />}
              </div>
            )}

            {selectedChapter === "chapter4" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Manage Live Classes
                  </h1>
                  {/* Set state to show Editcourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("edit")}
                  >
                    View Classes
                  </h1>
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <Updateliveconnect />}
                {courseView === "edit" && <Editliveconnect />}
              </div>
            )}

            {selectedChapter === "chapter5" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Manage Internships
                  </h1>
                  {/* Set state to show Editcourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("edit")}
                  >
                    View Internships
                  </h1>
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <Updateinternship />}
                {courseView === "edit" && <Editinternship />}
              </div>
            )}

            {selectedChapter === "chapter6" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Manage Recent Jobs
                  </h1>
                  {/* Set state to show Editcourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("edit")}
                  >
                    View Jobs
                  </h1>
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <Updaterecentjobs />}
                {courseView === "edit" && <Editrecentjobs />}
              </div>
            )}

            {selectedChapter === "chapter7" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Contact Table
                  </h1>
                  {/* Set state to show Editcourses */}
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <ContactTable />}
              </div>
            )}

            {selectedChapter === "chapter8" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Internship Table
                  </h1>
                  {/* Set state to show Editcourses */}
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <InternshipTable />}
              </div>
            )}

            {selectedChapter === "chapter9" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    Job Table
                  </h1>
                  {/* Set state to show Editcourses */}
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <JobsTable />}

              </div>
            )}

            {selectedChapter === "chapter10" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    News Letter
                  </h1>
                  {/* Set state to show Editcourses */}
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <ViewNewsletter />}

              </div>
            )}

            {selectedChapter === "chapter11" && (
              <div className={style.chaptercontent}>
                <div className={style.headingdiv}>
                  {/* Set state to show Updatecourses */}
                  <h1
                    className={style.heading}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCourseView("update")}
                  >
                    REGISTER USERS
                  </h1>
                  {/* Set state to show Editcourses */}
                </div>
                {/* Conditionally render components based on the state */}
                {courseView === "update" && <Transactions />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Iitsadminpannel;
