import React, { useEffect, useRef, useState } from 'react';
import style from './../Css/ProfileEditModal.module.css';

const ProfileEditModal = ({ isOpen, onClose }) => {
  const [referralLink] = useState('http://example.com/referral?id=12345'); // Example referral link
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobilenumber, setMobilenumber] = useState('');
  
  const modalRef = useRef(null);

  useEffect(() => {
    const storedFullName = localStorage.getItem('fullName');
    const storedEmail = localStorage.getItem('userEmail');
    const storedMobilenumber = localStorage.getItem('mobilenumber');

    if (storedFullName || storedEmail || storedMobilenumber) {
      setFullName(storedFullName);
      setEmail(storedEmail);
      setMobilenumber(storedMobilenumber);
    }
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    alert('Referral link copied to clipboard!');
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose(); // Close the modal if click is outside of modal content
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={style.modalOverlay}>
      <div className={style.modalContent} ref={modalRef}>
        <h2 className={style.heading2}>Profile</h2>
        <div className={style.profileInfo}>
  <p>
    <strong>Name:</strong> {fullName}
  </p>
  <p>
    <strong>Email:</strong> {email}
  </p>
  <p>
    <strong>Phone:</strong> {mobilenumber}
  </p>
</div>

        <div className={style.referralSection}>
          <h3 className={style.heading3}>Referral Link</h3>
          <div className={style.referralLink}>
            <input
              type="text"
              value={referralLink}
              readOnly
              className={style.inputField}
            />
            <button onClick={copyToClipboard} className={style.copyButton}>
              <i className="fas fa-copy"></i> Copy
            </button>
          </div>
          <h3 className={style.heading3}>Share on Social Media</h3>
          <div className={style.socialMediaIcons}>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(referralLink)}`} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(referralLink)}`} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
        <div className={style.buttonGroup}>
          <button onClick={onClose} className={style.closeButton}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditModal;
