import React, { useState, useEffect, useRef } from 'react';
import style from "./../Css/Dashboard.module.css";
import MyCourses from './../Componenets/Mycourses';
import LiveLectures from './../Componenets/LiveLectures';
import RecordedLectures from './../Componenets/RecordedLectures';
import StudyMaterial from './../Componenets/StudyMaterial';
import PracticeTest from './../Componenets/PracticeTest';
import Certification from './../Componenets/Certification';



import ProfileEditModal from './ProfileEditModal';
import NotificationBar from './NotificationBar';
import notifications from './NotificationData';
import DashboardLanding from './DashboardLanding';
import { Link } from 'react-router-dom';
import API_BASE_URL from '../apiConfig';

const Dashboard = () => {
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [isNotificationBarOpen, setNotificationBarOpen] = useState(false);
  const notificationBarRef = useRef(null);

  const userEmail = localStorage.getItem('userEmail');

  const [fullName, setFullName] = useState('');
  const [activeTitle, setActiveTitle] = useState('DASHBOARD');
  { activeTitle.toUpperCase() }

  // Redirect when session end
  if (userEmail == "" || userEmail == null) {
    window.location.href = '/Landing_page'; // Redirect to login
  }


  const handleSectionChange = (section) => {
    setSelectedSection(section);
    setActiveTitle(section === 'Dashboard' ? 'DASHBOARD' : section); // Update the active title based on the section

  };


  const handleProfileClick = () => {
    setProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setProfileModalOpen(false);
  };

  const toggleNotificationBar = () => {
    setNotificationBarOpen(!isNotificationBarOpen);
  };




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationBarRef.current && !notificationBarRef.current.contains(event.target)) {
        setNotificationBarOpen(false);
      }
    };

    if (isNotificationBarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotificationBarOpen]);

  const renderContent = () => {
    if (selectedTutor) {
      // return showChat ? <ChatPanel tutor={selectedTutor} /> : <TutorDetail tutor={selectedTutor} />;
    }

    switch (selectedSection) {
      case 'My Courses':
        return <MyCourses />;
      case 'Live Lectures':
        return <LiveLectures />;
      case 'Recorded Lectures':
        return <RecordedLectures />;
      case 'Study Material':
        return <StudyMaterial />;
      case 'Practice Test':
        return <PracticeTest />;
      case 'Certification':
        return <Certification />;
      default:
        return <DashboardLanding />;
    }
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1040);

  useEffect(() => {
    const updateView = () => {
      setIsMobileView(window.innerWidth <= 1040);
    };

    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  }, []);




  useEffect(() => {
    // Retrieve the stored user name from localStorage
    const storedFullName = localStorage.getItem('fullName');

    if (storedFullName) {
      setFullName(storedFullName);
    }
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/logout.php`, {
        method: 'POST',
      });
      const data = await response.json();
      if (data.status === 'success') {
        localStorage.clear(); // Clear local storage
        window.location.href = '/'; // Redirect to login
      }
    } catch (error) {
      console.log('Logout error: ', error);
    }
  };


  return (
    <>
      <div className={`${style.dashfluid} container-fluid`}>
        <div className={`${style.dashboardheader} row`}>
          <div className={style.dashboardlogo}>
            <Link to={"/"} className={style.dashlogo} style={{ textDecoration: 'none', }} >IITS CODE</Link>
          </div>
          <div className={`${style.dashboardwrite} col`} >
            {activeTitle.toUpperCase()}
          </div>

          <div className={`${style.dashright} col d-flex`}>
            <div className={`col text-end me-4`}>
              <button className={style.notificationicon} onClick={toggleNotificationBar}>
                <i className={`fa-solid fa-bell`}></i>
                {notifications.length > 0 && (
                  <span className={style.notificationCount}>{notifications.length}</span>
                )}
              </button>
            </div>
            <div className={`${style.profilename} col d-flex`} onClick={handleProfileClick}>
              <div className={style.name}>
                {fullName}
              </div>
              <div className="col">
                <i className={`${style.profileimg} fa-solid fa-user`} /></div>
            </div>

          </div>
        </div>
      </div>

      <div className={`${style.da} container-fluid`}>
        <div className={`${style.dashboard} row`}>
          {!isMobileView && (
            <div className={`${style.leftpannel} col-2`}>


              <div className={style.dashboardlink}>

                <div className={`${style.dash} ${selectedSection === 'Dashboard' ? style.active : ''}`} onClick={() => handleSectionChange('Dashboard')}>
                  <button className={style.dashlink} >

                    <i className={`${style.dashicon} fa-regular fa-building mx-1`}> </i>  <span className={` ${style.dashtxt} mx-3`}>DASHBOARD</span>

                  </button>
                </div>

                <div className={`${style.dash} ${selectedSection === 'My Courses' ? style.active : ''}`} onClick={() => handleSectionChange('My Courses')}>
                  <button className={style.dashlink} >
                    <i className={`${style.dashicon} fa-solid fa-layer-group`}></i>  <span className={style.dashtxt}>My courses </span>
                  </button>
                </div>
                <div className={`${style.dash} ${selectedSection === 'Live Lectures' ? style.active : ''}`} onClick={() => handleSectionChange('Live Lectures')}>
                  <button className={style.dashlink} >
                    <i className={`${style.dashicon} fa-brands fa-youtube`}></i>  <span className={style.dashtxt}>Live lectures </span>
                  </button>
                </div>
                <div className={`${style.dash} ${selectedSection === 'Recorded Lectures' ? style.active : ''}`} onClick={() => handleSectionChange('Recorded Lectures')}>
                  <button className={style.dashlink} >
                    <i className={`${style.dashicon} fa-solid fa-video`}></i>  <span className={style.dashtxt}>Recorded lectures</span>
                  </button>
                </div>
                <div className={`${style.dash} ${selectedSection === 'Study Material' ? style.active : ''}`} onClick={() => handleSectionChange('Study Material')}>
                  <button className={style.dashlink} >
                    <i className={`${style.dashicon} fa-solid fa-book`}></i> <span className={style.dashtxt}>Study material </span>
                  </button>
                </div>
                <div className={`${style.dash} ${selectedSection === 'Practice Test' ? style.active : ''}`} onClick={() => handleSectionChange('Practice Test')}>
                  <button className={style.dashlink} >
                    <i className={`${style.dashicon} fa-solid fa-pen`}></i> <span className={style.dashtxt}>Practice test </span>
                  </button>
                </div>
                <div className={`${style.dash} ${selectedSection === 'Certification' ? style.active : ''}`} onClick={() => handleSectionChange('Certification')}>
                  <button className={style.dashlink} >
                    <i className={`${style.dashicon} fa-solid fa-certificate`}></i> <span className={style.dashtxt}>Certification </span>
                  </button>
                </div>
              </div>



              <div className={style.logoutbtn}>
                <button className={style.logout}
                  onClick={handleLogout}
                >
                  <i className={`${style.logouticon} fa-solid fa-right-from-bracket`}></i>
                  <span className={style.logoutword}>Logout</span>
                </button>
              </div>
            </div>
          )}

          <div className={`${style.dashboardcenter} ${isMobileView ? 'col' : 'col'}`}>
            {renderContent()}
          </div>






        </div>
      </div>

      {/* Profile Edit Modal */}
      <ProfileEditModal isOpen={isProfileModalOpen} onClose={closeProfileModal} />

      {/* Notification Bar */}
      <div ref={notificationBarRef}>
        <NotificationBar isOpen={isNotificationBarOpen} onClose={toggleNotificationBar} notifications={notifications} />
      </div>

      {/* Bottom Navigation Bar for Mobile View */}

      {isMobileView && (
        <div className={`${style.mobileNav} fixed-bottom d-flex justify-content-around`}>
          <button onClick={() => handleSectionChange('My Courses')} className={style.navButton}>
            <i className={`fa-solid fa-layer-group`}></i>
            <span className={style.navText}>Courses</span>
          </button>
          <button onClick={() => handleSectionChange('Live Lectures')} className={style.navButton}>
            <i className={`fa-brands fa-youtube`}></i>
            <span className={style.navText}>Live</span>
          </button>
          <button onClick={() => handleSectionChange('Recorded Lectures')} className={style.navButton}>
            <i className={`fa-solid fa-video`}></i>
            <span className={style.navText}>Recorded</span>
          </button>

          <button onClick={toggleNotificationBar} className={style.navButton}>
            <i className={`fa-solid fa-bell`}></i>
            {notifications.length > 0 && (
              <span className={style.notificationCount2}>{notifications.length}</span>
            )}
          </button>



          <div class={` ${style.dropup} dropup-center dropup`}>
            <button class={` ${style.dropupbtn} btn btn-secondary dropdown-toggle1`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa-solid fa-caret-up"></i>
            </button>

            <ul class={` ${style.dropupmenu} dropdown-menu `}>

              <li className='py-2'>  <button onClick={handleProfileClick} className={style.navButton}>
                <i className={`fa-solid fa-user px-2`}></i>
                <span className={style.dropText}>Profile</span>
              </button></li>

              <li className='py-2'>
                <button onClick={() => handleSectionChange('Certification')} className={style.navButton}>
                  <i className={`fa-solid fa-certificate px-2`}></i>
                  <span className={style.dropText}>Certificate</span>
                </button>
              </li>

              <li className='py-2'>
                <button onClick={() => handleSectionChange('Study Material')} className={style.navButton}>
                  <i className={`fa-solid fa-book px-2`}></i>
                  <span className={style.dropText}>Study</span>
                </button>
              </li>

              <li className='py-2'>
                <button onClick={() => handleSectionChange('Practice Test')} className={style.navButton}>
                  <i className={`fa-solid fa-pen px-2`}></i>
                  <span className={style.dropText}>Test</span>
                </button>
              </li>
              <li className='py-2'>
                <button className={style.droplogout}
                  onClick={handleLogout}
                >
                  <span className={style.logoutword}>Logout</span>
                  <i className={`${style.logouticon} fa-solid fa-right-from-bracket px-2`}></i>
                </button>

              </li>
            </ul>
          </div>
        </div>
      )}

    </>
  );
};

export default Dashboard;
