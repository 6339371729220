import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';  // Import the styles

const InternshipApplications = () => {
    const [applications, setApplications] = useState([]);
    const [filteredApplications, setFilteredApplications] = useState([]);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/Internship_table.php`);
                if (response.data.status === 'success') {
                    setApplications(response.data.data);
                    setFilteredApplications(response.data.data);
                } else {
                    setError(response.data.message);
                }
            } catch (err) {
                setError('Failed to fetch data. Please try again.');
            }
        };

        fetchApplications();
    }, []);
    const [selectedMonthYear, setSelectedMonthYear] = useState(null);

    // Handle the change of the selected month and year
    const handleMonthYearChange = (date) => {
        setSelectedMonthYear(date);
        filterApplicationsByMonthYear(date);
    };

    // Filter applications based on selected month and year
    const filterApplicationsByMonthYear = (date) => {
        if (date) {
            const selectedMonth = date.getMonth(); // 0-based month (Jan = 0, Feb = 1, etc.)
            const selectedYear = date.getFullYear(); // Full year (e.g., 2024)

            const filtered = applications.filter((app) => {
                const appDate = new Date(app.create); // Convert 'create' to Date object
                const appMonth = appDate.getMonth();
                const appYear = appDate.getFullYear();

                // Compare the selected month/year with the application date's month/year
                return appMonth === selectedMonth && appYear === selectedYear;
            });
            setFilteredApplications(filtered);
        } else {
            setFilteredApplications(applications); // If no date is selected, show all
        }
    };
      // Handle search query change
      const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        filterApplications(query);
    };

    // Filter applications based on search query
    const filterApplications = (query) => {
        const filtered = applications.filter(
            (app) =>
                app.full_name.toLowerCase().includes(query) || app.email.toLowerCase().includes(query)
        );
        setFilteredApplications(filtered);
    };
    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Internship Applications</h1>

            {/* Filters */}
                 {/* Filters */}
                 <div style={styles.filters}>
                <div>
                    <h3>Select Month and Year</h3>
                    <DatePicker
                        selected={selectedMonthYear}
                        onChange={handleMonthYearChange}
                        dateFormat="yyyy-MM" // Display format as 'yyyy-MM'
                        showMonthYearPicker // Allow month and year selection
                        className="form-control"
                    />
                </div>
                <div>
                    <h3>Search by Name or Email</h3>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search by name or email"
                        style={styles.searchInput}
                        className="form-control"
                    />
                </div>
            </div>

            {error ? (
                <p style={styles.error}>{error}</p>
            ) : filteredApplications.length > 0 ? (
                <div className="table-responsive">
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.headerCell}>#</th>
                            <th style={styles.headerCell}>Full Name</th>
                            <th style={styles.headerCell}>Email</th>
                            <th style={styles.headerCell}>Phone Number</th>
                            <th style={styles.headerCell}>Education</th>
                            <th style={styles.headerCell}>Position</th>
                            <th style={styles.headerCell}>LinkedIn</th>
                            <th style={styles.headerCell}>Portfolio</th>
                            <th style={styles.headerCell}>Start Date</th>
                            <th style={styles.headerCell}>Duration</th>
                            <th style={styles.headerCell}>Skills</th>
                            <th style={styles.headerCell}>Resume</th>
                            <th style={styles.headerCell}>Submit date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredApplications.map((app, index) => (
                            <tr key={index}>
                                <td style={styles.cell}>{index + 1}</td> {/* Dynamic Row Number */}
                                <td style={styles.cell}>{app.full_name}</td>
                                <td style={styles.cell}>{app.email}</td>
                                <td style={styles.cell}>{app.phone_number}</td>
                                <td style={styles.cell}>{app.education}</td>
                                <td style={styles.cell}>{app.position}</td>
                                <td style={styles.cell}>
                                    <a href={app.linkedin} target="_blank" rel="noopener noreferrer">
                                        View Profile
                                    </a>
                                </td>
                                <td style={styles.cell}>
                                    <a href={app.portfolio} target="_blank" rel="noopener noreferrer">
                                        View Portfolio
                                    </a>
                                </td>
                                <td style={styles.cell}>{app.start_date}</td>
                                <td style={styles.cell}>{app.duration}</td>
                                <td style={styles.cell}>{app.skills}</td>
                                <td style={styles.cell}>
                                    <a href={`${API_BASE_URL}/internshipt_resume/${app.resume}`} target="_blank" rel="noopener noreferrer">
                                        Download Resume
                                    </a>
                                </td>
                                <td style={styles.cell}>{app.create}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            ) : (
                <p style={styles.noData}>No applications found.</p>
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        margin: 'auto',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    filters: {
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        flexWrap: 'wrap',
    },
    select: {
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: '#fff',
        borderRadius: '8px',
        overflow: 'hidden',
    },
    headerCell: {
        border: '1px solid #ddd',
        padding: '10px',
        backgroundColor: '#007bff',
        color: 'white',
        textAlign: 'left',
    },
    cell: {
        border: '1px solid #ddd',
        padding: '10px',
    },
    noData: {
        textAlign: 'center',
        padding: '20px',
        color: '#555',
    },
};

export default InternshipApplications;
