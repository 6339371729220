import React, { useRef, useEffect, useState } from "react";
import style from "./../Css/Coursesbuy.module.css";
import API_BASE_URL from "../apiConfig";
import { showCustomAlert } from "../customalert";
import { useNavigate } from "react-router-dom";


const Coursesbuy = () => {
  const [courses, setCourses] = useState([]);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // Fetch data from backend
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get_courses.php`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCourses(data);
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      }
    };

    fetchCourses();
  }, []);

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };
  
  const navigate = useNavigate();

  const handlePaymentSuccess = async (courseId, amount, courseName) => {
    const useremail = localStorage.getItem("userEmail");
    const userName = localStorage.getItem("fullName");
    if (!useremail || !userName) {
      showCustomAlert("You must be logged in to make a payment. Please log in first.");
      // navigate("/Login");
      return;
    }

    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded) {
      showCustomAlert("Failed to load Razorpay SDK. Check your internet connection.");
      return;
    }
  
  
    try {
      const response = await fetch(`${API_BASE_URL}/create_payment_order.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          course_id: courseId,
          course_name: courseName,
          user_email: useremail,
          amount: String(amount),
          type: "course"
        }),
      });
  
      const paymentData = await response.json();
      console.log(paymentData)
      if (paymentData.status === "success") {
        const options = {
          key: "rzp_test_4PAo9zFuVbG062", 
          amount: paymentData.amount, 
          currency: "INR",
          order_id: paymentData.order_id,
          name: courseName,
          description: "Course Payment",
          handler: async function (response) {
            try {
              console.log(response)
              const verifyResponse = await fetch(`${API_BASE_URL}/verify_payment.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  course_id: courseId,
                  course_name: courseName,
                  user_email: useremail,
                  amount: amount,
                }),
              });
              const result = await verifyResponse.json();
              console.log(result)
              if (result.status === "success") {
                showCustomAlert("Payment verified successfully!");
              } else {
                showCustomAlert("Payment verification failed");
              }
            } catch (error) {
              console.error("Error verifying payment:", error);
            }
          },
          prefill: {
            name: userName,
            email: useremail,
          },
          theme: {
            color: "#21486f",
          },
        };
  
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        alert("Failed to create payment order");
      }
    } catch (error) {
      console.error("Error creating payment order:", error);
    }
  };
  


  return (
    <>
      <div
        id="coursesbuyy"
        className={`${style.coursebuycontainer_main} container-fluid`}
      >
        <div className={style.coursesbuycontainer}>
          <div className="heading">
            <h1 className={style.coursesbuyheading}>
              Transform Your Potential with Our Courses
            </h1>
          </div>

          <div className={style.scrollContainer} ref={scrollContainerRef}>
            <div className={style.coursesbuyrow}>
              {courses
                .filter((course) => Number(course.hidden) === 0) // Exclude hidden courses
                .map((course) => (
                  <div key={course.id} className={style.coursesbuy}>
                    <div className={style.courseslogo}>
                      <img
                        className={style.coursesbuylogo}
                        src={`${API_BASE_URL}/courseimg/${course.courseLogo}`}
                        alt={course.courseName}
                      />
                    </div>
                    <div className={style.coursesdetail}>
                      <h4 className={style.nameheading}>{course.courseName}</h4>
                      <h6 className={style.coursesdata}>
                        {course.courseFullName}
                      </h6>
                      <p className={style.price}>
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        {course.coursePrice}
                      </p>
                      <p className={style.courseduration}>
                        Duration: {course.courseDuration}
                      </p>
                      <p className={style.coursetechnologies}>
                        {course.courseTechnology}
                      </p>
                      <div className={style.buttoncontainer}>
                        <a
                          className={style.learnmorebuy}
                          href={`${API_BASE_URL}/coursepdf/${course.viewpdf}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more
                        </a>
                        <button
                          className={style.coursesbuybutton}
                          onClick={() =>
                            handlePaymentSuccess(
                              course.id,
                              course.coursePrice,
                              course.courseFullName
                            )
                          }
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className={style.arrowContainer}>
            <button onClick={scrollLeft} className={style.leftArrow}>
              <i className={`fa-solid fa-caret-left ${style.arrowicon}`}></i>
            </button>
            <button onClick={scrollRight} className={style.rightArrow}>
              <i className={`fa-solid fa-caret-right ${style.arrowicon}`}></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coursesbuy;
