import React, { useState } from 'react';
import style from './../../Css/Courses.module.css'

function Javacourse() {
    // State to keep track of the selected chapter
    const [selectedChapter, setSelectedChapter] = useState('chapter1')

    // Function to handle chapter selection
    const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter)
    }

    // State to track the active chapter
    const [activeChapter, setActiveChapter] = useState('chapter1');

    // Function to handle chapter click
    const handleChapterActive = (chapter) => {
        setActiveChapter(chapter);
    };
    const handleClick = (chapter) => {
        handleChapterActive(chapter);
        handleChapterClick(chapter);
        window.scrollTo(0,0);
    };


    return (
        <div className={`${style.firstcontainer} container-fluid`}>

            <div className={`${style.therow} row`}>
            <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Java SE
                    </button>
                    
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>


                        <li onClick={() => handleClick('chapter1')}
                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`} >
                       Introduction 
                        </li>



                        <li
                onClick={() => handleClick('chapter2')}
                className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                History and Applications of Java
                </li>


                <li onClick={() => handleClick('chapter3')}
                className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} dropdown-item `}>
                Features of Java
                </li>
    
                <li onClick={() => handleClick('chapter4')} 
                className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''} dropdown-item `}>
                Basic Syntax
                </li>

                <li onClick={() => handleClick('chapter5')} 
                className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''} dropdown-item `}>
                Comments
                </li>


                <li onClick={() => handleClick('chapter6')} 
                className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} dropdown-item `}>
                How to Set Path
                </li>


                <li onClick={() => handleClick('chapter7')}
                className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} dropdown-item `}>
                JDK , JRE and JVM
                </li>

                <li onClick={() => handleClick('chapter8')} 
                className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} dropdown-item `}>
                  Java Variables
                </li>


                <li onClick={() => handleClick('chapter9')}
                className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} dropdown-item `}>
                  Java DataTypes
                </li>

                <li onClick={() => handleClick('chapter10')}
                className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} dropdown-item `}>
                  Operators
                </li>

                <li onClick={() => handleClick('chapter11')}
                className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} dropdown-item `}>
                  Keywords
                </li>

<h5>Methods</h5>
                <li onClick={() => handleClick('chapter12')} 
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item `}>
                 nextInt()
                    </li>


                <li onClick={() => handleClick('chapter13')}
                className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} dropdown-item `}>
                  nextDouble()
                </li>


            <li onClick={() => handleClick('chapter14')} 
                className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''} dropdown-item `}>
                  nextFloat()
                </li>

                <li onClick={() => handleClick('chapter15')} 
                className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''} dropdown-item `}>
                  nextLong()
                </li>

                <li onClick={() => handleClick('chapter16')} 
                  className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''} dropdown-item `}>
                    nextShort()
                </li>



                <li onClick={() => handleClick('chapter17')} 
                className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''} dropdown-item `}>
                  nextByte()
                </li>

                <li onClick={() => handleClick('chapter18')}
                  className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''} dropdown-item `}>
                    nextBoolean()
                </li>


                <li onClick={() => handleClick('chapter19')} 
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} dropdown-item `}>
                  next()
                </li>


                <li onClick={() => handleClick('chapter20')}
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''} dropdown-item `}>
                  nextLine()
                </li>


<h5>Types of Methods</h5>
                <li onClick={() => handleClick('chapter128')} 
                className={`${style.chapter128} ${activeChapter === 'chapter128' ? style.active : ''} dropdown-item `}>
                  Non-return type
                </li>

                <li onClick={() => handleClick('chapter129')} 
                className={`${style.chapter129} ${activeChapter === 'chapter129' ? style.active : ''} dropdown-item `}>
                  Non-return type with parameters
                </li>

                <li onClick={() => handleClick('chapter130')} 
                className={`${style.chapter21} ${activeChapter === 'chapter130' ? style.active : ''} dropdown-item `}>
                  Return type
                </li>

                <li onClick={() => handleClick('chapter131')} 
                className={`${style.chapter131} ${activeChapter === 'chapter131' ? style.active : ''} dropdown-item `}>
                  Return type with parameters
                </li>

<h5>Decision-Making Statements:</h5>
                <li onClick={() => handleClick('chapter21')} 
                className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} dropdown-item `}>
                  if Statement
                </li>

                <li onClick={() => handleClick('chapter22')}
                className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''} dropdown-item `}>
                  if-else Statement
                </li>


                <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} dropdown-item `}>
                  if-else-if Ladder
                </li>

                <li onClick={() => handleClick('chapter24')} 
                className={`${style.chapter2} ${activeChapter === 'chapter24' ? style.active : ''} dropdown-item `}>
                  Nested if Statement4
                </li>


                <li onClick={() => handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} dropdown-item `}>
                  switch Statement
                </li>

<h5>Looping Statements</h5>
                <li onClick={() => handleClick('chapter26')} 
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} dropdown-item `}>
                  for Loop
                </li>

                <li onClick={() => handleClick('chapter27')} 
                className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''} dropdown-item `}>
                  while Loop
                </li>

                <li onClick={() => handleClick('chapter28')} 
                className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''} dropdown-item `}>
                  do-while Loop
                </li>

                <li onClick={() => handleClick('chapter29')} 
                 className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} dropdown-item `}>
                  Enhanced for Loop (for-each)
                </li>

<h5>Jump Statements</h5>
                <li onClick={() => handleClick('chapter30')} 
                className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} dropdown-item `}>
                  break Statement
                </li>

                <li onClick={() => handleClick('chapter31')} 
                className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} dropdown-item `}>
                  continue Statement
                </li>


                <li onClick={() => handleClick('chapter32')} 
                className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} dropdown-item `}>
                  return Statement
                </li>

<h5>Java Array</h5>
                <li onClick={() => handleClick('chapter33')} 
                className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} dropdown-item `}>
                  Introduction to Arrays
                </li>


                <li onClick={() => handleClick('chapter34')} 
                className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} dropdown-item `}>
                  Single-Dimensional Arrays
                </li>

                <li onClick={() => handleClick('chapter35')} 
                className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} dropdown-item `}>
                  Multi-Dimensional Arrays 
                </li>


                <li onClick={() => handleClick('chapter36')} 
                className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} dropdown-item `}>
                  Jagged Arrays
                </li>

                <li onClick={() => handleClick('chapter37')} 
                className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''} dropdown-item `}>
                  Loop Through Array
                </li>




  <h5>java String </h5>
                <li onClick={() => handleClick('chapter61')} 
                className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''} dropdown-item `}>
                  Intoduction
                </li>

                <li onClick={() => handleClick('chapter62')} 
                className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''} dropdown-item `}>
                  Immutable String
                </li>

                <li onClick={() => handleClick('chapter63')} 
                className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''} dropdown-item `}>
                  String Concatention
                </li>

                <li onClick={() => handleClick('chapter64')} 
                className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''} dropdown-item `}>
                  Substring
                </li>

                <li onClick={() => handleClick('chapter65')} 
                className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''} dropdown-item `}>
                  method of String Class
                </li>


                <li onClick={() => handleClick('chapter66')} 
                className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''} dropdown-item `}>
                  String Biffer Class
                </li>

                <li onClick={() => handleClick('chapter67')} 
                className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''} dropdown-item `}>
                  String Builder Class
                </li>

                <li onClick={() => handleClick('chapter68')} 
                className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''} dropdown-item `}>
                  String Buffer vs Builder
                </li>


                <li onClick={() => handleClick('chapter69')} 
                className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''} dropdown-item `}>
                  Creating Immutable Class
                </li>

                <li onClick={() => handleClick('chapter70')} 
                className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''} dropdown-item `}>
                  to string Method
                </li>

                <li onClick={() => handleClick('chapter71')} 
                className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''} dropdown-item `}>
                  To string Tokenizer Method
                </li>

<h5>Java String Methods</h5>
                <li onClick={() => handleClick('chapter72')} 
                className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''} dropdown-item `}>
                  String charAt() 
                </li>

                <li onClick={() => handleClick('chapter73')} 
                className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''} dropdown-item `}>
                  String compareTo()
                </li>


                <li onClick={() => handleClick('chapter74')} 
                className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''} dropdown-item `}>
                  String concat()
                </li>

                <li onClick={() => handleClick('chapter75')} 
                className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''} dropdown-item `}>
                  String contains()
                </li>

                <li onClick={() => handleClick('chapter76')} 
                className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''} dropdown-item `}>
                  String endsWith()
                </li>

                <li onClick={() => handleClick('chapter77')} 
                className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''} dropdown-item `}>
                  String equals()
                </li>

                <li onClick={() => handleClick('chapter78')} 
                className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''} dropdown-item `}>
                  String format()
                </li>

                <li onClick={() => handleClick('chapter79')} 
                className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''} dropdown-item `}>
                  String getBytes()
                </li>

                <li onClick={() => handleClick('chapter80')} 
                className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''} dropdown-item `}>
                  String getChars()
                </li>

                <li onClick={() => handleClick('chapter81')} 
                className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''} dropdown-item `}>
                   String indexOf()
                </li>

                <li onClick={() => handleClick('chapter82')} 
                className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''} dropdown-item `}>
                 String intern()
                </li>


                <li onClick={() => handleClick('chapter83')} 
                className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''} dropdown-item `}>
                  String isEmpty()
                </li>

                <li onClick={() => handleClick('chapter84')} 
                className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''} dropdown-item `}>
                  String join()
                </li>

                <li onClick={() => handleClick('chapter85')} 
                className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''} dropdown-item `}>
                  String lastIndexOf()
                </li>

                <li onClick={() => handleClick('chapter86')} 
                className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''} dropdown-item `}>
                  String length()
                </li>


                <li onClick={() => handleClick('chapter87')} 
                className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''} dropdown-item `}>
                  String replace()
                </li>

                <li onClick={() => handleClick('chapter88')} 
                className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''} dropdown-item `}>
                  String split()
                </li>

                <li onClick={() => handleClick('chapter89')} 
                className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''} dropdown-item `}>
                  String startswith()
                </li>

                <li onClick={() => handleClick('chapter90')} 
                className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''} dropdown-item `}>
                  String substring()
                </li>

                <li onClick={() => handleClick('chapter91')} 
                className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''} dropdown-item `}>
                  String toLowerCase()
                </li>

                <li onClick={() => handleClick('chapter92')} 
                className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''} dropdown-item `}>
                  String toUpperCase()
                </li>


                <li onClick={() => handleClick('chapter93')} 
                className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} dropdown-item `}>
                  String trim()
                </li>

                <li onClick={() => handleClick('chapter94')} 
                className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''} dropdown-item `}>
                  String toCharArray()
                </li>






<h5>Java OOPS</h5>                
                <li onClick={() => handleClick('chapter38')} 
                className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} dropdown-item `}>
                  Java OOPS Concept
                </li>

                <li onClick={() => handleClick('chapter39')} 
                className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''} dropdown-item `}>
                  Objects and classes
                </li>

                <li onClick={() => handleClick('chapter40')} 
                className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''} dropdown-item `}>
                  Class Method
                </li>

                <li onClick={() => handleClick('chapter41')} 
                className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''} dropdown-item `}>
                  Constructors
                </li>


                <li onClick={() => handleClick('chapter42')} 
                className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''} dropdown-item `}>
                  Static Keyword
                </li>

                <li onClick={() => handleClick('chapter43')} 
                className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''} dropdown-item `}>
                  This Keyword
                </li>

                <li onClick={() => handleClick('chapter44')} 
                className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''} dropdown-item `}>
                  Java Object get class() Method
                </li>


<h5>Java Inheritance</h5>
                <li onClick={() => handleClick('chapter45')} 
                className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''} dropdown-item `}>
                   Inheritance
                </li>

                <li onClick={() => handleClick('chapter46')} 
                className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''} dropdown-item `}>
                  Aggregation
                </li>

<h5>Java Polymorphism </h5>
                <li onClick={() => handleClick('chapter47')} 
                className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''} dropdown-item `}>
                  Method Overloading
                </li>


                <li onClick={() => handleClick('chapter48')} 
                className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''} dropdown-item `}>
                  Method Overriding
                </li>


                <li onClick={() => handleClick('chapter49')} 
                className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''} dropdown-item `}>
                  Covariant Return Types
                </li>

                <li onClick={() => handleClick('chapter50')} 
                className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''} dropdown-item `}>
                  Super Keyword
                </li>


                <li onClick={() => handleClick('chapter51')} 
                className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''} dropdown-item `}>
                  Final Keyword
                </li>

                <li onClick={() => handleClick('chapter52')} 
                className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''} dropdown-item `}>
                  Runtime Polymorphism
                </li>

                <li onClick={() => handleClick('chapter53')} 
                className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''} dropdown-item `}>
                  Dynamic Method Dispatch
                </li>


                <li onClick={() => handleClick('chapter54')} 
                className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''} dropdown-item `}>
                  instanceof with Polymorphism
                </li>

<h5>Java Abstraction</h5>
                <li onClick={() => handleClick('chapter55')} 
                className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''} dropdown-item `}>
                  Abstract Class
                </li>

                <li onClick={() => handleClick('chapter56')} 
                className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''} dropdown-item `}>
                  Interface
                </li>


                <li onClick={() => handleClick('chapter57')} 
                className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''} dropdown-item `}>
                  Abstract vs Interface
                </li>


<h5>Java Encapsulation</h5>
                <li onClick={() => handleClick('chapter58')} 
                className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''} dropdown-item `}>
                  Package
                </li>

                <li onClick={() => handleClick('chapter59')} 
                className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''} dropdown-item `}>
                  Access Modifiers
                </li>

                <li onClick={() => handleClick('chapter60')} 
                className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''} dropdown-item `}>
                  Encapsulation
                </li>

   <h5>Exception Handling</h5>
                <li onClick={() => handleClick('chapter95')} 
                className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''} dropdown-item `}>
                  Java Exception
                </li>

                <li onClick={() => handleClick('chapter96')} 
                className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''} dropdown-item `}>
                  Java Try-catch Block
                </li>

                <li onClick={() => handleClick('chapter97')} 
                className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''} dropdown-item `}>
                  Java Multiple catch Block
                </li>


                <li onClick={() => handleClick('chapter98')} 
                className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''} dropdown-item `}>
                  Java Nested try
                </li>

                <li onClick={() => handleClick('chapter99')} 
                className={`${style.chapter99} ${activeChapter === 'chapter99' ? style.active : ''} dropdown-item `}>
                  Java Finally Block
                </li>

                <li onClick={() => handleClick('chapter100')} 
                className={`${style.chapter100} ${activeChapter === 'chapter100' ? style.active : ''} dropdown-item `}>
                  Java Throw Keyword
                </li>


                <li onClick={() => handleClick('chapter101')} 
                className={`${style.chapter101} ${activeChapter === 'chapter101' ? style.active : ''} dropdown-item `}>
                  Java Throws Keyword
                </li>



                <li onClick={() => handleClick('chapter102')} 
                className={`${style.chapter102} ${activeChapter === 'chapter102' ? style.active : ''} dropdown-item `}>
                  Java Throw vs Throws Keyword
                </li>


                <li onClick={() => handleClick('chapter103')} 
                className={`${style.chapter103} ${activeChapter === 'chapter103' ? style.active : ''} dropdown-item `}>
                  Java Exception Propagation
                </li>



                <li onClick={() => handleClick('chapter104')} 
                className={`${style.chapter104} ${activeChapter === 'chapter104' ? style.active : ''} dropdown-item `}>
                  Final vs Finally vs Finalize
                </li>



                <li onClick={() => handleClick('chapter105')} 
                className={`${style.chapter105} ${activeChapter === 'chapter105' ? style.active : ''} dropdown-item `}>
                  Exception Handling with Method Overriding
                </li>



                <li onClick={() => handleClick('chapter106')} 
                className={`${style.chapter106} ${activeChapter === 'chapter106' ? style.active : ''} dropdown-item `}>
                  Java Custom Exceptions
                </li>




<h5>Java File Handling </h5>
                <li onClick={() => handleClick('chapter107')} 
                className={`${style.chapter107} ${activeChapter === 'chapter107' ? style.active : ''} dropdown-item `}>
                  Java Files
                </li>


                <li onClick={() => handleClick('chapter108')} 
                className={`${style.chapter108} ${activeChapter === 'chapter108' ? style.active : ''} dropdown-item `}>
                  Java Create/Write Files
                </li>

                <li onClick={() => handleClick('chapter109')} 
                className={`${style.chapter109} ${activeChapter === 'chapter109' ? style.active : ''} dropdown-item `}>
                  Java Read Files
                </li>


                <li onClick={() => handleClick('chapter110')} 
                className={`${style.chapter110} ${activeChapter === 'chapter110' ? style.active : ''} dropdown-item `}>
                  Java Delete Files
                </li>


<h5>Java Multithreading</h5>
                <li onClick={() => handleClick('chapter111')} 
                className={`${style.chapter111} ${activeChapter === 'chapter111' ? style.active : ''} dropdown-item `}>
                  Java Multithreading
                </li>


                <li onClick={() => handleClick('chapter112')} 
                className={`${style.chapter112} ${activeChapter === 'chapter112' ? style.active : ''} dropdown-item `}>
                  Java Thread Life Cycle
                </li>


                <li onClick={() => handleClick('chapter113')} 
                className={`${style.chapter113} ${activeChapter === 'chapter113' ? style.active : ''} dropdown-item `}>
                  Java Create Thread
                </li>


                <li onClick={() => handleClick('chapter114')} 
                className={`${style.chapter114} ${activeChapter === 'chapter114' ? style.active : ''} dropdown-item `}>
                  Java Thread Scheduler
                </li>


                <li onClick={() => handleClick('chapter115')} 
                className={`${style.chapter115} ${activeChapter === 'chapter115' ? style.active : ''} dropdown-item `}>
                  Java sleeping a Thread
                </li>


                <li onClick={() => handleClick('chapter116')} 
                className={`${style.chapter116} ${activeChapter === 'chapter116' ? style.active : ''} dropdown-item `}>
                  Java Start a Thread twice
                </li>


                <li onClick={() => handleClick('chapter117')} 
                className={`${style.chapter117} ${activeChapter === 'chapter117' ? style.active : ''} dropdown-item `}>
                  Java run() method
                </li>

                <li onClick={() => handleClick('chapter118')} 
                className={`${style.chapter118} ${activeChapter === 'chapter118' ? style.active : ''} dropdown-item `}>
                  Java Joining a Thread
                </li>

                <li onClick={() => handleClick('chapter119')} 
                className={`${style.chapter119} ${activeChapter === 'chapter119' ? style.active : ''} dropdown-item `}>
                  Java Naming a thread
                </li>

                <li onClick={() => handleClick('chapter120')} 
                className={`${style.chapter120} ${activeChapter === 'chapter120' ? style.active : ''} dropdown-item `}>
                  Java Thread priority
                </li>

                <li onClick={() => handleClick('chapter121')} 
                className={`${style.chapter121} ${activeChapter === 'chapter121' ? style.active : ''} dropdown-item `}>
                  Java Daemon Thread
                </li>

                <li onClick={() => handleClick('chapter122')} 
                className={`${style.chapter122} ${activeChapter === 'chapter122' ? style.active : ''} dropdown-item `}>
                  Java Thread Pool
                </li>

                <li onClick={() => handleClick('chapter123')} 
                className={`${style.chapter123} ${activeChapter === 'chapter123' ? style.active : ''} dropdown-item `}>
                  Java Thread Group
                </li>

                <li onClick={() => handleClick('chapter124')} 
                className={`${style.chapter124} ${activeChapter === 'chapter124' ? style.active : ''} dropdown-item `}>
                  Java Performance multiple task
                </li>

                <li onClick={() => handleClick('chapter125')} 
                className={`${style.chapter125} ${activeChapter === 'chapter125' ? style.active : ''} dropdown-item `}>
                  Java ShutdownHook
                </li>

                <li onClick={() => handleClick('chapter126')} 
                className={`${style.chapter126} ${activeChapter === 'chapter126' ? style.active : ''} dropdown-item `}>
                  Java Garbage Collection
                </li>

                <li onClick={() => handleClick('chapter127')} 
                className={`${style.chapter127} ${activeChapter === 'chapter127' ? style.active : ''} dropdown-item `}>
                  Java Runtime class
                </li>






              </ul>
                </div>





                <div className={`${style.leftcolumn} col-2`}>

                    <ul className={`${style.chapters} `}>
                    <h5 className={style.stickyheading} > Java SE</h5>
                
                <li
                onClick={() => handleClick('chapter1')}
                className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} `}>
                Intoduction
                </li>
               


                <li
                onClick={() => handleClick('chapter2')}
                className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}  `}>
                History and Applications of Java
                </li>


                <li onClick={() => handleClick('chapter3')}
                className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} `}>
                Features of Java
                </li>
    
                <li onClick={() => handleClick('chapter4')} 
                className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}  `}>
                Basic Syntax
                </li>

                <li onClick={() => handleClick('chapter5')} 
                className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}  `}>
                Comments
                </li>


                <li onClick={() => handleClick('chapter6')} 
                className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}  `}>
                How to Set Path
                </li>


                <li onClick={() => handleClick('chapter7')}
                className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} `}>
                JDK , JRE and JVM
                </li>

                <li onClick={() => handleClick('chapter8')} 
                className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} `}>
                  Java Variables
                </li>


                <li onClick={() => handleClick('chapter9')}
                className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}  `}>
                  Java DataTypes
                </li>

                <li onClick={() => handleClick('chapter10')}
                className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} `}>
                  Operators
                </li>

                <li onClick={() => handleClick('chapter11')}
                className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''}  `}>
                  Keywords
                </li>

<h5>Methods</h5>
                <li onClick={() => handleClick('chapter12')} 
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} `}>
                 nextInt()
                    </li>


                <li onClick={() => handleClick('chapter13')}
                className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} `}>
                  nextDouble()
                </li>


            <li onClick={() => handleClick('chapter14')} 
                className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}  `}>
                  nextFloat()
                </li>

                <li onClick={() => handleClick('chapter15')} 
                className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}  `}>
                  nextLong()
                </li>

                <li onClick={() => handleClick('chapter16')} 
                  className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}  `}>
                    nextShort()
                </li>



                <li onClick={() => handleClick('chapter17')} 
                className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}  `}>
                  nextByte()
                </li>

                <li onClick={() => handleClick('chapter18')}
                  className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}  `}>
                    nextBoolean()
                </li>


                <li onClick={() => handleClick('chapter19')} 
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''}  `}>
                  next()
                </li>


                <li onClick={() => handleClick('chapter20')}
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}  `}>
                  nextLine()
                </li>


<h5>Types of Methods</h5>
                <li onClick={() => handleClick('chapter128')} 
                className={`${style.chapter128} ${activeChapter === 'chapter128' ? style.active : ''}  `}>
                  Non-return type
                </li>

                <li onClick={() => handleClick('chapter129')} 
                className={`${style.chapter129} ${activeChapter === 'chapter129' ? style.active : ''}  `}>
                  Non-return type with parameters
                </li>

                <li onClick={() => handleClick('chapter130')} 
                className={`${style.chapter21} ${activeChapter === 'chapter130' ? style.active : ''}  `}>
                  Return type
                </li>

                <li onClick={() => handleClick('chapter131')} 
                className={`${style.chapter131} ${activeChapter === 'chapter131' ? style.active : ''}  `}>
                  Return type with parameters
                </li>

<h5>Decision-Making Statements:</h5>
                <li onClick={() => handleClick('chapter21')} 
                className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}  `}>
                  if Statement
                </li>

                <li onClick={() => handleClick('chapter22')}
                className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}  `}>
                  if-else Statement
                </li>


                <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}  `}>
                  if-else-if Ladder
                </li>

                <li onClick={() => handleClick('chapter24')} 
                className={`${style.chapter2} ${activeChapter === 'chapter24' ? style.active : ''}  `}>
                  Nested if Statement4
                </li>


                <li onClick={() => handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}  `}>
                  switch Statement
                </li>

<h5>Looping Statements</h5>
                <li onClick={() => handleClick('chapter26')} 
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}  `}>
                  for Loop
                </li>

                <li onClick={() => handleClick('chapter27')} 
                className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}  `}>
                  while Loop
                </li>

                <li onClick={() => handleClick('chapter28')} 
                className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}  `}>
                  do-while Loop
                </li>

                <li onClick={() => handleClick('chapter29')} 
                 className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''}  `}>
                  Enhanced for Loop (for-each)
                </li>

<h5>Jump Statements</h5>
                <li onClick={() => handleClick('chapter30')} 
                className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} `}>
                  break Statement
                </li>

                <li onClick={() => handleClick('chapter31')} 
                className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} `}>
                  continue Statement
                </li>


                <li onClick={() => handleClick('chapter32')} 
                className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} `}>
                  return Statement
                </li>

<h5>Java Array</h5>
                <li onClick={() => handleClick('chapter33')} 
                className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} `}>
                  Introduction to Arrays
                </li>


                <li onClick={() => handleClick('chapter34')} 
                className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} `}>
                  Single-Dimensional Arrays
                </li>

                <li onClick={() => handleClick('chapter35')} 
                className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} `}>
                  Multi-Dimensional Arrays 
                </li>


                <li onClick={() => handleClick('chapter36')} 
                className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''}  `}>
                  Jagged Arrays
                </li>

                <li onClick={() => handleClick('chapter37')} 
                className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''}  `}>
                  Loop Through Array
                </li>




                <h5>java String </h5>
                <li onClick={() => handleClick('chapter61')} 
                className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''}  `}>
                  Intoduction
                </li>

                <li onClick={() => handleClick('chapter62')} 
                className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''}  `}>
                  Immutable String
                </li>

                <li onClick={() => handleClick('chapter63')} 
                className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''}  `}>
                  String Concatention
                </li>

                <li onClick={() => handleClick('chapter64')} 
                className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''}  `}>
                  Substring
                </li>

                <li onClick={() => handleClick('chapter65')} 
                className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''}  `}>
                  method of String Class
                </li>


                <li onClick={() => handleClick('chapter66')} 
                className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''}  `}>
                  String Biffer Class
                </li>

                <li onClick={() => handleClick('chapter67')} 
                className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''}  `}>
                  String Builder Class
                </li>

                <li onClick={() => handleClick('chapter68')} 
                className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''}  `}>
                  String Buffer vs Builder
                </li>


                <li onClick={() => handleClick('chapter69')} 
                className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''}  `}>
                  Creating Immutable Class
                </li>

                <li onClick={() => handleClick('chapter70')} 
                className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''}  `}>
                  to string Method
                </li>

                <li onClick={() => handleClick('chapter71')} 
                className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''}  `}>
                  To string Tokenizer Method
                </li>

<h5>Java String Methods</h5>
                <li onClick={() => handleClick('chapter72')} 
                className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''}  `}>
                  String charAt() 
                </li>

                <li onClick={() => handleClick('chapter73')} 
                className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''}  `}>
                  String compareTo()
                </li>


                <li onClick={() => handleClick('chapter74')} 
                className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''}  `}>
                  String concat()
                </li>

                <li onClick={() => handleClick('chapter75')} 
                className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''}  `}>
                  String contains()
                </li>

                <li onClick={() => handleClick('chapter76')} 
                className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''}  `}>
                  String endsWith()
                </li>

                <li onClick={() => handleClick('chapter77')} 
                className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''}  `}>
                  String equals()
                </li>

                <li onClick={() => handleClick('chapter78')} 
                className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''}  `}>
                  String format()
                </li>

                <li onClick={() => handleClick('chapter79')} 
                className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''}  `}>
                  String getBytes()
                </li>

                <li onClick={() => handleClick('chapter80')} 
                className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''}  `}>
                  String getChars()
                </li>

                <li onClick={() => handleClick('chapter81')} 
                className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''}  `}>
                   String indexOf()
                </li>

                <li onClick={() => handleClick('chapter82')} 
                className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''}  `}>
                 String intern()
                </li>


                <li onClick={() => handleClick('chapter83')} 
                className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''}  `}>
                  String isEmpty()
                </li>

                <li onClick={() => handleClick('chapter84')} 
                className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''}  `}>
                  String join()
                </li>

                <li onClick={() => handleClick('chapter85')} 
                className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''}  `}>
                  String lastIndexOf()
                </li>

                <li onClick={() => handleClick('chapter86')} 
                className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''}  `}>
                  String length()
                </li>


                <li onClick={() => handleClick('chapter87')} 
                className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''}  `}>
                  String replace()
                </li>

                <li onClick={() => handleClick('chapter88')} 
                className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''}  `}>
                  String split()
                </li>

                <li onClick={() => handleClick('chapter89')} 
                className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''}  `}>
                  String startswith()
                </li>

                <li onClick={() => handleClick('chapter90')} 
                className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''}  `}>
                  String substring()
                </li>

                <li onClick={() => handleClick('chapter91')} 
                className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''}  `}>
                  String toLowerCase()
                </li>

                <li onClick={() => handleClick('chapter92')} 
                className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''}  `}>
                  String toUpperCase()
                </li>


                <li onClick={() => handleClick('chapter93')} 
                className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} `}>
                  String trim()
                </li>

                <li onClick={() => handleClick('chapter94')} 
                className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''}  `}>
                  String toCharArray()
                </li>



                <h5>Java OOPS</h5>                
                <li onClick={() => handleClick('chapter38')} 
                className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''}  `}>
                  Java OOPS Concept
                </li>

                <li onClick={() => handleClick('chapter39')} 
                className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''}  `}>
                  Objects and classes
                </li>

                <li onClick={() => handleClick('chapter40')} 
                className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''}  `}>
                  Class Method
                </li>

                <li onClick={() => handleClick('chapter41')} 
                className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''}  `}>
                  Constructors
                </li>


                <li onClick={() => handleClick('chapter42')} 
                className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''}  `}>
                  Static Keyword
                </li>

                <li onClick={() => handleClick('chapter43')} 
                className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''}  `}>
                  This Keyword
                </li>

                <li onClick={() => handleClick('chapter44')} 
                className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''}  `}>
                  Java Object get class() Method
                </li>





<h5>Java Inheritance</h5>
                <li onClick={() => handleClick('chapter45')} 
                className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''}  `}>
                   Inheritance
                </li>

                <li onClick={() => handleClick('chapter46')} 
                className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''}  `}>
                  Aggregation
                </li>

<h5>Java Polymorphism </h5>
                <li onClick={() => handleClick('chapter47')} 
                className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''}  `}>
                  Method Overloading
                </li>


                <li onClick={() => handleClick('chapter48')} 
                className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''}  `}>
                  Method Overriding
                </li>


                <li onClick={() => handleClick('chapter49')} 
                className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''}  `}>
                  Covariant Return Types
                </li>

                <li onClick={() => handleClick('chapter50')} 
                className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''}  `}>
                  Super Keyword
                </li>


                <li onClick={() => handleClick('chapter51')} 
                className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''}  `}>
                  Final Keyword
                </li>

                <li onClick={() => handleClick('chapter52')} 
                className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''}  `}>
                  Runtime Polymorphism
                </li>

                <li onClick={() => handleClick('chapter53')} 
                className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''}  `}>
                  Dynamic Method Dispatch
                </li>


                <li onClick={() => handleClick('chapter54')} 
                className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''}  `}>
                  instanceof with Polymorphism
                </li>

<h5>Java Abstraction</h5>
                <li onClick={() => handleClick('chapter55')} 
                className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''}  `}>
                  Abstract Class
                </li>

                <li onClick={() => handleClick('chapter56')} 
                className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''}  `}>
                  Interface
                </li>


                <li onClick={() => handleClick('chapter57')} 
                className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''}  `}>
                  Abstract vs Interface
                </li>


<h5>Java Encapsulation</h5>
                <li onClick={() => handleClick('chapter58')} 
                className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''}  `}>
                  Package
                </li>

                <li onClick={() => handleClick('chapter59')} 
                className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''}  `}>
                  Access Modifiers
                </li>

                <li onClick={() => handleClick('chapter60')} 
                className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''}  `}>
                  Encapsulation
                </li>


<h5>Exception Handling</h5>
                <li onClick={() => handleClick('chapter95')} 
                className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''}  `}>
                  Java Exception
                </li>

                <li onClick={() => handleClick('chapter96')} 
                className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''}  `}>
                  Java Try-catch Block
                </li>

                <li onClick={() => handleClick('chapter97')} 
                className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''}  `}>
                  Java Multiple catch Block
                </li>


                <li onClick={() => handleClick('chapter98')} 
                className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''}  `}>
                  Java Nested try
                </li>

                <li onClick={() => handleClick('chapter99')} 
                className={`${style.chapter99} ${activeChapter === 'chapter99' ? style.active : ''}  `}>
                  Java Finally Block
                </li>

                <li onClick={() => handleClick('chapter100')} 
                className={`${style.chapter100} ${activeChapter === 'chapter100' ? style.active : ''}  `}>
                  Java Throw Keyword
                </li>


                <li onClick={() => handleClick('chapter101')} 
                className={`${style.chapter101} ${activeChapter === 'chapter101' ? style.active : ''}  `}>
                  Java Throws Keyword
                </li>



                <li onClick={() => handleClick('chapter102')} 
                className={`${style.chapter102} ${activeChapter === 'chapter102' ? style.active : ''}  `}>
                  Java Throw vs Throws Keyword
                </li>


                <li onClick={() => handleClick('chapter103')} 
                className={`${style.chapter103} ${activeChapter === 'chapter103' ? style.active : ''}  `}>
                  Java Exception Propagation
                </li>



                <li onClick={() => handleClick('chapter104')} 
                className={`${style.chapter104} ${activeChapter === 'chapter104' ? style.active : ''} `}>
                  Final vs Finally vs Finalize
                </li>



                <li onClick={() => handleClick('chapter105')} 
                className={`${style.chapter105} ${activeChapter === 'chapter105' ? style.active : ''} `}>
                  Exception Handling with Method Overriding
                </li>



                <li onClick={() => handleClick('chapter106')} 
                className={`${style.chapter106} ${activeChapter === 'chapter106' ? style.active : ''} `}>
                  Java Custom Exceptions
                </li>


<h5>Java File Handling </h5>
                <li onClick={() => handleClick('chapter107')} 
                className={`${style.chapter107} ${activeChapter === 'chapter107' ? style.active : ''} `}>
                  Java Files
                </li>


                <li onClick={() => handleClick('chapter108')} 
                className={`${style.chapter108} ${activeChapter === 'chapter108' ? style.active : ''} `}>
                  Java Create/Write Files
                </li>

                <li onClick={() => handleClick('chapter109')} 
                className={`${style.chapter109} ${activeChapter === 'chapter109' ? style.active : ''} `}>
                  Java Read Files
                </li>


                <li onClick={() => handleClick('chapter110')} 
                className={`${style.chapter110} ${activeChapter === 'chapter110' ? style.active : ''} `}>
                  Java Delete Files
                </li>


<h5>Java Multithreading</h5>
                <li onClick={() => handleClick('chapter111')} 
                className={`${style.chapter111} ${activeChapter === 'chapter111' ? style.active : ''} `}>
                  Java Multithreading
                </li>


                <li onClick={() => handleClick('chapter112')} 
                className={`${style.chapter112} ${activeChapter === 'chapter112' ? style.active : ''} `}>
                  Java Thread Life Cycle
                </li>


                <li onClick={() => handleClick('chapter113')} 
                className={`${style.chapter113} ${activeChapter === 'chapter113' ? style.active : ''} `}>
                  Java Create Thread
                </li>


                <li onClick={() => handleClick('chapter114')} 
                className={`${style.chapter114} ${activeChapter === 'chapter114' ? style.active : ''} `}>
                  Java Thread Scheduler
                </li>


                <li onClick={() => handleClick('chapter115')} 
                className={`${style.chapter115} ${activeChapter === 'chapter115' ? style.active : ''} `}>
                  Java sleeping a Thread
                </li>


                <li onClick={() => handleClick('chapter116')} 
                className={`${style.chapter116} ${activeChapter === 'chapter116' ? style.active : ''} `}>
                  Java Start a Thread twice
                </li>


                <li onClick={() => handleClick('chapter117')} 
                className={`${style.chapter117} ${activeChapter === 'chapter117' ? style.active : ''} `}>
                  Java run() method
                </li>

                <li onClick={() => handleClick('chapter118')} 
                className={`${style.chapter118} ${activeChapter === 'chapter118' ? style.active : ''} `}>
                  Java Joining a Thread
                </li>

                <li onClick={() => handleClick('chapter119')} 
                className={`${style.chapter119} ${activeChapter === 'chapter119' ? style.active : ''} `}>
                  Java Naming a thread
                </li>

                <li onClick={() => handleClick('chapter120')} 
                className={`${style.chapter120} ${activeChapter === 'chapter120' ? style.active : ''} `}>
                  Java Thread priority
                </li>

                <li onClick={() => handleClick('chapter121')} 
                className={`${style.chapter121} ${activeChapter === 'chapter121' ? style.active : ''} `}>
                  Java Daemon Thread
                </li>

                <li onClick={() => handleClick('chapter122')} 
                className={`${style.chapter122} ${activeChapter === 'chapter122' ? style.active : ''} `}>
                  Java Thread Pool
                </li>

                <li onClick={() => handleClick('chapter123')} 
                className={`${style.chapter123} ${activeChapter === 'chapter123' ? style.active : ''} `}>
                  Java Thread Group
                </li>

                <li onClick={() => handleClick('chapter124')} 
                className={`${style.chapter124} ${activeChapter === 'chapter124' ? style.active : ''} `}>
                  Java Performance multiple task
                </li>

                <li onClick={() => handleClick('chapter125')} 
                className={`${style.chapter125} ${activeChapter === 'chapter125' ? style.active : ''} `}>
                  Java ShutdownHook
                </li>

                <li onClick={() => handleClick('chapter126')} 
                className={`${style.chapter126} ${activeChapter === 'chapter126' ? style.active : ''} `}>
                  Java Garbage Collection
                </li>

                <li onClick={() => handleClick('chapter127')} 
                className={`${style.chapter127} ${activeChapter === 'chapter127' ? style.active : ''} `}>
                  Java Runtime class
                </li>

                

              

</ul>
</div>

<div className={`${style.rightcolumn} col`}>


{/* Chapter Content */}


       
  
  
{selectedChapter === 'chapter1' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction  </h1>



        <p>
          Java is a versatile, high-level programming language that is widely
          used in various fields, from building mobile and web applications to
          enterprise software and embedded systems. Here's a detailed overview:
        </p>

        <br/>

        <h3>1. What is Java?</h3>
        <p>Java is:</p>
        <ul>
          <li>
            <strong>A Programming Language</strong>: Designed to be simple,
            object-oriented, and secure.
          </li><br/>
          <li>
            <strong>Platform Independent</strong>: Code written in Java can run
            on any platform that supports the Java Virtual Machine (JVM), making
            it highly portable.
          </li><br/>
          <li>
            <strong>Concurrent</strong>: Supports multithreading, allowing the
            execution of multiple tasks simultaneously.
          </li><br/>
          <li>
            <strong>Class-based</strong>: Everything in Java revolves around
            classes and objects, following the principles of Object-Oriented
            Programming (OOP).
          </li>
        </ul>

        <br/>

        <h3>2. Key Features of Java</h3>
        <ol>
          <li>
            <strong>Platform Independence</strong>: Java’s “Write Once, Run
            Anywhere” (WORA) philosophy allows Java programs to run on any
            operating system without modification, as long as there is a JVM
            available.
          </li><br/>
          <li>
            <strong>Object-Oriented</strong>: Java is based on OOP principles,
            making it modular, reusable, and easier to maintain.
          </li><br/>
          <li>
            <strong>Robust</strong>: Java includes features like garbage
            collection, exception handling, and strong type-checking, which make
            programs less error-prone.
          </li><br/>
          <li>
            <strong>Secure</strong>: Java provides built-in security features,
            such as bytecode verification, a security manager, and access control.
          </li><br/>
          <li>
            <strong>Multithreading</strong>: Java supports concurrent programming,
            allowing multiple threads to run independently within the same program.
          </li><br/>
          <li>
            <strong>Distributed Computing</strong>: Java simplifies the creation
            of distributed applications using technologies like Remote Method
            Invocation (RMI).
          </li><br/>
          <li>
            <strong>Dynamic and Extensible</strong>: Java can dynamically link new
            class libraries, methods, and objects at runtime.
          </li>
        </ol>

        <br/>

        <h3>3. Components of Java</h3>
        <ul>
          <li>
            <strong>JVM (Java Virtual Machine)</strong>: Acts as the runtime
            environment for Java programs. Converts bytecode (compiled Java code)
            into machine code for the host system.
          </li><br/>
          <li>
            <strong>JRE (Java Runtime Environment)</strong>: Provides the
            libraries and JVM needed to run Java applications.
          </li><br/>
          <li>
            <strong>JDK (Java Development Kit)</strong>: A software development
            kit that includes the JRE, a compiler, and tools like `javac` and
            `java`.
          </li>
        </ul>

        <br/>

        <h3>4. History of Java</h3>
        <ul>
          <li>
            <strong>1991</strong>: Developed by James Gosling and his team at Sun
            Microsystems under the project name "Oak."
          </li><br/>
          <li>
            <strong>1995</strong>: Officially released as "Java."
          </li><br/>
          <li>
            <strong>2010</strong>: Oracle Corporation acquired Sun Microsystems
            and took over Java’s development.
          </li>
        </ul>

        <br/>

        <h3>5. Applications of Java</h3>
        <ul>
          <li>
            <strong>Web Applications</strong>: Using technologies like Servlets,
            JSP, and Spring Framework.
          </li><br/>
          <li>
            <strong>Mobile Applications</strong>: Especially Android apps.
          </li><br/>
          <li>
            <strong>Desktop Applications</strong>: GUI-based applications with
            Swing, AWT, or JavaFX.
          </li><br/>
          <li>
            <strong>Enterprise Software</strong>: Robust back-end systems with
            tools like Java EE and Spring Boot.
          </li><br/>
          <li>
            <strong>Embedded Systems</strong>: From smart cards to Blu-ray
            players.
          </li><br/>
          <li>
            <strong>Game Development</strong>: Popular for lightweight games.
          </li>
        </ul>

        <br/>

        <h3>6. Setting Up Java</h3>
        <p>Steps to Set Up:</p>
        <ol>
          <li>
            <strong>Download JDK</strong>: Download the latest JDK from{' '}
            <a href="https://www.oracle.com/java/technologies/javase-downloads.html" target="_blank" rel="noopener noreferrer">
              Oracle's website
            </a>.
          </li><br/>
          <li><strong>Install JDK</strong>: Follow the installation instructions for your operating system.</li><br/>
          <li>
            <strong>Set Environment Variables</strong>:
            <ul>
              <li>Add the `JAVA_HOME` path.</li><br/>
              <li>Include the `bin` directory in the system’s `PATH`.</li><br/>
            </ul>
          </li><br/>
          <li>
            <strong>Verify Installation</strong>: Run the following commands in the terminal:
            <pre>
              <code>java -version</code>
              <code>javac -version</code>
            </pre>
          </li>
        </ol>

        <br/>

        <h3>7. Java Syntax Basics</h3>
        <p>Here’s a simple Java program:</p>

        <pre>
          <code>
            {`// MyFirstJavaProgram.java
public class MyFirstJavaProgram {
    public static void main(String[] args) {
        System.out.println("Hello, World!"); // Output to the console
    }
}`}
          </code>
        </pre>

        <p><strong>Explanation:</strong></p>
        <ul>
          <li><strong>public class</strong>: Declares a class.</li><br/>
          <li><strong>main method</strong>: Entry point of any Java application.</li><br/>
          <li><strong>System.out.println</strong>: Prints text to the console.</li>
        </ul>

        <br/>

       
      </div>
    )}




{selectedChapter === 'chapter2' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>History and Applications of Java</h1>

        <div>
         
          <p>
            Java's journey began in the early 1990s and has since evolved into
            one of the most widely used programming languages worldwide. Here's
            a detailed look at its history:
          </p>

          <br/>

          <h3>1. The Beginning</h3>
          <ul>
            <li>
              <strong>1991</strong>: A team of developers, led by{' '}
              <strong>James Gosling</strong>, working at{' '}
              <strong>Sun Microsystems</strong>, began a project called{' '}
              <strong>"Oak"</strong>. The project aimed to create a
              platform-independent language for consumer electronics like
              televisions and set-top boxes.
            </li><br/>
            <li>
              The name "Oak" was inspired by an oak tree outside Gosling's
              office.
            </li>
          </ul>

          <br/>

          <h3>2. Renaming and Public Release</h3>
          <ul>
            <li>
              <strong>1995</strong>: The language was renamed{' '}
              <strong>Java</strong>, inspired by a type of coffee popular with
              the developers. Sun Microsystems officially released Java with
              the slogan <strong>"Write Once, Run Anywhere" (WORA)</strong>,
              emphasizing its platform independence.
            </li><br/>
            <li>
              The release coincided with the growth of the internet, making Java
              an ideal choice for web applications due to its portability and
              security features.
            </li>
          </ul>

          <br/>

          <h3>3. Key Milestones in Java's Development</h3>
          <ul>
            <li>
              <strong>1996</strong>: Java Development Kit (JDK) 1.0 was
              released, marking Java's official debut as a programming language.
              It was immediately adopted for web development using{' '}
              <strong>Java applets</strong>.
            </li><br/>
            <li>
              <strong>1998</strong>: The release of <strong>JDK 1.2</strong>{' '}
              introduced significant improvements, such as the{' '}
              <strong>Swing GUI toolkit</strong> and{' '}
              <strong>Collections Framework</strong>.
            </li><br/>
            <li>
              <strong>2004</strong>: <strong>Java 5.0</strong> introduced{' '}
              <strong>generics</strong>, <strong>autoboxing</strong>, and the{' '}
              <strong>enhanced for loop</strong>, making the language more
              modern and developer-friendly.
            </li><br/>
            <li>
              <strong>2009</strong>: Oracle Corporation acquired Sun
              Microsystems, taking over the stewardship of Java.
            </li><br/>
            <li>
              <strong>2011</strong>: <strong>Java 7</strong> introduced features
              like <strong>try-with-resources</strong> and the{' '}
              <strong>diamond operator</strong>.
            </li><br/>
            <li>
              <strong>2014</strong>: <strong>Java 8</strong> became one of the
              most influential releases, introducing{' '}
              <strong>lambda expressions</strong>, <strong>streams</strong>, and
              the new <strong>Date and Time API</strong>.
            </li><br/>
            <li>
              <strong>2018 and Beyond</strong>: Oracle adopted a faster release
              cycle for Java, delivering updates every six months.{' '}
              <strong>Java 11</strong> introduced long-term support (LTS),
              providing stability and new features.
            </li>
          </ul>

          <br/>

          <h3>4. Java Today</h3>
          <p>
            Java remains a dominant force in programming due to its robustness,
            scalability, and versatility. With its continued evolution, Java is
            used in cutting-edge fields like cloud computing, big data, and IoT.
          </p>

          <br/>

          <h2 style={{paddingBottom:"6px"}}>Applications of Java</h2>
          <p>
            Java's versatility and platform independence make it suitable for a
            wide variety of applications. Here’s an overview of where Java
            excels:
          </p><br/>

          <h3>1. Web Applications</h3>
          <p>
            Java is a top choice for building dynamic web applications.
            Frameworks like <strong>Spring</strong>, <strong>Hibernate</strong>,
            and <strong>Struts</strong> simplify the development process. Java
            technologies such as <strong>Servlets</strong> and{' '}
            <strong>JavaServer Pages (JSP)</strong> are widely used for backend
            development.
          </p><br/>

          <h3>2. Mobile Applications*</h3>
          <p>
            Java is the foundation of <strong>Android app development</strong>.
            The <strong>Android SDK</strong> is based on Java, making it
            essential for building Android applications.
          </p><br/>

          <h3>3. Enterprise Applications</h3>
          <p>
            Java dominates the enterprise software market with platforms like{' '}
            <strong>Java EE (Enterprise Edition)</strong>. It powers
            large-scale applications, including banking systems, e-commerce
            platforms, and customer relationship management (CRM) systems.
          </p><br/>

          <h3>4. Desktop Applications</h3>
          <p>
            Java’s frameworks, such as <strong>Swing</strong> and{' '}
            <strong>JavaFX</strong>, enable the development of feature-rich
            desktop applications. These are platform-independent, meaning they
            can run on Windows, macOS, or Linux without modification.
          </p><br/>

          <h3>5. Embedded Systems</h3>
          <p>
            Java is used in <strong>embedded systems</strong>, including Blu-ray
            players, smart cards, and industrial automation systems. Its small
            footprint and portability make it ideal for resource-constrained
            environments.
          </p><br/>

          <h3>6. Game Development</h3>
          <p>
            Java is used for lightweight game development with libraries like{' '}
            <strong>LWJGL (Lightweight Java Game Library)</strong> and{' '}
            <strong>jMonkeyEngine</strong>.
          </p><br/>

          <h3>7. Cloud Computing</h3>
          <p>
            Java is a popular language for building <strong>microservices</strong>{' '}
            and cloud-based applications. Frameworks like{' '}
            <strong>Spring Boot</strong> and platforms like AWS and Azure support
            Java for creating scalable and distributed systems.
          </p><br/>

          <h3>8. Big Data and Analytics</h3>
          <p>
            Tools like <strong>Apache Hadoop</strong> and{' '}
            <strong>Apache Spark</strong> use Java for big data processing and
            analytics.
          </p><br/>

          <h3>9. Internet of Things (IoT)</h3>
          <p>
            Java plays a significant role in IoT development due to its
            portability and ability to run on small devices. It is used in
            applications ranging from smart homes to industrial automation.
          </p><br/>

          <h3>10. Scientific Applications</h3>
          <p>
            Java is used in scientific computing and research for its
            reliability, precision, and large number of available libraries.
          </p>

          <br/>

          <h3>Conclusion</h3>
          <p>
            Java's rich history, combined with its versatility and robustness,
            has made it an essential tool in the software development world. Its
            ability to adapt to new technologies ensures that Java remains
            relevant for a wide variety of applications, from traditional
            enterprise systems to modern innovations like cloud computing and IoT.
          </p>
        </div>
      </div>
    )}





{selectedChapter === "chapter3" && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Features of Java</h1>

          <section>
            <p>
              Java stands out as a powerful, versatile, and widely-used programming language due to its distinctive
              features. These features make Java suitable for various applications, from mobile apps to large-scale
              enterprise systems. Let’s dive into the key features of Java in detail:
            </p>
          </section>

          <br/>

          <section>
            <h2 style={{paddingBottom:"6px"}}>1. Platform Independence</h2>
            <p>
              Java follows the principle of <strong>"Write Once, Run Anywhere" (WORA)</strong>. This means that code
              written in Java can run on any platform that supports the Java Virtual Machine (JVM).
            </p>
            <ul>
              <li>
                The Java compiler converts source code into <strong>bytecode</strong>, a platform-independent format.
              </li><br/>
              <li>
                This bytecode can be executed on any device with a JVM, regardless of the underlying operating system
                (Windows, macOS, Linux, etc.).
              </li>
            </ul>
          </section><br/>

          <section>
            <h2 style={{paddingBottom:"6px"}}>2. Object-Oriented Programming (OOP)</h2>
            <p>
              Java is a fully object-oriented language, meaning everything in Java revolves around objects and classes.
              Core OOP principles in Java include:
            </p>
            <ul>
              <li>
                <strong>Encapsulation</strong>: Bundling data (fields) and methods into classes.
              </li><br/>
              <li>
                <strong>Inheritance</strong>: Reusing code by creating hierarchical relationships between classes.
              </li><br/>
              <li>
                <strong>Polymorphism</strong>: Defining multiple behaviors for the same interface or method.
              </li><br/>
              <li>
                <strong>Abstraction</strong>: Hiding implementation details and exposing only essential features.
              </li>
            </ul>
          </section><br/>

          <section>
            <h2>3. Simplicity</h2>
            <p>
              Java is designed to be easy to learn and use. It eliminates complex features of older programming
              languages like C++:
            </p>
            <ul>
              <li>No pointers (direct memory access is not allowed).</li><br/>
              <li>No multiple inheritance (avoids ambiguity through interfaces).</li><br/>
              <li>Automatic memory management through <strong>Garbage Collection</strong>.</li>
            </ul>
          </section><br/>

          <section>
            <h2>4. Robustness</h2>
            <p>Java ensures reliability and minimizes runtime errors through features like:</p>
            <ul>
              <li>
                <strong>Exception Handling</strong>: Developers can handle runtime errors gracefully.
              </li><br/>
              <li>
                <strong>Garbage Collection</strong>: Automatically removes unused objects from memory, preventing memory
                leaks.
              </li><br/>
              <li>
                <strong>Strong Type Checking</strong>: Java performs strict type checking at compile-time, reducing the
                risk of errors.
              </li>
            </ul>
          </section><br/>

          <section>
            <h2>5. Security</h2>
            <p>
              Java is renowned for its built-in security features, making it a preferred choice for developing secure
              applications:
            </p>
            <ul>
              <li>
                <strong>Bytecode Verification</strong>: Ensures that Java code does not violate access rights or corrupt
                memory.
              </li><br/>
              <li>
                <strong>Security Manager</strong>: Defines access controls for classes.
              </li><br/>
              <li>
                <strong>Sandboxing</strong>: Restricts the execution of untrusted code.
              </li><br/>
              <li>Avoids vulnerabilities like buffer overflows due to the absence of pointers.</li>
            </ul>
          </section><br/>

          <section>
            <h2>6. Multithreading</h2>
            <p>
              Java supports <strong>multithreading</strong>, allowing multiple threads to run concurrently within the
              same program.
            </p>
            <ul>
              <li>Improves performance by utilizing CPU resources effectively.</li><br/>
              <li>Simplifies the development of applications like games, multimedia, and real-time systems.</li><br/>
              <li>
                Java's <code>Thread</code> class and <code>Runnable</code> interface make it easy to implement
                multithreading.
              </li>
            </ul>
          </section><br/>

          <section>
            <h2>7. Portability</h2>
            <p>
              Java is highly portable because its compiled bytecode can run on any system with a JVM. Developers do not
              need to rewrite code for different operating systems.
            </p>
          </section><br/>

          <section>
            <h2>8. High Performance</h2>
            <p>
              Although Java is not as fast as C or C++, its performance is enhanced by features like:
            </p>
            <ul>
              <li>
                <strong>Just-In-Time (JIT) Compilation</strong>: Converts bytecode into native machine code at runtime,
                improving execution speed.
              </li><br/>
              <li>Efficient memory management through garbage collection.</li>
            </ul>
          </section><br/>

          <section>
            <h2>9. Distributed Computing</h2>
            <p>
              Java simplifies the creation of distributed systems, where applications can run across multiple machines.
            </p>
            <ul>
              <li>Features like <strong>Remote Method Invocation (RMI)</strong> and <strong>JAX-WS</strong>.</li><br/>
              <li>Enables seamless communication between systems over a network.</li>
            </ul>
          </section><br/>

          <section>
            <h2>10. Dynamic and Extensible</h2>
            <p>
              Java is dynamic because it supports the loading of classes at runtime and allows changes to libraries
              without affecting the application. Developers can extend Java programs easily by integrating new classes,
              libraries, and methods.
            </p>
          </section><br/>

          <section>
            <h2>11. Architecture Neutral</h2>
            <p>
              Java programs are not tied to a specific processor or system architecture. The bytecode is designed to be
              neutral and interpretable by any JVM implementation.
            </p>
          </section><br/>

          <section>
            <h2>12. Rich Standard Library</h2>
            <p>
              Java provides an extensive set of prebuilt libraries for tasks like:
            </p>
            <ul>
              <li>Networking (e.g., <code>java.net</code>).</li><br/>
              <li>Data structures (e.g., <code>java.util</code>).</li><br/>
              <li>Input/Output operations (e.g., <code>java.io</code>).</li><br/>
              <li>Graphics and GUI development (e.g., <code>java.awt</code> and <code>javax.swing</code>).</li><br/>
              <li>Concurrent programming (e.g., <code>java.util.concurrent</code>).</li>
            </ul>
          </section><br/>

          <section>
            <h2>Conclusion</h2>
            <p>
              The features of Java make it a robust, versatile, and developer-friendly language, suitable for a wide
              array of applications, from mobile development to large-scale enterprise solutions. Its combination of
              platform independence, security, scalability, and simplicity ensures that Java remains a popular choice
              for developers worldwide.
            </p>
          </section>
        </div>
)}
   
  


{selectedChapter === 'chapter4' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   Basic Syntax  </h1>

      <p>To write a basic Java program that prints output, you need to understand the fundamental syntax. Here's a step-by-step guide for writing and understanding a simple Java program to print something on the console:</p>
      <br/>
      <h3>Basic Java Program Syntax</h3>
      <p>A basic Java program consists of the following key parts:</p>
      <ol>
        <li><strong>Class Declaration</strong>: In Java, every program is part of a class.</li><br/>
        <li><strong>Main Method</strong>: The <code>main</code> method is where the program starts execution. It is the entry point of a Java application.</li><br/>
        <li><strong>Statement to Print Output</strong>: To display something on the console, we use <code>System.out.println()</code>.</li>
      </ol><br/>

      <h3>Example: Basic Java Program</h3>
      <pre>
        <code>
{`public class Main {
    public static void main(String[] args) {
        System.out.println("Hello, World!"); // This prints the text to the console
    }
}`}
        </code>
      </pre><br/>

      <h3>Explanation of the Code:</h3>
      <p>
        <strong>1. <code>public class HelloWorld</code></strong><br /><br/>
        - <code>public</code>: This is an access modifier that means the class is accessible from outside.<br /><br/>
        - <code>class HelloWorld</code>: This declares a class named <code>HelloWorld</code>. In Java, every application must have at least one class.
      </p><br/>

      <p>
        <strong>2. <code>public static void main(String[] args)</code></strong><br /><br/>
        - <code>public</code>: Makes the <code>main</code> method accessible from outside the class.<br /><br/>
        - <code>static</code>: Means that the method can be called without creating an instance of the class.<br /><br/>
        - <code>void</code>: Means this method does not return any value.<br /><br/>
        - <code>main</code>: The method name that tells the JVM where the program starts execution.<br /><br/>
        - <code>String[] args</code>: This allows the method to accept command-line arguments (though we won’t use them in this simple program).
      </p><br/><br/>

      <p>
        <strong>3. <code>System.out.println("Hello, World!");</code></strong><br /><br/>
        - <code>System</code>: Refers to the <code>System</code> class in the <code>java.lang</code> package.<br /><br/>
        - <code>out</code>: Refers to the <code>PrintStream</code> object associated with the standard output (the console).<br /><br/>
        - <code>println()</code>: This method is used to print the text within the parentheses to the console, followed by a new line.
      </p>
      <br/>
      <h3 style={{paddingBottom:"6px"}}> How to Run the Program:</h3>
      <ol>
        <li>Save the program in a file named <code>HelloWorld.java</code>.</li><br/>
        <li>Compile the program using the Java compiler:
          <pre>
            <code>javac HelloWorld.java</code>
          </pre>
        </li><br/>
        <li>Run the compiled program using the Java interpreter:
          <pre>
            <code>java HelloWorld</code>
          </pre>
        </li>
      </ol><br/>

      <h3>Output:</h3>
      <pre>
        <code>Hello, World!</code>
      </pre><br/>

      <h3>Conclusion</h3>
      <p>
        This is the simplest structure of a Java program. The key takeaway is that:</p>
        <ul>
          <li>A Java program is written inside a class.</li><br/>
          <li>The <code>main</code> method is the entry point of the program.</li><br/>
          <li>The <code>System.out.println()</code> is used to print text to the console.</li>
        </ul><br/>
       <p> This basic syntax serves as the foundation for writing more complex Java programs.
      </p>
    </div>
  )}



{selectedChapter === 'chapter5' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   Comments   </h1>

  
      <p>In Java, comments are lines of text that the compiler ignores. They are used to make the code more readable and understandable for developers.</p>
      <br/>
      <h2>Types of Comments in Java</h2>

      <h3>1. Single-line Comments</h3>
      <p>
        Used for brief explanations or notes. Begins with <code>//</code>. Everything after <code>//</code> on the same line is ignored by the compiler.
      </p>
      <pre>
        <code>
{`// This is a single-line comment
System.out.println("Hello, World!"); // Prints a message to the console`}
        </code>
      </pre><br/>

      <h3>2. Multi-line Comments</h3>
      <p>
        Used for longer explanations or documentation. Starts with <code>/*</code> and ends with <code>*/</code>. Everything between these delimiters is ignored by the compiler.
      </p>
      <pre>
        <code>
{`/*
 This is a multi-line comment.
 It spans across multiple lines.
*/
System.out.println("Welcome to Java!");`}
        </code>
      </pre><br/>

      <h3>3. Documentation Comments</h3>
      <p>
        Used to create API documentation using the <code>javadoc</code> tool. Starts with <code>/**</code> and ends with <code>*/</code>.
      </p>
      <p>Often includes tags like <code>@param</code>, <code>@return</code>, and <code>@author</code>.</p>
      <pre>
        <code>
{`/**
 * This is a documentation comment.
 * @author Amit
 * @version 1.0
 * @since 2024
 */
public class Main {
    public static void main(String[] args) {
        System.out.println("Hello, Java Docs!");
    }
}`}
        </code>
      </pre><br/>

      <h2>Why Use Comments?</h2>
      <ul>
        <li><strong>Improves Code Readability:</strong> Explains what the code does, making it easier for others (or yourself) to understand later.</li><br/>
        <li><strong>Debugging Helper:</strong> Comments can temporarily disable lines of code during debugging.</li><br/>
        <li><strong>Documentation:</strong> Generates useful API documentation for larger projects using <code>javadoc</code>.</li>
      </ul><br/>
    </div>
  )}



{selectedChapter === 'chapter6' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>How to Set Path </h1>

    <p>To run Java programs from the command line (outside an IDE), you need to set the <strong>PATH</strong> environment variable. The <strong>PATH</strong> variable tells the operating system where to look for executables like the Java compiler (<code>javac</code>) and Java runtime (<code>java</code>). Here’s a detailed guide on how to set the Java path for different operating systems.</p>
    <br/>
    <h2>Steps to Set Java Path</h2>

    <h3 style={{paddingBottom:"6px"}}>1. Find the Java Installation Directory</h3>
    <p>First, make sure Java is installed on your system. You need to know where Java is installed in order to set the path correctly.</p>

    <ul>
      <li><strong>Windows:</strong> The default installation directory for Java is usually <code>{`C:\Program Files\Java\jdk-<version>`}</code>.</li><br/>
      <li><strong>Mac:</strong> Java is usually installed in <code>{`/Library/Java/JavaVirtualMachines/jdk-<version>.jdk/Contents/Home`}</code>.</li><br/>
      <li><strong>Linux:</strong> The Java installation directory can be <code>{`/usr/lib/jvm/java-<version>`}</code>.</li>
    </ul><br/>

    <p>If you're unsure of the installation path, you can check it using these commands:</p>

    <ul>
      <li><strong>Windows:</strong> Open Command Prompt and type:
        <pre>where java</pre>
      </li><br/>
      <li><strong>Mac/Linux:</strong> Open Terminal and type:
        <pre>which java</pre>
      </li>
    </ul><br/>

    <h3 style={{paddingBottom:"6px"}}>2. Set the Path on Different Operating Systems</h3>

    <h4>Windows</h4>
    <ol>
      <li><strong>Locate the Java installation directory:</strong>
        <p>For example: <code>C:\Program Files\Java\jdk-11.0.1\bin</code></p>
      </li><br/>
      <li><strong>Set the PATH environment variable:</strong>
        <ul>
          <li>Right-click on <code>This PC</code> (or <code>Computer</code>) and select <strong>Properties</strong>.</li><br/>
          <li>Click on <strong>Advanced system settings</strong> on the left.</li><br/>
          <li>In the System Properties window, click on the <strong>Environment Variables</strong> button.</li><br/>
          <li>In the Environment Variables window, under the System variables section, find and select the <strong>Path</strong> variable, then click <strong>Edit</strong>.</li><br/>
          <li>In the Edit environment variable window, click <strong>New</strong> and add the path to the <code>bin</code> directory of your Java installation (e.g., <code>C:\Program Files\Java\jdk-11.0.1\bin</code>).</li><br/>
          <li>Click <strong>OK</strong> to close all windows.</li>
        </ul><br/>
      </li>
      <li><strong>Verify the Java Path:</strong>
        <p>Open a new Command Prompt window and type:
          <pre>java -version</pre>
        </p>
      </li>
    </ol><br/>

    <h4 style={{paddingBottom:"6px"}}>MacOS</h4>
    <ol>
      <li><strong>Locate the Java installation directory:</strong>
        <p>Open Terminal and type:
          <pre>/usr/libexec/java_home -v 1.8</pre>
        </p>
      </li><br/>
      <li><strong>Set the PATH environment variable:</strong>
        <ul>
          <li>Open Terminal and edit the <code>.bash_profile</code> (or <code>.zshrc</code> if you're using Zsh) file:
            <pre>nano ~/.bash_profile</pre>
          </li><br/>
          <li>Add the following line (adjust the path based on your Java version):
            <pre>export PATH=$PATH:/Library/Java/JavaVirtualMachines/jdk-11.0.1.jdk/Contents/Home/bin</pre>
          </li><br/>
          <li>Save and exit the editor by pressing <code>CTRL + X</code>, then <code>Y</code> to confirm, and <code>Enter</code>.</li>
        </ul><br/>
      </li>
      <li><strong>Apply the changes:</strong>
        <pre>source ~/.bash_profile</pre>
      </li><br/>
      <li><strong>Verify the Java Path:</strong>
        <pre>java -version</pre>
      </li>
    </ol><br/>

    <h4>Linux</h4>
    <ol>
      <li><strong>Locate the Java installation directory:</strong>
        <pre>update-alternatives --config java</pre>
      </li><br/>
      <li><strong>Set the PATH environment variable:</strong>
        <ul>
          <li>Open a terminal and edit the <code>.bashrc</code> file (or <code>.bash_profile</code> depending on your shell):
            <pre>nano ~/.bashrc</pre>
          </li><br/>
          <li>Add the following line (adjust the path based on your installation):
            <pre>export PATH=$PATH:/usr/lib/jvm/java-11-openjdk-amd64/bin</pre>
          </li>
        </ul><br/>
      </li>
      <li><strong>Apply the changes:</strong>
        <pre>source ~/.bashrc</pre>
      </li><br/>
      <li><strong>Verify the Java Path:</strong>
        <pre>java -version</pre>
      </li>
    </ol><br/>

    <h3 style={{paddingBottom:"6px"}}>Setting the JAVA_HOME Variable</h3>
    <p>In addition to setting the <strong>PATH</strong>, it’s also a good practice to set the <strong>JAVA_HOME</strong> environment variable. This variable points to the Java installation directory and is used by many Java applications.</p>
    <br/>
    <h4>1. Set JAVA_HOME on Windows</h4>
    <ol>
      <li>Open <strong>Environment Variables</strong> (as described above).</li><br/>
      <li>Click on <strong>New</strong> under <strong>System variables</strong>.</li><br/>
      <li>Set the <strong>Variable name</strong> as <code>JAVA_HOME</code> and the <strong>Variable value</strong> as the Java installation directory (e.g., <code>C:\Program Files\Java\jdk-11.0.1</code>).</li><br/>
      <li>Click <strong>OK</strong> to save.</li>
    </ol><br/>

    <h4>2. Set JAVA_HOME on Mac/Linux</h4>
    <ol>
      <li><h6>Open the <code>.bash_profile</code> (or <code>.zshrc</code> for Zsh) and add the following line:</h6>
        <pre>export JAVA_HOME=/Library/Java/JavaVirtualMachines/jdk-11.0.1.jdk/Contents/Home</pre>
      </li><br/>
      <li>Save the file and run:
        <pre>source ~/.bash_profile</pre>
      </li>
    </ol><br/>

    <h3>Conclusion</h3>
    <p>Once you've set the <code>PATH</code> and <code>JAVA_HOME</code> variables, your system will be ready to compile and run Java programs from the command line. Setting these paths ensures that you can execute Java tools like the <code>javac</code> compiler and the <code>java</code> interpreter from any directory in the terminal or command prompt.</p>

   
  </div>
)}



{selectedChapter === 'chapter7' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>JDK, JRE, and JVM</h1>

    <p>
      In Java, the terms <strong>JDK</strong>, <strong>JRE</strong>, and <strong>JVM</strong> are commonly used, and they refer to different components of the Java programming environment. Let's break down each one in detail:
    </p><br/>

    <h2>1. JVM (Java Virtual Machine)</h2>
    <p>
      The <strong>JVM</strong> is the engine that runs Java bytecode. It is the core part of the Java Runtime Environment (JRE) and is responsible for converting the compiled Java code (bytecode) into machine code that the host machine can execute.
    </p><br/>

    <h3>Key Points about JVM:</h3>
    <ul>
      <li><strong>Platform Independence:</strong> The JVM allows Java to be platform-independent, which means you can write a Java program on one system, and it will run on any other system that has the JVM installed.</li><br/>
      <li><strong>Bytecode Execution:</strong> When you compile a Java program, it is compiled into an intermediate form called bytecode (not directly machine code). The JVM interprets and runs this bytecode on the host machine.</li><br/>
      <li><strong>Memory Management:</strong> JVM handles memory management, including garbage collection, which automatically frees up memory that is no longer in use by the program.</li><br/>
      <li><strong>JVM Features:</strong>
        <ul>
          <li><strong>Just-In-Time (JIT) Compilation:</strong> The JVM uses JIT to optimize the performance of Java programs by compiling bytecode into native machine code at runtime.</li><br/>
          <li><strong>Garbage Collection:</strong> Automatic memory management that ensures unused objects are removed from memory to prevent memory leaks.</li>
        </ul><br/>
      </li>
    </ul><br/>

    <h3>JVM Architecture:</h3>
    <ul>
      <li><strong>Class Loader:</strong> Loads .class files (bytecode) into memory.</li><br/>
      <li><strong>Execution Engine:</strong> Executes the bytecode.</li><br/>
      <li><strong>Garbage Collector:</strong> Reclaims memory from objects that are no longer needed.</li>
    </ul><br/>

    <h2>2. JRE (Java Runtime Environment)</h2>
    <p>
      The <strong>JRE</strong> is a software package that includes everything needed to run Java applications but does not include the tools required for developing them.
    </p><br/>

    <h3>Key Points about JRE:</h3>
    <ul>
      <li><strong>Includes JVM:</strong> The JRE includes the JVM along with a set of libraries and other files required to run Java programs.</li><br/>
      <li><strong>No Development Tools:</strong> While it has everything required to execute Java programs, it does not have development tools like compilers or debuggers.</li><br/>
      <li><strong>Used by End Users:</strong> JRE is used by end users to run Java applications. It is typically installed on a machine where Java applications need to be run but not developed.</li>
    </ul><br/>

    <h3>Components of JRE:</h3>
    <ul>
      <li><strong>JVM:</strong> As mentioned, the JVM is a core component of the JRE.</li><br/>
      <li><strong>Java Class Libraries:</strong> A set of libraries that provide commonly used functionality in Java applications, such as input/output (I/O), networking, and utilities.</li><br/>
      <li><strong>Java Native Interface (JNI):</strong> Allows Java code to interact with code written in other languages like C or C++.</li>
    </ul><br/>

    <p>In summary, the JRE is what you need to <strong>run</strong> Java applications.</p>
    <br/>
    <h2>3. JDK (Java Development Kit)</h2>
    <p>
      The <strong>JDK</strong> is a full-featured software development kit that is required for Java development. It includes everything provided by the JRE, as well as development tools needed to write Java programs.
    </p><br/>

    <h3>Key Points about JDK:</h3>
    <ul>
      <li><strong>Includes JRE:</strong> Since you need the JRE to run Java applications, the JDK includes it. However, it also provides additional tools required for Java development.</li><br/>
      <li><strong>Development Tools:</strong> The JDK includes essential tools such as:
        <ul>
          <li><strong>javac:</strong> The Java compiler that compiles your source code (written in `.java` files) into bytecode (stored in `.class` files).</li><br/>
          <li><strong>Javadoc:</strong> A tool to generate API documentation from Java source code.</li><br/>
          <li><strong>Java Debugger (jdb):</strong> A tool for debugging Java programs.</li><br/>
          <li><strong>Java Profiler:</strong> To analyze performance.</li>
        </ul><br/>
      </li>
      <li><strong>Used by Developers:</strong> The JDK is what Java developers need to write and compile Java programs. It's installed on developer machines.</li>
    </ul><br/>

    <h3>Components of JDK:</h3>
    <ul>
      <li><strong>JRE:</strong> The JDK includes the JRE to provide everything needed to run Java applications.</li><br/>
      <li><strong>Compiler (javac):</strong> Compiles Java source code into bytecode.</li><br/>
      <li><strong>Libraries:</strong> The JDK includes Java class libraries like the JRE, plus additional development libraries.</li><br/>
      <li><strong>Tools:</strong> Development tools like debuggers, profilers, etc.</li>
    </ul><br/>

    <h2>Difference Between JVM, JRE, and JDK</h2>
    <table className={style.differenceTable}>
      <thead>
        <tr>
          <th>Component</th>
          <th>Purpose</th>
          <th>Included Components</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>JVM</strong> (Java Virtual Machine)</td>
          <td>Executes Java bytecode.</td>
          <td>Converts bytecode to machine code.</td>
        </tr>
        <tr>
          <td><strong>JRE</strong> (Java Runtime Environment)</td>
          <td>Provides the environment to run Java applications.</td>
          <td>Includes JVM and necessary libraries to run Java programs.</td>
        </tr>
        <tr>
          <td><strong>JDK</strong> (Java Development Kit)</td>
          <td>Provides tools for Java development.</td>
          <td>Includes JRE, JVM, compiler (javac), and development tools (e.g., debuggers, profilers).</td>
        </tr>
      </tbody>
    </table><br/>

    <h2>Conclusion</h2>
    <p>
      <strong>JVM:</strong> The engine that runs Java bytecode.<br /><br/>
      <strong>JRE:</strong> The environment that includes the JVM and other libraries to run Java programs.<br /><br/>
      <strong>JDK:</strong> A complete development kit that includes everything in the JRE, plus tools to write, compile, and debug Java code.
    </p><br/>
    <p>
      To sum it up, if you're just running Java programs, you'll need the <strong>JRE</strong>. If you're a developer, you'll need the <strong>JDK</strong>, which includes everything in the JRE along with development tools. The <strong>JVM</strong> is the heart of both the JRE and the JDK, enabling the execution of Java applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter8' && (
  <div className={style.chapterContent}>
    <h1 className={style.heading}>Java Variables</h1>

    <p>In Java, <strong>variables</strong> are used to store data that can be referenced and manipulated in a program. Every variable in Java has a <strong>type</strong>, a <strong>name</strong>, and a <strong>value</strong>. Here’s a detailed breakdown:</p>
    <br/>
    <h2>1. Types of Variables</h2>
    <p>Java has several types of variables, which can be categorized into <strong>primitive</strong> and <strong>reference</strong> types.</p>

    <h3>Primitive Variables</h3>
    <p>These variables hold simple data values directly. The 8 primitive types in Java are:</p>
    <ul>
      <li><strong>byte</strong>: A 8-bit integer. Range: -128 to 127.</li><br/>
      <li><strong>short</strong>: A 16-bit integer. Range: -32,768 to 32,767.</li><br/>
      <li><strong>int</strong>: A 32-bit integer. Range: -2,147,483,648 to 2,147,483,647.</li><br/>
      <li><strong>long</strong>: A 64-bit integer. Range: -9,223,372,036,854,775,808 to 9,223,372,036,854,775,807 .</li><br/>
      <li><strong>float</strong>: A 32-bit floating-point number. Used for decimal values with single precision.</li><br/>
      <li><strong>double</strong>: A 64-bit floating-point number. Used for decimal values with double precision.</li><br/>
      <li><strong>char</strong>: A single 16-bit Unicode character. Can store a character like 'A', '1', or even special characters.</li><br/>
      <li><strong>boolean</strong>: Can hold only two values: <code>true</code> or <code>false</code>.</li>
    </ul><br/>

    <h3>Reference Variables</h3>
    <p>These variables store references (or memory addresses) to objects. Unlike primitive variables, they do not hold the actual data but a reference to the data in memory. Examples include:</p>
    <ul>
      <li><strong>String</strong>: A sequence of characters.</li><br/>
      <li><strong>Arrays</strong>: A collection of similar elements, like <code>int[]</code> or <code>String[]</code>.</li><br/>
      <li><strong>Objects</strong>: Instances of classes.</li>
    </ul><br/>

    <h2>2. Variable Declaration</h2>
    <p>In Java, a variable must be declared before it is used. A declaration specifies the type of the variable and its name.</p>
    <pre><code>int age; // Declaration of an integer variable
String name; // Declaration of a String variable</code></pre>
<br/>
    <h2>3. Variable Initialization</h2>
    <p>After declaring a variable, you can assign it a value. This is called initialization.</p>
    <pre><code>int age = 25; // Declaration and initialization in one line
String name = "Altaf";</code></pre>
    <p>You can also initialize variables later:</p>
    <pre><code>int age;
age = 25; // Initialization after declaration</code></pre>
<br/>
    <h2>4. Variable Scope</h2>
    <p>The <strong>scope</strong> of a variable defines where in the code it can be accessed. Java variables can be categorized based on their scope:</p>
    <br/>
    <h3>Local Variables</h3>
    <p>Declared within a method, constructor, or block. They are only accessible within that method or block.</p>
    <pre><code>{`public void greet() {
  String greeting = "Hello"; // local variable
  System.out.println(greeting);
}`}</code></pre>
<br/>
    <h3>Instance Variables (Non-static fields)</h3>
    <p>Declared inside a class but outside any method. Each instance (object) of the class has its own copy of the variable.</p>
    <pre><code>{`public class Person {
  String name; // Instance variable
  int age;     // Instance variable
}`}</code></pre>
<br/>
    <h3>Class Variables (Static fields)</h3>
    <p>Declared as <code>static</code> inside a class. They are shared among all instances of the class.</p>
    <pre><code>{`public class Counter {
  static int count = 0; // Class variable (shared among all instances)
}`}</code></pre>
<br/>
    <h2>5. Naming Conventions</h2>
    <p>Variable names in Java follow certain rules and conventions:</p>
    <ul>
      <li>Must start with a letter, underscore (<code>_</code>), or dollar sign (<code>$</code>).</li><br/>
      <li>The remaining characters can include letters, digits, underscores, and dollar signs.</li><br/>
      <li>Cannot use Java reserved keywords (e.g., <code>int</code>, <code>for</code>, <code>class</code>).</li>
    </ul><br/>
    <p>By convention:</p>
    <ul>
      <li><strong>Local variables</strong>: Camel case, starting with a lowercase letter (e.g., <code>userAge</code>).</li><br/>
      <li><strong>Instance and class variables</strong>: Also camel case, but starting with a lowercase letter (e.g., <code>totalAmount</code>).</li><br/>
      <li><strong>Constants</strong>: Uppercase letters with underscores separating words (e.g., <code>MAX_SPEED</code>).</li>
    </ul><br/>

    <h2>6. Default Values</h2>
    <p>When you declare a variable but do not explicitly initialize it, Java provides default values for variables based on their type:</p>
    <ul>
      <li><strong>Primitive types</strong>:
        <ul>
          <li><code>int</code>, <code>short</code>, <code>byte</code>, <code>long</code> → <code>0</code></li><br/>
          <li><code>float</code>, <code>double</code> → <code>0.0</code></li><br/>
          <li><code>char</code> → <code>'\u0000'</code> (null character)</li><br/>
          <li><code>boolean</code> → <code>false</code></li>
        </ul><br/>
      </li>
      <li><strong>Reference types</strong> (like <code>String</code>, <code>Object</code>): <code>null</code></li>
    </ul><br/>
    <pre><code>int number; // Default value is 0
String text; // Default value is null</code></pre>
<br/>
    <h2>7. Constants</h2>
    <p>A constant is a variable whose value cannot be changed once it has been assigned. In Java, constants are declared using the <code>final</code> keyword.</p>
    <pre><code>final int MAX_SPEED = 120; // Constant, value cannot be changed</code></pre>
    <br/>
    <h2>8. Type Casting</h2>
    <p>In some cases, you may need to convert one type of variable to another. This is called <strong>type casting</strong>. Java supports both <strong>implicit</strong> and <strong>explicit</strong> casting.</p>
    <br/>
    <h3>Implicit casting (Widening)</h3>
    <p>Automatic conversion of smaller data types to larger ones.</p>
    <pre><code>int a = 100;
long b = a; // Implicit casting from int to long</code></pre>
<br/>
    <h3>Explicit casting (Narrowing)</h3>
    <p>When converting a larger data type to a smaller one, you must cast explicitly.</p>
    <pre><code>double x = 10.5;
int y = (int) x; // Explicit casting from double to int</code></pre>
<br/>
    <h2>9. Static vs Instance Variables</h2>
    <p><strong>Static variables</strong>: Belong to the class, not instances. They are shared among all instances of the class.</p>
    <p><strong>Instance variables</strong>: Each object has its own copy of the variable.</p>
    <pre><code>{`class Car {
  static int wheels = 4; // Static variable
  String color;           // Instance variable
}`}</code></pre>
<br/>
    <h2>10. Final Variables</h2>
    <p>When a variable is declared as <code>final</code>, its value cannot be changed once it has been assigned. This is useful for constants.</p>
    <pre><code>final int MAX_USERS = 100; // This value cannot be changed</code></pre>
    <br/>
   
  </div>
)}



{selectedChapter === 'chapter9' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java DataTypes   </h1>


      <p>
        In Java, a <strong>data type</strong> defines the type of a variable and the operations that can be performed on it. Java is a statically typed language, meaning that every variable must be declared with a data type. The data types in Java can be divided into two categories: <strong>primitive data types</strong> and <strong>reference data types</strong>.
      </p><br/>

      <h2>1. <strong>Primitive Data Types</strong></h2>

      <p>These are the most basic data types in Java and represent simple values. Java has 8 primitive data types:</p>
      <br/>
      <h3>a. <strong>byte</strong></h3>
      <p>
        <strong>Description:</strong> A byte is an 8-bit signed integer.<br />
        <strong>Range:</strong> -128 to 127<br />
        <strong>Default Value:</strong> 0<br />
        <strong>Usage:</strong> Typically used when you need to save memory in large arrays, where the memory savings are most needed.<br />
        <strong>Example:</strong>
        <pre><code>byte b = 100;</code></pre>
      </p><br/>

      <h3>b. <strong>short</strong></h3>
      <p>
        <strong>Description:</strong> A short is a 16-bit signed integer.<br />
        <strong>Range:</strong> -32,768 to 32,767<br />
        <strong>Default Value:</strong> 0<br />
        <strong>Usage:</strong> Used when memory savings are important, but the byte type is too small.<br />
        <strong>Example:</strong>
        <pre><code>short s = 3000;</code></pre>
      </p><br/>

      <h3>c. <strong>int</strong></h3>
      <p>
        <strong>Description:</strong> An int is a 32-bit signed integer.<br />
        <strong>Range:</strong> -2^31 to 2^31-1 (approximately -2 billion to 2 billion)<br />
        <strong>Default Value:</strong> 0<br />
        <strong>Usage:</strong> The most commonly used data type for integer values.<br />
        <strong>Example:</strong>
        <pre><code>int num = 500;</code></pre>
      </p><br/>

      <h3>d. <strong>long</strong></h3>
      <p>
        <strong>Description:</strong> A long is a 64-bit signed integer.<br />
        <strong>Range:</strong> -2^63 to 2^63-1 (extremely large numbers)<br />
        <strong>Default Value:</strong> 0L (suffix L indicates a long literal)<br />
        <strong>Usage:</strong> Used when an int type is too small to hold a large value.<br />
        <strong>Example:</strong>
        <pre><code>long distance = 15000000000L;</code></pre>
      </p><br/>

      <h3>e. <strong>float</strong></h3>
      <p>
        <strong>Description:</strong> A float is a 32-bit floating point number.<br />
        <strong>Range:</strong> Approximately 3.40282347E+38F (positive or negative)<br />
        <strong>Default Value:</strong> 0.0f<br />
        <strong>Usage:</strong> Used for single-precision floating-point numbers.<br />
        <strong>Example:</strong>
        <pre><code>float price = 19.99f;</code></pre>
      </p><br/>

      <h3>f. <strong>double</strong></h3>
      <p>
        <strong>Description:</strong> A double is a 64-bit floating point number.<br />
        <strong>Range:</strong> Approximately 1.7976931348623157E+308 (positive or negative)<br />
        <strong>Default Value:</strong> 0.0d<br />
        <strong>Usage:</strong> Used for double-precision floating-point numbers and is the default data type for decimal values.<br />
        <strong>Example:</strong>
        <pre><code>double pi = 3.14159;</code></pre>
      </p><br/>

      <h3>g. <strong>char</strong></h3>
      <p>
        <strong>Description:</strong> A char is a 16-bit Unicode character.<br />
        <strong>Range:</strong> 0 to 65,535 (character values)<br />
        <strong>Default Value:</strong> '\u0000' (null character)<br />
        <strong>Usage:</strong> Used to store a single character. It can represent any character from any language, symbol, or punctuation mark.<br />
        <strong>Example:</strong>
        <pre><code>char letter = 'A';</code></pre>
      </p><br/>

      <h3>h. <strong>boolean</strong></h3>
      <p>
        <strong>Description:</strong> A boolean represents a logical value.<br />
        <strong>Values:</strong> true or false<br />
        <strong>Default Value:</strong> false<br />
        <strong>Usage:</strong> Used to store true/false values for decision-making and control flow.<br />
        <strong>Example:</strong>
        <pre><code>boolean isJavaFun = true;</code></pre>
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>2. <strong>Reference Data Types</strong></h2>

      <p>Reference types are used to store references (or addresses) to objects. They don’t store the actual data but instead store the memory location where the data is stored. The reference data types include classes, arrays, and interfaces.</p>

      <h3>a. <strong>String</strong></h3>
      <p>
        <strong>Description:</strong> A String is a sequence of characters (a reference type).<br />
        <strong>Default Value:</strong> null<br />
        <strong>Usage:</strong> Used to store textual data.<br />
        <strong>Example:</strong>
        <pre><code>String name = "Altaf";</code></pre>
      </p><br/>

      <h3>b. <strong>Arrays</strong></h3>
      <p>
        <strong>Description:</strong> An array is a collection of variables of the same type, stored in contiguous memory locations.<br />
        <strong>Default Value:</strong> null for arrays of objects, and default values for arrays of primitive types (e.g., 0 for int, false for boolean).<br />
        <strong>Usage:</strong> Used to store multiple values in a single variable.<br />
        <strong>Example:</strong>
        <pre><code>{`int[] numbers = {1, 2, 3};`}</code></pre>
      </p><br/>

      <h3>c. <strong>Objects</strong></h3>
      <p>
        <strong>Description:</strong> Objects are instances of classes and are used to store data and methods that operate on the data.<br />
        <strong>Default Value:</strong> null<br />
        <strong>Usage:</strong> Used to create user-defined types using classes.<br />
        <strong>Example:</strong>
        <pre><code>{`class Car {" "}
        String make;
        String model;
        int year;
        }

        Car myCar = new Car();
        myCar.make = "Toyota";
        myCar.model = "Camry";
        myCar.year = 2021;`}</code></pre>
      </p>

      {/* Continue with the rest of the content */}
    </div>
  )}


{selectedChapter === 'chapter10' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Operators  </h1>
      
   
        <p>
          In Java, <strong>operators</strong> are special symbols or keywords used to perform operations on variables and values.
          Java provides a rich set of operators that can be categorized based on their functionality. Here's a detailed explanation of each category of operators in Java:
        </p><br/>

        {/* 1. Arithmetic Operators */}
        <h2>1. <strong>Arithmetic Operators</strong></h2>
        <p>
          These operators are used to perform basic mathematical operations such as addition, subtraction, multiplication, division, and modulus.
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>+</td>
              <td>Addition</td>
              <td>5 + 3</td>
              <td>8</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Subtraction</td>
              <td>5 - 3</td>
              <td>2</td>
            </tr>
            <tr>
              <td>*</td>
              <td>Multiplication</td>
              <td>5 * 3</td>
              <td>15</td>
            </tr>
            <tr>
              <td>/</td>
              <td>Division</td>
              <td>5 / 2</td>
              <td>2 (integer division)</td>
            </tr>
            <tr>
              <td>%</td>
              <td>Modulus (remainder)</td>
              <td>5 % 2</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>int a = 10, b = 4;</code><br />
          <code>int sum = a + b;      // sum = 14</code><br />
          <code>int diff = a - b;     // diff = 6</code><br />
          <code>int product = a * b;  // product = 40</code><br />
          <code>int quotient = a / b; // quotient = 2</code><br />
          <code>int remainder = a % b; // remainder = 2</code>
        </p>
        <br/>
        {/* 2. Relational Operators */}
        <h2>2. <strong>Relational Operators</strong></h2>
        <p>
          Relational operators are used to compare two values or expressions. They return a boolean result (<code>true</code> or <code>false</code>).
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>==</td>
              <td>Equal to</td>
              <td>5 == 3</td>
              <td>false</td>
            </tr>
            <tr>
              <td>!=</td>
              <td>Not equal to</td>
              <td>5 != 3</td>
              <td>true</td>
            </tr>
            <tr>
              <td>&gt;</td>
              <td>Greater than</td>
              <td>5 &gt; 3</td>
              <td>true</td>
            </tr>
            <tr>
              <td>&lt;</td>
              <td>Less than</td>
              <td>5 &lt; 3</td>
              <td>false</td>
            </tr>
            <tr>
              <td>&gt;=</td>
              <td>Greater than or equal to</td>
              <td>5 &gt;= 5</td>
              <td>true</td>
            </tr>
            <tr>
              <td>&lt;=</td>
              <td>Less than or equal to</td>
              <td>5 &lt;= 3</td>
              <td>false</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>int a = 10, b = 5;</code><br />
          <code>boolean result = a &gt; b;  // true</code><br />
          <code>result = a == b;         // false</code>
        </p>
        <br/>
        {/* 3. Logical Operators */}
        <h2>3. <strong>Logical Operators</strong></h2>
        <p>
          Logical operators are used to perform logical operations on boolean values. They are often used in conditional statements (<code>if</code>, <code>while</code>, etc.) to combine multiple conditions.
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&amp;&amp;</td>
              <td>Logical AND</td>
              <td>true &amp;&amp; false</td>
              <td>false</td>
            </tr>
            <tr>
              <td>||</td>
              <td>Logical OR</td>
              <td>true || false</td>
              <td>true</td>
            </tr>
            <tr>
              <td>!</td>
              <td>Logical NOT</td>
              <td>!true</td>
              <td>false</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>boolean a = true, b = false;</code><br />
          <code>boolean result = a &amp;&amp; b; // false</code><br />
          <code>result = a || b;         // true</code><br />
          <code>result = !a;             // false</code>
        </p>
        <br/>
        {/* 4. Assignment Operators */}
        <h2>4. <strong>Assignment Operators</strong></h2>
        <p>
          Assignment operators are used to assign values to variables. The most common assignment operator is <code>=</code>, but Java also provides shorthand versions for other operations like addition, subtraction, multiplication, etc.
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>=</td>
              <td>Assign a value</td>
              <td>x = 5</td>
              <td>Assigns 5 to x</td>
            </tr>
            <tr>
              <td>+=</td>
              <td>Add and assign</td>
              <td>x += 5</td>
              <td>x = x + 5</td>
            </tr>
            <tr>
              <td>-=</td>
              <td>Subtract and assign</td>
              <td>x -= 5</td>
              <td>x = x - 5</td>
            </tr>
            <tr>
              <td>*=</td>
              <td>Multiply and assign</td>
              <td>x *= 5</td>
              <td>x = x * 5</td>
            </tr>
            <tr>
              <td>/=</td>
              <td>Divide and assign</td>
              <td>x /= 5</td>
              <td>x = x / 5</td>
            </tr>
            <tr>
              <td>%=</td>
              <td>Modulus and assign</td>
              <td>x %= 5</td>
              <td>x = x % 5</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>int x = 10;</code><br />
          <code>x += 5;  // x = 15</code><br />
          <code>x *= 2;  // x = 30</code>
        </p>
        <br/>
        {/* 5. Unary Operators */}
        <h2>5. <strong>Unary Operators</strong></h2>
        <p>
          Unary operators operate on a single operand. They are used for operations such as incrementing or decrementing values or performing logical negation.
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>+</td>
              <td>Unary plus</td>
              <td>+5</td>
              <td>5</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Unary minus</td>
              <td>-5</td>
              <td>-5</td>
            </tr>
            <tr>
              <td>++</td>
              <td>Increment</td>
              <td>x++ or ++x</td>
              <td>x = x + 1</td>
            </tr>
            <tr>
              <td>--</td>
              <td>Decrement</td>
              <td>x-- or --x</td>
              <td>x = x - 1</td>
            </tr>
            <tr>
              <td>!</td>
              <td>Logical NOT</td>
              <td>!true</td>
              <td>false</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>int x = 10;</code><br />
          <code>x++; // x becomes 11</code><br />
          <code>++x; // x becomes 12</code><br />
          <code>x--; // x becomes 11</code>
        </p>
        <br/>
        {/* 6. Bitwise Operators */}
        <h2>6. <strong>Bitwise Operators</strong></h2>
        <p>
          Bitwise operators are used to perform operations at the bit level on integer values. They operate on the binary representations of numbers.
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&</td>
              <td>Bitwise AND</td>
              <td>5 &amp; 3</td>
              <td>1</td>
            </tr>
            <tr>
              <td>|</td>
              <td>Bitwise OR</td>
              <td>5 | 3</td>
              <td>7</td>
            </tr>
            <tr>
              <td>^</td>
              <td>Bitwise XOR</td>
              <td>5 ^ 3</td>
              <td>6</td>
            </tr>
            <tr>
              <td>~</td>
              <td>Bitwise NOT</td>
              <td>~5</td>
              <td>-6</td>
            </tr>
            <tr>
              <td>&lt;&lt;</td>
              <td>Left shift</td>
              <td>5 &lt;&lt; 1</td>
              <td>10</td>
            </tr>
            <tr>
              <td>&gt;&gt;</td>
              <td>Right shift</td>
              <td>5 &gt;&gt; 1</td>
              <td>2</td>
            </tr>
            <tr>
              <td>&gt;&gt;&gt;</td>
              <td>Unsigned right shift</td>
              <td>5 &gt;&gt;&gt; 1</td>
              <td>2</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>int a = 5, b = 3;</code><br />
          <code>int result = a & b; // 1</code><br />
          <code>result = a | b; // 7</code><br />
          <code>result = a ^ b; // 6</code>
        </p>
        <br/>
        {/* 7. Ternary Operator */}
        <h2>7. <strong>Ternary Operator</strong></h2>
        <p>
          The ternary operator is a shorthand for an if-else statement. It is used to evaluate a condition and return one of two values depending on whether the condition is true or false.
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>?:</td>
              <td>Conditional (ternary)</td>
              <td>condition ? value1 : value2</td>
              <td>value1 or value2</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>int a = 10, b = 5;</code><br />
          <code>int result = (a &gt; b) ? a : b;  // result will be 10</code>
        </p>
        <br/>
        {/* 8. Instanceof Operator */}
        <h2>8. <strong>Instanceof Operator</strong></h2>
        <p>
          The <code>instanceof</code> operator checks whether an object is an instance of a specific class or interface.
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>instanceof</td>
              <td>Type checking operator</td>
              <td>obj instanceof ClassName</td>
              <td>true/false</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>String str = "Hello";</code><br />
          <code>boolean result = str instanceof String;  // true</code>
        </p>
        <br/>
        {/* 9. Type Cast Operator */}
        <h2>9. <strong>Type Cast Operator</strong></h2>
        <p>
          Type casting allows you to convert a variable from one data type to another. It can be used for both widening and narrowing conversions.
        </p>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Description</th>
              <th>Example</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>(type)</td>
              <td>Type casting</td>
              <td>(int) 3.14</td>
              <td>3</td>
            </tr>
          </tbody>
        </table>

        <p>
          <code>double d = 10.5;</code><br />
          <code>int i = (int) d;  // i = 10</code>
        </p>
        <br/>
        {/* Summary */}
        <h2>Summary of Java Operators</h2>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Operator Category</th>
              <th>Operators</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Arithmetic</td>
              <td>+, -, *, /, %</td>
            </tr>
            <tr>
              <td>Relational</td>
              <td>{`==, !=, >, <, >=, <=`}</td>
            </tr>
            <tr>
              <td>Logical</td>
              <td>&amp;&amp;, ||, !</td>
            </tr>
            <tr>
              <td>Assignment</td>
              <td>=, +=, -=, *=, /=, %=</td>
            </tr>
            <tr>
              <td>Unary</td>
              <td>+, -, ++, --, !</td>
            </tr>
            <tr>
              <td>Bitwise</td>
              <td>&amp;, |, ^, ~, &lt;&lt;, &gt;&gt;, &gt;&gt;&gt;</td>
            </tr>
            <tr>
              <td>Ternary</td>
              <td>?:</td>
            </tr>
            <tr>
              <td>Instanceof</td>
              <td>instanceof</td>
            </tr>
            <tr>
              <td>Type Cast</td>
              <td>(type)</td>
            </tr>
          </tbody>
        </table>
        <br/>
        <p>
          Each operator type is used for specific tasks, whether it's performing arithmetic, comparing values, or manipulating bits. Understanding them is essential for writing efficient Java programs.
        </p>
    
  </div>
)}






{selectedChapter === 'chapter11' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Keywords </h1>
          <p>
            Java keywords are reserved words that have a predefined meaning in the Java programming language. They are used to define the structure, behavior, and flow of a program. Java has a total of <strong>50 keywords</strong>, and each one has a specific purpose. Here’s a detailed explanation of each:
          </p><br/>

          <ol>
            <li><strong>`abstract`</strong> - Used to define an abstract class or an abstract method. An abstract class cannot be instantiated, and an abstract method must be implemented by subclasses.</li><br/>
            <li><strong>`assert`</strong> - Used for debugging purposes. It checks whether a condition is true; if not, it throws an <code>AssertionError</code>.</li><br/>
            <li><strong>`boolean`</strong> - Defines a variable that can hold one of two values: <code>true</code> or <code>false</code>.</li><br/>
            <li><strong>`break`</strong> - Exits a loop or a switch statement.</li><br/>
            <li><strong>`byte`</strong> - Defines a variable that holds an 8-bit integer value (from -128 to 127).</li><br/>
            <li><strong>`case`</strong> - Used in a <code>switch</code> statement to define different conditions to match against the <code>switch</code> expression.</li><br/>
            <li><strong>`catch`</strong> - Used to handle exceptions in a <code>try-catch</code> block.</li><br/>
            <li><strong>`char`</strong> - Defines a variable to store a single 16-bit Unicode character.</li><br/>
            <li><strong>`class`</strong> - Defines a class in Java, which is a blueprint for creating objects.</li><br/>
            <li><strong>`const`</strong> - Reserved for future use, but not used in current Java programming.</li><br/>
            <li><strong>`continue`</strong> - Skips the current iteration of a loop and proceeds to the next iteration.</li><br/>
            <li><strong>`default`</strong> - Used in a <code>switch</code> statement to specify the default block of code to be executed if no case matches.</li><br/>
            <li><strong>`do`</strong> - Defines the start of a <code>do-while</code> loop, where the loop runs at least once before checking the condition.</li><br/>
            <li><strong>`double`</strong> - Defines a variable that holds a 64-bit floating-point value.</li><br/>
            <li><strong>`else`</strong> - Defines the block of code to execute when the <code>if</code> condition is false.</li><br/>
            <li><strong>`enum`</strong> - Used to define a set of named constants (enumerated types).</li><br/>
            <li><strong>`extends`</strong> - Indicates that a class is inheriting from a superclass (used for inheritance).</li><br/>
            <li><strong>`final`</strong> - Used to declare constants, prevent method overriding, or prevent class inheritance. <br />
              <code>final variable:</code> Cannot be reassigned. <br />
              <code>final method:</code> Cannot be overridden. <br />
              <code>final class:</code> Cannot be subclassed.
            </li><br/>
            <li><strong>`finally`</strong> - Used to define a block of code that will always execute after a <code>try-catch</code> block, regardless of whether an exception occurs.</li><br/>
            <li><strong>`float`</strong> - Defines a variable that holds a 32-bit floating-point value.</li><br/>
            <li><strong>`for`</strong> - Defines the start of a <code>for</code> loop, which repeats a block of code a fixed number of times.</li><br/>
            <li><strong>`goto`</strong> - Reserved for future use, but not used in current Java programming.</li><br/>
            <li><strong>`if`</strong> - Defines a conditional statement that executes a block of code if the condition is true.</li><br/>
            <li><strong>`implements`</strong> - Used to specify that a class implements an interface, providing the method definitions specified in the interface.</li><br/>
            <li><strong>`import`</strong> - Used to import other classes or entire packages into the current file.</li><br/>
            <li><strong>`instanceof`</strong> - Tests whether an object is an instance of a specific class or implements an interface.</li><br/>
            <li><strong>`int`</strong> - Defines a variable that holds a 32-bit integer value.</li><br/>
            <li><strong>`interface`</strong> - Defines an interface, which is a collection of abstract methods that a class can implement.</li><br/>
            <li><strong>`long`</strong> - Defines a variable that holds a 64-bit integer value.</li><br/>
            <li><strong>`native`</strong> - Specifies that a method is implemented in another language, typically C or C++.</li><br/>
            <li><strong>`new`</strong> - Used to create new objects or arrays.</li><br/>
            <li><strong>`null`</strong> - Represents the absence of a value or a reference to an object.</li><br/>
            <li><strong>`package`</strong> - Used to define the package in which a class belongs.</li><br/>
            <li><strong>`private`</strong> - Specifies that a method, variable, or class is accessible only within its own class.</li><br/>
            <li><strong>`protected`</strong> - Specifies that a method or variable is accessible within its own package and by subclasses.</li><br/>
            <li><strong>`public`</strong> - Specifies that a method, variable, or class is accessible from any other class.</li><br/>
            <li><strong>`return`</strong> - Used to exit from a method and optionally return a value.</li><br/>
            <li><strong>`short`</strong> - Defines a variable that holds a 16-bit integer value.</li><br/>
            <li><strong>`static`</strong> - Defines a method or variable that belongs to the class rather than instances of the class.</li><br/>
            <li><strong>`strictfp`</strong> - Ensures that floating-point calculations conform to the IEEE 754 standard.</li><br/>
            <li><strong>`super`</strong> - Refers to the superclass of the current object, used to access superclass methods and constructors.</li><br/>
            <li><strong>`switch`</strong> - Defines a switch statement for handling multiple conditions.</li><br/>
            <li><strong>`synchronized`</strong> - Used to ensure that a method or block of code is accessed by only one thread at a time.</li><br/>
            <li><strong>`this`</strong> - Refers to the current instance of the class.</li><br/>
            <li><strong>`throw`</strong> - Used to throw an exception explicitly.</li><br/>
            <li><strong>`throws`</strong> - Declares that a method may throw one or more exceptions.</li><br/>
            <li><strong>`transient`</strong> - Used to indicate that a field should not be serialized.</li><br/>
            <li><strong>`try`</strong> - Defines a block of code that will be tested for exceptions.</li><br/>
            <li><strong>`void`</strong> - Specifies that a method does not return any value.</li><br/>
            <li><strong>`volatile`</strong> - Indicates that a variable may be changed unexpectedly, typically used in multi-threading to ensure that the latest value is always read from memory.</li>
          </ol>
        </div>
      )}
   




    
      {selectedChapter === 'chapter12' && (
        <div className={style.chaptercontent}>
          <h1 className={style.heading}> nextInt() </h1>

          

          <p>
            In Java, the method <code>nextInt()</code> is a part of the <code>Scanner</code> class, which is used for reading user input. Specifically, <code>nextInt()</code> is used to read the next integer value entered by the user.
          </p><br/>

          <h3> 1. Method Signature </h3>
          <pre>
            <code>public int nextInt()</code>
          </pre><br/>

          <h3> 2. Purpose </h3>
          <p>
            The <code>nextInt()</code> method is used to read the next token (or input) as an integer. It scans the input provided by the user and returns the integer representation of that input.
          </p><br/>

          <h3> 3. How It Works </h3>
          <ul>
            <li>The method reads the next token from the input, which is expected to be a valid integer.</li><br/>
            <li>If the user provides an invalid input (e.g., a string that cannot be parsed as an integer), the method throws an <code>InputMismatchException</code>.</li>
          </ul><br/>

          <h3> 4. Example Code </h3>
          <pre>
            <code>
{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object
        
        System.out.println("Enter an integer: ");
        int userInput = scanner.nextInt();  // Read the next integer
        
        System.out.println("You entered: " + userInput); // Print the entered integer
        
        scanner.close(); // Close the scanner object
    }
}`}
            </code>
          </pre><br/>

          <h3> 5. Explanation of Example </h3>
          <ul>
            <li><strong>Step 1:</strong> A <code>Scanner</code> object is created to read input from the console.</li><br/>
            <li><strong>Step 2:</strong> The <code>nextInt()</code> method is used to read the next integer entered by the user.</li><br/>
            <li><strong>Step 3:</strong> The integer entered by the user is stored in the <code>userInput</code> variable.</li><br/>
            <li><strong>Step 4:</strong> Finally, the program prints the entered integer.</li>
          </ul><br/>

          <h3> 6. Handling Input Mismatch </h3>
          <p>
            If the user enters something that is not an integer (e.g., a string or a floating-point number), an <code>InputMismatchException</code> will occur. To handle this, you can use a <code>try-catch</code> block:
          </p>
          <pre>
            <code>
{`import java.util.Scanner;
import java.util.InputMismatchException;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        
        try {
            System.out.println("Enter an integer: ");
            int userInput = scanner.nextInt();
            System.out.println("You entered: " + userInput);
        } catch (InputMismatchException e) {
            System.out.println("That's not a valid integer!");
        } finally {
            scanner.close(); // Always close the scanner
        }
    }
}`}
            </code>
          </pre><br/>

          <h3> 7. Important Notes </h3>
          <ul>
            <li><strong>Whitespace Handling:</strong> If the user enters extra spaces before or after the number, <code>nextInt()</code> will ignore the spaces and read the integer.</li><br/>
            <li><strong>Next Token:</strong> If you need to read the rest of the line after the integer, you might need to call <code>nextLine()</code> to consume any leftover newline character (<code>\n</code>).</li>
          </ul><br/>
          <pre>
            <code>
{`int number = scanner.nextInt();
scanner.nextLine(); // Consume the newline character left by nextInt()
String text = scanner.nextLine(); // Now you can read a full line of text`}
            </code>
          </pre><br/>

          <h3> 8. Differences from Other Methods </h3>
          <ul>
            <li><code>next()</code>: Reads a single word (string) from input.</li><br/>
            <li><code>nextLine()</code>: Reads a full line of text, including spaces.</li><br/>
            <li><code>nextFloat()</code>: Reads a floating-point number.</li><br/>
            <li><code>nextDouble()</code>: Reads a double precision floating-point number.</li>
          </ul><br/>

          <h3> Summary </h3>
          <ul>
            <li><code>nextInt()</code> is a simple way to read integer values from user input.</li><br/>
            <li>It only reads the next token as an integer and throws an exception if the input is not a valid integer.</li><br/>
            <li>It’s often used with a <code>Scanner</code> object in console-based Java programs to interact with users.</li>
          </ul><br/>

          
        </div>
      )}
  




  {selectedChapter === 'chapter13' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> nextDouble()  </h1>

      <p>The <code>nextDouble()</code> method is part of the <code>Scanner</code> class in Java, which is used to read user input. Specifically, the <code>nextDouble()</code> method reads the next token (input) from the user as a <code>double</code> value.</p>
      <br/>
      <h4>1. Method Signature:</h4>
      <pre><code>public double nextDouble()</code></pre>
      <br/>
      <h4>2. Purpose:</h4>
      <p>The <code>nextDouble()</code> method is used to read the next token as a <code>double</code>. This method scans the input provided by the user, interprets it as a floating-point number (following the <code>double</code> precision standard), and returns the <code>double</code> representation of that input.</p>
      <br/>
      <h4>3. How It Works:</h4>
      <ul>
        <li>The method reads the next token from the input.</li><br/>
        <li>It expects the user to enter a valid <code>double</code> value. The value can have a decimal point (e.g., <code>3.14</code> or <code>-2.5</code>).</li><br/>
        <li>If the user enters an invalid input that cannot be converted into a valid <code>double</code> (e.g., a string or a number with an incorrect format), the method throws an <code>InputMismatchException</code>.</li>
      </ul>
      <br/>
      <h4>4. Example Code:</h4>
      <pre><code>
{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object
        
        System.out.println("Enter a double value: ");
        double userInput = scanner.nextDouble();  // Read the next double
        
        System.out.println("You entered: " + userInput); // Print the entered double
        
        scanner.close(); // Close the scanner object
    }
}`}
      </code></pre>
      <br/>
      <h4>5. Explanation of Example:</h4>
      <ol>
        <li><strong>Step 1:</strong> A <code>Scanner</code> object is created to read input from the console.</li><br/>
        <li><strong>Step 2:</strong> The <code>nextDouble()</code> method is used to read the next <code>double</code> entered by the user.</li><br/>
        <li><strong>Step 3:</strong> The value entered by the user is stored in the <code>userInput</code> variable.</li><br/>
        <li><strong>Step 4:</strong> The program prints the value entered by the user.</li>
      </ol><br/>

      <h4>6. Handling Input Mismatch:</h4>
      <p>If the user enters something that is not a valid <code>double</code> (e.g., a string or an improperly formatted number), an <code>InputMismatchException</code> will be thrown. To handle this, you can use a <code>try-catch</code> block:</p>

      <pre><code>
{`import java.util.Scanner;
import java.util.InputMismatchException;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        
        try {
            System.out.println("Enter a double value: ");
            double userInput = scanner.nextDouble();
            System.out.println("You entered: " + userInput);
        } catch (InputMismatchException e) {
            System.out.println("That's not a valid double!");
        } finally {
            scanner.close(); // Always close the scanner
        }
    }
}`}
      </code></pre>
      <br/>
      <h4>7. Important Notes:</h4>
      <ul>
        <li><strong>Whitespace Handling:</strong> If there are extra spaces or newlines, they will be ignored by <code>nextDouble()</code> when reading the input. It only cares about the next token as a valid <code>double</code>.</li><br/>
        <li><strong>Decimal Points:</strong> You can enter numbers with a decimal point or in scientific notation (e.g., <code>1.23</code>, <code>3.14e2</code>).</li><br/>
        <li><strong>Next Token:</strong> After using <code>nextDouble()</code>, if you need to read the rest of the line (for example, if the user enters additional text after the number), you should call <code>nextLine()</code> to consume the newline character.</li>
      </ul><br/>

      <pre><code>
{`double number = scanner.nextDouble();
scanner.nextLine(); // Consume the newline character left by nextDouble()
String text = scanner.nextLine(); // Now you can read a full line of text`}
      </code></pre>
      <br/>
      <h4>Summary:</h4>
      <ul>
        <li><code>nextDouble()</code> is used to read a <code>double</code> value from the user input.</li><br/>
        <li>It reads the next token and expects it to be a valid <code>double</code>. If not, it throws an <code>InputMismatchException</code>.</li><br/>
        <li>You can handle input mismatches using a <code>try-catch</code> block.</li><br/>
        <li>It’s ideal for reading floating-point numbers from the user in console-based Java programs.</li>
      </ul>

      
    </div>
  )}   



{selectedChapter === 'chapter14' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>nextFloat()</h1>

    <p>
      The <code>nextFloat()</code> method is part of the <code>Scanner</code> class in Java and is used to read a <code>float</code> value from user input. Here's an explanation of how this method works, along with an example:
    </p>
    <br/>
    <h3>Java Method: <code>nextFloat()</code></h3>

    <p>
      The <code>nextFloat()</code> method reads the next token (input) as a <code>float</code> value. It interprets the input as a floating-point number and returns the value as a <code>float</code>.
    </p>
    <br/>
    <h4>1. Method Signature:</h4>
    <pre>
      <code>
        public float nextFloat()
      </code>
    </pre>
    <br/>
    <h4>2. Purpose:</h4>
    <p>
      The <code>nextFloat()</code> method is designed to read a <code>float</code> value from the input provided by the user. It expects the input to be in the form of a floating-point number (such as <code>3.14</code>, <code>-0.56</code>, or <code>5e2</code> for scientific notation). If the input cannot be parsed as a valid <code>float</code>, it will throw an <code>InputMismatchException</code>.
    </p><br/>

    <h4>3. How It Works:</h4>
    <ul>
      <li>The method reads the next token from the input.</li><br/>
      <li>It expects the token to be a valid <code>float</code> value (either with or without a decimal point, and can also be in scientific notation).</li><br/>
      <li>If the user enters something that is not a valid <code>float</code> (e.g., a string or an improperly formatted number), the method will throw an <code>InputMismatchException</code>.</li>
    </ul><br/>

    <h4>4. Example Code:</h4>
    <pre>
      <code>{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object
        
        System.out.println("Enter a float value: ");
        if (scanner.hasNextFloat()) {  // Check if the input is a valid float
            float userInput = scanner.nextFloat();  // Read the next float
            
            System.out.println("You entered: " + userInput); // Print the entered float
        } else {
            System.out.println("Invalid input. Please enter a valid float.");
        }
        
        scanner.close(); // Close the scanner object
    }
}`}</code>
    </pre><br/>

    <h4>5. Explanation of Example:</h4>
    <ol>
      <li><strong>Step 1</strong>: A <code>Scanner</code> object is created to read input from the user.</li><br/>
      <li><strong>Step 2</strong>: The <code>nextFloat()</code> method is used to read the next floating-point number entered by the user.</li><br/>
      <li><strong>Step 3</strong>: The entered <code>float</code> is stored in the <code>userInput</code> variable.</li><br/>
      <li><strong>Step 4</strong>: The program prints the <code>float</code> value entered by the user.</li>
    </ol><br/>

    <h4>6. Handling Input Mismatch:</h4>
    <p>
      If the user enters an invalid input (like a string or a number with an incorrect format), an <code>InputMismatchException</code> will be thrown. You can handle this by using a <code>try-catch</code> block:
    </p>

    <pre>
      <code>{`import java.util.Scanner;
import java.util.InputMismatchException;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        
        try {
            System.out.println("Enter a float value: ");
            float userInput = scanner.nextFloat();
            System.out.println("You entered: " + userInput);
        } catch (InputMismatchException e) {
            System.out.println("That's not a valid float!");
        } finally {
            scanner.close(); // Always close the scanner
        }
    }
}`}</code>
    </pre><br/>

    <h4>7. Important Notes:</h4>
    <ul>
      <li><strong>Whitespace Handling:</strong> Extra spaces or newlines before or after the number are ignored by <code>nextFloat()</code>. The method only reads the next token as a valid <code>float</code>.</li><br/>
      <li><strong>Decimal Points:</strong> The input can include decimal points or be in scientific notation (e.g., <code>1.23</code> or <code>1.23e2</code>).</li><br/>
      <li><strong>Next Token:</strong> If you need to read the rest of the line after using <code>nextFloat()</code>, call <code>nextLine()</code> to consume the leftover newline character.</li>
    </ul><br/>

    <h4>Example:</h4>
    <pre>
      <code>
        {`float number = scanner.nextFloat();
scanner.nextLine(); // Consume the newline character left by nextFloat()
String text = scanner.nextLine(); // Now you can read a full line of text`}
      </code>
    </pre><br/>

    <h3>Summary:</h3>
    <ul>
      <li>The <code>nextFloat()</code> method is used to read a <code>float</code> value from the user input.</li><br/>
      <li>It reads the next token and expects it to be a valid <code>float</code>. If the input cannot be parsed as a valid <code>float</code>, it throws an <code>InputMismatchException</code>.</li><br/>
      <li>You can handle input mismatches using a <code>try-catch</code> block.</li><br/>
      <li>It's commonly used to read floating-point numbers in console-based Java programs.</li>
    </ul>

    
  </div>
)}




{selectedChapter === 'chapter15' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>nextLong()</h1>
    
    <p>
      The <code>nextLong()</code> method is part of the <code>Scanner</code> class in Java, used to read a <code>long</code> value from the user input. This method reads the next token (input) from the user as a <code>long</code> value.
    </p><br/>

    <h2>1. Method Signature</h2>
    <pre>
      <code>public long nextLong()</code>
    </pre><br/>

    <h2>2. Purpose</h2>
    <p>
      The <code>nextLong()</code> method is used to read the next token from the input and interpret it as a <code>long</code> value. A <code>long</code> is a 64-bit signed integer, and this method ensures that the input provided by the user is interpreted as such.
    </p><br/>

    <h2>3. How It Works</h2>
    <ul>
      <li>The method reads the next token from the input, which is expected to be a valid <code>long</code> integer (i.e., an integer without decimal points).</li><br/>
      <li>If the user enters an invalid input (e.g., a string or a floating-point number), the method throws an <code>InputMismatchException</code>.</li>
    </ul><br/>

    <h2>4. Example Code</h2>
    <pre>
      <code>
{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object

        System.out.println("Enter a long value: ");
        long userInput = scanner.nextLong();  // Read the next long

        System.out.println("You entered: " + userInput); // Print the entered long

        scanner.close(); // Close the scanner object
    }
}`}
      </code>
    </pre><br/>

    <h2>5. Explanation of Example</h2>
    <ol>
      <li>A <code>Scanner</code> object is created to read input from the user.</li><br/>
      <li>The <code>nextLong()</code> method is used to read the next token as a <code>long</code> value entered by the user.</li><br/>
      <li>The value entered by the user is stored in the <code>userInput</code> variable.</li><br/>
      <li>The program prints the value of the <code>userInput</code>.</li>
    </ol><br/>

    <h2>6. Handling Input Mismatch</h2>
    <p>
      If the user enters an invalid input (e.g., a string or a floating-point number), an <code>InputMismatchException</code> will be thrown. You can handle this by using a <code>try-catch</code> block to catch the exception and handle it gracefully.
    </p>
    <pre>
      <code>
{`import java.util.Scanner;
import java.util.InputMismatchException;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);

        try {
            System.out.println("Enter a long value: ");
            long userInput = scanner.nextLong();
            System.out.println("You entered: " + userInput);
        } catch (InputMismatchException e) {
            System.out.println("That's not a valid long!");
        } finally {
            scanner.close(); // Always close the scanner
        }
    }
}`}
      </code>
    </pre><br/>

    <h2>7. Important Notes</h2>
    <ul>
      <li>
        <strong>Whitespace Handling:</strong> The <code>nextLong()</code> method automatically skips any leading whitespace before the number, but it does not ignore other non-numeric characters (e.g., letters or special characters).
      </li><br/>
      <li>
        <strong>Next Token:</strong> If you need to read the rest of the line after using <code>nextLong()</code>, you should call <code>nextLine()</code> to consume the newline character, as the <code>nextLong()</code> method only reads the numeric part.
      </li>
    </ul><br/>

    <p>
      Example of handling newline:
    </p>
    <pre>
      <code>
{`long number = scanner.nextLong();
scanner.nextLine(); // Consume the newline character left by nextLong()
String text = scanner.nextLine(); // Now you can read a full line of text`}
      </code>
    </pre><br/>

    <h2>8. Overflow Handling</h2>
    <p>
      The <code>long</code> type can store values from <code>-2^63</code> to <code>2^63 - 1</code>. If the input exceeds this range, it will result in an overflow error. Java does not automatically handle such overflows, so it's important to ensure the user input is within the valid <code>long</code> range.
    </p><br/>

    <h2>Summary</h2>
    <ul>
      <li>The <code>nextLong()</code> method is used to read a <code>long</code> value from user input in Java.</li><br/>
      <li>It expects a valid <code>long</code> value (without decimal points) as input and throws an <code>InputMismatchException</code> if the input is invalid.</li><br/>
      <li>Use <code>try-catch</code> to handle input mismatches.</li><br/>
      <li>Be mindful of whitespace, and consider using <code>nextLine()</code> if you need to read more input after calling <code>nextLong()</code>.</li>
    </ul>

   
  </div>
)}





{selectedChapter === 'chapter16' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>nextShort()</h1>
    <p>
      The <code>nextShort()</code> method in Java is part of the <code>Scanner</code> class, which is used to read primitive data types from user input. The <code>nextShort()</code> method specifically reads the next token of input as a <code>short</code> value.
    </p><br/>

    <h3>1. Method Signature:</h3>
    <pre><code>public short nextShort()</code></pre><br/>

    <h3>2. Purpose:</h3>
    <p>
      The <code>nextShort()</code> method reads the next token from the input as a <code>short</code> value, which is a 16-bit signed integer. The method expects the input to be a valid <code>short</code> integer without decimal points, and it throws an <code>InputMismatchException</code> if the input cannot be interpreted as a <code>short</code>.
    </p><br/>

    <h3>3. How It Works:</h3>
    <ul>
      <li>The method reads the next token from the input.</li><br/>
      <li>It expects the token to be a valid <code>short</code> value, which can range from <code>-32,768</code> to <code>32,767</code>.</li><br/>
      <li>If the user enters a value that is out of the range of a <code>short</code> (e.g., a number greater than <code>32,767</code> or less than <code>-32,768</code>), the method will throw an <code>InputMismatchException</code>.</li><br/>
      <li>If the input is not a valid number (e.g., letters or symbols), it also throws an <code>InputMismatchException</code>.</li>
    </ul><br/>

    <h3>4. Example Code:</h3>
    <pre>
      <code>
{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object
        
        System.out.println("Enter a short value: ");
        short userInput = scanner.nextShort();  // Read the next short
        
        System.out.println("You entered: " + userInput); // Print the entered short
        
        scanner.close(); // Close the scanner object
    }
}`}
      </code>
    </pre><br/>

    <h3>5. Explanation of Example:</h3>
    <ul>
      <li><strong>Step 1:</strong> A <code>Scanner</code> object is created to read input from the user.</li><br/>
      <li><strong>Step 2:</strong> The <code>nextShort()</code> method is used to read the next token entered by the user as a <code>short</code> value.</li><br/>
      <li><strong>Step 3:</strong> The entered <code>short</code> value is stored in the <code>userInput</code> variable.</li><br/>
      <li><strong>Step 4:</strong> The program prints the value entered by the user.</li>
    </ul><br/>

    <h3>6. Handling Input Mismatch:</h3>
    <p>
      If the user enters an invalid input (such as a string, decimal, or a number outside the <code>short</code> range), the method throws an <code>InputMismatchException</code>. You can handle this by using a <code>try-catch</code> block to catch and manage the exception.
    </p>

    <pre>
      <code>
{`import java.util.Scanner;
import java.util.InputMismatchException;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        
        try {
            System.out.println("Enter a short value: ");
            short userInput = scanner.nextShort();
            System.out.println("You entered: " + userInput);
        } catch (InputMismatchException e) {
            System.out.println("That's not a valid short!");
        } finally {
            scanner.close(); // Always close the scanner
        }
    }
}`}
      </code>
    </pre><br/>

    <h3>7. Important Notes:</h3>
    <ul>
      <li><strong>Whitespace Handling:</strong> Like other <code>next*</code> methods, <code>nextShort()</code> will skip over any leading whitespace characters before reading the input.</li><br/>
      <li><strong>Range of Values:</strong> The valid range for a <code>short</code> is from <code>-32,768</code> to <code>32,767</code>. If a number outside this range is entered, it will cause an exception.</li><br/>
      <li><strong>Handling Larger Numbers:</strong> If the user enters a value larger than the <code>short</code> range, such as <code>33000</code>, it will throw an <code>InputMismatchException</code>.</li>
    </ul><br/>

    <h3>8. Using <code>nextLine()</code> After <code>nextShort()</code>:</h3>
    <p>
      If you need to read more input after using <code>nextShort()</code>, you may need to call <code>nextLine()</code> to consume the leftover newline character.
    </p>

    <pre>
      <code>
{`short number = scanner.nextShort();
scanner.nextLine(); // Consume the newline character left by nextShort()
String text = scanner.nextLine(); // Now you can read a full line of text
`}
      </code>
    </pre><br/>

    <h3>Summary:</h3>
    <ul>
      <li>The <code>nextShort()</code> method is used to read a <code>short</code> value from user input in Java.</li><br/>
      <li>It expects a valid integer within the range of <code>short</code> (<code>-32,768</code> to <code>32,767</code>) and throws an <code>InputMismatchException</code> if the input is invalid or out of range.</li><br/>
      <li>You can handle mismatches using a <code>try-catch</code> block.</li><br/>
      <li>Be mindful of whitespace, and use <code>nextLine()</code> if you need to read additional text after <code>nextShort()</code>.</li>
    </ul>

  </div>
)}



{selectedChapter === 'chapter17' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>nextByte()</h1>

    <p>
      The <code>nextByte()</code> method in Java is a part of the <code>Scanner</code> class, which is used to read different types of data from user input. Specifically, the <code>nextByte()</code> method reads the next token from the input as a <code>byte</code> value.
    </p><br/>

    <h3>1. Method Signature:</h3>
    <pre>
      <code>
        public byte nextByte()
      </code>
    </pre><br/>

    <h3>2. Purpose:</h3>
    <p>
      The <code>nextByte()</code> method reads the next token from the input as a <code>byte</code> value, which is an 8-bit signed integer. It expects the input to be a valid <code>byte</code> integer without any decimal points. If the input is invalid or out of the <code>byte</code> range, the method will throw an <code>InputMismatchException</code>.
    </p><br/>

    <h3>3. How It Works:</h3>
    <ul>
      <li>The method reads the next token from the input.</li><br/>
      <li>It expects the token to be a valid <code>byte</code> value, which ranges from <code>-128</code> to <code>127</code>.</li><br/>
      <li>If the user enters a number outside of this range, it will throw an <code>InputMismatchException</code>.</li><br/>
      <li>If the user enters non-numeric data (like letters or symbols), the method will also throw an <code>InputMismatchException</code>.</li>
    </ul><br/>

    <h3>4. Example Code:</h3>
    <pre>
      <code>{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object

        System.out.println("Enter a byte value: ");
        byte userInput = scanner.nextByte();  // Read the next byte

        System.out.println("You entered: " + userInput); // Print the entered byte

        scanner.close(); // Close the scanner object
    }
}`}</code>
    </pre><br/>

    <h3>5. Explanation of Example:</h3>
    <ol>
      <li><strong>Step 1:</strong> A <code>Scanner</code> object is created to read input from the user.</li><br/>
      <li><strong>Step 2:</strong> The <code>nextByte()</code> method is used to read the next token entered by the user as a <code>byte</code> value.</li><br/>
      <li><strong>Step 3:</strong> The entered <code>byte</code> value is stored in the <code>userInput</code> variable.</li><br/>
      <li><strong>Step 4:</strong> The program prints the value entered by the user.</li>
    </ol><br/>

    <h3>6. Handling Input Mismatch:</h3>
    <p>
      If the user enters an invalid input (such as a string, decimal, or a number outside the <code>byte</code> range), the method throws an <code>InputMismatchException</code>. You can handle this by using a <code>try-catch</code> block to catch and manage the exception.
    </p>

    <pre>
      <code>{`import java.util.Scanner;
import java.util.InputMismatchException;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object

        try {
            System.out.println("Enter a byte value: ");
            byte userInput = scanner.nextByte();  // Read the next byte
            System.out.println("You entered: " + userInput); // Print the entered byte
        } catch (InputMismatchException e) {
            System.out.println("That's not a valid byte!");
        } finally {
            scanner.close(); // Always close the scanner
        }
    }
}`}</code>
    </pre>
    <br/>
    <h3>7. Important Notes:</h3>
    <ul>
      <li><strong>Whitespace Handling:</strong> The <code>nextByte()</code> method automatically skips any leading whitespace before reading the input.</li><br/>
      <li><strong>Range of Values:</strong> The valid range for a <code>byte</code> is from <code>-128</code> to <code>127</code>. If a number outside this range is entered, it will cause an <code>InputMismatchException</code>.</li><br/>
      <li><strong>Handling Larger Numbers:</strong> If the user enters a value larger than the <code>byte</code> range (e.g., <code>128</code> or <code>-130</code>), an <code>InputMismatchException</code> will be thrown.</li><br/>
      <li><strong>Reading More Input:</strong> If you need to read more input after using <code>nextByte()</code>, you may need to call <code>nextLine()</code> to consume the newline character.</li>
    </ul><br/>

    <h3>8. Example of Handling the Newline:</h3>
    <pre>
      <code>
        byte number = scanner.nextByte(); {'\n'}
        scanner.nextLine(); // Consume the newline character left by nextByte() {'\n'}
        String text = scanner.nextLine(); // Now you can read a full line of text
      </code>
    </pre><br/>

    <h3>Summary:</h3>
    <ul>
      <li>The <code>nextByte()</code> method is used to read a <code>byte</code> value from user input in Java.</li><br/>
      <li>It expects a valid integer within the range of <code>byte</code> (<code>-128</code> to <code>127</code>) and throws an <code>InputMismatchException</code> if the input is invalid or out of range.</li><br/>
      <li>You can handle mismatches using a <code>try-catch</code> block.</li><br/>
      <li>Be mindful of whitespace, and use <code>nextLine()</code> if you need to read additional text after <code>nextByte()</code>.</li>
    </ul><br/>

  </div>
)}






{selectedChapter === 'chapter18' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>nextBoolean()</h1>

    <p>
      The <code>nextBoolean()</code> method in Java is part of the <code>Scanner</code> class
      and is used to read a boolean value from the user input. It reads the next token of input
      and interprets it as a boolean value.
    </p><br/>

    <h3>1. Method Signature:</h3>
    <pre>
      <code>public boolean nextBoolean()</code>
    </pre><br/>

    <h3>2. Purpose:</h3>
    <p>
      The <code>nextBoolean()</code> method is used to read a boolean value (<code>true</code> or <code>false</code>)
      from the user input. It expects the input to be a valid boolean value, and it can only accept
      specific values like <code>"true"</code> or <code>"false"</code>. Any other input will throw
      an <code>InputMismatchException</code>.
    </p><br/>

    <h3>3. How It Works:</h3>
    <ul>
      <li>The method reads the next token from the input.</li><br/>
      <li>It expects the token to be either <code>"true"</code> or <code>"false"</code> (case-sensitive).</li><br/>
      <li>If the input is <code>"true"</code>, it returns <code>true</code>.</li><br/>
      <li>If the input is <code>"false"</code>, it returns <code>false</code>.</li><br/>
      <li>If the input is anything else (such as numbers, special characters, or other strings),
        an <code>InputMismatchException</code> is thrown.</li>
    </ul><br/>

    <h3>4. Example Code:</h3>
    <pre>
      <code>
{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object

        System.out.println("Enter a boolean value (true/false): ");
        boolean userInput = scanner.nextBoolean();  // Read the next boolean value

        System.out.println("You entered: " + userInput); // Print the entered boolean

        scanner.close(); // Close the scanner object
    }
}`}
      </code>
    </pre><br/>

    <h3>5. Explanation of Example:</h3>
    <ul>
      <li><strong>Step 1:</strong> A <code>Scanner</code> object is created to read input from the user.</li><br/>
      <li><strong>Step 2:</strong> The <code>nextBoolean()</code> method is used to read the next token entered by the user as a boolean value.</li><br/>
      <li><strong>Step 3:</strong> The entered boolean value is stored in the <code>userInput</code> variable.</li><br/>
      <li><strong>Step 4:</strong> The program prints the value entered by the user.</li>
    </ul><br/>

    <h3>6. Handling Input Mismatch:</h3>
    <p>
      If the user enters anything other than <code>"true"</code> or <code>"false"</code>, such as
      <code>"yes"</code>, <code>"1"</code>, or <code>"0"</code>, it will throw an
      <code>InputMismatchException</code>. You can handle this by using a <code>try-catch</code> block.
    </p>

    <pre>
      <code>
{`import java.util.Scanner;
import java.util.InputMismatchException;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);

        try {
            System.out.println("Enter a boolean value (true/false): ");
            boolean userInput = scanner.nextBoolean();
            System.out.println("You entered: " + userInput);
        } catch (InputMismatchException e) {
            System.out.println("That's not a valid boolean!");
        } finally {
            scanner.close(); // Always close the scanner
        }
    }
}`}
      </code>
    </pre><br/>

    <h3>7. Important Notes:</h3>
    <ul>
      <li><strong>Whitespace Handling:</strong> The <code>nextBoolean()</code> method automatically skips any leading whitespace before reading the boolean value.</li><br/>
      <li><strong>Case Sensitivity:</strong> The input <code>"true"</code> and <code>"false"</code> are case-sensitive. Only lowercase <code>"true"</code> and <code>"false"</code> are valid inputs. <code>"True"</code> or <code>"FALSE"</code> will throw an <code>InputMismatchException</code>.</li><br/>
      <li><strong>Handling Other Input:</strong> If the user enters anything that cannot be interpreted as a boolean (such as a number or word), an exception is thrown.</li>
    </ul><br/>

    <h3>8. Using nextLine() After nextBoolean():</h3>
    <p>
      If you need to read more input after using <code>nextBoolean()</code>, you may need to call
      <code>nextLine()</code> to consume the newline character left by <code>nextBoolean()</code>.
    </p>

    <pre>
      <code>
{`boolean flag = scanner.nextBoolean();
scanner.nextLine(); // Consume the newline character left by nextBoolean()
String text = scanner.nextLine(); // Now you can read a full line of text`}
      </code>
    </pre><br/>

    <h3>Summary:</h3>
    <ul>
      <li>The <code>nextBoolean()</code> method is used to read a boolean value (<code>true</code> or <code>false</code>) from user input in Java.</li><br/>
      <li>It throws an <code>InputMismatchException</code> if the input is not a valid boolean value.</li><br/>
      <li>Use a <code>try-catch</code> block to handle invalid input.</li><br/>
      <li>Be mindful of case sensitivity and whitespace, and consider using <code>nextLine()</code> to read additional input after <code>nextBoolean()</code>.</li>
    </ul><br/>

  </div>
)}




{selectedChapter === 'chapter19' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>next() </h1>

    <p>
      The <code>next()</code> method in Java is part of the <code>Scanner</code> class
      and is used to read the next token (a sequence of characters separated by
      whitespace) from the input. It is commonly used to retrieve input as a <code>String</code>
      from the user.
    </p><br/>

    <h2>1. Method Signature:</h2>
    <pre>
      <code>public String next()</code>
    </pre><br/>

    <h2>2. Purpose:</h2>
    <p>
      The <code>next()</code> method is used to read and return the next token from the input.
      A token is defined as a sequence of characters separated by whitespace (spaces, tabs, or newlines).
    </p><br/>

    <h2>3. How It Works:</h2>
    <ul>
      <li>The method reads the input character by character until it encounters a whitespace character (such as a space, tab, or newline).</li><br/>
      <li>It returns the sequence of characters (the token) as a <code>String</code> that was read.</li><br/>
      <li>Unlike methods like <code>nextLine()</code>, which reads the entire line, <code>next()</code> only reads up to the next whitespace and returns that portion.</li><br/>
      <li>After reading the token, the method moves the cursor to the next token (i.e., the next non-whitespace character).</li>
    </ul><br/>

    <h2>4. Example Code:</h2>
    <pre>
      <code>
        {`import java.util.Scanner;

public class Main {
      public static void main(String[] args) {
          Scanner scanner = new Scanner(System.in); // Create a scanner object

          System.out.println("Enter a sentence: ");
          String userInput = scanner.next();  // Read the next token

          System.out.println("You entered: " + userInput); // Print the entered token

          scanner.close(); // Close the scanner object
      }
}`}
      </code>
    </pre><br/>

    <h2>5. Explanation of Example:</h2>
    <ul>
      <li><b>Step 1</b>: A <code>Scanner</code> object is created to read input from the user.</li><br/>
      <li><b>Step 2</b>: The <code>next()</code> method is used to read the next token entered by the user.</li><br/>
      <li><b>Step 3</b>: The entered token (a string of characters without spaces) is stored in the <code>userInput</code> variable.</li><br/>
      <li><b>Step 4</b>: The program prints the token entered by the user.</li>
    </ul><br/>

    <h2>6. Important Notes:</h2>
    <ul>
      <li><b>Whitespace Handling</b>: The <code>next()</code> method skips any leading whitespace before reading the next token. However, it stops reading once it encounters a whitespace character.</li><br/>
      <li><b>No Input Handling</b>: If the user provides no input and the <code>next()</code> method is called, it will throw a <code>NoSuchElementException</code>.</li><br/>
      <li><b>Multiple Calls</b>: You can call <code>next()</code> multiple times to read successive tokens from the input. Each call to <code>next()</code> will read up to the next whitespace character.</li>
    </ul><br/>

    <h2>7. Using next() with Multiple Words:</h2>
    <pre>
      <code>
        {`import java.util.Scanner;

        public class Main {
            public static void main(String[] args) {
                Scanner scanner = new Scanner(System.in);

                System.out.println("Enter multiple words: ");
                String firstWord = scanner.next();  // First word
                String secondWord = scanner.next(); // Second word

                System.out.println("First word: " + firstWord);
                System.out.println("Second word: " + secondWord);

                scanner.close();
        }
}`}
      </code>
    </pre><br/>

    <h2>8. Difference Between next() and nextLine():</h2>
    <p>
      <ul>
        <li><code>next()</code> reads only a single token (up to the next whitespace).</li><br/>
        <li><code>nextLine()</code> reads an entire line, including spaces between words.</li>
      </ul>
    </p>

    <p>
      For example, if the user enters the string <code>"Hello World"</code>, calling <code>next()</code> twice would return <code>"Hello"</code> the first time and <code>"World"</code> the second time, while calling <code>nextLine()</code> would return <code>"Hello World"</code> as a single string.
    </p><br/>

    <h2>Summary:</h2>
    <ul>
      <li>The <code>next()</code> method reads and returns the next token from the user input as a <code>String</code>.</li><br/>
      <li>It reads input up to the next whitespace and skips over leading whitespaces.</li><br/>
      <li>It’s commonly used to process input in a tokenized fashion when you need individual pieces of data.</li>
    </ul>
  </div>
)}




{selectedChapter === 'chapter20' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> nextLine() </h1>

    <p>
      The <code>nextLine()</code> method in Java is a part of the <code>Scanner</code> class,
      and it is used to read an entire line of text from the input. Unlike methods like
      <code>next()</code> which read tokens (sequences of characters separated by whitespace),
      <code>nextLine()</code> reads the entire line, including spaces between words, until the user
      presses Enter (or a newline character).
    </p><br/>

    <h3>1. Method Signature:</h3>
    <pre>
      <code>public String nextLine()</code>
    </pre><br/>

    <h3>2. Purpose:</h3>
    <p>
      The <code>nextLine()</code> method is used to read and return an entire line of text. It is
      particularly useful when you want to capture user input that might contain spaces or multiple
      words. The method reads characters from the input until it encounters the end of the line (which
      is marked by the user pressing the Enter key).
    </p><br/>

    <h3>3. How It Works:</h3>
    <ul>
      <li>The method reads the entire line of input, including spaces between words.</li><br/>
      <li>It stops reading when it encounters a newline character (when the user presses Enter).</li><br/>
      <li>It returns the entire line of input as a single <code>String</code> (including spaces between words).</li><br/>
      <li>It consumes the newline character (the Enter key) from the input buffer, so subsequent input
        operations (like <code>next()</code> or <code>nextLine()</code>) will behave as expected.</li>
    </ul><br/>

    <h3>4. Example Code:</h3>
    <pre>
      <code>{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in); // Create a scanner object

        System.out.println("Enter a sentence: ");
        String userInput = scanner.nextLine();  // Read the entire line

        System.out.println("You entered: " + userInput); // Print the entered line

        scanner.close(); // Close the scanner object
    }
}`}</code>
    </pre><br/>

    <h3>5. Explanation of Example:</h3>
    <ul>
      <li><strong>Step 1</strong>: A <code>Scanner</code> object is created to read input from the user.</li><br/>
      <li><strong>Step 2</strong>: The <code>nextLine()</code> method is used to read the entire line entered by the user, which can include spaces between words.</li><br/>
      <li><strong>Step 3</strong>: The entire line entered by the user is stored in the <code>userInput</code> variable.</li><br/>
      <li><strong>Step 4</strong>: The program prints the entire line that the user entered.</li>
    </ul><br/>

    <h3>6. Important Notes:</h3>
    <ul>
      <li><strong>Whitespace Handling:</strong> Unlike <code>next()</code>, which stops reading when it encounters a whitespace, <code>nextLine()</code> reads the entire line, including any spaces between words.</li><br/>
      <li><strong>Newline Character:</strong> After reading the input, <code>nextLine()</code> consumes the newline character (<code>\n</code>) generated by the Enter key.</li><br/>
      <li><strong>Multiple Lines:</strong> You can call <code>nextLine()</code> multiple times to read multiple lines of input. Each call will read one entire line of text.</li>
    </ul><br/>

    <h3>7. Common Pitfalls:</h3>
    <p>
      If you use <code>nextLine()</code> after calling methods like <code>next()</code> or <code>nextInt()</code>,
      you may encounter unexpected behavior because these methods don’t consume the newline character, leaving
      it in the input buffer. In this case, a call to <code>nextLine()</code> will immediately return an empty string,
      because it reads the leftover newline.
    </p><br/>
    <p>
      To avoid this, you can add a <code>nextLine()</code> call after <code>next()</code> or <code>nextInt()</code> to consume the leftover newline:
    </p>
    <pre>
      <code>{`Scanner scanner = new Scanner(System.in);
System.out.print("Enter your name: ");
String name = scanner.next();  // Reads the first token
scanner.nextLine(); // Consume the leftover newline
System.out.print("Enter your full address: ");
String address = scanner.nextLine(); // Now reads the full address properly`}</code>
    </pre><br/>

    <h3>8. Difference Between nextLine() and next():</h3>
    <ul>
      <li><code>nextLine()</code> reads the entire line, including spaces, and returns it as a <code>String</code>.</li><br/>
      <li><code>next()</code> reads only a single token (up to the next whitespace) and returns it as a <code>String</code>.</li>
    </ul><br/>

    <h3>9. Summary:</h3>
    <ul>
      <li>The <code>nextLine()</code> method is used to read an entire line of text, including spaces, from the user input.</li><br/>
      <li>It is especially useful for capturing sentences, paragraphs, or other multi-word inputs.</li><br/>
      <li>It consumes the newline character at the end of the input, which is important when reading input after other methods like <code>next()</code>, <code>nextInt()</code>, etc.</li>
    </ul>

    
  </div>
)}



{selectedChapter === 'chapter21' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   `if` Statement  </h1>

        <p>
          The <code>if</code> statement is a fundamental control flow structure
          in Java that allows conditional execution of a block of code. It
          enables the program to decide whether to execute a certain piece of
          code based on whether a given condition evaluates to <code>true</code>.
        </p><br/>

        <h2 style={{paddingBottom:"6px"}}>Syntax of the <code>if</code> Statement</h2>
        <pre className={style.codeblock}>
          <code>
            {`if (condition) {
    // Code block to execute if the condition is true
}`}
          </code>
        </pre><br/>

        <h2>Key Components</h2>
        <ol>
          <li>
            <strong>Condition:</strong> The condition inside the parentheses{" "}
            <code>(condition)</code> must evaluate to a <code>boolean</code>{" "}
            value: <code>true</code> or <code>false</code>. If the condition is{" "}
            <code>true</code>, the code block inside the <code>&#123;&#125;</code> will execute. If{" "}
            <code>false</code>, the code block is skipped.
          </li><br/>
          <li>
            <strong>Code Block:</strong> A set of one or more statements that
            will only execute if the condition is <code>true</code>. While the{" "}
            <code>&#123;&#125;</code> braces are optional for single-line
            statements, using them is a good practice.
          </li>
        </ol><br/>

        <h2>How It Works</h2>
        <p>
          1. The program evaluates the condition inside the <code>if</code>{" "}
          statement.
        </p>
        <p>
          2. If the condition evaluates to <code>true</code>, the program
          executes the code block inside <code>&#123;&#125;</code>.
        </p>
        <p>3. If the condition evaluates to <code>false</code>, the program skips the code block.</p><br/>

        <h2>Examples</h2>

        <h3>1. Basic <code>if</code> Statement</h3>
        <pre className={style.codeblock}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int number = 10;

        if (number > 5) {
            System.out.println("The number is greater than 5.");
        }

        System.out.println("This statement will always execute.");
    }
}`}
          </code>
        </pre>

        <p><strong>Output:</strong></p>
        <pre className={style.codeblock}>
          <code>
            {`The number is greater than 5.
This statement will always execute.`}
          </code>
        </pre><br/>

        <h3>2. Single-Line <code>if</code> Statement (No Braces)</h3>
        <pre className={style.codeblock}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int number = 3;

        if (number > 0)
            System.out.println("The number is positive.");

        System.out.println("This statement will always execute.");
    }
}`}
          </code>
        </pre>

        <p><strong>Output:</strong></p>
        <pre className={style.codeblock}>
          <code>
            {`The number is positive.
This statement will always execute.`}
          </code>
        </pre><br/>

        <h3>3. Using Logical Operators in the Condition</h3>
        <pre className={style.codeblock}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int age = 20;
        boolean hasID = true;

        if (age >= 18 && hasID) {
            System.out.println("You are eligible to enter.");
        }
    }
}`}
          </code>
        </pre>

        <p><strong>Output:</strong></p>
        <pre className={style.codeblock}>
          <code>You are eligible to enter.</code>
        </pre><br/>

        <h3>4. Nested <code>if</code> Statement</h3>
        <pre className={style.codeblock}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int number = 8;

        if (number > 0) {
            if (number % 2 == 0) {
                System.out.println("The number is positive and even.");
            }
        }
    }
}`}
          </code>
        </pre>

        <p><strong>Output:</strong></p>
        <pre className={style.codeblock}>
          <code>The number is positive and even.</code>
        </pre><br/>

        <h3>5. Practical Application</h3>
        <pre className={style.codeblock}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int age = 17;

        if (age >= 18) {
            System.out.println("You are eligible to vote.");
        }

        System.out.println("Thank you for checking your eligibility.");
    }
}`}
          </code>
        </pre>

        <p><strong>Output:</strong></p>
        <pre className={style.codeblock}>
          <code>Thank you for checking your eligibility.</code>
        </pre><br/>

        <h2>Summary</h2>
        <p>
          - The <code>if</code> statement allows conditional execution of code
          based on a <code>boolean</code> condition.
        </p>
        <pre className={style.codeblock}>
          <code>
            {`if (condition) {
    // Code block to execute if condition is true
}`}
          </code>
        </pre>
        <p>
          It is the simplest form of decision-making and forms the foundation
          for controlling program flow.
        </p>
      </div>
    )}




{selectedChapter === 'chapter22' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  `if-else` Statement    </h1>


        <p>
          The <code>if-else</code> statement is a control structure in Java that allows the execution
          of one block of code if a condition is <code>true</code>, and a different block of code if
          the condition is <code>false</code>. It provides a way to perform conditional logic based
          on a boolean condition.
        </p>

        <br/>

        <h2>Syntax of if-else Statement</h2>
        <pre className={style.code}>
          <code>
            {`if (condition) {
    // Code block executed if condition is true
} else {
    // Code block executed if condition is false
}`}
          </code>
        </pre><br/>

        <h2>How It Works</h2>
        <ol>
          <li>The condition inside the parentheses <code>(condition)</code> is evaluated.</li><br/>
          <li>
            If the condition evaluates to <code>true</code>, the code block inside the{" "}
            <code>if</code> statement is executed, and the <code>else</code> block is skipped.
          </li><br/>
          <li>
            If the condition evaluates to <code>false</code>, the code block inside the{" "}
            <code>else</code> statement is executed, and the <code>if</code> block is skipped.
          </li>
        </ol>

        <br/>

        <h2>Example 1: Basic if-else Statement</h2>
        <pre className={style.code}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int number = 10;

        if (number > 0) {
            System.out.println("The number is positive.");
        } else {
            System.out.println("The number is not positive.");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.output}>
          <code>{`The number is positive.`}</code>
        </pre>

        <br/>

        <h2>Example 2: Checking Even or Odd Number</h2>
        <pre className={style.code}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int number = 7;

        if (number % 2 == 0) {
            System.out.println("The number is even.");
        } else {
            System.out.println("The number is odd.");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.output}>
          <code>{`The number is odd.`}</code>
        </pre>

        <br/>

        <h2>Example 3: Using Logical Operators in the Condition</h2>
        <pre className={style.code}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int age = 20;

        if (age >= 18 && age <= 60) {
            System.out.println("You are eligible for the job.");
        } else {
            System.out.println("You are not eligible for the job.");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.output}>
          <code>{`You are eligible for the job.`}</code>
        </pre>

        <br/>

        <h2>Common Pitfalls</h2>
        <ul>
          <li>
            <strong>Missing Braces:</strong> Omitting braces <code>{`{}`}</code> for single-line
            statements is allowed but can lead to errors if additional statements are added later.
            Always use braces for clarity.
          </li><br/>
          <li>
            <strong>Assignment Instead of Comparison:</strong> Using <code>=</code> (assignment)
            instead of <code>==</code> (comparison) in the condition is a common mistake.
          </li><br/>
          <li>
            <strong>Unreachable Code:</strong> Ensure that both <code>if</code> and{" "}
            <code>else</code> blocks handle valid scenarios. Avoid situations where code becomes
            unreachable.
          </li>
        </ul>

        <br/>

        <h2>Practical Application</h2>
        <h3>Program to Check Voting Eligibility</h3>
        <pre className={style.code}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int age = 17;

        if (age >= 18) {
            System.out.println("You are eligible to vote.");
        } else {
            System.out.println("You are not eligible to vote.");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.output}>
          <code>{`You are not eligible to vote.`}</code>
        </pre>

        <br/>

        <h2>Summary</h2>
        <p>
          - The <code>if-else</code> statement provides a way to execute one block of code when a
          condition is <code>true</code> and another block when it is <code>false</code>.
        </p>
        <pre className={style.code}>
          <code>
            {`if (condition) {
    // Code block if true
} else {
    // Code block if false
}`}
          </code>
        </pre><br/>
        <p>
          It helps make decisions in programs based on logical conditions. Using nested{" "}
          <code>if-else</code> or combining logical operators can help evaluate complex conditions.
        </p>
     
      </div>
    )}



{selectedChapter === 'chapter23' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   `if-else-if` Ladder     </h1>

        <p>
          The <code>if-else-if</code> ladder is used when you need to test multiple conditions
          sequentially. It allows the execution of one block of code among multiple options by
          evaluating conditions in order. As soon as one condition evaluates to{" "}
          <code>true</code>, its corresponding block of code executes, and the rest of the ladder is
          skipped.
        </p>

        <br/>

        <h2>Syntax of the `if-else-if` Ladder</h2>
        <pre className={style.code}>
          <code>
            {`if (condition1) {
    // Code block for condition1
} else if (condition2) {
    // Code block for condition2
} else if (condition3) {
    // Code block for condition3
} else {
    // Code block if none of the above conditions are true
}`}
          </code>
        </pre><br/>

        <h2>How It Works</h2>
        <ol>
          <li>The program evaluates the conditions one by one starting from <code>condition1</code>.</li><br/>
          <li>
            If a condition evaluates to <code>true</code>, its corresponding block of code executes,
            and the ladder ends.
          </li><br/>
          <li>
            If none of the conditions evaluate to <code>true</code>, the code in the <code>else</code>{" "}
            block executes (if present).
          </li>
        </ol>

        <br/>

        <h2>Example 1: Determining Grades</h2>
        <pre className={style.code}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int marks = 85;

        if (marks >= 90) {
            System.out.println("Grade: A+");
        } else if (marks >= 80) {
            System.out.println("Grade: A");
        } else if (marks >= 70) {
            System.out.println("Grade: B");
        } else if (marks >= 60) {
            System.out.println("Grade: C");
        } else {
            System.out.println("Grade: F");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.output}>
          <code>{`Grade: A`}</code>
        </pre>

        <br/>

        <h2>Example 2: Checking Temperature Levels</h2>
        <pre className={style.code}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int temperature = 35;

        if (temperature > 40) {
            System.out.println("It's very hot.");
        } else if (temperature > 30) {
            System.out.println("It's hot.");
        } else if (temperature > 20) {
            System.out.println("It's warm.");
        } else if (temperature > 10) {
            System.out.println("It's cool.");
        } else {
            System.out.println("It's cold.");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.output}>
          <code>{`It's hot.`}</code>
        </pre>

        <br/>

        <h2>Example 3: Finding the Largest of Three Numbers</h2>
        <pre className={style.code}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int a = 10, b = 20, c = 15;

        if (a > b && a > c) {
            System.out.println("a is the largest.");
        } else if (b > a && b > c) {
            System.out.println("b is the largest.");
        } else {
            System.out.println("c is the largest.");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.output}>
          <code>{`b is the largest.`}</code>
        </pre>

        <br/>
        <h2>Common Pitfalls</h2>
        <ul>
          <li>
            <strong>Unreachable `else` Block:</strong> If one of the `if` or `else if` conditions is
            always <code>true</code>, the `else` block will never execute. Ensure conditions are
            mutually exclusive when necessary.
          </li><br/>
          <li>
            <strong>Order of Conditions:</strong> Conditions should be ordered logically from the most
            specific to the most general. For example:
            <pre>
              <code>
                {`if (marks >= 90) { ... }
else if (marks >= 80) { ... }`}
              </code>
            </pre>
            Placing <code>marks {">= 80"}</code> before <code>marks {">= "}90</code> would incorrectly match
            students scoring above 80 but below 90.
          </li><br/>
          <li>
            <strong>Complex Conditions:</strong> Avoid overly complex conditions. Break them into
            smaller, readable parts if needed.
          </li>
        </ul>

        <br/>

        <h2>Practical Application</h2>
        <h3>Program to Display Day of the Week Based on Input</h3>
        <pre className={style.code}>
          <code>
            {`public class Main {
public static void main(String[] args) {
        int day = 3; // Assume day input is 3

        if (day == 1) {
            System.out.println("Monday");
        } else if (day == 2) {
            System.out.println("Tuesday");
        } else if (day == 3) {
            System.out.println("Wednesday");
        } else if (day == 4) {
            System.out.println("Thursday");
        } else if (day == 5) {
            System.out.println("Friday");
        } else if (day == 6) {
            System.out.println("Saturday");
        } else if (day == 7) {
            System.out.println("Sunday");
        } else {
            System.out.println("Invalid day number.");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.output}>
          <code>{`Wednesday`}</code>
        </pre>

        <br/>

        <h2>Summary</h2>
        <p>
          The <code>if-else-if</code> ladder is useful for testing multiple conditions sequentially.
          Syntax:
        </p>
        <pre className={style.code}>
          <code>
            {`if (condition1) {
    // Code block for condition1
} else if (condition2) {
    // Code block for condition2
} else {
    // Code block if none of the above conditions are true
}`}
          </code>
        </pre>
      </div>
    )}



{selectedChapter === 'chapter24' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Nested `if` Statement  </h1>

    <p>
      A <strong>nested `if` statement</strong> is an <code>if</code> statement inside another <code>if</code> statement. It allows you to check multiple conditions in a hierarchical way. The inner <code>if</code> statement is executed only if the condition of the outer <code>if</code> statement is <code>true</code>. This approach is useful when decisions depend on more than one condition.
    </p>

    <br/>

    <h2>Syntax of Nested `if` Statement</h2>
    <pre className={style.code}>
      <code>
        {`if (condition1) {
    // Outer if block
    if (condition2) {
        // Inner if block
    }
}`}
      </code>
    </pre><br/>

    <ol>
      <li>The program first evaluates <code>condition1</code>.</li><br/>
      <li>If <code>condition1</code> is <code>true</code>, it evaluates <code>condition2</code>.</li><br/>
      <li>If both conditions are <code>true</code>, the code inside the inner <code>if</code> block executes.</li><br/>
      <li>If either condition is <code>false</code>, the corresponding block is skipped.</li>
    </ol>

    <br/>

    <h2>Example 1: Checking Eligibility for a Scholarship</h2>
    <pre className={style.code}>
      <code>
        {`public class Main {
public static void main(String[] args) {
        int marks = 85;
        int age = 18;

        if (marks >= 80) { // Outer if block
            if (age >= 18) { // Inner if block
                System.out.println("Eligible for scholarship.");
            }
        }
    }
}`}
      </code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre className={style.output}>
      <code>Eligible for scholarship.</code>
    </pre>

    <br/>

    <h2>Example 2: Categorizing a Number</h2>
    <pre className={style.code}>
      <code>
        {`public class Main {
public static void main(String[] args) {
        int number = 10;

        if (number > 0) { // Outer if block
            if (number % 2 == 0) { // Inner if block
                System.out.println("Positive even number.");
            } else {
                System.out.println("Positive odd number.");
            }
        } else {
            System.out.println("Non-positive number.");
        }
    }
}`}
      </code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre className={style.output}>
      <code>Positive even number.</code>
    </pre>

    <br/>

    <h2>Example 3: Determining a Character's Type</h2>
    <pre className={style.code}>
      <code>
        {`public class Main {
public static void main(String[] args) {
        char ch = 'A';

        if (ch >= 'A' && ch <= 'Z') { // Outer if block
            if (ch == 'A' || ch == 'E' || ch == 'I' || ch == 'O' || ch == 'U') { // Inner if block
                System.out.println("Uppercase vowel.");
            } else {
                System.out.println("Uppercase consonant.");
            }
        }
    }
}`}
      </code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre className={style.output}>
      <code>Uppercase vowel.</code>
    </pre>

    <br/>

    <h2>Key Points</h2>
    <ul>
      <li>
        <strong>Logical Flow:</strong> The inner <code>if</code> executes only when the outer <code>if</code> condition is <code>true</code>.
        Multiple nested levels are allowed, but excessive nesting can make the code harder to read.
      </li><br/>
      <li>
        <strong>Readability:</strong> Always use proper indentation to clearly distinguish between different levels of <code>if</code> statements.
      </li><br/>
      <li>
        <strong>Efficient Conditions:</strong> Write conditions logically to minimize unnecessary checks.
      </li>
    </ul>

    <br/>

    <h2>Common Pitfalls</h2>
    <ul>
      <li>
        <strong>Excessive Nesting:</strong> Too many nested <code>if</code> statements can make the code complex. Consider using logical operators (<code>&&</code>, <code>||</code>) or alternative structures like <code>if-else if</code>.
      </li><br/>
      <li>
        <strong>Forgotten Braces:</strong> Braces <code>{}</code> are optional for single statements, but forgetting them in nested conditions can lead to errors.
        <pre className={style.code}>
          <code>
            {`if (x > 0) 
    if (x < 10) // Works without braces, but hard to read.
        System.out.println("Valid number.");`}
          </code>
        </pre>
      </li><br/>
      <li>
        <strong>Overlapping Conditions:</strong> Ensure that conditions are mutually exclusive or handled appropriately to avoid unexpected behavior.
      </li>
    </ul>

    <br/>

    <h2>Summary</h2>
    <p>
      A nested <code>if</code> statement is used for decision-making where multiple conditions need to be checked in a hierarchy.
      <br />
      <strong>Syntax:</strong>
    </p>
    <pre className={style.code}>
      <code>
        {`if (condition1) {
    if (condition2) {
        // Code for condition1 and condition2
    }
}`}
      </code>
    </pre><br/>
    <p>It is useful for complex decisions but should be used judiciously to maintain code readability.</p>
  </div>
)}





{selectedChapter === 'chapter25' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Switch Statement  </h1>

    <p>The <strong>switch</strong> statement in Java is used to execute one out of many possible blocks of code based on the value of an expression. It’s often used as a cleaner and more efficient alternative to multiple <code>if-else</code> statements when you have several possible conditions to check, especially when those conditions involve checking the value of a single variable.</p>

    <br/>
    <h3>Syntax of the Switch Statement</h3>

    <pre><code>
      {`switch (expression) {
  case value1:
      // Code block for value1
      break;
  case value2:
      // Code block for value2
      break;
  case value3:
      // Code block for value3
      break;
  default:
      // Code block if no case matches
}`}
</code>
    </pre><br/>

    <ul>
      <li><strong>expression</strong>: The variable or expression whose value is being checked.</li><br/>
      <li><strong>case value</strong>: Each <code>case</code> represents a possible match for the value of the expression.</li><br/>
      <li><strong>break</strong>: If a case matches, the corresponding code executes, and the <code>break</code> statement exits the switch statement, preventing further cases from being checked.</li><br/>
      <li><strong>default</strong>: This optional block runs if no <code>case</code> matches the expression’s value.</li>
    </ul>

    <br/>

    <h3>How It Works</h3>

    <ol>
      <li>The <code>switch</code> statement evaluates the expression.</li><br/>
      <li>It compares the result of the expression with each <code>case</code> value.</li><br/>
      <li>If a match is found, the corresponding block of code runs.</li><br/>
      <li>The <code>break</code> statement ensures that once a block is executed, the program exits the switch statement.</li><br/>
      <li>If no case matches, and the <code>default</code> block is present, the code in the <code>default</code> block will execute.</li>
    </ol>

    <br/>

    <h3>Example 1: Basic Switch Statement</h3>

    <pre><code>
      {`public class Main {
public static void main(String[] args) {
        int day = 3;
        
        switch (day) {
            case 1:
                System.out.println("Monday");
                break;
            case 2:
                System.out.println("Tuesday");
                break;
            case 3:
                System.out.println("Wednesday");
                break;
            case 4:
                System.out.println("Thursday");
                break;
            case 5:
                System.out.println("Friday");
                break;
            case 6:
                System.out.println("Saturday");
                break;
            case 7:
                System.out.println("Sunday");
                break;
            default:
                System.out.println("Invalid day number");
        }
    }
}`}
    </code></pre>

    <p><strong>Output:</strong></p>
    <pre>Wednesday</pre>

    <p>In this example:</p>
    <ul>
      <li>The variable <code>day</code> is compared to the <code>case</code> values.</li><br/>
      <li>Since <code>day</code> is <code>3</code>, the code in the <code>case 3</code> block is executed, printing <code>"Wednesday"</code>.</li><br/>
      <li>The <code>break</code> statement ensures that once a match is found, no further cases are checked.</li>
    </ul>

    <br/>

    <h3>Example 2: Switch Statement with Default Case</h3>

    <pre><code>
      {`public class Main {
public static void main(String[] args) {
        char grade = 'B';
        
        switch (grade) {
            case 'A':
                System.out.println("Excellent");
                break;
            case 'B':
                System.out.println("Good");
                break;
            case 'C':
                System.out.println("Average");
                break;
            default:
                System.out.println("Invalid grade");
        }
    }
}`}
    </code></pre>

    <p><strong>Output:</strong></p>
    <pre>Good</pre>

    <p>In this example:</p>
    <ul>
      <li>The variable <code>grade</code> is compared to each <code>case</code> character.</li><br/>
      <li>Since <code>grade</code> is <code>'B'</code>, the code in the <code>case 'B'</code> block is executed, printing <code>"Good"</code>.</li><br/>
      <li>If <code>grade</code> had been anything other than 'A', 'B', or 'C', the <code>default</code> case would execute.</li>
    </ul>

    <br/>

    <h3>Key Points</h3>
    <ul>
      <li><strong>Expression Types</strong>: The expression in a <code>switch</code> statement can be of types <code>byte</code>, <code>short</code>, <code>int</code>, <code>char</code>, <code>enum</code>, and <code>String</code> (in Java 7 and later).</li><br/>
      <li><strong>Fall-Through</strong>: If you forget the <code>break</code> statement, the code will "fall through" to the next <code>case</code> and continue executing. This can be useful if multiple cases should execute the same code.</li><br/>
      <li><strong>Default Case</strong>: The <code>default</code> case is optional but provides a way to handle unexpected values.</li><br/>
      <li><strong>String and Enums</strong>: In Java 7 and later, <code>String</code> values can be used in a <code>switch</code> statement, and Java 5 introduced <code>enum</code> support in <code>switch</code> statements.</li>
    </ul>

    <br/>

    <h3>Common Pitfalls</h3>
    <ul>
      <li><strong>Fall-Through</strong>: Forgetting the <code>break</code> statement can lead to unintended behavior, where multiple <code>case</code> blocks are executed.
        <pre><code>
{`switch (day) {
    case 1:
        System.out.println("Monday");
    case 2:
        System.out.println("Tuesday"); // This will execute even if day is 1
        break;
}`}</code></pre>
      </li><br/>
      <li><strong>Missing Default</strong>: While not mandatory, omitting the <code>default</code> case can make the program behave unpredictably if no matching <code>case</code> is found.</li><br/>
      <li><strong>Limited to Primitive and String Types</strong>: In Java, the expression must evaluate to a primitive type (<code>byte</code>, <code>short</code>, <code>int</code>, <code>char</code>) or <code>String</code> (in Java 7 or later), and you cannot use floating-point types or boolean types in a <code>switch</code>.</li>
    </ul>

    <br/>

    <h3>Summary</h3>
    <p>The <code>switch</code> statement is an efficient way to handle multiple conditions based on the value of a single variable. It improves readability and performance compared to a series of <code>if-else</code> statements when dealing with a large number of conditions. Always ensure to use <code>break</code> statements unless you specifically want fall-through behavior, and consider including a <code>default</code> case for handling unexpected values.</p>

  </div>
)}



{selectedChapter === 'chapter26' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>For Loop  </h1>

    <p>A <strong>for loop</strong> in Java is used to repeatedly execute a block of code for a specific number of times. It is one of the most common looping structures and is very efficient when you know in advance how many times you want to repeat a block of code.</p>
    <br/>
    <h3>Structure of a For Loop</h3>
    <pre>
      <code>
        {`for (initialization; condition; increment/decrement) {
    // Code to be executed
}`}
      </code>
    </pre><br/>

    <p>Here’s a breakdown of each part:</p>
    <ul>
      <li><strong>Initialization</strong>: This part is executed only once, before the loop starts. It is used to initialize variables (often a counter variable).</li><br/>
      <li><strong>Condition</strong>: Before each iteration, this condition is checked. If it evaluates to <code>true</code>, the code inside the loop is executed. If it evaluates to <code>false</code>, the loop ends, and the program moves to the next line of code after the loop.</li><br/>
      <li><strong>Increment/Decrement</strong>: After each iteration, this part is executed. It typically updates the counter (either by incrementing or decrementing it) to move the loop toward completion.</li>
    </ul><br/>

    <h3>Syntax Example</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        // Define the number of iterations
        int loopLimit = 5;

        // Using a for loop to iterate from 0 to loopLimit - 1
        for (int i = 0; i < loopLimit; i++) {
            // Print the current value of i
            System.out.println(i);
        }
    }
}`} </code>
    </pre><br/>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li><code>{`int i = 0`}</code>: The loop starts by initializing the variable <code>i</code> to 0.</li><br/>
      <li><code>{`i < 5`}</code>: The loop runs as long as <code>i</code> is less than 5.</li><br/>
      <li><code>{`i++`}</code>: After each iteration, <code>i</code> is incremented by 1.</li>
    </ul><br/>

    <h4>Output:</h4>
    <pre>
      <code>
        {`0
1
2
3
4`}
      </code>
    </pre><br/>

    <p>In this example:</p>
    <ul>
      <li>The variable <code>i</code> is initialized to 0.</li><br/>
      <li>The condition <code>{`i < 5`}</code> is checked, and if it's true, the loop continues to print the value of <code>i</code>.</li><br/>
      <li>After each iteration, <code>i</code> is incremented by 1, and the loop runs until <code>i</code> reaches 5.</li>
    </ul><br/>

    <h3>Types of For Loops</h3>

    <h4>1. Basic For Loop</h4>
    <p>This is the standard form, which is useful when you know how many times to iterate.</p>
    <pre>
      <code>{`public class Main {
  public static void main(String[] args) {
        // Define the maximum number of iterations
        final int MAX_LIMIT = 10;  // Constant for loop limit

        // Loop from 0 to 9 (10 iterations)
        for (int i = 0; i < MAX_LIMIT; i++) {
            // Print the current number with a descriptive message
            System.out.println("Number: " + i);
        }
    }
}`}</code>
    </pre><br/>

    <h4>2. For-Each Loop (Enhanced For Loop)</h4>
    <p>This type of loop is used to iterate over arrays or collections. It simplifies iteration when you don’t need the index.</p>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        // Array of numbers to iterate through
        int[] numbers = {1, 2, 3, 4, 5};
        
        // Using an enhanced for loop (for-each loop) to print each number
        System.out.println("Array elements are:");
        for (int num : numbers) {
            System.out.println(num);  // Print each number in the array
        }
    }
}`}
      </code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>
        {`1
2
3
4
5`}
      </code>
    </pre><br/>

    <h4>3. Nested For Loop</h4>
    <p>A for loop inside another for loop is called a nested for loop. This is useful when you need to work with multidimensional arrays (like matrices).</p>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        // Outer loop that iterates over 'i'
        for (int i = 0; i < 3; i++) {
            // Inner loop that iterates over 'j'
            for (int j = 0; j < 3; j++) {
                // Print the values of i and j for each iteration
                System.out.println("i: " + i + ", j: " + j);
            }
        }
    }
}`}
      </code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>
        {`i: 0, j: 0
i: 0, j: 1
i: 0, j: 2
i: 1, j: 0
i: 1, j: 1
i: 1, j: 2
i: 2, j: 0
i: 2, j: 1
i: 2, j: 2`}
      </code>
    </pre><br/>

    <h3>Key Points</h3>
    <ul>
      <li><strong>Initialization</strong> happens only once, before the loop starts.</li><br/>
      <li><strong>Condition</strong> is checked before every iteration. If it’s <code>false</code>, the loop terminates.</li><br/>
      <li><strong>Increment/Decrement</strong> happens after each iteration and is typically used to update the loop counter.</li><br/>
      <li><strong>Termination</strong>: When the condition becomes <code>false</code>, the loop stops and control moves to the next line of code.</li>
    </ul><br/>

    <h3>Common Pitfalls to Avoid</h3>
    <ul>
      <li><strong>Infinite Loop</strong>: If the condition never becomes <code>false</code>, the loop will run forever.</li>
      <pre>
        <code>{`public class Main {
public static void main(String[] args) {
        // Start from 10 and decrement until 0
        for (int i = 10; i >= 0; i--) {
            System.out.println(i);
        }
    }
}`} </code>
      </pre>
      <p>This loop will run indefinitely because <code>i</code> is decreasing instead of increasing.</p>
      <br/>
      <li><strong>Skipping Code Inside Loop</strong>: If you forget to modify the counter (or increment/decrement), the loop might run too many times or never end.</li><br/>

      <li><strong>Out-of-Bounds Errors</strong>: In a for loop iterating over an array, ensure the index doesn’t go out of bounds.</li>
    </ul>

    <h3>Summary</h3>
    <ul>
      <li>A <strong>for loop</strong> is used for repeating a block of code a specific number of times, often when the number of iterations is known beforehand.</li><br/>
      <li>It is more compact and clear than using multiple <code>if</code> statements or a <code>while</code> loop when iterating through known ranges.</li>
    </ul>
  </div>
)}




{selectedChapter === 'chapter27' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>While Loop in Java</h1>

    <p>A <strong>while loop</strong> in Java is used to repeatedly execute a block of code as long as a specified condition is <code>true</code>. It is commonly used when the number of iterations is not known in advance, but the loop needs to run as long as a condition holds.</p>

    <br/>

    <h4>Syntax of While Loop</h4>
    <pre><code>while (condition) &#123;
    // Code to be executed
&#125;</code></pre>

    <p><strong>condition</strong>: This is the condition checked before each iteration of the loop. If it evaluates to <code>true</code>, the code inside the loop executes. If it evaluates to <code>false</code>, the loop ends, and the program continues with the next statement after the loop.</p>

    <br/>

    <h4>How the While Loop Works:</h4>
    <ol>
      <li><strong>Condition Check:</strong> Before each iteration, the condition is evaluated.</li><br/>
      <li><strong>Loop Execution:</strong> If the condition is <code>true</code>, the code inside the loop is executed.</li><br/>
      <li><strong>Repeat:</strong> After each execution, the condition is checked again. The loop continues as long as the condition remains <code>true</code>.</li><br/>
      <li><strong>Termination:</strong> The loop stops once the condition becomes <code>false</code>.</li>
    </ol><br/>

    <h4>Key Points:</h4>
    <ul>
      <li><strong>Condition Evaluation:</strong> The condition is checked before each iteration. If it's <code>true</code>, the loop continues. If it's <code>false</code>, the loop stops.</li><br/>
      <li><strong>Infinite Loop:</strong> If the condition never becomes <code>false</code>, the loop will run indefinitely. This can happen if the condition is always true or if the loop's internal code doesn't change the condition.</li><br/>
      <li><strong>Counter Management:</strong> You must manually update the condition or use a counter inside the loop to avoid infinite loops.</li>
    </ul>

    <br/>

    <h4>Example 1: Basic While Loop</h4>
    <pre><code>{`public class Main {
public static void main(String[] args) {
        int i = 0; // Initialization
        while (i < 5) { // Condition
            System.out.println(i); // Code to execute
            i++; // Increment
        }
    }
}`}</code></pre>

    <h5>Output:</h5>
    <pre><code>{`0
1
2
3
4`}</code></pre><br/>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li>The loop starts with <code>i = 0</code>.</li><br/>
      <li>The condition <code>i &lt; 5</code> is checked before each iteration. If <code>i</code> is less than 5, the loop continues.</li><br/>
      <li>After each iteration, <code>i</code> is incremented by 1.</li><br/>
      <li>The loop stops when <code>i</code> reaches 5 because the condition <code>i &lt; 5</code> becomes <code>false</code>.</li>
    </ul>

    <br/>

    <h4>Types of While Loops in Java</h4>

    <h5>1. Basic While Loop (Standard While Loop)</h5>
    <p>This is the standard <code>while</code> loop where the condition is checked at the beginning of the loop, and the loop continues as long as the condition evaluates to <code>true</code>.</p>
    <pre><code>while (condition) &#123;
    // Code to execute
&#125;</code></pre>

    <h5>Example:</h5>
    <pre><code>{`public class Main {
public static void main(String[] args) {
        int num = 1;
        while (num <= 5) {
            System.out.println(num);
            num++;
        }
    }
}`}</code></pre>

    <h5>Output:</h5>
    <pre><code>{`1
2
3
4
5`}</code></pre>

<br/>

    <h5>2. Do-While Loop (Post-Condition Loop)</h5>
    <p>In a <strong>do-while loop</strong>, the condition is checked <strong>after</strong> the loop is executed, which ensures that the code inside the loop runs <strong>at least once</strong>, regardless of the condition.</p>
    <pre><code>do &#123;
    // Code to execute
&#125; while (condition);</code></pre>

    <h5>Example:</h5>
    <pre><code>{`public class Main {
public static void main(String[] args) {
        int num = 1;
        do {
            System.out.println(num);
            num++;
        } while (num <= 5);
    }
}`}</code></pre>

    <h5>Output:</h5>
    <pre><code>{`1
2
3
4
5`}</code></pre>

<br/>

    <h5>3. Infinite While Loop (Endless Loop)</h5>
    <p>An <strong>infinite while loop</strong> is a loop that runs indefinitely because the condition is always <code>true</code> (or never changes to <code>false</code> inside the loop).</p>
    <pre><code>while (true) &#123;
    // Code to execute
&#125;</code></pre>

    <h5>Example (Infinite Loop):</h5>
    <pre><code>{`public class Main {
public static void main(String[] args) {
        int i = 0;
        while (true) {
            System.out.println(i);
            i++;
            if (i > 10) {
                break; // Break the loop after 10 iterations
            }
        }
    }
}`}</code></pre>

    <h5>Output:</h5>
    <pre><code>{`0
1
2
3
4
5
6
7
8
9
10`}</code></pre>

<br/>

    <h4>Key Differences Between the Types of While Loops</h4>
    <table>
      <thead>
        <tr>
          <th>Loop Type</th>
          <th>Condition Check</th>
          <th>Code Execution</th>
          <th>Use Case</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>Basic While</strong></td>
          <td>Before loop</td>
          <td>May not execute</td>
          <td>When the number of iterations is not known in advance</td>
        </tr>
        <tr>
          <td><strong>Do-While</strong></td>
          <td>After loop</td>
          <td>Executes at least once</td>
          <td>When you need the code to run at least once, regardless of the condition</td>
        </tr>
        <tr>
          <td><strong>Infinite While</strong></td>
          <td>Always true</td>
          <td>Infinite execution until broken</td>
          <td>When you want the loop to run indefinitely until a specific condition or event occurs</td>
        </tr>
      </tbody>
    </table>

    <br/>

    <h4>Common Pitfalls to Avoid:</h4>
    <ul>
      <li><strong>Infinite Loop:</strong> Forgetting to update the condition or counter can lead to an infinite loop. Make sure the condition will eventually become <code>false</code>.</li><br/>
      <li><strong>Incorrect Condition:</strong> Ensure the condition is logically correct, as it determines when the loop will stop.</li><br/>
      <li><strong>Lack of Break:</strong> If you need to break out of a loop, use the <code>break</code> statement to exit the loop early.</li>
    </ul>

    <br/>

    <h4>Summary</h4>
    <ul>
      <li>A <strong>while loop</strong> is used to repeat a block of code as long as a condition remains <code>true</code>.</li><br/>
      <li>It is useful when the number of iterations is not predetermined.</li><br/>
      <li>The <strong>do-while</strong> loop ensures that the code runs at least once, regardless of the condition.</li><br/>
      <li>An <strong>infinite loop</strong> runs indefinitely until stopped explicitly.</li>
    </ul>
  </div>
)}




{selectedChapter === 'chapter28' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Do-While Loop  </h1>

    <p>
      A <strong>do-while loop</strong> in Java is similar to the <code>while</code> loop, but with a key difference: the condition is checked <strong>after</strong> the loop is executed, ensuring that the code inside the loop is always executed at least once, regardless of whether the condition is true or false.
    </p><br/>

    <h2>Syntax of the do-while Loop:</h2>
    <pre><code>{`do {
    // Code to be executed
} while (condition);`}</code></pre>

    <ul>
      <li><strong>do</strong>: The loop starts with the <code>do</code> keyword, which defines the block of code to be executed.</li><br/>
      <li><strong>Code to execute</strong>: This is the block of code that will run inside the loop.</li><br/>
      <li><strong>while (condition)</strong>: After the code block is executed, the condition is checked. If the condition evaluates to <code>true</code>, the loop will run again. If it evaluates to <code>false</code>, the loop stops.</li>
    </ul><br/>

    <h2>How the do-while Loop Works:</h2>
    <ol>
      <li><strong>Execute Code</strong>: The code inside the <code>do</code> block is executed <strong>first</strong>.</li><br/>
      <li><strong>Condition Check</strong>: After the code block runs, the condition is evaluated.</li><br/>
      <li><strong>Repeat</strong>: If the condition is <code>true</code>, the loop will run again. If it is <code>false</code>, the loop will terminate.</li><br/>
      <li><strong>Guaranteed Execution</strong>: Since the condition is checked after the first execution, the code inside the loop will always run at least once, even if the condition is initially <code>false</code>.</li>
    </ol><br/>

    <h2>Key Points:</h2>
    <ul>
      <li><strong>At Least One Execution</strong>: Unlike the <code>while</code> loop, the <code>do-while</code> loop guarantees that the code inside the loop will be executed at least once, no matter what.</li><br/>
      <li><strong>Condition Evaluation</strong>: The condition is checked <strong>after</strong> the loop’s code block is executed.</li><br/>
      <li><strong>Useful for User Input</strong>: It’s particularly useful when you want to prompt the user for input and need the prompt to appear at least once.</li>
    </ul><br/>

    <h2>Example 1: Basic do-while Loop</h2>
    <pre><code>{`public class Main {
public static void main(String[] args) {
        int i = 1;
        do {
            System.out.println(i);  // Print the current value of i
            i++;  // Increment i
        } while (i <= 5);  // Continue looping as long as i is less than or equal to 5
    }
}`}</code></pre>

    <h3>Output:</h3>
    <pre>{`
      1
      2
      3
      4
      5
   `} </pre><br/>

    <h3>Explanation:</h3>
    <p>
      - The loop begins by executing the code inside the <code>do</code> block, which prints the value of <code>i</code> (starting at 1) and increments it.
      - After the code executes, the condition <code>i &lt;= 5</code> is checked.
      - Since <code>i</code> starts at 1 and increments after each iteration, the loop will continue until <code>i</code> is greater than 5, at which point the condition becomes false and the loop stops.
    </p><br/>

    <h2>Example 2: do-while Loop with User Input</h2>
    <pre><code>{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number;
        int i = 1;
        do {
            
            System.out.println("Enter a number greater than 10:");
            number = scanner.nextInt(); 
            i++;
        }
         while (number <= 10);  
        
        System.out.println("You entered: " + number);
        scanner.close();
   }
}`}</code></pre><br/>

    <h3>Explanation:</h3>
    <p>
      - The loop asks the user to enter a number.
      - If the user enters a number less than or equal to 10, the loop will repeat and ask for the number again.
      - Once the user enters a number greater than 10, the condition <code>number &lt;= 10</code> becomes <code>false</code>, and the loop stops.
    </p><br/>

    <h2>Key Differences Between do-while and while Loops:</h2>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th><strong>do-while Loop</strong></th>
          <th><strong>while Loop</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Condition Check</td>
          <td>After the loop body (guaranteed execution)</td>
          <td>Before the loop body (may not execute if condition is false)</td>
        </tr>
        <tr>
          <td>Execution Guarantee</td>
          <td>Executes at least once</td>
          <td>May not execute at all if condition is false initially</td>
        </tr>
        <tr>
          <td>Ideal Use Case</td>
          <td>When you want the code to run at least once (e.g., for user input validation)</td>
          <td>When you want the loop to run only if the condition is initially true</td>
        </tr>
      </tbody>
    </table><br/>

    <h2>Summary:</h2>
    <ul>
      <li>The <strong>do-while loop</strong> in Java ensures that the code inside the loop runs at least once, even if the condition is false at the beginning.</li><br/>
      <li>It’s often used when you need to execute the loop body once before checking the condition (such as prompting for user input).</li><br/>
      <li>The condition is checked after the loop’s body executes, which is the key difference from the regular <code>while</code> loop.</li>
    </ul>
  </div>
)}



{selectedChapter === 'chapter29' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  The for-each loop   </h1>

        <p>
          The <strong>for-each loop</strong> in Java is a special type of loop used to iterate through elements of an array or a collection (like a list or set) without needing to manage the index or manually access each element. It's a simplified version of the regular <code>for</code> loop and is especially useful when you only need to process elements without caring about their positions.
        </p><br/>

        <h3>Syntax of the For-Each Loop:</h3>
        <pre>
          <code>
            {`for (dataType element : collection) {
    // Code to execute using the element
}`}
          </code>
        </pre><br/>
        <ul>
          <li><strong>dataType:</strong> The type of elements in the array or collection (e.g., <code>int</code>, <code>String</code>, <code>Object</code>).</li><br/>
          <li><strong>element:</strong> A temporary variable that holds each item in the array or collection during each iteration.</li><br/>
          <li><strong>collection:</strong> This is the array or collection (like a <code>List</code>, <code>Set</code>, or array) that you're iterating over.</li>
        </ul><br/>

        <h3>How the For-Each Loop Works:</h3>
        <ol>
          <li><strong>Iterates over the Collection:</strong> It goes through each element of the collection one by one.</li><br/>
          <li><strong>Assigns the Current Element:</strong> The current element is assigned to the <code>element</code> variable.</li><br/>
          <li><strong>Executes the Code Block:</strong> The code inside the loop runs once for each element in the collection.</li>
        </ol><br/>

        <h3>Key Points:</h3>
        <ul>
          <li><strong>No Indexing:</strong> Unlike the regular <code>for</code> loop, you don’t have to manage indices or worry about going out of bounds.</li><br/>
          <li><strong>Read-Only:</strong> The for-each loop is mainly for accessing the elements, and you cannot modify the collection (array or list) directly during iteration. If you need to modify elements, you should use a traditional <code>for</code> loop or an iterator.</li><br/>
          <li><strong>Only for Collections/Arrays:</strong> It works with arrays, <code>List</code>, <code>Set</code>, and other collections, but not with maps (since maps have key-value pairs).</li>
        </ul><br/>

        <h3>Example 1: Iterating Over an Array</h3>
        <pre>
          <code>{`public class Main {
public static void main(String[] args) {
        int[] numbers = {1, 2, 3, 4, 5};

        // Using the enhanced for loop (for-each loop) to iterate over the array
        for (int num : numbers) {
            System.out.println(num);  // Prints each number in the array
        }
    }
}`}
          </code>
        </pre>
        <p><strong>Output:</strong></p>
        <pre>
          <code>
            {`1
2
3
4
5`}
          </code>
        </pre><br/>

        <h3>Example 2: Iterating Over a List</h3>
        <pre>
          <code>
            {`import java.util.ArrayList;

public class Main {
  public static void main(String[] args) {
      ArrayList<String> names = new ArrayList<>();
      names.add("Alice");
      names.add("Bob");
      names.add("Charlie");

      for (String name : names) {
          System.out.println(name);  // Prints each name in the list
      }
  }
}`}
          </code>
        </pre>
        <p><strong>Output:</strong></p>
        <pre>
          <code>
            {`Alice
Bob
Charlie`}
          </code>
        </pre><br/>

        <h3>Example 3: Modifying Elements (Using Traditional For Loop)</h3>
        <pre>
          <code>
            {`public class Main {
  public static void main(String[] args) {
      int[] numbers = {1, 2, 3, 4, 5};
  
      // Traditional for loop for modification
      for (int i = 0; i < numbers.length; i++) {
          numbers[i] = numbers[i] * 2;  // Modify the array elements
      }
  
      // Print the modified array
      for (int num : numbers) {
          System.out.println(num);
      }
  }
}`}
          </code>
        </pre>
        <p><strong>Output:</strong></p>
        <pre>
          <code>
            {`2
4
6
8
10`}
          </code>
        </pre><br/>

        <h3>When to Use the For-Each Loop:</h3>
        <ul>
          <li>When you need to iterate through all elements of an array or collection without worrying about their positions.</li><br/>
          <li>When you don’t need to modify the elements of the collection directly.</li><br/>
          <li>It’s simpler and more readable compared to using an index-based loop.</li>
        </ul><br/>

        <h3>Summary:</h3>
        <ul>
          <li>The <strong>for-each loop</strong> is a simple, readable way to iterate over collections like arrays, lists, and sets.</li><br/>
          <li>It’s used when you don’t need to access the index or modify the collection during iteration.</li><br/>
          <li>It’s not suitable for modifying elements, and it doesn’t work with maps directly.</li>
        </ul>
      </div>
    )}



{selectedChapter === 'chapter30' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Break Statement  </h1>

    <p>
      In Java, the <strong>break statement</strong> is used to terminate the current loop or switch statement. It allows the program to exit out of a loop or switch case before the loop or switch statement naturally completes. This can be very useful in scenarios where you want to stop the execution under certain conditions.
    </p><br/>

    <h2>How the <code>break</code> Statement Works:</h2>
    <ul>
      <li><strong>Inside Loops (for, while, do-while)</strong>: When the <code>break</code> statement is encountered, the loop is immediately terminated, and the program continues executing the code that follows the loop.</li><br/>
      <li><strong>Inside Switch Statements</strong>: The <code>break</code> statement is used to terminate a case in a switch statement. Without it, the program will continue to execute the next case (this is called "fall-through").</li>
    </ul><br/>

    <h2>Syntax of the <code>break</code> Statement:</h2>
    <pre><code>break;  // Exits from the current loop or switch</code></pre><br/>

    <h2>1. Using <code>break</code> in a Loop:</h2>
    <p>
      When a <code>break</code> statement is used inside a loop, it exits the loop and continues executing the code that follows the loop.
    </p>
    
    <h3>Example: <code>break</code> in a <code>for</code> loop</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        for (int i = 1; i <= 10; i++) {
            if (i == 5) {
                break;  // Exits the loop when i equals 5
            }
            System.out.println(i);  // Print numbers from 1 to 4
        }
        System.out.println("Loop exited.");
    }
}`}</code>
    </pre>

    <h4>Output:</h4>
    <pre><code>{`1
2
3
4
Loop exited.`}</code></pre><br/>

    <h2>2. Using <code>break</code> in a <code>while</code> Loop:</h2>
    <p>The <code>break</code> statement can also be used in a <code>while</code> loop to exit the loop early.</p>

    <h3>Example: <code>break</code> in a <code>while</code> loop</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        int i = 1;
        while (i <= 10) {
            if (i == 6) {
                break;  // Exits the loop when i equals 6
            }
            System.out.println(i);  // Print numbers from 1 to 5
            i++;
        }
        System.out.println("While loop exited.");
    }
}`}</code>
    </pre>

    <h4>Output:</h4>
    <pre><code>{`1
2
3
4
5
While loop exited.`}</code></pre><br/>

    <h2>3. Using <code>break</code> in a <code>switch</code> Statement:</h2>
    <p>In a <code>switch</code> statement, the <code>break</code> statement prevents "fall-through," where the code keeps executing into the next case even if it doesn't match.</p>

    <h3>Example: <code>break</code> in a <code>switch</code> statement</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        int day = 3;

        switch (day) {
            case 1:
                System.out.println("Monday");
                break;  // Exits the switch statement after printing Monday
            case 2:
                System.out.println("Tuesday");
                break;  // Exits the switch statement after printing Tuesday
            case 3:
                System.out.println("Wednesday");
                break;  // Exits the switch after printing Wednesday
            case 4:
                System.out.println("Thursday");
                break;  // Exits the switch statement after printing Thursday
            default:
                System.out.println("Invalid day");  // Executes if no case matches
        }
    }
}`}</code>
    </pre><br/>

    <h4>Output:</h4>
    <pre><code>Wednesday</code></pre>

    <h2>Key Points About the <code>break</code> Statement:</h2>
    <ul>
      <li><strong>Exiting Loops</strong>: The <code>break</code> statement is used to exit loops (<code>for</code>, <code>while</code>, <code>do-while</code>) before they complete naturally.</li><br/>
      <li><strong>Exiting <code>switch</code> Cases</strong>: It is also used in <code>switch</code> statements to prevent the program from continuing to execute subsequent cases.</li><br/>
      <li><strong>One Level of Loop</strong>: The <code>break</code> statement only breaks out of the loop or <code>switch</code> statement in which it is directly placed. It does not affect outer loops or blocks.</li><br/>
      <li><strong>Usage in Complex Scenarios</strong>: You can use <code>break</code> with conditional statements to optimize loops (e.g., searching for an element in an array).</li>
    </ul>
    <br/>
    <h2>Summary:</h2>
    <p>The <code>break</code> statement is useful when you need to stop a loop or <code>switch</code> case under certain conditions. It immediately exits the current loop or <code>switch</code> statement and continues with the rest of the program.</p>
  </div>
)}


{selectedChapter === 'chapter31' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Continue Statement  </h1>
    <p>
      In Java, the <strong>`continue`</strong> statement is used to skip the current iteration of a loop and proceed to the next iteration. It is commonly used when you want to skip certain steps in a loop without terminating the loop entirely.
    </p><br/>

    <h3>How the <strong>continue</strong> Statement Works:</h3>
    <ul>
      <li>When the <strong>`continue`</strong> statement is encountered inside a loop (<em>for</em>, <em>while</em>, or <em>do-while</em>), the remaining code in the current iteration is skipped.</li><br/>
      <li>The loop then proceeds to the next iteration (or checks the condition again if in a <em>while</em> or <em>do-while</em> loop).</li>
    </ul><br/>

    <h3>Syntax of the <strong>`continue`</strong> Statement:</h3>
    <pre><code>continue;</code></pre>
    <p>
      The <strong>`continue`</strong> statement skips the current iteration of the loop and proceeds with the next iteration.
    </p><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Inside Loops:</strong> The <strong>`continue`</strong> statement only affects loops, and it can be used to skip certain conditions in the loop body.</li><br/>
      <li><strong>Does Not Terminate the Loop:</strong> Unlike the <strong>`break`</strong> statement, which exits the loop entirely, the <strong>`continue`</strong> statement only skips the current iteration and proceeds with the next one.</li><br/>
      <li><strong>Can Be Used in All Loop Types:</strong> You can use the <strong>`continue`</strong> statement in a <em>for</em> loop, <em>while</em> loop, and <em>do-while</em> loop.</li>
    </ul><br/>

    <h3>Example 1: Using <strong>`continue`</strong> in a <em>for</em> loop:</h3>
    <pre><code>{`public class Main {
public static void main(String[] args) {
          for (int i = 1; i <= 10; i++) {
            if (i == 5) {
              continue;  // Skips the rest of the code when i equals 5
            }
            System.out.println(i);  // Prints numbers from 1 to 10, except 5
        }
     }
}`}</code></pre>
    <h4>Output:</h4>
    <pre><code>{`1
2
3
4
6
7
8
9
10`}</code></pre>
    <p>Explanation: When <em>i</em> equals 5, the <strong>continue</strong> statement is executed, skipping the printing for that iteration, and the loop continues with the next iteration.</p>
    <br/>
    <h3>Example 2: Using <strong>`continue`</strong> in a <em>while</em> loop:</h3>
    <pre><code>{`public class Main {
public static void main(String[] args) {
      int i = 1;
      while (i <= 10) {
        if (i == 7) {
          i++;  // Increment first before continue to avoid infinite loop
          continue;  // Skips the rest of the loop when i equals 7
        }
        System.out.println(i);  // Prints numbers from 1 to 10, except 7
        i++;
      }
   }
}`}</code></pre>
    <h4>Output:</h4>
    <pre><code>{`1
2
3
4
5
6
8
9
10`} </code></pre>
    <p>Explanation: In this <em>while</em> loop, when <em>i</em> equals 7, the <strong>continue</strong> statement skips the printing of 7 and continues the loop with the next iteration.</p>
    <br/>
    <h3>Example 3: Using <strong>`continue`</strong> in a <em>do-while</em> loop:</h3>
    <pre><code>{`public class Main {
public static void main(String[] args) {
          int i = 1;
          do {
            if (i == 3) {
              i++;
              continue;  // Skips the rest of the code when i equals 3
            }
            System.out.println(i);  // Prints numbers from 1 to 5, except 3
            i++;
          } while (i <= 5);
      }
}`}</code></pre>
    <h4>Output:</h4>
    <pre><code>{`1
2
4
5`}</code></pre>
    <p>Explanation: When <em>i</em> equals 3, the <strong>continue</strong> statement is triggered, skipping the printing of 3, and the loop continues with the next value of <em>i</em>.</p>
    <br/>
    <h3>Key Differences Between <strong>`break`</strong> and <strong>`continue`</strong>:</h3>
    <ul>
      <li><strong>`break`</strong>: Exits the loop entirely, and the program continues executing the code that follows the loop.</li><br/>
      <li><strong>`continue`</strong>: Skips the current iteration and proceeds with the next iteration of the loop.</li>
    </ul><br/>

    <h3>When to Use the <strong>`continue`</strong> Statement:</h3>
    <ul>
      <li><strong>Skipping Specific Iterations:</strong> Use <strong>`continue`</strong> when you want to skip some iterations of a loop based on a condition, such as when processing a list and skipping certain values.</li><br/>
      <li><strong>Cleaner Code:</strong> It can help avoid deep nesting of <strong>if</strong> statements by allowing you to skip to the next loop iteration when a condition is met.</li>
    </ul><br/>

    <h3>Summary:</h3>
    <p>The <strong>`continue`</strong> statement in Java helps you skip over the current iteration of a loop and continue with the next iteration. It’s useful for conditions where you don’t want to execute the remaining code inside a loop but still want the loop to continue running for the next items.</p>
  </div>
)}



{selectedChapter === 'chapter32' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Return Statement in Java</h1>

    <p>
      In Java, the <strong>return</strong> statement is used to exit a method and optionally return a value to the caller. When the <code>return</code> statement is executed, the control is passed back to the point where the method was called. It can be used in methods that have a return type other than <code>void</code>, and its primary purpose is to provide the result from the method.
    </p><br/>

    <h2>Key Points:</h2>
    <ul>
      <li><strong>Returning from a Method</strong>: The <code>return</code> statement ends the execution of the method and optionally sends a value back to the caller.</li><br/>
      <li><strong>Return Type</strong>: If the method has a return type other than <code>void</code>, the <code>return</code> statement must provide a value of that type. For methods with a <code>void</code> return type, the <code>return</code> statement can be used without returning any value (<code>return;</code>).</li><br/>
      <li><strong>Control Flow</strong>: When a <code>return</code> statement is encountered, the method's execution halts, and any code after the <code>return</code> statement is not executed.</li>
    </ul><br/>

    <h2>Syntax:</h2>
    <pre>
      <code>{`
return value;  // For methods with a return type (other than void)
return;        // For methods with a void return type
`}</code>
    </pre><br/>

    <h2>1. Using <code>return</code> in a Method with a Return Type</h2>
    <p>If the method is supposed to return a value (like <code>int</code>, <code>String</code>, etc.), the <code>return</code> statement must return a value of that type.</p>

    <h3>Example: Method with an <code>int</code> return type</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        int result = addNumbers(5, 3);
        System.out.println("The sum is: " + result);
    }

    public static int addNumbers(int a, int b) {
        return a + b; // Returns the sum of a and b
    }
}`}</code>
    </pre>

    <p><strong>Output:</strong></p>
    <pre>
      <code>The sum is: 8</code>
    </pre>

    <h3>Explanation:</h3>
    <p>The method <code>addNumbers</code> returns the sum of <code>a</code> and <code>b</code>. The <code>return</code> statement sends the sum back to the caller, which stores the result in the <code>result</code> variable and prints it.</p>
    <br/>
    <h2>2. Using <code>return</code> in a Void Method</h2>
    <p>In a method with a <code>void</code> return type (i.e., a method that does not return a value), the <code>return</code> statement is used to exit the method early.</p>

    <h3>Example: <code>void</code> method with <code>return</code></h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        printMessage(0);  // Will call the method with 0 and exit early
        printMessage(1);  // Will call the method with 1 and print the message
    }

    public static void printMessage(int num) {
        if (num == 0) {
            return;  // Exits the method early if num is 0
        }
        System.out.println("The number is: " + num);
    }
}`}</code>
    </pre>

    <p><strong>Output:</strong></p>
    <pre>
      <code>The number is: 1</code>
    </pre>

    <h3>Explanation:</h3>
    <p>The <code>printMessage</code> method exits early when <code>num == 0</code> because of the <code>return;</code> statement. It doesn't print anything if <code>num</code> is 0. If <code>num</code> is not 0, it prints the message.</p>
    <br/>
    <h2>3. Returning Multiple Values</h2>
    <p>Java does not allow returning multiple values directly from a method, but you can use objects, arrays, or collections to return multiple values.</p>

    <h3>Example: Using an array to return multiple values</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        int[] result = getValues(); // Calls the method to get the array of values
        System.out.println("First value: " + result[0]); // Prints the first value
        System.out.println("Second value: " + result[1]); // Prints the second value
    }

    public static int[] getValues() {
        int[] values = {5, 10}; // Creates an array with two values
        return values; // Returns the array
    }
}`}</code>
    </pre>

    <p><strong>Output:</strong></p>
    <pre>
    <code>{`First value: 5
Second value: 10`}</code>
    </pre>

    <h3>Explanation:</h3>
    <p>The method <code>getValues</code> returns an array containing two values, and the calling method prints these values.</p>
    <br/>
    <h2>Key Points About the <code>return</code> Statement:</h2>
    <ul>
      <li><strong>Exits the Method</strong>: When <code>return</code> is executed, the method immediately ends, and the program control returns to the point where the method was called.</li><br/>
      <li><strong>Return Value</strong>: If the method has a return type other than <code>void</code>, a value must be returned of the specified type.</li><br/>
      <li><strong>Void Methods</strong>: For methods with a <code>void</code> return type, <code>return</code> can be used without any value to exit the method early.</li>
    </ul><br/>

    <h2>Summary:</h2>
    <p>The <code>return</code> statement is used to exit a method and optionally return a value. In non-<code>void</code> methods, it provides the result back to the caller, while in <code>void</code> methods, it is used to terminate the method's execution early.</p>
  </div>
)}




{selectedChapter === 'chapter33' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to Array </h1>
      
      <p>
        In Java, an <strong>array</strong> is a collection of elements of the same type stored in a contiguous block of memory. Arrays are used to store multiple values in a single variable instead of declaring separate variables for each value.
      </p><br/>

      <h2>Key Points about Arrays:</h2>
      <ul>
        <li><strong>Fixed Size</strong>: Once an array is created, its size cannot be changed. You must know how many elements you want to store in advance.</li><br/>
        <li><strong>Homogeneous</strong>: All elements in an array must be of the same type, such as all integers, strings, or objects.</li><br/>
        <li><strong>Indexed</strong>: Each element in an array is accessed using an index, starting from 0. The first element has index 0, the second element has index 1, and so on.</li>
      </ul><br/>

      <h3>Syntax to Declare an Array:</h3>
      <pre>
        <code>
          {`type[] arrayName;   // Type is the data type (e.g., int, String)`}
        </code>
      </pre>
      <p>Example:</p>
      <pre>
        <code>
          {`int[] numbers;  // Declare an array of integers`}
        </code>
      </pre>
      <br/>
      <h3>Syntax to Initialize an Array:</h3>
      <p>You can initialize an array in two ways:</p>
      <h4>1. Static Initialization</h4>
      <pre>
        <code>
          {`int[] numbers = {1, 2, 3, 4, 5};  // Array with values`}
        </code>
      </pre>
      <br/>
      <h4>2. Dynamic Initialization</h4>
      <pre>
        <code>
          {`int[] numbers = new int[5];  // Array of size 5, initially filled with 0`}
        </code>
      </pre>
      <br/>
      <h3>Accessing Elements:</h3>
      <p>Array elements are accessed using their index:</p>
      <pre>
        <code>
          {`int firstNumber = numbers[0];  // Access the first element (index 0)`}
        </code>
      </pre>

      <h3>Example:</h3>
      <pre>
        <code>
          {`public class Main {
public static void main(String[] args) {
        // Declare and initialize an array
        int[] numbers = {10, 20, 30, 40, 50};

        // Accessing elements of the array
        System.out.println("First number: " + numbers[0]);
        System.out.println("Second number: " + numbers[1]);

        // Changing an element
        numbers[2] = 35;
        System.out.println("Updated third number: " + numbers[2]);
    }
}`}
        </code>
      </pre>

      <h3>Output:</h3>
      <pre>
        <code>
          {`
First number: 10
Second number: 20
Updated third number: 35
          `}
        </code>
      </pre>
      <br/>
      <h3>Important Points:</h3>
      <ul>
        <li><strong>Length of Array</strong>: The size of the array is fixed when it is created, but you can find out the length of an array using the <code>length</code> property:</li>
      </ul><br/>
      <pre>
        <code>
          {`System.out.println("Array length: " + numbers.length);`}
        </code>
      </pre><br/>

      <h3>Summary:</h3>
      <ul>
        <li>An array allows you to store multiple values of the same type in a single variable.</li><br/>
        <li>Arrays in Java are fixed in size, and the elements are indexed starting from 0.</li><br/>
        <li>You can declare, initialize, and access arrays easily in Java.</li>
      </ul>
    </div>
  )}



{selectedChapter === 'chapter34' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Single-Dimensional Arrays  </h1>

    <p>
      In Java, a <strong>single-dimensional array</strong> is a simple type of array that stores a sequence of elements, all of the same type, in a linear form. Each element is accessed using a single index, and the array is treated as a list of values that can be manipulated individually.
    </p><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Fixed Size:</strong> Once you define the size of the array, it cannot be changed. The size must be known at the time of creation.</li><br/>
      <li><strong>Indexed Access:</strong> Each element is accessed via its index, starting from <code>0</code> for the first element, <code>1</code> for the second, and so on.</li><br/>
      <li><strong>Homogeneous:</strong> All elements of a single-dimensional array must be of the same type, such as <code>int</code>, <code>double</code>, <code>String</code>, etc.</li>
    </ul><br/>

    <h3>Syntax to Declare a Single-Dimensional Array:</h3>
    <pre>
      <code>{`type[] arrayName;  // Syntax for declaring an array`}</code>
    </pre>
    <p>
      Here, <code>type</code> refers to the data type of the elements in the array, such as <code>int</code>, <code>String</code>, etc., and <code>arrayName</code> is the name of the array.
    </p><br/>

    <h3>Syntax to Initialize a Single-Dimensional Array:</h3>
    <p>There are two main ways to initialize a single-dimensional array in Java:</p>

    <h4>1. Static Initialization (initialize with values directly):</h4>
    <pre>
      <code>{`int[] numbers = {10, 20, 30, 40, 50};  // Array with values`}</code>
    </pre>
    <br/>
    <h4>2. Dynamic Initialization (initialize with a specific size, then populate later):</h4>
    <pre>
      <code>{`int[] numbers = new int[5];  // Array of size 5, initially filled with 0`}</code>
    </pre><br/>

    <h3>Accessing Array Elements:</h3>
    <p>You can access or modify the elements of a single-dimensional array using the index:</p>
    <pre>
      <code>{`int firstNumber = numbers[0];  // Access the first element (index 0)`}</code>
    </pre>
    <pre>
      <code>{`numbers[2] = 35;  // Modify the third element (index 2)`}</code>
    </pre><br/>

    <h3>Example of Single-Dimensional Array:</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        // Declare and initialize a single-dimensional array
        int[] numbers = {10, 20, 30, 40, 50};

        // Accessing elements of the array
        System.out.println("First number: " + numbers[0]);  // Access element at index 0
        System.out.println("Second number: " + numbers[1]); // Access element at index 1

        // Changing an element of the array
        numbers[2] = 35;  // Change element at index 2
        System.out.println("Updated third number: " + numbers[2]); // Output updated value
    }
}`}</code>
    </pre>

    <h3>Output:</h3>
    <pre>
      <code>{`First number: 10
Second number: 20
Updated third number: 35`}</code>
    </pre><br/>

    <h3>Important Points:</h3>
    <ul>
      <li><strong>Array Indexing:</strong> The first element of the array is at index <code>0</code>, and the last element is at index <code>length-1</code>, where <code>length</code> is the size of the array.</li><br/>
      <li><strong>Length of Array:</strong> The length of an array can be accessed using the <code>length</code> property. Example:</li>
    </ul><br/>
    <pre>
      <code>{`System.out.println("Array length: " + numbers.length);`}</code>
    </pre><br/>

    <h3>Summary:</h3>
    <p>
      A single-dimensional array in Java is a basic data structure that stores a sequence of elements of the same type. It allows you to access and modify elements via their index, and it is useful when you need to store a collection of related values in a single variable.
    </p>
  </div>
)}


{selectedChapter === 'chapter35' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Multi-Dimensional Arrays in Java</h1>

    <p>In Java, a <strong>multi-dimensional array</strong> is an array of arrays, where each element of the array itself is another array. It allows you to store more than one set of values in a single variable. Multi-dimensional arrays are particularly useful when you need to represent data in a grid-like structure (e.g., matrices or tables).</p>
    <br/>
    <h2>Key Points:</h2>
    <ul>
      <li><strong>Fixed Size:</strong> Like single-dimensional arrays, multi-dimensional arrays also have a fixed size. The size of each dimension must be specified at the time of creation.</li><br/>
      <li><strong>Indexed Access:</strong> Elements of a multi-dimensional array are accessed by specifying an index for each dimension. For example, a two-dimensional array requires two indices—one for the row and another for the column.</li><br/>
      <li><strong>Homogeneous:</strong> All elements in a multi-dimensional array must be of the same type, similar to single-dimensional arrays.</li>
    </ul><br/>

    <h2>Types of Multi-Dimensional Arrays:</h2>
    <ul>
      <li><strong>2D Arrays (Two-Dimensional Arrays):</strong> Represented as a grid or matrix, where each element has two indices—one for rows and one for columns.</li><br/>
      <li><strong>3D Arrays (Three-Dimensional Arrays):</strong> Extends the concept of 2D arrays to a third dimension (like a cube), and so on for higher dimensions.</li>
    </ul><br/>

    <h2>Syntax to Declare a Multi-Dimensional Array:</h2>
    <pre>
      <code>{`{/* 2D Array */}
type[][] arrayName;
{/* 3D Array */}
type[][][] arrayName;`}</code>
    </pre><br/>

    <h2>Syntax to Initialize a Multi-Dimensional Array:</h2>
    <h3>1. Static Initialization:</h3>
    <pre>
      <code>{` int[][] matrix = {{1, 2, 3}, {4, 5, 6}, {7, 8, 9}};`}</code>
    </pre>
    <p>This initializes a 2D array with predefined values.</p>

    <h3>2. Dynamic Initialization:</h3>
    <pre>
      <code>{`int[][] matrix = new int[3][3];  // Creates a 3x3 array
matrix[0][0] = 1;
matrix[0][1] = 2;
matrix[0][2] = 3;
matrix[1][0] = 4;
matrix[1][1] = 5;
matrix[1][2] = 6;
matrix[2][0] = 7;
matrix[2][1] = 8;
matrix[2][2] = 9;`}</code>
    </pre><br/>

    <h2>Accessing Elements in a Multi-Dimensional Array:</h2>
    <p>Each element is accessed using multiple indices. For a 2D array, you need to specify both the row and column index:</p>
    <pre>
      <code>{`int element = matrix[1][2];  // Access the element in row 1, column 2 (value is 6)`}</code>
    </pre><br/>

    <h2>Example of a Two-Dimensional Array:</h2>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        int[][] matrix = {{1, 2, 3}, {4, 5, 6}, {7, 8, 9}};
        System.out.println("Element at (0,0): " + matrix[0][0]);  // Output: 1
        System.out.println("Element at (1,2): " + matrix[1][2]);  // Output: 6
        System.out.println("Element at (2,1): " + matrix[2][1]);  // Output: 8
                matrix[2][2] = 10;
        System.out.println("Updated Element at (2,2): " + matrix[2][2]);  // Output: 10
    }
}`}</code>
    </pre>

    <h3>Output:</h3>
    <pre>
      <code>{`Element at (0,0): 1
Element at (1,2): 6
Element at (2,1): 8
Updated Element at (2,2): 10`}</code>
    </pre><br/>

    <h2>Example of a Three-Dimensional Array:</h2>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
            int[][][] cube = {
                {{1, 2, 3}, {4, 5, 6}, {7, 8, 9}},
                {{10, 11, 12}, {13, 14, 15}, {16, 17, 18}}
            };
            System.out.println("Element at (0,0,0): " + cube[0][0][0]);  // Output: 1
            System.out.println("Element at (1,2,1): " + cube[1][2][1]);  // Output: 17
            System.out.println("Element at (0,1,2): " + cube[0][1][2]);  // Output: 6
     }
}`}</code>
    </pre>

    <h3>Output:</h3>
    <pre>
      <code>{`Element at (0,0,0): 1
Element at (1,2,1): 17
Element at (0,1,2): 6`}</code>
    </pre><br/>

    <h2>Important Points:</h2>
    <ul>
      <li><strong>Array Indexing:</strong> In a multi-dimensional array, the first index represents the outermost dimension, and each subsequent index represents the inner dimensions.</li><br/>
      <li><strong>Jagged Arrays:</strong> Multi-dimensional arrays in Java can also be jagged, meaning each row can have a different number of columns. This is different from traditional 2D arrays, where all rows have the same number of columns.
        <pre>
          <code>{`int[][] jaggedArray = new int[3][];  // 3 rows, but the number of columns can vary
jaggedArray[0] = new int[2];  // 2 columns in the first row
jaggedArray[1] = new int[3];  // 3 columns in the second row
jaggedArray[2] = new int[1];  // 1 column in the third row`}</code>
        </pre>
      </li>
    </ul><br/>

    <h2>Summary:</h2>
    <p>A <strong>multi-dimensional array</strong> in Java is an array that stores arrays as its elements. It is especially useful for representing grid-like or matrix data structures. You can use multiple indices to access elements, and it can be extended to multiple dimensions (2D, 3D, etc.) based on your needs.</p>
  </div>
)}



{selectedChapter === 'chapter36' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Jagged Array  </h1>
        <p>
          A <strong>jagged array</strong> in Java is an array of arrays where each "inner" array can have a different length (i.e., different number of elements). This is different from a regular multi-dimensional array, where all rows (or dimensions) must have the same number of elements.
        </p>
        <p>
          In simpler terms, think of a jagged array as a collection of arrays where each array can have a different size. This allows more flexibility, especially when the data is not structured uniformly.
        </p><br/>

        <h2>Key Features of Jagged Arrays:</h2>
        <ul>
          <li><strong>Arrays of Arrays</strong>: A jagged array is an array whose elements are themselves arrays.</li><br/>
          <li><strong>Variable Length</strong>: The main difference between a jagged array and a regular multi-dimensional array is that in a jagged array, each row (or inner array) can have a different number of columns (or elements).</li><br/>
          <li><strong>Memory Efficiency</strong>: Jagged arrays can save memory when you don't need every row to have the same number of elements.</li>
        </ul><br/>

        <h2>Syntax of Jagged Array:</h2>

        <h3>Declaring a Jagged Array:</h3>
        <pre><code>
{`type[][] arrayName;`}
        </code></pre>
        <p>Here, <strong>type</strong> is the data type (such as <code>int</code>, <code>String</code>, etc.), and <strong>arrayName</strong> is the name of the jagged array.</p>

        <h3>Initializing a Jagged Array:</h3>
        <pre><code>
{`int[][] jaggedArray = new int[3][];  // 3 rows, but number of columns can vary
jaggedArray[0] = new int[2];  // First row has 2 columns
jaggedArray[1] = new int[3];  // Second row has 3 columns
jaggedArray[2] = new int[1];  // Third row has 1 column`}
       </code> </pre><br/>

        <h3>Static Initialization (with values):</h3>
        <pre><code>
{`int[][] jaggedArray = {
    {1, 2},          // Row 1 with 2 elements
    {3, 4, 5},       // Row 2 with 3 elements
    {6}              // Row 3 with 1 element
};`}
        </code></pre><br/>

        <h2>Accessing Elements of a Jagged Array:</h2>
        <p>You can access elements in a jagged array using two indices — one for the row and one for the column:</p>
        <pre><code>
{`int value = jaggedArray[1][2];  // Access the element at row 1, column 2 (value is 5)`}
        </code></pre>

        <h2>Example of a Jagged Array in Java:</h2>

        <pre><code>
{`public class Main {
    public static void main(String[] args) {
        int[][] jaggedArray = {
            {1, 2},
            {3, 4, 5},
            {6}
        };

        System.out.println("Element at (0, 0): " + jaggedArray[0][0]);
        System.out.println("Element at (1, 2): " + jaggedArray[1][2]);
        System.out.println("Element at (2, 0): " + jaggedArray[2][0]);

        jaggedArray[1][1] = 10;  // Change the element at (1, 1) to 10
        System.out.println("Updated Element at (1, 1): " + jaggedArray[1][1]);

        for (int i = 0; i < jaggedArray.length; i++) {
            for (int j = 0; j < jaggedArray[i].length; j++) {
                System.out.print(jaggedArray[i][j] + " ");
            }
            System.out.println();  // Move to the next row
        }
    }
}`}
       </code> </pre>

        <h2>Output:</h2>
        <pre><code>
{`Element at (0, 0): 1
Element at (1, 2): 5
Element at (2, 0): 6
Updated Element at (1, 1): 10
1 2
3 10 5
6`}
       </code> </pre>

        <h2>Explanation of Example:</h2>
        <ul>
          <li><strong>Initialization</strong>: The jagged array is initialized with 3 rows:
            <ul>
              <li>{"Row 1 has 2 elements ({1, 2})"}</li><br/>
              <li>{"Row 2 has 3 elements ({3, 4, 5})"}</li><br/>
              <li>{"Row 3 has 1 element ({6})"}</li>
            </ul><br/>
          </li>
          <li><strong>Accessing Elements</strong>: Elements are accessed using two indices. For example, <code>jaggedArray[1][2]</code> accesses the third element in the second row (5).</li><br/>
          <li><strong>Modifying Elements</strong>: You can change the value of an element in a jagged array by specifying the row and column, like <code>jaggedArray[1][1] = 10;</code>.</li><br/>
          <li><strong>Iterating Over the Jagged Array</strong>: The program uses nested <code>for</code> loops to iterate over the rows and columns of the jagged array, printing all the elements row by row.</li>
        </ul><br/>

        <h2>Advantages of Jagged Arrays:</h2>
        <ul>
          <li><strong>Flexibility</strong>: Each row can have a different number of elements, which is useful for representing non-uniform data structures (like ragged matrices).</li><br/>
          <li><strong>Memory Efficiency</strong>: Memory is allocated only for the number of elements in each row, which can be more efficient than using a regular 2D array where every row must have the same number of columns.</li>
        </ul><br/>

        <h2>Summary:</h2>
        <p>
          A jagged array is an array of arrays where the inner arrays can have different lengths. It provides flexibility in storing data where the number of elements in each row may vary. It can be useful for representing non-uniform data structures (like a table with rows of varying sizes). This flexibility makes jagged arrays a useful tool when working with irregular datasets.
        </p>
      </div>
    )}



{selectedChapter === 'chapter37' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Looping Through Arrays    </h1>

          <p>In Java, looping through an array allows you to access and manipulate each element of the array sequentially. You can use different types of loops to iterate over an array, including `for` loops, `enhanced for` loops (also known as the "for-each" loop), and `while` loops.</p>
          <br/>
          <h3>1. Using a Regular `for` Loop</h3>
          <p>The regular `for` loop is the most common way to loop through an array. It allows you to control the index and iterate through each element.</p>
          <br/>
          <pre>
            <code>
              {`for (int i = 0; i < array.length; i++) {
  // Access the array element using array[i]
}`}
            </code>
          </pre><br/>
          
          <p>Example:</p>
          <pre>
            <code>
              {`public class Main {
    public static void main(String[] args) {
        int[] numbers = {1, 2, 3, 4, 5};
        
        // Using a regular for loop to print each element
        for (int i = 0; i < numbers.length; i++) {
            System.out.println(numbers[i]);
        }
    }
}`}
            </code>
          </pre>
          <p>Output:</p>
          <pre>
            <code>
              {`1
2
3
4
5`}
            </code>
          </pre><br/>

          <h3>2. Using Enhanced `for` Loop (For-Each Loop)</h3>
          <p>The enhanced `for` loop, also known as the "for-each" loop, provides a simpler syntax for iterating over the elements of an array. It automatically handles the array's indices and provides direct access to each element.</p>
          
          <pre>
            <code>
              {`for (type element : array) {
  // Access the array element using element
}`}
            </code>
          </pre>
          
          <p>Example:</p>
          <pre>
            <code>
              {`public class Main {
    public static void main(String[] args) {
        int[] numbers = {1, 2, 3, 4, 5};
        
        // Using the enhanced for loop to print each element
        for (int number : numbers) {
            System.out.println(number);
        }
    }
}`}
            </code>
          </pre>
          <p>Output:</p>
          <pre>
            <code>
              {`1
2
3
4
5`}
            </code>
          </pre><br/>

          <h3>3. Using a `while` Loop</h3>
          <p>A `while` loop can also be used to iterate over an array. It requires manually managing the index variable.</p>
          
          <pre>
            <code>
              {`int i = 0;
while (i < array.length) {
  // Access the array element using array[i]
  i++;
}`}
            </code>
          </pre>
          
          <p>Example:</p>
          <pre>
            <code>
              {`public class Main {
    public static void main(String[] args) {
        int[] numbers = {1, 2, 3, 4, 5};
        
        // Using a while loop to print each element
        int i = 0;
        while (i < numbers.length) {
            System.out.println(numbers[i]);
            i++;
        }
    }
}`}
            </code>
          </pre>
          <p>Output:</p>
          <pre>
            <code>
              {`1
2
3
4
5`}
            </code>
          </pre><br/>

          <h3>4. Using a `do-while` Loop</h3>
          <p>The `do-while` loop is similar to a `while` loop, except that it guarantees at least one iteration (even if the condition is false from the start).</p>
          
          <pre>
            <code>
              {`int i = 0;
do {
  // Access the array element using array[i]
  i++;
} while (i < array.length);`}
            </code>
          </pre>
          
          <p>Example:</p>
          <pre>
            <code>
              {`public class Main {
    public static void main(String[] args) {
        int[] numbers = {1, 2, 3, 4, 5};
        
        // Using a do-while loop to print each element
        int i = 0;
        do {
            System.out.println(numbers[i]);
            i++;
        } while (i < numbers.length);
    }
}`}
            </code>
          </pre>
          <p>Output:</p>
          <pre>
            <code>
              {`1
2
3
4
5`}
            </code>
          </pre><br/>

          <h3>5. Using `Stream` API (Java 8 and above)</h3>
          <p>In modern Java (Java 8 and later), you can use the `Stream` API to loop through arrays. This approach is typically used for more complex operations, but it's also a cleaner and more functional style of iteration.</p>
          
          <pre>
            <code>
              {`import java.util.Arrays;

public class Main {
    public static void main(String[] args) {
        int[] numbers = {1, 2, 3, 4, 5};
        
        // Using Streams to print each element
        Arrays.stream(numbers).forEach(System.out::println);
    }
}`}
            </code>
          </pre>
          <p>Output:</p>
          <pre>
            <code>
              {`1
2
3
4
5`}
            </code>
          </pre><br/>

          <h3>Key Differences:</h3>
          <ul>
            <li><strong>Regular `for` loop:</strong> Provides more control over iteration, especially when you need to manipulate the index.</li><br/>
            <li><strong>Enhanced `for` loop:</strong> Simplifies the syntax and makes it easier to iterate over each element in the array directly.</li><br/>
            <li><strong>`while` loop:</strong> Offers flexibility but requires manual index management.</li><br/>
            <li><strong>`do-while` loop:</strong> Similar to `while` but guarantees one iteration, even if the condition is false initially.</li><br/>
            <li><strong>Stream API:</strong> Useful for functional programming and more complex operations.</li>
          </ul><br/>

          <h3>Summary:</h3>
          <ul>
            <li>Use a <strong>regular `for` loop</strong> if you need control over the index and need to access elements based on the index.</li><br/>
            <li>Use an <strong>enhanced `for` loop</strong> when you need to iterate over all elements without worrying about the index.</li><br/>
            <li>Use <strong>`while` or `do-while` loops</strong> when the number of iterations isn't known beforehand or when the logic requires manual control.</li><br/>
            <li>Use the <strong>Stream API</strong> for a functional approach and to work with more advanced operations like filtering and mapping.</li>
          </ul>
        </div>
)}
   



   {selectedChapter === 'chapter38' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java OOPS   </h1>

          <p>
            Java's Object-Oriented Programming System (OOPS) is a programming paradigm based on the
            concept of <strong>classes</strong> and <strong>objects</strong>. It provides a structured
            way of developing software by modeling real-world entities.
          </p><br/>

          <h2 style={{paddingBottom:"6px"}}>Core Concepts of Java OOPS:</h2>

          <h3>1. Class</h3>
          <p>
            A <strong>class</strong> is a blueprint or template for creating objects. It defines
            properties (fields) and behaviors (methods) that the objects of the class can have.
          </p>
          <pre>
            <code>
              {`public class Car {
    String brand;  // Property
    int speed;     // Property

    void displayInfo() {  // Method
        System.out.println("Brand: " + brand + ", Speed: " + speed);
    }
}`}
            </code>
          </pre><br/>
          <p>
            <strong>Key Points:</strong>
            <ul>
              <li>Fields represent the state of the class.</li><br/>
              <li>Methods represent the behavior of the class.</li>
            </ul>
          </p><br/>

          <h3>2. Object</h3>
          <p>
            An <strong>object</strong> is an instance of a class. It has a state (defined by fields)
            and behavior (defined by methods).
          </p>
          <pre>
            <code>
              {`class Car {
    String brand;
    int speed;

    void displayInfo() {
        System.out.println("Car brand: " + brand);
        System.out.println("Car speed: " + speed + " km/h");
    }
}

public class Main {
    public static void main(String[] args) {
        Car myCar = new Car();  // Create an object
        myCar.brand = "Toyota"; // Set field values
        myCar.speed = 120;
        myCar.displayInfo();    // Call method
    }
}`}
            </code>
          </pre>
          <p>
            <strong>Output:</strong>
            <br />
            <pre><code>Brand: Toyota, Speed: 120</code></pre>
          </p><br/>

          <h3>3. Encapsulation</h3>
          <p>
            Encapsulation ensures that data (fields) are private and accessed or modified using public
            methods (getters and setters).
          </p>
          <pre>
            <code>
              {`public class Employee {
    private int id;      // Private field
    private String name;

    // Getter method
    public int getId() {
        return id;
    }

    // Setter method
    public void setId(int id) {
        this.id = id;
    }
}`}
            </code>
          </pre>
          <p>
            <strong>Benefits:</strong>
            <ul>
              <li>Protects the data.</li><br/>
              <li>Controls access and modification.</li>
            </ul>
          </p><br/>

          <h3>4. Inheritance</h3>
          <p>
            Inheritance allows one class (child) to acquire properties and behaviors of another class
            (parent).
          </p>
          <pre>
            <code>
              {`class Animal {
    void eat() {
        System.out.println("This animal eats food.");
    }
}

 class Dog extends Animal {
    void bark() {
        System.out.println("This dog barks.");
    }
}

 class Main {
    public static void main(String[] args) {
        Dog dog = new Dog();
        dog.eat();   // Access parent class method
        dog.bark();  // Access child class method
    }
}`}
            </code>
          </pre>
          <p>
            <strong>Output:</strong>
            <br />
            <pre><code>This animal eats food.</code>
            <br />
            <code>This dog barks.</code></pre>
          </p><br/>

          <h3>5. Polymorphism</h3>
          <p>
            Polymorphism allows the same entity (method or object) to behave differently depending on
            the context.
          </p><br/>
          <h4>a) Method Overloading (Compile-Time Polymorphism)</h4>
          <pre>
            <code>
              {`public class Calculator {
    int add(int a, int b) {
        return a + b;
    }

    double add(double a, double b) {
        return a + b;
    }
}`}
            </code>
          </pre><br/>
          <h4>b) Method Overriding (Run-Time Polymorphism)</h4>
          <pre>
            <code>
              {`public class Parent {
    void display() {
        System.out.println("Parent class display");
    }
}

public class Child extends Parent {
    @Override
    void display() {
        System.out.println("Child class display");
    }
}`}
            </code>
          </pre><br/>

          <h3>6. Abstraction</h3>
          <p>
            Abstraction hides implementation details and shows only essential features.
          </p><br/>
          <h4>a) Abstract Class</h4>
          <pre>
            <code>
              {`abstract class Shape {
    abstract void draw(); // Abstract method
}

class Circle extends Shape {
    void draw() {
        System.out.println("Drawing a circle.");
    }
}`}
            </code>
          </pre><br/>
          <h4>b) Interface</h4>
          <pre>
            <code>
              {`interface Animal {
    void sound();  // Abstract method
}

class Cat implements Animal {
    public void sound() {
        System.out.println("Meow");
    }
}`}
            </code>
          </pre><br/>

          <h3>7. Key Terms</h3>
          <ul>
            <li>
              <strong>Constructor:</strong> Special method used to initialize objects.
            </li><br/>
            <li>
              <strong>Static Keyword:</strong> Defines members that belong to the class rather than
              any specific object.
            </li><br/>
            <li>
              <strong>This Keyword:</strong> Refers to the current object.
            </li>
          </ul><br/>

          <h3>Advantages of OOPS in Java</h3>
          <ul>
            <li>Code Reusability: Inheritance promotes reuse of existing code.</li><br/>
            <li>Data Security: Encapsulation hides sensitive data.</li><br/>
            <li>Modularity: Classes and objects separate logic into manageable pieces.</li><br/>
            <li>Ease of Maintenance: Abstraction simplifies complex systems.</li><br/>
            <li>Flexibility: Polymorphism allows scalable and flexible code.</li>
          </ul>
        </div>
      )}
  



  {selectedChapter === 'chapter39' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Objects and Classes   </h1>

        <h2>Java OOPS: Objects and Classes</h2>
        <p>
          Java follows the Object-Oriented Programming System (OOPS) paradigm, and the foundation of OOPS revolves
          around <strong>Objects</strong> and <strong>Classes</strong>.
        </p>

        <br/>

        <h3>What is a Class?</h3>
        <p>
          A <strong>class</strong> in Java is a blueprint or template for creating objects. It defines the structure
          and behavior that the objects of the class will have. Think of a class as a set of instructions for creating
          and operating objects.
        </p><br/>

        <ul>
          <li>
            <strong>Fields:</strong> Variables that hold the data or properties of the class.
          </li><br/>
          <li>
            <strong>Methods:</strong> Functions that define the behavior of the class.
          </li><br/>
          <li>
            <strong>Constructors:</strong> Special methods used to initialize objects.
          </li>
        </ul><br/>

        <pre className={style.code}>
          {`public class ClassName {
    // Fields
    String field1;
    int field2;

    // Constructor
    public ClassName(String field1, int field2) {
        this.field1 = field1;
        this.field2 = field2;
    }

    // Methods
    void displayInfo() {
        System.out.println("Field1: " + field1 + ", Field2: " + field2);
    }
}`}
        </pre>

        <br/>

        <h3>What is an Object?</h3>
        <p>
          An <strong>object</strong> is an instance of a class. It represents a specific entity with a state (defined
          by fields) and behavior (defined by methods). Objects are created from classes and are used to interact with
          the application.
        </p><br/>

        <h4>Creating an Object</h4>
        <pre className={style.code}>
          {`public class Main {
    public static void main(String[] args) {
        // Creating an object
        Car myCar = new Car("Toyota", 120);

        // Accessing object's methods and fields
        myCar.displayInfo();
    }
}`}
        </pre>

        <br/>

        <h3>Example: Class and Object in Java</h3>
        <pre className={style.code}>
          {`// Defining the class
 class Car {
    // Fields (properties)
    String brand;
    int speed;

    // Constructor
    public Car(String brand, int speed) {
        this.brand = brand;
        this.speed = speed;
    }

    // Method (behavior)
    void displayInfo() {
        System.out.println("Brand: " + brand + ", Speed: " + speed + " km/h");
    }
}

// Main class to create objects and test functionality
public class Main {
    public static void main(String[] args) {
        // Creating objects of the Car class
        Car car1 = new Car("Toyota", 120);
        Car car2 = new Car("Honda", 110);

        // Accessing methods and displaying object information
        car1.displayInfo();
        car2.displayInfo();
    }
}`}
        </pre>

        <h4>Output:</h4>
        <pre className={style.code}>{`Brand: Toyota, Speed: 120 km/h
Brand: Honda, Speed: 110 km/h`}</pre>

<br/>

        <h3>Key Features of Classes and Objects</h3>
        <ul>
          <li>
            <strong>Fields:</strong> Represent the state or attributes of a class.
          </li><br/>
          <li>
            <strong>Methods:</strong> Define the actions or behaviors of a class.
          </li><br/>
          <li>
            <strong>Constructors:</strong> Special methods used to initialize objects.
          </li><br/>
          <li>
            <strong>Accessing Members:</strong> Use the <code>.</code> operator to access fields and methods of an
            object.
          </li>
        </ul>

        <br/>

        <h3>Key Advantages</h3>
        <ul>
          <li>
            <strong>Modularity:</strong> Classes divide code into logical modules.
          </li><br/>
          <li>
            <strong>Code Reusability:</strong> Once a class is defined, it can be reused to create multiple objects.
          </li><br/>
          <li>
            <strong>Abstraction:</strong> Classes help in modeling real-world entities, making it easier to design
            software.
          </li>
        </ul>

        <br/>

        <h3>Advanced Concepts Related to Classes and Objects</h3>
        <ul>
          <li>
            <strong>Static Members:</strong> Belong to the class rather than any specific object.
            <pre className={style.code}>
              {`public class Example {
    static int count = 0;
}`}
            </pre>
          </li><br/>
          <li>
            <strong>This Keyword:</strong> Refers to the current object.
            <pre className={style.code}>
              {`public void setBrand(String brand) {
    this.brand = brand;  // Refers to the field of the current object
}`}
            </pre>
          </li><br/>
          <li>
            <strong>Access Modifiers:</strong> Control access to class members.
            <ul>
              <li>
                <strong>Private:</strong> Accessible only within the class.
              </li><br/>
              <li>
                <strong>Public:</strong> Accessible everywhere.
              </li><br/>
              <li>
                <strong>Protected:</strong> Accessible in the same package and subclasses.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    )}




{selectedChapter === 'chapter40' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Class Method </h1>

    <p>
      A <strong>class method</strong> in Java is a method that is associated with the <strong>class</strong> itself rather than with instances (objects) of the class. These methods are defined with the <code>static</code> keyword and can be called without creating an instance of the class.
    </p><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Static Keyword:</strong> Class methods are declared with the <code>static</code> keyword. This means they belong to the class itself rather than to any particular object of that class.</li><br/>
      <li><strong>No Object Required:</strong> Class methods can be called directly using the class name without needing to instantiate an object of the class.</li><br/>
      <li><strong>Cannot Access Instance Variables/Methods:</strong> Since class methods do not operate on an instance, they cannot access instance variables or instance methods directly. They can only access other static members (fields and methods).</li><br/>
      <li><strong>Common Use Cases:</strong>
        <ul>
          <li>Utility functions, such as mathematical calculations, that do not depend on object-specific state.</li><br/>
          <li>Methods that manipulate or manage static fields.</li>
        </ul>
      </li>
    </ul><br/>

    <h3>Syntax of a Class Method:</h3>
    <pre>
      <code>
{`public class MyClass {
  // Static field (variable)
  static int counter = 0;

  // Static method (class method)
  public static void displayCounter() {
    System.out.println("Counter: " + counter);
  }

  public static void main(String[] args) {
    // Calling the static method without creating an object
    MyClass.displayCounter(); // Output: Counter: 0
  }
}`}
      </code>
    </pre>

    <h3>Explanation:</h3>
    <p>
      1. <strong>Static Field:</strong> <code>counter</code> is a static field, meaning it is shared across all instances of the class.
    </p>
    <p>
      2. <strong>Static Method:</strong> <code>displayCounter()</code> is a static method that prints the value of the <code>counter</code> field. It can be called directly using the class name (<code>MyClass.displayCounter()</code>), without creating an instance of <code>MyClass</code>.
    </p>
    <p>
      3. <strong>Calling the Method:</strong> In the <code>main</code> method, the static method <code>displayCounter()</code> is called directly on the class without any object creation.
    </p><br/>

    <h3>Example with Static Variables:</h3>
    <pre>
      <code>
{`public class Calculator {
  // Static field
  static int totalOperations = 0;

  // Static method
  public static void add(int a, int b) {
    totalOperations++;  // Increment static field
    System.out.println("Sum: " + (a + b));
  }

  public static void subtract(int a, int b) {
    totalOperations++;  // Increment static field
    System.out.println("Difference: " + (a - b));
  }

  // Static method to display total operations performed
  public static void displayTotalOperations() {
    System.out.println("Total Operations: " + totalOperations);
  }

  public static void main(String[] args) {
    // Calling static methods without creating an object
    Calculator.add(5, 3);
    Calculator.subtract(10, 4);
    Calculator.displayTotalOperations();  // Output: Total Operations: 2
  }
}`}
      </code>
    </pre>

    <h3>Output:</h3>
    <pre>
      <code>
{`Sum: 8
Difference: 6
Total Operations: 2`}
      </code>
    </pre><br/>

    <h3>Explanation of the Example:</h3>
    <ul>
      <li><strong>Static Fields:</strong> <code>totalOperations</code> keeps track of the number of operations performed.</li><br/>
      <li><strong>Static Methods:</strong> The <code>add()</code>, <code>subtract()</code>, and <code>displayTotalOperations()</code> methods are static. They can be called directly on the class <code>Calculator</code>, without needing an instance of it.</li><br/>
      <li><strong>Accessing Static Members:</strong> The static methods can access the static field <code>totalOperations</code> directly, but they cannot access instance variables because they don't operate on objects.</li>
    </ul><br/>

    <h3>When to Use Static Methods (Class Methods):</h3>
    <ul>
      <li><strong>Utility Functions:</strong> Methods that don't require an object and work independently, like mathematical calculations (e.g., <code>Math.sqrt()</code>, <code>Math.pow()</code>).</li><br/>
      <li><strong>Shared Data:</strong> When the method is intended to work with class-level data that is shared by all instances.</li><br/>
      <li><strong>Factory Methods:</strong> Methods that create and return instances of the class (commonly used in Singleton patterns).</li>
    </ul><br/>

    <h3>Limitations:</h3>
    <ul>
      <li>Static methods cannot access <strong>instance variables</strong> or <strong>instance methods</strong> directly, because they are tied to the class and not to a specific object instance.</li><br/>
      <li>They cannot use the <code>this</code> keyword because <code>this</code> refers to the current instance of the class, and static methods do not have an instance.</li>
    </ul><br/>

    <h3>Key Takeaways:</h3>
    <ul>
      <li>Class methods are declared using the <code>static</code> keyword.</li><br/>
      <li>They can be called without an instance of the class.</li><br/>
      <li>They operate on static fields and cannot access instance-specific data directly.</li>
    </ul>

    
  </div>
)}



{selectedChapter === 'chapter41' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Constructor </h1>

    <p>
      A <strong>constructor</strong> in Java is a special method that is used to initialize objects. 
      It is called when an object of a class is created. The constructor allows you to set initial values 
      for the object’s fields when the object is created.
    </p><br/>

    <h2>1. What is a Constructor?</h2>
    <p>
      A constructor is a block of code that gets executed when you create an object of a class. Its main 
      purpose is to initialize the newly created object with default or specific values.
    </p><br/>

    <h2>2. Characteristics of a Constructor:</h2>
    <ul>
      <li><strong>Same Name as the Class:</strong> The constructor has the same name as the class.</li><br/>
      <li><strong>No Return Type:</strong> Constructors do not have a return type, not even <code>void</code>. They only initialize objects.</li><br/>
      <li><strong>Automatically Called:</strong> The constructor is automatically called when an object is created using the <code>new</code> keyword.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>3. Types of Constructors:</h2>

    <h3>a) Default Constructor (No-Argument Constructor):</h3>
    <p>
      A default constructor is provided by Java automatically if you don't define any constructor in your class. 
      It initializes the object with default values.
    </p>
    <pre>
      <code>
        {`public class Car {
    String brand;
    int speed;

    // Default constructor
    public Car() {
        brand = "Unknown";  // Default value for brand
        speed = 0;          // Default value for speed
    }
}`}
      </code>
    </pre><br/>

    <h3>b) Parameterized Constructor:</h3>
    <p>
      A parameterized constructor allows you to pass values when creating an object. This way, you can initialize 
      the object with specific values.
    </p>
    <pre>
      <code>
        {`public class Car {
    String brand;
    int speed;

    // Parameterized constructor
    public Car(String brand, int speed) {
        this.brand = brand;  // Initialize brand with the passed value
        this.speed = speed;  // Initialize speed with the passed value
    }
}`}
      </code>
    </pre><br/>

    <h2>4. Using Constructors:</h2>

    <h3>Using Default Constructor:</h3>
    <pre>
      <code>
        {`public class Main {
    public static void main(String[] args) {
        // Create an object using the default constructor
        Car car1 = new Car();
        System.out.println(car1.brand);  // Output: Unknown
        System.out.println(car1.speed);  // Output: 0
    }
}`}
      </code>
    </pre>

    <h3>Using Parameterized Constructor:</h3>
    <pre>
      <code>
        {`public class Main {
    public static void main(String[] args) {
        // Create an object using the parameterized constructor
        Car car2 = new Car("Toyota", 120);
        System.out.println(car2.brand);  // Output: Toyota
        System.out.println(car2.speed);  // Output: 120
    }
}`}
      </code>
    </pre><br/>

    <h2>5. Why Use Constructors?</h2>
    <ul>
      <li><strong>Object Initialization:</strong> Constructors help initialize the object’s fields with specific values when the object is created.</li><br/>
      <li><strong>Flexibility:</strong> With parameterized constructors, you can create objects with different initial states, making your code flexible.</li><br/>
      <li><strong>Encapsulation:</strong> Constructors help maintain control over how an object’s fields are initialized.</li>
    </ul><br/>

    <h2>6. Constructor Overloading:</h2>
    <p>
      Just like methods, constructors can also be overloaded. This means you can have more than one constructor in 
      the same class with different parameters.
    </p>
    <pre>
      <code>
        {`public class Car {
    String brand;
    int speed;

    // Default constructor
    public Car() {
        brand = "Unknown";
        speed = 0;
    }

    // Parameterized constructor
    public Car(String brand, int speed) {
        this.brand = brand;
        this.speed = speed;
    }
}`}
      </code>
    </pre><br/>

    <h2>7. Constructor Chaining:</h2>
    <p>
      Constructor chaining is when one constructor calls another constructor in the same class or a parent class. 
      It’s often done using the <code>this()</code> keyword.
    </p>
    <pre>
      <code>
        {`public class Car {
    String brand;
    int speed;

    // Default constructor
    public Car() {
        this("Unknown", 0);  // Calls parameterized constructor
    }

    // Parameterized constructor
    public Car(String brand, int speed) {
        this.brand = brand;
        this.speed = speed;
    }
}`}
      </code>
    </pre><br/>

    <h2>8. Summary:</h2>
    <ul>
      <li>Constructors are special methods used to initialize objects.</li><br/>
      <li>A constructor’s name must be the same as the class name.</li><br/>
      <li>Constructors do not have a return type.</li><br/>
      <li>You can use default or parameterized constructors based on the need.</li><br/>
      <li>Constructor overloading allows multiple constructors with different parameters.</li>
    </ul><br/>

    
  </div>
)}



{selectedChapter === 'chapter42' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  `static` Keyword   </h1>

      <p>
        The `static` keyword in Java is used to define class-level variables, methods, blocks, and inner classes that are shared among all instances of the class. In simple terms, anything marked with `static` belongs to the class itself, not to any specific object (instance) of that class. Here's a detailed breakdown:
      </p><br/>

      <h3>1. Static Variables (Class Variables)</h3>
      <p>
        A static variable is a variable that is shared by all instances (objects) of the class. Instead of each object having its own copy of the variable, there is only one copy of the static variable for the entire class.
      </p>
      <pre>
{`public class Counter {
    static int count = 0;  // Static variable

    public Counter() {
        count++;  // Increment the shared static variable
    }

    public static void displayCount() {
        System.out.println("Count: " + count);
    }

    public static void main(String[] args) {
        Counter c1 = new Counter();
        Counter c2 = new Counter();
        Counter.displayCount();  // Output: Count: 2
    }
}`}
      </pre>
      <p>
      Explanation: The static variable `count` is shared by all objects of the `Counter` class. Each time a new object is created, the `count` variable increases. Because it's static, the value of `count` is shared and updated across all objects.
      </p><br/>

      <h3>2. Static Methods (Class Methods)</h3>
      <p>
        A static method is a method that belongs to the class rather than to any specific object of the class. You can call a static method directly using the class name without needing to create an instance of the class.
      </p>
      <pre>
{`public class MathUtils {
    public static int add(int a, int b) {
        return a + b;  // Static method
    }

    public static void main(String[] args) {
        // Calling static method without creating an object
        int result = MathUtils.add(5, 3);
        System.out.println("Sum: " + result);  // Output: Sum: 8
    }
}`}
      </pre>
      <p>
        Explanation: The method `add()` is static, so it can be called directly on the class (`MathUtils.add(5, 3)`) without creating an instance of the class.
      </p><br/>

      <h3>3. Static Blocks</h3>
      <p>
        A static block is a block of code that is run only once when the class is loaded into memory. It is often used to perform initialization that needs to be done once, such as setting up static variables.
      </p>
      <pre>
{`public class Initialization {
    static int value;

    // Static block
    static {
        value = 10;
        System.out.println("Static block initialized.");
    }

    public static void main(String[] args) {
        System.out.println("Value: " + value);  // Output: Static block initialized.
                                               //         Value: 10
    }
}`}
      </pre>
      <p>
        Explanation: The static block runs when the class is loaded, and the static variable `value` is initialized before any method (like `main()`) is called.
      </p><br/>

      <h3>4. Static Classes (Nested Classes)</h3>
      <p>
        A static nested class is a nested class that is marked with the `static` keyword. It does not require an instance of the outer class to be created. It can be instantiated independently of the outer class.
      </p>
      <pre>
{`public class OuterClass {
    static int outerValue = 100;

    // Static nested class
    static class InnerClass {
        void display() {
            System.out.println("Outer value: " + outerValue);  // Can access static member of outer class
        }
    }

    public static void main(String[] args) {
        // Creating an object of the static nested class
        OuterClass.InnerClass inner = new OuterClass.InnerClass();
        inner.display();  // Output: Outer value: 100
    }
}`}
      </pre>
      <p>
        Explanation: The static nested class `InnerClass` can be instantiated without an instance of `OuterClass`. It can access static members of the outer class, but not instance members.
      </p><br/>

      <h3>5. Key Points About the `static` Keyword:</h3>
      <ul>
        <li><strong>Shared among all instances:</strong> Static variables and methods are shared by all objects of the class. They are class-level features, not object-level.</li><br/>
        <li><strong>Memory efficiency:</strong> Since static members are shared, they use less memory compared to instance members, especially when they don't change across instances.</li><br/>
        <li><strong>Can be accessed without an object:</strong> Static methods can be called using the class name without creating an instance of the class.</li><br/>
        <li><strong>Cannot access instance members directly:</strong> A static method cannot access non-static (instance) variables or methods because static methods do not know about individual objects.</li><br/>
        <li><strong>Used for utility functions and constants:</strong> Static methods are commonly used for utility functions (e.g., math calculations) and constants (like `Math.PI`).</li>
      </ul><br/>

      <h3>6. When to Use `static`?</h3>
      <ul>
        <li>Use static for variables and methods that are common to all instances of a class and don't depend on instance-specific data.</li><br/>
        <li>Use static for utility methods or constants that are shared across instances, such as helper functions (`Math.max()`, `Math.sqrt()`) or constants (`Math.PI`).</li>
      </ul><br/>

      <h3>Summary:</h3>
      <ul>
        <li><strong>Static variables:</strong> Are shared by all instances of the class.</li><br/>
        <li><strong>Static methods:</strong> Belong to the class and can be called without creating an object.</li><br/>
        <li><strong>Static blocks:</strong> Run once when the class is loaded, used for initialization.</li><br/>
        <li><strong>Static nested classes:</strong> Can be instantiated independently of the outer class.</li>
      </ul>

    
   
    </div>
  )}




{selectedChapter === 'chapter43' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  `this` Keyword    </h1>

    <p>
        The `this` keyword in Java is used to refer to the current instance of a class. It is used within an object or method to refer to the object that is calling the method. The `this` keyword helps in differentiating between instance variables and parameters or local variables, especially when they have the same name.
      </p><br/>

      <h3>1. Using `this` to Refer to Instance Variables</h3>
      <p>
        The `this` keyword is often used in a constructor or method to refer to instance variables of the current object. It differentiates between instance variables and method parameters that have the same name.
      </p>

      <p><strong>Example:</strong></p>
      <pre>
        {`class Person {
  String name;
  
  // Constructor
  public Person(String name) {
      this.name = name;  // Refers to the instance variable 'name'
  }

  public void showName() {
      System.out.println("Name: " + this.name);  // Refers to the current object's 'name'
  }

  public static void main(String[] args) {
      Person person = new Person("John");
      person.showName();  // Output: Name: John
  }
}`}
      </pre>
      <p>
        Explanation: In this example, the parameter `name` in the constructor and the instance variable `name` have the same name. Using `this.name` refers to the instance variable of the object, while `name` without `this` refers to the parameter in the constructor.
      </p><br/>

      <h3>2. Using `this` to Call Instance Methods</h3>
      <p>
        You can use the `this` keyword to call instance methods from within another instance method. This can be helpful to avoid ambiguity between method names and local variables.
      </p>

      <p><strong>Example:</strong></p>
      <pre>
        {`class Calculator {
  int add(int a, int b) {
      return a + b;
  }

  void displaySum() {
      int result = this.add(5, 3);  // Calls the add method using 'this'
      System.out.println("Sum: " + result);  // Output: Sum: 8
  }

  public static void main(String[] args) {
      Calculator calc = new Calculator();
      calc.displaySum();
  }
}`}
      </pre>
      <p>
        Explanation: Here, `this.add(5, 3)` calls the `add()` method from the same instance of the class, ensuring it refers to the current object. Even though `add()` could be directly called without `this`, it's shown for clarity and demonstration.
      </p><br/>

      <h3>3. Using `this` in Constructors</h3>
      <p>
        The `this` keyword is often used in constructors to differentiate between instance variables and constructor parameters when they share the same name.
      </p>

      <p><strong>Example:</strong></p>
      <pre>
        {`class Car {
  String model;
  int year;

  // Constructor using 'this' to assign values to instance variables
  public Car(String model, int year) {
      this.model = model;  // Refers to the instance variable 'model'
      this.year = year;    // Refers to the instance variable 'year'
  }

  public void displayInfo() {
      System.out.println("Model: " + this.model + ", Year: " + this.year);
  }

  public static void main(String[] args) {
      Car car = new Car("Toyota", 2020);
      car.displayInfo();  // Output: Model: Toyota, Year: 2020
  }
}`}
      </pre>
      <p>
        Explanation: The constructor assigns the parameters `model` and `year` to the instance variables `this.model` and `this.year`. This helps avoid confusion when parameter names are the same as the instance variables.
      </p><br/>

      <h3>4. `this` in Method Chaining</h3>
      <p>
        The `this` keyword can also be used in method chaining. By returning `this` from a method, we allow other methods to be called on the same object.
      </p>

      <p><strong>Example:</strong></p>
      <pre>
        {`class Builder {
  String name;
  int age;

  public Builder setName(String name) {
      this.name = name;
      return this;  // Return the current object for method chaining
  }

  public Builder setAge(int age) {
      this.age = age;
      return this;  // Return the current object for method chaining
  }

  public void showInfo() {
      System.out.println("Name: " + this.name + ", Age: " + this.age);
  }

  public static void main(String[] args) {
      Builder builder = new Builder();
      builder.setName("Alice").setAge(25).showInfo();  // Output: Name: Alice, Age: 25
  }
}`}
      </pre>
      <p>
        Explanation: Here, the methods `setName()` and `setAge()` return `this`, allowing method chaining. Each method modifies the state of the same object and then returns the object itself.
      </p><br/>

      <h3>5. Key Points About the `this` Keyword:</h3>
      <ul>
        <li><strong>Refers to the current object:</strong> `this` refers to the current instance of the class.</li><br/>
        <li><strong>Used to resolve ambiguity:</strong> It is commonly used when method parameters or local variables have the same name as instance variables.</li><br/>
        <li><strong>Useful in method chaining:</strong> Returning `this` allows methods to be chained on the same object.</li><br/>
        <li><strong>Not available in static methods:</strong> Since static methods do not belong to any specific object, they cannot use the `this` keyword.</li>
      </ul><br/>
      

      <h3>6. When to Use `this`?</h3>
      <ul>
        <li>Use `this` to refer to instance variables or methods when there is ambiguity with local variables or parameters.</li><br/>
        <li>Use `this` in constructors to differentiate between instance variables and constructor parameters.</li><br/>
        <li>Use `this` for method chaining to return the current object from methods.</li>
      </ul><br/>

      <h3>Summary:</h3>
      <ul>
        <li><strong>Refers to the current object:</strong> `this` is used to refer to the current instance of a class.</li><br/>
        <li><strong>Used to avoid ambiguity:</strong> It helps differentiate between instance variables and local variables/parameters with the same name.</li><br/>
        <li><strong>Facilitates method chaining:</strong> Returning `this` from a method allows chaining of methods.</li>
      </ul>

     

 
  </div>
)}



{selectedChapter === 'chapter44' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Understanding `getClass()` Method    </h1>

          <p>
            In Java, the <code>getClass()</code> method is used to obtain the <strong>runtime class</strong> of an object. 
            This method is part of the <code>Object</code> class, and since every class in Java inherits from <code>Object</code>, 
            all objects can access this method.
          </p><br/>

          <h2>Simple Explanation:</h2>
          <p>
            The <code>getClass()</code> method returns the <strong>Class object</strong> associated with the runtime class of the current object. 
            A <strong>Class object</strong> represents the metadata (information) about a class, such as its name, methods, fields, and more.
          </p><br/>

          <h3>Key Points:</h3>
          <ul>
            <li>The <code>getClass()</code> method is <strong>inherited</strong> from the <code>Object</code> class, which means every Java object can use it.</li><br/>
            <li>It returns a <code>Class</code> object, which provides methods to query details about the class, such as its name, package, and other characteristics.</li><br/>
            <li>You cannot call <code>getClass()</code> on a <code>null</code> object. It will throw a <code>NullPointerException</code> if the object is <code>null</code>.</li>
          </ul><br/>

          <h3>Syntax:</h3>
          <pre>
            <code>
              public final Class&lt;?&gt; getClass()
            </code>
          </pre>

          <h3>Example:</h3>
          <pre>
            <code>
              {`public class MyClass {\n` +
                `    public static void main(String[] args) {\n` +
                `        MyClass myObject = new MyClass();\n` +
                `        // Get the class of the object\n` +
                `        Class<?> clazz = myObject.getClass();\n` +
                `        // Print the class name\n` +
                `        System.out.println("Class name: " + clazz.getName());\n` +
                `    }\n` +
                `}\n`}
            </code>
          </pre>

          <h3>Output:</h3>
          <pre>
            <code>Class name: MyClass</code>
          </pre>

          <h3>Explanation:</h3>
          <p>
            1. We create an object <code>myObject</code> of type <code>MyClass</code>.<br /><br />
            2. Calling <code>myObject.getClass()</code> returns a <code>Class</code> object that represents the class <code>MyClass</code>.<br /><br />
            3. <code>clazz.getName()</code> returns the name of the class (<code>MyClass</code> in this case).
          </p><br/>

          <h3>Use Cases:</h3>
          <ul>
            <li><strong>Object Identification:</strong> You can use <code>getClass()</code> to identify the class of an object at runtime, useful in reflection or debugging.</li><br/>
            <li><strong>Reflection:</strong> In Java, reflection allows you to inspect and modify the behavior of classes, methods, and fields at runtime, and <code>getClass()</code> is often used in such cases.</li>
          </ul><br/>

          <h3>Example in Reflection:</h3>
          <pre>
            <code>
              {`import java.lang.reflect.Method;\n\n` +
                `public class MyClass {\n` +
                `    public void myMethod() {\n` +
                `        System.out.println("Hello from myMethod");\n` +
                `    }\n\n` +
                `    public static void main(String[] args) throws Exception {\n` +
                `        MyClass myObject = new MyClass();\n\n` +
                `        // Using getClass() to get the Class object\n` +
                `        Class<?> clazz = myObject.getClass();\n\n` +
                `        // Get the method from the class using reflection\n` +
                `        Method method = clazz.getMethod("myMethod");\n\n` +
                `        // Invoke the method\n` +
                `        method.invoke(myObject);  // Output: Hello from myMethod\n` +
                `    }\n` +
                `}`}
            </code>
          </pre><br/>

          <h3>Summary:</h3>
          <ul>
            <li><code>getClass()</code> provides a way to obtain the <strong>runtime class</strong> of an object.</li><br/>
            <li>It is commonly used for reflection and object identification.</li><br/>
            <li>The method returns a <code>Class&lt;?&gt;</code> object that can be used to retrieve various details about the class.</li>
          </ul>
        </div>
)}



{selectedChapter === 'chapter45' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Inheritance (IS-A)   </h1>

    
    <p>
      Inheritance in Java is a mechanism where one class acquires the properties (fields) and behaviors (methods) of another class. It is one of the key principles of Object-Oriented Programming (OOP) and promotes <strong>code reuse</strong> and a <strong>hierarchical classification</strong>.
    </p><br/>
    
    <h2>Why Use Inheritance?</h2>
    <ul>
      <li><strong>Code Reusability:</strong> Common code in the parent class can be reused in multiple child classes.</li><br/>
      <li><strong>Ease of Maintenance:</strong> Changes in the parent class are automatically reflected in child classes.</li><br/>
      <li><strong>Polymorphism:</strong> Enables method overriding to provide specific implementations in child classes.</li><br/>
      <li><strong>Hierarchical Classification:</strong> Helps in logically organizing code by creating parent-child relationships.</li>
    </ul><br/>

    <h2>Key Concepts in Inheritance:</h2>
    <ul>
      <li><strong>Parent Class (Superclass):</strong> The class whose members (fields and methods) are inherited.</li><br/>
      <li><strong>Child Class (Subclass):</strong> The class that inherits members of the parent class.</li><br/>
      <li><strong>extends Keyword:</strong> Used to establish an inheritance relationship between the parent and child classes.</li><br/>
      <li><strong>Method Overriding:</strong> A child class can provide its own implementation of a method that exists in the parent class.</li><br/>
      <li><strong>super Keyword:</strong> Used in the child class to call the parent class’s constructor or methods.</li>
    </ul><br/>

    <h2>Types of Inheritance:</h2>
    <ul>
      <li><strong>Single Inheritance:</strong> A child class inherits from one parent class.</li><br/>
      <li><strong>Multilevel Inheritance:</strong> A class inherits from another class, which in turn inherits from another class.</li><br/>
      <li><strong>Hierarchical Inheritance:</strong> Multiple child classes inherit from the same parent class.</li>
    </ul><br/>

    <p><strong>Note:</strong> Java does <em>not</em> support multiple inheritance with classes to avoid ambiguity. However, multiple inheritance is possible through interfaces.</p>

    <h3>Syntax of Inheritance:</h3>
    <pre>
      {`class ParentClass {
    // Fields and methods of parent class
}

class ChildClass extends ParentClass {
    // Fields and methods of child class
}`}
    </pre>

    <h3>Examples of Inheritance</h3>

    <h4>1. Single Inheritance:</h4>
    <pre>
      {`// Parent class
class Animal {
    void eat() {
        System.out.println("This animal eats food.");
    }
}

// Child class
class Dog extends Animal {
    void bark() {
        System.out.println("The dog barks.");
    }
}

// Main class
public class Main {
    public static void main(String[] args) {
        Dog myDog = new Dog();
        myDog.eat();  // Inherited from Animal
        myDog.bark(); // Defined in Dog
    }
}`}
    </pre>
    <p><strong>Output:</strong></p>
    <pre>{`This animal eats food.
The dog barks.`}</pre><br/>

    <h4>2. Multilevel Inheritance:</h4>
    <pre>
      {`// Parent class
class Animal {
    void eat() {
        System.out.println("This animal eats food.");
    }
}

// Intermediate class
class Mammal extends Animal {
    void walk() {
        System.out.println("This mammal walks.");
    }
}

// Child class
class Dog extends Mammal {
    void bark() {
        System.out.println("The dog barks.");
    }
}

// Main class
public class Main {
    public static void main(String[] args) {
        Dog myDog = new Dog();
        myDog.eat();  // Inherited from Animal
        myDog.walk(); // Inherited from Mammal
        myDog.bark(); // Defined in Dog
    }
}`}
    </pre>
    <p><strong>Output:</strong></p>
    <pre>{`This animal eats food.
This mammal walks.
The dog barks.`}</pre><br/>

    <h4>3. Hierarchical Inheritance:</h4>
    <pre>
      {`// Parent class
class Animal {
    void eat() {
        System.out.println("This animal eats food.");
    }
}

// Child class 1
class Dog extends Animal {
    void bark() {
        System.out.println("The dog barks.");
    }
}

// Child class 2
class Cat extends Animal {
    void meow() {
        System.out.println("The cat meows.");
    }
}

// Main class
public class Main {
    public static void main(String[] args) {
        Dog myDog = new Dog();
        myDog.eat();  // Inherited from Animal
        myDog.bark(); // Defined in Dog

        Cat myCat = new Cat();
        myCat.eat();  // Inherited from Animal
        myCat.meow(); // Defined in Cat
    }
}`}
    </pre>
    <p><strong>Output:</strong></p>
    <pre>{`This animal eats food.
The dog barks.
This animal eats food.
The cat meows.`}</pre><br/>

    <h4>4. Method Overriding in Inheritance:</h4>
    <pre>
      {`// Parent class
class Animal {
    void sound() {
        System.out.println("Animals make sound.");
    }
}

// Child class
class Dog extends Animal {
    @Override
    void sound() {
        System.out.println("The dog barks.");
    }
}

// Main class
public class Main {
    public static void main(String[] args) {
        Animal myAnimal = new Dog(); // Polymorphism
        myAnimal.sound(); // Calls the overridden method in Dog
    }
}`}
    </pre>
    <p><strong>Output:</strong></p>
    <pre>{`The dog barks.`}</pre><br/>

    <h4>5. Using the super Keyword:</h4>
    <pre>
      {`// Parent class
class Animal {
    void eat() {
        System.out.println("This animal eats food.");
    }
}

// Child class
class Dog extends Animal {
    @Override
    void eat() {
        super.eat(); // Call the parent class method
        System.out.println("The dog eats bones.");
    }
}

// Main class
public class Main {
    public static void main(String[] args) {
        Dog myDog = new Dog();
        myDog.eat();
    }
}`}
    </pre>
    <p><strong>Output:</strong></p>
    <pre>{`This animal eats food.
The dog eats bones.`}</pre><br/>

    <h3>Key Rules in Java Inheritance:</h3>
    <ul>
      <li><strong>Private Members Are Not Inherited:</strong> A subclass cannot access private fields or methods of the parent class directly.</li><br/>
      <li><strong>Constructors Are Not Inherited:</strong> The child class does not inherit constructors but can call the parent class’s constructor using <code>super()</code>.</li><br/>
      <li><strong>Final Class Cannot Be Inherited:</strong> A class declared as <code>final</code> cannot be extended.</li><br/>
      <li><strong>Final Method Cannot Be Overridden:</strong> A method declared as <code>final</code> in the parent class cannot be overridden in the child class.</li><br/>
      <li><strong>Static Members Are Not Inherited Dynamically:</strong> Static methods and fields belong to the class and are not overridden but can be hidden.</li>
    </ul><br/>

    <h3>Advantages of Inheritance:</h3>
    <ul>
      <li><strong>Code Reusability:</strong> Avoids code duplication.</li><br/>
      <li><strong>Extensibility:</strong> Child classes can add new features to the parent class.</li><br/>
      <li><strong>Polymorphism:</strong> Enables method overriding for dynamic method calls.</li>
    </ul><br/>

    <h3>Disadvantages of Inheritance:</h3>
    <ul>
      <li><strong>Tight Coupling:</strong> Changes in the parent class may impact all child classes.</li><br/>
      <li><strong>Inheritance Abuse:</strong> Excessive inheritance can make the code complex and hard to maintain.</li>
    </ul><br/>

    <h3>Summary:</h3>
    <ul>
      <li><strong>Inheritance</strong> allows a class to inherit properties and methods from another class, promoting <strong>code reuse</strong>.</li><br/>
      <li>It is implemented using the <code>extends</code> keyword.</li><br/>
      <li>Use <strong>method overriding</strong> to customize behaviors in the child class.</li><br/>
      <li>Use the <code>super</code> keyword to refer to the parent class explicitly.</li>
    </ul>

   
  </div>
)}




{selectedChapter === 'chapter46' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Aggregation (HAS-A Relationship) </h1>
        <p>
          In Java, <strong>Aggregation</strong> represents a <strong>HAS-A relationship</strong>, where one class contains a reference to another class as part of its attributes. Unlike inheritance, aggregation focuses on "having" rather than "being." This makes it a complementary concept to inheritance, promoting better modularity and code reusability.
        </p>

        <br/>

        <h2>Aggregation vs. Inheritance</h2>
        <table className={style.comparisonTable}>
          <thead>
            <tr>
              <th>Aggregation (HAS-A)</th>
              <th>Inheritance (IS-A)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Represents a <strong>HAS-A</strong> relationship.</td>
              <td>Represents an <strong>IS-A</strong> relationship.</td>
            </tr>
            <tr>
              <td>
                Achieved by including a reference to another class in the current class.
              </td>
              <td>Achieved using the <code>extends</code> keyword.</td>
            </tr>
            <tr>
              <td>Promotes <strong>loose coupling</strong>.</td>
              <td>Promotes <strong>tight coupling</strong>.</td>
            </tr>
            <tr>
              <td>
                Example: A <code>Car</code> <strong>has an</strong> <code>Engine</code>.
              </td>
              <td>
                Example: A <code>Dog</code> <strong>is an</strong> <code>Animal</code>.
              </td>
            </tr>
          </tbody>
        </table>

        <br/>

        <h2>Key Characteristics</h2>
        <ul>
          <li><strong>HAS-A Relationship:</strong> One class contains another as a member.</li><br/>
          <li><strong>Loose Coupling:</strong> The two classes are connected but can function independently.</li><br/>
          <li><strong>No Parent-Child Relationship:</strong> Unlike inheritance, aggregation doesn't define a parent-child hierarchy.</li><br/>
          <li><strong>Code Reusability:</strong> Common functionalities can be reused without extending classes.</li>
        </ul>

        <br/>

        <h2>When to Use Aggregation?</h2>
        <ul>
          <li>Use <strong>Inheritance</strong> when there is a natural <strong>IS-A</strong> relationship.</li><br/>
          <li>Use <strong>Aggregation</strong> when there is a <strong>HAS-A</strong> relationship between objects.</li>
        </ul><br/>

        <p>For example:</p>
        <ul>
          <li>A <code>Car</code> <strong>has an</strong> <code>Engine</code> (Aggregation).</li><br/>
          <li>A <code>Dog</code> <strong>is an</strong> <code>Animal</code> (Inheritance).</li>
        </ul>

        <br/>

        <h2>Examples of Aggregation in Java</h2>

        <h3>1. Car <strong>has an</strong> Engine</h3>
        <pre className={style.code}>
          <code>
{`// Engine class
class Engine {
    String type;

    Engine(String type) {
        this.type = type;
    }

    void startEngine() {
        System.out.println("The " + type + " engine is starting...");
    }
}

// Car class
class Car {
    String brand;
    Engine engine; // Aggregation: Car "has an" Engine

    Car(String brand, Engine engine) {
        this.brand = brand;
        this.engine = engine;
    }

    void startCar() {
        System.out.println("Starting the car: " + brand);
        engine.startEngine(); // Using the Engine class's functionality
    }
}

// Main class
public class Main {
    public static void main(String[] args) {
        Engine carEngine = new Engine("V8");
        Car myCar = new Car("Ford Mustang", carEngine);
        myCar.startCar();
    }
}`}
          </code>
        </pre>

        <h4>Output:</h4>
        <pre className={style.code}>
          <code>
{`Starting the car: Ford Mustang
The V8 engine is starting...`}
          </code>
        </pre><br/>

        <h3>2. University <strong>has</strong> Departments</h3>
        <pre className={style.code}>
          <code>
{`// Employee class
class Employee {
    String name;
    int id;

    Employee(String name, int id) {
        this.name = name;
        this.id = id;
    }

    void displayEmployeeDetails() {
        System.out.println("Employee ID: " + id + ", Name: " + name);
    }
}

// Subclass: Professor
class Professor extends Employee {
    String subject;

    Professor(String name, int id, String subject) {
        super(name, id);
        this.subject = subject;
    }

    void displayProfessorDetails() {
        super.displayEmployeeDetails();
        System.out.println("Subject: " + subject);
    }
}

// Department class
class Department {
    String departmentName;
    Professor professor; // Aggregation: Department "has a" Professor

    Department(String departmentName, Professor professor) {
        this.departmentName = departmentName;
        this.professor = professor;
    }

    void displayDepartmentDetails() {
        System.out.println("Department: " + departmentName);
        professor.displayProfessorDetails();
    }
}

// Main class
public class Main {
    public static void main(String[] args) {
        Professor prof = new Professor("Dr. Smith", 101, "Computer Science");
        Department csDept = new Department("Computer Science", prof);
        csDept.displayDepartmentDetails();
    }
}`}
          </code>
        </pre>

        <h4>Output:</h4>
        <pre className={style.code}>
          <code>
{`Department: Computer Science
Employee ID: 101, Name: Dr. Smith
Subject: Computer Science`}
          </code>
        </pre>

        <br/>

        <h2>Key Points to Remember</h2>
        <ul>
          <li>Aggregation models a <strong>HAS-A relationship</strong>.</li><br/>
          <li>It promotes <strong>loose coupling</strong>.</li><br/>
          <li>Encourages <strong>code reuse</strong> without the restrictions of inheritance.</li>
        </ul><br/>

        <h2>Advantages of Aggregation</h2>
        <ul>
          <li><strong>Modularity:</strong> Classes can be developed and tested independently.</li><br/>
          <li><strong>Reusability:</strong> Enables code reuse without inheritance.</li><br/>
          <li><strong>Flexibility:</strong> Changes to one class have minimal impact on others.</li>
        </ul>
        <br/>
        <h2>Disadvantages of Aggregation</h2>
        <ul>
          <li><strong>Complexity:</strong> May increase complexity in large systems.</li><br/>
          <li><strong>Design Dependency:</strong> Requires careful planning to ensure proper relationships.</li>
        </ul>

        <br/>
        <h2>Real-World Use Cases</h2>
        <ul>
          <li>A <code>Library</code> <strong>has many</strong> <code>Books</code>.</li><br/>
          <li>A <code>Department</code> <strong>has a</strong> <code>Professor</code>.</li><br/>
          <li>A <code>Car</code> <strong>has an</strong> <code>Engine</code>.</li>
        </ul>
      </div>  
  )}



{selectedChapter === 'chapter47' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Method Overloading  </h1>

      <p>
        <strong>Method Overloading</strong> is a concept in Java where two or more methods in the same class can have the same name but must differ in the following ways:
      </p>
      <ul>
        <li><strong>Number of parameters:</strong> The methods differ in the number of parameters.</li><br/>
        <li><strong>Type of parameters:</strong> The methods differ in the type of parameters.</li>
      </ul><br/>
      <p>
        The return type can be the same or different, but it cannot be the sole distinguishing factor between overloaded methods. The key idea is that the method name is the same, but Java distinguishes the methods based on their method signature, which includes the number and type of parameters.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>Why Method Overloading?</h2>
      <ul>
        <li><strong>Improves Readability:</strong> You can use the same method name to perform different tasks, which makes the code easier to read and maintain.</li><br/>
        <li><strong>Code Reusability:</strong> Method overloading allows you to reuse method names for different types or numbers of arguments.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Points of Method Overloading:</h2>
      <ul>
        <li><strong>Same Method Name:</strong> The overloaded methods have the same name.</li><br/>
        <li><strong>Different Method Signature:</strong> They must differ in the number or type of parameters.</li><br/>
        <li><strong>Return Type:</strong> The return type can be the same or different, but it cannot be used to distinguish overloaded methods.</li><br/>
        <li><strong>Compile-Time Polymorphism:</strong> Method overloading is an example of <em>compile-time polymorphism</em> or <em>static polymorphism</em>, meaning the method call is resolved during compile time.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Method Overloading Example:</h2>

      <h3>Example 1: Overloading Based on Number of Parameters</h3>
      <pre>
        {`class Calculator {
    int add(int a, int b) {
        return a + b;
    }

    int add(int a, int b, int c) {
        return a + b + c;
    }

    public static void main(String[] args) {
        Calculator calc = new Calculator();
        System.out.println("Sum of two numbers: " + calc.add(10, 20));    // Calls add(int, int)
        System.out.println("Sum of three numbers: " + calc.add(10, 20, 30)); // Calls add(int, int, int)
    }
}`}
      </pre>

      <h3>Output:</h3>
      <p><pre>
        Sum of two numbers: 30 <br />
        Sum of three numbers: 60
        </pre>
      </p>

      <h3>Explanation:</h3>
      <p>
        The <code>add</code> method is overloaded with two versions: one that takes two parameters and another that takes three. Depending on how many arguments you pass when calling the method, Java will determine which version of <code>add</code> to invoke.
      </p>
      <br/>
      <h3>Example 2: Overloading Based on Type of Parameters</h3>
      <pre>
        {`class Printer {
    void print(int a) {
        System.out.println("Integer: " + a);
    }

    void print(String s) {
        System.out.println("String: " + s);
    }

    public static void main(String[] args) {
        Printer printer = new Printer();
        printer.print(100);       // Calls print(int)
        printer.print("Hello!");  // Calls print(String)
    }
}`}
      </pre>

      <h3>Output:</h3>
      <p><pre>
        Integer: 100 <br />
        String: Hello!
        </pre></p>

      <h3>Explanation:</h3>
      <p>
        The <code>print</code> method is overloaded based on the type of the argument. If you pass an integer, the first method is invoked. If you pass a string, the second method is invoked.
      </p><br/>

      <h3>Example 3: Overloading with Different Parameter Types</h3>
      <pre>
        {`class Display {
    void show(int a) {
        System.out.println("Integer: " + a);
    }

    void show(double a) {
        System.out.println("Double: " + a);
    }

    public static void main(String[] args) {
        Display display = new Display();
        display.show(10);    // Calls show(int)
        display.show(10.5);  // Calls show(double)
    }
}`}
      </pre>

      <h3>Output:</h3>
      <p><pre>
        Integer: 10 <br />
        Double: 10.5
        </pre></p>

      <h3>Explanation:</h3>
      <p>
        The <code>show</code> method is overloaded based on the parameter type. If you pass an integer, the first method is invoked. If you pass a double, the second method is invoked.
      </p><br/>

      <h2>Important Considerations in Method Overloading:</h2>
      <ul>
        <li><strong>Return Type Doesn't Matter:</strong> Java does not consider the return type when determining which overloaded method to call. For example, the following would not be valid overloading:</li>
      </ul>
      <pre>
        {`int add(int a, int b) {
    return a + b;
}

double add(int a, int b) {  // Error: Same method signature
    return a + b;
}`}
      </pre>
      <p>
        <strong>Type Promotion:</strong> Java supports <em>automatic type promotion</em> when overloaded methods are called.
      </p>
      <pre>
        {`class Example {
    void display(int a) {
        System.out.println("Integer: " + a);
    }

    void display(double a) {
        System.out.println("Double: " + a);
    }

    public static void main(String[] args) {
        Example ex = new Example();
        ex.display(10);      // Calls display(int)
        ex.display(10.5);    // Calls display(double)
        ex.display(10.5f);   // Calls display(double) due to type promotion
    }
}`}
      </pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Advantages of Method Overloading:</h2>
      <ul>
        <li><strong>Increased Readability:</strong> It allows you to use the same method name for similar actions, making the code more readable.</li><br/>
        <li><strong>Reusability:</strong> You can reuse method names for different parameter combinations without needing to define new names.</li><br/>
        <li><strong>Code Clarity:</strong> It reduces the clutter of having many methods with similar names that perform similar tasks but on different data types or counts of parameters.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>Disadvantages of Method Overloading:</h2>
      <ul>
        <li><strong>Overuse:</strong> Overloading methods excessively might make the code harder to understand, as the programmer must understand all possible signatures.</li><br/>
        <li><strong>Confusion:</strong> If method overloading is not implemented properly, it can lead to ambiguities or errors that are difficult to debug.</li>
      </ul><br/>

      <h2>Conclusion:</h2>
      <p>
        Method overloading is a powerful feature in Java that allows multiple methods with the same name but different signatures. It helps increase readability, reusability, and overall code organization. It is resolved at compile-time and is a form of compile-time polymorphism. By understanding how to overload methods, you can create more flexible and efficient programs.
      </p>
    </div>
  )}


   
{selectedChapter === 'chapter48' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Method Overriding  </h1>

      <p><strong>Method Overriding</strong> is a feature in Java that allows a subclass to provide a specific implementation for a method that is already defined in its superclass. It is an essential part of <strong>runtime polymorphism</strong> in Java, where the method that gets called is determined at runtime, based on the object's type, rather than the reference type.</p>
      <br/>
      <p>
        In <strong>method overriding</strong>, the method signature in the subclass must be the same as the method signature in the superclass. This means the method name, return type, and parameters must match exactly.
      </p><br/>

      <h4><strong>Key Points of Method Overriding:</strong></h4>
      <ul>
        <li><strong>Same Method Signature:</strong> The method in the subclass must have the same name, return type, and parameter list as the method in the superclass.</li><br/>
        <li><strong>Access Modifier:</strong> The access level of the overridden method in the subclass cannot be more restrictive than the one in the superclass. For example, if the method in the superclass is <code>public</code>, the method in the subclass must also be <code>public</code> or more permissive (like <code>protected</code>).</li><br/>
        <li><strong>Return Type:</strong> The return type of the overridden method can be the same or a subtype (covariant return type) of the return type in the superclass.</li><br/>
        <li><strong>Throws Clause:</strong> The method in the subclass can throw fewer or the same exceptions as the superclass method, but it cannot throw more or broader exceptions.</li><br/>
        <li><strong>Runtime Polymorphism:</strong> Method overriding is an example of <strong>runtime polymorphism</strong> because the method call is resolved during the execution of the program based on the object's type.</li>
      </ul><br/>

      <h4><strong>Why Method Overriding?</strong></h4>
      <ul>
        <li><strong>Achieve Dynamic Polymorphism:</strong> Method overriding is a fundamental way to achieve dynamic method dispatch (runtime polymorphism) in Java. It allows for the behavior of the method to change based on the object that is being referred to.</li><br/>
        <li><strong>Specialize or Modify Behavior:</strong> In the subclass, you can modify or extend the behavior of the superclass method.</li>
      </ul><br/>

      <h4><strong>Example of Method Overriding:</strong></h4>
      <pre>
        <code>{`// Superclass (Parent class)
class Animal {
    void makeSound() {
        System.out.println("Animal makes a sound");
    }
}

// Subclass (Child class)
class Dog extends Animal {
    @Override
    void makeSound() {
        System.out.println("Dog barks");
    }
}

public class Main {
    public static void main(String[] args) {
        Animal animal = new Animal();
        animal.makeSound(); // Calls Animal's makeSound method

        Animal dog = new Dog(); // Upcasting
        dog.makeSound(); // Calls Dog's makeSound method due to method overriding
    }
}`}</code>
      </pre>
      <pre><code>Animal makes a sound<br/>
      Dog barks</code></pre>
      <br/>

      <h4><strong>Explanation:</strong></h4>
      <ul>
        <li>In the <code>Animal</code> class, there is a method <code>makeSound()</code> that prints a general message.</li><br/>
        <li>The <code>Dog</code> class overrides the <code>makeSound()</code> method to print a message specific to dogs.</li><br/>
        <li>When we call <code>makeSound()</code> on an <code>Animal</code> reference that points to a <code>Dog</code> object, the <code>Dog</code> version of the method is called (this is because of method overriding and runtime polymorphism).</li>
      </ul><br/>

      <h4><strong>Important Considerations in Method Overriding:</strong></h4>
      <ul>
        <li><strong>Use of <code>@Override</code> Annotation:</strong> The <code>@Override</code> annotation is optional but highly recommended. It helps the compiler detect errors at compile time (e.g., if the method signature in the subclass doesn’t match the method in the superclass).</li><br/>
        <li><strong>Access Modifiers in Overriding:</strong> If a method in the superclass is <code>protected</code>, you can override it as <code>protected</code> or <code>public</code> in the subclass. You cannot override a <code>private</code> method from the superclass since it is not visible in the subclass.</li><br/>
        <li><strong>Return Type in Overriding:</strong> The return type of the overridden method can be the same as the superclass method or a subclass of the original return type (covariant return type).</li><br/>
        <li><strong>Invoking the Superclass Method:</strong> If needed, you can call the superclass method inside the overridden method using the <code>super</code> keyword.</li><br/>
        <li><strong>Method Overriding vs. Method Overloading:</strong> <code>Method Overloading</code> occurs when multiple methods in the same class have the same name but different parameter lists (number or type). <code>Method Overriding</code> occurs when a subclass provides a specific implementation of a method that is already defined in its superclass with the same method signature.</li>
      </ul><br/>

      <h4><strong>Advantages of Method Overriding:</strong></h4>
      <ul>
        <li><strong>Achieves Dynamic Polymorphism:</strong> Overriding enables a subclass to define specific behaviors while maintaining a common interface, allowing objects of different types to be treated uniformly.</li><br/>
        <li><strong>Extensibility:</strong> It allows you to extend the functionality of a class without modifying the superclass code.</li><br/>
        <li><strong>Code Reusability:</strong> It enables reusing code in subclasses, while still allowing each subclass to have its own specific behavior.</li>
      </ul><br/>

      <h4><strong>Disadvantages of Method Overriding:</strong></h4>
      <ul>
        <li><strong>Increased Complexity:</strong> While method overriding provides flexibility, it can increase the complexity of code, especially when dealing with deep inheritance hierarchies.</li><br/>
        <li><strong>Performance Overhead:</strong> Dynamic method dispatch can result in a slight performance overhead at runtime since the method to call is resolved dynamically.</li>
      </ul><br/>

      <h3><strong>Conclusion:</strong></h3>
      <p>
        Method overriding is a key concept in Java that supports <strong>runtime polymorphism</strong>. It allows subclasses to provide specific implementations of methods already defined in a superclass. By overriding methods, Java enables objects to behave differently based on their actual class type, even when accessed through references of the superclass type. This is an essential feature for achieving flexibility and extensibility in object-oriented programs.
      </p>

     
    </div>
  )}



{selectedChapter === 'chapter49' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Covariant Return Types  </h1>

    <h3>Covariant Return Types in Java</h3>
    <p>
      In Java, <strong>covariant return types</strong> allow a method in a subclass to return a type that is a subtype (or subclass) of the type returned by the method in the superclass. This is a feature introduced in <strong>Java 5</strong> that enhances method overriding by allowing more flexibility in the return type while still adhering to the principle of method overriding.
    </p><br/>

    <h4>Key Points About Covariant Return Types:</h4>
    <ul>
      <li><strong>Inheritance Hierarchy</strong>: Covariant return types work in inheritance hierarchies, where a subclass can override a method and return a more specific type (a subclass) than the one returned by the superclass method.</li><br/>
      <li><strong>Type Substitution</strong>: In Java, a subclass can return an object of the subclass type in place of a superclass type. The return type in the subclass is said to be <strong>covariant</strong> because it follows a hierarchical relationship with the original return type in the superclass.</li><br/>
      <li><strong>Signature Matching</strong>: For a method in a subclass to override a method in a superclass, the method signatures must match — this includes the return type. However, with covariant return types, the return type in the subclass does not have to be the same as in the superclass, but it must be a subclass or subclass equivalent of the original return type.</li>
    </ul><br/>

    <h4>Example of Covariant Return Types:</h4>
    <pre>
      <code>{`// Superclass (Parent class)
class Animal {
    // Method in the parent class
    Animal getAnimal() {
        return new Animal();
    }
}

// Subclass (Child class)
class Dog extends Animal {
    // Overriding the getAnimal method in the child class
    @Override
    Dog getAnimal() {
        return new Dog();
    }
}

public class Main {
    public static void main(String[] args) {
        Animal animal = new Animal();
        animal.getAnimal(); // Calls Animal's getAnimal method

        Animal dog = new Dog(); // Upcasting
        dog.getAnimal(); // Calls Dog's getAnimal method due to covariant return type
    }
}`}</code>
    </pre>

    <h4>Explanation:</h4>
    <ol>
      <li>The <code>getAnimal()</code> method in the <code>Animal</code> class returns an <code>Animal</code> object.</li><br/>
      <li>The <code>Dog</code> class overrides the <code>getAnimal()</code> method but returns a <code>Dog</code> object, which is a subclass of <code>Animal</code>.</li><br/>
      <li>Despite the difference in the return type, Java allows this override because <code>Dog</code> is a subclass of <code>Animal</code>, and <code>Dog</code> is a <strong>covariant</strong> return type of <code>Animal</code>.</li><br/>
      <li>This allows the <code>Dog</code> class to be more specific in its return type while still conforming to the original method signature for overriding.</li>
    </ol><br/>

    <h4>Advantages of Covariant Return Types:</h4>
    <ul>
      <li><strong>Flexibility in Overriding</strong>: Covariant return types provide more flexibility when overriding methods, allowing subclasses to return more specific types without breaking the method signature contract.</li><br/>
      <li><strong>Type Safety</strong>: Since the return type is still part of the inheritance hierarchy, it maintains type safety, ensuring that the method still adheres to the rules of inheritance and polymorphism.</li><br/>
      <li><strong>Better Use of Inheritance</strong>: Covariant return types allow subclasses to take advantage of inheritance more effectively by tailoring methods to return more specialized objects while still respecting the method's contract in the superclass.</li>
    </ul><br/>

    <h4>Important Considerations:</h4>
    <ul>
      <li><strong>Method Signature</strong>: While the return type can change, the method name, parameters, and other parts of the signature must still be the same.</li><br/>
      <li><strong>Subtyping Relationship</strong>: The subclass return type must be a subtype of the superclass return type, or else the code will not compile.</li><br/>
      <li><strong>Runtime Polymorphism</strong>: Covariant return types enable more specific behavior for methods in subclasses while still maintaining the principle of runtime polymorphism.</li>
    </ul><br/>

    <h4>Example with Method Invocation:</h4>
    <pre>
      <code>{`// Superclass (Parent class)
class Animal {
    Animal getAnimal() {
        return new Animal();
    }
}

// Subclass (Child class) - Dog
class Dog extends Animal {
    @Override
    Dog getAnimal() {
        return new Dog();
    }
}

// Subclass (Child class) - Cat
class Cat extends Animal {
    @Override
    Animal getAnimal() {
        return new Cat();
    }
}

public class Main {
    public static void main(String[] args) {
        // Upcasting to Animal
        Animal animal = new Dog();  // Animal reference holding Dog object
        Animal animalReturned = animal.getAnimal(); // Calls Dog's getAnimal method
        System.out.println(animalReturned.getClass()); // Output: class Dog

        Animal animalCat = new Cat(); // Upcasting to Animal
        Animal catReturned = animalCat.getAnimal(); // Calls Cat's getAnimal method
        System.out.println(catReturned.getClass()); // Output: class Cat
    }
}`}</code>
    </pre>

    <h4>Explanation:</h4>
    <ul>
      <li>When we call <code>getAnimal()</code> on a <code>Dog</code> object, even though it's referenced by <code>Animal</code>, the method in the <code>Dog</code> class is called because of method overriding and polymorphism.</li><br/>
      <li>Similarly, calling <code>getAnimal()</code> on a <code>Cat</code> object invokes the overridden method in <code>Cat</code> due to method overriding.</li>
    </ul><br/>

    <h4>Conclusion:</h4>
    <p>
      Covariant return types provide a more specialized return type in subclass methods, making Java's polymorphism more powerful and flexible. It allows subclasses to refine the behavior of overridden methods, especially when dealing with types in inheritance hierarchies, without breaking the contract of method overriding.
    </p>
    <p>
      This feature of covariant return types is widely used in Java's class libraries, especially in collections and various object-oriented designs, where a more specific return type can be useful in subclass implementations.
    </p>
  </div>
)}



{selectedChapter === 'chapter50' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Super Keyword  </h1>

    <p>The <code>super</code> keyword in Java is used to refer to the superclass (parent class) of the current object. It plays a crucial role in inheritance and is often used to access the members (fields, methods, and constructors) of the parent class from the subclass. Here's an in-depth explanation of the <code>super</code> keyword:</p>
    <br/>
    <h2>Key Uses of the <code>super</code> Keyword:</h2>
    <ul>
      <li><strong>Access Superclass Methods:</strong> The <code>super</code> keyword can be used to call methods of the superclass that are overridden by the subclass. This is useful when you want to invoke the method from the parent class while overriding it in the subclass.</li><br/>
      <li><strong>Access Superclass Constructor:</strong> The <code>super</code> keyword can be used to explicitly call a constructor of the superclass. This is useful when you want to initialize the superclass with specific arguments before initializing the subclass.</li><br/>
      <li><strong>Access Superclass Fields:</strong> If a field (variable) is hidden (not overridden) in the subclass, you can use <code>super</code> to access the field of the superclass.</li>
    </ul><br/>

    <h2>Syntax of <code>super</code>:</h2>
    <ul>
      <li><code>super.methodName();</code> - Used to call a method from the superclass.</li><br/>
      <li><code>super(parameterList);</code> - Used to call a constructor from the superclass.</li><br/>
      <li><code>super.fieldName;</code> - Used to access a field from the superclass.</li>
    </ul><br/>

    <h2>Detailed Explanation with Examples:</h2>

    <h3>1. Accessing Superclass Methods</h3>
    <p>When a method is overridden in the subclass, but you still need to call the method in the parent class, you can use <code>super</code>.</p>

    <pre>
      <code>
{`class Animal {
    void makeSound() {
        System.out.println("Animal makes a sound");
    }
}

class Dog extends Animal {
    void makeSound() {
        super.makeSound();  // Calls the makeSound method of the Animal class
        System.out.println("Dog barks");
    }
}

public class Main {
    public static void main(String[] args) {
        Dog dog = new Dog();
        dog.makeSound();  // Output: Animal makes a sound \\n Dog barks
    }
}`}
      </code>
    </pre><br/>

    <h3>2. Accessing Superclass Constructor</h3>
    <p>A subclass constructor can invoke a superclass constructor using <code>super()</code>. This is typically used to initialize the superclass with certain parameters before initializing the subclass constructor.</p>

    <pre>
      <code>
{`class Animal {
    Animal(String name) {
        System.out.println("Animal name: " + name);
    }
}

class Dog extends Animal {
    Dog(String name) {
        super(name);  // Calls the constructor of the Animal class
        System.out.println("Dog's name: " + name);
    }
}

public class Main {
    public static void main(String[] args) {
        Dog dog = new Dog("Buddy");
        // Output: Animal name: Buddy
        //         Dog's name: Buddy
    }
}`}
      </code>
    </pre><br/>

    <h3>3. Accessing Superclass Fields</h3>
    <p>If a field in the subclass has the same name as a field in the superclass, you can use <code>super</code> to access the superclass field.</p>

    <pre>
      <code>
{`class Animal {
    String type = "Mammal";
}

class Dog extends Animal {
    String type = "Dog";

    void displayType() {
        System.out.println("Subclass type: " + type);  // Refers to Dog's type
        System.out.println("Superclass type: " + super.type);  // Refers to Animal's type
    }
}

public class Main {
    public static void main(String[] args) {
        Dog dog = new Dog();
        dog.displayType();
        // Output: Subclass type: Dog
        //         Superclass type: Mammal
    }
}`}
      </code>
    </pre><br/>

    <h3>4. Super in Constructor Chaining</h3>
    <p>Constructor chaining refers to calling one constructor from another within the same class or between superclass and subclass. Using <code>super()</code> helps in constructor chaining when the subclass constructor needs to call the superclass constructor.</p>

    <pre>
      <code>
{`class Animal {
    Animal() {
        System.out.println("Animal constructor called");
    }
}

class Dog extends Animal {
    Dog() {
        super();  // Calls Animal's constructor
        System.out.println("Dog constructor called");
    }
}

public class Main {
    public static void main(String[] args) {
        Dog dog = new Dog();
        // Output: Animal constructor called
        //         Dog constructor called
    }
}`}
      </code>
    </pre><br/>

    <h2>Important Considerations:</h2>
    <ul>
      <li><strong>Accessing Overridden Methods:</strong> If a subclass overrides a method, and you want to call the method from the superclass, you must use <code>super.methodName()</code>. If you don't, the subclass's overridden version of the method is called.</li><br/>
      <li><strong><code>super()</code> in Constructors:</strong> The <code>super()</code> constructor call must be the first statement in the constructor. If not explicitly called, the Java compiler inserts <code>super()</code> by default to call the no-argument constructor of the superclass.</li><br/>
      <li><strong>Limitation on Access:</strong> The <code>super</code> keyword can only be used to access members (methods, constructors, or fields) of the immediate superclass, not any class higher in the inheritance chain.</li>
    </ul><br/>

    <h2>Conclusion:</h2>
    <p>The <code>super</code> keyword in Java provides essential functionality for working with inheritance. It allows you to:</p>
    <ul>
      <li>Call superclass methods.</li>
      <li>Access constructors from the superclass.</li>
      <li>Access hidden fields in the superclass.</li>
    </ul>
    <p>It helps in maintaining a clean and organized inheritance structure, ensuring that a subclass can effectively use and extend the behavior of its superclass.</p>
  </div>
)}



{selectedChapter === 'chapter51' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Final Keyword    </h1>

    <h2>Key Uses of the <code>final</code> Keyword:</h2>
    <ul>
      <li><strong>Final Variables (Constants):</strong> When a variable is declared as <code>final</code>, it means that the value of the variable cannot be changed once it has been initialized. This is used to define constants.</li><br/>
      <li><strong>Final Methods:</strong> When a method is declared as <code>final</code>, it cannot be overridden by subclasses. This is useful when you want to prevent a method from being modified in a subclass.</li><br/>
      <li><strong>Final Classes:</strong> A class declared as <code>final</code> cannot be subclassed. This is useful when you want to prevent inheritance and ensure that the class cannot be extended.</li><br/>
      <li><strong>Final Parameters:</strong> A parameter can be declared as <code>final</code> in a method, meaning that its value cannot be changed within the method.</li>
    </ul><br/>

    <h2>Syntax of the <code>final</code> Keyword:</h2>
    <pre><code>{`final int MAX_VALUE = 100; // Final Variable

public final void display() { // Final Method
  System.out.println("This method cannot be overridden");
}

public final class Car { // Final Class
  // Class implementation
}

public void setValue(final int value) { // Final Parameter
  // value = 200; // This will cause an error because 'value' is final
}`}</code></pre><br/>

    <h2>Detailed Explanation with Examples:</h2>

    <h3>1. Final Variables (Constants):</h3>
    <p>In Java, a <code>final</code> variable is a constant whose value cannot be changed once it is initialized.</p>
    <pre><code>{`class Circle {
    final double PI = 3.14159;  // Final variable, cannot be modified

    public double calculateArea(double radius) {
        return PI * radius * radius;
    }
}`}</code></pre>
    <p>In this example, the <code>PI</code> variable is declared as <code>final</code>. Once initialized with the value <code>3.14159</code>, it cannot be modified. Attempting to assign a new value to <code>PI</code> anywhere in the class will result in a compilation error.</p>
    <br/>
    <h3>2. Final Methods:</h3>
    <p>A <code>final</code> method cannot be overridden in any subclass. This is useful when you want to maintain the original behavior of a method in the superclass.</p>
    <pre><code>{`class Animal {
    public final void makeSound() {
        System.out.println("Animal makes a sound");
    }
}

class Dog extends Animal {
    // Attempting to override the final method will result in an error
    // public void makeSound() {
    //     System.out.println("Dog barks");
    // }
}`}</code></pre>
    <p>In this example, the method <code>makeSound()</code> in the <code>Animal</code> class is declared as <code>final</code>. It cannot be overridden in the <code>Dog</code> subclass. If you attempt to override it in <code>Dog</code>, the code will not compile.</p>
    <br/>
    <h3>3. Final Classes:</h3>
    <p>A <code>final</code> class cannot be subclassed. This is used to create immutable classes or to prevent further modification of certain classes.</p>
    <pre><code>{`final class Vehicle {
    private String model;

    public Vehicle(String model) {
        this.model = model;
    }

    public String getModel() {
        return model;
    }
}

// The following class will cause a compilation error because Vehicle is final
// class Car extends Vehicle { }
`}</code></pre>
    <p>The class <code>Vehicle</code> is declared as <code>final</code>, meaning it cannot be subclassed. If you try to extend the <code>Vehicle</code> class, the Java compiler will generate an error.</p>
    <br/>
    <h3>4. Final Parameters:</h3>
    <p>A parameter can be declared as <code>final</code>, meaning its value cannot be changed within the method.</p>
    <pre><code>{`class Calculator {
    public int add(final int a, final int b) {
        // a = 5; // Error: Cannot assign a value to final variable a
        return a + b;
    }
}`}</code></pre>
    <p>The parameters <code>a</code> and <code>b</code> are declared as <code>final</code>, so their values cannot be modified inside the <code>add()</code> method.</p>
    <br/>
    <h2>Advantages of Using the <code>final</code> Keyword:</h2>
    <ul>
      <li><strong>Immutability:</strong> By declaring variables, methods, and classes as <code>final</code>, you can create immutable objects. This ensures that the object's state cannot change after it is created, which is useful for creating secure, reliable, and thread-safe code.</li><br/>
      <li><strong>Security:</strong> Using the <code>final</code> keyword helps in enforcing security by preventing unauthorized method overriding or class inheritance.</li><br/>
      <li><strong>Performance Optimization:</strong> The use of <code>final</code> can sometimes help the Java compiler optimize code, particularly with final variables, as it knows the value will not change during execution.</li><br/>
      <li><strong>Code Clarity:</strong> Declaring a method as <code>final</code> makes it clear to the developer that the method is designed to remain unchanged, enhancing readability and maintainability of the code.</li>
    </ul><br/>

    <h2>Important Considerations:</h2>
    <ul>
      <li><strong>Final Variables Initialization:</strong> A <code>final</code> variable must be initialized either during declaration or inside a constructor (if it is an instance variable).</li><br/>
      <li><strong>Final Methods in Subclasses:</strong> A <code>final</code> method can be called from subclasses, but it cannot be overridden. It ensures that the behavior remains consistent across subclasses.</li><br/>
      <li><strong>Final Classes and Inheritance:</strong> A <code>final</code> class cannot be subclassed. However, it can still be used as a base class for object creation and can implement interfaces.</li><br/>
      <li><strong>Effect on Polymorphism:</strong> Declaring a method as <code>final</code> in the superclass can limit polymorphism since subclasses cannot override the method. This can be an intentional design choice to restrict certain behaviors.</li>
    </ul><br/>

    <h2>Conclusion:</h2>
    <p>The <code>final</code> keyword in Java is a versatile tool that enforces immutability, restricts method overriding, and prevents inheritance. It is particularly useful for creating constants, preventing modification of critical behavior, and ensuring that specific parts of your code cannot be altered by subclasses.</p>
    <p>Understanding when and how to use the <code>final</code> keyword can lead to more secure, efficient, and maintainable Java code, especially in large and complex systems.</p>
  </div>
)}



{selectedChapter === 'chapter52' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Runtime Polymorphism </h1>

    <p>
      Runtime polymorphism, also known as dynamic method dispatch, is one of the most important concepts in Java's object-oriented programming model. It allows Java to determine at runtime which method to call based on the object type rather than the reference type. This is achieved through method overriding and is a core feature of polymorphism.
    </p><br/>

    <h3>Key Concepts of Runtime Polymorphism:</h3>
    <ul>
      <li><strong>Method Overriding:</strong> In runtime polymorphism, the subclass method overrides the superclass method. The version of the method that gets called depends on the object that invokes the method, not the reference type.</li><br/>
      <li><strong>Dynamic Method Dispatch:</strong> The decision about which method to invoke is made at runtime, rather than compile time. This enables Java to execute the correct method based on the object’s actual class type (the object that the reference variable points to).</li><br/>
      <li><strong>Inheritance:</strong> In order for runtime polymorphism to work, there must be an inheritance relationship. The subclass inherits the methods from the superclass and overrides them.</li>
    </ul><br/>

    <h3>How Runtime Polymorphism Works in Java:</h3>
    <p>
      In Java, runtime polymorphism is achieved when a superclass reference variable refers to a subclass object. When the method is invoked on the superclass reference, the JVM determines at runtime which version of the method to call based on the actual object type (whether it is an instance of the superclass or the subclass).
    </p>

    <h3>Example:</h3>
    <pre>
      <code>{`// Superclass
class Animal {
    void sound() {
        System.out.println("Animal makes a sound");
    }
}

// Subclass - Dog
class Dog extends Animal {
    @Override
    void sound() {
        System.out.println("Dog barks");
    }
}

// Another Subclass - Cat
class Cat extends Animal {
    @Override
    void sound() {
        System.out.println("Cat meows");
    }
}

public class Main {
    public static void main(String[] args) {
        // Creating objects of Dog and Cat
        Animal myAnimal = new Animal();
        Animal myDog = new Dog();
        Animal myCat = new Cat();

        // Demonstrating runtime polymorphism
        myAnimal.sound(); // Calls Animal's sound method
        myDog.sound();    // Calls Dog's sound method (due to method overriding)
        myCat.sound();    // Calls Cat's sound method (due to method overriding)
    }
}`}</code>
    </pre>

    <h3>Explanation of the Example:</h3>
    <ul>
      <li><strong>Animal Class (Superclass):</strong> The <code>sound()</code> method is defined in the <code>Animal</code> class, which is the base class.</li><br/>
      <li><strong>Dog and Cat Classes (Subclasses):</strong> The <code>Dog</code> and <code>Cat</code> classes override the <code>sound()</code> method to provide their own implementations.</li><br/>
      <li><strong>Runtime Polymorphism:</strong> In the <code>main()</code> method, the reference variable of type <code>Animal</code> is used to refer to objects of <code>Animal</code>, <code>Dog</code>, and <code>Cat</code>. At runtime, when the <code>sound()</code> method is called on each of these reference variables (<code>myAnimal</code>, <code>myDog</code>, <code>myCat</code>), the actual method that gets executed is determined by the type of object being referred to at runtime. Even though the reference variable is of type <code>Animal</code>, the JVM will invoke the <code>sound()</code> method of the respective subclass (<code>Dog</code> or <code>Cat</code>) based on the actual object type (whether it’s a <code>Dog</code> or <code>Cat</code>).</li>
    </ul><br/>

    <h3>Output of the Example:</h3>
    <pre>
      <code>{`Animal makes a sound
Dog barks
Cat meows`}</code>
    </pre><br/>

    <h3>Advantages of Runtime Polymorphism:</h3>
    <ul>
      <li><strong>Flexibility and Extensibility:</strong> With runtime polymorphism, code can be more flexible and easily extensible. New subclasses can be added without modifying the existing code that uses polymorphism.</li><br/>
      <li><strong>Loose Coupling:</strong> It decouples code by allowing a class to refer to objects of other classes (subclasses). This improves maintainability and readability.</li><br/>
      <li><strong>Code Reusability:</strong> Runtime polymorphism promotes code reusability as subclasses can be used interchangeably with their superclass references.</li><br/>
      <li><strong>Improved Maintainability:</strong> By overriding methods in subclasses, polymorphism allows changes to be localized within subclasses, which makes maintenance easier.</li>
    </ul><br/>

    <h3>Important Considerations:</h3>
    <ul>
      <li><strong>Method Signature:</strong> For runtime polymorphism to work, the method signature in the subclass must be the same as the one in the superclass. This includes the method name, return type, and parameters (method overriding rules).</li><br/>
      <li><strong>Late Binding:</strong> Runtime polymorphism involves <strong>late binding</strong>, meaning that the method call is bound to the method body during runtime, not at compile time. This is different from <strong>early binding</strong> (or static polymorphism) where the method call is resolved at compile time.</li><br/>
      <li><strong>Access Modifiers:</strong> The overridden method in the subclass should have at least the same or more permissive access modifier as the superclass method.</li><br/>
      <li><strong>`final` Methods:</strong> If a method in the superclass is marked as <code>final</code>, it cannot be overridden in the subclass, and thus, polymorphism cannot be achieved for that method.</li>
    </ul><br/>

    <h3>Conclusion:</h3>
    <p>
      Runtime polymorphism in Java is a powerful feature that allows the method to be dynamically chosen at runtime based on the object being referred to, rather than the reference type. It is implemented using method overriding and dynamic method dispatch, and it plays a crucial role in making Java programs more flexible, reusable, and maintainable. By leveraging runtime polymorphism, developers can write more extensible code and ensure that their applications can easily adapt to new types and behaviors.
    </p>
  </div>
)}



{selectedChapter === 'chapter53' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Dynamic Method Dispatch  </h1>

    <p>
      Dynamic Method Dispatch is a mechanism in Java that allows the JVM to decide at runtime which method to call, based on the actual object type rather than the reference type. This is a core concept in runtime polymorphism and is typically used with method overriding.
    </p>
    <br/>
    <p>
      In simpler terms, <strong>dynamic method dispatch</strong> is the process by which a call to an overridden method is resolved at runtime, rather than at compile time. This behavior enables Java to provide flexibility and allows for more extensible and maintainable code.
    </p>

    <h3>Key Concepts of Dynamic Method Dispatch:</h3>
    <ol>
      <li><strong>Method Overriding</strong>: 
        <p>
          Dynamic method dispatch works in the context of method overriding, where a subclass provides its own implementation of a method that is already defined in its superclass.
        </p>
      </li><br/>
      <li><strong>Runtime Resolution</strong>: 
        <p>
          The method call is resolved at runtime based on the actual type of the object, not the reference type. This dynamic decision-making occurs only when the method is called on the object, not when the program is compiled.
        </p>
      </li><br/>
      <li><strong>Inheritance</strong>: 
        <p>
          Dynamic method dispatch requires inheritance. The subclass must inherit from a superclass and override one or more methods to provide a specific implementation.
        </p>
      </li>
    </ol><br/>

    <h3>How Dynamic Method Dispatch Works in Java:</h3>
    <p>
      Java uses dynamic method dispatch to select the appropriate method during runtime. Here's how it happens:
    </p>
    <ul>
      <li>At compile-time, Java will only check for method signatures, ensuring the method is available in the class hierarchy.</li><br/>
      <li>When the method is called, Java will look at the actual object (i.e., the object that the reference variable points to) and call the correct overridden method.</li><br/>
      <li>Even though the reference variable type is a superclass, the actual method executed will be from the subclass (if it has overridden the method).</li>
    </ul><br/>

    <h3>Example of Dynamic Method Dispatch:</h3>

    <pre>
      <code>
{`// Superclass
class Animal {
    void sound() {
        System.out.println("Animal makes a sound");
    }
}

// Subclass 1
class Dog extends Animal {
    @Override
    void sound() {
        System.out.println("Dog barks");
    }
}

// Subclass 2
class Cat extends Animal {
    @Override
    void sound() {
        System.out.println("Cat meows");
    }
}

public class Main {
    public static void main(String[] args) {
        // Reference variable of superclass type, pointing to subclass objects
        Animal myAnimal = new Animal();  // Animal object
        Animal myDog = new Dog();        // Dog object
        Animal myCat = new Cat();        // Cat object

        // Dynamic Method Dispatch: at runtime, the correct method is called
        myAnimal.sound();  // Output: Animal makes a sound
        myDog.sound();     // Output: Dog barks
        myCat.sound();     // Output: Cat meows
    }
}`}
      </code>
    </pre><br/>

    <h3>Explanation of the Example:</h3>
    <ul>
      <li><strong>Animal Class (Superclass)</strong>: The sound() method is defined in the Animal class. This method is inherited by the Dog and Cat classes.</li><br/>
      <li><strong>Dog and Cat Classes (Subclasses)</strong>: The Dog and Cat classes override the sound() method to provide their own implementations. The Dog class provides a barking sound, and the Cat class provides a meowing sound.</li><br/>
      <li><strong>Dynamic Method Dispatch</strong>: In the main() method, reference variables of type Animal are used to refer to objects of Animal, Dog, and Cat. Although all the reference variables (myAnimal, myDog, myCat) are of type Animal, the actual method that gets invoked depends on the actual object type (i.e., whether it is a Dog, Cat, or Animal). This is dynamic method dispatch in action, as the method call is resolved at runtime based on the object type.</li>
    </ul><br/>

    <h3>Output of the Example:</h3>
    <pre>
      <code>
{`Animal makes a sound
Dog barks
Cat meows`}
      </code>
    </pre><br/>

    <h3>Advantages of Dynamic Method Dispatch:</h3>
    <ul>
      <li><strong>Flexibility</strong>: Dynamic method dispatch allows the method calls to be resolved dynamically at runtime, offering more flexibility. This is essential for creating extensible code that can easily accommodate new subclasses without modifying the existing code.</li><br/>
      <li><strong>Polymorphism</strong>: It enables polymorphism by allowing different subclasses to provide their own implementation of the same method, even though the reference type may be the superclass. This allows for dynamic behavior based on the object type.</li><br/>
      <li><strong>Loose Coupling</strong>: The method calls are decoupled from specific object types. This improves the maintainability and readability of the code.</li><br/>
      <li><strong>Code Reusability</strong>: Subclasses can reuse the methods of the superclass and provide specific implementations, making it easier to maintain and extend the code.</li>
    </ul><br/>

    <h3>Important Considerations:</h3>
    <ul>
      <li><strong>Method Signature</strong>: For dynamic method dispatch to work, the overridden method in the subclass must have the same method signature (name, return type, and parameters) as the one in the superclass.</li><br/>
      <li><strong>Late Binding</strong>: Dynamic method dispatch involves <em>late binding</em>, meaning the method call is resolved at runtime. This is in contrast to <em>early binding</em>, where method calls are resolved during compile time.</li><br/>
      <li><strong>Access Modifiers</strong>: The access modifier of the overridden method in the subclass must be at least as permissive as the one in the superclass. For example, if the method in the superclass is <em>protected</em>, the overridden method in the subclass can be <em>protected</em> or <em>public</em>, but not <em>private</em>.</li><br/>
      <li><strong>final Methods</strong>: If a method is marked as <em>final</em> in the superclass, it cannot be overridden in the subclass, and thus dynamic method dispatch will not apply to that method.</li>
    </ul><br/>

    <h3>Conclusion:</h3>
    <p>
      Dynamic method dispatch in Java allows for the method to be dynamically resolved at runtime based on the object type, enabling runtime polymorphism. It plays a crucial role in making Java code more flexible, extensible, and maintainable by allowing different implementations of the same method across subclasses. By leveraging dynamic method dispatch, Java developers can create more adaptable and reusable code.
    </p>
  </div>
)}



{selectedChapter === 'chapter54' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  `instanceof` with Polymorphism  </h1>

    <p>
      The <code>instanceof</code> operator in Java is used to check if an object is an instance of a specific class or a subclass thereof. It is often used in conjunction with polymorphism to ensure type safety when working with objects that could belong to different classes within an inheritance hierarchy.
    </p><br/>

    <p>
      In the context of polymorphism, the <code>instanceof</code> operator helps determine the actual type of the object being referenced by a superclass or interface reference, especially when dealing with method overriding or runtime polymorphism.
    </p><br/>

    <h4>Key Concepts of `instanceof` with Polymorphism:</h4>
    <ol>
      <li><strong>Type Checking at Runtime:</strong>
        <p>The <code>instanceof</code> operator checks the type of an object during runtime, ensuring that a reference variable points to an object of the appropriate type or subclass. It is particularly useful when a reference variable of a superclass or interface type is pointing to an object of a subclass.</p>
      </li><br/>
      <li><strong>Polymorphic Behavior:</strong>
        <p>In polymorphism, a reference variable of a superclass or interface type can point to objects of its subclass or implementing classes. The <code>instanceof</code> operator can be used to confirm the actual type of the object, allowing developers to safely cast objects when necessary.</p>
      </li><br/>
      <li><strong>Null Check:</strong>
        <p>The <code>instanceof</code> operator will return <code>false</code> when applied to a <code>null</code> reference, making it useful for avoiding <code>NullPointerExceptions</code>.</p>
      </li>
    </ol><br/>

    <h4>Syntax of `instanceof` Operator:</h4>
    <pre><code>object instanceof ClassName</code></pre>
    <ul>
      <li><strong>`object`</strong>: The object to test.</li><br/>
      <li><strong>`ClassName`</strong>: The class or interface to check against.</li>
    </ul><br/>
    <p>It returns <code>true</code> if the object is an instance of the specified class or subclass (or if it implements the specified interface), and <code>false</code> otherwise.</p>
    <br/>
    <h4>Example of `instanceof` with Polymorphism:</h4>
    <pre><code>{`// Superclass
class Animal {
    void sound() {
        System.out.println("Animal makes a sound");
    }
}

// Subclass 1
class Dog extends Animal {
    @Override
    void sound() {
        System.out.println("Dog barks");
    }
}

// Subclass 2
class Cat extends Animal {
    @Override
    void sound() {
        System.out.println("Cat meows");
    }
}

public class Main {
    public static void main(String[] args) {
        // Create objects
        Animal myAnimal = new Animal();
        Animal myDog = new Dog();
        Animal myCat = new Cat();

        // Using instanceof to check the type of the object
        if (myAnimal instanceof Dog) {
            System.out.println("myAnimal is an instance of Dog");
        } else {
            System.out.println("myAnimal is not an instance of Dog");
        }

        if (myDog instanceof Dog) {
            System.out.println("myDog is an instance of Dog");
        } else {
            System.out.println("myDog is not an instance of Dog");
        }

        if (myCat instanceof Cat) {
            System.out.println("myCat is an instance of Cat");
        } else {
            System.out.println("myCat is not an instance of Cat");
        }

        // Using instanceof with polymorphism
        if (myDog instanceof Animal) {
            System.out.println("myDog is an instance of Animal");
        }
    }
}`}</code></pre><br/>

    <h4>Explanation of the Example:</h4>
    <ol>
      <li><strong>Superclass (Animal):</strong>
        <p>The <code>sound()</code> method is defined in the <code>Animal</code> class, and both <code>Dog</code> and <code>Cat</code> classes inherit from <code>Animal</code>.</p>
      </li><br/>
      <li><strong>Subclasses (Dog and Cat):</strong>
        <p>The <code>Dog</code> and <code>Cat</code> classes override the <code>sound()</code> method to provide their own implementations.</p>
      </li><br/>
      <li><strong>Using `instanceof`:</strong>
        <p>In the <code>main()</code> method, we use the <code>instanceof</code> operator to check the type of the objects referenced by the superclass type (<code>Animal</code>).</p>
        <p>The first check (<code>myAnimal instanceof Dog</code>) will return <code>false</code> because <code>myAnimal</code> is an instance of <code>Animal</code>, not <code>Dog</code>.</p>
        <p>The second check (<code>myDog instanceof Dog</code>) will return <code>true</code> because <code>myDog</code> is an instance of <code>Dog</code>.</p>
        <p>The third check (<code>myCat instanceof Cat</code>) will return <code>true</code> because <code>myCat</code> is an instance of <code>Cat</code>.</p>
        <p>The last check (<code>myDog instanceof Animal</code>) will return <code>true</code> because <code>Dog</code> is a subclass of <code>Animal</code>, so <code>myDog</code> is an instance of <code>Animal</code>.</p>
      </li>
    </ol><br/>

    <h4>Output of the Example:</h4>
    <pre><code>{`myAnimal is not an instance of Dog
myDog is an instance of Dog
myCat is an instance of Cat
myDog is an instance of Animal`}</code></pre><br/>

    <h4>Advantages of Using `instanceof` with Polymorphism:</h4>
    <ul>
      <li><strong>Type Safety:</strong>
        <p><code>instanceof</code> ensures that type casting is done safely. You can check the type of an object before casting it, preventing <code>ClassCastException</code>.</p>
      </li><br/>
      <li><strong>Avoiding Runtime Errors:</strong>
        <p>Using <code>instanceof</code> helps avoid runtime errors that might occur if an object is cast to an incorrect type.</p>
      </li><br/>
      <li><strong>Flexible Code:</strong>
        <p>When dealing with polymorphic references, <code>instanceof</code> allows code to adapt dynamically to different types of objects, enabling more flexible designs, such as in case of handling multiple subclass types or implementing custom behavior based on object types.</p>
      </li>
    </ul><br/>

    <h4>Important Considerations:</h4>
    <ul>
      <li><strong>Performance:</strong>
        <p>While <code>instanceof</code> is useful, excessive usage in a program can lead to performance issues, as it requires checking types at runtime. In most cases, polymorphism should be leveraged in a way that minimizes the need for <code>instanceof</code>.</p>
      </li><br/>
      <li><strong>Readability:</strong>
        <p>Overuse of <code>instanceof</code> can lead to complex and hard-to-read code. It’s generally better to design your classes and methods in a way that reduces the need for checking types explicitly.</p>
      </li><br/>
      <li><strong>Null Reference:</strong>
        <p>Applying <code>instanceof</code> to a <code>null</code> reference will always return <code>false</code>. This behavior can be used as a safeguard when checking types.</p>
      </li>
    </ul><br/>

    <h4>Conclusion:</h4>
    <p>
      The <code>instanceof</code> operator in Java is a powerful tool for ensuring type safety and handling polymorphic behavior, especially when working with references of superclass types that point to objects of subclass types. It provides a way to check the actual type of an object at runtime, making it possible to safely perform type-specific operations or casting. When used properly, <code>instanceof</code> enhances the flexibility and maintainability of Java programs.
    </p>
  </div>
)}



{selectedChapter === 'chapter55' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Abstract Class   </h1>

    <section>
      
      <p>An <strong>abstract class</strong> in Java is a class that cannot be instantiated on its own, but it can be subclassed by other classes. It serves as a blueprint for other classes and can contain both abstract methods (methods without a body) and concrete methods (methods with an implementation).</p>
    </section><br/>

    <section>
      <h2>Key Concepts of Abstract Class</h2>
      <ul>
        <li><strong>Abstract Methods</strong>: These methods are declared without an implementation. Subclasses of the abstract class are required to implement these methods.</li><br/>
        <li><strong>Concrete Methods</strong>: These methods are fully implemented and can be used directly by subclasses or overridden if needed.</li><br/>
        <li><strong>Cannot Instantiate an Abstract Class</strong>: You cannot create an instance of an abstract class directly. However, concrete subclasses can be instantiated.</li><br/>
        <li><strong>Purpose of Abstract Class</strong>: It provides a common interface and shared functionality for subclasses, while allowing them to implement specific behaviors.</li><br/>
        <li><strong>Inheritance</strong>: Abstract classes are often used as base classes in inheritance hierarchies, where concrete subclasses provide implementations for abstract methods.</li>
      </ul>
    </section><br/>

    <section>
      <h2>Syntax of Abstract Class</h2>
      <pre><code>{`abstract class ClassName {
    // Abstract method (does not have a body)
    abstract void abstractMethod();

    // Regular method
    void regularMethod() {
        System.out.println("This is a regular method.");
    }
}`}</code></pre>
    </section>

    <section>
      <h2>Example of Abstract Class in Java</h2>
      <pre><code>{`abstract class Animal {
    // Abstract method (does not have a body)
    abstract void sound();

    // Regular method
    void sleep() {
        System.out.println("This animal is sleeping.");
    }
}

class Dog extends Animal {
    void sound() {
        System.out.println("Dog barks");
    }
}

class Cat extends Animal {
    void sound() {
        System.out.println("Cat meows");
    }
}

public class Main {
    public static void main(String[] args) {
        Animal dog = new Dog();
        dog.sound();  // Outputs: Dog barks
        dog.sleep();  // Outputs: This animal is sleeping.

        Animal cat = new Cat();
        cat.sound();  // Outputs: Cat meows
        cat.sleep();  // Outputs: This animal is sleeping.
    }
}`}</code></pre>
    </section><br/>

    <section>
      <h2>Explanation of the Example</h2>
      <ul>
        <li><strong>Abstract Class (Animal)</strong>: The `Animal` class contains both an abstract method `sound()` and a concrete method `sleep()`.</li><br/>
        <li><strong>Concrete Subclasses (Dog and Cat)</strong>: The `Dog` and `Cat` classes implement the abstract method `sound()`.</li><br/>
        <li><strong>Using the Abstract Class</strong>: While you cannot instantiate the `Animal` class, you can instantiate subclasses like `Dog` and `Cat` and refer to them using the `Animal` reference, demonstrating polymorphism.</li>
      </ul>
    </section><br/>

    <section>
      <h2>Key Points</h2>
      <ul>
        <li><strong>Abstract Methods</strong>: They enforce a contract that subclasses must implement.</li><br/>
        <li><strong>Concrete Methods</strong>: Subclasses can use or override these methods.</li><br/>
        <li><strong>Cannot Instantiate Abstract Classes</strong>: You can only create objects of subclasses that implement the abstract methods.</li><br/>
        <li><strong>Polymorphism</strong>: Abstract classes enable polymorphism by allowing different implementations of abstract methods in subclasses.</li>
      </ul>
    </section><br/>

    <section>
      <h2>Advantages of Abstract Classes</h2>
      <ul>
        <li><strong>Code Reusability</strong>: Abstract classes allow common functionality to be reused by subclasses.</li><br/>
        <li><strong>Flexibility</strong>: They allow subclasses to define specific behavior while still providing shared functionality.</li><br/>
        <li><strong>Enforcing Method Implementation</strong>: Abstract methods ensure subclasses implement specific methods, promoting a common contract.</li>
      </ul>
    </section><br/>

    <section>
      <h2>When to Use an Abstract Class</h2>
      <p>Use an abstract class when you need to define a common base with some methods implemented, and you expect subclasses to provide specific implementations for other methods. It is ideal when you have a class hierarchy and the parent class cannot be instantiated but still serves as a foundation for child classes.</p>
    </section><br/>

    <section>
      <h2>Abstract Class vs Interface</h2>
      <ul>
        <li><strong>Abstract Class</strong>: Can have both abstract and concrete methods and can have instance variables.</li><br/>
        <li><strong>Interface</strong>: All methods are abstract by default (unless marked as `default` or `static`), and interfaces cannot have instance variables (only constants).</li>
      </ul>
    </section><br/>

    <section>
      <h2>Conclusion</h2>
      <p>An abstract class in Java provides a blueprint for other classes, where some methods must be implemented by subclasses, and others can be used directly. Abstract classes are useful for defining common functionality while leaving the implementation details to subclasses.</p>
    </section>
  </div>
)}



{selectedChapter === 'chapter56' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Interface   </h1>

    <p>
      An <strong>interface</strong> in Java is a reference type, similar to a class, that can contain only constants, method signatures, default methods, static methods, and nested types. It cannot contain instance fields or constructors. Interfaces are used to specify a contract for classes to follow, and a class that implements an interface must provide implementations for all the methods declared by the interface, unless the class is abstract.
    </p><br/>

    <h2>Key Concepts of Interface in Java</h2>
    <ul>
      <li><strong>Method Declarations:</strong> An interface can only contain method declarations (method signatures) without any method bodies. These methods are abstract by default, and any class that implements the interface must provide concrete implementations for these methods.</li><br/>
      <li><strong>Implementing Interfaces:</strong> A class that implements an interface must provide an implementation for all the abstract methods declared in the interface (unless the class is abstract). A class can implement multiple interfaces, which is one of the key features of interfaces in Java, providing a form of multiple inheritance.</li><br/>
      <li><strong>Default Methods:</strong> From Java 8 onward, interfaces can have <strong>default methods</strong> with a body. These methods have a default implementation, and any class implementing the interface does not need to override them unless desired.</li><br/>
      <li><strong>Static Methods:</strong> Java 8 introduced the ability to define <strong>static methods</strong> within an interface. These methods must have a body and can be called using the interface name.</li><br/>
      <li><strong>Constants:</strong> All fields in an interface are implicitly public, static, and final (constant values). You cannot declare instance variables in an interface.</li><br/>
      <li><strong>No Constructor:</strong> Interfaces cannot have constructors, as they cannot be instantiated directly. They are meant to be implemented by classes.</li><br/>
      <li><strong>Multiple Inheritance:</strong> In Java, a class can implement multiple interfaces, allowing for a form of multiple inheritance. This is useful when a class needs to inherit behaviors from multiple sources.</li>
    </ul><br/>

    <h2>Syntax of an Interface</h2>
    <pre>
      <code>
{`interface InterfaceName {
  // Constant (implicitly public, static, and final)
  int CONSTANT = 10;

  // Abstract method (implicitly public and abstract)
  void abstractMethod();

  // Default method (Java 8+)
  default void defaultMethod() {
    System.out.println("This is a default method.");
  }

  // Static method (Java 8+)
  static void staticMethod() {
    System.out.println("This is a static method.");
  }
}`}
      </code>
    </pre><br/>

    <h2>Example of Interface in Java</h2>
    <pre>
      <code>
{`// Define the interface
interface Animal {
  // Abstract method (no implementation)
  void sound();

  // Default method
  default void sleep() {
    System.out.println("This animal is sleeping.");
  }

  // Static method
  static void info() {
    System.out.println("Animals are living creatures.");
  }
}

// Implementing the interface in a class
class Dog implements Animal {
  // Provide implementation for the abstract method
  public void sound() {
    System.out.println("Dog barks");
  }
}

class Cat implements Animal {
  // Provide implementation for the abstract method
  public void sound() {
    System.out.println("Cat meows");
  }
}

public class Main {
  public static void main(String[] args) {
    // Create objects of classes that implement the interface
    Animal dog = new Dog();
    dog.sound();  // Outputs: Dog barks
    dog.sleep();  // Outputs: This animal is sleeping.

    Animal cat = new Cat();
    cat.sound();  // Outputs: Cat meows
    cat.sleep();  // Outputs: This animal is sleeping.

    // Using the static method from the interface
    Animal.info();  // Outputs: Animals are living creatures.
  }
}`}
      </code>
    </pre>
    <pre>
      <code>{`Dog barks
This animal is sleeping.
Cat meows
This animal is sleeping.
Animals are living creatures.`}</code></pre><br/>

    <h2>Explanation of the Example</h2>
    <ul>
      <li><strong>Interface (Animal):</strong> The <code>Animal</code> interface defines an abstract method <code>sound()</code>, a default method <code>sleep()</code>, and a static method <code>info()</code>. The abstract method <code>sound()</code> does not have an implementation, so any class that implements <code>Animal</code> must provide its implementation. The default method <code>sleep()</code> has a body, so implementing classes can use it as-is or override it if desired. The static method <code>info()</code> belongs to the interface itself and can be called using the interface name.</li><br/>
      <li><strong>Implementing the Interface:</strong> The <code>Dog</code> and <code>Cat</code> classes implement the <code>Animal</code> interface and provide specific implementations for the <code>sound()</code> method. They can also use the default <code>sleep()</code> method without having to provide an implementation for it.</li><br/>
      <li><strong>Using the Interface:</strong> In the <code>Main</code> class, we create instances of <code>Dog</code> and <code>Cat</code> using an <code>Animal</code> reference. This demonstrates polymorphism, as both <code>Dog</code> and <code>Cat</code> are types of <code>Animal</code>. We also call the static method <code>info()</code> from the <code>Animal</code> interface and the default method <code>sleep()</code> inherited from the interface.</li>
    </ul><br/>

    <h2>Key Points:</h2>
    <ul>
      <li><strong>Abstract Methods:</strong> An interface provides method signatures without implementation. Classes that implement the interface must provide implementations for these methods.</li><br/>
      <li><strong>Default Methods:</strong> Default methods allow interfaces to provide method bodies, reducing the need for all implementing classes to override them.</li><br/>
      <li><strong>Static Methods:</strong> Static methods can be defined in an interface and can be invoked using the interface name.</li><br/>
      <li><strong>Multiple Inheritance:</strong> A class can implement multiple interfaces, allowing for a form of multiple inheritance.</li><br/>
      <li><strong>Constant Fields:</strong> All fields in an interface are implicitly <code>public</code>, <code>static</code>, and <code>final</code> (constant).</li>
    </ul><br/>

    <h2>Advantages of Interfaces:</h2>
    <ul>
      <li><strong>Multiple Inheritance:</strong> A class can implement multiple interfaces, enabling it to inherit behavior from more than one source.</li><br/>
      <li><strong>Decoupling:</strong> Interfaces help decouple the design, allowing different classes to implement the same interface but provide their specific behavior.</li><br/>
      <li><strong>Flexibility:</strong> Interfaces allow classes to adhere to common behavior while leaving the specifics to the implementing classes, providing flexibility in implementation.</li>
    </ul><br/>

    <h2>When to Use an Interface:</h2>
    <ul>
      <li>Use an interface when you want to define a contract for classes to follow, without dictating the specific implementation.</li><br/>
      <li>Interfaces are ideal for defining common functionality across classes that don't share a common base class.</li><br/>
      <li>Interfaces are useful when multiple classes need to implement common methods but may have different behavior.</li>
    </ul><br/>

    <h2>Interface vs Abstract Class:</h2>
    <ul>
      <li><strong>Abstract Class:</strong> Can have both abstract and concrete methods. It can have instance variables and constructors.</li><br/>
      <li><strong>Interface:</strong> Can only contain abstract methods (unless Java 8+ features are used). Cannot have instance variables or constructors (except static final constants).</li>
    </ul><br/>

    <h2>Conclusion:</h2>
    <p>
      An interface in Java defines a contract that classes must follow by implementing its methods. It allows for multiple inheritance and provides a flexible way to decouple code. By using interfaces, you can define common functionality while leaving the implementation details to the classes that implement them.
    </p>
  </div>
)}



{selectedChapter === 'chapter57' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Abstract Class vs Interface    </h1>

    <p>
      In Java, both <strong>abstract classes</strong> and <strong>interfaces</strong> are used to achieve abstraction, but they have key differences in terms of their design, usage, and functionality. Let’s explore these differences in detail.
    </p>

    <br/>

    <h2>1. Abstract Class</h2>

    <p>
      An <strong>abstract class</strong> is a class that cannot be instantiated on its own and is meant to be inherited by other classes. It is used to represent a common base class for all classes that share some common behavior.
    </p><br/>

    <h3>Key Features of Abstract Class:</h3>
    <ul>
      <li><strong>Abstract Methods:</strong> An abstract class can have abstract methods (methods without a body) as well as concrete methods (methods with a body).</li><br/>
      <li><strong>Constructors:</strong> Abstract classes can have constructors, which can be called by subclasses.</li><br/>
      <li><strong>Instance Variables:</strong> Abstract classes can have instance variables.</li><br/>
      <li><strong>Access Modifiers:</strong> Abstract methods in an abstract class can have any access modifier (e.g., `public`, `protected`, `private`).</li><br/>
      <li><strong>Multiple Inheritance:</strong> A class can extend only one abstract class (i.e., Java does not support multiple inheritance via classes).</li>
    </ul><br/>

    <h3>Syntax of an Abstract Class:</h3>
    <pre>
      <code>
{`abstract class Animal {
    // Abstract method (no implementation)
    abstract void sound();
    
    // Concrete method (has implementation)
    void sleep() {
        System.out.println("This animal is sleeping.");
    }
}`}
      </code>
    </pre><br/>

    <h3>Example of Abstract Class:</h3>
    <pre>
      <code>
{`abstract class Animal {
    abstract void sound();  // Abstract method

    void sleep() {  // Concrete method
        System.out.println("This animal is sleeping.");
    }
}

class Dog extends Animal {
    void sound() {  // Implementing abstract method
        System.out.println("Dog barks");
    }
}

public class Main {
    public static void main(String[] args) {
        Animal dog = new Dog();
        dog.sound();  // Outputs: Dog barks
        dog.sleep();  // Outputs: This animal is sleeping.
    }
}`}
      </code>
    </pre><br/>

    <h2>2. Interface</h2>

    <p>
      An <strong>interface</strong> in Java is a reference type, similar to a class, that can contain only constants, method signatures, default methods, static methods, and nested types. It defines a contract that a class must follow.
    </p><br/>

    <h3>Key Features of Interface:</h3>
    <ul>
      <li><strong>Abstract Methods:</strong> Interfaces can only have abstract methods (methods without a body) until Java 8, when default and static methods were introduced.</li><br/>
      <li><strong>Default Methods:</strong> From Java 8 onward, interfaces can have default methods with a body. These methods have a default implementation.</li><br/>
      <li><strong>Static Methods:</strong> Interfaces can have static methods with a body.</li><br/>
      <li><strong>No Constructors:</strong> Interfaces cannot have constructors because they cannot be instantiated directly.</li><br/>
      <li><strong>Multiple Inheritance:</strong> A class can implement multiple interfaces, allowing for a form of multiple inheritance.</li><br/>
      <li><strong>Constants:</strong> All fields in an interface are `public`, `static`, and `final` by default, meaning they are constants.</li>
    </ul><br/>

    <h3>Syntax of an Interface:</h3>
    <pre>
      <code>
{`interface Animal {
    // Abstract method (no implementation)
    void sound();

    // Default method (Java 8+)
    default void sleep() {
        System.out.println("This animal is sleeping.");
    }

    // Static method (Java 8+)
    static void info() {
        System.out.println("Animals are living creatures.");
    }
}`}
      </code>
    </pre><br/>

    <h3>Example of Interface:</h3>
    <pre>
      <code>
{`interface Animal {
    void sound();  // Abstract method

    default void sleep() {  // Default method
        System.out.println("This animal is sleeping.");
    }

    static void info() {  // Static method
        System.out.println("Animals are living creatures.");
    }
}

class Dog implements Animal {
    public void sound() {  // Implementing abstract method
        System.out.println("Dog barks");
    }
}

public class Main {
    public static void main(String[] args) {
        Animal dog = new Dog();
        dog.sound();  // Outputs: Dog barks
        dog.sleep();  // Outputs: This animal is sleeping.
        Animal.info();  // Outputs: Animals are living creatures.
    }
}`}
      </code>
    </pre>

    <br/>

    <h2>Key Differences Between Abstract Class and Interface</h2>

    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Abstract Class</th>
          <th>Interface</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Method Implementation</td>
          <td>Can have both abstract (without body) and concrete (with body) methods.</td>
          <td>Can have only abstract methods (before Java 8). From Java 8, can have default and static methods.</td>
        </tr>
        <tr>
          <td>Multiple Inheritance</td>
          <td>A class can inherit from only one abstract class.</td>
          <td>A class can implement multiple interfaces.</td>
        </tr>
        <tr>
          <td>Constructors</td>
          <td>Can have constructors.</td>
          <td>Cannot have constructors.</td>
        </tr>
        <tr>
          <td>Instance Variables</td>
          <td>Can have instance variables.</td>
          <td>Cannot have instance variables (only constants).</td>
        </tr>
        <tr>
          <td>Access Modifiers</td>
          <td>Methods can have any access modifier (e.g., `private`, `protected`, `public`).</td>
          <td>Methods are implicitly `public` in an interface.</td>
        </tr>
        <tr>
          <td>Default Methods</td>
          <td>No concept of default methods.</td>
          <td>Can have default methods (Java 8+).</td>
        </tr>
        <tr>
          <td>Static Methods</td>
          <td>Can have static methods.</td>
          <td>Can have static methods (Java 8+).</td>
        </tr>
        <tr>
          <td>Field Modifiers</td>
          <td>Can have instance variables with any modifiers.</td>
          <td>All fields are implicitly `public`, `static`, and `final`.</td>
        </tr>
        <tr>
          <td>Purpose</td>
          <td>Used to provide a common base for related classes with shared behavior.</td>
          <td>Used to define a contract that multiple classes can implement.</td>
        </tr>
        <tr>
          <td>Instantiation</td>
          <td>Cannot be instantiated directly, but can be inherited.</td>
          <td>Cannot be instantiated directly.</td>
        </tr>
      </tbody>
    </table><br/>

    <h3>When to Use an Abstract Class:</h3>
    <ul>
      <li>Use an abstract class when you want to share common behavior among several related classes but still allow each class to have some of its own methods.</li><br/>
      <li>If there are common attributes and behavior that should be shared across a family of related classes, but you still want to leave certain methods to be implemented by subclasses.</li><br/>
      <li>When you want to provide default functionality and leave the option to override certain methods.</li>
    </ul><br/>

    <h3>When to Use an Interface:</h3>
    <ul>
      <li>Use an interface when you want to define a contract for behavior that can be implemented by any class, irrespective of where the class lies in the class hierarchy.</li><br/>
      <li>When a class is supposed to perform multiple behaviors (like different types of actions or roles), you can define these behaviors as separate interfaces and implement them in a single class.</li><br/>
      <li>If you need multiple inheritance, where a class implements multiple interfaces.</li>
    </ul><br/>

    <h3>Conclusion</h3>
    <p>
      <strong>Abstract Class:</strong> Suitable when you have a common base class with shared functionality but need to allow customization by subclasses. It is used when there’s a need to represent a "is-a" relationship with shared code.
    </p>
    <p>
      <strong>Interface:</strong> Suitable when you want to define a contract or blueprint for classes without specifying how the methods are implemented. It allows multiple inheritance and is ideal when defining unrelated classes with common behavior (e.g., `Runnable`, `Serializable`).
    </p>
    <p>
      Both abstract classes and interfaces are powerful tools in Java’s object-oriented design, and the choice between them depends on the design requirements and whether you need shared behavior or simply a contract to be followed.
    </p>
  </div>
)}



{selectedChapter === 'chapter58' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Package     </h1>

    <p>
      In Java, <strong>encapsulation</strong> refers to the concept of bundling the data (variables) and the methods
      that operate on the data into a single unit, i.e., a class. This is done by hiding the internal state of the object
      and providing controlled access through getters and setters.
    </p><br/>

    <p>
      A <strong>package</strong> is a way to group related classes and interfaces together. It plays a key role in
      organizing and encapsulating classes in large applications. By defining a package, you can manage the visibility of
      your classes and restrict access to them. In the context of <strong>Java Encapsulation</strong>, packages help to
      control access levels for classes and their members (fields and methods), allowing certain classes to be hidden or
      exposed to others based on your design needs.
    </p><br/>

    <h2>Role of Packages in Encapsulation</h2>
    <ol>
      <li><strong>Access Control</strong>: Packages help you define which classes, methods, and fields are visible and accessible to other parts of the application. Java’s access modifiers (like `public`, `private`, `protected`, and default) work in conjunction with packages to enforce encapsulation.</li><br/>
      <li><strong>Grouping Related Classes</strong>: Packages allow you to group classes with similar functionality, which aids in organizing and encapsulating your application. For example, classes that handle database operations can be placed in a package like `com.myapp.database`, and classes that manage user interface components can be placed in `com.myapp.ui`.</li><br/>
      <li><strong>Namespace Management</strong>: A package ensures that classes and interfaces have unique names, avoiding naming conflicts between different parts of the application.</li><br/>
      <li><strong>Access Modifiers and Packages</strong>: By placing classes in a package, you can restrict access to those classes. Classes within the same package can access each other's private members, promoting encapsulation and reducing unnecessary exposure.</li>
    </ol><br/>

    <h2>Access Modifiers and Packages</h2>
    <p>Java provides four types of access modifiers, which control the visibility of classes, methods, and fields within a package or to other packages:</p>
    <br/>
    <h3>1. Public</h3>
    <p>
      When a class, method, or field is declared <strong>public</strong>, it is accessible from any other class or package.
      However, it may expose more functionality than necessary, which can compromise encapsulation.
    </p>
    <pre>
      <code>
        {`public class Person {
  public String name;

  public void setName(String name) {
    this.name = name;
  }

  public String getName() {
    return name;
  }
}`}
      </code>
    </pre><br/>

    <h3>2. Private</h3>
    <p>
      Private members (fields and methods) can only be accessed within the class they are declared. This is the core principle
      of encapsulation, where the internal state of an object is hidden and controlled through methods (getters and setters).
    </p>
    <pre>
      <code>
        {`class Person {
  private String name;

  private void setName(String name) {
    this.name = name;
  }

  private String getName() {
    return name;
  }
}`}
      </code>
    </pre><br/>

    <h3>3. Protected</h3>
    <p>
      Protected members can be accessed within the same package or by subclasses. This allows for some degree of encapsulation
      while still enabling certain types of inheritance.
    </p>
    <pre>
      <code>
        {`class Person {
  protected String name;

  protected void setName(String name) {
    this.name = name;
  }

  protected String getName() {
    return name;
  }
}`}
      </code>
    </pre><br/>

    <h3>4. Default (Package-Private)</h3>
    <p>
      If no access modifier is specified, the member is accessible only within the same package. This is useful when you want
      to keep the implementation details hidden from external packages but still accessible within the same package.
    </p>
    <pre>
      <code>
        {`class Person {
  String name;  // Default access modifier (package-private)

  void setName(String name) {
    this.name = name;
  }

  String getName() {
    return name;
  }
}`}
      </code>
    </pre><br/>

    <h2>Packages and Encapsulation Example</h2>

    <h3>Person.java (in `com.example.model` package)</h3>
    <pre>
      <code>
        {`package com.example.model;

public class Person {
  private String name;  // private field

  // Constructor
  public Person(String name) {
    this.name = name;
  }

  // Getter and Setter methods to access the name field
  public String getName() {
    return name;
  }

  public void setName(String name) {
    this.name = name;
  }
}`}
      </code>
    </pre><br/>

    <h3>PersonDetails.java (in `com.example.ui` package)</h3>
    <pre>
      <code>
        {`package com.example.ui;

import com.example.model.Person;

public class PersonDetails {

  public static void main(String[] args) {
    // Creating an object of the Person class from another package
    Person person = new Person("John Doe");

    // Accessing the encapsulated data via getter method
    System.out.println("Person Name: " + person.getName());

    // Modifying the encapsulated data via setter method
    person.setName("Jane Smith");
    System.out.println("Updated Person Name: " + person.getName());
  }
}`}
      </code>
    </pre><br/>

    <h2>Explanation</h2>
    <ul>
      <li><strong>Package Organization</strong>: In the example above, we have organized the classes into different packages:
        <ul>
          <li><strong>com.example.model</strong> for the core class <code>Person</code> that encapsulates the <code>name</code> field.</li><br/>
          <li><strong>com.example.ui</strong> for the <code>PersonDetails</code> class, which interacts with the <code>Person</code> class.</li><br/>
        </ul>
      </li><br/>
      <li><strong>Encapsulation</strong>: The <code>Person</code> class uses private access to encapsulate the <code>name</code> field. The only way to access or modify it is through the public getter and setter methods. This enforces a controlled access pattern, ensuring that no other part of the program can directly change the internal state of the object in ways that might lead to inconsistent or invalid data.</li><br/>
      <li><strong>Access Control</strong>: Even though <code>Person</code> is in a different package, we can still interact with its public methods (<code>getName()</code> and <code>setName()</code>). However, the <code>name</code> field is not directly accessible from outside the <code>Person</code> class, which demonstrates the encapsulation principle.</li><br/>
      <li><strong>Visibility Control with Packages</strong>: The <code>PersonDetails</code> class has access to the <code>Person</code> class through the public methods, but the <code>name</code> field itself remains private. This ensures that only valid interactions with the <code>Person</code> class are allowed.</li>
    </ul><br/>

    <h2>Benefits of Using Packages in Encapsulation</h2>
    <ul>
      <li><strong>Organized Code</strong>: Packages help organize code logically and ensure that related classes are grouped together.</li><br/>
      <li><strong>Access Control</strong>: By using access modifiers and packages, you can ensure that only certain parts of the code can access or modify sensitive data, which reinforces encapsulation.</li><br/>
      <li><strong>Namespace Management</strong>: Packages provide namespaces, which prevent name conflicts between classes and improve the modularity of the code.</li><br/>
      <li><strong>Code Reusability</strong>: Code within packages can be reused in other projects while keeping the internal details hidden.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      In Java, packages play a significant role in encapsulation by helping to manage access to classes and their members. By carefully using packages, access modifiers, and encapsulation techniques, you can design systems that are modular, secure, and maintainable. Packages organize classes, and encapsulation ensures that their internal state is protected and only accessible in controlled ways. Together, these concepts form the foundation for good object-oriented design in Java.
    </p>
  </div>
)}




{selectedChapter === 'chapter59' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Access Modifiers  </h1>


      <p>
        In Java, <strong>encapsulation</strong> is a key concept in object-oriented programming (OOP), where the internal state of an object is hidden from the outside world and can only be accessed through well-defined interfaces (getters and setters). <strong>Access modifiers</strong> play a crucial role in controlling this access, making it one of the core principles of encapsulation. Java provides several types of access modifiers that determine the visibility of classes, methods, and fields.
      </p><br/>

      <h2>Types of Access Modifiers in Java</h2>

      <p>Java offers four main access modifiers:</p>
      <ol>
        <li><strong>Public</strong></li><br/>
        <li><strong>Private</strong></li><br/>
        <li><strong>Protected</strong></li><br/>
        <li><strong>Default (Package-Private)</strong></li>
      </ol><br/>

      <p>Each modifier has a different level of visibility and control over how classes, fields, and methods can be accessed by other classes and packages.</p>
      <br/>
      <h3>1. Public Access Modifier</h3>
      <p><strong>Visibility:</strong> The <code>public</code> modifier provides the widest access level. Members marked as <code>public</code> are accessible from any other class, regardless of the package in which they are defined.</p>
      <p><strong>Use in Encapsulation:</strong> While <code>public</code> gives the most accessibility, it is often not recommended to make fields or methods <code>public</code> in encapsulation because it exposes the internal state of an object. However, it’s common for getter and setter methods to be declared <code>public</code>, as they allow controlled access to private fields.</p>
      <pre>
        <code>
          {`public class Person {
    public String name;  // public field

    // Public method to access the name field
    public String getName() {
        return name;
    }
}`}
        </code>
      </pre>

      <p>In this case, the <code>name</code> field is public, meaning it can be accessed directly by any class. This may break the encapsulation principle, as the internal state of the object can be modified directly without validation.</p>
      <br/>
      <h3>2. Private Access Modifier</h3>
      <p><strong>Visibility:</strong> The <code>private</code> modifier restricts access to the class itself. Fields and methods marked as <code>private</code> can only be accessed within the same class, and no other class (even those in the same package) can access them directly.</p>
      <p><strong>Use in Encapsulation:</strong> This is the core of encapsulation. By declaring fields <code>private</code>, you can ensure that the internal state of an object is hidden from outside manipulation. Public getter and setter methods are typically used to access and modify these private fields in a controlled manner.</p>
      <pre>
        <code>
          {`public class Person {
    private String name;  // private field

    // Getter and Setter methods
    public String getName() {
        return name;
    }

    public void setName(String name) {
        if (name != null && !name.isEmpty()) {  // adding validation
            this.name = name;
        }
    }
}`}
        </code>
      </pre>

      <p>In this case, the <code>name</code> field is private, so it cannot be directly accessed from outside the class. Instead, controlled access is provided via the <code>getName()</code> and <code>setName()</code> methods, which can include validation or additional logic.</p>
      <br/>
      <h3>3. Protected Access Modifier</h3>
      <p><strong>Visibility:</strong> The <code>protected</code> modifier makes members accessible within the same package and by subclasses, even if they are in different packages. It provides a balance between <code>private</code> and <code>public</code> by allowing subclasses to inherit and use protected fields or methods.</p>
      <p><strong>Use in Encapsulation:</strong> The <code>protected</code> modifier is useful when you want to allow inheritance and share access to certain fields or methods with subclasses, while still preventing access from non-related classes.</p>
      <pre>
        <code>
          {`public class Person {
    protected String name;  // protected field

    // Getter and Setter methods
    public String getName() {
        return name;
    }

    public void setName(String name) {
        this.name = name;
    }
}

public class Employee extends Person {
    public void displayInfo() {
        // Can access the protected field 'name' from the parent class
        System.out.println("Employee Name: " + name);
    }
}`}
        </code>
      </pre><br/>

      <h3>4. Default (Package-Private) Access Modifier</h3>
      <p><strong>Visibility:</strong> If no access modifier is specified, it is considered the <strong>default</strong> access modifier, also known as <strong>package-private</strong>. Members with default access can only be accessed within classes in the same package.</p>
      <p><strong>Use in Encapsulation:</strong> The default modifier can be useful when you want to restrict access to certain fields or methods to just within the same package, while keeping them hidden from outside packages. However, it is less restrictive than <code>private</code>.</p>
      <pre>
        <code>
          {`class Person {
    String name;  // Default (package-private) field

    // Getter and Setter methods
    String getName() {
        return name;
    }

    void setName(String name) {
        this.name = name;
    }
}`}
        </code>
      </pre><br/>

      <h3>Access Modifiers and Encapsulation Example</h3>
      <p>Let's consider a real-world example that demonstrates how to use access modifiers in a way that supports encapsulation.</p>

      <h4>Example: Bank Account System</h4>
      <pre>
        <code>
          {`// Account.java (in the com.bank package)
package com.bank;

public class Account {
    private String accountNumber;  // private field
    private double balance;  // private field

    // Constructor to initialize account
    public Account(String accountNumber, double balance) {
        this.accountNumber = accountNumber;
        this.balance = balance;
    }

    // Public method to access private balance field
    public double getBalance() {
        return balance;
    }

    // Public method to set balance with validation
    public void deposit(double amount) {
        if (amount > 0) {
            balance += amount;
        }
    }

    public void withdraw(double amount) {
        if (amount > 0 && amount <= balance) {
            balance -= amount;
        }
    }
}

// Customer.java (in the same com.bank package)
package com.bank;

public class Customer {
    public static void main(String[] args) {
        Account account = new Account("12345", 1000.0);
        
        // Accessing balance via getter method (encapsulation in action)
        System.out.println("Account Balance: " + account.getBalance());

        // Modifying balance via deposit method
        account.deposit(500.0);
        System.out.println("Updated Balance: " + account.getBalance());

        // Trying to directly access private fields (will result in an error)
        // System.out.println(account.balance);  // Error: balance has private access
    }
}`}
        </code>
      </pre><br/>

      <p><strong>Explanation:</strong></p>
      <ul>
        <li><strong>Private Fields:</strong> The <code>accountNumber</code> and <code>balance</code> fields are private to prevent direct modification from outside the <code>Account</code> class. This ensures that the internal state of the object is not changed unexpectedly.</li><br/>
        <li><strong>Public Methods:</strong> The methods <code>getBalance()</code>, <code>deposit()</code>, and <code>withdraw()</code> are public, allowing controlled access to the <code>balance</code> field. The deposit and withdrawal methods include validation to ensure that only valid transactions are made, enforcing business logic.</li><br/>
        <li><strong>Encapsulation:</strong> By making fields <code>private</code> and exposing only the necessary functionality through public methods, we encapsulate the data and protect the integrity of the <code>Account</code> object.</li>
      </ul><br/>

      <h3>Benefits of Using Access Modifiers in Encapsulation</h3>
      <ul>
        <li><strong>Control Over Data:</strong> By using access modifiers, you can control how your data is accessed and modified, ensuring that the internal state of your objects is protected.</li><br/>
        <li><strong>Code Flexibility:</strong> You can expose only what is necessary (via <code>public</code>), restrict direct access (via <code>private</code>), and enable controlled access through inheritance (via <code>protected</code>).</li><br/>
        <li><strong>Security:</strong> Encapsulation helps in preventing unintended access and modifications to sensitive fields, reducing the risk of bugs and security issues.</li><br/>
        <li><strong>Maintainability:</strong> It becomes easier to change the implementation of a class without affecting the rest of the system, as the internal details are hidden behind well-defined interfaces.</li>
      </ul><br/>

      <h3>Conclusion</h3>
      <p>Access modifiers are a powerful tool in Java for enforcing encapsulation. By controlling access to fields and methods, Java allows you to protect the internal state of objects and expose only necessary functionality. The use of <code>public</code>, <code>private</code>, <code>protected</code>, and default access modifiers provides a range of visibility options, enabling fine-grained control over the accessibility of your classes and their members. Proper use of access modifiers promotes better design, security, and maintainability of Java applications.</p>
    </div>
  )}




{selectedChapter === 'chapter60' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Encapsulation     </h1>

    <p><strong>Encapsulation</strong> is one of the core principles of <strong>Object-Oriented Programming (OOP)</strong> and plays a significant role in Java. It involves bundling data (fields) and methods that operate on the data into a single unit, typically a class. More importantly, encapsulation restricts direct access to some of an object's components, which is why it is also often referred to as "data hiding."</p>
    
    <p>In Java, encapsulation is implemented by defining <strong>private</strong> fields and providing <strong>public</strong> getter and setter methods to access and update the value of those fields. This way, the internal state of an object can only be modified in controlled ways.</p>
    <br/>
    <h3>Key Features of Encapsulation:</h3>
    <ul>
      <li><strong>Data Hiding</strong>: The internal state (fields) of an object is hidden from the outside world. This ensures that the object’s data is protected from unauthorized access and modification.</li><br/>
      <li><strong>Access Control</strong>: Through access modifiers, you can control what parts of the object can be accessed and modified by other classes.</li><br/>
      <li><strong>Better Maintainability</strong>: Encapsulation allows you to change the internal workings of a class without affecting external code that uses the class.</li><br/>
      <li><strong>Flexibility and Extensibility</strong>: Encapsulation enables the flexibility to modify, validate, or add additional logic to the internal state before updating the field.</li>
    </ul><br/>

    <h3>How Encapsulation Works in Java</h3>
    <p>In Java, encapsulation is typically achieved by:</p>
    <ul>
      <li><strong>Declaring fields as private</strong>: This prevents direct access to the fields outside the class.</li><br/>
      <li><strong>Providing public getter and setter methods</strong>: These methods allow controlled access to the private fields.</li>
    </ul><br/>

    <h3>Example of Encapsulation in Java</h3>
    <pre><code>
      {`public class Person {
          // Private fields (data hiding)
          private String name;
          private int age;

          // Public constructor to initialize object
          public Person(String name, int age) {
              this.name = name;
              this.age = age;
          }

          // Public getter method for 'name'
          public String getName() {
              return name;
          }

          // Public setter method for 'name'
          public void setName(String name) {
              if (name != null && !name.isEmpty()) {
                  this.name = name;
              } else {
                  System.out.println("Invalid name");
              }
          }

          // Public getter method for 'age'
          public int getAge() {
              return age;
          }

          // Public setter method for 'age'
          public void setAge(int age) {
              if (age >= 0) {
                  this.age = age;
              } else {
                  System.out.println("Age cannot be negative");
              }
       }
}`}
    </code></pre>

    <p>In this example:</p>
    <ul>
      <li>The fields <code>name</code> and <code>age</code> are <strong>private</strong>, which means they cannot be accessed directly from outside the <code>Person</code> class.</li><br/>
      <li>The <strong>getter methods</strong> <code>getName()</code> and <code>getAge()</code> allow access to the <code>name</code> and <code>age</code> fields.</li><br/>
      <li>The <strong>setter methods</strong> <code>setName()</code> and <code>setAge()</code> allow modification of the fields but include validation logic to ensure that only valid values are set (e.g., ensuring the <code>name</code> is not null or empty, and <code>age</code> is non-negative).</li>
    </ul><br/>

    <h3>Benefits of Encapsulation in Java</h3>
    <ul>
      <li><strong>Control Over Data</strong>: Encapsulation allows you to control the values of the object's fields. For example, by using setter methods, you can add validation before a value is set.</li><br/>
      <li><strong>Data Security</strong>: By making fields private, you prevent unauthorized or accidental modification of an object's data, ensuring the integrity of the object's state.</li><br/>
      <li><strong>Improved Maintainability</strong>: Since fields are accessed through getter and setter methods, the internal implementation of the class can be changed without affecting the code that uses the class. If a new field or validation logic is added, only the class methods need to be updated.</li><br/>
      <li><strong>Reusability</strong>: Encapsulation allows you to reuse a class across multiple programs and projects without exposing the internal workings, which makes the class more secure and modular.</li><br/>
      <li><strong>Ease of Debugging and Testing</strong>: When fields are encapsulated and accessed through methods, it becomes easier to add debugging, logging, or other functionality inside the getter/setter methods to track changes to the data.</li>
    </ul><br/>

    <h3>Real-World Example: Bank Account System</h3>
    <pre><code>
      {`public class BankAccount {
          private String accountNumber;  // private field
          private double balance;        // private field

          // Constructor to initialize account details
          public BankAccount(String accountNumber, double balance) {
              this.accountNumber = accountNumber;
              this.balance = balance;
          }

          // Public getter method for balance
          public double getBalance() {
              return balance;
          }

          // Public method to deposit money (validation)
          public void deposit(double amount) {
              if (amount > 0) {
                  balance += amount;
              } else {
                  System.out.println("Deposit amount must be positive");
              }
          }

          // Public method to withdraw money (validation)
          public void withdraw(double amount) {
              if (amount > 0 && amount <= balance) {
                  balance -= amount;
              } else {
                  System.out.println("Insufficient balance or invalid amount");
              }
        }
}`}
    </code></pre><br/>

    <h3>Encapsulation vs. Abstraction</h3>
    <p>Though <strong>encapsulation</strong> and <strong>abstraction</strong> are closely related concepts in OOP, they are distinct:</p>
    <ul>
      <li><strong>Encapsulation</strong> is about bundling data and restricting direct access to it using getter and setter methods.</li><br/>
      <li><strong>Abstraction</strong> is about hiding the implementation details and exposing only the necessary functionality to the user.</li>
    </ul><br/>
    <p>In simpler terms:</p>
    <ul>
      <li><strong>Encapsulation</strong> is about <strong>data hiding</strong> (protecting the internal state of an object).</li><br/>
      <li><strong>Abstraction</strong> is about <strong>hiding complexity</strong> (showing only essential features).</li>
    </ul><br/>

    <h3>Conclusion</h3>
    <p>Encapsulation in Java is a powerful technique to ensure data integrity and security. By controlling access to an object’s fields through getter and setter methods, we can protect sensitive data from unauthorized manipulation and enforce rules and logic. Encapsulation not only makes your classes more secure but also enhances maintainability and flexibility, making it a fundamental concept in object-oriented design.</p>
  </div>
)}



{selectedChapter === 'chapter61' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction   </h1>
        
        <p>In Java, <strong>String</strong> is one of the most commonly used classes. It represents a sequence of characters and is a part of the <code>java.lang</code> package. A String object is used to store and manipulate textual data in Java. Unlike primitive types, String is an object, and it is immutable, which means once a String object is created, it cannot be modified.</p>
        <br/>
        <h2>Key Characteristics of Strings in Java:</h2>
        <ul>
          <li><strong>Immutability</strong>: Once a String object is created, it cannot be altered. Any operation that appears to modify the String actually creates a new String object.</li><br/>
          <li><strong>String Pool</strong>: Java maintains a special pool of Strings to optimize memory usage. When you create a String literal, Java checks the pool to see if that string already exists. If it does, the reference to the existing object is returned; otherwise, a new object is created and added to the pool.</li><br/>
          <li><strong>String as an Object</strong>: Although Strings are used so frequently, they are objects of the <code>String</code> class. This provides many useful methods for manipulation and comparison.</li><br/>
          <li><strong>String Interning</strong>: Java's String Pool allows for efficient memory usage. When you use string literals (like <code>"hello"</code>), Java stores them in a special area of memory known as the <strong>String Pool</strong>. This ensures that duplicate string values don’t result in multiple copies, reducing memory overhead.</li>
        </ul><br/>
        
        <h2>How Strings Work in Java</h2>
        <p>Strings are created either by using String literals or by using the <code>new</code> keyword:</p>
        <br/>
        <h3>Using String Literals:</h3>
        <pre><code>String str = "Hello, World!";</code></pre>
        <p>In this case, Java checks the String Pool to see if the literal <code>"Hello, World!"</code> already exists. If it exists, it returns the reference to that object, otherwise, it creates a new String object and stores it in the pool.</p>
        <br/>
        <h3>Using the <code>new</code> Keyword:</h3>
        <pre><code>String str2 = new String("Hello, World!");</code></pre>
        <p>This approach creates a new String object in the heap memory, even if an identical string already exists in the String Pool.</p>
        <br/>
        <h2>Example of String Operations</h2>
        <pre><code>{`public class StringExample {
public static void main(String[] args) {
        // String creation
        String str1 = "Hello";
        String str2 = "World";
        
        // Concatenation
        String greeting = str1 + " " + str2;
        System.out.println(greeting);  // Output: Hello World
    }
}`}</code></pre><br/>

        <h2>Common String Methods in Java</h2>
        <p>The <code>String</code> class provides a variety of methods for string manipulation. Some of the commonly used ones include:</p><br/>
        
        <ul>
          <li><strong>length()</strong>: Returns the length of the string (number of characters).</li><br/>
          <li><strong>charAt(int index)</strong>: Returns the character at the specified index.</li><br/>
          <li><strong>equals(String anotherString)</strong>: Compares the current string with another string.</li><br/>
          <li><strong>substring(int beginIndex, int endIndex)</strong>: Returns a substring from the given range.</li><br/>
          <li><strong>toUpperCase()</strong> / <strong>toLowerCase()</strong>: Converts the string to uppercase or lowercase.</li><br/>
          <li><strong>trim()</strong>: Removes any leading or trailing whitespace from the string.</li><br/>
          <li><strong>replace(char oldChar, char newChar)</strong>: Replaces all occurrences of a character in the string.</li><br/>
          <li><strong>split(String regex)</strong>: Splits the string into an array based on the given delimiter.</li>
        </ul><br/>

        <h2>String Pool and Interning</h2>
        <p>The <strong>String Pool</strong> is a memory optimization feature in Java. When you create a String using a literal (e.g., <code>"Hello"</code>), Java checks if that string is already in the pool. If it is, it reuses the reference instead of creating a new object. This can save memory and improve performance when dealing with many identical string values.</p>
        
        <pre><code>{`String str1 = "hello";
String str2 = "hello";
System.out.println(str1 == str2);  // Output: true
        `}</code></pre>

        <p>If you create a string using <code>new</code>:</p>
        <pre><code>{`String str3 = new String("hello");
System.out.println(str1 == str3);  // Output: false
        `}</code></pre>

        <p>Here, <code>str3</code> is a new object in the heap, so it is not the same object as <code>str1</code>, even though they have the same value. To explicitly add a string to the pool, you can use the <code>intern()</code> method:</p>
        <pre><code>{`String str4 = new String("hello").intern();
System.out.println(str1 == str4);  // Output: true
       `} </code></pre><br/>

        <h2>Why Strings are Immutable in Java</h2>
        <p>Strings in Java are <strong>immutable</strong> because they are designed to be shared across multiple objects. If Strings were mutable, it would lead to inconsistencies when they are shared between multiple references. Making them immutable ensures that once a String is created, it cannot be changed, which provides thread-safety and improves performance by reusing objects from the String Pool.</p>
        <br/>
        <h2>String in Real-World Applications</h2>
        <ul>
          <li><strong>Text Processing</strong>: Strings are fundamental in text processing, such as parsing data, manipulating text files, or generating dynamic content.</li><br/>
          <li><strong>Networking</strong>: In networking applications, Strings are often used to represent data sent over a network, like URLs, HTTP headers, etc.</li><br/>
          <li><strong>User Interface</strong>: In GUI applications, Strings are used for labels, messages, buttons, etc.</li><br/>
          <li><strong>Database Queries</strong>: Strings are used to build SQL queries dynamically in applications that interact with databases.</li><br/>
          <li><strong>Configuration Files</strong>: Strings are often used in configuration files where data like keys, values, and properties are stored and read.</li>
        </ul><br/>

        <h2>Conclusion</h2>
        <p>The <strong>String class</strong> in Java is essential for manipulating text and offers a variety of methods for string operations such as concatenation, comparison, search, replacement, and more. Its immutability and inclusion in the String Pool optimize memory usage and ensure data integrity across different parts of a program. Understanding how to effectively use Strings in Java is critical for any developer working with textual data.</p>
      </div>
)}



{selectedChapter === 'chapter62' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Immutable String  </h1>

        
        
        <p>
          Immutability means that once an object is created, its state cannot be changed. In the case of a <code>String</code>, if you perform any operation that seems to modify the string (such as concatenation, replacement, or trimming), Java actually creates a <strong>new String object</strong> rather than modifying the original string. This ensures that the original object remains unaltered.
        </p>
        <pre>
          <code>
            {`String str = "Hello";`}
            <br />
            {`str = str + " World"; // This does not modify 'str', a new String object is created.`}
          </code>
        </pre><br/>
        <p>
          In the above example, the original <code>str</code> ("Hello") is unchanged. The expression <code>str + " World"</code> creates a new string <code>"Hello World"</code>, and <code>str</code> is now pointing to that new string. The original <code>"Hello"</code> remains unchanged in memory.
        </p><br/>

        <h2 style={{paddingBottom:"6px"}}>2. Why Are Strings Immutable in Java?</h2>

        <h3>a. Performance and Memory Optimization (String Pooling)</h3>
        <p>
          Java maintains a special area of memory known as the <strong>String Pool</strong> or <strong>String Literal Pool</strong>. When a string is created using a literal (e.g., <code>"Hello"</code>), it is stored in this pool. If the same string literal is used elsewhere, Java will reuse the reference to the existing object in the pool instead of creating a new one.
        </p>
        <pre>
          <code>
            {`String str1 = "Hello";`}
            <br />
            {`String str2 = "Hello";`}
            <br />
            {`System.out.println(str1 == str2);  // Output: true`}
          </code>
        </pre>
        <p>
          In this case, both <code>str1</code> and <code>str2</code> point to the same object in the String Pool.
        </p><br/>

        <h3>b. Thread Safety</h3>
        <p>
          Immutability ensures that <code>String</code> objects are <strong>thread-safe</strong>. Since a string cannot be modified, multiple threads can access the same <code>String</code> object concurrently without the risk of data corruption or synchronization issues.
        </p>
        <pre>
          <code>
            {`String str = "Thread Safe";`}
          </code>
        </pre>
        <p>
          Multiple threads can read from <code>str</code> safely without needing synchronization because its value cannot be changed after it's created.
        </p><br/>

        <h3>c. Security</h3>
        <p>
          Immutability also enhances security. Consider how <code>String</code> is used in various sensitive operations like authentication, file paths, network communication, and more. If strings were mutable, it could lead to inadvertent or malicious modification of values, especially when strings are passed between different components or threads.
        </p><br/>

        <h3>d. Hash Code Consistency</h3>
        <p>
          Since a <code>String</code> object is immutable, its hash code (computed using <code>hashCode()</code>) remains consistent throughout the lifetime of the object. This is essential for the correct functioning of hash-based collections like <code>HashMap</code> and <code>HashSet</code>.
        </p><br/>
        
        <h2>3. How Does Immutability Work in Java?</h2>

        <h3>Example: Concatenation</h3>
        <pre>
          <code>
            {`String str = "Java";`}
            <br />
            {`str = str + " is awesome";`}
            <br />
            {`System.out.println(str); // Output: Java is awesome`}
          </code>
        </pre>
        <p>
          Initially, <code>str</code> is <code>"Java"</code>. When the <code>+</code> operator is used, Java creates a new <code>String</code> object with the value <code>"Java is awesome"</code>. The original <code>"Java"</code> string remains unchanged in memory, and <code>str</code> is updated to reference the new object.
        </p>
        <p>
          This process of creating a new object is why the <code>+</code> operator can be inefficient when concatenating many strings in a loop, as it results in multiple intermediate <code>String</code> objects being created. To optimize string concatenation, it's recommended to use <code>StringBuilder</code> or <code>StringBuffer</code> for more efficient handling.
        </p><br/>

        <h2>4. Example of Immutability with String Methods</h2>

        <h3>a. concat() Method</h3>
        <pre>
          <code>
            {`String str1 = "Hello";`}
            <br />
            {`String str2 = str1.concat(" World");`}
            <br />
            {`System.out.println(str2);  // Output: Hello World`}
          </code>
        </pre>
        <p>
          In this example, <code>str1</code> remains <code>"Hello"</code>, and a new string <code>"Hello World"</code> is created and assigned to <code>str2</code>.
        </p><br/>

        <h3>b. replace() Method</h3>
        <pre>
          <code>
            {`String str = "Java";`}
            <br />
            {`str = str.replace('a', 'o');`}
            <br />
            {`System.out.println(str);  // Output: Jovo`}
          </code>
        </pre>
        <p>
          Here, the original string <code>"Java"</code> is not modified. A new string <code>"Jovo"</code> is created, and <code>str</code> now refers to this new string.
        </p><br/>

        <h2>5. Working with Immutable Strings in Real-World Applications</h2>

        <ul>
          <li>
            <strong>String Manipulation:</strong> Developers should be mindful that every operation on a string creates a new object, which can have performance implications. For large strings or numerous operations, it's recommended to use <code>StringBuilder</code> or <code>StringBuffer</code> for more efficient handling.
          </li><br/>
          <li>
            <strong>Security:</strong> The immutability of <code>String</code> ensures that sensitive data, such as passwords and cryptographic keys, cannot be changed once created.
          </li><br/>
          <li>
            <strong>Data Integrity:</strong> In cases where strings must remain unchanged (e.g., configuration parameters, user input), immutability guarantees that their integrity is preserved.
          </li>
        </ul><br/>

        <h2>6. Conclusion</h2>
        <p>
          Java's <strong>immutable String</strong> design provides several advantages, including performance optimization through the String Pool, thread safety, security, and consistency of hash codes. While immutability is advantageous, it's important to understand that it leads to the creation of new objects when strings are modified, which can affect performance in certain scenarios. For more efficient string manipulation, <code>StringBuilder</code> or <code>StringBuffer</code> can be used in mutable scenarios.
        </p>
      </div>
    )}


{selectedChapter === 'chapter63' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String Concatenation   </h1>

    <p>In Java, <strong>String concatenation</strong> refers to the process of joining two or more <code>String</code> objects together to form a new <code>String</code> object. Concatenation is a common operation, especially when dealing with textual data, and Java provides multiple ways to concatenate strings. Below, we will explore the different techniques and how they work under the hood.</p>

    <br/>

    <h2>1. Using the <code>+</code> Operator</h2>
    <p>The simplest and most commonly used method for string concatenation in Java is the <code>+</code> operator. This operator can concatenate two or more strings or string-like objects.</p>

    <h3>Example:</h3>
    <pre>
      <code>
        {`String str1 = "Hello";
String str2 = "World";
String result = str1 + " " + str2;  // Concatenating "Hello" and "World"
System.out.println(result);  // Output: Hello World`}
      </code>
    </pre><br/>

    <p>When you use the <code>+</code> operator, Java creates a new string object that holds the concatenated result. However, this process can be inefficient in certain scenarios.</p>
    <br/>
    <h4>How It Works Internally:</h4>
    <ul>
      <li>Java creates a new <code>StringBuilder</code> or <code>StringBuffer</code> internally to handle concatenation efficiently.</li><br/>
      <li>Each time you concatenate strings using <code>+</code>, Java will create a new <code>StringBuilder</code>, append the strings, and then convert the builder to a <code>String</code> object.</li>
    </ul><br/>

    <p>For example:</p>
    <pre>
      <code>
        {`String result = str1 + " " + str2;`}
      </code>
    </pre>
    <p>Internally, it is equivalent to:</p>
    <pre>
      <code>
        {`StringBuilder sb = new StringBuilder();
sb.append(str1);
sb.append(" ");
sb.append(str2);
String result = sb.toString();`}
      </code>
    </pre>

    <p>This process can cause performance issues when used in a loop or with many concatenations because it leads to the creation of multiple intermediate string objects.</p>

    <br/>

    <h2>2. Using the <code>concat()</code> Method</h2>
    <p>The <code>String</code> class in Java provides a <code>concat()</code> method that concatenates the specified string to the end of the current string. This method does not use the <code>+</code> operator and is more efficient when concatenating only two strings.</p>

    <h3>Syntax:</h3>
    <pre>
      <code>
        {`String result = str1.concat(str2);`}
      </code>
    </pre>

    <h3>Example:</h3>
    <pre>
      <code>
        {`String str1 = "Hello";
String str2 = "World";
String result = str1.concat(" ").concat(str2);
System.out.println(result);  // Output: Hello World`}
      </code>
    </pre><br/>

    <p>Key Points:</p>
    <ul>
      <li>The <code>concat()</code> method can only concatenate one string at a time.</li><br/>
      <li>It is slightly more efficient than using the <code>+</code> operator, but still creates new strings for each concatenation.</li><br/>
      <li>If either <code>str1</code> or <code>str2</code> is <code>null</code>, a <code>NullPointerException</code> will be thrown.</li>
    </ul>

    <br/>

    <h2>3. Using <code>StringBuilder</code> or <code>StringBuffer</code></h2>
    <p>When concatenating multiple strings, especially in loops or performance-critical applications, using <code>StringBuilder</code> or <code>StringBuffer</code> is a more efficient approach. Both classes are mutable, meaning they can modify the contents without creating new objects for each change.</p>
    <br/>
    <p><strong><code>StringBuilder</code></strong>: It is used when thread-safety is not a concern.</p>
    <p><strong><code>StringBuffer</code></strong>: It is used when thread-safety is required, but it is slower than <code>StringBuilder</code> due to synchronization.</p>
    <br/>
    <h3>Example with <code>StringBuilder</code>:</h3>
    <pre>
      <code>
        {`String str1 = "Hello";
String str2 = "World";
StringBuilder sb = new StringBuilder(str1);
sb.append(" ").append(str2);
String result = sb.toString();
System.out.println(result);  // Output: Hello World`}
      </code>
    </pre><br/>

    <h3>Example with <code>StringBuffer</code>:</h3>
    <pre>
      <code>
        {`String str1 = "Hello";
String str2 = "World";
StringBuffer sb = new StringBuffer(str1);
sb.append(" ").append(str2);
String result = sb.toString();
System.out.println(result);  // Output: Hello World`}
      </code>
    </pre><br/>

    <p>Key Points:</p>
    <ul>
      <li><strong>Performance</strong>: <code>StringBuilder</code> and <code>StringBuffer</code> are much more efficient than <code>+</code> or <code>concat()</code> in scenarios where multiple concatenations are performed, especially inside loops.</li><br/>
      <li><strong>Memory Usage</strong>: These classes manage memory more efficiently by avoiding the creation of intermediate objects. They use a dynamically resizing character array internally.</li>
    </ul>

    <br/>

    <h2>4. Using <code>String.join()</code> Method (Java 8 and Later)</h2>
    <p>Since Java 8, the <code>String.join()</code> method has been introduced to simplify concatenation of multiple strings with a delimiter. This method is especially useful when you need to concatenate a collection or array of strings.</p>

    <h3>Syntax:</h3>
    <pre>
      <code>
        {`String result = String.join(delimiter, string1, string2, ..., stringN);`}
      </code>
    </pre>

    <h3>Example:</h3>
    <pre>
      <code>
        {`String str1 = "Hello";
String str2 = "World";
String result = String.join(" ", str1, str2);
System.out.println(result);  // Output: Hello World`}
      </code>
    </pre><br/>

    <p>Key Points:</p>
    <ul>
      <li>The <code>String.join()</code> method is ideal for joining multiple strings with a specific delimiter, such as a comma, space, etc.</li><br/>
      <li>It can be used with collections like lists or arrays, making it a convenient method for concatenating a variable number of strings.</li>
    </ul>

    <br/>

    <h2>5. Performance Considerations</h2>
    <p>When dealing with large-scale string concatenations, the choice of concatenation method can have a significant impact on performance.</p>

    <p><strong>Using <code>+</code> in loops</strong>: While <code>+</code> is convenient, it can be inefficient for concatenating strings inside loops. This is because each concatenation creates a new string, which involves creating intermediate objects.</p>

    <pre>
      <code>
        {`String result = "";
for (int i = 0; i < 1000; i++) {
    result += "Hello";
}`}
      </code>
    </pre>
    <p>This code will result in many intermediate <code>String</code> objects being created, making it inefficient.</p>

    <p><strong>Using <code>StringBuilder</code> or <code>StringBuffer</code></strong>: These are the preferred methods for concatenation when performance is a concern, especially in loops.</p>

    <pre>
      <code>
        {`StringBuilder sb = new StringBuilder();
for (int i = 0; i < 1000; i++) {
    sb.append("Hello");
}
String result = sb.toString();`}
      </code>
    </pre>

    <p>In this example, the <code>StringBuilder</code> avoids the creation of intermediate strings and appends to the same object, making it much more efficient.</p>

    <br/>

    <h2>6. Conclusion</h2>
    <p>String concatenation in Java is a common operation, but the method you choose can have a significant impact on performance and memory usage:</p>
    <ul>
      <li><strong>For concatenating a small number of strings</strong>: Using the <code>+</code> operator or the <code>concat()</code> method is fine and easy to use.</li><br/>
      <li><strong>For large-scale or repeated concatenation</strong> (especially in loops): Always use <code>StringBuilder</code> or <code>StringBuffer</code> to avoid inefficient memory use and performance degradation.</li><br/>
      <li><strong>For joining multiple strings with a delimiter</strong>: The <code>String.join()</code> method introduced in Java 8 is a great option.</li>
    </ul><br/>

    <p>By understanding how each concatenation method works and its performance characteristics, you can optimize your code for better efficiency and readability.</p>
  </div>
)}


{selectedChapter === 'chapter64' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Substring</h1>

    
    <p>
      In Java, the <code>substring()</code> method is used to extract a part of a string.
      This part is a sequence of characters from the original string, based on the specified starting and ending positions.
      It is commonly used to get smaller portions of a string for further processing.
    </p><br/>

    <h4><strong>Syntax of <code>substring()</code> Method:</strong></h4>

    <pre>
      <code>{`
        String substring(int startIndex)
        String substring(int startIndex, int endIndex)
      `}</code>
    </pre>

    <p>
      - <code>substring(int startIndex)</code>: This method returns a new string starting from the specified index (<code>startIndex</code>) and goes to the end of the string.
    </p>
    <p>
      - <code>substring(int startIndex, int endIndex)</code>: This method returns a new string starting from <code>startIndex</code> (inclusive) and ending at <code>endIndex</code> (exclusive). It means that the character at the <code>endIndex</code> is not included in the resulting substring.
    </p><br/>

    <h4><strong>Parameters:</strong></h4>
    <ul>
      <li><strong>startIndex:</strong> The index of the first character to be included in the substring. The index starts from <code>0</code> (like arrays in Java).</li><br/>
      <li><strong>endIndex:</strong> The index where the substring ends, but the character at this position is not included.</li>
    </ul><br/>

    <h4><strong>Examples of Using <code>substring()</code></strong></h4>

    <h5><strong>1. Extracting a substring from a given start index:</strong></h5>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello, Java!";  // Original string
        String subStr = str.substring(7);  // Extracts substring from index 7 to the end
        System.out.println(subStr);  // Output: Java!
    }
}`}      </code>
    </pre>

    <p>
      The <code>substring(7)</code> starts extracting the string from index <code>7</code> to the end of the string, which gives "Java!".
    </p><br/>

    <h5><strong>2. Extracting a substring between two indexes:</strong></h5>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello, Java!";  // Original string
        String subStr = str.substring(0, 5);  // Extracts substring from index 0 to index 5 (exclusive)
        System.out.println(subStr);  // Output: Hello
    }
}`}</code>
    </pre>

    <p>
      The <code>substring(0, 5)</code> method extracts the string starting from index <code>0</code> to index <code>5</code> (excluding index <code>5</code>). The result is "Hello".
    </p><br/>

    <h4><strong>Key Points to Remember:</strong></h4>
    <ul>
      <li><strong>Zero-based Indexing:</strong> String indices start from <code>0</code>. For example, in the string "Hello", the index of <code>H</code> is <code>0</code>, <code>e</code> is <code>1</code>, and so on.</li><br/>
      <li><strong>startIndex and endIndex:</strong> 
        <ul>
          <li><code>startIndex</code> is <strong>inclusive</strong>.</li><br/>
          <li><code>endIndex</code> is <strong>exclusive</strong> (it is not part of the substring).</li>
        </ul>
      </li><br/>
      <li><strong>Out of Bounds:</strong> If the <code>startIndex</code> or <code>endIndex</code> are out of bounds (i.e., negative values or greater than the length of the string), a <code>StringIndexOutOfBoundsException</code> is thrown.</li><br/>
      <li><strong>Length of Substring:</strong> The length of the substring is determined by the difference between <code>endIndex</code> and <code>startIndex</code>. If <code>startIndex</code> is greater than <code>endIndex</code>, an exception is thrown.</li>
    </ul><br/>

    <h4><strong>Common Use Cases for <code>substring()</code>:</strong></h4>
    <ul>
      <li>Extracting specific information from a string, such as a part of a URL, a name from an email address, or a date from a timestamp.</li><br/>
      <li>Parsing or processing user inputs or data from files.</li>
    </ul><br/>

    <h4><strong>Examples of <code>substring()</code> in Real Life:</strong></h4>

    <h5><strong>Extracting file extension:</strong></h5>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String fileName = "image.png";  // Original file name
        String extension = fileName.substring(fileName.lastIndexOf(".") + 1);  // Extract the file extension
        System.out.println(extension);  // Output: png
    }
}`}</code>
    </pre><br/>

    <p>
      We use <code>substring()</code> to extract the file extension by finding the position of the last dot (<code>"."</code>) and getting the substring after it.
    </p><br/>

    <h4><strong>Summary:</strong></h4>
    <ul>
      <li>The <code>substring()</code> method is a simple and useful way to work with portions of a string.</li><br/>
      <li>You can use it to extract substrings starting from a specific index or between two indices.</li><br/>
      <li>It’s very useful in text processing, parsing, or any operation where you need to break down a string into smaller parts.</li>
    </ul>
  </div>
)}





{selectedChapter === 'chapter65' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Method of String Class </h1>

          <p>The <code>String</code> class in Java is part of the <code>java.lang</code> package and is used to represent a sequence of characters. Since strings are immutable in Java, the <code>String</code> class provides several useful methods to manipulate and interact with strings without modifying the original string.</p>
          <br/>
          <h2>1. Length of a String (<code>length()</code>)</h2>
          <p>The <code>length()</code> method returns the number of characters in the string.</p>
          <pre><code>int length()</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "Hello";\nSystem.out.println(str.length());  // Output: 5`}</code></pre>
          <br/>
          <h2>2. Character at a Specific Index (<code>charAt()</code>)</h2>
          <p>The <code>charAt(int index)</code> method returns the character at the specified index. The index starts at 0.</p>
          <pre><code>char charAt(int index)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "Hello";\nSystem.out.println(str.charAt(1));  // Output: 'e'`}</code></pre>
          <br/>
          <h2>3. Substring (<code>substring()</code>)</h2>
          <p>The <code>substring()</code> method extracts a portion of the string, either starting from a specified index or between two specified indices.</p>
          <pre><code>String substring(int beginIndex)</code></pre>
          <pre><code>String substring(int beginIndex, int endIndex)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "Hello, World!";\nSystem.out.println(str.substring(7));        // Output: "World!"\nSystem.out.println(str.substring(0, 5));     // Output: "Hello"`}</code></pre>
          <br/>
          <h2>4. Convert to Lowercase (<code>toLowerCase()</code>)</h2>
          <p>The <code>toLowerCase()</code> method converts all the characters in the string to lowercase.</p>
          <pre><code>String toLowerCase()</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "Hello";\nSystem.out.println(str.toLowerCase());  // Output: "hello"`}</code></pre>
          <br/>
          <h2>5. Convert to Uppercase (<code>toUpperCase()</code>)</h2>
          <p>The <code>toUpperCase()</code> method converts all characters in the string to uppercase.</p>
          <pre><code>String toUpperCase()</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "hello";\nSystem.out.println(str.toUpperCase());  // Output: "HELLO"`}</code></pre>
          <br/>
          <h2>6. Trim Whitespace (<code>trim()</code>)</h2>
          <p>The <code>trim()</code> method removes leading and trailing whitespace characters from the string.</p>
          <pre><code>String trim()</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "  Hello  ";\nSystem.out.println(str.trim());  // Output: "Hello"`}</code></pre>
          <br/>
          <h2>7. Check for Substring (<code>contains()</code>)</h2>
          <p>The <code>contains()</code> method checks if a specified sequence of characters is present in the string.</p>
          <pre><code>boolean contains(CharSequence sequence)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "Hello, World!";\nSystem.out.println(str.contains("World"));  // Output: true\nSystem.out.println(str.contains("Java"));   // Output: false`}</code></pre>
          <br/>
          <h2>8. Index of a Character (<code>indexOf()</code>)</h2>
          <p>The <code>indexOf()</code> method returns the index of the first occurrence of a specified character or substring in the string.</p>
          <pre><code>int indexOf(int ch)</code></pre>
          <pre><code>int indexOf(String str)</code></pre>
          <pre><code>int indexOf(int ch, int fromIndex)</code></pre>
          <pre><code>int indexOf(String str, int fromIndex)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "Hello, World!";\nSystem.out.println(str.indexOf('o'));      // Output: 4\nSystem.out.println(str.indexOf("World"));  // Output: 7`}</code></pre>
          <br/>
          <h2>9. Replace Characters (<code>replace()</code>)</h2>
          <p>The <code>replace()</code> method replaces all occurrences of a specified character or substring with a new one.</p>
          <pre><code>String replace(char oldChar, char newChar)</code></pre>
          <pre><code>String replace(CharSequence target, CharSequence replacement)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "Hello, World!";\nSystem.out.println(str.replace('o', 'O'));   // Output: "HellO, WOrld!"\nSystem.out.println(str.replace("World", "Java"));  // Output: "Hello, Java!"`}</code></pre>
          <br/>
          <h2>10. Split String (<code>split()</code>)</h2>
          <p>The <code>split()</code> method divides the string into an array of substrings based on the specified delimiter.</p>
          <pre><code>String[] split(String regex)</code></pre>
          <pre><code>String[] split(String regex, int limit)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "apple,banana,orange";\nString[] fruits = str.split(",");\nfor (String fruit : fruits) {\n    System.out.println(fruit);\n}`}</code></pre>
          <br/>
          <h2>11. Check Equality (<code>equals()</code>)</h2>
          <p>The <code>equals()</code> method compares two strings to check if they are exactly equal in terms of content.</p>
          <pre><code>boolean equals(Object anObject)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str1 = "Hello";\nString str2 = "Hello";\nSystem.out.println(str1.equals(str2));  // Output: true`}</code></pre>
          <br/>
          <h2>12. Check Equality Ignoring Case (<code>equalsIgnoreCase()</code>)</h2>
          <p>The <code>equalsIgnoreCase()</code> method compares two strings for equality, ignoring case differences.</p>
          <pre><code>boolean equalsIgnoreCase(String anotherString)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str1 = "Hello";\nString str2 = "hello";\nSystem.out.println(str1.equalsIgnoreCase(str2));  // Output: true`}</code></pre>
          <br/>
          <h2>13. Concatenate Strings (<code>concat()</code>)</h2>
          <p>The <code>concat()</code> method appends the specified string to the end of the current string.</p>
          <pre><code>String concat(String str)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str1 = "Hello";\nString str2 = " World!";\nSystem.out.println(str1.concat(str2));  // Output: "Hello World!"`}</code></pre>
          <br/>
          <h2>14. String Comparison (<code>compareTo()</code> and <code>compareToIgnoreCase()</code>)</h2>
          <p>The <code>compareTo()</code> method compares two strings lexicographically. The <code>compareToIgnoreCase()</code> method does the same, but it ignores case differences.</p>
          <pre><code>int compareTo(String anotherString)</code></pre>
          <pre><code>int compareToIgnoreCase(String anotherString)</code></pre>
          <p>Example:</p>
          <pre><code>{`String str1 = "apple";\nString str2 = "banana";\nSystem.out.println(str1.compareTo(str2));  // Output: -1\nSystem.out.println(str1.compareToIgnoreCase("APPLE"));  // Output: 0`}</code></pre>
          <br/>
          <h2>15. String to Char Array (<code>toCharArray()</code>)</h2>
          <p>The <code>toCharArray()</code> method converts a string into a new character array.</p>
          <pre><code>char[] toCharArray()</code></pre>
          <p>Example:</p>
          <pre><code>{`String str = "Hello";\nchar[] charArray = str.toCharArray();\nfor (char c : charArray) {\n    System.out.print(c);  // Output: "Hello"\n}`}</code></pre>
          <br/>
          <h2>Conclusion</h2>
          <p>The <code>String</code> class in Java provides a rich set of methods that allow you to manipulate and interact with strings efficiently. From basic operations like <code>length()</code>, <code>charAt()</code>, and <code>substring()</code> to more advanced methods like <code>replace()</code>, <code>split()</code>, and <code>compareTo()</code>, the <code>String</code> class is essential for working with text in Java. Understanding these methods will help you write cleaner, more efficient string manipulation code.</p>
        </div>
      )}
    


{selectedChapter === 'chapter66' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> StringBuffer Class  </h1>

          <p>
            The <code>StringBuffer</code> class in Java is part of the <code>java.lang</code> package
            and is used to create mutable (modifiable) sequences of characters. Unlike the <code>String</code> class,
            which is immutable (i.e., any modification creates a new object), <code>StringBuffer</code> allows modifications
            to be made directly to the existing string without creating new objects.
          </p><br/>

          <h2>Key Features of <code>StringBuffer</code>:</h2>
          <ul>
            <li><strong>Mutability</strong>: You can modify the content of the string buffer after its creation, which makes it more efficient for string manipulations when repeated changes are needed.</li><br/>
            <li><strong>Thread-Safety</strong>: Methods in <code>StringBuffer</code> are synchronized, meaning they are thread-safe. However, this synchronization comes at the cost of performance when compared to <code>StringBuilder</code> (which is similar but not synchronized).</li><br/>
            <li><strong>Efficiency</strong>: For large strings and repeated modifications (like appending, inserting, or deleting), <code>StringBuffer</code> is more efficient than using regular <code>String</code> objects because it avoids creating new objects for each modification.</li>
          </ul><br/>

          <h2>Commonly Used Methods of <code>StringBuffer</code>:</h2>
          
          <h3>1. <code>append()</code></h3>
          <p>The <code>append()</code> method is used to add characters to the end of the existing string.</p>
          <pre>
            <code>{`StringBuffer sb = new StringBuffer("Hello");<br />
sb.append(" World!");<br />
System.out.println(sb);  // Output: "Hello World!"`}</code>
          </pre><br/>

          <h3>2. <code>insert()</code></h3>
          <p>The <code>insert()</code> method inserts a string, character, or other data type at a specified position in the <code>StringBuffer</code>.</p>
          <pre>
            <code>{`StringBuffer sb = new StringBuffer("Hello World!");<br />
sb.insert(6, "Java ");<br />
System.out.println(sb);  // Output: "Hello Java World!"
`}</code>
          </pre><br/>

          <h3>3. <code>delete()</code></h3>
          <p>The <code>delete()</code> method removes characters from the <code>StringBuffer</code> between the specified start and end indices.</p>
          <pre>
            <code>{`StringBuffer sb = new StringBuffer("Hello Java World!");<br />
sb.delete(6, 10);  // Removes "Java"<br />
System.out.println(sb);  // Output: "`}</code>
          </pre><br/>

          <h3>4. <code>replace()</code></h3>
          <p>The <code>replace()</code> method replaces a portion of the string with a new string, starting at the specified index and extending to the end index.</p>
          <pre>
            <code>{`StringBuffer sb = new StringBuffer("Hello World!");<br />
sb.replace(6, 11, "Java");<br />
System.out.println(sb);  // Output: "Hello Java!"
`} </code>
          </pre><br/>

          <h3>5. <code>reverse()</code></h3>
          <p>The <code>reverse()</code> method reverses the order of characters in the <code>StringBuffer</code>.</p>
          <pre>
            <code>{`StringBuffer sb = new StringBuffer("Hello");<br />
sb.reverse();<br />
System.out.println(sb);  // Output: "olleH"
`}</code>
          </pre><br/>

          <h3>6. <code>capacity()</code></h3>
          <p>The <code>capacity()</code> method returns the current capacity of the <code>StringBuffer</code>, which is the amount of space allocated for the string buffer.</p>
          <pre>
            <code>{`
StringBuffer sb = new StringBuffer();<br />
System.out.println(sb.capacity());  // Output: 16 (default initial capacity)
`}</code>
          </pre><br/>

          <h3>7. <code>ensureCapacity()</code></h3>
          <p>The <code>ensureCapacity()</code> method ensures that the <code>StringBuffer</code> can accommodate a specified number of characters without resizing.</p>
          <pre>
            <code>{`StringBuffer sb = new StringBuffer();<br />
sb.ensureCapacity(50);  // Ensures capacity is at least 50 characters<br />
System.out.println(sb.capacity());  // Output: 50
`} </code>
          </pre>
          <br/>
          <h3>8. <code>setLength()</code></h3>
          <p>The <code>setLength()</code> method sets the length of the <code>StringBuffer</code>. If the new length is greater than the current length, the buffer is padded with null characters. If the new length is shorter, the string is truncated.</p>
          <pre>
            <code>{`
StringBuffer sb = new StringBuffer("Hello World!");<br />
sb.setLength(5);<br />
System.out.println(sb);  // Output: "Hello"
`}</code>
          </pre><br/>

          <h3>9. <code>substring()</code></h3>
          <p>The <code>substring()</code> method extracts a substring from the <code>StringBuffer</code>, similar to how it works in the <code>String</code> class.</p>
          <pre>
            <code>{`StringBuffer sb = new StringBuffer("Hello Java");<br />
System.out.println(sb.substring(6));  // Output: "Java"<br />
System.out.println(sb.substring(0, 5));  // Output: "Hello"
`}</code>
          </pre><br/>

          <h2>Comparison: <code>StringBuffer</code> vs. <code>String</code></h2>
          <ul>
            <li><strong>Mutability</strong>: <code>StringBuffer</code> is mutable, meaning it allows modification of its content without creating new objects. On the other hand, <code>String</code> is immutable.</li><br/>
            <li><strong>Performance</strong>: <code>StringBuffer</code> is more efficient when performing numerous string operations (like appending or inserting) because it avoids creating new objects every time. For a single operation, <code>String</code> is faster because there’s no synchronization overhead.</li><br/>
            <li><strong>Thread-Safety</strong>: <code>StringBuffer</code> is synchronized, meaning it is thread-safe. However, if thread safety is not needed, <code>StringBuilder</code> (which is similar to <code>StringBuffer</code> but not synchronized) might be a better choice for performance reasons.</li>
          </ul><br/>

          <h2>When to Use <code>StringBuffer</code>?</h2>
          <ul>
            <li><strong>Multiple Modifications</strong>: When you need to perform multiple modifications to a string (like appending, inserting, deleting), <code>StringBuffer</code> is ideal due to its mutability.</li><br/>
            <li><strong>Thread-Safety Required</strong>: If you are working in a multithreaded environment and need a thread-safe version of <code>StringBuilder</code>, <code>StringBuffer</code> is the appropriate choice.</li>
          </ul><br/>

          <h2>Example: Working with <code>StringBuffer</code></h2>
          <pre>
            <code>{`public class Main {
    public static void main(String[] args) {
        // Create a StringBuffer
        StringBuffer sb = new StringBuffer("Hello");

        // Append text to the StringBuffer
        sb.append(" World!");
        System.out.println(sb);  // Output: Hello World!

        // Insert text at a specific position
        sb.insert(6, "Java ");
        System.out.println(sb);  // Output: Hello Java World!

        // Replace a part of the StringBuffer content
        sb.replace(6, 10, "Awesome");
        System.out.println(sb);  // Output: Hello Awesome World!

        // Delete a part of the StringBuffer content
        sb.delete(6, 14);
        System.out.println(sb);  // Output: Hello World!

        // Reverse the content of the StringBuffer
        sb.reverse();
        System.out.println(sb);  // Output: !dlroW olleH
    }
}`}</code>
          </pre>
        </div>
      )}
   


   {selectedChapter === 'chapter67' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> StringBuilder Class  </h1>

      <p>
        The <code>StringBuilder</code> class in Java is part of the <code>java.lang</code> package and is used to create mutable (modifiable) sequences of characters. It is similar to the <code>StringBuffer</code> class, but with a key difference: <code>StringBuilder</code> is <strong>not synchronized</strong>, making it faster and more suitable for single-threaded environments where thread safety is not a concern.
      </p><br/>

      <h2>Key Features of <code>StringBuilder</code>:</h2>
      <ul>
        <li><strong>Mutability:</strong> Like <code>StringBuffer</code>, <code>StringBuilder</code> allows modifications to the string it contains without creating new objects. This makes it more efficient than the immutable <code>String</code> class for repeated string manipulations.</li><br/>
        <li><strong>No Thread-Safety:</strong> Unlike <code>StringBuffer</code>, which is synchronized and thread-safe, <code>StringBuilder</code> is not synchronized. This makes it faster in scenarios where thread safety is not required, as synchronization comes with overhead.</li><br/>
        <li><strong>Performance:</strong> <code>StringBuilder</code> is generally more efficient than <code>StringBuffer</code> in single-threaded scenarios because there’s no synchronization overhead.</li><br/>
        <li><strong>Memory Efficiency:</strong> <code>StringBuilder</code> dynamically expands the internal character array as needed, minimizing memory usage for string manipulation.</li>
      </ul><br/>

      <h2>Commonly Used Methods of <code>StringBuilder</code>:</h2>

      <h3>1. Append (<code>append()</code>)</h3>
      <p>The <code>append()</code> method adds characters to the end of the existing <code>StringBuilder</code> object.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder("Hello");<br />
          sb.append(" World!");<br />
          System.out.println(sb);  // Output: "Hello World!"
        `}</code>
      </pre><br/>

      <h3>2. Insert (<code>insert()</code>)</h3>
      <p>The <code>insert()</code> method inserts a string, character, or other data types at a specific position in the <code>StringBuilder</code>.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder("Hello World!");<br />
          sb.insert(6, "Java ");<br />
          System.out.println(sb);  // Output: "Hello Java World!"
       `} </code>
      </pre><br/>

      <h3>3. Delete (<code>delete()</code>)</h3>
      <p>The <code>delete()</code> method removes a portion of the string starting from the specified start index to the end index.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder("Hello Java World!");<br />
          sb.delete(6, 10);  // Removes "Java"<br />
          System.out.println(sb);  // Output: "Hello World!"
       `} </code>
      </pre><br/>

      <h3>4. Replace (<code>replace()</code>)</h3>
      <p>The <code>replace()</code> method replaces a portion of the string with a new string, starting at the specified index and extending to the end index.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder("Hello World!");<br />
          sb.replace(6, 11, "Java");<br />
          System.out.println(sb);  // Output: "Hello Java!"
        `}</code>
      </pre><br/>

      <h3>5. Reverse (<code>reverse()</code>)</h3>
      <p>The <code>reverse()</code> method reverses the order of characters in the <code>StringBuilder</code>.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder("Hello");<br />
          sb.reverse();<br />
          System.out.println(sb);  // Output: "olleH"
        `}</code>
      </pre><br/>

      <h3>6. Capacity (<code>capacity()</code>)</h3>
      <p>The <code>capacity()</code> method returns the current capacity of the <code>StringBuilder</code>, which is the amount of space allocated for the string buffer.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder();<br />
          System.out.println(sb.capacity());  // Output: 16 (default initial capacity)
        `}</code>
      </pre><br/>

      <h3>7. Ensure Capacity (<code>ensureCapacity()</code>)</h3>
      <p>The <code>ensureCapacity()</code> method ensures that the <code>StringBuilder</code> can accommodate a specified number of characters without resizing.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder();<br />
          sb.ensureCapacity(50);  // Ensures capacity is at least 50 characters<br />
          System.out.println(sb.capacity());  // Output: 50
        `}</code>
      </pre>

      <h3>8. Set Length (<code>setLength()</code>)</h3>
      <p>The <code>setLength()</code> method sets the length of the <code>StringBuilder</code>. If the new length is greater than the current length, the buffer is padded with null characters. If the new length is shorter, the string is truncated.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder("Hello World!");<br />
          sb.setLength(5);<br />
          System.out.println(sb);  // Output: "Hello"
       `} </code>
      </pre><br/>

      <h3>9. Substring (<code>substring()</code>)</h3>
      <p>The <code>substring()</code> method extracts a substring from the <code>StringBuilder</code>, similar to how it works in the <code>String</code> class.</p>
      <pre>
        <code>{`
          StringBuilder sb = new StringBuilder("Hello Java");<br />
          System.out.println(sb.substring(6));  // Output: "Java"<br />
          System.out.println(sb.substring(0, 5));  // Output: "Hello"
        `}</code>
      </pre><br/>

      <h2>Comparison: <code>StringBuilder</code> vs. <code>StringBuffer</code></h2>
      <p>
        <strong>Thread-Safety:</strong> <code>StringBuffer</code> is thread-safe because its methods are synchronized, while <code>StringBuilder</code> is not synchronized and hence is not thread-safe. This makes <code>StringBuilder</code> more suitable for single-threaded applications where synchronization is not needed.
      </p><br/>
      <p>
        <strong>Performance:</strong> Since <code>StringBuilder</code> does not have the overhead of synchronization, it generally performs better than <code>StringBuffer</code> in single-threaded scenarios. However, if you need thread safety, <code>StringBuffer</code> should be used.
      </p><br/>

      <h2>When to Use <code>StringBuilder</code>?</h2>
      <ul>
        <li><strong>Single-threaded Environment:</strong> Use <code>StringBuilder</code> when thread safety is not a concern, as it is faster than <code>StringBuffer</code>.</li><br/>
        <li><strong>String Manipulations:</strong> If you need to perform multiple string manipulations like appending, inserting, replacing, or deleting in a loop, <code>StringBuilder</code> is more efficient than using <code>String</code> objects.</li>
      </ul><br/>

      <h2>Example: Working with <code>StringBuilder</code></h2>
      <pre>
        <code>{`public class Main {
    public static void main(String[] args) {
        // Create a StringBuilder
        StringBuilder sb = new StringBuilder("Hello");

        // Append a string
        sb.append(" World!");
        System.out.println(sb);  // Output: Hello World!

        // Insert at a specific index
        sb.insert(6, "Java ");
        System.out.println(sb);  // Output: Hello Java World!

        // Replace part of the string
        sb.replace(6, 10, "Awesome");
        System.out.println(sb);  // Output: Hello Awesome World!

        // Delete part of the string
        sb.delete(6, 14);
        System.out.println(sb);  // Output: Hello World!

        // Reverse the string
        sb.reverse();
        System.out.println(sb);  // Output: !dlroW olleH
    }
}`}</code>
      </pre><br/>

      <h2>Conclusion</h2>
      <p>
        <code>StringBuilder</code> provides a more efficient way to perform string manipulations when thread safety is not a concern. It is particularly useful in scenarios where you need to modify a string repeatedly. Since it is not synchronized, it outperforms <code>StringBuffer</code> in single-threaded environments, making it the preferred choice for such situations.
      </p>
    </div>
  )}


{selectedChapter === 'chapter68' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> StringBuffer vs. StringBuilder   </h1>

      <h2>1. Thread-Safety</h2>
      <p>
        <strong>StringBuffer:</strong> 
        <br />
        <span><strong>Thread-Safe:</strong> `StringBuffer` is synchronized, meaning that its methods are thread-safe. This synchronization ensures that only one thread can access a method at a time, making it safe for concurrent access. However, the trade-off is that synchronization introduces overhead, making it slower than `StringBuilder` in single-threaded environments.</span>
        <br /><br/>
        <strong>Use Case:</strong> `StringBuffer` should be used in situations where thread safety is a priority, such as in multi-threaded applications.
      </p><br/>
      
      <p>
        <strong>StringBuilder:</strong>
        <br />
        <span><strong>Not Thread-Safe:</strong> Unlike `StringBuffer`, `StringBuilder` is not synchronized. This means multiple threads can access and modify a `StringBuilder` object concurrently, which can lead to unpredictable behavior if thread safety is not handled externally. However, the absence of synchronization makes `StringBuilder` faster than `StringBuffer` in single-threaded environments, where thread safety is not a concern.</span>
        <br /><br/>
        <strong>Use Case:</strong> `StringBuilder` is preferred in single-threaded environments or in cases where thread safety is not a concern.
      </p><br/>

      <h2>2. Performance</h2>
      <p>
        <strong>StringBuffer:</strong> Since `StringBuffer` methods are synchronized, the overhead of acquiring and releasing locks can slow down performance, especially in single-threaded scenarios.
        <br /><br/>
        <strong>Performance:</strong> Slightly slower due to synchronization overhead.
      </p><br/>

      <p>
        <strong>StringBuilder:</strong> `StringBuilder` does not have the synchronization overhead, so it performs better than `StringBuffer` in environments where thread safety is not a concern (typically single-threaded applications).
        <br /><br/>
        <strong>Performance:</strong> Faster than `StringBuffer` in most cases, especially when thread safety is not required.
      </p><br/>

      <h2>3. Use Cases</h2>
      <p>
        <strong>StringBuffer:</strong> It is ideal for use in <strong>multi-threaded</strong> environments where multiple threads might modify a string.
        <br /><br/>
        Example use cases include applications with multiple threads modifying the same string, or when building strings in parallel.
      </p><br/>

      <p>
        <strong>StringBuilder:</strong> It is designed for use in <strong>single-threaded</strong> environments, where you need to perform multiple string manipulations, such as appending, inserting, and modifying strings frequently.
        <br /><br/>
        Example use cases include string concatenation in loops or algorithms that require repeated string manipulation.
      </p>
      <br/>
      <h2>4. Memory Allocation and Growth</h2>
      <p>
        Both `StringBuffer` and `StringBuilder` dynamically resize their internal buffer when the length of the sequence exceeds the current buffer capacity. By default, the initial capacity of both classes is 16 characters, but this value can be modified.
      </p><br/>

      <p>
        <strong>StringBuffer:</strong> The internal character array grows by approximately double its current size when the capacity is exceeded.
      </p><br/>

      <p>
        <strong>StringBuilder:</strong> The internal character array also grows dynamically, but the growth behavior may differ slightly from `StringBuffer`. The general behavior is still doubling the size when necessary.
        <br /><br/>
        In both classes, if you know in advance how much space your string will need, you can set the initial capacity to avoid frequent resizing.
      </p><br/>

      <h2>5. Key Methods (Both `StringBuffer` and `StringBuilder`)</h2>
      <ul>
        <li><strong>append():</strong> Adds data to the end of the string.</li><br/>
        <li><strong>insert():</strong> Inserts data at a specific position.</li><br/>
        <li><strong>delete():</strong> Removes characters from a specified range.</li><br/>
        <li><strong>replace():</strong> Replaces characters in a specified range with new data.</li><br/>
        <li><strong>reverse():</strong> Reverses the sequence of characters.</li><br/>
        <li><strong>substring():</strong> Extracts a substring from the sequence.</li><br/>
        <li><strong>capacity():</strong> Returns the current capacity of the sequence.</li><br/>
        <li><strong>ensureCapacity():</strong> Ensures that the capacity is at least as large as the specified value.</li>
      </ul><br/>

      <h2>6. Example Code</h2>

      <h3>StringBuffer Example (Thread-Safe)</h3>
      <pre>
        {`public class Main {
    public static void main(String[] args) {
        StringBuffer sb = new StringBuffer("Hello");
        
        // Append a string
        sb.append(" World!");
        System.out.println(sb);  // Output: "Hello World!"
        
        // Insert at a specific position
        sb.insert(6, "Java ");
        System.out.println(sb);  // Output: "Hello Java World!"
        
        // Reverse the string
        sb.reverse();
        System.out.println(sb);  // Output: "!dlroW avaJ olleH"
    }
}`}
      </pre><br/>

      <h3>StringBuilder Example (Faster in Single-threaded Environments)</h3>
      <pre>
        {`public class Main {
    public static void main(String[] args) {
        StringBuilder sb = new StringBuilder("Hello");
        
        // Append a string
        sb.append(" World!");
        System.out.println(sb);  // Output: "Hello World!"
        
        // Insert at a specific position
        sb.insert(6, "Java ");
        System.out.println(sb);  // Output: "Hello Java World!"
        
        // Reverse the string
        sb.reverse();
        System.out.println(sb);  // Output: "!dlroW avaJ olleH"
    }
}`}
      </pre><br/>

      <h2>Summary Table</h2>
      <table>
        <thead>
          <tr>
            <th>Feature</th>
            <th>StringBuffer</th>
            <th>StringBuilder</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Thread Safety</td>
            <td>Synchronized, thread-safe</td>
            <td>Not synchronized, not thread-safe</td>
          </tr>
          <tr>
            <td>Performance</td>
            <td>Slower due to synchronization overhead</td>
            <td>Faster, especially in single-threaded environments</td>
          </tr>
          <tr>
            <td>Use Case</td>
            <td>Multi-threaded environments</td>
            <td>Single-threaded environments</td>
          </tr>
          <tr>
            <td>Memory Efficiency</td>
            <td>Dynamically resizes the internal buffer</td>
            <td>Dynamically resizes the internal buffer</td>
          </tr>
          <tr>
            <td>Methods</td>
            <td>Same methods as StringBuilder</td>
            <td>Same methods as StringBuffer</td>
          </tr>
          <tr>
            <td>Initial Capacity</td>
            <td>16 characters by default</td>
            <td>16 characters by default</td>
          </tr>
        </tbody>
      </table><br/>

      <h2 style={{paddingBottom:"6px"}}>When to Use StringBuffer vs. StringBuilder?</h2>
      <h3>Use StringBuffer:</h3>
      <ul>
        <li>When you are working in a multi-threaded environment and you need thread safety.</li><br/>
        <li>When you need a mutable string with synchronization provided by the class itself.</li>
      </ul><br/>

      <h3>Use StringBuilder:</h3>
      <ul>
        <li>In single-threaded applications where thread safety is not a concern.</li><br/>
        <li>For performance-sensitive operations that involve frequent string manipulations.</li>
      </ul><br/>

      <h2>Conclusion</h2>
      <p>
        Both `StringBuffer` and `StringBuilder` are great classes for manipulating strings in Java, but the decision of which to use depends mainly on the threading requirements of your application. If thread safety is crucial, use `StringBuffer`. For most modern, single-threaded applications where performance is a concern, `StringBuilder` is the preferred choice due to its better performance.
      </p>
    </div>
  )}



{selectedChapter === 'chapter69' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Creating Immutable Class </h1>

      <p>
        In Java, an <strong>immutable class</strong> is a class whose objects cannot be modified after they are created. This concept is commonly used to ensure that the state of an object remains constant and cannot be altered, providing better thread safety and predictable behavior in concurrent environments. In Java, the <code>String</code> class is a classic example of an immutable class.
      </p><br/>

      <h3>Key Features of an Immutable Class:</h3>
      <ol>
        <li><strong>Final Class</strong>: The class itself is typically marked as <code>final</code> to prevent subclassing.</li><br/>
        <li><strong>Final Fields</strong>: All fields (variables) of the class are declared <code>final</code> so that they cannot be changed after initialization.</li><br/>
        <li><strong>No Setter Methods</strong>: There are no methods that modify the object's state.</li><br/>
        <li><strong>Deep Copying for Mutable Fields</strong>: If the class contains references to mutable objects, the class ensures that those objects cannot be modified by providing defensive copies.</li>
      </ol><br/>

      <h3>Why Create an Immutable Class?</h3>
      <ul>
        <li><strong>Thread-Safety</strong>: Immutable objects can be shared between threads without the need for synchronization since their state cannot change once they are created.</li><br/>
        <li><strong>Security</strong>: It prevents clients from changing the state of an object, which is particularly useful when passing sensitive data.</li><br/>
        <li><strong>Ease of Maintenance</strong>: Since the state cannot change, immutable objects are easier to reason about and maintain.</li><br/>
        <li><strong>HashCode Consistency</strong>: Immutable objects can be safely used as keys in a hash-based collection like <code>HashMap</code>, as their hash codes remain consistent throughout their lifetime.</li>
      </ul><br/>

      <h3>Steps to Create an Immutable Class</h3>

      <h4>1. Mark the Class as <code>final</code></h4>
      <p>To prevent subclassing and modification of behavior, the class is marked <code>final</code>:</p>
      <pre><code>{`public final class Person {
    private final String name;
    private final int age;
}`}</code></pre><br/>

      <h4>2. Declare All Fields as <code>private</code> and <code>final</code></h4>
      <p>Fields in an immutable class are usually declared <code>private</code> to restrict access and <code>final</code> to ensure that they can only be assigned once:</p>
      <pre><code>{`private final String name;
private final int age;`}</code></pre><br/>

      <h4>3. Initialize Fields via a Constructor</h4>
      <p>The fields should be initialized via a constructor, and this constructor should be the only place where the fields are assigned values. The constructor is used to set the state of the object at the time of creation:</p>
      <pre><code>{`public Person(String name, int age) {
    this.name = name;
    this.age = age;
}`}</code></pre><br/>

      <h4>4. Do Not Provide Setter Methods</h4>
      <p>Since we want to prevent the modification of the object's state, we do not provide setter methods:</p>
      <pre><code>{`// No setter methods
public void setName(String name) {
    this.name = name; // This would not be allowed in an immutable class
}`}</code></pre><br/>

      <h4>5. Provide Getter Methods</h4>
      <p>You can provide getter methods to retrieve the values of the fields, but they cannot modify the state:</p>
      <pre><code>{`public String getName() {
    return name;
}

public int getAge() {
    return age;
}`}</code></pre><br/>

      <h4>6. Handle Mutable Fields Carefully</h4>
      <p>If the immutable class has fields that refer to mutable objects (e.g., <code>Date</code>, <code>ArrayList</code>), you need to ensure that:</p>
      <ul>
        <li>You don’t allow the original object to be modified.</li><br/>
        <li>You make a defensive copy of any mutable field when the object is created or returned.</li>
      </ul><br/>
      <p>For example, if we had a <code>Date</code> field:</p>
      <pre><code>{`import java.util.Date;

public final class Person {
    private final String name;
    private final Date birthDate;

    public Person(String name, Date birthDate) {
        this.name = name;
        // Creating a defensive copy of the mutable Date object
        this.birthDate = new Date(birthDate.getTime());
    }

    public String getName() {
        return name;
    }

    public Date getBirthDate() {
        // Returning a defensive copy to prevent modification of the original object
        return new Date(birthDate.getTime());
    }
}`}</code></pre><br/>

      <h3>Example of an Immutable Class</h3>
      <pre><code>{`import java.util.Date;

public final class Employee {
    private final String name;
    private final int employeeId;
    private final Date dateOfJoining;

    // Constructor to initialize fields
    public Employee(String name, int employeeId, Date dateOfJoining) {
        this.name = name;
        this.employeeId = employeeId;
        // Creating defensive copies of mutable objects
        this.dateOfJoining = new Date(dateOfJoining.getTime());
    }

    // Getter methods (no setters)
    public String getName() {
        return name;
    }

    public int getEmployeeId() {
        return employeeId;
    }

    public Date getDateOfJoining() {
        // Returning a defensive copy to maintain immutability
        return new Date(dateOfJoining.getTime());
    }
}`}</code></pre><br/>

      <h3>Summary of Best Practices for Immutable Classes</h3>
      <ul>
        <li><strong>Class should be <code>final</code></strong>: To prevent subclassing.</li><br/>
        <li><strong>Fields should be <code>private</code> and <code>final</code></strong>: So they cannot be changed after initialization.</li><br/>
        <li><strong>No setter methods</strong>: This ensures the object's state is not modified after construction.</li><br/>
        <li><strong>Provide getters for accessing field values</strong>.</li><br/>
        <li><strong>For mutable fields, use defensive copies</strong>: Always create copies of mutable objects to ensure the immutability of the class.</li><br/>
        <li><strong>Initialization via constructor</strong>: Fields are set only during object construction.</li>
      </ul><br/>

      <h3>Advantages of Immutable Classes</h3>
      <ul>
        <li><strong>Thread Safety</strong>: Immutable objects can be shared among multiple threads without synchronization because their state cannot change.</li><br/>
        <li><strong>Security</strong>: Immutable objects prevent external modification of their state.</li><br/>
        <li><strong>Consistency</strong>: Since their state doesn’t change, immutable objects provide predictable behavior.</li><br/>
        <li><strong>Simplicity</strong>: They reduce the complexity associated with mutating the state of an object.</li>
      </ul>
      <br/>
      <p>Immutable objects are a core design pattern in Java, and understanding how to create them will help in designing reliable and secure applications.</p>
    </div>
  )}



{selectedChapter === 'chapter70' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> `toString()` Method   </h1>

      <p>
        The <strong>toString()</strong> method in Java is a method that is present in the <code>Object</code> class, which is the superclass of all classes in Java. This method is intended to provide a string representation of an object, making it possible to print the object or display it as a string.
      </p><br/>

      <p>
        By default, the <strong>toString()</strong> method returns a string that contains the class name of the object and its memory address in hexadecimal form. However, many classes override this method to return a more meaningful string representation, which is especially useful for debugging and logging.
      </p>
      <br/>
      <h2>Default Behavior of <code>toString()</code></h2>
      <p>
        By default, the <strong>toString()</strong> method in the <code>Object</code> class returns a string consisting of:
      </p><br/>
      <ol>
        <li>The <strong>class name</strong> of the object.</li><br/>
        <li>The <strong>at-sign symbol (@)</strong>.</li><br/>
        <li>The <strong>hashcode</strong> of the object (in hexadecimal format).</li>
      </ol><br/>

      <pre>
        <code>
          {`public class Person {
  public static void main(String[] args) {
    Person person = new Person();
    System.out.println(person.toString());
  }
}`}
        </code>
      </pre>

      <p>Output:</p>
      <pre>
        <code>
          {`
Person@15db9742
          `}
        </code>
      </pre><br/>

      <h2>Customizing the <code>toString()</code> Method</h2>
      <p>
        To provide a more meaningful string representation of an object, you can override the <strong>toString()</strong> method in your own classes. A common practice is to return a string that represents the important fields of the object.
      </p><br/>

      <h3>Example: Custom <code>toString()</code> Implementation</h3>

      <pre>
        <code>
          {`public class Person {
  private String name;
  private int age;

  // Constructor to initialize fields
  public Person(String name, int age) {
    this.name = name;
    this.age = age;
  }

  // Overriding the toString method to provide a meaningful representation
  @Override
  public String toString() {
    return "Person{name='" + name + "', age=" + age + "}";
  }

  public static void main(String[] args) {
    Person person = new Person("John", 30);
    System.out.println(person.toString()); // Prints: Person{name='John', age=30}
  }
}`}
        </code>
      </pre>

      <p>Output:</p>
      <pre>
        <code>
          {`
Person{name='John', age=30}
          `}
        </code>
      </pre><br/>

      <h2>Benefits of Overriding <code>toString()</code></h2>
      <ul>
        <li><strong>Improved Debugging</strong>: Overriding <code>toString()</code> allows you to see useful information about an object when printing or logging it, making debugging easier.</li><br/>
        <li><strong>Readable Output</strong>: By providing a clear string representation of the object, the output is more readable for developers and end-users.</li><br/>
        <li><strong>Consistency</strong>: In some frameworks (like logging libraries or collection classes), the <code>toString()</code> method is used to display objects. Overriding it ensures consistent and meaningful output.</li><br/>
        <li><strong>String Representation of Complex Objects</strong>: If your class contains other objects, you can call <code>toString()</code> on those objects too, which is especially useful for displaying nested objects.</li>
      </ul><br/>

      <h3>Example with Nested Objects</h3>

      <pre>
        <code>
          {`public class Address {
  private String street;
  private String city;

  public Address(String street, String city) {
    this.street = street;
    this.city = city;
  }

  @Override
  public String toString() {
    return "Address{street='" + street + "', city='" + city + "'}";
  }
}

public class Person {
  private String name;
  private int age;
  private Address address;

  public Person(String name, int age, Address address) {
    this.name = name;
    this.age = age;
    this.address = address;
  }

  @Override
  public String toString() {
    return "Person{name='" + name + "', age=" + age + ", address=" + address + "}";
  }

  public static void main(String[] args) {
    Address address = new Address("123 Main St", "Springfield");
    Person person = new Person("John", 30, address);
    System.out.println(person.toString());
  }
}`}
        </code>
      </pre>

      <p>Output:</p>
      <pre>
        <code>
          {`
Person{name='John', age=30, address=Address{street='123 Main St', city='Springfield'}}
          `}
        </code>
      </pre><br/>

      <h2>Important Considerations</h2>
      <ul>
        <li><strong>Avoid Infinite Recursion</strong>: If the <code>toString()</code> method of an object refers to itself (for example, if it directly or indirectly calls <code>toString()</code> on another object that refers back), it can lead to infinite recursion. Ensure the method avoids this scenario.</li><br/>
        <li><strong>Return String that Represents State</strong>: The string returned from <code>toString()</code> should provide meaningful information that represents the state of the object. Avoid returning generic information that doesn't add value.</li><br/>
        <li><strong>Override when Necessary</strong>: While overriding <code>toString()</code> is recommended for classes that will be printed or logged, it's not mandatory. You should consider overriding it when you need better or more detailed output.</li><br/>
        <li><strong><code>toString()</code> and Collections</strong>: Java collections (like <code>List</code>, <code>Set</code>, etc.) rely on the <code>toString()</code> method to provide a string representation of the contained elements. Overriding <code>toString()</code> helps when objects are stored in collections.</li>
      </ul><br/>

      <h2>Common Uses of <code>toString()</code></h2>
      <ul>
        <li><strong>Printing Objects</strong>: When you print an object directly with <code>System.out.println()</code>, it automatically calls the <code>toString()</code> method.</li><br/>
        <li><strong>Logging</strong>: When logging information, many logging frameworks use the <code>toString()</code> method to log object details.</li><br/>
        <li><strong>Debugging</strong>: By using <code>toString()</code> for object state representation, developers can easily inspect object contents.</li>
      </ul><br/>

      <h2>Summary</h2>
      <ul>
        <li>The <code>toString()</code> method provides a string representation of an object.</li><br/>
        <li>By default, it returns the class name and the memory address (hashcode), but it can be overridden to provide more meaningful information.</li><br/>
        <li>Overriding <code>toString()</code> is helpful for debugging, logging, and providing better visibility into object states.</li><br/>
        <li>It is recommended to override <code>toString()</code> when dealing with objects that need to be printed, logged, or inspected.</li>
      </ul>
    </div>
  )}



{selectedChapter === 'chapter71' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  StringTokenizer Class  </h1>

      <p>
        The <code>StringTokenizer</code> class in Java is a utility class used to break a string into tokens.
        A token is a part of the string that is separated by delimiters, such as spaces, commas, or other characters.
        The <code>StringTokenizer</code> class makes it easy to split a string into multiple parts based on a delimiter and then process those parts individually.
      </p><br/>

      <h2>Key Concepts:</h2>
      <ul>
        <li><strong>Token:</strong> A substring that is separated by delimiters.</li><br/>
        <li><strong>Delimiter:</strong> The character or characters that separate tokens in the string.</li>
      </ul><br/>

      <h2>Importing the Class</h2>
      <p>
        To use <code>StringTokenizer</code>, you need to import it from the <code>java.util</code> package:
      </p>
      <pre>
        <code>
          import java.util.StringTokenizer;
        </code>
      </pre><br/>

      <h2>Constructors of StringTokenizer</h2>
      <p>The <code>StringTokenizer</code> class provides several constructors:</p>
      <ul>
        <li>
          <strong>StringTokenizer(String str):</strong> This constructor splits the string <code>str</code> using the default delimiter, which is whitespace (spaces, tabs, newlines).
          <pre><code>StringTokenizer st = new StringTokenizer("Java is fun");</code></pre>
          <p>This will split the string <code>"Java is fun"</code> into three tokens: <code>"Java"</code>, <code>"is"</code>, and <code>"fun"</code>.</p>
        </li><br/>
        <li>
          <strong>StringTokenizer(String str, String delim):</strong> This constructor splits the string <code>str</code> using the specified delimiter <code>delim</code>.
          <pre><code>StringTokenizer st = new StringTokenizer("apple,orange,banana", ",");</code></pre>
          <p>This will split the string <code>"apple,orange,banana"</code> into three tokens: <code>"apple"</code>, <code>"orange"</code>, and <code>"banana"</code>, using the comma <code>,</code> as the delimiter.</p>
        </li><br/>
        <li>
          <strong>StringTokenizer(String str, String delim, boolean returnDelims):</strong> This constructor splits the string <code>str</code> using the specified delimiter <code>delim</code>, and the boolean <code>returnDelims</code> indicates whether the delimiters should be included in the tokens.
          <pre><code>StringTokenizer st = new StringTokenizer("apple,orange,banana", ",", true);</code></pre>
          <p>This will split the string <code>"apple,orange,banana"</code> into tokens: <code>"apple"</code>, <code>","</code>, <code>"orange"</code>, <code>","</code>, and <code>"banana"</code>, with commas included as tokens.</p>
        </li>
      </ul><br/>

      <h2>Important Methods of StringTokenizer</h2>
      <ul>
        <li><strong>hasMoreTokens():</strong> Returns <code>true</code> if there are more tokens to be retrieved; otherwise, it returns <code>false</code>.</li><br/>
        <li><strong>nextToken():</strong> Returns the next token in the string.</li><br/>
        <li><strong>nextToken(String delim):</strong> Allows specifying a custom delimiter for the current token.</li><br/>
        <li><strong>countTokens():</strong> Returns the number of remaining tokens that can be retrieved.</li><br/>
        <li><strong>inDelimiter(char ch):</strong> Checks if the character <code>ch</code> is a delimiter for the string tokenizer.</li>
      </ul><br/>

      <h2>Example Usage</h2>
      <pre>
        <code>{`import java.util.StringTokenizer;

public class Main {
    public static void main(String[] args) {
        String str = "Java is fun; I love programming!";

        // Using StringTokenizer with default delimiters (whitespace)
        StringTokenizer st = new StringTokenizer(str);
        System.out.println("Tokens using default delimiter (whitespace):");
        while (st.hasMoreTokens()) {
            System.out.println(st.nextToken());
        }

        // Using StringTokenizer with a custom delimiter (semicolon)
        StringTokenizer st2 = new StringTokenizer(str, ";");
        System.out.println("\nTokens using semicolon delimiter:");
        while (st2.hasMoreTokens()) {
            System.out.println(st2.nextToken());
        }

        // Using StringTokenizer with returnDelims = true
        StringTokenizer st3 = new StringTokenizer(str, " ", true);
        System.out.println("\nTokens with delimiters included:");
        while (st3.hasMoreTokens()) {
            System.out.println(st3.nextToken());
        }
    }
}`}</code>
      </pre>

      <h2>Output:</h2>
      <pre>
        <code>{`Tokens using default delimiter (whitespace):
Java
is
fun;
I
love
programming!

Tokens using semicolon delimiter:
Java is fun
I love programming!

Tokens with delimiters included:
Java
is
fun;
I
love
programming!`}</code>
      </pre><br/>

      <h2>Important Considerations</h2>
      <ul>
        <li><strong>Thread Safety:</strong> The <code>StringTokenizer</code> class is not synchronized, meaning it’s not thread-safe. If you need to use it in a multi-threaded environment, consider using <code>String.split()</code> instead, or use synchronization mechanisms like <code>synchronized</code> blocks.</li><br/>
        <li><strong>Alternatives:</strong> Since <code>StringTokenizer</code> has been around for a while, some modern alternatives like <code>String.split()</code> are preferred in newer Java versions. The <code>String.split()</code> method allows splitting a string using regular expressions, providing more flexibility.</li>
      </ul><br/>

      <h2>Summary</h2>
      <ul>
        <li>The <code>StringTokenizer</code> class is used to break a string into tokens based on delimiters.</li><br/>
        <li>It has constructors for specifying the delimiter and whether to return delimiters as tokens.</li><br/>
        <li>You can iterate over the tokens using methods like <code>hasMoreTokens()</code> and <code>nextToken()</code>.</li><br/>
        <li>The class is simple and works well for basic string tokenization tasks, but alternatives like <code>String.split()</code> are often preferred in modern Java code for their flexibility and use of regular expressions.</li>
      </ul>
    </div>
)}


{selectedChapter === 'chapter72' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>charAt()</h1>
    
    <p>
      The <code>charAt()</code> method in Java is used to retrieve the character at a specific index in a string. This method is helpful when you need to access individual characters of a string.
    </p><br/>

    <h3>Method Signature:</h3>
    <pre>
      <code>public char charAt(int index)</code>
    </pre>

    <ul>
      <li><strong>Parameters:</strong> The method takes one parameter:
        <ul>
          <li><code>index</code> (<strong>int</strong>): The position (index) of the character you want to retrieve. The index is <strong>zero-based</strong>, meaning the first character is at index 0, the second at index 1, and so on.</li><br/>
        </ul>
      </li><br/>
      <li><strong>Returns:</strong> It returns the character at the specified index as a <code>char</code> type.</li>
    </ul><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Zero-Based Indexing:</strong> The index starts at 0. The first character in the string is at index 0, the second at index 1, and so on.</li><br/>
      <li><strong>Index Out of Bounds:</strong> If the provided index is less than 0 or greater than or equal to the length of the string, the method throws a <code>StringIndexOutOfBoundsException</code>.</li><br/>
      <li><strong>Immutability:</strong> Like other <code>String</code> methods, <code>charAt()</code> does not modify the original string but only returns a character from it.</li>
    </ul><br/>

    <h3>Example 1: Basic Usage of <code>charAt()</code></h3>
    <pre>
      <code>{`public class CharAtExample {
    public static void main(String[] args) {
        String str = "Hello";  // Define the string
        char ch = str.charAt(1);  // Extract the character at index 1
        System.out.println(ch);  // Output: e
    }
}`}</code>
    </pre>
    <p><strong>Explanation:</strong> In the string "Hello", the character at index 1 is 'e'. <code>charAt(1)</code> returns 'e', and it is printed.</p>
    <br/>
    <h3>Example 2: Accessing the First and Last Characters</h3>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Java";  // Define the string

        char firstChar = str.charAt(0);  // First character
        char lastChar = str.charAt(str.length() - 1);  // Last character

        System.out.println(firstChar);  // Output: J
        System.out.println(lastChar);   // Output: a
    }
}`}</code>
    </pre>
    <p><strong>Explanation:</strong> <code>charAt(0)</code> gives the first character 'J' and <code>charAt(3)</code> gives the last character 'a' (since the string length is 4, the last index is 3).</p>
    <br/>
    <h3>Example 3: Using <code>charAt()</code> in a Loop</h3>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Programming";  // Define the string

        for (int i = 0; i < str.length(); i++) {
            System.out.print(str.charAt(i) + " ");  // Print each character followed by a space
        }
        // Output: P r o g r a m m i n g
    }
}`}</code>
    </pre>
    <p><strong>Explanation:</strong> This example loops through the string "Programming", and <code>charAt(i)</code> is used to get and print each character.</p>
    <br/>
    <h3>Example 4: Handling Index Out of Bounds</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        String str = "Hello";  // Define the string

        try {
            char ch = str.charAt(10);  // This will throw an exception
            System.out.println(ch);  // This line won't be executed due to exception
        } catch (StringIndexOutOfBoundsException e) {
            System.out.println("Index out of bounds: " + e.getMessage());
        }
    }
}
 `}</code>
    </pre>
    <p><strong>Explanation:</strong> Since the string "Hello" has only 5 characters, accessing <code>charAt(10)</code> will throw a <code>StringIndexOutOfBoundsException</code>.</p>
    <br/>
    <h3>Example 5: Using <code>charAt()</code> for String Validation</h3>
    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        String str = "123abc";  // Define the string

        if (Character.isDigit(str.charAt(0))) {  // Check if the first character is a digit
            System.out.println("The first character is a digit.");
        } else {
            System.out.println("The first character is not a digit.");
        }
    }
}
`}</code>
    </pre>
    <p><strong>Explanation:</strong> We use <code>charAt(0)</code> to get the first character of the string "123abc", which is '1'. The <code>Character.isDigit()</code> method checks if this character is a digit.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li>The <code>charAt()</code> method returns the character at a specified index in a string.</li><br/>
      <li>It uses zero-based indexing, so the first character is at index 0.</li><br/>
      <li>An exception is thrown if the index is out of bounds.</li><br/>
      <li>It is useful when you need to access or manipulate individual characters in a string.</li><br/><br/>
      <li><code>charAt()</code> is commonly used in loops, validations, and accessing specific characters in a string.</li>
    </ul>
  </div>
)}



{selectedChapter === 'chapter73' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String compareTo() </h1>

      <p>The <code>compareTo()</code> method in Java is used to compare two strings lexicographically (dictionary order). It is a method of the <code>String</code> class and returns an integer value based on the comparison result.</p>
      <br/>
      <h2>Method Signature:</h2>
      <pre><code>public int compareTo(String anotherString)</code></pre>

      <h3>Parameters:</h3>
      <ul>
        <li><strong>anotherString</strong>: The string to be compared with the current string (<code>this</code>).</li><br/>
      </ul><br/>

      <h3>Return Value:</h3>
      <ul>
        <li><strong>0</strong>: If the two strings are equal (they have the same characters in the same order).</li><br/>
        <li><strong>A negative number</strong>: If the current string (<code>this</code>) is lexicographically less than the string being compared (<code>anotherString</code>).</li><br/>
        <li><strong>A positive number</strong>: If the current string (<code>this</code>) is lexicographically greater than the string being compared (<code>anotherString</code>).</li>
      </ul><br/>

      <h3>Lexicographical Order:</h3>
      <p>Lexicographical order is based on the Unicode value of each character in the strings. It compares characters one by one, starting from the first character of both strings. The comparison stops as soon as a difference is found or the end of the strings is reached.</p>

      <h2>Example:</h2>
      <pre>
        <code>
{`public class Main {
    public static void main(String[] args) {
        String str1 = "apple";
        String str2 = "banana";
        String str3 = "apple";

        // Comparing str1 and str2
        System.out.println(str1.compareTo(str2)); // Output: Negative number (apple < banana)

        // Comparing str1 and str3
        System.out.println(str1.compareTo(str3)); // Output: 0 (apple == apple)

        // Comparing str2 and str1
        System.out.println(str2.compareTo(str1)); // Output: Positive number (banana > apple)
    }
}`}
        </code>
      </pre>

      <h3>Output:</h3>
      <pre>
        <code>
{`-1      // Because "apple" comes before "banana" in lexicographical order.
0       // Because both strings are the same: "apple".
1       // Because "banana" comes after "apple" in lexicographical order.`}
        </code>
      </pre><br/>

      <h2>How <code>compareTo()</code> Works:</h2>
      <h3>1. First Character Comparison:</h3>
      <p>If the first characters of the two strings are different, the method returns the difference in their Unicode values.</p>
      <p>For example, for the strings <code>"apple"</code> and <code>"banana"</code>, the first characters are <code>'a'</code> (Unicode 97) and <code>'b'</code> (Unicode 98). The result will be <code>97 - 98 = -1</code> (negative).</p>
      <br/>
      <h3>2. If Characters Are Equal:</h3>
      <p>The method continues comparing characters in order. If all characters are the same but the strings have different lengths, the shorter string is considered lexicographically less.</p>
      <p>For example, <code>"apple"</code> and <code>"apple pie"</code>:</p>
      <ul>
        <li>The first five characters are the same: <code>'a'</code>, <code>'p'</code>, <code>'p'</code>, <code>'l'</code>, <code>'e'</code>.</li><br/>
        <li>Since <code>"apple"</code> is shorter, it is considered less than <code>"apple pie"</code>, so <code>compareTo()</code> will return a negative value.</li>
      </ul><br/>

      <h3>3. If All Characters Are Identical:</h3>
      <p>If the strings are identical, <code>compareTo()</code> will return <code>0</code>.</p>
      <br/>
      <h2>Important Considerations:</h2>
      <ul>
        <li><strong>Case Sensitivity</strong>: <code>compareTo()</code> is case-sensitive. For example, <code>"apple"</code> is considered less than <code>"Apple"</code> because <code>'a'</code> has a higher Unicode value than <code>'A'</code>.</li><br/>
        <li><strong>Unicode Values</strong>: The method uses Unicode values to compare characters, which means that the lexicographical order follows the Unicode table.</li>
      </ul><br/>

      <h3>Example with Case Sensitivity:</h3>
      <pre>
        <code>
{`public class Main {
    public static void main(String[] args) {
        String str1 = "apple";
        String str2 = "Apple";
        
        // Comparing case-sensitive strings
        System.out.println(str1.compareTo(str2));  // Output: Positive number (because 'a' > 'A')
    }
}`}
        </code>
      </pre>

      <h3>Output:</h3>
      <pre>
        <code>
{`32      // Because 'a' has a greater Unicode value than 'A'.`}
        </code>
      </pre><br/>

      <h2>Summary:</h2>
      <ul>
        <li>The <code>compareTo()</code> method compares two strings lexicographically.</li><br/>
        <li>It returns:
          <ul>
            <li><code>0</code> if the strings are equal.</li><br/>
            <li>A negative number if the current string is lexicographically less.</li><br/>
            <li>A positive number if the current string is lexicographically greater.</li>
          </ul>
        </li><br/>
        <li>The method compares characters based on their Unicode values, and is case-sensitive.</li>
      </ul>
    </div>
  )}


{selectedChapter === 'chapter74' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  String concat()  </h1>

    <p>
      The <code>concat()</code> method in Java is used to combine (concatenate) two strings together into one. It is a method of the <code>String</code> class and returns a new string that is the result of joining two strings.
    </p><br/>

    <h3>Method Signature:</h3>
    <pre><code>public String concat(String str)</code></pre>

    <h3>Parameters:</h3>
    <ul>
      <li><strong>str</strong>: The string that you want to concatenate to the original string.</li>
    </ul><br/>

    <h3>Return Value:</h3>
    <p>
      The method returns a new string that is a combination of the original string and the string passed as the parameter.
    </p><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Immutability of Strings</strong>: Strings in Java are immutable, meaning that when you use <code>concat()</code>, the original string is not modified. Instead, a new string is created with the combined value.</li><br/>
      <li><strong>String Concatenation</strong>: This method is often used for combining strings in a simple and readable way.</li>
    </ul><br/>

    <h3>Example:</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str1 = "Hello";
        String str2 = " World!";
        String result = str1.concat(str2);
        System.out.println(result);  // Output: Hello World!
    }
}`}</code></pre>

    <h3>Output:</h3>
    <pre><code>Hello World!</code></pre>

    <p>
      In this example, <code>"Hello"</code> and <code>" World!"</code> are concatenated to produce <code>"Hello World!"</code>.
    </p><br/>

    <h3>Important Notes:</h3>
    <ul>
      <li><strong>Using + for Concatenation:</strong> In Java, you can also concatenate strings using the <code>+</code> operator. However, <code>concat()</code> is typically used when you want to clearly express the intent of combining strings.</li><br/>
      <pre><code>String result = str1 + str2;</code></pre>

      <li><strong>Efficiency Considerations:</strong> If you need to concatenate strings in a loop or repeatedly, consider using <code>StringBuilder</code> or <code>StringBuffer</code> instead, as these classes are more efficient for such tasks.</li><br/>
      <pre><code>{`public class Main {
    public static void main(String[] args) {
        StringBuilder sb = new StringBuilder();
        sb.append("Hello");
        sb.append(" World!");
        System.out.println(sb.toString());  // Output: Hello World!
    }
}`}</code></pre>
    </ul><br/>

    <h3>Summary:</h3>
    <ul>
      <li><code>concat(String str)</code> combines the current string with another string.</li><br/>
      <li>It returns a new string with the two strings joined together.</li><br/>
      <li>It does not modify the original string, as strings are immutable in Java.</li>
    </ul>
  </div>
)}



{selectedChapter === 'chapter75' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String contains() </h1>

    <p>The <code>contains()</code> method in Java is used to check if a specified sequence of characters or substring exists within a given string. It returns a boolean value indicating whether the substring is found within the string.</p>
    <br/>
    <h2>Method Signature:</h2>
    <pre>
      <code>public boolean contains(CharSequence sequence)</code>
    </pre><br/>

    <h3>Parameters:</h3>
    <ul>
      <li><strong>sequence</strong>: The sequence of characters to search for in the string. It can be a string or a sequence of characters that you want to check for.</li>
    </ul><br/>

    <h3>Return Value:</h3>
    <ul>
      <li><strong>true</strong>: If the specified sequence of characters exists within the string.</li><br/>
      <li><strong>false</strong>: If the specified sequence of characters does not exist within the string.</li>
    </ul><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Case Sensitivity</strong>: The <code>contains()</code> method is case-sensitive. If you search for a substring with a different case than in the original string, it will return <code>false</code>.</li><br/>
      <li><strong>Null Input</strong>: If the input sequence is <code>null</code>, the method throws a <code>NullPointerException</code>.</li><br/>
      <li><strong>Immutability of Strings</strong>: Strings are immutable in Java, which means that the original string is not modified by this method. It simply checks for the presence of the substring.</li>
    </ul><br/>

    <h3>Example 1: Basic Usage</h3>
    <pre>
      <code>
{`public class Main {
  public static void main(String[] args) {
    String str = "Hello, World!";
    
    // Check if the string contains "World"
    System.out.println(str.contains("World")); // Output: true
    
    // Check if the string contains "world" (case-sensitive)
    System.out.println(str.contains("world")); // Output: false
  }
}`}
      </code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>
{`true
false`}
      </code>
    </pre><br/>

    <p>In this example:</p>
    <ul>
      <li>The first check returns <code>true</code> because <code>"World"</code> exists in the string <code>"Hello, World!"</code>.</li><br/>
      <li>The second check returns <code>false</code> because <code>"world"</code> with a lowercase 'w' does not match <code>"World"</code> (case-sensitive comparison).</li>
    </ul><br/>

    <h3>Example 2: Using contains() with Different Substrings</h3>
    <pre>
      <code>
{`public class Main {
  public static void main(String[] args) {
    String str = "Java programming is fun";
    
    // Check if the string contains "Java"
    System.out.println(str.contains("Java")); // Output: true
    
    // Check if the string contains "python"
    System.out.println(str.contains("python")); // Output: false
    
    // Check if the string contains "programming"
    System.out.println(str.contains("programming")); // Output: true
  }
}`}
      </code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>
{`true
false
true`}
      </code>
    </pre><br/>

    <p>In this example:</p>
    <ul>
      <li>The method checks if substrings such as <code>"Java"</code> and <code>"programming"</code> are present in the string <code>"Java programming is fun"</code>, returning <code>true</code> for both.</li><br/>
      <li>It returns <code>false</code> for <code>"python"</code> because that substring doesn't exist in the string.</li>
    </ul><br/>

    <h3>Example 3: Null Input Handling</h3>
    <pre>
      <code>
{`public class Main {
  public static void main(String[] args) {
    String str = "Java programming is fun";
    
    // This will throw a NullPointerException because the argument is null
    System.out.println(str.contains(null)); // Throws NullPointerException
  }
}`}
      </code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>
{`Exception in thread "main" java.lang.NullPointerException`}
      </code>
    </pre>

    <p>If the input <code>sequence</code> is <code>null</code>, the <code>contains()</code> method will throw a <code>NullPointerException</code>.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li><code>contains(CharSequence sequence)</code> is used to check if a substring exists within a string.</li><br/>
      <li>It returns <code>true</code> if the substring is present and <code>false</code> otherwise.</li><br/>
      <li>The method is case-sensitive and works on <code>String</code> objects or any object that implements <code>CharSequence</code>.</li><br/>
      <li>It is commonly used for substring search operations in Java.</li>
    </ul>

  </div>
)}




{selectedChapter === 'chapter76' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  String endsWith()  </h1>

    <p>
      The <code>endsWith()</code> method in Java is used to check if a string ends with a specified suffix. This method is part of the <code>String</code> class and returns a boolean value indicating whether the string ends with the given sequence of characters.
    </p><br/>

    <h2>Method Signature:</h2>
    <pre><code>public boolean endsWith(String suffix)</code></pre>
    <br/>
    <h2>Parameters:</h2>
    <ul>
      <li><strong>suffix</strong>: The suffix to check for. It is the sequence of characters that you want to check at the end of the string.</li>
    </ul><br/>

    <h2>Return Value:</h2>
    <ul>
      <li><strong>true</strong>: If the string ends with the specified suffix.</li><br/>
      <li><strong>false</strong>: If the string does not end with the specified suffix.</li>
    </ul><br/>

    <h2>Key Points:</h2>
    <ol>
      <li><strong>Case Sensitivity</strong>: The <code>endsWith()</code> method is <strong>case-sensitive</strong>. If the suffix has a different case than the ending characters of the string, it will return <code>false</code>.</li><br/>
      <li><strong>Null Input</strong>: If the input <code>suffix</code> is <code>null</code>, the method throws a <code>NullPointerException</code>.</li><br/>
      <li><strong>String Immutability</strong>: Strings are immutable in Java. The method does not modify the original string but simply returns a boolean indicating whether the string ends with the specified suffix.</li>
    </ol><br/>

    <h2>Example 1: Basic Usage</h2>
    <pre><code>{`public class Main {
          public static void main(String[] args) {
              String str1 = "Hello, World!";

              // Check if the string ends with "World!"
              System.out.println(str1.endsWith("World!")); // Output: true

              // Check if the string ends with "world!" (case-sensitive)
              System.out.println(str1.endsWith("world!")); // Output: false
          }
      }`}</code></pre>

    <h2>Output:</h2>
    <pre>
      true
      false
    </pre><br/>

    <h2>Explanation:</h2>
    <ul>
      <li>The first check returns <strong>true</strong> because the string <code>"Hello, World!"</code> ends with the suffix <code>"World!"</code>.</li><br/>
      <li>The second check returns <strong>false</strong> because of case sensitivity—<code>"world!"</code> with a lowercase "w" does not match the string's ending <code>"World!"</code>.</li>
    </ul><br/>

    <h2>Example 2: Using endsWith() with Different Suffixes</h2>
    <pre><code>{`public class Main {
          public static void main(String[] args) {
              String str = "Java programming is fun";

              // Check if the string ends with "fun"
              System.out.println(str.endsWith("fun")); // Output: true

              // Check if the string ends with "programming"
              System.out.println(str.endsWith("programming")); // Output: false

              // Check if the string ends with "is"
              System.out.println(str.endsWith("is")); // Output: false
          }
}`}</code></pre>

    <h2>Output:</h2>
    <pre>
      true
      false
      false
    </pre><br/>

    <h2>Explanation:</h2>
    <ul>
      <li>The first check returns <strong>true</strong> because the string <code>"Java programming is fun"</code> ends with <code>"fun"</code>.</li><br/>
      <li>The second and third checks return <strong>false</strong> because the string does not end with <code>"programming"</code> or <code>"is"</code>.</li>
    </ul><br/>

    <h2>Example 3: Null Input Handling</h2>
    <pre><code>{`public class Main {
          public static void main(String[] args) {
              String str = "Java programming is fun";

              // This will throw a NullPointerException because the argument is null
              System.out.println(str.endsWith(null)); // Throws NullPointerException
          }
 }`}</code></pre>

    <h2>Output:</h2>
    <pre>
      Exception in thread "main" java.lang.NullPointerException
    </pre><br/>

    <h2>Explanation:</h2>
    <ul>
      <li>If the input <code>suffix</code> is <code>null</code>, the <code>endsWith()</code> method will throw a <code>NullPointerException</code>.</li>
    </ul><br/>

    <h2>Summary:</h2>
    <ul>
      <li>The <code>endsWith(String suffix)</code> method is used to check if a string ends with a specified suffix.</li><br/>
      <li>It returns <strong>true</strong> if the string ends with the given suffix and <strong>false</strong> otherwise.</li><br/>
      <li>It is case-sensitive, so <code>"hello".endsWith("HELLO")</code> would return <strong>false</strong>.</li><br/>
      <li>It is commonly used for tasks like validating file extensions, checking URL patterns, etc.</li>
    </ul><br/>

    <h2>Practical Example:</h2>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String filename = "document.txt";

        // Check if the file ends with ".txt"
        if (filename.endsWith(".txt")) {
            System.out.println("This is a text file.");
        } else {
            System.out.println("This is not a text file.");
        }
    }
}`}</code></pre>

    <h2>Output:</h2>
    <pre>
      This is a text file.
    </pre><br/>

    <p>
      This method is very useful for scenarios where you need to validate whether a string ends with a particular sequence, such as file extensions, specific patterns, or keywords in a string.
    </p>
  </div>
)}


{selectedChapter === 'chapter77' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String equals()  </h1>

    <p>The <code>equals()</code> method in Java is used to compare two strings for <strong>equality</strong>. It checks if the two strings have the same sequence of characters. This method is case-sensitive, meaning it will return <code>false</code> if the characters in the strings are the same but have different cases (e.g., uppercase vs lowercase).</p>
    <br/>
    <h3>Method Signature:</h3>
    <pre><code>public boolean equals(Object obj)</code></pre><br/>

    <h3>Parameters:</h3>
    <ul>
      <li><strong>obj</strong>: The object to compare the string with. It can be another string or any other object.</li>
    </ul><br/>

    <h3>Return Value:</h3>
    <ul>
      <li><strong>true</strong>: If the two strings are equal (i.e., they have the same characters in the same order).</li><br/>
      <li><strong>false</strong>: If the two strings are not equal.</li>
    </ul><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Case Sensitivity</strong>: The <code>equals()</code> method is <strong>case-sensitive</strong>, so <code>"abc".equals("ABC")</code> will return <code>false</code> because the case of the letters is different.</li><br/>
      <li><strong>Null Check</strong>: If the object passed as a parameter is <code>null</code>, <code>equals()</code> will return <code>false</code>.</li><br/>
      <li><strong>String Immutability</strong>: Strings in Java are immutable, meaning the original strings are not modified when you compare them with <code>equals()</code>.</li>
    </ul><br/>

    <h3>Example 1: Basic Usage</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str1 = "Hello";
        String str2 = "Hello";
        String str3 = "hello";

        // Comparing str1 and str2
        System.out.println(str1.equals(str2)); // Output: true

        // Comparing str1 and str3
        System.out.println(str1.equals(str3)); // Output: false
    }
}`} </code></pre>

    <h3>Output:</h3>
    <pre>
true
false
    </pre><br/>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li>The first comparison returns <code>true</code> because both <code>str1</code> and <code>str2</code> are <code>"Hello"</code>, and they are exactly the same.</li><br/>
      <li>The second comparison returns <code>false</code> because <code>str1</code> is <code>"Hello"</code>, and <code>str3</code> is <code>"hello"</code>, but the cases of the letters are different.</li>
    </ul><br/>

    <h3>Example 2: Case Sensitivity</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str1 = "Java";
        String str2 = "java";

        // Comparing with different cases
        System.out.println(str1.equals(str2)); // Output: false
    }
}`}</code></pre>

    <h3>Output:</h3>
    <pre>
false
    </pre>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li>Here, <code>"Java"</code> and <code>"java"</code> are compared. Since <code>equals()</code> is case-sensitive, it returns <code>false</code> because the letter 'J' in <code>str1</code> is uppercase, while 'j' in <code>str2</code> is lowercase.</li>
    </ul><br/>

    <h3>Example 3: Null Check</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello";

        // Comparing with null
        System.out.println(str.equals(null)); // Output: false
    }
}`} </code></pre>

    <h3>Output:</h3>
    <pre>
false
    </pre>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li>When you try to compare a string with <code>null</code>, the <code>equals()</code> method returns <code>false</code> since a string cannot be equal to <code>null</code>.</li>
    </ul><br/>

    <h3>Example 4: Comparing Different Objects</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Java";

        // Comparing a string with an object of another class
        Object obj = new Object();
        System.out.println(str.equals(obj)); // Output: false
    }
}`} </code></pre>

    <h3>Output:</h3>
    <pre>
false
    </pre>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li>In this case, you are comparing a <code>String</code> object with an <code>Object</code> object. Since they are of different types, the <code>equals()</code> method returns <code>false</code>.</li>
    </ul><br/>

    <h3>Summary:</h3>
    <ul>
      <li>The <code>equals()</code> method is used to compare two strings for equality.</li><br/>
      <li>It returns <code>true</code> if the strings have the same characters in the same order and <code>false</code> if they do not.</li><br/>
      <li>The comparison is <strong>case-sensitive</strong>.</li><br/>
      <li>It is used for checking exact matches between strings in Java, making it ideal for tasks like password validation, string matching, etc.</li><br/>
      <li>If you want a case-insensitive comparison, you can use the <code>equalsIgnoreCase()</code> method instead.</li>
    </ul><br/>

    <h3>Example using <code>equalsIgnoreCase()</code>:</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str1 = "Java";
        String str2 = "java";

        // Comparing strings ignoring case sensitivity
        System.out.println(str1.equalsIgnoreCase(str2));  // Output: true
    }
}`} </code></pre><br/>

    <p>This method helps when you don't want the comparison to be affected by the letter casing.</p>
  </div>
)}



{selectedChapter === 'chapter78' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String format()   </h1>

    <p>
      The <code>String format()</code> method in Java is used to create a formatted string by replacing placeholders in a template with actual values. It is a powerful tool for constructing strings with dynamic content. This method works similarly to how <code>printf</code> works in C, allowing you to format strings with specific types of data.
    </p><br/>

    <h2>Method Signature:</h2>
    <pre><code>public static String format(String format, Object... args)</code></pre>
    <br/>
    <h2>Parameters:</h2>
    <ul>
      <li><strong>format</strong>: The format string containing placeholders, which define how the final string should be constructed.</li><br/>
      <li><strong>args</strong>: The arguments that will replace the placeholders in the format string. You can pass any number of arguments, and they will be inserted into the format string in the order they appear.</li>
    </ul><br/>

    <h2>Return Value:</h2>
    <p>The method returns a new string with the placeholders replaced by the corresponding arguments.</p>
    <br/>
    <h2>Key Points:</h2>
    <ol>
      <li><strong>Placeholders:</strong> Placeholders in the format string are marked with a % symbol followed by a character indicating the type of value to be inserted (e.g., <code>%s</code> for string, <code>%d</code> for integer).</li><br/>
      <li><strong>Types of Placeholders:</strong>
        <ul>
          <li><code>%s</code>: For a string.</li><br/>
          <li><code>%d</code>: For an integer.</li><br/>
          <li><code>%f</code>: For floating-point numbers.</li><br/>
          <li><code>%x</code>: For hexadecimal representation of integers.</li><br/>
          <li><code>%t</code>: For dates and times.</li>
        </ul>
      </li><br/>
      <li><strong>Argument Order:</strong> The arguments passed to the <code>format()</code> method are inserted into the string in the same order as the placeholders appear.</li><br/>
      <li><strong>Locale Support:</strong> You can also specify a locale for formatting (useful for number and date formatting in different languages).</li>
    </ol><br/>

    <h2>Example 1: Basic Usage</h2>
    <pre><code>
{`public class Main {
    public static void main(String[] args) {
        String name = "Alice";
        int age = 30;
        
        // Formatting a string with a name and age
        String formattedString = String.format("Hello, my name is %s and I am %d years old.", name, age);
        
        System.out.println(formattedString); // Output: Hello, my name is Alice and I am 30 years old.
    }
}`}
    </code></pre>
    <p>Output: <code>Hello, my name is Alice and I am 30 years old.</code></p>
    <br/>
    <h2>Example 2: Formatting Floating-Point Numbers</h2>
    <pre><code>
{`public class Main {
    public static void main(String[] args) {
        double price = 19.99;
        
        // Formatting a floating-point number to two decimal places
        String formattedString = String.format("The price is %.2f", price);
        
        System.out.println(formattedString); // Output: The price is 19.99
    }
}`}
    </code></pre>
    <p>Output: <code>The price is 19.99</code></p>
    <br/>
    <h2>Example 3: Formatting with Multiple Arguments</h2>
    <pre><code>
{`public class Main {
    public static void main(String[] args) {
        String name = "John";
        int score = 95;
        double percentage = 92.5;
        
        // Formatting multiple arguments
        String formattedString = String.format("%s scored %d out of 100, with a percentage of %.2f%%.", name, score, percentage);
        
        System.out.println(formattedString); // Output: John scored 95 out of 100, with a percentage of 92.50%.
    }
}`}
    </code></pre>
    <p>Output: <code>John scored 95 out of 100, with a percentage of 92.50%.</code></p>
    <br/>
    <h2>Example 4: Using String.format() for Padding</h2>
    <pre><code>
{`public class Main {
    public static void main(String[] args) {
        int number = 42;
        
        // Formatting a number with leading zeros (total length 5)
        String formattedString = String.format("%05d", number);
        
        System.out.println(formattedString); // Output: 00042
    }
}`}
    </code></pre>
    <p>Output: <code>00042</code></p>
    <br/>
    <h2>Example 5: Using Date Formatting</h2>
    <pre><code>
{`import java.util.Date;

public class Main {
    public static void main(String[] args) {
        Date currentDate = new Date();
        
        // Formatting the current date
        String formattedString = String.format("Current date and time: %1$tF %1$tT", currentDate);
        
        System.out.println(formattedString); // Output: Current date and time: 2024-11-26 14:30:15
    }
}`}
    </code></pre>
    <p>Output: <code>Current date and time: 2024-11-26 14:30:15</code></p>
    <br/>
    <h2>Summary:</h2>
    <ul>
      <li>The <code>String.format()</code> method is a powerful way to create formatted strings by inserting values into a template.</li><br/>
      <li>It supports various types of placeholders (for strings, integers, floating-point numbers, dates, etc.).</li><br/>
      <li>It can be used to control the formatting of numbers, text alignment, and date/time values, making it useful for generating reports, user messages, and logs.</li><br/>
      <li>For case-insensitive formatting or localized formatting, you can also use <code>String.format(Locale locale, String format, Object... args)</code>.</li>
    </ul>
  </div>
)}


{selectedChapter === 'chapter79' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String getBytes() </h1>

    <p>The <code>String getBytes()</code> method in Java is used to encode a string into a sequence of bytes using the platform's default character encoding (or a specified encoding if provided). This method is helpful when you need to convert a string to a byte array, for example, when dealing with file I/O, network transmission, or encoding conversions.</p>
    <br/>
    <h3>Method Signature:</h3>
    <pre><code>{`
      public byte[] getBytes()
      public byte[] getBytes(String charsetName) throws UnsupportedEncodingException
    `}</code></pre>
<br/>
    <h3>Parameters:</h3>
    <ul>
      <li><strong>charsetName</strong> (optional): The name of the character encoding to be used (e.g., <code>"UTF-8"</code>, <code>"ISO-8859-1"</code>, etc.). If no encoding is specified, the default platform encoding is used.</li>
    </ul>
    <br/>
    <h3>Return Value:</h3>
    <p>The method returns a byte array that represents the string encoded using the specified charset.</p>
    <br/>
    <h3>Key Points:</h3>
    <ul>
      <li><strong>Default Encoding:</strong> When the method is called without a charset, it uses the platform's default character encoding (e.g., UTF-8 on most systems).</li><br/>
      <li><strong>Specified Encoding:</strong> When you specify a charset (e.g., <code>"UTF-8"</code>), the method encodes the string using that encoding.</li><br/>
      <li><strong>Throws Exception:</strong> If the specified charset is unsupported, the <code>getBytes()</code> method with the <code>charsetName</code> argument throws an <code>UnsupportedEncodingException</code>.</li>
    </ul><br/>

    <h3>Example 1: Using Default Encoding</h3>
    <pre><code>{`import java.util.Arrays;

public class Main {
    public static void main(String[] args) {
        String str = "Hello, World!";
        
        // Convert string to bytes using default encoding
        byte[] byteArray = str.getBytes();
        
        // Print the byte array
        System.out.println("Byte array: " + Arrays.toString(byteArray));
    }
}`}</code></pre>

    <h4>Output:</h4>
    <pre><code>
      Byte array: [72, 101, 108, 108, 111, 44, 32, 87, 111, 114, 108, 100, 33]
    </code></pre>

    <p><strong>Explanation:</strong><br />
    Each number in the byte array corresponds to the ASCII value of the characters in the string "Hello, World!". The default encoding is used, which is usually UTF-8 (but can vary based on your system settings).</p>
    <br/>
    <h3>Example 2: Using a Specified Charset (UTF-8)</h3>
    <pre><code>{`import java.io.UnsupportedEncodingException;
import java.util.Arrays;

public class Main {
    public static void main(String[] args) throws UnsupportedEncodingException {
        String str = "Hello, World!";
        
        // Convert string to bytes using UTF-8 encoding
        byte[] byteArray = str.getBytes("UTF-8");
        
        // Print the byte array
        System.out.println("Byte array using UTF-8 encoding: " + Arrays.toString(byteArray));
    }
}`}</code></pre>

    <h4>Output:</h4>
    <pre><code>
      Byte array using UTF-8 encoding: [72, 101, 108, 108, 111, 44, 32, 87, 111, 114, 108, 100, 33]
    </code></pre>

    <p><strong>Explanation:</strong><br />
    The <code>getBytes("UTF-8")</code> method encodes the string using the UTF-8 encoding. The output byte array is the same as in the previous example because UTF-8 is commonly used for encoding ASCII characters.</p>
    <br/>
    <h3>Example 3: Using a Different Charset (ISO-8859-1)</h3>
    <pre><code>{`import java.io.UnsupportedEncodingException;
import java.util.Arrays;

public class Main {
    public static void main(String[] args) throws UnsupportedEncodingException {
        String str = "Hello, World!";
        
        // Convert string to bytes using ISO-8859-1 encoding
        byte[] byteArray = str.getBytes("ISO-8859-1");
        
        // Print the byte array
        System.out.println("Byte array using ISO-8859-1 encoding: " + Arrays.toString(byteArray));
    }
}`}</code></pre>

    <h4>Output:</h4>
    <pre><code>
      Byte array using ISO-8859-1 encoding: [72, 101, 108, 108, 111, 44, 32, 87, 111, 114, 108, 100, 33]
    </code></pre>

    <p><strong>Explanation:</strong><br />
    The <code>getBytes("ISO-8859-1")</code> method encodes the string using the ISO-8859-1 (Latin-1) character encoding. The byte array is identical to the previous examples because all the characters in "Hello, World!" are part of the ISO-8859-1 character set.</p>
    <br/>
    <h3>Example 4: Unsupported Encoding</h3>
    <pre><code>{`import java.io.UnsupportedEncodingException;

public class Main {
    public static void main(String[] args) {
        String str = "Hello, World!";
        try {
            // Attempt to use an unsupported encoding
            byte[] byteArray = str.getBytes("UnsupportedEncoding");
        } catch (UnsupportedEncodingException e) {
            System.out.println("Error: " + e.getMessage());
        }
    }
}`}</code></pre>

    <h4>Output:</h4>
    <pre><code>
      Error: UnsupportedEncoding
    </code></pre>

    <p><strong>Explanation:</strong><br />
    This example attempts to use an unsupported encoding (<code>"UnsupportedEncoding"</code>), which throws an <code>UnsupportedEncodingException</code>.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li>The <code>getBytes()</code> method is useful for converting a string into a byte array.</li><br/>
      <li>You can use it with the platform's default encoding or specify a particular encoding (like UTF-8 or ISO-8859-1).</li><br/>
      <li>The method is commonly used when working with byte-based I/O or network communication where encoding is important.</li><br/>
      <li>Be cautious of encoding mismatches when transferring data across different systems or platforms, as it can lead to unexpected results.</li>
    </ul>

   
  </div>
)}




{selectedChapter === 'chapter80' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  String `getChars()`  </h1>

        
        <p>
          The <code>String getChars()</code> method in Java is used to copy characters from a string into an array of characters (<code>char[]</code>).
          It allows you to extract a specific range of characters from a string and place them into a character array. This method is useful when you want to process or manipulate parts of a string individually.
        </p><br/>
        
        <h3>Method Signature:</h3>
        <pre>
          <code>
            public void getChars(int srcBegin, int srcEnd, char[] dst, int dstBegin)
          </code>
        </pre><br/>
        
        <h3>Parameters:</h3>
        <ul>
          <li><b>srcBegin</b>: The starting index (inclusive) from which characters are copied.</li><br/>
          <li><b>srcEnd</b>: The ending index (exclusive) where copying stops.</li><br/>
          <li><b>dst</b>: The character array where the extracted characters will be placed.</li><br/>
          <li><b>dstBegin</b>: The starting position in the destination array (<code>dst</code>) where the copied characters should be placed.</li>
        </ul><br/>

        <h3>Return Value:</h3>
        <p>The method does not return any value. It modifies the <code>dst</code> array by copying the characters from the source string into it.</p>
        <br/>
        <h3>Key Points:</h3>
        <ol>
          <li><b>Source String Range:</b> The characters are copied from the string starting at index <code>srcBegin</code> and ending just before <code>srcEnd</code>.</li><br/>
          <li><b>Destination Array:</b> The <code>dst</code> array should be large enough to hold the specified range of characters.</li><br/>
          <li><b>Array Indexing:</b> The characters are copied to the <code>dst</code> array starting from the index <code>dstBegin</code>.</li>
        </ol><br/>

        <h3>Example 1: Basic Usage of <code>getChars()</code></h3>
        <pre>
          <code>
            {`public class Main {
  public static void main(String[] args) {
    String str = "Hello, World!";
    char[] charArray = new char[5]; // Array to hold copied characters

    // Copy characters from index 7 to 12 of the string into the charArray
    str.getChars(7, 12, charArray, 0);

    // Print the character array
    System.out.println(charArray); // Output: World
  }
}`}
          </code>
        </pre>

        <h3>Output:</h3>
        <pre><code>World</code></pre>

        <p><strong>Explanation:</strong> The method copies characters from the string starting from index 7 ('W') to index 12 (exclusive). It places the copied characters into the <code>charArray</code>, starting from index 0.</p>
        <br/>
        <h3>Example 2: Copying a Substring to a Specific Position in the Array</h3>
        <pre>
          <code>
            {`public class Main {
  public static void main(String[] args) {
    String str = "Java Programming";
    char[] charArray = new char[15]; // Array large enough to hold the characters

    // Copy characters from index 0 to 4 of the string into charArray starting at index 5
    str.getChars(0, 5, charArray, 5);

    // Print the character array
    System.out.println(charArray); // Output:      Java
  }
}`}
          </code>
        </pre>

        <h3>Output:</h3>
        <pre><code>     Java</code></pre>

        <p><strong>Explanation:</strong> The method copies the first 5 characters of the string <code>str</code> ("Java") and places them starting from index 5 in the <code>charArray</code>. The output shows the space-filled positions before "Java".</p>
        <br/>
        <h3>Example 3: Handling Index Out of Bounds</h3>
        <pre>
          <code>
            {`public class Main {
  public static void main(String[] args) {
    String str = "Hello, Java!";
    char[] charArray = new char[5];

    try {
      // Attempt to copy characters beyond the end of the string
      str.getChars(7, 15, charArray, 0);  // This will cause an exception
    } catch (StringIndexOutOfBoundsException e) {
      System.out.println("Error: " + e.getMessage()); // Output: Error: String index out of range: 15
    }
  }
}`}
          </code>
        </pre>

        <h3>Output:</h3>
        <pre><code>Error: String index out of range: 15</code></pre>

        <p><strong>Explanation:</strong> The <code>getChars()</code> method throws a <code>StringIndexOutOfBoundsException</code> if the <code>srcEnd</code> index is greater than the length of the string.</p>
        <br/>
        <h3>Summary:</h3>
        <ul>
          <li>The <code>getChars()</code> method is used to extract a range of characters from a string and place them into a character array.</li><br/>
          <li>You must specify a valid range of indices (<code>srcBegin</code> to <code>srcEnd</code>) and ensure that the destination array has enough space to accommodate the characters.</li><br/>
          <li>This method doesn't return any value; it directly modifies the provided destination array.</li><br/>
          <li>It's commonly used when you need to manipulate a substring or extract specific parts of a string for further processing.</li>
        </ul>

       
      </div>
    )}



{selectedChapter === 'chapter81' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String `indexOf()` </h1>

    <p>The `indexOf()` method in Java is used to find the index of the first occurrence of a specified character or substring within a string. This method returns the index of the character or substring if it is found, or `-1` if it is not found.</p>
    <br/>
    <h4>Method Signatures:</h4>
    <pre>
      <code>{`
        public int indexOf(int ch)  // Search for a character 
        public int indexOf(int ch, int fromIndex)  // Search for a character starting from a specific index 
        public int indexOf(String str)  // Search for a substring <
        public int indexOf(String str, int fromIndex)  // Search for a substring starting from a specific index
      `}</code>
    </pre><br/>

    <h4>Parameters:</h4>
    <ul>
      <li><strong>`ch`</strong>: The character to search for.</li>
      <li><strong>`str`</strong>: The substring to search for.</li>
      <li><strong>`fromIndex`</strong>: The index from which to start searching. If not provided, the search starts from the beginning of the string.</li>
    </ul><br/>

    <h4>Return Value:</h4>
    <p>The method returns the index of the first occurrence of the specified character or substring. If the character or substring is not found, it returns <code>-1</code>.</p>
    <br/>
    <h4>Key Points:</h4>
    <ol>
      <li><strong>Search Direction</strong>: `indexOf()` searches from left to right in the string.</li><br/>
      <li><strong>First Occurrence</strong>: It returns the index of the first occurrence of the character or substring.</li><br/>
      <li><strong>Case-Sensitivity</strong>: The search is case-sensitive.</li><br/>
      <li><strong>Negative Return</strong>: If the character or substring is not found, the method returns <code>-1</code>.</li>
    </ol><br/>

    <h4>Example 1: Basic Usage with a Character</h4>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello, World!";
        int index = str.indexOf('o');
        System.out.println("Index of 'o': " + index);  // Output: 4
    }
}`} </code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>Index of 'o': 4</code>
    </pre>

    <h4>Explanation:</h4>
    <p>The method searches for the first occurrence of the character `'o'` in the string `"Hello, World!"`. The index of the first `'o'` is 4 (remember, indices are 0-based).</p>
    <br/>
    <h4>Example 2: Using `indexOf()` with a Substring</h4>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello, World!";
        int index = str.indexOf("World");
        System.out.println("Index of 'World': " + index);  // Output: 7
    }
}`}</code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>Index of 'World': 7</code>
    </pre>

    <h4>Explanation:</h4>
    <p>The method searches for the first occurrence of the substring `"World"` in the string `"Hello, World!"`. The index of the first `"World"` is 7.</p>
    <br/>
    <h4>Example 3: Searching from a Specific Index</h4>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello, World!";
        int index = str.indexOf('o', 5);
        System.out.println("Index of 'o' after index 5: " + index);  // Output: 8
    }
}`}</code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>Index of 'o' after index 5: 8</code>
    </pre>

    <h4>Explanation:</h4>
    <p>The method starts searching from index 5 and finds the next occurrence of the character `'o'` at index 8.</p>
    <br/>
    <h4>Example 4: When the Character or Substring is Not Found</h4>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello, World!";
        int index = str.indexOf('z');
        System.out.println("Index of 'z': " + index);  // Output: -1
    }
}`} </code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>Index of 'z': -1</code>
    </pre>

    <h4>Explanation:</h4>
    <p>The method returns `-1` because the character `'z'` is not found in the string `"Hello, World!"`.</p>
    <br/>
    <h4>Example 5: Using `indexOf()` with Substring Not Found</h4>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello, World!";
        int index = str.indexOf("Java");
        System.out.println("Index of 'Java': " + index);  // Output: -1
    }
}`}</code>
    </pre>

    <h4>Output:</h4>
    <pre>
      <code>Index of 'Java': -1</code>
    </pre>

    <h4>Explanation:</h4>
    <p>The method returns `-1` because the substring `"Java"` is not found in the string `"Hello, World!"`.</p>
    <br/>
    <h4>Summary:</h4>
    <ul>
      <li>The `indexOf()` method is used to find the index of the first occurrence of a character or substring in a string.</li><br/>
      <li>It returns the index if the character or substring is found, or `-1` if not found.</li><br/>
      <li>You can also start the search from a specific index by passing the `fromIndex` parameter.</li><br/>
      <li>The search is case-sensitive, and it returns the first occurrence of the character or substring.</li>
    </ul>
  </div>
)}



{selectedChapter === 'chapter82' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String `intern()` </h1>

    <p>
      The <code>intern()</code> method in Java is used to ensure that all strings with the same value share the same memory reference. If a string with the same value already exists in the string pool, the method returns a reference to that string from the pool. If no such string exists, it adds the string to the pool and returns the reference.
    </p><br/>

    <p>
      In simple terms, <code>intern()</code> is used to reduce memory usage by storing only one copy of each distinct string in a special memory area called the <strong>string pool</strong> (also called the <strong>string constant pool</strong>).
    </p><br/>

    <h2>Method Signature:</h2>
    <pre><code>public String intern()</code></pre><br/>

    <h2>Parameters:</h2>
    <p>No parameters are required for this method.</p>
    <br/>
    <h2>Return Value:</h2>
    <p>
      The method returns a reference to the string from the <strong>string pool</strong>. If the string already exists in the pool, it returns the reference to that existing string. If not, it adds the string to the pool and returns its reference.
    </p><br/>

    <h2>Key Points:</h2>
    <ul>
      <li><strong>String Pool</strong>: The string pool is a special storage area in the JVM for strings. It allows the reuse of string literals to save memory.</li><br/>
      <li><strong>String Literal vs <code>intern()</code></strong>: String literals are automatically added to the pool, but strings created with <code>new</code> or dynamically (like concatenation) are not added to the pool unless explicitly interned.</li><br/>
      <li><strong>Memory Efficiency</strong>: By using <code>intern()</code>, Java ensures that identical strings take up only one memory reference, improving memory efficiency when the same string value is used repeatedly.</li><br/>
      <li><strong>Comparison</strong>: The reference returned by <code>intern()</code> can be compared using <code>==</code> because it points to the same object in the string pool.</li>
    </ul><br/>

    <h2>Example 1: Basic Usage of <code>intern()</code></h2>
    <pre><code>{`public class Main {
          public static void main(String[] args) {
              String str1 = new String("Hello");
              String str2 = "Hello";
              
              // Using intern() to add str1 to the pool
              String str3 = str1.intern();
              
              // Comparing references
              System.out.println(str1 == str2);  // Output: false
              System.out.println(str2 == str3);  // Output: true
          }
}`}</code></pre>

    <h3>Output:</h3>
    <pre><code>
      false
      true
    </code></pre>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li><code>str1</code> is created using <code>new String()</code>, so it is not automatically added to the string pool.</li><br/>
      <li><code>str2</code> is a string literal, so it is already in the pool.</li><br/>
      <li>Calling <code>intern()</code> on <code>str1</code> gives a reference to the string <code>"Hello"</code> from the pool, which is the same as the reference to <code>str2</code>.</li><br/>
      <li>Hence, <code>str1 == str2</code> is <code>false</code> because they refer to different objects, but <code>str2 == str3</code> is <code>true</code> because both refer to the same string in the pool.</li>
    </ul><br/>

    <h2>Example 2: Adding a String to the Pool</h2>
    <pre><code>P{`public class Main {
          public static void main(String[] args) {
              String str1 = new String("Java");
              
              // Intern the string
              String str2 = str1.intern();
              
              // Comparing the references
              System.out.println(str1 == str2);  // Output: false
              System.out.println(str2 == "Java");  // Output: true
          }
}`} </code></pre>

    <h3>Output:</h3>
    <pre><code>
      false
      true
    </code></pre>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li><code>str1</code> is created using <code>new String()</code>, so it’s not in the string pool.</li><br/>
      <li><code>str2</code> is the interned version of <code>str1</code>, so it now refers to the string <code>"Java"</code> in the pool.</li><br/>
      <li>When comparing <code>str2</code> with the literal <code>"Java"</code>, they refer to the same string in the pool, so <code>str2 == "Java"</code> is <code>true</code>.</li>
    </ul><br/>

    <h2>Example 3: Interning Multiple Strings</h2>
    <pre><code>{`public class Main {
          public static void main(String[] args) {
              String str1 = "Interning";
              String str2 = new String("Interning");
              
              // Intern str2
              String str3 = str2.intern();
              
              // Comparing references
              System.out.println(str1 == str2);  // Output: false
              System.out.println(str1 == str3);  // Output: true
          }
}`}</code></pre>

    <h3>Output:</h3>
    <pre><code>
      false
      true
    </code></pre>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li><code>str1</code> is a string literal, so it is already in the string pool.</li><br/>
      <li><code>str2</code> is created using <code>new String()</code>, so it is not in the pool.</li><br/>
      <li>Calling <code>intern()</code> on <code>str2</code> gives a reference to the string <code>"Interning"</code> from the pool, which is the same as the reference to <code>str1</code>.</li><br/>
      <li>Therefore, <code>str1 == str3</code> is <code>true</code>, as both refer to the same string in the pool.</li>
    </ul><br/>

    <h2>Example 4: <code>intern()</code> with Non-Existing String</h2>
    <pre><code>{`public class Main {
          public static void main(String[] args) {
              String str1 = new String("NewString");
              
              // Intern the string
              String str2 = str1.intern();
              
              // Comparing with a literal
              System.out.println(str2 == "NewString");  // Output: true
          }
}`}</code></pre>

    <h3>Output:</h3>
    <pre><code>
      true
    </code></pre>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li>Since <code>"NewString"</code> is not a literal and is created dynamically, calling <code>intern()</code> adds <code>"NewString"</code> to the string pool.</li><br/>
      <li>After calling <code>intern()</code>, <code>str2</code> refers to the string <code>"NewString"</code> in the pool, which is the same as the reference of the literal <code>"NewString"</code>, making the comparison <code>true</code>.</li>
    </ul><br/>

    <h2>Summary:</h2>
    <ul>
      <li>The <code>intern()</code> method is used to ensure that a string is part of the string pool, improving memory efficiency by reusing string values.</li><br/>
      <li>String literals are automatically part of the string pool, but dynamically created strings can be added by calling <code>intern()</code>.</li><br/>
      <li>Using <code>intern()</code> helps in situations where you expect many identical strings and want to minimize memory usage by reusing references.</li>
    </ul>
  </div>
)}



{selectedChapter === 'chapter83' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String `isEmpty()` </h1>

    <p>The <code>isEmpty()</code> method in Java is used to check if a string is empty. It returns <code>true</code> if the string has no characters (i.e., its length is 0), and <code>false</code> otherwise.</p>
    <br/>
    <h3>Method Signature:</h3>
    <pre><code>public boolean isEmpty()</code></pre>
    <br/>
    <h3>Parameters:</h3>
    <ul>
      <li>This method does not take any parameters.</li>
    </ul><br/>

    <h3>Return Value:</h3>
    <ul>
      <li>The method returns:
        <ul>
          <li><code>true</code> if the string has no characters (i.e., its length is 0).</li><br/>
          <li><code>false</code> if the string contains one or more characters.</li>
        </ul>
      </li>
    </ul><br/>

    <h3>Key Points:</h3>
    <ol>
      <li><strong>Empty String:</strong> A string is considered empty if its length is 0. For example, the string <code>""</code> (empty string) is considered empty.</li><br/>
      <li><strong>Null vs Empty:</strong> The <code>isEmpty()</code> method only checks if the string is empty. It does <em>not</em> check if the string is <code>null</code>. To avoid <code>NullPointerException</code>, always ensure the string is not <code>null</code> before calling <code>isEmpty()</code>.</li><br/>
      <li><strong>String Length:</strong> Internally, the <code>isEmpty()</code> method checks whether the length of the string is 0 using the <code>length()</code> method. It is equivalent to checking <code>str.length() == 0</code>.</li>
    </ol><br/>

    <h3>Example 1: Basic Usage of <code>isEmpty()</code></h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str1 = "";
        String str2 = "Hello";
        
        System.out.println(str1.isEmpty());  // Output: true
        System.out.println(str2.isEmpty());  // Output: false
    }
}`}</code></pre>
    <h4>Output:</h4>
    <pre>
true
false
    </pre>

    <h3>Explanation:</h3>
    <ul>
      <li><code>str1</code> is an empty string, so <code>str1.isEmpty()</code> returns <code>true</code>.</li><br/>
      <li><code>str2</code> contains the word <code>"Hello"</code>, so <code>str2.isEmpty()</code> returns <code>false</code>.</li>
    </ul><br/>

    <h3>Example 2: Checking for an Empty String in a Conditional</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "";
        
        if (str.isEmpty()) {
            System.out.println("The string is empty.");
        } else {
            System.out.println("The string is not empty.");
        }
    }
}`}</code></pre>
    <h4>Output:</h4>
    <pre>
The string is empty.
    </pre>

    <h3>Explanation:</h3>
    <ul>
      <li>Since <code>str</code> is an empty string, the <code>if</code> statement checks <code>str.isEmpty()</code> and prints <code>"The string is empty."</code>.</li>
    </ul><br/>

    <h3>Example 3: Checking for Non-Empty Strings</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str1 = "Java";
        String str2 = "";
        
        if (!str1.isEmpty()) {
            System.out.println("str1 is not empty.");
        }
        
        if (!str2.isEmpty()) {
            System.out.println("str2 is not empty.");
        } else {
            System.out.println("str2 is empty.");
        }
    }
}`}</code></pre>
    <h4>Output:</h4>
    <pre>
str1 is not empty.
str2 is empty.
    </pre>

    <h3>Explanation:</h3>
    <ul>
      <li><code>str1</code> is not empty, so <code>!str1.isEmpty()</code> evaluates to <code>true</code>, and <code>"str1 is not empty."</code> is printed.</li><br/>
      <li><code>str2</code> is empty, so <code>str2.isEmpty()</code> returns <code>true</code>, and <code>"str2 is empty."</code> is printed.</li>
    </ul><br/>

    <h3>Example 4: Avoiding <code>NullPointerException</code></h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = null;
        
        // To avoid NullPointerException, ensure string is not null before using isEmpty()
        if (str != null && str.isEmpty()) {
            System.out.println("The string is empty.");
        } else {
            System.out.println("The string is either null or not empty.");
        }
    }
}`} </code></pre>
    <h4>Output:</h4>
    <pre>
The string is either null or not empty.
    </pre>

    <h3>Explanation:</h3>
    <ul>
      <li>Since <code>str</code> is <code>null</code>, directly calling <code>isEmpty()</code> would throw a <code>NullPointerException</code>. The check <code>str != null</code> ensures the string is not <code>null</code> before calling <code>isEmpty()</code>.</li>
    </ul><br/>

    <h3>Summary:</h3>
    <ul>
      <li>The <code>isEmpty()</code> method checks whether a string has zero characters.</li><br/>
      <li>It returns <code>true</code> if the string is empty (i.e., its length is 0), and <code>false</code> otherwise.</li><br/>
      <li>Always ensure the string is not <code>null</code> before calling <code>isEmpty()</code> to avoid exceptions.</li>
    </ul>
  </div>
)}


{selectedChapter === 'chapter84' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  String `join()`  </h1>


      <p>The `join()` method in Java is used to join multiple strings into a single string with a specified delimiter. It is a static method of the `String` class, introduced in Java 8, and is very useful for concatenating strings with a specific separator.</p>
      <br/>
      <h4>Method Signature:</h4>
      <pre>
        <code>public static String join(CharSequence delimiter, CharSequence... elements)</code>
      </pre><br/>

      <h4>Parameters:</h4>
      <ul>
        <li><strong>delimiter</strong>: This is the separator that will be inserted between the elements in the final string.</li><br/>
        <li><strong>elements</strong>: These are the strings (or `CharSequence` objects) to be joined together.</li>
      </ul><br/>

      <h4>Return Value:</h4>
      <p>The method returns a new string that consists of all the provided strings joined by the specified delimiter.</p><br/>

      <h4>Key Points:</h4>
      <ol>
        <li><strong>Delimiter</strong>: The delimiter can be any string or character that you want to place between each element when joining. For example, a comma (`,`) can be used to join the strings like in CSV formatting.</li><br/>
        <li><strong>Varargs</strong>: The `elements` parameter uses varargs, meaning you can pass any number of strings to join.</li><br/>
        <li><strong>Null Handling</strong>: If any element in the list is `null`, it is treated as the string `"null"`, not an empty string.</li><br/>
        <li><strong>StringBuilder Usage</strong>: Internally, the `join()` method uses a `StringBuilder` to concatenate the elements efficiently.</li>
      </ol><br/>

      <h4>Example 1: Basic Usage of `join()`</h4>
      <pre>
        <code>{`public class Main {
              public static void main(String[] args) {
                  String[] words = {"Java", "is", "fun"};
                  String result = String.join(" ", words);
                  System.out.println(result);  // Output: Java is fun
         }
}`}</code>
      </pre>

      <h4>Output:</h4>
      <pre><code>Java is fun</code></pre>

      <h4>Explanation:</h4>
      <p>The strings "Java", "is", and "fun" are joined using a space " " as the delimiter. The output is "Java is fun".</p>
      <br/>
      <h4>Example 2: Using a Comma as a Delimiter</h4>
      <pre>
        <code>{`public class Main {
              public static void main(String[] args) {
                  String[] fruits = {"Apple", "Banana", "Cherry"};
                  String result = String.join(", ", fruits);
                  System.out.println(result);  // Output: Apple, Banana, Cherry
       }
}`}</code>
      </pre>

      <h4>Output:</h4>
      <pre><code>Apple, Banana, Cherry</code></pre>

      <h4>Explanation:</h4>
      <p>The strings "Apple", "Banana", and "Cherry" are joined using a comma and space ", " as the delimiter. The output is "Apple, Banana, Cherry".</p>
      <br/>
      <h4>Example 3: Handling Null Values in the Elements</h4>
      <pre>
        <code>{`public class Main {
              public static void main(String[] args) {
                  String[] words = {"Java", null, "is", "fun"};
                  String result = String.join(" ", words);
                  System.out.println(result);  // Output: Java null is fun
              }
}`}</code>
      </pre>

      <h4>Output:</h4>
      <pre><code>Java null is fun</code></pre>

      <h4>Explanation:</h4>
      <p>The string `null` is treated as the string "null", not as an empty string. The output is "Java null is fun".</p><br/>

      <h4>Example 4: Using a Custom Delimiter</h4>
      <pre>
        <code>{`public class Main {
              public static void main(String[] args) {
                  String[] words = {"Java", "is", "fun"};
                  String result = String.join("-", words);
                  System.out.println(result);  // Output: Java-is-fun
         }
}`}</code>
      </pre>

      <h4>Output:</h4>
      <pre><code>Java-is-fun</code></pre>

      <h4>Explanation:</h4>
      <p>The strings "Java", "is", and "fun" are joined using a dash "-" as the delimiter. The output is "Java-is-fun".</p>
      <br/>
      <h4>Example 5: Joining Strings from a List</h4>
      <pre>
        <code>{`import java.util.List;

          public class Main {
              public static void main(String[] args) {
                  List<String> cities = List.of("New York", "Los Angeles", "Chicago");
                  String result = String.join(", ", cities);
                  System.out.println(result);  // Output: New York, Los Angeles, Chicago
         }
}`}</code>
      </pre>

      <h4>Output:</h4>
      <pre><code>New York, Los Angeles, Chicago</code></pre>

      <h4>Explanation:</h4>
      <p>The `join()` method can also be used with a `List` of strings. It works the same way as with an array.</p>
      <br/>
      <h4>Summary:</h4>
      <ul>
        <li>The `join()` method is a convenient way to concatenate strings with a delimiter.</li><br/>
        <li>It takes a delimiter and an array (or `Iterable`) of strings and joins them into one string with the specified separator.</li><br/>
        <li>It's especially useful when you need to join multiple strings or elements from a collection with a specific separator like commas, spaces, or other characters.</li>
      </ul>
    </div>
  )}


{selectedChapter === 'chapter85' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  String `lastIndexOf()` </h1>


        <p>The <code>lastIndexOf()</code> method in Java is used to find the last occurrence of a specified character or substring within a string. It returns the index (position) of the last occurrence of the character or substring. If the character or substring is not found, it returns <code>-1</code>.</p>
        <br/>
        <h3>Method Signatures:</h3>

        <h4>1. For Character:</h4>
        <pre><code>public int lastIndexOf(int ch)</code></pre>
        <p><strong>Parameters:</strong> <code>ch</code>: The character to be found.</p>
        <p><strong>Returns:</strong> The index of the last occurrence of the specified character, or <code>-1</code> if not found.</p>
        <br/>
        <h4>2. For Substring:</h4>
        <pre><code>public int lastIndexOf(String str)</code></pre>
        <p><strong>Parameters:</strong> <code>str</code>: The substring to be found.</p>
        <p><strong>Returns:</strong> The index of the last occurrence of the specified substring, or <code>-1</code> if not found.</p>
        <br/>
        <h4>3. For Substring with a start index:</h4>
        <pre><code>public int lastIndexOf(String str, int fromIndex)</code></pre>
        <p><strong>Parameters:</strong> <code>str</code>: The substring to be found. <br />
          <code>fromIndex</code>: The index from which the search should start. The search will start from <code>fromIndex</code> and go backward.</p>
        <p><strong>Returns:</strong> The index of the last occurrence of the substring, or <code>-1</code> if not found.</p>
        <br/>
        <h3>Key Points:</h3>
        <ul>
          <li><strong>Last Occurrence:</strong> The method searches for the last occurrence of the specified character or substring, starting from the end of the string.</li><br/>
          <li><strong>Negative Index:</strong> If the character or substring is not found, it returns <code>-1</code>.</li><br/>
          <li><strong>Search Direction:</strong> By default, the method searches the string from the end to the beginning.</li>
        </ul><br/>

        <h3>Example 1: Using <code>lastIndexOf()</code> to Find the Last Occurrence of a Character</h3>
        <pre>
          <code>
{`public class Main {
    public static void main(String[] args) {
        String str = "Java Programming Language";
        
        // Find the last occurrence of 'a'
        int lastIndex = str.lastIndexOf('a');
        
        System.out.println(lastIndex);  // Output: 17
    }
}`}
          </code>
        </pre>
        <p><strong>Output:</strong></p>
        <pre>17</pre>
        <p><strong>Explanation:</strong> The last occurrence of the character <code>'a'</code> is at index 17 in the string <code>"Java Programming Language"</code>.</p>
        <br/>
        <h3>Example 2: Using <code>lastIndexOf()</code> to Find the Last Occurrence of a Substring</h3>
        <pre>
          <code>
{`public class Main {
    public static void main(String[] args) {
        String str = "Java Programming Language, Java is fun";
        
        // Find the last occurrence of "Java"
        int lastIndex = str.lastIndexOf("Java");
        
        System.out.println(lastIndex);  // Output: 24
    }
}`}
          </code>
        </pre>
        <p><strong>Output:</strong></p>
        <pre>24</pre>
        <p><strong>Explanation:</strong> The last occurrence of the substring <code>"Java"</code> is at index 24 in the string <code>"Java Programming Language, Java is fun"</code>.</p>
        <br/>
        <h3>Example 3: Using <code>lastIndexOf()</code> with a Start Index</h3>
        <pre>
          <code>
{`public class Main {
    public static void main(String[] args) {
        String str = "Java Programming Language, Java is fun";
        
        // Find the last occurrence of "Java" before index 20
        int lastIndex = str.lastIndexOf("Java", 20);
        
        System.out.println(lastIndex);  // Output: 0
    }
}`}
          </code>
        </pre>
        <p><strong>Output:</strong></p>
        <pre>0</pre>
        <p><strong>Explanation:</strong> By specifying a start index of <code>20</code>, the search for the substring <code>"Java"</code> begins from index 20 and moves backward. The last occurrence of <code>"Java"</code> before index 20 is at index 0.</p>
        <br/>
        <h3>Example 4: When Substring or Character is Not Found</h3>
        <pre>
          <code>
{`public class Main {
    public static void main(String[] args) {
        String str = "Java Programming Language";
        
        // Search for the character 'z' which is not in the string
        int lastIndex = str.lastIndexOf('z');
        
        System.out.println(lastIndex);  // Output: -1
    }
}`}
          </code>
        </pre>
        <p><strong>Output:</strong></p>
        <pre>-1</pre>
        <p><strong>Explanation:</strong> Since the character <code>'z'</code> does not exist in the string <code>"Java Programming Language"</code>, the method returns <code>-1</code>.</p>
        <br/>
        <h3>Summary:</h3>
        <ul>
          <li>The <code>lastIndexOf()</code> method is used to find the last occurrence of a character or substring in a string.</li><br/>
          <li>It returns the index of the last occurrence or <code>-1</code> if not found.</li><br/>
          <li>The method can search for characters, substrings, and can even start the search from a specified index, scanning backward from the given index.</li>
        </ul>

        
      </div>
    )}


{selectedChapter === 'chapter86' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String `length()` </h1>

    <p>The <code>length()</code> method in Java is a built-in method of the <code>String</code> class, which is used to find the length (i.e., the number of characters) of a string. The method returns an integer value that represents the number of characters in the string.</p>
    <br/>
    <h3>Method Signature:</h3>
    <pre><code>public int length()</code></pre>
    <br/>
    <h3>Parameters:</h3>
    <p>The <code>length()</code> method does not take any parameters.</p>
    <br/>
    <h3>Return Value:</h3>
    <p>The method returns an integer value, which is the total number of characters present in the string. It includes spaces, special characters, and other characters that may be part of the string.</p>
    <br/>
    <h3>Key Points:</h3>
    <ul>
      <li><strong>Character Count:</strong> The <code>length()</code> method counts all characters in the string, including spaces and special characters.</li><br/>
      <li><strong>Zero-Length String:</strong> If the string is empty (i.e., <code>""</code>), the <code>length()</code> method will return <code>0</code>.</li><br/>
      <li><strong>Unicode Characters:</strong> Each character, regardless of its size in memory (such as Unicode characters), counts as one character in the string.</li>
    </ul><br/>

    <h3>Example 1: Basic Usage of <code>length()</code></h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello, World!";
        int length = str.length();
        System.out.println(length);  // Output: 13
    }
}`}</code></pre>
    <p><strong>Explanation:</strong> The string <code>"Hello, World!"</code> has 13 characters, including the space and punctuation. The output will be <code>13</code>.</p>
    <br/>
    <h3>Example 2: Empty String</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "";
        int length = str.length();
        System.out.println(length);  // Output: 0
    }
}`}</code></pre>
    <p><strong>Explanation:</strong> The string is empty, so the <code>length()</code> method returns <code>0</code>.</p>
    <br/>
    <h3>Example 3: String with Spaces and Special Characters</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello  @  World!";
        int length = str.length();
        System.out.println(length);  // Output: 16
    }
}`}</code></pre>
    <p><strong>Explanation:</strong> The string <code>"Hello  @  World!"</code> includes spaces and special characters, so the method counts all of them. The output will be <code>16</code>.</p>
    <br/>
    <h3>Example 4: String with Unicode Characters</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "こんにちは";  // Japanese greeting "Konnichiwa"
        int length = str.length();
        System.out.println(length);  // Output: 5
    }
}`}</code></pre>
    <p><strong>Explanation:</strong> The string <code>"こんにちは"</code> consists of 5 characters, each being a Unicode character. The method counts the Unicode characters individually, so it returns <code>5</code>.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li>The <code>length()</code> method in Java is a simple way to find how many characters are in a string.</li><br/>
      <li>It returns an integer representing the total number of characters, including spaces and special characters.</li><br/>
      <li>If the string is empty, it returns <code>0</code>.</li><br/>
      <li>This method is useful when you need to check the size of a string for operations such as validation, formatting, or iteration.</li>
    </ul>

   
  </div>
)}




{selectedChapter === 'chapter87' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String `replace()` </h1>

    <p>The `replace()` method in Java is a part of the `String` class, and it is used to replace occurrences of a specified character or substring in a string with another character or substring.</p>
    <br/>
    <h3>Method Signatures:</h3>

    <ul>
      <li>
        <strong>For Replacing a Character:</strong>
        <pre>
          <code>public String replace(char oldChar, char newChar)</code>
        </pre>
        <ul>
          <li><strong>Parameters:</strong>
            <ul>
              <li><code>oldChar</code>: The character to be replaced.</li><br/>
              <li><code>newChar</code>: The character to replace the old character with.</li>
            </ul>
          </li><br/>
          <li><strong>Returns:</strong> A new string with the replaced characters.</li>
        </ul>
      </li><br/>
      <li>
        <strong>For Replacing a Substring:</strong>
        <pre>
          <code>public String replace(CharSequence target, CharSequence replacement)</code>
        </pre>
        <ul>
          <li><strong>Parameters:</strong>
            <ul>
              <li><code>target</code>: The substring to be replaced.</li><br/>
              <li><code>replacement</code>: The substring to replace the target with.</li>
            </ul><br/>
          </li>
          <li><strong>Returns:</strong> A new string with the replaced substrings.</li>
        </ul>
      </li>
    </ul><br/>

    <h3>Key Points:</h3>
    <ol>
      <li><strong>Immutability of Strings:</strong> Strings in Java are immutable, meaning that the original string is not changed. Instead, a new string with the replacements is created and returned.</li><br/>
      <li><strong>No Changes for Unmatched Characters or Substrings:</strong> If the character or substring to be replaced is not found, the original string is returned unchanged.</li><br/>
      <li><strong>Case-Sensitive:</strong> The <code>replace()</code> method is case-sensitive. If you want to replace characters or substrings without considering case, you need to first convert the string to a common case using methods like <code>toLowerCase()</code> or <code>toUpperCase()</code>.</li>
    </ol><br/>

    <h3>Examples:</h3>

    <h4>Example 1: Replacing a Character</h4>
    <pre>
      <code>{`public class Main {
            public static void main(String[] args) {
                String str = "Hello, World!";
                // Replace 'o' with '0'
                String result = str.replace('o', '0');
                System.out.println(result);  // Output: Hell0, W0rld!
      }
}`} </code>
    </pre>
    <p><strong>Explanation:</strong> The method replaces all occurrences of the character <code>'o'</code> with <code>'0'</code>. The output is <code>"Hell0, W0rld!"</code>.</p>
    <br/>
    <h4>Example 2: Replacing a Substring</h4>
    <pre>
      <code>{`public class Main {
            public static void main(String[] args) {
                String str = "Java Programming Language";
                // Replace "Programming" with "Coding"
                String result = str.replace("Programming", "Coding");
                System.out.println(result);  // Output: Java Coding Language
        }
}`} </code>
    </pre>
    <p><strong>Explanation:</strong> The method replaces the substring <code>"Programming"</code> with <code>"Coding"</code>. The output is <code>"Java Coding Language"</code>.</p>
    <br/>
    <h4>Example 3: Replacing Multiple Occurrences</h4>
    <pre>
      <code>
        {`public class ReplaceMultipleExample {
            public static void main(String[] args) {
                String str = "I like Java and Java is fun";
                // Replace all occurrences of "Java" with "Python"
                String result = str.replace("Java", "Python");
                System.out.println(result);  // Output: I like Python and Python is fun
         }
}`}</code>
    </pre>
    <p><strong>Explanation:</strong> The method replaces all occurrences of <code>"Java"</code> with <code>"Python"</code>. The output is <code>"I like Python and Python is fun"</code>.</p>
    <br/>
    <h4>Example 4: Case-Sensitive Replacement</h4>
    <pre>
      <code>{`public class Main {
            public static void main(String[] args) {
                String str = "Java and java are different";
                // Replace "java" with "Python" (case-sensitive)
                String result = str.replace("java", "Python");
                System.out.println(result);  // Output: Java and Python are different
       }
}`} </code>
    </pre>
    <p><strong>Explanation:</strong> The method replaces only the lowercase <code>"java"</code> with <code>"Python"</code>, and the uppercase <code>"Java"</code> remains unchanged. The output is <code>"Java and Python are different"</code>.</p>
    <br/>
    <h4>Example 5: Replacing with Regular Expressions (Using <code>replaceAll()</code>)</h4>
    <pre>
      <code>{`public class Main {
            public static void main(String[] args) {
                String str = "I have 2 apples and 3 oranges.";
                // Replace all digits with 'X'
                String result = str.replaceAll("\\d", "X");
                System.out.println(result);  // Output: I have X apples and X oranges.
        }
}`} </code>
    </pre>
    <p><strong>Explanation:</strong> The <code>replaceAll()</code> method allows you to use regular expressions for matching patterns. Here, <code>\\d</code> is the regex for any digit. The output is <code>"I have X apples and X oranges."</code>.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li>The <code>replace()</code> method is used to replace characters or substrings in a string.</li><br/>
      <li>It is case-sensitive and returns a new string with the changes.</li><br/>
      <li>You can use it to replace all occurrences of a character or substring in a string.</li><br/>
      <li>If you need to use patterns, you can use <code>replaceAll()</code> with regular expressions.</li>
    </ul><br/>

    
  </div>
)}




{selectedChapter === 'chapter88' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String split()  </h1>
      
      <p>
        The <code>split()</code> method in Java is a part of the <code>String</code> class and is used to split a string into an array of substrings based on a specified delimiter or regular expression.
      </p><br/>
      
      <h3>Method Signature:</h3>
      <pre><code>public String[] split(String regex)</code></pre>
      <ul>
        <li><strong>Parameters:</strong> 
          <ul>
            <li><code>regex</code>: The regular expression (delimiter) that defines where the string should be split. It can be any pattern (such as a space, comma, or other special characters).</li>
          </ul><br/>
        </li>
        <li><strong>Returns:</strong> An array of strings that contains the substrings of the original string.</li>
      </ul><br/>

      <h3>Key Points:</h3>
      <ul>
        <li><strong>Delimiter:</strong> The method uses the regular expression passed as the <code>regex</code> parameter to determine the splitting point.</li><br/>
        <li><strong>Empty Strings:</strong> If the delimiter is not found, the method returns an array containing the original string as its only element.</li><br/>
        <li><strong>Multiple Delimiters:</strong> The <code>split()</code> method can handle multiple characters as delimiters.</li><br/>
        <li><strong>Limit:</strong> There is an overloaded version of <code>split()</code> that allows you to specify the maximum number of substrings you want to split the string into.</li>
      </ul><br/>

      <h3>Method Signatures:</h3>
      <ul>
        <li><strong>Basic Split:</strong> <pre><code>public String[] split(String regex)</code></pre> - Splits the string based on the regular expression.</li><br/>
        <li><strong>Split with Limit:</strong> <pre><code>public String[] split(String regex, int limit)</code></pre> - Splits the string based on the regular expression but with a limit on the number of substrings returned.</li>
      </ul><br/>

      <h3>Example 1: Basic Split Using Space as a Delimiter</h3>
      <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Java is fun";
        
        // Split the string by spaces
        String[] words = str.split(" ");
        
        // Print each word
        for (String word : words) {
            System.out.println(word);
        }
    }
}`} </code></pre>
      <p><strong>Output:</strong></p>
      <pre><code>Java
is
fun</code></pre><br/>
      
      <h3>Example 2: Split Using a Comma as a Delimiter</h3>
      <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Apple, Banana, Cherry";
        
        // Split the string by commas followed by spaces
        String[] fruits = str.split(", ");
        
        // Print each fruit
        for (String fruit : fruits) {
            System.out.println(fruit);
        }
    }
}`}</code></pre>
      <p><strong>Output:</strong></p>
      <pre><code>Apple
Banana
Cherry</code></pre><br/>

      <h3>Example 3: Using the <code>split()</code> Method with a Limit</h3>
      <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Apple, Banana, Cherry, Date, Elderberry";
        
        // Split the string by commas with a limit of 3 parts
        String[] fruits = str.split(", ", 3);
        
        // Print each part
        for (String fruit : fruits) {
            System.out.println(fruit);
        }
    }
}`}</code></pre>
      <p><strong>Output:</strong></p>
      <pre><code>Apple
Banana
Cherry, Date, Elderberry</code></pre><br/>

      <h3>Example 4: Splitting with a Regular Expression (Multiple Delimiters)</h3>
      <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Java; Python, C++, Ruby";
        
        // Split the string by semicolon or comma
        String[] languages = str.split("[;,] ");
        
        // Print each language
        for (String language : languages) {
            System.out.println(language);
        }
    }
}`}</code></pre>
      <p><strong>Output:</strong></p>
      <pre><code>Java
Python
C++
Ruby</code></pre><br/>

      <h3>Example 5: Splitting an Empty String</h3>
      <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "";
        
        // Split the empty string by a space
        String[] parts = str.split(" ");
        
        // Print the length of the array
        System.out.println(parts.length);  // Output: 0
    }
} `}</code></pre>
      <p><strong>Output:</strong></p>
      <pre><code>0</code></pre><br/>

      <h3>Summary:</h3>
      <ul>
        <li>The <code>split()</code> method in Java is used to divide a string into an array of substrings based on a given delimiter (regular expression).</li><br/>
        <li>It can handle spaces, commas, special characters, or even more complex patterns using regular expressions.</li><br/>
        <li>The method can also limit the number of splits, keeping the remaining part of the string intact.</li><br/>
        <li>It is useful for breaking down strings into manageable parts for further processing, such as parsing, analyzing, or formatting text.</li>
      </ul>
    </div>
  )}


{selectedChapter === 'chapter89' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String startsWith()  </h1>

        <p>
          The <code>startsWith()</code> method in Java is a part of the <code>String</code> class and is used to check if a string starts with a specified prefix. It returns <code>true</code> if the string starts with the given prefix, and <code>false</code> otherwise.
        </p><br/>

        <h3>Method Signature:</h3>
        <pre>
          <code>
            public boolean startsWith(String prefix)
          </code>
        </pre><br/>

        <ul>
          <li><strong>Parameters:</strong> <code>prefix</code>: The prefix you want to check. It can be any substring you expect the string to begin with.</li><br/>
          <li><strong>Returns:</strong> <code>true</code> if the string starts with the specified prefix. <code>false</code> if the string does not start with the specified prefix.</li>
        </ul><br/>

        <h3>Key Points:</h3>
        <ul>
          <li><strong>Case Sensitivity:</strong> The method is case-sensitive. For example, "Java" and "java" are treated as different prefixes.</li><br/>
          <li><strong>Empty String Prefix:</strong> An empty string <code>""</code> is considered a valid prefix, and any string (including an empty string) is considered to start with an empty string. Therefore, <code>str.startsWith("")</code> will always return <code>true</code>.</li><br/>
          <li><strong>Efficiency:</strong> The method is efficient, checking the prefix against the start of the string without searching the entire string.</li>
        </ul><br/>

        <h3>Examples:</h3>
        <h4>Example 1: Basic Usage</h4>
        <pre>
          <code>{`const str = "Java Programming";
const result = str.startsWith("Java");
console.log(result);  // Output: true`}</code>
        </pre>
        <p><strong>Explanation:</strong> The string "Java Programming" starts with "Java", so the result is <code>true</code>.</p>
        <br/>
        <h4>Example 2: Case-Sensitive Check</h4>
        <pre>
          <code>{`const str = "Java Programming";
const result = str.startsWith("java");
console.log(result);  // Output: false`}</code>
        </pre>
        <p><strong>Explanation:</strong> The method is case-sensitive. Since "Java" does not start with "java" (note the lowercase "j"), the result is <code>false</code>.</p>
        <br/>
        <h4>Example 3: Checking for an Empty Prefix</h4>
        <pre>
          <code>{`const str = "Hello, World!";
const result = str.startsWith("");
console.log(result);  // Output: true`}</code>
        </pre>
        <p><strong>Explanation:</strong> Any string starts with an empty string, so the result is <code>true</code>.</p>
        <br/>
        <h4>Example 4: Checking with a Prefix That Doesn’t Match</h4>
        <pre>
          <code>{`const str = "Hello, Java!";
const result = str.startsWith("World");
console.log(result);  // Output: false`}</code>
        </pre>
        <p><strong>Explanation:</strong> The string "Hello, Java!" does not start with "World", so the result is <code>false</code>.</p>
        <br/>
        <h4>Example 5: Checking a String Starts with a Substring</h4>
        <pre>
          <code>{`const str = "Hello, World!";
const result = str.startsWith("Hello");
console.log(result);  // Output: true`} </code>
        </pre>
        <p><strong>Explanation:</strong> The string "Hello, World!" starts with "Hello", so the result is <code>true</code>.</p>
        <br/>
        <h3>Summary:</h3>
        <ul>
          <li>The <code>startsWith()</code> method in Java checks if a string begins with a specific prefix.</li><br/>
          <li>It is case-sensitive, so "Java" and "java" would not match.</li><br/>
          <li>The method returns <code>true</code> if the string starts with the given prefix and <code>false</code> otherwise.</li><br/>
          <li>An empty string as the prefix always returns <code>true</code>.</li>
        </ul>

       
      </div>
    )}




{selectedChapter === 'chapter90' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> String substring()   </h1>

        <p>
          The <code>substring()</code> method in Java is a part of the <code>String</code> class, and it is used to extract a portion of a string (a substring) from the original string. This method allows you to specify a starting index and, optionally, an ending index to define the substring.
        </p><br/>

        <h3>Method Signatures:</h3>
        <h4>1. Basic Substring (starting from a specific index):</h4>
        <pre>
          <code>
            public String substring(int startIndex)
          </code>
        </pre>
        <ul>
          <li><strong>Parameters:</strong> <code>startIndex</code>: The index from where to start the substring (inclusive).</li><br/>
          <li><strong>Returns:</strong> A new string starting from <code>startIndex</code> to the end of the original string.</li>
        </ul><br/>

        <h4>2. Substring (starting and ending at specific indexes):</h4>
        <pre>
          <code>
            public String substring(int startIndex, int endIndex)
          </code>
        </pre>
        <ul>
          <li><strong>Parameters:</strong> <code>startIndex</code>: The index from where to start the substring (inclusive), <code>endIndex</code>: The index where the substring will end (exclusive).</li><br/>
          <li><strong>Returns:</strong> A new string that starts from <code>startIndex</code> and ends at <code>endIndex - 1</code>.</li>
        </ul><br/>

        <h3>Key Points:</h3>
        <ul>
          <li><strong>Zero-based Indexing:</strong> Java uses zero-based indexing, meaning the first character of the string has an index of 0.</li><br/>
          <li><strong>Inclusive and Exclusive:</strong> The start index is inclusive, and the end index is exclusive.</li><br/>
          <li><strong>Immutability:</strong> Strings are immutable, so <code>substring()</code> returns a new string.</li><br/>
          <li><strong>Index Out of Bounds:</strong> If the <code>startIndex</code> or <code>endIndex</code> is out of range, an <code>IndexOutOfBoundsException</code> is thrown.</li>
        </ul><br/>

        <h3>Examples:</h3>
        
        <h4>Example 1: Basic Substring (Starting from a Specific Index)</h4>
        <pre>
          <code>{`const str = "Hello, World!";
const result = str.substring(7);
console.log(result);  // Output: World!`}</code>
        </pre>
        <p><strong>Explanation:</strong> The substring starts from index 7 (the character "W") and continues to the end of the string. The output is <code>"World!"</code>.</p>
        <br/>
        <h4>Example 2: Substring (Specifying Both Start and End Index)</h4>
        <pre>
          <code>{`const str = "Hello, World!";
const result = str.substring(7, 12);
console.log(result);  // Output: World`} </code>
        </pre>
        <p><strong>Explanation:</strong> The substring starts from index 7 (the character "W") and ends just before index 12 (the character "!"). The output is <code>"World"</code>.</p>
        <br/>
        <h4>Example 3: Using <code>substring()</code> with a Single Parameter (From a Start Index)</h4>
        <pre>
          <code>{`const str = "Java Programming";
const result = str.substring(5);
console.log(result);  // Output: P`} </code>
        </pre>
        <p><strong>Explanation:</strong> The substring starts from index 5 (the character "P") and continues to the end of the string. The output is <code>"Programming"</code>.</p>
        <br/>
        <h4>Example 4: Substring with Invalid Indices (Throws Exception)</h4>
        <pre>
          <code>{`const str = "Hello, World!";
  try {
    const result = str.substring(10, 5);
    console.log(result);
  } catch (e) {
    console.log("Error: " + e.message);
  }
`}</code>
        </pre>
        <p><strong>Explanation:</strong> In this case, the start index (10) is greater than the end index (5), which results in an <code>IndexOutOfBoundsException</code>. The exception message will be printed: <code>"Error: String index out of range: -5"</code>.</p>
        <br/>
        <h4>Example 5: Substring with Edge Cases</h4>
        <pre>
          <code>{`const str = "Hello";
const result1 = str.substring(0, 1); // Output: H
const result2 = str.substring(4, 5); // Output: o
const result3 = str.substring(0, 5); // Output: Hello`}</code>
        </pre>
        <p><strong>Explanation:</strong> This example demonstrates using <code>substring()</code> to extract specific characters from the string, including the first and last characters, as well as the whole string.</p>
        <br/>
        <h3>Summary:</h3>
        <ul>
          <li>The <code>substring()</code> method extracts a part of a string by specifying start and optionally end indices.</li><br/>
          <li>It is case-sensitive and uses zero-based indexing.</li><br/>
          <li>It is commonly used for string manipulation in Java.</li>
        </ul>

        
      </div>
    )}



{selectedChapter === 'chapter91' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String `toLowerCase()`  </h1>

    <p>
      The <code>toLowerCase()</code> method is a part of the <code>String</code> class in Java. 
      It is used to convert all characters in a string to lowercase. This is particularly useful 
      when you want to standardize text, for example, before performing a case-insensitive comparison.
    </p><br/>

    <h3>Method Signature:</h3>
    <pre><code>public String toLowerCase()</code></pre>
    <br/>
    <ul>
      <li><strong>Returns:</strong> A new string where all the characters in the original string are converted to lowercase.</li>
    </ul><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>No Parameters:</strong> The <code>toLowerCase()</code> method does not take any parameters. It simply returns a new string with all lowercase characters.</li><br/>
      <li><strong>Case Insensitivity:</strong> This method is often used to make string comparisons case-insensitive. For example, when comparing two strings that should be treated equally regardless of case, you can convert both strings to lowercase.</li><br/>
      <li><strong>Immutability:</strong> Like other <code>String</code> methods, <code>toLowerCase()</code> does not change the original string. Instead, it returns a new string.</li>
    </ul><br/>

    <h3>Example 1: Basic Usage of <code>toLowerCase()</code></h3>
    <pre>
      <code>
        {`public class Main {
    public static void main(String[] args) {
        String str = "Hello World!";
        String result = str.toLowerCase();
        System.out.println(result);  // Output: hello world!
    }
}`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> The string <code>"Hello World!"</code> is converted to lowercase, resulting in <code>"hello world!"</code>.</p>
    <br/>
    <h3>Example 2: Case-insensitive Comparison Using <code>toLowerCase()</code></h3>
    <pre>
      <code>
        {`
public class Main {
    public static void main(String[] args) {
        String str1 = "Java Programming";
        String str2 = "JAVA PROGRAMMING";
        boolean isEqual = str1.toLowerCase().equals(str2.toLowerCase());
        System.out.println(isEqual);  // Output: true
    }
}`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> The <code>toLowerCase()</code> method is used to convert both strings to lowercase before comparing them. Even though <code>str1</code> and <code>str2</code> have different cases, they are considered equal when compared in lowercase, so the output is <code>true</code>.</p>
    <br/>
    <h3>Example 3: Using <code>toLowerCase()</code> with a Non-ASCII Character</h3>
    <pre>
      <code>
        {`public class Main {
    public static void main(String[] args) {
        String str = "Grüß Gott";
        String result = str.toLowerCase();
        System.out.println(result);  // Output: gr?? gott
    }
}`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> The <code>toLowerCase()</code> method also handles non-ASCII characters correctly. In this case, it converts <code>"Grüß Gott"</code> to <code>"grüß gott"</code>, preserving the special character (<code>ß</code>).</p>
    <br/>
    <h3>Example 4: Using <code>toLowerCase()</code> on an Empty String</h3>
    <pre>
      <code>
        {`public class Main {
    public static void main(String[] args) {
        String str = "";
        String result = str.toLowerCase();
        System.out.println(result);  // Output: (empty string)
    }
}`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> If the string is empty, the <code>toLowerCase()</code> method simply returns an empty string.</p>
    <br/>
    <h3>Example 5: Using <code>toLowerCase()</code> for Locale-Sensitive Operations</h3>
    <pre>
      <code>
        {`import java.util.Locale;

public class Main {
    public static void main(String[] args) {
        String str = "Istanbul";
        String result = str.toLowerCase(Locale.forLanguageTag("tr"));
        System.out.println(result);  // Output: istanbul
    }
}`}
      </code>
    </pre>
    <p><strong>Explanation:</strong> In some languages, such as Turkish, the lowercase conversion of certain characters (like <code>I</code> to <code>ı</code>) is locale-dependent. By passing a <code>Locale</code>, you ensure correct behavior for language-specific rules.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li>The <code>toLowerCase()</code> method is used to convert a string into all lowercase characters.</li><br/>
      <li>It’s often used for case-insensitive comparisons and standardizing strings.</li><br/>
      <li>The method does not modify the original string (since strings are immutable) but returns a new string.</li><br/>
      <li>It works with non-ASCII characters and can also handle locale-sensitive conversions.</li>
    </ul>

    
  </div>
)}




{selectedChapter === 'chapter92' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String `toUpperCase()`   </h1>
    
    <p>The <code>toUpperCase()</code> method is a part of the <code>String</code> class in Java. It is used to convert all characters in a string to uppercase. This is useful when you want to standardize text or perform case-insensitive comparisons.</p>
    <br/>
    <h3>Method Signature:</h3>
    <pre><code>public String toUpperCase()</code></pre>
    <br/>
    <ul>
      <li><strong>Returns</strong>: A new string where all the characters in the original string are converted to uppercase.</li>
    </ul><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>No Parameters</strong>: The <code>toUpperCase()</code> method does not take any parameters. It simply returns a new string with all uppercase characters.</li><br/>
      <li><strong>Case Sensitivity</strong>: This method is often used to make string comparisons case-insensitive, where you convert both strings to uppercase before comparing them.</li><br/>
      <li><strong>Immutability</strong>: The <code>toUpperCase()</code> method does not modify the original string (since strings are immutable). Instead, it returns a new string.</li><br/>
      <li><strong>Non-ASCII Characters</strong>: The method works with non-ASCII characters and handles them appropriately (e.g., converting accented characters to uppercase).</li>
    </ul><br/>

    <h3>Example 1: Basic Usage of <code>toUpperCase()</code></h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "Hello World!";
        
        // Convert the string to uppercase
        String result = str.toUpperCase();
        
        System.out.println(result);  // Output: HELLO WORLD!
    }
}`} </code></pre>
    <p><strong>Explanation:</strong></p>
    <p>The string <code>"Hello World!"</code> is converted to uppercase, resulting in <code>"HELLO WORLD!"</code>.</p><br/>

    <h3>Example 2: Case-insensitive Comparison Using <code>toUpperCase()</code></h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str1 = "Java Programming";
        String str2 = "JAVA PROGRAMMING";
        
        // Compare strings in a case-insensitive manner
        boolean isEqual = str1.toUpperCase().equals(str2.toUpperCase());
        
        System.out.println(isEqual);  // Output: true
    }
}`} </code></pre>
    <p><strong>Explanation:</strong></p>
    <p>The <code>toUpperCase()</code> method is used to convert both strings to uppercase before comparing them. Even though <code>str1</code> and <code>str2</code> have different cases, they are considered equal when compared in uppercase, so the output is <code>true</code>.</p>
    <br/>
    <h3>Example 3: Using <code>toUpperCase()</code> with Non-ASCII Characters</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "grüß gott";
        
        // Convert the string to uppercase
        String result = str.toUpperCase();
        
        System.out.println(result);  // Output: GRÜß GOTT
    }
} `}</code></pre>
    <p><strong>Explanation:</strong></p>
    <p>The <code>toUpperCase()</code> method also works with non-ASCII characters like <code>ü</code> and <code>ß</code>. It converts the string <code>"grüß gott"</code> to <code>"GRÜß GOTT"</code>, handling the special characters correctly.</p>
    <br/>
    <h3>Example 4: Using <code>toUpperCase()</code> on an Empty String</h3>
    <pre><code>{`public class Main {
    public static void main(String[] args) {
        String str = "";
        
        // Convert an empty string to uppercase
        String result = str.toUpperCase();
        
        System.out.println(result);  // Output: (empty string)
    }
} `}</code></pre>
    <p><strong>Explanation:</strong></p>
    <p>If the string is empty, the <code>toUpperCase()</code> method simply returns an empty string.</p>
    <br/>
    <h3>Example 5: Using <code>toUpperCase()</code> for Locale-Sensitive Operations</h3>
    <pre><code>{`import java.util.Locale;

public class Main {
    public static void main(String[] args) {
        String str = "istanbul";
        
        // Convert the string to uppercase in a specific locale (e.g., Turkey)
        String result = str.toUpperCase(Locale.forLanguageTag("tr"));
        
        System.out.println(result);  // Output: ISTANBUL
    }
} `}</code></pre>
    <p><strong>Explanation:</strong></p>
    <p>In some languages, like Turkish, the uppercase conversion of certain characters (such as <code>i</code> to <code>İ</code>) can be locale-dependent. By passing a <code>Locale</code>, you ensure that the method follows the correct rules for the specified language.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li>The <code>toUpperCase()</code> method is used to convert all characters in a string to uppercase.</li><br/>
      <li>It is commonly used for case-insensitive string comparisons and text standardization.</li><br/>
      <li>The method does not modify the original string (because strings are immutable) but returns a new string.</li><br/>
      <li>It works with non-ASCII characters and can handle locale-specific uppercase conversion rules.</li>
    </ul>

    
  </div>
)}




{selectedChapter === 'chapter93' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String `trim()` </h1>

    <p>
      The <code>trim()</code> method in Java is used to remove any leading (before the first character) and trailing (after the last character) whitespace from a string. This is helpful when you want to clean up input or output data by removing unnecessary spaces that could affect string comparisons or formatting.
    </p><br/>

    <h3>Method Signature:</h3>
    <pre><code>public String trim()</code></pre>
    <p>
      <strong>Returns:</strong> A new string with the leading and trailing whitespace removed. If there are no such whitespaces, it returns the original string.
    </p><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Whitespace Removal:</strong> The <code>trim()</code> method removes only the spaces, tabs, and newline characters at the beginning and end of the string.</li><br/>
      <li><strong>Immutability:</strong> Like other <code>String</code> methods, <code>trim()</code> does not modify the original string but instead returns a new string with the whitespace removed.</li><br/>
      <li><strong>Effect on Inner Spaces:</strong> The <code>trim()</code> method does not affect spaces that are present between characters in the string. Only leading and trailing spaces are removed.</li>
    </ul><br/>

    <h3>Example 1: Basic Usage of <code>trim()</code></h3>
    <pre><code>
{`public class Main {
  public static void main(String[] args) {
      String str = "   Hello World!   ";

      // Remove leading and trailing spaces
      String trimmedStr = str.trim();

      System.out.println(trimmedStr);  // Output: "Hello World!"
  }
}`}
    </code></pre>
    <p><strong>Explanation:</strong> The string <code>"   Hello World!   "</code> has spaces before and after the words. After calling <code>trim()</code>, these spaces are removed, resulting in <code>"Hello World!"</code>.</p>
    <br/>
    <h3>Example 2: No Effect if No Leading or Trailing Whitespace</h3>
    <pre><code>
{`public class Main {
  public static void main(String[] args) {
      String str = "Hello World!";

      // Call trim() on a string with no leading or trailing spaces
      String trimmedStr = str.trim();

      System.out.println(trimmedStr);  // Output: "Hello World!"
  }
}`}
    </code></pre>
    <p><strong>Explanation:</strong> In this case, the string <code>"Hello World!"</code> already has no leading or trailing whitespace. Calling <code>trim()</code> does not change the string, so the result is still <code>"Hello World!"</code>.</p>
    <br/>
    <h3>Example 3: Leading and Trailing Whitespace with Newlines</h3>
    <pre><code>
{`public class Main {
  public static void main(String[] args) {
      String str = "\\n  Hello World!  \\n";

      // Remove leading and trailing spaces and newlines
      String trimmedStr = str.trim();

      System.out.println(trimmedStr);  // Output: "Hello World!"
  }
}`}
    </code></pre>
    <p><strong>Explanation:</strong> The string contains leading and trailing newline characters (<code>\n</code>) along with spaces. After calling <code>trim()</code>, both the newlines and spaces are removed, resulting in <code>"Hello World!"</code>.</p>
    <br/>
    <h3>Example 4: Handling Empty String</h3>
    <pre><code>
{`public class Main {
  public static void main(String[] args) {
      String str = "   ";

      // Trim an empty string with only spaces
      String trimmedStr = str.trim();

      System.out.println(trimmedStr);  // Output: ""
  }
}`}
    </code></pre>
    <p><strong>Explanation:</strong> When the string is made up entirely of spaces, calling <code>trim()</code> removes all spaces, resulting in an empty string <code>""</code>.</p>
    <br/>
    <h3>Example 5: Using <code>trim()</code> in String Comparisons</h3>
    <pre><code>
{`public class Main {
  public static void main(String[] args) {
      String str1 = "   Java Programming   ";
      String str2 = "Java Programming";

      // Compare strings after trimming
      boolean isEqual = str1.trim().equals(str2);

      System.out.println(isEqual);  // Output: true
  }
}`}
    </code></pre>
    <p><strong>Explanation:</strong> The string <code>str1</code> has leading and trailing spaces, while <code>str2</code> does not. By calling <code>trim()</code> on <code>str1</code>, both strings are standardized, making the comparison return <code>true</code>.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li>The <code>trim()</code> method removes leading and trailing whitespace (spaces, tabs, and newline characters) from a string.</li><br/>
      <li>It does not affect spaces that are in between words or characters.</li><br/>
      <li>It returns a new string and does not modify the original string.</li><br/>
      <li>The <code>trim()</code> method is useful when cleaning user input or formatting strings before comparisons.</li>
    </ul>

   
  </div>
)}



{selectedChapter === 'chapter94' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>String `toCharArray()`   </h1>

    <p>The `toCharArray()` method in Java is used to convert a `String` into an array of characters (`char[]`). This can be useful when you need to process each character of a string individually.</p>
    <br/>
    <h3>Method Signature:</h3>
    <pre><code>public char[] toCharArray()</code></pre>
    <br/>
    <p><strong>Returns:</strong> A new array of type <code>char[]</code> that contains the characters of the original string. The characters are placed in the array in the same order as they appear in the string.</p>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Conversion:</strong> The `toCharArray()` method converts the entire string into a character array. It is useful when you need to manipulate or iterate over the individual characters of a string.</li><br/>
      <li><strong>Immutability:</strong> The `String` itself remains unchanged. The method returns a new array, and the string object remains intact.</li><br/>
      <li><strong>Index Access:</strong> Since the result is an array, you can access each character by its index (just like any other array in Java).</li>
    </ul><br/>

    <h3>Example 1: Basic Usage of `toCharArray()`</h3>
    <pre><code>
      {`public class Main {
  public static void main(String[] args) {
    String str = "Hello";
    // Convert the string to a character array
    char[] charArray = str.toCharArray();
    
    // Print the character array
    for (char c : charArray) {
        System.out.print(c + " ");  // Output: H e l l o
    }
  }
}`}
    </code></pre>
    <p><strong>Explanation:</strong> The string `"Hello"` is converted into a character array. The array contains individual characters: <code>['H', 'e', 'l', 'l', 'o']</code>. The loop prints each character with a space in between.</p>
    <br/>
    <h3>Example 2: Accessing Characters Using Array Index</h3>
    <pre><code>
      {`public class Main {
  public static void main(String[] args) {
    String str = "Java";
    // Convert the string to a character array
    char[] charArray = str.toCharArray();
    
    // Access characters using their indices
    System.out.println(charArray[0]);  // Output: J
    System.out.println(charArray[1]);  // Output: a
  }
}`}
    </code></pre>
    <p><strong>Explanation:</strong> The string `"Java"` is converted into a character array. You can access individual characters by their index (0-based), such as <code>charArray[0]</code> for 'J' and <code>charArray[1]</code> for 'a'.</p>
    <br/>
    <h3>Example 3: Manipulating Characters Using `toCharArray()`</h3>
    <pre><code>
      {`public class Main {
  public static void main(String[] args) {
    String str = "Hello";
    // Convert the string to a character array
    char[] charArray = str.toCharArray();
    
    // Change the first character to 'Y'
    charArray[0] = 'Y';
    
    // Convert the character array back to a string
    String newStr = new String(charArray);
    
    System.out.println(newStr);  // Output: Yello
  }
}`}
   </code> </pre>
    <p><strong>Explanation:</strong> The string `"Hello"` is converted into a character array. We modify the first character of the array (<code>charArray[0] = 'Y'</code>), changing the array to <code>['Y', 'e', 'l', 'l', 'o']</code>. We convert the modified array back to a string and print the result, which is `"Yello"`.</p>
    <br/>
    <h3>Example 4: Using `toCharArray()` to Reverse a String</h3>
    <pre><code>
      {`public class Main {
  public static void main(String[] args) {
    String str = "Programming";
    // Convert the string to a character array
    char[] charArray = str.toCharArray();
    
    // Reverse the array manually
    for (int i = 0; i < charArray.length / 2; i++) {
        char temp = charArray[i];
        charArray[i] = charArray[charArray.length - 1 - i];
        charArray[charArray.length - 1 - i] = temp;
    }
    
    // Convert the character array back to a string
    String reversedStr = new String(charArray);
    
    System.out.println(reversedStr);  // Output: gnimmargorP
  }
}`}
   </code> </pre>
    <p><strong>Explanation:</strong> We convert the string `"Programming"` to a character array. We reverse the array manually by swapping the characters. The reversed array is then converted back to a string and printed.</p>
    <br/>
    <h3>Example 5: Using `toCharArray()` in String Comparison</h3>
    <pre><code>
      {`public class Main {
  public static void main(String[] args) {
    String str1 = "apple";
    String str2 = "apple";
    // Convert both strings to character arrays
    char[] charArray1 = str1.toCharArray();
    char[] charArray2 = str2.toCharArray();
    
    // Compare the character arrays
    boolean areEqual = java.util.Arrays.equals(charArray1, charArray2);
    
    System.out.println(areEqual);  // Output: true
  }
}`}
    </code></pre>
    <p><strong>Explanation:</strong> The strings `"apple"` and `"apple"` are both converted to character arrays. The <code>java.util.Arrays.equals()</code> method is used to compare the arrays. Since the arrays are equal, the output is <code>true</code>.</p>
    <br/>
    <h3>Summary:</h3>
    <ul>
      <li>The <code>toCharArray()</code> method converts a string into a character array, making it easy to work with individual characters.</li><br/>
      <li>It does not modify the original string, but instead returns a new array.</li><br/>
      <li>You can access or manipulate the characters in the array using their indices.</li><br/>
      <li>This method is useful when you need to perform operations on individual characters in a string or compare strings character by character.</li>
    </ul>

    
  </div>
)}




{selectedChapter === 'chapter95' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Exception Handling   </h1>

        <p>
          In Java, an <strong>exception</strong> is an event that disrupts the
          normal flow of a program's execution. It usually occurs when the
          program encounters an error while running, such as dividing a number
          by zero, accessing an invalid array index, or trying to open a file
          that doesn't exist.
        </p><br/>

        <p>
          Java provides a robust mechanism to handle such runtime errors
          gracefully, ensuring that the program doesn't crash unexpectedly.
          This mechanism is called <strong>exception handling</strong>.
        </p><br/>

        <h2>Key Terms in Exception Handling</h2>
        <ul>
          <li>
            <strong>Exception:</strong> An unwanted or unexpected event that
            occurs during the execution of a program.
          </li><br/>
          <li>
            <strong>Exception Handling:</strong> The process of managing
            exceptions to ensure the program continues to run or terminates
            gracefully.
          </li><br/>
          <li>
            <strong>Error:</strong> Serious issues that a program cannot handle
            (e.g., running out of memory). Errors are not the same as
            exceptions.
          </li>
        </ul><br/>

        <h2>Hierarchy of Exceptions</h2>
        <p>
          In Java, all exceptions are part of the{" "}
          <code>java.lang.Throwable</code> class. It has two main subclasses:
        </p><br/>
        <ul>
          <li>
            <strong>Error:</strong> Represents critical issues that cannot be
            handled by the program (e.g., <code>OutOfMemoryError</code>,{" "}
            <code>StackOverflowError</code>).
          </li><br/>
          <li>
            <strong>Exception:</strong> Represents conditions that the program
            can handle. These are further divided into:
            <ul>
              <li>
                <strong>Checked Exceptions:</strong> Exceptions that are
                checked at compile-time (e.g., <code>IOException</code>,{" "}
                <code>SQLException</code>).
              </li><br/>
              <li>
                <strong>Unchecked Exceptions:</strong> Exceptions that occur at
                runtime (e.g., <code>ArithmeticException</code>,{" "}
                <code>NullPointerException</code>).
              </li>
            </ul>
          </li>
        </ul><br/>

        <h2>How to Handle Exceptions in Java</h2>
        <p>Java provides the following keywords to handle exceptions:</p>
        <ul>
          <li>
            <code>try</code>: Defines a block of code where exceptions can
            occur.
          </li><br/>
          <li>
            <code>catch</code>: Handles exceptions that occur in the{" "}
            <code>try</code> block.
          </li><br/>
          <li>
            <code>finally</code>: Defines a block of code that will always
            execute, whether or not an exception occurs.
          </li><br/>
          <li>
            <code>throw</code>: Used to explicitly throw an exception.
          </li><br/>
          <li>
            <code>throws</code>: Declares the exceptions that a method might
            throw.
          </li>
        </ul><br/>

        <h2>Examples</h2>

        <h3>Example 1: Handling an Exception</h3>
        <pre className={style.codeblock}>
          <code>
{`public class Main {
    public static void main(String[] args) {
        try {
            int result = 10 / 0; // This will throw ArithmeticException
            System.out.println(result);
        } catch (ArithmeticException e) {
            System.out.println("Error: Cannot divide by zero."); // Handle the exception
        }
    }
}`}
          </code>
        </pre>
        <p><strong>Output:</strong> Error: Cannot divide by zero.</p>
        <br/>
        <h3>Example 2: Using <code>finally</code></h3>
        <pre className={style.codeblock}>
          <code>
{`public class Main {
    public static void main(String[] args) {
        try {
            int[] arr = {1, 2, 3};
            System.out.println(arr[5]); // Accessing an invalid index
        } catch (ArrayIndexOutOfBoundsException e) {
            System.out.println("Error: Array index out of bounds.");
        } finally {
            System.out.println("This block always executes.");
        }
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong> <br />
          Error: Array index out of bounds. <br />
          This block always executes.
        </p>
        <br/>
        <h3>Example 3: Throwing an Exception</h3>
        <pre className={style.codeblock}>
          <code>
{`public class Main {
    public static void main(String[] args) {
        throw new ArithmeticException("This is a custom exception.");
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong> <br />
          Exception in thread "main" java.lang.ArithmeticException: This is a
          custom exception.
        </p>
        <br/>
        <h2>Summary</h2>
        <ul>
          <li>
            <strong>Exceptions:</strong> Events that disrupt program execution.
          </li><br/>
          <li>
            Java provides keywords like <code>try</code>, <code>catch</code>,{" "}
            and <code>finally</code> for graceful exception handling.
          </li><br/>
          <li>
            <strong>Checked exceptions</strong> must be handled explicitly,
            while <strong>unchecked exceptions</strong> occur at runtime.
          </li><br/>
          <li>
            You can create <strong>custom exceptions</strong> for specific use
            cases.
          </li>
        </ul>
      </div>
    )}




{selectedChapter === 'chapter96' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Try-Catch Block</h1>
    <p>
      In Java, a <strong>try-catch block</strong> is a mechanism used to handle
      exceptions gracefully. Instead of crashing the program when an exception
      occurs, the try-catch block allows you to "catch" the exception and define
      what the program should do next.
    </p><br/>
    <ul>
      <li>
        The <code>try</code> block contains the code that might throw an
        exception.
      </li><br/>
      <li>
        The <code>catch</code> block contains the code to handle that exception.
      </li><br/>
    </ul>
    <h3>Syntax of Try-Catch Block</h3>
    <pre>
      <code>
{`try {
    // Code that might throw an exception
} catch (ExceptionType e) {
    // Code to handle the exception
}`}
      </code>
    </pre><br/>
    <h3>How it Works</h3>
    <ol>
      <li>
        <strong>The Try Block:</strong> Any code that might throw an exception
        is placed inside the <code>try</code> block. If an exception occurs in
        this block, the program jumps to the <code>catch</code> block.
      </li><br/>
      <li>
        <strong>The Catch Block:</strong> This block handles the exception. The
        parameter <code>(ExceptionType e)</code> specifies the type of exception
        it can catch. <code>e</code> is the reference to the exception object,
        which contains details about the exception (e.g., its message or stack
        trace).
      </li>
    </ol><br/>
    <h3>Key Points</h3>
    <ul>
      <li>
        <strong>One Try, Multiple Catches:</strong> You can have multiple{" "}
        <code>catch</code> blocks to handle different types of exceptions.
      </li><br/>
      <li>
        <strong>Catch Hierarchy:</strong> Place more specific exception types
        before general ones (like <code>Exception</code>).
      </li><br/>
      <li>
        <strong>Unhandled Exceptions:</strong> If no <code>catch</code> block
        matches the thrown exception, the program will terminate.
      </li><br/>
      <li>
        <strong>Optional Finally:</strong> You can add a <code>finally</code>{" "}
        block to execute code regardless of whether an exception occurred.
      </li>
    </ul><br/>
    <h3>Examples</h3>
    <h4>Example 1: Basic Try-Catch</h4>
    <pre>
      <code>
{`public class Main {
    public static void main(String[] args) {
        try {
            int result = 10 / 0; // This will throw ArithmeticException
        } catch (ArithmeticException e) {
            System.out.println("Error: Cannot divide by zero.");
        }
    }
}`}
      </code>
    </pre><br/>
    <h4>Example 2: Multiple Catch Blocks</h4>
    <pre>
      <code>
{`public class Main {
    public static void main(String[] args) {
        try {
            int[] arr = {1, 2, 3};
            System.out.println(arr[5]); // This will throw ArrayIndexOutOfBoundsException
        } catch (ArithmeticException e) {
            System.out.println("Arithmetic Exception occurred.");
        } catch (ArrayIndexOutOfBoundsException e) {
            System.out.println("Array Index Out of Bounds Exception occurred.");
        }
    }
}`}
      </code>
    </pre><br/>
    <h4>Example 3: Catching Multiple Exceptions in One Block</h4>
    <pre>
      <code>
{`public class Main {
    public static void main(String[] args) {
        try {
            int result = 10 / 0; // This will throw ArithmeticException
            String str = null;
            System.out.println(str.length()); // NullPointerException
        } catch (ArithmeticException | NullPointerException e) {
            System.out.println("An exception occurred: " + e.getMessage());
        }
    }
}`}
      </code>
    </pre><br/>
    <h4>Example 4: Try-Catch with Finally</h4>
    <pre>
      <code>
{`public class Main {
    public static void main(String[] args) {
        try {
            int result = 10 / 0; // This will throw ArithmeticException
        } catch (ArithmeticException e) {
            System.out.println("Error: Cannot divide by zero.");
        } finally {
            System.out.println("This block will always execute.");
        }
    }
}`}
      </code>
    </pre><br/>
    <h4>Example 5: Unhandled Exception</h4>
    <pre>
      <code>
{`public class Main {
    public static void main(String[] args) {
        try {
            String str = null;
            System.out.println(str.length()); // This will throw NullPointerException
        } catch (ArithmeticException e) {
            System.out.println("This will not catch the exception.");
        }
    }
}`}
      </code>
    </pre><br/>
    <h3>Common Mistakes with Try-Catch</h3>
    <ul>
      <li>
        <strong>Catching General Exceptions First:</strong> Always catch
        specific exceptions before general ones to avoid unreachable code.
      </li><br/>
      <li>
        <strong>Empty Catch Blocks:</strong> Avoid leaving{" "}
        <code>catch</code> blocks empty, as it makes debugging harder.
      </li><br/>
      <li>
        <strong>Overusing Try-Catch:</strong> Use <code>try-catch</code> only
        for exceptional cases, not for regular control flow.
      </li>
    </ul><br/>
    <h3>Summary</h3>
    <p>
      The <strong>try-catch block</strong> is used to handle exceptions in Java.
      The <code>try</code> block contains code that might throw an exception,
      and the <code>catch</code> block handles the exception. Multiple{" "}
      <code>catch</code> blocks can be used for different exception types. The{" "}
      <code>finally</code> block ensures code execution regardless of
      exceptions.
    </p>
  </div>
)}




{selectedChapter === 'chapter97' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Multiple Catch Block </h1>
          <p>
            In Java, a <strong>multiple catch block</strong> allows you to handle different types of exceptions
            separately. This is useful when a block of code might throw multiple exceptions, and each requires specific
            handling.
          </p>
          <br/>
          <h2>How It Works</h2>
          <ul>
            <li>A single <code>try</code> block can have multiple <code>catch</code> blocks.</li><br/>
            <li>Each <code>catch</code> block is designed to handle a specific type of exception.</li><br/>
            <li>
              When an exception occurs in the <code>try</code> block:
              <ol>
                <li>The program checks each <code>catch</code> block in order.</li><br/>
                <li>The first matching <code>catch</code> block is executed.</li><br/>
                <li>Remaining <code>catch</code> blocks are ignored.</li>
              </ol>
            </li>
          </ul>
          <br/>
          <h2>Syntax of Multiple Catch Blocks</h2>
          <pre className={style.codeblock}>
{`try {
    // Code that might throw exceptions
} catch (ExceptionType1 e1) {
    // Handle ExceptionType1
} catch (ExceptionType2 e2) {
    // Handle ExceptionType2
} catch (Exception e) {
    // Handle other exceptions
}`}
          </pre>
          <br/>
          <h2>Key Rules</h2>
          <ul>
            <li>
              <strong>Order Matters</strong>: Place more specific exception types before general ones (e.g.,{' '}
              <code>ArithmeticException</code> before <code>Exception</code>) to avoid a{' '}
              <strong>compile-time error</strong>.
            </li><br/>
            <li>
              <strong>Catch One Exception at a Time</strong>: Only one <code>catch</code> block is executed for a
              single exception.
            </li><br/>
            <li>
              <strong>Exceptions Must Be Related</strong>: All exceptions handled in the <code>catch</code> blocks must
              extend <code>Throwable</code>.
            </li>
          </ul>
          <br/>
          <h2>Examples</h2>
          <h3>Example 1: Handling Two Exceptions</h3>
          <pre className={style.codeblock}>
{`public class Main {
    public static void main(String[] args) {
        try {
            int[] arr = {1, 2, 3};
            System.out.println(arr[5]); // ArrayIndexOutOfBoundsException
            int result = 10 / 0;       // ArithmeticException
        } catch (ArrayIndexOutOfBoundsException e) {
            System.out.println("Error: Array index out of bounds.");
        } catch (ArithmeticException e) {
            System.out.println("Error: Division by zero.");
        }
    }
}`}
          </pre>
          <p>
            <strong>Output:</strong> <code>Error: Array index out of bounds.</code>
          </p><br/>
          <h3>Example 2: Catching All Exceptions</h3>
          <pre className={style.codeblock}>
{`public class Main {
    public static void main(String[] args) {
        try {
            int result = 10 / 0; // This will throw ArithmeticException
        } catch (ArithmeticException e) {
            System.out.println("Error: Division by zero.");
        } catch (Exception e) {
            System.out.println("A general exception occurred.");
        }
    }
}`}
          </pre>
          <p>
            <strong>Output:</strong> <code>Error: Division by zero.</code>
          </p><br/>
          <h3>Example 3: Incorrect Order of Catch Blocks</h3>
          <pre className={style.codeblock}>
{`public class Main {
    public static void main(String[] args) {
        try {
            int result = 10 / 0; // ArithmeticException
        } catch (Exception e) {
            System.out.println("General exception caught.");
        } catch (ArithmeticException e) {
            // Compile-time error: Unreachable code
            System.out.println("Arithmetic exception caught.");
        }
    }
}`}
          </pre>
          <p>
            <strong>Output:</strong> <em>Compile-Time Error</em>
          </p><br/>
          <h3>Example 4: Multiple Exceptions in a Single Catch</h3>
          <pre className={style.codeblock}>
{`public class Main {
    public static void main(String[] args) {
        try {
            String str = null;
            System.out.println(str.length()); // NullPointerException
        } catch (NullPointerException | ArithmeticException e) {
            System.out.println("Either null reference or arithmetic error occurred.");
        }
    }
}`}
          </pre>
          <p>
            <strong>Output:</strong> <code>Either null reference or arithmetic error occurred.</code>
          </p>
          <br/>
          <h2>Why Use Multiple Catch Blocks?</h2>
          <ul>
            <li>
              <strong>Improved Readability</strong>: Handle different exceptions with specific logic.
            </li><br/>
            <li>
              <strong>Error-Specific Handling</strong>: Provide meaningful messages or actions for each type of
              exception.
            </li><br/>
            <li>
              <strong>Maintain Program Flow</strong>: Prevent the program from crashing when multiple potential
              exceptions exist.
            </li>
          </ul>
          <br/>
          <h2>Best Practices</h2>
          <ul>
            <li>
              <strong>Use Specific Exceptions First</strong>:
              <pre className={style.codeblock}>
{`catch (ArithmeticException e) {
    // Handle specific exception
} catch (Exception e) {
    // Handle general exceptions
}`}
              </pre>
            </li><br/>
            <li>
              <strong>Avoid Empty Catch Blocks</strong>: Always log or handle the exception in a meaningful way.
              <pre className={style.codeblock}>
{`catch (Exception e) {
    // Do something meaningful
    System.out.println(e.getMessage());
}`}
              </pre>
            </li><br/>
            <li>
              <strong>Group Similar Exceptions</strong>:
              <pre className={style.codeblock}>
{`catch (IOException | SQLException e) {
    System.out.println("I/O or Database error occurred.");
}`}
              </pre>
            </li>
          </ul>
          <br/>
          <h2>Summary</h2>
          <p>
            - Multiple <code>catch</code> blocks allow handling of different exceptions from a single <code>try</code>{' '}
            block. <br />
            - Place specific exception types before general ones. <br />
            - From Java 7 onward, you can group exceptions using the pipe (<code>|</code>) symbol. <br />
            - Ensure that each <code>catch</code> block handles the exception meaningfully.
          </p>
        </div>
      )}
    
  
    {selectedChapter === 'chapter98' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Nested Try Blocks  </h1>
    <p>
      In Java, <strong>nested try blocks</strong> occur when one <code>try</code> block is placed inside another
      <code>try</code> block. This is useful when you have different sections of code that might throw exceptions, and each section requires separate handling. Nested try blocks help organize and handle exceptions more precisely.
    </p>

    <br/>

    <h2>Why Use Nested Try Blocks?</h2>
    <ol>
      <li>
        <strong>Handle Specific Exceptions Separately:</strong> You might have multiple operations that could throw
        different exceptions. Nesting allows you to handle each exception exactly where it happens.
      </li><br/>
      <li>
        <strong>Prevent Program Crashes:</strong> Even if one part of the code fails, other parts can continue executing
        because exceptions are managed appropriately.
      </li><br/>
      <li>
        <strong>Better Granularity:</strong> You can break down complex error-prone code into smaller parts, each with
        its exception handling.
      </li>
    </ol>

    <br/>

    <h2>Structure of Nested Try Blocks</h2>
    <pre>
      <code>{`try {
    // Outer try block
    try {
        // Inner try block
    } catch (ExceptionType1 e) {
        // Handle exceptions from the inner try block
    }
} catch (ExceptionType2 e) {
    // Handle exceptions from the outer try block
}`}</code>
    </pre>

    <br/>

    <h2>Example 1: Basic Nested Try Block</h2>
    <h3>Problem: Accessing an invalid array index and dividing by zero.</h3>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        int[] numbers = {10, 0, 5};

        try {
            // Outer try block
            System.out.println("Outer try block running...");
            
            try {
                // Inner try block
                System.out.println("Inner try block: Accessing array element...");
                System.out.println(numbers[5]); // Throws ArrayIndexOutOfBoundsException
            } catch (ArrayIndexOutOfBoundsException e) {
                // Handle inner try exception
                System.out.println("Inner catch: Invalid array index. " + e);
            }

            // Code after inner try-catch
            int result = numbers[0] / numbers[1]; // Throws ArithmeticException
            System.out.println("Division result: " + result);

        } catch (ArithmeticException e) {
            // Handle outer try exception
            System.out.println("Outer catch: Cannot divide by zero. " + e);
        }

        System.out.println("Program continues...");
    }
}`}</code>
    </pre>

    <h3>Output:</h3>
    <pre>
      <code>{`Outer try block running...
Inner try block: Accessing array element...
Inner catch: Invalid array index. java.lang.ArrayIndexOutOfBoundsException: Index 5 out of bounds for length 3
Outer catch: Cannot divide by zero. java.lang.ArithmeticException: / by zero
Program continues...`}</code>
    </pre>

    <br/>

    <h2>Execution Flow Explained</h2>
    <ol>
      <li>
        <strong>Outer Try Block Starts:</strong> Handles general operations. If an exception occurs in the <strong>inner
        try block</strong>, it is caught by the <strong>inner catch block</strong>.
      </li><br/>
      <li>
        <strong>Inner Try Block Executes:</strong> Focuses on accessing an array. If an error occurs (like invalid array
        index), it’s caught and handled immediately by the <strong>inner catch block</strong>.
      </li><br/>
      <li>
        <strong>Outer Catch Block Handles Remaining Exceptions:</strong> Exceptions like dividing by zero are caught
        here because they occur outside the <strong>inner try block</strong>.
      </li>
    </ol>

    <br/>

    <h2>Example 2: Nested Try Blocks with Multiple Inner Tries</h2>
    <h3>Problem: Array access and division operations, both with potential errors.</h3>
    <pre>
      <code>{`public class Main {
    public static void main(String[] args) {
        int[] numbers = {10, 0, 5};

        try {
            // Outer try block
            System.out.println("Outer try block running...");

            try {
                // First inner try block
                System.out.println("Inner try 1: Accessing array element...");
                System.out.println(numbers[5]); // Throws ArrayIndexOutOfBoundsException
            } catch (ArrayIndexOutOfBoundsException e) {
                System.out.println("Inner catch 1: Invalid array index. " + e);
            }

            try {
                // Second inner try block
                System.out.println("Inner try 2: Performing division...");
                int result = numbers[0] / numbers[1]; // Throws ArithmeticException
                System.out.println("Division result: " + result);
            } catch (ArithmeticException e) {
                System.out.println("Inner catch 2: Cannot divide by zero. " + e);
            }

        } catch (Exception e) {
            // Catch any other exceptions
            System.out.println("Outer catch: General exception caught. " + e);
        }

        System.out.println("Program continues...");
    }
}`}</code>
    </pre>

    <h3>Output:</h3>
    <pre>
      <code>{`Outer try block running...
Inner try 1: Accessing array element...
Inner catch 1: Invalid array index. java.lang.ArrayIndexOutOfBoundsException: Index 5 out of bounds for length 3
Inner try 2: Performing division...
Inner catch 2: Cannot divide by zero. java.lang.ArithmeticException: / by zero
Program continues...`}</code>
    </pre>

    <br/>

    <h2>Advantages of Nested Try Blocks</h2>
    <ol>
      <li>
        <strong>Precision in Exception Handling:</strong> Different exceptions can be caught and managed at the right
        place.
      </li><br/>
      <li>
        <strong>Prevents Program Termination:</strong> The program doesn’t crash because exceptions are handled
        appropriately.
      </li><br/>
      <li>
        <strong>Improved Readability:</strong> Divides error-prone operations into smaller, manageable sections.
      </li>
    </ol>

    <br/>

    <h2>Disadvantages of Nested Try Blocks</h2>
    <ol>
      <li>
        <strong>Complexity:</strong> Too many nested try blocks can make the code harder to read and debug.
      </li><br/>
      <li>
        <strong>Maintainability:</strong> Deeply nested structures can be difficult to modify or understand.
      </li>
    </ol>

    <br/>

    <h2>Best Practices</h2>
    <ol>
      <li>
        <strong>Avoid Excessive Nesting:</strong> If possible, break the code into smaller methods instead of deeply
        nesting try blocks.
      </li><br/>
      <li>
        <strong>Handle Exceptions at the Right Level:</strong> Use inner try blocks for exceptions specific to a small
        piece of code.
      </li><br/>
      <li>
        <strong>Provide Meaningful Messages:</strong> Always log or display useful information in catch blocks.
      </li>
    </ol><br/>

    <p>
      Nested try blocks are a great tool for handling exceptions in complex scenarios. Use them wisely to maintain
      clarity and effectiveness in your code. 
    </p>
  </div>
)}



{selectedChapter === 'chapter99' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Finally Block  </h1>

    <p>
      In Java, the <code>finally</code> block is an essential part of exception handling. It is used to execute a block of code after a <code>try-catch</code> block, regardless of whether an exception was thrown or not. This makes it perfect for tasks that must always be completed, such as closing file streams, database connections, or releasing resources.
    </p><br/>

    <h3>Why Use the Finally Block?</h3>
    <ul>
      <li><strong>Ensure Code Execution:</strong> The <code>finally</code> block will always execute, even if no exception occurs or if one is thrown and caught. This is useful for cleanup tasks.</li><br/>
      <li><strong>Resource Management:</strong> You can use the <code>finally</code> block to release resources like file handles, network connections, or database connections, ensuring they are closed properly.</li><br/>
      <li><strong>Prevent Resource Leaks:</strong> Even when an exception is thrown, the <code>finally</code> block ensures that resources are not left open, preventing resource leaks.</li>
    </ul><br/>

    <h3>Syntax of the Finally Block</h3>
    <pre>
      <code>
        {`try {
    // Code that may throw an exception
} catch (ExceptionType e) {
    // Handle the exception
} finally {
    // Code that will always execute, no matter what
}`}
      </code>
    </pre><br/>

    <h3>Example 1: Basic Use of the Finally Block</h3>
    <p><strong>Problem:</strong> Closing a file after performing operations.</p>
    <pre>
      <code>
        {`import java.io.*;

public class Main {
    public static void main(String[] args) {
        FileInputStream file = null;

        try {
            file = new FileInputStream("example.txt");
            // Code that may throw an exception (e.g., file not found)
            System.out.println("File is open.");
        } catch (FileNotFoundException e) {
            // Handle the exception if file is not found
            System.out.println("File not found: " + e);
        } finally {
            // This block will execute regardless of whether an exception occurred or not
            try {
                if (file != null) {
                    file.close(); // Ensure the file is closed
                    System.out.println("File closed.");
                }
            } catch (IOException e) {
                System.out.println("Error closing file: " + e);
            }
        }
    }
}`}
      </code>
    </pre><br/>

    <h3>Example 2: Finally Block with Return Statement</h3>
    <p><strong>Problem:</strong> Demonstrating return inside <code>try</code> and <code>finally</code> blocks.</p>
    <pre>
      <code>
        {`public class Main {
    public static void main(String[] args) {
        System.out.println(testMethod());
    }

    public static String testMethod() {
        try {
            System.out.println("Inside try block");
            return "Returned from try block"; // This return is executed first
        } catch (Exception e) {
            System.out.println("Inside catch block");
            return "Returned from catch block"; // This return will be ignored if there's no exception
        } finally {
            System.out.println("Inside finally block");
            // The finally block will execute before the method actually returns
        }
    }
}`}
      </code>
    </pre><br/>

    <h3>Important Points to Remember</h3>
    <ul>
      <li><strong>Execution Order:</strong> The <code>finally</code> block always runs after the <code>try</code> block (and the <code>catch</code> block if an exception was thrown). It runs regardless of whether an exception was thrown or not.</li><br/>
      <li><strong>Cannot Be Skipped:</strong> The <code>finally</code> block is executed even if there’s a <code>return</code> in the <code>try</code> or <code>catch</code> blocks. However, the <code>finally</code> block will run before the method completes, so if there’s a return in the <code>finally</code>, it can override the return value from the <code>try</code> or <code>catch</code>.</li><br/>
      <li><strong>Exceptions in Finally:</strong> If an exception is thrown inside the <code>finally</code> block, it can prevent the program from continuing as expected. Always handle exceptions inside the <code>finally</code> block to avoid this.</li>
    </ul><br/>

    <h3>Advantages of Using Finally</h3>
    <ul>
      <li><strong>Consistency:</strong> It ensures that cleanup code is always executed, which is especially important for resource management (e.g., closing files, database connections).</li><br/>
      <li><strong>Reliability:</strong> Helps make your program more reliable by ensuring certain actions (like cleanup or closing resources) are always performed.</li>
    </ul><br/>

    <h3>Disadvantages of Using Finally</h3>
    <ul>
      <li><strong>Overuse Can Cause Confusion:</strong> Relying too much on <code>finally</code> for important logic can make your code harder to maintain and understand, especially if it contains complex exception-handling logic.</li><br/>
      <li><strong>Performance Overhead:</strong> If the code in the <code>finally</code> block is too resource-intensive, it might affect the performance, especially in programs that use <code>finally</code> frequently.</li>
    </ul><br/>

    <h3>Best Practices</h3>
    <ul>
      <li><strong>Use Finally for Cleanup:</strong> Always use the <code>finally</code> block for releasing resources (like file handles, database connections, etc.), so they are closed even if an exception occurs.</li><br/>
      <li><strong>Avoid Using <code>return</code> in Finally:</strong> Avoid using a <code>return</code> statement inside the <code>finally</code> block, as it can obscure the intent of the method and cause unexpected behavior.</li><br/>
      <li><strong>Keep the Finally Block Simple:</strong> Avoid placing complex logic inside the <code>finally</code> block, as its purpose is to perform essential cleanup, not to handle business logic.</li>
    </ul><br/>

    <p>The <code>finally</code> block is a powerful tool in Java's exception-handling mechanism, ensuring that crucial cleanup actions always happen, even when errors occur.</p>
  </div>
)}




{selectedChapter === 'chapter100' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java `throw` Keyword  </h1>

    <p>
      The <code>throw</code> keyword in Java is used to explicitly throw an exception from a method or block of code. It allows developers to create their own exceptions and handle them in a custom way. This is different from exceptions being thrown automatically by Java’s runtime system (like <code>NullPointerException</code>, <code>ArithmeticException</code>, etc.). Using <code>throw</code>, you can define when and where to throw specific exceptions, giving you more control over exception handling in your programs.
    </p><br/>

    <h3>Why Use the `throw` Keyword?</h3>
    <ul>
      <li><strong>Custom Exception Handling:</strong> You can define specific conditions in your code where you want to throw exceptions. This is useful for handling edge cases, invalid input, or other error conditions that might not be covered by built-in exceptions.</li><br/>
      <li><strong>Improved Error Reporting:</strong> By throwing your own exceptions, you can include meaningful messages or details that help with debugging or understanding why an error occurred.</li><br/>
      <li><strong>Flow Control:</strong> Throwing exceptions intentionally allows you to break out of loops, methods, or code blocks when an error is detected.</li>
    </ul>
    <br/>
    <h3>Syntax of the `throw` Keyword</h3>
    <pre><code>
      {`throw new ExceptionType("Error message");`}
    </code></pre>
    <p>
      - <strong><code>new ExceptionType</code></strong>: Creates a new instance of an exception. You can use built-in exceptions like <code>NullPointerException</code>, <code>IllegalArgumentException</code>, or create your own custom exception class.<br />
      - <strong>"Error message"</strong>: A descriptive message that provides more information about the exception.
    </p><br/>

    <h3>Example 1: Throwing a Built-in Exception</h3>
    <p><strong>Problem:</strong> Throwing an exception when an invalid number is entered.</p>
    <pre><code>
{`import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);

        System.out.println("Enter a positive number:");

        int number = scanner.nextInt();

        if (number < 0) {
            // Throwing an IllegalArgumentException if the number is negative
            throw new IllegalArgumentException("Number cannot be negative.");
        } else {
            System.out.println("You entered a valid number: " + number);
        }

        scanner.close();
    }
}`}
    </code></pre><br/>

    <h3>Example 2: Throwing a Custom Exception</h3>
    <p><strong>Problem:</strong> Throwing a custom exception when a user tries to withdraw more money than they have.</p>
    <pre><code>
{`class InsufficientBalanceException extends Exception {
    public InsufficientBalanceException(String message) {
        super(message);
    }
}

public class Main {
    public static void main(String[] args) {
        double balance = 500.00;
        double withdrawalAmount = 600.00;

        try {
            if (withdrawalAmount > balance) {
                // Throwing a custom exception
                throw new InsufficientBalanceException("Insufficient balance for withdrawal.");
            } else {
                balance -= withdrawalAmount;
                System.out.println("Withdrawal successful. New balance: " + balance);
            }
        } catch (InsufficientBalanceException e) {
            // Handling the custom exception
            System.out.println("Error: " + e.getMessage());
        }
    }
}`}
    </code></pre><br/>

    <h3>Key Points to Remember About the `throw` Keyword</h3>
    <ul>
      <li><strong>Throws Exception from Code:</strong> The <code>throw</code> keyword is used to explicitly throw an exception when a specific condition occurs. The exception could be built-in or a custom one.</li><br/>
      <li><strong>Control Flow:</strong> Once an exception is thrown using <code>throw</code>, the normal flow of the program is disrupted, and control moves to the nearest <code>catch</code> block (if it exists) or the calling method.</li><br/>
      <li><strong>Checked vs Unchecked Exceptions:</strong>
        <ul>
          <li>If you throw a checked exception (like <code>IOException</code>), you need to declare it in the method signature using the <code>throws</code> keyword.</li><br/>
          <li>Unchecked exceptions (like <code>RuntimeException</code>) do not require explicit declaration.</li>
        </ul>
      </li>
    </ul><br/>

    <h3>Example 3: Throwing and Catching Exceptions</h3>
    <pre><code>
{`public class Main {
    public static void main(String[] args) {
        try {
            validateAge(15);
        } catch (IllegalArgumentException e) {
            System.out.println("Caught Exception: " + e.getMessage());
        }
    }

    // Method to validate age
    public static void validateAge(int age) {
        if (age < 18) {
            // Throwing an exception if age is less than 18
            throw new IllegalArgumentException("Age must be 18 or older.");
        }
        System.out.println("Age is valid.");
    }
}`}
    </code></pre><br/>

    <h3>Advantages of Using the `throw` Keyword</h3>
    <ul>
      <li><strong>Custom Error Handling:</strong> You can define your own exceptions for specific situations, improving the clarity and accuracy of error reporting.</li><br/>
      <li><strong>Enhanced Debugging:</strong> With custom exceptions and detailed messages, you can track down the exact location and reason for errors more easily.</li><br/>
      <li><strong>Flow Control:</strong> The <code>throw</code> keyword can be used for handling specific error conditions that would otherwise disrupt program execution.</li>
    </ul><br/>

    <h3>Disadvantages of Using the `throw` Keyword</h3>
    <ul>
      <li><strong>Overuse Can Lead to Complex Code:</strong> Throwing too many exceptions, especially custom ones, can lead to cluttered and hard-to-maintain code.</li><br/>
      <li><strong>Performance Impact:</strong> Throwing and handling exceptions can impact the performance of your application if overused or used in critical performance-sensitive sections of the code.</li>
    </ul><br/>

    <h3>Best Practices</h3>
    <ul>
      <li><strong>Use Throw for Validation:</strong> Use the <code>throw</code> keyword for input validation and handling specific conditions like negative numbers, invalid data, etc.</li><br/>
      <li><strong>Create Meaningful Custom Exceptions:</strong> When creating custom exceptions, ensure they are meaningful and provide enough information to help with debugging.</li><br/>
      <li><strong>Avoid Throwing Exceptions in Loops:</strong> Avoid throwing exceptions in loops unless absolutely necessary, as it can cause performance issues.</li>
    </ul><br/>

    <p>
      In conclusion, the <code>throw</code> keyword allows for a more controlled and flexible exception handling mechanism in Java. It lets you raise exceptions when needed, whether they are standard Java exceptions or custom ones you create.
    </p>
  </div>
)}




{selectedChapter === 'chapter101' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Understanding the `throws` Keyword in Java</h1>

    <p>
      The <code>throws</code> keyword in Java is used to declare that a method can throw one or more exceptions during its execution. Unlike the <code>throw</code> keyword, which is used to explicitly throw an exception, <code>throws</code> is used in a method signature to indicate that the method might throw certain exceptions, and it allows the caller of the method to handle those exceptions.
    </p><br/>

    <h2>Why Use <code>throws</code>?</h2>
    <p>
      When a method might cause an exception (like reading from a file, dividing numbers, or working with input from a user), Java wants to make sure that the exception is properly handled. The <code>throws</code> keyword is a way of warning the developer that the method could cause an exception, and that the developer should handle it properly using <code>try-catch</code> or re-throw it.
    </p><br/>

    <h2>How to Use <code>throws</code></h2>
    <p>The syntax for the <code>throws</code> keyword is as follows:</p>
    <pre>
      <code>
{`public returnType methodName() throws ExceptionType {
  // method body
}`}
      </code>
    </pre><br/>
    <ul>
      <li><strong>ExceptionType:</strong> This is the type of exception that the method can throw. It can be a built-in exception (like <code>IOException</code>, <code>SQLException</code>) or a custom exception you define.</li><br/>
      <li><strong>throws:</strong> This tells the compiler that the method might throw an exception of the specified type.</li>
    </ul><br/>

    <h2>Example of <code>throws</code> Usage</h2>
    <p>Imagine you have a method that reads data from a file. If the file does not exist, it will throw a <code>FileNotFoundException</code>.</p>

    <pre>
      <code>
{`import java.io.File;
import java.io.FileNotFoundException;
import java.util.Scanner;

public class Main {

    // Declaring that this method can throw a FileNotFoundException
    public static void readFile(String filename) throws FileNotFoundException {
        File file = new File(filename);
        Scanner scanner = new Scanner(file);  // This line may throw FileNotFoundException
        System.out.println("File contents: " + scanner.nextLine());
        scanner.close();
    }

    public static void main(String[] args) {
        try {
            // Calling the method that can throw an exception
            readFile("nonexistentfile.txt");
        } catch (FileNotFoundException e) {
            System.out.println("Error: " + e.getMessage());
        }
    }
}`}
      </code>
    </pre>

    <h3>Output:</h3>
    <pre>
      <code>
Error: nonexistentfile.txt (The system cannot find the file specified)
      </code>
    </pre><br/>

    <h4>Explanation:</h4>
    <ul>
      <li>The <code>readFile</code> method declares that it *might* throw a <code>FileNotFoundException</code> using the <code>throws</code> keyword.</li><br/>
      <li>In the <code>main</code> method, we call <code>readFile</code> inside a <code>try-catch</code> block, because we know that the method can throw an exception. If the file doesn't exist, the exception will be thrown and caught by the <code>catch</code> block.</li>
    </ul>

    <h2>Multiple Exceptions with <code>throws</code></h2>
    <p>A method can also declare that it throws more than one exception, separated by a comma.</p>
    <pre>
      <code>
{`public void myMethod() throws IOException, SQLException {
    // method body
}`}
      </code>
    </pre>
    <p>This means that the method could throw either an <code>IOException</code> or a <code>SQLException</code>.</p>
    <br/>
    <h2>Important Notes about <code>throws</code></h2>
    <ul>
      <li><strong>Checked vs Unchecked Exceptions:</strong>
        <ul>
          <li><strong>Checked exceptions</strong> are exceptions that must be either caught or declared in the method using <code>throws</code>. These include exceptions like <code>IOException</code>, <code>SQLException</code>, and <code>FileNotFoundException</code>.</li><br/>
          <li><strong>Unchecked exceptions</strong> (or runtime exceptions), such as <code>NullPointerException</code> and <code>ArrayIndexOutOfBoundsException</code>, do not need to be declared using <code>throws</code>. These are usually programming errors and are not required to be handled explicitly.</li>
        </ul>
      </li><br/>
      <li><strong>Declaring Exceptions in Methods:</strong> When you use <code>throws</code>, it is your responsibility to either handle the exception inside the method using <code>try-catch</code> or declare it in the method signature so that the calling code can handle it.</li>
    </ul><br/>

    <h2>Summary</h2>
    <ul>
      <li>The <code>throws</code> keyword in Java is used in method declarations to specify that a method can throw certain exceptions.</li><br/>
      <li>It allows the caller of the method to handle these exceptions, either by catching them or by declaring them further up the call stack.</li><br/>
      <li>It is typically used for checked exceptions, while unchecked exceptions do not need to be declared.</li>
    </ul><br/>

    <p>This mechanism helps to ensure that exceptions are handled properly and promotes better error management in Java programs.</p>
  </div>
)}



{selectedChapter === 'chapter102' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Throw vs Throws  </h1>

    <p>
      In Java, both the <code>throw</code> and <code>throws</code> keywords are related to exception handling, but they serve different purposes. Here's a simple breakdown of the differences between the two and how to use them:
    </p><br/>

    <h2>1. <code>throw</code> Keyword</h2>
    <p>
      The <code>throw</code> keyword is used to explicitly <strong>throw</strong> an exception from a method or a block of code. You can use <code>throw</code> to create custom exceptions or to trigger built-in exceptions when certain conditions arise.
    </p><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Purpose:</strong> To <strong>throw</strong> an exception explicitly during the execution of your program.</li><br/>
      <li><strong>Where it's used:</strong> Inside a method or a block of code (like <code>if</code> statements or loops).</li><br/>
      <li><strong>When it's used:</strong> You use <code>throw</code> when you want to intentionally raise an exception (either built-in or custom) to indicate that something went wrong in your program.</li>
    </ul><br/>

    <h3>Example:</h3>
    <pre>
      {`public class Main {
    public static void main(String[] args) {
        int age = 15;

        // Throwing an exception if age is less than 18
        if (age < 18) {
            throw new IllegalArgumentException("Age must be 18 or older.");
        }
    }
}`}
    </pre>

    <p>
      In this example, the <code>throw</code> keyword is used to explicitly throw an <code>IllegalArgumentException</code> if the age is less than 18.
    </p>

    <br/>

    <h2>2. <code>throws</code> Keyword</h2>
    <p>
      The <code>throws</code> keyword is used in a <strong>method declaration</strong> to specify that the method <strong>can throw</strong> one or more exceptions. It's a way of warning the caller of the method that exceptions may be thrown, and they need to handle them.
    </p><br/>

    <h3>Key Points:</h3>
    <ul>
      <li><strong>Purpose:</strong> To <strong>declare</strong> that a method might throw certain exceptions.</li><br/>
      <li><strong>Where it's used:</strong> In the method signature, not inside the method body.</li><br/>
      <li><strong>When it's used:</strong> You use <code>throws</code> when you want to indicate that a method could throw a certain exception (especially checked exceptions) during its execution, so the caller knows that they need to handle those exceptions.</li>
    </ul><br/>

    <h3>Example:</h3>
    <pre>
      {`import java.io.FileNotFoundException;
import java.io.File;
import java.util.Scanner;

public class Main {
    // Declaring that this method might throw a FileNotFoundException
    public static void readFile(String filename) throws FileNotFoundException {
        File file = new File(filename);
        Scanner scanner = new Scanner(file);
        System.out.println("File contents: " + scanner.nextLine());
        scanner.close();
    }

    public static void main(String[] args) {
        try {
            // Calling the method that might throw an exception
            readFile("nonexistentfile.txt");
        } catch (FileNotFoundException e) {
            System.out.println("Error: " + e.getMessage());
        }
    }
}`}
    </pre>

    <p>
      The method <code>readFile()</code> declares that it can throw a <code>FileNotFoundException</code> using the <code>throws</code> keyword. In the <code>main</code> method, we call <code>readFile()</code>. Since <code>readFile()</code> can throw an exception, we handle it using a <code>try-catch</code> block.
    </p>

    <br/>

    <h2>Key Differences Between <code>throw</code> and <code>throws</code>:</h2>
    <table className={style.differenceTable}>
      <thead>
        <tr>
          <th>Aspect</th>
          <th><code>throw</code></th>
          <th><code>throws</code></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Purpose</td>
          <td>Used to explicitly throw an exception.</td>
          <td>Used to declare that a method can throw exceptions.</td>
        </tr>
        <tr>
          <td>Where Used</td>
          <td>Inside a method or code block.</td>
          <td>In the method signature.</td>
        </tr>
        <tr>
          <td>Functionality</td>
          <td>Actually raises an exception.</td>
          <td>Declares that a method might throw an exception.</td>
        </tr>
        <tr>
          <td>Exception Type</td>
          <td>Can throw any type of exception (built-in or custom).</td>
          <td>Typically used for checked exceptions (like <code>IOException</code>, <code>SQLException</code>).</td>
        </tr>
        <tr>
          <td>Handling</td>
          <td>Exception is thrown immediately and must be handled or propagated.</td>
          <td>Caller must handle the declared exception using <code>try-catch</code> or propagate it further.</td>
        </tr>
      </tbody>
    </table>

    <br/>

    <h2>Example of Both <code>throw</code> and <code>throws</code> Together:</h2>
    <pre>
      {`import java.io.FileNotFoundException;
import java.util.Scanner;

public class Main {

    // This method declares that it might throw a FileNotFoundException
    public static void readFile(String filename) throws FileNotFoundException {
        if (filename == null || filename.isEmpty()) {
            // Throwing an exception explicitly if the filename is invalid
            throw new IllegalArgumentException("Filename cannot be empty or null.");
        }

        Scanner scanner = new Scanner(filename);  // This can throw a FileNotFoundException
        System.out.println("File contents: " + scanner.nextLine());
        scanner.close();
    }

    public static void main(String[] args) {
        try {
            readFile("data.txt");
        } catch (FileNotFoundException | IllegalArgumentException e) {
            // Handling both the thrown exceptions
            System.out.println("Error: " + e.getMessage());
        }
    }
}`}
    </pre>

    <p>
      The method <code>readFile</code> uses <code>throws</code> to declare that it might throw a <code>FileNotFoundException</code>. Inside the method, <code>throw</code> is used to raise an <code>IllegalArgumentException</code> if the filename is invalid. In the <code>main</code> method, both exceptions are handled using a <code>try-catch</code> block.
    </p>

    <br/>

    <h2>Summary:</h2>
    <ul>
      <li><code>throw</code> is used to <strong>throw</strong> an exception manually inside a method or a block of code.</li><br/>
      <li><code>throws</code> is used in a <strong>method signature</strong> to declare that the method might throw one or more exceptions, and it must be handled by the caller.</li>
    </ul><br/>

    <p>
      By using <code>throw</code>, you control the point at which an exception is raised, while <code>throws</code> is used to inform the caller that they need to prepare for potential exceptions.
    </p>
  </div>
)}




{selectedChapter === 'chapter103' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Exception Propagation </h1>

    <p>
      In Java, exception propagation refers to the process by which exceptions are passed from one method to another in the call stack. When an exception occurs in a method, it doesn't always have to be handled within that method. Instead, the exception can propagate (or be passed) up the call stack to the caller method, allowing the caller to handle it.
    </p><br/>

    <h2>How Exception Propagation Works:</h2>
    <ol>
      <li>
        <strong>An Exception Occurs:</strong> When an exception occurs in a method (let’s call it Method A), Java looks for a way to handle the exception. If there is a <code>try-catch</code> block within Method A that can handle the exception, it is caught and handled there.
      </li><br/>
      <li>
        <strong>If Not Handled, It Propagates:</strong> If Method A does not handle the exception (i.e., there’s no <code>catch</code> block), the exception is propagated (passed) to the calling method (Method B). If Method B does not handle the exception, it gets passed to the calling method of Method B (Method C), and so on, until the exception either:
        <ul>
          <li>Reaches a method that handles it using a <code>try-catch</code> block, or</li><br/>
          <li>Reaches the <code>main()</code> method, where if not handled, it results in the program terminating.</li>
        </ul>
      </li><br/>
      <li>
        <strong>Handled or Unhandled:</strong> If the exception is eventually caught, the program continues executing normally after the <code>catch</code> block. If no method handles the exception (and it reaches the <code>main()</code> method without being caught), the program terminates, and an error message is shown.
      </li>
    </ol><br/>

    <h2>Example of Exception Propagation:</h2>
    <p>
      Consider the following example where exceptions are not handled within the method that throws them but are propagated to the calling method:
    </p>

    <pre>
      <code>
{`import java.io.FileNotFoundException;
import java.io.FileReader;
import java.io.IOException;

public class Main {

    // Method that might throw an exception
    public static void readFile() throws IOException {
        FileReader file = new FileReader("nonexistentfile.txt");
    }

    // Method that calls the method which throws the exception
    public static void processFile() throws IOException {
        readFile();  // Exception from readFile() will be propagated here
    }

    public static void main(String[] args) {
        try {
            processFile();  // Exception from processFile() will be propagated here
        } catch (IOException e) {
            System.out.println("Caught Exception: " + e.getMessage());
        }
    }
}`}
      </code>
    </pre><br/>

    <h3>Explanation:</h3>
    <ul>
      <li>
        <strong>Method <code>readFile()</code></strong> throws an <code>IOException</code> when it tries to open a nonexistent file.
      </li><br/>
      <li>
        <strong>Method <code>processFile()</code></strong> calls <code>readFile()</code> and doesn’t catch the exception, so it propagates the exception up to the calling method (i.e., <code>main()</code>).
      </li><br/>
      <li>
        <strong>Method <code>main()</code></strong> catches the exception and handles it, preventing the program from crashing.
      </li>
    </ul><br/>

    <h2>Key Points:</h2>
    <ul>
      <li><strong>Throws Declaration:</strong> Each method that might throw an exception needs to declare the exception using the <code>throws</code> keyword. For example, <code>public static void readFile() throws IOException</code>.</li><br/>
      <li><strong>Try-Catch Block:</strong> If a method calls another method that throws an exception, it can either handle the exception using a <code>try-catch</code> block, or declare that it also might throw the exception.</li><br/>
      <li><strong>Unchecked Exceptions:</strong> Unchecked exceptions (like <code>NullPointerException</code>, <code>ArrayIndexOutOfBoundsException</code>, etc.) propagate automatically without the need for declaring them using <code>throws</code>. These do not need to be explicitly caught or declared.</li>
    </ul><br/>

    <h2>Exception Propagation Example with Multiple Methods:</h2>
    <pre>
      <code>
{`public class Main {

    public static void method1() throws ArithmeticException {
        int result = 10 / 0;  // This will throw ArithmeticException
    }

    public static void method2() throws ArithmeticException {
        method1();  // Exception from method1 is propagated here
    }

    public static void main(String[] args) {
        try {
            method2();  // Exception from method2 is propagated here
        } catch (ArithmeticException e) {
            System.out.println("Caught Exception: " + e.getMessage());
        }
    }
}`}
      </code>
    </pre><br/>

    <h3>Key Points in This Example:</h3>
    <ul>
      <li>The <code>method1()</code> throws an <code>ArithmeticException</code> when trying to divide by zero.</li><br/>
      <li>The exception is propagated from <code>method1()</code> to <code>method2()</code>, and then caught in the <code>main()</code> method.</li>
    </ul><br/>

    <h2>Summary:</h2>
    <ul>
      <li><strong>Exception propagation</strong> occurs when an exception is thrown in a method, and if it is not handled, it is passed to the calling method.</li><br/>
      <li>This process continues until the exception is either caught or reaches the <code>main()</code> method and causes the program to terminate.</li><br/>
      <li><strong><code>throws</code></strong> is used in method signatures to declare exceptions that the method might throw, while <strong><code>try-catch</code></strong> is used to handle exceptions where they occur.</li>
    </ul><br/>

    <p>
      In general, the goal of exception propagation is to allow methods to delegate exception handling to the caller, enabling better error management in large applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter104' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Final vs Finally vs Finalize  </h1>


          <p>
            In Java, <code>final</code>, <code>finally</code>, and <code>finalize</code> are related to exception handling and object management,
            but they serve different purposes. Here's a detailed yet simple explanation of the three:
          </p><br/>

          <h2>1. <code>final</code> Keyword</h2>
          <p>
            The <code>final</code> keyword is used to declare constants, prevent method overriding, and prevent inheritance. It is not specifically tied to exception handling but is often used in programs to define behavior and ensure certain values or actions cannot be modified.
          </p><br/>
          <h3>Key Points:</h3>
          <ul>
            <li><strong>Final Variables</strong>: Once assigned a value, the value cannot be changed.</li><br/>
            <li><strong>Final Methods</strong>: A method declared as <code>final</code> cannot be overridden by subclasses.</li><br/>
            <li><strong>Final Classes</strong>: A class declared as <code>final</code> cannot be subclassed (inherited).</li>
          </ul><br/>
          
          <h3>Example:</h3>
          <pre>
            <code>
{`public class Main {
  // Final variable
  final int MAX_VALUE = 100;

  // Final method
  public final void displayMessage() {
      System.out.println("This method cannot be overridden.");
  }

  // Final class
  public final class InnerClass {
      // Class cannot be inherited
  }
}`}
            </code>
          </pre><br/>

          <h2>2. <code>finally</code> Block</h2>
          <p>
            The <code>finally</code> block is used in exception handling to execute code that should always run, regardless of whether an exception is thrown or not. It is commonly used to clean up resources such as closing files, releasing database connections, etc. The <code>finally</code> block follows the <code>try-catch</code> block and will execute even if no exception occurs, or if an exception is caught.
          </p><br/>
          <h3>Key Points:</h3>
          <ul>
            <li><strong>Purpose</strong>: Ensures that important clean-up code runs after <code>try-catch</code> blocks, regardless of exception occurrence.</li><br/>
            <li><strong>When Used</strong>: Typically used to release resources (like closing files or database connections) after a <code>try-catch</code>.</li><br/>
            <li><strong>Execution</strong>: The code in the <code>finally</code> block is always executed, even if there’s a return statement in the <code>try</code> or <code>catch</code> block.</li>
          </ul><br/>

          <h3>Example:</h3>
          <pre>
            <code>
{`public class Main {
  public static void main(String[] args) {
      try {
          int result = 10 / 0;  // This will cause ArithmeticException
      } catch (ArithmeticException e) {
          System.out.println("Caught an exception: " + e.getMessage());
      } finally {
          System.out.println("This block will always execute.");
      }
  }
}`}
            </code>
          </pre><br/>

          <h2>3. <code>finalize</code> Method</h2>
          <p>
            The <code>finalize()</code> method is part of the <code>Object</code> class and is called by the garbage collector before an object is destroyed. It allows an object to clean up resources (like closing files or releasing memory) before it is removed from memory. However, its usage is not recommended due to the unpredictability of garbage collection timing and the fact that it doesn't guarantee timely execution.
          </p><br/>
          <h3>Key Points:</h3>
          <ul>
            <li><strong>Purpose</strong>: Allows objects to clean up resources before they are garbage collected.</li><br/>
            <li><strong>When Used</strong>: It's called by the garbage collector when an object is no longer reachable.</li><br/>
            <li><strong>Usage</strong>: Not recommended for most applications because it's not guaranteed to be called immediately and might delay garbage collection.</li>
          </ul><br/>

          <h3>Example:</h3>
          <pre>
            <code>
{`public class Main {
  @Override
  protected void finalize() throws Throwable {
      System.out.println("Finalize method called. Object is about to be garbage collected.");
      super.finalize();
  }

  public static void main(String[] args) {
      FinalizeExample obj = new FinalizeExample();
      obj = null;  // Dereference the object
      System.gc();  // Suggest garbage collection (not guaranteed)
  }
}`}
            </code>
          </pre><br/>

          <h2>Key Differences:</h2>
          <table>
            <thead>
              <tr>
                <th>Aspect</th>
                <th><code>final</code></th>
                <th><code>finally</code></th>
                <th><code>finalize</code></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Purpose</strong></td>
                <td>To make variables, methods, or classes unchangeable.</td>
                <td>To ensure that certain code runs after a <code>try-catch</code>.</td>
                <td>To clean up before an object is garbage collected.</td>
              </tr>
              <tr>
                <td><strong>Usage Context</strong></td>
                <td>Used in variable declarations, method definitions, and class declarations.</td>
                <td>Used after a <code>try-catch</code> block to ensure cleanup.</td>
                <td>Used to perform object clean-up tasks before garbage collection.</td>
              </tr>
              <tr>
                <td><strong>Execution Timing</strong></td>
                <td>At compile-time (e.g., constant values, method overrides).</td>
                <td>Always executed after a <code>try-catch</code>.</td>
                <td>Executed by the garbage collector, if called.</td>
              </tr>
              <tr>
                <td><strong>Common Example</strong></td>
                <td>Declaring constants, preventing method overriding, or preventing inheritance.</td>
                <td>Closing resources like files or network connections.</td>
                <td>Cleaning up before an object is collected.</td>
              </tr>
              <tr>
                <td><strong>Is it automatic?</strong></td>
                <td>No. You must explicitly define it.</td>
                <td>Yes, it runs automatically after the <code>try-catch</code>.</td>
                <td>No, it’s called by the garbage collector (and may not be called immediately).</td>
              </tr>
            </tbody>
          </table><br/>

          <h2>Summary:</h2>
          <p>
            <ul>
              <li><code>final</code> is used to define constants, prevent inheritance, or prevent overriding methods.</li><br/>
              <li><code>finally</code> is used to ensure that specific code (like clean-up) always runs after a <code>try-catch</code> block, regardless of whether an exception occurs.</li><br/>
              <li><code>finalize()</code> is a method in the <code>Object</code> class, which is called by the garbage collector before an object is removed from memory. However, it's rarely used and should be avoided in favor of other resource management strategies.</li>
            </ul>
          </p>
        </div>
      )}
   


   {selectedChapter === 'chapter105' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Exception Handling with Method Overriding    </h1>

    <p>
      In Java, <strong>method overriding</strong> and <strong>exception handling</strong> are two important concepts.
      When combined, they have specific rules that need to be followed to ensure that your program handles exceptions effectively.
      Let’s break down these concepts and see how they work together.
    </p><br/>

    <h3>Key Concepts:</h3>
    <ul>
      <li><strong>Method Overriding</strong>: This occurs when a subclass provides a specific implementation of a method that is already defined in its superclass. This is an important feature in Java for achieving runtime polymorphism.</li><br/>
      <li><strong>Exception Handling</strong>: Exception handling refers to managing errors that occur during the execution of a program. Exceptions in Java can be either <strong>checked</strong> (which need to be caught or declared) or <strong>unchecked</strong> (which don't need to be declared).</li>
    </ul><br/>

    <h3>Rules of Exception Handling in Method Overriding</h3>
    <p>When you override a method in Java that throws exceptions, you must follow certain rules regarding how exceptions are handled:</p>
    <br/>
    <h4>1. Checked Exceptions:</h4>
    <p>
      <strong>Checked exceptions</strong> are exceptions that must either be caught or declared in the method signature.
      These include exceptions like <code>IOException</code>, <code>SQLException</code>, etc.
    </p>
    <ul>
      <li>If a superclass method throws a checked exception, the subclass can throw:
        <ul>
          <li>The <strong>same type</strong> of exception.</li><br/>
          <li>A <strong>subclass</strong> of the exception thrown by the superclass.</li>
        </ul>
      </li><br/>
      <li>However, the subclass <strong>cannot throw a broader exception</strong> (e.g., if the superclass throws a <code>FileNotFoundException</code>, the subclass cannot throw a generic <code>Exception</code>).</li>
    </ul><br/>

    <h4>2. Unchecked Exceptions:</h4>
    <p>
      <strong>Unchecked exceptions</strong> are exceptions that extend <code>RuntimeException</code>. These exceptions are <strong>not required to be declared</strong> or caught explicitly in the method signature. Examples include <code>NullPointerException</code>, <code>ArithmeticException</code>, etc.
    </p>
    <ul>
      <li>A subclass method can throw an unchecked exception, even if the superclass method does not throw any.</li>
    </ul><br/>

    <h4>3. Exception Handling Behavior:</h4>
    <p>The subclass method can either:</p>
    <ul>
      <li><strong>Declare</strong> the same exception or a subclass of it.</li><br/>
      <li><strong>Catch</strong> the exception inside the method using a <code>try-catch</code> block.</li><br/>
      <li><strong>Propagate</strong> the exception using the <code>throws</code> keyword if it cannot handle the exception itself.</li>
    </ul><br/>

    <h3>Example of Exception Handling with Method Overriding</h3>

    <h4>Step 1: Create the Superclass</h4>
    <pre>
      <code>{`class Animal {
  // Superclass method that throws a checked exception
  public void makeSound() throws Exception {
    System.out.println("Animal makes a sound");
  }
}`}</code>
    </pre><br/>

    <h4>Step 2: Create the Subclass that Overrides the Method</h4>
    <pre>
      <code>{`class Dog extends Animal {
  // Subclass overrides the method and declares the same exception type
  @Override
  public void makeSound() throws Exception {
    System.out.println("Dog barks");
  }
}

class Cat extends Animal {
  // Subclass overrides the method and declares a specific subclass of the exception
  @Override
  public void makeSound() throws IOException {
    System.out.println("Cat meows");
  }
}`}</code>
    </pre><br/>

    <h4>Step 3: Handle the Exception in the Main Method</h4>
    <pre>
      <code>{`public class Main {
  public static void main(String[] args) {
    try {
      Animal animal = new Dog();
      animal.makeSound();  // Dog barks

      animal = new Cat();
      animal.makeSound();  // Cat meows

    } catch (Exception e) {
      System.out.println("Exception caught: " + e.getMessage());
    }
  }
}`}</code>
    </pre><br/>

    <h3>Explanation of the Example:</h3>
    <ol>
      <li><strong>Superclass (Animal)</strong>: The <code>makeSound()</code> method in the <code>Animal</code> class declares that it throws a general <code>Exception</code>. This is a <strong>checked exception</strong>.</li><br/>
      <li><strong>Subclass (Dog)</strong>: The <code>Dog</code> class overrides the <code>makeSound()</code> method and declares that it throws the same type of exception (<code>Exception</code>). This is allowed because the exception in the subclass is the same as the one declared by the superclass.</li><br/>
      <li><strong>Subclass (Cat)</strong>: The <code>Cat</code> class also overrides <code>makeSound()</code>, but it declares a more specific exception (<code>IOException</code>), which is a subclass of <code>Exception</code>. This is allowed because the subclass can declare a more specific exception.</li><br/>
      <li><strong>Method Invocation in Main</strong>: In the <code>main()</code> method, we invoke the <code>makeSound()</code> method on both <code>Dog</code> and <code>Cat</code> objects. Both methods can throw the exceptions they declared, which are then caught in the <code>catch</code> block.</li>
    </ol><br/>

    <h3>Key Points to Remember:</h3>
    <ul>
      <li><strong>Checked Exceptions</strong>: A subclass method can throw the same type of checked exception or a subclass of the exception declared in the superclass method.</li><br/>
      <li><strong>Unchecked Exceptions</strong>: A subclass method can throw unchecked exceptions, even if the superclass method doesn't declare them.</li><br/>
      <li><strong>Exception Propagation</strong>: If the subclass method doesn’t catch an exception, it must declare it using the <code>throws</code> keyword so that the caller can handle it.</li>
    </ul><br/>

    <h3>Summary:</h3>
    <p>
      When overriding methods in Java, you need to ensure that exceptions are handled properly. If a method in the superclass declares a checked exception, the subclass can throw the same type of exception or a subclass of it. Unchecked exceptions, on the other hand, can be thrown without restrictions. Always remember to catch or declare exceptions when necessary to maintain proper exception handling in your program.
    </p>
  </div>
)}




{selectedChapter === 'chapter106' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Custom Exceptions  </h1>
    
    <p>
      In Java, exceptions are used to handle errors or exceptional conditions that occur during the execution of a program. Java provides several built-in exceptions, but sometimes you may need to create your own exception classes to handle specific types of errors in your application. These are called <strong>custom exceptions</strong>.
    </p><br/>
    
    <p>
      A custom exception is a class that extends either <code>Exception</code> (for checked exceptions) or <code>RuntimeException</code> (for unchecked exceptions). You can use custom exceptions to handle unique error conditions that aren’t covered by standard Java exceptions.
    </p><br/>

    <h2>Steps to Create a Custom Exception:</h2>
    <ul>
      <li><strong>Define a new class</strong> that extends <code>Exception</code> or <code>RuntimeException</code>.</li><br/>
      <li><strong>Provide constructors</strong> to initialize the exception with a custom message or a cause (another exception).</li><br/>
      <li>Optionally, you can add extra fields or methods to the custom exception class.</li>
    </ul><br/>

    <h2>Types of Custom Exceptions:</h2>
    <ul>
      <li><strong>Checked Exceptions</strong>: These exceptions are subclasses of the <code>Exception</code> class. They must be either caught using a <code>try-catch</code> block or declared in the method signature using the <code>throws</code> keyword.</li><br/>
      <li><strong>Unchecked Exceptions</strong>: These exceptions are subclasses of <code>RuntimeException</code>. They do not need to be declared or caught explicitly.</li>
    </ul><br/>

    <h2>Example of a Checked Custom Exception:</h2>

    <h3>Step 1: Create a Custom Exception Class</h3>
    <pre>
      <code>{`// Custom checked exception
class InsufficientBalanceException extends Exception {
    public InsufficientBalanceException(String message) {
        super(message);  // Pass the message to the Exception class constructor
    }
}

      `}</code>
    </pre><br/>

    <h3>Step 2: Use the Custom Exception</h3>
    <pre>
      <code>{`// Custom checked exception
class InsufficientBalanceException extends Exception {
    public InsufficientBalanceException(String message) {
        super(message);  // Pass the message to the Exception class constructor
    }
}

// BankAccount class
class BankAccount {
    private double balance;

    public BankAccount(double balance) {
        this.balance = balance;
    }

    public void withdraw(double amount) throws InsufficientBalanceException {
        if (amount > balance) {
            throw new InsufficientBalanceException("Insufficient balance for withdrawal");
        }
        balance -= amount;
        System.out.println("Withdrawal successful, new balance: " + balance);
    }
}

// Main class to test the BankAccount
public class Main {
    public static void main(String[] args) {
        BankAccount account = new BankAccount(1000.00);

        try {
            account.withdraw(1200.00);  // This will throw the custom exception
        } catch (InsufficientBalanceException e) {
            System.out.println("Error: " + e.getMessage());  // Handle the custom exception
        }
    }
}`}</code>
    </pre><br/>

    <h2>Example of an Unchecked Custom Exception:</h2>

    <h3>Step 1: Create the Custom Unchecked Exception</h3>
    <pre>
      <code>{`// Custom unchecked exception (extends RuntimeException)
class DivisionByZeroException extends RuntimeException {
    public DivisionByZeroException(String message) {
        super(message);  // Pass the message to the RuntimeException constructor
    }
}

      `}</code>
    </pre><br/>

    <h3>Step 2: Use the Custom Unchecked Exception</h3>
    <pre>
      <code>{`// Custom unchecked exception (extends RuntimeException)
class DivisionByZeroException extends RuntimeException {
    public DivisionByZeroException(String message) {
        super(message);  // Pass the message to the RuntimeException constructor
    }
}

// Calculator class with divide method
class Calculator {
    public int divide(int dividend, int divisor) {
        if (divisor == 0) {
            throw new DivisionByZeroException("Cannot divide by zero");
        }
        return dividend / divisor;
    }
}

// Main class to test the division
public class Main {
    public static void main(String[] args) {
        Calculator calculator = new Calculator();

        try {
            int result = calculator.divide(10, 0);  // This will throw the custom exception
        } catch (DivisionByZeroException e) {
            System.out.println("Error: " + e.getMessage());  // Handle the custom exception
        }
    }
}`}</code>
    </pre><br/>

    <h2>Why Use Custom Exceptions?</h2>
    <ul>
      <li><strong>Better Control Over Error Handling</strong>: Custom exceptions help you create specific error handling for unique situations that built-in exceptions might not cover.</li><br/>
      <li><strong>Improve Readability</strong>: Using custom exceptions with meaningful names makes the code easier to read and understand. For example, <code>InsufficientBalanceException</code> is much clearer than just catching a generic <code>Exception</code>.</li><br/>
      <li><strong>Maintainability</strong>: Custom exceptions help in maintaining and extending your codebase. If a new error condition arises, you can create a custom exception and add the necessary logic to handle it.</li>
    </ul><br/>

    <h2>Key Points:</h2>
    <ul>
      <li>Custom exceptions are useful for handling specific error conditions.</li><br/>
      <li>A custom exception is created by extending <code>Exception</code> (for checked exceptions) or <code>RuntimeException</code> (for unchecked exceptions).</li><br/>
      <li><strong>Checked exceptions</strong> must be caught or declared, while <strong>unchecked exceptions</strong> can be thrown without explicit handling.</li><br/>
      <li>Use custom exceptions to make your error handling more meaningful and easier to maintain.</li>
    </ul><br/>

    <h2>Summary:</h2>
    <p>
      <strong>Custom exceptions</strong> provide a way to handle specific errors that built-in Java exceptions do not cover.
    </p><br/>
    <p>
      You create a custom exception by extending <code>Exception</code> (for checked exceptions) or <code>RuntimeException</code> (for unchecked exceptions).
    </p><br/>
    <p>
      Using custom exceptions makes your code more readable, maintainable, and easier to debug.
    </p><br/>

    <p>
      Custom exceptions are a powerful tool for managing errors in Java, ensuring that your code handles specific conditions in a predictable way.
    </p>
  </div>
)}




{selectedChapter === 'chapter107' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java File Handling  </h1>

    <p>
      Java provides a comprehensive set of classes for handling files. File handling is essential for reading from and writing to files, storing data, and managing the contents of files in a program. Java provides the <code>java.io</code> package, which includes classes and interfaces for file operations. Let's break down how Java handles files in a simple and easy-to-understand way.
    </p><br/>

    <h2>Key Concepts in Java File Handling</h2>
    <ul>
      <li><strong>File Class:</strong> The <code>File</code> class in Java is used to represent a file or directory path in the system. It does not deal with file contents but rather allows you to manipulate files and directories (like creating, deleting, checking for existence, etc.).</li><br/>
      <li><strong>Input and Output Streams:</strong> To read from or write to files, Java uses two types of streams:
        <ul>
          <li><strong>Input Stream:</strong> For reading data from a source (e.g., a file).</li><br/>
          <li><strong>Output Stream:</strong> For writing data to a destination (e.g., a file).</li>
        </ul><br/>
      </li>
      <li><strong>Readers and Writers:</strong> These are used for reading and writing character data (text files), while streams are used for byte data (binary files).</li><br/>
      <li><strong>Exceptions:</strong> File operations in Java can throw exceptions, especially when trying to open a non-existent file or when there is a permission issue. Handling these exceptions is crucial.</li>
    </ul><br/>

    <h2>Basic File Operations in Java</h2>
    <ol>
      <li><strong>Creating a File:</strong> You can create a file in Java using the <code>File</code> class. It’s also possible to check if a file already exists before creating it.</li><br/>
      <li><strong>Reading from a File:</strong> Java provides <code>FileReader</code>, <code>BufferedReader</code>, and other classes to read data from a file.</li><br/>
      <li><strong>Writing to a File:</strong> You can write data to a file using <code>FileWriter</code>, <code>BufferedWriter</code>, or <code>PrintWriter</code>.</li><br/>
      <li><strong>Deleting a File:</strong> Files can be deleted using the <code>delete()</code> method of the <code>File</code> class.</li>
    </ol><br/>

    <h2>Example of Basic File Handling in Java</h2>

    <h3>1. Creating a File</h3>
    <pre>
      <code>{`import java.io.File;
import java.io.IOException;

        public class Main {
            public static void main(String[] args) {
                File file = new File("example.txt");

                try {
                    if (file.createNewFile()) {
                        System.out.println("File created: " + file.getName());
                    } else {
                        System.out.println("File already exists.");
                    }
                } catch (IOException e) {
                    System.out.println("An error occurred.");
                    e.printStackTrace();
           }
      }
}`}</code>
    </pre>

    <p><strong>Explanation:</strong> <code>File.createNewFile()</code> creates a new file if it doesn’t already exist. If the file exists, it simply returns <code>false</code>.</p>
    <br/>
    <h3>2. Writing to a File</h3>
    <pre>
      <code>{`import java.util.Scanner;
import java.io.FileWriter;
import java.io.IOException;

public class Main{
    public static void main(String[]args){
        try{
            FileWriter obj = new FileWriter("XYZ.txt");
            System.out.println("Enter your message");
            Scanner in = new Scanner(System.in);
            String s = in.nextLine();
            obj .write(s);
            obj.close();
            System.out.println("Successfully wrote into the file");
            
        }
        catch(IOException e){
            System.out.println("An error occured");
        }
        
    }
}`}</code>
    </pre>

    <p><strong>Explanation:</strong> The <code>FileWriter</code> class writes data to a file. The file is created if it doesn't exist, and it will overwrite the file if it does exist.</p>
    <br/>
    <h3>3. Reading from a File</h3>
    <pre>
      <code>{`import java.io.FileReader;
import java.io.BufferedReader;
import java.io.IOException;

public class Main {
    public static void main(String[] args) {
        try {
            FileReader reader = new FileReader("example.txt");
            BufferedReader bufferedReader = new BufferedReader(reader);

                    String line;
                    while ((line = bufferedReader.readLine()) != null) {
                        System.out.println(line);
                    }
                    bufferedReader.close();
                } catch (IOException e) {
                    System.out.println("An error occurred while reading the file.");
                    e.printStackTrace();
            }
      }
}`}</code>
    </pre>

    <p><strong>Explanation:</strong> <code>BufferedReader.readLine()</code> reads the file line by line. You can then process or print each line to the console. Always close the reader after use to free up system resources.</p>
    <br/>
    <h3>4. Deleting a File</h3>
    <pre>
      <code>{`import java.io.File;

        public class Main {
            public static void main(String[] args) {
                File file = new File("example.txt");

                if (file.delete()) {
                    System.out.println("File deleted successfully.");
                } else {
                    System.out.println("Failed to delete the file.");
             }
      }
}`}</code>
    </pre>

    <p><strong>Explanation:</strong> <code>file.delete()</code> deletes the file. It returns <code>true</code> if the file was successfully deleted, otherwise <code>false</code>.</p>
    <br/>
    <h2>File <br/>Handling Methods Summary</h2>
    <ul>
      <li><strong>Creating a File:</strong> <code>file.createNewFile()</code> creates a new file.</li><br/>
      <li><strong>Writing to a File:</strong> <code>FileWriter</code> and <code>BufferedWriter</code> are used for writing data to files.</li><br/>
      <li><strong>Reading from a File:</strong> <code>FileReader</code> and <code>BufferedReader</code> are used for reading file content.</li><br/>
      <li><strong>Deleting a File:</strong> <code>file.delete()</code> removes a file from the system.</li>
    </ul><br/>

    <h2>Key Points to Remember:</h2>
    <ul>
      <li><strong>Streams vs Readers/Writers:</strong> Streams work with byte data (e.g., images), while readers/writers work with character data (e.g., text).</li><br/>
      <li><strong>File Operations Exceptions:</strong> File operations often throw <code>IOException</code>, so it’s important to handle exceptions properly.</li><br/>
      <li><strong>Resource Management:</strong> Always close file streams after use to avoid memory leaks and other issues.</li>
    </ul><br/>

    <h2>Why Use File Handling in Java?</h2>
    <ul>
      <li><strong>Persistence:</strong> Save data and retrieve it later.</li><br/>
      <li><strong>Data Exchange:</strong> Share data between different applications or systems.</li><br/>
      <li><strong>Configuration:</strong> Store settings, user preferences, etc.</li>
    </ul><br/>

    <p>In summary, Java file handling is a simple yet powerful way to work with files, whether you’re writing, reading, or deleting them. By using the <code>java.io</code> package, Java makes it easy to perform file operations and handle exceptions that may occur during these operations.</p>
  </div>
)}




{selectedChapter === 'chapter108' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Create/Write Files  </h1>

    <p>
      In Java, file handling allows you to create and write to files using various classes provided by the <code>java.io</code> package. These classes make it easy to create new files, write content to them, and handle potential exceptions that may arise during file operations.
    </p>

    <p>Let's break down how you can <strong>create</strong> and <strong>write</strong> to files in Java step by step, with simple examples.</p>
    <br/>
    <h2>Key Concepts in Java File Creation and Writing</h2>
    <ul>
      <li><strong>File Class:</strong> The <code>File</code> class represents the file or directory path in the system. It is used to create, delete, and check file existence, but not for reading or writing content to the file.</li><br/>
      <li><strong>FileWriter Class:</strong> The <code>FileWriter</code> class is used to write character data to files. It is ideal for writing text files.</li><br/>
      <li><strong>BufferedWriter Class:</strong> This is a wrapper around <code>FileWriter</code> and allows for more efficient writing of data. It buffers the characters to make file writing faster, especially when writing large amounts of text.</li>
    </ul><br/>

    <h2>Steps to Create and Write to a File in Java</h2>
    <ol>
      <li><strong>Create a File:</strong> Use the <code>File</code> class to create a new file. If the file already exists, it will not be created again.</li><br/>
      <li><strong>Write to the File:</strong> Use the <code>FileWriter</code> or <code>BufferedWriter</code> class to write data to the file.</li>
    </ol><br/>

    <h2>Example: Create and Write to a File in Java</h2>

    <h3>Step 1: Create a New File</h3>
    <pre>
      <code>{`import java.io.File;
import java.io.IOException;

        public class Main {
            public static void main(String[] args) {
                // Create a new file object representing the file
                File file = new File("example.txt");

                try {
                    // Create the file if it does not exist
                    if (file.createNewFile()) {
                        System.out.println("File created: " + file.getName());
                    } else {
                        System.out.println("File already exists.");
                    }
                } catch (IOException e) {
                    System.out.println("An error occurred.");
                    e.printStackTrace();
              }
       }
}`}</code>
    </pre>

    <p><strong>Explanation:</strong> <code>File.createNewFile()</code> creates a new file if it doesn’t already exist. If the file exists, it returns <code>false</code>.</p>
    <br/>
    <h3>Step 2: Write to the File</h3>
    <pre>
      <code>{`import java.io.FileWriter;
import java.io.IOException;

        public class Main {
            public static void main(String[] args) {
                try {
                    // Create a FileWriter to write to a file
                    FileWriter writer = new FileWriter("example.txt");

                    // Write text to the file
                    writer.write("Hello, this is an example of file writing in Java!");

                    // Close the writer to save the changes
                    writer.close();

                    System.out.println("Successfully wrote to the file.");
                } catch (IOException e) {
                    System.out.println("An error occurred while writing to the file.");
                    e.printStackTrace();
                }
         }
}`} </code>
    </pre>

    <p><strong>Explanation:</strong> <code>FileWriter</code> writes text to the file. It creates the file if it doesn’t exist and overwrites it if it does. The <code>writer.write()</code> method writes the string, and <code>writer.close()</code> ensures the data is written and resources are freed.</p>
    <br/>
    <h3>Step 3: Writing Multiple Lines to the File with <code>BufferedWriter</code></h3>
    <pre>
      <code>{`import java.io.FileWriter;
import java.io.BufferedWriter;
import java.io.IOException;

        public class Main {
            public static void main(String[] args) {
                try {
                    // Create a FileWriter and BufferedWriter to write to a file
                    FileWriter writer = new FileWriter("example.txt");
                    BufferedWriter bufferedWriter = new BufferedWriter(writer);

                    // Write multiple lines to the file
                    bufferedWriter.write("Hello, this is the first line.");
                    bufferedWriter.newLine(); // Inserts a new line
                    bufferedWriter.write("This is the second line.");

                    // Close the buffered writer to save the changes
                    bufferedWriter.close();

                    System.out.println("Successfully wrote to the file with BufferedWriter.");
                } catch (IOException e) {
                    System.out.println("An error occurred while writing to the file.");
                    e.printStackTrace();
                }
        }
}`}</code>
    </pre>

    <p><strong>Explanation:</strong> The <code>BufferedWriter</code> class wraps around <code>FileWriter</code> to improve writing performance, especially when handling larger files or more complex data. The <code>newLine()</code> method adds a line break to make the content more readable.</p>
    <br/>
    <h2>Key Points to Remember</h2>
    <ul>
      <li><strong>Create File:</strong> Use <code>File.createNewFile()</code> to create a new file if it doesn't exist.</li><br/>
      <li><strong>Write to File:</strong> Use <code>FileWriter</code> or <code>BufferedWriter</code> to write data to a file.</li><br/>
      <li><strong>BufferedWriter for Performance:</strong> For writing large amounts of data, <code>BufferedWriter</code> can help improve performance.</li><br/>
      <li><strong>Exception Handling:</strong> Always handle exceptions when performing file operations using <code>try-catch</code>.</li><br/>
      <li><strong>Close Resources:</strong> Always close the writer (<code>FileWriter</code> or <code>BufferedWriter</code>) to release system resources.</li>
    </ul><br/>

    <h2>Summary</h2>
    <p>
      In Java, creating and writing files is simple with the <code>File</code>, <code>FileWriter</code>, and <code>BufferedWriter</code> classes. <code>File</code> handles file creation, while <code>FileWriter</code> and <code>BufferedWriter</code> are used for writing text. <code>BufferedWriter</code> is preferred for large amounts of text or multiple lines. Proper exception handling and resource management are important to ensure smooth operations.
    </p>
  </div>
)}




{selectedChapter === 'chapter109' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Read Files  </h1>

    <p>
      Reading files is an essential operation in many programs, allowing you to access stored data for further processing. In Java, reading files can be done through classes provided in the <code>java.io</code> package. The process involves opening a file, reading its contents, and closing the file to release system resources. Let's break down the process of reading files in Java with a simple explanation and example code.
    </p><br/>

    <h2>Key Concepts in Java File Reading</h2>
    <ul>
      <li><strong>FileReader Class:</strong> The <code>FileReader</code> class is used to read character data from a file. It is useful for reading text files (character-based files).</li><br/>
      <li><strong>BufferedReader Class:</strong> A wrapper around <code>FileReader</code> that allows for more efficient reading by buffering the data. It reads data in chunks, making it faster when dealing with large files.</li><br/>
      <li><strong>File Input Stream:</strong> For reading binary data (e.g., images, audio), you can use the <code>FileInputStream</code> class. However, for text files, <code>FileReader</code> and <code>BufferedReader</code> are more commonly used.</li><br/>
      <li><strong>Exception Handling:</strong> File operations in Java can throw <code>IOException</code>, so it is important to handle exceptions using a <code>try-catch</code> block.</li>
    </ul><br/>

    <h2>Steps to Read from a File in Java</h2>
    <ol>
      <li><strong>Open the File:</strong> Create a <code>FileReader</code> or <code>BufferedReader</code> object to read from the file.</li><br/>
      <li><strong>Read the Data:</strong> Use methods like <code>read()</code> (for <code>FileReader</code>) or <code>readLine()</code> (for <code>BufferedReader</code>) to get the content.</li><br/>
      <li><strong>Close the File:</strong> Always close the file to free up system resources after the operation is done.</li>
    </ol><br/>

    <h2>Example: Read Data from a File in Java</h2>

    <h3>1. Simple File Reading Using <code>FileReader</code></h3>

    <pre><code>{`import java.io.FileReader;
import java.io.IOException;

public class Main {
    public static void main(String[] args) {
        try {
            // Create a FileReader to read from a file
            FileReader reader = new FileReader("example.txt");

            // Read one character at a time
            int data;
            while ((data = reader.read()) != -1) {
                System.out.print((char) data); // Print each character
            }

            // Close the reader to release resources
            reader.close();
        } catch (IOException e) {
            System.out.println("An error occurred while reading the file.");
            e.printStackTrace();
        }
    }
}`}</code></pre><br/>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li><code>FileReader</code>: Used to read character data from the file.</li><br/>
      <li><code>reader.read()</code>: Reads one character at a time from the file and returns it as an integer (which can be cast to a character).</li><br/>
      <li><code>reader.close()</code>: Always close the reader to prevent memory leaks and free up system resources.</li>
    </ul><br/>

    <h3>2. Reading a File Line by Line with <code>BufferedReader</code></h3>

    <pre><code>{`import java.io.FileReader;
import java.io.BufferedReader;
import java.io.IOException;

public class Main {
    public static void main(String[] args) {
        try {
            // Create a BufferedReader to read from the file
            FileReader reader = new FileReader("example.txt");
            BufferedReader bufferedReader = new BufferedReader(reader);

            // Read the file line by line
            String line;
            while ((line = bufferedReader.readLine()) != null) {
                System.out.println(line); // Print each line
            }

            // Close the bufferedReader to release resources
            bufferedReader.close();
        } catch (IOException e) {
            System.out.println("An error occurred while reading the file.");
            e.printStackTrace();
        }
    }
}`}</code></pre><br/>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li><code>BufferedReader.readLine()</code>: Reads the file line by line, which is useful for reading larger text files as it avoids loading the entire file into memory at once.</li><br/>
      <li><code>readLine()</code>: Returns a <code>String</code> that represents one line of the file. It returns <code>null</code> when the end of the file is reached.</li><br/>
      <li><code>Closing the BufferedReader</code>: Just like <code>FileReader</code>, always close the <code>BufferedReader</code> after you're done reading.</li>
    </ul><br/>

    <h3>3. Reading Files Using <code>Scanner</code> Class</h3>

    <pre><code>{`import java.io.File;
import java.io.FileNotFoundException;
import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        try {
            // Create a Scanner object to read from the file
            File file = new File("example.txt");
            Scanner scanner = new Scanner(file);

            // Read the file line by line
            while (scanner.hasNextLine()) {
                String line = scanner.nextLine(); // Read the next line
                System.out.println(line);
            }

            // Close the scanner to release resources
            scanner.close();
        } catch (FileNotFoundException e) {
            System.out.println("The file was not found.");
            e.printStackTrace();
        }
    }
}`}</code></pre><br/>

    <p><strong>Explanation:</strong></p>
    <ul>
      <li><code>Scanner.hasNextLine()</code>: Checks if there is another line in the file.</li><br/>
      <li><code>scanner.nextLine()</code>: Reads the next line from the file.</li><br/>
      <li><code>scanner.close()</code>: Always close the <code>Scanner</code> after reading from a file.</li>
    </ul><br/>

    <h2>Key Points to Remember:</h2>
    <ul>
      <li><strong>Choose the Right Class:</strong> Use <code>FileReader</code> or <code>BufferedReader</code> for reading text files. Use <code>FileInputStream</code> for binary files.</li><br/>
      <li><strong>BufferedReader for Efficiency:</strong> If you're reading large files or want to read line-by-line, <code>BufferedReader</code> is a better choice than <code>FileReader</code> because it buffers the content, making it more efficient.</li><br/>
      <li><strong>Handle Exceptions:</strong> Always wrap file reading operations in a <code>try-catch</code> block to handle potential <code>IOException</code> or <code>FileNotFoundException</code>.</li><br/>
      <li><strong>Close the File:</strong> Always close your <code>FileReader</code>, <code>BufferedReader</code>, or <code>Scanner</code> to release system resources.</li>
    </ul><br/>

    <h2>Summary:</h2>
    <p>
      In Java, reading files is a straightforward process that can be done using classes like <code>FileReader</code>, <code>BufferedReader</code>, or <code>Scanner</code>. These classes allow you to read text files either character by character or line by line. For efficient reading, <code>BufferedReader</code> is often the best choice, especially for larger files. Proper exception handling and closing of resources are crucial for managing file operations safely.
    </p>
  </div>
)}




{selectedChapter === 'chapter110' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Delete Files  </h1>

    <p>
      In Java, deleting files is an essential file management operation, allowing you to remove files from the system. The process of deleting files is simple, but it requires careful handling to ensure the file exists and that the operation completes successfully. Java provides methods for deleting files in the <code>java.io</code> package.
    </p><br/>

    <h3>Key Concepts in Java File Deletion</h3>
    <ul>
      <li><strong>File Class:</strong> The <code>File</code> class in Java is used to represent the path to a file or directory. It provides a method to delete files.</li><br/>
      <li><strong>delete() Method:</strong> The <code>delete()</code> method of the <code>File</code> class is used to delete a file. It returns <code>true</code> if the file was successfully deleted, and <code>false</code> if the deletion failed (e.g., if the file does not exist).</li><br/>
      <li><strong>Exception Handling:</strong> Deleting files may fail due to various reasons (like insufficient permissions or the file not existing), so it's essential to handle any exceptions that may arise.</li>
    </ul><br/>

    <h3>Steps to Delete a File in Java</h3>
    <ol>
      <li><strong>Create a File Object:</strong> Create a <code>File</code> object that points to the file you want to delete.</li><br/>
      <li><strong>Delete the File:</strong> Use the <code>delete()</code> method to attempt to delete the file.</li><br/>
      <li><strong>Check Success:</strong> The method returns <code>true</code> if the deletion was successful, and <code>false</code> if it failed.</li>
    </ol><br/>

    <h3>Example: Delete a File in Java</h3>
    <h4>Step 1: Delete a Single File</h4>

    <pre>
      <code>
        {`import java.io.File;

public class Main {
    public static void main(String[] args) {
        // Create a File object representing the file to be deleted
        File file = new File("example.txt");

        // Attempt to delete the file
        if (file.delete()) {
            System.out.println("File deleted successfully.");
        } else {
            System.out.println("Failed to delete the file.");
        }
    }
}`}
      </code>
    </pre>

    <p>
      <strong>Explanation:</strong> The <code>File.delete()</code> method attempts to delete the file. If the file is deleted successfully, it returns <code>true</code>; otherwise, it returns <code>false</code>.
    </p><br/>

    <h4>Handling Exceptions During File Deletion</h4>

    <pre>
      <code>
        {`import java.io.File;
import java.io.IOException;

public class Main {
    public static void main(String[] args) {
        // Create a File object representing the file to be deleted
        File file = new File("example.txt");

        try {
            // Attempt to delete the file
            if (file.delete()) {
                System.out.println("File deleted successfully.");
            } else {
                System.out.println("Failed to delete the file. It may not exist.");
            }
        } catch (SecurityException e) {
            System.out.println("You do not have permission to delete this file.");
            e.printStackTrace();
        }
    }
}`}
      </code>
    </pre><br/>

    <p>
      <strong>Explanation:</strong> If a security-related issue occurs (e.g., if the file is in use or lacks permission), a <code>SecurityException</code> is thrown, which is caught and handled in the <code>try-catch</code> block.
    </p><br/>

    <h3>Key Points to Remember</h3>
    <ul>
      <li><strong>File.delete():</strong> The <code>delete()</code> method is used to delete a file. It returns <code>true</code> if successful, and <code>false</code> otherwise.</li><br/>
      <li><strong>Check File Existence:</strong> Before attempting to delete a file, you can use <code>file.exists()</code> to check if the file exists.</li><br/>
      <li><strong>Security Considerations:</strong> Deleting a file can fail if the program doesn’t have the proper permissions, so always handle potential exceptions.</li><br/>
      <li><strong>Directory Deletion:</strong> The <code>delete()</code> method can only delete files, not directories that contain files. To delete a directory, you need to first delete its contents.</li>
    </ul><br/>

    <h3>Deleting a Directory (with Files Inside)</h3>

    <pre>
      <code>
        {`import java.io.File;

public class Main {
    public static void main(String[] args) {
        // Create a File object representing the directory to be deleted
        File directory = new File("myDirectory");

        // Ensure the directory is empty before deleting
        if (directory.isDirectory()) {
            String[] files = directory.list();
            if (files != null && files.length > 0) {
                for (String file : files) {
                    File fileToDelete = new File(directory, file);
                    fileToDelete.delete();
                }
            }
            // Now delete the empty directory
            if (directory.delete()) {
                System.out.println("Directory deleted successfully.");
            } else {
                System.out.println("Failed to delete the directory.");
            }
        }
    }
}`}
      </code>
    </pre><br/>

    <p>
      <strong>Explanation:</strong> The <code>directory.list()</code> method lists all the files in the directory. You need to delete each file before deleting the directory. After deleting all the contents, you can delete the empty directory using <code>directory.delete()</code>.
    </p><br/>

    <h3>Summary</h3>
    <p>
      In Java, deleting a file is done using the <code>File.delete()</code> method. It returns <code>true</code> if the deletion is successful, and <code>false</code> if it fails. To handle errors, such as permission issues, you should use exception handling. For directories, ensure they are empty before deletion. Always handle possible failures gracefully and ensure proper permissions when working with file deletion in your applications.
    </p>
  </div>
)}





{selectedChapter === 'chapter111' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Multithreading in Java </h1>

       
        <p>
          Multithreading is the capability of a CPU (Central Processing Unit), or a single core in a multi-core processor, to provide multiple threads of execution concurrently. A <strong>thread</strong> is the smallest unit of a CPU's execution.
        </p><br/>
        <ul>
          <li>In Java, multithreading allows multiple threads to run simultaneously within a single process, sharing the process resources (like memory).</li><br/>
          <li>It is used to perform complex tasks in parallel, like handling multiple user requests in a server application, executing time-consuming tasks in the background (like downloading data), or keeping a GUI responsive while performing computations.</li>
        </ul><br/>

        <h2>Understanding Threads in Java</h2>
        <p>In Java, the <strong>Thread class</strong> and <strong>Runnable interface</strong> are key elements used to create and manage threads.</p><br/>

        <h3>1. Thread Class</h3>
        <p>
          The <code>Thread</code> class is part of <code>java.lang</code> and is used to create and manage threads in Java.
        </p>
        <p>It has methods like <code>start()</code>, <code>sleep()</code>, <code>join()</code>, <code>yield()</code>, etc. By extending this class, you override the <code>run()</code> method to define the code executed by the thread.</p>
        <pre>
          <code>
            {`class MyThread extends Thread {
  public void run() {
    // Thread’s code
    System.out.println("Hello from MyThread");
  }
}`}
          </code>
        </pre><br/>

        <h3>2. Runnable Interface</h3>
        <p>
          The <code>Runnable</code> interface represents a task that can be executed by a thread. Unlike extending the <code>Thread</code> class, implementing <code>Runnable</code> allows the class to extend another class as well.
        </p>
        <p>The <code>run()</code> method is overridden to define the task that the thread will perform. The <code>Thread</code> class accepts a <code>Runnable</code> object in its constructor.</p>
        <pre>
          <code>
            {`class MyRunnable implements Runnable {
  public void run() {
    System.out.println("Hello from MyRunnable");
  }
}

Thread t1 = new Thread(new MyRunnable());
t1.start(); // Starts the thread`}
          </code>
        </pre><br/>

        <h2 style={{paddingBottom:"6px"}}>The Lifecycle of a Thread in Java</h2>
        <p>A thread in Java goes through the following stages during its life cycle:</p>
        <ul>
          <li><strong>New</strong>: When a thread is created, but not yet started. Example: <code>Thread t1 = new Thread();</code></li><br/>
          <li><strong>Runnable</strong>: When the thread is ready to run, but the scheduler hasn’t given it CPU time yet. Calling the <code>start()</code> method puts the thread in this state.</li><br/>
          <li><strong>Blocked</strong>: The thread is blocked when it’s waiting for a resource or event, like I/O completion or acquiring a lock (in the case of synchronized code).</li><br/>
          <li><strong>Waiting</strong>: A thread can enter a waiting state by calling <code>wait()</code>, <code>join()</code>, or similar methods. It remains in this state until another thread performs some action, like notifying it (<code>notify()</code>).</li><br/>
          <li><strong>Terminated</strong>: The thread has completed execution or has been forcefully stopped.</li>
        </ul><br/>

        <h2>How Multithreading Works in Java</h2>
        <p>
          Threads are executed by the <strong>Java Virtual Machine (JVM)</strong>, and the actual threading is handled by the underlying operating system. However, Java provides a thread scheduler that controls the order and timing of thread execution, ensuring that multiple threads are executed efficiently.
        </p><br/>
        <p>
          In a typical program, a thread runs until its task is complete or it's interrupted.
        </p><br/>
        
        <h3>Thread Management Methods</h3>
        <ul>
          <li><strong>start()</strong>: Initiates the thread and calls the <code>run()</code> method.</li><br/>
          <li><strong>sleep(milliseconds)</strong>: Makes the thread sleep for a specific time, releasing CPU for other threads.</li><br/>
          <li><strong>join()</strong>: Causes the current thread to wait until the thread on which <code>join()</code> was called finishes its execution.</li><br/>
          <li><strong>yield()</strong>: Suggests to the scheduler that the current thread is willing to yield CPU time to other threads of the same priority.</li>
        </ul><br/>

        <h3>Example</h3>
        <pre>
          <code>
            {`class SleepThread extends Thread {
  public void run() {
    try {
      Thread.sleep(2000);  // Sleep for 2 seconds
      System.out.println("After sleep");
    } catch (InterruptedException e) {
      System.out.println("Thread interrupted");
    }
  }
}

public class Main {
  public static void main(String[] args) {
    SleepThread t = new SleepThread();
    t.start();
  }
}`}
          </code>
        </pre><br/>

        <h2>Advantages of Multithreading in Java</h2>
        <ul>
          <li><strong>Improved Performance</strong>: With multithreading, multiple tasks can run in parallel, fully utilizing the CPU's capabilities.</li><br/>
          <li><strong>Better Resource Utilization</strong>: Threads within the same process share resources like memory, which makes the overhead of creating threads much lower than creating separate processes.</li><br/>
          <li><strong>Concurrent Operations</strong>: Multithreading allows your program to handle multiple operations concurrently, keeping the application responsive.</li><br/>
          <li><strong>Real-Time Processing</strong>: Tasks such as user input, networking, or database operations can be handled in separate threads.</li><br/>
          <li><strong>Parallelism</strong>: Multithreading allows your program to perform parallel tasks, beneficial for web servers or video processing.</li>
        </ul><br/>

        <h2>Challenges in Multithreading</h2>
        <ul>
          <li><strong>Synchronization</strong>: If multiple threads access shared resources, there’s a risk of data inconsistency. Java provides <code>synchronized</code> blocks and methods to prevent interference.</li><br/>
          <li><strong>Deadlock</strong>: Deadlock occurs when two or more threads are blocked forever, waiting for each other to release resources.</li><br/>
          <li><strong>Race Conditions</strong>: A race condition happens when two threads modify shared data simultaneously, leading to unexpected results.</li>
        </ul><br/>

        <h2>Real-World Use Cases for Multithreading</h2>
        <ul>
          <li><strong>Web Servers</strong>: Handle multiple client requests concurrently.</li><br/>
          <li><strong>Parallel Computing</strong>: Tasks like video rendering or database query processing benefit from multithreading.</li><br/>
          <li><strong>User Interface Applications</strong>: Multithreading allows background tasks without freezing the UI.</li>
        </ul><br/>

        <h2>Example: Using Synchronization in Multithreading</h2>
        <pre>
          <code>
            {`class BankAccount {
  private int balance = 0;

  public synchronized void deposit(int amount) {
    balance += amount;
  }

  public synchronized int getBalance() {
    return balance;
  }
}

public class Main {
  public static void main(String[] args) {
    BankAccount account = new BankAccount();

    Runnable depositTask = () -> {
      for (int i = 0; i < 1000; i++) {
        account.deposit(1);
      }
    };

    Thread t1 = new Thread(depositTask);
    Thread t2 = new Thread(depositTask);

    t1.start();
    t2.start();

    try {
      t1.join();
      t2.join();
    } catch (InterruptedException e) {
      System.out.println("Main thread interrupted");
    }

    System.out.println("Final balance: " + account.getBalance());
  }
}`}
          </code>
        </pre><br/>

        <h2>Conclusion</h2>
        <p>Multithreading in Java helps improve performance, efficiency, and responsiveness of applications. It's crucial for modern applications, especially in server-side programming and interactive user interfaces.</p>
      </div>
    )}


{selectedChapter === 'chapter112' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Thread Life Cycle</h1>

    <p>
      The <strong>Java Thread Life Cycle</strong> describes the various stages a thread goes through during its existence. A thread in Java follows a well-defined sequence of states from creation to termination. Understanding these stages helps in managing and controlling thread execution efficiently.
    </p><br/>

    <h2>1. <strong>New (Born) State</strong></h2>
    <ul>
      <li>A thread is in the <strong>New</strong> state when it is created but not yet started.</li><br/>
      <li>At this point, the thread has been instantiated, but it hasn't begun execution.</li><br/>
      <li>Example:
        <pre><code>{`Thread t1 = new Thread();  // Thread is in New state`}</code></pre>
      </li>
    </ul><br/>

    <h2>2. <strong>Runnable State</strong></h2>
    <ul>
      <li>Once you call the <code>start()</code> method on the thread, it moves to the <strong>Runnable</strong> state.</li><br/>
      <li>In this state, the thread is ready to run, but the thread scheduler (part of the Java Virtual Machine, JVM) decides when to allocate CPU time for execution.</li><br/>
      <li>The thread is <strong>ready</strong> to be picked up by the thread scheduler but might not immediately start executing.</li>
      <li>Example:
        <pre><code>{`t1.start();  // Thread is now in Runnable state`}</code></pre>
      </li>
    </ul><br/>

    <h2>3. <strong>Blocked State</strong></h2>
    <ul>
      <li>A thread enters the <strong>Blocked</strong> state when it is trying to access a resource that is currently unavailable. This could be due to:</li><br/>
      <ul>
        <li>Waiting for I/O operations to complete.</li><br/>
        <li>Trying to acquire a lock that another thread is holding (e.g., synchronized blocks/methods).</li>
      </ul><br/>
      <li>The thread remains in this state until the resource becomes available.</li><br/>
      <li>Example: If a thread calls a <code>synchronized</code> method and another thread is holding the lock, the calling thread will enter the blocked state.</li>
    </ul><br/>

    <h2>4. <strong>Waiting State</strong></h2>
    <ul>
      <li>A thread enters the <strong>Waiting</strong> state when it is waiting for another thread to perform a particular action.</li><br/>
      <li>This happens when a thread calls methods like <code>wait()</code>, <code>join()</code>, or <code>sleep()</code>.</li><br/>
      <li>The thread will remain in the Waiting state until another thread sends a notification (e.g., <code>notify()</code> or <code>notifyAll()</code>).</li><br/>
      <li>Example:
        <pre><code>{` synchronized (this) {
wait();  // Thread enters Waiting state
}`}</code></pre>
      </li>
    </ul><br/>

    <h2>5. <strong>Timed Waiting State</strong></h2>
    <ul>
      <li>A thread can also enter the <strong>Timed Waiting</strong> state if it calls methods that put the thread to sleep for a specified period or yields its execution for a specific amount of time.</li><br/>
      <li>Methods like <code>sleep(milliseconds)</code> or <code>join(milliseconds)</code> cause the thread to enter Timed Waiting.</li><br/>
      <li>The thread will return to the <strong>Runnable</strong> state after the specified time expires.</li><br/>
      <li>Example:
        <pre><code>{`Thread.sleep(1000);  // Thread enters Timed Waiting state for 1 second`}</code></pre>
      </li>
    </ul><br/>

    <h2>6. <strong>Terminated (Dead) State</strong></h2>
    <ul>
      <li>A thread enters the <strong>Terminated</strong> or <strong>Dead</strong> state once it has finished its execution or has been forcefully terminated (e.g., through <code>interrupt()</code>).</li><br/>
      <li>After this state, the thread cannot be started again.</li><br/>
      <li>Example: After the <code>run()</code> method completes, the thread reaches the Terminated state.</li>
    </ul><br/>

    <h3>Thread Life Cycle Diagram</h3>
    <pre>
      <code>{`
        +--------+            +-----------+            +--------+
        |  New   |--start()--> | Runnable |--running-->| Blocked |
        +--------+            +-----------+            +--------+
                                  ^    |
                                  |    |
                           wait()|    |sleep() or join()
                                  v    |
                            +----------+
                            | Waiting  |
                            +----------+
                                  |
                             +----------+
                             | Terminated|
                             +----------+
     `} </code>
    </pre>
    <br/>
    <h2 style={{paddingBottom:"6px"}}>Summary of Thread Life Cycle States</h2>
    <ul>
      <li><strong>New</strong>: Thread is created but not yet started.</li><br/>
      <li><strong>Runnable</strong>: The thread is ready to run, but the JVM decides when it will execute.</li><br/>
      <li><strong>Blocked</strong>: The thread is waiting for a resource (e.g., a lock) to be available.</li><br/>
      <li><strong>Waiting</strong>: The thread is waiting for another thread to take action (e.g., notify it).</li><br/>
      <li><strong>Timed Waiting</strong>: The thread is waiting for a fixed time duration (e.g., <code>sleep()</code>).</li><br/>
      <li><strong>Terminated</strong>: The thread has completed its task or was forcefully stopped.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>
      Understanding the thread life cycle in Java is essential for managing thread execution. Each state represents a distinct stage, from the creation of a thread to its termination. By controlling thread states appropriately, you can optimize the performance and reliability of multithreaded applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter113' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java Create Thread  </h1>

        <p>
          In Java, a thread is a lightweight process that allows multiple operations to run concurrently within a single program.
          To create and execute a thread, Java provides a simple and structured way to do so. Let's explore how to create threads in Java in all possible ways.
        </p><br/>

        <h2 style={{ paddingBottom: "6px" }}>1. Using the <code>Thread</code> class (Extending Thread)</h2>

        <p>
          The simplest way to create a thread in Java is by creating a class that extends the <code>Thread</code> class.
          This approach involves overriding the <code>run()</code> method, which contains the code that the thread will execute.
        </p><br/>

        <h3>Steps:</h3>
        <ul>
          <li>Create a new class that extends the <code>Thread</code> class.</li><br/>
          <li>Override the <code>run()</code> method.</li><br/>
          <li>Call the <code>start()</code> method to begin the execution of the thread.</li>
        </ul><br/>

        <h3>Example:</h3>
        <pre>
          <code>
            {`class MyThread extends Thread {
  @Override
  public void run() {
    System.out.println("Thread is running.");
  }

  public static void main(String[] args) {
    MyThread thread = new MyThread();
    thread.start();  // Start the thread
  }
}`}
          </code>
        </pre>

        <h3>Explanation:</h3>
        <ul>
          <li>The <code>run()</code> method contains the code that will be executed by the thread when it's started.</li><br/>
          <li>The <code>start()</code> method is used to begin the thread’s execution.</li><br/>
          <li><strong>Note:</strong> You should never directly call the <code>run()</code> method. Always use <code>start()</code> to begin execution.</li>
        </ul><br/>

        <h2 style={{ paddingBottom: "6px" }}>2. Using the <code>Runnable</code> Interface (Implementing Runnable)</h2>

        <p>
          Another way to create a thread is by implementing the <code>Runnable</code> interface. This method is more flexible because it allows your class to extend another class (since Java supports single inheritance).
        </p>

        <h3>Steps:</h3>
        <ul>
          <li>Create a class that implements the <code>Runnable</code> interface.</li><br/>
          <li>Override the <code>run()</code> method to specify the task.</li><br/>
          <li>Create a <code>Thread</code> object by passing the <code>Runnable</code> object to the <code>Thread</code> constructor.</li><br/>
          <li>Start the thread using the <code>start()</code> method.</li>
        </ul><br/>

        <h3>Example:</h3>
        <pre>
          <code>
            {`class MyRunnable implements Runnable {
  @Override
  public void run() {
    System.out.println("Thread is running using Runnable.");
  }

  public static void main(String[] args) {
    MyRunnable myRunnable = new MyRunnable();
    Thread thread = new Thread(myRunnable);  // Pass Runnable object to Thread
    thread.start();  // Start the thread
  }
}`}
          </code>
        </pre>

        <h3>Explanation:</h3>
        <ul>
          <li>By implementing the <code>Runnable</code> interface, you can create threads in classes that already extend other classes.</li><br/>
          <li>The <code>run()</code> method contains the code that the thread will execute.</li><br/>
          <li>The <code>Thread</code> constructor takes a <code>Runnable</code> object as an argument, which it will execute when started.</li>
        </ul><br/>

        <h2>3. Using Lambda Expressions (Java 8 and above)</h2>

        <p>
          In Java 8 and later, you can use lambda expressions to simplify the implementation of the <code>Runnable</code> interface.
        </p>

        <h3>Steps:</h3>
        <ul>
          <li>Use a lambda expression to define the <code>run()</code> method inline.</li>
        </ul><br/>

        <h3>Example:</h3>
        <pre>
          <code>
            {`public class Main {
  public static void main(String[] args) {
    Runnable runnable = () -> System.out.println("Thread is running using Lambda.");
    Thread thread = new Thread(runnable);
    thread.start();  // Start the thread
  }
}`}
          </code>
        </pre>

        <h3>Explanation:</h3>
        <ul>
          <li>The lambda expression <code>() {"->"} System.out.println("Thread is running using Lambda.")</code> is used to implement the <code>run()</code> method of the <code>Runnable</code> interface.</li><br/>
          <li>This approach is concise and eliminates the need for a separate class to implement <code>Runnable</code>.</li>
        </ul><br/>

        <h2 style={{ paddingBottom: "6px" }}>4. Using the <code>ExecutorService</code> (Thread Pool)</h2>

        <p>
          For managing a large number of threads or when you need to execute many tasks concurrently, you can use the <code>ExecutorService</code> framework.
          This allows you to manage and reuse threads from a thread pool, rather than creating a new thread each time.
        </p>

        <h3>Steps:</h3>
        <ul>
          <li>Create an instance of <code>ExecutorService</code> (e.g., <code>ExecutorService executor = Executors.newFixedThreadPool(10);</code>).</li><br/>
          <li>Submit tasks (usually <code>Runnable</code> or <code>Callable</code> tasks) for execution.</li><br/>
          <li>Shutdown the <code>ExecutorService</code> when all tasks are completed.</li>
        </ul><br/>

        <h3>Example:</h3>
        <pre>
          <code>
            {`import java.util.concurrent.ExecutorService;
import java.util.concurrent.Executors;

public class Main {
  public static void main(String[] args) {
    ExecutorService executor = Executors.newFixedThreadPool(2);  // Thread pool with 2 threads

    Runnable task = () -> {
      System.out.println("Task is running in thread: " + Thread.currentThread().getName());
    };

    executor.submit(task);  // Submit the task for execution
    executor.submit(task);  // Submit another task for execution

    executor.shutdown();  // Shutdown the executor
  }
}`}
          </code>
        </pre>

        <h3>Explanation:</h3>
        <ul>
          <li><code>Executors.newFixedThreadPool(2)</code> creates a thread pool with two threads.</li><br/>
          <li><code>submit()</code> is used to add tasks (runnable objects) to the pool.</li><br/>
          <li><code>shutdown()</code> is called to stop the <code>ExecutorService</code> after all tasks are executed.</li><br/>
          <li>This approach is highly efficient when handling a large number of tasks, as it reuses threads from the pool.</li>
        </ul><br/>

        <h2>Thread Life Cycle</h2>

        <p>
          Once a thread is created, it goes through various states in its lifecycle. These states are:
        </p>

        <ul>
          <li><strong>New</strong>: The thread is created but not started yet.</li><br/>
          <li><strong>Runnable</strong>: The thread is ready to run, but it may not be running immediately (depending on the thread scheduler).</li><br/>
          <li><strong>Blocked</strong>: The thread is waiting for a resource, such as I/O operations or a lock.</li><br/>
          <li><strong>Waiting</strong>: The thread is waiting for another thread to perform a specific action.</li><br/>
          <li><strong>Timed Waiting</strong>: The thread is waiting for a specified period.</li><br/>
          <li><strong>Terminated</strong>: The thread has finished its execution.</li>
        </ul><br/>

        <h2>Advantages of Using Threads</h2>

        <ul>
          <li><strong>Improved Performance</strong>: Threads allow parallel execution of tasks, utilizing multi-core processors more efficiently.</li><br/>
          <li><strong>Better Resource Management</strong>: Threads within the same process share memory, reducing the overhead compared to separate processes.</li><br/>
          <li><strong>Enhanced Responsiveness</strong>: Multithreading allows for responsive user interfaces and background tasks (e.g., downloading files while keeping the UI active).</li>
        </ul>

        <h2>Summary</h2>

        <ul>
          <li><strong>Extending <code>Thread</code></strong>: Simple to implement but limits flexibility (as you can only extend one class).</li><br/>
          <li><strong>Implementing <code>Runnable</code></strong>: More flexible and allows multiple classes to implement <code>Runnable</code> without the need to extend <code>Thread</code>.</li><br/>
          <li><strong>Lambda Expressions</strong>: Makes the code more concise and easier to read in Java 8 and above.</li><br/>
          <li><strong>ExecutorService</strong>: Ideal for managing multiple threads, especially when you have many tasks to handle concurrently.</li>
        </ul>
      </div>
    )}


{selectedChapter === 'chapter114' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java Thread Scheduler </h1>

      <p>In Java, the <strong>Thread Scheduler</strong> is a part of the Java Virtual Machine (JVM) that decides which thread should run and when. It is responsible for managing the execution of multiple threads by allocating CPU time to each thread.</p>
      <br/>
      <p>The <strong>Thread Scheduler</strong> follows a set of policies and algorithms to determine the order of thread execution. In a multi-threaded program, multiple threads can be running concurrently, but the CPU can only run one thread at a time on a single processor core. So, the scheduler decides how to allocate time to threads.</p>
      <br/>
      <h3 style={{paddingBottom:"6px"}}>Key Concepts of Thread Scheduling</h3>

      <ul>
        <li><strong>Thread States:</strong> The thread scheduler manages threads in various states during their lifecycle. These states are:
          <ul>
            <li><strong>New:</strong> The thread is created but not started yet.</li><br/>
            <li><strong>Runnable:</strong> The thread is ready to run, but it may not be running immediately (depends on the CPU scheduler).</li><br/>
            <li><strong>Blocked:</strong> The thread is waiting for a resource (like I/O operation or a lock) to proceed.</li><br/>
            <li><strong>Waiting:</strong> The thread is waiting for another thread to perform an action.</li><br/>
            <li><strong>Timed Waiting:</strong> The thread is waiting for a specific period.</li><br/>
            <li><strong>Terminated:</strong> The thread has finished execution.</li>
          </ul>
        </li><br/>
        <li><strong>Thread Priority:</strong> Java allows you to assign priorities to threads, which influences the order in which threads are scheduled. Threads with higher priorities are generally given preference over lower-priority threads, although the exact behavior depends on the underlying operating system and JVM implementation.
          <ul>
            <li>Thread priorities range from <code>Thread.MIN_PRIORITY</code> (1) to <code>Thread.MAX_PRIORITY</code> (10), with the default being <code>Thread.NORM_PRIORITY</code> (5).</li><br/>
          </ul>
        </li><br/>
        <li><strong>Preemptive Scheduling vs. Cooperative Scheduling:</strong>
          <ul>
            <li><strong>Preemptive Scheduling:</strong> The thread scheduler can interrupt a running thread and give the CPU to another thread, even if the running thread has not completed its task.</li><br/>
            <li><strong>Cooperative Scheduling:</strong> The running thread must voluntarily give up the CPU, allowing other threads to run. Java mainly uses <strong>preemptive scheduling</strong>.</li>
          </ul>
        </li><br/>
        <li><strong>Time Slice (Quantum):</strong> In preemptive scheduling, the thread scheduler allocates a fixed time slice or quantum to each thread. Once a thread’s time slice expires, the thread is paused, and another thread is given CPU time. This cycle continues until all threads are executed.</li>
      </ul><br/>

      <h3 style={{paddingBottom:"6px"}}>Thread Scheduling in Java: How it Works</h3>

      <p>Java's thread scheduler is typically built on top of the native thread scheduler provided by the operating system. The JVM uses the operating system's thread management features to decide which thread to run next.</p>
      <br/>
      <h4>1. Java Thread Priority and Scheduling</h4>

      <p>When you create a thread, you can set its priority using the <code>Thread.setPriority(int priority)</code> method. The priority influences the order of thread execution but doesn’t guarantee that a thread with a higher priority will always run before lower-priority threads. This depends on the OS and JVM.</p>
      <br/>
      <p><strong>Example:</strong></p>

      <pre>
        <code>{`class MyThread extends Thread {
              public void run() {
                  System.out.println("Thread with priority " + getPriority() + " is running.");
              }

              public static void main(String[] args) {
                  MyThread thread1 = new MyThread();
                  MyThread thread2 = new MyThread();

                  thread1.setPriority(Thread.MIN_PRIORITY);   // Set lowest priority
                  thread2.setPriority(Thread.MAX_PRIORITY);   // Set highest priority

                  thread1.start();
                  thread2.start();
           }
}`}</code>
      </pre>

      <p><strong>Explanation:</strong></p>
      <ul>
        <li><code>thread1</code> will run with the lowest priority, and <code>thread2</code> will run with the highest priority, but the actual scheduling will still depend on the underlying OS and JVM.</li>
      </ul><br/>

      <h4>2. Thread Scheduler Behavior</h4>

      <ul>
        <li><strong>Fairness:</strong> The Java thread scheduler does not guarantee fairness. That means threads may not be scheduled in the exact order they are created, and threads with higher priority may not always get CPU time.</li><br/>
        <li><strong>CPU-bound vs. I/O-bound Threads:</strong> CPU-bound threads (which perform heavy computations) and I/O-bound threads (which are waiting for data) are handled differently. If a thread is waiting for I/O (blocked), the scheduler may give CPU time to other threads.</li><br/>
        <li><strong>Thread Sleep:</strong> If a thread calls <code>Thread.sleep()</code>, it voluntarily gives up its remaining time slice, allowing other threads to execute. However, after the sleep time expires, it goes back to the <strong>Runnable</strong> state and waits for the scheduler to allocate it CPU time.</li>
      </ul><br/>

      <h4 style={{paddingBottom:"6px"}}>3. Multithreading and Synchronization</h4>

      <ul>
        <li><strong>Context Switching:</strong> If multiple threads are running on different processors or cores, the thread scheduler performs <strong>context switching</strong> to switch between threads. This means saving the state of the currently running thread and loading the state of the next thread.</li><br/>
        <li><strong>Locking:</strong> If two threads are trying to access the same resource simultaneously, the scheduler may lock the resource for one thread, forcing the other thread to wait. This ensures thread safety but can result in <strong>deadlock</strong> if not handled properly.</li>
      </ul><br/>

      <h4>4. Thread Scheduling in Different Environments</h4>

      <ul>
        <li><strong>Operating System Dependent:</strong> The actual thread scheduling behavior depends on the OS. For example, Linux, Windows, and macOS all have different thread scheduling algorithms. The Java thread scheduler delegates the task of scheduling threads to the native OS scheduler.</li><br/>
        <li><strong>Real-Time Java:</strong> In some specialized applications (like real-time systems), Java can be configured to use <strong>real-time scheduling</strong> policies to guarantee thread execution within a certain time frame.</li>
      </ul><br/>

      <h3>Summary of Thread Scheduling in Java</h3>

      <ul>
        <li><strong>Thread Scheduler:</strong> It decides which thread runs and when based on factors like thread priority, time slices, and system resources.</li><br/>
        <li><strong>Thread Priority:</strong> Threads can be assigned priorities, influencing the order in which they run.</li><br/>
        <li><strong>Preemptive Scheduling:</strong> Java typically uses preemptive scheduling, where threads are given a fixed time slice before being paused.</li><br/>
        <li><strong>Fairness:</strong> Java does not guarantee fairness, meaning higher-priority threads might not always get executed before lower-priority threads.</li><br/>
        <li><strong>Platform Dependent:</strong> The actual scheduling behavior depends on the underlying operating system's thread management.</li>
      </ul><br/>

      <h3>Example of Thread Scheduling with Different Priorities</h3>

      <p>Here’s a simple example that demonstrates how thread priorities work:</p>

      <pre>
        <code>{`class MyThread extends Thread {
              public void run() {
                  System.out.println(Thread.currentThread().getName() + " with priority: " + getPriority());
              }

              public static void main(String[] args) {
                  MyThread thread1 = new MyThread();
                  MyThread thread2 = new MyThread();
                  MyThread thread3 = new MyThread();

                  thread1.setPriority(Thread.MIN_PRIORITY);  // Priority 1
                  thread2.setPriority(Thread.NORM_PRIORITY); // Priority 5 (default)
                  thread3.setPriority(Thread.MAX_PRIORITY);  // Priority 10

                  thread1.start();
                  thread2.start();
                  thread3.start();
         }
}`}</code>
      </pre><br/>

      <p>In the example above, threads are created with different priorities, and when executed, you’ll see that the priority influences the order of execution, although it depends on the underlying OS.</p>

     
    </div>
)}



{selectedChapter === 'chapter115' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java Thread Sleep </h1>

      <p>
        In Java, <strong>sleeping a thread</strong> refers to making a thread pause its execution for a specified period of time. This is done using the <code>Thread.sleep()</code> method. It allows other threads to run while the current thread is paused, which is useful in multithreading scenarios where you want to introduce delays or give other threads a chance to execute.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>1. The <code>Thread.sleep()</code> Method</h2>
      <ul>
        <li><strong>Syntax:</strong>
          <pre><code>Thread.sleep(long milliseconds);</code></pre>
          <p>
            <code>milliseconds</code>: The time period (in milliseconds) for which the thread will sleep or pause.
          </p>
        </li><br/>
        <li><strong>Alternative Syntax:</strong>
          <pre><code>Thread.sleep(long milliseconds, int nanoseconds);</code></pre>
          <p>
            This allows you to specify both milliseconds and nanoseconds to achieve more precise sleep duration.
          </p>
        </li><br/>
        <li><strong>Important Points:</strong>
          <ul>
            <li>The method is <strong>static</strong> and can be called from any thread, not just the one that will sleep.</li><br/>
            <li>The sleep method throws an <strong>InterruptedException</strong>, meaning the thread may be interrupted while sleeping. You need to handle this exception, typically using a <code>try-catch</code> block.</li>
          </ul>
        </li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>2. How <code>Thread.sleep()</code> Works</h2>
      <p>
        When a thread calls <code>Thread.sleep()</code>, it tells the JVM to pause the execution of that thread for the specified duration. During this time, the thread is in a <strong>Blocked</strong> state, meaning it is not using the CPU, and other threads are free to run.
      </p><br/>
      <p>
        After the specified time passes, the thread moves back to the <strong>Runnable</strong> state and waits for the CPU to allocate it some time to execute again.
      </p><br/>

      <h2>3. Key Points About <code>Thread.sleep()</code></h2>
      <ul>
        <li><strong>Non-Interruptive:</strong> <code>Thread.sleep()</code> does not stop the thread immediately; it only pauses the thread for a given amount of time. The thread will resume once the time expires, unless it is interrupted.</li><br/>
        <li><strong>Interrupting a Thread:</strong> A sleeping thread can be interrupted by another thread calling <code>interrupt()</code> on it. If a thread is sleeping and another thread interrupts it, an <code>InterruptedException</code> is thrown, and the sleeping thread will be woken up immediately.</li><br/>
        <li><strong>Does Not Consume CPU:</strong> While a thread is sleeping, it does not use any CPU resources, which is why it is useful in scenarios where you need to wait for some time or allow other threads to execute.</li><br/>
        <li><strong>Time Precision:</strong> The actual time a thread sleeps may be a bit longer than the specified time due to the way operating systems handle timing. You can't guarantee that the thread will wake up exactly after the specified time.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>4. Example: Basic Thread Sleep</h2>
      <p>Here’s an example of how you would use <code>Thread.sleep()</code> in a simple Java program:</p>
      <pre><code>{`class MyThread extends Thread {
    @Override
    public void run() {
        try {
            System.out.println("Thread is going to sleep for 2 seconds...");
            Thread.sleep(2000);  // Sleep for 2000 milliseconds (2 seconds)
            System.out.println("Thread woke up after 2 seconds.");
        } catch (InterruptedException e) {
            System.out.println("Thread was interrupted.");
        }
    }

    public static void main(String[] args) {
        MyThread thread = new MyThread();
        thread.start();  // Start the thread
    }
}`} </code></pre>

      <p>
        In this example, the <code>MyThread</code> class extends <code>Thread</code>, and in the <code>run()</code> method, we call <code>Thread.sleep(2000)</code>, which makes the thread sleep for 2 seconds. If the thread is interrupted during the sleep, an <code>InterruptedException</code> will be caught, and the message "Thread was interrupted" will be printed.
      </p><br/>

      <h2 style={{paddingBottom:"6px"}}>5. Use Cases for <code>Thread.sleep()</code></h2>
      <ul>
        <li><strong>Introducing Delays:</strong> <code>Thread.sleep()</code> is often used to introduce delays in a program, for example, in simulations or games where you need to wait before executing the next operation.</li><br/>
        <li><strong>Timing Issues:</strong> Sometimes, threads are designed to periodically perform a task at certain intervals. You can use <code>Thread.sleep()</code> to wait between tasks in such situations.</li><br/>
        <li><strong>Allowing Other Threads to Run:</strong> In some cases, you may want to give other threads a chance to execute while your current thread is doing nothing (like waiting for a resource or performing some waiting task). Using <code>sleep()</code> for a small duration ensures the thread doesn't hold up the CPU unnecessarily.</li><br/>
        <li><strong>Polling:</strong> When you are periodically checking a condition or waiting for an event, using <code>Thread.sleep()</code> can avoid busy-waiting (where a thread constantly checks a condition in a loop without pausing).</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>6. Important Considerations</h2>
      <ul>
        <li><strong>Interrupt Handling:</strong> Since <code>Thread.sleep()</code> can throw an <code>InterruptedException</code>, it’s important to handle it properly. You should either catch this exception or declare it in the method signature.</li><br/>
        <li><strong>Thread Synchronization:</strong> <code>Thread.sleep()</code> can be used in conjunction with synchronization techniques to prevent race conditions or ensure that threads wait for a resource to become available.</li><br/>
        <li><strong>Efficiency:</strong> Using <code>Thread.sleep()</code> frequently for timing or waiting might not always be the most efficient solution, especially in real-time or high-performance applications. In such cases, more advanced synchronization or timing mechanisms might be preferred.</li>
      </ul><br/>

      <h2 style={{paddingBottom:"6px"}}>7. Example of Thread Sleep in a Loop</h2>
      <p>Here's an example where multiple threads use <code>Thread.sleep()</code> in a loop to simulate periodic tasks:</p>
      <pre><code>{`class PeriodicTask extends Thread {
    @Override
    public void run() {
        for (int i = 0; i < 5; i++) {
            try {
                System.out.println("Task #" + (i + 1) + " is running.");
                Thread.sleep(1000);  // Sleep for 1 second between tasks
            } catch (InterruptedException e) {
                System.out.println("Task was interrupted.");
                return;  // Stop the task if interrupted
            }
        }
    }

    public static void main(String[] args) {
        PeriodicTask task = new PeriodicTask();
        task.start();  // Start the task
    }
}`} </code></pre>
<br/>
      <p>
        In this example, the <code>PeriodicTask</code> thread runs five times, with a 1-second delay (<code>Thread.sleep(1000)</code>) between each task. If the thread is interrupted during sleep, it catches the <code>InterruptedException</code> and stops executing.
      </p><br/>

      <h2>Conclusion</h2>
      <p>
        In Java, <code>Thread.sleep()</code> is a simple yet powerful tool for pausing thread execution for a specific amount of time. It allows other threads to execute while the current thread is sleeping and helps in scenarios where you need delays or periodic tasks. However, it’s important to handle the <code>InterruptedException</code> properly and be mindful of how thread synchronization interacts with sleep behavior.
      </p>
    </div>
  )}



{selectedChapter === 'chapter116' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Starting a Thread Twice </h1>


      <p>
        In Java, starting a thread twice is not allowed. If you attempt to start a thread more than once, it will throw an exception. This is because a thread in Java can only be started once, and once its `run()` method has been executed, it transitions to the <strong>terminated</strong> state and cannot be restarted.
      </p><br/>

      <h2>1. Understanding the Thread Lifecycle</h2>
      <p>
        A thread in Java goes through several states during its lifecycle:
      </p>
      <ul>
        <li><strong>New</strong>: The thread is created but has not yet started.</li><br/>
        <li><strong>Runnable</strong>: The thread is ready to run and waiting for CPU time.</li><br/>
        <li><strong>Blocked</strong>: The thread is blocked waiting for a resource.</li><br/>
        <li><strong>Terminated</strong>: The thread has completed its execution or has been terminated.</li>
      </ul><br/>

      <h2>2. The `Thread.start()` Method</h2>
      <p>
        To start a thread in Java, you use the `start()` method. This method internally invokes the `run()` method, which contains the code that defines the thread's task.
      </p>

      <pre>
        <code>
          {`class MyThread extends Thread {
    @Override
    public void run() {
        System.out.println("Thread is running...");
    }
}

public class TestThread {
    public static void main(String[] args) {
        MyThread thread1 = new MyThread();
        thread1.start();  // Start the thread for the first time

        // Uncommenting the next line will throw IllegalThreadStateException
        // thread1.start();  // Attempting to start the thread again
    }
}`}
        </code>
      </pre><br/>

      <h2>3. Why Can’t We Start a Thread Twice?</h2>
      <p>
        <strong>IllegalThreadStateException:</strong> In Java, once a thread's `run()` method completes (or it is interrupted), the thread enters the <strong>terminated</strong> state. A thread cannot be restarted once it's terminated.
      </p><br/>
      <p>
        The `start()` method can only be called once for a thread. If you try to invoke `start()` on a thread that has already been started or completed its execution, Java will throw an <code>IllegalThreadStateException</code>.
      </p><br/>

      <h2>4. What Happens When You Call `start()` Twice?</h2>
      <p>
        If you attempt to call `start()` more than once on the same thread, Java throws an <code>IllegalThreadStateException</code> and the program will crash unless the exception is handled. This is because threads in Java are designed to be started once to ensure predictable and safe execution.
      </p>

      <pre>
        <code>
          {`class MyThread extends Thread {
    @Override
    public void run() {
        System.out.println("Thread is running...");
    }
}

public class TestThread {
    public static void main(String[] args) {
        MyThread thread = new MyThread();
        thread.start(); // First time: thread starts running
        thread.start(); // Second time: IllegalThreadStateException is thrown
    }
}`}
        </code>
      </pre>
      <p>
        When you run this code, you'll encounter an exception like:
      </p>
      <pre>
        <code>
          {`Exception in thread "main" java.lang.IllegalThreadStateException
    at java.base/java.lang.Thread.start(Thread.java:764)
    at TestThread.main(TestThread.java:13)`}
        </code>
      </pre><br/>

      <h2>5. Correct Ways to Handle Thread Restart</h2>
      <p>
        If you need to "restart" a thread, the typical approach is to create a new instance of the <code>Thread</code> class. This new thread can then be started independently from the previous one.
      </p>

      <pre>
        <code>
          {`class MyThread extends Thread {
    @Override
    public void run() {
        System.out.println("Thread is running...");
    }
}

public class TestThread {
    public static void main(String[] args) {
        MyThread thread1 = new MyThread();
        thread1.start(); // First thread execution

        MyThread thread2 = new MyThread(); // Create a new thread
        thread2.start(); // Start the second thread
    }
}`}
        </code>
      </pre><br/>

      <h2>6. Key Takeaways</h2>
      <ul>
        <li><strong>Start Once:</strong> A thread can only be started once using <code>start()</code>. After it completes, it cannot be restarted.</li><br/>
        <li><strong>Create a New Thread:</strong> To run the same task again, create a new thread instance.</li><br/>
        <li><strong>Thread Lifecycle:</strong> Once a thread completes its execution, it transitions to the <strong>terminated</strong> state and cannot be reused.</li>
      </ul><br/>

      <h2>7. Conclusion</h2>
      <p>
        In Java, trying to start a thread twice will result in an <code>IllegalThreadStateException</code>. This is because a thread is designed to run only once, and once it finishes execution, it cannot be restarted. To run a thread's task again, you need to create a new thread instance.
      </p>
    </div>
  )}



{selectedChapter === 'chapter117' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java `run()` Method  </h1>
      
      <p>
        In Java, the <code>run()</code> method is a key component of how threads operate. It's the entry point for any task that you want a thread to execute. Let’s break down how the <code>run()</code> method works in simple terms.
      </p><br/>

      <h2>1. What is the <code>run()</code> Method?</h2>
      <p>
        The <code>run()</code> method is part of the <code>Thread</code> class or the <code>Runnable</code> interface. When you create a new thread, you define what that thread will do by overriding the <code>run()</code> method.
      </p><br/>
      <ul>
        <li><strong>In the <code>Thread</code> class</strong>: You can extend the <code>Thread</code> class and override its <code>run()</code> method.</li><br/>
        <li><strong>In the <code>Runnable</code> interface</strong>: You can implement the <code>Runnable</code> interface and override its <code>run()</code> method.</li>
      </ul><br/>
      <p>
        The <code>run()</code> method contains the code that defines the task the thread will execute.
      </p><br/>

      <h2>2. How is the <code>run()</code> Method Used?</h2>
      <p>
        When you create a thread by calling <code>new Thread()</code>, you provide the task to be executed within the <code>run()</code> method.
      </p><br/>
      <p>
        The <code>run()</code> method is <strong>not called directly</strong> to start the thread. Instead, you call the <code>start()</code> method on the thread. This triggers the thread to call the <code>run()</code> method internally.
      </p>

      <pre>
        <code>
          {`class MyThread extends Thread {
  @Override
  public void run() {
    System.out.println("Thread is running...");
  }
}

public class TestThread {
  public static void main(String[] args) {
    MyThread thread = new MyThread();
    thread.start();  // This calls the run() method internally
  }
}`}
        </code>
      </pre><br/>
      
      <h2>3. Important Points About the <code>run()</code> Method</h2>
      <ul>
        <li><strong>Thread Execution:</strong> The <code>run()</code> method contains the code that will be executed by the thread. Once a thread is started, the <code>run()</code> method is invoked by the JVM in the thread’s execution context.</li><br/>
        <li><strong>Do not call <code>run()</code> directly:</strong> If you call <code>run()</code> directly on a thread object (e.g., <code>thread.run()</code>), it will not start a new thread. Instead, it will just execute the <code>run()</code> method on the current thread (the calling thread). To start the thread properly, you must call <code>start()</code>.</li>
      </ul><br/>

      <pre>
        <code>
          {`MyThread thread = new MyThread();
thread.run(); // This does NOT start a new thread, it just runs the method in the current thread`}
        </code>
      </pre><br/>

      <h2>4. <code>run()</code> in Runnable Interface</h2>
      <p>
        Instead of extending the <code>Thread</code> class, you can implement the <code>Runnable</code> interface to define the task in the <code>run()</code> method. This is preferred when you need to inherit from a different class because Java supports only single inheritance.
      </p>

      <pre>
        <code>
          {`class MyRunnable implements Runnable {
  @Override
  public void run() {
    System.out.println("Thread is running...");
  }
}

public class TestThread {
  public static void main(String[] args) {
    MyRunnable myRunnable = new MyRunnable();
    Thread thread = new Thread(myRunnable);
    thread.start(); // This will call the run() method inside MyRunnable
  }
}`}
        </code>
      </pre><br/>

      <h2>5. Example of Using the <code>run()</code> Method</h2>
      <p>
        Let's see an example where a thread performs a task, such as counting numbers:
      </p>

      <pre>
        <code>
          {`class CountingThread extends Thread {
  @Override
  public void run() {
    for (int i = 1; i <= 5; i++) {
      System.out.println("Counting: " + i);
      try {
        Thread.sleep(1000); // Sleep for 1 second between counts
      } catch (InterruptedException e) {
        System.out.println("Thread was interrupted.");
      }
    }
  }

  public static void main(String[] args) {
    CountingThread thread = new CountingThread();
    thread.start();  // Starts the thread and calls the run() method
  }
}`}
        </code>
      </pre><br/>

      <h2>6. What Happens Inside the <code>run()</code> Method?</h2>
      <p>
        The <code>run()</code> method typically contains the code that performs the task assigned to the thread. Here are some common actions you might perform inside the <code>run()</code> method:
      </p><br/>
      <ul>
        <li>Performing some computation or task.</li><br/>
        <li>Accessing shared resources (with synchronization if needed).</li><br/>
        <li>Communicating with other threads (using <code>wait()</code>, <code>notify()</code>, or <code>join()</code> methods).</li><br/>
        <li>Handling exceptions such as <code>InterruptedException</code> when dealing with thread sleep or other thread interruptions.</li>
      </ul><br/>

      <h2>7. When Does the <code>run()</code> Method End?</h2>
      <p>
        The <code>run()</code> method finishes its execution when:
      </p><br/>
      <ul>
        <li>The last statement inside the method is executed.</li><br/>
        <li>If an exception occurs inside the <code>run()</code> method that is not caught, the thread will terminate abruptly.</li>
      </ul><br/>

      <p>
        After the <code>run()</code> method finishes execution, the thread enters the <strong>terminated</strong> state, and it cannot be started again.
      </p><br/>

      <h2>8. Key Takeaways</h2>
      <ul>
        <li>The <code>run()</code> method contains the task you want the thread to perform.</li><br/>
        <li>You should call the <code>start()</code> method to begin thread execution, which internally calls the <code>run()</code> method.</li><br/>
        <li>Directly calling <code>run()</code> will not start a new thread; it will just execute the <code>run()</code> method in the current thread.</li><br/>
        <li>The <code>run()</code> method is crucial for defining the behavior of a thread in Java.</li>
      </ul><br/>

      <h2>Conclusion</h2>
      <p>
        The <code>run()</code> method is where you define the task or behavior of a thread in Java. It is invoked automatically when you call <code>start()</code> on a thread, enabling concurrent execution. By overriding <code>run()</code> in a <code>Thread</code> or <code>Runnable</code>, you can specify what the thread should do when it runs.
      </p>
    </div>
  )}


{selectedChapter === 'chapter118' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java Joining a Thread </h1>


      
      <p>
        In Java, the concept of "joining" a thread means making the current thread wait for another thread to finish before it proceeds. This is useful in situations where you need one thread to finish its execution before continuing with other tasks. The <code>join()</code> method is used for this purpose.
      </p><br/>

      <h3>Syntax:</h3>
      <pre><code>public final void join() throws InterruptedException</code></pre>

      <p>
        - The <code>join()</code> method can throw an <code>InterruptedException</code> if the current thread is interrupted while waiting for the other thread to finish.
        - It doesn't take any parameters and doesn't return any value.
      </p><br/>

      <h2>How Does the `join()` Method Work?</h2>
      <p>
        When a thread calls <code>join()</code> on another thread, the calling thread is put on hold until the thread on which <code>join()</code> was called completes its execution. Once the other thread finishes, the calling thread resumes.
      </p><br/>

      <p>Here's a step-by-step breakdown:</p>
      <ul>
        <li><strong>Thread A</strong> starts and calls <code>join()</code> on <strong>Thread B</strong>.</li><br/>
        <li><strong>Thread A</strong> pauses and waits for <strong>Thread B</strong> to finish.</li><br/>
        <li><strong>Thread B</strong> executes its <code>run()</code> method and finishes.</li><br/>
        <li>Once <strong>Thread B</strong> finishes, <strong>Thread A</strong> resumes execution after the <code>join()</code> method.</li>
      </ul><br/>

      <h2>Basic Example of Using `join()`</h2>
      <pre><code>
{`class MyThread extends Thread {
  @Override
  public void run() {
    try {
      System.out.println(Thread.currentThread().getName() + " is running...");
      Thread.sleep(2000);  // Simulate some work by sleeping for 2 seconds
      System.out.println(Thread.currentThread().getName() + " finished.");
    } catch (InterruptedException e) {
      System.out.println(Thread.currentThread().getName() + " was interrupted.");
    }
  }
}

public class TestThreadJoin {
  public static void main(String[] args) throws InterruptedException {
    MyThread thread1 = new MyThread();
    MyThread thread2 = new MyThread();

    thread1.start(); // Start the first thread
    thread2.start(); // Start the second thread

    thread1.join(); // Main thread waits for thread1 to finish
    thread2.join(); // Main thread waits for thread2 to finish

    System.out.println("Both threads have finished.");
  }
}`}
      </code></pre>

      <p><strong>Explanation:</strong></p>
      <ul>
        <li><strong>thread1</strong> and <strong>thread2</strong> are started using the <code>start()</code> method.</li><br/>
        <li>The <strong>main()</strong> thread calls <code>join()</code> on <strong>thread1</strong> and <strong>thread2</strong>.</li><br/>
        <li>The main thread pauses at each <code>join()</code> call and only proceeds when the corresponding thread finishes.</li><br/>
        <li>The program will print:
          <ul>
            <li><code>thread1 is running...</code></li><br/>
            <li><code>thread2 is running...</code></li><br/>
            <li><code>thread1 finished.</code></li><br/>
            <li><code>thread2 finished.</code></li><br/>
            <li><code>Both threads have finished.</code></li>
          </ul>
        </li>
      </ul><br/>

      <h2>Why Use `join()`?</h2>
      <p>The <code>join()</code> method is useful when you want to ensure that one thread has finished its task before the next one begins. It helps you control the execution order of threads.</p>
      <br/>
      <ul>
        <li><strong>Sequencing tasks:</strong> If you want one thread to finish before another starts (e.g., processing steps in a pipeline).</li><br/>
        <li><strong>Waiting for multiple threads:</strong> If you have multiple threads and want to ensure all of them complete before moving to the next step.</li>
      </ul><br/>

      <h2>Handling Interruptions with `join()`</h2>
      <p>The <code>join()</code> method can throw an <code>InterruptedException</code> if the current thread is interrupted while waiting. You can handle this exception using a <code>try-catch</code> block.</p>

      <pre><code>
{`class MyThread extends Thread {
  @Override
  public void run() {
    try {
      Thread.sleep(2000);  // Simulate work
      System.out.println(Thread.currentThread().getName() + " finished.");
    } catch (InterruptedException e) {
      System.out.println(Thread.currentThread().getName() + " was interrupted.");
    }
  }
}

public class TestThreadJoin {
  public static void main(String[] args) throws InterruptedException {
    MyThread thread1 = new MyThread();
    thread1.start();

    // Interrupt the thread after 1 second
    Thread.sleep(1000);
    thread1.interrupt();

    // The main thread waits for thread1 to finish
    thread1.join();
    System.out.println("Main thread finished.");
  }
}`}
      </code></pre><br/>

      <h2>`join()` with a Timeout</h2>
      <p>The <code>join()</code> method also has an overloaded version that accepts a time parameter in milliseconds. This version allows you to wait for the thread to finish, but only for a specific amount of time. If the thread doesn't finish within the time limit, the calling thread will resume execution.</p>

      <pre><code>
{`class MyThread extends Thread {
  @Override
  public void run() {
    try {
      System.out.println(Thread.currentThread().getName() + " is running...");
      Thread.sleep(5000);  // Simulate some work by sleeping for 5 seconds
      System.out.println(Thread.currentThread().getName() + " finished.");
    } catch (InterruptedException e) {
      System.out.println(Thread.currentThread().getName() + " was interrupted.");
    }
  }
}

public class TestThreadJoinTimeout {
  public static void main(String[] args) throws InterruptedException {
    MyThread thread1 = new MyThread();
    thread1.start();

    thread1.join(2000); // Main thread waits for 2 seconds for thread1 to finish

    System.out.println("Main thread finished.");
  }
}`}
      </code></pre><br/>

      <h2 style={{paddingBottom:"6px"}}>Key Takeaways</h2>
      <ul>
        <li>The <code>join()</code> method ensures that one thread waits for another thread to finish before proceeding.</li><br/>
        <li>It is used to control the execution order of threads.</li><br/>
        <li>Calling <code>join()</code> without a timeout causes the current thread to wait indefinitely until the other thread finishes.</li><br/>
        <li>Using <code>join()</code> with a timeout allows for waiting only for a specific amount of time.</li><br/>
        <li>If the current thread is interrupted while waiting, <code>InterruptedException</code> is thrown and should be handled.</li>
      </ul><br/>

      <h2>Conclusion</h2>
      <p>In Java multithreading, the <code>join()</code> method is a powerful tool to coordinate and control the execution flow between threads. It ensures that one thread waits for another to finish, helping to synchronize tasks in multithreaded applications.</p>
    </div>
  )}



{selectedChapter === 'chapter119' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java Naming a Thread </h1>

      <p>
        In Java, every thread has a name associated with it. By default, when a thread is created, it gets a name automatically assigned by the JVM, usually something like "Thread-0", "Thread-1", and so on. However, you can also assign a custom name to a thread to make it easier to identify, especially in debugging or logging situations.
      </p><br/>

      <h2>1. Why Name a Thread?</h2>
      <p>
        Naming a thread can be helpful in various situations:
      </p>
      <ul>
        <li><strong>Debugging:</strong> When you're dealing with multiple threads, it can become difficult to know which thread is causing an issue. By naming the threads, you can make it easier to identify which thread is performing which task.</li><br/>
        <li><strong>Logging:</strong> Thread names can be used in log files to indicate which thread is executing which piece of code, helping you trace problems or understand execution flow in a multithreaded application.</li>
      </ul><br/>

      <h2>2. How to Name a Thread?</h2>
      <p>
        There are two primary ways to name a thread in Java:
      </p>
      <ol>
        <li>Using the Thread constructor that accepts a name.</li><br/>
        <li>Using the <code>setName()</code> method on an existing thread.</li>
      </ol><br/>

      <h3>2.1 Naming a Thread Using the Constructor</h3>
      <p>
        You can assign a name to a thread when you create it using a constructor that accepts a <code>String</code> argument for the thread name.
      </p>
      <pre>
        <code>
{`public Thread(String name)`}
        </code>
      </pre>
      <h4>Example:</h4>
      <pre>
        <code>
{`class MyThread extends Thread {
  public MyThread(String name) {
    super(name);  // Call the parent class constructor with a custom thread name
  }

  @Override
  public void run() {
    System.out.println(Thread.currentThread().getName() + " is running...");
  }
}

public class TestThreadNaming {
  public static void main(String[] args) {
    MyThread thread1 = new MyThread("WorkerThread1");
    MyThread thread2 = new MyThread("WorkerThread2");

    thread1.start();  // Start thread1
    thread2.start();  // Start thread2
}`}
        </code>
      </pre>
      <p>
        In this example, <code>WorkerThread1</code> and <code>WorkerThread2</code> are the names of the threads. When the threads run, the output will be:
      </p>
      <pre>
        <code>
{`WorkerThread1 is running...
WorkerThread2 is running...`}
        </code>
      </pre><br/>

      <h3>2.2 Naming a Thread Using setName() Method</h3>
      <p>
        If you already have a thread and want to change its name after it has been created, you can use the <code>setName()</code> method.
      </p>
      <pre>
        <code>
{`public void setName(String name)`}
        </code>
      </pre>
      <h4>Example:</h4>
      <pre>
        <code>
{`class MyThread extends Thread {
  @Override
  public void run() {
    System.out.println(Thread.currentThread().getName() + " is running...");
  }
}

public class TestThreadNaming {
  public static void main(String[] args) {
    MyThread thread1 = new MyThread();
    thread1.setName("CustomThreadName");  // Set a custom name for thread1
    thread1.start();  // Start thread1
}`}
        </code>
      </pre>
      <p>
        In this case, the thread name is set to <code>CustomThreadName</code> after the thread is created but before it starts. When the thread runs, the output will be:
      </p>
      <pre>
        <code>
{`CustomThreadName is running...`}
        </code>
      </pre><br/>

      <h2>3. Getting the Name of a Thread</h2>
      <p>
        Once a thread has a name, you can retrieve it using the <code>getName()</code> method. This is useful for printing the thread's name in logs or debug statements.
      </p>
      <h4>Example:</h4>
      <pre>
        <code>
{`class MyThread extends Thread {
  @Override
  public void run() {
    System.out.println("Thread name: " + Thread.currentThread().getName());
  }
}

public class TestThreadNaming {
  public static void main(String[] args) {
    MyThread thread1 = new MyThread();
    thread1.setName("MyCustomThread");
    thread1.start();
}`}
        </code>
      </pre>
      <p>
        The output will print:
      </p>
      <pre>
        <code>
{`Thread name: MyCustomThread`}
        </code>
      </pre><br/>

      <h2>4. Default Thread Names</h2>
      <p>
        If you don't assign a name to a thread, Java automatically assigns a default name. The names are in the format <code>"Thread-"</code> followed by a number, starting from 0.
      </p>
      <h4>Example:</h4>
      <pre>
        <code>
{`class MyThread extends Thread {
  @Override
  public void run() {
    System.out.println("Thread name: " + Thread.currentThread().getName());
  }
}

public class TestThreadNaming {
  public static void main(String[] args) {
    MyThread thread1 = new MyThread();
    thread1.start();  // This will have a default name like "Thread-0"
}`}
        </code>
      </pre>
      <p>
        If you don't call <code>setName()</code> or pass a name to the constructor, the output might be:
      </p>
      <pre>
        <code>
{`Thread name: Thread-0`}
        </code>
      </pre><br/>

      <h2>5. Best Practices for Naming Threads</h2>
      <ul>
        <li><strong>Meaningful Names:</strong> Give threads descriptive names that indicate their purpose or task, like <code>"DataProcessingThread"</code> or <code>"UIUpdateThread"</code>.</li><br/>
        <li><strong>Avoid Generic Names:</strong> Avoid using generic names like <code>"Thread1"</code>, <code>"Thread2"</code>, etc., as it can be difficult to figure out what the thread is doing.</li><br/>
        <li><strong>Consistency:</strong> Be consistent with naming conventions, especially if you have multiple threads of the same type performing similar tasks.</li>
      </ul><br/>

      <h2>6. Key Takeaways</h2>
      <ul>
        <li>By default, threads are named automatically (e.g., <code>Thread-0</code>, <code>Thread-1</code>).</li><br/>
        <li>You can assign custom names using the <code>Thread(String name)</code> constructor or the <code>setName()</code> method.</li><br/>
        <li>Naming threads helps in debugging and logging, especially when working with multiple threads.</li><br/>
        <li>Thread names can be retrieved using <code>getName()</code>.</li>
      </ul><br/>

      <h2>7. Conclusion</h2>
      <p>
        Naming threads in Java is a simple but effective way to improve the readability of your multithreaded code, making it easier to debug and track thread activities. Whether you're using the <code>Thread(String name)</code> constructor or <code>setName()</code>, naming threads helps you identify and manage threads more efficiently in complex applications.
      </p>
    </div>
  )}


{selectedChapter === 'chapter120' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Thread Priority  </h1>

    <p>
      In Java, <strong>thread priority</strong> is a mechanism that allows you to specify the importance or urgency of a thread relative to other threads. This can be helpful in determining the order in which threads are executed, although it is important to note that thread priority is a <strong>suggestion</strong> to the JVM, not a guarantee.
    </p><br/>

    <h2>1. What is Thread Priority?</h2>
    <p>
      Thread priority allows you to assign a priority value to a thread, which can influence the order in which the thread is scheduled to run by the operating system. Threads with higher priority are generally given more CPU time, but it's ultimately the operating system's scheduler that decides the exact behavior.
    </p><br/>
    <p>Java provides a set of constants for thread priority levels:</p>
    <ul>
      <li><code>Thread.MIN_PRIORITY</code> (value 1)</li><br/>
      <li><code>Thread.NORM_PRIORITY</code> (value 5) - default priority</li><br/>
      <li><code>Thread.MAX_PRIORITY</code> (value 10)</li>
    </ul><br/>

    <h2>2. Setting the Thread Priority</h2>
    <p>
      In Java, you can set the priority of a thread using the <code>setPriority(int priority)</code> method. The priority value must be between <code>Thread.MIN_PRIORITY</code> (1) and <code>Thread.MAX_PRIORITY</code> (10). If you don't explicitly set a priority, Java assigns the default priority (<code>Thread.NORM_PRIORITY</code>).
    </p>

    <h3>Syntax:</h3>
    <pre>
      <code>
        public final void setPriority(int priority)
      </code>
    </pre>

    <h3>Example:</h3>
    <pre>
      <code>{`class MyThread extends Thread {
    @Override
    public void run() {
        System.out.println(Thread.currentThread().getName() + " is running...");
    }
}

public class Main {
    public static void main(String[] args) {
        MyThread thread1 = new MyThread();
        MyThread thread2 = new MyThread();
        
        // Set thread priorities
        thread1.setPriority(Thread.MAX_PRIORITY);  // Highest priority
        thread2.setPriority(Thread.MIN_PRIORITY);  // Lowest priority

        thread1.start();
        thread2.start();
    }
}`}</code>
    </pre>
    <p>
      <strong>Explanation:</strong><br />
      In this example, <code>thread1</code> is given the highest priority (<code>Thread.MAX_PRIORITY</code>), and <code>thread2</code> is given the lowest priority (<code>Thread.MIN_PRIORITY</code>). The exact order in which <code>thread1</code> and <code>thread2</code> run is determined by the JVM and the operating system, but <code>thread1</code> is more likely to be executed first due to its higher priority.
    </p><br/>

    <h2>3. Default Priority</h2>
    <p>
      If you don’t set a priority explicitly, a thread gets the default priority value of <code>Thread.NORM_PRIORITY</code>, which is 5. This is the default for threads created by calling the <code>Thread</code> constructor without a priority argument.
    </p>

    <h3>Example:</h3>
    <pre>
      <code>{`class MyThread extends Thread {
    @Override
    public void run() {
        System.out.println(Thread.currentThread().getName() + " is running...");
    }
}

public class Main {
    public static void main(String[] args) {
        MyThread thread = new MyThread();
        thread.start();  // Thread will have the default priority of 5
    }
}`} </code>
    </pre><br/>

    <h2>4. Thread Priority Ranges</h2>
    <p>
      Java allows the priority values to be in the range of 1 to 10:
    </p>
    <ul>
      <li><code>Thread.MIN_PRIORITY</code> (1): The lowest priority a thread can have.</li><br/>
      <li><code>Thread.NORM_PRIORITY</code> (5): The default priority (if not set).</li><br/>
      <li><code>Thread.MAX_PRIORITY</code> (10): The highest priority a thread can have.</li>
    </ul><br/>
    <p>
      You can set any value between 1 and 10, but note that setting the priority to a value outside this range will result in an <code>IllegalArgumentException</code>.
    </p><br/>

    <h2>5. Thread Priority and OS Behavior</h2>
    <p>
      Thread priorities are advisory to the operating system's thread scheduler. The actual behavior depends on the operating system and how it handles thread scheduling. Some operating systems may honor thread priorities strictly, while others may treat all threads equally regardless of priority.
    </p><br/>
    <p>
      For example, on some operating systems:
      <ul>
        <li><strong>High-priority threads</strong> may get more CPU time.</li><br/>
        <li><strong>Low-priority threads</strong> may be delayed or receive less CPU time.</li>
      </ul><br/>
      However, on other systems, thread priority may have little to no effect on thread scheduling.
    </p><br/>

    <h2>6. Thread Scheduling</h2>
    <p>
      Thread scheduling is handled by the thread scheduler, which is part of the JVM. The scheduler typically uses the following two policies for thread execution:
    </p><br/>
    <ul>
      <li><strong>Preemptive Scheduling</strong>: The scheduler gives CPU time to threads based on priority.</li><br/>
      <li><strong>Cooperative Scheduling</strong>: Threads give up control voluntarily to allow other threads to run.</li>
    </ul><br/>
    <p>
      Java's thread scheduling is preemptive, meaning that higher-priority threads are more likely to be chosen for execution.
    </p><br/>

    <h2>7. Important Points About Thread Priority</h2>
    <ul>
      <li><strong>Thread priority is a hint, not a guarantee</strong>: It is up to the JVM and OS to honor the priority settings.</li><br/>
      <li><strong>Thread priority is not always honored</strong>: On some operating systems, thread priorities may be ignored, and all threads may be treated equally.</li><br/>
      <li><strong>Priority affects CPU time allocation</strong>: Higher-priority threads might get more CPU time, but in a system with many threads, all threads will eventually run.</li><br/>
      <li><strong>Be careful with thread priority</strong>: Overusing thread priority can lead to poor application performance, especially if you give one thread too much priority over others. It may lead to starvation (where low-priority threads never get executed).</li>
    </ul><br/>

    <h2>8. Best Practices for Using Thread Priority</h2>
    <ul>
      <li><strong>Avoid overusing priority</strong>: In most cases, relying on Java's default priority is sufficient. Thread priority is mainly useful in real-time systems where precise control over execution order is required.</li><br/>
      <li><strong>Use priority for critical tasks</strong>: Only assign high priority to critical threads that must be executed immediately (e.g., for time-sensitive tasks).</li><br/>
      <li><strong>Don't use priority for scheduling</strong>: For complex scheduling needs, it's better to use higher-level frameworks or use <code>ExecutorService</code> in Java, which abstracts thread management.</li>
    </ul><br/>

    <h2>9. Key Takeaways</h2>
    <ul>
      <li>Thread priority is a mechanism to influence the scheduling of threads.</li><br/>
      <li>Java allows you to set thread priorities using <code>setPriority(int priority)</code> with values ranging from 1 to 10.</li><br/>
      <li>The operating system determines the exact behavior of thread scheduling, so thread priority is only a suggestion.</li><br/>
      <li>By default, threads have a priority of <code>Thread.NORM_PRIORITY</code> (5).</li><br/>
      <li>Thread priority should be used judiciously, mainly in time-sensitive scenarios.</li>
    </ul><br/>

    <p>
      This is a detailed yet simple explanation of thread priority in Java. It’s a useful feature for fine-tuning thread execution, but it’s important to remember that it’s not a guarantee, and system-specific behavior may vary.
    </p>
  </div>
)}



{selectedChapter === 'chapter121' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Daemon Thread </h1>

    <p>
      In Java, a <strong>Daemon thread</strong> is a special type of thread that runs in the background to perform tasks
      that support the main program but are not critical to the program’s completion. These threads are typically used
      for housekeeping tasks such as garbage collection, periodic background tasks, or monitoring.
    </p>

    <p>
      A <strong>Daemon thread</strong> is designed to run in the background and automatically terminate when all
      non-daemon threads in the program have finished executing. In other words, the Java Virtual Machine (JVM) exits
      when only daemon threads are left running.
    </p><br/>

    <h2>1. What is a Daemon Thread?</h2>
    <p>
      A daemon thread is a thread that runs in the background, typically for performing maintenance tasks, like garbage
      collection, without preventing the program from terminating. When all user (non-daemon) threads are finished, the
      JVM shuts down, even if there are still daemon threads running.
    </p><br/>

    <h2>2. How to Create a Daemon Thread</h2>
    <p>
      By default, when you create a thread in Java, it is a <strong>user thread</strong>, which means it will keep the
      JVM running until it finishes its execution. To create a daemon thread, you need to call the
      <code>setDaemon(true)</code> method on a thread instance before starting it.
    </p><br/>

    <h3>Syntax:</h3>
    <pre>
      <code>public void setDaemon(boolean daemon)</code>
    </pre><br/>

    <h2>3. Example: Creating a Daemon Thread</h2>
    <p>
      Here's an example where a thread is created as a daemon thread:
    </p>

    <pre>
      <code>
        {`class DaemonThread extends Thread {
    public void run() {
        while (true) {
            System.out.println("Daemon thread is running...");
            try {
                Thread.sleep(1000);  // Sleep for 1 second
            } catch (InterruptedException e) {
                System.out.println("Daemon thread interrupted");
            }
        }
    }
}

public class Main {
    public static void main(String[] args) {
        DaemonThread thread = new DaemonThread();

        // Set the thread as a daemon thread
        thread.setDaemon(true);  // Make this thread a daemon thread

        thread.start();  // Start the daemon thread

        try {
            Thread.sleep(5000);  // Main thread sleeps for 5 seconds
        } catch (InterruptedException e) {
            e.printStackTrace();
        }

        // After main thread finishes, JVM will exit and daemon thread will stop
        System.out.println("Main thread finished.");
    }
}`}
      </code>
    </pre>

    <h3>Explanation:</h3>
    <ul>
      <li>
        The <code>DaemonThread</code> class extends <code>Thread</code> and overrides the <code>run()</code> method.
      </li><br/>
      <li>
        The <code>setDaemon(true)</code> method is called to make the thread a daemon thread before it is started.
      </li><br/>
      <li>
        The main thread sleeps for 5 seconds, and the daemon thread runs in the background, printing a message every second.
      </li><br/>
      <li>
        Once the main thread finishes, the JVM exits and the daemon thread is automatically terminated, even though it
        has not finished its work.
      </li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>4. Key Characteristics of Daemon Threads</h2>
    <ul>
      <li><strong>Background Execution:</strong> Daemon threads are background threads that are useful for tasks that don't prevent the program from terminating, such as garbage collection.</li><br/>
      <li><strong>Automatic Termination:</strong> They are automatically terminated when all user threads have finished. When no user threads are left, the JVM exits, and any daemon threads running are abruptly stopped.</li><br/>
      <li><strong>Not Critical to Program Termination:</strong> Daemon threads do not prevent the JVM from shutting down if there are no user threads left.</li><br/>
      <li><strong>Cannot be started after being set as daemon:</strong> You must call <code>setDaemon(true)</code> before calling <code>start()</code> on a thread. If you try to set a daemon thread after starting it, the JVM will throw an <code>IllegalThreadStateException</code>.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>5. When to Use Daemon Threads</h2>
    <p>
      Daemon threads are typically used for tasks like:
    </p><br/>
    <ul>
      <li><strong>Garbage Collection:</strong> Java’s garbage collector runs as a daemon thread, cleaning up memory in the background.</li><br/>
      <li><strong>Background Tasks:</strong> Daemon threads can be used to monitor system health, log data, or periodically execute certain tasks without blocking the main program.</li><br/>
      <li><strong>Time-sensitive Tasks:</strong> If you need something to run in the background, such as a timer or server monitoring, a daemon thread is a good option.</li>
    </ul><br/>

    <h2 style={{paddingBottom:"6px"}}>6. Daemon Threads and Program Exit</h2>
    <p>
      The main characteristic of daemon threads is that the JVM does not wait for them to finish execution before exiting.
      This is very different from user threads, which prevent the JVM from exiting until they complete.
    </p><br/>
    <ul>
      <li><strong>User Threads:</strong> The JVM will wait for all user threads to finish before terminating.</li><br/>
      <li><strong>Daemon Threads:</strong> The JVM terminates as soon as the last user thread finishes, even if daemon threads are still running.</li>
    </ul><br/>

    <h2>7. Important Considerations</h2>
    <ul>
      <li><strong>Daemon threads are typically used for non-essential tasks:</strong> Because they are terminated abruptly when the program exits, you should not use them for tasks that require proper cleanup or that must complete before the program ends.</li><br/>
      <li><strong>Thread Interruption:</strong> Daemon threads can be interrupted at any point, and the JVM does not guarantee their completion.</li><br/>
      <li><strong>Thread Safety:</strong> Daemon threads must be thread-safe if they interact with other threads. They might be terminated at any time, so you need to handle situations where a daemon thread is interrupted mid-execution.</li>
    </ul><br/>

    <h2>8. Best Practices for Daemon Threads</h2>
    <ul>
      <li><strong>Use daemon threads for background tasks:</strong> For non-critical tasks that do not need to complete before the program exits, such as logging or periodic background work.</li><br/>
      <li><strong>Avoid using daemon threads for important tasks:</strong> Do not use daemon threads for tasks that must finish properly, like file I/O, network communication, or database transactions.</li><br/>
      <li><strong>Ensure safe termination:</strong> If daemon threads are accessing shared resources, make sure you handle synchronization and proper termination to avoid potential issues when they are abruptly stopped.</li>
    </ul><br/>

    <h2>9. Key Takeaways</h2>
    <ul>
      <li>A <strong>daemon thread</strong> runs in the background and is automatically terminated when all user threads have finished.</li><br/>
      <li>Daemon threads are useful for tasks like garbage collection, logging, or periodic background tasks.</li><br/>
      <li>To create a daemon thread, use the <code>setDaemon(true)</code> method before calling <code>start()</code>.</li><br/>
      <li>The JVM does not wait for daemon threads to finish before exiting, making them suitable for non-critical tasks.</li><br/>
      <li><strong>Important:</strong> Daemon threads should not be used for tasks that need to complete properly before the program exits.</li>
    </ul><br/>

    <p>
      Daemon threads offer an efficient way to handle background tasks in Java programs while ensuring that they don’t prevent the JVM from shutting down when no user threads are left. However, because they can be abruptly stopped, care should be taken to ensure they do not perform essential work.
    </p>
  </div>
)}



{selectedChapter === 'chapter122' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Thread Pool  </h1>

          <p>
            In Java, managing threads can become complex, especially when dealing with a large number of tasks that need to be executed concurrently. One efficient way to handle multiple threads is by using a <strong>Thread Pool</strong>.
          </p>

          <p>
            A <strong>Thread Pool</strong> is a collection of threads that can be used to execute tasks concurrently. Instead of creating a new thread every time a task needs to be executed, a thread pool reuses a fixed number of threads for multiple tasks. This helps in improving performance, as creating and destroying threads frequently is an expensive operation.
          </p><br/>

          <h2>1. What is a Thread Pool?</h2>
          <p>
            A <strong>Thread Pool</strong> is a container that holds a set of worker threads. These threads are used to execute tasks asynchronously. When a task is submitted to the thread pool, it assigns the task to an available thread in the pool. Once the task is completed, the thread is returned to the pool, ready to handle another task.
          </p><br/>

          <h2>2. Benefits of Using a Thread Pool</h2>
          <ul>
            <li><strong>Performance Improvement:</strong> Reusing threads instead of creating new ones every time can reduce the overhead of thread creation.</li><br/>
            <li><strong>Resource Management:</strong> It limits the number of threads that can run at once, helping in resource management, especially in systems with limited resources (like CPU or memory).</li><br/>
            <li><strong>Better Utilization of System Resources:</strong> Thread pools allow better utilization of system resources because tasks are distributed among threads based on availability.</li><br/>
            <li><strong>Control over Concurrency:</strong> You can control the maximum number of threads that can run simultaneously, preventing the system from being overloaded.</li>
          </ul><br/>

          <h2>3. How to Create a Thread Pool in Java</h2>
          <p>
            In Java, the <strong>Executor Framework</strong> provides a simple and flexible way to manage thread pools. The <code>ExecutorService</code> interface is used to manage thread pools, and the <code>Executors</code> class provides factory methods to create different types of thread pools.
          </p>

          <h3>Example of Creating a Thread Pool:</h3>
          <pre>
            {`import java.util.concurrent.ExecutorService;
import java.util.concurrent.Executors;

public class Main {
    public static void main(String[] args) {
        // Create a thread pool with a fixed number of threads
        ExecutorService executor = Executors.newFixedThreadPool(3); // A pool with 3 threads

        // Submit tasks to the thread pool
        for (int i = 1; i <= 5; i++) {
            executor.submit(new RunnableTask(i));
        }

        // Shutdown the executor once all tasks are completed
        executor.shutdown();
    }
}

class RunnableTask implements Runnable {
    private int taskId;

    public RunnableTask(int taskId) {
        this.taskId = taskId;
    }

    @Override
    public void run() {
        System.out.println("Task " + taskId + " is being executed by " + Thread.currentThread().getName());
        try {
            Thread.sleep(2000); // Simulate work
        } catch (InterruptedException e) {
            Thread.currentThread().interrupt();
        }
        System.out.println("Task " + taskId + " completed by " + Thread.currentThread().getName());
    }
}`}
          </pre><br/>

          <h2>4. Types of Thread Pools</h2>
          <ul>
            <li><strong>Fixed Thread Pool (newFixedThreadPool):</strong> Creates a pool with a fixed number of threads. Tasks are queued when all threads are busy.</li><br/>
            <li><strong>Cached Thread Pool (newCachedThreadPool):</strong> Creates a pool that dynamically adjusts the number of threads based on demand. Threads are terminated if idle for too long.</li><br/>
            <li><strong>Single Thread Executor (newSingleThreadExecutor):</strong> Creates a pool with only one thread. Tasks are executed sequentially.</li><br/>
            <li><strong>Scheduled Thread Pool (newScheduledThreadPool):</strong> Creates a pool that can schedule tasks to run at fixed-rate intervals or after a delay.</li>
          </ul><br/>

          <h2>5. Executor Framework Methods</h2>
          <ul>
            <li><code>submit():</code> Submits a task for execution and returns a <code>Future</code> object to check the status of the task.</li><br/>
            <li><code>invokeAll():</code> Submits a collection of tasks and waits for all of them to finish.</li><br/>
            <li><code>invokeAny():</code> Submits a collection of tasks and waits for the first one to finish.</li><br/>
            <li><code>shutdown():</code> Initiates an orderly shutdown of the executor service.</li><br/>
            <li><code>shutdownNow():</code> Attempts to stop all actively executing tasks and halts the processing of waiting tasks.</li>
          </ul><br/>

          <h2>6. When to Use a Thread Pool</h2>
          <p>
            A thread pool is useful when you have a large number of tasks to be executed concurrently but don't want to overwhelm the system by creating a new thread for each task. You should consider using a thread pool in scenarios such as:
          </p><br/>
          <ul>
            <li>Handling large numbers of client requests in a server.</li><br/>
            <li>Running background tasks that need to be executed periodically.</li><br/>
            <li>Improving performance by limiting the number of threads used by the program.</li>
          </ul><br/>

          <h2>7. Important Considerations</h2>
          <ul>
            <li><strong>Thread Pool Size:</strong> The size of the thread pool should be chosen based on the system’s capabilities and the nature of the tasks.</li><br/>
            <li><strong>Task Queueing:</strong> Tasks that arrive when all threads are busy will be queued in a waiting queue. Be mindful of the queue size, as it can affect performance.</li><br/>
            <li><strong>Graceful Shutdown:</strong> Always ensure to shut down the thread pool properly using <code>shutdown()</code> to avoid resource leakage.</li>
          </ul><br/>

          <h2>8. Key Takeaways</h2>
          <ul>
            <li>A <strong>Thread Pool</strong> reuses a set of threads to execute multiple tasks concurrently.</li><br/>
            <li>Thread pools improve performance by reducing the overhead of thread creation and destruction.</li><br/>
            <li>Java’s <strong>Executor Framework</strong> provides several types of thread pools, including fixed, cached, and scheduled thread pools.</li><br/>
            <li>You can manage tasks and control thread behavior using methods like <code>submit()</code>, <code>invokeAll()</code>, and <code>shutdown()</code>.</li><br/>
            <li>Thread pools are especially useful in applications with high concurrency and multiple short-lived tasks.</li>
          </ul>
        </div>
      )}
   



   {selectedChapter === 'chapter123' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java Thread Group </h1>
          
          <p>
            A <strong>Thread Group</strong> in Java is a mechanism that allows you to group multiple threads into a single unit, making it easier to manage and control them collectively. It was introduced in Java to provide a way to organize threads and manage their execution in a more structured manner.
          </p><br/>

          <h3>2. Key Features of Thread Groups</h3>
          <ul>
            <li><strong>Organizing Threads:</strong> It provides an easy way to organize related threads, which is helpful in cases where you want to manage threads that perform similar tasks or belong to the same part of your program.</li><br/>
            <li><strong>Hierarchical Structure:</strong> Thread groups can have a hierarchical structure, meaning a thread group can contain other thread groups. This allows you to manage threads in a tree-like structure.</li><br/>
            <li><strong>Control Over Threads:</strong> You can perform operations like interrupting, checking if all threads in a group are alive, and setting a thread's uncaught exception handler for all threads in the group.</li><br/>
            <li><strong>Thread Monitoring:</strong> It provides a way to get information about all the threads in a group, such as their state, whether they are alive, and how many threads are part of the group.</li>
          </ul><br/>

          <h3>3. How to Create a Thread Group?</h3>
          <p>A thread group is created by using the <code>ThreadGroup</code> class. You can create a thread group by either:</p><br/>
          <ol>
            <li>Using the default constructor: This creates a thread group with the specified name.</li><br/>
            <li>Using the constructor that allows you to create a child thread group of an existing group, thus creating a hierarchical structure.</li>
          </ol><br/>

          <h4>Example: Creating a Thread Group</h4>
          <pre>
            {`public class Main {
  public static void main(String[] args) {
    // Create a new thread group named "Group1"
    ThreadGroup group1 = new ThreadGroup("Group1");

    // Create a new thread in the "Group1" thread group
    Thread thread1 = new Thread(group1, new RunnableTask());
    Thread thread2 = new Thread(group1, new RunnableTask());

    // Start the threads
    thread1.start();
    thread2.start();

    // Print all active threads in the "Group1"
    group1.list();
  }
}

class RunnableTask implements Runnable {
  @Override
  public void run() {
    System.out.println(Thread.currentThread().getName() + " is executing.");
  }
}`}
          </pre><br/>

          <h3>4. Important Methods of ThreadGroup</h3>
          <ul>
            <li><code>activeCount()</code>: Returns the number of active threads in the group (including all subgroups). This helps track the number of threads in the group.</li><br/>
            <li><code>list()</code>: Prints all threads in the group to the console. It also prints information like thread name, thread state, etc.</li><br/>
            <li><code>interrupt()</code>: Interrupts all threads in the group.</li><br/>
            <li><code>setDaemon()</code>: This method sets the thread group to be a daemon group, meaning all threads in the group are treated as daemon threads, which are automatically terminated when the program ends.</li><br/>
            <li><code>uncaughtExceptionHandler()</code>: You can set a handler for uncaught exceptions in the thread group. This handler will be invoked whenever a thread in the group throws an uncaught exception.</li>
          </ul><br/>

          <h3>5. Thread Group Hierarchy</h3>
          <p>A thread group can have a parent-child relationship, where one thread group is a child of another. This helps you manage threads in a hierarchical manner. If you perform an action (e.g., interrupt, list) on a parent group, it affects the child groups as well.</p>

          <pre>
            {`
ThreadGroup parentGroup = new ThreadGroup("Parent Group");
ThreadGroup childGroup = new ThreadGroup(parentGroup, "Child Group");

// Now, you can add threads to the child group and manage them.
            `}
          </pre><br/>

          <h3 style={{paddingBottom:"6px"}}>6. Use Cases of Thread Group</h3>
          <ul>
            <li><strong>Organizing Threads:</strong> If you have multiple threads performing similar tasks (like handling different parts of a large server), grouping them allows easier management.</li><br/>
            <li><strong>Graceful Shutdown:</strong> In cases where you need to stop all threads of a certain type or operation, using a thread group allows you to easily interrupt or stop all threads within that group.</li><br/>
            <li><strong>Exception Handling:</strong> If a thread in a group throws an uncaught exception, you can set a global exception handler to handle those exceptions consistently for all threads in the group.</li>
          </ul><br/>

          <h3 style={{paddingBottom:"6px"}}>7. Important Considerations</h3>
          <ul>
            <li><strong>Legacy Technology:</strong> While <code>ThreadGroup</code> provides useful functionalities, it is considered somewhat outdated. Modern Java concurrency often uses more powerful tools, such as the Executor framework, for thread management.</li><br/>
            <li><strong>Thread Safety:</strong> Although a thread group helps in managing threads, you still need to be cautious about thread safety issues within the threads that belong to the group.</li><br/>
            <li><strong>Daemon Threads:</strong> If you set a thread group as a daemon, all threads within it will be daemon threads. Daemon threads are killed automatically when the JVM shuts down.</li>
          </ul><br/>

          <h3 style={{paddingBottom:"6px"}}>8. Key Takeaways</h3>
          <ul>
            <li>A <strong>Thread Group</strong> is a collection of threads that can be managed collectively.</li><br/>
            <li>It provides an easy way to organize threads, perform operations like interrupting all threads in the group, and monitor their status.</li><br/>
            <li>Thread groups can be hierarchical, with parent-child relationships between groups.</li><br/>
            <li><strong>ThreadGroup</strong> is now considered somewhat outdated in Java, with modern concurrency management being done through the <strong>Executor Framework</strong>.</li><br/>
            <li>Use thread groups when you need to manage a group of related threads with operations like interruption or exception handling.</li>
          </ul><br/>

          <p>Overall, thread groups allow for better thread management, especially in large applications where multiple threads are required to execute various tasks simultaneously.</p>
        </div>
      )}
  


  {selectedChapter === 'chapter124' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>performing multiple tasks  </h1>


      <p>
        In Java, performing multiple tasks concurrently can greatly improve the performance of applications by utilizing system resources more efficiently. This is commonly achieved through <strong>multithreading</strong> and <strong>parallel processing</strong>, where multiple tasks are executed at the same time. Below is a detailed yet simple explanation of how Java handles multiple tasks and enhances performance.
      </p><br/>

      <h2>1. Introduction to Java Multithreading</h2>

      <p>
        <strong>Multithreading</strong> is a core feature in Java that allows you to perform multiple tasks concurrently in a single program. In multithreading, a program is divided into smaller threads, each of which runs independently but shares the same resources, such as memory.
      </p><br/>

      <p><strong>Why use multithreading?</strong></p>
      <ul>
        <li><strong>Efficiency:</strong> Multithreading helps utilize CPU resources better by allowing tasks to run concurrently, thus reducing the total execution time for tasks.</li><br/>
        <li><strong>Better User Experience:</strong> It allows one part of the program to run while the other is waiting for input/output operations, making the program feel more responsive (e.g., GUI apps, web servers).</li><br/>
        <li><strong>Parallelism:</strong> On multi-core processors, multiple threads can run on different cores simultaneously, which can lead to significant performance improvements.</li>
      </ul><br/>

      <h2>2. Key Concepts in Java Multithreading</h2>
      <p>
        Before diving into performance, it's important to understand the basic elements involved in Java's multithreading system.
      </p><br/>

      <ul>
        <li><strong>Thread:</strong> A thread is the smallest unit of execution in a Java program. Each thread has its own execution path and shares resources like memory with other threads in the program.</li><br/>
        <li><strong>Runnable Interface:</strong> To create a thread, you typically implement the <code>Runnable</code> interface, which has a single method <code>run()</code>. You pass an instance of this interface to a <code>Thread</code> object to define the task that the thread should execute.
          <pre>
            {`class MyRunnable implements Runnable {
  @Override
  public void run() {
    System.out.println(Thread.currentThread().getName() + " is running");
  }
}`}
          </pre>
        </li><br/>
        <li><strong>Thread Class:</strong> This class is used to represent a thread of execution. You can extend it or implement the <code>Runnable</code> interface for multithreading.</li><br/>
        <li><strong>Executor Service:</strong> The <code>Executor</code> framework provides a higher-level replacement for the traditional way of creating and managing threads. It simplifies the management of thread pools, allowing you to better control and optimize thread usage.
          <pre>
            {`ExecutorService executor = Executors.newFixedThreadPool(10);
executor.submit(new MyRunnable());`}
          </pre>
        </li>
      </ul><br/>

      <h2>3. Types of Tasks and Performance Enhancement</h2>

      <h3>A. Parallel Processing of Independent Tasks</h3>
      <p>
        In Java, tasks that are independent of each other can be executed in parallel. This is particularly useful when you have large amounts of data to process, such as sorting, searching, or other computations.
      </p><br/>
      <p>
        For example, if you are processing a large list of numbers (say, calculating the sum of elements), you can divide the list into chunks and assign each chunk to a different thread. This way, multiple threads can work on different parts of the data at the same time, speeding up the overall process.
      </p><br/>

      <h3>B. Task Division Using Thread Pooling</h3>
      <p>
        Instead of creating new threads for every task, thread pooling involves creating a pool of reusable threads. Thread pooling reduces the overhead of creating and destroying threads repeatedly and helps manage system resources better.
      </p><br/>
      <ul>
        <li><strong>Fixed Thread Pool:</strong> A fixed number of threads are created, and tasks are submitted to the pool. If all threads are busy, tasks wait in a queue until a thread becomes available.
          <pre>
            {`ExecutorService executor = Executors.newFixedThreadPool(4);
for (int i = 0; i < 10; i++) {
  executor.submit(new MyRunnable());
}`}
          </pre>
        </li><br/>
        <li><strong>Cached Thread Pool:</strong> Threads are created dynamically as tasks are submitted and are reused when tasks are completed. This is useful when the number of tasks is unpredictable.
          <pre>
            {`ExecutorService executor = Executors.newCachedThreadPool();
executor.submit(new MyRunnable());`}
          </pre>
        </li>
      </ul><br/>

      <h3>C. Synchronization and Concurrency</h3>
      <p>
        When multiple threads are accessing shared resources (e.g., variables or data structures), <strong>synchronization</strong> is important to avoid data corruption. Java provides mechanisms like the <code>synchronized</code> keyword and <code>Lock</code> objects to ensure that only one thread accesses a critical section of code at a time.
      </p><br/>
      <pre>
        {`synchronized (someObject) {
  // critical section code
}`}
      </pre>
      <p>
        The <code>java.util.concurrent.locks</code> package provides advanced lock mechanisms that give more control over locking and synchronization than <code>synchronized</code> blocks.
      </p><br/>

      <h3>D. Handling Multiple I/O Tasks</h3>
      <p>
        I/O-bound tasks, such as reading from or writing to a file or database, can be significantly optimized with multithreading. While one thread is blocked waiting for I/O operations to complete, other threads can continue executing.
      </p><br/>
      <p>
        For example, a file download process can be divided into multiple parts where each part is downloaded by a different thread. When one part finishes, it can be processed while the other parts are still downloading.
      </p>

      <h2 style={{paddingBottom:"6px"}}>4. Performance Considerations</h2>
      <ul>
        <li><strong>CPU-bound Tasks:</strong> For tasks that require heavy CPU processing, such as complex calculations, multithreading can improve performance by running on multiple CPU cores simultaneously.</li><br/>
        <li><strong>I/O-bound Tasks:</strong> For tasks that involve I/O operations (e.g., file reading/writing, database access, network communication), multithreading helps by allowing threads to continue executing while others wait for I/O operations to complete.</li><br/>
        <li><strong>Thread Overhead:</strong> Creating too many threads can actually degrade performance due to the overhead of thread management (context switching, memory consumption, etc.).</li><br/>
        <li><strong>Thread Contention:</strong> When multiple threads compete for the same resources (e.g., CPU, memory, or I/O), contention can occur, which can decrease performance.</li><br/>
        <li><strong>CPU Affinity:</strong> Some advanced techniques involve binding certain threads to specific CPU cores, known as CPU affinity. This helps improve cache locality and reduces the time spent switching between CPU cores.</li>
      </ul><br/>

      <h2>5. Example of Multiple Task Performance in Java</h2>

      <pre>
        {`public class Main {
  public static void main(String[] args) {
      Runnable sumTask = () -> {
          int sum = 0;
          for (int i = 0; i < 1000; i++) {
              sum += i;
          }
          System.out.println("Sum: " + sum);
      };

      Runnable printTask = () -> {
          for (int i = 1; i <= 1000; i++) {
              System.out.println(i);
          }
      };

      ExecutorService executor = Executors.newFixedThreadPool(2);
      executor.submit(sumTask);
      executor.submit(printTask);
      executor.shutdown();
  }
}`}
      </pre><br/>

      <h2>6. Best Practices for Maximizing Performance</h2>
      <ul>
        <li>Use Thread Pools: Avoid creating too many threads manually. Use an <code>ExecutorService</code> for efficient thread management.</li><br/>
        <li>Limit Synchronization: Minimize the use of synchronization to reduce contention and blocking.</li><br/>
        <li>Batch Processing: Divide large tasks into smaller sub-tasks and process them concurrently.</li><br/>
        <li>Balance CPU and I/O tasks: Ensure you don't overuse threads for I/O-bound tasks, as they might just cause contention for system resources.</li>
      </ul><br/>

      <h2>7. Conclusion</h2>
      <p>
        Java’s multithreading capabilities allow you to perform multiple tasks concurrently, which improves performance by efficiently utilizing available resources. By using techniques like <strong>thread pooling</strong>, <strong>parallel processing</strong>, and <strong>task division</strong>, you can maximize your program's performance. However, it’s important to consider factors like synchronization, thread contention, and overhead to ensure optimal performance.
      </p>
    </div>
  )}


{selectedChapter === 'chapter125' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java ShutdownHook </h1>
      <p>
        In Java, a <strong>Shutdown Hook</strong> is a special mechanism that allows you to run certain cleanup tasks or operations when the Java Virtual Machine (JVM) is shutting down. This could be when the program ends, or when the system is shutting down (e.g., when the user closes the application, or the system is restarted or turned off).
      </p><br/>

      <h3>Why Use ShutdownHook?</h3>
      <ul>
        <li>Saving user data.</li><br/>
        <li>Releasing resources (like closing file streams, network connections, or database connections).</li><br/>
        <li>Logging important information before the program exits.</li><br/>
        <li>Cleaning up temporary files.</li>
      </ul><br/>

      <p>
        A shutdown hook allows you to execute a specific block of code to handle these tasks just before the JVM terminates.
      </p>
      <br/>
      <h3>How Shutdown Hook Works</h3>
      <p>
        The <strong>ShutdownHook</strong> works by registering a special thread that will run when the JVM is shutting down. You register a shutdown hook using the <code>Runtime.getRuntime().addShutdownHook(Thread hook)</code> method. This thread will run in the background and execute the code defined inside the <code>run()</code> method of the <code>Thread</code> class when the JVM begins its shutdown process.
      </p><br/>

      <h3>Key Concepts:</h3>
      <ul>
        <li><strong>Runtime.getRuntime().addShutdownHook(Thread hook)</strong>: This method registers a new shutdown hook.</li><br/>
        <li><strong>Thread</strong>: The shutdown hook must be a <code>Thread</code>, and its <code>run()</code> method will contain the code that is executed when the JVM is shutting down.</li><br/>
        <li><strong>JVM Shutdown</strong>: The JVM invokes shutdown hooks when it is about to terminate, whether the program ends naturally or via a system shutdown.</li>
      </ul><br/>

      <h3>How to Use a Shutdown Hook in Java</h3>
      <ol>
        <li><strong>Creating and Registering the Shutdown Hook:</strong> You create a new thread that performs cleanup tasks and register it with the JVM.</li><br/>
        <li><strong>JVM Shutdown Triggers:</strong> The shutdown hook is triggered when the JVM is about to exit (due to normal program termination, a <code>System.exit()</code> call, or a system shutdown).</li>
      </ol><br/>

      <h3>Example:</h3>
      <pre>
        <code>
{`public class Main {
  public static void main(String[] args) {
      // Register the ShutdownHook
      Runtime.getRuntime().addShutdownHook(new Thread(() -> {
          // Cleanup or shutdown tasks
          System.out.println("Shutdown hook is running...");
          // Closing resources or saving data before shutdown
      }));

      // Simulating a long-running program
      try {
          System.out.println("Program is running...");
          Thread.sleep(10000);  // Simulate some work (e.g., 10 seconds)
      } catch (InterruptedException e) {
          e.printStackTrace();
      }

      System.out.println("Program finished execution.");
  }
}`}
        </code>
      </pre><br/>

      <h3>How It Works:</h3>
      <p>
        1. <strong>Adding Shutdown Hook:</strong> We use <code>Runtime.getRuntime().addShutdownHook()</code> to register a thread with the JVM. This thread will execute the code inside its <code>run()</code> method when the JVM shuts down.
      </p><br/>
      <p>
        2. <strong>Running the Program:</strong> The program simulates some work using <code>Thread.sleep(10000)</code> for 10 seconds. During this time, if the JVM is about to shut down (e.g., when the program finishes or the system shuts down), the shutdown hook will be triggered.
      </p><br/>
      <p>
        3. <strong>Shutdown Hook Execution:</strong> If the JVM is shutting down, the message <code>"Shutdown hook is running..."</code> will be printed just before the program terminates.
      </p><br/>

      <h3>Shutdown Hook Order</h3>
      <p>
        If multiple shutdown hooks are registered, they are executed in the order they were added, but in reverse order (LIFO - Last In, First Out). This ensures that more recent hooks are given higher priority.
      </p><br/>

      <h3>Important Points to Remember</h3>
      <ul>
        <li><strong>JVM Shutdown:</strong> The shutdown hook runs when the JVM is about to exit normally (either through <code>System.exit()</code> or a system shutdown).</li><br/>
        <li><strong>Exception Handling:</strong> Any uncaught exceptions in a shutdown hook do not prevent the JVM from shutting down. The JVM will just log the exception and continue with its shutdown process.</li><br/>
        <li><strong>Limitations:</strong> A shutdown hook should be lightweight. It’s not designed to run long-running tasks because the JVM may forcefully terminate it after a certain period.</li><br/>
        <li><strong>Graceful Shutdown:</strong> Always ensure that the code inside a shutdown hook is efficient and fast. For tasks that may take longer (e.g., saving a large amount of data), it is better to perform them before invoking <code>System.exit()</code>.</li>
      </ul><br/>

      <h3>Example: Using Shutdown Hook for Saving Data</h3>
      <pre>
        <code>
{`public class Main {
    public static void main(String[] args) {
        // Register a shutdown hook for saving user data
        Runtime.getRuntime().addShutdownHook(new Thread(() -> {
            System.out.println("Saving user data before shutdown...");
            // Simulate saving data
            try {
                Thread.sleep(2000);  // Simulate a delay (e.g., saving data to file/database)
                System.out.println("User data saved.");
            } catch (InterruptedException e) {
                System.out.println("Error saving data: " + e.getMessage());
            }
        }));

        // Simulating some work in the program
        try {
            System.out.println("Program is running...");
            Thread.sleep(5000);  // Simulating program work
        } catch (InterruptedException e) {
            e.printStackTrace();
        }

        System.out.println("Program finished execution.");
    }
}`}
        </code>
      </pre><br/>

      <h3>Conclusion</h3>
      <p>
        Shutdown hooks are a powerful feature in Java that allows you to perform important tasks (like resource cleanup, saving data, etc.) when the JVM is about to terminate. They are simple to implement using <code>Runtime.getRuntime().addShutdownHook()</code>, but should be used carefully as they should execute quickly and should not block the JVM shutdown process.
      </p>
    </div>
  )}


{selectedChapter === 'chapter126' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Java Garbage Collection </h1>


          
          <p>
            In any Java program, you create many objects during its execution. However, some objects are no longer needed after a certain point. 
            If the memory used by these objects is not released, it can cause the program to run out of memory and crash. 
            This is where Garbage Collection comes in — it automatically frees up memory by removing unused objects, so you don't need to manage memory manually.
          </p><br/>

          <h2>How Garbage Collection Works in Java</h2>
          <p>The Java Virtual Machine (JVM) handles garbage collection automatically. Here's how it works:</p>

          <ol>
            <li><strong>Heap Memory</strong>: Java stores objects in the heap memory area, which is divided into different regions such as:</li><br/>
            <ul>
              <li><strong>Young Generation</strong>: Where new objects are created. This includes:
                <ul>
                  <li><strong>Eden Space</strong>: The area where new objects are allocated.</li><br/>
                  <li><strong>Survivor Spaces (S0 and S1)</strong>: Where objects are moved after surviving a garbage collection cycle.</li>
                </ul>
              </li><br/>
              <li><strong>Old Generation</strong>: Objects that have survived many garbage collection cycles and are considered long-lived are eventually promoted to the Old Generation.</li><br/>
              <li><strong>Permanent Generation (Metaspace)</strong>: Stores metadata related to the class structure and method data (in modern JVMs, this is called Metaspace).</li>
            </ul><br/>
            <li><strong>Object Reachability</strong>: An object is considered "reachable" if there is a reference to it from the root (e.g., the stack, active threads, static fields, etc.). When an object becomes unreachable (no references to it), it becomes eligible for garbage collection.</li><br/>
            <li><strong>GC Roots</strong>: These are special objects that are always reachable and are used to track references in the heap memory. Some of the GC roots are:
              <ul>
                <li>Active threads</li><br/>
                <li>Static variables</li><br/>
                <li>Local variables on the stack</li>
              </ul>
            </li><br/>
            <li><strong>Mark-and-Sweep Algorithm</strong>: Java's garbage collection typically follows the <strong>Mark-and-Sweep</strong> process:
              <ul>
                <li><strong>Mark Phase</strong>: The JVM starts by marking all reachable objects starting from the GC roots.</li><br/>
                <li><strong>Sweep Phase</strong>: It then sweeps through the heap, removing all objects that are not marked (i.e., objects that are no longer reachable).</li>
              </ul>
            </li>
          </ol><br/>

          <h2>Garbage Collection Types</h2>
          <ul>
            <li><strong>Serial Garbage Collector</strong>: A simple garbage collector designed for single-threaded applications or small programs. It uses a single thread for both marking and sweeping.</li><br/>
            <li><strong>Parallel Garbage Collector</strong>: Uses multiple threads to perform garbage collection tasks, suitable for multi-threaded applications that require more efficient GC.</li><br/>
            <li><strong>Concurrent Mark-Sweep (CMS) Collector</strong>: Focuses on minimizing the pause time caused by garbage collection by performing most of the work concurrently with the application.</li><br/>
            <li><strong>G1 Garbage Collector</strong>: The <strong>Garbage First (G1)</strong> collector is designed for applications with large heaps and aims to provide predictable pause times by dividing the heap into regions.</li>
          </ul><br/>

          <h2>Types of Garbage Collection in Java</h2>
          <h3>Minor GC</h3>
          <p>
            Occurs when the Young Generation fills up and needs to be cleaned. Involves the Eden Space and Survivor Spaces. 
            Objects that survive the Minor GC are promoted to the Old Generation. Minor GC is usually quick and has lower pause times.
          </p><br/>

          <h3>Major GC (Full GC)</h3>
          <p>
            Occurs when the Old Generation fills up. It is a more time-consuming process because it involves cleaning the entire heap, including the Old Generation.
            Full GC can cause significant pauses and is generally less frequent than Minor GC, but it has a larger performance impact.
          </p><br/>

          <h2>Garbage Collection Phases</h2>
          <h3>Young Generation Collection (Minor GC)</h3>
          <p>
            Initially, all objects are created in the Young Generation (in Eden space). When Eden is full, Minor GC happens. 
            The reachable objects in Eden are moved to Survivor Space (either S0 or S1). Objects that survive multiple Minor GCs are promoted to the Old Generation.
          </p><br/>

          <h3>Old Generation Collection (Major or Full GC)</h3>
          <p>
            Objects that have lived long enough (after surviving several Minor GCs) are moved to the Old Generation. 
            When the Old Generation fills up, a Full GC is triggered to reclaim memory from unreachable objects in the Old Generation.
          </p><br/>

          <h3>Permanent Generation / Metaspace Cleanup</h3>
          <p>
            In older JVMs, the Permanent Generation stores metadata for loaded classes. In modern JVMs, this is replaced by Metaspace. 
            GC also cleans up unused classes and their metadata, freeing memory.
          </p><br/>

          <h2>Garbage Collection Triggers</h2>
          <ul>
            <li><strong>Automatic GC</strong>: The JVM automatically runs GC when the heap memory is running low or when memory allocation fails.</li><br/>
            <li><strong>Manual GC</strong>: The program can request garbage collection by calling <code>System.gc()</code>. However, this is not recommended as it is not a guarantee that GC will run immediately.</li>
          </ul><br/>

          <h2>Tuning Garbage Collection</h2>
          <p>While the JVM automatically handles Garbage Collection, you can tune it to optimize your program’s performance. Common tuning options include:</p>
          <ul>
            <li><strong>Heap Size</strong>: Adjusting the size of the heap (using <code>-Xms</code> and <code>-Xmx</code> flags).</li><br/>
            <li><strong>GC Algorithms</strong>: Choosing the right garbage collector based on your application's needs (e.g., use G1 for large applications requiring low pause times).</li><br/>
            <li><strong>JVM Flags</strong>: You can specify JVM flags for GC tuning, such as <code>-XX:+UseG1GC</code> for enabling the G1 Garbage Collector.</li>
          </ul><br/>

          <h2>Example of Garbage Collection in Java</h2>
          <pre>
            {`public class Main {
public static void main(String[] args) {
        System.out.println("Starting Garbage Collection Example...");
        
        // Creating some objects
        for (int i = 0; i < 100000; i++) {
            MyObject obj = new MyObject(i); // Create an object
            // The object 'obj' is scoped to the loop, and will go out of scope after each iteration
        }

        // Requesting Garbage Collection (not recommended for production)
        System.gc(); // Suggests garbage collection to the JVM

        System.out.println("Garbage Collection Requested.");

        // Give some time for GC to happen (not a guarantee that it will occur immediately)
        try {
            Thread.sleep(1000); // Sleep for 1 second to allow GC to run
        } catch (InterruptedException e) {
            e.printStackTrace();
        }

        System.out.println("End of Garbage Collection Example.");
    }
}

class MyObject {
    private int value;

    public MyObject(int value) {
        this.value = value;
    }

    @Override
    protected void finalize() throws Throwable {
        // This method is called before the object is garbage collected
        System.out.println("Object with value " + value + " is being garbage collected.");
        super.finalize(); // Call the superclass's finalize method
    }
}`}
          </pre><br/>

          <h2>Important Points to Remember</h2>
          <ul>
            <li><strong>Garbage Collection is Automatic</strong>: Java’s memory management takes care of freeing memory without requiring manual intervention.</li><br/>
            <li><strong>GC is not instantaneous</strong>: The timing of garbage collection is unpredictable and depends on the JVM and available resources.</li><br/>
            <li><strong>Avoid Memory Leaks</strong>: Even though Java handles memory management, you should still avoid memory leaks by ensuring that objects are no longer referenced once they are no longer needed.</li><br/>
            <li><strong>Finalization</strong>: The <code>finalize()</code> method is rarely used in modern Java. It's not a reliable way to release resources, and it’s better to use try-with-resources or explicitly close resources like streams.</li>
          </ul><br/>

          <h2>Conclusion</h2>
          <p>
            Garbage Collection in Java is an essential part of memory management, which helps automate the process of reclaiming memory by removing objects that are no longer in use. 
            It reduces the risk of memory leaks and improves the overall efficiency of Java programs. Understanding the basics of GC, its types, and how it works helps in writing more efficient and performance-optimized Java applications.
          </p>
        </div>
      )}
  


  {selectedChapter === 'chapter127' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Runtime Class: A Detailed Explanation</h1>

    <p>
      In Java, the <strong>Runtime</strong> class is a part of the <code>java.lang</code> package. It provides an interface to the environment in which the Java application is running. The <strong>Runtime</strong> class is designed to allow the Java program to interact with the Java Virtual Machine (JVM) during runtime. Specifically, it allows you to perform tasks such as managing system resources, running system commands, and accessing the available system environment variables.
    </p><br/>

    <h2>Key Features of the Runtime Class</h2>
    <ol>
      <li><strong>Singleton Nature:</strong>
        <p>The <strong>Runtime</strong> class follows the <em>singleton pattern</em>, meaning that there is only one instance of the <strong>Runtime</strong> class per Java application. This instance can be accessed using the <code>getRuntime()</code> method.</p>
      </li><br/>
      <li><strong>Accessing the JVM and System Resources:</strong>
        <p>The <strong>Runtime</strong> class allows you to interact with the JVM and system resources such as memory, processors, and the operating system.</p>
      </li>
    </ol><br/>

    <h2>Important Methods of the Runtime Class</h2>
    <p>Here are some important methods provided by the <strong>Runtime</strong> class:</p><br/>
    <ul>
      <li><strong>getRuntime()</strong>
        <p>This method is used to get the runtime object associated with the current Java application.</p>
        <code>{`Runtime runtime = Runtime.getRuntime();`}</code>
      </li><br/>
      <li><strong>exec(String command)</strong>
        <p>This method is used to execute system-level commands or external programs. It runs the specified command in a separate process.</p>
        <code>{`Process process = Runtime.getRuntime().exec("command");`}</code>
      </li><br/>
      <li><strong>freeMemory()</strong>
        <p>This method returns the amount of free memory in the JVM. It can be useful for monitoring the memory usage of a Java application.</p>
        <code>long freeMemory = Runtime.getRuntime().freeMemory();</code>
      </li><br/>
      <li><strong>totalMemory()</strong>
        <p>This method returns the total amount of memory that the JVM is using. It includes both free and used memory.</p>
        <code>{`long totalMemory = Runtime.getRuntime().totalMemory();`}</code>
      </li><br/>
      <li><strong>maxMemory()</strong>
        <p>This method returns the maximum amount of memory that the JVM can use. This value is set when starting the JVM, and it can be controlled via the <code>-Xmx</code> flag.</p>
        <code>{`long maxMemory = Runtime.getRuntime().maxMemory();`}</code>
      </li><br/>
      <li><strong>gc()</strong>
        <p>This method is used to suggest to the JVM to perform garbage collection. It doesn't guarantee immediate garbage collection but suggests that the JVM should reclaim memory.</p>
        <code>{`Runtime.getRuntime().gc();`}</code>
      </li><br/>
      <li><strong>addShutdownHook(Thread hook)</strong>
        <p>This method registers a shutdown hook, which is a thread that will be executed when the JVM shuts down. This is useful for cleaning up resources or performing any cleanup tasks when the program terminates.</p>
        <code>{`Runtime.getRuntime().addShutdownHook(new Thread() { public void run() { /* cleanup code */ }});`}</code>
      </li><br/>
      <li><strong>removeShutdownHook(Thread hook)</strong>
        <p>This method removes a previously registered shutdown hook. If you no longer want the JVM to execute the shutdown hook when it shuts down, you can use this method.</p>
        <code>{`Runtime.getRuntime().removeShutdownHook(hook);`}</code>
      </li>
    </ul><br/>

    <h2>Example of Using Java Runtime Class</h2>
    <p>Let’s put the above methods together in an example:</p>

    <pre>
      <code>{`public class Main {
public static void main(String[] args) {
        // Get the runtime instance
        Runtime runtime = Runtime.getRuntime();
        
        // Print total memory and free memory
        System.out.println("Total Memory: " + runtime.totalMemory());
        System.out.println("Free Memory: " + runtime.freeMemory());
        
        // Try to execute a system command (for example, open Notepad in Windows)
        try {
            runtime.exec("notepad");
        } catch (Exception e) {
            e.printStackTrace();
        }
        
        // Suggest garbage collection (not guaranteed to run immediately)
        runtime.gc();
        System.out.println("Garbage Collection Suggested.");
        
        // Print available memory after garbage collection
        System.out.println("Free Memory After GC: " + runtime.freeMemory());
        
        // Register a shutdown hook
        runtime.addShutdownHook(new Thread() {
            public void run() {
                System.out.println("Shutdown Hook: Cleaning up resources...");
            }
        });
        
        // Print message that the application is running
        System.out.println("Application Running...");
    }
}`} </code>
    </pre><br/>

    <h2>Key Points to Remember</h2>
    <ul>
      <li><strong>Singleton Design:</strong> You can only have one instance of the <strong>Runtime</strong> class, which is accessed via <code>Runtime.getRuntime()</code>.</li><br/>
      <li><strong>System Interaction:</strong> The <strong>exec()</strong> method allows your program to run system-level commands or external programs.</li><br/>
      <li><strong>Memory Management:</strong> Methods like <code>freeMemory()</code>, <code>totalMemory()</code>, and <code>maxMemory()</code> allow you to track and manage memory usage in the JVM.</li><br/>
      <li><strong>Shutdown Hooks:</strong> Use <code>addShutdownHook()</code> to register cleanup operations that run when the JVM shuts down (e.g., closing files, releasing resources).</li><br/>
      <li><strong>Garbage Collection:</strong> The <code>gc()</code> method can be called to suggest that the JVM run garbage collection, but it’s not guaranteed.</li>
    </ul><br/>

    <h2>Conclusion</h2>
    <p>The <strong>Runtime</strong> class in Java provides an essential interface for interacting with the JVM and system resources. By using methods like <code>exec()</code>, <code>freeMemory()</code>, and <code>addShutdownHook()</code>, you can manage memory, execute system commands, and perform cleanup operations in a controlled manner during the runtime of your Java application. Understanding how to use <strong>Runtime</strong> can be valuable when dealing with system-level operations and optimizing the memory usage of your Java programs.</p>
  </div>
)}




{selectedChapter === "chapter128" && (
   <div className={style.chaptercontent}>
       <h1 className={style.heading}>  Non-Return Type  </h1>

          <section>
            <p>
              In Java, <strong>non-return type methods</strong> are methods that{" "}
              <strong>do not return any value</strong> to the caller. They perform an action or
              operation, such as printing a message, modifying an object, or performing
              calculations, without providing a result. The keyword <code>void</code> is used as
              the return type to indicate that the method does not return any value.
            </p>
          </section>

          <br/>

          <section>
            <h2>Characteristics of Non-Return Type Methods</h2>
            <ol>
              <li>
                <strong>Return Type:</strong> Always declared with the keyword <code>void</code>.
              </li><br/>
              <li>
                <strong>Purpose:</strong> Typically used for performing operations or displaying
                output.
              </li><br/>
              <li>
                <strong>Return Statement:</strong> These methods may contain a{" "}
                <code>return</code> statement, but only to exit the method early, not to return a
                value.
              </li><br/>
              <li>
                <strong>Parameters:</strong> These methods can accept parameters (input values) or
                may not take any parameters.
              </li>
            </ol>
          </section>

          <br/>

          <section>
            <h2>Categories of Non-Return Type Methods</h2>
            <h3>1. Non-Return Type, No Parameters</h3>
            <p>
              These methods do not accept any input and do not return any value. They are used to
              perform simple actions or display messages.
            </p><br/>

            <pre>
              <code>
                {`class Example {
    /**
     * A method with no parameters and no return value.
     * This method simply prints a greeting message to the console.
     */
    void greet() {
        System.out.println("Hello, World!"); // Print a greeting message
    }

    /**
     * The main method is the entry point of the program.
     * It creates an instance of the Example class and calls the greet method.
     *
     * @param args Command-line arguments (not used here).
     */
    public static void main(String[] args) {
        Example example = new Example(); // Create an instance of the Example class
        example.greet(); // Call the greet method
    }
}`}
              </code>
            </pre>
            <p><strong>Output:</strong></p>
            <pre className={style.outputBlock}>
              <code>Hello, World!</code>
            </pre><br/>

            <h3>2. Non-Return Type, With Parameters</h3>
            <p>
              These methods accept parameters but do not return any value. They are used to perform
              operations based on input values.
            </p>

            <pre>
              <code>
                {`class Example {
    // A method with parameters but no return value
    void displayMessage(String message) {
        System.out.println("Message: " + message);  // Print the message passed as an argument
    }

    public static void main(String[] args) {
        Example example = new Example();  // Create an instance of the Example class
        example.displayMessage("Java is powerful!");  // Call the method with a parameter
    }
}`}
              </code>
            </pre>
            <p><strong>Output:</strong></p>
            <pre className={style.outputBlock}>
              <code>Message: Java is powerful!</code>
            </pre>
          </section>

          <br/>

          <section>
            <h2>Key Points About Non-Return Type Methods</h2>
            <ul>
              <li>
                <strong>No Returned Data:</strong> The caller of the method cannot capture any value
                because nothing is returned.
              </li><br/>
              <li>
                <strong>Usage of <code>return</code> Statement:</strong> Although they don't return
                values, a <code>return</code> statement can still be used to exit the method early.
              </li><br/>
              <li>
                <strong>Execution Flow:</strong> Once the method completes its task, control returns
                to the calling method or main method.
              </li>
            </ul>
          </section>

          <br/>

          <section>
            <h2>Advantages of Non-Return Type Methods</h2>
            <ul>
              <li>
                <strong>Simplifies Code:</strong> Reduces complexity when no result needs to be
                returned.
              </li><br/>
              <li>
                <strong>Performs Actions:</strong> Ideal for tasks like logging, modifying object
                states, or initiating processes.
              </li><br/>
              <li>
                <strong>Flexible:</strong> Can accept input parameters to perform operations.
              </li>
            </ul>
          </section>

          <br/>

          <section>
            <h2>When to Use Non-Return Type Methods</h2>
            <ul>
              <li>
                When you need to <strong>perform an operation</strong> without expecting a result
                (e.g., displaying information).
              </li><br/>
              <li>
                When the method is intended to cause a <strong>side effect</strong> (e.g., updating
                a database or modifying a file).
              </li><br/>
              <li>
                When the task is <strong>independent of any return value</strong> and is
                self-contained.
              </li>
            </ul>
          </section>

          <br/>

          <section>
            <h2>Summary</h2>
            <p>
              Non-return type methods in Java (<code>void</code> methods) are essential for
              operations where returning a value is unnecessary. They focus on performing tasks or
              creating side effects, making them invaluable for building modular and reusable code.
              Whether they accept parameters or not, they provide great flexibility for developers
              to encapsulate logic effectively.
            </p>
          </section>
        </div>
      )}
    


{selectedChapter === 'chapter129' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Non-Return Type with Parameters </h1>

       

        <p>
          In Java, <strong>non-return type with parameters</strong> methods are a category of methods that{" "}
          <strong>do not return any value</strong> but can <strong>accept input parameters</strong> to perform operations. These
          methods are useful when the method needs input to carry out its functionality, but the result of the operation does
          not need to be returned to the caller.
        </p>
        <p>
          The keyword <code>void</code> is used to declare that the method does not return any value.
        </p>

        <br/>

        <h3>Characteristics of Non-Return Type with Parameters</h3>
        <ul>
          <li>
            <strong>Return Type</strong>: Declared with the keyword <code>void</code> to indicate no value is returned.
          </li><br/>
          <li>
            <strong>Parameters</strong>: Accepts one or more parameters as input for its operations.
          </li><br/>
          <li>
            <strong>Action-Oriented</strong>: Used for performing tasks such as updating variables, printing information, or
            modifying objects based on the input.
          </li><br/>
          <li>
            <strong>No Return Value</strong>: The method cannot return any value to the calling function but may produce side
            effects like printing or modifying states.
          </li>
        </ul>

        <br/>

        <h3>Syntax of Non-Return Type with Parameters</h3>
        <pre>
          <code>
            {`void methodName(parameterType parameterName) {
    // method body
}`}
          </code>
        </pre>

        <br/>

        <h3>Example of Non-Return Type with Parameters</h3>

        <h4>Example 1: Printing a Message with a Parameter</h4>
        <pre className={style.codeblock}>
          <code>
            {`class Example {
    // Method with one parameter
    void displayMessage(String message) {
        System.out.println("Message: " + message);  // Print the message passed as an argument
    }

    public static void main(String[] args) {
        Example example = new Example();  // Create an instance of the Example class
        example.displayMessage("Java is versatile!");  // Call the method with a string argument
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.codeblock}>
          <code>
            {`Message: Java is versatile!`}
          </code>
        </pre><br/>

        <h4>Example 2: Adding Two Numbers</h4>
        <pre className={style.codeblock}>
          <code>
            {`class Calculator {
    // Method with two parameters
    void addNumbers(int num1, int num2) {
        // Calculate the sum of num1 and num2
        int sum = num1 + num2;
        // Print the sum
        System.out.println("Sum: " + sum);
    }

    public static void main(String[] args) {
        // Create an instance of the Calculator class
        Calculator calculator = new Calculator();
        // Call the addNumbers method with two integers as arguments
        calculator.addNumbers(10, 20);  // Passing two integers (10 and 20) as arguments
    }
}`}
          </code>
        </pre>
        <p>
          <strong>Output:</strong>
        </p>
        <pre className={style.codeblock}>
          <code>
            {`Sum: 30`}
          </code>
        </pre>

        <br/>

        <h3>Key Points to Remember</h3>
        <ul>
          <li>
            <strong>Parameter List</strong>: A method can have zero, one, or multiple parameters. The parameters are specified
            within parentheses in the method declaration.
          </li><br/>
          <li>
            <strong>Arguments</strong>: When calling the method, the actual values passed are called arguments. The arguments'
            types must match the parameters' types in both number and order.
          </li><br/>
          <li>
            <strong>No Return Value</strong>: These methods are called for their effect (e.g., printing or modifying state), not
            for their result.
          </li><br/>
          <li>
            <strong>Can Use `return` to Exit Early</strong>: Though they don't return values, a <code>return</code> statement can
            be used to exit the method early if certain conditions are met.
          </li>
        </ul>

        <br/>

        <h3>Advantages of Non-Return Type with Parameters</h3>
        <ul>
          <li>
            <strong>Flexibility</strong>: Allows dynamic behavior by accepting different input values at runtime.
          </li><br/>
          <li>
            <strong>Modularity</strong>: Encapsulates operations that depend on inputs, making the code more organized and
            reusable.
          </li><br/>
          <li>
            <strong>Improved Functionality</strong>: Facilitates interaction between methods by passing arguments, enhancing
            functionality.
          </li>
        </ul>

        <br/>

        <h3>When to Use Non-Return Type with Parameters</h3>
        <ul>
          <li>When the method needs input to complete its task but does not need to return a result.</li><br/>
          <li>
            Common use cases include:
            <ul>
              <li>Printing or logging messages based on inputs.</li><br/>
              <li>Performing calculations and displaying results.</li><br/>
              <li>Modifying object states based on arguments.</li>
            </ul>
          </li>
        </ul>

        <br/>

        <h3>Comparison with Other Types of Methods</h3>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Returns Value?</th>
              <th>Accepts Parameters?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Non-Return, No Parameters</td>
              <td>No</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Non-Return, With Parameters</td>
              <td>No</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Return Type, No Parameters</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Return Type, With Parameters</td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
          </tbody>
        </table>

        <br/>

        <h3>Summary</h3>
        <p>
          Non-return type methods with parameters (<code>void</code> methods with arguments) are widely used in Java for tasks
          that depend on input values but do not require a result to be returned. They offer a simple and effective way to
          perform operations like logging, displaying, or interacting with objects in a modular manner.
        </p>
      </div>
    )}



{selectedChapter === 'chapter130' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Return Type </h1>

          <p>
            In Java, methods with a <strong>return type</strong> are those that perform a task and then <strong>return a value</strong> to the caller. These methods are useful when the result of the operation needs to be reused or processed further. The return type is specified in the method declaration and determines the type of data the method will return.
          </p><br/>

          <h2>Characteristics of Return Type Methods</h2>
          <ul>
            <li><strong>Return Type:</strong> The data type of the returned value is explicitly mentioned in the method signature.</li><br/>
            <li><strong>Return Statement:</strong> The method must include a <code>return</code> statement that returns a value of the declared type.</li><br/>
            <li><strong>Purpose:</strong> Typically used for computations, data retrieval, or any operation that generates a result.</li><br/>
            <li><strong>Parameters:</strong> These methods may accept input parameters or may not, depending on their functionality.</li>
          </ul><br/>

          <h2>Syntax of Return Type Methods</h2>
          <pre>
            <code>
{`returnType methodName(parameterType parameterName) {
    // Method body
    return value;  // Value must match the return type
}`}
            </code>
          </pre><br/>

          <h2>Examples of Return Type Methods</h2>
          <h3>Example 1: Method Returning an Integer</h3>
          <pre>
            <code>
{`class Calculator {
    // Method with two parameters and a return type of int
    int addNumbers(int num1, int num2) {
        // Calculate the sum of num1 and num2
        int sum = num1 + num2;
        // Return the sum
        return sum;
    }

    public static void main(String[] args) {
        // Create an instance of the Calculator class
        Calculator calculator = new Calculator();
        
        // Call the addNumbers method, passing two integers as arguments, and capture the returned value
        int result = calculator.addNumbers(10, 20); 
        
        // Print the result of the addition
        System.out.println("Sum: " + result);  // Output: Sum: 30
    }
}`}
            </code>
          </pre>
          <pre>
          <strong>Output:</strong> Sum: 30</pre><br/>

          <h3>Example 2: Method Returning a String</h3>
          <pre>
            <code>
{`class Example {
    // Method that returns a greeting message based on the name parameter
    String getGreeting(String name) {
        return "Hello, " + name + "!";  // Return the greeting message
    }

    public static void main(String[] args) {
        // Create an instance of the Example class
        Example example = new Example();
        
        // Call the getGreeting method with "John" and capture the returned greeting
        String greeting = example.getGreeting("John"); 
        
        // Print the greeting message
        System.out.println(greeting);  // Output: Hello, John!
    }
}`}
            </code>
          </pre>
          <pre><strong>Output:</strong> Hello, John!</pre><br/>

          <h2>Key Points to Remember</h2>
          <ul>
            <li><strong>Return Type Declaration:</strong> The method signature must declare the return type (e.g., <code>int</code>, <code>String</code>, <code>boolean</code>, or even custom objects).</li><br/>
            <li><strong>Matching Return Type:</strong> The value returned by the method must match the declared return type.</li><br/>
            <li><strong>Return Statement:</strong> A <code>return</code> statement is mandatory for methods with a return type.</li><br/>
            <li><strong>Void vs. Return Type:</strong> Methods with <code>void</code> do not return a value, while return type methods must provide a value.</li><br/>
            <li><strong>Multiple Return Statements:</strong> A method can have multiple <code>return</code> statements for conditional logic.</li>
          </ul><br/>

          <h2>Advantages of Return Type Methods</h2>
          <ul>
            <li><strong>Reusability:</strong> The returned value can be reused or assigned to variables for further processing.</li><br/>
            <li><strong>Encapsulation:</strong> Encapsulates logic to compute or retrieve data and provides the result to the caller.</li><br/>
            <li><strong>Flexibility:</strong> Methods can be designed to return different types of values based on input or logic.</li>
          </ul><br/>

          <h2>Example: Returning a Custom Object</h2>
          <pre>
            <code>
{`class Employee {
    String name;
    int id;

    // Constructor to initialize the Employee object
    Employee(String name, int id) {
        this.name = name;
        this.id = id;
    }
}

class Example {
    // Method that returns an Employee object
    Employee getEmployeeDetails() {
        return new Employee("John", 101);  // Return a new Employee object
    }

    public static void main(String[] args) {
        // Create an instance of the Example class
        Example example = new Example();
        
        // Call the getEmployeeDetails method and capture the returned Employee object
        Employee emp = example.getEmployeeDetails();
        
        // Print the details of the Employee object
        System.out.println("Employee Name: " + emp.name);
        System.out.println("Employee ID: " + emp.id);
    }
}`}
            </code>
          </pre>
          <pre><code>{`Output:
Employee Name: John
Employee ID: 101`}</code></pre><br/>

          <h2>Comparison with Non-Return Type</h2>
          <table className={style.table}>
            <thead>
              <tr>
                <th>Aspect</th>
                <th>Return Type</th>
                <th>Non-Return Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Return Value</td>
                <td>Must return a value.</td>
                <td>Does not return a value.</td>
              </tr>
              <tr>
                <td>Purpose</td>
                <td>Provides a result.</td>
                <td>Performs an action.</td>
              </tr>
              <tr>
                <td>Keyword</td>
                <td>Data type (e.g., int).</td>
                <td><code>void</code>.</td>
              </tr>
              <tr>
                <td>Usage</td>
                <td>When a result is needed.</td>
                <td>When only action is needed.</td>
              </tr>
            </tbody>
          </table><br/>

          <h2>Summary</h2>
          <p>
            Methods with a return type in Java are fundamental for creating reusable, modular, and logical components in a program. They allow a method to compute or retrieve a result and provide it to the caller for further use. Whether returning simple data types like <code>int</code> or <code>String</code>, or complex objects like <code>Employee</code>, these methods form the backbone of Java's object-oriented programming approach.
          </p>
        </div>
      )}
   


{selectedChapter === 'chapter131' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Return Type with Parameters </h1>

       
        <p>
          In Java, methods that have both a <strong>return type</strong> and <strong>parameters</strong> are the most common in practice. These methods allow you to pass input (parameters) to the method and get a result (return type) after performing some operations. Such methods are essential when the method needs to process input values and return a result, which can be further used in the program.
        </p><br/>

        <h4>Key Characteristics of Return Type with Parameters Methods:</h4>
        <ul>
          <li><strong>Return Type</strong>: The method is declared with a specific return type (e.g., <code>int</code>, <code>String</code>, <code>boolean</code>, custom objects, etc.). The return type defines the type of value the method will return to the caller.</li><br/>
          <li><strong>Parameters</strong>: These methods can accept one or more parameters that are passed when calling the method. The parameters allow the method to work with input data to perform a task.</li><br/>
          <li><strong>Return Statement</strong>: A method with a return type must include a <code>return</code> statement that provides the appropriate value of the declared return type.</li><br/>
          <li><strong>Purpose</strong>: These methods are useful when a computation or task is performed based on the parameters, and a result is expected to be returned for further use.</li>
        </ul><br/>

        <h4>Syntax of Return Type with Parameters Method:</h4>
        <pre>
          <code>{`returnType methodName(parameterType parameter1, parameterType parameter2, ...) {
// Method body (processing logic)
return returnValue;  // Return the result of the processing
}`}</code>
        </pre><br/>

        <h4>Examples of Return Type with Parameters Methods</h4>

        <h5>Example 1: Method Returning a Sum of Two Integers</h5>
        <pre>
          <code>{`class Calculator {
// Method with two integer parameters and an int return type
    int addNumbers(int num1, int num2) {
        int sum = num1 + num2;  // Perform addition
        return sum;  // Return the sum to the caller
    }

    public static void main(String[] args) {
        Calculator calculator = new Calculator();
        int result = calculator.addNumbers(10, 20);  // Call the method and get the result
        System.out.println("Sum: " + result);  // Output the result
    }
}`}

          </code>
        </pre>

        <p><strong>Explanation:</strong></p>
        <ul>
          <li>The method <code>addNumbers</code> takes two integer parameters <code>num1</code> and <code>num2</code>.</li><br/>
          <li>It returns their sum as an integer.</li><br/>
          <li>The return type is <code>int</code>, and the method is invoked with two integer values (<code>10</code> and <code>20</code>), resulting in the sum being printed as <code>30</code>.</li>
        </ul><br/>

        <h5>Example 2: Method Returning a Greeting Message</h5>
        <pre>
          <code>{`class Greeting {
    // Method with one String parameter and String return type
    String getGreeting(String name) {
        return "Hello, " + name + "!";  // Return the greeting message
    }

    public static void main(String[] args) {
        Greeting greeting = new Greeting();
        String message = greeting.getGreeting("Alice");  // Pass a name as the argument
        System.out.println(message);  // Output the greeting message
    }
}`}

          </code>
        </pre>

        <p><strong>Explanation:</strong></p>
        <ul>
          <li>The method <code>getGreeting</code> takes a <code>String</code> parameter <code>name</code> and returns a <code>String</code> that is a greeting message.</li><br/>
          <li>When calling <code>getGreeting("Alice")</code>, it returns "Hello, Alice!" and prints it.</li>
        </ul><br/>

        <h5>Example 3: Method Returning a Custom Object (e.g., <code>Employee</code>)</h5>
        <pre>
          <code>{`class Employee {
    String name;
    int id;

    // Constructor to initialize the Employee object
    Employee(String name, int id) {
        this.name = name;
        this.id = id;
    }
}

class EmployeeDetails {
    // Method that returns an Employee object
    Employee getEmployeeDetails(String name, int id) {
        return new Employee(name, id);  // Return a new Employee object
    }

    public static void main(String[] args) {
        EmployeeDetails details = new EmployeeDetails();
        Employee emp = details.getEmployeeDetails("John", 101);  // Call the method with arguments
        System.out.println("Employee Name: " + emp.name);  // Output employee details
        System.out.println("Employee ID: " + emp.id);
    }
}`}

          </code>
        </pre>

        <p><strong>Explanation:</strong></p>
        <ul>
          <li>The <code>getEmployeeDetails</code> method returns an <code>Employee</code> object, created using the provided <code>name</code> and <code>id</code> parameters.</li><br/>
          <li>This method is used to return an object that contains data (name and ID of an employee).</li>
        </ul><br/>

        <h4>Key Points to Remember:</h4>
        <ul>
          <li><strong>Return Type Declaration</strong>: The return type in the method signature must match the type of the value returned. For example, if the return type is <code>int</code>, the method must return an integer value.</li><br/>
          <li><strong>Return Statement</strong>: A method with a return type must include a <code>return</code> statement that returns a value of the declared type.</li><br/>
          <li><strong>Parameter List</strong>: A method can accept any number of parameters (including zero). The parameter types must match the argument types provided when the method is called.</li><br/>
          <li><strong>Type Safety</strong>: Java is a strongly typed language, so the types of parameters and the return value must align. If there is a mismatch, a compilation error will occur.</li><br/>
          <li><strong>Method Invocation</strong>: When you call a method that has parameters, you pass values (arguments) that match the method’s declared parameter types.</li>
        </ul><br/>

        <h4>Advantages of Return Type with Parameters Methods:</h4>
        <ul>
          <li><strong>Modularity</strong>: These methods can be reused in different parts of the codebase with different arguments, promoting code reuse.</li><br/>
          <li><strong>Flexibility</strong>: They allow passing dynamic data, enabling flexible and adaptable functionality.</li><br/>
          <li><strong>Clarity</strong>: The method's return type clearly defines what type of result it will produce, making the code easier to understand and maintain.</li>
        </ul><br/>

        <h4>Use Cases:</h4>
        <ul>
          <li><strong>Mathematical Operations</strong>: Methods like <code>addNumbers</code>, <code>multiplyNumbers</code> that take parameters and return results.</li><br/>
          <li><strong>Data Retrieval</strong>: Methods that query and return data, such as fetching information from a database or API.</li><br/>
          <li><strong>Conditional Computations</strong>: Methods that return values based on the parameters, like checking if a number is prime or finding the maximum of two numbers.</li>
        </ul><br/>

        <h4>Comparison with Other Types of Methods:</h4>
        <table>
          <thead>
            <tr>
              <th>Aspect</th>
              <th>Return Type with Parameters</th>
              <th>Non-Return Type with Parameters</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Return Value</td>
              <td>Returns a value of a specified type</td>
              <td>Does not return a value (void)</td>
            </tr>
            <tr>
              <td>Parameters</td>
              <td>Can have one or more parameters</td>
              <td>Can have one or more parameters</td>
            </tr>
            <tr>
              <td>Usage</td>
              <td>When a result needs to be computed or fetched</td>
              <td>When an action needs to be performed (e.g., print, modify objects)</td>
            </tr>
            <tr>
              <td>Method Signature</td>
              <td>Includes a return type (e.g., <code>int</code>, <code>String</code>)</td>
              <td>Includes <code>void</code> as the return type</td>
            </tr>
          </tbody>
        </table><br/>

        <h4>Summary:</h4>
        <p>
          Methods with a <strong>return type and parameters</strong> are essential for creating flexible, reusable, and modular code. These methods allow for dynamic behavior based on the input parameters and provide meaningful results to the caller, making them a fundamental part of Java programming.
        </p>
      </div>
    )}
  
 

                </div>
            </div>
        </div>

    );
}


export default Javacourse;