import React, { useRef, useEffect, useState } from "react";
import style from "./../Css/Editpackages.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import { Link } from "react-router-dom";
import API_BASE_URL from "../apiConfig";

const Editpackages = () => {
  const [packages, setPackages] = useState([]); // State to hold packages data
  const [editPackage, setEditPackage] = useState(null); // State to manage edit mode
  const [message, setMessage] = useState(""); // State for popup message
  const [modalVisible, setModalVisible] = useState(false);
  const [files, setFiles] = useState({ logo: null, previewPDF: null }); // To hold uploaded files

  useEffect(() => {
    // Fetch data from backend
    const fetchPackages = async () => {
      try {
        const response = await fetch
           (`${API_BASE_URL}/get_package.php`
        );
        const data = await response.json();
        setPackages(data);
      } catch (error) {
        console.error("Failed to fetch Packages:", error);
      }
    };

    fetchPackages();
  }, []);

  const [colClass, setColClass] = useState("col-md-3");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1333.5) {
        setColClass("col-md-8");
      }
      if (window.innerWidth <= 1018.5) {
        setColClass("col-md-8");
      } else {
        setColClass("col-md-6");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDelete = async (packageId) => {
    const showCustomAlert = (message, onConfirm, onCancel) => {
      const alertBox = document.createElement('div');
      alertBox.style.position = 'fixed';
      alertBox.style.top = '20%';
      alertBox.style.left = '50%';
      alertBox.style.transform = 'translate(-50%, -50%)';
      alertBox.style.padding = '20px';
      alertBox.style.backgroundColor = '#ACAF50';
      alertBox.style.color = 'white';
      alertBox.style.fontSize = '16px';
      alertBox.style.borderRadius = '5px';
      alertBox.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
      alertBox.style.zIndex = '9999';
      alertBox.style.textAlign = 'center';
  
      // Add the message
      const messageElement = document.createElement('p');
      messageElement.innerText = message;
      alertBox.appendChild(messageElement);
  
      // Add the Yes button
      const yesButton = document.createElement('button');
      yesButton.innerText = 'Yes';
      yesButton.style.margin = '10px';
      yesButton.style.padding = '5px 15px';
      yesButton.style.backgroundColor = '#5cb85c';
      yesButton.style.color = 'white';
      yesButton.style.border = 'none';
      yesButton.style.borderRadius = '3px';
      yesButton.style.cursor = 'pointer';
  
      yesButton.addEventListener('click', () => {
        alertBox.remove();
        onConfirm();
      });
  
      // Add the Cancel button
      const cancelButton = document.createElement('button');
      cancelButton.innerText = 'Cancel';
      cancelButton.style.margin = '10px';
      cancelButton.style.padding = '5px 15px';
      cancelButton.style.backgroundColor = '#d9534f';
      cancelButton.style.color = 'white';
      cancelButton.style.border = 'none';
      cancelButton.style.borderRadius = '3px';
      cancelButton.style.cursor = 'pointer';
  
      cancelButton.addEventListener('click', () => {
        alertBox.remove();
        if (onCancel) onCancel();
      });
  
      alertBox.appendChild(yesButton);
      alertBox.appendChild(cancelButton);
  
      document.body.appendChild(alertBox);
    };
  
    showCustomAlert(
      'Are you sure you want to delete this PACKAGE?',
      async () => {

    try {
      const response = await fetch
         (`${API_BASE_URL}/delete_package.php`,
        {
          method: "POST",
          body: JSON.stringify({ id: packageId }),
          headers: { "Content-Type": "application/json" },
        }
      );

      const result = await response.json();

      if (result.status === "success") {
        setPackages(packages.filter((pkg) => pkg.id !== packageId));
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error deleting package:", error);
    }
  }
);
  };

  const handleEdit = (pkg) => {
    setEditPackage(pkg);
    setFiles({ previewPDF: null }); // Reset file inputs
    setModalVisible(true); // Show modal
  };

  const closeModal = () => {
    setModalVisible(false);
    setEditPackage(null); // Clear the edit state
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    setFiles((prev) => ({ ...prev, [fileType]: file }));
  };

  const handleUpdate = async () => {
    if (editPackage) {
      const formData = new FormData();
      formData.append("id", editPackage.id);
      formData.append("packageName", editPackage.packageName);
      formData.append("packagePrice", editPackage.packagePrice);
      formData.append("packageBenefit1", editPackage.packageBenefit1);
      formData.append("packageBenefit2", editPackage.packageBenefit2);
      formData.append("packageBenefit3", editPackage.packageBenefit3);
  
      if (files.previewPDF) {
        formData.append("viewpdf", files.previewPDF);
      }
  
      try {
        const response = await fetch(`${API_BASE_URL}/edit_package.php`, {
          method: "POST",
          body: formData,
        });
  
        const result = await response.json();
  
        if (result.status === "success") {
          setPackages(
            packages.map((pkg) =>
              pkg.id === editPackage.id ? { ...editPackage } : pkg
            )
          );
          setEditPackage(null);
          setMessage("Changes saved successfully!");
          setTimeout(() => setMessage(""), 1500);
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error("Error updating package:", error);
      }
    }
  };


    const handleHideToggle = async (pkg) => {
    const newStatus = Number(pkg.hidden) === 1 ? 0 : 1; // Toggle status

    try {
      const response = await fetch
         (`${API_BASE_URL}/toggle_hide_package.php`, // Update with the correct API endpoint
        {
          method: "POST",
          body: JSON.stringify({ id: pkg.id, hidden: newStatus }),
          headers: { "Content-Type": "application/json" },
        }
      );

      const result = await response.json();
      console.log("Response from toggle_hide_package.php:", result); // Add this log

      if (result.status === "success") {
        setPackages(
          packages.map((p) =>
            p.id === pkg.id ? { ...p, hidden: newStatus.toString() } : p
          )
        );
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error toggling package visibility:", error);
    }
  };
  
  

  return (
    <>
      <div className={`${style.packagescontainer} container-fluid`}>
        <div className="container-fluid">
          <div className="row text-center">
            <h1 className={style.packageheading}>
              Your Path to Success Starts Here
            </h1>
            <p className={style.packagedescription}>
              Choose the package that suits your learning needs and goals.
            </p>
          </div>

          <div className={`${style.packagesrow} row`}>
            {packages.map((pkg) => (
              <div key={pkg.id} className={`${style.packagescol} ${colClass}`}>
                <div className={style.packagebox}>
                  <div className={style.plans}>
                    <h5 className={style.heading}>{pkg.packageName}</h5>
                    <h1 className={style.packageprice}>
                      <i className="fa-solid fa-indian-rupee-sign"></i>
                      {pkg.packagePrice}
                    </h1>
                  </div>
                  <p className={style.description}>{pkg.packageDetails}</p>
                  <ul className={style.featureList}>
                    <li>{pkg.packageBenefit1}</li>
                    <li>{pkg.packageBenefit2}</li>
                    <li>{pkg.packageBenefit3}</li>
                  </ul>
                  <a
                        className={style.learnmorebuy}
                         // Dynamically link to the PDF
                         href={`${API_BASE_URL}/packagepdf/${pkg.viewpdf}`}
                        target="_blank" // Opens the PDF in a new tab
                        rel="noopener noreferrer" // Security measure for opening links in a new tab
                      >
                        Learn more
                      </a>
                  <button className={style.packagebutton}>Coming Soon</button>
                  <div className={style.editbtns}>
                 
                  <Link className={style.editbtnone} onClick={() => handleEdit(pkg)}>Edit</Link>
                    <Link className={style.editbtntwo} onClick={() => handleDelete(pkg.id)}>
                      Delete
                    </Link>
                    <Link className={style.editbtnthree} onClick={() => handleHideToggle(pkg)}>
                      {Number(pkg.hidden) === 1 ? "Unhide" : "Hide"}
                    </Link>
                </div>
                </div>

                
               
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Edit Form */}
      {modalVisible && editPackage && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={closeModal}>
              &times;
            </button>

            <div className={`${style.editformrow} ${style.editformheading}row`}>
              <h2>Edit Package</h2>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
                {" "}
                <label>Package Name</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editPackage.packageName}
                  onChange={(e) =>
                    setEditPackage({
                      ...editPackage,
                      packageName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6">
                {" "}
                <label>Package Price</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editPackage.packagePrice}
                  onChange={(e) =>
                    setEditPackage({
                      ...editPackage,
                      packagePrice: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>

                {" "}
                <label>Package Details</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editPackage.packageDetails}
                  onChange={(e) =>
                    setEditPackage({
                      ...editPackage,
                      packageDetails: e.target.value,
                    })
                  }
                />
              </div>
              <div className={`${style.editformrow} row`}>
                <label>Package Benefit 1</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editPackage.packageBenefit1}
                  onChange={(e) =>
                    setEditPackage({
                      ...editPackage,
                      packageBenefit1: e.target.value,
                    })
                  }
                />
              </div>
            

              <div className={`${style.editformrow} row`}>
                {" "}
                <label>Package Benefit 2</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editPackage.packageBenefit2}
                  onChange={(e) =>
                    setEditPackage({
                      ...editPackage,
                      packageBenefit2: e.target.value,
                    })
                  }
                />
              </div>
              <div className={`${style.editformrow} row`}>
                {" "}
                <label>Package Benefit 3</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editPackage.packageBenefit3}
                  onChange={(e) =>
                    setEditPackage({
                      ...editPackage,
                      packageBenefit3: e.target.value,
                    })
                  }
                />
              </div>

              <div className={`${style.editformrow} row`}>
              <div>
                    <label>Upload Preview PDF (pdf only):</label>
                    <input
                    className={styles.basicinputdesign}
                      type="file"
                      name="previewPDF"
                      onChange={(e) => handleFileChange(e, "previewPDF")}
                    />
                  </div>
              </div>    
          

              <div className={`${style.editformrow} row`}>
              <div className={` ${style.savechangesbtn} col-6`}>
                <Link className={style.editbtnfour} onClick={handleUpdate}>Save Changes</Link>
              </div>
              <div className={` ${style.cancelbtn} col-6`}>
                <Link className={style.editbtnfour} onClick={() => setEditPackage(null)}>Cancel</Link>
              </div>
            </div>
          </div>
        </div>
        
      )}
      {/* Render Popup Message */}
      {message && (
          <div className={style.popupMessage}>
            <p>{message}</p>
          </div>
        )}
    </>
  );
};

export default Editpackages;
