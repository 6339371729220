import { useRef, useState, useEffect } from "react";
import React from "react";
import style from "./../Css/Editliveproject.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import { Link } from "react-router-dom";
import API_BASE_URL from "../apiConfig";

const Editliveproject = () => {
  // State for managing which section is active
  const [activeSection, setActiveSection] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [projects, setProjects] = useState([]); // State to store fetched projects
  const [editProject, setEditProject] = useState(null); // State to manage edit mode
  const [modalVisibleef, setModalVisibleef] = useState(false); //ef => edit form
  const [message, setMessage] = useState(""); // State for popup message
  const [files, setFiles] = useState({
    image1: null,
    image2: null,
    image3: null,
    previewPDF: null,
    sourceCodePDF: null,
  }); // State to manage file uploads

  // Fetch data from backend
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch
            (`${API_BASE_URL}/fetch_live_projects.php`
        );
        const data = await response.json();
        setProjects(data); // Set the fetched data
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, []);

  // Handle editing a project
  const handleEdit = (project) => {
    setEditProject(project);
    setFiles({
      image1: null,
      image2: null,
      image3: null,
      previewPDF: null,
      sourceCodePDF: null,
    });
    setModalVisibleef(true); // Show modal
  };

  const closeModal = () => {
    setModalVisibleef(false);
    setEditProject(null); // Clear the edit state
  };

  // Handle file change (image or PDF)
  const handleFileChange = (e) => {
    const { name, files: selectedFiles } = e.target;
    setFiles({
      ...files,
      [name]: selectedFiles[0],
    });
  };

  // Handle deleting a project
  const handleDelete = async (projectId) => {
    const showCustomAlert = (message, onConfirm, onCancel) => {
      const alertBox = document.createElement('div');
      alertBox.style.position = 'fixed';
      alertBox.style.top = '20%';
      alertBox.style.left = '50%';
      alertBox.style.transform = 'translate(-50%, -50%)';
      alertBox.style.padding = '20px';
      alertBox.style.backgroundColor = '#ACAF50';
      alertBox.style.color = 'white';
      alertBox.style.fontSize = '16px';
      alertBox.style.borderRadius = '5px';
      alertBox.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
      alertBox.style.zIndex = '9999';
      alertBox.style.textAlign = 'center';
  
      // Add the message
      const messageElement = document.createElement('p');
      messageElement.innerText = message;
      alertBox.appendChild(messageElement);
  
      // Add the Yes button
      const yesButton = document.createElement('button');
      yesButton.innerText = 'Yes';
      yesButton.style.margin = '10px';
      yesButton.style.padding = '5px 15px';
      yesButton.style.backgroundColor = '#5cb85c';
      yesButton.style.color = 'white';
      yesButton.style.border = 'none';
      yesButton.style.borderRadius = '3px';
      yesButton.style.cursor = 'pointer';
  
      yesButton.addEventListener('click', () => {
        alertBox.remove();
        onConfirm();
      });
  
      // Add the Cancel button
      const cancelButton = document.createElement('button');
      cancelButton.innerText = 'Cancel';
      cancelButton.style.margin = '10px';
      cancelButton.style.padding = '5px 15px';
      cancelButton.style.backgroundColor = '#d9534f';
      cancelButton.style.color = 'white';
      cancelButton.style.border = 'none';
      cancelButton.style.borderRadius = '3px';
      cancelButton.style.cursor = 'pointer';
  
      cancelButton.addEventListener('click', () => {
        alertBox.remove();
        if (onCancel) onCancel();
      });
  
      alertBox.appendChild(yesButton);
      alertBox.appendChild(cancelButton);
  
      document.body.appendChild(alertBox);
    };
  
    showCustomAlert(
      'Are you sure you want to delete this PROJECT?',
      async () => {
    
    try {
      const response = await fetch
        (`${API_BASE_URL}/delete_liveproject.php`,
        {
          method: "POST",
          body: JSON.stringify({ id: projectId }),
          headers: { "Content-Type": "application/json" },
        }
      );

      const result = await response.json();

      if (result.status === "success") {
        setProjects(projects.filter((project) => project.id !== projectId));
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  }
);
  };

  // Handle updating project
  const handleUpdate = async () => {
    if (editProject) {
      const formData = new FormData();
      formData.append("id", editProject.id);
      formData.append("project_name", editProject.project_name);
      formData.append("project_details", editProject.project_details);
      formData.append("project_subdetails", editProject.project_subdetails);
      formData.append("project_type", editProject.project_type);
      formData.append("package_type", editProject.package_type);

      // Append the new files (if any) to formData
      if (files.image1) formData.append("project_image1", files.image1);
      if (files.image2) formData.append("project_image2", files.image2);
      if (files.image3) formData.append("project_image3", files.image3);
      if (files.previewPDF)
        formData.append("projectPreviewPDF", files.previewPDF);
      if (files.sourceCodePDF)
        formData.append("projectSourceCodePDF", files.sourceCodePDF);

      try {
        const response = await fetch
          (`${API_BASE_URL}/edit_liveproject.php`,
          {
            method: "POST",
            body: formData,
          }
        );

        const result = await response.json();

        if (result.status === "success") {
          setProjects(
            projects.map((project) =>
              project.id === editProject.id ? editProject : project
            )
          );
          setEditProject(null); // Clear edit mode
          setMessage("Changes saved successfully!"); // Set success message
          setTimeout(() => setMessage(""), 1500); // Hide message after 3 seconds
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error("Error updating project:", error);
      }
    }
  };

  const handleHideToggle = async (project) => {
    const newStatus = Number(project.hidden) === 1 ? 0 : 1; // Toggle status

    try {
      const response = await fetch
        (`${API_BASE_URL}/toggle_hide_project.php`,
        {
          method: "POST",
          body: JSON.stringify({ id: project.id, hidden: newStatus }),
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const result = await response.json();
      console.log("Backend response:", result);

      if (result.status === "success") {
        setProjects(
          projects.map((c) =>
            c.id === project.id ? { ...c, hidden: newStatus.toString() } : c
          )
        );
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error toggling course visibility:", error);
    }
  };

  // Handle section change (for navigation bar)
  const handleSectionChange = (section) => {
    setActiveSection(section || null);
  };

  // Scroll container for horizontal scrolling
  const scrollContainerRef = useRef(null);
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  // Modal handlers for multiple images preview
  const handlePreview = (images) => {
    setModalImages(images);
    setModalVisible(true);
  };

  const handleClosePreview = () => {
    setModalVisible(false);
    setModalImages([]);
  };

  return (
    <>
      {/* Modal for multiple images */}
      {modalVisible && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={handleClosePreview}>
              &times;
            </button>
            <div className={style.imageContainer}>
              {modalImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Preview ${index}`}
                  className={style.modalImage}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className={`${style.liveprojectcategories} container-fluid`}>
        <div className="container">
          {/* Categories bar */}
          <div className={style.scrollitemcategories}>
            <ul className={style.scrollitem}>
              <li className="nav-item">
                <a
                  className={`${style.navitem} ${style.navbackbtn} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("")}
                >
                  <i className="fa-solid fa-left-long"></i>
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("webDevelopment")}
                >
                  Web Development
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("dataAnalyst")}
                >
                  Data Analyst
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("frontEnd")}
                >
                  Front-end
                </a>
                <a
                  className={`${style.navitem} nav-link`}
                  role="button"
                  onClick={() => handleSectionChange("backEnd")}
                >
                  Back-end
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="container-fluid">
        <div className="container">
          {/* Landing view content */}
          {activeSection === null && (
            <div className={`${style.landingview} row`}>
              <div className={`${style.ourproject} col-6`}>
                <h2 className={style.mainheading}>Our Projects</h2>
                {/* <h2 className={style.mainheading}>
             {projects[0]?.project_type || "Default Project Type"} Our
           </h2> */}
                {projects
                  .filter((project) => project.project_type === "ourProjects")
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  ) // Sort by created_at, newest first
                  .slice(0, 2) // Get the first two projects
                  .map((project, index) => (
                    <div key={index} className={`${style.coursesbuyrowmain}`}>
                      <div className={`${style.coursesbuymain}`}>
                        <div className={style.courseslogomain}>
                          <img
                            className={style.coursesbuylogo}
                            // src={project.project_image1}
                            src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                            alt={project.project_name}
                          />
                          <img
                            className={style.coursesbuylogo}
                            src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                            alt={project.project_name}
                          />
                          <img
                            className={style.coursesbuylogo}
                            src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                            alt={project.project_name}
                          />
                        </div>
                        <div className={style.coursesdetail}>
                          {/* <h3 className={style.nameheading}>
                       {project.package_type}
                     </h3> */}
                          <h4 className={style.nameheading}>
                            {project.project_name}
                          </h4>
                          <span className={style.coursesdata}>
                            {project.project_details}
                          </span>
                          <h5 className={style.learnmorebuy}>
                            {project.project_subdetails}
                          </h5>
                        </div>
                        <div className={style.buttonz}>
                          {/* Preview button triggers modal for multiple images */}
                          {/* <button className={style.coursesbuybutton}>
                       {project.preview_pdf}
                     </button> */}
                          <button
                            className={style.coursesbuybutton}
                            onClick={() =>
                              handlePreview([
                                `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                                `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                                `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                              ])
                            }
                          >
                            Preview
                          </button>
                          {/* <button className={style.coursesbuybutton}>
                            {project.sourcecode_pdf}{" "}
                            <i className="fa-solid fa-download"></i>
                          </button> */}


                          <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`}  // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>



                        </div>

                        <div className={style.editbtns}>
                        <Link className={style.editbtnone} onClick={() => handleEdit(project)}>
                            Edit
                          </Link>
                          <Link className={style.editbtntwo} onClick={() => handleDelete(project.id)}>
                            Delete
                          </Link>
                          <Link className={style.editbtnthree} onClick={() => handleHideToggle(project)}>
                            {Number(project.hidden) === 1 ? "Unhide" : "Hide"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              {/* Live project section */}
              <div className={`${style.ourproject} col-6`}>
                <h2 className={style.mainheading}>Live Projects</h2>
                {/* <h2 className={style.mainheading}>
             {projects[0]?.project_type || "Default Project Type"} Live
           </h2> */}
                {projects
                  .filter((project) => project.project_type === "liveProjects")
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  ) // Sort by created_at, newest first
                  .slice(0, 2) // Get the first two projects
                  .map((project, index) => (
                    <div key={index} className={`${style.coursesbuyrowmain}`}>
                      <div className={`${style.coursesbuymain}`}>
                        <div className={style.courseslogomain}>
                        <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                        </div>
                        <div className={style.coursesdetail}>
                          {/* <h3 className={style.nameheading}>
                       {project.package_type}
                     </h3> */}
                          <h4 className={style.nameheading}>
                            {project.project_name}
                          </h4>
                          <span className={style.coursesdata}>
                            {project.project_details}
                          </span>
                          <h5 className={style.learnmorebuy}>
                            {project.project_subdetails}
                          </h5>
                        </div>
                        <div className={style.buttonz}>
                          {/* <button className={style.coursesbuybutton}>
                       {project.preview_pdf}
                     </button> */}
                          <button
                            className={style.coursesbuybutton}
                            onClick={() =>
                              handlePreview([
                                `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                                `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                                `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                              ])
                            }
                          >
                            Preview
                          </button>
                          {/* <button className={style.coursesbuybutton}>
                            {project.sourcecode_pdf}{" "}
                            <i className="fa-solid fa-download"></i>
                          </button> */}

                          <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`} // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                        </div>

                        <div className={style.editbtns}>
                        <Link className={style.editbtnone} onClick={() => handleEdit(project)}>
                            Edit
                          </Link>
                          <Link className={style.editbtntwo} onClick={() => handleDelete(project.id)}>
                            Delete
                          </Link>
                          <Link className={style.editbtnthree} onClick={() => handleHideToggle(project)}>
                            {Number(project.hidden) === 1 ? "Unhide" : "Hide"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Additional sections (hidden when inactive) */}
      <div className="container-fluid">
        {activeSection === "webDevelopment" && (
          <div className={`${style.webDevlopment} ${style.d} container`}>
            <div
              className={`${style.scrollContainer}`}
              ref={scrollContainerRef}
            >
              {projects
                .filter((project) => project.package_type === "webDevelopment")
                .map((project, index) => (
                  <div key={index} className={`${style.coursesbuyrow}`}>
                    <div className={`${style.coursesbuy}`}>
                      <div className={style.courseslogo}>
                      <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                      </div>
                      <div className={style.coursesdetail}>
                        {/* <h3 className={style.nameheading}>
                          {project.package_type}
                        </h3> */}
                        <h4 className={style.nameheading}>
                          {project.project_name}
                        </h4>
                        <span className={style.coursesdata}>
                          {project.project_details}
                        </span>
                        <h5 className={style.learnmorebuy}>
                          {project.project_subdetails}
                        </h5>
                      </div>
                      <div className={style.buttonz}>
                        {/* <button className={style.coursesbuybutton}>
                          {project.preview_pdf}
                        </button> */}
                        <button
                          className={style.coursesbuybutton}
                          onClick={() =>
                            handlePreview([
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                            ])
                          }
                        >
                          Preview
                        </button>
                        <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`} // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                      </div>
                      <div className={style.editbtns}>
                        <Link className={style.editbtnone} onClick={() => handleEdit(project)}>
                            Edit
                          </Link>
                          <Link className={style.editbtntwo} onClick={() => handleDelete(project.id)}>
                            Delete
                          </Link>
                          <Link className={style.editbtnthree} onClick={() => handleHideToggle(project)}>
                            {Number(project.hidden) === 1 ? "Unhide" : "Hide"}
                          </Link>
                        </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className={style.arrowContainer}>
              <button onClick={scrollLeft} className={style.leftArrow}>
                &lt;
              </button>
              <button onClick={scrollRight} className={style.rightArrow}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="container-fluid">
        {activeSection === "dataAnalyst" && (
          <div className={`${style.dataAnalyst} ${style.d} container`}>
            <div
              className={`${style.scrollContainer}`}
              ref={scrollContainerRef}
            >
              {projects
                .filter((project) => project.package_type === "dataAnalyst")
                .map((project, index) => (
                  <div key={index} className={`${style.coursesbuyrow}`}>
                    <div className={`${style.coursesbuy}`}>
                      <div className={style.courseslogo}>
                      <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                      </div>
                      <div className={style.coursesdetail}>
                        {/* <h3 className={style.nameheading}>
                          {project.package_type}
                        </h3> */}
                        <h4 className={style.nameheading}>
                          {project.project_name}
                        </h4>
                        <span className={style.coursesdata}>
                          {project.project_details}
                        </span>
                        <h5 className={style.learnmorebuy}>
                          {project.project_subdetails}
                        </h5>
                      </div>
                      <div className={style.buttonz}>
                        {/* <button className={style.coursesbuybutton}>
                          {project.preview_pdf}
                        </button> */}
                        <button
                          className={style.coursesbuybutton}
                          onClick={() =>
                            handlePreview([
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                            ])
                          }
                        >
                          Preview
                        </button>
                        <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`} // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                      </div>
                      <div className={style.editbtns}>
                        <Link className={style.editbtnone} onClick={() => handleEdit(project)}>
                            Edit
                          </Link>
                          <Link className={style.editbtntwo} onClick={() => handleDelete(project.id)}>
                            Delete
                          </Link>
                          <Link className={style.editbtnthree} onClick={() => handleHideToggle(project)}>
                            {Number(project.hidden) === 1 ? "Unhide" : "Hide"}
                          </Link>
                        </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={style.arrowContainer}>
              <button onClick={scrollLeft} className={style.leftArrow}>
                &lt;
              </button>
              <button onClick={scrollRight} className={style.rightArrow}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="container-fluid">
        {activeSection === "frontEnd" && (
          <div className={`${style.frontEnd} ${style.d} container`}>
            <div
              className={`${style.scrollContainer}`}
              ref={scrollContainerRef}
            >
              {projects
                .filter((project) => project.package_type === "frontEnd")
                .map((project, index) => (
                  <div key={index} className={`${style.coursesbuyrow}`}>
                    <div className={`${style.coursesbuy}`}>
                      <div className={style.courseslogo}>
                      <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                      </div>
                      <div className={style.coursesdetail}>
                        {/* <h3 className={style.nameheading}>
                          {project.package_type}
                        </h3> */}
                        <h4 className={style.nameheading}>
                          {project.project_name}
                        </h4>
                        <span className={style.coursesdata}>
                          {project.project_details}
                        </span>
                        <h5 className={style.learnmorebuy}>
                          {project.project_subdetails}
                        </h5>
                      </div>
                      <div className={style.buttonz}>
                        {/* <button className={style.coursesbuybutton}>
                          {project.preview_pdf}
                        </button> */}
                        <button
                          className={style.coursesbuybutton}
                          onClick={() =>
                            handlePreview([
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                            ])
                          }
                        >
                          Preview
                        </button>
                        <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`} // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                      </div>
                      <div className={style.editbtns}>
                        <Link className={style.editbtnone} onClick={() => handleEdit(project)}>
                            Edit
                          </Link>
                          <Link className={style.editbtntwo} onClick={() => handleDelete(project.id)}>
                            Delete
                          </Link>
                          <Link className={style.editbtnthree} onClick={() => handleHideToggle(project)}>
                            {Number(project.hidden) === 1 ? "Unhide" : "Hide"}
                          </Link>
                        </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={style.arrowContainer}>
              <button onClick={scrollLeft} className={style.leftArrow}>
                &lt;
              </button>
              <button onClick={scrollRight} className={style.rightArrow}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="container-fluid">
        {activeSection === "backEnd" && (
          <div className={`${style.frontEnd} ${style.d} container`}>
            <div
              className={`${style.scrollContainer}`}
              ref={scrollContainerRef}
            >
              {projects
                .filter((project) => project.package_type === "backEnd")
                .map((project, index) => (
                  <div key={index} className={`${style.coursesbuyrow}`}>
                    <div className={`${style.coursesbuy}`}>
                      <div className={style.courseslogo}>
                      <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`}
                       alt={project.project_name}
                     />
                     <img
                       className={style.coursesbuylogo}
                       src={`${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`}
                       alt={project.project_name}
                     />
                      </div>
                      <div className={style.coursesdetail}>
                        {/* <h3 className={style.nameheading}>
                          {project.package_type}
                        </h3> */}
                        <h4 className={style.nameheading}>
                          {project.project_name}
                        </h4>
                        <span className={style.coursesdata}>
                          {project.project_details}
                        </span>
                        <h5 className={style.learnmorebuy}>
                          {project.project_subdetails}
                        </h5>
                      </div>
                      <div className={style.buttonz}>
                        {/* <button className={style.coursesbuybutton}>
                          {project.preview_pdf}
                        </button> */}
                        <button
                          className={style.coursesbuybutton}
                          onClick={() =>
                            handlePreview([
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image1}`,
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image2}`,
                              `${API_BASE_URL}/liveprojectimgpdf/images/${project.project_image3}`,
                            ])
                          }
                        >
                          Preview
                        </button>
                        <a
                     className={style.coursesbuybutton}
                     href={`${API_BASE_URL}/liveprojectimgpdf/pdf/${project.sourcecode_pdf}`} // Dynamically link to the PDF
                     target="_blank" // Opens the PDF in a new tab
                     rel="noopener noreferrer" // Security measure for opening links in a new tab
                     >
                     source code
                     <i className="fa-solid fa-download"></i>
                     </a>
                      </div>
                      <div className={style.editbtns}>
                        <Link className={style.editbtnone} onClick={() => handleEdit(project)}>
                            Edit
                          </Link>
                          <Link className={style.editbtntwo} onClick={() => handleDelete(project.id)}>
                            Delete
                          </Link>
                          <Link className={style.editbtnthree} onClick={() => handleHideToggle(project)}>
                            {Number(project.hidden) === 1 ? "Unhide" : "Hide"}
                          </Link>
                        </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={style.arrowContainer}>
              <button onClick={scrollLeft} className={style.leftArrow}>
                &lt;
              </button>
              <button onClick={scrollRight} className={style.rightArrow}>
                &gt;
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Edit Form */}
      {modalVisibleef && editProject && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <button className={style.closeButton} onClick={closeModal}>
              &times;
            </button>

            <div className={`${style.editformrow} ${style.editformheading}row`}>
              <h2>Edit Project</h2>
            </div>




            <div className={`${style.editformrow} row`}>
              <div className="col-6"> <label>Update Project Type:</label>
                <select
                className={styles.basicinputdesign}
                  type="text"
                  value={editProject.project_type}
                  onChange={(e) =>
                    setEditProject({
                      ...editProject,
                      project_type: e.target.value,
                    })
                  }
                >
                    <option value="">-- Please choose an option --</option>
                  <option value="ourProjects">Our Projects</option>
                  <option value="liveProjects">Live Projects</option>
                </select>
                </div>
              <div className="col-6">
              <label>Update Project Category:</label>
              <select
              className={styles.basicinputdesign}
                  type="text"
                  value={editProject.package_type}
                  onChange={(e) =>
                    setEditProject({
                      ...editProject,
                      package_type: e.target.value,
                    })
                  }
                >
                  <option value="">-- Please choose an option --</option>
                  <option value="webDevelopment">Web Development</option>
                  <option value="dataAnalyst">Data Analyst</option>
                  <option value="frontEnd">Front-end</option>
                  <option value="backEnd">Back-end</option>
                </select>

              </div>
            </div>








            <div className={`${style.editformrow} row`}>
              <div className="col-6">
              <label>Project Name:</label>
                <input
                className={styles.basicinputdesign}
                  type="text"
                  value={editProject.project_name}
                  onChange={(e) =>
                    setEditProject({
                      ...editProject,
                      project_name: e.target.value,
                    })
                  }
                />
              
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col-6">
              <label>Project Sub-Details:</label>
                {" "}
                <textarea
                className={styles.basicinputdesign}
                  type="text"
                  value={editProject.project_subdetails}
                  onChange={(e) =>
                    setEditProject({
                      ...editProject,
                      project_subdetails: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="col-6">
              <label>Project Details:</label>
                
                <textarea 
                className={styles.basicinputdesign}
                 type="text"
                  value={editProject.project_details}
                  onChange={(e) =>
                    setEditProject({
                      ...editProject,
                      project_details: e.target.value,
                    })
                  }></textarea>
              </div>
            </div>

            {/* File Inputs for Images */}
            <div className={`${style.editformrow} row`}>
              <div className="col-6">
              <label>Update Project Image 1:</label>
                {" "}
                <input className={styles.basicinputdesign} type="file" name="image1" onChange={handleFileChange} />
              </div>
              <div className="col-6">
              <label>Update Project Image 2:</label>
                {" "}
                <input className={styles.basicinputdesign} type="file" name="image2" onChange={handleFileChange} />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
              <div className="col"> 
              <label>Update Project Image 3:</label>
              <input className={styles.basicinputdesign} type="file" name="image3" onChange={handleFileChange} />
              </div>
            </div>

            {/* File Inputs for PDFs */}
            <div className={`${style.editformrow} row`}>
              <div className="col-6">
              <label>Update PDF for Preview:</label>
                {" "}
                <input
                className={styles.basicinputdesign}
                  type="file"
                  name="previewPDF"
                  onChange={handleFileChange}
                />
              </div>
              <div className="col-6">
              <label>Update PDF Source Code:</label>
                {" "}
                <input
                className={styles.basicinputdesign}
                  type="file"
                  name="sourceCodePDF"
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className={`${style.editformrow} row`}>
            <div className={` ${style.savechangesbtn} col-6`}>
            <Link className={style.editbtnfour} onClick={handleUpdate}>Save Changes</Link>
              </div>
              <div className={` ${style.cancelbtn} col-6`}>
              <Link className={style.editbtnfour} onClick={() => setEditProject(null)}>Cancel</Link>
              </div>
            </div>
          </div>
        </div>
      )}
        {/* Render Popup Message */}
        {message && (
          <div className={style.popupMessage}>
            <p>{message}</p>
          </div>
        )}
    </>
  );
};

export default Editliveproject;
