import React, { useRef, useEffect, useState } from "react";
import style from "./../Css/Editcourses.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import { Link } from "react-router-dom";
import API_BASE_URL from "../apiConfig";


const Editcourses = () => {
  const [courses, setCourses] = useState([]); // State to hold courses data
  const [editCourse, setEditCourse] = useState(null); // State to manage edit mode
  const [files, setFiles] = useState({ logo: null, previewPDF: null }); // To hold uploaded files
  const [message, setMessage] = useState(""); // State for popup message
  const scrollContainerRef = useRef(null);

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // Fetch data from backend
    const fetchCourses = async () => {
      try {
        const response = await fetch
          (`${API_BASE_URL}/get_courses.php`,
        ); // Replace with actual API URL
        const data = await response.json();
        setCourses(data);
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      }
    };

    fetchCourses();
  }, []);

  // const scrollRight = () => {
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
  //   }
  // };

  // const scrollLeft = () => {
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
  //   }
  // };

  const handleDelete = async (courseId) => {
    const showCustomAlert = (message, onConfirm, onCancel) => {
      const alertBox = document.createElement("div");
      alertBox.style.position = "fixed";
      alertBox.style.top = "20%";
      alertBox.style.left = "50%";
      alertBox.style.transform = "translate(-50%, -50%)";
      alertBox.style.padding = "20px";
      alertBox.style.backgroundColor = "#ACAF50";
      alertBox.style.color = "white";
      alertBox.style.fontSize = "16px";
      alertBox.style.borderRadius = "5px";
      alertBox.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
      alertBox.style.zIndex = "9999";
      alertBox.style.textAlign = "center";

      // Add the message
      const messageElement = document.createElement("p");
      messageElement.innerText = message;
      alertBox.appendChild(messageElement);

      // Add the Yes button
      const yesButton = document.createElement("button");
      yesButton.innerText = "Yes";
      yesButton.style.margin = "10px";
      yesButton.style.padding = "5px 15px";
      yesButton.style.backgroundColor = "#5cb85c";
      yesButton.style.color = "white";
      yesButton.style.border = "none";
      yesButton.style.borderRadius = "3px";
      yesButton.style.cursor = "pointer";

      yesButton.addEventListener("click", () => {
        alertBox.remove();
        onConfirm();
      });

      // Add the Cancel button
      const cancelButton = document.createElement("button");
      cancelButton.innerText = "Cancel";
      cancelButton.style.margin = "10px";
      cancelButton.style.padding = "5px 15px";
      cancelButton.style.backgroundColor = "#d9534f";
      cancelButton.style.color = "white";
      cancelButton.style.border = "none";
      cancelButton.style.borderRadius = "3px";
      cancelButton.style.cursor = "pointer";

      cancelButton.addEventListener("click", () => {
        alertBox.remove();
        if (onCancel) onCancel();
      });

      alertBox.appendChild(yesButton);
      alertBox.appendChild(cancelButton);

      document.body.appendChild(alertBox);
    };

    showCustomAlert(
      "Are you sure you want to delete this COURSE?",
      async () => {
        try {
          const response = await fetch
            (`${API_BASE_URL}/delete_course.php`,
            {
              method: "POST",
              body: JSON.stringify({ id: courseId }),
              headers: { "Content-Type": "application/json" },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            setCourses(courses.filter((course) => course.id !== courseId));
          } else {
            alert(result.message);
          }
        } catch (error) {
          console.error("Error deleting course:", error);
        }
      },
      () => {
        console.log("Delete action was cancelled.");
      }
    );
  };

  const handleEdit = (course) => {
    setEditCourse(course); // Set the course to edit
    setFiles({ logo: null, previewPDF: null }); // Reset file inputs
    setModalVisible(true); // Show modal
  };

  const closeModal = () => {
    setModalVisible(false);
    setEditCourse(null); // Clear the edit state
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    setFiles((prev) => ({ ...prev, [fileType]: file }));
  };

  const handleUpdate = async () => {
    if (editCourse) {
      const formData = new FormData();
      formData.append("id", editCourse.id);
      formData.append("courseName", editCourse.courseName);
      formData.append("courseFullName", editCourse.courseFullName);
      formData.append("coursePrice", editCourse.coursePrice);
      formData.append("courseDuration", editCourse.courseDuration);
      formData.append("courseTechnology", editCourse.courseTechnology);

      // Append files if they exist
      if (files.logo) formData.append("courseLogo", files.logo);
      if (files.previewPDF) formData.append("viewpdf", files.previewPDF);

      try {
        const response = await fetch
          (`${API_BASE_URL}/edit_course.php`,
          {
            method: "POST",
            body: formData,
          }
        );

        const result = await response.json();

        if (result.status === "success") {
          setCourses(
            courses.map((course) =>
              course.id === editCourse.id ? { ...editCourse } : course
            )
          );
          setEditCourse(null); // Clear edit mode
          setMessage("Changes saved successfully!"); // Set success message
          setTimeout(() => setMessage(""), 1500); // Hide message after 3 seconds
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error("Error updating course:", error);
      }
    }
  };

  const handleHideToggle = async (course) => {
    const newStatus = Number(course.hidden) === 1 ? 0 : 1; // Toggle status

    try {
      const response = await fetch
        (`${API_BASE_URL}/toggle_hide_course.php`,
        {
          method: "POST",
          body: JSON.stringify({ id: course.id, hidden: newStatus }),
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const result = await response.json();
      console.log("Backend response:", result);

      if (result.status === "success") {
        setCourses(
          courses.map((c) =>
            c.id === course.id ? { ...c, hidden: newStatus.toString() } : c
          )
        );
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error toggling course visibility:", error);
    }
  };

  return (
    <>
      <div
        id="coursesbuyy"
        className={`${style.coursebuycontainer_main} container-fluid`}
      >
        <div className={style.coursesbuycontainer}>
          <div className="heading">
            <h1 className={style.coursesbuyheading}>
              Transform Your Potential with Our Courses
            </h1>
          </div>

          <div className={`${style.scrollContainer}`} ref={scrollContainerRef}>
            <div className={style.coursesbuyrow}>
              {courses.map((course) => (
                <div key={course.id} className={style.coursesbuy}>
                  <div className={style.courseslogo}>
                    <img
                      className={style.coursesbuylogo}
                      // src={`http://localhost/iits_backend/courseimg/${course.courseLogo}`}
                      src={`${API_BASE_URL}/courseimg/${course.courseLogo}`}
                     
                      alt={course.courseName}
                    />
                  </div>
                  <div className={style.coursesdetail}>
                    <h4 className={style.nameheading}>{course.courseName}</h4>
                    <h6 className={style.coursesdata}>
                      {course.courseFullName}
                    </h6>
                    <p className={style.price}>
                      <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                      {course.coursePrice}
                    </p>
                    <p className={style.courseduration}>
                      {course.courseDuration}
                    </p>
                    <p className={style.coursetechnologies}>
                      {course.courseTechnology}
                    </p>
                    <div className={style.buttoncontainer}>
                      {/* <a className={style.learnmorebuy} href="#">
                        Learn more
                      </a> */}
                      <a
                        className={style.learnmorebuy}
                        // href={`http://localhost/iits_backend/coursepdf/${course.viewpdf}`}
                         // Dynamically link to the PDF
                         href={`${API_BASE_URL}/coursepdf/${course.viewpdf}`}
                        target="_blank" // Opens the PDF in a new tab
                        rel="noopener noreferrer" // Security measure for opening links in a new tab
                      >
                        Learn more
                      </a>
                      <Link className={style.coursesbuybutton} to={"/"}>
                        Coming Soon
                      </Link>
                    </div>
                  </div>
                  <div className={style.editbtns}>
                    <Link
                      className={style.editbtnone}
                      onClick={() => handleEdit(course)}
                    >
                      Edit
                    </Link>
                    <Link
                      className={style.editbtntwo}
                      onClick={() => handleDelete(course.id)}
                    >
                      Delete
                    </Link>
                    <Link
                      className={style.editbtnthree}
                      onClick={() => handleHideToggle(course)}
                    >
                      {Number(course.hidden) === 1 ? "Unhide" : "Hide"}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Arrow buttons at the bottom */}
          {/* <div className={`${style.arrowContainer} `}>
            <button onClick={scrollLeft} className={style.leftArrow}>
              <i className={` ${style.arrowicon} fa-solid fa-caret-left`}></i>
            </button>
            <button onClick={scrollRight} className={style.rightArrow}>
              <i className={` ${style.arrowicon} fa-solid fa-caret-right `}></i>
            </button>
          </div> */}
        </div>

        {/* Edit Form */}
        {modalVisible && editCourse && (
          <div className={style.modalOverlay}>
            <div className={style.modalContent}>
             

              <div
                className={`${style.editformrow} ${style.editformheading}row`}
              >
                <h2>Edit Course</h2>
              </div>
              <div className={`${style.editformrow} row`}>
                <div className="col-6">
                  <label>Course Name:</label>
                  <input
                  className={styles.basicinputdesign}
                    type="text"
                    value={editCourse.courseName}
                    onChange={(e) =>
                      setEditCourse({
                        ...editCourse,
                        courseName: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-6">
                  <label>Full Course Name:</label>
                  <input
                  className={styles.basicinputdesign}
                    type="text"
                    value={editCourse.courseFullName}
                    onChange={(e) =>
                      setEditCourse({
                        ...editCourse,
                        courseFullName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className={`${style.editformrow} row`}>
                <div className="col-6">
                  <label>Course Price</label>
                  <input
                  className={styles.basicinputdesign}
                    type="number"
                    value={editCourse.coursePrice}
                    onChange={(e) =>
                      setEditCourse({
                        ...editCourse,
                        coursePrice: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-6">
                  {" "}
                  <label>Course Duration</label>
                  <input
                  className={styles.basicinputdesign}
                    type="text"
                    value={editCourse.courseDuration}
                    onChange={(e) =>
                      setEditCourse({
                        ...editCourse,
                        courseDuration: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className={`${style.editformrow} row`}>
                <label>Course Technology</label>
                <div className="col-12">
                  <textarea
                  className={styles.basicinputdesign}
                    type="text"
                    value={editCourse.courseTechnology}
                    onChange={(e) =>
                      setEditCourse({
                        ...editCourse,
                        courseTechnology: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className={`${style.editformrow} row`}>
                <div className="col-6">
                  {" "}
                  <div>
                    <label>Upload Course Logo (JPEG, PNG, GIF only):</label>
                    <input
                      type="file"
                      className={styles.basicinputdesign}
                      // className={`${styles.basicinputimgdesign} ${style.inputprojectimage} courseLogo`}
                      onChange={(e) => handleFileChange(e, "logo")}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <label>Upload Preview PDF (pdf only):</label>
                    <input
                      type="file"
                      className={styles.basicinputdesign}
                      // className={`${styles.basicinputimgdesign} ${style.inputprojectimage} viewpdf`}
                      name="previewPDF"
                      onChange={(e) => handleFileChange(e, "previewPDF")}
                    />
                  </div>
                </div>
              </div>

              <div className={`${style.editformrow} row`}>
                <div className={` ${style.savechangesbtn} col-6`}>
                  <Link className={style.editbtnfour} onClick={handleUpdate}>
                    Save Changes
                  </Link>
                </div>
                <div className={` ${style.cancelbtn} col-6`}>
                  {" "}
                  <Link
                    className={style.editbtnfour}
                    onClick={() => setEditCourse(null)}
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Render Popup Message */}
        {message && (
          <div className={style.popupMessage}>
            <p>{message}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Editcourses;
