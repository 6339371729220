import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import 'typeface-roboto';

import ScrollToTop from './Componenets/ScrollToTop';
import LandingPageshow from './Componenets/LandingPageshow';
import Header2 from './Componenets/Header2';
import Liveproject from './Componenets/Liveproject';
import Liveclasses from "./Componenets/Liveclasses";
import Recentjob from "./Componenets/Recentjob";


import Footer from './Componenets/Footer';
import Navbar from './Componenets/NavBar';



import Internshipapply from './Componenets/InternshipapplyForm';
import Availableinternship from './Componenets/Availableinternship';



import Login from './Componenets/Login';


import Ccoursedata from './Componenets/CoursesIITS/Ccoursedata';
import Cplusplusedata from './Componenets/CoursesIITS/Cplusplusedata';
import Csscoursedata from './Componenets/CoursesIITS/Csscoursedata';
import DOTNETCoursedata from './Componenets/CoursesIITS/DOTNETCoursedata';
import Htmlcoursedata from './Componenets/CoursesIITS/Htmlcoursedata';
import JavaScriptdata from './Componenets/CoursesIITS/JavaScriptdata';
import Nodejsdata from './Componenets/CoursesIITS/Nodejsdata';
import Oracledata from './Componenets/CoursesIITS/Oracledata';
import Phpdata from './Componenets/CoursesIITS/Phpdata';
import ContactForm from './Componenets/ContactForm';
import PrivacyPolicy from './Componenets/PrivacyPolicy';
import TermsOfService from './Componenets/TermsOfService';
import Services from './Componenets/Services';
import Aboutus from './Componenets/Aboutus';
import JobApplicationform from './Componenets/JobApplicationform';
import CancellationAndRefund from './Componenets/CancellationAndRefund';
import ShippingAndDelivery from './Componenets/ShippingAndDelivery';
import CertificateSHOW from './Componenets/CertificateSHOW';
import Pythoncoursedata from './Componenets/CoursesIITS/Pythoncoursedata';
import Bootstrapcourse from './Componenets/CoursesIITS/Bootstrapcourse';
import AdvanceJavacoursedata from './Componenets/CoursesIITS/AdvanceJavacoursedata';
import Javacourse from './Componenets/CoursesIITS/Javacourse';
import MySQLcourse from './Componenets/CoursesIITS/MySQLcourse';
import Reactjscourse from './Componenets/CoursesIITS/Reactjscourse';
import Dashboard from './Componenets/Dashboard'
import ViewNewsletter from './Componenets/ViewNewsletter';
import InternshipTable from './Componenets/InternshipTable';

// saif
import Iitsadminlogin from './Componenets/Iitsadminlogin';
import Iitscodeadminpannel from './Componenets/Iitscodeadminpannel';
import ASPdotNetCourse from './Componenets/CoursesIITS/ASPdotNetCourse'
import Packages from './Componenets/Packages';




function App() {
  return (
    <Router>
      <ScrollToTop />

      <ConditionalWrapper>
     
        <Routes>
          <Route path="/" element={<LandingPageshow />} />
          <Route path="/Landing_page" element={<LandingPageshow />} />
          <Route path='/Login' element={<Login/>} />
          <Route path="/LiveProject" element={<Liveproject />} />
          <Route path="/Live_classes" element={<Liveclasses />} />
          <Route path="/Recent_job" element={<Recentjob />} />
          <Route path="/Dashboard" element={<Dashboard />} />
      


          <Route path="/Apply_internshipfoam" element={<Internshipapply />} />
          <Route path="/Available_internship" element={<Availableinternship />} />
          <Route path="/Contact_us" element={<ContactForm />} />
          <Route path="/Services_s" element={<Services/>} />
          <Route path="/About_uS" element={<Aboutus/>} />
          <Route path="/Privacy_policy" element={<PrivacyPolicy/>} />
          <Route path="/TERMSofServices" element={<TermsOfService/>} />
          <Route path="/jobApply_Form" element={<JobApplicationform/>} />
          <Route path="/Cancellation_Refund" element={<CancellationAndRefund/>} />
          <Route path="/Shipping_Delivery" element={<ShippingAndDelivery/>} />
          <Route path="/certificate_show" element={<CertificateSHOW/>} />
          {/* saif */}
          <Route path="/iitsadmin" element={<Iitsadminlogin />} />
          <Route path="/Iitscode_adminpannel" element={<Iitscodeadminpannel />} />

          {/* courses route  */}
          <Route path="/C_COURSE" element={<Ccoursedata/>} />       
          <Route path="/c++_COURSE" element={<Cplusplusedata/>} />
          <Route path="/CSS_COURSE" element={<Csscoursedata/>} />
          <Route path="/DOTNET_COURSE" element={<DOTNETCoursedata/>} />       
          <Route path="/HTM_L_COURSE" element={<Htmlcoursedata/>} />    
          <Route path="/JAVA_SCRIPT_COURSE" element={<JavaScriptdata/>} />
          <Route path="/NODEJS_COURSE" element={<Nodejsdata/>} />
          <Route path="/ORACLE_COURSE" element={<Oracledata/>} />
          <Route path="/PHP_COURSE" element={<Phpdata/>} />
          <Route path="/PYTHON" element={<Pythoncoursedata/>} />
          <Route path="/BOOTSTRAP" element={<Bootstrapcourse/>} />
          <Route path="/ADVANCE_JAVA" element={<AdvanceJavacoursedata/>} />
          <Route path="/JAV_A" element={<Javacourse/>} />
          <Route path="/My_SQL" element={<MySQLcourse/>} />
          <Route path="/ASPDOTNET_COURSE" element={<ASPdotNetCourse/>} />
          <Route path="/REACT" element={<Reactjscourse/>} />



          <Route path="/Packages" element={<Packages/>} />
        


        </Routes>

      </ConditionalWrapper>
    </Router>
  );
}

// A component to conditionally render Header and Footer
function ConditionalWrapper({ children }) {
  const location = useLocation();
  const hideHeaderFooter = ['/Dashboard',  "/Iitscode_adminpannel"].includes(location.pathname);

  return (
    <>
    {/* <ContactTable/> */}
     {/* <JobsTable/> */}
       {/* <ViewNewsletter/> */}
       {/* {<InternshipTable/>} */}
      {!hideHeaderFooter && <> <Header2 />  <Navbar /> </>}
      {children}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

export default App;
