import React, { useState } from 'react';
import style from './../../Css/Courses.module.css'

function DOTNETCoursedata() {
    // State to keep track of the selected chapter
    const [selectedChapter, setSelectedChapter] = useState('chapter1')

    // Function to handle chapter selection
    const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter)
    }

    // State to track the active chapter
    const [activeChapter, setActiveChapter] = useState('chapter1');

    // Function to handle chapter click
    const handleChapterActive = (chapter) => {
        setActiveChapter(chapter);
    };
    const handleClick = (chapter) => {
        handleChapterActive(chapter);
        handleChapterClick(chapter);
        window.scrollTo(0,0);
    };


    return (
        <div className={`${style.firstcontainer} container-fluid`}>

            <div className={`${style.therow} row`}>

                <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                   C#
                    </button>
                    
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>

           

                        <li onClick={() => handleClick('chapter1')}
                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`} >
                          Introduction
                        </li>



                        <li
                            onClick={() => handleClick('chapter2')}
                            className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                              History and Evolution
                        </li>


                        <li onClick={() => handleClick('chapter3')}
                             className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} dropdown-item `}>
                          Unerderstanding the Common Language Runtime 
                     </li>
            
                        <li onClick={() => handleClick('chapter4')} 
                          className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''} dropdown-item `}>
                         Introduction to the .NET ecosystem 
                        </li>

<h5>Setting Up the Devlopment Environment</h5>
                        <li onClick={() => handleClick('chapter5')} 
                        className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''} dropdown-item `}>
                        Installing Visual Studio and .NET SDK
                        </li>

                        <li onClick={() => handleClick('chapter6')}
                        className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} dropdown-item `}>
                     Overview of IDE features 
                        </li>

                        <li onClick={() => handleClick('chapter7')} 
                        className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} dropdown-item `}>
                      Creating your first .NET application
                        </li>
<h5>C# Programming Basics</h5>
                        <li onClick={() => handleClick('chapter8')}
                        className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} dropdown-item `}>
                       Data Types, Variables, and Constants
                        </li>

                        <li onClick={() => handleClick('chapter9')}
                          className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} dropdown-item `}>
                      Control Structures 
                        </li>

                        <li onClick={() => handleClick('chapter10')}
                        className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} dropdown-item `}>
                    Functions and Methods
                        </li>

                        <li onClick={() => handleClick('chapter11')} 
                        className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} dropdown-item `}>
                     Exception Handling
                            </li>


<h5>Object-Oriented Programming in C#</h5>
                        <li onClick={() => handleClick('chapter12')}
                        className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item `}>
                        Classes and Objects
                        </li>

                        <li onClick={() => handleClick('chapter13')} 
                        className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} dropdown-item `}>
                       Inheritance, Polymorphism, and Encapsulation
                        </li>
                        <li onClick={() => handleClick('chapter14')} 
                        className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}  dropdown-item `}>
                        Interfaces and Abstract Classes
                        </li>

                        <li onClick={() => handleClick('chapter15')} 
                          className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''} dropdown-item `}>
                          Properties and Indexers
                        </li>
<h5> Advanced C# Concepts</h5>
                        <li onClick={() => handleClick('chapter16')} 
                        className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''} dropdown-item `}>
                        Delegates and Events
                        </li>

                        <li onClick={() => handleClick('chapter17')}
                          className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''} dropdown-item `}>
                         Lambda Expression and LINQ 
                        </li>


                        <li onClick={() => handleClick('chapter18')} 
                        className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''} dropdown-item `}>
                        Generics and Collections
                        </li>



                        <li onClick={() => handleClick('chapter19')}
                        className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} dropdown-item `}>
                        Asynchronous Programming 
                        </li>

<h5>.NET Core and ASP.NET Core</h5>
                        <li onClick={() => handleClick('chapter20')} 
                        className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''} dropdown-item `}>
                      Overview of ASP.NET Core
                        </li>

                        <li onClick={() => handleClick('chapter21')}
                        className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} dropdown-item `}>
                       MVC Architecture 
                        </li>


                        <li onClick={() => handleClick('chapter22')} 
                        className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''} dropdown-item `}>
                        RESTful API devlopment with ASP.NET Core
                        </li>

                        <li onClick={() => handleClick('chapter23')} 
                        className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} dropdown-item `}>
                        Middleware and Dependency Injection
                        </li>

<h5>Working with Databases</h5>
                        <li onClick={() => handleClick('chapter24')} 
                        className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''} dropdown-item `}>
                        Introduction to Entity Framwork Core
                        </li>
                        
                        <li onClick={() => handleClick('chapter25')} 
                        className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} dropdown-item `}>
                       Code First vs. Database First Approach
                        </li>

                        <li onClick={() => handleClick('chapter26')} 
                        className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} dropdown-item `}>
                        Performing CRUD Operations
                        </li>

                        <li onClick={() => handleClick('chapter27')} 
                        className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''} dropdown-item `}>
                        Migrations and Seeding Data
                        </li>

                        <li onClick={() => handleClick('chapter28')} 
                         className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''} dropdown-item `}>
                         Working with SQL Server and Other Database
                        </li>

<h5>Frontend Devlopment with Blazor</h5>
                        <li onClick={() => handleClick('chapter29')} 
                        className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} dropdown-item `}>
                       Introduction to Blazor 
                        </li>

                        <li onClick={() => handleClick('chapter30')} 
                        className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} dropdown-item `}>
                       Buliding Components and Layouts
                        </li>


                        <li onClick={() => handleClick('chapter31')} 
                        className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} dropdown-item `}>
                        Data Binding and Event Handling 
                        </li>

                        <li onClick={() => handleClick('chapter32')} 
                        className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} dropdown-item `}>
                         Routing and Navigation
                        </li>

<h5>Web Development Fundamentals</h5>
                        <li onClick={() => handleClick('chapter33')} 
                        className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} dropdown-item `}>
                       Understanding HTML, CSS, and JavaScript
                        </li>

                        <li onClick={() => handleClick('chapter34')} 
                        className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} dropdown-item `}>
                       Client-side Scripting with JavaScript
                        </li>


                        <li onClick={() => handleClick('chapter35')} 
                        className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} dropdown-item `}>
                        AJAX and Fetch API for asynchronous 
                        </li>
<h5>Security in .NET Applications</h5>
                        <li onClick={() => handleClick('chapter36')} 
                        className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} dropdown-item `}>
                       Authentication and Authorization
                        </li>

                        <li onClick={() => handleClick('chapter37')} 
                        className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''} dropdown-item `}>
                      Identity Framework in ASP.NET Core
                        </li>

                        <li onClick={() => handleClick('chapter38')} 
                        className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} dropdown-item `}>
                      Securing APIs with JWT 
                        </li>

                        <li onClick={() => handleClick('chapter39')} 
                        className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''} dropdown-item `}>
                      Data Protection and encryption
                        </li>

<h5>Unit Testing and Test-Driven Development (TDD)</h5>
                        <li onClick={() => handleClick('chapter40')} 
                        className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''} dropdown-item `}>
                        Introduction to Unit Testing in .NET
                        </li>


                        <li onClick={() => handleClick('chapter41')} 
                        className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''} dropdown-item `}>
                       Using xUnit or NUnit for testing
                        </li>

                        <li onClick={() => handleClick('chapter42')} 
                        className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''} dropdown-item `}>
                         Writing testable code
                        </li>

                        <li onClick={() => handleClick('chapter43')} 
                        className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''} dropdown-item `}>
                        Mocking dependencies
                        </li>


<h5> Deployment and Hosting </h5>

                        <li onClick={() => handleClick('chapter44')} 
                        className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''} dropdown-item `}>
                        Deploying .NET application to IIS
                        </li>

                        <li onClick={() => handleClick('chapter45')} 
                        className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''} dropdown-item `}>
                         Hosting ASP. NET Core application in Azure
                        </li>

                        <li onClick={() => handleClick('chapter46')} 
                        className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''} dropdown-item `}>
                         Introduction to Docker and containerization
                        </li>


                        <li onClick={() => handleClick('chapter47')} 
                        className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''} dropdown-item `}>
                         Continous Integration/Continuous Deployment  Practices
                        </li>


<h5> Performance Optimization</h5>
                        <li onClick={() => handleClick('chapter48')} 
                        className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''} dropdown-item `}>
                         Profiling and analyzing performance
                        </li>

                        <li onClick={() => handleClick('chapter49')} 
                        className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''} dropdown-item `}>
                       Memory Management and Garbage Collection
                        </li>

                        <li onClick={() => handleClick('chapter50')} 
                        className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''} dropdown-item `}>
                        Caching strategies
                        </li>

                        <li onClick={() => handleClick('chapter51')} 
                        className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''} dropdown-item `}>
                       Best Practices for Writing Efficient Code
                        </li>


<h5>Advances Topics</h5>
                        <li onClick={() => handleClick('chapter52')} 
                        className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''} dropdown-item `}>
                        Microservices Architecture with .NET
                        </li>

                        <li onClick={() => handleClick('chapter53')} 
                        className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''} dropdown-item `}>
                         Building Background Services with Hangfire or Quartz 
                        </li>

                        <li onClick={() => handleClick('chapter54')} 
                        className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''} dropdown-item `}>
                       Understanding gRPC and SignalR for real-time applications
                        </li>

                        <li onClick={() => handleClick('chapter55')} 
                        className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''} dropdown-item `}>
                       Working with Cloud Services 
                        </li>


              </ul>
                </div>





                <div className={`${style.leftcolumn} col-2`}>

                    <ul className={`${style.chapters} `}>
                    <h5 className={style.stickyheading} >C#</h5>
                
                        <li
                            onClick={() => handleClick('chapter1')}

                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none`}>
                             Introduction
                            </li>


                       
                            <li
                            onClick={() => handleClick('chapter2')}
                            className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}  `}>
                              History and Evolution
                        </li>


                        <li onClick={() => handleClick('chapter3')}
                             className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''}  `}>
                          Unerderstanding the Common Language Runtime 
                     </li>
            
                        <li onClick={() => handleClick('chapter4')} 
                          className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}  `}>
                  Introduction to the .NET ecosystem 
                        </li>

<h5>Setting Up the Devlopment Environment</h5>
                        <li onClick={() => handleClick('chapter5')} 
                        className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}  `}>
                        Installing Visual Studio and .NET SDK
                        </li>

                        <li onClick={() => handleClick('chapter6')}
                        className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''}  `}>
                     Overview of IDE features 
                        </li>

                        <li onClick={() => handleClick('chapter7')} 
                        className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''}  `}>
                      Creating your first .NET application
                        </li>
<h5>C# Programming Basics</h5>
                        <li onClick={() => handleClick('chapter8')}
                        className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}  `}>
                       Data Types, Variables, and Constants
                        </li>

                        <li onClick={() => handleClick('chapter9')}
                          className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''}  `}>
                      Control Structures 
                        </li>

                        <li onClick={() => handleClick('chapter10')}
                        className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}  `}>
                    Functions and Methods
                        </li>

                        <li onClick={() => handleClick('chapter11')} 
                        className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} `}>
                     Exception Handling
                            </li>


<h5>Object-Oriented Programming in C#</h5>
                        <li onClick={() => handleClick('chapter12')}
                        className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} `}>
                        Classes and Objects
                        </li>

                        <li onClick={() => handleClick('chapter13')} 
                        className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}  `}>
                       Inheritance, Polymorphism, and Encapsulation
                        </li>
                        <li onClick={() => handleClick('chapter14')} 
                        className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}  `}>
                        Interfaces and Abstract Classes
                        </li>

                        <li onClick={() => handleClick('chapter15')} 
                          className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}  `}>
                          Properties and Indexers
                        </li>
<h5> Advanced C# Concepts</h5>
                        <li onClick={() => handleClick('chapter16')} 
                        className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}  `}>
                        Delegates and Events
                        </li>

                        <li onClick={() => handleClick('chapter17')}
                          className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}  `}>
                         Lambda Expression and LINQ 
                        </li>


                        <li onClick={() => handleClick('chapter18')} 
                        className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}  `}>
                        Generics and Collections
                        </li>



                        <li onClick={() => handleClick('chapter19')}
                        className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''}  `}>
                         Asynchronous Programming 
                        </li>

<h5>.NET Core and ASP.NET Core</h5>
                        <li onClick={() => handleClick('chapter20')} 
                        className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}  `}>
                       Overview of ASP.NET Core
                        </li>

                        <li onClick={() => handleClick('chapter21')}
                        className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} `}>
                       MVC Architecture 
                        </li>


                        <li onClick={() => handleClick('chapter22')} 
                        className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}`}>
                       RESTful API devlopment with ASP.NET Core
                        </li>

                        <li onClick={() => handleClick('chapter23')} 
                        className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} `}>
                       Middleware and Dependency Injection
                        </li>

<h5>Working with Databases</h5>
                        <li onClick={() => handleClick('chapter24')} 
                        className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}  `}>
                       Introduction to Entity Framwork Core
                        </li>
                        
                        <li onClick={() => handleClick('chapter25')} 
                        className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} `}>
                   Code First vs. Database First Approach
                        </li>

                        <li onClick={() => handleClick('chapter26')} 
                        className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} `}>
               Performing CRUD Operations
                        </li>

                        <li onClick={() => handleClick('chapter27')} 
                        className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}  `}>
               Migrations and Seeding Data
                        </li>

                        <li onClick={() => handleClick('chapter28')} 
                        className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''} `}>
               Working with SQL Server and Other Database
                        </li>

<h5>Frontend Devlopment with Blazor</h5>
                        <li onClick={() => handleClick('chapter29')} 
                        className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''}  `}>
                Introduction to Blazor 
                        </li>

                        <li onClick={() => handleClick('chapter30')} 
                        className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''}  `}>
            Buliding Components and Layouts
                        </li>


                        <li onClick={() => handleClick('chapter31')} 
                        className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''}  `}>
           Data Binding and Event Handling 
                        </li>

                        <li onClick={() => handleClick('chapter32')} 
                        className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} `}>
          Routing and Navigation
                        </li>

<h5>Web Development Fundamentals</h5>
                        <li onClick={() => handleClick('chapter33')} 
                        className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} `}>
               Understanding HTML, CSS, and JavaScript
                        </li>

                        <li onClick={() => handleClick('chapter34')} 
                        className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''}  `}>
            Client-side Scripting with JavaScript
                        </li>


                        <li onClick={() => handleClick('chapter35')} 
                        className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''}   `}>
                AJAX and Fetch API for asynchronous 
                        </li>
<h5>Security in .NET Applications</h5>
                        <li onClick={() => handleClick('chapter36')} 
                        className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} `}>
              Authentication and Authorization
                        </li>

                        <li onClick={() => handleClick('chapter37')} 
                        className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''}  `}>
        Identity Framework in ASP.NET Core
                        </li>

                        <li onClick={() => handleClick('chapter38')} 
                        className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} `}>
               Securing APIs with JWT 
                        </li>

                        <li onClick={() => handleClick('chapter39')} 
                        className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''}  `}>
              Data Protection and encryption
                        </li>

<h5>Unit Testing and Test-Driven Development (TDD)</h5>
                        <li onClick={() => handleClick('chapter40')} 
                        className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''}  `}>
             Introduction to Unit Testing in .NET
                        </li>


                        <li onClick={() => handleClick('chapter41')} 
                        className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''}  `}>
            Using xUnit or NUnit for testing
                        </li>

                        <li onClick={() => handleClick('chapter42')} 
                        className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''}  `}>
               Writing testable code
                        </li>

                        <li onClick={() => handleClick('chapter43')} 
                        className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''}  `}>
              Mocking dependencies
                        </li>


<h5> Deployment and Hosting </h5>

                        <li onClick={() => handleClick('chapter44')} 
                        className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''}  `}>
              Deploying .NET application to IIS
                        </li>

                        <li onClick={() => handleClick('chapter45')} 
                        className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''}  `}>
               Hosting ASP. NET Core application in Azure
                        </li>

                        <li onClick={() => handleClick('chapter46')} 
                        className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''}  `}>
          Introduction to Docker and containerization
                        </li>


                        <li onClick={() => handleClick('chapter47')} 
                        className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''}  `}>
                Continous Integration/Continuous Deployment  Practices
                        </li>


<h5> Performance Optimization</h5>
                        <li onClick={() => handleClick('chapter48')} 
                        className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''}  `}>
                Profiling and analyzing performance
                        </li>

                        <li onClick={() => handleClick('chapter49')} 
                        className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''}  `}>
               Memory Management and Garbage Collection
                        </li>

                        <li onClick={() => handleClick('chapter50')} 
                        className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''}  `}>
               Caching strategies
                        </li>

                        <li onClick={() => handleClick('chapter51')} 
                        className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''}  `}>
           Best Practices for Writing Efficient Code
                        </li>


<h5>Advances Topics</h5>
                        <li onClick={() => handleClick('chapter52')} 
                        className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''}  `}>
           Microservices Architecture with .NET
                        </li>

                        <li onClick={() => handleClick('chapter53')} 
                        className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''} `}>
                      Building Background Services with Hangfire or Quartz 
                        </li>

                        <li onClick={() => handleClick('chapter54')} 
                        className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''} `}>
                       Understanding gRPC and SignalR for real-time applications
                        </li>

                        <li onClick={() => handleClick('chapter55')} 
                        className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''}  `}>
                       Working with Cloud Services 
                        </li>


                    </ul>
                </div>




                <div className={`${style.rightcolumn} col`}>


                    {/* Chapter Content */}



                    {selectedChapter === 'chapter1' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction</h1>
   
    <p>
      .NET programming refers to the practice of developing applications using the .NET framework, a versatile and powerful platform created by Microsoft. This framework provides a comprehensive environment for building a wide range of applications, from web and desktop software to cloud services and mobile applications. With its extensive set of libraries, tools, and runtime environments, .NET simplifies the development process and enhances productivity for developers.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of .NET Programming</h3>

    <ol>
      <li>
        <strong>Multi-language Support</strong>: .NET supports various programming languages, including C#, VB.NET, and F#. This allows developers to work in the language they are most comfortable with while still leveraging the same underlying framework and libraries.
      </li><br />
      <li>
        <strong>Common Language Runtime (CLR)</strong>: The CLR is at the heart of the .NET framework, managing the execution of .NET applications. It provides services such as memory management, exception handling, and security, enabling programs written in different languages to work seamlessly together.
      </li><br />
      <li>
        <strong>Base Class Library (BCL)</strong>: .NET includes a rich Base Class Library that offers a wide variety of pre-built functionalities, such as data manipulation, file I/O, and network communication. This reduces the amount of code developers need to write from scratch and accelerates application development.
      </li><br />
      <li>
        <strong>Cross-platform Development</strong>: With the introduction of .NET Core and subsequent versions like .NET 5 and .NET 6, .NET has evolved into a cross-platform framework. Developers can build applications that run on Windows, macOS, and Linux, enhancing the flexibility and reach of their software.
      </li><br />
      <li>
        <strong>Integrated Development Environment (IDE)</strong>: Visual Studio is the primary IDE used for .NET development, providing a robust set of tools for writing, debugging, and managing code. Features such as IntelliSense, code refactoring, and integrated debugging facilitate a smoother development experience.
      </li><br />
      <li>
        <strong>Application Types</strong>: .NET supports a variety of application development scenarios, including:
        <ul>
          <li><strong>Web Applications</strong>: Built using ASP.NET for dynamic web pages and RESTful APIs.</li><br />
          <li><strong>Desktop Applications</strong>: Developed using Windows Forms and Windows Presentation Foundation (WPF).</li><br />
          <li><strong>Mobile Applications</strong>: Created with Xamarin for cross-platform mobile solutions.</li><br />
          <li><strong>Cloud-based Applications</strong>: Easily integrated with Microsoft Azure for scalable cloud services.</li>
        </ul>
      </li><br />
      <li>
        <strong>Automatic Memory Management</strong>: .NET's garbage collection mechanism automatically handles memory allocation and deallocation, allowing developers to focus on writing code rather than managing memory manually.
      </li><br />
      <li>
        <strong>Security Features</strong>: .NET provides robust security measures, including code access security and role-based security, to protect applications from unauthorized access and vulnerabilities.
      </li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      .NET programming is a powerful approach to application development that combines versatility, productivity, and strong community support. With its comprehensive framework and robust tools, developers can create modern, high-performance applications that meet a wide range of user needs and industry requirements. Whether for enterprise solutions, web services, or mobile apps, .NET programming continues to be a popular choice for developers around the world.
    </p>


  </div>
)}



{selectedChapter === 'chapter2' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>History and Evolution of .NET Programming</h1>
    
    <p>
      .NET is a comprehensive and versatile software development framework created by Microsoft. Its evolution reflects the changing landscape of technology and development practices over the years. Here’s a detailed look at the history and evolution of .NET programming:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Milestones in the History of .NET:</h2>

    <ol>
      <li>
        <strong>Early 2000s: The Birth of .NET Framework</strong>
        <ul>
          <li>
            The first version of the .NET Framework, .NET Framework 1.0, was released in February 2002. It was introduced alongside Visual Studio .NET and aimed to provide a unified platform for Windows application development.
          </li><br />
          <li>
            Key features included the Common Language Runtime (CLR), which allowed for the execution of managed code, and a Base Class Library (BCL) providing pre-built functionalities for developers.
          </li>
          <li>
            The framework supported multiple programming languages, including C#, VB.NET, and later F#.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>2005–2010: Expanding Capabilities</strong>
        <ul>
          <li>
            Several updates to the .NET Framework were released during this period, including .NET Framework 2.0 (2005) and .NET Framework 3.5 (2007), which introduced generics, LINQ (Language Integrated Query), and enhanced web development capabilities with ASP.NET.
          </li><br />
          <li>
            ASP.NET became increasingly popular for developing dynamic web applications and services.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>2012: Introduction of .NET Framework 4.5</strong>
        <ul>
          <li>
            This version introduced asynchronous programming with the <code>async</code> and <code>await</code> keywords, which significantly improved performance in web applications.
          </li><br />
          <li>
            The focus shifted towards building cloud-based applications, particularly with the advent of Microsoft Azure.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>2014: The Emergence of .NET Core</strong>
        <ul>
          <li>
            Microsoft introduced .NET Core, a modular, cross-platform version of the .NET Framework that could run on Windows, macOS, and Linux.
          </li><br />
          <li>
            This marked a significant shift as .NET Core was designed to be lightweight and optimized for cloud and microservices architectures.
          </li><br />
          <li>
            The open-source nature of .NET Core encouraged community contributions and broadened its adoption.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>2016: ASP.NET Core and the Microservices Revolution</strong>
        <ul>
          <li>
            ASP.NET Core was re-engineered to work with .NET Core, allowing developers to build high-performance web applications and APIs that were cross-platform.
          </li><br />
          <li>
            The rise of microservices architecture further propelled .NET Core's popularity, as it allowed for the development of small, independent services that could be deployed and scaled individually.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>2020: The Unification with .NET 5</strong>
        <ul>
          <li>
            Microsoft launched .NET 5, which unified the various .NET implementations (.NET Framework, .NET Core, and Xamarin) into a single platform.
          </li><br />
          <li>
            This consolidation provided a more cohesive development experience, allowing developers to use a single framework for web, desktop, mobile, and cloud applications.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>2021: .NET 6 and Long-Term Support (LTS)</strong>
        <ul>
          <li>
            .NET 6, released in November 2021, is the first LTS version of the unified platform, providing a stable foundation for developers with extended support.
          </li><br />
          <li>
            It introduced new features like minimal APIs for building lightweight services and MAUI (Multi-platform App UI) for cross-platform app development with a single codebase.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Future of .NET</strong>
        <ul>
          <li>
            Microsoft has committed to a regular release cycle for .NET, with new versions expected to build on the core principles of performance, flexibility, and productivity.
          </li><br />
          <li>
            Future iterations (like .NET 7 and beyond) will continue to enhance capabilities in cloud-native development, AI integration, and developer productivity tools.
          </li>
        </ul>
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Key Highlights of .NET Evolution:</h2>
    <ul>
      <li>
        <strong>From Windows-only to Cross-platform</strong>: The transition from a Windows-centric framework to a cross-platform ecosystem has broadened the scope for developers and applications.
      </li><br />
      <li>
        <strong>Open Source Movement</strong>: The open-source nature of .NET Core has fostered a collaborative community, leading to rapid innovation and enhancements.
      </li><br />
      <li>
        <strong>Unified Platform</strong>: The integration of various .NET implementations into a single platform has simplified the development landscape, allowing developers to use a consistent set of tools and libraries across different types of applications.
      </li>
    </ul><br />

    <p>
      Today, .NET is a mature, robust framework that supports a wide range of applications, from enterprise solutions and web services to mobile apps and games. Its evolution has made it one of the most versatile and widely adopted development platforms in the industry.
    </p>
  </div>
)}


{selectedChapter === 'chapter3' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Understanding the Common Language Runtime (CLR)</h1>

    <p>
      The Common Language Runtime (CLR) is a fundamental component of the .NET Framework that provides the execution environment for .NET applications. 
      It serves as the backbone of .NET's managed code, allowing developers to build applications in various programming languages while ensuring consistency and interoperability. 
      Here’s an in-depth look at CLR and its key features.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of the CLR</h3>

    <ol>
      <li>
        <strong>Execution Management:</strong>
        <ul>
          <li>
            The CLR is responsible for executing .NET applications. It compiles the Intermediate Language (IL) code, which is generated by the compiler, into machine code suitable for the host machine. 
            This process is known as Just-In-Time (JIT) compilation.
          </li>
          <li>
            JIT compilation allows for optimizations specific to the machine on which the application is running, improving performance.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Memory Management:</strong>
        <ul>
          <li>
            One of the primary responsibilities of the CLR is memory management through a process known as garbage collection (GC). The GC automatically handles memory allocation and deallocation, preventing memory leaks and fragmentation.
          </li>
          <li>
            By automatically reclaiming memory that is no longer in use, the CLR helps developers focus on writing code rather than managing memory manually.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Type Safety:</strong>
        <ul>
          <li>
            The CLR enforces type safety, ensuring that data types are used correctly throughout an application. This prevents type-related errors and enhances the security of the application.
          </li>
          <li>
            Type safety is achieved through strict checks during compilation and runtime, allowing the CLR to verify that objects are accessed in a manner consistent with their declared types.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Exception Handling:</strong>
        <ul>
          <li>
            The CLR provides a robust exception handling model that allows developers to catch and handle runtime errors gracefully.
          </li>
          <li>
            This model supports structured exception handling, which enables developers to separate error-handling logic from regular code, improving code readability and maintainability.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Interoperability:</strong>
        <ul>
          <li>
            The CLR facilitates interoperability between different programming languages. This means that code written in one .NET language (like C#) can seamlessly interact with code written in another language (like VB.NET or F#) without issues.
          </li>
          <li>
            This is made possible by the Common Type System (CTS), which defines how types are declared, used, and managed in the CLR.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Security:</strong>
        <ul>
          <li>
            The CLR includes a security model that helps protect applications from unauthorized access and malicious activities. This model includes code access security (CAS) and role-based security.
          </li>
          <li>
            CAS restricts the permissions granted to applications based on their origin and other criteria, ensuring that untrusted code cannot perform harmful operations.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Performance Optimization:</strong>
        <ul>
          <li>
            The CLR employs several performance optimizations, such as method inlining, dead code elimination, and optimized garbage collection. These optimizations enhance the performance of applications without requiring changes to the source code.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Versioning and Deployment:</strong>
        <ul>
          <li>
            The CLR supports versioning, allowing multiple versions of an assembly to coexist on the same machine. This feature prevents version conflicts and simplifies deployment.
          </li>
          <li>
            Developers can specify binding redirects to manage versioning issues and ensure that applications run with the correct assembly versions.
          </li>
        </ul>
      </li>
    </ol><br />

    <h3>Conclusion</h3>

    <p>
      The Common Language Runtime is a crucial component of the .NET Framework that provides a secure, efficient, and flexible environment for executing .NET applications. 
      Its features, including memory management, type safety, exception handling, and interoperability, empower developers to build robust and scalable applications across various domains, 
      from web services to enterprise solutions. Understanding the CLR is essential for leveraging the full potential of .NET programming and creating high-performance applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter4' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to the .NET ecosystem (Lnguages, Libraties, and Tools)
    </h1>
      
    <p>
      The .NET ecosystem is a comprehensive framework created by Microsoft that facilitates the development of a wide variety of applications, including web, desktop, mobile, cloud, and IoT solutions. It comprises a range of programming languages, libraries, and tools that work together to provide developers with a powerful environment for building robust and scalable applications.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Components of the .NET Ecosystem</h3>

    <ol>
      <li>
        <strong>Languages</strong>:
        <ul>
          <li>
            <strong>C#</strong>: The most widely used language in the .NET ecosystem, C# is a versatile, object-oriented programming language that is easy to learn and use. It is commonly used for building web applications, desktop applications, and cloud services.
          </li><br />
          <li>
            <strong>VB.NET</strong>: An evolution of the Visual Basic language, VB.NET allows developers to build .NET applications with a syntax that is more familiar to those who have used Visual Basic in the past.
          </li><br />
          <li>
            <strong>F#</strong>: A functional-first programming language that supports functional, imperative, and object-oriented programming. F# is well-suited for data analysis, machine learning, and complex calculations.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Libraries</strong>:
        <ul>
          <li>
            <strong>Base Class Library (BCL)</strong>: A core set of libraries that provide essential functionalities for .NET applications, including data manipulation, file I/O, network communication, and more. The BCL is designed to minimize the amount of code developers need to write from scratch.
          </li><br />
          <li>
            <strong>ASP.NET</strong>: A framework for building dynamic web applications and services. ASP.NET includes libraries for creating web pages, APIs, and real-time web applications using technologies like SignalR.
          </li><br />
          <li>
            <strong>Entity Framework</strong>: An Object-Relational Mapping (ORM) framework that allows developers to interact with databases using .NET objects. It simplifies data access and manipulation while providing a robust set of features for working with data.
          </li><br />
          <li>
            <strong>Xamarin</strong>: A set of libraries and tools for building cross-platform mobile applications using C#. Xamarin allows developers to share a significant portion of their codebase across iOS and Android platforms.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Tools</strong>:
        <ul>
          <li>
            <strong>Visual Studio</strong>: The primary integrated development environment (IDE) for .NET development. Visual Studio offers a comprehensive set of features, including code editing, debugging, testing, and deployment tools. It also includes powerful features like IntelliSense, code refactoring, and integration with version control systems.
          </li><br />
          <li>
            <strong>Visual Studio Code</strong>: A lightweight, cross-platform code editor that supports .NET development through extensions. It is popular among developers for its speed and flexibility.
          </li><br />
          <li>
            <strong>.NET CLI</strong>: The .NET Command-Line Interface (CLI) allows developers to create, build, run, and publish .NET applications directly from the command line, providing a convenient way to manage projects and dependencies.
          </li><br />
          <li>
            <strong>NuGet</strong>: A package manager for .NET that simplifies the process of integrating third-party libraries and dependencies into applications. It provides a vast repository of packages that developers can easily add to their projects.
          </li>
        </ul>
      </li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      The .NET ecosystem offers a rich array of languages, libraries, and tools that empower developers to create a diverse range of applications efficiently. With its strong community support, extensive documentation, and continuous evolution, .NET remains a popular choice for building modern software solutions across various platforms and industries. Whether you are developing a web application, a cloud service, or a mobile app, the .NET ecosystem provides the necessary resources and infrastructure to support your development needs.
    </p>


  </div>
)}



{selectedChapter === 'chapter5' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Installing Visual Studio and .NET SDK</h1>

    <p>
      Installing Visual Studio and the .NET SDK is an essential step for developers looking to create applications using the .NET framework. Here’s a comprehensive guide on how to install both components on your machine.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Prerequisites</h3>
    <ul>
      <li>A supported Windows version (Windows 10 or later is recommended).</li><br />
      <li>Adequate disk space (at least 10 GB is advisable).</li><br />
      <li>An internet connection to download the installer and necessary packages.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Step 1: Download Visual Studio Installer</h3>
    <ol>
      <li>
        <strong>Visit the Visual Studio Website:</strong> Go to the <a href="https://visualstudio.microsoft.com/downloads/" target="_blank" rel="noopener noreferrer">Visual Studio Download page</a>.
      </li><br />
      <li>
        <strong>Select the Version:</strong> You can choose between different editions:
        <ul>
          <li><strong>Visual Studio Community:</strong> A free edition suitable for individual developers, open-source projects, academic use, and small teams.</li><br />
          <li><strong>Visual Studio Professional:</strong> A paid version with additional features for small to medium teams.</li><br />
          <li><strong>Visual Studio Enterprise:</strong> A more advanced paid version with comprehensive tools for large teams and enterprise-level development.</li>
        </ul>
      </li><br />
      <li>
        <strong>Download the Installer:</strong> Click the "Download" button for your chosen edition to get the Visual Studio Installer.
      </li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Step 2: Run the Visual Studio Installer</h3>
    <ol>
      <li>
        <strong>Locate the Installer:</strong> Find the downloaded file (typically in your Downloads folder) and double-click it to run.
      </li><br />
      <li>
        <strong>User Account Control:</strong> If prompted by User Account Control, click "Yes" to allow the installer to run.
      </li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Step 3: Choose the Workloads</h3>
    <ol>
      <li>
        <strong>Select Workloads:</strong> The installer will present you with a list of workloads—these are groups of related features and tools you can install. Common workloads for .NET development include:
        <ul>
          <li><strong>ASP.NET and web development:</strong> For building web applications and services.</li><br />
          <li><strong>.NET desktop development:</strong> For creating Windows desktop applications.</li><br />
          <li><strong>Mobile development with .NET:</strong> For cross-platform mobile app development using Xamarin.</li><br />
        </ul>
        You can check multiple workloads depending on your development needs.
      </li><br />
      <li>
        <strong>Optional Components:</strong> You may also choose individual components by clicking on the "Individual components" tab if you want to customize your installation.
      </li><br />
      <li>
        <strong>Install:</strong> Click the "Install" button to begin the installation process. The installer will download and install the selected components.
      </li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Step 4: Download and Install .NET SDK</h3>
    <ol>
      <li>
        <strong>Automatic Installation:</strong> If you selected any .NET workload, the Visual Studio Installer automatically includes the necessary .NET SDK with your installation.
      </li><br />
      <li>
        <strong>Manual Installation (if needed):</strong> If you prefer to install the .NET SDK separately, visit the <a href="https://dotnet.microsoft.com/download" target="_blank" rel="noopener noreferrer">.NET download page</a> and:
        <ul>
          <li>Select the latest version of the .NET SDK.</li>
          <li>Download the installer for your operating system.</li>
          <li>Run the installer and follow the on-screen instructions.</li>
        </ul>
      </li>
    </ol><br />

    <h3>Step 5: Verify the Installation</h3>
    <ol>
      <li>
        <strong>Open Visual Studio:</strong> Launch Visual Studio after installation.
      </li><br />
      <li>
        <strong>Create a New Project:</strong> Click on "Create a new project" and select a project template (e.g., Console App, ASP.NET Core Web App) to ensure that the environment is set up correctly.
      </li><br />
      <li>
        <strong>Check .NET SDK Installation:</strong> Open a command prompt and run the command:
        <pre><code>dotnet --version</code></pre>
        This command should return the version number of the installed .NET SDK, confirming that it is installed correctly.
      </li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      You have successfully installed Visual Studio and the .NET SDK on your machine! You can now start building applications using the .NET framework. With Visual Studio’s powerful features and the flexibility of the .NET SDK, you are well-equipped to create a variety of software solutions, from web apps to mobile applications. Happy coding!
    </p>
  </div>
)}


{selectedChapter === 'chapter6' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Overview of IDE Features (Debugging, IntelliSense, etc.)</h1>

    <p>
      Integrated Development Environments (IDEs) play a crucial role in the development process by providing developers with essential tools and features that enhance productivity, streamline workflows, and improve the quality of software applications. In the context of .NET programming, popular IDEs such as Visual Studio and Visual Studio Code offer a rich set of features that facilitate various stages of application development. Below are some of the key features typically found in .NET IDEs:
    </p><br />

    <h2>1. Debugging</h2>
    <p >Debugging is a fundamental feature of any IDE, allowing developers to identify and fix issues within their code efficiently. Key debugging features in .NET IDEs include:</p>
    <ul>
      <li><strong>Breakpoints</strong>: Set breakpoints in your code to pause execution at specific lines, enabling inspection of variables, monitoring application flow, and evaluating expressions.</li><br />
      <li><strong>Watch Windows</strong>: Track the values of variables or expressions as the code executes, providing insight into the application's state.</li><br />
      <li><strong>Call Stack</strong>: View the active method calls at any given time to trace the sequence of method invocations.</li><br />
      <li><strong>Step Through Code</strong>: Navigate through code execution using options to step into, step over, or step out of methods.</li><br />
      <li><strong>Immediate Window</strong>: Execute commands and evaluate expressions on the fly while debugging for quick testing of code snippets.</li>
    </ul>
<br />

    <h2>2. IntelliSense</h2>
    <p >IntelliSense is a powerful code-completion feature that enhances coding efficiency and accuracy. Key aspects of IntelliSense include:</p>
    <ul>
      <li><strong>Code Suggestions</strong>: Provides real-time suggestions for classes, methods, properties, and variables based on the context.</li><br />
      <li><strong>Parameter Info</strong>: Displays information about method parameters, including their types and optional status, when calling a method.</li><br />
      <li><strong>Quick Info</strong>: Hovering over a symbol reveals detailed information, including its type and documentation comments.</li><br />
      <li><strong>Code Snippets</strong>: Insert predefined snippets to quickly add commonly used code patterns, improving consistency and productivity.</li>
    </ul><br />

    <h2>3. Code Navigation</h2>
    <p>Efficient code navigation features help developers quickly move through their codebase, particularly in large projects. Key navigation features include:</p>
    <ul>
      <li><strong>Go to Definition</strong>: Jump directly to the definition of a class, method, or variable for quick exploration.</li><br />
      <li><strong>Find All References</strong>: See where a symbol is referenced throughout the project to understand its usage and impact.</li><br />
      <li><strong>Code Map</strong>: Visual representation of code structure to understand relationships between different components.</li>
    </ul><br />

    <h2>4. Refactoring Tools</h2>
    <p>Refactoring tools assist developers in improving code structure without changing its behavior. Common refactoring features include:</p>
    <ul>
      <li><strong>Rename</strong>: Rename variables, methods, or classes across the entire project, ensuring all references are updated automatically.</li><br />
      <li><strong>Extract Method</strong>: Select a block of code to extract into a new method for better organization and readability.</li><br />
      <li><strong>Change Signature</strong>: Modify method parameters and update all calling references to maintain method integrity.</li>
    </ul><br />

    <h2>5. Version Control Integration</h2>
    <p>Version control integration is crucial for collaborative development. IDEs typically offer built-in support for version control systems (e.g., Git), including features such as:</p>
    <ul>
      <li><strong>Commit and Push</strong>: Commit changes and push them to remote repositories directly from the IDE.</li><br />
      <li><strong>Branching and Merging</strong>: Create branches, merge changes, and resolve conflicts with ease.</li><br />
      <li><strong>History View</strong>: View the commit history of files to track changes and understand project evolution.</li>
    </ul><br />


    <h2>6. Testing Tools</h2>
    <p>Many IDEs include integrated testing tools to support unit testing and test-driven development (TDD):</p>
    <ul>
      <li><strong>Test Explorer</strong>: A window for managing and running tests, displaying results, and identifying failures.</li><br />
      <li><strong>Test Coverage</strong>: Tools to analyze code coverage, ensuring tests adequately cover the application's codebase.</li>
    </ul><br />

    <h2>7. Extensions and Customization</h2>
    <p>The ability to extend and customize the IDE enhances its functionality to meet specific developer needs. Key features include:</p>
    <ul>
      <li><strong>Extensions Marketplace</strong>: Find and install extensions to add new features, improve workflows, or support additional programming languages.</li><br />
      <li><strong>Custom Themes and Layouts</strong>: Personalize the IDE experience by changing themes, keyboard shortcuts, and window layouts.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      The features offered by IDEs in .NET programming, such as debugging, IntelliSense, code navigation, refactoring tools, version control integration, testing support, and extensibility, significantly enhance the development experience. By leveraging these tools, developers can write better code more efficiently, streamline their workflows, and ultimately deliver higher-quality applications. Whether you are a beginner or an experienced developer, familiarizing yourself with these IDE features can greatly improve your productivity and effectiveness in .NET programming.
    </p>
  </div>
)}



{selectedChapter === 'chapter7' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Creating Your First .NET Application</h1>

    <p>
      Creating your first .NET application is an exciting step into the world of software development. This guide will walk you through the essential steps to set up a simple .NET application using the .NET SDK and either Visual Studio or Visual Studio Code. You’ll learn how to create a basic console application, which serves as a great introduction to the .NET environment.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Creating Your First .NET Application</h2>

    <h3>Prerequisites</h3>
    <p>Before you start, ensure you have the following:</p>
    <ul>
      <li>
        <strong>.NET SDK</strong>: Installed on your machine. You can check this by running <code>dotnet --version</code> in your command prompt or terminal.
      </li><br />
      <li>
        <strong>IDE</strong>: Visual Studio or Visual Studio Code installed.
      </li>
    </ul><br />

    <h3>Step 1: Create a New Project</h3>
    <ol>
      <li>
        <strong>Open your IDE:</strong>
        <ul>
          <li>If you’re using Visual Studio, launch the application.</li><br />
          <li>If you’re using Visual Studio Code, open the terminal within the application.</li>
        </ul>
      </li><br />
      <li>
        <strong>Create a New Console Application:</strong>
        <ul>
          <li>
            In <strong>Visual Studio</strong>:
            <ol>
              <li>Click on "Create a new project."</li><br />
              <li>Select "Console App (.NET Core)" or ".NET Console Application" from the list of templates.</li><br />
              <li>Click "Next."</li><br />
              <li>Name your project (e.g., "HelloWorld") and choose a location to save it.</li><br />
              <li>Click "Create."</li>
            </ol>
          </li><br />
          <li>
            In <strong>Visual Studio Code</strong>:
            <ol>
              <li>Open a terminal and navigate to the folder where you want to create your project.</li><br />
              <li>Run the command:
                <pre><code>dotnet new console -n HelloWorld</code></pre>
              </li><br />
              <li>Change to the project directory:
                <pre><code>cd HelloWorld</code></pre>
              </li>
            </ol>
          </li>
        </ul>
      </li>
    </ol><br />

    <h3>Step 2: Write Your Code</h3>
    <ol>
      <li>
        <strong>Open the Program File:</strong>
        <ul>
          <li>In Visual Studio, this will usually be named <code>Program.cs</code>.</li><br />
          <li>In Visual Studio Code, the same file will be in the root of your project.</li>
        </ul>
      </li><br />
      <li>
        <strong>Edit the Program:</strong>
        Replace the existing code with the following:
        <pre>
          <code>{`
            using System;

            namespace HelloWorld
            {{
                class Program
                {{
                    static void Main(string[] args)
                    {{
                        Console.WriteLine("Hello, World!");
                    }}
                }}
            }}
           `} </code>
        </pre>
      </li>
    </ol><br />

    <h3>Step 3: Build and Run the Application</h3>
    <ol>
      <li>
        <strong>Build the Application:</strong>
        <ul>
          <li>
            In <strong>Visual Studio</strong>: Click on "Build" in the menu bar, then select "Build Solution" (or simply press <code>Ctrl + Shift + B</code>).
          </li><br />
          <li>
            In <strong>Visual Studio Code</strong>: Run the following command in the terminal:
            <pre><code>dotnet build</code></pre>
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Run the Application:</strong>
        <ul>
          <li>
            In <strong>Visual Studio</strong>: Press <code>F5</code> to run the application in debug mode, or <code>Ctrl + F5</code> to run without debugging.
          </li><br />
          <li>
            In <strong>Visual Studio Code</strong>: Run the following command in the terminal:
            <pre><code>dotnet run</code></pre>
          </li>
        </ul>
      </li>
    </ol><br />

    <h3>Step 4: Observe the Output</h3>
    <p>
      After running the application, you should see the output:
    </p>
    <pre><code>Hello, World!</code></pre><br />

    <h2>Conclusion</h2>
    <p>
      Congratulations! You have successfully created your first .NET application. This simple console application introduces you to the .NET programming environment and demonstrates how to write, build, and run a basic C# program. You can now explore further by modifying the code, adding new features, or even branching out into more complex applications like web or desktop apps using .NET. Happy coding!
    </p>
  </div>
)}


{selectedChapter === 'chapter8' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Types, Variables, and Constants</h1>

   <h3>1. Data Types</h3>

    <p>
      In .NET, data types are classifications that dictate what kind of data can be stored and manipulated within a program. Each data type has its own size, range, and default value. .NET provides several built-in data types, which can be categorized as follows:
    </p>

    <ul>
      <li>
        <strong>Value Types:</strong> These hold data directly and are stored in the stack. Common value types include:
        <ul>
          <li><strong>Integral Types:</strong>
            <ul>
              <li><code>int</code> (32-bit signed integer)</li><br />
              <li><code>long</code> (64-bit signed integer)</li><br />
              <li><code>short</code> (16-bit signed integer)</li><br />
              <li><code>byte</code> (8-bit unsigned integer)</li>
            </ul>
          </li><br />
          <li>
            <strong>Floating Point Types:</strong>
            <ul>
              <li><code>float</code> (Single-precision floating point, 32-bit)</li><br />
              <li><code>double</code> (Double-precision floating point, 64-bit)</li>
            </ul>
          </li><br />
          <li>
            <strong>Boolean Type:</strong> <code>bool</code> (Represents a true or false value)
          </li><br />
          <li>
            <strong>Character Type:</strong> <code>char</code> (Represents a single 16-bit Unicode character)
          </li><br />
          <li><strong>Structs:</strong> Custom structures that can hold multiple data types.</li>
        </ul>
      </li><br />
      <li>
        <strong>Reference Types:</strong> These store references to their data (objects), which are stored in the heap. Common reference types include:
        <ul>
          <li><strong>String:</strong> Represents a sequence of characters (e.g., <code>string</code>).</li><br />
          <li><strong>Arrays:</strong> Collections of items of the same type.</li><br />
          <li><strong>Classes:</strong> User-defined types that encapsulate data and behavior.</li><br />
          <li><strong>Delegates:</strong> References to methods.</li>
        </ul>
      </li>
    </ul><br />

    <p>
      .NET also supports nullable types, allowing value types to be assigned a <code>null</code> value using <code>Nullable&lt;T&gt;</code> or the shorthand <code>T?</code>.
    </p>

    <h3>2. Variables</h3>

    <p>
      Variables are named storage locations in memory that hold values. In .NET, variables must be declared with a specific data type. The declaration creates the variable and allocates the necessary memory.
    </p>

    <p><strong>Declaration Syntax:</strong></p>
    <code>dataType variableName;</code><br /><br />

    <p><strong>Example:</strong></p>
    <code>
      int age; // Declaration of an integer variable <br />
      age = 30; // Assignment of a value to the variable
    </code><br /><br />

    <p>
      Variables can also be declared and initialized in a single line:
    </p>
    <code>string name = "John"; // Declaration and initialization</code><br /><br />

    <h3>Variable Naming Rules</h3>
    <ul>
      <li>Variable names must start with a letter or an underscore.</li><br />
      <li>They can contain letters, digits, and underscores.</li><br />
      <li>They are case-sensitive.</li><br />
      <li>They should not use reserved keywords.</li>
    </ul><br />

    <h3>3. Constants</h3>

    <p>
      Constants are similar to variables but with a key difference: once a constant is assigned a value, it cannot be changed throughout the program. Constants are declared using the <code>const</code> keyword.
    </p>

    <p><strong>Declaration Syntax:</strong></p>
    <code>const dataType constantName = value;</code><br /><br />

    <p><strong>Example:</strong></p>
    <code>const double Pi = 3.14159; // Declares a constant for Pi</code><br /><br />

    <h3 style={{paddingBottom:"6px"}}>Benefits of Constants</h3>
    <ul>
      <li><strong>Readability:</strong> Constants can improve code readability by providing meaningful names for fixed values.</li><br />
      <li><strong>Maintainability:</strong> If a constant value needs to change, it only needs to be updated in one place.</li>
    </ul><br />

    <h3>Summary</h3>

    <p>
      Understanding data types, variables, and constants is fundamental in .NET programming. Proper use of these elements allows developers to write efficient, readable, and maintainable code. By selecting the appropriate data type for variables and utilizing constants for fixed values, developers can enhance the robustness of their applications.
    </p>
  </div>
)}

{selectedChapter === 'chapter9' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Control Structures (if, switch, loops)</h1>

    <p>
      Control structures in .NET programming are constructs that control the flow of execution in a program. They allow developers to dictate the order in which statements are executed based on certain conditions or the number of times a block of code should run. Here’s an overview of the primary control structures in .NET, focusing on conditional statements (like <code>if</code> and <code>switch</code>) and loops (like <code>for</code>, <code>while</code>, and <code>foreach</code>).
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Control Structures in .NET Programming</h2>

    <h3>1. Conditional Statements</h3>

    <p>
      Conditional statements allow the program to make decisions based on specific conditions. The primary types are:
    </p><br />

    <h4>a. if Statement</h4>

    <p style={{paddingBottom:"6px"}}>
      The <code>if</code> statement evaluates a condition and executes a block of code if the condition is true. It can also include <code>else</code> and <code>else if</code> clauses for additional conditions.
    </p>

    <h5>Syntax:</h5>
    <pre><code>{`if (condition)
{
    // Code to execute if condition is true
}
else if (anotherCondition)
{
    // Code to execute if another condition is true
}
else
{
    // Code to execute if all conditions are false
}`}</code></pre><br />

    <h5>Example:</h5>
    <pre><code>{`int number = 10;

if (number > 0)
{
    Console.WriteLine("The number is positive.");
}
else if (number < 0)
{
    Console.WriteLine("The number is negative.");
}
else
{
    Console.WriteLine("The number is zero.");
}`}</code></pre><br />

    <h4>b. switch Statement</h4>

    <p style={{paddingBottom:"6px"}}>
      The <code>switch</code> statement is used to evaluate a variable against multiple possible values (cases). It’s often cleaner and more readable than using multiple <code>if</code> statements.
    </p>

    <h5>Syntax:</h5>
    <pre><code>{`switch (variable)
{
    case value1:
        // Code to execute if variable == value1
        break;
    case value2:
        // Code to execute if variable == value2
        break;
    default:
        // Code to execute if none of the above cases are matched
        break;
}`}</code></pre><br />

    <h5>Example:</h5>
    <pre><code>{`char grade = 'A';

switch (grade)
{
    case 'A':
        Console.WriteLine("Excellent!");
        break;
    case 'B':
        Console.WriteLine("Well done.");
        break;
    case 'C':
        Console.WriteLine("Good.");
        break;
    default:
        Console.WriteLine("Grade not recognized.");
        break;
}`}</code></pre><br />

    <h3>2. Loops</h3>

    <p style={{paddingBottom:"6px"}}>
      Loops allow a block of code to be executed repeatedly based on a condition. The main types of loops in .NET are:
    </p>

    <h4>a. for Loop</h4>

    <p style={{paddingBottom:"6px"}}>
      The <code>for</code> loop is used when the number of iterations is known. It includes initialization, a condition, and an increment/decrement statement.
    </p>

    <h5>Syntax:</h5>
    <pre><code>{`for (initialization; condition; increment/decrement)
{
    // Code to execute in each iteration
}`}</code></pre><br />

    <h5>Example:</h5>
    <pre><code>{`for (int i = 0; i < 5; i++)
{
    Console.WriteLine("Iteration: " + i);
}`}</code></pre><br />

    <h4>b. while Loop</h4>

    <p style={{paddingBottom:"6px"}}>
      The <code>while</code> loop continues to execute as long as the specified condition is true. The condition is checked before each iteration.
    </p>

    <h5>Syntax:</h5>
    <pre><code>{`while (condition)
{
    // Code to execute as long as condition is true
}`}</code></pre><br />

    <h5>Example:</h5>
    <pre><code>{`int count = 0;
while (count < 5)
{
    Console.WriteLine("Count: " + count);
    count++;
}`}</code></pre><br />

    <h4>c. do-while Loop</h4>

    <p style={{paddingBottom:"6px"}}>
      The <code>do-while</code> loop is similar to the <code>while</code> loop, but it guarantees that the code block will execute at least once because the condition is checked after the loop body.
    </p>

    <h5>Syntax:</h5>
    <pre><code>{`do
{
    // Code to execute
} while (condition);`}</code></pre><br />

    <h5>Example:</h5>
    <pre><code>{`int number = 0;
do
{
    Console.WriteLine("Number: " + number);
    number++;
} while (number < 5);`}</code></pre><br />

    <h4>d. foreach Loop</h4>

    <p style={{paddingBottom:"6px"}}>
      The <code>foreach</code> loop is used to iterate over a collection, such as an array or a list. It simplifies the process of looping through each item in a collection.
    </p>

    <h5>Syntax:</h5>
    <pre><code>{`foreach (var item in collection)
{
    // Code to execute for each item
}`}</code></pre><br />

    <h5>Example:</h5>
    <pre><code>{`string[] fruits = { "Apple", "Banana", "Cherry" };
foreach (string fruit in fruits)
{
    Console.WriteLine(fruit);
}`}</code></pre><br />

    <h2>Conclusion</h2>

    <p>
      Control structures are essential in .NET programming as they enable the implementation of logic within applications. Understanding how to use conditional statements and loops effectively allows developers to create dynamic and responsive applications. By mastering these control structures, developers can enhance the flow and functionality of their code.
    </p>
  </div>
)}

{selectedChapter === 'chapter10' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Functions and Methods</h1>

    <h3>1. Functions</h3>
    <p style={{paddingBottom:"6px"}}>
      In .NET programming, a <strong>function</strong> is a block of code that performs a specific task. It can take input parameters, execute a series of statements, and return a value. Functions promote code reusability and organization, allowing developers to break down complex problems into smaller, manageable parts.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Characteristics of Functions:</h4>
    <ul>
      <li>Can return a value or be void (no return value).</li><br />
      <li>Can take zero or more parameters.</li><br />
      <li>Can be called multiple times throughout a program.</li>
    </ul><br />

    <h4>Syntax:</h4>
    <pre>
      <code>{`
        returnType FunctionName(parameterType parameterName) {'{'}
        {'\n'}    // Code to execute
        {'\n'}    return value; // Optional, depending on return type
        {'\n'}{'}`}
</code>
    </pre><br />

    <h4>Example:</h4>
    <pre>
      <code>{`
        // Function that adds two numbers and returns the result
        int Add(int a, int b) {'{'}
        {'\n'}    return a + b; // Returns the sum of a and b
        {'\n'}{'}'}

        // Calling the function
        int result = Add(5, 3); // result will be 8
      `}</code>
    </pre><br />

    <h3>2. Methods</h3>
    <p style={{paddingBottom:"6px"}}>
      A <strong>method</strong> is similar to a function but is associated with a class or an object in object-oriented programming (OOP). In .NET, methods define behaviors for classes and can manipulate the data (fields) of an object.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Characteristics of Methods:</h4>
    <ul>
      <li>Defined within a class or struct.</li><br />
      <li>Can be instance methods (operating on instances of a class) or static methods (belonging to the class itself rather than any instance).</li><br />
      <li>Support overloading, allowing multiple methods with the same name but different parameter types or counts.</li>
    </ul><br />

    <h4>Syntax:</h4>
    <pre>
      <code>{`
        accessModifier returnType MethodName(parameterType parameterName) {'{'}
        {'\n'}    // Code to execute
        {'\n'}    return value; // Optional, depending on return type
        {'\n'}{'}'}
      `}</code>
    </pre><br />

    <h4>Example:</h4>
    <pre>
      <code>{`
        public class Calculator {'{'}
        {'\n'}    // Instance method to multiply two numbers
        {'\n'}    public int Multiply(int a, int b) {'{'}
        {'\n'}        return a * b; // Returns the product of a and b
        {'\n'}    {'}'}

        {'\n'}    // Static method to calculate the square of a number
        {'\n'}    public static int Square(int number) {'{'}
        {'\n'}        return number * number; // Returns the square of the number
        {'\n'}    {'}'}
        {'\n'}{'}'}

        // Creating an instance of the Calculator class
        Calculator calc = new Calculator();
        int product = calc.Multiply(4, 5); // product will be 20

        // Calling the static method without creating an instance
        int square = Calculator.Square(6); // square will be 36
       `} </code>
    </pre><br />

    <h2>Summary</h2>
    <p>
      In summary, functions and methods are fundamental building blocks in .NET programming, enabling developers to create reusable and organized code. While functions are standalone blocks of code, methods are tied to classes and encapsulate behaviors related to objects. Understanding how to define and utilize functions and methods is essential for effective programming in .NET.
    </p>
  </div>
)}


{selectedChapter === 'chapter11' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Exception Handling</h1>

    <p>
      Exception handling in .NET programming is a powerful mechanism that allows developers to gracefully manage runtime errors and exceptional conditions that may arise during the execution of a program. The goal of exception handling is to maintain the normal flow of an application even when unexpected situations occur.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Overview of Exception Handling</h2>

    <h3>What is an Exception?</h3>
    <p>
      An exception is an event that occurs during the execution of a program that disrupts its normal flow. Exceptions can be caused by various factors, such as invalid input, file access errors, network issues, or division by zero.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Why Use Exception Handling?</h3>
    <ul>
      <li><strong>Maintain Control:</strong> By handling exceptions, developers can ensure that their applications do not crash unexpectedly and can provide meaningful feedback to users.</li><br />
      <li><strong>Resource Management:</strong> Exception handling allows for proper resource cleanup, such as closing files or releasing network connections, even when errors occur.</li><br />
      <li><strong>Debugging:</strong> It helps identify issues in the code by providing stack traces and error messages.</li>
    </ul><br />

    <h2>Exception Handling Mechanism in .NET</h2>

    <p style={{paddingBottom:"6px"}}>
      In .NET, the exception handling mechanism is based on a combination of the <code>try</code>, <code>catch</code>, <code>finally</code>, and <code>throw</code> keywords.
    </p>

    <h3>1. Try Block</h3>
    <p>
      The <code>try</code> block contains the code that may throw an exception. If an exception occurs within this block, control is transferred to the corresponding <code>catch</code> block.
    </p>
    <pre>
      <code>
{`try
{
    // Code that may cause an exception
}`}
      </code>
    </pre><br />

    <h3>2. Catch Block</h3>
    <p>
      The <code>catch</code> block handles the exception. It can capture specific exceptions or use a general exception handler. Multiple <code>catch</code> blocks can be used to handle different types of exceptions.
    </p>
    <pre>
      <code>
{`catch (ExceptionType ex)
{
    // Code to handle the exception
}`}
      </code>
    </pre><br />

    <h4>Example:</h4>
    <pre>
      <code>
{`try
{
    int result = 10 / 0; // This will throw a DivideByZeroException
}
catch (DivideByZeroException ex)
{
    Console.WriteLine("Error: Division by zero is not allowed.");
}`}
      </code>
    </pre><br />

    <h3>3. Finally Block</h3>
    <p>
      The <code>finally</code> block contains code that will execute after the <code>try</code> and <code>catch</code> blocks, regardless of whether an exception was thrown or caught. This is typically used for cleanup actions.
    </p>
    <pre>
      <code>
{`finally
{
    // Code that runs regardless of an exception
}`}
      </code>
    </pre><br />

    <h4>Example:</h4>
    <pre>
      <code>
{`try
{
    // Code that may cause an exception
}
catch (Exception ex)
{
    // Handle the exception
}
finally
{
    // Cleanup code (e.g., closing a file or releasing resources)
    Console.WriteLine("Execution completed.");
}`}
      </code>
    </pre><br />

    <h3>4. Throw Statement</h3>
    <p>
      The <code>throw</code> statement is used to explicitly raise an exception. It can be used to throw a new exception or rethrow the current exception.
    </p>
    <pre>
      <code>
{`throw new Exception("Error message.");`}
      </code>
    </pre><br />

    <h4>Example:</h4>
    <pre>
      <code>
{`if (value < 0)
{
    throw new ArgumentException("Value must be non-negative.");
}`}
      </code>
    </pre><br />

    <h2>Summary</h2>
    <p>
      Exception handling in .NET is essential for building robust applications that can gracefully manage errors and maintain control over the application flow. By using <code>try</code>, <code>catch</code>, <code>finally</code>, and <code>throw</code>, developers can handle errors effectively, ensuring that their programs remain stable and user-friendly even in the face of unexpected issues. Properly implemented exception handling leads to cleaner code, better resource management, and improved user experience.
    </p>
  </div>
)}



{selectedChapter === 'chapter12' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Classes and Objects</h1>

    <p>
      In .NET programming, particularly within the paradigm of Object-Oriented Programming (OOP), 
      <strong> classes</strong> and <strong>objects</strong> are fundamental concepts that enable developers to model 
      real-world entities and their behaviors in a structured way.
    </p><br />

    <h2>1. Classes</h2>

    <p style={{paddingBottom:"6px"}}>
      A <strong>class</strong> is a blueprint or prototype from which objects are created. It defines a set of 
      properties (attributes) and methods (functions or behaviors) that represent the characteristics and actions 
      of the objects. Classes encapsulate data and functionality, promoting modularity and code reuse.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of Classes</h3>
    <ul>
      <li>
        <strong>Encapsulation</strong>: Classes bundle data (attributes) and methods (functions) together, 
        restricting direct access to some components and exposing only what is necessary. This helps in protecting 
        the integrity of the data.
      </li><br />
      <li>
        <strong>Abstraction</strong>: Classes allow for complex behaviors and data structures to be represented 
        with a simplified interface, hiding unnecessary details from the user.
      </li><br />
      <li>
        <strong>Inheritance</strong>: Classes can inherit features from other classes, allowing for a 
        hierarchical organization of related classes and promoting code reuse.
      </li><br />
      <li>
        <strong>Polymorphism</strong>: Classes can define methods that can be overridden in derived classes, 
        enabling the same method name to invoke different behaviors depending on the object type.
      </li>
    </ul><br />

    <h3>Syntax</h3>
    <pre>
      <code>
{`public class ClassName
{
    // Fields (data members)
    private int field;

    // Properties
    public int Property { get; set; }

    // Constructor
    public ClassName(int value)
    {
        field = value;
    }

    // Methods
    public void Method()
    {
        // Code to execute
    }
}`}
      </code>
    </pre><br />

    <h3>Example</h3>
    <pre>
      <code>
{`public class Person
{
    // Properties
    public string Name { get; set; }
    public int Age { get; set; }

    // Constructor
    public Person(string name, int age)
    {
        Name = name;
        Age = age;
    }

    // Method
    public void Greet()
    {
        Console.WriteLine($"Hello, my name is {Name} and I am {Age} years old.");
    }
}`}
      </code>
    </pre><br />

    <h2>2. Objects</h2>

    <p style={{paddingBottom:"6px"}}>
      An <strong>object</strong> is an instance of a class. When a class is defined, it serves as a template, 
      but memory is allocated only when an object is created from that class. Objects represent specific 
      entities with their own states and behaviors as defined by their class.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of Objects</h3>
    <ul>
      <li>
        <strong>State</strong>: The values of an object’s properties at any given time. Each object can have 
        its own unique state.
      </li><br />
      <li>
        <strong>Behavior</strong>: The actions or functions that an object can perform, defined by the 
        methods in its class.
      </li><br />
      <li>
        <strong>Identity</strong>: Each object has a unique identity, which distinguishes it from other 
        objects, even if they belong to the same class.
      </li>
    </ul><br />

    <h3>Creating Objects</h3>
    <pre>
      <code>
{`// Creating an instance of the Person class
Person person1 = new Person("Alice", 30);
person1.Greet(); // Output: Hello, my name is Alice and I am 30 years old.`}
      </code>
    </pre><br />

    <h2>Summary</h2>

    <p>
      In conclusion, classes and objects are essential components of Object-Oriented Programming in .NET. 
      A class serves as a blueprint that defines the structure and behavior of objects, while objects are 
      instances of classes that encapsulate both data and functionality. Mastering the concepts of classes 
      and objects is crucial for building organized, maintainable, and scalable applications in .NET.
    </p>
  </div>
)}


{selectedChapter === 'chapter13' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Inheritance, Polymorphism, and Encapsulation </h1>

    <p>
      In .NET programming, Object-Oriented Programming (OOP) relies on three fundamental principles: 
      <strong>Inheritance</strong>, <strong>Polymorphism</strong>, and <strong>Encapsulation</strong>. 
      These concepts enable developers to create more flexible, reusable, and organized code.
    </p><br />

    <h2>1. Inheritance</h2>

    <p style={{paddingBottom:"6px"}}>
      <strong>Inheritance</strong> is a mechanism that allows one class (the derived class or child class) 
      to inherit the properties and methods of another class (the base class or parent class). This 
      promotes code reusability and establishes a hierarchical relationship between classes.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of Inheritance</h3>
    <ul>
      <li>
        **Reusability**: Inherited classes can use existing methods and properties, reducing code duplication.
      </li><br />
      <li>
        **Hierarchical Relationships**: Classes can be organized in a tree structure, making it easier to manage and understand.
      </li><br />
      <li>
        **Overriding**: Derived classes can provide specific implementations of methods defined in the base class.
      </li>
    </ul><br />

    <h3>Syntax</h3>
    <pre>
      <code>
{`public class BaseClass
{
    public void BaseMethod()
    {
        Console.WriteLine("Base method");
    }
}

public class DerivedClass : BaseClass
{
    public void DerivedMethod()
    {
        Console.WriteLine("Derived method");
    }

    public void OverriddenMethod()
    {
        Console.WriteLine("Overridden method in derived class");
    }
}`}
      </code>
    </pre><br />

    <h3>Example</h3>
    <pre>
      <code>
{`BaseClass baseObj = new BaseClass();
baseObj.BaseMethod(); // Output: Base method

DerivedClass derivedObj = new DerivedClass();
derivedObj.BaseMethod(); // Output: Base method (inherited)
derivedObj.DerivedMethod(); // Output: Derived method
`}
      </code>
    </pre><br />

    <h2>2. Polymorphism</h2>

    <p style={{paddingBottom:"6px"}}>
      <strong>Polymorphism</strong> is the ability of different classes to be treated as instances of the same 
      class through a common interface. It allows methods to do different things based on the object that 
      it is acting upon, enabling one interface to be used for a general class of actions.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Types of Polymorphism</h3>
    <ul>
      <li>
        **Compile-Time Polymorphism**: Achieved through method overloading (same method name with different parameters) 
        and operator overloading.
      </li><br />
      <li>
        **Run-Time Polymorphism**: Achieved through method overriding, where a derived class provides a specific 
        implementation of a method already defined in its base class.
      </li>
    </ul><br />

    <h3>Example of Run-Time Polymorphism</h3>
    <pre>
      <code>
{`public class BaseClass
{
    public virtual void Display()
    {
        Console.WriteLine("Display from BaseClass");
    }
}

public class DerivedClass : BaseClass
{
    public override void Display()
    {
        Console.WriteLine("Display from DerivedClass");
    }
}

BaseClass obj = new DerivedClass();
obj.Display(); // Output: Display from DerivedClass
`}
      </code>
    </pre><br />

    <h2>3. Encapsulation</h2>

    <p style={{paddingBottom:"6px"}}>
      <strong>Encapsulation</strong> is the bundling of data (attributes) and methods (functions) that operate 
      on the data into a single unit or class. It restricts direct access to some of the object’s components, 
      which is a means of preventing unintended interference and misuse of the methods and data.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of Encapsulation</h3>
    <ul>
      <li>
        **Data Hiding**: By restricting access to certain properties and methods, encapsulation helps protect 
        an object's internal state from external modification.
      </li><br />
      <li>
        **Controlled Access**: Through public methods (getters and setters), encapsulation allows controlled access 
        to the object's data.
      </li><br />
      <li>
        **Improved Maintainability**: Changes to the encapsulated code can be made independently of the code 
        that uses it, making the application easier to maintain.
      </li>
    </ul><br />

    <h3>Syntax</h3>
    <pre>
      <code>
{`public class BankAccount
{
    private decimal balance;

    public void Deposit(decimal amount)
    {
        if (amount > 0)
        {
            balance += amount;
        }
    }

    public decimal GetBalance()
    {
        return balance;
    }
}`}
      </code>
    </pre><br />

    <h3>Example</h3>
    <pre>
      <code>
{`BankAccount account = new BankAccount();
account.Deposit(100);
Console.WriteLine("Balance: " + account.GetBalance()); // Output: Balance: 100
`}
      </code>
    </pre><br />

    <h2>Summary</h2>

    <p>
      In summary, Inheritance, Polymorphism, and Encapsulation are three core principles of Object-Oriented 
      Programming in .NET. They work together to promote code reuse, flexibility, and data protection, allowing 
      developers to create robust and maintainable applications.
    </p>
  </div>
)}

   


{selectedChapter === 'chapter14' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Interfaces and Abstract Classes</h1>
    
    <p>
      In .NET programming, <strong>Interfaces</strong> and <strong>Abstract Classes</strong> are two key concepts in object-oriented programming (OOP) that help define contracts and provide a foundation for creating class hierarchies. Here’s a detailed definition and comparison of both:
    </p><br />
    
    <h2>Interfaces</h2>
    <p style={{paddingBottom:"6px"}}>
      An interface in .NET is a contract that defines a set of methods, properties, events, or indexers that a class or struct must implement. Interfaces provide a way to achieve polymorphism and define capabilities without enforcing a specific inheritance structure.
    </p>
    
    <h3>Key Characteristics of Interfaces:</h3>
    <ul>
      <li><strong>No Implementation</strong>: An interface cannot contain any implementation (i.e., no method bodies). It only defines signatures.</li><br />
      <li><strong>Multiple Inheritance</strong>: A class or struct can implement multiple interfaces, allowing for a form of multiple inheritance.</li><br />
      <li><strong>Members</strong>: All members of an interface are implicitly public and abstract.</li><br />
      <li><strong>Use Cases</strong>: Interfaces are often used when different classes may perform similar actions but do not share a common ancestor. For example, <code>IComparable</code>, <code>IDisposable</code>, etc.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
{`public interface IShape
{
    double Area();
    double Perimeter();
}

public class Circle : IShape
{
    public double Radius { get; set; }

    public double Area()
    {
        return Math.PI * Radius * Radius;
    }

    public double Perimeter()
    {
        return 2 * Math.PI * Radius;
    }
}`}
      </code>
    </pre><br />

    <h2>Abstract Classes</h2>
    <p style={{paddingBottom:"6px"}}>
      An abstract class in .NET is a class that cannot be instantiated on its own and is intended to be a base class for other classes. Abstract classes can provide both complete and incomplete implementations of methods.
    </p>
    
    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Abstract Classes:</h3>
    <ul>
      <li><strong>Partial Implementation</strong>: Abstract classes can contain both abstract methods (without implementation) and concrete methods (with implementation).</li><br />
      <li><strong>Single Inheritance</strong>: A class can inherit from only one abstract class (single inheritance), but it can implement multiple interfaces.</li><br />
      <li><strong>State and Fields</strong>: Abstract classes can have fields, properties, and constructors.</li><br />
      <li><strong>Use Cases</strong>: Abstract classes are useful when you want to share code among closely related classes and also define a common interface.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
{`public abstract class Animal
{
    public abstract void Speak(); // Abstract method

    public void Eat() // Concrete method
    {
        Console.WriteLine("Eating...");
    }
}

public class Dog : Animal
{
    public override void Speak()
    {
        Console.WriteLine("Bark");
    }
}

public class Cat : Animal
{
    public override void Speak()
    {
        Console.WriteLine("Meow");
    }
}`}
      </code>
    </pre><br />

    <h2>Comparison of Interfaces and Abstract Classes</h2>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Interfaces</th>
          <th>Abstract Classes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Implementation</td>
          <td>No implementation allowed</td>
          <td>Can provide both abstract and concrete methods</td>
        </tr>
        <tr>
          <td>Inheritance</td>
          <td>Can implement multiple interfaces</td>
          <td>Can inherit from only one abstract class</td>
        </tr>
        <tr>
          <td>Members</td>
          <td>All members are public</td>
          <td>Can have access modifiers (public, protected, etc.)</td>
        </tr>
        <tr>
          <td>Fields and State</td>
          <td>Cannot have fields</td>
          <td>Can have fields and state</td>
        </tr>
        <tr>
          <td>Use Cases</td>
          <td>For defining capabilities across unrelated classes</td>
          <td>For sharing code among related classes</td>
        </tr>
      </tbody>
    </table><br />

    <h2>Conclusion</h2>
    <p>
      Both interfaces and abstract classes play vital roles in .NET programming by enabling polymorphism and code reuse. The choice between using an interface or an abstract class depends on the specific requirements of your application and the relationships between the classes you are designing.
    </p>
  </div>
)}


{selectedChapter === 'chapter15' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Properties and Indexers</h1>
    
    <p>
      In .NET programming, <strong>Properties</strong> and <strong>Indexers</strong> are important features of object-oriented programming that enable encapsulation and provide a way to access and modify data within a class. Here's a detailed definition of both concepts:
    </p><br />
    
    <h2>Properties</h2>
    <p style={{paddingBottom:"6px"}}>
      Properties are special class members that provide a flexible mechanism to read, write, or compute the values of private fields. They act like public fields but encapsulate the underlying data to ensure that it can be accessed and modified safely.
    </p>
    
    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Properties:</h3>
    <ul>
      <li><strong>Encapsulation</strong>: Properties allow you to encapsulate the data of a class and provide controlled access to it, promoting data integrity.</li><br />
      <li><strong>Getters and Setters</strong>: Properties typically have <code>get</code> and <code>set</code> accessors. The <code>get</code> accessor retrieves the value, while the <code>set</code> accessor assigns a new value.</li><br />
      <li><strong>Computed Properties</strong>: You can define properties that do not directly correspond to a private field; these are known as computed properties.</li><br />
      <li><strong>Automatic Properties</strong>: In C#, you can use automatic properties to simplify property declarations without needing to explicitly define a backing field.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
{`public class Person
{
    private string name; // Backing field

    public string Name // Property
    {
        get { return name; } // Getter
        set { name = value; } // Setter
    }

    public int Age { get; set; } // Automatic property
}

// Usage
var person = new Person();
person.Name = "Alice"; // Set
Console.WriteLine(person.Name); // Get`}
      </code>
    </pre><br />

    <h2>Indexers</h2>
    <p style={{paddingBottom:"6px"}}>
      Indexers in .NET allow instances of a class or struct to be indexed in a similar manner to arrays. They provide a way to access elements in a collection-like fashion using an index.
    </p><br />
    
    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Indexers:</h3>
    <ul>
      <li><strong>Accessing Data</strong>: Indexers enable you to access elements in a class or struct using array-like syntax (e.g., <code>object[index]</code>).</li><br />
      <li><strong>Getters and Setters</strong>: Like properties, indexers also have <code>get</code> and <code>set</code> accessors to retrieve or assign values.</li><br />
      <li><strong>Multiple Parameters</strong>: Indexers can take multiple parameters, allowing for flexible access patterns.</li><br />
      <li><strong>Read-Only and Write-Only</strong>: You can define indexers to be read-only, write-only, or both.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
{`public class StringCollection
{
    private List<string> strings = new List<string>();

    public string this[int index] // Indexer
    {
        get { return strings[index]; } // Getter
        set { strings.Insert(index, value); } // Setter
    }

    public int Count
    {
        get { return strings.Count; }
    }
}

// Usage
var collection = new StringCollection();
collection[0] = "Hello"; // Using indexer to set value
Console.WriteLine(collection[0]); // Using indexer to get value`}
      </code>
    </pre><br />

    <h2>Comparison of Properties and Indexers</h2>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Properties</th>
          <th>Indexers</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Access Syntax</td>
          <td>Accessed using property name</td>
          <td>Accessed using array-like syntax (e.g., <code>object[index]</code>)</td>
        </tr>
        <tr>
          <td>Purpose</td>
          <td>Encapsulate a single value</td>
          <td>Encapsulate a collection of values</td>
        </tr>
        <tr>
          <td>Parameters</td>
          <td>No parameters (or one)</td>
          <td>Can have one or more parameters</td>
        </tr>
        <tr>
          <td>Getters/Setters</td>
          <td>Can define both getters and setters</td>
          <td>Can define both getters and setters</td>
        </tr>
        <tr>
          <td>Use Case</td>
          <td>Used for exposing class data</td>
          <td>Used for exposing data in a collection-like manner</td>
        </tr>
      </tbody>
    </table><br />

    <h2>Conclusion</h2>
    <p>
      Properties and indexers are essential for encapsulating data and providing intuitive access to class members in .NET programming. Properties focus on individual data members, while indexers provide a way to work with collections or sequences of data. Using these features effectively enhances the maintainability and usability of your classes.
    </p>
  </div>
)}



{selectedChapter === 'chapter16' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Delegates and Events</h1>

    <p>
      In .NET programming, <strong>Delegates</strong> and <strong>Events</strong> are essential features that enable callback functionality and notification patterns, respectively. Here’s a comprehensive definition of both concepts, including their characteristics, use cases, and examples:
    </p><br />

    <h2>Delegates</h2>
    <p style={{paddingBottom:"6px"}}>
      A <strong>delegate</strong> is a type that represents references to methods with a specific parameter list and return type. Delegates allow methods to be passed as parameters, enabling powerful callback functionality.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Delegates:</h3>
    <ul>
      <li><strong>Type-Safe:</strong> Delegates hold references to methods that match their signature (return type and parameters).</li><br />
      <li><strong>Multicast:</strong> A delegate can reference multiple methods, allowing it to invoke more than one method when called.</li><br />
      <li><strong>Encapsulation:</strong> Delegates encapsulate method references, providing a clean way to define callback methods.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre><code className="language-csharp">
{`public delegate void Notify(string message); // Delegate declaration

public class Process
{
    public event Notify ProcessCompleted; // Event based on the delegate

    public void StartProcess()
    {
        // Simulating some process
        Console.WriteLine("Process started...");
        // After completing the process, invoke the event
        OnProcessCompleted("Process completed successfully.");
    }

    protected virtual void OnProcessCompleted(string message)
    {
        ProcessCompleted?.Invoke(message); // Invoking the delegate
    }
}

// Usage
class Program
{
    static void Main(string[] args)
    {
        Process process = new Process();
        process.ProcessCompleted += MessageHandler; // Subscribing to the event
        process.StartProcess();
    }

    static void MessageHandler(string message)
    {
        Console.WriteLine(message); // Handling the message
    }
}`}
    </code></pre><br />

    <h2>Events</h2>
    <p style={{paddingBottom:"6px"}}>
      An <strong>event</strong> is a special kind of delegate that is used to provide notifications. Events enable a publish-subscribe pattern, allowing an object to notify other objects when something of interest occurs.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Events:</h3>
    <ul>
      <li><strong>Encapsulation:</strong> Events encapsulate delegate instances, providing a way for the publisher (the class that raises the event) to notify subscribers (the classes that listen for the event).</li><br />
      <li><strong>Access Modifiers:</strong> Events can have access modifiers to control their accessibility (e.g., public, private).</li><br />
      <li><strong>Unsubscription:</strong> Subscribers can unsubscribe from events, allowing for dynamic management of event listeners.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre><code className="language-csharp">
{`public class Button
{
    public event EventHandler Click; // Event declaration

    public void OnClick()
    {
        // Triggering the event
        Click?.Invoke(this, EventArgs.Empty);
    }
}

// Usage
class Program
{
    static void Main(string[] args)
    {
        Button button = new Button();
        button.Click += Button_Click; // Subscribing to the event
        button.OnClick(); // Simulating a button click
    }

    private static void Button_Click(object sender, EventArgs e)
    {
        Console.WriteLine("Button was clicked!"); // Event handler
    }
}`}
    </code></pre><br />

    <h2>Comparison of Delegates and Events</h2>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Delegates</th>
          <th>Events</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Purpose</td>
          <td>Represents a method reference</td>
          <td>Used for notification and the publish-subscribe model</td>
        </tr>
        <tr>
          <td>Access</td>
          <td>Can be invoked directly</td>
          <td>Must be invoked through an event handler</td>
        </tr>
        <tr>
          <td>Visibility</td>
          <td>Can be public, private, protected</td>
          <td>Typically public but controlled by access modifiers</td>
        </tr>
        <tr>
          <td>Multicast</td>
          <td>Can hold multiple method references</td>
          <td>Supports multicast (can invoke multiple handlers)</td>
        </tr>
        <tr>
          <td>Encapsulation</td>
          <td>General-purpose</td>
          <td>Specific mechanism for notification</td>
        </tr>
      </tbody>
    </table><br />

    <h2>Conclusion</h2>
    <p>
      Delegates and events are fundamental to the .NET programming model, enabling flexible and powerful designs for handling callbacks and notifications. They support the creation of responsive applications that can react to user actions and other events in a clean and maintainable way. By leveraging delegates and events, developers can implement event-driven programming patterns, leading to better separation of concerns and improved code organization.
    </p>
  </div>
)}


{selectedChapter === 'chapter17' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Lambda Expression and LINQ (Language Integrated Query)</h1>

    <h2>Lambda Expressions</h2>

    <p style={{paddingBottom:"6px"}}>
      A <strong>lambda expression</strong> is an anonymous function that can contain expressions and statements. It is used
      to create delegates or expression tree types. Lambda expressions are particularly useful for writing concise and
      readable code when working with methods that require a delegate as a parameter, such as LINQ queries.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Lambda Expressions:</h3>
    <ul>
      <li><strong>Syntax:</strong> The syntax consists of input parameters, the lambda operator (<code>&gt;=</code>), and an expression or statement block.</li><br />
      <li><strong>Conciseness:</strong> Lambda expressions enable a more succinct syntax compared to traditional anonymous methods.</li><br />
      <li><strong>Type Inference:</strong> The compiler can infer the types of the input parameters based on the context, allowing for cleaner code.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`using System;
using System.Collections.Generic;

class Program
{
    static void Main()
    {
        List<int> numbers = new List<int> { 1, 2, 3, 4, 5 };

        // Using a lambda expression to filter even numbers
        var evenNumbers = numbers.FindAll(n => n % 2 == 0);

        Console.WriteLine("Even numbers:");
        foreach (var number in evenNumbers)
        {
            Console.WriteLine(number);
        }
    }
}`}
      </code>
    </pre><br />

    <h2>LINQ (Language Integrated Query)</h2>

    <p style={{paddingBottom:"6px"}}>
      <strong>LINQ</strong> is a powerful query language integrated into .NET languages that allows you to perform queries
      on collections in a more readable and concise manner. It provides a consistent model for working with data across
      various data sources, including arrays, collections, databases, and XML.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of LINQ:</h3>
    <ul>
      <li><strong>Declarative Syntax:</strong> LINQ allows for writing queries in a declarative manner, which can improve code readability.</li><br />
      <li><strong>Strongly Typed:</strong> LINQ queries are checked at compile time, providing compile-time safety and better error detection.</li><br />
      <li><strong>Supports Query Composition:</strong> You can compose complex queries using method chaining or query syntax.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`using System;
using System.Collections.Generic;
using System.Linq;

class Program
{
    static void Main()
    {
        List<string> names = new List<string> { "Alice", "Bob", "Charlie", "David", "Eve" };

        // LINQ query to find names that start with 'A'
        var aNames = from name in names
                     where name.StartsWith("A")
                     select name;

        Console.WriteLine("Names that start with 'A':");
        foreach (var name in aNames)
        {
            Console.WriteLine(name);
        }

        // LINQ method syntax to find names with length greater than 3
        var longNames = names.Where(n => n.Length > 3);

        Console.WriteLine("\\nNames with more than 3 characters:");
        foreach (var name in longNames)
        {
            Console.WriteLine(name);
        }
    }
}`}
      </code>
    </pre><br />

    <h3>Summary of Differences</h3>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Lambda Expressions</th>
          <th>LINQ</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Definition</td>
          <td>Anonymous functions for creating delegates</td>
          <td>Query language integrated into .NET</td>
        </tr>
        <tr>
          <td>Syntax</td>
          <td><code>parameters {"=>"} expression</code></td>
          <td>Query syntax: <code>from ... where ... select ...</code> or method syntax</td>
        </tr>
        <tr>
          <td>Usage</td>
          <td>Used to define inline functions</td>
          <td>Used to query collections and data sources</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>Can be used with any delegate type</td>
          <td>Works with collections, databases, XML, etc.</td>
        </tr>
      </tbody>
    </table><br />

    <h2>Conclusion</h2>
    <p>
      Lambda expressions and LINQ provide powerful and flexible ways to work with data in .NET programming. Lambda
      expressions simplify the creation of delegates and make code more concise, while LINQ allows for expressive queries
      that can operate on a variety of data sources. Together, they enhance the capabilities of .NET developers, making
      code easier to read and maintain.
    </p>
  </div>
)}




{selectedChapter === 'chapter18' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Generics and Collections</h1>

    <h2>Generics</h2>

    <p style={{paddingBottom:"6px"}}><strong>Generics</strong> in .NET allow you to define classes, methods, interfaces, and delegates with a placeholder for the data type. This means you can create a single class or method that works with any data type while providing type safety without the need for casting or boxing.</p>

    <h3>Key Characteristics of Generics:</h3>
    <ul>
      <li><strong>Type Safety</strong>: Generics enforce compile-time type checking, reducing runtime errors associated with type casting.</li><br />
      <li><strong>Code Reusability</strong>: You can define a generic class or method once and use it with different types without code duplication.</li><br />
      <li><strong>Performance</strong>: Generics improve performance by eliminating the need for boxing and unboxing with value types, which can lead to better memory management and speed.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`
using System;

public class GenericBox<T>
{
    private T _item;

    public void Pack(T item)
    {
        _item = item;
    }

    public T Unpack()
    {
        return _item;
    }
}

class Program
{
    static void Main()
    {
        GenericBox<int> intBox = new GenericBox<int>();
        intBox.Pack(123);
        Console.WriteLine($"Packed integer: {intBox.Unpack()}");

        GenericBox<string> stringBox = new GenericBox<string>();
        stringBox.Pack("Hello, Generics!");
        Console.WriteLine($"Packed string: {stringBox.Unpack()}");
    }
}
        `}
      </code>
    </pre><br />

    <h2>Collections</h2>

    <p style={{paddingBottom:"6px"}}><strong>Collections</strong> in .NET are classes that provide a way to store, manage, and manipulate groups of related objects. The .NET Framework provides several types of collections, such as lists, dictionaries, queues, stacks, and arrays, to handle data more effectively.</p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Collections:</h3>
    <ul>
      <li><strong>Dynamic Sizing</strong>: Many collections (like <code>List&lt;T&gt;</code>) can grow or shrink as needed, which makes them more flexible than arrays.</li><br />
      <li><strong>Built-in Methods</strong>: Collections provide built-in methods for adding, removing, and searching for elements, simplifying data manipulation.</li><br />
      <li><strong>Diverse Types</strong>: Different types of collections are optimized for specific use cases, such as ordered lists, key-value pairs, and queues.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Common Collection Types:</h3>
    <ul>
      <li><strong>List&lt;T&gt;</strong>: A resizable array that can hold a list of objects.</li><br />
      <li><strong>Dictionary&lt;TKey, TValue&gt;</strong>: A collection of key-value pairs, allowing fast lookups by key.</li><br />
      <li><strong>Queue&lt;T&gt;</strong>: A first-in, first-out (FIFO) collection.</li><br />
      <li><strong>Stack&lt;T&gt;</strong>: A last-in, first-out (LIFO) collection.</li>
    </ul><br />

    <h3>Example:</h3>
    <pre>
      <code>
        {`
using System;
using System.Collections.Generic;

class Program
{
    static void Main()
    {
        // Using List<T>
        List<string> fruits = new List<string> { "Apple", "Banana", "Cherry" };
        fruits.Add("Date");
        Console.WriteLine("Fruits: " + string.Join(", ", fruits));

        // Using Dictionary<TKey, TValue>
        Dictionary<int, string> students = new Dictionary<int, string>
        {
            { 1, "Alice" },
            { 2, "Bob" }
        };
        Console.WriteLine($"Student ID 1: {students[1]}");

        // Using Queue<T>
        Queue<string> queue = new Queue<string>();
        queue.Enqueue("First");
        queue.Enqueue("Second");
        Console.WriteLine($"Dequeued: {queue.Dequeue()}");

        // Using Stack<T>
        Stack<int> stack = new Stack<int>();
        stack.Push(10);
        stack.Push(20);
        Console.WriteLine($"Popped: {stack.Pop()}");
    }
}
        `}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>Generics and collections are fundamental features in .NET programming that enhance code flexibility, type safety, and performance. Generics allow for the creation of reusable components that work with various data types, while collections provide robust data structures for efficient data management. By understanding and utilizing these features, developers can write cleaner, more efficient, and maintainable code.</p>
  </div>
)}


{selectedChapter === 'chapter19' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Asynchronous Programming (async/await)</h1>
   
    <p>
      Asynchronous programming in .NET, primarily utilizing the <code>async</code> and <code>await</code> keywords, allows developers to write non-blocking code that can perform multiple operations concurrently. This approach is especially useful for improving the responsiveness of applications, particularly in scenarios that involve I/O-bound operations such as file access, web requests, or database queries.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of Asynchronous Programming</h2>

    <ol>
      <li>
        <strong>Asynchronous Method</strong>: An asynchronous method is defined by using the <code>async</code> modifier in the method signature. It allows the method to run asynchronously, enabling it to perform work without blocking the calling thread.
      </li><br />
      <li>
        <strong>Await Keyword</strong>: The <code>await</code> keyword is used within an asynchronous method to pause execution until the awaited task is completed. It allows the method to yield control back to the caller, thereby improving responsiveness.
      </li><br />
      <li>
        <strong>Task-Based Asynchronous Pattern (TAP)</strong>: .NET uses the Task-based Asynchronous Pattern, which is centered around the <code>Task</code> class. A <code>Task</code> represents an ongoing operation, and you can await its completion.
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of Asynchronous Programming</h2>

    <ul>
      <li><strong>Responsiveness</strong>: Asynchronous methods keep the application responsive by allowing the UI thread to remain active while performing time-consuming operations.</li><br />
      <li><strong>Scalability</strong>: Asynchronous programming can handle more requests with fewer threads, making it more efficient, especially in server-side applications.</li><br />
      <li><strong>Simplicity</strong>: Using <code>async</code> and <code>await</code> simplifies writing asynchronous code compared to older methods, such as callbacks or events.</li>
    </ul><br />

    <h2>Example</h2>

    <p>Here’s a simple example demonstrating asynchronous programming using <code>async</code> and <code>await</code> in .NET:</p>

    <pre>
      <code>
        {`
using System;
using System.Net.Http;
using System.Threading.Tasks;

class Program
{
    static async Task Main(string[] args)
    {
        Console.WriteLine("Starting async call...");

        // Calling the asynchronous method
        string result = await FetchDataFromWebAsync("https://jsonplaceholder.typicode.com/posts/1");

        Console.WriteLine("Data fetched:");
        Console.WriteLine(result);
    }

    // Asynchronous method to fetch data
    static async Task<string> FetchDataFromWebAsync(string url)
    {
        using (HttpClient client = new HttpClient())
        {
            // Awaiting the asynchronous operation
            string response = await client.GetStringAsync(url);
            return response;
        }
    }
}
        `}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Explanation of the Example</h2>

    <ol>
      <li>
        <code>async Task Main(string[] args)</code>: The entry point of the application is defined as an asynchronous method. This allows the use of <code>await</code> within it.
      </li><br />
      <li>
        <code>await FetchDataFromWebAsync(...)</code>: The <code>await</code> keyword is used to call the asynchronous method <code>FetchDataFromWebAsync</code>. Execution of the method pauses until the task completes, allowing other work to be done in the meantime.
      </li><br />
      <li>
        <code>HttpClient</code>: The <code>HttpClient</code> class is used to send an HTTP GET request asynchronously. The <code>GetStringAsync</code> method is awaited to retrieve the response.
      </li><br />
      <li>
        <strong>Non-blocking</strong>: While waiting for the web request to complete, the application remains responsive and can handle other tasks.
      </li>
    </ol><br />

    <h2>Conclusion</h2>

    <p>
      Asynchronous programming in .NET using <code>async</code> and <code>await</code> provides a powerful way to handle operations that would otherwise block the execution flow, improving the performance and responsiveness of applications. By leveraging these keywords, developers can create cleaner and more efficient code that effectively manages asynchronous operations.
    </p>
  </div>
)}



{selectedChapter === 'chapter20' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Overview of ASP.NET Core</h1>

    <p>
      <strong>ASP.NET Core</strong> is a cross-platform, high-performance framework for building modern, cloud-based, Internet-connected applications. It is a redesign of the original ASP.NET framework, designed to provide a more modular, lightweight, and flexible approach to web development.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of ASP.NET Core:</h3>

    <ol>
      <li><strong>Cross-Platform:</strong> ASP.NET Core can run on Windows, macOS, and Linux, allowing developers to create applications that can be deployed on various operating systems.</li><br />
      <li><strong>Modular Framework:</strong> The framework is composed of a set of NuGet packages, enabling developers to include only the components they need for their application. This modularity leads to smaller applications and better performance.</li><br />
      <li><strong>Performance:</strong> ASP.NET Core is optimized for speed and efficiency, offering significant performance improvements over the previous versions of ASP.NET. It includes a lightweight request pipeline and uses asynchronous programming for better scalability.</li><br />
      <li><strong>Dependency Injection:</strong> ASP.NET Core has built-in support for dependency injection, which promotes loose coupling and enhances testability in applications.</li><br />
      <li><strong>Unified MVC and Web API Frameworks:</strong> ASP.NET Core combines the capabilities of MVC (Model-View-Controller) and Web API into a single framework, making it easier to develop both web applications and RESTful APIs.</li><br />
      <li><strong>Flexible Hosting:</strong> ASP.NET Core applications can be hosted on various platforms, including IIS, Nginx, Docker, and self-hosted scenarios. This flexibility allows developers to choose the best hosting environment for their application.</li><br />
      <li><strong>Enhanced Security:</strong> ASP.NET Core includes features like built-in authentication and authorization, protection against cross-site request forgery (CSRF), and improved data protection APIs.</li><br />
      <li><strong>Integrated Development Environment (IDE) Support:</strong> ASP.NET Core has strong support in development tools like Visual Studio, Visual Studio Code, and JetBrains Rider, providing a rich development experience with features like debugging, IntelliSense, and project templates.</li>
    </ol><br />

    <h3>Application Structure</h3>

    <p>
      An ASP.NET Core application typically follows a standard structure, which includes:
    </p>
    <ul>
      <li><strong>Startup Class:</strong> This class configures services and the application request pipeline. It contains methods like <code>ConfigureServices</code> for configuring services and <code>Configure</code> for setting up the middleware pipeline.</li><br />
      <li><strong>Middleware:</strong> ASP.NET Core uses a middleware pipeline to handle requests and responses. Middleware components can be added to process requests, handle exceptions, serve static files, and more.</li><br />
      <li><strong>Controllers and Views:</strong> In an MVC application, controllers manage user input and return views. Views are typically implemented using Razor syntax, which combines HTML with C# code.</li><br />
      <li><strong>Configuration:</strong> ASP.NET Core applications are configurable via JSON files, environment variables, command-line arguments, and other sources, enabling flexible application settings.</li>
    </ul><br />

    <h3>Getting Started</h3>

    <p style={{paddingBottom:"6px"}}>
      To create a simple ASP.NET Core application, you typically use the .NET CLI or Visual Studio. Here's a quick example of creating a new ASP.NET Core Web Application:
    </p>

    <h4>Using the .NET CLI:</h4>
    <pre>
      <code>
        dotnet new webapp -n MyWebApp
        cd MyWebApp
        dotnet run
      </code>
    </pre><br />

    <h4>Using Visual Studio:</h4>
    <ul>
      <li>Create a new project and select "ASP.NET Core Web Application."</li><br />
      <li>Choose a template (e.g., Web Application, API).</li><br />
      <li>Configure your project settings and create the project.</li>
    </ul><br />

    <h3>Conclusion</h3>

    <p>
      ASP.NET Core is a powerful framework for building modern web applications and APIs. Its cross-platform capabilities, performance optimizations, and support for best practices like dependency injection make it an ideal choice for developers looking to create scalable and maintainable applications. With its modular architecture and extensive tooling support, ASP.NET Core provides a robust foundation for building cloud-based solutions that meet the demands of today’s web environment.
    </p>
  </div>
)}



{selectedChapter === 'chapter21' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>MVC Architecture (Model-View-Controller)</h1>

    <p>
      <strong>MVC Architecture</strong> is a design pattern commonly used in web applications to separate an application into three interconnected components: Model, View, and Controller. This separation helps manage complexity, facilitates easier maintenance, and enhances scalability and testability. In .NET programming, particularly with ASP.NET Core, MVC provides a robust framework for building web applications.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Components of MVC Architecture</h3>

    <ol>
      <li>
        <strong>Model</strong>:
        <ul>
          <li>The Model represents the application's data and business logic. It is responsible for managing the data, including retrieving, storing, and updating it.</li>
          <li>Models can also include validation logic to ensure data integrity.</li>
          <li>In ASP.NET Core, models are often implemented using classes that represent the structure of the data, commonly using Entity Framework for data access.</li>
        </ul>
      </li><br />
      <li>
        <strong>View</strong>:
        <ul>
          <li>The View is responsible for displaying the data to the user and providing a user interface. It presents the information in a format that is easy for users to understand and interact with.</li>
          <li>Views are usually implemented using Razor syntax, which allows embedding C# code within HTML to dynamically generate content based on the Model's state.</li>
          <li>The View subscribes to events from the Controller and updates its display in response.</li>
        </ul>
      </li><br />
      <li>
        <strong>Controller</strong>:
        <ul>
          <li>The Controller acts as an intermediary between the Model and the View. It handles user input, interacts with the Model, and determines which View to display.</li>
          <li>When a user makes a request (e.g., clicking a button or submitting a form), the Controller receives the request, processes the data (often involving calls to the Model), and then returns the appropriate View.</li>
          <li>Controllers are implemented as classes that inherit from the <code>Controller</code> base class in ASP.NET Core, with action methods defined to handle various routes.</li>
        </ul>
      </li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Benefits of MVC Architecture</h3>

    <ul>
      <li><strong>Separation of Concerns</strong>: Each component has a distinct responsibility, making the application easier to manage and scale.</li><br />
      <li><strong>Testability</strong>: With a clear separation, unit testing individual components becomes more straightforward.</li><br />
      <li><strong>Maintainability</strong>: Changes in one part of the application (like the user interface) do not directly impact the other parts (like data handling), allowing for easier updates and modifications.</li><br />
      <li><strong>Reusability</strong>: Components can be reused across different applications or different parts of the same application.</li>
    </ul><br />

    <h3>Example of MVC in ASP.NET Core</h3>

    <p>Here’s a simple example to illustrate how MVC architecture works in an ASP.NET Core application:</p>

    <h4>Model (Product.cs)</h4>
    <pre><code>{`
public class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
}
    </code></pre>

    <h4>Controller (ProductController.cs)</h4>
    <pre><code>
using Microsoft.AspNetCore.Mvc;
using System.Collections.Generic;

public class ProductController : Controller
{
    private static List<Product> products = new List<Product>
    {
        new Product { Id = 1, Name = "Product A", Price = 10.99M },
        new Product { Id = 2, Name = "Product B", Price = 15.99M },
    };

    public IActionResult Index()
    {
        return View(products); // Passing the list of products to the view
    }
}
     `} </code></pre><br />

    <h4>View (Index.cshtml)</h4>
    <pre><code>{`
@model IEnumerable<Product>

<h1>Product List</h1>
<table>
    <thead>
        <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Price</th>
        </tr>
    </thead>
    <tbody>
        @foreach (var product in Model)
        {
            <tr>
                <td>@product.Id</td>
                <td>@product.Name</td>
                <td>@product.Price</td>
            </tr>
        }
    </tbody>
</table>
     `} </code></pre><br />

    <h3>Conclusion</h3>
    <p>
      The MVC architecture in .NET programming provides a structured approach to building web applications. By separating the application into Model, View, and Controller components, developers can create scalable, maintainable, and testable applications. This design pattern is fundamental to ASP.NET Core, facilitating modern web development practices and improving the overall development experience.
    </p>
  </div>
)}

{selectedChapter === 'chapter22' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>RESTful API Development with ASP.NET Core</h1>

    <p>
      <strong>RESTful API</strong> (Representational State Transfer) is an architectural style for designing networked applications. It relies on stateless, client-server communication, typically using HTTP requests to manipulate data. ASP.NET Core provides a powerful framework for building RESTful APIs that can be consumed by various clients, including web browsers, mobile apps, and other services.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts of RESTful APIs</h3>
    <ol>
      <li>
        <strong>Resources</strong>: In REST, resources are identified by URIs (Uniform Resource Identifiers). Each resource can be represented in various formats, such as JSON or XML.
      </li><br />
      <li>
        <strong>HTTP Methods</strong>: RESTful APIs use standard HTTP methods to perform operations:
        <ul>
          <li><strong>GET</strong>: Retrieve data from the server.</li><br />
          <li><strong>POST</strong>: Create a new resource on the server.</li><br />
          <li><strong>PUT</strong>: Update an existing resource.</li><br />
          <li><strong>DELETE</strong>: Remove a resource.</li><br />
        </ul>
      </li>
      <li>
        <strong>Statelessness</strong>: Each API request from a client contains all the information needed to process that request. The server does not store any client context between requests, ensuring scalability and reliability.
      </li><br />
      <li>
        <strong>Representations</strong>: Resources can have multiple representations, such as JSON or XML. The client can specify the desired format using the <code>Accept</code> header in the request.
      </li><br />
      <li>
        <strong>Status Codes</strong>: RESTful APIs use standard HTTP status codes to indicate the success or failure of an API request (e.g., 200 OK, 201 Created, 404 Not Found, 500 Internal Server Error).
      </li>
    </ol><br />

    <h3>Building a RESTful API with ASP.NET Core</h3>
    <p style={{paddingBottom:"6px"}}>
      ASP.NET Core provides built-in features to facilitate the development of RESTful APIs. Here’s a high-level overview of the steps involved:
    </p>

    <h4>1. Create a New ASP.NET Core Web API Project</h4>
    <p>You can create a new project using the .NET CLI or Visual Studio.</p>
    <pre><code>dotnet new webapi -n MyApi
cd MyApi
    </code></pre><br />

    <h4>2. Define the Model</h4>
    <p>Create a model class that represents the resource. For example, a <code>Product</code> model might look like this:</p>
    <pre><code>{`
public class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
}
    `}</code></pre><br />

    <h4>3. Create a Data Context</h4>
    <p>Use Entity Framework Core to create a data context for accessing the database.</p>
    <pre><code>{`
public class ApiDbContext : DbContext
{
    public ApiDbContext(DbContextOptions<ApiDbContext> options) : base(options) { }

    public DbSet&lt;Product&gt; Products { get; set; }
}
`}</code></pre><br />

    <h4>4. Set Up the Controller</h4>
    <p>Create a controller to handle HTTP requests related to the resource. The controller will use dependency injection to access the data context.</p>
    <pre><code>{`
using Microsoft.AspNetCore.Mvc;
using System.Collections.Generic;
using System.Linq;

[Route("api/[controller]")]
[ApiController]
public class ProductsController : ControllerBase
{
    private readonly ApiDbContext _context;

    public ProductsController(ApiDbContext context)
    {
        _context = context;
    }

    // GET: api/products
    [HttpGet]
    public ActionResult&lt;IEnumerable&lt;Product&gt;&gt; GetProducts()
    {
        return _context.Products.ToList();
    }

    // GET: api/products/{id}
    [HttpGet("{id}")]
    public ActionResult&lt;Product&gt; GetProduct(int id)
    {
        var product = _context.Products.Find(id);
        if (product == null)
        {
            return NotFound();
        }
        return product;
    }

    // POST: api/products
    [HttpPost]
    public ActionResult&lt;Product&gt; PostProduct(Product product)
    {
        _context.Products.Add(product);
        _context.SaveChanges();

        return CreatedAtAction(nameof(GetProduct), new {{ id = product.Id }}, product);
    }

    // PUT: api/products/{id}
    [HttpPut("{id}")]
    public IActionResult PutProduct(int id, Product product)
    {
        if (id != product.Id)
        {
            return BadRequest();
        }

        _context.Entry(product).State = EntityState.Modified;
        _context.SaveChanges();

        return NoContent();
    }

    // DELETE: api/products/{id}
    [HttpDelete("{id}")]
    public IActionResult DeleteProduct(int id)
    {
        var product = _context.Products.Find(id);
        if (product == null)
        {
            return NotFound();
        }

        _context.Products.Remove(product);
        _context.SaveChanges();

        return NoContent();
    }
}
`}</code></pre><br />

    <h4>5. Configure Dependency Injection and Middleware</h4>
    <p>In the <code>Startup.cs</code> file, configure services for the database context and add middleware for routing and JSON serialization.</p>
    <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddDbContext&lt;ApiDbContext&gt;(options =>
        options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));
    services.AddControllers();
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage();
    }

    app.UseRouting();
    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllers();
    });
}
 `} </code></pre><br />

    <h4>6. Testing the API</h4>
    <p>You can test the API using tools like Postman or curl to send HTTP requests to your API endpoints.</p>

    <h3>Conclusion</h3>
    <p>
      Building a RESTful API with ASP.NET Core provides a scalable and maintainable way to expose your application’s functionality. With built-in support for routing, model binding, and dependency injection, ASP.NET Core simplifies the process of creating robust APIs that adhere to REST principles. By following best practices and leveraging the features of ASP.NET Core, developers can create APIs that serve as the backbone for modern web and mobile applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter23' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Middleware and Dependency Injection</h1>

    <h2>Middleware in .NET Programming</h2>
    <p>
      <strong>Middleware</strong> is a crucial concept in ASP.NET Core that refers to software components assembled into an application pipeline to handle requests and responses. Each middleware component is responsible for processing an incoming request, optionally modifying it, and then passing it to the next component in the pipeline. Middleware can perform various tasks, including:
    </p>
    <ul>
      <li><strong>Request logging</strong>: Recording details about incoming requests.</li><br />
      <li><strong>Authentication</strong>: Verifying the identity of the user making the request.</li><br />
      <li><strong>Authorization</strong>: Determining whether the authenticated user has permission to access a resource.</li><br />
      <li><strong>Error handling</strong>: Catching exceptions and returning appropriate error responses.</li><br />
      <li><strong>Response formatting</strong>: Modifying the response before it is sent back to the client.</li><br />
      <li><strong>Routing</strong>: Directing requests to the appropriate handler based on the request path and method.</li>
    </ul><br />

    <h3>How Middleware Works</h3>
    <p>
      In ASP.NET Core, middleware is configured in the <code>Startup.cs</code> file within the <code>Configure</code> method. The order in which middleware components are added is significant because it defines the request processing pipeline. For example:
    </p>
    <pre><code className="language-csharp">{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage(); // Error handling in development
    }
    else
    {
        app.UseExceptionHandler("/Home/Error"); // Error handling in production
        app.UseHsts(); // HTTP Strict Transport Security
    }

    app.UseHttpsRedirection(); // Redirect HTTP requests to HTTPS
    app.UseStaticFiles(); // Serve static files
    app.UseRouting(); // Enable routing

    app.UseAuthentication(); // Authentication middleware
    app.UseAuthorization(); // Authorization middleware

    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllers(); // Map controller routes
    });
}
`}</code></pre><br />

    <h2>Dependency Injection in .NET Programming</h2>
    <p>
      <strong>Dependency Injection (DI)</strong> is a design pattern used to achieve Inversion of Control (IoC) between classes and their dependencies. In ASP.NET Core, DI is a fundamental part of the framework that allows developers to inject dependencies into classes rather than creating them internally. This promotes loose coupling, making the application easier to test, maintain, and extend.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Benefits of Dependency Injection</h3>
    <ul>
      <li><strong>Decoupling</strong>: Classes are less dependent on specific implementations, making it easier to swap out dependencies for testing or replacing implementations.</li><br />
      <li><strong>Testability</strong>: With DI, you can easily mock dependencies in unit tests, allowing for more effective testing.</li><br />
      <li><strong>Configuration Management</strong>: Dependencies can be configured in a central location, making it easier to manage and change configurations.</li>
    </ul><br />

    <h3>How Dependency Injection Works</h3>
    <p>
      In ASP.NET Core, DI is configured in the <code>Startup.cs</code> file within the <code>ConfigureServices</code> method. You can register services with different lifetimes: <strong>Transient</strong>, <strong>Scoped</strong>, and <strong>Singleton</strong>. Here's an example:
    </p>
    <pre><code className="language-csharp">{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddControllers(); // Add MVC controllers

    // Registering services with different lifetimes
    services.AddTransient<IProductService, ProductService>(); // New instance for each request
    services.AddScoped<ICustomerService, CustomerService>(); // One instance per request
    services.AddSingleton<ILoggingService, LoggingService>(); // Single instance for the entire application
}
 `} </code></pre><br />

    <p>
      Once services are registered, they can be injected into controllers or other services via constructor injection:
    </p>
    <pre><code className="language-csharp">{`
public class ProductsController : ControllerBase
{
    private readonly IProductService _productService;

    public ProductsController(IProductService productService)
    {
        _productService = productService; // Dependency injected
    }

    // Controller actions using _productService
}
   `} </code></pre><br />

    <h3>Conclusion</h3>
    <p>
      Middleware and Dependency Injection are foundational concepts in .NET programming, particularly in ASP.NET Core. Middleware allows for the modular handling of HTTP requests and responses in a pipeline, while Dependency Injection facilitates loose coupling and enhances the testability of applications. Together, they contribute to building scalable, maintainable, and efficient web applications.
    </p>
  </div>
)}

{selectedChapter === 'chapter24' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Entity Framework Core</h1>

    <p>
      <strong>Entity Framework Core (EF Core)</strong> is an open-source, lightweight, and extensible version of the popular Entity Framework, designed to work with .NET Core applications. EF Core serves as an Object-Relational Mapper (ORM), allowing developers to interact with databases using .NET objects, which abstracts the underlying database interactions and facilitates data access in a more intuitive way.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of EF Core</h3>
    <ol>
      <li><strong>Cross-Platform</strong>: EF Core is built to support .NET Core, enabling applications to run on various operating systems, including Windows, macOS, and Linux.</li><br />
      <li><strong>Performance</strong>: EF Core has been optimized for performance, making it suitable for high-performance applications. It features improved query generation and caching mechanisms.</li><br />
      <li><strong>LINQ Support</strong>: EF Core allows developers to use Language Integrated Query (LINQ) to construct database queries using C# syntax. This provides compile-time checking and IntelliSense support in IDEs.</li><br />
      <li><strong>Migrations</strong>: EF Core supports database migrations, which allows developers to incrementally update the database schema while preserving existing data. This feature makes it easier to manage changes to the database structure over time.</li><br />
      <li><strong>Change Tracking</strong>: EF Core automatically tracks changes made to the entities, allowing developers to save only the modified data back to the database.</li><br />
      <li><strong>Support for Multiple Database Providers</strong>: EF Core can work with various databases, including SQL Server, SQLite, PostgreSQL, MySQL, and more. This flexibility allows developers to choose the best database solution for their application.</li><br />
      <li><strong>No Tracking Queries</strong>: Developers can execute queries without tracking the changes to improve performance for read-only scenarios.</li><br />
      <li><strong>Concurrency Control</strong>: EF Core supports optimistic concurrency, allowing multiple users to work with the same data simultaneously while managing potential conflicts.</li>
    </ol><br />

    <h3>Getting Started with EF Core</h3>
    <p>To start using EF Core in a .NET application, follow these steps:</p>

    <ol>
      <li>
        <strong>Install EF Core</strong>: You can install EF Core via NuGet. Use the following command in the Package Manager Console or your terminal:
        <pre><code>dotnet add package Microsoft.EntityFrameworkCore.SqlServer</code></pre>
      </li><br />
      <li>
        <strong>Create a Model</strong>: Define a model class that represents a table in the database. For example, a <code>Product</code> model:
        <pre><code>{`
public class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
}
        `}</code></pre>
      </li><br />
      <li>
        <strong>Set Up a Database Context</strong>: Create a context class that inherits from <code>DbContext</code> and includes <code>DbSet</code> properties for each model:
        <pre><code>{`
public class AppDbContext : DbContext
{
    public AppDbContext(DbContextOptions<AppDbContext> options) : base(options) { }

    public DbSet<Product> Products { get; set; }
}
`}</code></pre>
      </li><br />
      <li>
        <strong>Configure Services</strong>: In the <code>Startup.cs</code> file, configure EF Core in the <code>ConfigureServices</code> method:
        <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddDbContext<AppDbContext>(options =>
        options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));
    services.AddControllers();
}
 `} </code></pre>
      </li><br />
      <li>
        <strong>Migrations</strong>: To create a database schema based on the model, you can use EF Core migrations:
        <pre><code>dotnet ef migrations add InitialCreate
dotnet ef database update</code></pre>
      </li><br />
      <li>
        <strong>Data Access</strong>: You can now access data using the database context:
        <pre><code>{`
public class ProductsController : ControllerBase
{
    private readonly AppDbContext _context;

    public ProductsController(AppDbContext context)
    {
        _context = context;
    }

    [HttpGet]
    public async Task<ActionResult<IEnumerable<Product>>> GetProducts()
    {
        return await _context.Products.ToListAsync();
    }
}
 `} </code></pre>
      </li>
    </ol><br />

    <h3>Conclusion</h3>
    <p>
      Entity Framework Core simplifies data access in .NET applications by providing a powerful, flexible, and high-performance ORM. Its support for LINQ, migrations, and change tracking, along with its ability to work with multiple database providers, makes it an essential tool for developers building modern web applications with .NET. By using EF Core, developers can focus on business logic while letting the framework handle data interactions efficiently.
    </p>
  </div>
)}


{selectedChapter === 'chapter25' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Code First vs. Database First Approach</h1>
  
    <p>
      In <strong>.NET Programming</strong>, particularly when working with <strong>Entity Framework (EF)</strong>, there are two primary approaches for database management and development: <strong>Code First</strong> and <strong>Database First</strong>. Both approaches serve different needs and are useful in different scenarios, depending on whether the development starts with the code or an existing database.
    </p><br />

    <h3>1. Code First Approach</h3>
    <p style={{paddingBottom:"6px"}}>
      The <strong>Code First</strong> approach allows developers to define the database structure using C# classes and then generate the database schema based on these classes. This approach is especially useful when you're starting a project from scratch and want to define your domain models first, without worrying about the underlying database at the beginning.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Characteristics:</h4>
    <ul>
      <li><strong>Models Drive the Database</strong>: The database schema is generated based on the C# classes you create, called <em>entity classes</em>.</li><br />
      <li><strong>Migrations</strong>: Code First supports migrations, which help keep the database schema in sync with code changes.</li><br />
      <li><strong>Full Control Over the Schema</strong>: Developers have more control over the schema as they directly manage the C# classes and apply configuration via <em>Data Annotations</em> or <em>Fluent API</em>.</li><br />
      <li><strong>Ideal for Greenfield Projects</strong>: This approach works well for new projects where the database doesn’t exist, and the design evolves from the code.</li>
    </ul><br />

    <h4>Example Workflow for Code First:</h4>
    <ol>
      <li><strong>Define Entity Classes:</strong>
        <pre>
          <code>
{`public class Product {
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
}`}
          </code>
        </pre>
      </li><br />
      <li><strong>Create a DbContext:</strong>
        <pre>
          <code>
{`public class AppDbContext : DbContext {
    public DbSet<Product> Products { get; set; }
}`}
          </code>
        </pre>
      </li><br />
      <li><strong>Apply Migrations:</strong>
        <pre>
          <code>
{`dotnet ef migrations add InitialCreate`}
          </code>
        </pre>
        <pre>
          <code>
{`dotnet ef database update`}
          </code>
        </pre>
      </li>
    </ol><br />

    <h3>2. Database First Approach</h3>
    <p style={{paddingBottom:"6px"}}>
      The <strong>Database First</strong> approach is used when the database schema already exists, and you want to generate corresponding models and context classes based on the existing database structure. This approach is ideal when working with legacy databases or when the database is managed independently.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Characteristics:</h4>
    <ul>
      <li><strong>Database Drives the Models</strong>: The database schema exists first, and the entity classes and <code>DbContext</code> are generated from the database tables and relationships.</li><br />
      <li><strong>Scaffolding</strong>: EF Core provides scaffolding tools to generate models and context from the database.</li><br />
      <li><strong>Easier for Legacy Databases</strong>: Ideal for projects where the database is already in place, and the goal is to map the existing tables to C# objects.</li><br />
      <li><strong>Less Control Over Schema</strong>: The schema is controlled by the database, offering less flexibility compared to Code First.</li>
    </ul><br />

    <h4>Example Workflow for Database First:</h4>
    <ol>
      <li><strong>Scaffold the Database:</strong>
        <pre>
          <code>
{`dotnet ef dbcontext scaffold "YourConnectionString" Microsoft.EntityFrameworkCore.SqlServer`}
          </code>
        </pre>
      </li>
      <li><strong>Work with the Generated Models:</strong> Begin using the models and context for querying the database or manipulating data.</li>
    </ol><br />

    <h3>Comparison: Code First vs. Database First</h3>
    <table className={style.table}>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Code First</th>
          <th>Database First</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Starting Point</td>
          <td>Starts with C# classes (models)</td>
          <td>Starts with an existing database</td>
        </tr>
        <tr>
          <td>Schema Control</td>
          <td>Full control over schema through code</td>
          <td>Database controls the schema</td>
        </tr>
        <tr>
          <td>Migrations</td>
          <td>Supports migrations to update the database</td>
          <td>Not typically used</td>
        </tr>
        <tr>
          <td>Use Case</td>
          <td>Ideal for new projects</td>
          <td>Ideal for legacy databases</td>
        </tr>
        <tr>
          <td>Scaffolding</td>
          <td>Not needed</td>
          <td>Scaffolding generates models from the database</td>
        </tr>
        <tr>
          <td>Flexibility</td>
          <td>More flexibility with class design</td>
          <td>Limited flexibility; models must conform to the database</td>
        </tr>
        <tr>
          <td>Developer Experience</td>
          <td>Focuses on domain model first</td>
          <td>Focuses on mapping database structure to the code</td>
        </tr>
      </tbody>
    </table><br />

    <h3>Which Approach to Choose?</h3>
    <ul>
      <li><strong>Use Code First</strong> if you are building a new application from scratch and want the database to evolve from the code.</li><br />
      <li><strong>Use Database First</strong> if you're working with an existing or legacy database and need to map it to C# code.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Both <strong>Code First</strong> and <strong>Database First</strong> approaches provide powerful ways to interact with databases using Entity Framework Core in .NET programming. The choice between the two depends on project requirements and whether the database or code is the primary driver. Understanding these approaches enables developers to choose the best strategy for database interaction and design.
    </p>
  </div>
)}


{selectedChapter === 'chapter26' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Performing CRUD Operations</h1>
    
    <p>
      In <strong>.NET Programming</strong>, particularly when working with <strong>Entity Framework Core (EF Core)</strong> or other data access technologies, <strong>CRUD operations</strong> refer to the four fundamental operations required to interact with data in a database:
    </p>
    <ul>
      <li><strong>C</strong>: Create (Insert)</li>
      <li><strong>R</strong>: Read (Select)</li>
      <li><strong>U</strong>: Update</li>
      <li><strong>D</strong>: Delete</li>
    </ul>
    
    <p>
      These operations allow developers to manage and manipulate data records effectively. Here’s a breakdown of how to perform each CRUD operation using <strong>Entity Framework Core</strong> in <strong>.NET Programming</strong>:
    </p>

    <h2>1. Create (Insert)</h2>
    <p>
      The <strong>Create</strong> operation adds new records to the database. In EF Core, you add a new object to the <code>DbSet</code> and then call the <code>SaveChanges()</code> method to persist it to the database.
    </p>
    
    <pre>
    <code>{`
    using (var context = new AppDbContext())
    {{
        // Create a new entity
        var newProduct = new Product {{ Name = "Laptop", Price = 1200.00M }};
    
        // Add the entity to the DbSet
        context.Products.Add(newProduct);
    
        // Save changes to the database
        context.SaveChanges();
    }}
    `}</code>
    </pre>

    <p>
      In this example:
    </p>
    <ul>
      <li>A new <code>Product</code> entity is created.</li><br />
      <li>The <code>Add</code> method adds the entity to the <code>Products</code> <code>DbSet</code>.</li><br />
      <li>The <code>SaveChanges</code> method commits the change to the database.</li>
    </ul><br />

    <h2>2. Read (Select)</h2>
    <p>
      The <strong>Read</strong> operation retrieves data from the database. EF Core allows developers to query the database using <strong>LINQ</strong> (Language-Integrated Query).
    </p>
    
    <pre>
    <code>{`
    using (var context = new AppDbContext())
    {{
        // Retrieve a single product by its ID
        var product = context.Products.Find(1);
    
        // Retrieve all products
        var allProducts = context.Products.ToList();
    
        // Retrieve products that meet a condition
        var expensiveProducts = context.Products
                                       .Where(p => p.Price > 1000)
                                       .ToList();
    }}
    `}</code>
    </pre>

    <p>
      In this example:
    </p>
    <ul>
      <li><code>Find</code> is used to retrieve an entity by its primary key.</li><br />
      <li><code>ToList</code> retrieves all products from the database.</li><br />
      <li><code>Where</code> filters the data based on a specific condition.</li>
    </ul><br />

    <h2>3. Update</h2>
    <p>
      The <strong>Update</strong> operation modifies an existing record in the database. After retrieving the record, you modify its properties and then call <code>SaveChanges()</code> to commit the update.
    </p>
    
    <pre>
    <code>{`
    using (var context = new AppDbContext())
    {{
        // Retrieve an existing product
        var product = context.Products.Find(1);
    
        if (product != null)
        {{
            // Modify the product's properties
            product.Price = 1500.00M;
    
            // Save the changes to the database
            context.SaveChanges();
        }}
    }}
     `} </code>
    </pre>

    <p>
      In this example:
    </p>
    <ul>
      <li>The <code>Find</code> method retrieves an entity by its ID.</li><br />
      <li>The entity's properties are modified, and the <code>SaveChanges</code> method commits the changes to the database.</li>
    </ul><br />

    <h2>4. Delete</h2>
    <p>
      The <strong>Delete</strong> operation removes an existing record from the database. You retrieve the entity, remove it from the <code>DbSet</code>, and then call <code>SaveChanges()</code> to delete it.
    </p>
    
    <pre>
    <code>{`
    using (var context = new AppDbContext())
    {{
        // Retrieve an existing product
        var product = context.Products.Find(1);
    
        if (product != null)
        {{
            // Remove the product from the DbSet
            context.Products.Remove(product);
    
            // Save changes to delete the product
            context.SaveChanges();
        }}
    }}
     `} </code>
    </pre>

    <p>
      In this example:
    </p>
    <ul>
      <li>The entity is retrieved by its ID.</li><br />
      <li>The <code>Remove</code> method deletes the entity from the <code>DbSet</code>.</li><br />
      <li>The <code>SaveChanges</code> method commits the deletion to the database.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      CRUD operations are the basic operations necessary for any application to interact with databases. Using <strong>Entity Framework Core</strong> in <strong>.NET Programming</strong>, developers can efficiently perform CRUD operations using a consistent and easy-to-understand syntax. The <code>DbContext</code> and <code>DbSet</code> classes in EF Core provide an abstraction layer that simplifies database interactions, enabling developers to focus on application logic rather than the complexities of SQL commands.
    </p>
  </div>
)}


{selectedChapter === 'chapter27' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Migrations and Seeding Data</h1>

    <p>
      In <strong>.NET Programming</strong>, especially when working with <strong>Entity Framework Core (EF Core)</strong>, <strong>migrations</strong> and <strong>seeding data</strong> are essential tools for managing database schema changes and populating the database with initial data.
    </p><br />

    <h2>1. Migrations</h2>
    <p style={{paddingBottom:"6px"}}>
      Migrations are a way to incrementally apply changes to your database schema based on the modifications made to your domain model (C# classes). This feature enables developers to evolve the database schema in a controlled manner, applying versioning to database changes over time.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
    <ul>
      <li><strong>Schema Versioning:</strong> Keeps track of database schema changes, allowing for easy updates or rollbacks.</li><br />
      <li><strong>Automated SQL Script Generation:</strong> Migrations generate SQL scripts to update the database schema automatically.</li><br />
      <li><strong>Evolving the Database:</strong> Allows you to adapt the database schema without dropping and recreating the entire database.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Workflow for Using Migrations:</h3>
    <ol>
      <li>
        <strong>Add a Migration:</strong> After making changes to your entity classes, you can create a new migration that will reflect these changes.
        <pre><code>dotnet ef migrations add AddNewColumn</code></pre>
        This command generates a migration file that describes the changes.
      </li><br />
      <li>
        <strong>Update the Database:</strong> Apply the migration to update the database schema.
        <pre><code>dotnet ef database update</code></pre>
        This command executes the migration and applies the schema changes to the database.
      </li><br />
      <li>
        <strong>Roll Back a Migration:</strong> If needed, you can roll back a migration to a previous state.
        <pre><code>dotnet ef database update LastGoodMigration</code></pre>
      </li>
    </ol><br />

    <h3>Example:</h3>
    <p>Suppose you want to add a new column to an existing <code>Product</code> entity:</p>
    <pre>
      <code>
{`public class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
    public string Description { get; set; } // New column
}`}
      </code>
    </pre>
    <p>
      After making this change to the <code>Product</code> class, you would run the following commands:
    </p>
    <pre><code>dotnet ef migrations add AddDescriptionToProduct</code></pre>
    <pre><code>dotnet ef database update</code></pre>
    <p>This updates the database schema by adding the <code>Description</code> column to the <code>Products</code> table.</p><br />

    <h2>2. Seeding Data</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>Seeding</strong> refers to populating the database with initial or default data when the database is created or updated. This is particularly useful for adding reference data (e.g., default roles, initial admin users) to the database.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
    <ul>
      <li><strong>Automatic Population:</strong> The data is automatically inserted into the database when the database is created or updated via migrations.</li><br />
      <li><strong>Idempotent:</strong> The seeding process ensures that data is only inserted if it doesn’t already exist.</li>
    </ul><br />

    <h3>Workflow for Seeding Data:</h3>
    <ol>
      <li>
        Define your seed data within the <code>OnModelCreating</code> method of your <code>DbContext</code>.
        <pre>
          <code>
{`protected override void OnModelCreating(ModelBuilder modelBuilder)
{
    modelBuilder.Entity<Product>().HasData(
        new Product { Id = 1, Name = "Laptop", Price = 1200.00M },
        new Product { Id = 2, Name = "Smartphone", Price = 800.00M }
    );
}`}
          </code>
        </pre>
      </li><br />
      <li>
        Apply a migration to ensure the seeded data is added to the database.
        <pre><code>dotnet ef migrations add SeedInitialData</code></pre>
        <pre><code>dotnet ef database update</code></pre>
      </li><br />
      <li>
        The specified data (in this case, <code>Product</code> entities) will be inserted into the database if the records don’t already exist.
      </li>
    </ol><br />

    <h3>Example:</h3>
    <p>Here’s a simple seed data example for an <code>Admin</code> user:</p>
    <pre>
      <code>
{`modelBuilder.Entity<User>().HasData(
    new User
    {
        Id = 1,
        Username = "admin",
        Password = "admin123",
        Role = "Administrator"
    }
);`}
      </code>
    </pre>
    <p>
      After adding this code in <code>OnModelCreating</code>, running <code>dotnet ef database update</code> will ensure that the <code>User</code> table contains the initial admin user.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      - <strong>Migrations</strong> provide a structured way to evolve and version the database schema over time, making it easier to manage changes in a live production environment.
    </p>
    <p>
      - <strong>Seeding Data</strong> ensures that essential data is automatically populated when the database is created or updated, saving time and avoiding manual data entry.
    </p>
    <p>
      Together, migrations and data seeding form a robust mechanism for maintaining and initializing your database in .NET applications using Entity Framework Core.
    </p>
  </div>
)}

{selectedChapter === 'chapter28' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Working with SQL Server and Other Databases</h1>

    <p>
      In <strong>.NET Programming</strong>, working with databases is a core task, whether it's <strong>SQL Server</strong>, 
      <strong>MySQL</strong>, <strong>PostgreSQL</strong>, or other database systems. The framework, especially when paired with 
      <strong>Entity Framework Core (EF Core)</strong>, provides a robust and flexible way to interact with a variety of databases. 
      Let’s break down the essential concepts for working with <strong>SQL Server</strong> and other databases in .NET applications.
    </p><br />

    <h3>1. Working with SQL Server in .NET Programming</h3>

    <p style={{paddingBottom:"6px"}}><strong>SQL Server</strong> is Microsoft's flagship relational database management system (RDBMS), and it integrates 
    smoothly with the .NET ecosystem. .NET developers typically use <strong>ADO.NET</strong>, <strong>Entity Framework Core</strong>, 
    or <strong>Dapper</strong> to connect and interact with SQL Server databases.</p>

    <h4>A. Connecting to SQL Server</h4>

    <p>You typically define a <strong>connection string</strong> in your application's configuration file (like <code>appsettings.json</code>) 
    to establish a connection to a SQL Server database.</p>

    <pre>
      <code>
{`{
  "ConnectionStrings": {
    "DefaultConnection": "Server=your_server_name;Database=your_database;User Id=your_username;Password=your_password;"
  }
}`}
      </code>
    </pre>

    <p>You can retrieve this connection string in your code using <strong>Dependency Injection (DI)</strong> and configure your 
    <code>DbContext</code> in <strong>Entity Framework Core</strong>.</p><br />

    <h4>B. Configuring Entity Framework Core for SQL Server</h4>

    <p>In your <code>DbContext</code> class, you specify SQL Server as the database provider by configuring it in the 
    <code>OnConfiguring</code> method or in the <code>Startup.cs</code> when using ASP.NET Core.</p>

    <pre>
      <code>
{`public class AppDbContext : DbContext
{
    protected override void OnConfiguring(DbContextOptionsBuilder optionsBuilder)
    {
        optionsBuilder.UseSqlServer("Server=your_server_name;Database=your_database;User Id=your_username;Password=your_password;");
    }
}`}
      </code>
    </pre>

    <p>Alternatively, you can configure it in the <code>Startup.cs</code> (for ASP.NET Core):</p>

    <pre>
      <code>
{`public void ConfigureServices(IServiceCollection services)
{
    services.AddDbContext<AppDbContext>(options =>
        options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));
}`}
      </code>
    </pre><br />

    <h4>C. Performing CRUD Operations with SQL Server</h4>

    <p>Once SQL Server is configured as the database provider, you can perform <strong>CRUD (Create, Read, Update, Delete)</strong> 
    operations using <strong>Entity Framework Core</strong>.</p>

    <ul>
      <li><strong>Create</strong>: Insert new records into the SQL Server database.</li>
      <li><strong>Read</strong>: Query data using LINQ.</li>
      <li><strong>Update</strong>: Modify existing data and save changes.</li>
      <li><strong>Delete</strong>: Remove data from the database.</li>
    </ul>

    <p>For a detailed guide on CRUD operations, refer to the <strong>Performing CRUD Operations</strong> section.</p><br />

    <h4>D. Executing Raw SQL Queries</h4>

    <p>Sometimes, you may need to execute raw SQL queries directly against the SQL Server database. EF Core allows you to run raw SQL 
    queries using the <code>FromSqlRaw</code> method for queries or <code>ExecuteSqlRaw</code> for non-query operations.</p>

    <pre>
      <code>
{`var products = context.Products.FromSqlRaw("SELECT * FROM Products WHERE Price > 1000").ToList();`}
      </code>
    </pre><br />

    <h3>2. Working with Other Databases in .NET Programming</h3>

    <p style={{paddingBottom:"6px"}}><strong>.NET</strong> and <strong>EF Core</strong> support multiple database providers, such as <strong>MySQL</strong>, 
    <strong>PostgreSQL</strong>, <strong>SQLite</strong>, and others. The process of working with other databases is very similar to SQL 
    Server, with some slight configuration differences based on the database provider.</p>

    <h4>A. MySQL in .NET Programming</h4>

    <p>To work with <strong>MySQL</strong>, you can use the <strong>Pomelo.EntityFrameworkCore.MySql</strong> package for EF Core.</p>

    <pre>
      <code>
{`dotnet add package Pomelo.EntityFrameworkCore.MySql`}
      </code>
    </pre>

    <pre>
      <code>
{`public class AppDbContext : DbContext
{
    protected override void OnConfiguring(DbContextOptionsBuilder optionsBuilder)
    {
        optionsBuilder.UseMySql("Server=your_server;Database=your_db;User=your_user;Password=your_password;",
            new MySqlServerVersion(new Version(8, 0, 21)));
    }
}`}
      </code>
    </pre><br />

    <h4>B. PostgreSQL in .NET Programming</h4>

    <p>For <strong>PostgreSQL</strong>, use the <strong>Npgsql.EntityFrameworkCore.PostgreSQL</strong> provider with EF Core.</p>

    <pre>
      <code>
{`dotnet add package Npgsql.EntityFrameworkCore.PostgreSQL`}
      </code>
    </pre>

    <pre>
      <code>
{`public class AppDbContext : DbContext
{
    protected override void OnConfiguring(DbContextOptionsBuilder optionsBuilder)
    {
        optionsBuilder.UseNpgsql("Host=your_host;Database=your_db;Username=your_user;Password=your_password");
    }
}`}
      </code>
    </pre><br />

    <h4>C. SQLite in .NET Programming</h4>

    <p><strong>SQLite</strong> is an embedded database that is useful for lightweight applications or development environments.</p>

    <pre>
      <code>
{`dotnet add package Microsoft.EntityFrameworkCore.Sqlite`}
      </code>
    </pre>

    <pre>
      <code>
{`public class AppDbContext : DbContext
{
    protected override void OnConfiguring(DbContextOptionsBuilder optionsBuilder)
    {
        optionsBuilder.UseSqlite("Data Source=your_database.db");
    }
}`}
      </code>
    </pre><br />

    <h3>3. Database Provider Differences</h3>

    <p>While the general structure of working with SQL Server, MySQL, PostgreSQL, or SQLite is the same, there are some differences:</p>

    <ul>
      <li><strong>Data types</strong>: Different databases may have different data types (e.g., <code>VARCHAR</code> in SQL Server, 
      <code>TEXT</code> in PostgreSQL).</li><br />
      <li><strong>SQL syntax</strong>: Some raw SQL queries may vary between databases.</li><br />
      <li><strong>Features</strong>: Some databases support features like full-text search or JSON columns differently.</li>
    </ul><br />

    <h3>Conclusion</h3>

    <p>Working with databases in <strong>.NET</strong> is simplified by <strong>Entity Framework Core</strong>, which abstracts many 
    complexities of database interaction. Whether using <strong>SQL Server</strong>, <strong>MySQL</strong>, <strong>PostgreSQL</strong>, 
    or other databases, EF Core allows for a consistent coding experience, though some configuration may vary depending on the database 
    provider. Understanding connection strings, configuring the <code>DbContext</code>, and executing CRUD operations are foundational 
    skills when working with databases in .NET applications.</p>
  </div>
)}


{selectedChapter === 'chapter29' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Blazor (Server-side and WebAssembly)</h1>

    <p>
      <strong>Blazor</strong> is a web framework in the <strong>.NET</strong> ecosystem for building interactive, client-side web applications using <strong>C#</strong> instead of JavaScript. With Blazor, developers can create rich web UIs that run either in the browser using WebAssembly or on the server using SignalR. This framework simplifies development by allowing code sharing between the client and server, enabling the use of a single programming language—C#.
    </p><br />

    <p style={{paddingBottom:"6px"}}>Blazor has two main hosting models:</p>
    <ol>
      <li><strong>Blazor Server</strong> (Server-side)</li><br />
      <li><strong>Blazor WebAssembly</strong> (Client-side)</li>
    </ol><br />

    <h3>1. Blazor Server (Server-side Blazor)</h3>
    <p style={{paddingBottom:"6px"}}>
      <strong>Blazor Server</strong> is a hosting model where the application's logic and UI rendering are executed on the server, with the user interface updated in the browser via a real-time connection using <strong>SignalR</strong>.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Characteristics:</h4>
    <ul>
      <li>UI rendering happens on the server, and updates are sent to the browser via SignalR.</li><br />
      <li>Smaller download size compared to WebAssembly as no WebAssembly runtime is downloaded.</li><br />
      <li>State is managed on the server, maintaining a constant connection with the client.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Advantages:</h4>
    <ul>
      <li><strong>Fast load times:</strong> No WebAssembly payload means faster loads.</li><br />
      <li><strong>Server-side logic:</strong> Sensitive logic is executed on the server, enhancing security.</li><br />
      <li><strong>Minimal client resources:</strong> The client has minimal CPU and memory demands.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Disadvantages:</h4>
    <ul>
      <li><strong>Latency:</strong> UI interactions require round trips to the server, adding potential delays.</li><br />
      <li><strong>Connection-dependent:</strong> The app will stop functioning if the SignalR connection is lost.</li>
    </ul><br />

    <h5>Example of Blazor Server App Startup Code:</h5>
    <pre>
      <code>
{`public class Startup
{
    public void ConfigureServices(IServiceCollection services)
    {
        services.AddRazorComponents<App>();
        services.AddServerSideBlazor();
    }

    public void Configure(IApplicationBuilder app, IHostingEnvironment env)
    {
        if (env.IsDevelopment())
        {
            app.UseDeveloperExceptionPage();
        }

        app.UseRouting();

        app.UseEndpoints(endpoints =>
        {
            endpoints.MapBlazorHub();
            endpoints.MapFallbackToPage("/_Host");
        });
    }
}`}
      </code>
    </pre><br />

    <h3>2. Blazor WebAssembly (Client-side Blazor)</h3>
    <p style={{paddingBottom:"6px"}}>
      <strong>Blazor WebAssembly</strong> runs entirely in the browser using WebAssembly. In this model, the .NET runtime, along with the application code, is compiled into WebAssembly and executed on the client-side.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Characteristics:</h4>
    <ul>
      <li>The app runs as a WebAssembly package directly in the browser's sandbox.</li><br />
      <li>No need for a constant server connection—ideal for offline applications.</li><br />
      <li>Supports <strong>JavaScript interop</strong> for calling JavaScript APIs when necessary.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Advantages:</h4>
    <ul>
      <li><strong>True client-side execution:</strong> The app runs entirely in the browser.</li><br />
      <li><strong>Scalability:</strong> Since the server isn't responsible for UI rendering, it scales easily.</li><br />
      <li><strong>SPA support:</strong> Provides a single-page application experience similar to traditional SPAs.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Disadvantages:</h4>
    <ul>
      <li><strong>Large initial download:</strong> The app and WebAssembly runtime increase initial load times.</li><br />
      <li><strong>Limited system access:</strong> Runs in the browser's sandbox, limiting access to system resources.</li><br />
      <li><strong>Client-side execution:</strong> Client-side code may expose sensitive logic to users.</li>
    </ul><br />

    <h5>Example of Blazor WebAssembly App Startup Code:</h5>
    <pre>
      <code>
{`public class Startup
{
    public void ConfigureServices(IServiceCollection services)
    {
        services.AddBaseAddressHttpClient();
    }

    public void Configure(IApplicationBuilder app, IHostingEnvironment env)
    {
        if (env.IsDevelopment())
        {
            app.UseDeveloperExceptionPage();
        }

        app.UseBlazorFrameworkFiles();
        app.UseStaticFiles();
        app.UseRouting();

        app.UseEndpoints(endpoints =>
        {
            endpoints.MapFallbackToFile("index.html");
        });
    }
}`}
      </code>
    </pre><br />

    <h3>Blazor Server vs Blazor WebAssembly</h3>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Blazor Server</th>
          <th>Blazor WebAssembly</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Execution</td>
          <td>Runs on the server</td>
          <td>Runs in the browser</td>
        </tr>
        <tr>
          <td>Initial Load Time</td>
          <td>Fast</td>
          <td>Slower (due to WebAssembly download)</td>
        </tr>
        <tr>
          <td>Connection</td>
          <td>Requires a constant SignalR connection</td>
          <td>Works offline after load</td>
        </tr>
        <tr>
          <td>State Management</td>
          <td>Server-side</td>
          <td>Client-side</td>
        </tr>
        <tr>
          <td>Scaling</td>
          <td>Server resources needed for each user</td>
          <td>Scales easily without server involvement</td>
        </tr>
        <tr>
          <td>Offline Support</td>
          <td>Not supported</td>
          <td>Supported</td>
        </tr>
        <tr>
          <td>Security</td>
          <td>Server-side execution is more secure</td>
          <td>Client-side code may expose sensitive logic</td>
        </tr>
      </tbody>
    </table><br />

    <h3>Conclusion</h3>
    <p>
      <strong>Blazor</strong> offers a modern, powerful framework for building web applications using <strong>C#</strong>. Whether you choose Blazor Server or Blazor WebAssembly, both provide significant benefits depending on your use case. Blazor Server is ideal for applications where server resources can handle UI updates, while Blazor WebAssembly allows for a fully client-side experience. Both approaches enable .NET developers to leverage their C# skills for full-stack web development.
    </p>
  </div>
)}


{selectedChapter === 'chapter30' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building Components and Layouts</h1>

    <p>
      In .NET programming, particularly when working with Blazor, building components and layouts is fundamental for creating modular, reusable UI elements and structuring the application interface efficiently. Blazor components are the building blocks of a Blazor application, allowing developers to create encapsulated pieces of UI with their own logic and state.
    </p><br />

    <h3>Definition of Components</h3>

    <p style={{paddingBottom:"6px"}}>
      <strong>Components</strong> are self-contained units that manage their own rendering and behavior. Each component in Blazor can include HTML markup, C# code, and CSS styles, allowing for a flexible and cohesive approach to UI development. Components can be composed together to create more complex UIs.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Characteristics of Components:</h4>
    <ul>
      <li><strong>Encapsulation</strong>: Each component is encapsulated, meaning it can manage its own state and logic independently of other components.</li><br />
      <li><strong>Reusability</strong>: Components can be reused across different parts of the application or even in different applications.</li><br />
      <li><strong>Parameterization</strong>: Components can accept parameters, allowing for dynamic behavior and rendering based on the input provided.</li><br />
      <li><strong>Event Handling</strong>: Components can handle events, allowing for user interactions.</li>
    </ul><br />

    <h3>Definition of Layouts</h3>

    <p style={{paddingBottom:"6px"}}>
      <strong>Layouts</strong> in Blazor are special components that define a consistent structure for pages. They allow developers to create a uniform look and feel across different parts of an application, making it easier to manage and maintain the UI.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Characteristics of Layouts:</h4>
    <ul>
      <li><strong>Consistent Structure</strong>: Layouts provide a common structure, such as headers, footers, and navigation menus, which can be applied to multiple pages.</li><br />
      <li><strong>Page Composition</strong>: Pages can specify which layout they want to use, facilitating a clear separation of concerns.</li><br />
      <li><strong>Dynamic Content</strong>: Layouts can include placeholders for dynamic content, which is filled by the pages that utilize them.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Example Code</h3>

    <h4>Example Component: <code>Counter.razor</code></h4>

    <pre>
      <code>{`@page "/counter"
@using Microsoft.AspNetCore.Components

<h3>Counter</h3>

<p>Current count: @currentCount</p>

<button class="btn btn-primary" @onclick="IncrementCount">Increment</button>

@code {
    private int currentCount = 0;

    private void IncrementCount()
    {
        currentCount++;
    }
}`} 
      </code>
    </pre>

    <p>
      <strong>Explanation</strong>: This component defines a simple counter with a button to increment the count. The <code>@code</code> block contains the C# logic for the component.
    </p><br />

    <h4>Example Layout: <code>MainLayout.razor</code></h4>

    <pre>
      <code>{`@inherits LayoutComponentBase

<div class="main-layout">
    <header>
        <h1>My Blazor App</h1>
        <nav>
            <NavLink href="/" class="nav-link">Home</NavLink>
            <NavLink href="/counter" class="nav-link">Counter</NavLink>
        </nav>
    </header>
    <div class="content">
        @Body
    </div>
    <footer>
        <p>&copy; 2024 My Blazor App</p>
    </footer>
</div>

<style>
    .main-layout {
        padding: 20px;
    }
    header {
        background-color: #007bff;
        color: white;
        padding: 10px;
    }
    .content {
        margin: 20px 0;
    }
    footer {
        text-align: center;
        padding: 10px;
        background-color: #f8f9fa;
    }
`}</code>
      </pre>

    <p>
      <strong>Explanation</strong>: The <code>MainLayout</code> component defines a layout with a header, navigation, dynamic content (<code>@Body</code>), and a footer. Styles are included for a simple layout design.
    </p><br />

    <h4>Using the Layout in a Page</h4>

    <pre>
      <code>{`@page "/"
@layout MainLayout

<h3>Welcome to My Blazor App</h3>
<p>This is the home page content.</p>`}
      </code>
    </pre><br />

    <h3>Conclusion</h3>

    <p>
      Building components and layouts in .NET with Blazor provides a powerful way to create interactive web applications. By leveraging components for encapsulation and layouts for consistent structure, developers can build maintainable and scalable applications efficiently.
    </p>
  </div>
)}


{selectedChapter === 'chapter31' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Binding and Event Handling</h1>



    <h2>Definition of Data Binding</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>Data Binding</strong> in Blazor allows developers to synchronize data between the user interface (UI) and the application logic. It enables the automatic updating of the UI when the underlying data changes and vice versa. Blazor supports different types of data binding, including one-way and two-way binding.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Types of Data Binding:</h3>
    <ol>
      <li>
        <strong>One-way Binding</strong>: Updates the UI from the data source, but changes in the UI do not affect the data source. This is often used for displaying data.
      </li><br />
      <li>
        <strong>Two-way Binding</strong>: Allows for mutual updates between the UI and the data source. Changes in the UI (like user input) automatically update the underlying data, and any changes in the data source automatically update the UI.
      </li>
    </ol><br />

    <h2>Example of Data Binding in Blazor</h2>
    <p style={{paddingBottom:"6px"}}>
      Here’s an example of a Blazor component demonstrating one-way and two-way data binding:
    </p>

    <h3>Example Component: <code>DataBindingExample.razor</code></h3>
    <pre>
      <code>
        {`@page "/databinding"
@using Microsoft.AspNetCore.Components

<h3>Data Binding Example</h3>

<div>
    <h4>One-way Binding</h4>
    <p>Welcome, @userName!</p>

    <h4>Two-way Binding</h4>
    <input type="text" @bind="userInput" placeholder="Type your name here" />
    <p>Your input: @userInput</p>
</div>

@code {
    private string userName = "John Doe";
    private string userInput = string.Empty;
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Explanation:</h3>
    <ul>
      <li>In the one-way binding section, <code>@userName</code> displays a predefined string.</li><br />
      <li>In the two-way binding section, the input field is bound to the <code>userInput</code> property, meaning any changes to the input field are automatically reflected in the <code>userInput</code> variable, and vice versa.</li>
    </ul><br />

    <h2>Definition of Event Handling</h2>
    <p>
      <strong>Event Handling</strong> in Blazor involves responding to user interactions and system events. It allows developers to execute specific code when events such as button clicks, form submissions, or other user interactions occur.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Concepts in Event Handling:</h3>
    <ul>
      <li><strong>Event Delegates</strong>: Blazor uses event delegates to specify methods that should be invoked when an event occurs.</li><br />
      <li><strong>@onclick Directive</strong>: Used to bind an event to a method in the code-behind.</li>
    </ul><br />

    <h2>Example of Event Handling in Blazor</h2>
    <p>
      Here’s an example of a Blazor component demonstrating event handling:
    </p><br />

    <h3>Example Component: <code>EventHandlingExample.razor</code></h3>
    <pre>
      <code>
        {`@page "/eventhandling"
@using Microsoft.AspNetCore.Components

<h3>Event Handling Example</h3>

<button class="btn btn-primary" @onclick="IncrementCounter">Click me!</button>
<p>Current count: @currentCount</p>

@code {
    private int currentCount = 0;

    private void IncrementCounter()
    {
        currentCount++;
    }
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Explanation:</h3>
    <ul>
      <li>A button is rendered with an <code>@onclick</code> directive, which specifies the <code>IncrementCounter</code> method to be called whenever the button is clicked.</li><br />
      <li>The <code>currentCount</code> variable is incremented each time the button is clicked, and the updated value is displayed on the page.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Data binding and event handling are crucial concepts in Blazor for creating dynamic and interactive web applications. Data binding allows for seamless synchronization between the UI and data, while event handling enables developers to respond to user actions effectively. Together, they empower developers to build responsive and engaging user experiences in .NET applications.
    </p>

    <p>Feel free to ask if you need more examples or further explanations!</p>
  </div>
)}



{selectedChapter === 'chapter32' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Routing and Navigation</h1>

    <h2>Definition of Routing</h2>
    <p style={{paddingBottom:"6px"}}><strong>Routing</strong> in Blazor is the process of mapping URLs to components. It allows developers to define how the application responds to different URL requests, enabling navigation between different views and pages within a web application. The Blazor router determines which component to render based on the current URL.</p>

    <h3 style={{paddingBottom:"6px"}}>Key Concepts in Routing:</h3>
    <ul>
      <li><strong>Route Templates</strong>: A route template defines a URL pattern that can be matched to a specific component. It can include parameters to capture values from the URL.</li><br />
      <li><strong>Route View</strong>: The <code>&lt;Router&gt;</code> component manages routing in Blazor applications. The <code>&lt;RouteView&gt;</code> component displays the matched component based on the current URL.</li><br />
      <li><strong>Dynamic Routing</strong>: Blazor supports dynamic routing, allowing components to accept parameters from the URL, which can be used to customize the content displayed.</li>
    </ul><br />

    <h2>Example of Routing in Blazor</h2>
    <p>Here’s an example demonstrating how to set up routing in a Blazor application:</p>

    <h3>Example Component: <code>App.razor</code></h3>
    <pre>
      <code>
        {`@using Microsoft.AspNetCore.Components.Routing
@using Microsoft.AspNetCore.Components.Web

<Router AppAssembly="@typeof(Program).Assembly">
    <Found Context="routeData">
        <RouteView RouteData="@routeData" DefaultLayout="@typeof(MainLayout)" />
        <FocusOnNavigate RouteData="@routeData" Selector="h1" />
    </Found>
    <NotFound>
        <LayoutView Layout="@typeof(MainLayout)">
            <h1>Sorry, there's nothing at this address.</h1>
        </LayoutView>
    </NotFound>
</Router>`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Explanation:</h3>
    <ul>
      <li>The <code>&lt;Router&gt;</code> component sets up the routing for the application.</li><br />
      <li>The <code>&lt;RouteView&gt;</code> component is responsible for rendering the matched component based on the current route.</li><br />
      <li>The <code>DefaultLayout</code> attribute specifies the layout to use for the matched components.</li><br />
      <li>The <code>&lt;NotFound&gt;</code> section provides a fallback for any unmatched routes, displaying a friendly message.</li>
    </ul><br />

    <h3>Example Page: <code>Home.razor</code></h3>
    <pre>
      <code>
        {`@page "/"
@layout MainLayout

<h1>Welcome to My Blazor App</h1>
<p>This is the home page.</p>`}
      </code>
    </pre><br />

    <h3>Example Page: <code>Counter.razor</code></h3>
    <pre>
      <code>
        {`@page "/counter"
@layout MainLayout

<h3>Counter</h3>

<p>Current count: @currentCount</p>
<button class="btn btn-primary" @onclick="IncrementCount">Increment</button>

@code {
    private int currentCount = 0;

    private void IncrementCount()
    {
        currentCount++;
    }
}`}

      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Explanation of Pages:</h3>
    <ul>
      <li>The <code>@page</code> directive at the top of each component specifies the route for that page.</li><br />
      <li>In <code>Home.razor</code>, navigating to the root URL (<code>/</code>) will display the home page.</li><br />
      <li>In <code>Counter.razor</code>, navigating to <code>/counter</code> will render the counter component, allowing users to increment a count.</li>
    </ul><br />

    <h2>Navigation in Blazor</h2>
    <p style={{paddingBottom:"6px"}}>Blazor provides built-in components for navigation, such as <code>&lt;NavLink&gt;</code> for creating links that can respond to routing.</p>

    <h3>Example Navigation Menu: <code>NavMenu.razor</code></h3>
    <pre>
      <code>
        {`<nav>
    <NavLink href="/" class="nav-link">Home</NavLink>
    <NavLink href="/counter" class="nav-link">Counter</NavLink>
</nav>`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Explanation:</h3>
    <ul>
      <li>The <code>&lt;NavLink&gt;</code> component creates a navigation link. The <code>href</code> attribute specifies the target route.</li><br />
      <li>The component automatically applies an active class to the link that corresponds to the current route, allowing users to see which page they are on.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>Routing and navigation are essential components of Blazor applications, enabling smooth transitions between different views and enhancing user experience. By defining routes and utilizing navigation components, developers can create structured and user-friendly web applications in .NET.</p>

  </div>
)}


{selectedChapter === 'chapter33' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Understanding HTML, CSS, and JavaScript</h1>

 

    <ol>
      <li>
        <strong>HTML (HyperText Markup Language)</strong>: HTML is the standard markup language used to create the structure of web pages. It provides the basic building blocks of web content, including text, links, images, and other multimedia elements. HTML uses a series of elements represented by tags to define the content and its structure.
      </li><br />
      <li>
        <strong>CSS (Cascading Style Sheets)</strong>: CSS is a stylesheet language used to describe the presentation of a document written in HTML. It allows developers to control the layout, colors, fonts, and overall visual appearance of web pages. CSS can be applied to HTML elements to enhance their appearance and ensure responsive design across different devices.
      </li><br />
      <li>
        <strong>JavaScript</strong>: JavaScript is a high-level, dynamic programming language primarily used for adding interactivity and functionality to web pages. It enables developers to create dynamic content, control multimedia, animate images, and respond to user actions. JavaScript can manipulate HTML and CSS, allowing for the creation of interactive web applications.
      </li>
    </ol><br />

    <h2>Role of HTML, CSS, and JavaScript in .NET Programming</h2>

    <p>
      In the context of .NET programming, especially when building web applications using frameworks like ASP.NET Core or Blazor, HTML, CSS, and JavaScript play a crucial role:
    </p>
    <ul>
      <li><strong>HTML</strong> is used to structure the user interface components.</li><br />
      <li><strong>CSS</strong> is used to style the components, making them visually appealing and user-friendly.</li><br />
      <li><strong>JavaScript</strong> is used to handle client-side logic, enabling dynamic interactions and real-time updates within the web application.</li>
    </ul><br />

    <h2>Example Code</h2>

    <p style={{paddingBottom:"6px"}}>Here's a simple example demonstrating the integration of HTML, CSS, and JavaScript in a .NET context using Razor syntax:</p>

    <h3>HTML Structure (Razor Component)</h3>

    <pre>
      <code>
        {`@page "/example"
@layout MainLayout

<h1>Understanding HTML, CSS, and JavaScript</h1>
<p>This is a simple demonstration of integrating HTML, CSS, and JavaScript in a .NET application.</p>

<div class="content">
    <button id="alertButton" class="btn btn-primary">Click Me!</button>
</div>

<script>
    document.getElementById('alertButton').addEventListener('click', function () {
        alert('Hello from JavaScript!');
    });
</script>

<style>
    .content {
        text-align: center;
        margin-top: 20px;
    }
    .btn {
        padding: 10px 15px;
        font-size: 16px;
        cursor: pointer;
    }
</style>`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Explanation of the Example Code</h2>

    <ul>
      <li><strong>HTML</strong>: The <code>&lt;h1&gt;</code> and <code>&lt;p&gt;</code> tags create a title and description. The button is defined with an ID for JavaScript interaction.</li><br />
      <li><strong>CSS</strong>: The <code>&lt;style&gt;</code> tag includes basic styling for the content and button, ensuring that the layout is centered and the button is visually appealing.</li><br />
      <li><strong>JavaScript</strong>: The <code>&lt;script&gt;</code> tag includes a simple JavaScript function that displays an alert when the button is clicked. This showcases how JavaScript can interact with HTML elements.</li>
    </ul><br />

    <h2>Conclusion</h2>

    <p>
      Understanding HTML, CSS, and JavaScript is essential for building modern web applications in .NET programming. These technologies work together to create a cohesive user experience by structuring content, styling it, and adding interactivity.
    </p>
  </div>
)}



{selectedChapter === 'chapter34' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Client-side Scripting with JavaScript</h1>

    <p>
      <strong>Client-side scripting</strong> refers to scripts that are executed on the user's browser rather than the server. In the context of web applications, JavaScript is the most widely used client-side scripting language. It enables developers to create dynamic and interactive web pages by allowing for real-time content updates, user interaction handling, and enhanced user experiences without needing to reload the entire page.
    </p><br />

    <h2>Role of Client-side Scripting in .NET Programming</h2>
    <p>
      In .NET programming, especially when developing web applications with frameworks like ASP.NET Core and Blazor, client-side scripting plays a critical role:
    </p>
    <ul>
      <li><strong>Interactivity:</strong> JavaScript enhances the interactivity of web applications by allowing developers to respond to user actions such as clicks, mouse movements, and keyboard events.</li><br />
      <li><strong>Asynchronous Operations:</strong> It enables asynchronous requests to the server (using AJAX or Fetch API), allowing parts of a web page to be updated without refreshing the entire page.</li><br />
      <li><strong>Manipulation of HTML and CSS:</strong> JavaScript can dynamically manipulate the Document Object Model (DOM), changing the structure and style of the web page based on user interactions.</li><br />
      <li><strong>Validation:</strong> Client-side scripts can validate user inputs before sending data to the server, providing immediate feedback and improving user experience.</li>
    </ul><br />

    <h2>Example Code</h2>
    <p>Here's a simple example demonstrating client-side scripting with JavaScript in a .NET context using Razor syntax:</p>

    <h3>HTML Structure (Razor Component)</h3>
    <pre>
      <code>
{`@page "/client-script-example"
@layout MainLayout

<h1>Client-side Scripting with JavaScript</h1>
<p>This example demonstrates how JavaScript can enhance the interactivity of a .NET application.</p>

<div class="form-container">
    <label for="nameInput">Enter your name:</label>
    <input type="text" id="nameInput" />
    <button id="greetButton" class="btn btn-primary">Greet Me!</button>
</div>

<div id="greetingMessage" class="greeting-message"></div>

<script>
    document.getElementById('greetButton').addEventListener('click', function () {
        const name = document.getElementById('nameInput').value;
        const messageElement = document.getElementById('greetingMessage');

        if (name) {
            messageElement.innerText = \`Hello, \${name}! Welcome to our .NET application.\`;
            messageElement.style.color = 'green';
        } else {
            messageElement.innerText = 'Please enter your name.';
            messageElement.style.color = 'red';
        }
    });
</script>

<style>
    .form-container {
        text-align: center;
        margin-top: 20px;
    }
    .greeting-message {
        margin-top: 15px;
        font-size: 20px;
    }
    .btn {
        padding: 10px 15px;
        font-size: 16px;
        cursor: pointer;
    }
</style>
`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Explanation of the Example Code:</h2>
    <ul>
      <li><strong>HTML:</strong> The <code>&lt;h1&gt;</code> and <code>&lt;p&gt;</code> tags create a title and description. An input field allows users to enter their name, and a button triggers the greeting.</li><br />
      <li><strong>JavaScript:</strong> The <code>&lt;script&gt;</code> tag adds an event listener to the button. When clicked, it retrieves the user's name from the input field and displays a personalized greeting message. If the input is empty, it prompts the user to enter their name.</li><br />
      <li><strong>CSS:</strong> The <code>&lt;style&gt;</code> tag defines basic styling for the form container and greeting message to enhance the layout and visual appeal.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Client-side scripting with JavaScript is essential for creating interactive and responsive web applications in .NET programming. By leveraging JavaScript, developers can enhance user experiences, improve application performance, and streamline interactions without constant server communication. This integration empowers developers to build modern web applications that are engaging and user-friendly.
    </p>
  </div>
)}

{selectedChapter === 'chapter35' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>AJAX and Fetch API for Asynchronous Programming</h1>

    <h2 style={{ paddingBottom: "6px" }}>Definition of AJAX and Fetch API</h2>

    <ol>
      <li>
        <strong>AJAX (Asynchronous JavaScript and XML)</strong>: AJAX is a technique used to create asynchronous web applications. It allows web pages to communicate with the server and update parts of the page without reloading the entire page, resulting in a more dynamic and faster user experience. Although AJAX originally used XML for data interchange, it now commonly utilizes JSON, which is easier to work with in JavaScript.
      </li><br />
      <li>
        <strong>Fetch API</strong>: The Fetch API is a modern interface that allows you to make network requests to servers and handle responses in a simpler and more flexible way compared to the older XMLHttpRequest used in traditional AJAX. The Fetch API returns promises, which makes it easier to work with asynchronous operations and enhances code readability and maintainability.
      </li>
    </ol><br />

    <h2>Role of AJAX and Fetch API in .NET Programming</h2>

    <p>
      In .NET programming, particularly when developing web applications using ASP.NET Core or Blazor, AJAX and the Fetch API play a crucial role:
    </p>
    <ul>
      <li><strong>Data Retrieval</strong>: They enable developers to fetch data from the server asynchronously, allowing for real-time updates without disrupting the user experience.</li><br />
      <li><strong>Dynamic Content Updates</strong>: They allow specific sections of a web page to be updated based on user interactions, leading to smoother and more responsive applications.</li><br />
      <li><strong>Improved Performance</strong>: By reducing the need to reload entire pages, they enhance application performance and user satisfaction.</li>
    </ul><br />

    <h2>Example Code</h2>

    <p style={{ paddingBottom: "6px" }}>Here's a simple example demonstrating how to use AJAX and Fetch API in a .NET context:</p>

    <h3>HTML Structure (React Component)</h3>
 <pre> <code>{`
    <div className="data-container">
      <button className="btn btn-primary" onClick={fetchData}>
        Fetch Data
      </button>
      <div className="data-display" id="dataDisplay"></div>
    </div>

`}</code></pre> <br />
    <h3>JavaScript Function</h3>
    <pre>
      <code>
        {`function fetchData() {
  fetch('/api/sampledata') // Adjust the URL based on your API endpoint
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      const dataDisplay = document.getElementById('dataDisplay');
      dataDisplay.innerHTML = \`<pre>\${JSON.stringify(data, null, 2)}</pre>\`;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
      const dataDisplay = document.getElementById('dataDisplay');
      dataDisplay.innerText = 'Error fetching data.';
    });
}`}
      </code>
    </pre><br />

    <h3>CSS Styles</h3>
    <pre>
      <code>
        {`.data-container {
  text-align: center;
  margin-top: 20px;
}
.data-display {
  margin-top: 15px;
  font-size: 16px;
  white-space: pre-wrap;
}
.btn {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}`}
      </code>
    </pre><br />

    <h2>Explanation of the Example Code:</h2>

    <p>
      - <strong>HTML</strong>: The <code>&lt;h1&gt;</code> and <code>&lt;p&gt;</code> tags create a title and description. A button is provided to trigger the data fetching, and a <code>&lt;div&gt;</code> is designated to display the retrieved data.
    </p>
    <p>
      - <strong>JavaScript</strong>: The <code>fetchData</code> function uses the Fetch API to make a GET request to the specified endpoint (<code>/api/sampledata</code>). The response is processed as JSON, and the retrieved data is displayed in a pre-formatted manner. Error handling is included to manage any issues with the fetch operation.
    </p>
    <p>
      - <strong>CSS</strong>: The styles define basic styling for the data container and display area, enhancing the layout and visual appeal.
    </p><br />

    <h2>Conclusion</h2>

    <p>
      AJAX and the Fetch API are essential tools for implementing asynchronous programming in .NET applications. They allow developers to create dynamic and responsive web applications that provide a seamless user experience by minimizing page reloads and improving data handling. Leveraging these technologies, developers can build modern web applications that are efficient and user-friendly.
    </p>
  </div>
)}

{selectedChapter === 'chapter36' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Authentication and Authorization</h1>

    <p>
      In .NET programming, security is a critical aspect of application development. It encompasses protecting data, managing user identities, and controlling access to resources. Two fundamental components of security are <strong>authentication</strong> and <strong>authorization</strong>, which ensure that users are who they say they are and that they have the right permissions to perform actions within the application.
    </p>

 <br />

    <h2>1. Authentication in .NET</h2>

    <p>
      <strong style={{paddingBottom:"6px"}}>Authentication</strong> is the process of verifying the identity of a user or entity. In .NET applications, authentication can be implemented using various techniques, such as passwords, biometrics, or tokens. The goal is to ensure that users are who they claim to be before granting them access to the system.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Types of Authentication:</h3>
    <ul>
      <li><strong>Basic Authentication</strong>: Users provide a username and password.</li><br />
      <li><strong>Token-Based Authentication</strong>: Uses security tokens like JSON Web Tokens (JWT) to authenticate users, commonly used in web APIs.</li><br />
      <li><strong>OAuth2</strong>: A widely-used protocol for token-based authentication, often used with external identity providers (e.g., Google, Microsoft).</li><br />
      <li><strong>OpenID Connect</strong>: A protocol that works on top of OAuth2 to provide user identity information.</li><br />
      <li><strong>Windows Authentication</strong>: Used in enterprise environments where users are authenticated via their Windows credentials.</li>
    </ul><br />

    <h3>Example: Implementing JWT Authentication in ASP.NET Core</h3>
    <pre>
      <code>
{`public void ConfigureServices(IServiceCollection services)
{
    services.AddAuthentication(options =>
    {
        options.DefaultAuthenticateScheme = JwtBearerDefaults.AuthenticationScheme;
        options.DefaultChallengeScheme = JwtBearerDefaults.AuthenticationScheme;
    })
    .AddJwtBearer(options =>
    {
        options.TokenValidationParameters = new TokenValidationParameters
        {
            ValidateIssuer = true,
            ValidateAudience = true,
            ValidateLifetime = true,
            ValidateIssuerSigningKey = true,
            ValidIssuer = "yourIssuer",
            ValidAudience = "yourAudience",
            IssuerSigningKey = new SymmetricSecurityKey(Encoding.UTF8.GetBytes("yourSecretKey"))
        };
    });
}`}
      </code>
    </pre>

 <br />

    <h2>2. Authorization in .NET</h2>

    <p>
      <strong style={{paddingBottom:"6px"}}>Authorization</strong> occurs after authentication and determines what resources or actions an authenticated user is allowed to access. In .NET, authorization is typically role-based or policy-based, where specific roles or policies define the level of access a user has.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Types of Authorization:</h3>
    <ul>
      <li><strong>Role-Based Authorization</strong>: Assigns roles to users, and based on those roles, users are granted specific permissions.</li><br />
      <li><strong>Policy-Based Authorization</strong>: Uses policies that define a set of requirements and handlers to control user access to resources.</li><br />
      <li><strong>Claims-Based Authorization</strong>: Grants access based on claims, which are key-value pairs representing user attributes, such as email or department.</li>
    </ul><br />

    <h3>Example: Role-Based Authorization in ASP.NET Core</h3>
    <pre>
      <code>
{`[Authorize(Roles = "Admin")]
public IActionResult AdminDashboard()
{
    return View();
}`}
      </code>
    </pre><br />

    <h3>Example: Policy-Based Authorization in ASP.NET Core</h3>
    <pre>
      <code>
{`public void ConfigureServices(IServiceCollection services)
{
    services.AddAuthorization(options =>
    {
        options.AddPolicy("AdminOnly", policy =>
            policy.RequireRole("Admin"));
    });
}

[Authorize(Policy = "AdminOnly")]
public IActionResult AdminDashboard()
{
    return View();
}`}
      </code>
    </pre>

<br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Authentication and Authorization in .NET</h2>

    <ul>
      <li><strong>Use HTTPS</strong>: Always secure communication with SSL/TLS to prevent attacks like man-in-the-middle.</li><br />
      <li><strong>Strong Password Policies</strong>: Implement password policies such as complexity requirements and expiration.</li><br />
      <li><strong>Multi-Factor Authentication (MFA)</strong>: Add an extra layer of security by requiring a second form of verification.</li><br />
      <li><strong>Use Secure Token Storage</strong>: Store tokens (like JWTs) securely, such as in HTTP-only cookies, to protect them from XSS attacks.</li><br />
      <li><strong>Implement Least Privilege</strong>: Ensure users only have the minimum permissions needed for their tasks.</li><br />
      <li><strong>Regularly Update Dependencies</strong>: Keep .NET libraries and security packages up to date to avoid vulnerabilities.</li>
    </ul>

<br />

    <h2>Conclusion</h2>

    <p>
      Authentication and authorization are cornerstones of secure .NET applications. By implementing robust strategies for managing user identities and controlling access, developers can protect sensitive data and ensure that only authorized users can interact with critical parts of the system. Whether using JWT, OAuth2, or role-based and policy-based approaches, it's crucial to follow best practices to build secure applications in .NET.
    </p>
  </div>
)}


{selectedChapter === 'chapter37' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Identity Framework in ASP.NET Core</h1>

    <p>
      Here’s an explanation of <strong>Identity Framework in ASP.NET Core</strong> as part of security in .NET applications, focusing on authentication, user management, and authorization:
    </p>

<br />

    <h3>Identity Framework in ASP.NET Core</h3>

    <p>
      The <strong>ASP.NET Core Identity Framework</strong> is a robust system used to manage user authentication, authorization, and other identity-related features in ASP.NET Core applications. It provides functionality such as user registration, password management, role management, and external login providers (Google, Facebook, etc.).
    </p>

    <p>
      ASP.NET Core Identity integrates seamlessly with the broader ASP.NET Core security model and supports features like role-based and claims-based authorization, making it a powerful tool for managing users and securing your applications.
    </p>

   <br />

    <h3 style={{paddingBottom:"6px"}}>Key Components of Identity Framework</h3>

    <ol>
      <li>
        <strong>Users and Roles:</strong> Identity Framework allows you to create users and assign them roles. Roles help control what users are authorized to do within the application (e.g., Admin, User).
      </li><br />
      <li>
        <strong>Authentication:</strong> Identity manages the login process, ensuring users can authenticate with passwords, two-factor authentication (2FA), or external providers like Google, Facebook, and Microsoft. It supports both cookie-based authentication for web applications and token-based authentication (JWT) for APIs.
      </li><br />
      <li>
        <strong>Authorization:</strong> Identity integrates with the ASP.NET Core authorization system to provide both role-based and policy-based access control. It also supports claims-based authorization, where claims are used to represent the user's identity and access rights.
      </li><br />
      <li>
        <strong>User Management:</strong> Identity provides an API to manage users, such as creating, updating, deleting users, resetting passwords, and locking out users after failed login attempts.
      </li>
    </ol>

 <br />

    <h3>Setting Up Identity in ASP.NET Core</h3>

    <p style={{paddingBottom:"6px"}}>To use Identity in an ASP.NET Core application, follow these steps:</p>

    <h4>Step 1: Install the Identity Package</h4>
    <pre>
      <code>dotnet add package Microsoft.AspNetCore.Identity.EntityFrameworkCore</code>
    </pre><br />

    <h4>Step 2: Configure Identity in `Startup.cs`</h4>
    <pre>
      <code>
        {`
public void ConfigureServices(IServiceCollection services)
{
    services.AddIdentity<IdentityUser, IdentityRole>()
        .AddEntityFrameworkStores<ApplicationDbContext>()
        .AddDefaultTokenProviders();

    services.ConfigureApplicationCookie(options =>
    {
        options.LoginPath = "/Account/Login";
        options.AccessDeniedPath = "/Account/AccessDenied";
    });

    services.AddControllersWithViews();
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage();
    }
    else
    {
        app.UseExceptionHandler("/Home/Error");
        app.UseHsts();
    }

    app.UseHttpsRedirection();
    app.UseStaticFiles();
    app.UseRouting();

    app.UseAuthentication();
    app.UseAuthorization();

    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllerRoute(
            name: "default",
            pattern: "{controller=Home}/{action=Index}/{id?}");
    });
}
        `}
      </code>
    </pre><br />

    <h4>Step 3: Create the Identity Models and DbContext</h4>
    <pre>
      <code>
        {`
public class ApplicationDbContext : IdentityDbContext<IdentityUser>
{
    public ApplicationDbContext(DbContextOptions<ApplicationDbContext> options)
        : base(options)
    {
    }
}
        `}
      </code>
    </pre><br />

    <h4>Step 4: Migrate the Database</h4>
    <pre>
      <code>dotnet ef migrations add CreateIdentitySchema</code>
      <br />
      <code>dotnet ef database update</code>
    </pre>

    <p>This will generate tables like `AspNetUsers`, `AspNetRoles`, and `AspNetUserRoles` in your database.</p>

   <br />

    <h3>Authentication and User Registration</h3>

    <p style={{paddingBottom:"6px"}}>
      ASP.NET Core Identity provides built-in views for registration, login, and managing user accounts. You can scaffold these views using the following command:
    </p>

    <pre>
      <code>dotnet aspnet-codegenerator identity -dc ApplicationDbContext</code>
    </pre><br />

    <h4>Example: User Registration in ASP.NET Core Identity</h4>
    <pre>
      <code>
        {`
[HttpPost]
[ValidateAntiForgeryToken]
public async Task<IActionResult> Register(RegisterViewModel model)
{
    if (ModelState.IsValid)
    {
        var user = new IdentityUser { UserName = model.Email, Email = model.Email };
        var result = await _userManager.CreateAsync(user, model.Password);

        if (result.Succeeded)
        {
            await _signInManager.SignInAsync(user, isPersistent: false);
            return RedirectToAction("Index", "Home");
        }
        foreach (var error in result.Errors)
        {
            ModelState.AddModelError(string.Empty, error.Description);
        }
    }
    return View(model);
}
        `}
      </code>
    </pre>

  <br />

    <h3>Authorization with Identity</h3>

    <p style={{paddingBottom:"6px"}}>
      ASP.NET Core Identity provides role-based and claims-based authorization features. This allows you to control what resources or actions a user can access based on their roles or claims.
    </p>

    <h4>Example: Role-Based Authorization</h4>
    <pre>
      <code>
        {`
[Authorize(Roles = "Admin")]
public IActionResult AdminDashboard()
{
    return View();
}
        `}
      </code>
    </pre><br />

    <h4>Example: Claims-Based Authorization</h4>
    <pre>
      <code>
        {`
public void ConfigureServices(IServiceCollection services)
{
    services.AddAuthorization(options =>
    {
        options.AddPolicy("AdminOnly", policy => policy.RequireClaim("AdminClaim"));
    });
}

[Authorize(Policy = "AdminOnly")]
public IActionResult AdminDashboard()
{
    return View();
}
        `}
      </code>
    </pre>
<br />

    <h3>External Authentication Providers</h3>

    <p style={{paddingBottom:"6px"}}>
      ASP.NET Core Identity allows integration with external authentication providers like Google, Facebook, Microsoft, etc. You can configure them by registering the provider in `Startup.cs`.
    </p>

    <h4>Example: Google Authentication</h4>
    <pre>
      <code>
        {`
public void ConfigureServices(IServiceCollection services)
{
    services.AddAuthentication()
        .AddGoogle(options =>
        {
            options.ClientId = "your-client-id";
            options.ClientSecret = "your-client-secret";
        });
}
        `}
      </code>
    </pre>

 <br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices for ASP.NET Core Identity</h3>

    <ul>
      <li><strong>Use Strong Password Policies:</strong> Configure password policies to enforce strong passwords for users (e.g., minimum length, complexity).</li><br />
      <li><strong>Enable Multi-Factor Authentication (MFA):</strong> Add an extra layer of security by requiring MFA.</li><br />
      <li><strong>Use Claims and Roles Appropriately:</strong> Use roles for broad access control and claims for more granular, specific access decisions.</li><br />
      <li><strong>Regularly Update Identity Packages:</strong> Keep your ASP.NET Core Identity libraries updated to ensure you're using the latest security patches.</li>
    </ul>
<br />

    <h3>Conclusion</h3>

    <p>
      The Identity Framework in ASP.NET Core provides a complete solution for managing authentication and authorization in modern web applications. By using built-in features like user roles, claims, and external authentication providers, developers can secure applications with ease while following best practices to protect sensitive user data. Whether you're building a small app or a large enterprise system, ASP.NET Core Identity offers flexibility and security for managing user identities and access control.
    </p>
  </div>
)}

{selectedChapter === 'chapter38' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Securing APIs with JWT (JSON Web Tokens)</h1>

    <p>JSON Web Tokens (JWT) provide a secure and stateless way to authenticate users in .NET APIs. JWT consists of a header, payload, and signature.</p><br />
    
    <h2 style={{paddingBottom:"6px"}}>Key Benefits of JWT</h2>
    <ul>
      <li>Stateless Authentication</li><br />
      <li>Cross-Domain Support</li><br />
      <li>Compact and Secure</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Setting Up JWT in ASP.NET Core</h2>
    <h3>Step 1: Install Packages</h3>
    <pre><code>dotnet add package Microsoft.AspNetCore.Authentication.JwtBearer{'\n'}dotnet add package Microsoft.IdentityModel.Tokens</code></pre><br />

    <h3>Step 2: Configure JWT in <code>Startup.cs</code></h3>
    <pre><code>{`
services.AddAuthentication()
    .AddJwtBearer(options =>
    {
        options.TokenValidationParameters = new TokenValidationParameters
        {
            ValidateIssuer = true,
            ValidateAudience = true,
            ValidateLifetime = true,
            ValidateIssuerSigningKey = true,
            ValidIssuer = "issuer.com",
            ValidAudience = "audience.com",
            IssuerSigningKey = new SymmetricSecurityKey(Encoding.ASCII.GetBytes("SecretKey"))
        };
    });`}</code></pre><br />

    <h3>Step 3: Generate Token</h3>
    <pre><code>{`
public string GenerateJwtToken(string username)
{
    var claims = new[] { new Claim(JwtRegisteredClaimNames.Sub, username) };
    var token = new JwtSecurityTokenHandler().WriteToken(new JwtSecurityToken(issuer, audience, claims));
    return token;
}`}</code></pre><br />

    <h3>Step 4: Protect Endpoints</h3>
    <pre><code>{`
[Authorize]
[HttpGet("data")]
public IActionResult GetSecureData() => Ok("Protected data");`}</code></pre><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
    <ul>
      <li>Use strong keys and HTTPS</li><br />
      <li>Set token expiration and refresh mechanisms</li><br />
      <li>Validate tokens on every request</li>
    </ul><br />

    <p>JWT is a powerful tool for securing APIs in .NET. Implementing token-based authentication enhances scalability and security.</p>
  </div>
)}


{selectedChapter === 'chapter39' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Protection and Encryption</h1>
  
    <p>
      In .NET programming, <strong>data protection</strong> is crucial to safeguarding sensitive data like user credentials, personal information, and confidential business data. 
      It involves using encryption techniques to ensure the confidentiality, integrity, and security of data both in transit and at rest.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Encryption Concepts in .NET</h2>

    <h3>1. Symmetric Encryption:</h3>
    <p style={{paddingBottom:"6px"}}>
      - Uses the same key for both encryption and decryption.<br/>
      - Common algorithms: <strong>AES (Advanced Encryption Standard)</strong>.<br/>
      - Suitable for encrypting large amounts of data efficiently.
    </p>

    <p><strong>Example using AES in .NET:</strong></p>
    <pre>
      <code>{`using (Aes aesAlg = Aes.Create()) {
    aesAlg.Key = Encoding.UTF8.GetBytes("YourSecretKey");
    aesAlg.IV = new byte[16]; // Initialization Vector

    ICryptoTransform encryptor = aesAlg.CreateEncryptor(aesAlg.Key, aesAlg.IV);
    // Encryption logic here...
}`}</code>
    </pre><br />

    <h3>2. Asymmetric Encryption:</h3>
    <p style={{paddingBottom:"6px"}}>
      - Uses a public key for encryption and a private key for decryption.<br/>
      - Common algorithms: <strong>RSA</strong>.<br/>
      - Ideal for encrypting small data like keys or sensitive credentials.
    </p>

    <p><strong>Example using RSA in .NET:</strong></p>
    <pre>
      <code>{`using (RSA rsa = RSA.Create()) {
    rsa.ImportRSAPublicKey(publicKey, out _);
    byte[] encryptedData = rsa.Encrypt(dataToEncrypt, RSAEncryptionPadding.Pkcs1);
}`}</code>
    </pre><br />

    <h3>3. Hashing:</h3>
    <p style={{paddingBottom:"6px"}}>
      - Converts data into a fixed-size hash, ensuring data integrity.<br/>
      - Common algorithms: <strong>SHA256, SHA512</strong>.<br/>
      - Often used for password storage or verification.
    </p>

    <p><strong>Example using SHA256:</strong></p>
    <pre>
      <code>{`using (SHA256 sha256 = SHA256.Create()) {
    byte[] hashBytes = sha256.ComputeHash(Encoding.UTF8.GetBytes("password"));
}`}</code>
    </pre><br />

    <h3>4. Data Protection API (DPAPI):</h3>
    <p style={{paddingBottom:"6px"}}>
      - Built into .NET Core to protect sensitive data using <strong>ASP.NET Core Data Protection</strong> APIs.<br/>
      - It manages encryption keys automatically and ensures secure storage of data, such as tokens or cookies.
    </p>

    <p><strong>Example using Data Protection API:</strong></p>
    <pre>
      <code>{`var protector = _dataProtectionProvider.CreateProtector("DataProtectionPurpose");
string protectedData = protector.Protect("sensitive data");`}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Data Protection in .NET</h2>

    <ul>
      <li><strong>Use HTTPS</strong> to encrypt data in transit.</li><br />
      <li><strong>Rotate encryption keys</strong> periodically to limit exposure of compromised keys.</li><br />
      <li><strong>Avoid hard-coding secrets</strong> (e.g., API keys) directly in code; use tools like <strong>Azure Key Vault</strong> for secure storage.</li><br />
      <li><strong>Protect sensitive data at rest</strong> using encryption, such as encrypting databases and files.</li>
    </ul><br />

    <p>
      By following these practices, you can ensure robust security for your .NET applications, keeping data protected from unauthorized access and tampering.
    </p>
  </div>
)}



{selectedChapter === 'chapter40' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Unit Testing in .NET</h1>



    <p>
      Unit testing is a crucial aspect of software development, especially in .NET programming, as it helps ensure the reliability and correctness of code. 
      Unit tests are automated tests that validate individual components (or "units") of an application to ensure they behave as expected.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts in Unit Testing</h2>
    <ol>
      <li>
        <strong style={{paddingBottom:"6px"}}>Unit Tests:</strong>
        <ul>
          <li>Small, focused tests that validate the behavior of a single method or function.</li><br />
          <li>Typically written using testing frameworks like <strong>xUnit</strong>, <strong>NUnit</strong>, or <strong>MSTest</strong> in .NET.</li>
        </ul>
      </li><br />
      <li>
        <strong style={{paddingBottom:"6px"}}>Test-Driven Development (TDD):</strong>
        <ul>
          <li>A development approach where tests are written before the actual code.</li><br />
          <li>Encourages better code design and ensures the code meets the requirements.</li>
        </ul>
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Example: Unit Testing with xUnit in .NET</h2>
    <h3>Step 1: Install xUnit via NuGet Package Manager</h3>
    <pre>
      <code>
        dotnet add package xunit
      </code>
    </pre><br />

    <h3>Step 2: Create a Simple Test</h3>
    <pre>
      <code>
{`using Xunit;

public class CalculatorTests
{
    [Fact]
    public void Add_ReturnsCorrectSum()
    {
        // Arrange
        var calculator = new Calculator();
        
        // Act
        var result = calculator.Add(2, 3);
        
        // Assert
        Assert.Equal(5, result);
    }
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Key Best Practices</h2>
    <ul>
      <li><strong>Write small, isolated tests</strong> for each function or method.</li><br />
      <li><strong>Ensure tests are repeatable</strong> and independent.</li><br />
      <li><strong>Name tests clearly</strong> to describe the intended behavior.</li><br />
      <li><strong>Run tests frequently</strong> to catch issues early in the development process.</li>
    </ul><br />

    <p>
      Unit testing in .NET helps in catching bugs early, improving code quality, and simplifying future modifications.
    </p>
  </div>
)}


{selectedChapter === 'chapter41' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Using xUnit or NUnit for Testing in .NET</h1>

    <p>
      xUnit and NUnit are popular testing frameworks for .NET applications that facilitate unit testing, integration testing, and functional testing. They provide a structured way to write and execute tests, helping developers ensure their code is reliable and maintainable.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Choosing Between xUnit and NUnit</h2>
    <ul>
      <li>
        <strong>xUnit:</strong>
        <ul>
          <li>Designed for modern .NET applications, including .NET Core.</li><br />
          <li>Encourages the use of constructor injection for setup.</li><br />
          <li>Supports parallel test execution by default.</li>
        </ul>
      </li><br />
      <li>
        <strong>NUnit:</strong>
        <ul>
          <li>One of the oldest and most widely used frameworks.</li><br />
          <li>Offers a rich set of assertions and attributes.</li><br />
          <li>Supports data-driven tests through parameterized tests.</li>
        </ul>
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Getting Started with xUnit</h2>
    <h3>Step 1:</h3>
    <p>Install xUnit via NuGet package manager:</p>
    <pre>
      <code>dotnet add package xunit</code>
      <br />
      <code>dotnet add package xunit.runner.visualstudio</code>
    </pre><br />

    <h3>Step 2:</h3>
    <p>Create a simple test:</p>
    <pre>
      <code>{`
        using Xunit;

        public class MathTests
        {{
            [Fact]
            public void Multiply_ReturnsCorrectProduct()
            {{
                // Arrange
                var calculator = new Calculator();
                
                // Act
                var result = calculator.Multiply(4, 5);
                
                // Assert
                Assert.Equal(20, result);
            }}
        }}
      `}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Getting Started with NUnit</h2>
    <h3>Step 1:</h3>
    <p>Install NUnit via NuGet package manager:</p>
    <pre>
      <code>dotnet add package NUnit</code>
      <br />
      <code>dotnet add package NUnit3TestAdapter</code>
    </pre><br />

    <h3>Step 2:</h3>
    <p>Create a simple test:</p>
    <pre>
      <code>{`
        using NUnit.Framework;

        [TestFixture]
        public class MathTests
        {{
            [Test]
            public void Multiply_ReturnsCorrectProduct()
            {{
                // Arrange
                var calculator = new Calculator();
                
                // Act
                var result = calculator.Multiply(4, 5);
                
                // Assert
                Assert.AreEqual(20, result);
            }}
        }}
      `}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Testing with xUnit or NUnit</h2>
    <ul>
      <li><strong>Organize Tests:</strong> Group related tests in the same class to improve readability.</li><br />
      <li><strong>Use Descriptive Names:</strong> Name tests clearly to indicate their purpose and expected behavior.</li><br />
      <li><strong>Keep Tests Independent:</strong> Ensure tests do not depend on the results of other tests to avoid cascading failures.</li><br />
      <li><strong>Run Tests Regularly:</strong> Incorporate testing into your continuous integration pipeline to catch issues early.</li>
    </ul><br />

    <p>
      By using xUnit or NUnit, you can enhance the testing capabilities of your .NET applications, leading to more robust and maintainable code.
    </p>
  </div>
)}

{selectedChapter === 'chapter42' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Writing Testable Code</h1>



    <p>
      Writing testable code is essential in .NET programming as it allows developers to create applications that are easier to maintain, debug, and extend. Below are some key principles and practices that contribute to writing testable code:
    </p><br />

    <h3>1. Single Responsibility Principle (SRP)</h3>
    <p>
      Each class or method should have one responsibility or purpose. This makes it easier to test individual components in isolation.
    </p>
    <p><strong>Example:</strong> A class that handles user authentication should not also manage user data storage. Separate these concerns into different classes.</p><br />

    <h3>2. Dependency Injection (DI)</h3>
    <p>
      Use DI to manage dependencies rather than creating them within classes. This allows for easier mocking of dependencies in tests.
    </p>
    <pre><code>
{`public class UserService
{
    private readonly IUserRepository _userRepository;

    public UserService(IUserRepository userRepository)
    {
        _userRepository = userRepository;
    }
}`}
    </code></pre><br />

    <h3>3. Avoid Static Methods</h3>
    <p>
      Static methods can make it difficult to mock and test code since they cannot be overridden. Prefer instance methods that can be easily substituted in tests.
    </p>
    <p><strong>Example:</strong> Instead of using a static utility class for calculations, create an interface and an implementation that can be injected.</p><br />

    <h3>4. Use Interfaces</h3>
    <p>
      Define interfaces for your classes. This decouples the implementation from the interface, making it easier to swap out components in tests.
    </p>
    <pre><code>
{`public interface IMessageService
{
    void SendMessage(string message);
}

public class EmailService : IMessageService
{
    public void SendMessage(string message)
    {
        // Send email
    }
}`}
    </code></pre><br />

    <h3>5. Write Small, Focused Methods</h3>
    <p>
      Methods should perform a single task or action. This increases readability and makes it easier to test.
    </p>
    <p><strong>Example:</strong> A method that handles multiple responsibilities can be split into smaller methods, each handling one aspect.</p><br />

    <h3>6. Use Meaningful Names</h3>
    <p>
      Give classes, methods, and variables meaningful names that clearly describe their purpose. This aids understanding and makes tests easier to write.
    </p>
    <p><strong>Example:</strong> Instead of naming a method <code>DoWork()</code>, use <code>CalculateTotalPrice()</code>.</p><br />

    <h3>7. Test-Driven Development (TDD)</h3>
    <p>
      Embrace TDD by writing tests before writing the actual code. This practice encourages writing only the code necessary to pass the tests, resulting in more focused and simpler implementations.
    </p>
    <p><strong>Example:</strong> Write a test case for a method before you implement that method, ensuring that the code meets the required behavior.</p><br />

    <h3>8. Handle Exceptions Gracefully</h3>
    <p>
      Design your code to handle exceptions properly without crashing the application. This allows tests to simulate error scenarios and verify that the application behaves as expected.
    </p>
    <p><strong>Example:</strong> Use try-catch blocks where appropriate and log errors for analysis.</p><br />

    <h3>9. Maintain a Clear Separation of Concerns</h3>
    <p>
      Organize code into layers (e.g., presentation, business logic, data access) to isolate responsibilities. This makes it easier to test each layer independently.
    </p>
    <p><strong>Example:</strong> Use MVC (Model-View-Controller) or MVVM (Model-View-ViewModel) architectures to separate business logic from UI concerns.</p><br />

    <h2>Conclusion</h2>
    <p>
      Writing testable code in .NET programming requires following best practices that promote simplicity, modularity, and clarity. By adhering to these principles, developers can create robust applications that are easier to test, maintain, and evolve over time. This not only enhances the quality of the code but also reduces the time and effort required for testing and debugging.
    </p>
  </div>
)}


{selectedChapter === 'chapter43' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Mocking Dependencies in .NET Programming</h1>

    <p>
      Mocking dependencies in .NET programming is an essential practice in unit testing that allows developers to simulate the behavior of complex objects that a unit of code interacts with. This approach enables testing components in isolation, making it easier to verify that the code behaves as expected without requiring the actual implementations of its dependencies.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Why Mock Dependencies?</h2>
    <ol>
      <li>
        <strong>Isolation of Tests:</strong> By mocking dependencies, you can isolate the unit of work being tested. This isolation ensures that tests focus only on the logic within the unit without being affected by external factors, such as database states, network calls, or other external services.
      </li><br />
      <li>
        <strong>Control Over Behavior:</strong> Mocks allow you to define how dependencies should behave during the test. You can specify return values for methods or throw exceptions to simulate different scenarios, making it possible to test how the unit handles various situations.
      </li><br />
      <li>
        <strong>Performance:</strong> Testing with mocks is generally faster than using real implementations, especially if those implementations involve heavy operations like database access or API calls.
      </li><br />
      <li>
        <strong>Easier to Test Edge Cases:</strong> Mocking enables you to create specific conditions that may be difficult to replicate with real objects, such as error states or timeouts.
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Tools for Mocking in .NET</h2>
    <ul>
      <li>
        <strong>Moq:</strong> A popular mocking framework for .NET that allows you to create mock objects and set up expectations in a fluent and easy-to-read manner.
      </li><br />
      <li>
        <strong>NSubstitute:</strong> A mocking library that focuses on simplicity and readability, allowing you to substitute dependencies with minimal boilerplate code.
      </li><br />
      <li>
        <strong>FakeItEasy:</strong> A simple library for creating fake objects for unit tests.
      </li>
    </ul><br />

    <h2>Example of Mocking with Moq</h2>
    <p>Here’s an example to illustrate how to mock a dependency using Moq in .NET:</p>

    <pre>
      <code>
{`using Moq;
using Xunit;

// Service to be tested
public interface IOrderService
{
    decimal GetOrderTotal(int orderId);
}

// Class under test
public class OrderCalculator
{
    private readonly IOrderService _orderService;

    public OrderCalculator(IOrderService orderService)
    {
        _orderService = orderService;
    }

    public decimal CalculateTotal(int orderId)
    {
        return _orderService.GetOrderTotal(orderId);
    }
}

// Unit test
public class OrderCalculatorTests
{
    [Fact]
    public void CalculateTotal_ReturnsCorrectAmount()
    {
        // Arrange
        var mockOrderService = new Mock<IOrderService>();
        mockOrderService.Setup(service => service.GetOrderTotal(It.IsAny<int>())).Returns(100.0m);

        var calculator = new OrderCalculator(mockOrderService.Object);

        // Act
        var result = calculator.CalculateTotal(1);

        // Assert
        Assert.Equal(100.0m, result);
    }
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Explanation of the Example</h2>
    <ol>
      <li>
        <strong>Interface and Class Under Test:</strong> <code>IOrderService</code> is an interface representing a service that retrieves order totals. <code>OrderCalculator</code> is the class being tested, which relies on <code>IOrderService</code>.
      </li><br />
      <li>
        <strong>Mock Creation:</strong> A mock of <code>IOrderService</code> is created using <code>new Mock&lt;IOrderService&gt;()</code>.
      </li><br />
      <li>
        <strong>Setting Up Expectations:</strong> The <code>Setup</code> method defines the behavior of the <code>GetOrderTotal</code> method, returning a specific value (100.0) for any integer argument.
      </li><br />
      <li>
        <strong>Injecting the Mock:</strong> The mock object (<code>mockOrderService.Object</code>) is passed to the <code>OrderCalculator</code> constructor.
      </li><br />
      <li>
        <strong>Executing the Test:</strong> The <code>CalculateTotal</code> method is called, and the result is asserted against the expected value.
      </li>
    </ol><br />

    <h2>Conclusion</h2>
    <p>
      Mocking dependencies is a powerful technique in .NET programming that enhances unit testing by allowing developers to isolate the code under test, control the behavior of dependencies, and test various scenarios effectively. By leveraging mocking frameworks like Moq, NSubstitute, or FakeItEasy, developers can write cleaner, more reliable tests that contribute to better software quality.
    </p>
  </div>
)}


{selectedChapter === 'chapter44' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Deploying a .NET Application to IIS</h1>

    <p>
      Deploying a .NET application to Internet Information Services (IIS) is an essential skill for .NET developers, as it enables them to host web applications on a Windows server. This process involves several steps, including preparing the application, configuring IIS, and deploying the application files. Below is a detailed guide on how to deploy a .NET application to IIS.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Prerequisites</h2>
    <ul>
      <li><strong>IIS Installed</strong>: Ensure that IIS is installed on your Windows server. You can install it through the Windows Features dialog.</li><br />
      <li><strong>.NET Framework or .NET Core</strong>: Make sure the appropriate .NET runtime is installed on the server for the application you are deploying.</li><br />
      <li><strong>Administrator Access</strong>: You need administrator privileges on the server to configure IIS.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Steps to Deploy a .NET Application to IIS</h2>

    <h3>1. Publish Your Application</h3>
    <p style={{paddingBottom:"6px"}}>
      Start by publishing your .NET application. This step compiles the code and prepares it for deployment.
    </p>
    <h4 style={{paddingBottom:"6px"}}>Using Visual Studio:</h4>
    <ol>
      <li>Right-click on your project in Solution Explorer.</li><br />
      <li>Select <strong>Publish</strong>.</li><br />
      <li>Choose a publishing target (e.g., Folder, Azure, etc.).</li><br />
      <li>Configure the settings (e.g., target framework, deployment mode).</li><br />
      <li>Click <strong>Publish</strong> to generate the deployment files.</li>
    </ol><br />
    
    <h4>Using Command Line:</h4>
    <pre>
      <code>dotnet publish -c Release -o ./publish</code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>2. Set Up IIS</h3>
    <h4>Open IIS Manager:</h4>
    <ol>
      <li>Press <code>Windows + R</code>, type <code>inetmgr</code>, and press Enter.</li>
    </ol><br />
    
    <h4 style={{paddingBottom:"6px"}}>Create a New Site:</h4>
    <ol>
      <li>Right-click on <strong>Sites</strong> and select <strong>Add Website</strong>.</li><br />
      <li>Enter a <strong>Site Name</strong>.</li><br />
      <li>Set the <strong>Physical Path</strong> to the folder where your application was published.</li><br />
      <li>Choose a <strong>Port</strong> (default is 80) or use a different port if needed.</li><br />
      <li>Click <strong>OK</strong> to create the site.</li>
    </ol><br />

    <h4 style={{paddingBottom:"6px"}}>Configure Application Pool:</h4>
    <ol>
      <li>Right-click on your site, go to <strong>Manage Application</strong>, and select <strong>Advanced Settings</strong>.</li><br />
      <li>Set the <strong>.NET CLR Version</strong> accordingly (e.g., <strong>v4.0</strong> for .NET Framework or <strong>No Managed Code</strong> for .NET Core).</li>
    </ol><br />

    <h3>3. Configure Permissions</h3>
    <p style={{paddingBottom:"6px"}}>
      Ensure that the IIS user (usually <code>IIS_IUSRS</code>) has the necessary permissions to access the application's files.
    </p>
    <ol>
      <li>Navigate to your application's folder.</li><br />
      <li>Right-click on the folder, select <strong>Properties</strong>, and go to the <strong>Security</strong> tab.</li><br />
      <li>Click <strong>Edit</strong> and add <code>IIS_IUSRS</code>, granting it <strong>Read & Execute</strong>, <strong>List Folder Contents</strong>, and <strong>Read</strong> permissions.</li>
    </ol><br />

    <h3>4. Test the Application</h3>
    <p>
      Open a web browser and navigate to <code>http://localhost</code> (or the configured port). You should see your application running. If you face issues, check the following:
    </p>
    <ul>
      <li><strong>IIS Logs</strong>: Located in <code>C:\inetpub\logs\LogFiles</code>.</li><br />
      <li><strong>Event Viewer</strong>: Check for any application errors in the Windows Event Viewer.</li>
    </ul>

    <h3 style={{paddingBottom:"6px"}}>5. Additional Configuration (Optional)</h3>
    <ul>
      <li><strong>Enable HTTPS</strong>: You can secure your application by setting up an SSL certificate.</li><br />
      <li><strong>URL Rewrite</strong>: If using ASP.NET Core, consider adding URL Rewrite rules for better routing.</li><br />
      <li><strong>Error Pages</strong>: Customize error pages for a better user experience.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Deploying a .NET application to IIS is a straightforward process that involves publishing the application, configuring IIS, and ensuring proper permissions. Following these steps will help you host your .NET applications efficiently, providing a reliable platform for users to access your services.
    </p>
  </div>
)}




{selectedChapter === 'chapter45' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Hosting ASP.NET Core Application in Azure</h1>
    <p>
      Hosting an ASP.NET Core application in Azure is an effective way to take advantage of cloud scalability, reliability, and ease of management. This guide walks you through the essential steps to host your ASP.NET Core application in Microsoft Azure.
    </p><br />

    <h2>Prerequisites</h2>
    <ul>
      <li>
        <strong>Azure Subscription</strong>: You will need an active Azure subscription. You can create a free account if you don't have one.
      </li><br />
      <li>
        <strong>ASP.NET Core Application</strong>: Ensure you have a working ASP.NET Core application ready for deployment.
      </li><br />
      <li>
        <strong>Azure CLI or Azure Portal Access</strong>: Familiarity with using the Azure Portal or Azure CLI.
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Steps to Host ASP.NET Core Application in Azure</h2>

    <h3>1. Publish Your Application</h3>
    <p>Before hosting, publish your application to prepare it for Azure.</p>
    <ul>
      <li>
        <strong>Using Visual Studio</strong>:
        <ul>
          <li>Right-click your project in Solution Explorer and select <strong>Publish</strong>.</li><br />
          <li>Choose <strong>Azure</strong> as the target and follow the prompts to select your Azure subscription and resource group.</li><br />
          <li>Configure your App Service settings and click <strong>Publish</strong>.</li>
        </ul>
      </li><br />
      <li>
        <strong>Using Command Line</strong>:
        <pre>
          <code>
            {`dotnet publish -c Release -o ./publish`}
          </code>
        </pre>
      </li>
    </ul><br />

    <h3>2. Create an Azure App Service</h3>
    <ol>
      <li>
        <strong>Log in to Azure Portal</strong>:
        <ul>
          <li>Go to <a href="https://portal.azure.com" target="_blank" rel="noopener noreferrer">Azure Portal</a> and sign in.</li>
        </ul>
      </li><br />
      <li>
        <strong>Create a New App Service</strong>:
        <ul>
          <li>Click on <strong>Create a resource</strong> {">"} <strong>Web + Mobile</strong>{">"} <strong>Web App</strong>.</li><br />
          <li>Fill in the necessary details:
            <ul>
              <li><strong>App name</strong>: A unique name for your web app.</li><br />
              <li><strong>Subscription</strong>: Choose your subscription.</li><br />
              <li><strong>Resource group</strong>: Create a new or select an existing resource group.</li><br />
              <li><strong>Publish</strong>: Select <strong>Code</strong>.</li><br />
              <li><strong>Runtime stack</strong>: Choose the appropriate <strong>.NET Core</strong> version.</li><br />
              <li><strong>Region</strong>: Select the region closest to your users.</li>
            </ul>
          </li><br />
          <li>Click <strong>Review + Create</strong>, and then click <strong>Create</strong>.</li>
        </ul>
      </li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>3. Configure App Settings</h3>
    <ol>
      <li>
        <strong>Navigate to Your App Service</strong>:
        <ul>
          <li>Go to your App Service in the Azure Portal.</li>
        </ul>
      </li><br />
      <li>
        <strong>Configure Application Settings</strong>:
        <ul>
          <li>Under <strong>Settings</strong>, select <strong>Configuration</strong>.</li><br />
          <li>Add any necessary application settings, such as connection strings, API keys, etc.</li>
        </ul>
      </li>
    </ol><br />

    <h3>4. Deploy Your Application</h3>
    <p>You can deploy your application using different methods:</p>
    <ul>
      <li>
        <strong>Using Visual Studio</strong>:
        <ul>
          <li>Click <strong>Publish</strong> again in Visual Studio, and it will deploy to the Azure App Service.</li>
        </ul>
      </li><br />
      <li>
        <strong>Using Azure CLI</strong>:
        <pre>
          <code>
            {`az webapp up --name <your-app-name> --resource-group <your-resource-group>`}
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Using FTP or Web Deploy</strong>: 
        <ul>
          <li>In the Azure Portal, under <strong>Deployment Center</strong>, you can configure deployment options like FTP or GitHub integration.</li>
        </ul>
      </li><br />
    </ul>

    <h3>5. Monitor Your Application</h3>
    <p>Once deployed, you can monitor your application using Azure's built-in tools:</p>
    <ul>
      <li><strong>Application Insights</strong>: Enable Application Insights for real-time monitoring, diagnostics, and analytics.</li><br />
      <li><strong>Log Stream</strong>: Access live logs to troubleshoot issues directly from the Azure Portal.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Hosting an ASP.NET Core application in Azure offers a powerful platform for scaling and managing your web applications. By following the steps outlined above, you can deploy your application with ease, taking advantage of Azure's capabilities to enhance performance and reliability.
    </p>
  </div>
)}


{selectedChapter === 'chapter46' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Docker and Containerization</h1>

    <p>
      Docker and containerization represent a revolutionary approach to application deployment and management. This guide will introduce you to Docker and explain how it integrates with .NET programming to enhance application development, testing, and deployment processes.
    </p><br />

    <h2>What is Docker?</h2>
    <p>
      Docker is an open-source platform that automates the deployment, scaling, and management of applications within containers. A container is a lightweight, standalone, and executable package that includes everything needed to run a piece of software, including the code, runtime, libraries, and system tools.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Why Use Docker?</h2>
    <ul>
      <li><strong>Portability</strong>: Containers encapsulate an application and its dependencies, making it easy to run consistently across different environments (development, testing, production).</li><br />
      <li><strong>Isolation</strong>: Each container runs in its own isolated environment, allowing multiple applications to run on the same host without conflicts.</li><br />
      <li><strong>Scalability</strong>: Docker makes it easy to scale applications up or down quickly, responding to varying demand.</li><br />
      <li><strong>Efficiency</strong>: Containers share the host OS kernel, which makes them more lightweight than traditional virtual machines (VMs) and helps optimize resource usage.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}} >Docker Terminology</h2>
    <ul>
      <li><strong>Image</strong>: A lightweight, standalone, and executable package that includes everything needed to run a software application.</li><br />
      <li><strong>Container</strong>: A running instance of a Docker image. It is the actual environment where your application runs.</li><br />
      <li><strong>Dockerfile</strong>: A script that contains a series of instructions on how to build a Docker image.</li><br />
      <li><strong>Docker Hub</strong>: A cloud-based registry where Docker images can be stored and shared.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Getting Started with Docker and .NET</h2>

    <h3>1. Install Docker</h3>
    <ul>
      <li>Download and install Docker Desktop for Windows or Mac from the <a href="https://www.docker.com/products/docker-desktop" target="_blank" rel="noopener noreferrer">Docker website</a>.</li><br />
      <li>For Linux, follow the installation instructions specific to your distribution.</li>
    </ul><br />


    <h3>2. Create a Simple ASP.NET Core Application</h3>
    <pre>
      <code>
        {`dotnet new webapp -n MyDockerApp
cd MyDockerApp`}
      </code>
    </pre><br />

    <h3>3. Create a Dockerfile</h3>
    <p>In the root of your project, create a file named <code>Dockerfile</code> with the following content:</p>
    <pre>
      <code>
        {`# Use the official ASP.NET Core runtime as a base image
FROM mcr.microsoft.com/dotnet/aspnet:6.0 AS base
WORKDIR /app
EXPOSE 80

# Use the SDK image to build the application
FROM mcr.microsoft.com/dotnet/sdk:6.0 AS build
WORKDIR /src
COPY ["MyDockerApp.csproj", "./"]
RUN dotnet restore "./MyDockerApp.csproj"
COPY . .
WORKDIR "/src/."
RUN dotnet build "MyDockerApp.csproj" -c Release -o /app/build

# Publish the application
FROM build AS publish
RUN dotnet publish "MyDockerApp.csproj" -c Release -o /app/publish

# Final stage
FROM base AS final
WORKDIR /app
COPY --from=publish /app/publish .
ENTRYPOINT ["dotnet", "MyDockerApp.dll"]`}
      </code>
    </pre><br />

    <h3>4. Build the Docker Image</h3>
    <p>Open a terminal and navigate to your project directory, then run:</p>
    <pre>
      <code>docker build -t mydockerapp .</code>
    </pre><br />

    <h3>5. Run the Docker Container</h3>
    <p>After the image is built, run the container using:</p>
    <pre>
      <code>docker run -d -p 8080:80 mydockerapp</code>
    </pre>
    <p>Your ASP.NET Core application should now be running in a Docker container. You can access it by navigating to <a href="http://localhost:8080" target="_blank" rel="noopener noreferrer">http://localhost:8080</a> in your web browser.</p><br />

    <h2>Conclusion</h2>
    <p>
      Docker and containerization provide significant advantages for .NET developers by simplifying deployment processes and enhancing application portability and scalability. By leveraging Docker, developers can create consistent development environments and efficiently manage application lifecycles, ultimately leading to improved productivity and streamlined workflows.
    </p>
  </div>
)}





{selectedChapter === 'chapter47' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Continuous Integration/Continuous Deployment (CI/CD) Practices</h1>

    <p>
      Continuous Integration (CI) and Continuous Deployment (CD) are essential practices in modern software development that allow teams to deliver code changes more frequently and reliably. This guide outlines how to implement CI/CD practices in .NET programming.
    </p><br />

    <h2>What is CI/CD?</h2>
    <p>
      <strong>Continuous Integration (CI)</strong>: A development practice where developers integrate code changes into a shared repository several times a day. Automated builds and tests are run to ensure that new code changes do not break existing functionality.
    </p>
    <p>
      <strong>Continuous Deployment (CD)</strong>: An extension of CI that automates the release of software to production environments. After passing automated tests, code changes are automatically deployed to production without manual intervention.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of CI/CD</h2>
    <ul>
      <li><strong>Faster Release Cycles</strong>: Automating the build, test, and deployment processes enables teams to release new features and fixes quickly.</li><br />
      <li><strong>Improved Code Quality</strong>: Automated testing ensures that new code does not introduce bugs, maintaining a high standard of code quality.</li><br />
      <li><strong>Reduced Deployment Risks</strong>: Smaller, more frequent updates reduce the risk of deployment failures, as there are fewer changes to review and test at any given time.</li><br />
      <li><strong>Enhanced Collaboration</strong>: CI/CD encourages collaboration between developers and operations teams, fostering a DevOps culture.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Setting Up CI/CD for .NET Applications</h2>

    <h3>1. Choose a CI/CD Tool</h3>
    <p>Select a CI/CD tool that fits your team's needs. Popular options include:</p>
    <ul>
      <li><strong>Azure DevOps</strong>: A cloud service that provides CI/CD capabilities for building, testing, and deploying applications.</li><br />
      <li><strong>GitHub Actions</strong>: A feature within GitHub that allows you to automate your workflow, including CI/CD processes.</li><br />
      <li><strong>Jenkins</strong>: An open-source automation server that supports building, deploying, and automating software development.</li>
    </ul><br />

    <h3>2. Create a Build Pipeline</h3>
    <p>Define a build pipeline that automates the building of your .NET application. Here’s a basic example using Azure DevOps:</p>
    <p>
      - Create a New Pipeline in Azure DevOps.<br />
      - Choose your repository and select <strong>Starter Pipeline</strong>.<br />
      - Define your pipeline in YAML:
    </p>

    <pre>
      <code>
        {`trigger:
  branches:
    include:
      - main

pool:
  vmImage: 'windows-latest'

steps:
- task: DotNetCoreCLI@2
  inputs:
    command: 'restore'
    projects: '**/*.csproj'

- task: DotNetCoreCLI@2
  inputs:
    command: 'build'
    projects: '**/*.csproj'
    arguments: '--configuration Release'

- task: DotNetCoreCLI@2
  inputs:
    command: 'publish'
    projects: '**/*.csproj'
    arguments: '--configuration Release --output $(Build.ArtifactStagingDirectory)'
  
- publish: $(Build.ArtifactStagingDirectory)
  artifact: drop`}
      </code>
    </pre><br />

    <h3>3. Configure Automated Testing</h3>
    <p>Integrate automated testing into your pipeline to ensure code quality. This can include:</p>
    <ul>
      <li>Unit tests</li>
      <li>Integration tests</li>
      <li>UI tests</li>
    </ul>
    <p>For .NET applications, use testing frameworks like xUnit or NUnit.</p><br />

    <h3>4. Set Up Continuous Deployment</h3>
    <p>After successful builds, set up deployment steps. This can be done in Azure DevOps by adding deployment jobs in your pipeline:</p>

    <pre>
      <code>
        {`- stage: Deploy
  jobs:
  - deployment: DeployWeb
    environment: 'production'
    strategy:
      runOnce:
        deploy:
          steps:
          - task: AzureWebApp@1
            inputs:
              azureSubscription: 'YourAzureSubscription'
              appName: 'YourWebAppName'
              package: '$(Pipeline.Workspace)/drop/**/*.zip'`}
      </code>
    </pre><br />

    <h3>5. Monitor and Iterate</h3>
    <p>
      Once your CI/CD pipeline is in place, monitor its performance. Use logging and monitoring tools to track the health of your applications. Regularly iterate on your CI/CD processes to improve efficiency and effectiveness.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Implementing CI/CD practices in .NET programming enables teams to deliver high-quality software quickly and reliably. By automating the build, test, and deployment processes, developers can focus more on writing code and less on manual tasks, leading to improved productivity and faster delivery of features to users.
    </p>
  </div>
)}

   
{selectedChapter === 'chapter48' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Profiling and Analyzing Performance</h1>

    <p>
      Profiling and analyzing performance is crucial in .NET programming to ensure applications run efficiently and effectively. This guide provides an overview of performance profiling tools and techniques available for .NET applications.
    </p><br />

    <h2>What is Performance Profiling?</h2>
    <p>
      Performance profiling is the process of measuring the performance characteristics of an application to identify bottlenecks, memory usage issues, and opportunities for optimization. By analyzing various aspects of an application, developers can gain insights into where improvements can be made.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Why is Performance Profiling Important?</h2>
    <ul>
      <li><strong>Optimizing Resource Usage</strong>: Identify components that consume excessive CPU, memory, or other resources.</li><br />
      <li><strong>Improving Application Responsiveness</strong>: Detect slow-running processes that affect user experience.</li><br />
      <li><strong>Reducing Costs</strong>: Optimize applications to run efficiently on cloud resources, potentially lowering costs.</li><br />
      <li><strong>Ensuring Scalability</strong>: Evaluate how well applications perform under increased loads and make adjustments to support growth.</li>
    </ul><br />

    <h2>Performance Profiling Tools for .NET</h2>
    <p>Several tools can help you profile and analyze performance in .NET applications:</p>
    <ul>
      <li><strong>Visual Studio Diagnostic Tools</strong>: Built into Visual Studio, this tool provides performance analysis features, including CPU usage, memory usage, and timeline analysis.</li><br />
      <li><strong>dotTrace</strong>: A commercial profiling tool from JetBrains that offers advanced profiling capabilities, including tracing, memory profiling, and performance snapshots.</li><br />
      <li><strong>PerfView</strong>: A free performance analysis tool from Microsoft that allows you to collect and analyze performance data, focusing on CPU and memory usage.</li><br />
      <li><strong>Application Insights</strong>: A part of Azure Monitor, Application Insights provides real-time monitoring, diagnostics, and analytics for your applications.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>How to Profile a .NET Application</h2>

    <h3 style={{paddingBottom:"6px"}}>1. Using Visual Studio Diagnostic Tools</h3>
    <ol>
      <li>Open your .NET application in Visual Studio.</li><br />
      <li>Go to <strong>Debug</strong> {">"} <strong>Performance Profiler</strong>.</li><br />
      <li>Select the tools you want to use (CPU Usage, Memory Usage, etc.) and click <strong>Start</strong>.</li><br />
      <li>Interact with your application to simulate real usage scenarios.</li><br />
      <li>Stop the profiling session to view detailed reports and insights.</li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>2. Profiling with dotTrace</h3>
    <ol>
      <li>Install and launch dotTrace.</li><br />
      <li>Select your .NET application and choose the profiling mode (e.g., Sampling, Tracing).</li><br />
      <li>Start profiling and perform actions in your application.</li><br />
      <li>Stop the profiling session and analyze the results, focusing on hotspots and slow functions.</li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>3. Analyzing Memory Usage</h3>
    <ol>
      <li>In Visual Studio, open the <strong>Memory Usage</strong> tool in the Diagnostic Tools.</li><br />
      <li>Take snapshots before and after specific actions in your application.</li><br />
      <li>Compare snapshots to identify memory leaks or excessive memory usage.</li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Performance Optimization</h2>
    <ul>
      <li><strong>Use Asynchronous Programming</strong>: Leverage asynchronous programming patterns to improve responsiveness and reduce blocking.</li><br />
      <li><strong>Optimize Database Queries</strong>: Analyze and optimize database queries to minimize execution time and resource consumption.</li><br />
      <li><strong>Implement Caching</strong>: Use caching strategies to store frequently accessed data and reduce load times.</li><br />
      <li><strong>Minimize Object Creation</strong>: Reduce unnecessary object creation to lower memory usage and garbage collection overhead.</li><br />
      <li><strong>Profile Regularly</strong>: Incorporate performance profiling into your development process to catch issues early and continuously optimize performance.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Profiling and analyzing performance in .NET programming is essential for building efficient, responsive applications. By leveraging the right tools and techniques, developers can identify performance bottlenecks and make informed decisions to enhance application performance, ensuring a better experience for users.
    </p>
  </div>
)}




{selectedChapter === 'chapter49' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Memory Management and Garbage Collection</h1>

    <p>
      Memory management is a critical aspect of .NET programming, ensuring that applications efficiently use system resources. This guide explores how memory management works in .NET and the role of garbage collection in maintaining application performance.
    </p>

    <h2>What is Memory Management?**</h2>

    <p>
      Memory management refers to the process of allocating, using, and releasing memory in an application. It involves keeping track of each byte in a computer's memory and managing the allocation of memory for objects created by your application. In .NET, this is primarily handled by the Common Language Runtime (CLR).
    </p>

    <h3>Key Concepts in Memory Management</h3>

    <ul>
      <li>
        <strong>Heap vs. Stack:</strong>
        <ul>
          <li>
            The <strong>stack</strong> is used for static memory allocation, storing value types and reference pointers. It follows a Last In, First Out (LIFO) structure.
          </li><br />
          <li>
            The <strong>heap</strong> is used for dynamic memory allocation, where objects are allocated memory at runtime. The lifetime of these objects is managed by the garbage collector.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Value Types vs. Reference Types:</strong>
        <ul>
          <li>
            <strong>Value types</strong> (e.g., int, float, struct) are stored on the stack, while <strong>reference types</strong> (e.g., class, array, delegate) are stored on the heap. Reference types store a reference to the actual data, allowing multiple variables to reference the same object.
          </li>
        </ul>
      </li>
    </ul><br />

   <h3>Garbage Collection in .NET</h3>

    <p>
      Garbage collection (GC) is an automatic memory management feature of .NET that frees up memory occupied by objects that are no longer in use. The GC helps to prevent memory leaks and optimizes the allocation and deallocation of memory.
    </p><br />

    <h3>How Garbage Collection Works</h3>

    <ul>
      <li>
        <strong>Generational GC:</strong> The .NET garbage collector uses a generational approach, dividing objects into three generations based on their lifetime:
        <ul>
          <li><strong>Generation 0:</strong> Short-lived objects that are collected frequently.</li><br />
          <li><strong>Generation 1:</strong> Long-lived objects that survive the first collection.</li><br />
          <li><strong>Generation 2:</strong> Long-lived objects that are rarely collected.</li>
        </ul>
      </li><br />
      <li>
        <strong>Mark and Sweep Algorithm:</strong> The GC uses a mark-and-sweep algorithm to identify and collect unreachable objects. It marks objects that are still in use and sweeps away the unmarked (unused) objects to free memory.
      </li>
    </ul>
<br />
    <h3>Forcing Garbage Collection</h3>

    <p>
      While the garbage collector runs automatically, developers can also trigger it manually using:
    </p>

    ```csharp
    GC.Collect();
    ```

    <p>
      However, this should be used sparingly, as excessive calls can lead to performance overhead.
    </p><br />

    <h3>Best Practices for Memory Management</h3>

    <ul>
      <li>
        <strong>Dispose Unmanaged Resources:</strong> Implement the <code>IDisposable</code> interface for classes that hold unmanaged resources (like file handles or database connections) to ensure timely cleanup.
      </li><br />
      <li>
        <strong>Use `using` Statements:</strong> Leverage <code>using</code> statements to automatically dispose of resources when they go out of scope.
      </li><br />
      <li>
        <strong>Avoid Memory Leaks:</strong> Be cautious of event handlers and static references that can inadvertently keep objects alive longer than necessary.
      </li><br />
      <li>
        <strong>Profile Memory Usage:</strong> Regularly use profiling tools to monitor memory usage and detect leaks or excessive allocations.
      </li>
    </ul><br />


   <h3>Conclusion</h3> 

    <p>
      Understanding memory management and garbage collection in .NET is crucial for building efficient applications. By following best practices and leveraging the .NET garbage collector, developers can optimize memory usage and enhance application performance, resulting in a better experience for users.
    </p>

  </div>
)}


{selectedChapter === 'chapter50' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Caching Strategies </h1>

    <p>
      Caching is a critical optimization technique in .NET programming that enhances application performance by temporarily storing frequently accessed data. This guide explores various caching strategies available in .NET, helping developers make informed decisions about implementing caching effectively.
    </p><br />

  <h3> What is Caching?</h3>

    <p>
      Caching refers to the process of storing copies of files or data in a cache, allowing future requests for that data to be served faster. Instead of retrieving data from slower sources (like a database or web service), applications can access cached data, significantly improving performance.
    </p><br />

   <h3 style={{paddingBottom:"6px"}}>Benefits of Caching</h3> 

    <ul>
      <li><strong>Reduced Latency:</strong> Accessing data from the cache is typically much faster than querying a database or an external service.</li><br />
      <li><strong>Decreased Load on Resources:</strong> Caching reduces the number of requests made to databases or external services, thereby decreasing load and improving overall system performance.</li><br />
      <li><strong>Enhanced User Experience:</strong> Faster data retrieval leads to a more responsive application, improving user satisfaction.</li>
    </ul><br />

    <h3>Caching Strategies in .NET</h3>

    <p style={{paddingBottom:"6px"}}>
      There are several caching strategies available in .NET:
    </p>

   <h3>1. In-Memory Caching</h3>

    <p>
      In-memory caching stores data in the server's memory, providing fast access for applications running on the same server. This strategy is particularly effective for small to medium datasets.
    </p>
    <p><strong>Use Case:</strong> Suitable for applications that require quick access to frequently used data.</p>
    <p><strong>Implementation:</strong> In .NET, you can use the <code>MemoryCache</code> class from the <code>System.Runtime.Caching</code> namespace.</p>

    <pre>
    <code>{`
    using System.Runtime.Caching;

    var cache = MemoryCache.Default;
    string cacheKey = "dataKey";

    // Check if the data is in cache
    if (!cache.Contains(cacheKey))
    {
        var data = FetchData(); // Fetch data from the database
        cache.Add(cacheKey, data, DateTimeOffset.UtcNow.AddMinutes(10)); // Cache data for 10 minutes
    }
    var cachedData = cache[cacheKey];
 `} </code>
    </pre><br />

  <h3>2. Distributed Caching</h3>

    <p>
      Distributed caching stores data in a central cache server, making it accessible across multiple application instances. This approach is beneficial in cloud applications and microservices architectures, where instances may scale dynamically.
    </p>
    <p><strong>Use Case:</strong> Ideal for applications running on multiple servers or in a cloud environment.</p>
    <p><strong>Implementation:</strong> .NET offers several distributed caching solutions, such as Redis Cache or Microsoft SQL Server as a cache store.</p>

    <pre>
    <code>{`
    services.AddStackExchangeRedisCache(options =>
    {
        options.Configuration = "localhost:6379"; // Redis server address
    });

    // Usage
    var cacheKey = "dataKey";
    var cachedData = await _distributedCache.GetStringAsync(cacheKey);

    if (cachedData == null)
    {
        var data = FetchData(); // Fetch data from the database
        await _distributedCache.SetStringAsync(cacheKey, data, new DistributedCacheEntryOptions
        {
            AbsoluteExpirationRelativeToNow = TimeSpan.FromMinutes(10) // Cache for 10 minutes
        });
    }
 `} </code>
    </pre><br />

   <h3>3. Output Caching</h3>

    <p>
      Output caching stores the rendered output of a webpage or API response, which can be reused for subsequent requests. This strategy can greatly reduce the load on web servers and databases.
    </p>
    <p><strong>Use Case:</strong> Best for content that does not change frequently.</p>
    <p><strong>Implementation:</strong> In ASP.NET Core, use the <code>ResponseCache</code> attribute to enable output caching.</p>

    <pre>
    <code>{`
    [ResponseCache(Duration = 60)]
    public IActionResult GetCachedData()
    {
        var data = FetchData();
        return Ok(data);
    }
   `} </code>
    </pre><br />

   <h3>4. ASP.NET Core Distributed Caching</h3>

    <p>
      ASP.NET Core supports distributed caching out of the box. By using the <code>IMemoryCache</code> and <code>IDistributedCache</code> interfaces, developers can easily implement caching in their applications.
    </p>
    <p><strong>Use Case:</strong> Useful for session state storage or caching shared data across multiple instances of a web application.</p>

   <h3 style={{paddingBottom:"6px"}}> Best Practices for Caching</h3>

    <ul>
      <li><strong>Cache Duration:</strong> Set appropriate expiration times for cached items to ensure data freshness.</li><br />
      <li><strong>Cache Invalidation:</strong> Implement cache invalidation strategies to remove outdated cache entries when data changes.</li><br />
      <li><strong>Monitor Cache Performance:</strong> Regularly monitor and analyze cache hit/miss rates to fine-tune caching strategies.</li><br />
      <li><strong>Avoid Over-Caching:</strong> Be mindful of what to cache; over-caching can lead to memory pressure and reduced performance.</li>
    </ul><br />

 <h3> Conclusion</h3>

    <p>
      Implementing effective caching strategies in .NET programming can significantly enhance application performance and responsiveness. By understanding the different caching options available and following best practices, developers can optimize their applications to provide a seamless user experience.
    </p>
  </div>
)}



{selectedChapter === 'chapter51' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Best Practices for Writing Efficient Code</h1>

    <p>
      Writing efficient code is vital in .NET programming, ensuring applications are not only functional but also perform well under varying workloads. This guide outlines best practices that developers can follow to enhance the efficiency of their code.
    </p><br />

    <h2>1. Optimize Data Structures</h2>
    <p>
      Choosing the right data structure can significantly impact performance:
    </p>
    <ul>
      <li>Use <strong>arrays</strong> for fixed-size collections of elements.</li><br />
      <li>Opt for <strong>List&lt;T&gt;</strong> when you need a resizable array.</li><br />
      <li>Consider <strong>Dictionary&lt;TKey, TValue&gt;</strong> for fast lookups based on keys.</li><br />
      <li>Use <strong>HashSet&lt;T&gt;</strong> when you need a collection of unique items.</li>
    </ul><br />

    <h2>2. Minimize Memory Allocations</h2>
    <p>
      Reducing memory allocations can enhance performance, especially in high-throughput scenarios:
    </p>
    <ul>
      <li>Reuse objects whenever possible instead of creating new ones.</li><br />
      <li>Implement object pooling for expensive-to-create objects.</li>
    </ul><br />

    <h2>3. Leverage Asynchronous Programming</h2>
    <p>
      Use asynchronous programming patterns to keep your application responsive:
    </p>
    <ul>
      <li>Implement <code>async</code> and <code>await</code> keywords for I/O-bound operations to avoid blocking threads.</li><br />
      <li>Use <code>Task.Run</code> for CPU-bound tasks to free up the UI thread.</li>
    </ul><br />

    <h2>4. Optimize Database Access</h2>
    <p>
      Efficient database access can drastically improve application performance:
    </p>
    <ul>
      <li>Use <strong>parameterized queries</strong> to prevent SQL injection and improve execution plans.</li><br />
      <li>Utilize <strong>async database calls</strong> to avoid blocking the application.</li><br />
      <li>Use <strong>Entity Framework</strong> efficiently by minimizing unnecessary data fetching (e.g., using <code>.Include()</code> sparingly).</li>
    </ul><br />

    <h2>5. Avoid Unnecessary Computation</h2>
    <p>
      Reduce redundant calculations to enhance performance:
    </p>
    <ul>
      <li>Cache results of expensive computations if they are needed multiple times.</li><br />
      <li>Use lazy loading for expensive operations that may not be required immediately.</li>
    </ul><br />

    <h2>6. Implement Caching Wisely</h2>
    <p>
      Caching frequently accessed data can improve response times:
    </p>
    <ul>
      <li>Use in-memory caching for fast access to data that does not change often.</li><br />
      <li>Implement distributed caching for web applications running across multiple servers.</li>
    </ul><br />

    <h2>7. Profile and Monitor Performance</h2>
    <p>
      Regularly profile your application to identify bottlenecks:
    </p>
    <ul>
      <li>Use tools like Visual Studio's Performance Profiler, dotTrace, or Application Insights to analyze performance.</li><br />
      <li>Monitor metrics like memory usage, CPU usage, and response times to make informed decisions.</li>
    </ul><br />

    <h2>8. Follow SOLID Principles</h2>
    <p>
      Adhering to SOLID principles leads to better-organized code that is easier to maintain:
    </p>
    <ul>
      <li><strong>Single Responsibility Principle</strong>: Each class should have one reason to change.</li><br />
      <li><strong>Open/Closed Principle</strong>: Code should be open for extension but closed for modification.</li><br />
      <li><strong>Liskov Substitution Principle</strong>: Subtypes should be substitutable for their base types.</li><br />
      <li><strong>Interface Segregation Principle</strong>: No client should be forced to depend on methods it does not use.</li><br />
      <li><strong>Dependency Inversion Principle</strong>: Depend on abstractions, not concrete implementations.</li>
    </ul><br />

    <h2>9. Use Efficient Algorithms</h2>
    <p>
      Choose the most efficient algorithms for data processing:
    </p>
    <ul>
      <li>Analyze time and space complexity to select the best algorithm for your needs.</li><br />
      <li>Use built-in .NET methods for sorting, searching, and manipulating collections.</li>
    </ul><br />

    <h2>10. Stay Updated with .NET Improvements</h2>
    <p>
      Keep abreast of updates and enhancements in the .NET ecosystem:
    </p>
    <ul>
      <li>Utilize the latest features in C# and .NET to write cleaner and more efficient code.</li><br />
      <li>Explore performance improvements in newer versions of the framework.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Following these best practices for writing efficient code in .NET programming can significantly improve application performance and maintainability. By optimizing data structures, minimizing resource usage, and adopting best coding practices, developers can create applications that deliver a better user experience and are easier to manage over time.
    </p>
  </div>
)}



{selectedChapter === 'chapter52' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Microservices Architecture with .NET</h1>

    <p>
      Microservices architecture is a design pattern where applications are structured as a collection of loosely coupled, independently deployable services. Each microservice is responsible for a specific functionality and communicates with other services through APIs. In .NET, this architectural pattern can be implemented effectively using technologies like <strong>ASP.NET Core</strong>, <strong>Docker</strong>, and <strong>Kubernetes</strong>, which offer robust support for developing, deploying, and scaling microservices.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Microservices</h3>
    <ul>
      <li><strong>Single Responsibility</strong>: Each microservice is built to handle one specific business capability or function.</li><br />
      <li><strong>Loose Coupling</strong>: Services are designed to be as independent as possible, minimizing the need for direct dependencies.</li><br />
      <li><strong>Independently Deployable</strong>: Microservices can be deployed independently without affecting the functionality of other services.</li><br />
      <li><strong>Technology Agnostic</strong>: Each service can use different programming languages, databases, or frameworks.</li><br />
      <li><strong>Scalable</strong>: Individual services can be scaled based on their demand rather than scaling the entire application.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Benefits of Microservices in .NET</h3>
    <ul>
      <li><strong>Scalability</strong>: Microservices allow scaling specific parts of the application that are under heavy load without affecting the entire system.</li><br />
      <li><strong>Fault Isolation</strong>: A failure in one microservice doesn’t bring down the entire application, improving fault tolerance.</li><br />
      <li><strong>Technology Flexibility</strong>: Different microservices can use the most appropriate technology stack for their functionality.</li><br />
      <li><strong>Agility and Faster Time to Market</strong>: Teams can work on individual services independently, allowing for faster development cycles and deployment.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Building Blocks of Microservices with .NET</h3>

    <h4>1. ASP.NET Core</h4>
    <p>ASP.NET Core is a cross-platform framework perfect for building microservices due to its lightweight and modular architecture. It enables the creation of web APIs that can act as individual microservices.</p><br />

    <h4>2. API Gateway</h4>
    <p>
      An API Gateway sits between the client and the microservices, routing requests to the appropriate service. Popular .NET solutions include <strong>Ocelot</strong>, which offers API gateway functionality such as routing, authentication, rate limiting, and logging.
    </p>

    <pre>
      <code>
        {`{
  "ReRoutes": [
    {
      "DownstreamPathTemplate": "/api/orders",
      "UpstreamPathTemplate": "/orders",
      "DownstreamScheme": "http",
      "DownstreamHostAndPorts": [
        {
          "Host": "localhost",
          "Port": 5001
        }
      ]
    }
  ],
  "GlobalConfiguration": {
    "BaseUrl": "http://localhost:5000"
  }
}`}
      </code>
    </pre><br />

    <h4>3. Communication Between Services</h4>
    <p>
      Microservices communicate via APIs (RESTful APIs) or messaging protocols (e.g., <strong>gRPC</strong>, <strong>RabbitMQ</strong>, <strong>Kafka</strong>). ASP.NET Core provides strong support for building RESTful services.
    </p>

    <ul>
      <li><strong>Synchronous Communication</strong>: Services can communicate through HTTP/HTTPS protocols using REST APIs.</li><br />
      <li><strong>Asynchronous Communication</strong>: Use message brokers like RabbitMQ or Kafka for event-driven communication between services.</li>
    </ul><br />

    <h4>4. Service Discovery</h4>
    <p>
      In microservices architecture, services need to discover each other dynamically, especially in a distributed environment. Tools like <strong>Consul</strong>, <strong>Eureka</strong>, or <strong>Kubernetes</strong> can be used for service discovery.
    </p>
    <p>
      Example: In a Kubernetes-based microservice, services discover each other through the internal DNS provided by Kubernetes.
    </p><br />

    <h4>5. Resilience with Circuit Breakers</h4>
    <p>
      To prevent cascading failures, use a circuit breaker pattern to handle service failures gracefully. In .NET, libraries like <strong>Polly</strong> can be used to implement circuit breakers, retries, and fallback mechanisms.
    </p>

    <pre>
      <code>
        {`var policy = Policy
  .Handle<HttpRequestException>()
  .CircuitBreakerAsync(
    exceptionsAllowedBeforeBreaking: 3,
    durationOfBreak: TimeSpan.FromSeconds(30)
  );`}
      </code>
    </pre><br />

    <h4>6. Containerization with Docker</h4>
    <p>
      Containers make it easy to package and deploy microservices consistently across different environments. Docker is widely used for containerizing .NET microservices.
    </p>

    <pre>
      <code>
        {`FROM mcr.microsoft.com/dotnet/aspnet:6.0 AS base
WORKDIR /app
EXPOSE 80

FROM mcr.microsoft.com/dotnet/sdk:6.0 AS build
WORKDIR /src
COPY ["MyMicroservice/MyMicroservice.csproj", "MyMicroservice/"]
RUN dotnet restore "MyMicroservice/MyMicroservice.csproj"
COPY . .
WORKDIR "/src/MyMicroservice"
RUN dotnet build "MyMicroservice.csproj" -c Release -o /app/build

FROM build AS publish
RUN dotnet publish "MyMicroservice.csproj" -c Release -o /app/publish

FROM base AS final
WORKDIR /app
COPY --from=publish /app/publish .
ENTRYPOINT ["dotnet", "MyMicroservice.dll"]`}
      </code>
    </pre><br />

    <h4>7. Orchestration with Kubernetes</h4>
    <p>
      For managing large-scale deployments, <strong>Kubernetes</strong> is a powerful orchestration tool that handles containerized services, scaling, load balancing, and self-healing.
    </p>

    <pre>
      <code>
        {`apiVersion: apps/v1
kind: Deployment
metadata:
  name: my-microservice
spec:
  replicas: 3
  selector:
    matchLabels:
      app: my-microservice
  template:
    metadata:
      labels:
        app: my-microservice
    spec:
      containers:
      - name: my-microservice
        image: myregistry/my-microservice:latest
        ports:
        - containerPort: 80
---
apiVersion: v1
kind: Service
metadata:
  name: my-microservice-service
spec:
  selector:
    app: my-microservice
  ports:
  - protocol: TCP
    port: 80
    targetPort: 80
  type: LoadBalancer`}
      </code>
    </pre><br />

    <h4>8. Distributed Data Management</h4>
    <p>
      In microservices, each service typically manages its own data. This leads to a <strong>database-per-service</strong> pattern, where each microservice has its own dedicated database. However, consistency challenges arise due to distributed data.
    </p>
    <p>
      For distributed transactions, <strong>saga patterns</strong> and eventual consistency are often used to maintain data integrity across services.
    </p><br />

    <h4>9. Monitoring and Logging</h4>
    <p>
      Effective monitoring is crucial in microservices. Use distributed logging and monitoring tools like <strong>Elastic Stack (ELK)</strong>, <strong>Prometheus</strong>, and <strong>Grafana</strong> to gather logs and performance metrics from all services.
    </p>
    <p>
      In ASP.NET Core, you can enable logging and metrics using <strong>Application Insights</strong> or other logging frameworks like <strong>Serilog</strong>.
    </p><br />

    <h4>10. Security in Microservices</h4>
    <p>
      Security is paramount in microservices architecture, especially when services expose APIs. Key considerations include:
    </p>

    <ul>
      <li><strong>Authentication and Authorization</strong>: Use <strong>OAuth 2.0</strong> and <strong>OpenID Connect</strong> for securing APIs.</li><br />
      <li><strong>API Gateways</strong>: Centralized authentication and authorization mechanisms can be enforced through API gateways.</li><br />
      <li><strong>Service-to-Service Security</strong>: Ensure secure communication between services using mutual TLS (mTLS) or identity tokens (e.g., <strong>JWT</strong>).</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Microservices architecture in .NET provides flexibility, scalability, and fault tolerance for modern applications. Using ASP.NET Core for service creation, Docker for containerization, and Kubernetes for orchestration ensures a robust and scalable microservices architecture. However, designing microservices requires careful consideration of challenges like data management, inter-service communication, and security.
    </p>
  </div>
)}

{selectedChapter === 'chapter53' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building Background Services with Hangfire or Quartz in .NET</h1>


    <p>
      Background services are essential for executing long-running processes without blocking the main application thread. In .NET programming, <strong>Hangfire</strong> and <strong>Quartz.NET</strong> are two popular libraries that facilitate the creation and management of background tasks.
    </p><br />

    <h2>1. Overview of Background Services</h2>
    <p>
      Background services allow applications to perform tasks such as data processing, scheduled jobs, and email notifications asynchronously. This helps improve application performance and user experience by offloading intensive operations.
    </p><br />

    <h2>2. Hangfire</h2>
    <p style={{paddingBottom:"6px"}}>
      Hangfire is a popular library for background job processing in .NET. It allows you to create and manage jobs that run in the background, with support for delayed and recurring jobs.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
    <ul>
      <li><strong>Easy Setup:</strong> Simple installation via NuGet and minimal configuration required.</li><br />
      <li><strong>Dashboard:</strong> Comes with a built-in dashboard to monitor and manage background jobs.</li><br />
      <li><strong>Job Types:</strong> Supports one-time, delayed, and recurring jobs with ease.</li><br />
      <li><strong>Persistence:</strong> Stores job information in various databases, including SQL Server, Redis, and MongoDB.</li>
    </ul><br />
    <h3>Basic Example:</h3>
    <pre>
      <code>
        {`using Hangfire;

// In Startup.cs
public void ConfigureServices(IServiceCollection services)
{
    services.AddHangfire(config => config.UseSqlServerStorage("YourConnectionString"));
    services.AddHangfireServer();
}

// In a controller or service
public void ScheduleJob()
{
    BackgroundJob.Enqueue(() => Console.WriteLine("Hello from Hangfire!"));
}`}
      </code>
    </pre><br />

    <h2>3. Quartz.NET</h2>
    <p>
      Quartz.NET is a full-featured, open-source job scheduling system that can be integrated into .NET applications. It allows you to schedule jobs using various triggers, providing more control over job execution.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
    <ul>
      <li><strong>Advanced Scheduling:</strong> Supports complex scheduling scenarios, including cron expressions.</li><br />
      <li><strong>Job Persistence:</strong> Jobs can be stored in a database for recovery and management.</li><br />
      <li><strong>Integration:</strong> Can be easily integrated with other .NET frameworks and libraries.</li><br />
      <li><strong>Flexible API:</strong> Provides a comprehensive API for job management.</li>
    </ul><br />
    <h3>Basic Example:</h3>
    <pre>
      <code>
        {`using Quartz;
using Quartz.Impl;

// In a service
public async Task ScheduleJob()
{
    var schedulerFactory = new StdSchedulerFactory();
    var scheduler = await schedulerFactory.GetScheduler();

    var job = JobBuilder.Create<YourJobClass>()
        .WithIdentity("myJob", "group1")
        .Build();

    var trigger = TriggerBuilder.Create()
        .WithIdentity("myTrigger", "group1")
        .StartNow()
        .WithSimpleSchedule(x => x.RepeatForever().WithIntervalInSeconds(10))
        .Build();

    await scheduler.ScheduleJob(job, trigger);
    await scheduler.Start();
}`}
      </code>
    </pre><br />

    <h2>4. Choosing Between Hangfire and Quartz.NET </h2>
    <p>
      The choice between Hangfire and Quartz.NET largely depends on your specific requirements:
    </p>
    <ul>
      <li><strong>Hangfire</strong> is ideal for simpler use cases with less complex scheduling needs, particularly when you want an easy setup with a built-in dashboard.</li><br />
      <li><strong>Quartz.NET</strong> is better suited for more complex scheduling scenarios, especially when advanced scheduling features like cron jobs are needed.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Building background services using Hangfire or Quartz.NET can significantly enhance your application's capabilities. By leveraging these libraries, you can create robust background job processing that improves performance and user experience.
    </p>

    <p>
      Would you like to explore specific examples, configurations, or additional resources related to background services in .NET?
    </p>


  </div>
)}


{selectedChapter === 'chapter54' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Understanding gRPC and SignalR for Real-Time Applications</h1>

    <p>
      <strong>gRPC</strong> and <strong>SignalR</strong> are two technologies in .NET that enable communication between clients and servers, particularly in real-time applications. Both have unique strengths and are suited to different use cases.
    </p><br />

    <h2>1. gRPC Overview</h2>
    <p style={{paddingBottom:"6px"}}>
      gRPC is a high-performance, open-source RPC (Remote Procedure Call) framework. It’s ideal for efficient communication between services in microservices architectures or client-server models.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of gRPC:</h3>
    <ul>
      <li><strong>Efficient Binary Protocol</strong>: Uses Protocol Buffers (Protobuf) for serialization, which is more efficient than JSON.</li><br />
      <li><strong>Bidirectional Streaming</strong>: Supports streaming of data between client and server in real-time.</li><br />
      <li><strong>Strong Typing</strong>: Enforces strict contracts defined in Protobuf, ensuring strongly-typed messages.</li><br />
      <li><strong>Cross-Platform</strong>: Works across multiple languages and platforms.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Use Cases for gRPC:</h3>
    <ul>
      <li>Real-time messaging with strong typing.</li><br />
      <li>Microservices communication in distributed systems.</li><br />
      <li>High-performance APIs between services.</li>
    </ul><br />

    <h2>2. SignalR Overview</h2>
    <p style={{paddingBottom:"6px"}}>
      SignalR is a library that simplifies adding real-time web functionality to applications. It allows server-side code to push content to connected clients instantly.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of SignalR:</h3>
    <ul>
      <li><strong>Supports Multiple Transport Protocols</strong>: Automatically selects WebSockets, Server-Sent Events (SSE), or Long Polling based on client and server capabilities.</li><br />
      <li><strong>Hub-based Communication</strong>: Simplifies real-time communication through "hubs," abstracting connection complexities.</li><br />
      <li><strong>Broadcasting to Multiple Clients</strong>: Easily push updates to multiple clients at once, ideal for applications like chat or live dashboards.</li><br />
      <li><strong>Automatic Reconnect</strong>: Handles client reconnections automatically if the connection drops.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Use Cases for SignalR:</h3>
    <ul>
      <li>Real-time chat applications.</li><br />
      <li>Live notifications or dashboards.</li><br />
      <li>Collaborative tools (like real-time document editing).</li>
    </ul><br />

    <h2>3. When to Use gRPC vs SignalR</h2>
    <p>
      <strong>Use gRPC</strong> for efficient, structured communication in microservices or when handling binary data in real-time messaging. It excels in performance-critical environments.
    </p>
    <p>
      <strong>Use SignalR</strong> for web-based real-time applications where multiple clients need to receive updates simultaneously, such as chat applications, notifications, or live dashboards.
    </p>

    <p>
      Both gRPC and SignalR are vital tools in .NET for building real-time applications, but their use cases depend on the communication needs and patterns of your application.
    </p>
  </div>
)}



{selectedChapter === 'chapter55' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Working with Cloud Services (Azure Functions, AWS Lambda)</h1>

    <p>
      When covering <strong>Working with Cloud Services (Azure Functions, AWS Lambda) in .NET Programming</strong>, you would introduce serverless computing and how it integrates with .NET applications using these cloud platforms. Here's a breakdown of key points:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>1. Introduction to Serverless Computing</h2>
    <ul>
      <li>
        Explain what serverless computing is and how it eliminates the need for infrastructure management.
      </li><br />
      <li>
        Discuss key features like auto-scaling, pay-per-use, and event-driven architecture.
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>2. Azure Functions</h2>
    <ul>
      <li>
        <strong>Overview:</strong> Introduce Azure Functions as Microsoft's serverless platform for running small pieces of code, or functions, without having to manage infrastructure.
      </li><br />
      <li>
        <strong>Creating a Function App:</strong> Guide on setting up an Azure Function App using Visual Studio or Azure Portal.
      </li><br />
      <li>
        <strong>Writing Azure Functions in .NET:</strong> Focus on writing C# code to create HTTP-triggered functions, time-triggered functions, and integrations with Azure services like Blob Storage or Cosmos DB.
      </li><br />
      <li>
        <strong>Deployment:</strong> Show how to deploy a .NET-based Azure Function to Azure.
      </li><br />
      <li>
        <strong>Best Practices:</strong> Cover efficient scaling, durable functions, and monitoring/logging with Azure Application Insights.
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>3. AWS Lambda</h2>
    <ul>
      <li>
        <strong>Overview:</strong> Introduce AWS Lambda as Amazon’s serverless compute service.
      </li><br />
      <li>
        <strong>Creating Lambda Functions:</strong> Guide on setting up an AWS Lambda function using .NET Core with the AWS Toolkit for Visual Studio or AWS Console.
      </li><br />
      <li>
        <strong>Triggers and Event Sources:</strong> Explain how to integrate Lambda with AWS services like S3, DynamoDB, or API Gateway.
      </li><br />
      <li>
        <strong>Deployment:</strong> Show how to package and deploy a .NET Lambda function.
      </li><br />
      <li>
        <strong>Best Practices:</strong> Discuss cold starts, managing memory, optimizing execution time, and using AWS CloudWatch for monitoring.
      </li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>4. Comparison of Azure Functions vs AWS Lambda</h2>
    <ul>
      <li>Pricing models, performance, scalability, development tools, and integrations with other services.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>5. Use Cases in .NET</h2>
    <ul>
      <li>Event-driven microservices, scheduled tasks, real-time data processing, or serverless APIs in .NET applications.</li>
    </ul>

    <p>
      Would you like a specific code example or a deep dive into a particular area?
    </p>
  </div>
)}





                </div>
            </div>
        </div>

    );

}


export default DOTNETCoursedata;


