import React, { useState } from 'react';
import style from './../../Css/Courses.module.css';

function ASPdotNetCourse() {
    // State to keep track of the selected chapter
    const [selectedChapter, setSelectedChapter] = useState('chapter1')

    // Function to handle chapter selection
    const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter)
    }

    // State to track the active chapter
    const [activeChapter, setActiveChapter] = useState('chapter1');

    // Function to handle chapter click
    const handleChapterActive = (chapter) => {
        setActiveChapter(chapter);
    };
    const handleClick = (chapter) => {
        handleChapterActive(chapter);
        handleChapterClick(chapter);
        window.scrollTo(0,0);
    };


    return (
        <div className={`${style.firstcontainer} container-fluid`}>

            <div className={`${style.therow} row`}>
            <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ASP.Net
                    </button>
                    
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>

                  
                        <li onClick={() => handleClick('chapter1')}
                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`} >
                       Introduction 
                        </li>



                        <li
                    onClick={() => handleClick('chapter2')}
                    className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                   Evolution of ASP.NET 
                </li>


                <li onClick={() => handleClick('chapter3')}
                     className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} dropdown-item `}>
              Key Features and Benefits of ASP.NET
             </li>
    
                <li onClick={() => handleClick('chapter4')} 
                  className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''} dropdown-item `}>
              ASP.NET Core vs. ASP.NET Framework
                </li>


<h5> Setting Up the Environment  </h5>

                <li onClick={() => handleClick('chapter5')} 
                className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''} dropdown-item `}>
      Installing Visual Studio for ASP.NET Development
                </li>




                <li onClick={() => handleClick('chapter6')}
                className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} dropdown-item `}>
       Introduction to .NET Core SDK and CLI
                </li>

                <li onClick={() => handleClick('chapter7')} 
                className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} dropdown-item `}>
          Creating Your First ASP.NET Core Application
                </li>


                <li onClick={() => handleClick('chapter8')}
                className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} dropdown-item `}>
             ASP.NET Project Types 
                </li>

<h5>Understanding the ASP.NET Core Architecture</h5>
                <li onClick={() => handleClick('chapter9')}
                  className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} dropdown-item `}>
          Request-Response Pipeline
                </li>

                <li onClick={() => handleClick('chapter10')}
                className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} dropdown-item `}>
         Middleware in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter11')} 
                className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} dropdown-item `}>
          Dependency Injection in ASP.NET Core
                    </li>


                <li onClick={() => handleClick('chapter12')}
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item `}>
              Startup.cs and Program.cs File Explanation
                </li>

<h5>ASP.NET Core MVC Fundamentals  </h5>
            <li onClick={() => handleClick('chapter13')} 
                className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} dropdown-item `}>
           Introduction to MVC Architecture
                </li>



                <li onClick={() => handleClick('chapter14')} 
                className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''} dropdown-item `}>
             Controllers, Actions, and Action Results
                </li>

                <li onClick={() => handleClick('chapter15')} 
                  className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''} dropdown-item `}>
               Routing in ASP.NET Core MVC
                </li>



                <li onClick={() => handleClick('chapter16')} 
                className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''} dropdown-item `}>
       Model Binding and Validation
                </li>

                <li onClick={() => handleClick('chapter17')}
                  className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''} dropdown-item `}>
             View Engines 
                </li>


                <li onClick={() => handleClick('chapter18')} 
                className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''} dropdown-item `}>
     Working with Views and Partial Views
                </li>



                <li onClick={() => handleClick('chapter19')}
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} dropdown-item `}>
         Tag Helpers in ASP.NET Core
                </li>


<h5> Working with Data </h5>
                <li onClick={() => handleClick('chapter20')} 
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''} dropdown-item `}>
          Entity Framework Core Overview
                </li>

                <li onClick={() => handleClick('chapter21')}
                className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} dropdown-item `}>
           Code-First Approach in EF Core
                </li>


                <li onClick={() => handleClick('chapter22')} 
                className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''} dropdown-item `}>
             Database-First and Model-First Approaches
                </li>

                <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} dropdown-item `}>
            CRUD Operations with Entity Framework Core
                </li>


                <li onClick={() => handleClick('chapter24')} 
                className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''} dropdown-item `}>
                Migrations in EF Core
                </li>
                
                <li onClick={() => handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} dropdown-item `}>
        Working with LINQ in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter26')} 
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} dropdown-item `}>
            Using Dapper for Micro-ORM
                </li>

<h5>ASP.NET Razor Pages </h5>
                <li onClick={() => handleClick('chapter27')} 
                className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''} dropdown-item `}>
         Introduction to Razor Pages
                </li>


                <li onClick={() => handleClick('chapter28')} 
                 className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''} dropdown-item `}>
                 Razor Pages vs. MVC
                </li>


                <li onClick={() => handleClick('chapter29')} 
                className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} dropdown-item `}>
                  Working with Razor Page Handlers
                </li>

                <li onClick={() => handleClick('chapter30')} 
                className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} dropdown-item `}>
                Data Binding in Razor Pages
                </li>


                <li onClick={() => handleClick('chapter31')} 
                className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} dropdown-item `}>
                 Building Forms and Handling POST Requests
                </li>

<h5>ASP.NET Web API Development</h5>
                <li onClick={() => handleClick('chapter32')} 
                className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} dropdown-item `}>
             Introduction to REST and Web API
                </li>


                <li onClick={() => handleClick('chapter33')} 
                className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} dropdown-item `}>
          Building Web APIs in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter34')} 
                className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} dropdown-item `}>
               HTTP Verbs 
                </li>


                <li onClick={() => handleClick('chapter35')} 
                className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} dropdown-item `}>
            Routing in Web APIs
                </li>

                <li onClick={() => handleClick('chapter36')} 
                className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} dropdown-item `}>
          Model Binding and Validation in Web APIs
                </li>


                <li onClick={() => handleClick('chapter37')} 
                className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''} dropdown-item `}>
            Working with JSON and XML in Web APIs
                </li>

                <li onClick={() => handleClick('chapter38')} 
                className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} dropdown-item `}>
             Swagger/OpenAPI Integration for Documentation
                </li>

                <li onClick={() => handleClick('chapter39')} 
                className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''} dropdown-item `}>
       Securing Web APIs with OAuth2 and JWT Authentication
                </li>

<h5>State Management in ASP.NET</h5>

                <li onClick={() => handleClick('chapter40')} 
                className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''} dropdown-item `}>
        HTTP Context and Session State
                </li>


                <li onClick={() => handleClick('chapter41')} 
                className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''} dropdown-item `}>
             Cookies in ASP.NET
                </li>

                <li onClick={() => handleClick('chapter42')} 
                className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''} dropdown-item `}>
           TempData and ViewData
                </li>

                <li onClick={() => handleClick('chapter43')} 
                className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''} dropdown-item `}>
             Caching in ASP.NET Core 
                </li>

<h5>Authentication and Authorization</h5>
                <li onClick={() => handleClick('chapter44')} 
                className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''} dropdown-item `}>
          ASP.NET Core Identity Overview
                </li>

                <li onClick={() => handleClick('chapter45')} 
                className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''} dropdown-item `}>
               Authentication Schemes 
                </li>

                <li onClick={() => handleClick('chapter46')} 
                className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''} dropdown-item `}>
        Role-Based Authorization
                </li>


                <li onClick={() => handleClick('chapter47')} 
                className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''} dropdown-item `}>
              Policy-Based Authorization
                </li>



                <li onClick={() => handleClick('chapter48')} 
                className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''} dropdown-item `}>
              External Authentication Providers (Google, Facebook, Microsoft)
                </li>


<h5>Security in ASP.NET Core</h5>
                <li onClick={() => handleClick('chapter49')} 
                className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''} dropdown-item `}>
              Securing ASP.NET Core Applications with HTTPS
                </li>


                <li onClick={() => handleClick('chapter50')} 
                className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''} dropdown-item `}>
       Cross-Site Scripting (XSS) and Cross-Site Request Forgery (CSRF) Protection
                </li>

                <li onClick={() => handleClick('chapter51')} 
                className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''} dropdown-item `}>
         Data Protection in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter52')} 
                className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''} dropdown-item `}>
          Implementing Two-Factor Authentication (2FA)
                </li>


                <li onClick={() => handleClick('chapter53')} 
                className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''} dropdown-item `}>
        Identity Server Integration for SSO and OAuth2
                </li>


<h5>Error Handling and Logging</h5>
                <li onClick={() => handleClick('chapter54')} 
                className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''} dropdown-item `}>
          Global Exception Handling in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter55')} 
                className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''} dropdown-item `}>
          Custom Error Pages and Status Code Pages
                </li>


                <li onClick={() => handleClick('chapter56')} 
                className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''} dropdown-item `}>
            Logging in ASP.NET Core 
                </li>



                <li onClick={() => handleClick('chapter57')} 
                className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''} dropdown-item `}>
          Application Insights for Monitoring
                </li>



<h5>File Handling and Uploading</h5>
                <li onClick={() => handleClick('chapter58')} 
                className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''} dropdown-item `}>
     Working with File Uploads in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter59')} 
                className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''} dropdown-item `}>
          Saving and Retrieving Files from a Database
                </li>


                <li onClick={() => handleClick('chapter60')} 
                className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''} dropdown-item `}>
           Secure File Uploading
                </li>

                <li onClick={() => handleClick('chapter61')} 
                className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''} dropdown-item `}>
           Streaming Files 
                </li>

<h5> Advanced Routing and URL Rewriting</h5>
                <li onClick={() => handleClick('chapter62')} 
                className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''} dropdown-item `}>
          Attribute Routing in ASP.NET Core MVC
                </li>

                <li onClick={() => handleClick('chapter63')} 
                className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''} dropdown-item `}>
          URL Rewriting and Redirection
                </li>

                <li onClick={() => handleClick('chapter64')} 
                className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''} dropdown-item `}>
        Custom Middleware for Advanced Routing
                </li>


<h5>Real-Time Communication with SignalR</h5>
                <li onClick={() => handleClick('chapter65')} 
                className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''} dropdown-item `}>
            Introduction to SignalR
                </li>

                <li onClick={() => handleClick('chapter66')} 
                className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''} dropdown-item `}>
           Building Real-Time Applications with SignalR
                </li>

                <li onClick={() => handleClick('chapter67')} 
                className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''} dropdown-item `}>
           Working with Hubs and Clients
                </li>


             <li onClick={() => handleClick('chapter68')} 
                className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''} dropdown-item `}>
            Broadcasting Messages to Multiple Clients
                </li>

                <li onClick={() => handleClick('chapter69')} 
                className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''} dropdown-item  `}>
            SignalR Integration with Web APIs
                </li>


   <h5>ASP.NET Core Blazor</h5>
                <li onClick={() => handleClick('chapter70')} 
                className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''} dropdown-item `}>
          Introduction to Blazor 
                </li>

                <li onClick={() => handleClick('chapter71')} 
                className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''} dropdown-item `}>
         Building Components in Blazor
                </li>

                <li onClick={() => handleClick('chapter72')} 
                className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''} dropdown-item `}>
           Handling Forms and Events in Blazor
                </li>


                <li onClick={() => handleClick('chapter73')} 
                className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''} dropdown-item `}>
        Blazor Routing and Navigation
                </li>

                <li onClick={() => handleClick('chapter74')} 
                className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''} dropdown-item `}>
          State Management in Blazor
                </li>

                <li onClick={() => handleClick('chapter75')} 
                className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''} dropdown-item `}>
          Integrating Blazor with JavaScript
                </li>

<h5>Unit Testing and Test-Driven Development (TDD)</h5>
                <li onClick={() => handleClick('chapter76')} 
                className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''} dropdown-item `}>
           Introduction to Unit Testing in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter77')} 
                className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''} dropdown-item `}>
            Writing Unit Tests for Controllers, Services, and Repositories
                </li>

                <li onClick={() => handleClick('chapter78')} 
                className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''} dropdown-item  `}>
          Mocking Dependencies 
                </li>

                <li onClick={() => handleClick('chapter79')} 
                className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''} dropdown-item `}>
           Integration Testing in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter80')} 
                className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''} dropdown-item `}>
         Test-Driven Development Concepts
                </li>


<h5> Performance Optimization in ASP.NET Core</h5>
                <li onClick={() => handleClick('chapter81')} 
                className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''} dropdown-item `}>
            Application Caching 
                </li>


                <li onClick={() => handleClick('chapter82')} 
                className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''} dropdown-item `}>
           Response Caching and Compression
                </li>

                <li onClick={() => handleClick('chapter83')} 
                className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''} dropdown-item `}>
          Optimizing Database Access with EF Core
                </li>

                <li onClick={() => handleClick('chapter84')} 
                className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''} dropdown-item `}>
        Asynchronous Programming and async/await in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter85')} 
                className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''} dropdown-item `}>
      Profiling and Analyzing Performance
                </li>



<h5> Localization and Globalization</h5>
                <li onClick={() => handleClick('chapter86')} 
                className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''} dropdown-item `}>
          Introduction to Localization in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter87')} 
                className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''} dropdown-item `}>
          Implementing Resource Files for Localization
                </li>

                <li onClick={() => handleClick('chapter88')} 
                className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''} dropdown-item `}>
            Culture and Language Settings in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter89')} 
                className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''} dropdown-item `}>
            Building Multilingual Applications
                </li>


<h5>Deployment and Hosting</h5>
                <li onClick={() => handleClick('chapter90')} 
                className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''} dropdown-item `}>
            Deploying ASP.NET Core Applications to IIS
                </li>

                <li onClick={() => handleClick('chapter91')} 
                className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''} dropdown-item `}>
          Hosting ASP.NET Core Applications in Azure 
                </li>

                <li onClick={() => handleClick('chapter92')} 
                className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''} dropdown-item `}>
            Docker and Containerization for ASP.NET Core Apps
                </li>

                <li onClick={() => handleClick('chapter93')} 
                className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} dropdown-item `}>
       Continuous Integration/Continuous Deployment (CI/CD) with Azure DevOps or GitHub Actions
                </li>


<h5> Building and Consuming gRPC Services </h5>
                <li onClick={() => handleClick('chapter94')} 
                className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''} dropdown-item `}>
       Introduction to gRPC in ASP.NET Core

                </li>

                <li onClick={() => handleClick('chapter95')} 
                className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''} dropdown-item  `}>
        Building gRPC Services
                </li>

                <li onClick={() => handleClick('chapter96')} 
                className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''} dropdown-item `}>
       Consuming gRPC Services
                </li>

                <li onClick={() => handleClick('chapter97')} 
                className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''} dropdown-item `}>
           HTTP/2 and gRPC Streaming
                </li>


<h5> Background Services and Task Scheduling </h5>
                <li onClick={() => handleClick('chapter98')} 
                className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''} dropdown-item `}>
            Building Background Services in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter99')} 
                className={`${style.chapter99} ${activeChapter === 'chapter99' ? style.active : ''} dropdown-item `}>
      Using Hosted Services in ASP.NET Core

                </li>

                <li onClick={() => handleClick('chapter100')} 
                className={`${style.chapter100} ${activeChapter === 'chapter100' ? style.active : ''} dropdown-item `}>
           Scheduling Jobs with Hangfire or Quartz.NET
                </li>


<h5>WebSockets in ASP.NET Core</h5>
                <li onClick={() => handleClick('chapter101')} 
                className={`${style.chapter101} ${activeChapter === 'chapter101' ? style.active : ''} dropdown-item `}>
        Introduction to WebSockets
                </li>



                <li onClick={() => handleClick('chapter102')} 
                className={`${style.chapter102} ${activeChapter === 'chapter102' ? style.active : ''} dropdown-item `}>
        Implementing Real-Time WebSocket Communication
                </li>



                <li onClick={() => handleClick('chapter103')} 
                className={`${style.chapter103} ${activeChapter === 'chapter103' ? style.active : ''} dropdown-item `}>
          Working with WebSockets in ASP.NET Core
                </li>


<h5> Working with GraphQL </h5>
                <li onClick={() => handleClick('chapter104')} 
                className={`${style.chapter104} ${activeChapter === 'chapter104' ? style.active : ''} dropdown-item `}>
         Introduction to GraphQL in ASP.NET Core
                </li>



                <li onClick={() => handleClick('chapter105')} 
                className={`${style.chapter105} ${activeChapter === 'chapter105' ? style.active : ''} dropdown-item `}>
         Building GraphQL APIs
                </li>



                <li onClick={() => handleClick('chapter106')} 
                className={`${style.chapter106} ${activeChapter === 'chapter106' ? style.active : ''} dropdown-item `}>
        Querying GraphQL APIs with .NET Clients
                </li>



                <li onClick={() => handleClick('chapter107')} 
                className={`${style.chapter107} ${activeChapter === 'chapter107' ? style.active : ''} dropdown-item `}>
      Best Practices for GraphQL with ASP.NET Core
                </li>


<h5> Serverless Computing with ASP.NET Core </h5>
                <li onClick={() => handleClick('chapter108')} 
                className={`${style.chapter108} ${activeChapter === 'chapter108' ? style.active : ''} dropdown-item `}>
         Building Serverless APIs with Azure Functions
                </li>

                <li onClick={() => handleClick('chapter109')} 
                className={`${style.chapter109} ${activeChapter === 'chapter109' ? style.active : ''} dropdown-item `}>
         Integration of ASP.NET Core with AWS Lambda
                </li>

                <li onClick={() => handleClick('chapter110')} 
                className={`${style.chapter110} ${activeChapter === 'chapter110' ? style.active : ''} dropdown-item `}>
         Working with Serverless Databases 
                </li>


<h5>Advanced ASP.NET Core Topics</h5>
                <li onClick={() => handleClick('chapter111')} 
                className={`${style.chapter111} ${activeChapter === 'chapter111' ? style.active : ''} dropdown-item `}>
     Middleware Pipeline Customization
                </li>


                <li onClick={() => handleClick('chapter113')} 
                className={`${style.chapter113} ${activeChapter === 'chapter113' ? style.active : ''} dropdown-item `}>
     Advanced Model Binding and Custom Formatters
                </li>


                <li onClick={() => handleClick('chapter114')} 
                className={`${style.chapter114} ${activeChapter === 'chapter114' ? style.active : ''} dropdown-item `}>
     Advanced Dependency Injection Scenarios
                </li>


                <li onClick={() => handleClick('chapter115')} 
                className={`${style.chapter115} ${activeChapter === 'chapter115' ? style.active : ''} dropdown-item `}>
     Working with Distributed Transactions in ASP.NET Core
                </li>


                <li onClick={() => handleClick('chapter116')} 
                className={`${style.chapter116} ${activeChapter === 'chapter116' ? style.active : ''} dropdown-item `}>
     Repository and Unit of Work Pattern in ASP.NET Core
                </li>


                <li onClick={() => handleClick('chapter117')} 
                className={`${style.chapter117} ${activeChapter === 'chapter117' ? style.active : ''} dropdown-item `}>
     Clean Architecture in ASP.NET Core Applications 
                </li>


                <li onClick={() => handleClick('chapter118')} 
                className={`${style.chapter118} ${activeChapter === 'chapter118' ? style.active : ''} dropdown-item `}>
     Implementing CQRS and Mediator Pattern
                </li>



                <li onClick={() => handleClick('chapter119')} 
                className={`${style.chapter119} ${activeChapter === 'chapter119' ? style.active : ''} dropdown-item `}>
    SOLID Principles in ASP.NET Core
                </li>



<h5>Recommended Tools and Libraries</h5>
                <li onClick={() => handleClick('chapter120')} 
                className={`${style.chapter120} ${activeChapter === 'chapter120' ? style.active : ''} dropdown-item `}>
    Exploring Popular ASP.NET Core Extensions 
                </li>




                <li onClick={() => handleClick('chapter121')} 
                className={`${style.chapter121} ${activeChapter === 'chapter121' ? style.active : ''} dropdown-item `}>
    Productivity Tools for ASP.NET Core Developers 
                </li>




                <li onClick={() => handleClick('chapter122')} 
                className={`${style.chapter122} ${activeChapter === 'chapter122' ? style.active : ''} dropdown-item `}>
    Debugging and Diagnostic Tools
                </li>



<h5> Building a Full-Stack Application</h5>
                <li onClick={() => handleClick('chapter123')} 
                className={`${style.chapter123} ${activeChapter === 'chapter123' ? style.active : ''} dropdown-item `}>
    Combining ASP.NET Core with Frontend Technologies 
                </li>




                <li onClick={() => handleClick('chapter124')} 
                className={`${style.chapter124} ${activeChapter === 'chapter124' ? style.active : ''} dropdown-item `}>
     Building Full-Stack Applications with Blazor
                </li>





                <li onClick={() => handleClick('chapter125')} 
                className={`${style.chapter125} ${activeChapter === 'chapter125' ? style.active : ''} dropdown-item `}>
     RESTful API + Frontend Framework Integration
                </li>


<h5>ASP.NET Core in Microservices Architecture</h5>
                <li onClick={() => handleClick('chapter126')} 
                className={`${style.chapter126} ${activeChapter === 'chapter126' ? style.active : ''} dropdown-item `}>
     Introduction to Microservices with ASP.NET Core
                </li>



                <li onClick={() => handleClick('chapter127')} 
                className={`${style.chapter127} ${activeChapter === 'chapter127' ? style.active : ''} dropdown-item `}>
    Building and Deploying Microservices with Docker
                </li>



                <li onClick={() => handleClick('chapter128')} 
                className={`${style.chapter128} ${activeChapter === 'chapter128' ? style.active : ''} dropdown-item `}>
    Communication Between Microservices 
                </li>


                <li onClick={() => handleClick('chapter129')} 
                className={`${style.chapter129} ${activeChapter === 'chapter129' ? style.active : ''} dropdown-item `}>
     API Gateway Integration 
                </li>





              </ul>
                </div>





                <div className={`${style.leftcolumn} col-2`}>

                    <ul className={`${style.chapters} `}>
                    <h5 className={style.stickyheading} >ASP.Net</h5>
                
                <li
                    onClick={() => handleClick('chapter1')}

                    className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} `}>
                    Intoduction
                    </li>


               


                    <li
                    onClick={() => handleClick('chapter2')}
                    className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}  `}>
                   Evolution of ASP.NET 
                </li>


                <li onClick={() => handleClick('chapter3')}
                     className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} `}>
              Key Features and Benefits of ASP.NET
             </li>
    
                <li onClick={() => handleClick('chapter4')} 
                  className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}  `}>
              ASP.NET Core vs. ASP.NET Framework
                </li>


<h5> Setting Up the Environment  </h5>

                <li onClick={() => handleClick('chapter5')} 
                className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}  `}>
      Installing Visual Studio for ASP.NET Development
                </li>




                <li onClick={() => handleClick('chapter6')}
                className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} `}>
       Introduction to .NET Core SDK and CLI
                </li>

                <li onClick={() => handleClick('chapter7')} 
                className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} `}>
          Creating Your First ASP.NET Core Application
                </li>


                <li onClick={() => handleClick('chapter8')}
                className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}  `}>
             ASP.NET Project Types 
                </li>

<h5>Understanding the ASP.NET Core Architecture</h5>
                <li onClick={() => handleClick('chapter9')}
                  className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} `}>
          Request-Response Pipeline
                </li>

                <li onClick={() => handleClick('chapter10')}
                className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}  `}>
         Middleware in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter11')} 
                className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} `}>
          Dependency Injection in ASP.NET Core
                    </li>


                <li onClick={() => handleClick('chapter12')}
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} `}>
              Startup.cs and Program.cs File Explanation
                </li>

<h5>ASP.NET Core MVC Fundamentals  </h5>
            <li onClick={() => handleClick('chapter13')} 
                className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}  `}>
           Introduction to MVC Architecture
                </li>



                <li onClick={() => handleClick('chapter14')} 
                className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}  `}>
             Controllers, Actions, and Action Results
                </li>

                <li onClick={() => handleClick('chapter15')} 
                  className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}  `}>
               Routing in ASP.NET Core MVC
                </li>



                <li onClick={() => handleClick('chapter16')} 
                className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}  `}>
       Model Binding and Validation
                </li>

                <li onClick={() => handleClick('chapter17')}
                  className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}  `}>
             View Engines 
                </li>


                <li onClick={() => handleClick('chapter18')} 
                className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}  `}>
     Working with Views and Partial Views
                </li>



                <li onClick={() => handleClick('chapter19')}
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''}  `}>
         Tag Helpers in ASP.NET Core
                </li>


<h5> Working with Data </h5>
                <li onClick={() => handleClick('chapter20')} 
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}  `}>
          Entity Framework Core Overview
                </li>

                <li onClick={() => handleClick('chapter21')}
                className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}  `}>
           Code-First Approach in EF Core
                </li>


                <li onClick={() => handleClick('chapter22')} 
                className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}  `}>
             Database-First and Model-First Approaches
                </li>

                <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}  `}>
            CRUD Operations with Entity Framework Core
                </li>


                <li onClick={() => handleClick('chapter24')} 
                className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}  `}>
                Migrations in EF Core
                </li>
                
                <li onClick={() => handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}  `}>
        Working with LINQ in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter26')} 
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}  `}>
            Using Dapper for Micro-ORM
                </li>

<h5>ASP.NET Razor Pages </h5>
                <li onClick={() => handleClick('chapter27')} 
                className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}  `}>
         Introduction to Razor Pages
                </li>


                <li onClick={() => handleClick('chapter28')} 
                 className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}  `}>
                 Razor Pages vs. MVC
                </li>


                <li onClick={() => handleClick('chapter29')} 
                className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} `}>
                  Working with Razor Page Handlers
                </li>

                <li onClick={() => handleClick('chapter30')} 
                className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} `}>
                Data Binding in Razor Pages
                </li>


                <li onClick={() => handleClick('chapter31')} 
                className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} `}>
                 Building Forms and Handling POST Requests
                </li>

<h5>ASP.NET Web API Development</h5>
                <li onClick={() => handleClick('chapter32')} 
                className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} `}>
             Introduction to REST and Web API
                </li>


                <li onClick={() => handleClick('chapter33')} 
                className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} `}>
          Building Web APIs in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter34')} 
                className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} `}>
               HTTP Verbs 
                </li>


                <li onClick={() => handleClick('chapter35')} 
                className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''}  `}>
            Routing in Web APIs
                </li>

                <li onClick={() => handleClick('chapter36')} 
                className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''}  `}>
          Model Binding and Validation in Web APIs
                </li>


                <li onClick={() => handleClick('chapter37')} 
                className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''}  `}>
            Working with JSON and XML in Web APIs
                </li>

                <li onClick={() => handleClick('chapter38')} 
                className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''}  `}>
             Swagger/OpenAPI Integration for Documentation
                </li>

                <li onClick={() => handleClick('chapter39')} 
                className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''}  `}>
       Securing Web APIs with OAuth2 and JWT Authentication
                </li>

<h5>State Management in ASP.NET</h5>

                <li onClick={() => handleClick('chapter40')} 
                className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''}  `}>
        HTTP Context and Session State
                </li>


                <li onClick={() => handleClick('chapter41')} 
                className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''}  `}>
             Cookies in ASP.NET
                </li>

                <li onClick={() => handleClick('chapter42')} 
                className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''}  `}>
           TempData and ViewData
                </li>

                <li onClick={() => handleClick('chapter43')} 
                className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''}  `}>
             Caching in ASP.NET Core 
                </li>

<h5>Authentication and Authorization</h5>
                <li onClick={() => handleClick('chapter44')} 
                className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''}  `}>
          ASP.NET Core Identity Overview
                </li>

                <li onClick={() => handleClick('chapter45')} 
                className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''}  `}>
               Authentication Schemes 
                </li>

                <li onClick={() => handleClick('chapter46')} 
                className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''}  `}>
        Role-Based Authorization
                </li>


                <li onClick={() => handleClick('chapter47')} 
                className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''}  `}>
              Policy-Based Authorization
                </li>



                <li onClick={() => handleClick('chapter48')} 
                className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''}  `}>
              External Authentication Providers (Google, Facebook, Microsoft)
                </li>


<h5>Security in ASP.NET Core</h5>
                <li onClick={() => handleClick('chapter49')} 
                className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''}  `}>
              Securing ASP.NET Core Applications with HTTPS
                </li>


                <li onClick={() => handleClick('chapter50')} 
                className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''}  `}>
       Cross-Site Scripting (XSS) and Cross-Site Request Forgery (CSRF) Protection
                </li>

                <li onClick={() => handleClick('chapter51')} 
                className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''}  `}>
         Data Protection in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter52')} 
                className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''}  `}>
          Implementing Two-Factor Authentication (2FA)
                </li>


                <li onClick={() => handleClick('chapter53')} 
                className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''}  `}>
        Identity Server Integration for SSO and OAuth2
                </li>


<h5>Error Handling and Logging</h5>
                <li onClick={() => handleClick('chapter54')} 
                className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''}  `}>
          Global Exception Handling in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter55')} 
                className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''}  `}>
          Custom Error Pages and Status Code Pages
                </li>


                <li onClick={() => handleClick('chapter56')} 
                className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''}  `}>
            Logging in ASP.NET Core 
                </li>



                <li onClick={() => handleClick('chapter57')} 
                className={`${style.chapter57} ${activeChapter === 'chapter57' ? style.active : ''}  `}>
          Application Insights for Monitoring
                </li>



<h5>File Handling and Uploading</h5>
                <li onClick={() => handleClick('chapter58')} 
                className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''}  `}>
     Working with File Uploads in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter59')} 
                className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''}  `}>
          Saving and Retrieving Files from a Database
                </li>


                <li onClick={() => handleClick('chapter60')} 
                className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''}  `}>
           Secure File Uploading
                </li>

                <li onClick={() => handleClick('chapter61')} 
                className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''}  `}>
           Streaming Files 
                </li>

<h5> Advanced Routing and URL Rewriting</h5>
                <li onClick={() => handleClick('chapter62')} 
                className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''}  `}>
          Attribute Routing in ASP.NET Core MVC
                </li>

                <li onClick={() => handleClick('chapter63')} 
                className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''}  `}>
          URL Rewriting and Redirection
                </li>

                <li onClick={() => handleClick('chapter64')} 
                className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''}  `}>
        Custom Middleware for Advanced Routing
                </li>


<h5>Real-Time Communication with SignalR</h5>
                <li onClick={() => handleClick('chapter65')} 
                className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''}  `}>
            Introduction to SignalR
                </li>

                <li onClick={() => handleClick('chapter66')} 
                className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''}  `}>
           Building Real-Time Applications with SignalR
                </li>

                <li onClick={() => handleClick('chapter67')} 
                className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''}  `}>
           Working with Hubs and Clients
                </li>


             <li onClick={() => handleClick('chapter68')} 
                className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''}  `}>
            Broadcasting Messages to Multiple Clients
                </li>

                <li onClick={() => handleClick('chapter69')} 
                className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''}  `}>
            SignalR Integration with Web APIs
                </li>


   <h5>ASP.NET Core Blazor</h5>
                <li onClick={() => handleClick('chapter70')} 
                className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''}  `}>
          Introduction to Blazor 
                </li>

                <li onClick={() => handleClick('chapter71')} 
                className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''}  `}>
         Building Components in Blazor
                </li>

                <li onClick={() => handleClick('chapter72')} 
                className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''}  `}>
           Handling Forms and Events in Blazor
                </li>


                <li onClick={() => handleClick('chapter73')} 
                className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''}  `}>
        Blazor Routing and Navigation
                </li>

                <li onClick={() => handleClick('chapter74')} 
                className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''}  `}>
          State Management in Blazor
                </li>

                <li onClick={() => handleClick('chapter75')} 
                className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''}  `}>
          Integrating Blazor with JavaScript
                </li>

<h5>Unit Testing and Test-Driven Development (TDD)</h5>
                <li onClick={() => handleClick('chapter76')} 
                className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''}  `}>
           Introduction to Unit Testing in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter77')} 
                className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''}  `}>
            Writing Unit Tests for Controllers, Services, and Repositories
                </li>

                <li onClick={() => handleClick('chapter78')} 
                className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''}  `}>
          Mocking Dependencies 
                </li>

                <li onClick={() => handleClick('chapter79')} 
                className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''}  `}>
           Integration Testing in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter80')} 
                className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''}  `}>
         Test-Driven Development Concepts
                </li>


<h5> Performance Optimization in ASP.NET Core</h5>
                <li onClick={() => handleClick('chapter81')} 
                className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''}  `}>
            Application Caching 
                </li>


                <li onClick={() => handleClick('chapter82')} 
                className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''}  `}>
           Response Caching and Compression
                </li>

                <li onClick={() => handleClick('chapter83')} 
                className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''}  `}>
          Optimizing Database Access with EF Core
                </li>

                <li onClick={() => handleClick('chapter84')} 
                className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''}  `}>
        Asynchronous Programming and async/await in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter85')} 
                className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''}  `}>
      Profiling and Analyzing Performance
                </li>



<h5> Localization and Globalization</h5>
                <li onClick={() => handleClick('chapter86')} 
                className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''}  `}>
          Introduction to Localization in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter87')} 
                className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''}  `}>
          Implementing Resource Files for Localization
                </li>

                <li onClick={() => handleClick('chapter88')} 
                className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''}  `}>
            Culture and Language Settings in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter89')} 
                className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''}  `}>
            Building Multilingual Applications
                </li>


<h5>Deployment and Hosting</h5>
                <li onClick={() => handleClick('chapter90')} 
                className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''}  `}>
            Deploying ASP.NET Core Applications to IIS
                </li>

                <li onClick={() => handleClick('chapter91')} 
                className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''}  `}>
          Hosting ASP.NET Core Applications in Azure 
                </li>

                <li onClick={() => handleClick('chapter92')} 
                className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''}  `}>
            Docker and Containerization for ASP.NET Core Apps
                </li>

                <li onClick={() => handleClick('chapter93')} 
                className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} `}>
       Continuous Integration/Continuous Deployment (CI/CD) with Azure DevOps or GitHub Actions
                </li>


<h5> Building and Consuming gRPC Services </h5>
                <li onClick={() => handleClick('chapter94')} 
                className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''}  `}>
       Introduction to gRPC in ASP.NET Core

                </li>

                <li onClick={() => handleClick('chapter95')} 
                className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''}  `}>
        Building gRPC Services
                </li>

                <li onClick={() => handleClick('chapter96')} 
                className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''}  `}>
       Consuming gRPC Services
                </li>

                <li onClick={() => handleClick('chapter97')} 
                className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''}  `}>
           HTTP/2 and gRPC Streaming
                </li>


<h5> Background Services and Task Scheduling </h5>
                <li onClick={() => handleClick('chapter98')} 
                className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''}  `}>
            Building Background Services in ASP.NET Core
                </li>

                <li onClick={() => handleClick('chapter99')} 
                className={`${style.chapter99} ${activeChapter === 'chapter99' ? style.active : ''}  `}>
      Using Hosted Services in ASP.NET Core

                </li>

                <li onClick={() => handleClick('chapter100')} 
                className={`${style.chapter100} ${activeChapter === 'chapter100' ? style.active : ''}  `}>
           Scheduling Jobs with Hangfire or Quartz.NET
                </li>


<h5>WebSockets in ASP.NET Core</h5>
                <li onClick={() => handleClick('chapter101')} 
                className={`${style.chapter101} ${activeChapter === 'chapter101' ? style.active : ''}  `}>
        Introduction to WebSockets
                </li>



                <li onClick={() => handleClick('chapter102')} 
                className={`${style.chapter102} ${activeChapter === 'chapter102' ? style.active : ''}  `}>
        Implementing Real-Time WebSocket Communication
                </li>



                <li onClick={() => handleClick('chapter103')} 
                className={`${style.chapter103} ${activeChapter === 'chapter103' ? style.active : ''}  `}>
          Working with WebSockets in ASP.NET Core
                </li>


<h5> Working with GraphQL </h5>
                <li onClick={() => handleClick('chapter104')} 
                className={`${style.chapter104} ${activeChapter === 'chapter104' ? style.active : ''} `}>
         Introduction to GraphQL in ASP.NET Core
                </li>



                <li onClick={() => handleClick('chapter105')} 
                className={`${style.chapter105} ${activeChapter === 'chapter105' ? style.active : ''} `}>
         Building GraphQL APIs
                </li>



                <li onClick={() => handleClick('chapter106')} 
                className={`${style.chapter106} ${activeChapter === 'chapter106' ? style.active : ''} `}>
        Querying GraphQL APIs with .NET Clients
                </li>



                <li onClick={() => handleClick('chapter107')} 
                className={`${style.chapter107} ${activeChapter === 'chapter107' ? style.active : ''} `}>
      Best Practices for GraphQL with ASP.NET Core
                </li>


<h5> Serverless Computing with ASP.NET Core </h5>
                <li onClick={() => handleClick('chapter108')} 
                className={`${style.chapter108} ${activeChapter === 'chapter108' ? style.active : ''} `}>
         Building Serverless APIs with Azure Functions
                </li>

                <li onClick={() => handleClick('chapter109')} 
                className={`${style.chapter109} ${activeChapter === 'chapter109' ? style.active : ''} `}>
         Integration of ASP.NET Core with AWS Lambda
                </li>

                <li onClick={() => handleClick('chapter110')} 
                className={`${style.chapter110} ${activeChapter === 'chapter110' ? style.active : ''} `}>
         Working with Serverless Databases 
                </li>


<h5>Advanced ASP.NET Core Topics</h5>
                <li onClick={() => handleClick('chapter111')} 
                className={`${style.chapter111} ${activeChapter === 'chapter111' ? style.active : ''} `}>
     Middleware Pipeline Customization
                </li>


                <li onClick={() => handleClick('chapter113')} 
                className={`${style.chapter113} ${activeChapter === 'chapter113' ? style.active : ''} `}>
     Advanced Model Binding and Custom Formatters
                </li>


                <li onClick={() => handleClick('chapter114')} 
                className={`${style.chapter114} ${activeChapter === 'chapter114' ? style.active : ''} `}>
     Advanced Dependency Injection Scenarios
                </li>


                <li onClick={() => handleClick('chapter115')} 
                className={`${style.chapter115} ${activeChapter === 'chapter115' ? style.active : ''} `}>
     Working with Distributed Transactions in ASP.NET Core
                </li>


                <li onClick={() => handleClick('chapter116')} 
                className={`${style.chapter116} ${activeChapter === 'chapter116' ? style.active : ''} `}>
     Repository and Unit of Work Pattern in ASP.NET Core
                </li>


                <li onClick={() => handleClick('chapter117')} 
                className={`${style.chapter117} ${activeChapter === 'chapter117' ? style.active : ''} `}>
     Clean Architecture in ASP.NET Core Applications
                </li>


                <li onClick={() => handleClick('chapter118')} 
                className={`${style.chapter118} ${activeChapter === 'chapter118' ? style.active : ''} `}>
     Implementing CQRS and Mediator Pattern
                </li>



                <li onClick={() => handleClick('chapter119')} 
                className={`${style.chapter119} ${activeChapter === 'chapter119' ? style.active : ''} `}>
    SOLID Principles in ASP.NET Core
                </li>



<h5>Recommended Tools and Libraries</h5>
                <li onClick={() => handleClick('chapter120')} 
                className={`${style.chapter120} ${activeChapter === 'chapter120' ? style.active : ''} `}>
    Exploring Popular ASP.NET Core Extensions 
                </li>




                <li onClick={() => handleClick('chapter121')} 
                className={`${style.chapter121} ${activeChapter === 'chapter121' ? style.active : ''} `}>
    Productivity Tools for ASP.NET Core Developers 
                </li>




                <li onClick={() => handleClick('chapter122')} 
                className={`${style.chapter122} ${activeChapter === 'chapter122' ? style.active : ''} `}>
    Debugging and Diagnostic Tools
                </li>



<h5> Building a Full-Stack Application</h5>
                <li onClick={() => handleClick('chapter123')} 
                className={`${style.chapter123} ${activeChapter === 'chapter123' ? style.active : ''} `}>
    Combining ASP.NET Core with Frontend Technologies 
                </li>




                <li onClick={() => handleClick('chapter124')} 
                className={`${style.chapter124} ${activeChapter === 'chapter124' ? style.active : ''} `}>
     Building Full-Stack Applications with Blazor
                </li>





                <li onClick={() => handleClick('chapter125')} 
                className={`${style.chapter125} ${activeChapter === 'chapter125' ? style.active : ''} `}>
     RESTful API + Frontend Framework Integration
                </li>


<h5>ASP.NET Core in Microservices Architecture</h5>
                <li onClick={() => handleClick('chapter126')} 
                className={`${style.chapter126} ${activeChapter === 'chapter126' ? style.active : ''} `}>
     Introduction to Microservices with ASP.NET Core
                </li>



                <li onClick={() => handleClick('chapter127')} 
                className={`${style.chapter127} ${activeChapter === 'chapter127' ? style.active : ''} `}>
    Building and Deploying Microservices with Docker
                </li>



                <li onClick={() => handleClick('chapter128')} 
                className={`${style.chapter128} ${activeChapter === 'chapter128' ? style.active : ''} `}>
    Communication Between Microservices 
                </li>


                <li onClick={() => handleClick('chapter129')} 
                className={`${style.chapter129} ${activeChapter === 'chapter129' ? style.active : ''} `}>
     API Gateway Integration 
                </li>





                    </ul>
                </div>




                <div className={`${style.rightcolumn} col`}>


                    {/* Chapter Content */}

                    {selectedChapter === 'chapter1' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction</h1>
    
    <p><strong>ASP.NET</strong> is an open-source web framework developed by Microsoft, used for building dynamic, robust, and scalable web applications, websites, and services. It is part of the larger <strong>.NET platform</strong> and allows developers to build modern web applications using languages such as C# and VB.NET.</p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of ASP.NET:</h2>
    <ul>
      <li><strong>Cross-Platform</strong>: With the introduction of <strong>ASP.NET Core</strong>, applications can be developed and run on multiple platforms like Windows, macOS, and Linux.</li><br />
      <li><strong>MVC Architecture</strong>: ASP.NET supports the <strong>Model-View-Controller (MVC)</strong> pattern, separating application logic, UI, and data for better manageability and scalability.</li><br />
      <li><strong>Razor Pages</strong>: Simplifies building web UIs by using Razor syntax, which combines C# code with HTML in a clean and organized way.</li><br />
      <li><strong>Web API</strong>: ASP.NET provides built-in support for creating <strong>RESTful services</strong> and APIs, making it easy to build services for mobile apps or SPAs (Single Page Applications).</li><br />
      <li><strong>Security</strong>: ASP.NET offers robust security features like authentication, authorization, and protection against common vulnerabilities (e.g., Cross-Site Scripting, SQL Injection).</li><br />
      <li><strong>Fast and Scalable</strong>: ASP.NET Core is optimized for high performance and large-scale applications with minimal overhead.</li><br />
      <li><strong>Dependency Injection</strong>: ASP.NET Core has built-in support for <strong>dependency injection</strong>, enhancing loose coupling and easier testing.</li><br />
      <li><strong>Middleware Pipeline</strong>: ASP.NET Core uses a modular middleware pipeline for custom handling of requests, logging, and responses.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>ASP.NET Versions:</h2>
    <ul>
      <li><strong>ASP.NET Framework</strong>: The original version, built for Windows and integrated with the operating system.</li><br />
      <li><strong>ASP.NET Core</strong>: A cross-platform, modular, cloud-optimized rewrite of ASP.NET.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Common Use Cases:</h2>
    <ul>
      <li><strong>Web Applications</strong>: For building responsive web apps with rich UIs.</li><br />
      <li><strong>Web APIs</strong>: Backend services consumed by front-end clients, mobile apps, and third-party services.</li><br />
      <li><strong>Single Page Applications (SPA)</strong>: Modern SPAs built with Angular, React, or Vue.js, integrated with ASP.NET Core for backend services.</li><br />
      <li><strong>Real-time Applications</strong>: With <strong>SignalR</strong>, enabling real-time data updates for apps like chat, dashboards, and collaborative tools.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Advantages of ASP.NET:</h2>
    <ul>
      <li><strong>Open Source</strong>: ASP.NET Core is open-source, allowing community contributions and access to a wide range of tools.</li><br />
      <li><strong>Rich Development Tools</strong>: Supported by powerful IDEs like <strong>Visual Studio</strong>, offering IntelliSense, debugging, and templates to speed up development.</li><br />
      <li><strong>Seamless Integration</strong>: Works with modern cloud platforms like <strong>Microsoft Azure</strong>, making deployment and scaling easier.</li><br />
      <li><strong>High Performance</strong>: Optimized for performance using features like asynchronous programming and modular components to handle large traffic efficiently.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Example Applications Built Using ASP.NET:</h2>
    <ul>
      <li><strong>E-commerce platforms</strong></li><br />
      <li><strong>Social media sites</strong></li><br />
      <li><strong>Enterprise-level applications</strong></li><br />
      <li><strong>API backends for mobile apps</strong></li><br />
      <li><strong>Content management systems (CMS)</strong></li>
    </ul><br />

    <h2>Conclusion:</h2>
    <p>ASP.NET is a comprehensive framework that allows developers to build anything from small websites to enterprise-level applications. With ASP.NET Core, it's become even more versatile and powerful, providing the tools to develop cross-platform, high-performance web applications.</p>

    <p>Whether you're building an API, real-time app, or large-scale service, ASP.NET offers the flexibility and features to handle it efficiently.</p>
  </div>
)}

{selectedChapter === 'chapter2' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Evolution of ASP.NET (from Web Forms to MVC to Core)</h1>

     <p>ASP.NET has evolved significantly since its inception, adapting to new trends in web development and providing more flexibility, scalability, and cross-platform support. Below is an overview of the key stages in the evolution of ASP.NET, from Web Forms to MVC to ASP.NET Core.</p>

    <br />

    <h3>1. ASP.NET Web Forms (2002)</h3>
    <p style={{paddingBottom:"6px"}}><strong>ASP.NET Web Forms</strong> was the first iteration of ASP.NET and was released in 2002 as part of the .NET Framework. It introduced a new model for building web applications, emphasizing rapid development with event-driven programming. Web Forms abstracts the complexities of HTTP by providing a drag-and-drop, component-based development experience, similar to Windows desktop applications.</p>

    <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
    <ul>
      <li><strong>Event-Driven Programming:</strong> Allowed developers to use server-side events like button clicks and page loads, similar to desktop app development.</li><br />
      <li><strong>ViewState:</strong> Maintains the state of controls across HTTP requests, giving a stateful experience over a stateless protocol.</li><br />
      <li><strong>Server Controls:</strong> Drag-and-drop controls like TextBox, Button, GridView, etc., were provided to simplify development.</li><br />
      <li><strong>Code-Behind Model:</strong> The separation of HTML and C# or VB.NET code was handled using a "code-behind" model, making it easier to maintain and debug code.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Limitations:</h4>
    <ul>
      <li><strong>Heavy Page Lifecycle:</strong> The complex page lifecycle and heavy use of ViewState often resulted in slower performance.</li><br />
      <li><strong>Tight Coupling of UI and Logic:</strong> HTML markup and business logic were tightly coupled, making it difficult to implement modern separation-of-concerns (SoC) principles.</li><br />
      <li><strong>Limited Control over HTML:</strong> Developers had less control over the generated HTML, which made it harder to produce semantic, clean HTML code.</li>
    </ul><br />


    <h3>2. ASP.NET MVC (2009)</h3>
    <p style={{paddingBottom:"6px"}}><strong>ASP.NET MVC</strong> was introduced in 2009 to address the limitations of Web Forms and provide developers with a more flexible, modern web development model. It is based on the <strong>Model-View-Controller (MVC)</strong> design pattern, which separates the concerns of the application into three main components: Model (business logic), View (UI), and Controller (request handling).</p>

    <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
    <ul>
      <li><strong>Separation of Concerns (SoC):</strong> MVC enforces the separation of concerns, making it easier to maintain, test, and scale applications.</li><br />
      <li><strong>Full Control over HTML:</strong> Developers had more control over the generated HTML, allowing for cleaner, more semantic markup.</li><br />
      <li><strong>Routing System:</strong> MVC introduced a flexible routing system, enabling friendly URLs and more intuitive URL patterns.</li><br />
      <li><strong>Testability:</strong> Because of its separation of concerns, ASP.NET MVC made it easier to write unit tests for different parts of the application, especially the business logic and controllers.</li><br />
      <li><strong>No ViewState:</strong> MVC did away with ViewState, leading to lighter, more efficient applications.</li><br />
      <li><strong>Partial Views and Layouts:</strong> Allows reuse of HTML and UI components across multiple views for better maintainability and a consistent look and feel.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Limitations:</h4>
    <ul>
      <li><strong>Steeper Learning Curve:</strong> For developers transitioning from Web Forms, the MVC model had a steeper learning curve due to its more hands-on, code-centric approach.</li><br />
      <li><strong>Still Windows-Dependent:</strong> While MVC was a significant improvement over Web Forms, it was still tightly coupled with the Windows operating system and the full .NET Framework.</li>
    </ul><br />

   
    <h3>3. ASP.NET Core (2016)</h3>
    <p style={{paddingBottom:"6px"}}><strong>ASP.NET Core</strong> was a complete redesign of ASP.NET, released in 2016, aimed at modernizing the platform and making it cross-platform, modular, and cloud-ready. ASP.NET Core represented a significant departure from the previous frameworks, focusing on performance, scalability, and platform independence.</p>

    <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
    <ul>
      <li><strong>Cross-Platform:</strong> ASP.NET Core applications can run on Windows, macOS, and Linux, thanks to its decoupling from the Windows-only .NET Framework.</li><br />
      <li><strong>High Performance:</strong> ASP.NET Core is highly optimized for performance, being one of the fastest web frameworks available, especially in handling large numbers of requests per second.</li><br />
      <li><strong>Modular Architecture:</strong> ASP.NET Core is fully modular, allowing developers to use only the libraries and components they need, reducing application overhead.</li><br />
      <li><strong>Unified Framework:</strong> ASP.NET Core unified the development models of ASP.NET MVC and Web API into a single framework, making it easier to build both web applications and APIs.</li><br />
      <li><strong>Middleware Pipeline:</strong> The request processing pipeline in ASP.NET Core is built using a middleware system, providing more flexibility and control over how requests are handled.</li><br />
      <li><strong>Dependency Injection:</strong> ASP.NET Core comes with built-in support for Dependency Injection (DI), making it easier to manage dependencies and improve testability.</li><br />
      <li><strong>Razor Pages:</strong> Introduced in ASP.NET Core 2.0, Razor Pages simplifies the development of page-based applications, providing an easier alternative to MVC for simpler scenarios.</li><br />
      <li><strong>Cloud-Optimized:</strong> ASP.NET Core is optimized for modern cloud environments, with seamless integration with platforms like Microsoft Azure, Docker, and Kubernetes.</li><br />
      <li><strong>Open Source:</strong> ASP.NET Core is fully open-source, with contributions from both Microsoft and the broader community.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Advantages:</h4>
    <ul>
      <li><strong>Unified Development:</strong> One framework for building both web UIs and APIs.</li><br />
      <li><strong>Cross-Platform Deployment:</strong> Run your application on any platform or in containers (e.g., Docker).</li><br />
      <li><strong>Improved Performance:</strong> Lighter and faster than previous versions of ASP.NET.</li><br />
      <li><strong>Modern Tooling:</strong> Integration with modern development tools like Visual Studio, Visual Studio Code, and command-line interfaces (CLI).</li>
    </ul><br />

 

    <h3 style={{paddingBottom:"6px"}}>Key Milestones in the Evolution of ASP.NET:</h3>
    <ul>
      <li><strong>ASP.NET Web Forms (2002):</strong> The first release, focusing on rapid development with a drag-and-drop UI model.</li><br />
      <li><strong>ASP.NET MVC (2009):</strong> Introduced the MVC pattern for a more flexible, maintainable, and testable web development framework.</li><br />
      <li><strong>ASP.NET Core (2016):</strong> A complete redesign, making ASP.NET modular, cross-platform, cloud-ready, and faster than ever before.</li>
    </ul>

    <br />

    <h3>Conclusion:</h3>
    <p>The evolution of ASP.NET from Web Forms to MVC to Core reflects the changing needs of web development, from rapid application development to modular, scalable, and high-performance frameworks. ASP.NET Core represents the future of the platform, offering flexibility, cross-platform capabilities, and support for modern development practices, making it the ideal choice for building web applications in today's diverse ecosystem. Whether you're building small websites or large-scale enterprise applications, ASP.NET Core provides the tools and frameworks needed to create high-performance, scalable solutions.</p>
  </div>
)}


{selectedChapter === 'chapter3' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Key Features and Benefits of ASP.NET</h1>
    
    <p>Here’s an overview of the key features and benefits of ASP.NET, which you can incorporate into your course or content:</p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of ASP.NET:</h2>
    <ol>
      <li><strong>Cross-Platform Support:</strong>
        <p>With <strong>ASP.NET Core</strong>, developers can build and run web applications on <strong>Windows</strong>, <strong>macOS</strong>, and <strong>Linux</strong>. This cross-platform compatibility is one of the major improvements in ASP.NET Core over the original ASP.NET framework, which was Windows-only.</p>
      </li><br />
      <li><strong>High Performance:</strong>
        <p><strong>ASP.NET Core</strong> is designed for speed and scalability, making it one of the fastest frameworks available. The framework is highly optimized for handling a large number of concurrent requests and can handle millions of requests per second. Features like <strong>Kestrel</strong>, a lightweight web server, contribute to its performance improvements.</p>
      </li><br />
      <li><strong>Modular Architecture:</strong>
        <p>ASP.NET Core offers a <strong>modular</strong> architecture, allowing developers to include only the components they need for their applications, reducing unnecessary overhead and improving performance.</p>
      </li><br />
      <li><strong>Built-in Dependency Injection (DI):</strong>
        <p><strong>Dependency Injection</strong> is natively supported in ASP.NET Core, helping with the decoupling of components, which improves maintainability and testability. DI allows you to inject dependencies into classes, such as database connections or services, making code easier to manage and unit test.</p>
      </li><br />
      <li><strong>Unified Programming Model:</strong>
        <p>ASP.NET Core combines <strong>MVC</strong> (Model-View-Controller) and <strong>Web API</strong> into a single framework, simplifying development by providing a unified model for building both web applications and APIs.</p>
      </li><br />
      <li><strong>Razor Pages:</strong>
        <p><strong>Razor Pages</strong> simplify the development of page-based applications. It provides a more page-centric approach compared to the traditional MVC model and is ideal for smaller applications or those with simple requirements.</p>
      </li><br />
      <li><strong>Integrated Security Features:</strong>
        <p>ASP.NET provides several built-in features to secure applications, including <strong>authentication</strong> (JWT, OAuth, Identity) and <strong>authorization</strong> mechanisms, as well as features like data protection and request validation.</p>
      </li><br />
      <li><strong>Cloud-Ready and Container Support:</strong>
        <p><strong>ASP.NET Core</strong> is optimized for cloud environments and supports hosting on cloud platforms like <strong>Microsoft Azure</strong> and <strong>AWS</strong>. It also supports containerization, making it easy to deploy in Docker containers or Kubernetes clusters.</p>
      </li><br />
      <li><strong>Open-Source and Community-Driven:</strong>
        <p><strong>ASP.NET Core</strong> is open-source, with contributions from both Microsoft and the community. This enables rapid innovation and a large number of community-driven tools and libraries that extend its functionality.</p>
      </li><br />
      <li><strong>Real-Time Communication with SignalR:</strong>
        <p><strong>SignalR</strong> is a library for ASP.NET that facilitates real-time web communication, allowing you to build applications that require live updates, such as chat applications, gaming, and real-time notifications.</p>
      </li><br />
      <li><strong>Rich Ecosystem and Tooling:</strong>
        <p>ASP.NET integrates with a variety of modern development tools such as <strong>Visual Studio</strong>, <strong>Visual Studio Code</strong>, and the <strong>.NET CLI</strong> (Command-Line Interface), improving productivity. It also works seamlessly with <strong>Entity Framework Core</strong> for database management, and <strong>NuGet</strong> for managing third-party libraries.</p>
      </li><br />
      <li><strong>Comprehensive Testing Support:</strong>
        <p>ASP.NET Core provides great support for <strong>unit testing</strong> and <strong>integration testing</strong>. Tools like <strong>xUnit</strong> and <strong>NUnit</strong> are integrated into the framework, making it easier to write testable code and ensuring the reliability of your applications.</p>
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of ASP.NET:</h2>
    <ol>
      <li><strong>Productivity:</strong>
        <p>ASP.NET provides a variety of tools, libraries, and pre-built components to increase development speed. Features like <strong>Scaffolding</strong>, <strong>Razor</strong> templates, and <strong>Entity Framework</strong> significantly reduce the amount of boilerplate code you need to write.</p>
      </li><br />
      <li><strong>Scalability:</strong>
        <p>With its performance optimizations and modular architecture, ASP.NET Core is highly scalable and suitable for both small applications and large, enterprise-level applications that require significant resources.</p>
      </li><br />
      <li><strong>Security:</strong>
        <p>Built-in security features like <strong>data protection</strong>, <strong>input validation</strong>, and <strong>authenticity checks</strong> ensure that ASP.NET applications are protected against common security threats like cross-site scripting (XSS), cross-site request forgery (CSRF), and SQL injection.</p>
      </li><br />
      <li><strong>Cross-Platform Flexibility:</strong>
        <p>The ability to run applications on different operating systems (Windows, Linux, macOS) is a significant benefit, especially in diverse or cloud-based environments.</p>
      </li><br />
      <li><strong>Large Ecosystem:</strong>
        <p>The .NET ecosystem offers a wide range of libraries and tools, including <strong>NuGet packages</strong>, which enable developers to add new features or integrate with third-party services easily.</p>
      </li><br />
      <li><strong>Community and Support:</strong>
        <p>With extensive documentation, a large community, and enterprise-level support from Microsoft, developers can get assistance and resources easily. The open-source nature also ensures that the framework continues to evolve based on community input.</p>
      </li><br />
      <li><strong>Rapid Development:</strong>
        <p>ASP.NET, especially with features like <strong>Razor Pages</strong> and <strong>Blazor</strong> (for building interactive web UIs with C#), provides developers with the tools to quickly build and deploy web applications, while still offering fine-grained control over the HTML and backend logic.</p>
      </li><br />
      <li><strong>Flexibility for Different Application Types:</strong>
        <p>Whether you're building web APIs, real-time applications (via SignalR), or traditional MVC-based websites, ASP.NET offers the flexibility to handle a wide variety of use cases.</p>
      </li><br />
      <li><strong>Low Hosting Costs:</strong>
        <p>ASP.NET Core’s performance and lightweight nature help reduce infrastructure requirements, resulting in lower hosting costs. Additionally, hosting can be done on-premise, in the cloud, or in containers, giving you flexibility in deployment options.</p>
      </li><br />
      <li><strong>Continuous Improvement:</strong>
        <p>The framework continues to evolve rapidly with major updates, ensuring that ASP.NET remains at the cutting edge of web development technology. Microsoft's long-term commitment to ASP.NET Core assures developers that their skills and applications will continue to be supported.</p>
      </li>
    </ol>
<br />
    
  </div>
)}

{selectedChapter === 'chapter4' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>ASP.NET Core vs. ASP.NET Framework</h1>

    <h3>1. Platform Support</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> Runs only on <strong>Windows</strong>. Tightly coupled to the Windows OS, making it less flexible in terms of cross-platform deployment.</li><br />
      <li><strong>ASP.NET Core:</strong> Fully <strong>cross-platform</strong> and runs on <strong>Windows</strong>, <strong>macOS</strong>, and <strong>Linux</strong>. Supports containerization and can be deployed on cloud platforms like <strong>Microsoft Azure</strong>, <strong>AWS</strong>, or in <strong>Docker containers</strong>.</li>
    </ul><br />

    <h3>2. Performance</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> Generally slower and not optimized for high-performance scenarios. Legacy technology with less emphasis on modern optimizations.</li><br />
      <li><strong>ASP.NET Core:</strong> Built for <strong>performance</strong> and optimized for <strong>high-speed execution</strong>. Designed to handle <strong>high concurrency</strong> and large volumes of requests efficiently. Includes <strong>Kestrel</strong>, a lightweight, high-performance web server.</li>
    </ul><br />

    <h3>3. Modularity</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> <strong>Monolithic</strong> and includes everything by default, which can result in unnecessary overhead if only certain components are needed.</li><br />
      <li><strong>ASP.NET Core:</strong> <strong>Modular</strong> in nature, which allows developers to include only the required components, improving performance and reducing the footprint of applications. The framework is divided into <strong>NuGet packages</strong> for different components.</li>
    </ul><br />

    <h3>4. Dependency Injection (DI)</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> <strong>No built-in support</strong> for Dependency Injection. Developers need to rely on third-party libraries to integrate DI.</li><br />
      <li><strong>ASP.NET Core:</strong> <strong>Native support</strong> for Dependency Injection, making it easier to decouple components and improve testability and maintainability. DI is built into the framework and used throughout the application, including in controllers and services.</li>
    </ul><br />

    <h3>5. Web API & MVC</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> <strong>MVC</strong> and <strong>Web API</strong> are separate frameworks, which can sometimes cause confusion and redundancy in projects.</li><br />
      <li><strong>ASP.NET Core:</strong> Combines <strong>MVC</strong> and <strong>Web API</strong> into a <strong>single unified framework</strong>, streamlining development. Developers can build both <strong>web applications</strong> and <strong>RESTful APIs</strong> with the same code base and architecture.</li>
    </ul><br />

    <h3>6. Security</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> Security features are available, but often outdated and not as flexible for modern requirements. More reliance on Windows security mechanisms.</li><br />
      <li><strong>ASP.NET Core:</strong> Enhanced <strong>security features</strong>, such as <strong>data protection</strong>, <strong>authentication</strong> (JWT, OAuth, Identity), and <strong>authorization</strong> mechanisms. More flexible and robust, with modern techniques integrated by default. Includes <strong>built-in middleware</strong> for common security tasks like HTTPS redirection, content security, and request validation.</li>
    </ul><br />

    <h3>7. Development & Tooling</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> Primarily uses <strong>Visual Studio</strong> for development, which is Windows-only. Limited support for non-Windows environments.</li><br />
      <li><strong>ASP.NET Core:</strong> Supports cross-platform development using <strong>Visual Studio</strong>, <strong>Visual Studio Code</strong>, and the <strong>.NET CLI</strong>. Can be developed on <strong>Windows</strong>, <strong>macOS</strong>, or <strong>Linux</strong> and can run in any of these environments seamlessly.</li>
    </ul><br />

    <h3>8. Configuration</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> Uses <strong>XML-based configuration</strong> (web.config), which can become cumbersome and difficult to manage in larger applications.</li><br />
      <li><strong>ASP.NET Core:</strong> Uses a <strong>JSON-based configuration system</strong> (appsettings.json) and supports <strong>environment-based configuration</strong>. More flexible and developer-friendly, allowing for simpler configuration management.</li>
    </ul><br />

    <h3>9. Hosting & Deployment</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> Typically hosted on <strong>Internet Information Services (IIS)</strong>. Works only on Windows environments.</li><br />
      <li><strong>ASP.NET Core:</strong> Can be hosted on <strong>Kestrel</strong>, <strong>IIS</strong>, or other web servers. Supports a variety of hosting options, including <strong>Linux</strong>, <strong>Windows</strong>, <strong>Docker</strong>, and cloud-based platforms (e.g., Azure).</li>
    </ul>

    <h3>10. Open Source</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> <strong>Not open-source</strong>; it's closed-source and maintained solely by Microsoft.</li><br />
      <li><strong>ASP.NET Core:</strong> <strong>Open-source</strong> and community-driven. Contributions from Microsoft and the global developer community lead to rapid development and innovation.</li>
    </ul><br />

    <h3>11. Updates & Long-Term Support</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> Has <strong>limited updates</strong> in comparison to ASP.NET Core and is mostly in maintenance mode. <strong>Long-term support</strong> but no significant new features are being added.</li><br />
      <li><strong>ASP.NET Core:</strong> Actively developed and regularly updated with new features and performance improvements. <strong>Long-term support</strong> (LTS) versions are available, but new features are also frequently released.</li>
    </ul><br />

    <h3>12. Use Cases</h3>
    <ul>
      <li><strong>ASP.NET Framework:</strong> Best suited for <strong>legacy applications</strong> or environments where upgrading to ASP.NET Core is not feasible. Still widely used in older enterprise applications that require compatibility with Windows-specific technologies.</li><br />
      <li><strong>ASP.NET Core:</strong> Ideal for <strong>new web applications</strong>, APIs, microservices, and cloud-based projects. It’s the go-to framework for modern web development and highly scalable applications.</li>
    </ul><br />

    <h2>Summary:</h2>
    <p>
      <strong>ASP.NET Core</strong> is a modern, high-performance, cross-platform framework that is flexible, open-source, and optimized for the cloud, while <strong>ASP.NET Framework</strong> is a more traditional framework that works only on Windows and is primarily for legacy applications. If you're building new web applications or APIs, ASP.NET Core is the preferred choice due to its performance, security, flexibility, and cross-platform support. If you're maintaining an existing application built on the older ASP.NET framework, you might continue using it but consider migrating to ASP.NET Core for future development.
    </p>
  </div>
)}


{selectedChapter === 'chapter5' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Installing Visual Studio for ASP.NET Development</h1>
    
    <p>Follow these steps to install Visual Studio for ASP.NET development:</p>

    <ol>
      <li>
        <strong>Download Visual Studio Installer</strong>
        <ul>
          <li>Go to the official <a href="https://visualstudio.microsoft.com/downloads/" target="_blank" rel="noopener noreferrer">Visual Studio download page</a>.</li><br />
          <li>Select <strong>Visual Studio Community</strong> (free version) or another edition if needed (Professional or Enterprise).</li><br />
          <li>Download the <strong>Visual Studio Installer</strong>.</li>
        </ul>
      </li><br />

      <li>
        <strong>Launch the Installer</strong>
        <ul>
          <li>Open the downloaded installer file to begin the installation process.</li><br />
          <li>The installer will check for updates and proceed with installation options.</li>
        </ul>
      </li><br />

      <li>
        <strong>Select Workloads</strong>
        <ul>
          <li>In the Visual Studio Installer, you’ll see a list of <strong>Workloads</strong> (pre-configured sets of tools for different types of development).</li><br />
          <li>Select the <strong>ASP.NET and web development</strong> workload, which includes tools for ASP.NET and ASP.NET Core development.</li><br />
          <li>Optionally, you can choose additional workloads such as:
            <ul>
              <li><strong>.NET desktop development</strong></li><br />
              <li><strong>Azure development</strong></li><br />
              <li><strong>Python development</strong></li>
            </ul>
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Additional Options (Optional)</strong>
        <ul>
          <li>Under the workloads, you can choose <strong>Individual components</strong> and <strong>Language packs</strong> to further customize your installation:
            <ul>
              <li><strong>.NET Core SDK</strong>: Select this if you need a specific version of .NET Core.</li><br />
              <li><strong>Git for Windows</strong>: Install Git alongside Visual Studio.</li><br />
              <li><strong>SQL Server Data Tools</strong>: If your application interacts with SQL Server, you can add this.</li>
            </ul>
          </li>
          <li>You can also change the installation location if needed.</li>
        </ul>
      </li><br />

      <li>
        <strong>Install</strong>
        <ul>
          <li>Once you've selected the necessary workloads and options, click <strong>Install</strong>.</li><br />
          <li>The installer will download and install the components, which may take some time depending on your internet connection.</li>
        </ul>
      </li><br />

      <li>
        <strong>Launch Visual Studio</strong>
        <ul>
          <li>After installation, click <strong>Launch</strong> to start Visual Studio.</li><br />
          <li>If this is your first time, you may be prompted to sign in (optional) and choose a theme (light or dark mode).</li>
        </ul>
      </li><br />

      <li>
        <strong>Create a New ASP.NET Project</strong>
        <ul>
          <li>Once Visual Studio opens, click <strong>Create a new project</strong>.</li><br />
          <li>Search for <strong>ASP.NET Core</strong> to find templates for ASP.NET Core web applications.</li><br />
          <li>Select the project type (e.g., <strong>Web Application (Model-View-Controller)</strong>, <strong>Web API</strong>, etc.).</li><br />
          <li>Follow the prompts to configure your new project and click <strong>Create</strong>.</li>
        </ul>
      </li><br />

      <li>
        <strong>Install .NET SDK (if needed)</strong>
        <ul>
          <li>Ensure you have the latest version of the .NET SDK installed if you're working with <strong>ASP.NET Core</strong>.</li><br />
          <li>Check the installed version by running <code>dotnet --version</code> in the terminal.</li><br />
          <li>If needed, download the latest .NET SDK from <a href="https://dotnet.microsoft.com/download" target="_blank" rel="noopener noreferrer">here</a>.</li>
        </ul>
      </li><br />

      <li>
        <strong>Start Developing</strong>
        <ul>
          <li>You are now ready to develop your ASP.NET Core application using Visual Studio's tools, such as IntelliSense, debugging, and version control integration.</li>
        </ul>
      </li>
    </ol>

  <br />

    <h2 style={{paddingBottom:"6px"}}>Troubleshooting Tips:</h2>
    <ul>
      <li><strong>Check System Requirements:</strong> Ensure your system meets the minimum requirements for Visual Studio and ASP.NET development. Check the <a href="https://visualstudio.microsoft.com/vs/" target="_blank" rel="noopener noreferrer">Visual Studio website</a> for more information.</li><br />
      <li><strong>Install Updates:</strong> Make sure Visual Studio is fully updated by going to <strong>Help {">"} Check for Updates</strong>.</li><br />
      <li><strong>Use the Visual Studio Installer:</strong> Modify your installation by reopening the Visual Studio Installer and adding or removing components.</li>
    </ul><br />

    <p>Once Visual Studio is installed, you're all set to start building ASP.NET applications!</p>
  </div>
)}


{selectedChapter === 'chapter6' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to .NET Core SDK and CLI</h1>

      
      <p>
        The <strong>.NET Core SDK</strong> and <strong>CLI (Command-Line Interface)</strong> are crucial tools for developing ASP.NET applications, whether you're building web APIs, MVC applications, or microservices. ASP.NET Core is a cross-platform framework built on top of .NET Core, designed for creating modern, scalable, and high-performance web applications.
      </p><br />
      <p>
        In this section, we’ll explore the <strong>.NET Core SDK</strong> and <strong>CLI</strong> specifically within the context of <strong>ASP.NET Core development</strong>.
      </p>


      <h3>What is the .NET Core SDK?</h3>
      <p>
        The <strong>.NET Core SDK (Software Development Kit)</strong> is a set of tools, libraries, and runtime that developers need to build, test, and run .NET Core applications. It includes everything you need to create ASP.NET Core applications:
      </p>
      <ul>
        <li><strong>.NET Core runtime</strong>: Executes the application, providing the necessary APIs and libraries.</li><br />
        <li><strong>Compilers and build tools</strong>: Converts your C# code into executable programs.</li><br />
        <li><strong>Project templates</strong>: Pre-configured templates for building ASP.NET Core applications, including MVC, Web API, and Blazor apps.</li><br />
        <li><strong>Testing libraries</strong>: For writing and running unit tests with frameworks like xUnit or NUnit.</li>
      </ul><br />
      <p>
        The SDK also includes the <strong>ASP.NET Core runtime</strong>, which provides the necessary libraries and features to build web-based applications.
      </p>
      <p>
        To develop ASP.NET Core applications, you need to install the .NET Core SDK, which can be downloaded from the official <a href="https://dotnet.microsoft.com/download" target="_blank" rel="noopener noreferrer">.NET download page</a>.
      </p>

      <br />

      <h3>What is the .NET Core CLI?</h3>
      <p>
        The <strong>.NET Core CLI</strong> is a command-line tool that enables developers to manage, build, run, and publish .NET Core applications directly from the terminal or command prompt. It is especially useful for those who prefer working in a terminal environment or need to automate workflows in CI/CD pipelines.
      </p>
      <p>
        In ASP.NET Core development, the CLI provides a fast, efficient way to:
      </p>
      <ul>
        <li><strong>Create</strong> new ASP.NET Core projects.</li><br />
        <li><strong>Build</strong> and <strong>run</strong> ASP.NET Core applications.</li><br />
        <li><strong>Publish</strong> applications for deployment.</li><br />
        <li><strong>Manage packages</strong> and dependencies.</li><br />
        <li><strong>Test</strong> ASP.NET Core applications.</li>
      </ul><br />
      <p>
        The CLI is cross-platform and can be used on Windows, macOS, and Linux, making it an essential tool for modern, platform-independent ASP.NET Core development.
      </p>

      <br />

      <h3 style={{paddingBottom:"6px"}}>Commonly Used .NET Core CLI Commands for ASP.NET Core</h3>

      <h4>1. Create a New ASP.NET Core Project</h4>
      <p>You can create new ASP.NET Core projects using pre-defined templates with the <code>dotnet new</code> command. For example, to create a new ASP.NET Core Web API project:</p>
      <pre><code>dotnet new webapi -n MyWebAPI</code></pre>
      <p>This command creates a new Web API project named <code>MyWebAPI</code>. Other useful templates include:</p>
      <ul>
        <li><code>dotnet new mvc</code>: Creates an ASP.NET Core MVC application.</li><br />
        <li><code>dotnet new razor</code>: Creates an application with Razor Pages.</li><br />
        <li><code>dotnet new blazor</code>: Creates a Blazor WebAssembly or Blazor Server app.</li>
      </ul><br />

      <h4>2. Build the ASP.NET Core Application</h4>
      <p>Once you’ve created a project, you can build it using the following command:</p>
      <pre><code>dotnet build</code></pre><br />

      <h4>3. Run the ASP.NET Core Application</h4>
      <p>To start the ASP.NET Core application locally, use the <code>dotnet run</code> command:</p>
      <pre><code>dotnet run</code></pre>
      <p>By default, the application will run on <code>http://localhost:5000</code> or <code>https://localhost:5001</code> (for HTTPS).</p><br />

      <h4>4. Restore Project Dependencies</h4>
      <p>If you’ve added or modified the project dependencies (e.g., NuGet packages), use the <code>dotnet restore</code> command:</p>
      <pre><code>dotnet restore</code></pre><br />

      <h4>5. Publish the Application</h4>
      <p>When you’re ready to deploy your ASP.NET Core application to production or another environment, use the <code>dotnet publish</code> command:</p>
      <pre><code>dotnet publish -c Release -o ./publish</code></pre><br />

      <h4>6. Test the ASP.NET Core Application</h4>
      <p>You can run unit tests for your ASP.NET Core project using the <code>dotnet test</code> command:</p>
      <pre><code>dotnet test</code></pre><br />

      <h4>7. Add NuGet Packages</h4>
      <p>You can add third-party packages to your ASP.NET Core application using the CLI:</p>
      <pre><code>dotnet add package Newtonsoft.Json</code></pre>

  <br />

      <h3>Installing and Setting Up .NET Core SDK for ASP.NET Development</h3>
      <ol>
        <li><strong>Download the .NET Core SDK</strong>: Visit the <a href="https://dotnet.microsoft.com/download" target="_blank" rel="noopener noreferrer">official .NET download page</a> and select the latest version of the .NET SDK.</li><br />
        <li><strong>Run the Installer</strong>: After downloading, run the installer for your operating system (Windows, macOS, or Linux).</li><br />
        <li><strong>Verify the Installation</strong>: Open the terminal or command prompt and run the following command to verify the installation:
          <pre><code>dotnet --version</code></pre>
        </li>
      </ol>

     <br />

      <h3 style={{paddingBottom:"6px"}}>Benefits of Using .NET Core SDK and CLI in ASP.NET Core Development</h3>
      <ul>
        <li><strong>Cross-Platform Development</strong>: Develop ASP.NET Core applications on Windows, macOS, or Linux, ensuring your application can run on any platform.</li><br />
        <li><strong>Efficiency</strong>: The CLI provides fast, command-based workflows for creating, building, running, and publishing applications.</li><br />
        <li><strong>Flexibility</strong>: The .NET Core SDK and CLI offer great flexibility, allowing you to integrate with CI/CD pipelines, automate tasks, and work in a terminal environment.</li><br />
        <li><strong>Easy Deployment</strong>: With the <code>dotnet publish</code> command, you can easily package your application for deployment on various platforms, including Windows, Linux, and Docker containers.</li><br />
        <li><strong>Lightweight</strong>: The .NET Core SDK is lightweight and easy to install, making it ideal for developers who want to start building ASP.NET Core applications quickly.</li>
      </ul><br />

   
      <h3>Conclusion</h3>
      <p>
        The <strong>.NET Core SDK</strong> and <strong>CLI</strong> are powerful tools for ASP.NET Core developers, enabling efficient workflows for creating, building, running, and deploying web applications. With the cross-platform nature of .NET Core, you can develop applications on any operating system while using the CLI for streamlined, scriptable development. Whether you're building web APIs, MVC applications, or microservices, the .NET Core SDK and CLI provide everything you need to get started quickly and effectively.
      </p>
  
  </div>
)}

{selectedChapter === 'chapter7' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Creating Your First ASP.NET Core Application</h1>

    <p>To create your first ASP.NET Core application, follow these steps:</p>

    <h3>Prerequisites</h3>
    <ol>
      <li>
        <strong>Install .NET SDK:</strong> Make sure the latest .NET SDK is installed on your system. You can download it from{' '}
        <a href="https://dotnet.microsoft.com/download" target="_blank" rel="noopener noreferrer">Microsoft's official site</a>.
      </li><br />
      <li>
        <strong>IDE (Optional):</strong> Visual Studio or Visual Studio Code (VS Code) are commonly used IDEs for ASP.NET Core development. Visual Studio provides a complete suite, while VS Code is lightweight and extensible.
      </li>
    </ol><br />

    <h3>Step 1: Create a New ASP.NET Core Web Application</h3>
    <ol>
      <li><strong>Open a terminal/command prompt</strong> (or use the terminal in VS Code).</li><br />
      <li>Navigate to the directory where you want to create your project.</li><br />
      <li>Run the following command to create a new ASP.NET Core Web Application:</li>
    </ol><br />
    <pre><code>dotnet new webapp -n MyFirstAspNetCoreApp</code></pre>
    <p>This will create a new ASP.NET Core application named <code>MyFirstAspNetCoreApp</code>. The <code>webapp</code> template creates a simple web application.</p><br />

    <h3>Step 2: Explore the Project Structure</h3>
    <ol>
      <li>Once the project is created, navigate to the project folder:</li>
    </ol>
    <pre><code>cd MyFirstAspNetCoreApp</code></pre>

    <p style={{paddingBottom:"6px"}}>You will see several files and folders:</p>
    <ul>
      <li><strong>Program.cs:</strong> This is where the application is configured and started. It contains the <code>CreateHostBuilder</code> method and the setup for the web host.</li><br />
      <li><strong>Startup.cs:</strong> This is where you configure services and the app's request pipeline. It is used for middleware configuration, adding services like MVC, and more.</li><br />
      <li><strong>Pages/:</strong> This folder contains Razor Pages for the UI, including default pages such as <code>Index.cshtml</code> (for the home page).</li><br />
      <li><strong>appsettings.json:</strong> This file is used to configure application settings, like connection strings, environment settings, etc.</li>
    </ul><br />

    <h3>Step 3: Run the Application</h3>
    <ol>
      <li>To run the application, use the following command in the terminal:</li>
    </ol>
    <pre><code>dotnet run</code></pre>

    <p>Once the application starts, you will see a message indicating which port it is running on (usually <code>http://localhost:5000</code>).</p>

    <p>Open your browser and navigate to <code>http://localhost:5000</code>. You should see the default ASP.NET Core web page.</p><br />

    <h3>Step 4: Modify the Application</h3>
    <ol>
      <li><strong>Modify the Home Page:</strong> Open the <code>Pages/Index.cshtml</code> file and make some changes to the HTML. For example:</li>
    </ol>
    <pre><code>&lt;h1&gt;Welcome to My First ASP.NET Core Application!&lt;/h1&gt;</code></pre>

    <ol start="2">
      <li><strong>Modify the Logic:</strong> If you want to add logic or data, open <code>Pages/Index.cshtml.cs</code> and modify the code-behind class. For example, you can pass a model to the view:</li>
    </ol>
    <pre><code>{`
public class IndexModel : PageModel
{
    public string Message { get; set; }

    public void OnGet()
    {
        Message = "Hello, ASP.NET Core!";
    }
}
     `} </code></pre>

    <p>Then, in the <code>Index.cshtml</code>:</p>
    <pre><code>&lt;h2&gt;@Model.Message&lt;/h2&gt;</code></pre><br />

    <h3>Step 5: Add Routing and Views (Optional)</h3>
    <p>To handle different requests and views, you can add more Razor Pages or Controllers and Views (for MVC).</p>
    <ol>
      <li><strong>Add a New Razor Page:</strong> You can create a new Razor Page by adding a new <code>.cshtml</code> file under <code>Pages/</code> and creating its corresponding page model.</li><br />
      <li><strong>Add MVC Controllers (Optional):</strong> If you prefer MVC architecture instead of Razor Pages, modify <code>Startup.cs</code> to use controllers and views:</li>
    </ol>
    <pre><code>services.AddControllersWithViews();</code></pre><br />

    <h3>Step 6: Debugging and Testing</h3>
    <ol>
      <li><strong>Set Breakpoints:</strong> In Visual Studio or VS Code, set breakpoints in your code and run the application in debug mode to inspect values and catch errors.</li><br />
      <li><strong>Test APIs:</strong> You can also add API controllers to handle HTTP requests.</li>
    </ol><br />

    <h3>Step 7: Deploying the Application (Optional)</h3>
    <p>Once your application is ready, you can deploy it to Azure, IIS, or other platforms:</p>
    <ol>
      <li><strong>Azure:</strong> Use Visual Studio or the Azure CLI to deploy directly to an Azure App Service.</li><br />
      <li><strong>IIS:</strong> Publish the app and deploy it on IIS (Internet Information Services).</li>
    </ol>

<br />

    <h3>Conclusion</h3>
    <p>This is how you can create your first ASP.NET Core web application. From here, you can expand by adding more complex features, integrating with databases, implementing authentication/authorization, and deploying it to production environments.</p>
  </div>
)}
{selectedChapter === 'chapter8' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      ASP.NET Project Types (Web Forms, MVC, Web API, Razor Pages, Blazor)
    </h1>
    <p style={{paddingBottom:"6px"}}>ASP.NET Core supports several project types, each suited for different kinds of web applications. In this section, we’ll explore the key project types you can create when building applications with ASP.NET.</p>

    
      <h3>1. ASP.NET Web Forms</h3>
      <p style={{paddingBottom:"6px"}}>
        ASP.NET Web Forms is a traditional web application model that uses a drag-and-drop style of development.
        It was the default choice in early versions of ASP.NET, and it still exists in the framework for legacy applications.
      </p>

      <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
      <ul>
        <li><strong>Event-driven programming model</strong>: Similar to desktop applications, Web Forms relies on a control-based structure with events like button clicks and page loads.</li><br />
        <li><strong>Rich controls</strong>: Web Forms provide a set of powerful built-in controls like grids, calendars, and input forms.</li><br />
        <li><strong>ViewState</strong>: Web Forms use ViewState to persist the state of controls between page requests.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>When to Use:</h4>
      <ul>
        <li>Legacy applications that have been built using Web Forms.</li><br />
        <li>Rapid application development (RAD) for applications that do not require fine-grained control over the HTML output.</li>
      </ul><br />

      <h4>How to Create a Web Forms Project:</h4>
      <code>
        dotnet new webforms -n MyWebFormsApp
      </code><br /><br />
 
   
      <h3>2. ASP.NET MVC (Model-View-Controller)</h3>
      <p style={{paddingBottom:"6px"}}>
        ASP.NET MVC is a widely used pattern for developing web applications. It promotes the separation of concerns by splitting the application into three interconnected components: <strong>Model</strong>, <strong>View</strong>, and <strong>Controller</strong>.
      </p>

      <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
      <ul>
        <li><strong>Separation of concerns</strong>: The MVC pattern separates business logic, user interface, and input handling.</li><br />
        <li><strong>Full control over HTML</strong>: Unlike Web Forms, MVC provides full control over the generated HTML, which is ideal for fine-tuned and responsive web applications.</li><br />
        <li><strong>Routing</strong>: MVC uses routing to map URLs to controller actions.</li><br />
        <li><strong>Testability</strong>: The design of MVC makes it easy to test individual components.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>When to Use:</h4>
      <ul>
        <li>Applications that require a clean separation of concerns and fine-grained control over the HTML.</li><br />
        <li>Projects that need easy integration with REST APIs and third-party services.</li>
      </ul><br />

      <h4>How to Create an MVC Project:</h4>
      <code>
        dotnet new mvc -n MyMvcApp
      </code><br /><br />
    
      <h3>3. ASP.NET Web API</h3>
      <p style={{paddingBottom:"6px"}}>
        Web API is a framework for building HTTP-based services that can serve data to clients over HTTP. It’s particularly used for creating RESTful services and handling API requests.
      </p>

      <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
      <ul>
        <li><strong>RESTful services</strong>: Web API follows REST principles for building lightweight services.</li><br />
        <li><strong>Content negotiation</strong>: It allows you to return different formats (JSON, XML, etc.) depending on the client’s request.</li><br />
        <li><strong>Stateless</strong>: Web API is stateless by design, meaning each request contains all the necessary information.</li><br />
        <li><strong>Cross-platform</strong>: API services can be consumed by various platforms like web, mobile, and desktop.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>When to Use:</h4>
      <ul>
        <li>To create REST APIs or services that can be consumed by other applications or platforms.</li><br />
        <li>When you need a service-only application, without a user interface (UI).</li>
      </ul><br />

      <h4>How to Create a Web API Project:</h4>
      <code>
        dotnet new webapi -n MyWebApiApp
      </code><br /><br />
   
      <h3>4. ASP.NET Razor Pages</h3>
      <p style={{paddingBottom:"6px"}}>
        Razor Pages is a page-based programming model that is simpler than MVC and is great for building dynamic, data-driven web applications. It’s based on Razor views but uses the concept of "pages" instead of controllers.
      </p>

      <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
      <ul>
        <li><strong>Page-centric</strong>: Each page in Razor Pages corresponds to a handler method in its code-behind file.</li><br />
        <li><strong>Simpler than MVC</strong>: Razor Pages is considered simpler and more straightforward for small and medium-sized web applications.</li><br />
        <li><strong>Built on Razor</strong>: Razor Pages uses the same Razor view engine as MVC, meaning it supports the same view syntax and allows dynamic rendering of HTML.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>When to Use:</h4>
      <ul>
        <li>When you need a simpler, more streamlined approach to building web applications.</li><br />
        <li>For smaller projects or when you need to quickly build pages without the complexity of MVC.</li>
      </ul><br />

      <h4>How to Create a Razor Pages Project:</h4>
      <code style={{paddingBottom:"6px"}}>
        dotnet new webapp -n MyRazorPagesApp
      </code><br /><br />
   
      <h3 style={{paddingBottom:"6px"}}>5. Blazor</h3>
      <p>
        Blazor is a framework for building interactive web applications using C# instead of JavaScript. It allows you to run .NET code in the browser via WebAssembly, or on the server with Blazor Server.
      </p>

      <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
      <ul>
        <li><strong>C# in the browser</strong>: Blazor allows you to build client-side applications using C# code instead of JavaScript.</li><br />
        <li><strong>Two hosting models</strong>:
          <ul>
            <li><strong>Blazor WebAssembly</strong>: Runs .NET code directly in the browser using WebAssembly.</li><br />
            <li><strong>Blazor Server</strong>: Runs the application logic on the server, and the UI is rendered in the browser via SignalR.</li>
          </ul><br />
        </li>
        <li><strong>Component-based architecture</strong>: Blazor applications are composed of reusable components.</li><br />
        <li><strong>Full-stack development</strong>: You can use C# for both client and server-side logic, making it easier for developers familiar with C# to work end-to-end.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>When to Use:</h4>
      <ul>
        <li>For building interactive single-page applications (SPAs) without relying on JavaScript.</li><br />
        <li>If you want to leverage .NET technologies for both the client and server side of a web application.</li><br />
        <li>When building modern web applications with rich interactivity.</li>
      </ul><br />

      <h4>How to Create a Blazor Project:</h4>
      <code>
        dotnet new blazorwasm -n MyBlazorApp
      </code><br /><br />
      <p>For Blazor Server:</p>
      <code>
        dotnet new blazorserver -n MyBlazorServerApp
      </code><br /><br />
    

    <h3>Conclusion</h3>
    <p>Each ASP.NET project type serves a different purpose, and your choice will depend on the requirements of your application. Here’s a quick summary:</p>
    <table>
      <thead>
        <tr>
          <th><strong>Project Type</strong></th>
          <th><strong>Best For</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Web Forms</td>
          <td>Legacy applications, quick RAD development</td>
        </tr>
        <tr>
          <td>MVC</td>
          <td>Scalable, maintainable applications with full control over HTML</td>
        </tr>
        <tr>
          <td>Web API</td>
          <td>RESTful services and API backends</td>
        </tr>
        <tr>
          <td>Razor Pages</td>
          <td>Simpler applications and page-centric designs</td>
        </tr>
        <tr>
          <td>Blazor</td>
          <td>Interactive web applications using C#</td>
        </tr>
      </tbody>
    </table>
  </div>
)}


{selectedChapter === 'chapter9' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Request-Response Pipeline</h1>
        
        <p>
          The <strong>Request-Response Pipeline</strong> is a core component of the <strong>ASP.NET Core</strong> architecture 
          that manages how HTTP requests are processed and how responses are generated and returned to the client. It consists 
          of a series of <strong>middleware components</strong> that handle various aspects of a request, such as routing, 
          authentication, and response formatting.
        </p><br />
    
        <h3 style={{paddingBottom:"6px"}}>Key Concepts</h3>

        <h4>1. ASP.NET Core Architecture Overview</h4>
        <ul>
          <li>ASP.NET Core is a modern, cross-platform framework designed for building web applications.</li><br />
          <li>It is modular and lightweight, featuring components like <strong>middleware</strong>, <strong>routing</strong>, and <strong>dependency injection</strong>.</li>
        </ul><br />

        <h4>2. Middleware</h4>
        <p>
          Middleware are the components that handle HTTP requests in the pipeline. Each middleware can:
        </p>
        <ul>
          <li>Modify the incoming request or outgoing response.</li><br />
          <li>Forward the request to the next middleware in the pipeline.</li><br />
          <li>End the request by sending a response directly.</li>
        </ul><br />

        <h4 style={{paddingBottom:"6px"}}>3. Request-Response Pipeline Flow</h4>
        <ul>
          <li><strong>Client Request</strong>: A user sends an HTTP request (GET, POST, etc.) to the server.</li><br />
          <li><strong>Web Server</strong>: The server (Kestrel or IIS) forwards the request to the ASP.NET Core application.</li><br />
          <li><strong>Middleware</strong>: The request flows through a series of middleware components that can process or manipulate the request.</li><br />
          <li><strong>Routing</strong>: The request is routed to a specific controller action or Razor Page based on the URL.</li><br />
          <li><strong>Controller/Action</strong>: The logic for processing the request happens here (e.g., querying a database).</li><br />
          <li><strong>Response</strong>: The response is passed back through the middleware to be sent to the client.</li>
        </ul><br />

        <h4 style={{paddingBottom:"6px"}}>4. Middleware Examples</h4>
        <ul>
          <li><strong>Logging Middleware</strong>: Logs request and response details.</li><br />
          <li><strong>Static Files Middleware</strong>: Serves static files like CSS or images.</li><br />
          <li><strong>Authentication Middleware</strong>: Verifies the identity of the user.</li><br />
          <li><strong>Error Handling Middleware</strong>: Manages exceptions and errors.</li>
        </ul><br />

        <h4>5. Routing Middleware</h4>
        <p>
          Matches the URL of the request to a defined route and forwards the request to the appropriate controller or action method.
        </p><br />

        <h4>6. Customization and Flexibility</h4>
        <p>
          Developers can easily add, remove, or customize middleware components to fit the needs of their application. The pipeline is 
          <strong>asynchronous</strong>, meaning it handles requests efficiently using <code>async</code>/<code>await</code>.
        </p><br />
     
        <h3>Conclusion</h3>
        <p>
          The <strong>Request-Response Pipeline</strong> in ASP.NET Core plays a crucial role in processing HTTP requests and generating responses. 
          Its modular design allows for high flexibility, enabling developers to create efficient, scalable, and secure web applications by 
          managing how each request is handled from start to finish. Understanding and customizing this pipeline is key to building robust 
          applications in ASP.NET Core.
        </p>
    
    
  </div>
)}

{selectedChapter === 'chapter10' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Middleware in ASP.NET Core</h1>
    <div className={style.content}>

      <p>
        Model binding and validation are fundamental concepts in ASP.NET Core for processing and
        validating user input in a web application. Here's an overview of each concept:
      </p>
      <br />
      <h3>1. Model Binding</h3>
      <p style={{paddingBottom:"6px"}}>
        <strong>Model Binding</strong> is the process of mapping HTTP request data (query strings,
        form data, route data, etc.) to controller action parameters or model properties.
      </p>

      <h4>How Model Binding Works</h4>
      <ol>
        <li>
          <strong>HTTP Request Data Sources</strong>:
          <ul>
            <li>Form fields: Data submitted via forms (e.g., POST method).</li><br />
            <li>Route Data: Data in the URL specified in routing.</li><br />
            <li>Query String: Parameters in the URL after the "?" symbol.</li><br />
            <li>Headers: HTTP headers in the request.</li><br />
            <li>JSON Body: JSON data in the request body.</li>
          </ul>
        </li><br />
        <li>
          <strong>Binding Process</strong>: ASP.NET Core inspects the HTTP request and tries to
          match the incoming data with the action's parameter names or the model's properties.
        </li><br />
        <li>
          <strong>Supported Parameter Types</strong>:
          <ul>
            <li>Primitive types (e.g., int, string, DateTime).</li><br />
            <li>Complex types (e.g., classes and objects).</li><br />
            <li>Collections (e.g., List&lt;T&gt;, arrays).</li>
          </ul>
        </li>
      </ol><br />

      <h4>Example:</h4>
      <pre>
        <code>
          {`public IActionResult SubmitForm(string name, int age)
{
    // 'name' and 'age' will be populated from query string, form, or route data.
    return Ok($"Name: {name}, Age: {age}");
}

// URL: /SubmitForm?name=John&age=25`}
        </code>
      </pre><br />

      <h4>Using a Model:</h4>
      <pre>
        <code>
          {`public class UserModel
{
    public string Name { get; set; }
    public int Age { get; set; }
}

public IActionResult SubmitForm(UserModel model)
{
    // 'model' properties will be populated from the HTTP request.
    return Ok($"Name: {model.Name}, Age: {model.Age}");
}`}
        </code>
      </pre>

    <br />
      <h3>2. Validation</h3>
      <p style={{paddingBottom:"6px"}}>
        ASP.NET Core provides built-in support for <strong>server-side validation</strong> of user
        input using <strong>data annotations</strong> and custom validation logic.
      </p>

      <h4>Data Annotations</h4>
      <p>Attributes that define validation rules are applied to model properties.</p>
      <ul>
        <li>
          <code>[Required]</code>: Ensures the field is not empty.
        </li><br />
        <li>
          <code>[StringLength(maximumLength)]</code>: Limits the length of a string.
        </li><br />
        <li>
          <code>[Range(min, max)]</code>: Ensures a value falls within a range.
        </li><br />
        <li>
          <code>[RegularExpression(pattern)]</code>: Validates against a regular expression.
        </li><br />
        <li>
          <code>[EmailAddress]</code>: Validates email format.
        </li><br />
        <li>
          <code>[Compare(otherPropertyName)]</code>: Ensures two fields match.
        </li>
      </ul><br />

      <h4>Example:</h4>
      <pre>
        <code>
          {`public class UserModel
{
    [Required(ErrorMessage = "Name is required.")]
    [StringLength(50, ErrorMessage = "Name cannot exceed 50 characters.")]
    public string Name { get; set; }

    [Range(18, 100, ErrorMessage = "Age must be between 18 and 100.")]
    public int Age { get; set; }
}`}
        </code>
      </pre><br />

      <h4>Validation in a Controller:</h4>
      <pre>
        <code>
          {`[HttpPost]
public IActionResult SubmitForm(UserModel model)
{
    if (!ModelState.IsValid)
    {
        // Model validation failed
        return BadRequest(ModelState);
    }
    // Validation passed
    return Ok($"Name: {model.Name}, Age: {model.Age}");
}`}
        </code>
      </pre>

      {/* Include additional sections similarly */}
    </div>
  </div>
)}


{selectedChapter === 'chapter11' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Dependency Injection in ASP.NET Core</h1>
    <p>
      Dependency Injection (DI) is a design pattern that allows a class to request its dependencies 
      from an external source rather than creating them internally. ASP.NET Core has built-in 
      support for DI, which helps achieve loose coupling, better testability, and maintainable code. 
      This makes it an integral part of ASP.NET Core architecture.
    </p><br />

    <h2>Why Dependency Injection?</h2>
    <p>The main benefits of Dependency Injection in ASP.NET Core include:</p>
    <ul>
      <li><strong>Loose Coupling:</strong> Classes are not tightly bound to their dependencies, making the system more flexible and easier to modify.</li><br />
      <li><strong>Testability:</strong> It allows for easy mocking of dependencies in unit tests.</li><br />
      <li><strong>Maintainability:</strong> DI promotes the principle of Separation of Concerns, making code more modular and easier to maintain.</li>
    </ul><br />

    <h2>Built-in DI Container in ASP.NET Core</h2>
    <p>
      ASP.NET Core provides a lightweight, built-in Dependency Injection container that supports 
      constructor injection, method injection, and property injection. The DI container is 
      responsible for creating and managing the lifetime of objects based on the services registered.
    </p><br />

    <h2>DI Registration in ASP.NET Core</h2>
    <p>There are three main lifetimes when registering services in ASP.NET Core:</p>
    <ol>
      <li><strong>Transient:</strong> Created every time they are requested. Ideal for lightweight, stateless services.</li><br />
      <li><strong>Scoped:</strong> Created once per request. Best suited for services that require per-request context, such as database contexts.</li><br />
      <li><strong>Singleton:</strong> Created once and reused throughout the application's lifetime.</li>
    </ol><br />

    <pre>
      <code>
{`public void ConfigureServices(IServiceCollection services)
{
    // Transient Service
    services.AddTransient<IMyTransientService, MyTransientService>();

    // Scoped Service
    services.AddScoped<IMyScopedService, MyScopedService>();

    // Singleton Service
    services.AddSingleton<IMySingletonService, MySingletonService>();
}`}
      </code>
    </pre><br />

    <h2>Constructor Injection</h2>
    <p>
      ASP.NET Core primarily uses <strong>constructor injection</strong>, where dependencies are passed 
      via the constructor. For example:
    </p>

    <pre>
      <code>
{`public class MyController
{
    private readonly IMyService _service;

    public MyController(IMyService service)
    {
        _service = service;
    }

    public IActionResult Index()
    {
        _service.DoWork();
        return View();
    }
}`}
      </code>
    </pre><br />

    <h2>Method Injection and Property Injection</h2>
    <p>
      In addition to constructor injection, ASP.NET Core also supports method and property injection, 
      though constructor injection is preferred for its simplicity and effectiveness. Here's an example 
      of method injection:
    </p>

    <pre>
      <code>
{`public class MyClass
{
    private IMyService _service;

    public void Initialize(IMyService service)
    {
        _service = service;
    }
}`}
      </code>
    </pre><br />

    <h2>Service Lifetimes and Dependency Injection</h2>
    <p>
      Choosing the right lifetime for a service is important in ASP.NET Core, as it affects resource 
      management and application performance.
    </p>
    <ul>
      <li><strong>Transient:</strong> Best for lightweight, short-lived operations.</li><br />
      <li><strong>Scoped:</strong> Ideal for services that should maintain state across the lifetime of a request, like database context instances.</li><br />
      <li><strong>Singleton:</strong> Useful for services that should persist for the entire application duration, such as configuration providers or logging services.</li>
    </ul><br />

    <h2>Example: Injecting a Database Context</h2>
    <p>
      A common use case for DI in ASP.NET Core is injecting a database context using Entity Framework:
    </p>

    <pre>
      <code>
{`public void ConfigureServices(IServiceCollection services)
{
    services.AddDbContext<MyDbContext>(options =>
        options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));
}

public class MyRepository
{
    private readonly MyDbContext _context;

    public MyRepository(MyDbContext context)
    {
        _context = context;
    }

    // Use the context to perform database operations
}`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Dependency Injection is a key feature in ASP.NET Core that enhances modularity, testability, 
      and maintainability by decoupling the dependencies from the classes that use them. Understanding 
      and using DI properly can lead to better-structured applications that are easier to manage and extend.
    </p>
  </div>
)}


{selectedChapter === 'chapter12' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Startup.cs and Program.cs File Explanation</h1>



    <p>
      ASP.NET Core applications follow a structured startup process that defines how the application initializes and runs.
      Two key files play a significant role in this startup process: <strong>Program.cs</strong> and <strong>Startup.cs</strong>.
      These files configure the web host and the middleware pipeline, making them essential components of the ASP.NET Core architecture.
    </p>
<br />

    <h3>1. Program.cs File</h3>
    <p>
      The <code>Program.cs</code> file is the entry point for the ASP.NET Core application. It contains the <code>Main</code> method, which is the first method that gets called when the application starts. This method is responsible for configuring and launching the <strong>web host</strong>.
    </p>
    <ul>
      <li><strong>Creating the Web Host:</strong> The <code>Program.cs</code> file defines the web server that will host the application (e.g., Kestrel or IIS).</li><br />
      <li><strong>Loading the Startup Class:</strong> It specifies the <code>Startup</code> class, which contains the middleware and service configurations.</li><br />
      <li><strong>Configuring the Application:</strong> It configures fundamental services like logging, configuration, and environment variables.</li>
    </ul>

    <pre>
      <code>
{`public class Program
{
    public static void Main(string[] args)
    {
        CreateHostBuilder(args).Build().Run();
    }

    public static IHostBuilder CreateHostBuilder(string[] args) =>
        Host.CreateDefaultBuilder(args)
            .ConfigureWebHostDefaults(webBuilder =>
            {
                webBuilder.UseStartup<Startup>();
            });
}`}
      </code>
    </pre>

    <h4 style={{paddingBottom:"6px"}}>Explanation:</h4>
    <ul>
      <li><strong>Main Method:</strong> It calls the <code>CreateHostBuilder</code> method, which sets up the host that the application will run on.</li><br />
      <li><strong>CreateHostBuilder Method:</strong> This method creates and configures the web host. It also specifies the <strong>Startup</strong> class to use.</li>
    </ul><br />

    <h5>Host.CreateDefaultBuilder</h5>
    <p>
      <code>CreateDefaultBuilder</code> is a method that configures default services like:
    </p>
    <ul>
      <li><strong>Configuration Sources:</strong> JSON files, environment variables, and command-line arguments.</li><br />
      <li><strong>Logging:</strong> Configures logging with various providers.</li><br />
      <li><strong>Dependency Injection:</strong> Registers core services needed by the ASP.NET Core runtime.</li>
    </ul>

  <br />

    <h3>2. Startup.cs File</h3>
    <p>
      The <code>Startup.cs</code> file is where the application’s services and request handling pipeline are configured. It is automatically called by the <code>Program.cs</code> file when the application starts.
    </p>
    <ul>
      <li><strong>Configure Services:</strong> This method is used to register application services such as Entity Framework, Identity, and custom services.</li><br />
      <li><strong>Configure Middleware:</strong> This method defines how HTTP requests are handled by configuring middleware components in the request pipeline.</li>
    </ul>

    <pre>
      <code>
{`public class Startup
{
    public Startup(IConfiguration configuration)
    {
        Configuration = configuration;
    }

    public IConfiguration Configuration { get; }

    public void ConfigureServices(IServiceCollection services)
    {
        services.AddControllersWithViews();
    }

    public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
    {
        if (env.IsDevelopment())
        {
            app.UseDeveloperExceptionPage();
        }
        else
        {
            app.UseExceptionHandler("/Home/Error");
            app.UseHsts();
        }

        app.UseHttpsRedirection();
        app.UseStaticFiles();

        app.UseRouting();

        app.UseAuthentication();
        app.UseAuthorization();

        app.UseEndpoints(endpoints =>
        {
            endpoints.MapControllerRoute(
                name: "default",
                pattern: "{controller=Home}/{action=Index}/{id?}");
        });
    }
}`}
      </code>
    </pre>

    <h4 style={{paddingBottom:"6px"}}>Explanation:</h4>
    <h5>1. Constructor</h5>
    <p>
      The constructor takes an <code>IConfiguration</code> object as a parameter, which is used to access the application configuration (e.g., appsettings.json).
    </p><br />

    <h5>2. ConfigureServices Method</h5>
    <p>
      <strong>ConfigureServices</strong> is where you register services for Dependency Injection, such as database contexts, Identity services, and custom services.
    </p>

    <pre>
      <code>
{`services.AddDbContext<MyDbContext>(options =>
    options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));
services.AddAuthentication();`}
      </code>
    </pre><br />

    <h5>3. Configure Method</h5>
    <p>
      <strong>Configure</strong> sets up the middleware pipeline, which defines how the application responds to HTTP requests. Key middleware components are added in a specific order.
    </p><br />

    <h5 style={{paddingBottom:"6px"}}>Common Middleware</h5>
    <ul>
      <li><strong>app.UseHttpsRedirection():</strong> Redirects HTTP requests to HTTPS.</li><br />
      <li><strong>app.UseStaticFiles():</strong> Serves static files such as images, CSS, and JavaScript.</li><br />
      <li><strong>app.UseRouting():</strong> Enables routing to controllers or Razor Pages.</li><br />
      <li><strong>app.UseAuthentication():</strong> Adds the authentication middleware.</li><br />
      <li><strong>app.UseAuthorization():</strong> Adds the authorization middleware.</li><br />
      <li><strong>app.UseEndpoints():</strong> Maps routes to controllers.</li>
    </ul>

   <br />

    <h3>Summary</h3>
    <p>
      <strong>Program.cs:</strong> Responsible for building and starting the host. It sets up the web server and loads the <code>Startup</code> class.
    </p>
    <p>
      <strong>Startup.cs:</strong> Configures application services and the middleware pipeline. It defines how the app handles HTTP requests and which services are injected into the application.
    </p>
  </div>
)}


{selectedChapter === 'chapter13' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to MVC Architecture</h1>

    <p>
      The <strong>MVC architecture</strong> is a popular design pattern that separates an application into three main components: <strong>Model</strong>, <strong>View</strong>, and <strong>Controller</strong>. ASP.NET Core MVC follows this pattern to provide a clean separation of concerns, making the application more maintainable and scalable.
    </p>

   <br />

    <h2>1. What is MVC?</h2>
    <p>
      MVC stands for <strong>Model-View-Controller</strong>, and it is a way to structure your application by separating it into three core components:
    </p>
    <ul>
      <li><strong>Model</strong>: Represents the data and business logic of the application. It’s responsible for retrieving data from a database, processing it, and sending it to the View or Controller.</li><br />
      <li><strong>View</strong>: Represents the user interface (UI) of the application. It displays the data received from the Controller and allows users to interact with it.</li><br />
      <li><strong>Controller</strong>: Handles user input, processes it, and updates the Model and View accordingly.</li>
    </ul>

   <br />

    <h2>2. The Flow of Data in MVC</h2>
    <p>
      In an ASP.NET Core MVC application, the flow of data works as follows:
    </p>
    <ol>
      <li>A user sends a request to a <strong>Controller</strong>.</li><br />
      <li>The <strong>Controller</strong> processes the request and interacts with the <strong>Model</strong> if necessary (e.g., querying a database).</li><br />
      <li>The <strong>Model</strong> returns data to the <strong>Controller</strong>.</li><br />
      <li>The <strong>Controller</strong> then passes the data to a <strong>View</strong> for display.</li><br />
      <li>The <strong>View</strong> presents the data to the user.</li>
    </ol>

    <br />

    <h2 style={{paddingBottom:"6px"}}>3. MVC Components in ASP.NET Core</h2>

    <h3>Model</h3>
    <p>
      The <strong>Model</strong> component is used for data-related logic in the application. It can represent data from a database or any other resource the application uses. In ASP.NET Core, this is typically represented by classes that interact with a database using Entity Framework Core.
    </p>
    <pre>
      <code className="language-csharp">{`
        public class Product
        {
            public int Id { get; set; }
            public string Name { get; set; }
            public decimal Price { get; set; }
        }
`}</code>
    </pre><br />

    <h3>View</h3>
    <p>
      The <strong>View</strong> is responsible for displaying the UI to the user. It is usually built using <strong>Razor</strong> syntax, which combines HTML with C# code.
    </p>
    <pre>
      <code className="language-html">{`
        @model Product

        &lt;h2&gt;@Model.Name&lt;/h2&gt;
        &lt;p&gt;Price: @Model.Price&lt;/p&gt;
`}</code>
    </pre><br />

    <h3>Controller</h3>
    <p>
      The <strong>Controller</strong> acts as the middleman between the Model and the View. It receives user input, processes it (often by interacting with the Model), and returns a View to the user.
    </p>
    <pre>
      <code className="language-csharp">{`
        public class ProductController : Controller
        {
            private readonly MyDbContext _context;

            public ProductController(MyDbContext context)
            {
                _context = context;
            }

            public IActionResult Index()
            {
                var products = _context.Products.ToList();
                return View(products);
            }
        }
`}</code>
    </pre>

<br />

    <h2>4. Routing in MVC</h2>
    <p>
      In ASP.NET Core MVC, routing maps URLs to Controllers and Actions. The <strong>Route</strong> is defined by the combination of a URL pattern and a Controller method (Action).
    </p>
    <pre>
      <code className="language-csharp">{`
        app.UseEndpoints(endpoints =>
        {
            endpoints.MapControllerRoute(
                name: "default",
                pattern: "{controller=Home}/{action=Index}/{id?}");
        });
`}</code>
    </pre>
    <p>
      This means:
      <ul>
        <li>The default controller is <code>Home</code>.</li><br />
        <li>The default action is <code>Index</code>.</li><br />
        <li>The <code>id</code> parameter is optional.</li>
      </ul>
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>5. Summary</h2>
    <ul>
      <li><strong>Model-View-Controller (MVC)</strong> is a design pattern that helps separate the application's concerns for better maintainability and testability.</li><br />
      <li><strong>Model</strong> handles data logic and business rules.</li><br />
      <li><strong>View</strong> is responsible for rendering the UI.</li><br />
      <li><strong>Controller</strong> handles user input and updates the Model and View.</li>
    </ul>

   <br />

    <h2>Conclusion</h2>
    <p>
      The <strong>MVC architecture</strong> is essential for building scalable and maintainable applications in ASP.NET Core MVC. By following this pattern, developers can ensure that their applications are well-structured and easy to manage as they grow.
    </p>
  </div>
)}


{selectedChapter === 'chapter14' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Controllers, Actions, and Action Results</h1>

    <h3>1.  Controllers</h3>
    <p>
      In <strong>ASP.NET Core MVC</strong>, a <strong>Controller</strong> is responsible for handling incoming HTTP requests and returning an appropriate <strong>ActionResult</strong> to the client. Controllers are key components of the MVC design pattern, sitting between the <strong>View</strong> and <strong>Model</strong>. They receive user input, interact with the Model if necessary, and select a View to display.
    </p>
    <p style={{paddingBottom:"6px"}}>
      A <strong>Controller</strong> is typically a class that derives from <code>Controller</code>, a base class provided by ASP.NET Core. It contains methods known as <strong>Actions</strong> that correspond to HTTP requests.
    </p>

    <h4>Example of a simple Controller:</h4>
    <pre><code>{`
      public class HomeController : Controller {'\n'}
      {'  '}public IActionResult Index() {'\n'}
      {'    '}return View(); {'\n'}
      {'  }'}
    `}</code></pre>

    <p>
      In this example: <br />
      - <code>HomeController</code> is a controller class. <br />
      - The method <code>Index()</code> is an <strong>Action</strong> method that processes requests and returns an <strong>ActionResult</strong>.
    </p>

    <br />

    <h3>2. Actions </h3>
    <p style={{paddingBottom:"6px"}}>
      <strong>Actions</strong> are methods within a controller responsible for handling incoming requests. Each action corresponds to a specific HTTP request (GET, POST, etc.). Action methods are triggered by a route matching a URL pattern defined by routing configuration.
    </p>

    <h4>Types of Actions:</h4>

    <ul>
      <li><strong>GET Actions</strong>: These actions are used to handle requests that retrieve data (usually for displaying information).
        <pre><code>{`
          public IActionResult About() {'\n'}
          {'  '}return View(); {'\n'}
          `}  </code></pre>
      </li>
      <li><strong>POST Actions</strong>: These actions handle requests that send data to the server (usually when submitting a form).
        <pre><code>{`
          [HttpPost] {'\n'}
          public IActionResult Contact(ContactForm form) {'\n'}
          {'  '}if (ModelState.IsValid) {'\n'}
          {'    '}return RedirectToAction("ThankYou"); {'\n'}
          {'  '}return View(form); {'\n'}
        `}</code></pre>
      </li>
      <li><strong>Other HTTP Methods</strong>: You can use attributes like <code>[HttpPut]</code>, <code>[HttpDelete]</code> for handling other HTTP methods.
        <pre><code>{`
          [HttpPut] {'\n'}
          public IActionResult UpdateItem(int id, Item item) {'\n'}
          {'  '}if (ModelState.IsValid) {'\n'}
          {'    '}return Ok(); {'\n'}
          {'  '}return BadRequest(); {'\n'}
         `} </code></pre>
      </li>
    </ul>

    <br />

    <h3>3. Action Results</h3>
    <p style={{paddingBottom:"6px"}}>
      Every <strong>Action</strong> method must return an <strong>ActionResult</strong>, which indicates the outcome of the action. The <strong>ActionResult</strong> can represent various outcomes such as rendering a View, returning a file, redirecting to another action, or returning data in JSON format.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Common ActionResult Types:</h4>
    <ul>
      <li><strong>ViewResult</strong>: Renders a view (HTML page) to the user.
        <pre><code>{`
          public IActionResult Index() {'\n'}
          {'  '}return View(); {'\n'}
        `}</code></pre>
      </li><br />
      <li><strong>RedirectResult</strong>: Redirects to a different URL or action.
        <pre><code>{`
          public IActionResult RedirectToAbout() {'\n'}
          {'  '}return RedirectToAction("About"); {'\n'}
        `}</code></pre>
      </li><br />
      <li><strong>JsonResult</strong>: Returns JSON data.
        <pre><code>{`
          public IActionResult GetProducts() {'\n'}
          {'  '}return Json(products); {'\n'}
        `}</code></pre>
      </li><br />
      <li><strong>ContentResult</strong>: Returns plain text or other content.
        <pre><code>{`
          public IActionResult GetMessage() {'\n'}
          {'  '}return Content("Hello, World!"); {'\n'}
        `}</code></pre>
      </li><br />
      <li><strong>FileResult</strong>: Returns a file to the client.
        <pre><code>{`
          public IActionResult DownloadFile() {'\n'}
          {'  '}return File(fileBytes, "application/pdf", "example.pdf"); {'\n'}
        `}</code></pre>
      </li><br />
      <li><strong>StatusCodeResult</strong>: Returns a specific HTTP status code.
        <pre><code>{`
          public IActionResult Error() {'\n'}
          {'  '}return StatusCode(404); {'\n'}
         `} </code></pre>
      </li><br />
      <li><strong>EmptyResult</strong>: Returns an empty response (no content).
        <pre><code>{`
          public IActionResult NoContent() {'\n'}
          {'  '}return new EmptyResult(); {'\n'}
        `}</code></pre>
      </li>
    </ul>

   <br />

    <h3>4. Returning ViewData and ViewBag</h3>
    <p><strong>ViewData</strong>: A dictionary object that allows passing data from the controller to the view.</p>
    <pre><code>{`
      public IActionResult Index() {'\n'}
      {'  '}ViewData["Message"] = "Hello from Controller!"; {'\n'}
      {'  '}return View(); {'\n'}
     `}</code></pre><br />

    <p><strong>ViewBag</strong>: A dynamic object that allows passing data to views, much like ViewData but with a more flexible syntax.</p>
    <pre><code>{`
      public IActionResult Index() {'\n'}
      {'  '}ViewBag.Message = "Hello from ViewBag!"; {'\n'}
      {'  '}return View(); {'\n'}
    `}</code></pre>

    <br />

    <h3>5. Routing to Action Methods</h3>
    <p>
      Routing in <strong>ASP.NET Core MVC</strong> determines which controller and action method should handle a given HTTP request. The default routing pattern is <code>{`{controller=Home}/{action=Index}/{id?}`}</code>, where:
    </p>
    <ul>
      <li><code>{`{controller}`}</code> is the name of the controller.</li><br />
      <li><code>{`{action}`}</code> is the action method within the controller.</li><br />
      <li><code>{`{id?}`}</code> is an optional parameter.</li>
    </ul><br />

    <p>You can also specify routes using the <code>[Route]</code> attribute to create custom routes:</p>
    <pre><code>{`
      [Route("products/{id}")]
      public IActionResult ProductDetails(int id) {'\n'}
      {'  '}return View(product); {'\n'}
    `}</code></pre>

   <br />

    <h3 style={{paddingBottom:"6px"}}>6. Summary</h3>
    <ul>
      <li><strong>Controllers</strong> are classes that handle incoming HTTP requests in ASP.NET Core MVC.</li><br />
      <li><strong>Action Methods</strong> are methods within controllers that process requests and return <strong>Action Results</strong>.</li><br />
      <li><strong>Action Results</strong> define the outcome of an action, such as rendering a View, redirecting to another action, or returning JSON data.</li><br />
      <li>You can pass data between controllers and views using <strong>ViewData</strong>, <strong>ViewBag</strong>, or <strong>strongly typed models</strong>.</li><br />
      <li>Routing helps in mapping URLs to specific controllers and actions.</li>
    </ul> <br />

  
    <h3>Conclusion</h3>
    <p>
      Controllers, actions, and action results are core components of the <strong>ASP.NET Core MVC</strong> framework. Understanding how to design controllers and handle different types of requests through actions and result types is essential for building dynamic web applications. With the flexibility of routing and various action results, you can easily customize your application's behavior to suit different needs.
    </p>
  </div>
)}


{selectedChapter === 'chapter15' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Routing in ASP.NET Core MVC</h1>
    
    <p>
      Routing in ASP.NET Core MVC determines how incoming HTTP requests are matched to controller actions. It is a critical component that controls how URLs are mapped to specific controller methods. This system allows you to define how URLs are structured and how they are handled by the application.
    </p><br />
    
    <p style={{paddingBottom:"6px"}}>
      In the context of <strong>Controllers, Actions, and Action Results</strong>, routing is what directs HTTP requests to the correct controller and action. Here's an overview of how routing works in ASP.NET Core MVC:
    </p>

    <h4>1. Default Routing</h4>
    <p>
      ASP.NET Core MVC uses a <strong>default route template</strong> that is set up in the <code>Startup.cs</code> or <code>Program.cs</code> file. The default pattern is:
    </p>
    <pre><code>{`
      {controller=Home}/{action=Index}/{id?}
    `}</code></pre>
    <ul>
      <li><strong>controller=Home</strong>: This specifies that if no controller is provided in the URL, the <code>Home</code> controller is used by default.</li><br />
      <li><strong>action=Index</strong>: This indicates that the <code>Index</code> action method will be used if no action is specified in the URL.</li><br />
      <li><strong>id?</strong>: The <code>id</code> parameter is optional, meaning that it doesn't have to be included in the URL.</li>
    </ul>
    <p>
      For example, a URL like <code>/Home/Index</code> would route to the <code>Index()</code> method of the <code>HomeController</code>. If the URL is <code>/</code>, it would also route to the <code>Index()</code> method of the <code>HomeController</code> by default.
    </p>

   <br />

    <h4>2. Custom Routes with [Route] Attribute</h4>
    <p style={{paddingBottom:"6px"}}>
      You can customize routes for specific actions or controllers using the <code>[Route]</code> attribute. This provides more control over how URLs are structured.
    </p>
    <h5>Example of Custom Routes:</h5>
    <pre><code>{`
      public class ProductsController : Controller
      {{
          [Route("products/{id}")]
          public IActionResult Details(int id)
          {{
              var product = GetProductById(id); // Assuming this method fetches product details.
              return View(product);
          }}
          
          [Route("products/list")]
          public IActionResult List()
          {{
              var products = GetAllProducts(); // Fetch all products.
              return View(products);
          }}
      }}
    `}</code></pre>
    <ul>
      <li>The URL <code>/products/1</code> will route to the <code>Details()</code> action in <code>ProductsController</code> with the <code>id</code> value of 1.</li>
      <li>The URL <code>/products/list</code> will route to the <code>List()</code> action.</li>
    </ul>

 <br />

    <h4>3. Route Parameters</h4>
    <p>
      You can define route parameters that are passed to the action methods. Parameters in the URL are captured and passed to the controller’s action method.
    </p>
    <pre><code>{`
      public class HomeController : Controller
      {{
          [Route("home/{name}")]
          public IActionResult Greet(string name)
          {{
              ViewData["Message"] = $"Hello, {name}!";
              return View();
          }}
      }}
      `}</code></pre>
    <p>
      A request to <code>/home/John</code> will pass <code>"John"</code> as the <code>name</code> parameter to the <code>Greet()</code> action.
    </p>

    <br />

    <h4>4. Optional Parameters</h4>
    <p>
      Route parameters can be marked as optional by using a <code>?</code> at the end of the parameter. For example:
    </p>
    <pre><code>{`
      [Route("home/{id?}")]
      public IActionResult Index(int? id)
      {{
          if (id.HasValue)
          {{
              ViewData["Message"] = $"Welcome back! Your ID is {id}.";
          }}
          else
          {{
              ViewData["Message"] = "Welcome to our site!";
          }}
          return View();
      }}
    `}</code></pre>
    <ul>
      <li><code>/home</code> will route to the <code>Index()</code> method with <code>id</code> being <code>null</code>.</li>
      <li><code>/home/123</code> will route to the <code>Index()</code> method with <code>id</code> being <code>123</code>.</li>
    </ul>

    <br />

    <h4>5. Routing with Action Names</h4>
    <p>
      By default, ASP.NET Core MVC uses action names directly from the URL. You can define specific routes to map a controller's actions to URLs, making your app more RESTful and readable.
    </p>
    <pre><code>{`
      public class BlogController : Controller
      {{
          [Route("blog/{slug}")]
          public IActionResult Post(string slug)
          {{
              var post = GetPostBySlug(slug); // Get a blog post based on the slug.
              return View(post);
          }}
      }}
    `}</code></pre>
    <p>
      A request to <code>/blog/asp-net-core-routing</code> will map to the <code>Post()</code> action in <code>BlogController</code>, passing the <code>slug</code> parameter as <code>"asp-net-core-routing"</code>.
    </p>

    <br />
    <h4>6. Route Constraints</h4>
    <p>
      Route constraints allow you to limit the types of values that route parameters can have. For example, you can enforce a constraint to ensure that a parameter is an integer.
    </p>
    <pre><code>{`
      [Route("products/{id:int}")]
      public IActionResult Product(int id)
      {{
          var product = GetProductById(id); 
          return View(product);
      }}
    `}</code></pre>
    <p>
      In this case, only an integer value will be accepted for the <code>id</code> parameter, and a URL like <code>/products/abc</code> would result in a 404 error.
    </p>

   <br />

    <h4>7. Route Prefixes for Controllers</h4>
    <p>
      You can apply a route prefix to an entire controller, which automatically applies to all actions within that controller. This is useful for grouping routes logically.
    </p>
    <pre><code>{`
      [Route("api/[controller]")]
      public class ProductsController : Controller
      {{
          [HttpGet("{id}")]
          public IActionResult GetProduct(int id)
          {{
              var product = GetProductById(id);
              return Ok(product);
          }}

          [HttpPost]
          public IActionResult CreateProduct(Product product)
          {{
              // Create product logic here.
              return CreatedAtAction(nameof(GetProduct), new {{ id = product.Id }}, product);
          }}
      }}
      `}</code></pre>
    <ul>
      <li>Use <code>GET /api/products/1</code> for the <code>GetProduct()</code> action.</li><br />
      <li>Use <code>POST /api/products</code> for the <code>CreateProduct()</code> action.</li>
    </ul>

    <br />

    <h4>8. Route Order and Ambiguity</h4>
    <p>
      The order of route definitions matters. If two routes can potentially match the same URL, the first defined route takes precedence. To avoid ambiguity, make sure your route definitions are specific.
    </p>

    <br />

    <h3 style={{paddingBottom:"6px"}}>Summary</h3>
    <ul>
      <li><strong>Controllers</strong> are responsible for processing incoming requests and returning an <code>ActionResult</code>.</li><br />
      <li><strong>Actions</strong> within controllers are mapped to specific URLs via routing.</li><br />
      <li><strong>Action Results</strong> return responses such as Views, JSON data, or redirects.</li><br />
      <li><strong>Routing</strong> directs requests to the appropriate controller and action based on URL patterns.</li><br />
      <li>You can <strong>customize routes</strong>, use <strong>optional parameters</strong>, apply <strong>route constraints</strong>, and define <strong>route prefixes</strong> for controllers.</li>
    </ul><br />
    <p>
      This flexibility in routing allows ASP.NET Core MVC to handle a variety of URL patterns and routing strategies, making it a powerful framework for web development.
    </p>
  </div>
)}



{selectedChapter === 'chapter16' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Model Binding and Validation</h1>
    <ul>
      <li>
       <h2> What is Model Binding?</h2>
        <p>
          Model Binding in ASP.NET Core MVC is the process of mapping data from an HTTP request to action method parameters. This data can come from query strings, form data, route data, and more. ASP.NET Core MVC automatically maps incoming data to the action method parameters, allowing the developer to focus on business logic.
        </p>
      </li><br />

      <li>
        <h2>What is Validation?</h2>
        <p>
          Validation ensures that the incoming data is correct, meets business rules, and is safe for processing. ASP.NET Core MVC supports both client-side and server-side validation.
        </p>
      </li>
    </ul>

  <br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts to Cover</h3>

    <h4>1. Understanding Model Binding</h4>

    <p>
      <strong>Basic Model Binding:</strong> In a typical scenario, data from form fields (like <code>input</code> fields or drop-down lists) is sent to the server and bound to a model object in the controller.
    </p>

   
    <pre>
  <code>{`
    public class Product
    {
        public string Name { get; set; }
        public decimal Price { get; set; }
    }

    public class HomeController : Controller
    {
        [HttpPost]
        public IActionResult CreateProduct(Product product)
        {
            if (ModelState.IsValid)
            {
                // Save the product to the database
                return RedirectToAction("Index");
            }
            return View(product);
        }
    }
`}</code>
</pre>

  

    <p>
      <strong>Model Binding in Action:</strong> The <code>Product</code> model will be populated with data from the form (sent in a POST request), such as the product's name and price.
    </p><br />

    <h5>Binding Data from Query Strings and Route Data:</h5>
    <p>
      In ASP.NET Core, model binding also works with query strings and route data. You can bind data directly to action parameters.
    </p>

   
    <pre>
  <code>{`
    // Example using Route Data
    [HttpGet]
    public IActionResult Edit(int id)
    {
        var product = _productService.GetProductById(id);
        return View(product);
    }

    // Example using Query String
    public IActionResult Search(string query)
    {
        var results = _searchService.Find(query);
        return View(results);
    }
  `}</code>
</pre>

   <br />

    <h4>2. Validation in ASP.NET Core MVC</h4>
    <p>
      <strong>Server-Side Validation with Data Annotations:</strong> ASP.NET Core provides a set of attributes (data annotations) to apply validation rules to models. These rules are checked on the server side when a form is submitted.
    </p>

    <pre>
    <pre>
  <code>{`
    public class Product
    {
        [Required(ErrorMessage = "Product name is required.")]
        public string Name { get; set; }

        [Range(1, 10000, ErrorMessage = "Price must be between 1 and 10,000.")]
        public decimal Price { get; set; }
    }
`}</code>
</pre>

    </pre><br />

    <h5 style={{paddingBottom:"6px"}}>Common Data Annotations:</h5>
    <ul>
      <li><code>[Required]</code>: Ensures the field is not null or empty.</li><br />
      <li><code>[StringLength(max, min)]</code>: Validates the length of the string.</li><br />
      <li><code>[Range(min, max)]</code>: Ensures the value is within a specified range.</li><br />
      <li><code>[EmailAddress]</code>: Validates that the value is a valid email address.</li>
    </ul><br />

    <h5>Custom Validation:</h5>
    <p>
      You can create custom validation attributes to enforce complex validation rules.
    </p>

    <pre>
    
  <code>{`
    public class ValidProductNameAttribute : ValidationAttribute
    {
        public override bool IsValid(object value)
        {
            return value != null && value.ToString().StartsWith("Product");
        }
    }
  `}</code>
</pre><br />

    

    <h5>ModelState Validation:</h5>
    <p>
      Before processing a form submission, you can check <code>ModelState.IsValid</code> to ensure that the data passed to the action method is valid.
    </p>

    <pre>
  <code>{`
    public IActionResult CreateProduct(Product product)
    {
        if (ModelState.IsValid)
        {
            // Proceed with product creation
            return RedirectToAction("Index");
        }
        return View(product);
    }
`}</code>
</pre><br />


    <h4>3. Client-Side Validation</h4>
    <p>
      <strong>Client-Side Validation with jQuery Validation:</strong> ASP.NET Core MVC supports client-side validation using jQuery Validation. This can be enabled by including the necessary JavaScript files in your view.
    </p>

    <pre>
      <code>{`
        @model Product

        <form asp-action="CreateProduct" method="post">
            <div class="form-group">
                <label asp-for="Name"></label>
                <input asp-for="Name" class="form-control" />
                <span asp-validation-for="Name" class="text-danger"></span>
            </div>

            <div class="form-group">
                <label asp-for="Price"></label>
                <input asp-for="Price" class="form-control" />
                <span asp-validation-for="Price" class="text-danger"></span>
            </div>

            <button type="submit" class="btn btn-primary">Create</button>
        </form>

        <script src="https://ajax.aspnetcdn.com/ajax/jQuery/jquery-3.6.0.min.js"></script>
        <script src="https://ajax.aspnetcdn.com/ajax/jquery.validate/1.19.2/jquery.validate.min.js"></script>
        <script src="https://ajax.aspnetcdn.com/ajax/jquery.validate.unobtrusive/3.2.7/jquery.validate.unobtrusive.min.js"></script>
       `} </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>4. Handling Validation Errors</h4>
    <h5>Displaying Validation Errors:</h5>
    <p>
      In your Razor views, you can display validation error messages by using <code>asp-validation-for</code> to show specific error messages next to form fields.
    </p><br />

    <h5>Displaying Summary of Errors:</h5>
    <p>
      You can also display a summary of validation errors using <code>ValidationSummary</code>.
    </p>

    <pre>
      <code>{`
        <form asp-action="CreateProduct" method="post">
            @Html.ValidationSummary(true, "", new { @class = "text-danger" })
            <!-- Form fields here -->
        </form>
      `}</code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>5. Best Practices in Model Binding and Validation</h4>
    <ul>
      <li>Use Both Server-Side and Client-Side Validation: Always apply both server-side validation (for security and integrity) and client-side validation (for a better user experience).</li><br />
      <li>Keep Validation Logic Simple and Clear: Use built-in data annotations for simple rules and custom validation attributes for more complex scenarios.</li><br />
      <li>Handle Validation Errors Gracefully: Always ensure that validation errors are communicated to the user with clear, user-friendly messages.</li>
    </ul>

  <br />

    <h3>Conclusion</h3>
    <p>
      <strong>Model Binding and Validation</strong> are foundational concepts in ASP.NET Core MVC that allow developers to efficiently handle and validate user input. By leveraging model binding, validation attributes, and proper error handling, developers can create robust and secure web applications that provide a smooth user experience.
    </p>
  </div>
)}


{selectedChapter === 'chapter17' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>View Engines (Razor View Engine)</h1>

   

    <p>View engines in ASP.NET Core MVC are responsible for rendering views by combining data from models with HTML templates. In MVC, views are used to display data to the user. The <strong>Razor View Engine</strong> is the default engine in ASP.NET Core MVC, and it provides a powerful, flexible syntax for creating dynamic web pages.</p><br />

    <h4>What is the Razor View Engine?</h4>

    <p style={{paddingBottom:"6px"}}>The <strong>Razor View Engine</strong> is a markup syntax used to define views in ASP.NET Core MVC. It allows you to mix HTML and C# code seamlessly, making it easy to build dynamic web pages. Razor views typically have the <code>.cshtml</code> file extension and are used by the MVC framework to generate HTML content.</p>

    <h5 style={{paddingBottom:"6px"}}>How Razor View Engine Works:</h5>
    <ol>
      <li><strong>HTML and C# Code</strong>: Razor views allow mixing HTML with C# code using the Razor syntax. It is designed to be simple and clean.</li><br />
      <li><strong>Controller to View</strong>: The controller passes data (via <code>ViewBag</code>, <code>ViewData</code>, or <code>model</code>) to the Razor view. The view then renders the data within the HTML markup.</li><br />
      <li><strong>Rendering</strong>: When the user makes a request, the Razor view engine compiles the <code>.cshtml</code> file and renders it as HTML.</li>
    </ol><br />

    <h5 style={{paddingBottom:"6px"}}>Basic Razor Syntax:</h5>
    <ul>
      <li><strong>Embedding C# Code</strong>: Use <code>@</code> to insert C# code in a Razor view.
        <pre><code><h1>@Model.Title</h1></code></pre>
      </li><br />
      <li><strong>Code Blocks</strong>: You can write complex logic using Razor code blocks.
        <pre><code>{`@{ var greeting = "Hello, World!"; }`}</code></pre>
        <pre><code>&lt;p&gt;@greeting&lt;/p&gt;</code></pre>
      </li><br />
      <li><strong>Loops and Conditionals</strong>: Razor supports standard C# syntax for loops and conditionals.
        <pre><code><ul>{`
  @foreach (var product in Model.Products)
  {
    <li>;@product.Name</li>
  }
         `} </ul> </code></pre>
        <pre><code>{`@if (Model.IsAvailable) { <span>In Stock</span> } else { <span>Out of Stock</span> }`}</code></pre>
      </li>
    </ul>

    <br />

    <h4>How to Use Razor Views in ASP.NET Core MVC:</h4>
    <ol>
      <li><strong>Creating Razor Views</strong>: Razor views are typically created in the <code>Views</code> folder of your ASP.NET Core MVC application. Each controller typically has a corresponding folder within the <code>Views</code> directory.
        <p>Example:</p>
        <ul>
          <li>Controller: <code>HomeController.cs</code></li><br />
          <li>View: <code>Views/Home/Index.cshtml</code></li>
        </ul>
      </li><br />
      <li><strong>Passing Data to Razor Views</strong>: You can pass data from the controller to the Razor view in a few ways:
        <ul>
          <li><strong>Model</strong>: Pass a strongly-typed model from the controller to the view.</li><br />
          <li><strong>ViewBag</strong>: A dynamic object used to pass data to views.</li><br />
          <li><strong>ViewData</strong>: A dictionary object used to pass data to views.</li>
        </ul><br />
        <pre><code>{`public IActionResult Index()
{
    var products = _context.Products.ToList();
    return View(products);
}`}</code></pre>
        <p>In the view (<code>Index.cshtml</code>):</p>
        <pre><code><ul>{`
    @foreach (var product in Model)
    {
        <li>@product.Name</li>
    }
  `} </ul> </code></pre>
      </li>
    </ol>

<br />
    <h4>Razor Pages (Optional)</h4>
    <p>While <strong>Razor Views</strong> are a part of the MVC pattern, ASP.NET Core also supports <strong>Razor Pages</strong>, a simpler, page-based approach for handling views. Razor Pages work similarly but are often easier to use for simple applications or individual pages.</p>

    <pre><code>{`public class IndexModel : PageModel
{
    public void OnGet()
    {
        // Logic for handling GET request
    }
}`}</code></pre>

  <br />

    <h4 style={{paddingBottom:"6px"}}>Benefits of Using Razor View Engine:</h4>
    <ul>
      <li><strong>Clean Syntax</strong>: Razor provides a clean, concise syntax that makes it easier to integrate C# with HTML.</li><br />
      <li><strong>Separation of Concerns</strong>: Razor views help maintain a clear separation between the logic (in controllers) and the presentation (in views).</li><br />
      <li><strong>Intellisense and Strong Typing</strong>: Razor views offer strong typing and Intellisense in Visual Studio, improving the development experience.</li><br />
      <li><strong>Cross-platform</strong>: Razor is fully supported in ASP.NET Core and can run on Windows, Linux, and macOS.</li>
    </ul>

   <br />

    <h4 style={{paddingBottom:"6px"}}>Example: Full Razor View Example</h4>

    <h5>Controller (HomeController.cs):</h5>
    <pre><code>{`public class HomeController : Controller
{
    public IActionResult Index()
    {
        var model = new Product { Id = 1, Name = "Laptop", Price = 999.99 };
        return View(model);
    }
    }`}</code></pre>

    <h5>Model (Product.cs):</h5>
    <pre><code>{`ublic class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
}`}</code></pre>

    <h5>View (Index.cshtml):</h5>
    <pre><code>@model Product

<h1>Product Details</h1>
<ul>{`
    <li>Product ID: @Model.Id</li>
    <li>Name: @Model.Name</li>
    <li>Price: @Model.Price</li>
`}</ul></code></pre>

    <br />

    <h4 style={{paddingBottom:"6px"}}>Summary of Key Concepts:</h4>
    <ul>
      <li><strong>Razor View Engine</strong>: A powerful tool for rendering dynamic web pages with HTML and C# code.</li><br />
      <li><strong>Razor Syntax</strong>: Simple and clean syntax for combining HTML and C# code.</li><br />
      <li><strong>Data Binding</strong>: Razor allows binding data from the controller to the view using models, <code>ViewBag</code>, or <code>ViewData</code>.</li><br />
      <li><strong>Razor Pages</strong>: A simplified, page-based approach in ASP.NET Core.</li>
    </ul><br />

  </div>
)}


{selectedChapter === 'chapter18' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Working with Views and Partial Views   </h1>


    <h3>Views in ASP.NET Core MVC</h3>
    <p style={{paddingBottom:"6px"}}>Views in ASP.NET Core MVC are used to display data to users in a user-friendly format. They are typically responsible for rendering HTML and presenting data passed from the controller. Views are usually created using Razor, a markup syntax that combines HTML with C# code.</p>

    <h4>Creating Views</h4>
    <p>In an ASP.NET Core MVC application, views are generally stored in the <code>Views</code> folder. Each controller has its own folder under this directory. For example, for the <code>HomeController</code>, the views would typically be located in <code>Views/Home</code>.</p>

    <pre><code>{`public IActionResult Index()
{
    return View();
    }`}</code></pre>

    <p>The above action will search for a view called <code>Index.cshtml</code> inside the <code>Views/Home</code> folder. This view will be used to render HTML content when the action is invoked.</p><br />

    <h4>Passing Data to Views</h4>
    <p>Data can be passed from controllers to views using three main methods:</p>
    <ul>
      <li><strong>Model</strong>: A strongly-typed object that is passed from the controller to the view.</li><br />
      <li><strong>ViewData</strong>: A dictionary object used to pass data to views.</li><br />
      <li><strong>ViewBag</strong>: A dynamic object used to pass data to views.</li>
    </ul><br />

    <pre><code>{`public IActionResult Index()
{
    var products = _context.Products.ToList();
    return View(products);
    }`}</code></pre><br />

    <h4>Rendering Views</h4>
    <p>Once data is passed to the view, you can access it in the Razor view file using the <code>@Model</code> syntax.</p>

    <pre><code>&lt;ul&gt;{`
    @foreach (var product in Model)
    {
       <li>@product.Name</li>
    }
    `}&lt;/ul&gt;</code></pre>

   <br />

    <h3>Partial Views</h3>
    <p style={{paddingBottom:"6px"}}> A partial view is a reusable view that can be included in other views. It is used to encapsulate common elements that appear in multiple views, such as headers, footers, or sidebars. Partial views help avoid code duplication and improve maintainability.</p>

    <h4>Creating Partial Views</h4>
    <p>Partial views are typically stored in the same <code>Views</code> folder but are usually named with an underscore prefix, such as <code>_ProductList.cshtml</code>.</p>

    <pre><code>@model IEnumerable&lt;Product&gt;

&lt;ul&gt;{`
    @foreach (var product in Model)
    {
        <li>@product.Name</li>
    }
`}&lt;/ul&gt;</code></pre><br />

    <h4>Rendering Partial Views</h4>
    <p>To render a partial view inside a main view, use the <code>@Html.Partial</code> or <code>@Html.RenderPartial</code> helper methods.</p>

    <pre><code>@Html.Partial("_ProductList", Model)</code></pre>

    <p>Alternatively, you can use <code>{`@{ Html.RenderPartial("_ProductList", Model); }`}</code> if you need more control over rendering.</p>

  <br />

    <h4>Passing Data to Partial Views</h4>
    <p style={{paddingBottom:"6px"}}>Partial views can receive data in the same way as regular views. You can pass a model directly to the partial view using the <code>@Html.Partial</code> method, as shown in the example above.</p><br />

   

    <h3 style={{paddingBottom:"6px"}}>When to Use Views and Partial Views</h3>
    <ul>
      <li><strong>Use Views</strong>: For full page rendering where you are displaying entire pages with a lot of content.</li><br />
      <li><strong>Use Partial Views</strong>: When you have reusable sections of content, such as headers, footers, or lists of data, that should be shared across multiple pages.</li>
    </ul>

    <br />

    <h3 style={{paddingBottom:"6px"}}>Example: Using Views and Partial Views Together</h3>
    <h4>Controller (HomeController.cs):</h4>
    <pre><code>{`public IActionResult Index()
{
    var products = _context.Products.ToList();
    return View(products);
    }`}</code></pre><br />

    <h4>View (Index.cshtml):</h4>
    <pre><code>@model IEnumerable&lt;Product&gt;

<h1>Product List</h1>
@Html.Partial("_ProductList", Model)</code></pre><br />

    <h4>Partial View (_ProductList.cshtml):</h4>
    <pre><code>@model IEnumerable&lt;Product&gt;

&lt;ul&gt;{`
    @foreach (var product in Model)
    {
       <li>@product.Name</li>
    }
`}&lt;/ul&gt;</code></pre>

<br />
    <h3 style={{paddingBottom:"6px"}}>Summary of Key Concepts</h3>
    <ul>
      <li><strong>Views</strong>: Render entire pages and are typically associated with actions in controllers.</li><br />
      <li><strong>Partial Views</strong>: Reusable fragments of views, useful for sections that appear in multiple places in the application.</li><br />
      <li><strong>Data Passing</strong>: Use models, ViewData, or ViewBag to pass data from controllers to views and partial views.</li><br />
      <li><strong>Rendering</strong>: Use <code>@Html.Partial</code> or <code>@Html.RenderPartial</code> to render partial views.</li>
    </ul><br />
  </div>
)}



{selectedChapter === 'chapter19' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Tag Helpers in ASP.NET Core  </h1>

        <p>
          Tag Helpers in ASP.NET Core enable server-side rendering of HTML elements in Razor views
          with a natural HTML-like syntax. They are a powerful way to combine server-side logic with
          client-side UI generation, improving readability and maintainability.
        </p><br />
    

        <h2>Using Tag Helpers in Views and Partial Views</h2>
        <p style={{paddingBottom:"6px"}}>
          Tag Helpers are most commonly used in views and partial views to simplify HTML generation
          for forms, links, and other common web components.
        </p>
        <h3 style={{paddingBottom:"6px"}}>Commonly Used Tag Helpers</h3>
        <ul>
          <li>
            <strong>Anchor Tag Helper</strong>: Generates links using <code>&lt;a asp-controller&gt;</code>.
          </li><br />
          <li>
            <strong>Form Tag Helper</strong>: Builds forms using <code>&lt;form asp-controller&gt;</code>.
          </li><br />
          <li>
            <strong>Input Tag Helper</strong>: Generates <code>&lt;input&gt;</code> elements for model properties using <code>&lt;input asp-for&gt;</code>.
          </li><br />
          <li>
            <strong>Label Tag Helper</strong>: Generates labels for input fields using <code>&lt;label asp-for&gt;</code>.
          </li><br />
          <li>
            <strong>Validation Tag Helpers</strong>: Render validation messages and summaries.
          </li>
        </ul><br />
  
        <h2>Adding Tag Helpers to a View</h2>
        <p>
          By default, Tag Helpers are enabled in ASP.NET Core Razor views. To ensure they are active,
          check the <code>_ViewImports.cshtml</code> file:
        </p>
        <pre>
          <code>@addTagHelper *, Microsoft.AspNetCore.Mvc.TagHelpers</code>
        </pre><br />
    
        <h2 style={{paddingBottom:"6px"}}>Examples of Tag Helpers in Views</h2>
        <h3>Anchor Tag Helper</h3>
        <pre>
          <code>
            {`<a asp-controller="Home" asp-action="Index">Home</a>`}
          </code>
        </pre>
        <p>Output:</p>
        <pre>
          <code>{`<a href="/Home/Index">Home</a>`}</code>
        </pre><br />

        <h3>Form Tag Helper</h3>
        <pre>
          <code>
            {`<form asp-controller="Account" asp-action="Login" method="post">
  <input asp-for="Username" />
  <input asp-for="Password" type="password" />
  <button type="submit">Login</button>
</form>`}
          </code>
        </pre>
        <p>Output:</p>
        <pre>
          <code>{`<form action="/Account/Login" method="post">
  <input id="Username" name="Username" />
  <input id="Password" name="Password" type="password" />
  <button type="submit">Login</button>
</form>`}</code>
        </pre><br />
      
        <h2>Integrating Tag Helpers in Partial Views</h2>
        <p style={{paddingBottom:"6px"}}>
          Tag Helpers can be seamlessly used in partial views to create reusable components like
          navigation bars, forms, and table layouts.
        </p>
        <h3>Partial View Example: Navigation Bar</h3>
        <pre>
          <code>
            {`<ul>
  <li><a asp-controller="Home" asp-action="Index">Home</a></li>
  <li><a asp-controller="Products" asp-action="List">Products</a></li>
  <li><a asp-controller="Account" asp-action="Login">Login</a></li>
</ul>`}
          </code>
        </pre><br />
      
        <h2>Working with Strongly Typed Models and Tag Helpers</h2>
        <p>
          Tag Helpers are particularly effective with strongly-typed views. You can bind input fields
          and labels to model properties using the <code>asp-for</code> attribute.
        </p>
        <h3>Example</h3>
        <pre>
          <code>
            {`<form asp-controller="Products" asp-action="Create" method="post">
  <label asp-for="Name"></label>
  <input asp-for="Name" />
  
  <label asp-for="Price"></label>
  <input asp-for="Price" type="number" />
  
  <button type="submit">Submit</button>
</form>`}
          </code>
        </pre><br />
     
        <h2 style={{paddingBottom:"6px"}}>Summary of Key Benefits</h2>
        <ol>
          <li><strong>Improved Readability</strong>: Simplifies Razor syntax with intuitive HTML-like tags.</li><br />
          <li><strong>Strongly-Typed Binding</strong>: Easily bind inputs to model properties.</li><br />
          <li><strong>Reduced Boilerplate</strong>: Automatically generates repetitive attributes like <code>id</code> and <code>name</code>.</li><br />
          <li><strong>Reusable UI</strong>: Works seamlessly with partial views and custom Tag Helpers.</li>
        </ol>
   
    </div>
  )
}





{selectedChapter === 'chapter20' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Entity Framework Core Overview  </h1>


        <p>
          Entity Framework Core (EF Core) is a lightweight, extensible, and cross-platform version 
          of Microsoft's popular **Entity Framework ORM** (Object-Relational Mapper). It enables 
          developers to work with a database using .NET objects, eliminating the need for most of 
          the data access code.
        </p><br />
      
        <h2 style={{paddingBottom:"6px"}}>Key Features of EF Core</h2>
        <ul>
          <li>
            <strong>Cross-Platform:</strong> Works on Windows, macOS, and Linux with .NET Core.
          </li><br />
          <li>
            <strong>LINQ Support:</strong> Provides a rich querying capability using LINQ (Language Integrated Query).
          </li><br />
          <li>
            <strong>Migrations:</strong> Handles schema changes in the database through migrations.
          </li><br />
          <li>
            <strong>Performance:</strong> Designed with performance optimizations for modern applications.
          </li><br />
          <li>
            <strong>Customizable:</strong> Extensible for custom conventions, interceptors, and database providers.
          </li>
        </ul><br />
      
        <h2>How EF Core Fits into ASP.NET Applications</h2>
        <p>
          EF Core acts as a bridge between the database and the application, allowing developers to work
          with domain-specific objects while handling database interactions seamlessly.
        </p>
        <ol>
          <li>
            Define your database schema and relationships using **Code-First** or **Database-First** approaches.
          </li><br />
          <li>
            Use LINQ to query data without writing raw SQL.
          </li><br />
          <li>
            Leverage EF Core migrations to manage database changes in a structured way.
          </li>
        </ol><br />
     
        <h2 style={{paddingBottom:"6px"}}>Getting Started with EF Core</h2>
        <h3>1. Install EF Core</h3>
        <p>Install EF Core NuGet packages for your application:</p>
        <pre>
          <code>
            {`dotnet add package Microsoft.EntityFrameworkCore
dotnet add package Microsoft.EntityFrameworkCore.SqlServer
dotnet add package Microsoft.EntityFrameworkCore.Tools`}
          </code>
        </pre><br />

        <h3>2. Define Your Context and Model</h3>
        <p>Create a context class and define entities to map your database schema:</p>
        <pre>
          <code>
            {`public class ApplicationDbContext : DbContext
{
    public DbSet<Product> Products { get; set; }

    protected override void OnConfiguring(DbContextOptionsBuilder optionsBuilder)
    {
        optionsBuilder.UseSqlServer("YourConnectionString");
    }
}

public class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
}`}
          </code>
        </pre><br />

        <h3>3. Perform Database Operations</h3>
        <p>Use the context to perform CRUD (Create, Read, Update, Delete) operations:</p>
        <pre>
          <code>
            {`using (var context = new ApplicationDbContext())
{
    // Create
    var product = new Product { Name = "Laptop", Price = 1200.00m };
    context.Products.Add(product);
    context.SaveChanges();

    // Read
    var products = context.Products.ToList();

    // Update
    var firstProduct = products.FirstOrDefault();
    if (firstProduct != null)
    {
        firstProduct.Price = 1000.00m;
        context.SaveChanges();
    }

    // Delete
    context.Products.Remove(firstProduct);
    context.SaveChanges();
}`}
          </code>
        </pre><br />
     
        <h2 style={{paddingBottom:"6px"}}>Benefits of Using EF Core</h2>
        <ul>
          <li>Eliminates the need for manual SQL query writing for most use cases.</li><br />
          <li>Supports multiple database providers such as SQL Server, SQLite, MySQL, PostgreSQL, etc.</li><br />
          <li>Allows developers to focus on business logic rather than database details.</li><br />
          <li>Enables rapid prototyping and faster development cycles.</li>
        </ul><br />
    
        <h2>EF Core with Migrations</h2>
        <p>
          Migrations allow you to incrementally update your database schema as your model changes. 
          To add and apply migrations, use the following commands:
        </p>
        <pre>
          <code>
            {`dotnet ef migrations add InitialCreate
dotnet ef database update`}
          </code>
   </pre><br />

     
        <h2>Summary</h2>
        <p>
          EF Core simplifies database access in ASP.NET applications by providing a highly extensible 
          and performance-optimized ORM framework. Whether you're working with simple or complex data models, 
          EF Core provides the tools necessary to streamline database interactions and focus on building 
          robust, scalable applications.
        </p>
      
   


  </div>
)
}



{selectedChapter === 'chapter21' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Code-First Approach in EF Core  </h1>

     
        <p>
          The <strong>Code-First Approach </strong>  in Entity Framework Core (EF Core) is a development workflow that allows you to define 
          your database schema through C# classes. Instead of starting with a database, you design your application models, 
          and EF Core generates the corresponding database structure.
        </p><br />
      


        <h2 style={{paddingBottom:"6px"}}>Key Steps in the Code-First Approach</h2>
        <ol>
          <li>
            <strong>Define Entity Classes:</strong> Create C# classes that represent the tables in your database.
          </li><br />
          <li>
            <strong>Set Up the DbContext:</strong> Define a DbContext class to manage the database connections and entity configurations.
          </li><br />
          <li>
            <strong>Generate Migrations:</strong> Use migrations to create and update the database schema based on your entity classes.
          </li>
        </ol><br />

      
        <h2>1. Define Entity Classes</h2>
        <p>
          Entity classes represent the tables and columns in your database. Each property in the class corresponds to a 
          column in the table.
        </p>
        <pre>
          <code>
            {`public class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
    public int Stock { get; set; }
}`}
          </code>
        </pre><br />
     


        <h2>2. Set Up the DbContext</h2>
        <p>
          The DbContext class acts as a bridge between your entity classes and the database. Define a DbSet for each entity 
          you want to include in the database.
        </p>
        <pre>
          <code>
            {`public class ApplicationDbContext : DbContext
{
    public DbSet<Product> Products { get; set; }

    protected override void OnConfiguring(DbContextOptionsBuilder optionsBuilder)
    {
        optionsBuilder.UseSqlServer("YourConnectionString");
    }
}`}
          </code>
        </pre><br />
     


        <h2>3. Add and Apply Migrations</h2>
        <p>
          Once your entities and DbContext are ready, use EF Core migrations to create the database schema.
        </p>
        <h3 style={{paddingBottom:"6px"}}>Commands:</h3>
        <ul>
          <li>
            <strong>Add Migration:</strong> Creates a migration file based on changes in your model.
            <pre>
              <code>dotnet ef migrations add InitialCreate</code>
            </pre>
          </li><br />
          <li>
            <strong>Update Database:</strong> Applies the migration and creates the database.
            <pre>
              <code>dotnet ef database update</code>
            </pre>
          </li>
        </ul><br />
   


        <h2>4. Perform CRUD Operations</h2>
        <p>
          Use the DbContext to perform Create, Read, Update, and Delete operations.
        </p>
        <pre>
          <code>
            {`// Create
using (var context = new ApplicationDbContext())
{
    var product = new Product { Name = "Laptop", Price = 1200.00m, Stock = 10 };
    context.Products.Add(product);
    context.SaveChanges();
}

// Read
using (var context = new ApplicationDbContext())
{
    var products = context.Products.ToList();
}

// Update
using (var context = new ApplicationDbContext())
{
    var product = context.Products.FirstOrDefault();
    if (product != null)
    {
        product.Price = 1000.00m;
        context.SaveChanges();
    }
}

// Delete
using (var context = new ApplicationDbContext())
{
    var product = context.Products.FirstOrDefault();
    if (product != null)
    {
        context.Products.Remove(product);
        context.SaveChanges();
    }
}`}
          </code>
        </pre><br />
     


        <h2>Configuring Relationships</h2>
        <p>
          Use navigation properties and Fluent API configurations to define relationships between entities 
          (e.g., one-to-many, many-to-many).
        </p>
        <pre>
          <code>
            {`public class Category
{
    public int Id { get; set; }
    public string Name { get; set; }
    public ICollection<Product> Products { get; set; }
}

public class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
    public int Stock { get; set; }
    public int CategoryId { get; set; }
    public Category Category { get; set; }
}

// Configure in DbContext
protected override void OnModelCreating(ModelBuilder modelBuilder)
{
    modelBuilder.Entity<Category>()
        .HasMany(c => c.Products)
        .WithOne(p => p.Category)
        .HasForeignKey(p => p.CategoryId);
}`}
          </code>
        </pre><br />
      

        <h2 style={{paddingBlockStart:"6px"}}>Advantages of the Code-First Approach</h2>
        <ul>
          <li>Allows development without an existing database.</li><br />
          <li>Provides complete control over the database schema through C# code.</li><br />
          <li>Supports incremental changes to the schema with migrations.</li><br />
          <li>Reduces the need for database-specific tools during development.</li>
        </ul><br />
     


        <h2>Summary</h2>
        <p>
          The Code-First approach in EF Core is a powerful and flexible way to manage your database schema and interactions. 
          By defining your models in code, you can focus on application logic while EF Core handles the complexities 
          of database management. Its support for migrations, relationships, and seamless CRUD operations makes it 
          a preferred choice for modern .NET applications.
        </p>
     

  </div>
)}





{selectedChapter === 'chapter22' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Database-First and Model-First Approaches  </h1>

    
        <p>
          The <strong>Database-First</strong> and  <strong>Model-First</strong> approaches in Entity Framework Core are alternative workflows to the 
          Code-First approach. These methods are useful when the database already exists or when visual modeling is preferred 
          for schema design.
        </p><br />
     
        <h2>1. Database-First Approach</h2>
        <p>
          The Database-First approach allows developers to generate the model (classes) from an existing database schema. 
          It is ideal when:
        </p>
        <ul>
          <li>You already have a database, and you want to use EF Core for ORM operations.</li><br />
          <li>Database schema management is handled by database administrators (DBAs).</li>
        </ul><br />
        <h3>Steps to Use Database-First Approach</h3>
        <ol>
          <li>
            <strong>Install Required EF Core Tools:</strong> Ensure you have the necessary tools installed for scaffolding.
            <pre>
              <code>dotnet tool install --global dotnet-ef</code>
            </pre>
          </li><br />
          <li>
            <strong>Scaffold the Database:</strong> Use the `dotnet ef dbcontext scaffold` command to generate entity classes and the DbContext.
            <pre>
              <code>
                {`dotnet ef dbcontext scaffold "YourConnectionString" Microsoft.EntityFrameworkCore.SqlServer -o Models`}
              </code>
            </pre>
          </li><br />
          <li>
            <strong>Customize Generated Code:</strong> Modify the generated classes or DbContext if needed.
          </li>
        </ol><br />
        <h3 style={{paddingBottom:"6px"}}>Advantages of Database-First Approach</h3>
        <ul>
          <li>Works seamlessly with existing databases.</li><br />
          <li>Minimizes effort in generating models manually.</li><br />
          <li>Keeps the model synchronized with the database schema.</li>
        </ul><br />
     
        <h2>2. Model-First Approach</h2>
        <p>
          The Model-First approach enables developers to create a visual model of the database schema. From the model, 
          EF Core can generate both the database and entity classes. This approach is suitable when:
        </p>
        <ul>
          <li>The database doesn't exist, and you prefer designing it visually.</li><br />
          <li>You want to focus on database relationships and structures graphically before implementation.</li>
        </ul><br />
        <h3 style={{paddingBottom:"6px"}}>Steps to Use Model-First Approach</h3>
        <ol>
          <li>
            <strong>Create the Model:</strong> Use a tool like the Entity Framework Designer in Visual Studio to design the model.
          </li><br />
          <li>
            <strong>Generate the Database:</strong> Generate the database from the model using the Entity Framework tooling.
          </li><br />
          <li>
            <strong>Update Entities:</strong> Ensure that the generated entity classes reflect the model structure.
          </li>
        </ol><br />
        <h3 style={{paddingBottom:"6px"}}>Advantages of Model-First Approach</h3>
        <ul>
          <li>Provides a clear visual representation of the schema.</li><br />
          <li>Allows easy iteration and refinement of the design.</li><br />
          <li>Generates both the database and entity classes from a single model.</li>
        </ul><br />
    
        <h2>Comparison: Database-First vs. Model-First</h2>
        <table className={style.comparisonTable}>
          <thead>
            <tr>
              <th>Aspect</th>
              <th>Database-First</th>
              <th>Model-First</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Starting Point</td>
              <td>Existing database schema</td>
              <td>Visual model design</td>
            </tr>
            <tr>
              <td>Use Case</td>
              <td>When a database already exists</td>
              <td>When the database doesn't exist yet</td>
            </tr>
            <tr>
              <td>Tools</td>
              <td>EF Core scaffolding commands</td>
              <td>Entity Framework Designer</td>
            </tr>
            <tr>
              <td>Output</td>
              <td>Entity classes and DbContext</td>
              <td>Database schema and entity classes</td>
            </tr>
            <tr>
              <td>Preferred By</td>
              <td>Developers working with legacy databases</td>
              <td>Developers who prefer visual schema design</td>
            </tr>
          </tbody>
        </table><br />
     
        <h2>Summary</h2>
        <p>
          The Database-First and Model-First approaches provide flexibility for different development scenarios. 
          Database-First is ideal for working with existing databases, while Model-First is perfect for designing 
          new databases visually. Both approaches complement the Code-First approach, making EF Core a versatile ORM 
          for ASP.NET applications.
        </p>
    
  
   


  </div>
)}


{selectedChapter === 'chapter23' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> CRUD Operations with Entity Framework Core    </h1>


        <p>
          CRUD operations (Create, Read, Update, Delete) are fundamental database operations. Entity Framework Core 
          (EF Core) simplifies these operations by providing an abstraction over raw SQL queries and database interactions. 
          This guide outlines how to perform CRUD operations using EF Core.
        </p><br />
   
        <h2>1. Setting Up EF Core</h2>
        <p>
          Before performing CRUD operations, ensure EF Core is set up in your project:
        </p>
        <ol>
          <li>
            <strong>Install EF Core:</strong> Use NuGet to install EF Core packages.
            <pre>
              <code>
                {`dotnet add package Microsoft.EntityFrameworkCore
dotnet add package Microsoft.EntityFrameworkCore.SqlServer`}
              </code>
            </pre>
          </li><br />
          <li>
            <strong>Configure the DbContext:</strong> Set up the DbContext to connect to the database.
            <pre>
              <code>
                {`public class ApplicationDbContext : DbContext
{
    public DbSet<Product> Products { get; set; }

    protected override void OnConfiguring(DbContextOptionsBuilder optionsBuilder)
    {
        optionsBuilder.UseSqlServer("YourConnectionString");
    }
}`}
              </code>
            </pre>
          </li>
          <li>Create entity classes that represent your database tables.</li>
        </ol><br />
      
        <h2>2. Create Operation</h2>
        <p style={{paddingBottom:"6px"}}>
          Adding new records to the database can be achieved using the `Add` and `SaveChanges` methods.
        </p>
        <h3>Example</h3>
        <pre>
          <code>
            {`public void AddProduct()
{
    using (var context = new ApplicationDbContext())
    {
        var product = new Product { Name = "Laptop", Price = 1000 };
        context.Products.Add(product);
        context.SaveChanges();
    }
}`}
          </code>
        </pre><br />
      

        <h2>3. Read Operation</h2>
        <p style={{paddingBottom:"6px"}}>
          Retrieving data is done using LINQ queries. EF Core translates these queries into SQL.
        </p>
        <h3>Example: Retrieve All Products</h3>
        <pre>
          <code>
            {`public List<Product> GetAllProducts()
{
    using (var context = new ApplicationDbContext())
    {
        return context.Products.ToList();
    }
}`}
          </code>
        </pre><br />
        <h3>Example: Retrieve a Single Product by ID</h3>
        <pre>
          <code>
            {`public Product GetProductById(int id)
{
    using (var context = new ApplicationDbContext())
    {
        return context.Products.Find(id);
    }
}`}
          </code>
        </pre><br />


     
        <h2>4. Update Operation</h2>
        <p style={{paddingBottom:"6px"}}>
          Updating existing records is done by modifying the entity and calling `SaveChanges`.
        </p>
        <h3>Example</h3>
        <pre>
          <code>
            {`public void UpdateProduct(int id)
{
    using (var context = new ApplicationDbContext())
    {
        var product = context.Products.Find(id);
        if (product != null)
        {
            product.Price = 1200;
            context.SaveChanges();
        }
    }
}`}
          </code>
        </pre><br />
      

        <h2>5. Delete Operation</h2>
        <p>
          Deleting a record is achieved using the `Remove` method followed by `SaveChanges`.
        </p>
        <h3>Example</h3>
        <pre>
          <code>
            {`public void DeleteProduct(int id)
{
    using (var context = new ApplicationDbContext())
    {
        var product = context.Products.Find(id);
        if (product != null)
        {
            context.Products.Remove(product);
            context.SaveChanges();
        }
    }
}`}
          </code>
        </pre><br />
     


        <h2>6. Summary of CRUD Operations</h2>
        <table className={style.comparisonTable}>
          <thead>
            <tr>
              <th>Operation</th>
              <th>EF Core Method</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Create</td>
              <td>`Add`</td>
              <td>Adds a new entity to the DbContext.</td>
            </tr>
            <tr>
              <td>Read</td>
              <td>`Find`, `ToList`</td>
              <td>Retrieves one or more entities from the database.</td>
            </tr>
            <tr>
              <td>Update</td>
              <td>Modify properties, `SaveChanges`</td>
              <td>Updates an existing entity in the database.</td>
            </tr>
            <tr>
              <td>Delete</td>
              <td>`Remove`, `SaveChanges`</td>
              <td>Deletes an entity from the database.</td>
            </tr>
          </tbody>
        </table><br />
     

        <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
        <ul>
          <li>Use async methods like `SaveChangesAsync` for better scalability.</li><br />
          <li>Validate user inputs before performing database operations.</li><br />
          <li>Handle exceptions gracefully to ensure application stability.</li>
        </ul><br />




  </div>
)}




{selectedChapter === 'chapter24' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Migrations in EF Core   </h1>


        <p>
          Migrations in Entity Framework Core (EF Core) allow developers to update the database schema in sync with the 
          application’s data model changes. This feature automates the process of creating, applying, and maintaining database schema updates over time.
        </p><br />
   

        <h2>1. What are Migrations?</h2>
        <p>
          A migration is a set of changes to the database schema generated based on modifications in the EF Core model 
          classes or DbContext. These migrations can be applied to the database to ensure it reflects the current application model.
        </p><br />
     

        <h2>2. Adding Migrations</h2>
        <p style={{paddingBottom:"6px"}}>
          You can create a migration using the EF Core CLI or Package Manager Console (PMC):
        </p>
        <h3>Using EF Core CLI</h3>
        <pre>
          <code>
            {`dotnet ef migrations add InitialCreate`}
          </code>
        </pre><br />
        <h3>Using PMC</h3>
        <pre>
          <code>
            {`Add-Migration InitialCreate`}
          </code>
        </pre>
        <p>
          This command generates migration files that describe the changes made to your data model.
        </p><br />
     
        <h2>3. Applying Migrations</h2>
        <p style={{paddingBottom:"6px"}}>
          Once a migration is created, apply it to the database using the following commands:
        </p>
        <h3>Using EF Core CLI</h3>
        <pre>
          <code>
            {`dotnet ef database update`}
          </code>
        </pre><br />
        <h3>Using PMC</h3>
        <pre>
          <code>
            {`Update-Database`}
          </code>
        </pre>
        <p>
          This command executes the migration scripts and updates the database schema.
        </p><br />
     

        <h2>4. Removing Migrations</h2>
        <p>
          If you need to undo a migration that hasn’t been applied to the database yet:
        </p>
        <h3>Using EF Core CLI</h3>
        <pre>
          <code>
            {`dotnet ef migrations remove`}
          </code>
        </pre>
        <h3>Using PMC</h3>
        <pre>
          <code>
            {`Remove-Migration`}
          </code>
        </pre>
        <p>
          This removes the last migration file created without affecting the database.
        </p><br />
     
        <h2>5. Migration Files</h2>
        <p>
          A migration file consists of two key methods:
        </p>
        <ul>
          <li>
            <strong>Up:</strong> Contains the code to apply the migration (e.g., creating tables, adding columns).
          </li><br />
          <li>
            <strong>Down:</strong> Contains the code to revert the migration (e.g., dropping tables, removing columns).
          </li>
        </ul>
        <h3>Example</h3>
        <pre>
          <code>
            {`public partial class InitialCreate : Migration
{
    protected override void Up(MigrationBuilder migrationBuilder)
    {
        migrationBuilder.CreateTable(
            name: "Products",
            columns: table => new
            {
                Id = table.Column<int>(nullable: false)
                    .Annotation("SqlServer:Identity", "1, 1"),
                Name = table.Column<string>(nullable: true),
                Price = table.Column<decimal>(nullable: false)
            },
            constraints: table =>
            {
                table.PrimaryKey("PK_Products", x => x.Id);
            });
    }

    protected override void Down(MigrationBuilder migrationBuilder)
    {
        migrationBuilder.DropTable(
            name: "Products");
    }
}`}
          </code>
        </pre><br />
      
        <h2>6. Updating Migrations</h2>
        <p>
          If you modify your model after applying a migration, create a new migration to capture those changes:
        </p>
        <h3>Command</h3>
        <pre>
          <code>
            {`dotnet ef migrations add UpdateSchema`}
          </code>
        </pre>
        <p>
          This generates a new migration reflecting the latest changes to your data model.
        </p><br />
      
        <h2>7. Common Commands for Migrations</h2>
        <table className={style.comparisonTable}>
          <thead>
            <tr>
              <th>Command</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>`Add-Migration`</td>
              <td>Creates a new migration based on model changes.</td>
            </tr>
            <tr>
              <td>`Update-Database`</td>
              <td>Applies pending migrations to the database.</td>
            </tr>
            <tr>
              <td>`Remove-Migration`</td>
              <td>Deletes the most recent migration file.</td>
            </tr>
            <tr>
              <td>`dotnet ef migrations list`</td>
              <td>Displays all applied migrations.</td>
            </tr>
          </tbody>
        </table><br />
      
        <h2 style={{paddingBottom:"6px"}}>8. Best Practices</h2>
        <ul>
          <li>Keep migrations small and incremental for better maintainability.</li><br />
          <li>Always back up the database before applying new migrations.</li><br />
          <li>Use version control to track migration files.</li><br />
          <li>Test migrations in a staging environment before applying to production.</li>
        </ul>
    

  
  </div>
)}




{selectedChapter === 'chapter25' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Working with LINQ in ASP.NET Core  </h1>
  
    
        <p>
          LINQ (Language Integrated Query) is a powerful feature of C# that allows developers to write SQL-like queries directly in their code. LINQ queries can be used to access and manipulate data from various sources such as arrays, collections, and databases. In ASP.NET Core, LINQ is commonly used in conjunction with Entity Framework Core to interact with databases in a readable and efficient manner.
        </p><br />
   
        <h2>1. Introduction to LINQ</h2>
        <p>
          LINQ provides a unified approach for querying different types of data sources. It allows you to query data in a declarative way using C# syntax, making it simpler to write and maintain complex queries. LINQ queries are executed using deferred execution, meaning that they are not evaluated until you enumerate through the query.
        </p>
        <ul>
          <li><strong>LINQ to Objects:</strong> Used for querying in-memory collections (e.g., arrays, lists).</li><br />
          <li><strong>LINQ to Entities:</strong> Used for querying databases through Entity Framework Core.</li><br />
          <li><strong>LINQ to XML:</strong> Used for querying XML documents.</li>
        </ul><br />
      
        <h2>2. LINQ Syntax in ASP.NET Core</h2>
        <p style={{paddingBottom:"6px"}}>
          LINQ can be written using two syntax styles: <strong>query syntax</strong> and <strong>method syntax</strong>.
        </p>

        <h3>Query Syntax</h3>
        <pre>
          <code>
            {`var query = from product in context.Products
                        where product.Price > 100
                        orderby product.Name
                        select product;`}
          </code>
        </pre><br />

        <h3>Method Syntax</h3>
        <pre>
          <code>
            {`var query = context.Products
                        .Where(p => p.Price > 100)
                        .OrderBy(p => p.Name)
                        .ToList();`}
          </code>
        </pre>

        <p>
          While both syntaxes are functionally equivalent, query syntax often resembles SQL more closely, whereas method syntax is more natural for C# developers.
        </p><br />
    
        <h2>3. Common LINQ Methods</h2>
        <table className={style.comparisonTable}>
          <thead>
            <tr>
              <th>Method</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><code>Where</code></td>
              <td>Filters elements based on a given condition.</td>
            </tr>
            <tr>
              <td><code>Select</code></td>
              <td>Projects elements into a new form (e.g., a different type or subset of properties).</td>
            </tr>
            <tr>
              <td><code>OrderBy</code></td>
              <td>Orders elements in ascending order based on a key.</td>
            </tr>
            <tr>
              <td><code>OrderByDescending</code></td>
              <td>Orders elements in descending order.</td>
            </tr>
            <tr>
              <td><code>GroupBy</code></td>
              <td>Groups elements by a specified key.</td>
            </tr>
            <tr>
              <td><code>First</code></td>
              <td>Returns the first element in a collection that satisfies the condition.</td>
            </tr>
            <tr>
              <td><code>Count</code></td>
              <td>Counts the number of elements in a collection that match a condition.</td>
            </tr>
          </tbody>
        </table>
     <br />

        <h2 style={{paddingBottom:"6px"}}>4. LINQ Performance Tips</h2>
        <ul>
          <li>Use <code>AsNoTracking()</code> when querying data that won’t be modified, for better performance.</li><br />
          <li>Avoid client-side evaluation—ensure that queries are executed on the server, especially when working with large data sets.</li><br />
          <li>Use <code>Take()</code> to limit the number of records returned by a query.</li><br />
          <li>Index frequently queried columns in your database to improve performance.</li>
        </ul><br />
    
        <h2>5. LINQ with Entity Framework Core</h2>
        <p>
          In Entity Framework Core, LINQ can be used to perform CRUD operations against a database. Here’s an example of how you might use LINQ to retrieve data from a database:
        </p>
        <pre>
          <code>
            {`var expensiveProducts = context.Products
                                              .Where(p => p.Price > 100)
                                              .OrderBy(p => p.Name)
                                              .ToList();`}
          </code>
        </pre>
        <p>
          This query retrieves all products with a price greater than 100, ordered by their name. It demonstrates how LINQ integrates smoothly with EF Core to query the database.
        </p><br />
      
        <h2 style={{paddingBottom:"6px"}}>6. Best Practices for LINQ</h2>
        <ul>
          <li>Leverage method syntax for complex queries and chainable operations.</li><br />
          <li>For read-only queries, use <code>AsNoTracking()</code> to improve performance.</li><br />
          <li>Be mindful of deferred execution—queries are not executed until you enumerate them.</li><br />
          <li>When working with large data sets, consider using pagination techniques like <code>Skip()</code> and <code>Take()</code> to reduce the amount of data being loaded into memory.</li>
        </ul>
   
   
  </div>
)}


{selectedChapter === 'chapter26' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Using Dapper for Micro-ORM</h1>

   
      <h2>Using Dapper for Micro-ORM </h2>
      <p>
        Dapper is a lightweight Micro-ORM developed by Stack Exchange. It's a simple and efficient
        library for interacting with databases in .NET, especially when you want to avoid the
        complexity of full-fledged ORMs like Entity Framework. Here’s a guide on using Dapper in
        ASP.NET Core:
      </p>
     <br />
 

 
      <h3>1. Why Dapper?</h3>
      <ul>
        <li><strong>Performance:</strong> Dapper is fast as it uses raw SQL queries and minimizes overhead.</li><br />
        <li><strong>Flexibility:</strong> You have full control over the SQL queries.</li><br />
        <li><strong>Lightweight:</strong> No complex configurations or setup.</li><br />
        <li><strong>Compatibility:</strong> Works with any database that supports ADO.NET.</li>
      </ul>
     <br />

      <h3 style={{paddingBottom:"6px"}}>2. Getting Started with Dapper</h3>
      <h4>Step 1: Install Dapper</h4>
      <pre>
        <code>dotnet add package Dapper</code>
      </pre>

      <h4>Step 2: Configure Database Connection</h4>
      <pre>
        <code>
{`{
  "ConnectionStrings": {
    "DefaultConnection": "Server=your_server;Database=your_database;User Id=your_user;Password=your_password;"
  }
}`}
        </code>
      </pre><br />

      <h4>Step 3: Register Dapper in Dependency Injection</h4>
      <pre>
        <code>
{`builder.Services.AddScoped<IDbConnection>(sp => 
    new SqlConnection(builder.Configuration.GetConnectionString("DefaultConnection")));`}
        </code>
      </pre>
      <br />

      <h3 style={{paddingBottom:"6px"}}>3. Basic CRUD Operations with Dapper</h3>
      <h4>Example Model</h4>
      <pre>
        <code>
{`public class Product {
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
}`}
        </code>
      </pre><br />

      <h4>Repository Implementation</h4>
      <pre>
        <code>
{`public class ProductRepository {
    private readonly IDbConnection _dbConnection;

    public ProductRepository(IDbConnection dbConnection) {
        _dbConnection = dbConnection;
    }

    // Retrieve all products
    public async Task<IEnumerable<Product>> GetAllProductsAsync() {
        var query = "SELECT * FROM Products";
        return await _dbConnection.QueryAsync<Product>(query);
    }
}`}
        </code>
      </pre>

  </div>
)}


{selectedChapter === 'chapter27' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to Razor Pages  </h1>

        <p>
          Razor Pages is a simplified, page-centric way of building web applications in ASP.NET Core. It provides a more streamlined approach compared to MVC (Model-View-Controller) for handling the UI logic in a web application. Razor Pages is built around the idea of handling requests based on pages rather than controllers, making it a great choice for developers who want to focus on a page-oriented approach rather than a controller-based one.
        </p><br />
      
        <h2>1. What is Razor Pages?</h2>
        <p>
          Razor Pages is a web development framework in ASP.NET Core, designed to be a simpler alternative to the traditional MVC pattern. It leverages the Razor templating engine to dynamically generate HTML on the server side. Razor Pages simplifies the development of applications that have many pages with a straightforward structure and reduces the overhead of using controllers for each page.
        </p>
        <ul>
          <li><strong>Page-based:</strong> Each page in the app corresponds to a .cshtml file and is self-contained with its logic and view.</li><br />
          <li><strong>Simple routing:</strong> Razor Pages makes routing easy by automatically mapping URL patterns to the corresponding page models.</li><br />
          <li><strong>Integrated with ASP.NET Core:</strong> Razor Pages is fully integrated with ASP.NET Core, offering a powerful, modern platform for building web applications.</li>
        </ul><br />
    
        <h2>2. How Razor Pages Work</h2>
        <p>
          A Razor Page consists of two main components: a Razor view (.cshtml file) and a PageModel (.cshtml.cs file). The Razor view contains the HTML markup and Razor syntax, while the PageModel contains the C# code that interacts with the data and handles the page's logic.
        </p>
        <p>
          When a request is made to a Razor Page, ASP.NET Core automatically executes the logic in the associated PageModel and renders the Razor view to generate the final HTML.
        </p>
        <ul>
          <li><strong>Razor View (.cshtml):</strong> Contains the HTML structure and embedded C# Razor syntax to dynamically render content.</li><br />
          <li><strong>PageModel (.cshtml.cs):</strong> Contains C# code for handling user input, interacting with the database, and performing business logic.</li>
        </ul><br />
    
        <h2>3. Creating a Razor Page</h2>
        <p>
          To create a Razor Page, follow these steps:
        </p>
        <ul>
          <li>Right-click on the "Pages" folder in your ASP.NET Core project.</li><br />
          <li>Select "Add" and then "Razor Page..." from the context menu.</li><br />
          <li>Choose a template (e.g., Empty, Create, Edit) based on your needs.</li><br />
          <li>After adding the Razor Page, ASP.NET Core will generate both the Razor view and the PageModel class for you.</li>
        </ul><br />
        <p>
          Here's an example of a simple Razor Page:
        </p>
        <pre>
          <code>
            {`// Index.cshtml (Razor View)
@page
@model IndexModel
<h1>@Model.Message</h1>

// Index.cshtml.cs (Page Model)
public class IndexModel : PageModel
{
    public string Message { get; set; }

    public void OnGet()
    {
        Message = "Welcome to Razor Pages!";
    }
}`}
          </code>
        </pre>
        <p>
          In the above example:
          <ul>
            <li>The `@page` directive indicates that this file is a Razor Page.</li><br />
            <li>The `@model` directive links the Razor Page to the `IndexModel` PageModel class.</li><br />
            <li>The `OnGet()` method in the PageModel handles GET requests and sets the message to be displayed in the view.</li>
          </ul>
        </p>
  <br />


        <h2>4. Handling HTTP Requests</h2>
        <p>
          Razor Pages supports handling different HTTP verbs like GET, POST, PUT, and DELETE. You can handle these requests by defining methods in the PageModel class.
        </p>
        <p>
          For example, to handle a POST request for a form submission, you would define an `OnPost()` method:
        </p>
        <pre>
          <code>
            {`// Create.cshtml (Razor View)
@page
@model CreateModel
<form method="post">
    <input type="text" name="name" />
    <button type="submit">Submit</button>
</form>

// Create.cshtml.cs (Page Model)
public class CreateModel : PageModel
{
    [BindProperty]
    public string Name { get; set; }

    public void OnPost()
    {
        // Handle POST request
        // Logic to save Name to database or process input
    }
}`}
          </code>
        </pre>
        <p>
          In the example above:
          <ul>
            <li>The form in the Razor view submits a POST request to the server.</li><br />
            <li>The `OnPost()` method handles the form submission, with the form data being automatically bound to the `Name` property.</li>
          </ul>
        </p><br />
   


        <h2>5. Razor Pages vs MVC</h2>
        <p>
          While both Razor Pages and MVC are part of ASP.NET Core, there are key differences:
        </p>
        <ul>
          <li><strong>Routing:</strong> In Razor Pages, the URL corresponds directly to a Razor Page (e.g., `/Index`), whereas in MVC, URLs map to controller actions (e.g., `/Home/Index`).</li><br />
          <li><strong>Page-based vs Controller-based:</strong> Razor Pages focuses on individual pages, while MVC separates the logic into controllers and views.</li><br />
          <li><strong>Simplicity:</strong> Razor Pages is simpler for applications with many pages, as it doesn't require controllers for each view.</li>
        </ul><br />
     

        <h2 style={{paddingBottom:"6px"}}>6. Razor Pages Best Practices</h2>
        <ul>
          <li>Keep your Razor Pages focused on a single page’s logic, and avoid making them too complex.</li><br />
          <li>Use the `PageModel` to separate the logic from the view to follow the principle of separation of concerns.</li><br />
          <li>Leverage model binding to easily bind form data and query parameters to properties in the PageModel.</li><br />
          <li>Use tag helpers in your Razor views to simplify HTML generation and make it more readable.</li>
        </ul>
    


  </div>
)}



{selectedChapter === 'chapter28' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Razor Pages vs. MVC   </h1>
   
       <p>
          In ASP.NET Core, both Razor Pages and Model-View-Controller (MVC) are used for building web applications, but they follow different approaches to handling the requests and structuring the application. Understanding the differences between Razor Pages and MVC is important for choosing the right approach based on the project requirements.
        </p><br />
   
        <h2>1. Razor Pages</h2>
        <p>
          Razor Pages is a simplified, page-centric approach to building web applications. It is designed to make it easier for developers to handle the UI logic and routing without needing to define multiple controllers. Razor Pages is great for applications that consist of many pages with simple actions, as it combines the view and controller logic into a single entity—the page.
        </p>
        <ul>
          <li><strong>Page-Based:</strong> Razor Pages focuses on individual pages, where each page is represented by a `.cshtml` file and its corresponding `.cshtml.cs` PageModel file that handles logic and data.</li><br />
          <li><strong>Simpler Routing:</strong> URL routing in Razor Pages is based on the page file’s location within the folder structure, making it easier to set up and manage for simple applications.</li><br />
          <li><strong>Self-Contained:</strong> Each page is self-contained with both its view and logic in one place, reducing the need for complex controller actions.</li>
        </ul><br />
    
        <h2>2. MVC (Model-View-Controller)</h2>
        <p>
          MVC is a traditional design pattern where the application is divided into three main components: the Model, the View, and the Controller. The Model handles the data, the View is responsible for rendering the UI, and the Controller acts as the mediator between the Model and the View.
        </p>
        <ul>
          <li><strong>Controller-Based:</strong> In MVC, each page or view is typically controlled by a dedicated controller that processes input and returns a response. You need to define actions in controllers to handle specific requests.</li><br />
          <li><strong>Flexible Routing:</strong> MVC uses more complex routing where actions in controllers map to URLs. This allows for more flexibility in how routes are structured, which can be beneficial in larger applications with more complex routing needs.</li><br />
          <li><strong>Separation of Concerns:</strong> MVC maintains a clear separation between the application logic (controller), data (model), and view, which promotes maintainability in larger applications.</li>
        </ul><br />
 
        <h2>3. Key Differences</h2>
        <table className={style.differenceTable}>
          <thead>
            <tr>
              <th>Aspect</th>
              <th>Razor Pages</th>
              <th>MVC</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Structure</td>
              <td>Page-based with `.cshtml` and `.cshtml.cs` files</td>
              <td>Controller-action-based with `.cshtml` views and controllers</td>
            </tr>
            <tr>
              <td>Routing</td>
              <td>Page-centric routing based on file location</td>
              <td>Action-based routing using controller names and action methods</td>
            </tr>
            <tr>
              <td>Complexity</td>
              <td>Simpler for smaller applications</td>
              <td>Better suited for larger applications with complex routing</td>
            </tr>
            <tr>
              <td>Use Case</td>
              <td>Simple, page-focused apps with minimal logic</td>
              <td>Complex apps requiring more controller-level logic</td>
            </tr>
          </tbody>
        </table><br />
     
        <h2>4. When to Use Razor Pages</h2>
        <p>
          Razor Pages is a great choice when:
        </p>
        <ul>
          <li>You have a relatively simple web application with a lot of pages, but the business logic for each page is minimal.</li><br />
          <li>You want to reduce the boilerplate code involved in routing and controllers.</li><br />
          <li>You prefer a simpler model for handling HTTP requests and rendering responses.</li>
        </ul><br />
    
        <h2>5. When to Use MVC</h2>
        <p>
          MVC is a better choice when:
        </p>
        <ul>
          <li>You need to implement complex business logic that requires separation of concerns between data, logic, and presentation.</li><br />
          <li>Your application has a lot of reusable components, and you want to avoid page duplication.</li><br />
          <li>You need to handle more complex routing and require more control over the HTTP request handling.</li>
        </ul><br />
     
        <h2>6. Razor Pages vs MVC: Choosing the Right Approach</h2>
        <p>
          Both Razor Pages and MVC are powerful tools in ASP.NET Core, but they serve different needs:
        </p>
        <ul>
          <li>If you are building a simple, page-oriented application, Razor Pages might be a more efficient approach.</li><br />
          <li>If your application requires more advanced functionality with complex workflows and reusable components, MVC might be the better choice.</li><br />
          <li>For larger applications, MVC may provide better maintainability and separation of concerns, while Razor Pages can offer a simpler approach for smaller applications.</li>
        </ul>
    


  </div>
)}



{selectedChapter === 'chapter29' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Working with Razor Page Handlers   </h1>

       <p>
          Razor Pages in ASP.NET Core introduces the concept of "handlers" to handle HTTP requests for a specific page. A handler is a method defined in the `PageModel` class that corresponds to a particular HTTP request type (e.g., GET, POST, etc.) for a page. This helps keep the code more organized and focused on the page's functionality.
        </p><br />
     
        <h2>1. What are Razor Page Handlers?</h2>
        <p>
          Razor Pages use handlers to respond to HTTP requests for a specific page. These handlers are methods that are associated with specific HTTP verbs, such as GET, POST, PUT, and DELETE. They provide a way to organize page-specific logic without needing a full-blown controller, as is required in the traditional MVC pattern.
        </p>
        <p>
          Handlers are defined in the `PageModel` class, and each handler is invoked when the associated HTTP request is made for the page.
        </p><br />
     
        <h2>2. Defining a Razor Page Handler</h2>
        <p>
          A Razor Page handler is defined as a method within the `PageModel` class, and the method name must be prefixed with the verb (`On`), such as `OnGet()`, `OnPost()`, or `OnPut()`. Here’s an example of a basic Razor Page handler:
        </p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    public void OnGet()
    {
        // Logic for GET request
    }

    public void OnPost()
    {
        // Logic for POST request
    }
}`}
        </pre>

        <p>
          In this example, the `OnGet()` method will handle HTTP GET requests, and the `OnPost()` method will handle HTTP POST requests for the same Razor Page.
        </p><br />
     
        <h2>3. Using Parameters with Handlers</h2>
        <p>
          Handlers can also accept parameters, which allows you to process user input or pass additional data to the handler method. Parameters are automatically bound to query string parameters or form data.
        </p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    public string Message { get; set; }

    public void OnGet(string message)
    {
        Message = message;
    }

    public void OnPost(string message)
    {
        // Process the message
    }
}`}
        </pre>

        <p>
          In this example, both the `OnGet()` and `OnPost()` methods accept a `message` parameter, which is passed via the query string or form data, depending on the request type.
        </p><br />
    
        <h2>4. Handler Return Types</h2>
        <p>
          Handlers can return various types, including:
        </p>
        <ul>
          <li><strong>void:</strong> The handler doesn't need to return anything. This is the most common return type.</li><br />
          <li><strong>IActionResult:</strong> You can return an `IActionResult` to perform actions such as redirection, returning a view, or sending a file.</li><br />
          <li><strong>Task:</strong> If your handler involves asynchronous operations, you can return a `Task` to handle the logic asynchronously.</li>
        </ul><br />

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    public string Message { get; set; }

    public IActionResult OnPostRedirect()
    {
        return RedirectToPage("/Home");
    }
}`}
        </pre>

        <p>
          In this example, the `OnPostRedirect()` method returns an `IActionResult`, specifically a redirect to another page (`/Home`).
        </p><br />
     
        <h2>5. Using the `HandlerName` Attribute</h2>
        <p>
          By default, Razor Page handlers are mapped based on the HTTP verb (e.g., `OnGet()`, `OnPost()`). However, you can use the `HandlerName` attribute to map a custom method to a specific handler name, allowing for more flexibility.
        </p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    [BindProperty]
    public string Name { get; set; }

    [HttpPost("submit-name")]
    public IActionResult OnPostSubmitName()
    {
        // Logic to handle form submission
        return RedirectToPage("/Success");
    }
}`}
        </pre>

        <p>
          In this case, the `OnPostSubmitName()` method is mapped to a custom handler using the `HttpPost` attribute, allowing the method to handle POST requests to `/submit-name`.
        </p><br />
    
        <h2>6. Using Handlers with Ajax (Partial Requests)</h2>
        <p>
          You can also use Razor Page handlers to handle AJAX requests, allowing you to update part of a page without reloading the entire page. In such cases, handlers are typically asynchronous and return partial views or JSON data.
        </p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    public JsonResult OnGetFetchData()
    {
        var data = new { Name = "John", Age = 30 };
        return new JsonResult(data);
    }
}`}
        </pre>

        <p>
          In this example, the `OnGetFetchData()` method returns a JSON result, which can be fetched via an AJAX request to update parts of the page dynamically.
        </p><br />
      
        <h2>7. Common Razor Page Handlers</h2>
        <p>
          Here are some common Razor Page handlers:
        </p>
        <ul>
          <li><strong>OnGet:</strong> Handles HTTP GET requests, often used for retrieving data and rendering the page.</li><br />
          <li><strong>OnPost:</strong> Handles HTTP POST requests, typically used for handling form submissions.</li><br />
          <li><strong>OnPut:</strong> Handles HTTP PUT requests, useful for updating existing data.</li><br />
          <li><strong>OnDelete:</strong> Handles HTTP DELETE requests, typically used for removing data.</li>
        </ul><br />
      
        <h2>8. Summary</h2>
        <p>
          Razor Page Handlers provide a simple and efficient way to manage HTTP request logic in a page-centric manner. By defining methods for different HTTP verbs, such as `OnGet()`, `OnPost()`, and custom handlers, Razor Pages allow for easy interaction with page data, form submissions, and AJAX calls.
        </p>
      


  </div>
)}



{selectedChapter === 'chapter30' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Data Binding in Razor Pages  </h1>
    
        <p>
          Data binding in Razor Pages refers to the process of connecting data from your application's model to the Razor Page, allowing for the dynamic display of data and handling of user input. Razor Pages uses a variety of binding techniques to connect the view and model, making it easier to work with form data, display data, and update model properties.
        </p><br />
     
        <h2>1. What is Data Binding in Razor Pages?</h2>
        <p>
          Data binding in Razor Pages is a powerful feature that helps connect the data from the `PageModel` class to the Razor view. It allows the automatic transfer of data between the model and view, which can be particularly useful when working with forms, displaying data, and responding to user input.
        </p>
        <p>
          Data binding can be classified into two main types:
        </p>
        <ul>
          <li><strong>Model Binding:</strong> Automatically binds form values to properties in the `PageModel` class.</li><br />
          <li><strong>Display Binding:</strong> Binds data from the model to the view (e.g., showing a list of items in the UI).</li>
        </ul><br />
      

        <h2>2. Model Binding in Razor Pages</h2>
        <p>
          Model binding is a feature in Razor Pages that automatically maps form input fields to properties in the `PageModel` class. When a user submits a form, the values from the form fields are bound to the properties of the `PageModel` that match the field names.
        </p>
        <p>
          Here’s an example of model binding with a form in Razor Pages:
        </p>

        <pre className={style.codeBlock}>
{`@page
@model MyPageModel

<form method="post">
    <label for="name">Name:</label>
    <input type="text" id="name" asp-for="Name" />
    
    <label for="age">Age:</label>
    <input type="number" id="age" asp-for="Age" />
    
    <button type="submit">Submit</button>
</form>`}
        </pre>

        <p>
          In this example, the `asp-for` attribute binds the form fields to the `Name` and `Age` properties of the `PageModel`. When the form is submitted, these properties will automatically be populated with the values from the form inputs.
        </p><br />
      
        <h2>3. Displaying Data with Data Binding</h2>
        <p>
          Display binding is used to display data from the `PageModel` to the Razor view. You can bind properties in the model directly to the HTML view, allowing data to be dynamically shown in the page.
        </p>
        <p>
          Here’s an example of displaying data in a Razor Page:
        </p>

        <pre className={style.codeBlock}>
{`@page
@model MyPageModel

<h2>@Model.Name</h2>
<p>Age: @Model.Age</p>`}
        </pre>

        <p>
          In this example, the `Name` and `Age` properties from the `PageModel` are displayed on the page using `@Model.PropertyName`. When the page is rendered, the values from the `PageModel` will be injected into the HTML.
        </p><br />
    
        <h2>4. Using `BindProperty` for Model Binding</h2>
        <p>
          The `BindProperty` attribute can be used to explicitly bind model properties to form fields in Razor Pages. By using this attribute, you can bind properties for both GET and POST requests, making it easier to manage form submissions and data persistence.
        </p>
        <p>
          Here’s an example of how to use `BindProperty`:
        </p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    [BindProperty]
    public string Name { get; set; }

    [BindProperty]
    public int Age { get; set; }

    public void OnPost()
    {
        // Logic to process form data
    }
}`}
        </pre>

        <p>
          The `BindProperty` attribute allows Razor Pages to automatically bind the `Name` and `Age` properties to the form fields in the view.
        </p><br />
   
        <h2>5. Using `ModelState` for Validation</h2>
        <p>
          You can use `ModelState` for validating data submitted through forms. The `ModelState` dictionary tracks whether the submitted data is valid according to the validation rules defined in the `PageModel`.
        </p>
        <p>
          Example of using `ModelState` for form validation:
        </p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    [BindProperty]
    [Required]
    public string Name { get; set; }

    [BindProperty]
    [Range(1, 100)]
    public int Age { get; set; }

    public void OnPost()
    {
        if (!ModelState.IsValid)
        {
            // Handle validation errors
        }
        else
        {
            // Process valid form data
        }
    }
}`}
        </pre>

        <p>
          In this example, the `Name` property is required, and the `Age` property must be between 1 and 100. If the form data does not meet these requirements, the `ModelState.IsValid` property will return `false`, and the page can handle the validation errors accordingly.
        </p><br />
     
        <h2>6. Two-Way Data Binding</h2>
        <p>
          Razor Pages support two-way data binding, allowing the changes made to the UI to be reflected in the model and vice versa. This is particularly useful for forms where the user can interact with the input fields and submit the form with the updated values.
        </p>
        <p>
          Here’s an example of two-way data binding in Razor Pages:
        </p>

        <pre className={style.codeBlock}>
{`@page
@model MyPageModel

<form method="post">
    <label for="name">Name:</label>
    <input type="text" id="name" asp-for="Name" value="@Model.Name" />
    
    <label for="age">Age:</label>
    <input type="number" id="age" asp-for="Age" value="@Model.Age" />
    
    <button type="submit">Submit</button>
</form>`}
        </pre>

        <p>
          In this example, the `asp-for` attribute binds the input fields to the `Name` and `Age` properties. As the user interacts with the fields, the values will be updated in the model and sent when the form is submitted.
        </p><br />
     
        <h2>7. Summary</h2>
        <p>
          Data binding in Razor Pages allows for easy interaction between the view and model. It automates the process of transferring data from form fields to the model and vice versa. By using techniques like model binding, display binding, `BindProperty`, and validation with `ModelState`, you can efficiently handle user input and display data in Razor Pages.
        </p>
 



  </div>
)}




{selectedChapter === 'chapter31' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Building Forms and Handling POST Requests </h1>

        <p>
          In ASP.NET Razor Pages, building forms and handling POST requests is a core part of building interactive web applications. Forms allow users to send data to the server, which can be processed or stored. Razor Pages simplifies working with forms and handling POST requests by using built-in model binding and data annotations.
        </p><br />
   
        <h2>1. Creating a Basic Form in Razor Pages</h2>
        <p>
          The most basic form in Razor Pages involves creating a form in the Razor view and binding it to properties in the `PageModel` class. The `asp-for` tag helper is used to bind form fields to model properties. When the form is submitted, the data is posted to the server, where it can be processed.
        </p>
        <p>Here’s an example of a simple form:</p>

        <pre className={style.codeBlock}>
{`@page
@model MyPageModel

<form method="post">
    <label for="name">Name:</label>
    <input type="text" id="name" asp-for="Name" />
    
    <label for="email">Email:</label>
    <input type="email" id="email" asp-for="Email" />
    
    <button type="submit">Submit</button>
</form>`}
        </pre>

        <p>
          In this form, the `asp-for` tag helpers bind the form inputs to the `Name` and `Email` properties in the `PageModel`. When the form is submitted, the values from the form will be automatically mapped to the properties.
        </p><br />
     
        <h2>2. Handling POST Requests in Razor Pages</h2>
        <p>
          To handle POST requests in Razor Pages, you need to define an `OnPost` method in the `PageModel` class. This method is executed when the form is submitted using the POST method. You can then process the submitted data in this method.
        </p>
        <p>Here’s an example of handling a POST request:</p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    [BindProperty]
    public string Name { get; set; }

    [BindProperty]
    public string Email { get; set; }

    public void OnPost()
    {
        // Handle form submission
        // For example, save data to a database
        // Redirect or return a result
    }
}`}
        </pre>

        <p>
          In the above code, the `OnPost` method is called when the form is submitted. The `Name` and `Email` properties are bound to the form fields using the `[BindProperty]` attribute. The method can process the data, such as saving it to a database or performing validation, before sending a response back to the user.
        </p><br />
     
        <h2>3. Validating Form Data</h2>
        <p>
          Validating form data before processing it is crucial to ensure that only valid data is submitted to the server. You can validate form fields in Razor Pages using data annotations, which provide a simple way to specify validation rules.
        </p>
        <p>Here’s an example with validation annotations:</p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    [BindProperty]
    [Required]
    public string Name { get; set; }

    [BindProperty]
    [EmailAddress]
    public string Email { get; set; }

    public void OnPost()
    {
        if (!ModelState.IsValid)
        {
            // Handle invalid data
        }
        else
        {
            // Process valid data
        }
    }
}`}
        </pre>

        <p>
          In this example, the `Name` property is required, and the `Email` property must be a valid email address. If the form data does not meet these requirements, the `ModelState.IsValid` property will return `false`, indicating that the data is invalid. You can then display validation messages in the view or handle the invalid data as needed.
        </p><br />
     
        <h2>4. Redirecting After a Successful POST</h2>
        <p>
          After successfully handling a POST request, it's a common practice to redirect the user to another page or reload the current page to reflect the changes. This prevents the user from resubmitting the form if they refresh the page.
        </p>
        <p>Here’s how to redirect after a successful form submission:</p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    [BindProperty]
    public string Name { get; set; }

    [BindProperty]
    public string Email { get; set; }

    public IActionResult OnPost()
    {
        if (ModelState.IsValid)
        {
            // Process form data
            return RedirectToPage("Success"); // Redirect to a success page
        }

        return Page(); // Return the current page if validation fails
    }
}`}
        </pre>

        <p>
          In this example, after processing the form data, we redirect the user to a "Success" page if the form is valid. If the validation fails, the page is re-rendered with the error messages.
        </p><br />
     
        <h2>5. Handling File Uploads in Razor Pages</h2>
        <p>
          Razor Pages also allows handling file uploads. To upload files, you need to use a file input field in the form and bind it to a property in the `PageModel`. You can then handle the uploaded file in the `OnPost` method.
        </p>
        <p>Here’s an example of handling file uploads:</p>

        <pre className={style.codeBlock}>
{`@page
@model MyPageModel

<form method="post" enctype="multipart/form-data">
    <label for="fileUpload">Upload File:</label>
    <input type="file" id="fileUpload" asp-for="File" />
    
    <button type="submit">Submit</button>
</form>`}
        </pre>

        <p>
          In the `PageModel`, you can use a property of type `IFormFile` to handle the uploaded file:
        </p>

        <pre className={style.codeBlock}>
{`public class MyPageModel : PageModel
{
    [BindProperty]
    public IFormFile File { get; set; }

    public async Task OnPostAsync()
    {
        if (File != null && File.Length > 0)
        {
            var filePath = Path.Combine("Uploads", File.FileName);
            using (var stream = new FileStream(filePath, FileMode.Create))
            {
                await File.CopyToAsync(stream);
            }
        }
    }
}`}
        </pre>

        <p>
          In this example, the file is uploaded and saved to the "Uploads" directory on the server. The `OnPostAsync` method handles the file asynchronously to avoid blocking the main thread.
        </p><br />
     
        <h2>6. Summary</h2>
        <p>
          In Razor Pages, building forms and handling POST requests is straightforward using model binding and the `OnPost` method. By validating form data, handling redirects, and processing uploaded files, you can create rich, interactive forms in your Razor Pages applications.
        </p>
     

  </div>
)}


{selectedChapter === 'chapter32' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to REST and Web API </h1>

        <p>
          Web APIs (Application Programming Interfaces) are the backbone of modern web development, enabling communication between different software applications. REST (Representational State Transfer) is an architectural style that guides the design of Web APIs, making them stateless, scalable, and easy to interact with over the web.
        </p><br />
   
        <h2>1. What is REST?</h2>
        <p>
          REST is an architectural style for building web services that interact with clients via HTTP. It is based on a set of constraints that make APIs scalable, maintainable, and easy to use. RESTful APIs are stateless, meaning each request from a client must contain all the information needed to process the request, and the server doesn’t store any session information.
        </p>
        <ul>
          <li><strong>Statelessness:</strong> Each request is independent, with no context stored by the server.</li><br />
          <li><strong>Client-Server Architecture:</strong> Clients and servers are independent, with clients consuming the resources exposed by the server.</li><br />
          <li><strong>Uniform Interface:</strong> A uniform interface simplifies and decouples the architecture, making it easier to build and maintain.</li><br />
          <li><strong>Cacheable:</strong> Responses must explicitly define whether they are cacheable to improve performance.</li>
        </ul><br />
      
        <h2>2. What is a Web API?</h2>
        <p>
          A Web API is a set of HTTP-based endpoints that allow different software systems to communicate over the internet. Web APIs use standard HTTP methods such as GET, POST, PUT, and DELETE to perform CRUD (Create, Read, Update, Delete) operations on data. They typically return data in formats like JSON or XML.
        </p>
        <p>Here’s a basic overview of HTTP methods commonly used in Web APIs:</p>
        <ul>
          <li><strong>GET:</strong> Retrieves data from the server.</li><br />
          <li><strong>POST:</strong> Submits data to the server to create a new resource.</li><br />
          <li><strong>PUT:</strong> Updates an existing resource on the server.</li><br />
          <li><strong>DELETE:</strong> Removes a resource from the server.</li>
        </ul><br />
    
        <h2>3. RESTful Web API in ASP.NET Core</h2>
        <p>
          In ASP.NET Core, Web APIs are built using the MVC pattern with controllers. You can create RESTful APIs using the `ApiController` attribute, which simplifies routing and model binding. The controller methods correspond to HTTP verbs (GET, POST, PUT, DELETE) to handle the operations on the resources.
        </p>
        <p>Here’s an example of a simple Web API in ASP.NET Core:</p>

        <pre className={style.codeBlock}>
{`[ApiController]
[Route("api/[controller]")]
public class ProductsController : ControllerBase
{
    private static List<Product> products = new List<Product>
    {
        new Product { Id = 1, Name = "Product 1" },
        new Product { Id = 2, Name = "Product 2" }
    };

    // GET: api/products
    [HttpGet]
    public ActionResult<IEnumerable<Product>> Get()
    {
        return Ok(products);
    }

    // POST: api/products
    [HttpPost]
    public ActionResult<Product> Post([FromBody] Product product)
    {
        products.Add(product);
        return CreatedAtAction(nameof(Get), new { id = product.Id }, product);
    }

    // PUT: api/products/{id}
    [HttpPut("{id}")]
    public IActionResult Put(int id, [FromBody] Product product)
    {
        var existingProduct = products.FirstOrDefault(p => p.Id == id);
        if (existingProduct == null) return NotFound();

        existingProduct.Name = product.Name;
        return NoContent();
    }

    // DELETE: api/products/{id}
    [HttpDelete("{id}")]
    public IActionResult Delete(int id)
    {
        var product = products.FirstOrDefault(p => p.Id == id);
        if (product == null) return NotFound();

        products.Remove(product);
        return NoContent();
    }
}`}
        </pre>

        <p>
          In this example:
          <ul>
            <li>The `GET` method retrieves the list of products.</li><br />
            <li>The `POST` method allows clients to create a new product.</li><br />
            <li>The `PUT` method updates an existing product.</li><br />
            <li>The `DELETE` method removes a product from the list.</li>
          </ul>
        </p><br />


        <h2>4. JSON Format for Web APIs</h2>
        <p>
          Most Web APIs use JSON (JavaScript Object Notation) to represent the data being transferred between the server and the client. JSON is lightweight, easy to parse, and widely supported by programming languages and frameworks.
        </p>
        <p>An example of a JSON response from the API might look like this:</p>

        <pre className={style.codeBlock}>
{`{
  "id": 1,
  "name": "Product 1"
}`}
        </pre>

        <p>In the above example, the API returns a JSON object containing the product's `id` and `name` properties.</p><br />
     
        <h2 style={{paddingBottom:"6px"}}>5. Benefits of RESTful Web APIs</h2>
        <ul>
          <li><strong>Scalability:</strong> The stateless nature of REST APIs allows them to scale horizontally by adding more servers to handle requests.</li><br />
          <li><strong>Separation of Concerns:</strong> Clients and servers are independent, meaning that changes in the client or server can be made without affecting the other.</li><br />
          <li><strong>Performance:</strong> REST APIs are optimized for performance by using caching and HTTP’s built-in mechanisms.</li><br />
          <li><strong>Simplicity:</strong> REST APIs are simple to use and can be easily integrated with a wide range of clients, such as browsers, mobile devices, and other servers.</li>
        </ul>
      
        <h2>6. Summary</h2>
        <p>
          RESTful Web APIs are a powerful tool for enabling communication between software systems over the web. They are simple, scalable, and widely used in modern web development. By following REST principles, developers can build APIs that are easy to understand and maintain. ASP.NET Core makes it easy to create RESTful Web APIs using controllers and routing, allowing you to expose your application's resources to the world in a standard and efficient manner.
        </p>
      


  </div>
)}


{selectedChapter === 'chapter33' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building Web APIs in ASP.NET Core</h1>
    

      <p>
        ASP.NET Core is a cross-platform, high-performance framework designed to build modern, cloud-based, and internet-connected applications. One of its key capabilities is enabling the creation of robust and scalable <strong>Web APIs</strong>. ASP.NET Core simplifies the development of Web APIs by providing a well-structured pipeline, dependency injection, and seamless integration with modern web technologies.
      </p><br />
  
      <h2 style={{paddingBottom:"6px"}} >Key Concepts</h2>

      <h3>1. What is a Web API?</h3>
      <ul>
        <li>A Web API is a service exposed over HTTP to enable communication between client and server applications.</li><br />
        <li>ASP.NET Core Web APIs typically return data in <strong>JSON</strong> or <strong>XML</strong> format, making them ideal for RESTful services.</li><br />
        <li>Use cases include:
          <ul>
            <li>Mobile app backends</li><br />
            <li>Third-party integrations</li><br />
            <li>Microservices communication</li>
          </ul>
        </li>
      </ul><br />

      <h3 style={{paddingBottom:"6px"}}>2. Essential Components of ASP.NET Core Web API</h3>
      <ul>
        <li><strong>Controllers:</strong> Handle incoming HTTP requests and send responses.</li><br />
        <li><strong>Routing:</strong> Maps URLs to specific endpoints in the application.</li><br />
        <li><strong>Model Binding and Validation:</strong> Maps data from HTTP requests to C# objects.</li><br />
        <li><strong>Filters:</strong> Implement cross-cutting concerns like logging, authentication, and error handling.</li><br />
        <li><strong>Dependency Injection:</strong> Provides a clean way to manage service lifetimes and dependencies.</li><br />
        <li><strong>Middleware:</strong> Intercepts and processes HTTP requests and responses.</li>
      </ul><br />
   
      <h2 style={{paddingBottom:"6px"}}>Steps to Build a Web API</h2>

      <h3>Step 1: Create a New ASP.NET Core Web API Project</h3>
      <pre>
        <code>{`
dotnet new webapi -n MyWebAPI
         `} </code>
      </pre><br />

      <h3>Step 2: Define Models</h3>
      <pre>
        <code>{`
public class Product 
{ 
    public int Id { get; set; } 
    public string Name { get; set; } 
    public decimal Price { get; set; } 
}
         `} </code>
      </pre><br />

      <h3>Step 3: Create a Controller</h3>
      <pre>
        <code>{`
[ApiController]
[Route("api/[controller]")]
public class ProductsController : ControllerBase
{
    private static List<Product> products = new()
    {
        new Product { Id = 1, Name = "Laptop", Price = 1200 },
        new Product { Id = 2, Name = "Phone", Price = 800 }
    };

    // GET: api/products
    [HttpGet]
    public IEnumerable<Product> Get() => products;

    // GET: api/products/1
    [HttpGet("{id}")]
    public IActionResult Get(int id)
    {
        var product = products.FirstOrDefault(p => p.Id == id);
        if (product == null)
            return NotFound();
        return Ok(product);
    }

    // POST: api/products
    [HttpPost]
    public IActionResult Post([FromBody] Product product)
    {
        products.Add(product);
        return CreatedAtAction(nameof(Get), new { id = product.Id }, product);
    }
}
         `} </code>
      </pre><br />

      <h3>Step 4: Configure Services in <code>Startup.cs</code></h3>
      <pre>
        <code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddControllers();
}
 `} </code>
      </pre><br />

      <h3>Step 5: Configure the Request Pipeline</h3>
      <pre>
        <code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage();
    }

    app.UseRouting();
    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllers();
    });
}
 `} </code>
      </pre><br />


   
      <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
      <ul>
        <li><strong>Use Dependency Injection:</strong> Decouple business logic from controllers by injecting services.</li><br />
        <li><strong>Validate Input:</strong> Use data annotations or Fluent Validation to ensure data integrity.</li><br />
        <li><strong>Implement Versioning:</strong> Use API versioning for backward compatibility.</li><br />
        <li><strong>Enable CORS:</strong> Configure Cross-Origin Resource Sharing for external API consumption.</li><br />
        <li><strong>Handle Errors Gracefully:</strong> Implement middleware to capture and log errors.</li>
      </ul><br />
   
      <h2>Conclusion</h2>
      <p>
        Building Web APIs in ASP.NET Core is straightforward, with its rich framework providing tools for creating scalable and maintainable APIs. By following best practices and leveraging built-in features, developers can deliver high-performance APIs that cater to diverse client applications, including web, mobile, and IoT.
      </p>

  </div>
)}



{selectedChapter === 'chapter34' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>HTTP Verbs (GET, POST, PUT, DELETE)</h1>
  
      
      <p>
        HTTP Verbs define the type of action to be performed on a resource. ASP.NET Web API uses HTTP verbs like
        <strong>GET</strong>, <strong>POST</strong>, <strong>PUT</strong>, and <strong>DELETE</strong> to map client
        requests to server-side operations. These verbs enable RESTful communication, allowing developers to create APIs
        that conform to standard HTTP conventions.
      </p><br />
   

   

    
      <h3 style={{paddingBottom:"6px"}}>Key HTTP Verbs</h3>

      <h4 style={{paddingBottom:"6px"}}>1. GET</h4>
      <ul>
        <li>Retrieves data from the server.</li><br />
        <li>Typically used for <strong>read-only operations</strong>.</li>
      </ul>
      <pre>
        <code>
          {`[HttpGet]
public IEnumerable<Product> GetProducts()
{
    return products;
}`}
        </code>
      </pre><br />

      <h4 style={{paddingBottom:"6px"}}>2. POST</h4>
      <ul>
        <li>Sends data to the server to create a new resource.</li>
        <li>Used for <strong>create operations</strong>.</li>
      </ul>
      <pre>
        <code>
          {`[HttpPost]
public IActionResult CreateProduct([FromBody] Product product)
{
    products.Add(product);
    return CreatedAtAction(nameof(GetProduct), new { id = product.Id }, product);
}`}
        </code>
      </pre><br />

      <h4 style={{paddingBottom:"6px"}}>3. PUT</h4>
      <ul>
        <li>Updates an existing resource on the server.</li><br />
        <li>Used for <strong>update operations</strong>.</li>
      </ul>
      <pre>
        <code>
          {`[HttpPut("{id}")]
public IActionResult UpdateProduct(int id, [FromBody] Product updatedProduct)
{
    var product = products.FirstOrDefault(p => p.Id == id);
    if (product == null) return NotFound();

    product.Name = updatedProduct.Name;
    product.Price = updatedProduct.Price;
    return NoContent();
}`}
        </code>
      </pre><br />

      <h4 style={{paddingBottom:"6px"}}>4. DELETE</h4>
      <ul>
        <li>Removes a resource from the server.</li><br />
        <li>Used for <strong>delete operations</strong>.</li>
      </ul>
      <pre>
        <code>
          {`[HttpDelete("{id}")]
public IActionResult DeleteProduct(int id)
{
    var product = products.FirstOrDefault(p => p.Id == id);
    if (product == null) return NotFound();

    products.Remove(product);
    return NoContent();
}`}
        </code>
      </pre><br />
 

      <h3 style={{paddingBottom:"6px"}}>Mapping HTTP Verbs in ASP.NET Web API</h3>
      <ol>
        <li>
          <strong>Routing:</strong> Use attributes like <code>[HttpGet]</code>, <code>[HttpPost]</code>, <code>[HttpPut]</code>, and
          <code>[HttpDelete]</code> for clear mapping.
          <pre>
            <code>
              {`[ApiController]
[Route("api/products")]
public class ProductsController : ControllerBase
{
    // GET, POST, PUT, DELETE methods here
}`}
            </code>
          </pre>
        </li>
        <li>
          <strong>Default Routing:</strong> ASP.NET Core allows implicit routing based on method names (e.g., <code>Get</code>,
          <code>Post</code>).
        </li><br />
        <li>
          <strong>Action Constraints:</strong> Explicitly define HTTP verbs using <code>[HttpGet]</code>, <code>[HttpPost]</code>,
          etc., for better readability and maintainability.
        </li>
      </ol><br />
  

      <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
      <ul>
        <li>
          <strong>Idempotency:</strong> Ensure that PUT and DELETE operations are idempotent (multiple requests produce the same
          result).
        </li><br />
        <li>
          <strong>Status Codes:</strong> Use appropriate HTTP status codes (e.g., <code>200 OK</code>, <code>201 Created</code>, <code>404 Not Found</code>, <code>204 No Content</code>).
        </li><br />
        <li>
          <strong>Validation:</strong> Validate input data to prevent errors.
        </li><br />
        <li>
          <strong>Error Handling:</strong> Implement middleware for consistent error responses.
        </li><br />
        <li>
          <strong>Versioning:</strong> Maintain version control for API endpoints.
        </li>
      </ul><br />
 
      <h3>Testing the HTTP Verbs</h3>
      <p>Use <strong>Postman</strong> or <strong>curl</strong> to test API endpoints.</p>
      <pre>
        <code>
          {`# GET
curl -X GET https://localhost:5001/api/products

# POST
curl -X POST -H "Content-Type: application/json" -d '{"name":"Tablet", "price":500}' https://localhost:5001/api/products

# PUT
curl -X PUT -H "Content-Type: application/json" -d '{"name":"Updated Tablet", "price":550}' https://localhost:5001/api/products/1

# DELETE
curl -X DELETE https://localhost:5001/api/products/1`}
        </code>
      </pre><br />
    


      <h3>Conclusion</h3>
      <p>
        Understanding HTTP verbs is crucial for designing RESTful APIs that follow web standards. By properly implementing these
        verbs in ASP.NET Web API, developers can build efficient, scalable, and maintainable applications that meet diverse client
        needs.
      </p>

  </div>
)}


{selectedChapter === 'chapter35' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Routing in Web APIs</h1>
    
  
      <p>
        Routing is a fundamental concept in ASP.NET Web API, responsible for mapping incoming HTTP requests to the appropriate controller actions. It defines how the URL pattern and HTTP verbs (GET, POST, PUT, DELETE) interact with the controller and its methods. By configuring routing, developers can design APIs that are clean, intuitive, and RESTful.
      </p><br />

      <h2 style={{paddingBottom:"6px"}}>Key Concepts in Routing</h2>
      
      <h3>What is Routing?</h3>
      <p>
        Routing is the mechanism that directs HTTP requests to specific controller actions based on the URL and HTTP verb. It determines how URLs are structured and how they map to controller actions, making it a core component of ASP.NET Web API’s MVC (Model-View-Controller) architecture.
      </p><br />

      <h3>Routing in ASP.NET Web API</h3>
      <p>
        By default, ASP.NET Web API uses convention-based routing, which follows a URL pattern to route requests to the controller. The default routing format is:
      </p>
      <pre>
        /api/[controller]/[action]/[id]
      </pre>
      <p>
        For example, the request <code>GET /api/products</code> would map to the <code>Get</code> method of the <code>ProductsController</code>.
      </p><br />

      <h3>Custom Routing</h3>
      <p>
        In addition to default routing, developers can define custom routes to provide more control over URL structure and routing behavior. Custom routes can be specified using the <code>[Route]</code> attribute on controllers or actions.
      </p><br />
   
      <h2 style={{paddingBottom:"6px"}}>Configuring Routing</h2>
      
      <h3>Default Routing</h3>
      <p>
        By default, ASP.NET Web API is configured to use attribute routing or convention-based routing (defined in <code>WebApiConfig.cs</code>).
      </p>
      <pre><code>{`
        public static void Register(HttpConfiguration config) {'\n'}
        {' '}config.MapHttpAttributeRoutes(); {'\n'}
        {' '}config.Routes.MapHttpRoute( {'\n'}
        {' '}name: "DefaultApi", {'\n'}
        {' '}routeTemplate: "api/{controller}/{id}", {'\n'}
        {' '}defaults: new { id = RouteParameter.Optional } {'\n'}
        ); {'\n'}
      `}</code></pre><br />

      <h3>Attribute Routing</h3>
      <p>
        Attribute Routing provides more flexibility by allowing developers to specify routes directly on controller actions.
      </p>
      <pre><code>{`
        [Route("api/products/{id}")] {'\n'}
        public IHttpActionResult GetProduct(int id) {'\n'}
        {' '}var product = db.Products.FirstOrDefault(p => p.Id == id); {'\n'}
        {' '}if (product == null) {'\n'}
        {' '} {' '}return NotFound(); {'\n'}
        {' '}return Ok(product); {'\n'}
      `}</code></pre><br />

      <h3>Route Parameters</h3>
      <p>
        Route parameters are placeholders in the URL that are replaced with actual values from the request.
      </p>
      <pre><code>{`
        [Route("api/products/{id}")] {'\n'}
        public IHttpActionResult GetProductById(int id) {'\n'}
        {' '}// Retrieve and return the product by ID
      `}</code></pre><br />

      <h3>Optional Parameters</h3>
      <p>
        Parameters in routes can be optional, meaning they are not required for the request to be processed.
      </p>
      <pre><code>{`
        [Route("api/products/{id?}")] {'\n'}
        public IHttpActionResult GetProduct(int? id) {'\n'}
        {' '}if (id == null) {'\n'}
        {' '} {' '}return Ok(products); {'\n'}
        {' '}var product = products.FirstOrDefault(p => p.Id == id); {'\n'}
        {' '}return product == null ? NotFound() : Ok(product); {'\n'}
       `} </code></pre> <br />
 
      <h2 style={{paddingBottom:"6px"}}>Best Practices in Routing</h2>
      
      <h3>Consistency in URL Structure</h3>
      <p>
        Use consistent and meaningful URL patterns to make APIs predictable and easy to understand. For example, always use plural nouns for resource names (e.g., <code>/api/products</code> instead of <code>/api/product</code>).
      </p><br />

      <h3>RESTful URLs</h3>
      <p>
        Follow REST principles by using HTTP verbs (GET, POST, PUT, DELETE) appropriately with the correct routes:
      </p>
      <ul>
        <li><code>GET /api/products</code> – Retrieve a list of products.</li><br />
        <li><code>{`GET /api/products/{id}`}</code> – Retrieve a specific product by ID.</li><br />
        <li><code>POST /api/products</code> – Create a new product.</li><br />
        <li><code>{`PUT /api/products/{id}`}</code> – Update an existing product.</li><br />
        <li><code>{`DELETE /api/products/{id}`}</code> – Delete a product.</li>
      </ul><br />

      <h3>Avoiding Over-Complicating Routes</h3>
      <p>
        Keep URLs simple and readable. Avoid unnecessary complexities or long URL structures that could confuse developers or API consumers.
      </p><br />

      <h3>Use of HTTP Status Codes</h3>
      <p>
        Ensure your API correctly uses HTTP status codes to indicate the result of the action (e.g., <code>200 OK</code>, <code>201 Created</code>, <code>404 Not Found</code>).
      </p><br />

   
      <h2 style={{paddingBottom:"6px"}}>Testing Routing</h2>
      
      <h3>Use Tools like Postman or Swagger UI</h3>
      <p>
        Tools like Postman can be used to test the various routes and ensure they are functioning as expected. For example, you can send a <code>GET</code> request to <code>/api/products/1</code> to retrieve the product with ID 1.
      </p><br />

      <h3>Unit Testing</h3>
      <p>
        Write unit tests for controllers and actions to verify that routes are being mapped correctly.
      </p>
      <pre><code>{`
        [Fact] {'\n'}
        public void TestGetProductById() {'\n'}
        {' '}var controller = new ProductsController(); {'\n'}
        {' '}var result = controller.GetProductById(1); {'\n'}
        {' '}Assert.IsType<OkResult>(result); {'\n'}
       `} </code></pre><br />
  
      <h2>Conclusion</h2>
      <p>
        Routing is essential for any ASP.NET Web API, enabling developers to map HTTP requests to appropriate controller actions. By utilizing default and custom routing techniques, adhering to RESTful practices, and using tools for testing, developers can build efficient and intuitive APIs that meet modern web standards.
      </p>
   
  </div>
)}

{selectedChapter === 'chapter36' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Model Binding and Validation in Web APIs</h1>

    <p>
      Model Binding and Validation are key features in ASP.NET Web APIs, enabling smooth 
      data transfer between the client and server while ensuring the integrity and validity 
      of the data.
    </p>

  <br />

    <h3>1. What is Model Binding?</h3>
    <p>
      Model Binding is the process of converting HTTP request data (query strings, form data, 
      route data, etc.) into objects defined in your application.
    </p>
    <ul>
      <li>
        <strong>Example:</strong> If a client sends JSON data, Model Binding maps it to a 
        corresponding C# object.
      </li>
    </ul>

  <br />

    <h3>2. Understanding the Workflow</h3>
    <ol>
      <li><strong>Incoming Request Data:</strong> Data in the request (query string, headers, body) is parsed.</li><br />
      <li><strong>Binding Logic:</strong> ASP.NET maps this data to the action parameters or model properties.</li><br />
      <li><strong>Validation:</strong> If validation attributes are present on the model, ASP.NET checks them automatically.</li>
    </ol>

 <br />

    <h3 style={{paddingBottom:"6px"}}>3. Configuring Model Binding</h3>

    <h4>Binding from Query String</h4>
    <pre>
      <code>
{`[HttpGet]
public IActionResult GetUserDetails([FromQuery] int userId)
{
    // Automatically binds "userId" from the query string.
    return Ok($"User ID: {userId}");
}`}
      </code>
    </pre><br />

    <h4>Binding from Route Parameters</h4>
    <pre>
      <code>
{`[HttpGet("{id}")]
public IActionResult GetUserById(int id)
{
    // Binds "id" from the route.
    return Ok($"User ID: {id}");
}`}
      </code>
    </pre><br />

    <h4>Binding from Request Body</h4>
    <pre>
      <code>
{`[HttpPost]
public IActionResult CreateUser([FromBody] User user)
{
    // Automatically binds JSON data from the request body to the "User" object.
    if (!ModelState.IsValid)
    {
        return BadRequest(ModelState);
    }
    return Ok($"User Created: {user.Name}");
}`}
      </code>
    </pre>

   <br />

    <h3>4. Data Annotations for Validation</h3>
    <p>ASP.NET Core uses data annotation attributes to enforce validation rules on model properties.</p>
    <ul>
      <li><code>[Required]</code>: Ensures the property has a value.</li><br />
      <li><code>[StringLength]</code>: Sets maximum/minimum length for strings.</li><br />
      <li><code>[Range]</code>: Validates numeric ranges.</li><br />
      <li><code>[EmailAddress]</code>: Validates email format.</li><br />
      <li><code>[RegularExpression]</code>: Validates using regex patterns.</li>
    </ul>

 <br />

    <h3>5. Example: Validating a Model</h3>
    <pre style={{paddingBottom:"6px"}}>
      <code>
{`public class User
{
    [Required(ErrorMessage = "Name is required")]
    [StringLength(50, ErrorMessage = "Name can't exceed 50 characters")]
    public string Name { get; set; }

    [Required]
    [EmailAddress(ErrorMessage = "Invalid email address")]
    public string Email { get; set; }

    [Range(18, 60, ErrorMessage = "Age must be between 18 and 60")]
    public int Age { get; set; }
}`}
      </code>
    </pre>

    <h4>Controller Action</h4>
    <pre>
      <code>
{`[HttpPost]
public IActionResult RegisterUser([FromBody] User user)
{
    if (!ModelState.IsValid)
    {
        return BadRequest(ModelState); // Sends validation errors back to the client.
    }

    return Ok($"User {user.Name} registered successfully!");
}`}
      </code>
    </pre>

   <br />

    <h3 style={{paddingBottom:"6px"}}>6. Custom Validation</h3>
    <h4>Custom Validation Attribute</h4>
    <pre>
      <code>
{`public class MustBeEvenAttribute : ValidationAttribute
{
    protected override ValidationResult IsValid(object value, ValidationContext validationContext)
    {
        if (value is int intValue && intValue % 2 != 0)
        {
            return new ValidationResult("The value must be an even number.");
        }
        return ValidationResult.Success;
    }
}`}
      </code>
    </pre><br />

    <h4>Usage in Model</h4>
    <pre>
      <code>
{`public class Product
{
    [MustBeEven]
    public int ProductCode { get; set; }
}`}
      </code>
    </pre>

    {/* Continue rendering other sections in the same structure */}
  </div>
)}



{selectedChapter === 'chapter37' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Working with JSON and XML in Web APIs</h1>

    <p>
      In ASP.NET Web API, data can be transmitted between the client and server in different formats, with JSON (JavaScript Object Notation) and XML (eXtensible Markup Language) being the most commonly used formats. Web APIs use serialization to convert objects into a specific format, and deserialization to convert the formatted data back into objects. Understanding how to handle JSON and XML data is essential for building robust APIs that can communicate with a wide range of clients and services.
    </p>

  <br />

    <h4 style={{paddingBottom:"6px"}}>Handling JSON in Web APIs</h4>

    <ul>
      <li><strong>Default Format: JSON</strong></li>
      <p>
        By default, ASP.NET Web API uses JSON as the data format for requests and responses. It uses the <code>JsonMediaTypeFormatter</code> to handle serialization and deserialization of objects to and from JSON.
      </p><br />

      <li><strong>Sending JSON Data</strong></li>
      <p>
        To send JSON data to a Web API, simply use the <code>application/json</code> media type in the <code>Content-Type</code> header.
      </p>
      <pre>
        <code>{`{
  "ProductName": "Laptop",
  "Price": 999.99
}`}</code>
      </pre><br />

      <li><strong>Receiving JSON Data</strong></li>
      <p>
        Web API automatically deserializes JSON data into C# objects when the <code>Content-Type</code> header is <code>application/json</code>. Use attributes like <code>[FromBody]</code> to bind the incoming JSON data to the action method parameter.
      </p>
      <pre>
        <code>{`public IHttpActionResult CreateProduct([FromBody] Product product) {
  if (!ModelState.IsValid) {
      return BadRequest(ModelState);
  }
  products.Add(product);
  return CreatedAtAction(nameof(GetProduct), new { id = product.Id }, product);
}`}</code>
      </pre><br />

      <li><strong>Returning JSON Data</strong></li>
      <p>
        Web API automatically serializes C# objects into JSON by default. You can return a response using the <code>Ok()</code> method or any other result type that returns JSON data.
      </p>
      <pre>
        <code>{`public IHttpActionResult GetProduct(int id) {
  var product = products.FirstOrDefault(p => p.Id == id);
  if (product == null) {
      return NotFound();
  }
  return Ok(product);  // Returns product as JSON
}`}</code>
      </pre>
    </ul>

    <br />

    <h4>Handling XML in Web APIs</h4>

    <ul>
      <li><strong>Using XML Format</strong></li>
      <p>
        ASP.NET Web API also supports XML serialization and deserialization. To return XML data, you can either change the <code>Accept</code> header in the client request or configure the server to produce XML by default.
      </p><br />

      <li><strong>Sending XML Data</strong></li>
      <p>
        The client can send XML data by setting the <code>Content-Type</code> header to <code>application/xml</code>.
      </p>
      <pre>
        <code>{`<Product>
  <ProductName>Laptop</ProductName>
  <Price>999.99</Price>
</Product>`}</code>
      </pre><br />

      <li><strong>Receiving XML Data</strong></li>
      <p>
        ASP.NET Web API uses the <code>XmlMediaTypeFormatter</code> to automatically deserialize XML data into C# objects when the request's <code>Content-Type</code> header is <code>application/xml</code>.
      </p>
      <pre>
        <code>{`public IHttpActionResult CreateProduct([FromBody] Product product) {
  if (!ModelState.IsValid) {
      return BadRequest(ModelState);
  }
  products.Add(product);
  return CreatedAtAction(nameof(GetProduct), new { id = product.Id }, product);
}`}</code>
      </pre><br />

      <li><strong>Returning XML Data</strong></li>
      <p>
        To return XML data, the response must be configured with the <code>application/xml</code> media type. This can be done using the <code>Produces</code> attribute or by manually setting the response headers.
      </p>
      <pre>
        <code>{`[HttpGet]
[Produces("application/xml")]
public IHttpActionResult GetProduct(int id) {
  var product = products.FirstOrDefault(p => p.Id == id);
  if (product == null) {
      return NotFound();
  }
  return Ok(product);  // Returns product as XML
}`}</code>
      </pre>
    </ul>

   <br />

    <h4>Configuring Web API for JSON and XML</h4>

    <ul>
      <li><strong>Global Configuration for Supported Formats</strong></li>
      <p>
        You can configure Web API to support both JSON and XML globally by modifying the <code>GlobalConfiguration.Configuration.Formatters</code> collection.
      </p>
      <pre>
        <code>{`public static void Register(HttpConfiguration config) {
  // Add support for JSON and XML formats
  config.Formatters.JsonFormatter.SerializerSettings.ContractResolver = new CamelCasePropertyNamesContractResolver();
  config.Formatters.XmlFormatter.UseXmlSerializer = true;  // Enable XML serialization
}`}</code>
      </pre><br />

      <li><strong>Media Type Formatters</strong></li>
      <p>
        Web API uses formatters to control how data is serialized and deserialized. The <code>JsonMediaTypeFormatter</code> handles JSON, while the <code>XmlMediaTypeFormatter</code> handles XML. You can add, remove, or replace formatters as needed.
      </p>
      <pre>
        <code>{`config.Formatters.Remove(config.Formatters.XmlFormatter);  // Remove XML support`}</code>
      </pre>
    </ul>

   <br />

    <h4>Content Negotiation in Web APIs</h4>

    <ul>
      <li><strong>What is Content Negotiation?</strong></li>
      <p>
        Content negotiation allows the client and server to agree on the format for the response based on the <code>Accept</code> header in the request. Web API automatically selects the appropriate formatter (JSON, XML, etc.) based on the <code>Accept</code> header.
      </p><br />

      <li><strong>Configuring Content Negotiation</strong></li>
      <p>
        Web API uses content negotiation by default. However, you can override it by specifying the format directly in the URL or request headers.
      </p>
      <pre>
        <code>{`GET /api/products?format=json  // Forces JSON format
GET /api/products?format=xml   // Forces XML format`}</code>
      </pre>
    </ul>

    <br />

    <h4 style={{paddingBottom:"6px"}}>Best Practices for Working with JSON and XML</h4>

    <ul>
      <li><strong>Default to JSON</strong></li>
      <p>JSON is lightweight, widely used, and faster to parse compared to XML. It's generally a good practice to default to JSON for API responses.</p><br />

      <li><strong>Validate Input Data</strong></li>
      <p>Regardless of the format (JSON or XML), always validate the incoming data to ensure it is well-formed and conforms to the expected structure.</p><br />

      <li><strong>Error Handling</strong></li>
      <p>When an invalid format is detected (e.g., a malformed JSON or XML payload), return a proper error message with an appropriate status code (e.g., <code>400 Bad Request</code>).</p><br />

      <li><strong>Be Mindful of Serialization Settings</strong></li>
      <p>For JSON, consider settings such as camel casing, date formatting, and ignoring null values. For XML, consider setting options such as indentation and attribute naming conventions.</p>
    </ul>
<br />


    <h4>Testing JSON and XML in Web APIs</h4>

    <ul>
      <li><strong>Using Postman for Testing</strong></li>
      <p>Test the API with Postman by sending requests in both JSON and XML formats. Ensure that the correct data format is returned and that validation errors are handled correctly.</p><br />

      <li><strong>Unit Testing with JSON and XML</strong></li>
      <p>Write unit tests for controller actions to ensure that both JSON and XML responses are returned as expected.</p>
      <pre>
        <code>{`[Fact]
public void TestCreateProduct_JSON() {
  var controller = new ProductsController();
  var product = new Product { Name = "Laptop", Price = 999.99 };
  var result = controller.CreateProduct(product);
  Assert.IsType<CreatedAtActionResult>(result);
}`}</code>
      </pre>
    </ul>

  </div>
)}
{selectedChapter === 'chapter38' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Swagger/OpenAPI Integration for Documentation</h1>

    <p>
      This section provides a detailed explanation of <strong>"Swagger/OpenAPI Integration for Documentation"</strong> under the subject <strong>"ASP.NET Web API Development"</strong>.
    </p>

 <br />

    <h2 style={{paddingBottom:"6px"}}>Swagger/OpenAPI Integration for Documentation</h2>

    <h3>Introduction</h3>
    <p>
      Swagger (now known as OpenAPI) is a powerful framework used for documenting APIs. It enables developers to describe their APIs in a standardized way, making it easier for other developers and tools to interact with the API. In ASP.NET Web API, Swagger can be integrated seamlessly to provide live, interactive documentation, which helps with endpoint testing, understanding request/response formats, and maintaining consistency in API interactions.
    </p>
<br />

    <h3 style={{paddingBottom:"6px"}}>Why Use Swagger/OpenAPI?</h3>
    <ul>
      <li><strong>Interactive Documentation</strong>: Swagger offers an interactive UI where developers can test API endpoints directly from the documentation.</li><br />
      <li><strong>Standardized Format</strong>: OpenAPI Specification (OAS) is a standardized format for describing REST APIs, making it easier to maintain and share API documentation.</li><br />
      <li><strong>Automated API Documentation</strong>: Swagger automates the generation of API documentation, ensuring it stays up-to-date with minimal effort.</li>
    </ul>

   <br />

    <h3>Setting Up Swagger in ASP.NET Web API</h3>
    <p style={{paddingBottom:"6px"}}>To integrate Swagger into your ASP.NET Web API project, follow these steps:</p>

    <h4>1. Install NuGet Packages</h4>
    <p>
      Install the <code>Swashbuckle.AspNetCore</code> package to enable Swagger generation and UI for your API.
    </p>
    <pre>
      <code>Install-Package Swashbuckle.AspNetCore</code>
    </pre><br />

    <h4>2. Configure Swagger in `Startup.cs`</h4>
    <p>
      Add the following configuration in `Startup.cs` to enable Swagger:
    </p>
    <pre>
      <code>{`
        public void ConfigureServices(IServiceCollection services)
        {{
          services.AddSwaggerGen(c =>
          {{
            c.SwaggerDoc("v1", new OpenApiInfo {{ Title = "My API", Version = "v1" }});
          }});
        }}

        public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
        {{
          if (env.IsDevelopment())
          {{
            app.UseSwagger(); // Enable Swagger
            app.UseSwaggerUI(c =>
            {{
              c.SwaggerEndpoint("/swagger/v1/swagger.json", "My API V1");
              c.RoutePrefix = string.Empty; // Set Swagger UI as the default page
            }});
          }}
        }}
`}</code>
    </pre><br />

    <h4>3. Access Swagger UI</h4>
    <p>
      Once configured, run the application and navigate to the following URL to view the interactive API documentation:
    </p>
    <pre>
      <code>http://localhost:5000/swagger</code>
    </pre>

  <br />

    <h3>Customizing Swagger Documentation</h3>
    <p style={{paddingBottom:"6px"}}>
      Swagger allows customization by adding metadata, descriptions, and comments to your API methods and models.
    </p>

    <h4>1. Adding Descriptions to Controllers and Actions</h4>
    <p>
      Use XML comments to provide descriptions and summaries for controllers and actions:
    </p>
    <pre>
      <code>{`
        /// <summary>
        /// Retrieves a list of all products.
        /// </summary>
        /// <returns>A list of products</returns>
        [HttpGet]
        public IEnumerable<Product> Get()
        {{
          return _productService.GetAllProducts();
        }}
`}</code>
    </pre><br />

    <h4>2. Adding Custom Annotations</h4>
    <p>
      Use annotations to describe your API’s behavior and expectations:
    </p>
    <pre>
      <code>{`
        [HttpPost]
        [ProducesResponseType(typeof(Product), 200)]
        [ProducesResponseType(400)]
        public IActionResult CreateProduct([FromBody] Product product)
        {{
          if (!ModelState.IsValid)
            return BadRequest(ModelState);
          _productService.AddProduct(product);
          return CreatedAtAction(nameof(GetProduct), new {{ id = product.Id }}, product);
        }}
 `} </code>
    </pre>

   <br />

    <h3 style={{paddingBottom:"6px"}}>Advanced Swagger Features</h3>

    <h4>1. Authorization and Authentication</h4>
    <p>
      Swagger can support authentication mechanisms like API keys, OAuth2, or JWT. Here's an example of JWT authentication:
    </p>
    <pre>
      <code>{`
        services.AddSwaggerGen(c =>
        {{
          c.AddSecurityDefinition("Bearer", new OpenApiSecurityScheme
          {{
            In = ParameterLocation.Header,
            Description = "Please enter JWT token",
            Name = "Authorization",
            Type = SecuritySchemeType.ApiKey
          }});
          c.AddSecurityRequirement(new OpenApiSecurityRequirement
          {{
            {{
              new OpenApiSecurityScheme
              {{
                Reference = new OpenApiReference
                {{
                  Type = ReferenceType.SecurityScheme,
                  Id = "Bearer"
                }}
              }},
              new string[] {{}} // Empty array means no additional scopes are required
            }}
          }});
        }});
 `} </code>
    </pre><br />

    <h4>2. Customizing Swagger UI</h4>
    <p>
      Customize the appearance and features of Swagger UI, such as enabling search and displaying request duration:
    </p>
    <pre>
      <code>{`
        app.UseSwaggerUI(c =>
        {{
          c.SwaggerEndpoint("/swagger/v1/swagger.json", "My API V1");
          c.EnableFilter();  // Enable search/filtering
          c.DisplayRequestDuration();  // Display request duration time
        }});
 `} </code>
    </pre>

    <br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices for Swagger/OpenAPI</h3>

    <ul>
      <li><strong>Document All Endpoints</strong>: Ensure that every controller action is documented with appropriate summaries and descriptions to make it easier for other developers to understand the API's functionality.</li><br />
      <li><strong>Validate Responses</strong>: Define expected HTTP status codes and response types, including error responses, to ensure that clients handle them effectively.</li><br />
      <li><strong>Use API Versioning</strong>: If you support multiple versions of your API, use versioning in Swagger to document and differentiate between API versions.</li>
    </ul>

    <br />

    <h3>Conclusion</h3>
    <p>
      Integrating Swagger/OpenAPI into your ASP.NET Web API project simplifies documentation and testing of your API. With its interactive interface and automated documentation generation, Swagger enhances the development process by ensuring your API is well-documented, consistent, and easy to use.
    </p>

   <br />

    <p>
      This structure provides a comprehensive guide for Swagger/OpenAPI integration in your Web API documentation. Feel free to ask if you need more details or examples!
    </p>

  </div>
)}



{selectedChapter === 'chapter39' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Securing Web APIs with OAuth2 and JWT Authentication  </h1>

    <p>
      Securing Web APIs is a critical aspect of modern application development. OAuth2 (Open Authorization) and JWT (JSON Web Tokens) are widely used protocols for securing APIs. OAuth2 allows clients to access server resources on behalf of the user, while JWT provides a way to verify the authenticity of requests. In this section, we will explore how to implement OAuth2 and JWT for securing ASP.NET Web APIs.
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Why Use OAuth2 and JWT for API Security?</h2>
    <ul>
    <li><strong>OAuth2 Authorization:</strong> OAuth2 allows you to delegate authorization, enabling third-party applications to securely access your API on behalf of a user.</li> 
   
   <li> <strong>JWT Authentication:</strong>  JSON Web Tokens (JWT) provide a compact, URL-safe means of representing claims to be transferred between two parties, enabling secure authentication.</li> 
    </ul>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Setting Up OAuth2 and JWT Authentication in ASP.NET Web API</h2>

    <h3>Step 1: Install Required NuGet Packages</h3>
    <p>
      Install the following NuGet packages to integrate OAuth2 and JWT in your ASP.NET Web API project:
    </p>
    <pre>
      Install-Package Microsoft.AspNetCore.Authentication.JwtBearer
      Install-Package Microsoft.AspNetCore.Authorization
    </pre><br />

    <h3>Step 2: Configure JWT Authentication in `Startup.cs`</h3>
    <p>
      In the `ConfigureServices` method of your `Startup.cs`, configure JWT Bearer authentication as shown below:
    </p>
    <pre><code>{`
      public void ConfigureServices(IServiceCollection services)
      {
          services.AddAuthentication(JwtBearerDefaults.AuthenticationScheme)
              .AddJwtBearer(options =>
              {
                  options.TokenValidationParameters = new TokenValidationParameters
                  {
                      ValidateIssuer = true,
                      ValidateAudience = true,
                      ValidateLifetime = true,
                      ValidIssuer = "your-issuer",
                      ValidAudience = "your-audience",
                      IssuerSigningKey = new SymmetricSecurityKey(Encoding.UTF8.GetBytes("your-secret-key"))
                  };
              });

          services.AddAuthorization();
          services.AddControllers();
      }
`}</code></pre><br />

    <h3>Step 3: Protect API Endpoints with Authorization</h3>
    <p>
      You can now secure your Web API endpoints by applying the `[Authorize]` attribute:
    </p>
    <pre><code>{`
      [Authorize]
      [HttpGet]
      public IActionResult GetSecretData()
      {
          return Ok("This is a protected resource");
      }
    `}</code></pre>

   <br />

    <h2>OAuth2 Authentication Flow</h2>
    <p>
      OAuth2 follows a series of steps to grant access to resources. Below is an overview of the flow:
    </p>
    <ul>
      <li><strong>Authorization Code Grant Flow</strong>: This is the most common flow for securing web APIs. The client gets an authorization code from the authorization server and exchanges it for an access token.</li><br />
      <li><strong>Implicit Flow</strong>: This flow is used for single-page applications (SPAs) where the client gets an access token directly.</li><br />
      <li><strong>Client Credentials Flow</strong>: This is used for machine-to-machine communication where no user is involved, and the client authenticates itself directly.</li>
    </ul>

   <br />

    <h2>Best Practices for Securing Web APIs</h2>
    <p>
      1. **Use HTTPS**: Always ensure that your API communicates over HTTPS to protect sensitive data in transit.
    </p>
    <p>
      2. **Use Short-Lived Tokens**: JWT tokens should have a short expiration time to minimize the risk of a token being compromised.
    </p>
    <p>
      3. **Refresh Tokens**: Implement refresh tokens to allow users to get a new access token without re-authenticating.
    </p>

   <br />

    <h2>Conclusion</h2>
    <p>
      By integrating OAuth2 and JWT into your ASP.NET Web API, you can ensure secure communication between clients and your server. These protocols offer a robust way to authenticate users and authorize access to sensitive resources. With the implementation steps and best practices outlined in this section, you can start securing your APIs with ease.
    </p>

   <br />

    <p>
      This guide provides an overview of OAuth2 and JWT integration into your ASP.NET Web API project. If you need further assistance or examples, feel free to reach out!
    </p>

  </div>
)}

{selectedChapter === 'chapter40' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>HTTP Context and Session State</h1>

    <p>
      In ASP.NET, state management is an essential aspect of handling user data across multiple requests and interactions. While the HTTP protocol is stateless, ASP.NET provides tools for maintaining state during a user's session. Two fundamental concepts in state management are <strong>HTTP Context</strong> and <strong>Session State</strong>. Both are crucial for building web applications that remember user data and provide a personalized experience.
    </p><br />

    <h3>1. HTTP Context</h3>
    <p style={{paddingBottom:"6px"}}>
      The <strong>HTTP Context</strong> represents all the information related to a single HTTP request. It encapsulates data like the request, response, session, and user-specific data.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Properties:</h4>
    <ul>
      <li><strong>Request:</strong> The incoming request from the client, which includes query strings, form data, cookies, and other request headers.</li><br />
      <li><strong>Response:</strong> The outgoing response to the client, where data can be written, headers can be set, and cookies can be managed.</li><br />
      <li><strong>Session:</strong> Provides access to the session state, which can store user-specific data.</li><br />
      <li><strong>User:</strong> Contains information about the current user, including authentication details and roles.</li><br />
      <li><strong>Items:</strong> A dictionary for storing objects that are specific to the current request (can be used for temporary data storage).</li>
    </ul><br />

    <h4>Example:</h4>
    <pre>
      <code>
        public void ProcessRequest(HttpContext context) {'\n'}
        {'\t'}// Accessing Request {'\n'}
        {'\t'}var userAgent = context.Request.UserAgent; {'\n'}
        {'\t'}// Accessing Response {'\n'}
        {'\t'}context.Response.Write("Hello, World!"); {'\n'}
        {'\t'}// Storing and retrieving data in Items {'\n'}
        {'\t'}context.Items["Key"] = "Value"; {'\n'}
        {'\t'}var value = context.Items["Key"];
      </code>
    </pre><br />

    <h3>2. Session State</h3>
    <p style={{paddingBottom:"6px"}}>
      <strong>Session State</strong> allows data to persist across multiple requests from the same user. It is often used for storing user-specific data like preferences, shopping cart contents, and authentication information.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Session Modes:</h4>
    <ul>
      <li><strong>InProc:</strong> Stores session data in memory on the web server (default).</li><br />
      <li><strong>StateServer:</strong> Stores session data in a separate process (requires a dedicated service).</li><br />
      <li><strong>SQLServer:</strong> Stores session data in a SQL Server database, ideal for web farms or distributed applications.</li><br />
      <li><strong>Custom:</strong> Implement your own storage mechanism for session data.</li>
    </ul><br />

    <h4>Working with Session State:</h4>
    <p style={{paddingBottom:"6px"}}>
      Data in <strong>Session</strong> is stored on the server and is available to the same user during their session (until the session expires or is abandoned). Each session is identified by a unique session ID, which can be stored in cookies or as part of the URL.
    </p>

    <h4>Example:</h4>
    <pre>
      <code>
        // Storing session data {'\n'}
        Session["UserName"] = "JohnDoe"; {'\n'}
        {'\n'}
        // Retrieving session data {'\n'}
        var userName = Session["UserName"] as string; {'\n'}
        {'\n'}
        // Expiring session {'\n'}
        Session.Abandon();
      </code>
    </pre><br />

    <h4>Configuration:</h4>
    <pre>
      <code>
        &lt;system.web&gt; {'\n'}
        {'\t'}&lt;sessionState mode="InProc" timeout="20" /&gt; {'\n'}
        &lt;/system.web&gt;
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>3. Differences between HTTP Context and Session State</h3>
    <ul>
      <li><strong>Scope:</strong> HttpContext is tied to a single HTTP request, while Session is tied to the user's entire session across multiple requests.</li><br />
      <li><strong>Lifetime:</strong> The HttpContext exists only during a single HTTP request and is discarded after the response is sent. The Session state persists throughout the user's session until it expires or is abandoned.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>4. Best Practices</h3>
    <ul>
      <li><strong>Use session sparingly:</strong> Storing large amounts of data in session state can consume server resources. It’s best to store only critical data.</li><br />
      <li><strong>Session expiration:</strong> Configure session timeouts and implement logout mechanisms to ensure sessions are terminated properly.</li><br />
      <li><strong>Consider scalability:</strong> If your application runs in a web farm or cloud environment, consider using SQL Server or a distributed session state provider to store session data.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Understanding and leveraging <strong>HTTP Context</strong> and <strong>Session State</strong> are key for managing user-specific data in an ASP.NET web application. By effectively using these tools, developers can create seamless, personalized experiences for users while maintaining server-side state management.
    </p>
  </div>
)}


{selectedChapter === 'chapter41' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Cookies in ASP.NET</h1>

    <p>
      Cookies are small pieces of data that are stored on the client's browser and sent with each HTTP request. In ASP.NET, cookies are commonly used for storing user preferences, authentication tokens, or any other data that needs to persist across requests. Since cookies are stored on the client side, they are often used for state management in stateless HTTP communication.
    </p>

    <br />

    <h2>What is a Cookie?</h2>
    <p style={{ paddingBottom: '6px' }}>
      A cookie is a small text file that contains data about a user's session or preferences. It is sent from the server to the user's browser and stored on the client side. The cookie is then sent back to the server with subsequent requests, allowing the server to remember information about the user between requests.
    </p>

    <h3>Cookie Format</h3>
    <p>
      A typical cookie consists of a name, value, expiration date, path, domain, and security settings. The browser stores this data and sends it back to the server with each request to the same domain.
    </p>

    <br />

    <h2>Working with Cookies in ASP.NET</h2>
    <p style={{ paddingBottom: '6px' }}>
      ASP.NET provides easy-to-use methods for creating, reading, and deleting cookies. You can work with cookies using the `HttpCookie` class in classic ASP.NET or through the `Request.Cookies` and `Response.Cookies` collections in both ASP.NET and ASP.NET Core.
    </p>

    <h3>Creating and Sending Cookies</h3>
    <p>
      To create and send a cookie in ASP.NET, you can use the `Response.Cookies` collection. Here’s an example of how to set a cookie:
    </p>
    <pre>
      <code>
{`// Creating a cookie
HttpCookie cookie = new HttpCookie("UserName");
cookie.Value = "JohnDoe";
cookie.Expires = DateTime.Now.AddDays(1);  // Set expiration date
Response.Cookies.Add(cookie);`}
      </code>
    </pre>
    <p>
      This code creates a cookie named "UserName" with the value "JohnDoe" and sets it to expire in 1 day.
    </p>

    <br />

    <h3>Reading Cookies</h3>
    <p>
      To read a cookie in ASP.NET, you can use the `Request.Cookies` collection. Here's how to retrieve the value of a cookie:
    </p>
    <pre>
      <code>
{`// Reading a cookie
HttpCookie cookie = Request.Cookies["UserName"];
if (cookie != null)
{
    string userName = cookie.Value;
}`}
      </code>
    </pre>
    <p>
      In this example, we check if the cookie "UserName" exists, and if so, we retrieve its value.
    </p><br />

    <h3>Deleting Cookies</h3>
    <p>
      To delete a cookie, you can set its expiration date to a past date. Here’s an example:
    </p>
    <pre>
      <code>
{`// Deleting a cookie
HttpCookie cookie = new HttpCookie("UserName");
cookie.Expires = DateTime.Now.AddDays(-1);  // Set expiration to a past date
Response.Cookies.Add(cookie);`}
      </code>
    </pre>
    <p>
      By setting the expiration date to a date in the past, the browser will remove the cookie.
    </p>

  <br />

    <h2>Secure Cookies</h2>
    <p>
      Cookies can also be marked as secure, meaning they will only be sent over HTTPS connections. This is important for sensitive information like authentication tokens.
    </p>
    <pre>
      <code>
{`// Creating a secure cookie
HttpCookie cookie = new HttpCookie("AuthToken");
cookie.Value = "secureAuthTokenValue";
cookie.Secure = true;  // Make cookie secure (only sent over HTTPS)
Response.Cookies.Add(cookie);`}
      </code>
    </pre>
    <p style={{paddingBottom:"6px"}}>
      The `Secure` property ensures that the cookie is sent only over secure HTTPS connections.
    </p>

    <h3>Setting HttpOnly Cookies</h3>
    <p>
      To enhance security, cookies can also be marked as `HttpOnly`, which prevents client-side JavaScript from accessing them. This is useful for preventing cross-site scripting (XSS) attacks.
    </p>
    <pre>
      <code>
{`// Creating an HttpOnly cookie
HttpCookie cookie = new HttpCookie("AuthToken");
cookie.Value = "secureAuthTokenValue";
cookie.HttpOnly = true;  // Make cookie accessible only by the server
Response.Cookies.Add(cookie);`}
      </code>
    </pre>
    <p>
      Setting the `HttpOnly` flag makes sure that the cookie can only be accessed by the server and not by client-side JavaScript.
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Using Cookies</h2>
    <p>1. <b>Use Secure Cookies</b>: For sensitive data, always use the `Secure` and `HttpOnly` flags to ensure cookies are sent over secure connections and are protected from client-side JavaScript.</p>
    <p>2. <b>Set Expiration Dates</b>: Always set expiration dates for cookies to prevent them from lingering indefinitely and consuming client-side storage.</p>
    <p>3. <b>Limit Cookie Size</b>: Cookies should be kept small (less than 4KB) to avoid performance issues and excessive network traffic.</p>

    <br />

    <h2>Conclusion</h2>
    <p>
      Cookies are a powerful tool in state management for web applications. In ASP.NET, they provide an easy way to store user-specific information that persists across HTTP requests. By understanding how to create, read, and manage cookies, you can improve user experience and implement features like authentication, user preferences, and more.
    </p>

    <br />

    <p>
      This guide gives an overview of how to work with cookies in ASP.NET. For further questions or examples, feel free to ask!
    </p>
  </div>
)}

{selectedChapter === 'chapter42' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>TempData and ViewData</h1>
    <div className={style.articleContent}>
      <p>
        State management in ASP.NET is an essential concept that involves maintaining user data between HTTP requests. 
        Among the various state management techniques, <strong>TempData</strong> and <strong>ViewData</strong> are key tools 
        provided by ASP.NET MVC for passing data between controllers, views, and actions during a single or subsequent 
        HTTP request.
      </p>

      <br />

      <h2>What is TempData?</h2>
      <p>
        <strong>TempData</strong> is used to pass data between two requests in ASP.NET MVC. It is designed for short-lived 
        data that only needs to persist during the redirection to another action. After being read, TempData is automatically 
        cleared.
      </p>
      <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
      <ul>
        <li><strong>Short-Lived Data</strong>: Automatically cleared once accessed.</li><br />
        <li><strong>Persists Across Redirects</strong>: Useful for scenarios like redirecting to another action.</li><br />
        <li><strong>Stored in Session</strong>: Internally uses the session state.</li>
      </ul><br />

      <h3>Example Usage:</h3>
      <pre>
        <code>
{`// Setting a value in TempData
TempData["Message"] = "Data saved successfully!";

// Redirecting to another action
return RedirectToAction("AnotherAction");
`}
        </code>
      </pre>
      <pre>
        <code>
{`// Accessing TempData in the redirected action
if (TempData["Message"] != null)
{
    ViewBag.Message = TempData["Message"];
}`}
        </code>
      </pre>

    <br />

      <h2>What is ViewData?</h2>
      <p style={{paddingBottom:"6px"}}>
        <strong>ViewData</strong> is used to pass data from a controller to a view. It is a dictionary object that holds 
        key-value pairs and is available only for the current request.
      </p>
      <h3 style={{paddingBottom:"6px"}}>Key Features:</h3>
      <ul>
        <li><strong>Lifetime:</strong> Available only for the current request.</li><br />
        <li><strong>Type Casting Required:</strong> Data retrieved from ViewData needs to be explicitly typecast.</li><br />
        <li><strong>Less Persistent:</strong> Ideal for passing small amounts of data to the view.</li>
      </ul><br />

      <h3>Example Usage:</h3>
      <pre>
        <code>
{`// Setting a value in ViewData
ViewData["UserName"] = "John Doe";

// Accessing ViewData in the view
<h2>Hello, @ViewData["UserName"]!</h2>`}
        </code>
      </pre>

    <br />

      <h2>TempData vs. ViewData</h2>
      <table className={style.comparisonTable}>
        <thead>
          <tr>
            <th>Feature</th>
            <th>TempData</th>
            <th>ViewData</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Lifetime</td>
            <td>Persists across redirects</td>
            <td>Current request only</td>
          </tr>
          <tr>
            <td>Data Storage</td>
            <td>Session state</td>
            <td>Dictionary object</td>
          </tr>
          <tr>
            <td>Use Case</td>
            <td>Temporary data between actions</td>
            <td>Data for a single view</td>
          </tr>
          <tr>
            <td>Access</td>
            <td>Key-value pairs</td>
            <td>Key-value pairs</td>
          </tr>
        </tbody>
      </table>

      <br />

      <h2>When to Use TempData and ViewData?</h2>
      <ul>
        <li>
          <strong>Use TempData</strong> when:
          <ul>
            <li>You need to pass messages or data between controller actions during a redirect.</li>
            <li>Data does not need to persist beyond the subsequent request.</li>
          </ul>
        </li><br />
        <li>
          <strong>Use ViewData</strong> when:
          <ul>
            <li>You only need to pass data to the view for rendering.</li>
            <li>Data is specific to the current request and does not require persistence.</li>
          </ul>
        </li>
      </ul>

     <br />

      <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
      <ol>
        <li>Minimize dependency: Avoid overusing TempData and ViewData for complex data. Consider using strongly-typed views or ViewModels instead.</li><br />
        <li>Clear TempData: Be mindful that TempData is cleared after it’s accessed. Use it only for one-time messages or redirection data.</li><br />
        <li>Type Safety: Prefer strongly-typed data models over ViewData to avoid issues with typecasting.</li>
      </ol>

      <br />

      <h2>Conclusion</h2>
      <p>
        TempData and ViewData are simple yet powerful tools for managing state within an ASP.NET MVC application. Understanding 
        their differences and appropriate use cases can greatly enhance the efficiency and readability of your code. For more 
        complex scenarios, consider exploring <strong>ViewModels</strong>, <strong>Session</strong>, or <strong>Cache</strong> 
        for state management.
      </p>
    </div>
  </div>
)}



{selectedChapter === 'chapter43' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Caching in ASP.NET Core (In-Memory, Distributed, Response Caching)</h1>
   
      
      <p>
        Efficient state management is crucial for building high-performance
        ASP.NET Core applications, and caching is a key technique to improve
        application speed and scalability. ASP.NET Core supports multiple
        caching mechanisms, such as <strong>In-Memory Caching</strong>,{" "}
        <strong>Distributed Caching</strong>, and{" "}
        <strong>Response Caching</strong>, to optimize data access and minimize
        server processing.
      </p>

   <br />

      <h2>What is Caching?</h2>
      <p>
        Caching is a state management strategy that stores frequently accessed
        data in a fast storage medium, like memory, to reduce retrieval time. By
        avoiding repeated expensive computations or database calls, caching
        significantly enhances performance.
      </p>

     <br />

      <h2 style={{paddingBottom:"6px"}}>Types of Caching in ASP.NET Core</h2>

      <h3>1. In-Memory Caching</h3>
      <p style={{paddingBottom:"6px"}}>
        In-Memory Caching stores data in the memory of the application server.
        It is fast and suitable for single-server applications where data
        doesn’t need to persist across server restarts.
      </p>

      <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
      <ul>
        <li>Data is stored in the application’s memory.</li><br />
        <li>Best suited for single-instance or non-distributed setups.</li><br />
        <li>Provides high-speed access.</li>
      </ul><br />

      <h4>Example Usage:</h4>
      <pre>
        <code>
          {`// Enable In-Memory Caching in Startup.cs
public void ConfigureServices(IServiceCollection services)
{
    services.AddMemoryCache();
}

// Using In-Memory Cache in a Controller
public class HomeController : Controller
{
    private readonly IMemoryCache _cache;

    public HomeController(IMemoryCache memoryCache)
    {
        _cache = memoryCache;
    }

    public IActionResult Index()
    {
        string cacheKey = "CurrentTime";
        if (!_cache.TryGetValue(cacheKey, out string currentTime))
        {
            currentTime = DateTime.Now.ToString();
            var cacheEntryOptions = new MemoryCacheEntryOptions
            {
                AbsoluteExpirationRelativeToNow = TimeSpan.FromMinutes(5)
            };
            _cache.Set(cacheKey, currentTime, cacheEntryOptions);
        }

        ViewBag.Time = currentTime;
        return View();
    }
}`}
        </code>
      </pre>

      {/* Add similar sections for Distributed Caching and Response Caching */}

     <br />

      <h2>Comparison of Caching Techniques</h2>
      <table className={style.table}>
        <thead>
          <tr>
            <th>Feature</th>
            <th>In-Memory Caching</th>
            <th>Distributed Caching</th>
            <th>Response Caching</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Storage</td>
            <td>Application memory</td>
            <td>Centralized system (e.g., Redis)</td>
            <td>HTTP response headers</td>
          </tr>
          <tr>
            <td>Scope</td>
            <td>Single server</td>
            <td>Multiple servers</td>
            <td>HTTP responses only</td>
          </tr>
          <tr>
            <td>Performance</td>
            <td>Fast</td>
            <td>Moderate (depends on network)</td>
            <td>Fast</td>
          </tr>
          <tr>
            <td>Use Case</td>
            <td>Small-scale apps</td>
            <td>Scalable, multi-server apps</td>
            <td>
              Static or infrequently changing content
            </td>
          </tr>
        </tbody>
      </table>

     <br />

      <h2 style={{paddingBottom:"6px"}}>Best Practices for Caching</h2>
      <ul>
        <li>Use appropriate expiration policies.</li><br />
        <li>Avoid over-caching frequently updated data.</li><br />
        <li>Invalidate stale data appropriately.</li><br />
        <li>Secure sensitive data in the cache.</li><br />
        <li>Monitor cache performance and optimize settings.</li>
      </ul>

     <br />

      <h2>Conclusion</h2>
      <p>
        Caching is an essential technique in ASP.NET Core for enhancing
        application performance and scalability.{" "}
        <strong>In-Memory Caching</strong> is simple and efficient for
        single-server setups, while <strong>Distributed Caching</strong> ensures
        scalability in distributed environments.{" "}
        <strong>Response Caching</strong> is a lightweight solution for
        optimizing HTTP responses. By understanding and applying the right
        caching strategy, you can significantly improve the efficiency of your
        ASP.NET Core applications.
      </p>
    </div>

)}

{selectedChapter === 'chapter44' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>ASP.NET Core Identity Overview</h1>

    <p>
      ASP.NET Core Identity is a powerful and extensible membership system provided by Microsoft for managing user authentication and authorization in ASP.NET Core applications. It offers out-of-the-box support for features such as user registration, login, role management, password recovery, two-factor authentication, and more.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of ASP.NET Core Identity</h2>
    <ul>
      <li><strong>Authentication:</strong> Provides built-in mechanisms for validating user credentials and managing sessions securely.</li><br />
      <li><strong>Authorization:</strong> Offers role-based and claims-based access control for securing resources.</li><br />
      <li><strong>Customizable User Store:</strong> Supports storing user data in various data sources, including SQL Server, SQLite, and custom databases.</li><br />
      <li><strong>Secure Password Storage:</strong> Uses hashing algorithms like PBKDF2, SHA256, or SHA512 to securely store passwords.</li><br />
      <li><strong>Two-Factor Authentication (2FA):</strong> Includes support for two-factor authentication via email, SMS, or authenticator apps.</li><br />
      <li><strong>External Authentication Providers:</strong> Enables login via third-party providers such as Google, Facebook, Twitter, and Microsoft Accounts.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Getting Started with ASP.NET Core Identity</h2>
    <h3>Step 1: Install Required Packages</h3>
    <pre>
      <code>
        dotnet add package Microsoft.AspNetCore.Identity.EntityFrameworkCore{'\n'}
        dotnet add package Microsoft.EntityFrameworkCore.SqlServer
      </code>
    </pre><br />

    <h3>Step 2: Configure Services</h3>
    <pre>
      <code>
        {`services.AddDbContext<ApplicationDbContext>(options =>
    options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));

services.AddIdentity<IdentityUser, IdentityRole>()
    .AddEntityFrameworkStores<ApplicationDbContext>()
    .AddDefaultTokenProviders();`}
      </code>
    </pre><br />

    <h3>Step 3: Middleware Configuration</h3>
    <pre>
      <code>
        {`app.UseAuthentication();
app.UseAuthorization();`}
      </code>
    </pre><br />

    <h3>Step 4: Scaffold Identity Pages (Optional)</h3>
    <pre>
      <code>
        dotnet aspnet-codegenerator identity -dc ApplicationDbContext
      </code>
    </pre>

    <h2 style={{paddingBottom:"6px"}}>Understanding ASP.NET Core Identity Components</h2>
    <ul>
      <li><strong>User Manager:</strong> Provides APIs for managing user accounts, such as creating, updating, deleting, and finding users.</li><br />
      <li><strong>Role Manager:</strong> Handles operations related to user roles, such as creating roles and assigning roles to users.</li><br />
      <li><strong>Sign-In Manager:</strong> Manages user sign-in operations, including password verification and two-factor authentication.</li><br />
      <li><strong>DbContext:</strong> The default database context for storing user and role data using Entity Framework Core.</li><br />
      <li><strong>IdentityUser:</strong> The default class representing a user in the system. It can be extended for additional properties.</li>
    </ul><br />

    <h2>Database Schema</h2>
    <p>When using ASP.NET Core Identity, the following tables are created by default:</p>
    <table>
      <thead>
        <tr>
          <th>Table</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>AspNetUsers</td>
          <td>Stores user data such as username and email.</td>
        </tr>
        <tr>
          <td>AspNetRoles</td>
          <td>Stores roles like "Admin" or "User".</td>
        </tr>
        <tr>
          <td>AspNetUserRoles</td>
          <td>Maps users to roles.</td>
        </tr>
        <tr>
          <td>AspNetUserClaims</td>
          <td>Stores claims assigned to a user.</td>
        </tr>
        <tr>
          <td>AspNetRoleClaims</td>
          <td>Stores claims assigned to a role.</td>
        </tr>
        <tr>
          <td>AspNetUserLogins</td>
          <td>Stores external login information.</td>
        </tr>
        <tr>
          <td>AspNetUserTokens</td>
          <td>Stores tokens for authentication, such as 2FA.</td>
        </tr>
      </tbody>
    </table><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
    <ul>
      <li><strong>Secure Password Policies:</strong> Enforce strong password policies using <code>PasswordOptions</code>.</li><br />
      <li><strong>Enable Two-Factor Authentication:</strong> Add an extra layer of security for sensitive applications.</li><br />
      <li><strong>Regularly Update Identity Libraries:</strong> Keep your application updated with the latest Identity packages for security improvements.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      ASP.NET Core Identity provides a robust framework for managing user authentication and authorization. With its rich feature set and extensibility, it simplifies the implementation of secure and scalable membership systems in web applications. By understanding its components and applying best practices, you can build secure applications efficiently.
    </p>
  </div>
)}


{selectedChapter === 'chapter45' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Authentication Schemes (Cookies, JWT, OAuth, OpenID Connect)
    </h1>

    <p>
      ASP.NET Core Identity is a membership system provided by Microsoft for handling 
      <strong>authentication</strong> (verifying user identity) and 
      <strong>authorization</strong> (controlling user access to resources) in ASP.NET Core applications. 
      It simplifies user management by providing a secure and extensible framework.
    </p><br />

   
      <h2 style={{paddingBottom:"6px"}}>Key Features of ASP.NET Core Identity</h2>
      <ul>
        <li>
          <strong>Authentication:</strong> Enables secure login and user validation, supporting username/password, 
          token-based authentication, and external providers like Google or Facebook.
        </li><br />
        <li>
          <strong>Authorization:</strong>
          <ul>
            <li>
              <em>Role-based authorization:</em> Grants or restricts access based on assigned roles, e.g., "Admin" or "User."
            </li><br />
            <li>
              <em>Claims-based authorization:</em> Allows fine-grained access control by using custom claims.
            </li>
          </ul>
        </li><br />
        <li>
          <strong>Customizable and Extensible:</strong> Easily extend the built-in functionality to meet application-specific needs.
        </li><br />
        <li>
          <strong>Security Features:</strong>
          <ul>
            <li>Secure password storage using strong hashing algorithms like PBKDF2 or SHA256.</li><br />
            <li>Two-Factor Authentication (2FA) using SMS, email, or authenticator apps.</li><br />
            <li>Support for external authentication providers like Google, Facebook, and Twitter.</li>
          </ul>
        </li>
      </ul><br />
  
      <h2 style={{paddingBottom:"6px"}}>Key Components of ASP.NET Core Identity</h2>
      <ul>
        <li><strong>IdentityUser:</strong> Represents a user account and can be extended for custom properties.</li><br />
        <li><strong>UserManager:</strong> APIs for managing users, such as creating, updating, and authenticating.</li><br />
        <li><strong>SignInManager:</strong> Handles user sign-ins, including external logins and password validation.</li><br />
        <li><strong>RoleManager:</strong> Manages user roles, allowing for specific access levels.</li><br />
        <li><strong>IdentityDbContext:</strong> Default database context for Identity, customizable to store additional data.</li>
      </ul><br />
    
      <h2>Getting Started with ASP.NET Core Identity</h2>
      <ol>
        <li>
          <strong>Add Required Packages:</strong> Install the necessary NuGet packages:
          <pre>
            <code>
dotnet add package Microsoft.AspNetCore.Identity.EntityFrameworkCore
dotnet add package Microsoft.EntityFrameworkCore.SqlServer
            </code>
          </pre>
        </li><br />
        <li>
          <strong>Configure Services:</strong> Set up Identity in the application:
          <pre>
            <code>{`
services.AddDbContext<ApplicationDbContext>(options {"=>"}
    options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));

services.AddIdentity<IdentityUser, IdentityRole>()
    .AddEntityFrameworkStores<ApplicationDbContext>()
    .AddDefaultTokenProviders();
             `} </code>
          </pre>
        </li><br />
        <li>
          <strong>Middleware Setup:</strong> Add authentication and authorization to the pipeline:
          <pre>
            <code>{`
app.UseAuthentication();
app.UseAuthorization();
             `} </code>
          </pre>
        </li><br />
        <li>
          <strong>Scaffold Identity UI (Optional):</strong> Customize Identity UI:
          <pre>
            <code>{`
dotnet aspnet-codegenerator identity -dc ApplicationDbContext
             `} </code>
          </pre>
        </li>
      </ol><br />
 
      <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
      <ul>
        <li>
          <strong>Enforce Strong Password Policies:</strong> 
          <pre>
            <code>{`
services.Configure&lt;IdentityOptions&gt;(options =>
{
    options.Password.RequiredLength =8;
    options.Password.RequireNonAlphanumeric = true;
});
 `} </code>
          </pre>
        </li><br />
        <li>Enable Two-Factor Authentication (2FA) for additional security.</li><br />
        <li>Always use HTTPS to encrypt client-server communication.</li><br />
        <li>Regularly update Identity-related packages for security patches.</li>
      </ul><br />
   
      <h2>Conclusion</h2>
      <p>
        ASP.NET Core Identity is a robust and configurable framework for managing authentication and authorization 
        in web applications. By leveraging its features like role-based access, 2FA, and external authentication, 
        developers can create secure, scalable solutions.
      </p>
   
  </div>
)}


{selectedChapter === 'chapter46' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Role-Based Authorization</h1>
        
    <p>
      Role-based authorization in ASP.NET Core is a way of controlling access to specific parts of an application based on the roles assigned to a user. Roles are typically used to represent a group of users who share similar permissions. For example, roles like "Admin," "User," or "Manager" can be used to define which areas of the application a user can access and what actions they can perform.
    </p><br />
    
    <h3 style={{paddingBottom:"6px"}}>Key Concepts of Role-Based Authorization</h3>
    
    <ul>
      <li><strong>Roles:</strong> A role represents a set of permissions and is assigned to users. Common roles include "Admin", "User", "Manager", etc. Users can have one or more roles.</li><br />
      <li><strong>Role Claims:</strong> These are claims associated with a role. You can use role claims to store additional information about the role or user.</li><br />
      <li><strong>Authorization:</strong> ASP.NET Core provides the <code>[Authorize]</code> attribute to enforce access restrictions based on roles. This attribute can be applied to controllers or actions to allow only users with specific roles to access those resources.</li>
    </ul><br />
    
    <h3>Configuring Role-Based Authorization</h3>

    <p style={{paddingBottom:"6px"}}>To implement role-based authorization, follow these steps:</p>

    <h4>1. Define Roles</h4>
    <p>First, define the roles that will be used in your application. This is typically done during application setup, for example, when seeding the database with default roles.</p>
    
    <pre><code>{`
    public class ApplicationSeed
    {
        public static async Task SeedRoles(RoleManager&lt;IdentityRole&gt; roleManager)
        {
            string[] roleNames = { "Admin", "User", "Manager" };
            
            foreach (var roleName in roleNames)
            {
                var roleExist = await roleManager.RoleExistsAsync(roleName);
                if (!roleExist)
                {
                    await roleManager.CreateAsync(new IdentityRole(roleName));
                }
            }
        }
    }
     `} </code></pre>
    
    <p>Call this seeding method in the <code>Configure</code> method of <code>Startup.cs</code> to ensure that the roles are created when the application starts.</p><br />

    <h4>2. Assign Roles to Users</h4>
    <p>When a user registers or is created, you can assign them roles. This can be done using the <code>UserManager</code> class.</p>

    <pre><code>{`
    public class AccountController : Controller
    {
        private readonly UserManager&lt;ApplicationUser&gt; _userManager;
        private readonly RoleManager&lt;IdentityRole&gt; _roleManager;

        public AccountController(UserManager&lt;ApplicationUser&gt; userManager, RoleManager&lt;IdentityRole&gt; roleManager)
        {
            _userManager = userManager;
            _roleManager = roleManager;
        }

        public async Task&lt;IActionResult&gt; AssignRoles(string userId)
        {
            var user = await _userManager.FindByIdAsync(userId);
            if (user != null)
            {
                // Add roles to the user
                await _userManager.AddToRoleAsync(user, "Admin");
                await _userManager.AddToRoleAsync(user, "Manager");
            }
            return RedirectToAction("Index");
        }
    }
     `} </code></pre><br />

    <h4>3. Use the <code>[Authorize]</code> Attribute for Role-Based Authorization</h4>
    <p>You can use the <code>[Authorize]</code> attribute to restrict access to controllers or actions to specific roles. If a user does not belong to the required role, they will be denied access.</p>

    <p><strong>Restricting Access to Specific Roles on Controllers/Actions:</strong></p>
    <pre><code>{`
    [Authorize(Roles = "Admin")]
    public class AdminController : Controller
    {
        public IActionResult Index()
        {
            return View();
        }
    }
      `}  </code></pre>
    
    <p>In this example, only users with the "Admin" role can access the <code>Index</code> action of the <code>AdminController</code>.</p>
    
    <p><strong>Multiple Roles:</strong> You can specify multiple roles using a comma-separated list. Users who belong to any of the listed roles can access the resource.</p>
    
    <pre><code>{`
    [Authorize(Roles = "Admin,Manager")]
    public IActionResult AdminDashboard()
    {
        return View();
    }
     `} </code></pre>
    
    <p>In this example, users who belong to either the "Admin" or "Manager" role can access the <code>AdminDashboard</code> action.</p><br />

    <h4>4. Role-Based Authorization in Razor Views</h4>
    <p>You can also use role-based authorization directly in Razor views. For instance, you can show certain sections of your page only to users with a specific role.</p>

    <pre><code>{`

    @using Microsoft.AspNetCore.Identity
@inject UserManager<ApplicationUser> UserManager

@if (User.IsInRole("Admin"))
{
    <p>Welcome, Admin!</p>
}
else
{
    <p>You do not have admin access.</p>
}

 `} </code></pre><br />

    <h4>5. Policy-Based Authorization (Alternative to Roles)</h4>
    <p style={{paddingBottom:"6px"}}>While role-based authorization is very common, ASP.NET Core also supports <strong>policy-based authorization</strong>. Policies can be more granular than roles and allow for more complex logic in determining user access.</p>

    <p>Here's an example of a policy that requires a user to have a specific claim:</p>

    <h5>Configure a policy in <code>Startup.cs</code>:</h5>
    <pre><code>{`
    public void ConfigureServices(IServiceCollection services)
    {
        services.AddAuthorization(options =>
        {
            options.AddPolicy("AdminOnly", policy =>
                policy.RequireRole("Admin"));
            options.AddPolicy("HasClaim", policy =>
                policy.RequireClaim("Permission", "ViewReports"));
        });
    }
`}</code></pre><br />

    <h5>Use the policy in your controller:</h5>
    <pre><code>{`
    [Authorize(Policy = "AdminOnly")]
    public IActionResult AdminArea()
    {
        return View();
    }
     `} </code></pre><br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
    <ul>
      <li><strong>Role-Based Authorization for High-Level Permissions:</strong> Use roles for broad, high-level permissions like "Admin," "User," and "Guest."</li><br />
      <li><strong>Claims-Based or Policy-Based Authorization for Granular Control:</strong> Use claims or policies to enforce more specific, fine-grained access control.</li><br />
      <li><strong>Minimize Role Overload:</strong> Avoid creating too many roles that overlap or become difficult to manage. Keep roles simple and organized.</li><br />
      <li><strong>Use the ClaimsPrincipal Object for Custom Authorization Logic:</strong> You can use the ClaimsPrincipal object to write custom authorization rules based on user properties, beyond just roles.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>Role-based authorization in ASP.NET Core is a powerful way to manage user access to resources based on their assigned roles. By defining roles, assigning them to users, and applying the <code>[Authorize]</code> attribute with role-based checks, you can ensure that only authorized users can access certain parts of your application. Additionally, combining roles with policies can help you achieve more complex and fine-grained authorization.</p>
  </div>
)}


{selectedChapter === 'chapter47' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Policy-Based Authorization</h1>

    <p>
      <strong>Policy-Based Authorization in ASP.NET Core</strong> is an alternative to role-based authorization, offering more granular and flexible control over access to application resources. Unlike roles, which are typically broad and predefined (like "Admin" or "User"), policies allow you to define more specific conditions for access based on custom requirements, such as user claims, the presence of a specific permission, or any other complex logic.
    </p><br />

    <h3>Key Concepts of Policy-Based Authorization</h3>
    <ol>
      <li>
        <strong>Policies:</strong> A policy is a set of requirements that a user must meet to access a resource. Policies can include roles, claims, or custom requirements.
      </li><br />
      <li>
        <strong>Requirements:</strong> A requirement represents a condition that a user must meet to fulfill a policy. This can be based on claims, roles, or custom logic.
      </li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Steps to Implement Policy-Based Authorization</h3>

    <h4>1. Define Policies</h4>
    <p>
      You define policies in the <code>ConfigureServices</code> method of <code>Startup.cs</code> (or <code>Program.cs</code> in .NET 6+). Policies can be based on roles, claims, or any custom logic.
    </p>

    <pre>
      <code>
{`public void ConfigureServices(IServiceCollection services)
{
    // Add authorization services and configure policies
    services.AddAuthorization(options =>
    {
        // Define a policy called "AdminOnly" requiring the "Admin" role
        options.AddPolicy("AdminOnly", policy => 
            policy.RequireRole("Admin"));

        // Define a policy called "HasPermission" requiring a specific claim
        options.AddPolicy("HasPermission", policy =>
            policy.RequireClaim("Permission", "ViewReports"));
    });

    // Other service configurations
    services.AddControllersWithViews();
}`}
      </code>
    </pre><br />
    <p>
      In this example:
      <ul>
        <li><strong>AdminOnly</strong> policy requires the user to be in the "Admin" role.</li><br />
        <li><strong>HasPermission</strong> policy requires the user to have a claim <code>Permission</code> with the value <code>ViewReports</code>.</li>
      </ul>
    </p><br />

    <h4>2. Apply the Policy to Controllers or Actions</h4>
    <p>
      Once you've defined the policies, you can apply them to controllers or individual actions using the <code>[Authorize]</code> attribute.
    </p>

    <pre>
      <code>
{`// Restrict access using the policy
[Authorize(Policy = "AdminOnly")]
public IActionResult AdminDashboard()
{
    return View();
}`}

{`// Use multiple policies
[Authorize(Policy = "AdminOnly")]
[Authorize(Policy = "HasPermission")]
public IActionResult AdminArea()
{
    return View();
}`}
      </code>
    </pre><br />

    <h4>3. Create Custom Authorization Requirements (Optional)</h4>
    <p>
      If the built-in role and claim-based policies are insufficient, you can create custom authorization requirements. A custom requirement can be used to implement complex authorization logic.
    </p>

    <pre>
      <code>
{`// Define a custom requirement
public class MinimumAgeRequirement : IAuthorizationRequirement
{
    public int MinimumAge { get; }

    public MinimumAgeRequirement(int minimumAge)
    {
        MinimumAge = minimumAge;
    }
}

// Create a custom authorization handler
public class MinimumAgeHandler : AuthorizationHandler<MinimumAgeRequirement>
{
    protected override Task HandleRequirementAsync(AuthorizationHandlerContext context, MinimumAgeRequirement requirement)
    {
        var birthDateClaim = context.User.FindFirst(c => c.Type == "BirthDate");

        if (birthDateClaim != null)
        {
            var birthDate = DateTime.Parse(birthDateClaim.Value);
            var age = DateTime.Today.Year - birthDate.Year;

            if (birthDate > DateTime.Today.AddYears(-age)) age--;

            if (age >= requirement.MinimumAge)
            {
                context.Succeed(requirement); // Authorization successful
            }
        }

        return Task.CompletedTask;
    }
}`}
      </code>
    </pre>

    <pre>
      <code>
{`// Register the custom handler in ConfigureServices
public void ConfigureServices(IServiceCollection services)
{
    services.AddAuthorization(options =>
    {
        options.AddPolicy("MinimumAge18", policy =>
            policy.Requirements.Add(new MinimumAgeRequirement(18)));
    });

    services.AddSingleton<IAuthorizationHandler, MinimumAgeHandler>();

    // Other service configurations
}
`}
      </code>
    </pre><br />

    <h4>4. Handling Authorization Failures</h4>
    <p>
      If a user doesn't meet the authorization requirements of a policy, ASP.NET Core will block their access and typically redirect them to a configured login page or an access-denied page.
    </p>

    <pre>
      <code>
{`// Configure fallback policy in Startup.cs
services.AddAuthorization(options =>
{
    options.FallbackPolicy = new AuthorizationPolicyBuilder()
        .RequireAuthenticatedUser()
        .Build();
});
`}
      </code>
    </pre><br />

    <h4>5. Using Policy-Based Authorization in Razor Views</h4>
    <p>
      Just as you can use role-based authorization in Razor views, you can also use policy-based authorization. You can check if a user satisfies a policy using the <code>User.HasClaim()</code> or <code>User.IsInRole()</code> methods:
    </p>

    <pre>
      <code>
{`@using Microsoft.AspNetCore.Authorization
@inject IAuthorizationService AuthorizationService

@if (await AuthorizationService.AuthorizeAsync(User, "AdminOnly"))
{
    <p>Welcome, Admin!</p>
}
else
{
    <p>You do not have access to this section.</p>
}`}
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
    <ul>
      <li><strong>Use Policies for Granular Control:</strong> Policies offer more flexibility than roles. Use them when you need more complex rules beyond just roles.</li><br />
      <li><strong>Combine Policies with Claims:</strong> Policies allow for detailed authorization logic based on claims or other conditions.</li><br />
      <li><strong>Keep Policies Simple:</strong> While policies are flexible, avoid creating overly complex ones. If your logic becomes too complex, consider creating a custom authorization requirement.</li><br />
      <li><strong>Use Policies for Fine-Grained Control:</strong> When you have specific permissions (e.g., "ViewReports", "EditData"), use policies to handle those granular checks.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Policy-based authorization in ASP.NET Core provides a powerful and flexible way to manage access control in your application. By combining policies with roles, claims, and custom logic, you can create a robust and fine-grained authorization system that meets the needs of your application.
    </p>
  </div>
)}


   
{selectedChapter === 'chapter48' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      External Authentication Providers (Google, Facebook, Microsoft)
    </h1>
    <p>
      To integrate <strong>External Authentication Providers</strong> (like Google, Facebook, and Microsoft) in an ASP.NET Core application, you can use <strong>ASP.NET Core Identity</strong> and configure external authentication services. This allows users to sign in to your application using their existing credentials from external providers.
    </p><br />

    <h2>1. Install Necessary NuGet Packages</h2>
    <p>First, make sure you have the following NuGet packages installed:</p>
    <ul>
      <li><code>Microsoft.AspNetCore.Authentication.Google</code></li><br />
      <li><code>Microsoft.AspNetCore.Authentication.Facebook</code></li><br />
      <li><code>Microsoft.AspNetCore.Authentication.MicrosoftAccount</code></li>
    </ul><br />
    <p>You can install these packages using NuGet or the Package Manager Console:</p>
    <pre>
      <code>
       <p> dotnet add package Microsoft.AspNetCore.Authentication.Google</p>
       
       <p>  dotnet add package Microsoft.AspNetCore.Authentication.Facebook</p>
    
       <p>  dotnet add package Microsoft.AspNetCore.Authentication.MicrosoftAccount</p>
      </code>
    </pre><br />

    <h2>2. Configure External Authentication Providers in <code>Startup.cs</code> or <code>Program.cs</code></h2>
    <p>
      In <strong>ASP.NET Core 5.0 and later</strong>, the configuration of authentication providers is typically done in <code>Program.cs</code>. Below is an example of how to configure Google, Facebook, and Microsoft authentication:
    </p>

    <h3>a. Configure Authentication Services</h3>
    <pre>
      <code>
        {`public class Program
{
    public static void Main(string[] args)
    {
        CreateHostBuilder(args).Build().Run();
    }

    public static IHostBuilder CreateHostBuilder(string[] args) =>
        Host.CreateDefaultBuilder(args)
            .ConfigureWebHostDefaults(webBuilder =>
            {
                webBuilder.ConfigureServices((context, services) =>
                {
                    services.AddAuthentication()
                        .AddGoogle(options =>
                        {
                            options.ClientId = "your-google-client-id";
                            options.ClientSecret = "your-google-client-secret";
                        })
                        .AddFacebook(options =>
                        {
                            options.AppId = "your-facebook-app-id";
                            options.AppSecret = "your-facebook-app-secret";
                        })
                        .AddMicrosoftAccount(options =>
                        {
                            options.ClientId = "your-microsoft-client-id";
                            options.ClientSecret = "your-microsoft-client-secret";
                        });

                    services.AddRazorPages(); // Or AddControllersWithViews for MVC
                })
                .Configure(app =>
                {
                    app.UseAuthentication();
                    app.UseAuthorization();
                    app.UseEndpoints(endpoints =>
                    {
                        endpoints.MapRazorPages(); // Or MapControllerRoute for MVC
                    });
                });
            });
}`}
      </code>
    </pre>
    <p>
      Replace <code>your-google-client-id</code>, <code>your-facebook-app-id</code>, and <code>your-microsoft-client-id</code> with your actual client IDs and secrets, which can be obtained from the respective provider’s developer console.
    </p><br />

    <h3>b. Add Redirect URIs</h3>
    <p>
      When setting up your external authentication providers (Google, Facebook, Microsoft), you will need to configure the <strong>Redirect URIs</strong>. These are the URLs to which the authentication provider will redirect the user after successful authentication.
    </p>
    <p>
      For Google, Facebook, and Microsoft, the default redirect URI will look like:
    </p>
    <pre>
      <code>
        https://localhost:5001/signin-google
        <br />
        https://localhost:5001/signin-facebook
        <br />
        https://localhost:5001/signin-microsoft
      </code>
    </pre>
    <p>Make sure to configure these redirect URIs in the respective developer portals.</p><br />

    <h2>3. Create Authentication Actions in Controllers</h2>
    <p style={{paddingBottom:"6px"}}>In your application, you can create a controller or use Razor Pages to initiate the login flow.</p>

    <h3>Example Controller</h3>
    <pre>
      <code>
        {`public class AccountController : Controller
{
    [HttpGet]
    public IActionResult Login()
    {
        return View();
    }

    // The default external login callback action
    public IActionResult ExternalLoginCallback(string returnUrl = null)
    {
        return RedirectToAction("Index", "Home");
    }
}`}
      </code>
    </pre><br />

    <h2>4. Create Login View</h2>
    <p>You can create a login view where users can click on buttons to authenticate via Google, Facebook, or Microsoft.</p>
    <h3>Example <code>Login.cshtml</code> View</h3>
    <pre>
      <code>
        {`@{
    ViewData["Title"] = "Login";
}

<h1>@ViewData["Title"]</h1>

<a href="~/Account/ExternalLogin?provider=Google" class="btn btn-primary">Login with Google</a>
<a href="~/Account/ExternalLogin?provider=Facebook" class="btn btn-primary">Login with Facebook</a>
<a href="~/Account/ExternalLogin?provider=Microsoft" class="btn btn-primary">Login with Microsoft</a>`}
      </code>
    </pre><br />

    <h2>5. Handle External Login Callback</h2>
    <p>To handle the callback and process the user information from external providers, ASP.NET Core provides a default mechanism that is automatically handled by the authentication middleware.</p>
  </div>
)}


{selectedChapter === 'chapter49' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Securing ASP.NET Core Applications with HTTPS</h1>

    <div>
      <p>
        Ensuring your ASP.NET Core application is secure is a critical part of its development and deployment.
        HTTPS (Hypertext Transfer Protocol Secure) provides encryption for data in transit, safeguarding
        sensitive information from interception or tampering.
      </p>

    <br />

      <h2 style={{paddingBottom:"6px"}}>Why Use HTTPS?</h2>
      <ul>
        <li><strong>Encryption:</strong> Protects data exchanged between the client and server.</li><br />
        <li><strong>Authentication:</strong> Confirms the identity of the server, preventing man-in-the-middle attacks.</li><br />
        <li><strong>Trust:</strong> Modern browsers flag sites without HTTPS as insecure.</li>
      </ul>

     <br />

      <h2>1. Enforce HTTPS with Middleware</h2>
      <p>
        In ASP.NET Core, you can enforce HTTPS by adding the HTTPS Redirection Middleware in your application pipeline.
      </p>
      <pre>
        <code>
{`var builder = WebApplication.CreateBuilder(args);

// Add services to the container.
builder.Services.AddHttpsRedirection(options =>
{
    options.RedirectStatusCode = StatusCodes.Status308PermanentRedirect;
    options.HttpsPort = 5001; // Ensure this matches your port
});

var app = builder.Build();

app.UseHttpsRedirection(); // Redirect HTTP requests to HTTPS
app.UseAuthorization();
app.MapControllers();

app.Run();`}
        </code>
      </pre>

      <br />

      <h2>2. Configure HTTPS in Development</h2>
      <p>
        Ensure your application uses HTTPS in development by configuring <code>launchSettings.json</code>.
      </p>
      <pre>
        <code>
{`"profiles": {
  "IIS Express": {
    "applicationUrl": "https://localhost:5001;http://localhost:5000",
    "sslPort": 5001
  },
  "MyApp": {
    "commandName": "Project",
    "applicationUrl": "https://localhost:5001;http://localhost:5000"
  }
}`}
        </code>
      </pre>
      <p>
        <strong>Trust the Development Certificate:</strong> Run the following command to trust the ASP.NET Core
        development certificate:
      </p>
      <pre>
        <code>dotnet dev-certs https --trust</code>
      </pre>

    <br />

      <h2>3. Configure HTTPS for Production</h2>
      <p>
        In production, obtain an SSL certificate from a trusted Certificate Authority (CA). Deploy it on your
        hosting environment:
      </p>
      <ul>
        <li>
          <strong>Windows IIS:</strong> Use the IIS Manager to import your certificate and bind it to your site.
        </li><br />
        <li>
          <strong>Linux with Nginx:</strong> Configure SSL settings in your Nginx configuration file:
        </li>
      </ul>
      <pre>
        <code>
{`server {
    listen 443 ssl;
    server_name yourdomain.com;

    ssl_certificate /etc/ssl/certs/your_certificate.crt;
    ssl_certificate_key /etc/ssl/private.key;

    location / {
        proxy_pass http://localhost:5000;
    }
}`}
        </code>
      </pre>

      <br />

      <h2>4. Enable HTTP Strict Transport Security (HSTS)</h2>
      <p>HSTS instructs browsers to only communicate over HTTPS.</p>
      <pre>
        <code>
{`if (!app.Environment.IsDevelopment())
{
    app.UseHsts();
}`}
        </code>
      </pre>
      <p><strong>Important:</strong> Avoid enabling HSTS in development environments to prevent browser caching issues.</p>

    <br />

      <h2>5. Redirect HTTP to HTTPS</h2>
      <p>
        To ensure all HTTP requests are redirected to HTTPS, configure your server to issue permanent redirects.
      </p>
      <pre>
        <code>
{`builder.Services.AddHttpsRedirection(options =>
{
    options.RedirectStatusCode = StatusCodes.Status308PermanentRedirect;
});`}
        </code>
      </pre>

     <br />

      <h2>6. Require HTTPS in Controllers or Actions</h2>
      <p>
        Use the <code>[RequireHttps]</code> attribute to ensure specific controllers or actions only respond to HTTPS
        requests.
      </p>
      <pre>
        <code>
{`[RequireHttps]
public class SecureController : Controller
{
    public IActionResult Index()
    {
        return View();
    }
}`}
        </code>
      </pre>

      <br />

      <h2>7. Test and Validate HTTPS</h2>
      <ul>
        <li>Use tools like <a href="https://www.ssllabs.com/">SSL Labs</a> to test the configuration of your HTTPS deployment.</li><br />
        <li>Validate that all sensitive endpoints are served over HTTPS.</li>
      </ul>

     <br />

      <h2>Conclusion</h2>
      <p>
        By enforcing HTTPS, configuring strict HSTS policies, and obtaining valid certificates, you can significantly
        improve the security of your ASP.NET Core applications. Proper HTTPS implementation not only protects your
        users’ data but also enhances trust in your application.
      </p>
    </div>
  </div>
)}



{selectedChapter === "chapter50" && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Cross-Site Scripting (XSS) and Cross-Site Request Forgery (CSRF) Protection
    </h1>
 
      <p>
        Cross-Site Scripting (XSS) and Cross-Site Request Forgery (CSRF) are common vulnerabilities that attackers exploit to compromise web applications. ASP.NET Core provides built-in mechanisms to mitigate these risks effectively.
      </p>
 <br />

      <h2>Understanding XSS</h2>
      <p style={{paddingBottom:"6px"}}>
        <strong>Cross-Site Scripting (XSS)</strong> occurs when an attacker injects malicious scripts into a website, which are then executed in the user's browser.
      </p>

      <h3 style={{paddingBottom:"6px"}}>Types of XSS Attacks</h3>
      <ul>
        <li>
          <strong>Stored XSS</strong>: Malicious scripts are stored on the server (e.g., in a database).
        </li><br />
        <li>
          <strong>Reflected XSS</strong>: Malicious scripts are reflected off a web application, often via query parameters.
        </li><br />
        <li>
          <strong>DOM-Based XSS</strong>: Scripts manipulate the DOM directly in the browser.
        </li>
      </ul>
     <br />

      <h3 style={{paddingBottom:"6px"}}>1. Preventing XSS in ASP.NET Core</h3>
      <h4>a. HTML Encoding</h4>
      <p>ASP.NET Core automatically encodes output in Razor views to prevent XSS.</p>
      <pre>
        <code>
          {`<!-- Razor View -->
<div>@Model.UserInput</div> <!-- Automatically encoded -->`}
        </code>
      </pre><br />

      <h4>b. Using <code>HtmlEncoder</code> for Manual Encoding</h4>
      <pre>
        <code>
          {`using System.Text.Encodings.Web;

string encodedInput = HtmlEncoder.Default.Encode(userInput);`}
        </code>
      </pre><br />

      <h4>c. Validate User Input</h4>
      <p>Always validate and sanitize user input using libraries like HtmlSanitizer.</p>
      <pre>
        <code>
          {`using Ganss.XSS;

var sanitizer = new HtmlSanitizer();
string sanitizedInput = sanitizer.Sanitize(userInput);`}
        </code>
      </pre>
     <br />

      <h3>2. Content Security Policy (CSP)</h3>
      <p>
        Implement a Content Security Policy to restrict the sources of scripts and prevent inline script execution.
      </p>
      <pre>
        <code>
          {`app.Use(async (context, next) =>
{
    context.Response.Headers.Add("Content-Security-Policy", "default-src 'self'; script-src 'self'; style-src 'self'");
    await next();
});`}
        </code>
      </pre>
    <br />

      <h2>Understanding CSRF</h2>
      <p style={{paddingBottom:"6px"}}>
        <strong>Cross-Site Request Forgery (CSRF)</strong> tricks authenticated users into performing unwanted actions on a trusted website.
      </p>



      <h3 style={{paddingBottom:"6px"}}>3. Built-In CSRF Protection in ASP.NET Core</h3>
      <h4>a. Enabling CSRF Protection</h4>
      <p>
        Anti-forgery tokens are automatically added to Razor Pages and MVC views with form tags.
      </p>
      <pre>
        <code>
          {`<form asp-controller="Account" asp-action="Login" method="post">
    @Html.AntiForgeryToken()
    <input type="text" name="username" />
    <input type="password" name="password" />
    <button type="submit">Login</button>
</form>`}
        </code>
      </pre><br />

      <h4>b. Validating Anti-Forgery Tokens in Controllers</h4>
      <pre>
        <code>
          {`[ValidateAntiForgeryToken]
public IActionResult Login(UserLoginModel model)
{
    // Handle login logic
}`}
        </code>
      </pre>
     <br />

      <h3>4. Handling CSRF in APIs</h3>
      <p>
        For APIs, cookies-based CSRF protection may not be ideal. Use custom headers and validate them.
      </p>
      <pre>
        <code>
          {`public class AntiCsrfMiddleware
{
    private readonly RequestDelegate _next;

    public AntiCsrfMiddleware(RequestDelegate next)
    {
        _next = next;
    }

    public async Task Invoke(HttpContext context)
    {
        if (context.Request.Method == HttpMethods.Post &&
            !context.Request.Headers.ContainsKey("X-CSRF-TOKEN"))
        {
            context.Response.StatusCode = StatusCodes.Status403Forbidden;
            return;
        }

        await _next(context);
    }
}`}
        </code>
      </pre>
    <br />

      <h3>5. Anti-Forgery for Angular or React Applications</h3>
      <p>
        Use custom anti-CSRF tokens with JavaScript frameworks.
      </p>
      <ol>
        <li>Generate an anti-CSRF token in your server-side application.</li>
        <li>Include the token in the initial page load or via an API endpoint.</li>
        <li>Attach the token as a custom header in AJAX requests.</li>
      </ol>
      <pre>
        <code>
          {`fetch('/api/resource', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken // Token provided by the server
    },
    body: JSON.stringify({ data: 'value' })
});`}
        </code>
      </pre>
     <br />

      <h3>6. Disabling CSRF Protection for Specific Endpoints</h3>
      <p>
        In scenarios where CSRF protection is not necessary, such as for public APIs, disable it:
      </p>
      <pre>
        <code>
          {`[IgnoreAntiforgeryToken]
public IActionResult PublicApi()
{
    return Ok();
}`}
        </code>
      </pre>
     <br />

      <h3 style={{paddingBottom:"6px"}}>Testing for XSS and CSRF Vulnerabilities</h3>
      <ul>
        <li>Use security testing tools like OWASP ZAP or Burp Suite.</li><br />
        <li>Test forms and input fields for XSS payloads.</li><br />
        <li>Validate the proper functioning of anti-CSRF tokens.</li>
      </ul>
     <br />

      <h3>Conclusion</h3>
      <p>
        Securing your ASP.NET Core application against XSS and CSRF ensures the integrity of user data and builds trust. By implementing encoding practices, leveraging ASP.NET Core's built-in CSRF protections, and enforcing CSP headers, you can significantly reduce the attack surface of your application.
      </p>
    </div>

)}


{selectedChapter === 'chapter51' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Data Protection in ASP.NET Core</h1>

    <p>
      Securing sensitive data is a critical aspect of web application development. ASP.NET Core provides a robust
      <strong> Data Protection API</strong> to safeguard information such as user data, cookies, and tokens. This API simplifies 
      encryption and ensures compliance with modern security standards.
    </p>

  <br />

    <h2>Understanding the Data Protection API</h2>
    <p>
      The Data Protection API in ASP.NET Core is designed to provide cryptographic services such as:
    </p>
    <ul>
      <li>
        <strong>Data Encryption and Decryption:</strong> Encrypt sensitive data and decrypt it securely when needed.
      </li><br />
      <li>
        <strong>Purpose-based Protection:</strong> Use purpose strings to ensure that cryptographic keys are bound to specific use cases.
      </li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Configuring Data Protection</h2>
    <h3>Basic Setup</h3>
    <p>
      By default, ASP.NET Core registers the Data Protection system automatically. You can configure it explicitly for advanced scenarios:
    </p>
    <pre className={style.codeblock}>
      <code>{`
using Microsoft.AspNetCore.DataProtection;

public class Startup
{
    public void ConfigureServices(IServiceCollection services)
    {
        services.AddDataProtection()
            .SetApplicationName("MyApp")
            .PersistKeysToFileSystem(new DirectoryInfo(@"C:\\Keys"));
    }
}
      `}</code>
    </pre><br />

    <h3>Purpose Strings</h3>
    <p>
      Purpose strings ensure that cryptographic keys are used for their intended purposes only. They prevent unintended decryption of data.
    </p>
    <pre className={style.codeblock}>
      <code>{`
using Microsoft.AspNetCore.DataProtection;

public class DataProtectionExample
{
    private readonly IDataProtector _protector;

    public DataProtectionExample(IDataProtectionProvider provider)
    {
        _protector = provider.CreateProtector("SpecificPurpose");
    }

    public string ProtectData(string data)
    {
        return _protector.Protect(data);
    }

    public string UnprotectData(string protectedData)
    {
        return _protector.Unprotect(protectedData);
    }
}
      `}</code>
    </pre>
<br />

    <h2>Protecting Application Secrets</h2>
    <p style={{paddingBottom:"6px"}}>
      To safeguard application secrets, integrate the Data Protection API with secure storage mechanisms like Azure Key Vault or AWS Secrets Manager. For local development, consider using the <code>dotnet user-secrets</code> tool.
    </p>

    <h3>Example: Using Azure Key Vault</h3>
    <pre className={style.codeblock}>
      <code>{`
// Install Azure Key Vault NuGet Package
// dotnet add package Microsoft.Azure.KeyVault.Extensions

services.AddDataProtection()
    .PersistKeysToAzureBlobStorage("YourAzureBlobConnectionString", "container-name", "keys.xml")
    .ProtectKeysWithAzureKeyVault("YourKeyVaultUri", "ClientId", "ClientSecret");
      `}</code>
    </pre>

   <br />

    <h2>Key Management</h2>
    <p>
      The Data Protection API manages keys securely and rotates them automatically. However, you can customize key storage and lifecycle.
    </p>
    <h3>Custom Key Lifetime</h3>
    <pre className={style.codeblock}>
      <code>{`
services.AddDataProtection()
    .SetDefaultKeyLifetime(TimeSpan.FromDays(90));
      `}</code>
    </pre>

   <br />

    <h2>Secure Cookie Management</h2>
    <p>
      Cookies are a common attack surface. Using the Data Protection API, you can encrypt and decrypt cookies securely:
    </p>
    <pre className={style.codeblock}>
      <code>{`
services.ConfigureApplicationCookie(options =>
{
    options.Cookie.HttpOnly = true;
    options.Cookie.SecurePolicy = CookieSecurePolicy.Always;
    options.DataProtectionProvider = DataProtectionProvider.Create("MyApp");
});
      `}</code>
    </pre>

   <br />

    <h2>Testing and Troubleshooting</h2>
    <ul>
      <li>
        <strong>Test Encrypted Data:</strong> Verify encryption and decryption processes to ensure data integrity.
      </li><br />
      <li>
        <strong>Use Logging:</strong> Enable logging to debug issues related to key management and encryption.
      </li>
    </ul>

    <br />

    <h2>Conclusion</h2>
    <p>
      The Data Protection API is a versatile and secure solution for managing sensitive data in ASP.NET Core. By configuring it effectively, you can enhance the security of your applications while adhering to best practices.
    </p>
  </div>
)}

{selectedChapter === 'chapter52' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Implementing Two-Factor Authentication (2FA)</h1>

    <p>
      Two-Factor Authentication (2FA) is a crucial security measure that adds an extra layer of protection to user accounts by requiring a second form of authentication. In ASP.NET Core, implementing 2FA can be done efficiently using built-in Identity features.
    </p>

  <br />

    <h2>What is Two-Factor Authentication?</h2>
    <p>
      2FA requires users to provide two separate forms of authentication:
    </p>
    <ul>
      <li><strong>Something they know:</strong> Password or PIN.</li><br />
      <li><strong>Something they have:</strong> Phone, hardware token, or email.</li>
    </ul>
    <p>
      This approach significantly enhances security by making it harder for attackers to gain access, even if a password is compromised.
    </p>

   <br />

    <h2>Enabling 2FA in ASP.NET Core Identity</h2>
    <p style={{paddingBottom:"6px"}}>
      ASP.NET Core Identity has built-in support for 2FA via email, SMS, or authenticator apps like Google Authenticator. Follow these steps to implement 2FA.
    </p>

    <h3>Step 1: Configure Identity Services</h3>
    <pre className={style.codeblock}>
      <code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddDbContext<ApplicationDbContext>(options =>
        options.UseSqlServer(Configuration.GetConnectionString("DefaultConnection")));

    services.AddIdentity<IdentityUser, IdentityRole>()
        .AddEntityFrameworkStores<ApplicationDbContext>()
        .AddDefaultTokenProviders();

    services.Configure<DataProtectionTokenProviderOptions>(options =>
        options.TokenLifespan = TimeSpan.FromMinutes(10));
}
      `}</code>
    </pre><br />

    <h3>Step 2: Enable 2FA in User Settings</h3>
    <p>
      Add functionality for users to enable or disable 2FA in their account settings. Use <code>GenerateTwoFactorTokenAsync</code> to create verification tokens.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public async Task<IActionResult> Enable2FA()
{
    var user = await _userManager.GetUserAsync(User);
    var token = await _userManager.GenerateTwoFactorTokenAsync(user, TokenOptions.DefaultProvider);
    // Send the token via SMS or email
    await _emailSender.SendEmailAsync(user.Email, "2FA Token", $"Your token is {token}");
    return View();
}
      `}</code>
    </pre><br />

    <h3>Step 3: Verify 2FA Code</h3>
    <p>
      During login, prompt the user to enter the 2FA code and verify it.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public async Task<IActionResult> Verify2FA(string token)
{
    var user = await _signInManager.GetTwoFactorAuthenticationUserAsync();
    if (await _userManager.VerifyTwoFactorTokenAsync(user, TokenOptions.DefaultProvider, token))
    {
        await _signInManager.SignInAsync(user, isPersistent: false);
        return RedirectToAction("Index", "Home");
    }
    ModelState.AddModelError(string.Empty, "Invalid token.");
    return View();
}
      `}</code>
    </pre>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Implementing 2FA with Authenticator Apps</h2>
    <h3>Generate QR Code</h3>
    <p>
      Use a library like <code>QRCoder</code> to generate QR codes for authenticator apps.
    </p>
    <pre className={style.codeblock}>
      <code>{`
var user = await _userManager.GetUserAsync(User);
var key = await _userManager.GetAuthenticatorKeyAsync(user);
if (string.IsNullOrEmpty(key))
{
    await _userManager.ResetAuthenticatorKeyAsync(user);
    key = await _userManager.GetAuthenticatorKeyAsync(user);
}

var qrCodeData = $"otpauth://totp/MyApp:{user.Email}?secret={key}&issuer=MyApp";
var qrCodeImage = GenerateQRCode(qrCodeData);
return View(qrCodeImage);
      `}</code>
    </pre><br />

    <h3>Validate Authenticator App Code</h3>
    <p>
      Use <code>VerifyTwoFactorTokenAsync</code> to validate the TOTP code generated by an authenticator app.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public async Task<IActionResult> VerifyAuthenticatorCode(string code)
{
    var user = await _userManager.GetUserAsync(User);
    var isValid = await _userManager.VerifyTwoFactorTokenAsync(user, TokenOptions.DefaultAuthenticatorProvider, code);
    if (isValid)
    {
        // Enable 2FA for the user
        await _userManager.SetTwoFactorEnabledAsync(user, true);
        return RedirectToAction("Success");
    }
    ModelState.AddModelError(string.Empty, "Invalid code.");
    return View();
}
      `}</code>
    </pre>

  <br />

    <h2 style={{paddingBottom:"6px"}}>Testing and Best Practices</h2>
    <ul>
      <li>Test with multiple authentication methods (email, SMS, authenticator apps).</li><br />
      <li>Set a reasonable expiration time for tokens.</li><br />
      <li>Provide fallback mechanisms, such as recovery codes.</li>
    </ul>

<br />

    <h2>Conclusion</h2>
    <p>
      Implementing 2FA in ASP.NET Core significantly enhances application security by requiring an additional verification step. By leveraging the built-in Identity features, you can efficiently integrate 2FA into your applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter53' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Identity Server Integration for SSO and OAuth2</h1>

    <p>
     IdentityServer is an OpenID Connect and OAuth 2.0 framework for ASP.NET Core. It enables Single Sign-On (SSO) and secure access to APIs. Integrating IdentityServer enhances your application's security by centralizing authentication and authorization.
    </p>

    <br />

    <h2>What is IdentityServer?</h2>
    <p>
      IdentityServer is a framework for implementing authentication and authorization based on OpenID Connect and OAuth2 protocols. Its features include:
    </p>
    <ul>
      <li>Centralized authentication for multiple applications (SSO).</li><br />
      <li>Token-based access for securing APIs.</li><br />
      <li>Support for external identity providers like Google or Azure AD.</li>
    </ul>

 <br />

    <h2 style={{paddingBottom:"6px"}}>Step-by-Step Integration</h2>

    <h3>1. Setting Up IdentityServer</h3>
    <p>
      Start by creating a new ASP.NET Core project for IdentityServer.
    </p>
    <pre className={style.codeblock}>
      <code>{`
dotnet new webapi -n IdentityServer
cd IdentityServer
dotnet add package Duende.IdentityServer
      `}</code>
    </pre><br />

    <h4>Configure IdentityServer</h4>
    <p>
      In the `Program.cs` file, configure IdentityServer with in-memory clients, resources, and users.
    </p>
    <pre className={style.codeblock}>
      <code>{`
builder.Services.AddIdentityServer()
    .AddInMemoryClients(Config.GetClients())
    .AddInMemoryApiResources(Config.GetApiResources())
    .AddInMemoryApiScopes(Config.GetApiScopes())
    .AddInMemoryIdentityResources(Config.GetIdentityResources())
    .AddTestUsers(Config.GetUsers());

var app = builder.Build();
app.UseIdentityServer();
app.Run();
      `}</code>
    </pre>

    <p>
      Add a `Config` class for clients, resources, and test users:
    </p>
    <pre className={style.codeblock}>
      <code>{`
// Config.cs
public static IEnumerable<Client> GetClients() =>
    new List<Client>
    {
        new Client
        {
            ClientId = "client_id",
            AllowedGrantTypes = GrantTypes.ClientCredentials,
            ClientSecrets = { new Secret("client_secret".Sha256()) },
            AllowedScopes = { "api_scope" }
        }
    };

public static IEnumerable<ApiScope> GetApiScopes() =>
    new List<ApiScope> { new ApiScope("api_scope", "API Access") };

public static IEnumerable<ApiResource> GetApiResources() =>
    new List<ApiResource>();

public static IEnumerable<IdentityResource> GetIdentityResources() =>
    new List<IdentityResource> { new IdentityResources.OpenId() };

public static List<TestUser> GetUsers() =>
    new List<TestUser>
    {
        new TestUser
        {
            SubjectId = "1",
            Username = "testuser",
            Password = "password"
        }
    };
      `}</code>
    </pre>

    <br />

    <h3>2. Securing an API</h3>
    <p>
      Add IdentityServer authentication to an ASP.NET Core API.
    </p>
    <pre className={style.codeblock}>
      <code>{`
builder.Services.AddAuthentication("Bearer")
    .AddJwtBearer("Bearer", options =>
    {
        options.Authority = "https://localhost:5001";
        options.Audience = "api_scope";
    });

builder.Services.AddAuthorization();
var app = builder.Build();
app.UseAuthentication();
app.UseAuthorization();
      `}</code>
    </pre>

    <p>
      Protect API endpoints using the `[Authorize]` attribute:
    </p>
    <pre className={style.codeblock}>
      <code>{`
[Authorize]
[ApiController]
[Route("[controller]")]
public class SecureController : ControllerBase
{
    [HttpGet]
    public IActionResult GetSecureData() => Ok("This is protected data.");
}
      `}</code>
    </pre>

    <br />

    <h3>3. Setting Up a Client Application</h3>
    <p>
      Add OpenID Connect authentication to an ASP.NET Core MVC or Blazor client.
    </p>
    <pre className={style.codeblock}>
      <code>{`
builder.Services.AddAuthentication(options =>
{
    options.DefaultScheme = "Cookies";
    options.DefaultChallengeScheme = "oidc";
})
.AddCookie("Cookies")
.AddOpenIdConnect("oidc", options =>
{
    options.Authority = "https://localhost:5001";
    options.ClientId = "client_id";
    options.ClientSecret = "client_secret";
    options.ResponseType = "code";
    options.Scope.Add("openid");
    options.Scope.Add("profile");
    options.SaveTokens = true;
});
      `}</code>
    </pre>

    <p>
      Secure pages by applying the `[Authorize]` attribute:
    </p>
    <pre className={style.codeblock}>
      <code>{`
[Authorize]
public IActionResult SecurePage()
{
    return View();
}
      `}</code>
    </pre>

   <br />

    <h3>4. Testing SSO</h3>
    <p>
      Launch the IdentityServer and client applications. Log in once to the IdentityServer, and you'll have access to all secured applications within the same session.
    </p>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
    <ul>
      <li>Use HTTPS for all IdentityServer interactions.</li><br />
      <li>Configure refresh tokens for long-lived sessions.</li><br />
      <li>Regularly review and update allowed scopes and claims.</li><br />
      <li>Integrate external identity providers like Google or Azure AD for flexibility.</li>
    </ul><br />

   <br />

    <h2>Conclusion</h2>
    <p>
      By integrating IdentityServer, you can centralize user authentication, enable Single Sign-On, and implement OAuth2 for secure API access. This setup ensures robust security and scalability for modern applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter54' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Global Exception Handling in ASP.NET Core  </h1>


    <p>
      Global exception handling is a critical part of building robust and secure applications. It ensures that unhandled exceptions are caught and appropriately managed, preventing your application from crashing unexpectedly and providing a controlled response to users.
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Why Global Exception Handling?</h2>
    <ul>
      <li> <strong> Graceful Error Recovery:</strong>By catching exceptions globally, we prevent unexpected crashes and maintain application stability.</li> <br />
     <li> <strong>Security:</strong> Prevents the leakage of sensitive information to end users and helps in logging errors securely.</li><br /> 
      <li> <strong> User Experience:</strong> Allows displaying user-friendly error messages rather than generic server error responses.</li>
    </ul>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Implementing Global Exception Handling in ASP.NET Core</h2>

    <h3>1. Configuring Global Exception Handling in Middleware</h3>
    <p>
      ASP.NET Core allows you to configure global exception handling using middleware. This middleware catches unhandled exceptions and processes them (logs, returns custom error pages, etc.).
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class Startup
{
    public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
    {
        if (env.IsDevelopment())
        {
            app.UseDeveloperExceptionPage(); // Shows detailed error page for development environments
        }
        else
        {
            app.UseExceptionHandler("/Home/Error"); // Redirects to a custom error page in production
            app.UseHsts(); // Enforce HTTPS
        }

        app.UseRouting();
        app.UseEndpoints(endpoints =>
        {
            endpoints.MapControllerRoute(
                name: "default",
                pattern: "{controller=Home}/{action=Index}/{id?}");
        });
    }
}
      `}</code>
    </pre><br />

    <h4>Custom Error Handling for Production</h4>
    <p>
      In production, it's a good practice to handle exceptions by redirecting users to a friendly error page instead of showing raw error messages. This ensures that users do not see sensitive details about the application's internals.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class HomeController : Controller
{
    [Route("Home/Error")]
    public IActionResult Error()
    {
        return View(); // Returns a user-friendly error page
    }
}
      `}</code>
    </pre>

   <br />
    <h3>2. Custom Exception Handling Middleware</h3>
    <p>
      You can create custom middleware to handle exceptions globally in a more customized way. This is useful if you need to log exceptions, send notifications, or take other actions before displaying the error page.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class ExceptionHandlingMiddleware
{
    private readonly RequestDelegate _next;
    private readonly ILogger<ExceptionHandlingMiddleware> _logger;

    public ExceptionHandlingMiddleware(RequestDelegate next, ILogger<ExceptionHandlingMiddleware> logger)
    {
        _next = next;
        _logger = logger;
    }

    public async Task InvokeAsync(HttpContext context)
    {
        try
        {
            await _next(context);
        }
        catch (Exception ex)
        {
            _logger.LogError(ex, "An error occurred processing the request.");
            context.Response.Redirect("/Home/Error"); // Redirects to custom error page
        }
    }
}
      `}</code>
    </pre>

    <p>
      To use this custom middleware, you would add it to the request pipeline in the `Configure` method:
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app)
{
    app.UseMiddleware<ExceptionHandlingMiddleware>(); // Add custom middleware
    app.UseRouting();
    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllerRoute(
            name: "default",
            pattern: "{controller=Home}/{action=Index}/{id?}");
    });
}
      `}</code>
    </pre>

  <br />

    <h3>3. Exception Handling in Different Environments</h3>
    <p>
      ASP.NET Core provides an easy way to customize error handling based on the environment (Development, Staging, Production, etc.).
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage(); // Detailed error page for development
    }
    else if (env.IsProduction())
    {
        app.UseExceptionHandler("/Home/Error"); // Friendly error page for production
        app.UseHsts(); // Enforce HTTPS
    }
}
      `}</code>
    </pre>

    <p>
      The `UseDeveloperExceptionPage` middleware shows detailed error information, useful for debugging in development environments. In contrast, `UseExceptionHandler` ensures users are redirected to a custom error page in production.
    </p>

    <br />

    <h2>Exception Filters: A More Granular Approach</h2>
    <p>
      For finer control, ASP.NET Core also allows you to use exception filters. These filters can be applied to controllers or actions to handle exceptions more specifically.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class CustomExceptionFilter : IExceptionFilter
{
    private readonly ILogger<CustomExceptionFilter> _logger;

    public CustomExceptionFilter(ILogger<CustomExceptionFilter> logger)
    {
        _logger = logger;
    }

    public void OnException(ExceptionContext context)
    {
        _logger.LogError(context.Exception, "Unhandled exception occurred.");
        context.Result = new ViewResult { ViewName = "Error" }; // Display a custom error page
    }
}
      `}</code>
    </pre>

    <p>
      To apply the exception filter globally, register it in `ConfigureServices`:
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddControllersWithViews(options =>
    {
        options.Filters.Add(new CustomExceptionFilter());
    });
}
      `}</code>
    </pre>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Global Exception Handling</h2>
    <ul>
      <li><strong>Don't expose sensitive information:</strong> Avoid showing stack traces or internal error details to users, especially in production environments.</li><br />
      <li><strong>Use structured logging:</strong> Log exception details in a structured format for better analysis and troubleshooting.</li><br />
      <li><strong>Have a user-friendly error page:</strong> Create custom error pages that provide users with meaningful messages without compromising security.</li><br />
      <li><strong>Log errors appropriately:</strong> Make sure to log all exceptions with sufficient context (e.g., request details, user information) for easier debugging.</li>
    </ul>

   <br />

    <h2>Conclusion</h2>
    <p>
      Implementing global exception handling in ASP.NET Core ensures that errors are properly managed, improving security, user experience, and maintainability. By using the built-in middleware or custom exception filters, you can create a more resilient application that gracefully handles errors without exposing sensitive information to end users.
    </p>
  </div>
)}







{selectedChapter === 'chapter55' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Custom Error Pages and Status Code Pages  </h1>

    <p>
      Custom error pages and status code pages allow you to handle specific HTTP status codes (such as 404, 500, etc.) in a user-friendly and secure way. Rather than showing default server error messages, you can redirect users to custom error pages with helpful information or a better user experience.
    </p>

  <br />

    <h2 style={{paddingBottom:"6px"}}>Why Custom Error Pages and Status Code Pages?</h2>
    <ul>
    <li><strong>User Experience:</strong>  A friendly, branded error page can help keep users engaged, even when they encounter errors.</li><br />
    <li><strong>Security:</strong>  Custom error pages prevent exposing sensitive details about the internal workings of your application (e.g., stack traces).</li> <br />
    <li><strong>Branding:</strong> Custom error pages allow you to maintain consistent design across your application, even in error situations.</li>
    </ul>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Setting Up Custom Error Pages in ASP.NET Core</h2>

    <h3>1. Handling General Errors with UseExceptionHandler</h3>
    <p>
      ASP.NET Core provides a middleware for handling general errors and redirecting users to a custom error page. The `UseExceptionHandler` middleware catches unhandled exceptions and allows you to redirect the user to a designated error page.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage(); // Shows detailed error information for development
    }
    else
    {
        app.UseExceptionHandler("/Home/Error"); // Redirects to a custom error page
        app.UseHsts(); // Enforces HTTPS
    }

    app.UseRouting();
    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllerRoute(
            name: "default",
            pattern: "{controller=Home}/{action=Index}/{id?}");
    });
}
      `}</code>
    </pre>

    <p>
      In this example, if an unhandled exception occurs, the application redirects the user to the `Error` action of the `HomeController`.
    </p>

 <br />

    <h3>2. Handling Status Code Errors (e.g., 404, 500)</h3>
    <p>
      ASP.NET Core also allows you to handle specific HTTP status codes, such as 404 (Not Found) and 500 (Internal Server Error), using the `UseStatusCodePages` middleware. This middleware provides a way to display custom pages for specific HTTP status codes.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    // Handle general status codes (e.g., 404, 500)
    app.UseStatusCodePagesWithRedirects("/Home/StatusCodePage?code={0}");

    app.UseRouting();
    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllerRoute(
            name: "default",
            pattern: "{controller=Home}/{action=Index}/{id?}");
    });
}
      `}</code>
    </pre>

    <p style={{paddingBottom:"6px"}}>
      In this example, when a status code error (e.g., 404) occurs, the user is redirected to the `StatusCodePage` action of the `HomeController`, passing the status code as a query parameter.
    </p>

    <h4>Handling 404 Errors Specifically</h4>
    <p>
      You can handle specific status codes by creating custom pages for errors like 404. For example, you can create a "Page Not Found" error page for 404 errors:
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class HomeController : Controller
{
    public IActionResult StatusCodePage(int code)
    {
        if (code == 404)
        {
            return View("NotFound"); // Returns the custom 404 error page
        }
        else
        {
            return View("Error"); // Returns a general error page for other status codes
        }
    }
}
      `}</code>
    </pre>

  <br />

    <h2>Customizing Error Views</h2>
    <p>
      You can create custom views for each error type. For example, the `NotFound` view could be designed to provide information such as, "The page you are looking for does not exist."
    </p>

    <pre className={style.codeblock}>
      <code>{`
@{
    ViewData["Title"] = "Page Not Found";
}

<h1>404 - Page Not Found</h1>
<p>The page you are looking for might have been moved or deleted.</p>
      `}</code>
    </pre>

    <p>
      The above view is for a custom 404 error page. You can create similar views for other errors, such as 500 (server errors) or custom exceptions.
    </p>

<br />

    <h2>3. Custom Status Code Pages with Razor Pages</h2>
    <p>
      If you're using Razor Pages instead of MVC controllers, you can still configure status code pages by adding custom Razor Pages for each status code.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    // Handle status codes using Razor Pages
    app.UseStatusCodePagesWithReExecute("/Error/{0}");
}
      `}</code>
    </pre>

    <p>
      In this setup, you can create Razor Pages such as `Error/404.cshtml` and `Error/500.cshtml` to handle specific errors based on status codes.
    </p>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Custom Error Pages</h2>
    <ul>
      <li><strong>Provide useful information:</strong> Ensure the error pages give users some context, such as how to proceed (e.g., try a different page or contact support).</li><br />
      <li><strong>Maintain a user-friendly design:</strong> Make sure that error pages are designed in a way that fits the look and feel of the rest of the site.</li><br />
      <li><strong>Avoid exposing sensitive information:</strong> Error pages should not reveal stack traces or internal details about the system, especially in production.</li><br />
      <li><strong>Implement logging:</strong> Log the errors in a structured way (e.g., using a logging framework like Serilog, NLog, or Microsoft.Extensions.Logging) to track what caused the error.</li>
    </ul>

   <br />

    <h2>Conclusion</h2>
    <p>
      Custom error pages and status code pages enhance both security and the user experience by providing meaningful error information. By setting up `UseExceptionHandler` and `UseStatusCodePages`, you can control how your application handles various errors and status codes, offering a more graceful and professional user experience even in case of failures.
    </p>
  </div>
)}







{selectedChapter === 'chapter56' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Logging in ASP.NET Core (Built-in and Third-Party Loggers)</h1>

    <p>
      Logging is a crucial part of any application for tracking errors, performance issues, and general application behavior. ASP.NET Core provides a built-in logging system that allows developers to log events, handle exceptions, and capture critical application data. It also supports integration with third-party logging libraries for advanced features and more flexible configurations.
    </p>
<br />

    <h2>Why Logging is Important?</h2>
    <p>
      - **Diagnostics**: Helps diagnose issues by capturing errors, exceptions, and warnings.
      - **Audit Trails**: Keeps a record of events and actions taken in the application, useful for compliance and security.
      - **Monitoring**: Allows for monitoring application performance and behavior in real-time or over time.
      - **Debugging**: Logs help developers understand application behavior in different environments (development, production).
    </p>

   <br />

    <h2>Built-in Logging in ASP.NET Core</h2>
    <p style={{paddingBottom:"6px"}}>
      ASP.NET Core's built-in logging framework is highly configurable and supports various log levels, such as Information, Warning, Error, and Critical. It can log messages to multiple destinations, such as the console, debug output, files, and external services.
    </p>

    <h3>Configuring Built-in Logging</h3>
    <p>
      To configure logging in an ASP.NET Core application, you typically use the `ILogger` interface. It can be injected into controllers, services, or middleware to capture log messages.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class HomeController : Controller
{
    private readonly ILogger<HomeController> _logger;

    public HomeController(ILogger<HomeController> logger)
    {
        _logger = logger;
    }

    public IActionResult Index()
    {
        _logger.LogInformation("Index page visited.");
        return View();
    }

    public IActionResult Error()
    {
        _logger.LogError("An error occurred on the Error page.");
        return View();
    }
}
      `}</code>
    </pre>

    <p>
      In the above example, the `ILogger` instance is injected into the `HomeController`, and log messages are generated using methods such as `LogInformation` and `LogError`.
    </p>

   <br />

    <h3>Configuring Log Levels</h3>
    <p>
      ASP.NET Core supports multiple log levels that you can use to categorize the severity of the messages:
    </p>
    <ul>
      <li><strong>Trace</strong>: Fine-grained information used for debugging. Rarely enabled in production.</li><br />
      <li><strong>Debug</strong>: Information useful for debugging. It can be used to capture application flow.</li><br />
      <li><strong>Information</strong>: Used for normal application operations (e.g., user actions, page loads).</li><br />
      <li><strong>Warning</strong>: Indicating something unexpected happened, but the application can still continue.</li><br />
      <li><strong>Error</strong>: An error occurred that may disrupt the application’s functionality.</li><br />
      <li><strong>Critical</strong>: A severe error indicating the application might be unable to continue running.</li>
    </ul><br />

    <h3>Configuring Logging in `appsettings.json`</h3>
    <p>
      ASP.NET Core uses `appsettings.json` to configure logging providers and log levels. Here’s an example of how to configure logging for different log levels and log destinations:
    </p>
    <pre className={style.codeblock}>
      <code>{`
{
  "Logging": {
    "LogLevel": {
      "Default": "Information",
      "Microsoft": "Warning",
      "Microsoft.Hosting.Lifetime": "Information",
      "System": "Error"
    }
  }
}
      `}</code>
    </pre>

    <p>
      This configuration ensures that general logs are captured at the `Information` level, while Microsoft-specific logs are only logged at the `Warning` level or higher.
    </p>
<br />

    <h2>Third-Party Logging Providers</h2>
    <p style={{paddingBottom:"6px"}}>
      While ASP.NET Core provides robust built-in logging, many applications require advanced features like log aggregation, persistence, and real-time monitoring. Third-party logging libraries can extend ASP.NET Core's logging functionality by offering integrations with services such as AWS CloudWatch, Azure Monitor, or external log storage systems.
    </p>

    <h3>1. Serilog</h3>
    <p>
      Serilog is a popular third-party logging library that provides structured logging and is highly configurable. It allows logs to be written in JSON format, enabling better parsing and searching.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class Program
{
    public static IHostBuilder CreateHostBuilder(string[] args) =>
        Host.CreateDefaultBuilder(args)
            .ConfigureLogging((context, logging) =>
            {
                logging.ClearProviders();
                logging.AddSerilog(); // Add Serilog as a logging provider
            })
            .ConfigureWebHostDefaults(webBuilder =>
            {
                webBuilder.UseStartup<Startup>();
            });
}
      `}</code>
    </pre>

    <p>
      To use Serilog, you first need to install the Serilog NuGet package and configure it in the `Program.cs` file as shown above. You can then configure Serilog to log to various destinations such as files, databases, or even remote services.
    </p>

    <br />

    <h3>2. NLog</h3>
    <p>
      NLog is another third-party logging library that is highly configurable and can log messages to many destinations, including files, databases, and remote systems. It also offers advanced features such as logging to multiple targets simultaneously.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class Program
{
    public static IHostBuilder CreateHostBuilder(string[] args) =>
        Host.CreateDefaultBuilder(args)
            .ConfigureLogging((context, logging) =>
            {
                logging.ClearProviders();
                logging.AddNLog(); // Add NLog as a logging provider
            })
            .ConfigureWebHostDefaults(webBuilder =>
            {
                webBuilder.UseStartup<Startup>();
            });
}
      `}</code>
    </pre>

    <p>
      NLog configuration can be done in the `NLog.config` file, where you define the log targets (e.g., file, database) and log rules.
    </p>

    <br />

    <h3>3. Log4Net</h3>
    <p>
      Log4Net is a popular logging framework for .NET applications. It supports a wide range of logging outputs, including file-based logging, database logging, and more.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class Program
{
    public static IHostBuilder CreateHostBuilder(string[] args) =>
        Host.CreateDefaultBuilder(args)
            .ConfigureLogging((context, logging) =>
            {
                logging.ClearProviders();
                logging.AddLog4Net(); // Add Log4Net as a logging provider
            })
            .ConfigureWebHostDefaults(webBuilder =>
            {
                webBuilder.UseStartup<Startup>();
            });
}
      `}</code>
    </pre>

    <p>
      Log4Net is configured through an `App.config` or `log4net.config` file, where you define appenders and logging rules.
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Logging</h2>
    <ul>
      <li><strong>Use appropriate log levels:</strong> Avoid using overly verbose log levels in production (e.g., `Debug` or `Trace`). Use `Information` or higher for production environments.</li><br />
      <li><strong>Log useful information:</strong> Log information that helps diagnose issues, such as user actions, parameters, and error messages.</li><br />
      <li><strong>Don't log sensitive data:</strong> Be careful not to log sensitive data such as passwords, credit card numbers, or personally identifiable information (PII).</li><br />
      <li><strong>Aggregate logs:</strong> Use external services to aggregate and analyze logs (e.g., Serilog, Loggly, Splunk, or ELK stack).</li><br />
      <li><strong>Use structured logging:</strong> Structured logging formats, such as JSON, allow for easier querying and analysis of logs.</li>
    </ul>

   <br />

    <h2>Conclusion</h2>
    <p>
      Logging is an essential part of building robust, maintainable applications. ASP.NET Core's built-in logging system provides a flexible way to capture logs, while third-party logging libraries like Serilog, NLog, and Log4Net provide additional features such as structured logging, advanced configurations, and better integration with external systems. By following best practices and configuring the appropriate logging providers, you can ensure that your application remains transparent, debuggable, and easier to maintain.
    </p>
  </div>
)}



{selectedChapter === 'chapter57' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Application Insights for Monitoring</h1>

    <p>
      In modern web applications, error handling and logging are crucial for identifying and troubleshooting issues. However, relying solely on traditional logging techniques can sometimes be insufficient, especially for large-scale applications. This is where <strong>Application Insights</strong>, part of Azure Monitor, comes into play. It is a powerful monitoring and analytics tool that helps developers track application performance, diagnose errors, and gain insights into user behavior in real-time.
    </p><br />

    <h3>What is Application Insights?</h3>
    <p style={{paddingBottom:"6px"}}>
      <strong>Application Insights</strong> is a feature of Microsoft Azure that allows you to monitor the performance and health of your application, both on the server and client side. It collects telemetry data from your application, such as requests, exceptions, page views, and more, and allows you to analyze it to improve your application's performance and user experience.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Features of Application Insights:</h4>
    <ul>
      <li><strong>Real-Time Monitoring:</strong> Gain real-time insights into your application’s performance and usage.</li><br />
      <li><strong>Error Tracking:</strong> Detect and track exceptions and errors, with detailed stack traces and request data.</li><br />
      <li><strong>Performance Metrics:</strong> Track server performance, response times, and resource usage.</li><br />
      <li><strong>Usage Analytics:</strong> Analyze user behaviors such as page views, clicks, and custom events.</li><br />
      <li><strong>Alerts and Dashboards:</strong> Set up alerts for specific conditions and create custom dashboards to visualize data.</li>
    </ul><br />

    <h3>Integrating Application Insights</h3>
    <p style={{paddingBottom:"6px"}}>
      Application Insights can be integrated with your application in a variety of ways, including client-side (JavaScript, React, etc.) and server-side (ASP.NET, Node.js, Java, etc.). For .NET applications, the integration is straightforward using the <strong>Application Insights SDK</strong>.
    </p>

    <h4>1. Install the Application Insights SDK:</h4>
    <pre>
      <code>
        Install-Package Microsoft.ApplicationInsights.AspNetCore
      </code>
    </pre><br />

    <h4>2. Configure Application Insights:</h4>
    <p>
      After installation, you need to configure Application Insights in your application by adding the instrumentation key (which you get from the Azure portal) to your application's settings (such as `appsettings.json` for ASP.NET Core).
    </p>
    <pre>
      <code>
        {`"ApplicationInsights": {`}
        {'\n'}{`  "InstrumentationKey": "<your_instrumentation_key>"`}
        {'\n'}{`}`}
      </code>
    </pre><br />

    <h4>3. Telemetry Initialization:</h4>
    <p>
      Once you have set up the instrumentation key, the SDK automatically starts sending telemetry data. You can also send custom telemetry events, log custom errors, and track performance metrics manually by using the API.
    </p>
    <pre style={{paddingBottom:"6px"}}>
      <code>
        var telemetryClient = new TelemetryClient(); {'\n'}
        telemetryClient.TrackEvent("MyCustomEvent"); {'\n'}
        telemetryClient.TrackException(exception);
      </code>
    </pre><br />

    <h3>Monitoring with Application Insights</h3>
    <p style={{paddingBottom:"6px"}}>
      After setting up Application Insights, you can monitor and analyze your application's performance, usage, and exceptions via the Azure portal. The portal provides rich analytics and visualizations to help you understand the health of your application.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Monitoring Capabilities:</h4>
    <ul>
      <li><strong>Live Metrics Stream:</strong> View live telemetry as it arrives, including request counts, response times, and errors.</li><br />
      <li><strong>Performance Profiler:</strong> Identify slow dependencies, database queries, and other performance bottlenecks.</li><br />
      <li><strong>Distributed Tracing:</strong> Trace the flow of requests across microservices and applications.</li><br />
      <li><strong>Failure Diagnostics:</strong> Investigate issues using detailed stack traces and correlated requests and exceptions.</li>
    </ul><br />

    <h3>Setting up Alerts</h3>
    <p style={{paddingBottom:"6px"}}>
      Application Insights allows you to set up automated alerts to notify you when certain conditions are met, such as high error rates, slow response times, or specific custom events. These alerts can be configured to send notifications via email, SMS, or webhook.
    </p>

    <h4>Example: Creating an Alert for Errors</h4>
    <ol>
      <li>Go to the Application Insights resource in the Azure portal.</li>
      <li>Navigate to the "Alerts" section.</li>
      <li>Click "New alert rule" and set the condition to track exceptions.</li>
      <li>Configure the alert threshold (e.g., error count greater than 5 in the last 5 minutes).</li>
      <li>Set up your notification preferences.</li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices for Using Application Insights</h3>
    <ul>
      <li><strong>Track Only Relevant Data:</strong> Avoid overloading Application Insights with unnecessary telemetry data. Focus on key metrics and events that matter for your app's performance and user experience.</li><br />
      <li><strong>Use Sampling:</strong> For high-traffic applications, enable telemetry sampling to avoid excessive data volume and cost.</li><br />
      <li><strong>Set Up Alerts Early:</strong> Set up alerts to proactively catch issues, such as performance degradation or errors, before they impact users.</li><br />
      <li><strong>Analyze Trends:</strong> Use Application Insights' built-in analytics tools to identify long-term trends in performance and usage, helping to guide optimizations.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Application Insights is a powerful tool for monitoring your application's performance, diagnosing errors, and gaining deep insights into user behavior. By leveraging its capabilities, developers can ensure their applications run smoothly, improve user experience, and troubleshoot issues effectively.
    </p>

  </div>
)}





{selectedChapter === 'chapter58' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Working with File Uploads in ASP.NET Core  </h1>

    <p>
      File uploads are a common feature in many web applications, allowing users to upload images, documents, and other types of files. ASP.NET Core provides a flexible and secure way to handle file uploads through its built-in features. This guide will walk through the process of handling file uploads, including the necessary setup, common practices, and how to manage file storage effectively in an ASP.NET Core application.
    </p><br />

    <h3>Setting Up File Upload in ASP.NET Core</h3>
    <p style={{paddingBottom:"6px"}}>
      To allow file uploads in an ASP.NET Core application, you need to create an API or form that can accept file submissions. The file data is sent from the client to the server as part of a multipart/form-data HTTP request.
    </p>

    <h4>1. Creating a Form to Upload Files</h4>
    <p>
      For a simple file upload form, you can use HTML and Razor to create a form that sends the file to the server via POST request. Here’s a basic form:
    </p>
    <pre>
      <code>
        <form method="post" enctype="multipart/form-data">
            <input type="file" name="file" />
            <button type="submit">Upload</button>
        </form>
      </code>
    </pre>
    <p>
      The <code>enctype="multipart/form-data"</code> attribute is necessary for submitting file data along with other form fields.
    </p><br />

    <h4>2. Configuring the Controller to Handle File Uploads</h4>
    <p>
      You will need to create an action in the controller that accepts the uploaded file and processes it. This can be done using the <code>IFormFile</code> interface provided by ASP.NET Core. The file will be accessible from the controller action as a parameter.
    </p><br />

    <h3>Uploading Files in the Controller</h3>
    <p style={{paddingBottom:"6px"}}>
      The example below shows how to handle file uploads in a controller action using <code>IFormFile</code>:
    </p>
    <pre>
      <code>{`
        public async Task<IActionResult> UploadFile(IFormFile file)
        {
            if (file != null && file.Length > 0)
            {
                var filePath = Path.Combine(Directory.GetCurrentDirectory(), "wwwroot", "uploads", file.FileName);

                using (var stream = new FileStream(filePath, FileMode.Create))
                {
                    await file.CopyToAsync(stream);
                }

                return Ok(new { FilePath = filePath });
            }
            return BadRequest("No file uploaded");
        }
       `} </code>
    </pre>
    <p>
      - In this example, the file is saved in the "wwwroot/uploads" directory.
      - The file's name is retained, but you can modify the path or rename the file if needed.
      - The method returns an HTTP status code indicating whether the upload was successful.
    </p><br />

    <h4>Handling File Validation</h4>
    <p>
      You should validate the uploaded files for security reasons. Common validations include checking the file type, size, and extension. This can help prevent malicious files from being uploaded to the server.
    </p>
    <pre>
      <code>{`
        var allowedExtensions = new[] { ".jpg", ".jpeg", ".png", ".gif" };
        var extension = Path.GetExtension(file.FileName).ToLower();

        if (!allowedExtensions.Contains(extension))
        {
            return BadRequest("Invalid file type");
        }

        if (file.Length > 5 * 1024 * 1024) // 5 MB limit
        {
            return BadRequest("File size exceeds the maximum allowed size (5MB)");
        }
      `}</code>
    </pre>
    <p>
      This checks if the file's extension is one of the allowed types and ensures the file size does not exceed a specified limit.
    </p><br />

    <h3>Saving Files to a Storage Location</h3>
    <p>
      Files are typically saved either on the server's local file system or to cloud storage services like AWS S3 or Azure Blob Storage. For the sake of simplicity, we'll save the files locally in the "wwwroot/uploads" directory in this example. However, for production applications, consider using a cloud storage service or a dedicated file server for scalability and reliability.
    </p><br />

    <h3>Handling Multiple File Uploads</h3>
    <p>
      ASP.NET Core also supports handling multiple file uploads in a single request. This is done by accepting a collection of <code>IFormFile</code> objects.
    </p>
    <pre>
      <code>{`
        public async Task<IActionResult> UploadFiles(List<IFormFile> files)
        {
            if (files != null && files.Count > 0)
            {
                foreach (var file in files)
                {
                    var filePath = Path.Combine(Directory.GetCurrentDirectory(), "wwwroot", "uploads", file.FileName);

                    using (var stream = new FileStream(filePath, FileMode.Create))
                    {
                        await file.CopyToAsync(stream);
                    }
                }
                return Ok(new { Message = "Files uploaded successfully!" });
            }
            return BadRequest("No files uploaded");
        }
      `}</code>
    </pre>

    <p>
      This action receives a list of files and processes each one, saving them to the "uploads" folder.
    </p>
<br />

    <h3>Security Considerations</h3>
    <p>
      File uploads can introduce significant security risks if not properly managed. Here are a few security practices:
    </p>
    <ul>
      <li><strong>File Type Validation:</strong> Always validate the file extension and MIME type to ensure only safe files are allowed.</li><br />
      <li><strong>File Size Limits:</strong> Limit the size of the uploaded files to avoid excessive use of server resources.</li><br />
      <li><strong>Store Files Outside the Web Root:</strong> For extra security, store files outside the "wwwroot" folder to prevent direct access from the web.</li><br />
      <li><strong>Antivirus Scanning:</strong> Consider scanning uploaded files for viruses and malicious content before saving them.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      ASP.NET Core makes it simple to handle file uploads through its built-in support for the <code>IFormFile</code> interface. By setting up file upload forms, processing files in controllers, and handling validation and security measures, developers can build robust and secure file upload functionalities for their applications. For larger applications or applications requiring high scalability, it's recommended to use cloud storage solutions.
    </p>
 


    </div>
)}



{selectedChapter === 'chapter59' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Saving and Retrieving Files from a Database</h1>

  
    <p>
      In many web applications, users need to upload files, such as images, documents, and PDFs. These files can be stored in various places, such as the file system or cloud storage, but in some cases, it is necessary to store files directly in a database. Storing files in a database offers benefits like easy backup and restore, enhanced security, and simplified management in terms of consistency and transactions. This process typically involves saving files as binary data in the database and retrieving them when needed.
    </p><br />

    <h3>How to Store Files in a Database</h3>
    <p style={{paddingBottom:"6px"}}>
      To store a file in a database, you need to convert the file into a binary format (such as a byte array), which can be inserted into the database. Most relational databases have a special data type for storing binary data, often called <strong>BLOB (Binary Large Object)</strong>.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Steps to Save Files into the Database:</h4>
    <ol>
      <li><strong>Choose a Storage Field</strong>: Use a field with a BLOB or VARBINARY data type in your database to store the file.</li><br />
      <li><strong>File Upload Interface</strong>: Provide a form or API endpoint for the user to upload the file.</li><br />
      <li><strong>Convert the File to Binary</strong>: Read the file as binary data in your application code.</li><br />
      <li><strong>Insert into the Database</strong>: Insert the binary data into the BLOB field of your database.</li>
    </ol><br />

    <h3>Saving Files to the Database - Example</h3>
    <p>
      Below is an example of how to upload and save a file to a SQL Server database using C# and ASP.NET Core.
    </p>
    <pre>
      <code>{`
        using (var stream = file.OpenReadStream()) 
        {
            byte[] fileBytes = new byte[file.Length];
            stream.Read(fileBytes, 0, fileBytes.Length);
            
            using (var context = new YourDbContext())
            {
                var fileEntity = new FileEntity
                {
                    FileName = file.FileName,
                    FileData = fileBytes
                };

                context.Files.Add(fileEntity);
                context.SaveChanges();
            }
        }
      `}</code>
    </pre><br />

    <h3>Retrieving Files from the Database</h3>
    <p style={{paddingBottom:"6px"}}>
      To retrieve a file, you need to read the binary data from the database and convert it back into a file format that can be used by the application. In most cases, you’ll return the binary data as a stream and allow the user to download or view the file.
    </p>

    <h4>Steps to Retrieve Files from the Database:</h4>
    <ol>
      <li><strong>Query the Database</strong>: Fetch the file record from the database by querying the BLOB column.</li><br />
      <li><strong>Convert Binary Data to File</strong>: Read the binary data and convert it into a file format that can be used.</li><br />
      <li><strong>Return the File</strong>: Return the file as a downloadable resource or display it in the user interface.</li>
    </ol><br />

    <h3>Retrieving Files from the Database - Example</h3>
    <p>
      The following code demonstrates how to retrieve a file from a database and send it as a file download response in an ASP.NET Core application.
    </p>
    <pre>
      <code>{`
        public async Task<IActionResult> GetFile(int id)
        {
            var fileEntity = await _context.Files.FindAsync(id);
            
            if (fileEntity == null)
                return NotFound();

            return File(fileEntity.FileData, "application/octet-stream", fileEntity.FileName);
        }
       `} </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Considerations and Best Practices</h3>
    <ul>
      <li><strong>File Size Limitations:</strong> Storing large files in the database can impact performance. Always ensure there are reasonable size limits for uploaded files.</li><br />
      <li><strong>Database Performance:</strong> For large volumes of files, it is recommended to use a file storage solution in combination with the database, such as storing the files on disk or cloud storage and only saving the file path in the database.</li><br />
      <li><strong>Security:</strong> Ensure that files are scanned for viruses and malicious content before being stored in the database.</li><br />
      <li><strong>Access Control:</strong> Protect file retrieval endpoints and ensure only authorized users can access specific files.</li>
    </ul><br />

    <h3>Alternatives to Storing Files in the Database</h3>
    <p>
      While storing files in a database can be beneficial in some cases, there are alternatives that might better suit certain applications, such as:
    </p>
    <ul>
      <li><strong>File System Storage:</strong> Store files on the server or cloud storage and keep a reference to the file location in the database.</li><br />
      <li><strong>Cloud Storage Solutions:</strong> Use cloud storage services like AWS S3, Azure Blob Storage, or Google Cloud Storage, which are optimized for large-scale file storage.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Saving and retrieving files from a database can provide several benefits, including better control and security over file storage. However, developers must consider performance, scalability, and security implications when choosing whether to store files directly in the database. With proper techniques and best practices, storing files in a database can be an efficient solution for many applications.
    </p>
  </div>
)}





{selectedChapter === 'chapter60' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Secure File Uploading    </h1>

    <p>
      File uploading is a common feature in web applications, but it also poses several security risks. Malicious users can upload harmful files (e.g., malware, scripts) that can compromise your system. Therefore, it's crucial to implement secure file uploading practices to mitigate potential threats.
    </p>

<br />

    <h2>Why Secure File Uploading is Important</h2>
    <p>
      The ability to upload files opens up potential security vulnerabilities if not properly controlled. Some of the risks associated with file uploading include:
    </p>
    <ul>
      <li><strong>Malware Upload:</strong> Users might upload files containing viruses or malware.</li><br />
      <li><strong>Script Execution:</strong> Malicious users could upload executable files that could run server-side scripts.</li><br />
      <li><strong>Overwriting Files:</strong> Users could upload files that overwrite important server files.</li><br />
      <li><strong>File Size Limits:</strong> Without restrictions, attackers might upload very large files, consuming server resources.</li>
    </ul>
<br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Secure File Uploading</h2>

    <h3>1. Validate File Extensions</h3>
    <p>
      Only allow certain file types (e.g., images, PDFs) to be uploaded. Limiting the file extensions can prevent users from uploading potentially dangerous files like scripts or executable files.
    </p>
    <pre className={style.codeblock}>
      <code>{`
string[] allowedExtensions = { ".jpg", ".jpeg", ".png", ".gif", ".pdf" };
string fileExtension = Path.GetExtension(file.FileName).ToLower();
if (!allowedExtensions.Contains(fileExtension))
{
    return BadRequest("Invalid file type.");
}
      `}</code>
    </pre>
    <p>
      In this example, only image files and PDFs are allowed for upload. Any other file extension will result in an error message.
    </p><br />

    <h3>2. Validate File Size</h3>
    <p>
      Limit the size of files that can be uploaded to avoid excessive disk usage and resource exhaustion. ASP.NET Core provides a simple way to restrict file size in the configuration.
    </p>
    <pre className={style.codeblock}>
      <code>{`
services.Configure<FormOptions>(options =>
{
    options.MultipartBodyLengthLimit = 10 * 1024 * 1024; // 10 MB
});
      `}</code>
    </pre>
    <p>
      In this example, the maximum allowed file size is set to 10MB. Larger files will be rejected by the server.
    </p><br />

    <h3>3. Use Strong Antivirus Scanning</h3>
    <p>
      For enhanced security, scan uploaded files for malware using antivirus software or a file scanning service. Integrate antivirus checks into your file upload process to ensure files are safe before storing or processing them.
    </p>
    <p>
      You can use services like <strong>VirusTotal</strong> or integrate an antivirus API to scan files upon upload.
    </p><br />

    <h3>4. Store Files Outside the Web Root</h3>
    <p>
      Files should be stored outside the web root (i.e., outside the public folder) to prevent direct access by users. If users can access uploaded files via a URL, they might be able to execute malicious scripts or view sensitive files.
    </p>
    <pre className={style.codeblock}>
      <code>{`
string uploadDirectory = Path.Combine(Directory.GetCurrentDirectory(), "UploadedFiles");
string filePath = Path.Combine(uploadDirectory, file.FileName);
if (!Directory.Exists(uploadDirectory))
{
    Directory.CreateDirectory(uploadDirectory);
}
using (var fileStream = new FileStream(filePath, FileMode.Create))
{
    await file.CopyToAsync(fileStream);
}
      `}</code>
    </pre>
    <p>
      In this example, files are stored in the "UploadedFiles" directory, which is outside the web root. This ensures that users can't directly access the files via URLs.
    </p><br />

    <h3>5. Rename Uploaded Files</h3>
    <p>
      Rename files upon upload to avoid naming conflicts and to prevent malicious users from overwriting important files. You can use GUIDs or unique timestamps to generate safe file names.
    </p>
    <pre className={style.codeblock}>
      <code>{`
string uniqueFileName = Guid.NewGuid().ToString() + Path.GetExtension(file.FileName);
string filePath = Path.Combine(uploadDirectory, uniqueFileName);
using (var fileStream = new FileStream(filePath, FileMode.Create))
{
    await file.CopyToAsync(fileStream);
}
      `}</code>
    </pre>
    <p>
      In this case, each uploaded file is renamed with a unique GUID, ensuring no file name conflicts or overwrites occur.
    </p><br />

    <h3>6. Use Content-Type Validation</h3>
    <p>
      Content-type validation checks that the file's MIME type matches its extension. This can help prevent attackers from uploading files with dangerous extensions that are disguised as safe files.
    </p>
    <pre className={style.codeblock}>
      <code>{`
if (file.ContentType != "image/jpeg" && file.ContentType != "image/png")
{
    return BadRequest("Invalid file type.");
}
      `}</code>
    </pre>
    <p>
      In this example, only JPEG and PNG image files are allowed based on their MIME type.
    </p><br />

    <h3>7. Implement User Authentication and Authorization</h3>
    <p>
      Ensure that only authorized users can upload files. If your application allows file uploads as part of user accounts or roles, implement authentication and authorization to control who can upload files.
    </p>
    <pre className={style.codeblock}>
      <code>{`
[Authorize]
[HttpPost]
public async Task<IActionResult> UploadFile(IFormFile file)
{
    // Upload logic here...
}
      `}</code>
    </pre>
    <p>
      In this case, only authenticated users are allowed to upload files. You can also add role-based checks to restrict uploads to specific users.
    </p>

  <br />

    <h2>Handling Errors and Edge Cases</h2>
    <p>
      Always handle errors and edge cases such as:
    </p>
    <ul>
      <li>File upload exceeding the size limit.</li><br />
      <li>Invalid file types.</li><br />
      <li>File read/write errors.</li><br />
      <li>Network issues during file upload.</li>
    </ul>
    <p>
      Use try-catch blocks to gracefully handle unexpected errors and provide feedback to users.
    </p>

    <br />

    <h2>Conclusion</h2>
    <p>
      Secure file uploading is essential to protect your application from various security risks. By implementing best practices such as validating file extensions, using antivirus scanning, restricting file sizes, and storing files securely, you can significantly reduce the risk of file upload vulnerabilities. Always keep security a top priority when handling user-generated content.
    </p>

  </div>
)}

{selectedChapter === 'chapter61' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Streaming Files (e.g., PDFs, Images)  </h1>

    <p>
      In web applications, serving files such as PDFs, images, and other media content directly to users is a common requirement. Streaming allows you to send the content to users without loading the entire file into memory, which can be particularly useful for large files or when serving files from remote sources.
    </p>

   <br />

    <h2>What is File Streaming?</h2>
    <p>
      File streaming is the process of transmitting a file in a continuous stream of data. This method allows for efficient delivery of content, enabling users to start viewing or downloading the file before the entire file is transmitted. It is especially important when dealing with large files, like videos or large PDFs, where you don't want to load the entire file into memory at once.
    </p>
    <p>
      ASP.NET Core provides a built-in mechanism for streaming files using the <code>FileStreamResult</code> and <code>File</code> helper methods.
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Why Use File Streaming?</h2>
    <ul>
      <li><strong>Memory Efficiency:</strong> Streaming files helps avoid loading large files into memory, reducing the strain on server resources.</li><br />
      <li><strong>Progressive Download:</strong> Users can begin interacting with files (e.g., viewing images or PDFs) without waiting for the entire file to download.</li><br />
      <li><strong>Large File Support:</strong> Streaming is essential when dealing with large files (e.g., videos, PDFs) that could overwhelm the server's memory if fully loaded.</li><br />
      <li><strong>Content-Type Flexibility:</strong> With streaming, you can serve different types of media based on file types, such as images, PDFs, or other formats.</li>
    </ul>

   <br />

    <h2>Serving Files with FileStreamResult</h2>
    <p>
      In ASP.NET Core, <code>FileStreamResult</code> is used to stream a file to the client. The method allows for transmitting large files, without consuming a lot of memory.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public IActionResult DownloadFile(string fileName)
{
    var filePath = Path.Combine(Directory.GetCurrentDirectory(), "Files", fileName);
    
    if (!System.IO.File.Exists(filePath))
    {
        return NotFound();
    }
    
    var fileStream = new FileStream(filePath, FileMode.Open);
    return new FileStreamResult(fileStream, "application/pdf");
}
      `}</code>
    </pre>
    <p>
      In the example above, a PDF file is streamed to the client. The <code>FileStreamResult</code> returns the file stream with the appropriate content type (e.g., "application/pdf").
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Parameters of <code>FileStreamResult</code>:</h3>
    <ul>
      <li><strong>Stream:</strong> The file content, passed as a <code>FileStream</code> or any other stream.</li><br />
      <li><strong>Content-Type:</strong> The MIME type of the file (e.g., "application/pdf" for PDFs or "image/jpeg" for JPEG images).</li>
    </ul>

   <br />

    <h2>Streaming Images</h2>
    <p>
      Streaming images works similarly to other files, but you can specify the image's MIME type based on the file extension.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public IActionResult GetImage(string imageName)
{
    var imagePath = Path.Combine(Directory.GetCurrentDirectory(), "Images", imageName);
    
    if (!System.IO.File.Exists(imagePath))
    {
        return NotFound();
    }
    
    var imageFileStream = new FileStream(imagePath, FileMode.Open);
    return new FileStreamResult(imageFileStream, "image/jpeg");
}
      `}</code>
    </pre>
    <p>
      In this example, an image is streamed as a JPEG file. You can change the MIME type to fit other image formats (e.g., "image/png" for PNG images).
    </p>

    <br />
    <h2>Streaming Large Files with <code>FileStream</code> and Range Requests</h2>
    <p>
      For very large files, it's often necessary to support <strong>range requests</strong> to allow the user to download the file in chunks. This is important when users need to download specific portions of a file (e.g., seeking to a position in a video or large PDF).
    </p>
    <p>
      Range requests allow clients to request specific byte ranges of a file. The server can respond with the appropriate byte range, enabling the client to download only a part of the file at a time.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public IActionResult DownloadLargeFile(string fileName)
{
    var filePath = Path.Combine(Directory.GetCurrentDirectory(), "LargeFiles", fileName);
    
    if (!System.IO.File.Exists(filePath))
    {
        return NotFound();
    }
    
    var file = new FileInfo(filePath);
    var fileStream = new FileStream(filePath, FileMode.Open, FileAccess.Read);
    
    var fileBytes = new byte[file.Length];
    fileStream.Read(fileBytes, 0, fileBytes.Length);
    
    return File(fileBytes, "application/octet-stream");
}
      `}</code>
    </pre>
    <p>
      This example demonstrates how you could implement file streaming. However, to fully implement range requests, additional logic is needed to handle partial downloads by checking the HTTP request headers for <code>Range</code>.
    </p><br />

    <h3>Handling Range Requests</h3>
    <p>
      To handle range requests properly, inspect the <code>Range</code> header in the request and return the appropriate byte range of the file:
    </p>
    <pre className={style.codeblock}>
      <code>{`
public IActionResult StreamFileWithRange(string fileName)
{
    var filePath = Path.Combine(Directory.GetCurrentDirectory(), "LargeFiles", fileName);
    
    if (!System.IO.File.Exists(filePath))
    {
        return NotFound();
    }

    var file = new FileInfo(filePath);
    var rangeHeader = Request.Headers["Range"];
    
    // Extract range (e.g., bytes=0-1024) from the Range header
    if (rangeHeader.Count > 0)
    {
        var range = rangeHeader.ToString().Substring(6).Split('-');
        long startByte = long.Parse(range[0]);
        long endByte = range.Length > 1 ? long.Parse(range[1]) : file.Length - 1;
        
        var fileStream = new FileStream(filePath, FileMode.Open, FileAccess.Read);
        var buffer = new byte[endByte - startByte + 1];
        fileStream.Seek(startByte, SeekOrigin.Begin);
        fileStream.Read(buffer, 0, buffer.Length);
        
        return File(buffer, "application/octet-stream", fileName);
    }
    else
    {
        // No range requested, stream the entire file
        var fileStream = new FileStream(filePath, FileMode.Open, FileAccess.Read);
        return new FileStreamResult(fileStream, "application/octet-stream");
    }
}
      `}</code>
    </pre>
    <p>
      This example shows a simple implementation of range requests for file streaming, allowing clients to download parts of a large file based on the "Range" header.
    </p>

  <br />

    <h2 style={{paddingBottom:"6px"}}>Considerations for Streaming Files</h2>
    <ul>
      <li><strong>File Size:</strong> Streaming is most beneficial for large files. For small files, loading the entire file in memory might be more efficient.</li><br />
      <li><strong>Concurrency:</strong> Ensure your server can handle multiple simultaneous file streams, especially for large files.</li><br />
      <li><strong>Security:</strong> Ensure proper file validation (e.g., file extension, size, MIME type) to prevent malicious uploads.</li><br />
      <li><strong>Performance:</strong> Use appropriate caching mechanisms to optimize repeated requests for the same file.</li>
    </ul>

   <br />

    <h2>Conclusion</h2>
    <p>
      Streaming files, such as PDFs, images, and videos, is a crucial feature for many web applications. ASP.NET Core provides easy-to-use methods for file streaming, enabling you to serve content efficiently and securely. By using <code>FileStreamResult</code> and supporting range requests, you can ensure that users can access large files without overloading your server's memory. Always consider security and performance best practices when implementing file streaming in your application.
    </p>
 

  </div>
)}

{selectedChapter === 'chapter62' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Attribute Routing in ASP.NET Core MVC  </h1>

    <p>
      Attribute routing in ASP.NET Core MVC is a feature that allows developers to define routes directly on action methods or controller classes using attributes. This provides a more declarative way of mapping HTTP requests to controller actions, allowing for greater control and flexibility in defining routes.
    </p>

<br />

    <h2>What is Attribute Routing?</h2>
    <p>
      In traditional routing (convention-based routing), routes are defined in the <code>Startup.cs</code> class in the <code>Configure</code> method, and they map incoming requests to controllers and actions. However, attribute routing allows routes to be defined directly on controller classes and action methods using attributes, which gives developers more fine-grained control over URL patterns.
    </p>
    <p>
      Attribute routing is particularly useful when you want to define more complex, hierarchical, or RESTful routes for your application.
    </p>

 <br />

    <h2>Enabling Attribute Routing</h2>
    <p>
      Attribute routing is enabled by default in ASP.NET Core MVC. There’s no need for additional configuration. You simply need to use the <code>[Route]</code> attribute on controllers and actions to specify how URLs should be mapped to them.
    </p>

    <pre className={style.codeblock}>
      <code>{`
public class ProductsController : Controller
{
    [Route("products/{id}")]
    public IActionResult Details(int id)
    {
        // Logic for displaying product details by id
        return View();
    }
}
      `}</code>
    </pre>
    <p>
      In this example, the <code>{`[Route("products/{id}")]`}</code> attribute maps a URL like <code>/products/1</code> to the <code>Details</code> action method.
    </p>

  <br />

    <h2>Using Placeholders in Attribute Routes</h2>
    <p>
      Attribute routes can include placeholders, which represent dynamic segments of the URL. These placeholders are surrounded by curly braces and can capture values from the URL that are passed as parameters to action methods.
    </p>

    <pre className={style.codeblock}>
      <code>{`
[Route("products/{category}/{id}")]
public IActionResult CategoryDetails(string category, int id)
{
    // Logic for displaying product details for a specific category
    return View();
}
      `}</code>
    </pre>
    <p>
      In this example, the route <code>{`products/{category}/{id}`}</code> captures the category and id values from the URL. For example, the URL <code>/products/electronics/1</code> will invoke the <code>CategoryDetails</code> action with <code>category="electronics"</code> and <code>id=1</code>.
    </p>

   <br />

    <h2>Customizing Routes with HTTP Methods</h2>
    <p>
      You can also specify which HTTP methods (GET, POST, PUT, DELETE, etc.) an action method should handle using attributes like <code>[HttpGet]</code>, <code>[HttpPost]</code>, and so on. This allows you to create routes that are specific to certain types of HTTP requests.
    </p>
    <pre className={style.codeblock}>
      <code>{`
[Route("products/{id}")]
[HttpGet]
public IActionResult GetProduct(int id)
{
    // Logic for getting product by id
    return View();
}

[Route("products/{id}")]
[HttpPost]
public IActionResult CreateProduct(int id, Product product)
{
    // Logic for creating a product
    return RedirectToAction("Details", new { id });
}
      `}</code>
    </pre>
    <p>
      In this example, the <code>GetProduct</code> action responds to GET requests, while the <code>CreateProduct</code> action responds to POST requests for the same route.
    </p>

    <br />

    <h2>Route Prefix with Controller-Level Attribute</h2>
    <p>
      You can define a route prefix at the controller level, which will be applied to all actions within that controller. This is particularly useful for grouping related actions under a common URL structure.
    </p>
    <pre className={style.codeblock}>
      <code>{`
[Route("products")]
public class ProductsController : Controller
{
    [Route("{id}")]
    public IActionResult Details(int id)
    {
        // Logic for displaying product details by id
        return View();
    }

    [Route("create")]
    public IActionResult Create()
    {
        // Logic for creating a new product
        return View();
    }
}
      `}</code>
    </pre>
    <p>
      In this example, the route prefix <code>products</code> is applied to all actions in the <code>ProductsController</code>. So, the <code>Details</code> action will respond to the route <code>{`/products/{id}`}</code>, and the <code>Create</code> action will respond to <code>/products/create</code>.
    </p>

    <br />

    <h2>Route Order and Specificity</h2>
    <p>
      When defining multiple routes on different actions, the order in which they are defined can impact routing. Routes should be defined in order of specificity, with more specific routes placed before more general ones.
    </p>
    <pre className={style.codeblock}>
      <code>{`
[Route("products/{id}")]
public IActionResult Details(int id)
{
    // Logic for displaying a product by id
    return View();
}

[Route("products")]
public IActionResult Index()
{
    // Logic for displaying all products
    return View();
}
      `}</code>
    </pre>
    <p>
      In this case, the route for <code>{`products/{id}`}</code> is more specific, so it should be listed before the route for <code>products</code> to avoid confusion.
    </p>
<br />
    

    <h2>Using Named Routes</h2>
    <p>
      You can assign a name to a route using the <code>name</code> parameter in the <code>[Route]</code> attribute. Named routes are useful for generating URLs dynamically in your application, especially when using the <code>RedirectToAction</code> or <code>Url.Action</code> methods.
    </p>
    <pre className={style.codeblock}>
      <code>{`
[Route("products/{id}", Name = "product_details")]
public IActionResult Details(int id)
{
    // Logic for displaying product details
    return View();
}

// Generating URL for the "product_details" route
var url = Url.RouteUrl("product_details", new { id = 1 });
      `}</code>
    </pre>
    <p>
      In this example, the route for displaying product details has a name "product_details." You can generate the URL for this route by using the <code>Url.RouteUrl</code> method and passing the route name and parameters.
    </p>

    <br />

    <h2>Conclusion</h2>
    <p>
      Attribute routing is a powerful feature in ASP.NET Core MVC that allows you to define and control routes more declaratively. It provides flexibility in defining complex, RESTful URLs and helps keep route definitions close to the relevant action methods. By combining attribute routing with HTTP method-specific attributes, route prefixes, and named routes, you can build more maintainable and organized routing configurations for your ASP.NET Core MVC applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter63' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  URL Rewriting and Redirection  </h1>

    <p>
      URL rewriting and redirection are essential techniques for managing URLs in web applications. In ASP.NET Core, these features help in manipulating URLs to direct users or search engines to specific pages, enforce SEO strategies, or ensure compatibility when URL patterns change.
    </p>
<br />

    <h2>What is URL Rewriting?</h2>
    <p>
      URL rewriting refers to the process of modifying a requested URL before the request reaches the target page. This can be used for a variety of reasons, such as simplifying URLs, improving SEO, or mapping old URLs to new ones without breaking existing links.
    </p>
    <p>
      In ASP.NET Core, URL rewriting can be done using the <code>RewriteOptions</code> class, which provides functionality to define rules for rewriting URLs based on patterns and conditions.
    </p>

   <br />

    <h2>Configuring URL Rewriting in ASP.NET Core</h2>
    <p>
      To implement URL rewriting in ASP.NET Core, you need to add the <code>Microsoft.AspNetCore.Rewrite</code> package and configure the <code>RewriteOptions</code> in the <code>Startup.cs</code> file.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app)
{
    var options = new RewriteOptions()
        .AddRewrite(@"^old-page$", "new-page", skipRemainingRules: true)
        .AddRewrite(@"^products/(\d+)$", "products/details/$1", skipRemainingRules: true);

    app.UseRewriter(options);
    app.UseRouting();
    app.UseEndpoints(endpoints => { });
}
      `}</code>
    </pre>
    <p>
      In this example, the first rule rewrites requests for <code>/old-page</code> to <code>/new-page</code>, while the second rule rewrites product IDs in the URL (e.g., <code>/products/123</code>) to a more readable format like <code>/products/details/123</code>.
    </p>

  <br />

    <h2>Rewriting URLs with Regex Patterns</h2>
    <p>
      URL rewriting in ASP.NET Core is often based on regular expressions (regex), allowing you to create flexible and powerful rules. You can capture parts of the URL in parentheses and then refer to them in the target URL.
    </p>
    <pre className={style.codeblock}>
      <code>{`
.AddRewrite(@"^articles/(\d{4})/(\d{2})$", "articles/year/$1/month/$2", skipRemainingRules: true);
      `}</code>
    </pre>
    <p>
      This example captures a year and month from the URL (e.g., <code>/articles/2024/11</code>) and rewrites it to a more descriptive format like <code>/articles/year/2024/month/11</code>.
    </p>

    <br />

    <h2>What is URL Redirection?</h2>
    <p>
      URL redirection is the process of forwarding a user’s request from one URL to another. Unlike URL rewriting, redirection changes the URL that appears in the browser’s address bar. Redirections are useful when you want to direct users or search engines to a new location.
    </p>
    <p>
      In ASP.NET Core, you can implement redirection using the <code>Redirect</code> method in the controller, or configure it globally using middleware.
    </p>

    <br />

    <h2>Configuring URL Redirection in ASP.NET Core</h2>
    <p>
      URL redirection can be configured in the <code>Configure</code> method of the <code>Startup.cs</code> class, using rules and patterns that match specific incoming requests.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app)
{
    app.UseRewriter(new RewriteOptions()
        .AddRedirect(@"^old-page$", "/new-page", 301)  // Permanent redirect
        .AddRedirect(@"^old-products$", "/new-products", 301));  // Permanent redirect

    app.UseRouting();
    app.UseEndpoints(endpoints => { });
}
      `}</code>
    </pre>
    <p>
      In this example, requests for <code>/old-page</code> and <code>/old-products</code> are redirected permanently (HTTP status code 301) to new URLs.
    </p>

    <br />

    <h2>Using Status Codes for Redirection</h2>
    <p>
      When implementing redirection, you can choose from different HTTP status codes depending on the type of redirection you want. Common status codes for redirection include:
    </p>
    <ul>
      <li><strong>301 (Moved Permanently):</strong> The requested resource has been permanently moved to a new URL.</li><br />
      <li><strong>302 (Found):</strong> The requested resource is temporarily located at a different URL.</li><br />
      <li><strong>303 (See Other):</strong> The response to the request can be found at another URL using the GET method.</li>
    </ul>
    <p>
      The <code>Redirect</code> method allows you to specify the status code for the redirection, as shown in the previous example.
    </p>

   <br />

    <h2>Conditional Redirection</h2>
    <p>
      You can also perform conditional redirections based on request headers, query parameters, or other conditions. For example, you might want to redirect users based on whether they are authenticated or not.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app)
{
    app.Use(async (context, next) =>
    {
        if (!context.User.Identity.IsAuthenticated)
        {
            context.Response.Redirect("/login");
        }
        else
        {
            await next();
        }
    });

    app.UseRouting();
    app.UseEndpoints(endpoints => { });
}
      `}</code>
    </pre>
    <p>
      This example checks if the user is authenticated and redirects them to the login page if not.
    </p>

  <br />

    <h2>Handling Redirect Loops</h2>
    <p>
      When implementing redirection, it’s essential to avoid redirect loops, where the request keeps getting redirected back to the original URL. For example, redirecting from <code>/home</code> to <code>/home</code> would cause an infinite loop.
    </p>
    <p>
      To avoid such issues, ensure that your redirection rules are well-defined and don’t conflict with existing paths. You can also use <code>AddRewrite()</code> to ensure that the request is only rewritten or redirected once.
    </p>

 <br />

    <h2>Conclusion</h2>
    <p>
      URL rewriting and redirection are powerful tools for managing URLs and ensuring a smooth user experience in ASP.NET Core applications. Whether you're simplifying URL structures, redirecting to new pages, or ensuring backward compatibility, these techniques are essential for modern web development.
    </p>
  </div>
)}





{selectedChapter === 'chapter64' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Custom Middleware for Advanced Routing   </h1>


    <p>
      Middleware in ASP.NET Core is a powerful tool for processing HTTP requests and responses. By creating custom middleware, you can add advanced routing capabilities, such as conditional routing, logging, or custom redirects, before or after the routing decision has been made.
    </p>
 <br />
    
    <h2>What is Middleware in ASP.NET Core?</h2>
    <p>
      Middleware is software that is assembled into an application pipeline to handle requests and responses. Each component in the pipeline has the ability to process a request, call the next middleware in the pipeline, or short-circuit the pipeline by returning a response directly.
    </p>
    <p>
      Custom middleware is useful when you need to implement functionality that is not covered by built-in middleware, or when you need to intercept requests before they reach a specific route or controller.
    </p>

    <br />

    <h2>Creating Custom Middleware for Routing</h2>
    <p style={{paddingBottom:"6px"}}>
      In this section, we will create a custom middleware that inspects incoming requests and routes them based on specific conditions. For example, we can create a middleware that routes certain requests to a specific controller based on custom logic, like user roles or specific request headers.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Steps to Create Custom Middleware:</h3>
    <ul>
      <li>Create a middleware class that implements the request handling logic.</li><br />
      <li>Use the <code>app.UseMiddleware&lt;CustomMiddleware&gt;()</code> method to add it to the pipeline in the <code>Startup.cs</code> file.</li><br />
      <li>Access the request and decide the routing logic or pass it to the next middleware.</li>
    </ul><br />

    <pre className={style.codeblock}>
      <code>{`
public class CustomRoutingMiddleware
{
    private readonly RequestDelegate _next;

    public CustomRoutingMiddleware(RequestDelegate next)
    {
        _next = next;
    }

    public async Task InvokeAsync(HttpContext context)
    {
        // Custom routing logic
        var path = context.Request.Path.Value;
        if (path.Contains("special"))
        {
            // Redirect to a special route
            context.Response.Redirect("/special");
            return;  // Short-circuit the pipeline to prevent further processing
        }

        // Call the next middleware
        await _next(context);
    }
}
      `}</code>
    </pre>
    <p>
      In the example above, the custom middleware intercepts the request. If the request path contains the word "special", the middleware redirects the user to the "/special" route. Otherwise, the middleware passes the request to the next component in the pipeline.
    </p>

<br />

    <h2>Adding Custom Middleware to the Application Pipeline</h2>
    <p>
      To use the custom middleware, you need to register it in the application’s request processing pipeline in the <code>Configure</code> method in <code>Startup.cs</code>.
    </p>

    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    // Register custom middleware
    app.UseMiddleware<CustomRoutingMiddleware>();

    // Add other built-in middleware like routing, endpoints, etc.
    app.UseRouting();
    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllerRoute(
            name: "default",
            pattern: "{controller=Home}/{action=Index}/{id?}");
    });
}
      `}</code>
    </pre>
    <p>
      Here, we register our custom middleware using <code>app.UseMiddleware&lt;CustomRoutingMiddleware&gt;()</code> at the beginning of the pipeline. This ensures that our custom routing logic is evaluated before the rest of the request handling pipeline.
    </p>

  <br />

    <h2>Advanced Routing Logic in Middleware</h2>
    <p>
      Custom middleware can also be used to implement more advanced routing logic. For example, you could route requests based on conditions like:
    </p>
    <ul>
      <li><strong>Custom Headers:</strong> Route requests based on specific header values.</li><br />
      <li><strong>Request Method:</strong> Handle GET and POST requests differently.</li><br />
      <li><strong>IP Address:</strong> Route requests differently based on the client’s IP address.</li><br />
      <li><strong>User Authentication:</strong> Redirect unauthenticated users to the login page.</li>
    </ul>
    <p>
      You can access the request method, headers, query parameters, or even the request body to create complex routing conditions in your middleware.
    </p><br />

    <pre className={style.codeblock}>
      <code>{`
public class CustomRoutingMiddleware
{
    private readonly RequestDelegate _next;

    public CustomRoutingMiddleware(RequestDelegate next)
    {
        _next = next;
    }

    public async Task InvokeAsync(HttpContext context)
    {
        var method = context.Request.Method;
        var ipAddress = context.Connection.RemoteIpAddress?.ToString();

        // Example: Check if the request is a POST from a specific IP
        if (method == "POST" && ipAddress == "192.168.1.1")
        {
            // Redirect to a custom route
            context.Response.Redirect("/custom-post-route");
            return;
        }

        await _next(context);  // Pass the request to the next middleware
    }
}
      `}</code>
    </pre>
    <p>
      In the example above, the middleware checks the request method and IP address. If the request is a POST and comes from the IP address "192.168.1.1", the request is redirected to a custom route.
    </p>
<br />

    <h2>Conditional Routing with Query Parameters</h2>
    <p>
      You can also use query parameters in your custom routing logic. For example, based on a specific query parameter, you can route users to different controllers or actions.
    </p>

    <pre className={style.codeblock}>
      <code>{`
public class CustomRoutingMiddleware
{
    private readonly RequestDelegate _next;

    public CustomRoutingMiddleware(RequestDelegate next)
    {
        _next = next;
    }

    public async Task InvokeAsync(HttpContext context)
    {
        var queryParam = context.Request.Query["type"];

        // If the query parameter 'type' is 'premium', redirect to premium content
        if (queryParam == "premium")
        {
            context.Response.Redirect("/premium");
            return;
        }

        await _next(context);
    }
}
      `}</code>
    </pre>
    <p>
      In this example, the middleware checks if the query parameter "type" is equal to "premium". If so, it redirects the request to the "/premium" route.
    </p>

   <br />

    <h2>Logging and Debugging in Middleware</h2>
    <p>
      When implementing complex routing logic in custom middleware, it’s essential to log requests and debug your routing behavior. You can use ASP.NET Core’s built-in logging framework to log data for troubleshooting purposes.
    </p>
    <pre className={style.codeblock}>
      <code>{`
public class CustomRoutingMiddleware
{
    private readonly RequestDelegate _next;
    private readonly ILogger<CustomRoutingMiddleware> _logger;

    public CustomRoutingMiddleware(RequestDelegate next, ILogger<CustomRoutingMiddleware> logger)
    {
        _next = next;
        _logger = logger;
    }

    public async Task InvokeAsync(HttpContext context)
    {
        _logger.LogInformation("Processing request: {0}", context.Request.Path);

        await _next(context);
    }
}
      `}</code>
    </pre>
    <p>
      In this example, we inject the <code>ILogger</code> service into our middleware to log the request path. This can be extremely helpful for debugging and understanding how requests are being routed.
    </p>

 <br />

    <h2>Conclusion</h2>
    <p>
      Custom middleware for advanced routing in ASP.NET Core allows you to implement flexible, dynamic routing rules and logic that go beyond the capabilities of built-in routing. By creating custom middleware, you can control request handling based on conditions such as request method, headers, query parameters, user roles, or even IP address.
    </p>



  </div>
)}
{selectedChapter === 'chapter65' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to SignalR</h1>

    <p>
      SignalR is a library in ASP.NET Core that facilitates real-time communication between the server and client. Unlike traditional web applications, where the client makes requests to the server and waits for a response, SignalR allows for persistent, bidirectional communication. This enables the server to push updates to clients in real-time, making it ideal for applications like chat systems, live notifications, and real-time data updates.
    </p><br />

    <h2>What is SignalR?</h2>
    <p>
      SignalR is a library for adding real-time web functionality to applications. Real-time web functionality enables server-side code to push content to clients instantly, rather than requiring the client to request new data periodically. This enables highly interactive applications such as:
    </p>
    <ul>
      <li>Real-time chat applications</li><br />
      <li>Live notifications</li><br />
      <li>Live dashboards with real-time data updates</li><br />
      <li>Collaborative editing</li>
    </ul><br />

    <p>
      SignalR uses WebSockets as its transport protocol, but it falls back to other techniques (like long polling) if WebSockets is not available. This allows SignalR to work in a variety of scenarios and on different platforms.
    </p><br />

    <h2>Core Features of SignalR</h2>
    <p>Some of the key features of SignalR are:</p>
    <ul>
      <li><strong>Real-Time Communication:</strong> Enables bidirectional communication, allowing the server to push messages to clients without the clients needing to request them.</li><br />
      <li><strong>Persistent Connections:</strong> Clients can establish persistent connections with the server, allowing for low-latency communication.</li><br />
      <li><strong>Automatic Reconnection:</strong> If the connection drops, SignalR automatically reconnects without needing to refresh the page or re-establish the connection manually.</li><br />
      <li><strong>Scalability:</strong> SignalR can be scaled across multiple servers using backplane technologies like Redis, ensuring high availability and performance in large-scale applications.</li>
    </ul><br />

    <h2>How SignalR Works</h2>
    <p>
      SignalR works by establishing a persistent connection between the client and the server. The client connects to the server using WebSockets if supported, or falls back to other methods like Server-Sent Events or long polling. Once the connection is established, both the client and server can send messages to each other.
    </p>
    <p>SignalR supports multiple communication models:</p>
    <ul>
      <li><strong>Server to Client:</strong> The server can send messages to connected clients whenever there is new data to push.</li><br />
      <li><strong>Client to Server:</strong> The client can send messages to the server, triggering actions like updates or commands.</li><br />
      <li><strong>Group Communication:</strong> SignalR supports grouping clients into groups, allowing for targeted communication to specific clients.</li>
    </ul><br />

    <h2 style={{ paddingBottom: '6px' }}>Setting Up SignalR in an ASP.NET Core Application</h2>
    <h3>1. Install SignalR NuGet Package</h3>
    <p>The first step is to install the SignalR NuGet package. You can do this through the NuGet Package Manager or the .NET CLI:</p>
    <pre className={style.codeblock}>
      <code>{`
dotnet add package Microsoft.AspNetCore.SignalR
      `}</code>
    </pre><br />

    <h3>2. Configure SignalR in Startup.cs</h3>
    <p>Next, you need to configure SignalR in your application's `Startup.cs` file. In the `ConfigureServices` method, add the SignalR service:</p>
    <pre className={style.codeblock}>
      <code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddSignalR();
}
      `}</code>
    </pre><br />

    <h3>3. Set Up SignalR Hub</h3>
    <p>A SignalR Hub is the central point for communication between the client and the server. You need to create a Hub class that clients will connect to. This Hub class will contain methods that can be invoked by clients.</p>
    <pre className={style.codeblock}>
      <code>{`
public class ChatHub : Hub
{
    public async Task SendMessage(string user, string message)
    {
        // Broadcast the message to all clients
        await Clients.All.SendAsync("ReceiveMessage", user, message);
    }
}
      `}</code>
    </pre><br />

    <h3>4. Configure Routing for SignalR</h3>
    <p>After configuring the SignalR service and setting up the Hub, you need to define a route for clients to connect to the Hub. This is done in the `Configure` method of `Startup.cs`:</p>
    <pre className={style.codeblock}>
      <code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    app.UseRouting();
    
    app.UseEndpoints(endpoints =>
    {
        // Map the SignalR Hub route
        endpoints.MapHub<ChatHub>("/chatHub");
    });
}
      `}</code>
    </pre><br />

    <h2>Creating a Client to Connect to SignalR Hub</h2>
    <p style={{ paddingBottom: '6px' }}>
      On the client-side, you can use JavaScript (or any supported client) to connect to the SignalR Hub. You will need to include the SignalR client library in your HTML or JavaScript application.
    </p>

    <h3>1. Install SignalR JavaScript Client</h3>
    <p>To install the SignalR JavaScript client, include the following script tag in your HTML or install it via npm:</p>
    <pre className={style.codeblock}>
      <code>{`
<script src="https://cdn.jsdelivr.net/npm/@microsoft/signalr@3.1.3/dist/browser/signalr.min.js"></script>
      `}</code>
    </pre>
    <p>Or use npm:</p>
    <pre className={style.codeblock}>
      <code>{`
npm install @microsoft/signalr
      `}</code>
    </pre><br />

    <h3>2. JavaScript Client to Connect to the Hub</h3>
    <p>Now, create a JavaScript function that connects to the SignalR Hub and handles messages sent from the server:</p>
    <pre className={style.codeblock}>
      <code>{`
const connection = new signalR.HubConnectionBuilder()
    .withUrl("/chatHub")
    .build();

// Receive messages from the server
connection.on("ReceiveMessage", (user, message) => {
    console.log(\`\${user}: \${message}\`);
});

// Start the connection
connection.start().catch(err => console.error("Connection failed: ", err));
      `}</code>
    </pre><br />

    <h3>3. Send Messages to the Server</h3>
    <p>To send messages from the client to the server, invoke a method on the Hub from the client:</p>
    <pre className={style.codeblock}>
      <code>{`
function sendMessage(user, message) {
    connection.invoke("SendMessage", user, message).catch(err => console.error(err));
}
      `}</code>
    </pre>

  <br />

    <h2>Conclusion</h2>
    <p>
      SignalR makes it easy to add real-time functionality to your ASP.NET Core applications. By using SignalR, you can establish persistent connections between clients and the server, allowing for efficient and low-latency communication. Whether you're building a real-time chat application, live notifications, or interactive data feeds, SignalR provides the tools you need to create engaging user experiences.
    </p>
  </div>
)}


{selectedChapter === 'chapter66' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building Real-Time Applications with SignalR</h1>
   
    <p>
      SignalR is an ASP.NET library that simplifies the process of adding real-time web functionality to applications. It allows bi-directional communication between server and client, enabling applications to push updates to the client instantly. Real-time web functionality is crucial for applications such as chat systems, live dashboards, multiplayer games, and real-time notifications.
    </p><br />

    <h2>Why Use SignalR?</h2>
    <p>
      In traditional web applications, the client periodically makes HTTP requests to the server for updated information (polling). SignalR eliminates the need for this repetitive polling by allowing the server to send updates to clients automatically as soon as they occur, making it more efficient and providing a smoother user experience.
    </p><br />

    <h2>Key Features of SignalR</h2>
    <ul>
      <li><strong>Real-Time Communication:</strong> Push data from the server to the client instantly.</li><br />
      <li><strong>Automatic Reconnection:</strong> The library can automatically reconnect if a connection is lost.</li><br />
      <li><strong>Connection Management:</strong> SignalR manages connections, including connection IDs, groups, and connection state.</li><br />
      <li><strong>Support for Scalable Applications:</strong> SignalR can scale to large applications by using Azure SignalR Service or Redis to handle multiple servers.</li>
    </ul><br />

    <h2>Setting Up SignalR in ASP.NET Core</h2>
    <p style={{paddingBottom:"6px"}}>To start building real-time applications with SignalR, you need to install and configure SignalR in your ASP.NET Core application.</p>

    <h3>1. Install SignalR NuGet Package</h3>
    <p>First, install the SignalR package in your ASP.NET Core application using NuGet. You can do this using the following .NET CLI command:</p>
    <pre><code>dotnet add package Microsoft.AspNetCore.SignalR</code></pre><br />

    <h3>2. Configure SignalR in Startup.cs</h3>
    <p>In the <code>Startup.cs</code> file, you need to configure SignalR by adding it to the service container and setting up a route for the SignalR hub.</p>
    <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddSignalR();
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    app.UseRouting();

    app.UseEndpoints(endpoints =>
    {
        endpoints.MapHub<MyHub>("/myHub");
    });
}
`}</code></pre>
    <p>Here, <code>/myHub</code> is the URL where clients will connect to the SignalR hub.</p><br />

    <h2>Creating a SignalR Hub</h2>
    <p>A SignalR hub is a central point for real-time communication. It is a class that inherits from the <code>Hub</code> class, and it contains methods that clients can call.</p>
    <pre><code>{`
public class MyHub : Hub
{
    public async Task SendMessage(string user, string message)
    {
        await Clients.All.SendAsync("ReceiveMessage", user, message);
    }
}
      `}  </code></pre>
    <p>In this example, the <code>SendMessage</code> method sends a message to all connected clients by calling <code>Clients.All.SendAsync</code>.</p><br />

    <h2>Client-Side Setup</h2>
    <p>To interact with SignalR on the client side, you need to use the SignalR JavaScript client. This can be added to your project either via CDN or npm:</p>
    <pre style={{paddingBottom:"6px"}}><code>npm install @microsoft/signalr</code></pre>

    <h3>Establishing a Connection in JavaScript</h3>
    <p>To connect to the SignalR hub from your JavaScript application, use the <code>HubConnectionBuilder</code>:</p>
    <pre><code>{`
import { HubConnectionBuilder } from "@microsoft/signalr";

const connection = new HubConnectionBuilder()
    .withUrl("/myHub")
    .build();

connection.start().catch(err => console.error(err));

connection.on("ReceiveMessage", (user, message) => {
      console.log(\`\${user}: \${message}\`);
});
`}</code></pre>
    <p>This JavaScript code establishes a connection to the <code>myHub</code> hub and listens for the <code>ReceiveMessage</code> event. When the server sends a message, the callback function will be executed, logging the message.</p><br />

    <h3>Sending Messages from the Client</h3>
    <p>To send a message from the client to the server, use the <code>invoke</code> method:</p>
    <pre><code>
connection.invoke("SendMessage", "User1", "Hello, SignalR!").catch(err {"=>"} console.error(err));
    </code></pre>
    <p>This sends a message to the server’s <code>SendMessage</code> method, which will then broadcast it to all connected clients.</p><br />

    <h2>Handling Connection Lifecycle</h2>
    <p style={{paddingBottom:"6px"}}>SignalR provides events to handle the connection lifecycle, such as when the connection is established, lost, or reconnected.</p>

    <h3>Handling Disconnection and Reconnection</h3>
    <pre><code>{`
connection.onclose(()  {"=>"}  {
    console.log("Connection lost. Reconnecting...");
    setTimeout(() => connection.start(), 5000);
});
    `}</code></pre>
    <p>In this example, if the connection is lost, the client will try to reconnect after 5 seconds.</p><br />

    <h2>Scaling SignalR Applications</h2>
    <p>SignalR can be scaled to support larger applications, especially those that run on multiple servers or in cloud environments. Here are some scaling options:</p>
    <ul>
      <li><strong>Azure SignalR Service:</strong> A fully managed service that handles scaling and connectivity for real-time apps.</li><br />
      <li><strong>Redis Backplane:</strong> Use Redis to store messages that can be shared between servers.</li><br />
      <li><strong>SQL Server:</strong> SignalR can be backed by SQL Server to scale horizontally.</li>
    </ul><br />

    <h2>Security Considerations</h2>
    <p style={{paddingBottom:"6px"}}>For any real-time application, securing connections is crucial. SignalR supports authentication and authorization through ASP.NET Core Identity, JWT, or other methods.</p>

    <h3>Securing the Hub</h3>
    <p>You can restrict access to the SignalR hub by checking the user's authentication status in the <code>OnConnectedAsync</code> method:</p>
    <pre><code>{`
public override Task OnConnectedAsync()
{
    if (Context.User == null || !Context.User.Identity.IsAuthenticated)
    {
        throw new UnauthorizedAccessException("User not authenticated");
    }
    return base.OnConnectedAsync();
}
     `} </code></pre><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Building Real-Time Applications</h2>
    <ul>
      <li><strong>Use Groups:</strong> SignalR supports grouping clients, allowing you to send messages to specific subsets of connected clients.</li><br />
      <li><strong>Throttling and Rate Limiting:</strong> To prevent overwhelming clients, consider implementing message throttling.</li><br />
      <li><strong>Optimize Performance:</strong> Limit the frequency of server-to-client communication to ensure optimal performance.</li><br />
      <li><strong>Error Handling:</strong> Implement proper error handling and logging for better troubleshooting and stability.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      SignalR is a robust and powerful library that makes it easier to build real-time applications with ASP.NET Core. By setting up SignalR on both the server and client, handling connection lifecycle events, scaling the application, and securing communication, you can create highly interactive and scalable real-time web applications. Whether you're building a live chat, real-time dashboard, or collaborative tool, SignalR provides the necessary features to enhance user experiences through instant, bidirectional communication.
    </p>

 <br />
    <p>This guide provides a comprehensive approach to building real-time applications using SignalR, from installation to advanced use cases.</p>
  </div>
)}



{selectedChapter === 'chapter67' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Working with Hubs and Clients</h1>

    <p>SignalR is a powerful library in ASP.NET Core that enables real-time, bi-directional communication between clients and servers. This makes it ideal for scenarios where you need to push updates to clients instantly, such as in chat applications, live data dashboards, and collaborative tools.</p><br />

    <h2>Understanding SignalR Hubs</h2>
    <p>In SignalR, <strong>Hubs</strong> are central points of communication between clients and the server. A Hub allows the server to push messages to all connected clients or specific groups of clients. Clients can also send messages to the server via the Hub.</p><br />

    <h2 style={{paddingBottom:"6px"}}>Steps to Set Up and Use SignalR with Hubs</h2>

    <h3>1. Install SignalR NuGet Package</h3>
    <p>First, you need to install the SignalR NuGet package to use it in your project:</p>
    <pre><code>dotnet add package Microsoft.AspNetCore.SignalR</code></pre><br />

    <h3>2. Create a Hub Class</h3>
    <p>A Hub class defines methods that clients can call. The methods are invoked by the clients through the SignalR connection. For example, a simple <strong>ChatHub</strong> class could look like this:</p>
    <pre><code>{`
public class ChatHub : Hub
{
    public async Task SendMessage(string user, string message)
    {
        // Broadcast message to all connected clients
        await Clients.All.SendAsync("ReceiveMessage", user, message);
    }
}
`}</code></pre><br />

    <h3>3. Configure SignalR in Startup.cs</h3>
    <p>In the <strong>Startup.cs</strong> file, configure SignalR by adding it to the services collection and setting up routing for your Hub:</p>
    <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddSignalR();
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    app.UseRouting();

    app.UseEndpoints(endpoints =>
    {
        // Map the SignalR Hub to a route
        endpoints.MapHub<ChatHub>("/chatHub");
    });
}
`}</code></pre><br />

    <h3>4. Connect Clients to the Hub</h3>
    <p style={{paddingBottom:"6px"}}>On the client side, use the SignalR JavaScript client to connect to the Hub and receive messages in real time.</p>

    <h4>JavaScript Client Setup</h4>
    <p>Include the SignalR JavaScript client in your HTML file:</p>
    <pre><code>
<script src="https://cdn.jsdelivr.net/npm/@microsoft/signalr@3.1.3/dist/browser/signalr.min.js"></script>
    </code></pre>

    <p>Then, use JavaScript to create a connection to the Hub and set up event handlers for messages:</p>
    <pre><code>{`
const connection = new signalR.HubConnectionBuilder()
    .withUrl("/chatHub")
    .build();

connection.on("ReceiveMessage", (user, message) => {
  console.log(\`\${user}: \${message}\`);
});

connection.start().catch(err => console.error("Connection failed: ", err));
`}</code></pre><br />

    <h3>5. Send Messages from Client to Server</h3>
    <p>To send a message from the client to the server, invoke a method on the Hub:</p>
    <pre><code>{`
function sendMessage(user, message) {
    connection.invoke("SendMessage", user, message)
        .catch(err => console.error(err));
}
     `} </code></pre><br />

    <h2>Conclusion</h2>
    <p>SignalR's Hub system provides an easy way to handle real-time communication in your web applications. By setting up Hubs on the server and connecting clients with JavaScript, you can create powerful features like live chat, real-time updates, and collaborative tools. Whether you're building a real-time messaging system, live data dashboard, or any other feature that requires instant updates, SignalR is an ideal solution for your ASP.NET Core applications.</p>
  </div>
)}


{selectedChapter === 'chapter68' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Broadcasting Messages to Multiple Clients  </h1>

    <p>SignalR is a powerful library in ASP.NET Core that facilitates real-time, bi-directional communication between clients and servers. One of the most common use cases for SignalR is broadcasting messages to multiple clients in real-time. This can be extremely useful for applications like chat systems, live notifications, and collaborative tools where multiple users need to receive the same information simultaneously.</p><br />

    <h2>What is Broadcasting in SignalR?</h2>
    <p>In SignalR, broadcasting refers to sending messages from the server to all connected clients or a specific group of clients. This allows the server to push real-time updates to users, ensuring they stay synchronized.</p><br />

    <h2 style={{paddingBottom:"6px"}}>Steps for Broadcasting Messages to Multiple Clients</h2>

    <h3>1. Install SignalR NuGet Package</h3>
    <p>To get started, you first need to install the SignalR NuGet package in your ASP.NET Core project:</p>
    <pre><code>dotnet add package Microsoft.AspNetCore.SignalR</code></pre><br />

    <h3>2. Create a Hub Class</h3>
    <p>In SignalR, a Hub is used to define methods that clients can call. You can use the Hub to broadcast messages to multiple clients. Here’s an example of how to create a simple Hub class that broadcasts a message to all connected clients:</p>
    <pre><code>{`
public class ChatHub : Hub
{
    // Method to send messages to all connected clients
    public async Task SendMessage(string user, string message)
    {
        // Broadcasting the message to all connected clients
        await Clients.All.SendAsync("ReceiveMessage", user, message);
    }
}
`}</code></pre><br />

    <h3>3. Configure SignalR in Startup.cs</h3>
    <p>Next, configure SignalR in your <strong>Startup.cs</strong> file. This involves adding SignalR to the services collection and setting up a route to handle Hub connections:</p>
    <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddSignalR();
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    app.UseRouting();

    app.UseEndpoints(endpoints =>
    {
        // Mapping the SignalR Hub to a URL
        endpoints.MapHub<ChatHub>("/chatHub");
    });
}
`}</code></pre><br />

    <h3>4. Set Up the Client-Side Connection</h3>
    <p>On the client-side, you'll need to set up a connection to the SignalR Hub. Use the SignalR JavaScript client to establish the connection and handle incoming messages. Here’s how to set up the connection in JavaScript:</p>
    <pre><code>{`
<script src="https://cdn.jsdelivr.net/npm/@microsoft/signalr@3.1.3/dist/browser/signalr.min.js"></script>
    `}</code></pre>

    <p>Then, create the connection and set up the event listener for receiving messages:</p>
    <pre><code>{`
const connection = new signalR.HubConnectionBuilder()
    .withUrl("/chatHub")
    .build();

connection.on("ReceiveMessage", (user, message) => {
    console.log(\`\${user}: \${message}\`);
});

connection.start().catch(err => console.error("Connection failed: ", err));
`}</code></pre><br />

    <h3>5. Broadcasting Messages from the Server</h3>
    <p>To broadcast a message from the server to all clients, simply invoke the method defined in the Hub (in this case, `SendMessage`) from the server-side logic:</p>
    <pre><code>{`
function sendMessage(user, message) {
    connection.invoke("SendMessage", user, message)
        .catch(err => console.error(err));
}
    `}</code></pre><br />

    <h2>Conclusion</h2>
    <p>SignalR makes real-time communication in web applications incredibly simple. By using SignalR Hubs, you can easily broadcast messages to multiple clients, ensuring that all users receive updates in real-time. Whether it's for live notifications, chat systems, or collaborative platforms, SignalR provides a reliable solution for real-time communication in ASP.NET Core applications.</p>


  </div>
)}

{selectedChapter === 'chapter69' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> SignalR Integration with Web APIs   </h1>
    
    <p>SignalR provides a powerful way to achieve real-time communication between clients and servers in ASP.NET Core. By integrating SignalR with Web APIs, you can enhance your application with real-time capabilities. This allows the server to push updates to clients instantly, while also enabling clients to communicate with the server via the API. This is particularly useful for applications that require live data updates, notifications, or interactive features such as chat and collaborative tools.</p><br />

    <h2>Why Integrate SignalR with Web APIs?</h2>
    <p>Integrating SignalR with Web APIs offers a combination of two powerful technologies:
      <ul>
        <li><strong>Web APIs</strong> provide the standard HTTP-based communication for traditional request/response models.</li><br />
        <li><strong>SignalR</strong> enables real-time, bi-directional communication, ideal for scenarios where instant updates are required (e.g., live chat, notifications, etc.).</li>
      </ul>
      Together, these technologies allow for a more responsive and interactive user experience, as clients can receive real-time updates from the server and also make traditional API calls to interact with the backend.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Steps for Integrating SignalR with Web APIs</h2>

    <h3>1. Install SignalR NuGet Package</h3>
    <p>To begin, add the SignalR NuGet package to your project:</p>
    <pre><code>dotnet add package Microsoft.AspNetCore.SignalR</code></pre><br />

    <h3>2. Create a SignalR Hub</h3>
    <p>Next, create a Hub class that will manage the real-time communication. A Hub class defines methods that clients can invoke, and these methods can broadcast messages to clients in real time.</p>
    <pre><code>{`
public class NotificationHub : Hub
{
    // Method to send notifications to all connected clients
    public async Task SendNotification(string message)
    {
        await Clients.All.SendAsync("ReceiveNotification", message);
    }
}
`}</code></pre><br />

    <h3>3. Configure SignalR in Startup.cs</h3>
    <p>In the <strong>Startup.cs</strong> file, add SignalR to the services collection and set up the endpoint routing for the Hub:</p>
    <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddSignalR();
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    app.UseRouting();

    app.UseEndpoints(endpoints =>
    {
        // Map the SignalR Hub to the "/notifications" route
        endpoints.MapHub<NotificationHub>("/notifications");
    });
}
`}</code></pre><br />

    <h3>4. Create Web API Endpoints</h3>
    <p>Now, you can create Web API endpoints that will interact with clients via HTTP. These endpoints will handle traditional API requests, while SignalR will handle real-time communication.</p>
    <pre><code>{`
[ApiController]
[Route("api/[controller]")]
public class NotificationsController : ControllerBase
{
    private readonly IHubContext<NotificationHub> _hubContext;

    public NotificationsController(IHubContext<NotificationHub> hubContext)
    {
        _hubContext = hubContext;
    }

    // API endpoint to send a notification
    [HttpPost("send")]
    public async Task<IActionResult> SendNotification([FromBody] string message)
    {
        // Send notification to all clients via SignalR
        await _hubContext.Clients.All.SendAsync("ReceiveNotification", message);
        return Ok(new { message = "Notification sent successfully!" });
    }
}
`}</code></pre><br />

    <h3>5. Set Up the Client-Side Connection</h3>
    <p>On the client-side, you can use the SignalR JavaScript client to connect to the SignalR Hub and receive notifications in real time. First, include the SignalR JavaScript client in your HTML file:</p>
    <pre><code>
<script src="https://cdn.jsdelivr.net/npm/@microsoft/signalr@3.1.3/dist/browser/signalr.min.js"></script>
    </code></pre>

    <p>Then, create the SignalR connection and set up event listeners for the incoming notifications:</p>
    <pre><code>{`
const connection = new signalR.HubConnectionBuilder()
    .withUrl("/notifications")
    .build();

connection.on("ReceiveNotification", (message) => {
    console.log("New Notification: ", message);
    alert(message);  // Display the notification to the user
});

connection.start().catch(err => console.error("Connection failed: ", err));
`}</code></pre><br />

    <h3>6. Sending Notifications through Web API</h3>
    <p>You can now use the API to send notifications from the server to all connected clients. This can be done by sending a POST request to the <strong>/api/notifications/send</strong> endpoint:</p>
    <pre><code>{`
fetch("/api/notifications/send", {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify("Hello, this is a real-time notification!")
})
.then(response => response.json())
.then(data => console.log(data.message))
.catch(err => console.error("Error sending notification: ", err));
`}</code></pre><br />

    <h2>Conclusion</h2>
    <p>By integrating SignalR with Web APIs, you can create a highly interactive and real-time experience for your users. The combination of HTTP-based Web API communication for traditional interactions and SignalR for real-time updates provides a flexible and scalable solution for modern web applications. Whether you're building a notification system, chat application, or collaborative tool, SignalR's integration with Web APIs enables seamless real-time communication between clients and the server.</p>
  </div>
)}



{selectedChapter === 'chapter70' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to Blazor (WebAssembly and Server-Side)  </h1>

    <p>Blazor is a framework for building interactive web applications using C# and .NET instead of JavaScript. It allows developers to write web applications using C# both on the client-side and the server-side. Blazor applications run in the browser using WebAssembly (WASM) or on the server via SignalR, giving you the flexibility to choose the hosting model that best suits your needs.</p><br />

    <h2>What is Blazor?</h2>
    <p style={{paddingBottom:"6px"}}>Blazor is a part of ASP.NET Core, which enables the development of web applications using C# and .NET technologies. It enables developers to use C# for both client-side and server-side code, making it easier for .NET developers to create modern web apps without needing to learn JavaScript.</p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of Blazor:</h3>
    <ul>
      <li><strong>Interactive Web UI:</strong> Blazor lets you build interactive web UIs using C#.</li><br />
      <li><strong>Single-page Applications (SPA):</strong> Like other SPA frameworks (e.g., React, Angular), Blazor allows for client-side routing and rendering.</li><br />
      <li><strong>Component-based Architecture:</strong> Blazor uses reusable components to build UI, making code modular and maintainable.</li><br />
      <li><strong>Two Hosting Models:</strong> Blazor supports two hosting models: Blazor WebAssembly (client-side) and Blazor Server-side.</li>
    </ul><br />

    <h2>Blazor Hosting Models</h2>
    <p style={{paddingBottom:"6px"}}>Blazor offers two main hosting models: Blazor WebAssembly (WASM) and Blazor Server-side. Each model has its own benefits and use cases.</p>

    <h3>1. Blazor WebAssembly</h3>
    <p style={{paddingBottom:"6px"}}>Blazor WebAssembly runs your application directly in the browser using WebAssembly (WASM). WebAssembly is a low-level bytecode format that runs in modern browsers. With this hosting model, the entire application (including the .NET runtime) is downloaded to the client's browser, and all code execution happens on the client-side.</p>

    <h4 style={{paddingBottom:"6px"}}>Benefits of Blazor WebAssembly:</h4>
    <ul>
      <li><strong>Offline Support:</strong> Since the app runs entirely on the client, it can function offline once the application is downloaded.</li><br />
      <li><strong>Reduced Server Load:</strong> Since the execution happens on the client, there is less load on the server, making it suitable for applications with less server interaction.</li><br />
      <li><strong>Full .NET Runtime in the Browser:</strong> You can run .NET code directly in the browser without needing a server-side runtime.</li>
    </ul>

    <h4>Example of a simple Blazor WebAssembly setup:</h4>
    <pre><code>{`
<Program.cs>
public static IHostBuilder CreateHostBuilder(string[] args) =>
    BlazorWebAssemblyHost.CreateDefaultBuilder(args)
        .ConfigureApplicationServices(services =>
        {
            // Register services here
        })
        .UseBlazorStartup<Startup>()
        .Build();

<MainLayout.razor>
<PageTitle>Blazor WebAssembly App</PageTitle>
<h3>Welcome to Blazor WebAssembly!</h3>
`}</code></pre><br />

    <h3>2. Blazor Server-Side</h3>
    <p style={{paddingBottom:"6px"}}>Blazor Server-Side runs the application on the server. The UI interactions are handled via SignalR, which is a real-time communication framework. When a user interacts with the app (e.g., clicks a button), an event is sent to the server, which processes the request and sends the updated UI back to the client. The UI is rendered on the server and updated on the client through a WebSocket connection.</p>

    <h4 style={{paddingBottom:"6px"}}>Benefits of Blazor Server-Side:</h4>
    <ul>
      <li><strong>Smaller Application Size:</strong> Since the app is running on the server, there is no need to download large files like the .NET runtime or application code to the client.</li><br />
      <li><strong>Centralized Processing:</strong> The logic is all executed on the server, so you can take advantage of your server resources for processing and avoid issues like client compatibility or storage limitations.</li><br />
      <li><strong>Instant Updates:</strong> UI updates happen instantly without requiring full page reloads, similar to client-side SPAs.</li>
    </ul><br />

    <h4>Example of a simple Blazor Server-Side setup:</h4>
    <pre><code>{`
<Program.cs>
public static IHostBuilder CreateHostBuilder(string[] args) =>
    Host.CreateDefaultBuilder(args)
        .ConfigureWebHostDefaults(webBuilder =>
        {
            webBuilder.UseStartup<Startup>();
        })
        .Build();

<MainLayout.razor>
<PageTitle>Blazor Server-Side App</PageTitle>
<h3>Welcome to Blazor Server-Side!</h3>
`}</code></pre><br />

    <h2>Which Hosting Model to Choose?</h2>
    <p>Choosing between Blazor WebAssembly and Blazor Server-Side depends on the specific needs of your application:</p>
    <ul>
      <li><strong>Blazor WebAssembly:</strong> Best for applications that require offline support or minimal server interaction. Ideal for applications where you need to run .NET code directly on the client-side without relying on a server.</li><br />
      <li><strong>Blazor Server-Side:</strong> Best for applications with heavy server interaction or applications that need to be lightweight. Suitable for apps that don’t need to work offline but require real-time interactivity.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>Blazor is a powerful framework that allows you to build interactive web applications using C# and .NET. Whether you choose the client-side WebAssembly model or the server-side model, Blazor brings .NET developers into the world of web development with modern tools and best practices. With Blazor, you can leverage your existing C# skills to build full-fledged web applications that are highly interactive, responsive, and maintainable.</p>
  </div>
)}





{selectedChapter === 'chapter71' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Building Components in Blazor   </h1>
    
    <p>Blazor allows developers to build interactive web applications using components, which are reusable building blocks that manage their own UI and logic. Components are at the heart of Blazor and can be composed of both HTML and C# code. They are similar to components in other frameworks such as React and Angular.</p><br />

    <h2>What Are Blazor Components?</h2>
    <p style={{paddingBottom:"6px"}}>In Blazor, a component is a piece of UI (a UI element or a part of a page) that is defined using C# and HTML. A component can include data-binding, event handling, and other logic in the same file. Components in Blazor are self-contained units of functionality that can be reused and nested to create more complex UIs.</p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Blazor Components:</h3>
    <ul>
      <li><strong>Reusability:</strong> Components can be reused in multiple parts of your application, reducing code duplication.</li><br />
      <li><strong>Encapsulation:</strong> Components encapsulate their own logic and rendering, making it easier to manage and maintain.</li><br />
      <li><strong>Composition:</strong> Components can be nested inside other components to build complex UIs from smaller parts.</li><br />
      <li><strong>Data Binding:</strong> Components can bind data to UI elements, making the UI automatically update when data changes.</li>
    </ul><br />

    <h2>Creating a Blazor Component</h2>
    <p style={{paddingBottom:"6px"}}>Creating a Blazor component is straightforward. Components are typically defined in `.razor` files, which combine HTML and C# code. The basic structure of a Blazor component includes HTML markup for the UI and C# code for handling the logic.</p>

    <h3>1. Defining a Component</h3>
    <p>Let’s start by creating a simple component. In a Blazor project, create a `.razor` file to define the component. Here's an example of a basic component:</p>
    <pre><code>{`
<Counter.razor>
@code {
    private int count = 0;

    private void IncrementCount()
    {
        count++;
    }
}

<h3>Current count: @count</h3>
<button @onclick="IncrementCount">Increment</button>
`}</code></pre>
    <p>This component, named <strong>Counter</strong>, keeps track of a count and provides a button that increments the count when clicked. The `@code` directive in the component file contains the C# logic for handling the button click event.</p><br />

    <h3>2. Using the Component</h3>
    <p>Once you’ve created a component, you can use it in other components or pages by simply including it as a tag. For example, to use the <strong>Counter</strong> component on a page, you would write:</p>
    <pre><code>{`
<MainPage.razor>
<Counter />
`}</code></pre><br />

    <h2>Component Parameters</h2>
    <p style={{paddingBottom:"6px"}}>Components in Blazor can accept parameters, which allow data to be passed from parent components to child components. You define parameters in a component using the `<strong>[Parameter]</strong>` attribute in the C# code section.</p>

    <h3>Passing Parameters to a Component</h3>
    <p>Here’s an example where we create a component that accepts a `name` parameter and displays a personalized greeting:</p>
    <pre><code>{`
<Greeting.razor>
@code {
    [Parameter]
    public string Name { get; set; }
}

<h3>Hello, @Name!</h3>
`}</code></pre>

    <p>To use the <strong>Greeting</strong> component and pass a parameter, you would write:</p>
    <pre><code>{`
<MainPage.razor>
<Greeting Name="Blazor User" />
`}</code></pre>
    <p>This will display "Hello, Blazor User!" on the page.</p><br />

    <h3>Handling Event Binding in Components</h3>
    <p>Blazor provides a way to handle events like clicks, key presses, etc., in components using event binding. You can bind an event handler to an event using the `@` syntax.</p>

    <p>In the following example, a <strong>Button</strong> component handles a `click` event:</p>
    <pre><code>{`
<Button.razor>
@code {
    [Parameter]
    public EventCallback OnClick { get; set; }
}

<button @onclick="OnClick">Click me</button>
`}</code></pre>

    <p>In a parent component, you can use this <strong>Button</strong> component and provide an event handler for the `OnClick` event:</p>
    <pre><code>{`
<MainPage.razor>
<Button OnClick="HandleClick" />

@code {
    private void HandleClick()
    {
        Console.WriteLine("Button clicked!");
    }
}
`}</code></pre><br />

    <h2>Component Lifecycle</h2>
    <p style={{paddingBottom:"6px"}}>Blazor components have a lifecycle, which defines a series of methods that are called at different stages of a component’s existence. These lifecycle methods are useful for initializing data, handling changes, and cleaning up resources.</p>

    <h3 style={{paddingBottom:"6px"}}>Important Lifecycle Methods</h3>
    <ul>
      <li><strong>OnInitialized:</strong> Called when the component is initialized (before rendering).</li><br />
      <li><strong>OnParametersSet:</strong> Called when parameters are set or updated.</li><br />
      <li><strong>OnAfterRender:</strong> Called after the component has rendered.</li><br />
      <li><strong>Dispose:</strong> Called when the component is disposed of, allowing you to clean up resources.</li>
    </ul><br />

    <h4>Example of using lifecycle methods:</h4>
    <pre><code>{`
<LifecycleExample.razor>
@code {
    private string message;

    protected override void OnInitialized()
    {
        message = "Component initialized!";
    }

    protected override void OnAfterRender(bool firstRender)
    {
        if (firstRender)
        {
            Console.WriteLine("Component rendered for the first time.");
        }
    }
}

<h3>@message</h3>
`}</code></pre><br />

    <h2>Conclusion</h2>
    <p>Blazor components allow you to build modular, reusable, and interactive UI elements using C# and .NET. They provide a rich way to manage both UI and application logic in a single file, making your applications easier to build and maintain. Whether you're building simple UI elements or complex interactive forms, components are the foundation of your Blazor application.</p>



  </div>
)}


    {selectedChapter === 'chapter72' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Handling Forms and Events in Blazor</h1>

    <p>Blazor provides robust support for handling forms and events, enabling you to create interactive and dynamic web applications. In Blazor, you can easily build forms, validate user input, and handle various events such as button clicks and form submissions. This chapter will explore how to handle forms and events efficiently in a Blazor application.</p><br />

    <h2>Forms in Blazor</h2>
    <p style={{paddingBottom:"6px"}}>Forms in Blazor are designed to make it easy to collect user input and validate it. Blazor supports standard HTML form elements, and you can use them to bind data to C# properties in your components.</p>

    <h3>Creating a Simple Form</h3>
    <p>In Blazor, you can create a form by using standard HTML tags combined with data binding. The following is an example of a simple form where users can enter their name and age:</p>
    <pre><code>{`
<EditForm Model="@user" OnValidSubmit="HandleSubmit">
    <DataAnnotationsValidator />
    <ValidationSummary />

    <div>
        <label for="name">Name:</label>
        <InputText id="name" @bind-Value="user.Name" />
    </div>

    <div>
        <label for="age">Age:</label>
        <InputNumber id="age" @bind-Value="user.Age" />
    </div>

    <button type="submit">Submit</button>
</EditForm>

@code {
    private User user = new User();

    private void HandleSubmit()
    {
        // Handle form submission
        Console.WriteLine($"Name: {user.Name}, Age: {user.Age}");
    }

    public class User
    {
        public string Name { get; set; }
        public int Age { get; set; }
    }
}
`}</code></pre>

    <p>In this example:</p>
    <ul>
        <li><strong>EditForm:</strong> This is a Blazor component used to create forms. It takes a model and an event handler for the form submission.</li><br />
        <li><strong>Model:</strong> The `@user` model is used to bind form data to a C# object.</li><br />
        <li><strong>Data Annotations Validator:</strong> The <code>DataAnnotationsValidator</code> component validates the data entered into the form using data annotations applied to the model class.</li><br />
        <li><strong>Input Components:</strong> The <code>InputText</code> and <code>InputNumber</code> components bind form fields to the model’s properties.</li><br />
        <li><strong>OnValidSubmit:</strong> This event is triggered when the form is submitted, and the model data is valid.</li>
    </ul><br />

    <h2>Event Handling in Blazor</h2>
    <p style={{paddingBottom:"6px"}}>Blazor supports event handling, allowing you to respond to user interactions such as button clicks, form submissions, and other DOM events. Event handling in Blazor is done by binding event handlers to HTML elements in the component.</p>

    <h3>Handling Button Clicks</h3>
    <p>Button clicks are the most common events in Blazor. You can handle them using the <code>@onclick</code> directive. Here’s an example:</p>
    <pre><code>{`
<button @onclick="HandleButtonClick">Click Me</button>

@code {
    private void HandleButtonClick()
    {
        Console.WriteLine("Button clicked!");
    }
}
`}</code></pre>
    
    <p>In this example, the <code>@onclick</code> directive binds the <code>HandleButtonClick</code> method to the button’s click event. When the button is clicked, the method is invoked, and the message "Button clicked!" is logged to the console.</p><br />

    <h3>Event Binding with Parameters</h3>
    <p>You can pass parameters to event handlers in Blazor. For example, you may want to pass information about the clicked button to the event handler:</p>
    <pre><code>{`
<button @onclick="() => HandleButtonClick('Button 1')">Button 1</button>
<button @onclick="() => HandleButtonClick('Button 2')">Button 2</button>

@code {
    private void HandleButtonClick(string buttonName)
    {
        Console.WriteLine($"{buttonName} clicked!");
    }
}
`}</code></pre>
    
    <p>In this example, the event handler <code>HandleButtonClick</code> is passed a string parameter that identifies which button was clicked. The button name is then logged to the console.</p><br />

    <h2>Form Validation in Blazor</h2>
    <p style={{paddingBottom:"6px"}}>Blazor provides built-in support for validating forms. You can use data annotations in C# models to specify validation rules, and Blazor will automatically validate the form inputs based on those rules.</p>

    <h3>Using Data Annotations for Validation</h3>
    <p>You can use data annotations to specify validation rules in your models. For example, the <code>[Required]</code> and <code>[Range]</code> attributes are commonly used to enforce validation rules:</p>
    <pre><code>{`
<EditForm Model="@user" OnValidSubmit="HandleSubmit">
    <DataAnnotationsValidator />
    <ValidationSummary />

    <div>
        <label for="name">Name:</label>
        <InputText id="name" @bind-Value="user.Name" />
    </div>

    <div>
        <label for="age">Age:</label>
        <InputNumber id="age" @bind-Value="user.Age" />
    </div>

    <button type="submit">Submit</button>
</EditForm>

@code {
    private User user = new User();

    private void HandleSubmit()
    {
        Console.WriteLine($"Name: {user.Name}, Age: {user.Age}");
    }

    public class User
    {
        [Required(ErrorMessage = "Name is required.")]
        public string Name { get; set; }

        [Range(18, 120, ErrorMessage = "Age must be between 18 and 120.")]
        public int Age { get; set; }
    }
}
`}</code></pre>

    <p>In this example, the <code>[Required]</code> attribute ensures that the user enters a name, and the <code>[Range]</code> attribute ensures that the age is between 18 and 120. If these rules are not followed, validation will fail, and error messages will be displayed automatically.</p>

    <h2>Conclusion</h2>
    <p>Blazor makes it easy to handle forms and events in web applications. By combining standard HTML forms with C# code, you can collect user input, validate it, and process it on the server. Event handling in Blazor is straightforward, and you can bind event handlers to UI elements with ease. Whether you are building simple forms or complex interactive pages, Blazor provides the tools you need to handle forms and events effectively in your web applications.</p>
  </div>
)}





{selectedChapter === 'chapter73' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Blazor Routing and Navigation  </h1>

    <p>Blazor offers a powerful routing mechanism for single-page applications (SPA), enabling navigation between different components and pages seamlessly. In Blazor, routing is used to define how different URLs map to specific components or pages in the application. This chapter will walk you through setting up routing, navigating between pages, and passing parameters to components.</p><br />

    <h2>Introduction to Routing in Blazor</h2>
    <p style={{paddingBottom:"6px"}}>Routing in Blazor is handled through the <code>Router</code> component. The router component uses route templates defined in pages to map specific URLs to corresponding components. The Blazor app can be thought of as a single-page application (SPA), where content is dynamically swapped based on the URL, rather than reloading the entire page.</p>

    <h3>Setting Up the Router</h3>
    <p>To set up routing in a Blazor application, you need to configure the <code>Router</code> component in the <code>App.razor</code> file. Here's an example of how to configure the router:</p>
    <pre><code>{`
<Router AppAssembly="@typeof(Program).Assembly">
    <RouteView RouteData="@routeData" DefaultLayout="typeof(MainLayout)" />
    <FocusOnNavigate />
</Router>
`}</code></pre>

    <p>The <code>Router</code> component handles the navigation process. It uses <code>RouteView</code> to display the appropriate component based on the current URL. The <code>DefaultLayout</code> specifies the layout that should be used for the pages.</p><br />

    <h3>Defining Routes in Blazor</h3>
    <p style={{paddingBottom:"6px"}}>In Blazor, routes are defined within components using the <code>@page</code> directive. This directive associates a URL with a component. The URL defined in the <code>@page</code> directive becomes the route for that component.</p>

    <h4>Example of a Simple Page Component</h4>
    <pre><code>{`
@page "/home"

<h3>Welcome to the Home Page!</h3>
`}</code></pre>

    <p>In this example, the component will be displayed when the user navigates to <code>/home</code>.</p><br />

    <h3>Route Parameters</h3>
    <p style={{paddingBottom:"6px"}}>Blazor allows you to pass parameters to components through the route URL. You can define dynamic route parameters by including placeholders in the URL path. These parameters can then be accessed within the component.</p>

    <h4>Defining Route Parameters</h4>
    <pre><code>{`
@page "/user/{userId}"

<h3>User Profile: @userId</h3>

@code {
    [Parameter]
    public string userId { get; set; }
}
`}</code></pre>

    <p>In this example, the route parameter <code>userId</code> is defined as part of the route. When navigating to <code>/user/123</code>, the component will display "User Profile: 123". The parameter is accessed using the <code>[Parameter]</code> attribute.</p><br />

    <h2>Navigation in Blazor</h2>
    <p style={{paddingBottom:"6px"}}>In Blazor, you can navigate between different pages and components programmatically using the <code>NavigationManager</code> class. This allows you to change the current URL without reloading the page.</p>

    <h3>Using NavigationManager for Programmatic Navigation</h3>
    <pre><code>{`
@inject NavigationManager Navigation

<button @onclick="NavigateToProfile">Go to Profile</button>

@code {
    private void NavigateToProfile()
    {
        Navigation.NavigateTo("/user/123");
    }
}
`}</code></pre>

    <p>The <code>NavigationManager.NavigateTo()</code> method is used to navigate to a specific route. In this example, when the button is clicked, it navigates to the user profile page with the <code>userId</code> parameter set to 123.</p><br />

    <h3>Navigation Links</h3>
    <p>Blazor provides the <code>NavLink</code> component for declarative navigation. This component creates anchor links that navigate to a specified route. It also automatically applies an active class when the link is selected.</p>

    <pre><code>{`
<NavLink href="/home" class="nav-link">Home</NavLink>
<NavLink href="/user/123" class="nav-link">User Profile</NavLink>
`}</code></pre>

    <p>In this example, clicking the "Home" or "User Profile" link will navigate to the corresponding route. The <code>NavLink</code> component is more efficient than using a regular <code>&lt;a&gt;</code> tag, as it will ensure that the Blazor router handles the navigation and the link will update to reflect the active route.</p><br />

    <h2>Route Views and Layouts</h2>
    <p style={{paddingBottom:"6px"}}>Blazor allows you to define different layouts for different pages. A layout is a Razor component that provides a consistent structure for your pages, such as headers, footers, or sidebars. You can specify the layout for each page either globally or on a per-page basis.</p>

    <h3>Setting a Layout for a Page</h3>
    <pre><code>{`
@page "/about"
@layout AboutLayout

<h3>About Us</h3>
`}</code></pre>

    <p>In this example, the "About Us" page uses a custom layout called <code>AboutLayout</code>. The layout will be used to wrap the content of the page.</p><br />

    <h3>Global Layout</h3>
    <p>You can also set a global layout for all pages in your application by specifying it in the <code>Router</code> component in <code>App.razor</code>.</p>
    <pre><code>{`
<Router AppAssembly="@typeof(Program).Assembly">
    <RouteView RouteData="@routeData" DefaultLayout="typeof(MainLayout)" />
</Router>
`}</code></pre><br />

    <h2>Conclusion</h2>
    <p>Blazor's routing and navigation system provides a powerful way to create single-page applications with clean, URL-based navigation. By using the <code>Router</code> component, the <code>@page</code> directive, and the <code>NavigationManager</code>, you can build interactive and dynamic web applications with ease. The ability to define routes, pass parameters, and set layouts gives you full control over the application's structure and navigation.</p>
  </div>
)}


{selectedChapter === 'chapter74' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>State Management in Blazor  </h1>

    <p>State management in Blazor is essential for maintaining data across different components and pages, especially in single-page applications (SPA) where the browser doesn't reload the page between navigations. Blazor provides multiple strategies for handling state, ensuring that your application is responsive and provides a consistent experience for users. This chapter explores the different approaches for state management in Blazor applications.</p><br />

    <h2>Introduction to State Management</h2>
    <p>State management in Blazor involves storing data that is shared across components or across requests during the lifecycle of the application. This is particularly important in Blazor WebAssembly (WASM) where data is not automatically persisted between page refreshes, and in Blazor Server when handling user sessions in a stateless environment.</p><br />

    <h2>Strategies for State Management</h2>
    <p>Blazor supports several state management strategies, each suitable for different use cases. Below are the primary strategies:</p><br />

    <h3>1. Component-Level State</h3>
    <p style={{paddingBottom:"6px"}}>State management at the component level is the most localized approach, where the state (data) is stored within the component and is lost when the component is disposed of or re-rendered. This approach is suitable for transient state that is specific to a particular component.</p>

    <h4>Example of Component-Level State</h4>
    <pre><code>{`
@code {
    private int count = 0;

    private void Increment()
    {
        count++;
    }
}
`}</code></pre>
    <p>In this example, the <code>count</code> variable is stored within the component and can be updated by the <code>Increment</code> method. However, when the component is removed from the UI or re-rendered, the state will be lost.</p><br />

    <h3>2. Cascading Parameters</h3>
    <p style={{paddingBottom:"6px"}}>Cascading parameters allow you to pass state from a parent component down to its child components. This is useful when you need to share state across a tree of components without manually passing parameters down through each level.</p>

    <h4>Example of Cascading Parameter</h4>
    <pre><code>{`
@code {
    [CascadingParameter]
    public string User { get; set; }
}
`}</code></pre>

    <p>In this example, the <code>User</code> property is cascaded from the parent component to all its child components. This approach is particularly useful for passing state that should be available globally, such as user information or application settings.</p><br />

    <h3>3. Local Storage and Session Storage</h3>
    <p style={{paddingBottom:"6px"}}>For more persistent state, Blazor provides mechanisms to use the browser’s local storage and session storage. Local storage persists the state even after the browser is closed and reopened, while session storage lasts only for the duration of the browser session.</p>

    <h4>Example of Using Local Storage</h4>
    <pre><code>{`
@inject ILocalStorageService localStorage

@code {
    private string userName;

    protected override async Task OnInitializedAsync()
    {
        userName = await localStorage.GetItemAsync<string>("UserName");
    }

    private async Task SaveUserName()
    {
        await localStorage.SetItemAsync("UserName", userName);
    }
}
`}</code></pre>

    <p>This example uses the <code>ILocalStorageService</code> to save and retrieve data from local storage. When the application is reopened, the user’s name is persisted, which helps maintain the state across sessions.</p><br />

    <h3>4. Singleton Services</h3>
    <p>Singleton services can be used to store state that needs to be shared across components throughout the lifetime of the application. These services are typically injected into components and used to manage shared state. The data is persisted for as long as the application is running, making this approach suitable for scenarios where you need to keep track of data between page reloads or across components.</p>

    <h4>Example of Using Singleton Service</h4>
    <pre><code>{`
public class UserService
{
    public string UserName { get; set; }
}

// In Startup.cs or Program.cs
builder.Services.AddSingleton<UserService>();

@code {
    [Inject]
    public UserService UserService { get; set; }

    private void SetUserName()
    {
        UserService.UserName = "John Doe";
    }
}
`}</code></pre>

    <p>In this example, the <code>UserService</code> is registered as a singleton service, and its state can be accessed and modified across multiple components. Since it is a singleton, the state will persist as long as the application is running.</p><br />

    <h3>5. StateContainer</h3>
    <p>The state container is a design pattern in Blazor where a service is used to manage and share state across components. This pattern is particularly useful when you need to manage more complex state across multiple components.</p>

    <h4>Example of Using StateContainer</h4>
    <pre><code>{`
public class StateContainer
{
    public string State { get; set; }
}

@code {
    [Inject]
    public StateContainer StateContainer { get; set; }

    private void UpdateState(string newState)
    {
        StateContainer.State = newState;
    }
}
`}</code></pre>

    <p>In this example, the <code>StateContainer</code> service holds a shared state that can be accessed and modified by different components. By injecting the service into components, state can be easily passed around and updated.</p><br />

    <h2>Handling State with Blazor Server vs Blazor WebAssembly</h2>
    <p>In Blazor WebAssembly, the state is maintained on the client-side, meaning it persists in the browser’s memory. However, when using Blazor Server, the state is stored on the server and is transmitted over the SignalR connection to the client. Blazor WebAssembly allows more flexibility, as the state is persistent across sessions (using local storage), whereas Blazor Server requires careful consideration of how state is managed between requests.</p><br />

    <h2>Conclusion</h2>
    <p>State management in Blazor is a critical aspect of building modern web applications. By choosing the right state management strategy based on your app’s requirements, you can ensure that your Blazor application provides a seamless and efficient user experience. Whether you are managing state at the component level, using cascading parameters, leveraging browser storage, or relying on singleton services, Blazor provides a variety of tools to suit your needs.</p>




  </div>
)}

{selectedChapter === 'chapter75' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Integrating Blazor with JavaScript</h1>

    <p>Blazor provides powerful functionality to integrate with JavaScript, enabling developers to leverage existing JavaScript libraries and interact with the browser's DOM directly. This integration allows for scenarios where Blazor features alone might not be sufficient, such as interacting with third-party JavaScript components or performing client-side operations not yet supported in Blazor.</p><br />

    <h2>Why Integrate Blazor with JavaScript?</h2>
    <p>While Blazor offers robust capabilities, there are scenarios where JavaScript interop is essential:</p>
    <ul>
      <li>Using third-party JavaScript libraries, such as charting or UI libraries.</li><br />
      <li>Performing advanced browser interactions, like manipulating the DOM or handling animations.</li><br />
      <li>Accessing browser APIs unavailable in Blazor, such as geolocation or local notifications.</li><br />
      <li>Creating a bridge between legacy JavaScript code and modern Blazor applications.</li>
    </ul><br />

    <h2>JavaScript Interoperability (JS Interop) in Blazor</h2>
    <p style={{paddingBottom:"6px"}}>Blazor's JavaScript Interoperability (JS Interop) allows C# code to call JavaScript functions and vice versa. This is achieved using the <code>IJSRuntime</code> service.</p>

    <h3>Calling JavaScript from Blazor</h3>
    <p style={{paddingBottom:"6px"}}>You can call JavaScript functions from Blazor components using the <code>InvokeAsync</code> method of <code>IJSRuntime</code>.</p>

    <h4>Example: Calling a JavaScript Function</h4>
    <pre><code>{`
<!-- JavaScript function in wwwroot/scripts.js -->
function showAlert(message) {
    alert(message);
}
`}</code></pre>

    <pre><code>{`
// Blazor Component
@inject IJSRuntime JSRuntime

<button @onclick="CallJsFunction">Show Alert</button>

@code {
    private async Task CallJsFunction()
    {
        await JSRuntime.InvokeVoidAsync("showAlert", "Hello from Blazor!");
    }
}
`}</code></pre>

    <p>In this example, the Blazor component invokes the <code>showAlert</code> JavaScript function defined in a script file. The <code>InvokeVoidAsync</code> method is used when the JavaScript function does not return a value.</p><br />

    <h3>Calling JavaScript with Return Values</h3>
    <p>If the JavaScript function returns a value, use the <code>InvokeAsync</code> method with the desired return type.</p>

    <pre><code>{`
<!-- JavaScript function -->
function addNumbers(a, b) {
    return a + b;
}
`}</code></pre>

    <pre><code>{`
// Blazor Component
private async Task<int> GetSum(int num1, int num2)
{
    return await JSRuntime.InvokeAsync<int>("addNumbers", num1, num2);
}
`}</code></pre>

    <p>In this case, the Blazor component receives the sum of two numbers from the JavaScript function.</p><br />

    <h3>Calling Blazor Methods from JavaScript</h3>
    <p style={{paddingBottom:"6px"}}>Blazor components can expose methods to JavaScript by registering them with the <code>DotNetObjectReference</code> class.</p>

    <h4>Example: Invoking a Blazor Method</h4>
    <pre><code>{`
// Blazor Component
@implements IDisposable
@inject IJSRuntime JSRuntime

@code {
    private DotNetObjectReference<MyComponent> objRef;

    protected override async Task OnInitializedAsync()
    {
        objRef = DotNetObjectReference.Create(this);
        await JSRuntime.InvokeVoidAsync("registerBlazorMethod", objRef);
    }

    [JSInvokable]
    public void ShowMessage(string message)
    {
        Console.WriteLine($"Message from JavaScript: {message}");
    }

    public void Dispose()
    {
        objRef?.Dispose();
    }
}
`}</code></pre>

    <pre><code>{`
<!-- JavaScript -->
function registerBlazorMethod(dotNetObject) {
    setTimeout(() => {
        dotNetObject.invokeMethodAsync("ShowMessage", "Hello from JavaScript!");
    }, 1000);
}
`}</code></pre>

    <p>In this example, the <code>ShowMessage</code> method in the Blazor component is invoked from JavaScript. The method is marked with the <code>[JSInvokable]</code> attribute to make it accessible from JavaScript.</p><br />

    <h2>Integrating with Third-Party JavaScript Libraries</h2>
    <p style={{paddingBottom:"6px"}}>Blazor can integrate seamlessly with popular JavaScript libraries like Chart.js, D3.js, or jQuery.</p>

    <h4>Example: Using Chart.js</h4>
    <pre><code>{`
<!-- HTML -->
<canvas id="myChart"></canvas>
`}</code></pre>

    <pre><code>{`
<!-- JavaScript -->
function drawChart() {
    var ctx = document.getElementById('myChart').getContext('2d');
    new Chart(ctx, {
        type: 'bar',
        data: {
            labels: ['Red', 'Blue', 'Yellow'],
            datasets: [{
                label: 'Votes',
                data: [12, 19, 3],
                backgroundColor: ['red', 'blue', 'yellow'],
            }]
        }
    });
}
`}</code></pre>

    <pre><code>{`
// Blazor Component
@inject IJSRuntime JSRuntime

@code {
    protected override async Task OnAfterRenderAsync(bool firstRender)
    {
        if (firstRender)
        {
            await JSRuntime.InvokeVoidAsync("drawChart");
        }
    }
}
`}</code></pre>

    <p>In this example, the Chart.js library is used to render a chart, demonstrating how Blazor can interoperate with third-party JavaScript components.</p><br />

    <h2>Performance Considerations</h2>
    <ul>
      <li>Minimize frequent calls between Blazor and JavaScript, as each call introduces overhead.</li><br />
      <li>Batch data when passing large amounts between Blazor and JavaScript to improve efficiency.</li><br />
      <li>Test thoroughly for browser compatibility when using JavaScript interop.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for JS Interop</h2>
    <ul>
      <li>Encapsulate JavaScript interop code in reusable services to improve maintainability.</li><br />
      <li>Ensure that JavaScript libraries and functions are properly loaded before invoking them from Blazor.</li><br />
      <li>Handle exceptions in JavaScript interop calls to avoid runtime errors in your Blazor application.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>Integrating Blazor with JavaScript opens up a wide range of possibilities for creating rich, interactive web applications. By combining the capabilities of both technologies, developers can build applications that are performant, feature-rich, and user-friendly. With Blazor's JavaScript interop, the potential for leveraging existing JavaScript libraries and browser APIs becomes seamless, ensuring a smooth development experience.</p>



  </div>
)}

{selectedChapter === 'chapter76' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Unit Testing in ASP.NET Core</h1>

    <p>
      Unit testing is a foundational practice in modern software development, enabling developers to validate that 
      individual components of an application behave as expected. In ASP.NET Core, unit testing ensures the correctness 
      of small, isolated units of code, such as methods or classes, improving code quality, reducing bugs, and facilitating 
      maintainability.
    </p>

    <br />
    <h2>What is Unit Testing?</h2>
    <p>
      Unit testing involves testing the smallest functional parts of an application in isolation to verify that they produce 
      the expected outcomes. These tests are performed independently of the entire system.
    </p>

    <ul>
      <li><strong>Focus:</strong> Tests individual methods or functions.</li><br />
      <li><strong>Tools:</strong> Common frameworks include xUnit, NUnit, and MSTest.</li><br />
      <li><strong>Automation:</strong> Ensures tests can be run automatically as part of CI/CD pipelines.</li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Why Perform Unit Testing in ASP.NET Core?</h2>
    <ul>
      <li><strong>Improves Code Quality:</strong> Detects bugs early during development.</li><br />
      <li><strong>Facilitates Refactoring:</strong> Enables developers to modify code with confidence.</li><br />
      <li><strong>Supports TDD:</strong> Test-Driven Development requires writing tests before implementation.</li><br />
      <li><strong>Enhances Maintainability:</strong> Simplifies ongoing application support and evolution.</li><br />
      <li><strong>Encourages Modular Design:</strong> Leads to loosely coupled and testable components.</li>
    </ul>

  <br />

    <h2>Setting Up Unit Testing in ASP.NET Core</h2>
    <ol>
      <li>
        <strong>Create a Unit Test Project:</strong>
        <pre>
          <code>dotnet new xunit -n MyApp.Tests</code>
        </pre>
      </li><br />
      <li>
        <strong>Add a Reference to the ASP.NET Core Project:</strong>
        <pre>
          <code>dotnet add MyApp.Tests reference MyApp</code>
        </pre>
      </li><br />
      <li>
        <strong>Install Mocking Frameworks (Optional):</strong> Mocking libraries like Moq or NSubstitute can be added 
        to mock dependencies.
      </li>
    </ol>
<br />

    <h2>Testing with xUnit</h2>
    <p style={{paddingBottom:"6px"}}>
      xUnit is a popular testing framework in the .NET ecosystem due to its simplicity and extensibility.
    </p>

    <h3>Basic Example: Service Testing</h3>
    <h4>Service Implementation:</h4>
    <pre>
      <code>
{`public interface ICalculatorService
{
    int Add(int a, int b);
    int Subtract(int a, int b);
}

public class CalculatorService : ICalculatorService
{
    public int Add(int a, int b) => a + b;
    public int Subtract(int a, int b) => a - b;
}`}
      </code>
    </pre><br />

    <h4>Unit Test:</h4>
    <pre>
      <code>
{`using Xunit;

public class CalculatorServiceTests
{
    private readonly ICalculatorService _calculatorService;

    public CalculatorServiceTests()
    {
        _calculatorService = new CalculatorService();
    }

    [Fact]
    public void Add_ShouldReturnCorrectSum()
    {
        // Arrange
        int a = 5, b = 3;

        // Act
        int result = _calculatorService.Add(a, b);

        // Assert
        Assert.Equal(8, result);
    }

    [Fact]
    public void Subtract_ShouldReturnCorrectDifference()
    {
        // Arrange
        int a = 5, b = 3;

        // Act
        int result = _calculatorService.Subtract(a, b);

        // Assert
        Assert.Equal(2, result);
    }
}`}
      </code>
    </pre>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Unit Testing in ASP.NET Core</h2>
    <ul>
      <li><strong>Test Small Units:</strong> Focus on a single responsibility for each test.</li><br />
      <li><strong>Use Meaningful Names:</strong> Name test methods descriptively, e.g., `Add_ShouldReturnCorrectSum`.</li><br />
      <li><strong>Isolate Dependencies:</strong> Use mocking to avoid external dependencies.</li><br />
      <li><strong>Write Tests for Edge Cases:</strong> Cover boundary conditions and unusual inputs.</li><br />
      <li><strong>Automate Test Execution:</strong> Integrate tests into CI/CD pipelines.</li>
    </ul>

    <br />
    <h2>Conclusion</h2>
    <p>
      Unit testing is an indispensable practice in ASP.NET Core development. It ensures that individual components work 
      as expected, encourages modular design, and facilitates robust applications. By leveraging tools like xUnit and 
      libraries like Moq, developers can achieve high test coverage, maintainable code, and a smooth development process.
    </p>
  </div>
)}

{selectedChapter === 'chapter77' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Writing Unit Tests for Controllers, Services, and Repositories</h1>


      <p>
        Unit testing is an essential practice in software development, especially when working with ASP.NET Core
        applications. Testing different layers of the application—such as controllers, services, and repositories—
        ensures code quality and reduces the likelihood of defects.
      </p>
    

   <br />

      <h2>Overview of Testing Layers in ASP.NET Core</h2>
      <ul>
        <li>
          <strong>Controllers:</strong> Controllers handle HTTP requests and responses. Tests ensure they return the
          expected results for given inputs.
        </li><br />
        <li>
          <strong>Services:</strong> Services encapsulate business logic. Testing them ensures correctness and
          reusability.
        </li><br />
        <li>
          <strong>Repositories:</strong> Repositories abstract data access logic. Tests verify CRUD operations and
          ensure proper data handling.
        </li>
      </ul>
    

   <br />

   
      <h2>Unit Testing Controllers</h2>
      <p style={{paddingBottom:"6px"}}>
        Controllers handle incoming HTTP requests and invoke the appropriate services. Testing them involves verifying
        the correct HTTP status codes and responses.
      </p>

      <h3 style={{paddingBottom:"6px"}}>Example</h3>
      <h4>Controller Implementation:</h4>
      <pre>
        <code>
{`[ApiController]
[Route("api/[controller]")]
public class ProductController : ControllerBase
{
    private readonly IProductService _productService;

    public ProductController(IProductService productService)
    {
        _productService = productService;
    }

    [HttpGet("{id}")]
    public IActionResult GetProduct(int id)
    {
        var product = _productService.GetProductById(id);
        if (product == null)
            return NotFound();

        return Ok(product);
    }
}`}
        </code>
      </pre><br />

      <h4>Unit Test:</h4>
      <pre>
        <code>
{`using Microsoft.AspNetCore.Mvc;
using Moq;
using Xunit;

public class ProductControllerTests
{
    private readonly Mock<IProductService> _mockProductService;
    private readonly ProductController _controller;

    public ProductControllerTests()
    {
        _mockProductService = new Mock<IProductService>();
        _controller = new ProductController(_mockProductService.Object);
    }

    [Fact]
    public void GetProduct_ShouldReturnNotFound_WhenProductDoesNotExist()
    {
        // Arrange
        _mockProductService.Setup(s => s.GetProductById(It.IsAny<int>())).Returns((Product)null);

        // Act
        var result = _controller.GetProduct(1);

        // Assert
        Assert.IsType<NotFoundResult>(result);
    }

    [Fact]
    public void GetProduct_ShouldReturnOk_WhenProductExists()
    {
        // Arrange
        var product = new Product { Id = 1, Name = "Test Product" };
        _mockProductService.Setup(s => s.GetProductById(It.IsAny<int>())).Returns(product);

        // Act
        var result = _controller.GetProduct(1) as OkObjectResult;

        // Assert
        Assert.NotNull(result);
        Assert.Equal(200, result.StatusCode);
        Assert.Equal(product, result.Value);
    }
}`}
        </code>
      </pre>
    
   <br />

    
      <h2>Unit Testing Services</h2>
      <p style={{paddingBottom:"6px"}}>
        Services encapsulate business logic, making them highly reusable and testable.
      </p>
      <h4>Service Implementation:</h4>
      <pre>
        <code>
{`public interface IProductService
{
    Product GetProductById(int id);
}

public class ProductService : IProductService
{
    private readonly IProductRepository _repository;

    public ProductService(IProductRepository repository)
    {
        _repository = repository;
    }

    public Product GetProductById(int id)
    {
        return _repository.FindById(id);
    }
}`}
        </code>
      </pre><br />
      <h4>Unit Test:</h4>
      <pre>
        <code>
{`using Moq;
using Xunit;

public class ProductServiceTests
{
    private readonly Mock<IProductRepository> _mockRepository;
    private readonly IProductService _service;

    public ProductServiceTests()
    {
        _mockRepository = new Mock<IProductRepository>();
        _service = new ProductService(_mockRepository.Object);
    }

    [Fact]
    public void GetProductById_ShouldReturnProduct_WhenProductExists()
    {
        // Arrange
        var product = new Product { Id = 1, Name = "Test Product" };
        _mockRepository.Setup(r => r.FindById(It.IsAny<int>())).Returns(product);

        // Act
        var result = _service.GetProductById(1);

        // Assert
        Assert.NotNull(result);
        Assert.Equal(product.Id, result.Id);
        Assert.Equal(product.Name, result.Name);
    }

    [Fact]
    public void GetProductById_ShouldReturnNull_WhenProductDoesNotExist()
    {
        // Arrange
        _mockRepository.Setup(r => r.FindById(It.IsAny<int>())).Returns((Product)null);

        // Act
        var result = _service.GetProductById(1);

        // Assert
        Assert.Null(result);
    }
}`}
        </code>
      </pre>
   

    {/* Add additional sections for Repositories and Best Practices here */}

  </div>
)}




{selectedChapter === 'chapter78' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Mocking Dependencies (Moq or NSubstitute)</h1>

    <p>
      Mocking is a fundamental concept in <strong>Unit Testing</strong> and
      <strong> Test-Driven Development (TDD)</strong>. It allows developers to
      isolate and test individual units of code by replacing dependencies with mock
      implementations. In .NET, libraries like <strong>Moq</strong> and
      <strong>NSubstitute</strong> are commonly used to create and manage mocks
      effectively.
    </p>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Why Mock Dependencies?</h2>
    <ul>
      <li><strong>Isolation:</strong> Ensures the unit under test is isolated from external dependencies like databases, APIs, or third-party libraries.</li><br />
      <li><strong>Controlled Behavior:</strong> Allows developers to define specific behavior for dependencies during tests.</li><br />
      <li><strong>Reproducibility:</strong> Tests are not affected by external systems, leading to more consistent results.</li><br />
      <li><strong>Efficiency:</strong> Eliminates the need to set up complex external systems, reducing test runtime.</li>
    </ul>

   <br />

    <h2>Mocking with Moq</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>Moq</strong> is a popular mocking library for .NET that simplifies
      creating and configuring mocks.
    </p>

    <h3>Example: Mocking with Moq</h3>
    <p><strong>Scenario:</strong> Testing a service that depends on a repository.</p>

    <pre className={style.code}>
      {`public interface IProductRepository
{
    Product GetProductById(int id);
}

public class ProductService
{
    private readonly IProductRepository _repository;

    public ProductService(IProductRepository repository)
    {
        _repository = repository;
    }

    public Product GetProductDetails(int id)
    {
        return _repository.GetProductById(id);
    }
}`}
    </pre><br />

    <h4>Unit Test with Moq:</h4>
    <pre className={style.code}>
      {`using Moq;
using Xunit;

public class ProductServiceTests
{
    [Fact]
    public void GetProductDetails_ShouldReturnProduct_WhenProductExists()
    {
        // Arrange
        var mockRepository = new Mock<IProductRepository>();
        var product = new Product { Id = 1, Name = "Test Product" };

        mockRepository.Setup(repo => repo.GetProductById(1)).Returns(product);

        var service = new ProductService(mockRepository.Object);

        // Act
        var result = service.GetProductDetails(1);

        // Assert
        Assert.NotNull(result);
        Assert.Equal("Test Product", result.Name);
    }

    [Fact]
    public void GetProductDetails_ShouldReturnNull_WhenProductDoesNotExist()
    {
        // Arrange
        var mockRepository = new Mock<IProductRepository>();

        mockRepository.Setup(repo => repo.GetProductById(1)).Returns((Product)null);

        var service = new ProductService(mockRepository.Object);

        // Act
        var result = service.GetProductDetails(1);

        // Assert
        Assert.Null(result);
    }
}`}
    </pre>

  <br />

    <h2>Mocking with NSubstitute</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>NSubstitute</strong> is another lightweight library for mocking in .NET.
      It emphasizes simplicity and intuitive syntax.
    </p>

    <h3>Example: Mocking with NSubstitute</h3>
    <pre className={style.code}>
      {`using NSubstitute;
using Xunit;

public class ProductServiceTests
{
    [Fact]
    public void GetProductDetails_ShouldReturnProduct_WhenProductExists()
    {
        // Arrange
        var mockRepository = Substitute.For<IProductRepository>();
        var product = new Product { Id = 1, Name = "Test Product" };

        mockRepository.GetProductById(1).Returns(product);

        var service = new ProductService(mockRepository);

        // Act
        var result = service.GetProductDetails(1);

        // Assert
        Assert.NotNull(result);
        Assert.Equal("Test Product", result.Name);
    }

    [Fact]
    public void GetProductDetails_ShouldReturnNull_WhenProductDoesNotExist()
    {
        // Arrange
        var mockRepository = Substitute.For<IProductRepository>();

        mockRepository.GetProductById(1).Returns((Product)null);

        var service = new ProductService(mockRepository);

        // Act
        var result = service.GetProductDetails(1);

        // Assert
        Assert.Null(result);
    }
}`}
    </pre>

   <br />

    <h2>Key Features of Moq vs. NSubstitute</h2>
    <table className={style.table}>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Moq</th>
          <th>NSubstitute</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Syntax</td>
          <td>Fluent API</td>
          <td>Intuitive and natural</td>
        </tr>
        <tr>
          <td>Setup Configuration</td>
          <td><code>Setup</code> to define behavior</td>
          <td>Direct property/method calls</td>
        </tr>
        <tr>
          <td>Exception Handling</td>
          <td>Throws exceptions on invalid setups</td>
          <td>Allows non-strict mocks</td>
        </tr>
        <tr>
          <td>Learning Curve</td>
          <td>Slightly higher</td>
          <td>Easier for beginners</td>
        </tr>
      </tbody>
    </table>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Mocking Dependencies</h2>
    <ul>
      <li>Mock only external dependencies to avoid mocking internal logic.</li><br />
      <li>Choose the right library based on project needs and team familiarity.</li><br />
      <li>Use interaction verification to ensure dependency usage correctness.</li><br />
      <li>Avoid excessive mocking to keep tests maintainable.</li><br />
      <li>Leverage dependency injection for better testability.</li>
    </ul>

<br />

    <h2>Conclusion</h2>
    <p>
      Mocking with libraries like <strong>Moq</strong> and <strong>NSubstitute</strong>
      enables effective unit testing by isolating the system under test and providing
      controlled environments. By adhering to best practices, developers can create
      reliable and maintainable unit tests that enhance code quality and facilitate TDD.
    </p>
  </div>
)}




{selectedChapter === 'chapter79' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Integration Testing in ASP.NET Core</h1>

      <p>
        Integration testing verifies that different components or modules of an application work together as expected. Unlike unit tests that focus on individual pieces of functionality in isolation, integration tests evaluate the interaction between multiple components, including middleware, controllers, services, and the database.
      </p><br />
   
      <h2 style={{paddingBottom:"6px"}}>Why Perform Integration Testing?</h2>
      <ul>
        <li><strong>Ensure Component Interaction:</strong> Confirms that various components integrate seamlessly.</li><br />
        <li><strong>Identify Hidden Bugs:</strong> Uncovers issues that may not be apparent in unit tests.</li><br />
        <li><strong>Validate Application Flow:</strong> Tests workflows across layers like APIs, data access, and services.</li><br />
        <li><strong>Confidence in Deployments:</strong> Reduces the risk of integration failures in production environments.</li>
      </ul><br />
   
      <h2>Integration Testing in ASP.NET Core</h2>
      <p style={{paddingBottom:"6px"}}>
        ASP.NET Core provides tools and frameworks to facilitate integration testing. Using the <code>Microsoft.AspNetCore.Mvc.Testing</code> package, you can easily create a test host and simulate requests to your application.
      </p>
      
      <h3 style={{paddingBottom:"6px"}}>Key Tools for Integration Testing</h3>
      <ul>
        <li><strong>xUnit or NUnit:</strong> Popular test frameworks.</li><br />
        <li><strong>Test Server:</strong> Provided by ASP.NET Core for running application instances in memory.</li><br />
        <li><strong>In-memory Database:</strong> Use <code>InMemoryDatabase</code> for database-related tests.</li>
      </ul><br />

      <h2>Setting Up Integration Testing</h2>
      <ol>
        <li><strong>Add NuGet Packages:</strong>
          <pre>
            dotnet add package Microsoft.AspNetCore.Mvc.Testing
            dotnet add package Microsoft.EntityFrameworkCore.InMemory
          </pre>
        </li><br />
        <li><strong>Configure the Test Project:</strong> Reference your ASP.NET Core application in your test project.</li><br />
        <li><strong>Create a Custom WebApplicationFactory:</strong> Use <code>WebApplicationFactory</code> to create a testable version of your application.</li>
      </ol><br />
    

      <h2>Example: Integration Test in ASP.NET Core</h2>
      <p style={{paddingBottom:"6px"}}><strong>Scenario:</strong> Testing a simple API endpoint for fetching products.</p>

      <h3>API Implementation:</h3>
      <pre>
{`[ApiController]
[Route("api/[controller]")]
public class ProductsController : ControllerBase
{
    private readonly IProductService _service;

    public ProductsController(IProductService service)
    {
        _service = service;
    }

    [HttpGet("{id}")]
    public IActionResult GetProduct(int id)
    {
        var product = _service.GetProductById(id);
        if (product == null)
            return NotFound();

        return Ok(product);
    }
}`}
      </pre><br />

      <h3>Integration Test:</h3>
      <pre>
{`using System.Net;
using System.Net.Http.Json;
using Microsoft.AspNetCore.Mvc.Testing;
using Xunit;

public class ProductsControllerIntegrationTests : IClassFixture<WebApplicationFactory<Program>>
{
    private readonly HttpClient _client;

    public ProductsControllerIntegrationTests(WebApplicationFactory<Program> factory)
    {
        _client = factory.CreateClient();
    }

    [Fact]
    public async Task GetProduct_ShouldReturnOk_WhenProductExists()
    {
        // Arrange
        var productId = 1;

        // Act
        var response = await _client.GetAsync($"/api/products/{productId}");

        // Assert
        Assert.Equal(HttpStatusCode.OK, response.StatusCode);

        var product = await response.Content.ReadFromJsonAsync<Product>();
        Assert.NotNull(product);
        Assert.Equal(productId, product.Id);
    }

    [Fact]
    public async Task GetProduct_ShouldReturnNotFound_WhenProductDoesNotExist()
    {
        // Arrange
        var productId = 999;

        // Act
        var response = await _client.GetAsync($"/api/products/{productId}");

        // Assert
        Assert.Equal(HttpStatusCode.NotFound, response.StatusCode);
    }
}`}
      </pre>
    <br />


      <h2 style={{paddingBottom:"6px"}}>Best Practices for Integration Testing</h2>
      <ul>
        <li><strong>Isolate External Dependencies:</strong> Use in-memory databases or mock services to avoid relying on external systems.</li><br />
        <li><strong>Use Dependency Injection:</strong> Override services in the <code>WebApplicationFactory</code> for test-specific configurations.</li><br />
        <li><strong>Focus on End-to-End Flows:</strong> Test critical paths in your application, ensuring the entire flow works as expected.</li><br />
        <li><strong>Keep Tests Fast:</strong> Minimize reliance on external resources and keep test data lightweight.</li><br />
        <li><strong>Clean Up After Tests:</strong> Ensure each test runs in isolation by resetting states and cleaning up resources.</li>
      </ul><br />
   
      <h2>Conclusion</h2>
      <p>
        Integration testing is crucial for validating the interaction between components in ASP.NET Core applications. By leveraging the built-in tools like <code>WebApplicationFactory</code> and in-memory databases, developers can write robust and efficient tests to ensure their applications work as intended. Following best practices can further enhance the reliability and maintainability of your tests, contributing to a smooth development and deployment process.
      </p>
  
  </div>
)}

   
    {selectedChapter === 'chapter80' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Test-Driven Development (TDD) Concepts</h1>

    
      <p>
        Test-Driven Development (TDD) is a software development process in which tests are written before the code that needs to pass them. It follows a "Red-Green-Refactor" cycle, ensuring that the code is tested frequently and iteratively, which helps improve code quality and design.
      </p><br />
   
      <h2 style={{paddingBottom:"6px"}}>Why Use TDD?</h2>
      <ul>
        <li><strong>Ensures Correctness:</strong> TDD ensures that your code works as expected by testing it early and often.</li><br />
        <li><strong>Improves Code Quality:</strong> Writing tests first forces developers to consider edge cases and write more maintainable code.</li><br />
        <li><strong>Reduces Bugs:</strong> By writing tests before code, you catch bugs early in the development process.</li><br />
        <li><strong>Enhances Refactoring:</strong> TDD makes refactoring safer by ensuring existing functionality is not broken.</li><br />
        <li><strong>Better Design:</strong> The need for testable code results in cleaner and more modular design.</li>
      </ul><br />
   
      <h2>The TDD Cycle: Red-Green-Refactor</h2>
      <p>
        The TDD cycle consists of three simple steps:
      </p>
      <ol>
        <li><strong>Red:</strong> Write a failing test. The test should fail because the functionality isn’t implemented yet.</li><br />
        <li><strong>Green:</strong> Write just enough code to make the test pass.</li><br />
        <li><strong>Refactor:</strong> Clean up the code while ensuring the test still passes, improving code quality and readability.</li>
      </ol><br />
   
      <h2 style={{paddingBottom:"6px"}}>Basic Example of TDD</h2>
      <h3>Scenario:</h3>
      <p style={{paddingBottom:"6px"}}>We will develop a simple function to calculate the sum of two numbers using TDD.</p>

      <h3>Step 1: Write the Test (Red)</h3>
      <pre>
{`public class CalculatorTests
{
    [Fact]
    public void Add_TwoNumbers_ReturnsCorrectSum()
    {
        // Arrange
        var calculator = new Calculator();

        // Act
        var result = calculator.Add(2, 3);

        // Assert
        Assert.Equal(5, result);
    }
}`}
      </pre><br />

      <h3>Step 2: Write the Code to Pass the Test (Green)</h3>
      <pre>
{`public class Calculator
{
    public int Add(int a, int b)
    {
        return a + b;
    }
}`}
      </pre><br />

      <h3>Step 3: Refactor the Code (Refactor)</h3>
      <p>In this case, the code is simple enough that no immediate refactor is required. However, in a real-world scenario, you might clean up or improve the implementation at this stage.</p>

      <h2 style={{paddingBottom:"6px"}}>Best Practices for TDD</h2>
      <ul>
        <li><strong>Write small tests:</strong> Focus on small, isolated units of functionality rather than large features.</li><br />
        <li><strong>Test one thing at a time:</strong> Keep your tests focused on testing a single behavior or aspect of the code.</li><br />
        <li><strong>Refactor with confidence:</strong> TDD allows you to refactor your code with confidence, knowing that your tests will catch any regression issues.</li><br />
        <li><strong>Write readable tests:</strong> Write tests that clearly describe the expected behavior so that other developers can understand them.</li><br />
        <li><strong>Keep tests fast:</strong> Ensure tests run quickly so that they can be run frequently, promoting a fast feedback loop.</li>
      </ul><br />
   
      <h2 style={{paddingBottom:"6px"}}>Advantages of TDD</h2>
      <ul>
        <li><strong>Immediate Feedback:</strong> The feedback loop is short, so you can address issues as soon as they arise.</li><br />
        <li><strong>Improved Design and Architecture:</strong> TDD encourages clean, modular, and testable code that leads to better application architecture.</li><br />
        <li><strong>Higher Code Coverage:</strong> Since tests are written for every piece of functionality, TDD generally results in higher code coverage.</li><br />
        <li><strong>Fewer Bugs:</strong> By catching errors early, you reduce the number of bugs in production code.</li>
      </ul><br />
  
      <h2 style={{paddingBottom:"6px"}}>Challenges of TDD</h2>
      <ul>
        <li><strong>Initial Time Investment:</strong> Writing tests before code may seem like it takes more time upfront, but it can save time in the long run by reducing bugs and rework.</li><br />
        <li><strong>Requires Discipline:</strong> TDD requires developers to adhere to a strict discipline and avoid skipping the testing step.</li><br />
        <li><strong>Test Maintenance:</strong> As the codebase grows, keeping tests up to date can become challenging, especially in fast-moving projects.</li>
      </ul><br />
    
      <h2>Conclusion</h2>
      <p>
        Test-Driven Development (TDD) is a powerful practice that helps developers write more reliable and maintainable code by ensuring tests are written before code. By following the Red-Green-Refactor cycle, TDD improves the design of your software and reduces the number of bugs introduced during development. While there are challenges, the benefits of TDD make it a worthwhile investment in creating high-quality software.
      </p>
 
  </div>
)}



{selectedChapter === 'chapter81' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Application Caching (In-Memory, Distributed, Redis)</h1>

    <p>
      Application caching is a vital technique for improving the performance of ASP.NET Core applications by reducing latency and database load. By storing frequently accessed data temporarily, you can serve it quickly without needing to re-fetch or recompute it. ASP.NET Core supports multiple caching strategies, including <strong>In-Memory Caching</strong>, <strong>Distributed Caching</strong>, and <strong>Redis Caching</strong>, each suitable for different use cases depending on your application's scale and infrastructure needs.
    </p>

  <br />

    <h3>In-Memory Caching</h3>
    <p style={{paddingBottom:"6px"}}>
      In-memory caching stores data directly in the memory of the application server. It is the simplest and fastest caching method, ideal for small to medium-sized applications running on a single server. In-memory caching ensures that repeated data retrievals are fast and avoid hitting the database for every request.
    </p>

    <h4>Use Case:</h4>
    <ul>
      <li>Cache small datasets or frequently accessed information, such as configuration values or user session data, within a single instance of your application.</li>
    </ul><br />

    <h4>Implementation:</h4>
    <p><strong>Configure the Memory Cache in <code>Startup.cs</code>:</strong></p>
    <pre>
      <code>
        public class Startup {'\n'}
        {'  '}public void ConfigureServices(IServiceCollection services) {'\n'}
        {'    '}services.AddMemoryCache();  // Add In-Memory Cache service {'\n'}
        {'  }'}{' '}
      </code>
    </pre><br />
    <p><strong>Using In-Memory Cache in a Controller:</strong></p>
    <pre>
      <code>
        public class HomeController : Controller {'\n'}
        {'  '}private readonly IMemoryCache _memoryCache; {'\n'}
        {'  '}public HomeController(IMemoryCache memoryCache) {'\n'}
        {'    '}_memoryCache = memoryCache; {'\n'}
        {'  }'} {'\n'}
        {'  '}public IActionResult Index() {'\n'}
        {'    '}string cacheKey = "recentData"; {'\n'}
        {'    '}if (!_memoryCache.TryGetValue(cacheKey, out string cachedData)) {'\n'}
        {'      '}cachedData = "Fresh Data from Database"; {'\n'}
        {'      '}_memoryCache.Set(cacheKey, cachedData, TimeSpan.FromMinutes(5)); {'\n'}
        {'    }'} {'\n'}
        {'    '}return View("Index", cachedData); {'\n'}
        {'  }'}
      </code>
    </pre><br />

    <h4>Advantages:</h4>
    <ul>
      <li>Extremely fast since it stores data in the local memory of the server.</li><br />
      <li>Simple to configure and implement.</li>
    </ul><br />

    <h4>Limitations:</h4>
    <ul>
      <li>Data is only available on the local server; it cannot be shared across multiple application instances.</li><br />
      <li>If the server restarts, all cached data is lost.</li>
    </ul>

<br />

    <h3>Distributed Caching</h3>
    <p style={{paddingBottom:"6px"}}>
      Distributed caching is necessary when your application is running on multiple servers or in a cloud-based environment. It allows the cached data to be shared across different instances of the application, ensuring that all servers can access the same cache.
    </p>

    <h4>Use Case:</h4>
    <ul>
      <li>Ideal for large-scale applications that run on multiple web servers or when you need consistent cache access across different application instances.</li>
    </ul><br />

    <h4>Implementation with SQL Server as a Distributed Cache:</h4>
    <p><strong>Install the Distributed Caching Package:</strong></p>
    <pre>
      <code>
        dotnet add package Microsoft.Extensions.Caching.SqlServer
      </code>
    </pre>
    <p><strong>Configure Distributed Caching in <code>Startup.cs</code>:</strong></p>
    <pre>
      <code>{`
        public class Startup {'\n'}
        {'  '}public void ConfigureServices(IServiceCollection services) {'\n'}
        {'    '}services.AddDistributedSqlServerCache(options => {'\n'}
        {'      '}options.ConnectionString = "YourSQLServerConnectionString"; {'\n'}
        {'      '}options.SchemaName = "dbo"; {'\n'}
        {'      '}options.TableName = "Cache"; {'\n'}
        {'    });'} {'\n'}
        {'    '}services.AddControllersWithViews(); {'\n'}
        {'  }'}{' '}
       `} </code>
    </pre><br />
    <p>Using Distributed Cache in a Controller:</p>
    <pre>
      <code>{`
        public class HomeController : Controller {'\n'}
        {'  '}private readonly IDistributedCache _distributedCache; {'\n'}
        {'  '}public HomeController(IDistributedCache distributedCache) {'\n'}
        {'    '}_distributedCache = distributedCache; {'\n'}
        {'  }'} {'\n'}
        {'  '}public async Task<IActionResult> Index() {'\n'}
        {'    '}string cacheKey = "userSession"; {'\n'}
        {'    '}var cachedData = await _distributedCache.GetStringAsync(cacheKey); {'\n'}
        {'    '}if (cachedData == null) {'\n'}
        {'      '}cachedData = "Fresh Data from Database"; {'\n'}
        {'      '}await _distributedCache.SetStringAsync(cacheKey, cachedData, new DistributedCacheEntryOptions {'\n'}
        {'        '}AbsoluteExpirationRelativeToNow = TimeSpan.FromMinutes(5) {'\n'}
        {'      });'} {'\n'}
        {'    }'} {'\n'}
        {'    '}return View("Index", cachedData); {'\n'}
        {'  }'}
       `} </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>Advantages:</h4>
    <ul>
      <li>Supports distributed systems where multiple application instances share the same cache.</li><br />
      <li>Suitable for large-scale applications running in cloud or load-balanced environments.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Limitations:</h4>
    <ul>
      <li>Slightly slower than in-memory caching due to network latency.</li><br />
      <li>Requires external cache stores such as SQL Server, Redis, or NCache.</li>
    </ul>

   <br />

    <h3>Redis Caching</h3>
    <p style={{paddingBottom:"6px"}}>
      Redis is a fast, open-source, in-memory key-value store that is widely used for distributed caching. Redis provides high performance, persistence, and scalability, making it an ideal choice for large applications and services that require high-speed caching across multiple servers.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Use Case:</h4>
    <ul>
      <li>Ideal for caching large datasets, session management, or frequently accessed data in a highly scalable environment.</li><br />
      <li>Supports persistence (saving cached data to disk), pub/sub messaging, and advanced data types (e.g., lists, sets).</li>
    </ul><br />

    <h4>Setting Up Redis Caching in ASP.NET Core:</h4>
    <p><strong>Install the Redis Caching Package:</strong></p>
    <pre>
      <code>
        dotnet add package Microsoft.Extensions.Caching.StackExchangeRedis
      </code>
    </pre>
    <p><strong>Configure Redis Caching in <code>Startup.cs</code>:</strong></p>
    <pre>
      <code>{`
        public class Startup {'\n'}
        {'  '}public void ConfigureServices(IServiceCollection services) {'\n'}
        {'    '}services.AddStackExchangeRedisCache(options => {'\n'}
        {'      '}options.Configuration = "localhost"; {'\n'}
        {'      '}options.InstanceName = "SampleApp_"; {'\n'}
        {'    });'} {'\n'}
        {'    '}services.AddControllersWithViews(); {'\n'}
        {'  }'}{' '}
 `} </code>
    </pre><br />
    <p><strong>Using Redis Cache in a Controller:</strong></p>
    <pre>
      <code>{`
        public class HomeController : Controller {'\n'}
        {'  '}private readonly IDistributedCache _redisCache; {'\n'}
        {'  '}public HomeController(IDistributedCache redisCache) {'\n'}
        {'    '}_redisCache = redisCache; {'\n'}
        {'  }'} {'\n'}
        {'  '}public async Task<IActionResult> Index() {'\n'}
        {'    '}string cacheKey = "userSession"; {'\n'}
        {'    '}var cachedData = await _redisCache.GetStringAsync(cacheKey); {'\n'}
        {'    '}if (cachedData == null) {'\n'}
        {'      '}cachedData = "Fresh Data from Database"; {'\n'}
        {'      '}await _redisCache.SetStringAsync(cacheKey, cachedData, new DistributedCacheEntryOptions {'\n'}
        {'        '}AbsoluteExpirationRelativeToNow = TimeSpan.FromMinutes(5) {'\n'}
        {'      });'} {'\n'}
        {'    }'} {'\n'}
        {'    '}return View("Index", cachedData); {'\n'}
        {'  }'}
      `}</code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>Advantages:</h4>
    <ul>
      <li>Fast, scalable, and supports high-availability clusters.</li><br />
      <li>Advanced features like persistence, transactions, and data replication.</li>
    </ul><br />

    <h4>Limitations:</h4>
    <ul>
      <li>Requires additional setup and infrastructure (Redis server or managed Redis service).</li><br />
      <li>Higher complexity compared to in-memory caching.</li>
    </ul>
  </div>
)}

{selectedChapter === 'chapter82' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Response Caching and Compression</h1>

    <p>
      Optimizing performance is critical for modern web applications. ASP.NET Core provides powerful tools such as **Response Caching** and **Response Compression** to improve the speed and efficiency of your applications.
    </p>

   <br />

    <h3>What is Response Caching?</h3>
    <p>
      Response caching allows you to store and reuse HTTP responses, reducing the need for repeated computation or database queries. It can dramatically improve application performance and reduce server load by serving cached content for subsequent requests.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Benefits of Response Caching</h4>
    <ul className={style.list}>
      <li>Reduces server processing time.</li><br />
      <li>Improves client-side performance.</li><br />
      <li>Lowers bandwidth usage.</li>
    </ul><br />

    <h4>Enabling Response Caching</h4>
    <p style={{paddingBottom:"6px"}}>
      To enable response caching, you need to configure middleware and set appropriate caching headers.
    </p>
    <h5>1. Add Middleware in <code>Startup.cs</code></h5>
    <pre >
      {`public void ConfigureServices(IServiceCollection services)
{
    services.AddResponseCaching();
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    app.UseResponseCaching();

    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllers();
    });
}`}
    </pre><br />

    <h5>2. Use Cache-Control Headers</h5>
    <p>
      In your controller or action, set the cache behavior using the <code>[ResponseCache]</code> attribute:
    </p>
    <pre className={style.codeBlock}>
      {`[ResponseCache(Duration = 60, Location = ResponseCacheLocation.Client, NoStore = false)]
public IActionResult Get()
{
    return Ok("This response is cached for 60 seconds.");
}`}
    </pre>

    <br />

    <h3>What is Response Compression?</h3>
    <p style={{paddingBottom:"6px"}}>
      Response compression reduces the size of the response payload, improving load times and decreasing bandwidth usage. ASP.NET Core supports several compression algorithms, such as Gzip and Brotli.
    </p>

    <h4>Benefits of Response Compression</h4>
    <ul>
      <li>Reduces response size.</li><br />
      <li>Speeds up data transfer.</li><br />
      <li>Enhances user experience by improving page load times.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>Enabling Response Compression</h4>
    <h5>1. Add Middleware in <code>Startup.cs</code></h5>
    <pre className={style.codeBlock}>
      {`public void ConfigureServices(IServiceCollection services)
{
    services.AddResponseCompression(options =>
    {
        options.EnableForHttps = true;
    });
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    app.UseResponseCompression();

    app.UseEndpoints(endpoints =>
    {
        endpoints.MapControllers();
    });
}`}
    </pre><br />

    <h5>2. Customize Compression Providers</h5>
    <p>
      You can configure additional providers or settings for specific compression algorithms. For example, to use Brotli:
    </p>
    <pre>
      {`services.AddResponseCompression(options =>
{
    options.Providers.Add<BrotliCompressionProvider>();
    options.EnableForHttps = true;
});`}
    </pre>

    <br />

    <h3>Best Practices for Response Caching and Compression</h3>
    <ul>
      <li>Enable compression only for static or text-based content (e.g., JSON, HTML, CSS, JavaScript).</li><br />
      <li>Use caching carefully for dynamic or sensitive data to avoid serving stale content.</li><br />
      <li>Test the performance impact of different compression algorithms on your specific application.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      By implementing **Response Caching** and **Compression**, you can significantly enhance the performance and scalability of your ASP.NET Core applications. These techniques are essential for delivering a fast and responsive user experience.
    </p>
  </div>
)}


{selectedChapter === 'chapter83' && (
  <div className={style.chaptercontent}>
  
    <h1 className={style.heading}>Optimizing Database Access with EF Core</h1>

    <p>
      Entity Framework Core (EF Core) is a powerful Object-Relational Mapping (ORM) framework that helps developers work with databases using .NET objects.
      However, when dealing with large datasets or complex queries, performance can become a concern. Optimizing database access in EF Core is crucial for improving
      application responsiveness and reducing resource consumption.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Here are several strategies to optimize database access in EF Core:</h3>

    <ul>
      <li>
        <h4>Use AsNoTracking for Read-Only Queries</h4>
        <p>
          EF Core tracks the entities it retrieves from the database, which can be useful for updates and deletes. However, for read-only queries, tracking is unnecessary
          and introduces overhead. You can disable change tracking using the <code>AsNoTracking</code> method.
        </p>
        <pre><code>{`var products = context.Products.AsNoTracking().ToList();`}</code></pre>
        <p>This tells EF Core to return entities without tracking them, improving performance for scenarios where you don't need to update the entities.</p>
      </li><br />

      <li>
        <h4>Efficient Query Design</h4>
        <p>
          Designing efficient queries ensures that the database returns only the necessary data. EF Core can generate complex SQL queries, which can impact performance.
          To improve efficiency:
        </p>
        <ul>
          <li><strong>Limit data:</strong> Always use <code>Where</code>, <code>Select</code>, and <code>Take</code> methods to filter or limit the results.</li><br />
          <li><strong>Avoid N+1 queries:</strong> Ensure that related data is fetched in a single query by using methods like <code>Include</code> or <code>ThenInclude</code> to eagerly load relationships.</li>
        </ul>
        <pre><code>{`var products = context.Products
                                .Include(p => p.Category)
                                .Where(p => p.Price > 100)
                                .ToList();`}</code></pre>
      </li><br />

      <li>
        <h4>Pagination for Large Result Sets</h4>
        <p>
          When working with large datasets, it's important to avoid loading the entire result set into memory at once. Instead, implement pagination with <code>Skip</code>
          and <code>Take</code>.
        </p>
        <pre><code>{`var pageSize = 10;
var pageNumber = 1;

var pagedProducts = context.Products
                            .Skip((pageNumber - 1) * pageSize)
                            .Take(pageSize)
                            .ToList();`}</code></pre>
        <p>This approach ensures that only a subset of records is retrieved, reducing memory usage and improving response time.</p>
      </li><br />

      <li>
        <h4>Use Raw SQL Queries for Complex Operations</h4>
        <p>
          For more complex queries, especially those that require specific optimizations, raw SQL queries can provide better performance than LINQ queries. You can execute
          raw SQL queries directly in EF Core using <code>FromSqlRaw</code> or <code>ExecuteSqlRaw</code>.
        </p>
        <pre><code>{`var products = context.Products
                       .FromSqlRaw("SELECT * FROM Products WHERE Price > {0}", 100)
                       .ToList();`}</code></pre>
        <p>This method can bypass EF Core’s query translation, providing the opportunity for manual query optimizations.</p>
      </li><br />

      <li>
        <h4>Indexing Database Columns</h4>
        <p>
          While not specific to EF Core, ensuring that your database tables are properly indexed can significantly enhance query performance. EF Core can automatically
          use indexes if they are set up in the database.
        </p>
        <pre><code>{`modelBuilder.Entity<Product>()
            .HasIndex(p => p.Price);`}</code></pre>
      </li><br />

      <li>
        <h4>Limit the Number of Queries with Batch Updates</h4>
        <p>
          When dealing with bulk operations, sending many separate queries to the database can be slow. Instead, use EF Core's ability to batch multiple operations in a
          single database request. EF Core supports batch updates and deletes when executed in a loop.
        </p>
        <p>Use a library like <a href="https://github.com/loresoft/EFCore.BulkExtensions">EFCore.BulkExtensions</a> to enable bulk operations, which can drastically reduce the number of queries sent to the database.</p>
      </li><br />

      <li>
        <h4>Connection Pooling</h4>
        <p>
          Connection pooling helps reduce the overhead of establishing database connections. EF Core utilizes connection pooling automatically, but ensure that your database
          connection string includes pooling settings.
        </p>
        <pre><code>{`"ConnectionStrings": {
    "DefaultConnection": "Server=localhost;Database=MyDb;Trusted_Connection=True;MultipleActiveResultSets=true;Pooling=true;"
}`}</code></pre>
      </li><br />

      <li>
        <h4>Caching Results</h4>
        <p>
          If certain queries are run frequently without many changes in the data, consider caching the results using an in-memory cache (e.g., <code>IMemoryCache</code>) or
          a distributed cache (e.g., Redis). This can prevent repeated database calls for the same data, improving performance.
        </p>
        <pre><code>{`var cacheKey = "expensive-query-results";
var cachedResult = _cache.Get<List<Product>>(cacheKey);

if (cachedResult == null)
{
    var result = context.Products.ToList();
    _cache.Set(cacheKey, result, TimeSpan.FromMinutes(10));
    return result;
}

return cachedResult;`}</code></pre>
      </li><br />

      <li>
        <h4>Optimize Query Execution with Compiled Queries</h4>
        <p>
          For queries that are executed frequently with varying parameters, compiled queries can significantly improve performance by pre-compiling the SQL query. EF Core
          supports compiled queries for commonly used LINQ queries.
        </p>
        <pre><code>{`private static readonly Func<MyDbContext, decimal, IQueryable<Product>> _getProductsByPrice =
    EF.CompileQuery((MyDbContext context, decimal price) =>
        context.Products.Where(p => p.Price > price));

var products = _getProductsByPrice(context, 100).ToList();`}</code></pre>
      </li><br />

      <li>
        <h4> Avoiding Lazy Loading in Performance-Critical Code</h4>
        <p>
          While lazy loading can make code more concise, it can lead to multiple database queries, which may degrade performance. It's often better to use explicit loading
          or eager loading to control the data retrieval strategy.
        </p>
        <pre><code>{`var products = context.Products
                       .Include(p => p.Category) // Eager loading
                       .ToList();`}</code></pre>
      </li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Optimizing database access with EF Core involves various techniques that range from query optimization to architectural changes. By following these strategies, you can
      reduce database load, minimize response times, and enhance the overall performance of your ASP.NET Core application. Always profile your queries to understand where
      bottlenecks exist, and continuously monitor your application as it scales to handle larger datasets.
    </p>
  </div>
)}



{selectedChapter === 'chapter84' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Asynchronous Programming and async/await in ASP.NET Core  </h1>

    <p>
      Asynchronous programming is a critical technique for improving the performance of ASP.NET Core applications, especially when handling I/O-bound operations.
      The <code>async</code> and <code>await</code> keywords in C# provide an easy and effective way to implement asynchronous programming.
      This approach helps in reducing the thread-blocking nature of synchronous code and improves the scalability of web applications.
    </p><br />

    <h3>Why Use Asynchronous Programming?</h3>
    <p>
      In a web server environment like ASP.NET Core, handling many requests concurrently can be challenging if each request is blocked while waiting for I/O operations (e.g., database queries, file I/O, network requests).
      Asynchronous programming allows for non-blocking operations, meaning that while one request is waiting for I/O, other requests can be processed simultaneously, improving the overall throughput and responsiveness of the application.
    </p><br />

    <h3>1. <code>async</code> and <code>await</code> Keywords</h3>
    <p>
      The <code>async</code> modifier is applied to methods that perform asynchronous operations, indicating that the method will contain <code>await</code> expressions.
      The <code>await</code> keyword is used to call an asynchronous method and await its result. This allows the thread to continue executing other tasks while waiting for the asynchronous method to complete.
    </p>
    <pre><code>{`public async Task<IActionResult> GetProduct(int id)
{
    var product = await _context.Products.FindAsync(id);
    return View(product);
}`}</code></pre>
    <p>
      In this example, the <code>GetProduct</code> method performs a database query asynchronously, preventing the thread from being blocked while waiting for the database response.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>2. Benefits of Asynchronous Programming in ASP.NET Core</h3>
    <ul>
      <li><strong>Improved Scalability:</strong> Asynchronous methods free up threads, enabling the server to handle more requests concurrently.</li><br />
      <li><strong>Non-blocking I/O:</strong> Asynchronous code helps avoid blocking while waiting for long-running I/O operations like reading from a file, querying a database, or calling external APIs.</li><br />
      <li><strong>Better Resource Utilization:</strong> By avoiding thread-blocking, you can serve more clients with fewer resources, reducing the need for additional threads or expensive multi-threading techniques.</li>
    </ul><br />

    <h3>3. Handling Asynchronous Code Properly</h3>
    <p style={{paddingBottom:"6px"}}>
      When working with asynchronous code, it's essential to handle exceptions and timeouts properly, as well as to ensure that asynchronous methods are used correctly to avoid common pitfalls.
    </p>
    <h4>Exception Handling</h4>
    <p>
      Exceptions in asynchronous code should be handled just like in synchronous code. Use <code>try</code> and <code>catch</code> blocks around asynchronous operations to catch exceptions that may arise during the execution.
    </p>
    <pre><code>{`public async Task<IActionResult> GetProduct(int id)
{
    try
    {
        var product = await _context.Products.FindAsync(id);
        return View(product);
    }
    catch (Exception ex)
    {
        // Log exception
        return View("Error", ex.Message);
    }
}`}</code></pre>
<br />
    <h4>Timeouts</h4>
    <p>
      Sometimes, asynchronous operations may take too long to complete. You can set timeouts to ensure that a request doesn't hang indefinitely.
      For example, you can use the <code>CancellationToken</code> to cancel the operation after a set timeout period.
    </p>
    <pre><code>{`public async Task<IActionResult> GetProductWithTimeout(int id, CancellationToken cancellationToken)
{
    var product = await _context.Products
        .Where(p => p.Id == id)
        .FirstOrDefaultAsync(cancellationToken);
    return View(product);
}`}</code></pre>
    <p>
      In this example, if the request takes too long, the operation will be canceled using the <code>cancellationToken</code> to prevent blocking resources.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>4. Common Asynchronous Patterns in ASP.NET Core</h3>
    <h4>Asynchronous Database Access</h4>
    <p>
      Many operations in ASP.NET Core are related to database access, and using asynchronous methods for querying and updating data can significantly improve performance.
    </p>
    <pre><code>{`public async Task<List<Product>> GetProductsAsync()
{
    return await _context.Products.ToListAsync();
}`}</code></pre><br />

    <h4>Asynchronous File and Stream I/O</h4>
    <p>
      Reading from or writing to files can often block the main thread. Use asynchronous methods such as <code>ReadAsync</code> and <code>WriteAsync</code> to handle file operations asynchronously.
    </p>
    <pre><code>{`public async Task<IActionResult> DownloadFile(string filePath)
{
    var fileBytes = await System.IO.File.ReadAllBytesAsync(filePath);
    return File(fileBytes, "application/octet-stream", "file.txt");
    `}</code></pre><br />

    <h4>Asynchronous API Calls</h4>
    <p>
      When making external API calls, use asynchronous methods to avoid blocking the application thread while waiting for a response.
    </p>
    <pre><code>{`public async Task<IActionResult> GetExternalData()
{
    using var client = new HttpClient();
    var response = await client.GetStringAsync("https://api.example.com/data");
    return View("Data", response);
}`}</code></pre><br />

    <h3 style={{paddingBottom:"6px"}}>5. Best Practices for Asynchronous Programming</h3>
    <ul>
      <li><strong>Use asynchronous APIs whenever possible:</strong> If the method supports async/await, prefer to use it to improve scalability.</li><br />
      <li><strong>Avoid blocking calls:</strong> Don’t use <code>Task.Result</code> or <code>Task.Wait</code>, as they block the thread.</li><br />
      <li><strong>Don’t use async void:</strong> Methods returning <code>void</code> should be avoided, as they can’t be awaited, making error handling difficult.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Asynchronous programming with <code>async</code> and <code>await</code> is a powerful tool in ASP.NET Core that can improve application performance and scalability. By writing non-blocking code, applications can handle many more requests concurrently and reduce resource consumption. Proper use of asynchronous programming patterns and exception handling ensures robust and high-performing applications.
    </p>

   


  </div>
)}

{selectedChapter === 'chapter85' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Profiling and Analyzing Performance   </h1>


    <p>
      Profiling and performance analysis are crucial steps in optimizing an ASP.NET Core application. 
      By identifying performance bottlenecks, such as slow database queries, inefficient algorithms, or excessive memory usage, 
      developers can take actionable steps to improve the responsiveness and scalability of their applications. 
      In this section, we will explore some techniques and tools that can help you profile and analyze performance in ASP.NET Core applications.
    </p><br />

    <h3>1. Profiling Overview</h3>
    <p>
      Profiling refers to the process of collecting data about your application's performance during runtime, such as method execution time, memory usage, and CPU load. 
      This data can help identify performance bottlenecks, slow operations, and potential optimizations. 
      Profiling tools allow developers to measure and analyze these metrics to pinpoint areas that need improvement.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>2. Tools for Profiling and Performance Analysis</h3>

    <h4>2.1. Visual Studio Diagnostic Tools</h4>
    <p>
      Visual Studio provides a powerful set of diagnostic tools for profiling ASP.NET Core applications. These tools allow you to monitor CPU usage, memory consumption, 
      thread activity, and network usage while running your application. The <strong>Performance Profiler</strong> in Visual Studio can help you capture detailed information about where 
      time is being spent in your application.
    </p>
    <ul>
      <li><strong>CPU Usage:</strong> Measure CPU consumption of methods and identify which parts of your code are most resource-intensive.</li><br />
      <li><strong>Memory Usage:</strong> Analyze memory allocation and identify any potential memory leaks.</li><br />
      <li><strong>Function Timing:</strong> Track the time taken by individual methods and functions.</li>
    </ul><br />

    <h4>2.2. Application Insights</h4>
    <p>
      <strong>Application Insights</strong> is a cloud-based monitoring service from Microsoft that helps track the performance of live applications. 
      By adding Application Insights to your ASP.NET Core application, you can collect detailed telemetry data about requests, dependencies, exceptions, and custom events.
    </p>
    <ul>
      <li><strong>Request Performance:</strong> Monitor the time it takes for HTTP requests to be processed by the application.</li><br />
      <li><strong>Dependency Tracking:</strong> Track the performance of external dependencies like database queries and third-party APIs.</li><br />
      <li><strong>Live Metrics Stream:</strong> View real-time data on your application’s performance.</li>
    </ul>
    <pre><code>{`services.AddApplicationInsightsTelemetry(Configuration["ApplicationInsights:InstrumentationKey"]);`}</code></pre><br />

    <h4>2.3. MiniProfiler</h4>
    <p>
      <strong>MiniProfiler</strong> is a lightweight, open-source profiling tool for ASP.NET Core applications. 
      It provides easy-to-use instrumentation to track the performance of requests, database queries, and more.
    </p>
    <ul>
      <li><strong>Request Timing:</strong> Measures the time taken to process a request.</li><br />
      <li><strong>SQL Query Profiling:</strong> Profiles the time taken by database queries executed during a request.</li><br />
      <li><strong>Custom Profiling:</strong> Allows you to add custom timing information to track other aspects of your code.</li>
    </ul>
    <pre><code>{`services.AddMiniProfiler(options =>
{
    options.RouteBasePath = "/profiler";
});`}</code></pre><br />

    <h4>2.4. BenchmarkDotNet</h4>
    <p>
      <strong>BenchmarkDotNet</strong> is a powerful benchmarking library that allows you to measure the performance of individual methods and code snippets. 
      It runs tests under controlled conditions, providing accurate performance data and comparisons between different implementations.
    </p>
    <pre><code>{`[Benchmark]
public void MyMethodBenchmark()
{
    var result = MyMethod();
}`}</code></pre><br />

    <h3 style={{paddingBottom:"6px"}}>3. Performance Metrics to Monitor</h3>

    <h4>3.1. CPU Usage</h4>
    <p>
      High CPU usage often indicates that a method is computationally expensive or that there is unnecessary repetition in code. 
      Profiling CPU usage can help identify which methods consume the most processing power, allowing you to optimize or refactor inefficient code.
    </p><br />

    <h4>3.2. Memory Usage</h4>
    <p>
      Memory profiling allows you to track the memory consumption of your application and helps in identifying memory leaks or excessive memory allocations. 
      In ASP.NET Core, this can be especially important in long-running applications or services where memory usage over time can degrade performance.
    </p><br />

    <h4>3.3. Response Time</h4>
    <p>
      Response time is a critical performance metric that indicates how long it takes for the server to process a request and return a response. 
      High response times can be caused by inefficient database queries, slow third-party services, or blocking I/O operations.
    </p><br />

    <h4>3.4. Database Query Performance</h4>
    <p>
      Poorly optimized database queries can severely affect the performance of your application. By analyzing SQL queries and their execution time, 
      you can identify slow-running queries and optimize them by adding indexes, reducing joins, or simplifying the queries.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>4. Identifying Performance Bottlenecks</h3>

    <h4>4.1. Slow Database Queries</h4>
    <p>
      Database queries are often the most significant source of performance issues in web applications. Profiling database queries using tools like MiniProfiler or 
      SQL Server Profiler can help you identify slow queries and optimize them.
    </p>
    <pre><code>{`var products = context.Products.Where(p => p.Price > 100).ToList();`}</code></pre>
    <p>
      In this example, you might find that querying products based on price without proper indexing can lead to slower response times. 
      Consider adding indexes or optimizing the query to reduce execution time.
    </p><br />

    <h4>4.2. Threading and Concurrency Issues</h4>
    <p>
      Issues related to threading and concurrency can cause performance bottlenecks, such as thread contention, deadlocks, or excessive context switching. 
      Using profiling tools, you can monitor thread activity and diagnose issues related to thread pooling and locking.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>5. Optimizing Based on Profiling Data</h3>
    <h4>5.1. Database Indexing</h4>
    <p>
      One of the most common optimizations after profiling is adding appropriate indexes to your database. 
      Indexing frequently queried columns can drastically reduce query time, especially for large datasets.
    </p>
    <pre><code>{`modelBuilder.Entity<Product>()
            .HasIndex(p => p.Price);`}</code></pre><br />

    <h4>5.2. Query Optimization</h4>
    <p>
      After profiling, you may find that certain queries are inefficient due to unnecessary joins, nested queries, or the retrieval of excessive data. 
      Optimizing these queries can significantly improve performance.
    </p><br />


    <h4>5.3. Caching</h4>
    <p>
      Caching frequently requested data, such as product lists or user profiles, can reduce database load and improve response times. 
      Use in-memory caching or distributed caching to store results that don’t change frequently.
    </p><br />

    <h3>6. Conclusion</h3>
    <p>
      Profiling and performance analysis are essential parts of the optimization process for any ASP.NET Core application. 
      By using the right tools and techniques, you can identify and resolve performance bottlenecks, ensuring that your application is responsive, scalable, and efficient. 
      Always profile your application before and after optimizations to ensure that the changes you make result in real-world improvements.
    </p>
  </div>
)}


   
    {selectedChapter === 'chapter86' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Localization in ASP.NET Core</h1>

    <p>
      Localization is the process of adapting an application to meet the language, cultural, and regional preferences of users in different locales. In ASP.NET Core, localization is a key feature for providing a seamless and personalized experience for users worldwide. It allows your application to support multiple languages and cultures, offering dynamic content based on the user's locale.
    </p>

    <p>
      Globalization, on the other hand, refers to designing the application in such a way that it can be easily adapted to different languages and regions without requiring significant changes. Localization is part of globalization, focusing specifically on the translation of content and adapting cultural elements.
    </p>

   <br />

    <h3>Setting Up Localization in ASP.NET Core</h3>
    <p style={{paddingBottom:"6px"}}>
      To implement localization in ASP.NET Core, you need to configure services, define resources, and make use of culture-based content dynamically. ASP.NET Core provides built-in support for localization, allowing you to manage resources and handle various cultures easily.
    </p>

    <h4>Step 1: Configure Localization Services</h4>
    <p>
      In order to support localization, first, you need to add the localization services in the <code>Startup.cs</code> file. Here’s how you can configure it:
    </p>
    <pre>
      <code>
        public class Startup {'\n'}
        {'  '}public void ConfigureServices(IServiceCollection services) {'\n'}
        {'    '}services.AddLocalization(options {"=>"} options.ResourcesPath = "Resources"); {'\n'}
        {'    '}services.AddControllersWithViews().AddDataAnnotationsLocalization(); {'\n'}
        {'  }'}{' '}
      </code>
    </pre>
    <p>
      This code registers localization services and specifies the path where your resource files will be stored.
    </p><br />

    <h4>Step 2: Define Resource Files</h4>
    <p>
      Resource files (.resx) are used to store localized strings and content. Create a folder named "Resources" in your project, then add .resx files for each language you want to support. For example, create <code>Messages.en-US.resx</code> for English (US) and <code>Messages.fr-FR.resx</code> for French (France).
    </p>

    <pre>
      <code>{`
        // Messages.en-US.resx {'\n'}
        {'  '}WelcomeMessage = "Welcome to our application!"; {'\n'}
        // Messages.fr-FR.resx {'\n'}
        {'  '}WelcomeMessage = "Bienvenue dans notre application!"; {'\n'}
        `}  </code>
    </pre><br />

    <h4>Step 3: Set the Culture for Localization</h4>
    <p>
      You can set the culture dynamically based on the user’s preference or browser settings. Here’s how to configure culture in <code>Startup.cs</code>:
    </p>
    <pre>
      <code>{`
        public class Startup {'\n'}
        {'  '}public void Configure(IApplicationBuilder app, IHostingEnvironment env) {'\n'}
        {'    '}var supportedCultures = new[] {"en-US", "fr-FR"}; {'\n'}
        {'    '}var localizationOptions = new RequestLocalizationOptions {'\n'}
        {'      '}DefaultRequestCulture = new RequestCulture("en-US"), {'\n'}
        {'      '}SupportedCultures = supportedCultures, {'\n'}
        {'      '}SupportedUICultures = supportedCultures {'\n'}
        {'    };'} {'\n'}
        {'    '}app.UseRequestLocalization(localizationOptions); {'\n'}
        {'  }'}{' '}
       `} </code>
    </pre>
    <p>
      This configuration sets "en-US" as the default culture but supports both English and French languages.
    </p><br />

    <h4>Step 4: Using Localized Strings in Views</h4>
    <p>
      Once you’ve set up the localization, you can use the localized strings in your views. You can inject the <code>IStringLocalizer</code> service into your views to access these strings. For example:
    </p>

    <pre>
      <code>{`
        @inject IStringLocalizer&lt;Messages&gt; localizer {'\n'}
        <h2>@localizer["WelcomeMessage"]</h2>
       `} </code>
    </pre>
    <p>
      The <code>localizer</code> instance will fetch the appropriate string based on the current culture (e.g., "Welcome to our application!" for English or "Bienvenue dans notre application!" for French).
    </p>

    <br />
    <h3>Advantages of Localization</h3>
    <ul>
      <li>Improves user experience by offering content in the user's native language.</li><br />
      <li>Helps expand your application's reach to a global audience.</li><br />
      <li>Supports cultural and regional preferences, such as number formats and date styles.</li>
    </ul><br />

    <h4>Localization vs Globalization</h4>
    <p>
      Localization is a specific part of the broader process of globalization. While globalization prepares an application to be adaptable to different languages and regions, localization focuses on translating and adjusting content for each locale. In short:
    </p>
    <ul>
      <li><strong>Globalization</strong> involves designing software that can handle multiple languages, cultures, and regions.</li><br />
      <li><strong>Localization</strong> involves adapting the software to a specific language, country, or region.</li>
    </ul>

  <br />

    <h3 style={{paddingBottom:"6px"}}>Common Issues in Localization</h3>
    <ul>
      <li><strong>Resource Management:</strong> Ensure that all text is properly localized and that resource files are up to date.</li><br />
      <li><strong>Cultural Sensitivity:</strong> Be mindful of cultural differences, such as symbols, colors, and images that might have different meanings in different cultures.</li><br />
      <li><strong>Complexity in Maintaining Multiple Cultures:</strong> Managing and maintaining different resource files for many cultures can be challenging as the application grows.</li>
    </ul>
  </div>
)}



    {selectedChapter === 'chapter87' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Implementing Resource Files for Localization</h1>

    <p>
      Resource files are a core part of implementing localization in ASP.NET Core applications. They store key-value pairs that represent localized content. These resource files are culture-specific and allow an application to dynamically serve content based on the user's language and region.
    </p>

    <p>
      In ASP.NET Core, resource files are typically created with the extension <code>.resx</code> and can be stored in a directory structure that reflects different cultures. The resource file for each language contains the translated strings used throughout the application, providing a simple yet effective way to manage multiple languages.
    </p>

  <br />

    <h3>Step 1: Creating Resource Files</h3>
    <p style={{paddingBottom:"6px"}}>
      To begin implementing localization, you need to create resource files for each culture that your application will support. These files will contain key-value pairs, where the key is the name of the resource and the value is the translated text for a particular culture.
    </p>

    <h4>Creating Default Resource File</h4>
    <p>
      The first resource file you create should be for the default culture (usually "en-US" for English). This file will contain the original strings for your application.
    </p>
    <p>
      In Visual Studio, right-click on your project and add a new folder called <code>Resources</code>. Then, add a new resource file named <code>Messages.resx</code> in this folder. For example:
    </p>
    <pre>
      <code>
        // Messages.resx {'\n'}
        {'  '}WelcomeMessage = "Welcome to our application!"; {'\n'}
        {'  '}GoodbyeMessage = "Thank you for using our app!"; {'\n'}
      </code>
    </pre>
    <p>
      This resource file will store the default English text. You can then add other resource files for additional cultures.
    </p><br />

    <h4>Creating Culture-Specific Resource Files</h4>
    <p>
      For each additional language you want to support, create a new resource file for that culture. For example, to add French (France) support, create a resource file called <code>Messages.fr-FR.resx</code> in the same <code>Resources</code> folder:
    </p>
    <pre>
      <code>
        // Messages.fr-FR.resx {'\n'}
        {'  '}WelcomeMessage = "Bienvenue dans notre application!"; {'\n'}
        {'  '}GoodbyeMessage = "Merci d'avoir utilisé notre application!"; {'\n'}
      </code>
    </pre>
    <p>
      You can create resource files for as many cultures as needed, following the same naming pattern: <code>Messages.[culture-code].resx</code>.
    </p>

   <br />

    <h3>Step 2: Configuring ASP.NET Core for Localization</h3>
    <p style={{paddingBottom:"6px"}}>
      After creating your resource files, you need to configure your ASP.NET Core application to support localization. This involves adding localization services and specifying the available cultures and the default culture.
    </p>

    <h4>Configuring Localization in Startup.cs</h4>
    <p>
      In your <code>Startup.cs</code> file, you need to configure localization by registering the necessary services and defining the supported cultures.
    </p>
    <pre>
      <code>{`
        public class Startup {'\n'}
        {'  '}public void ConfigureServices(IServiceCollection services) {'\n'}
        {'    '}services.AddLocalization(options => options.ResourcesPath = "Resources"); {'\n'}
        {'    '}services.AddControllersWithViews().AddDataAnnotationsLocalization(); {'\n'}
        {'  }'} {'\n'}

        public void Configure(IApplicationBuilder app, IHostingEnvironment env) {'\n'}
        {'    '}var supportedCultures = new[] {"en-US", "fr-FR"}; {'\n'}
        {'    '}var localizationOptions = new RequestLocalizationOptions {'\n'}
        {'      '}DefaultRequestCulture = new RequestCulture("en-US"), {'\n'}
        {'      '}SupportedCultures = supportedCultures, {'\n'}
        {'      '}SupportedUICultures = supportedCultures {'\n'}
        {'    };'} {'\n'}
        {'    '}app.UseRequestLocalization(localizationOptions); {'\n'}
        {'  }'}
     `} </code>
    </pre>
    <p>
      In this code:
    </p>
    <ul>
      <li><strong>ResourcesPath</strong> specifies the folder where your resource files are located.</li><br />
      <li><strong>SupportedCultures</strong> defines the list of cultures supported by your application.</li><br />
      <li><strong>DefaultRequestCulture</strong> sets the default culture when a user’s culture preference is not specified.</li><br />
      <li><strong>UseRequestLocalization</strong> applies the localization settings to the request pipeline.</li>
    </ul>

<br />

    <h3>Step 3: Accessing Localized Resources in Views and Controllers</h3>
    <p style={{paddingBottom:"6px"}}>
      Once the resource files and localization configuration are set up, you can start using the localized strings in your views and controllers. ASP.NET Core provides the <code>IStringLocalizer</code> service, which allows you to access resources and get the appropriate translations for the current culture.
    </p>

    <h4>Using Localized Strings in Views</h4>
    <p>
      In your Razor views, you can inject the <code>IStringLocalizer</code> to access the localized strings. For example:
    </p>
    <pre>
      <code>{`
        @inject IStringLocalizer&lt;Messages&gt; localizer {'\n'}
        <h2>@localizer["WelcomeMessage"]</h2>
        <p>@localizer["GoodbyeMessage"]</p>
       `} </code>
    </pre>
    <p>
      The <code>localizer</code> will automatically fetch the appropriate translation based on the user's current culture. If the user’s culture is set to "fr-FR," it will show "Bienvenue dans notre application!" instead of "Welcome to our application!"
    </p><br />

    <h4>Using Localized Strings in Controllers</h4>
    <p>
      You can also use the <code>IStringLocalizer</code> in your controllers to provide localized messages in responses or in business logic. Here’s an example:
    </p>
    <pre>
      <code>{`
        public class HomeController : Controller {'\n'}
        {'  '}private readonly IStringLocalizer&lt;Messages&gt; _localizer; {'\n'}
        {'  '}public HomeController(IStringLocalizer&lt;Messages&gt; localizer) {'\n'}
        {'    '}_localizer = localizer; {'\n'}
        {'  }'} {'\n'}

        {'  '}public IActionResult Index() {'\n'}
        {'    '}ViewBag.Message = _localizer["WelcomeMessage"]; {'\n'}
        {'    '}return View(); {'\n'}
        {'  }'}
      `}</code>
    </pre>
    <p>
      In this example, the controller fetches the localized "WelcomeMessage" and passes it to the view via <code>ViewBag</code>.
    </p>

 <br />

    <h3>Step 4: Changing Culture Dynamically</h3>
    <p>
      You may want to allow users to change the language of the application dynamically. To do this, you can set the culture for the current request based on user input, such as a language switcher.
    </p>

    <pre>
      <code>{`
        public class CultureController : Controller {'\n'}
        {'  '}public IActionResult SetCulture(string culture) {'\n'}
        {'    '}CultureInfo.CurrentCulture = new CultureInfo(culture); {'\n'}
        {'    '}CultureInfo.CurrentUICulture = new CultureInfo(culture); {'\n'}
        {'    '}return RedirectToAction("Index", "Home"); {'\n'}
        {'  }'}
    `}</code>
    </pre>
    <p>
      This controller action sets the current culture and UI culture based on the language passed by the user (e.g., "en-US" or "fr-FR"). After changing the culture, the user is redirected to the Home page, where the appropriate language is applied.
    </p>

   <br />

    <h3 style={{paddingBottom:"6px"}}>Advantages of Using Resource Files for Localization</h3>
    <ul>
      <li>Centralized management of localized strings, making it easier to update translations.</li><br />
      <li>Separation of code and content, allowing developers and translators to work independently.</li><br />
      <li>Flexibility in supporting multiple languages and cultures without requiring code changes.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Common Issues in Localization with Resource Files</h3>
    <ul>
      <li><strong>Missing Translations:</strong> Ensure that all resource files have the necessary translations for all keys.</li><br />
      <li><strong>Incorrect Culture Detection:</strong> Double-check that the application is correctly detecting the user's culture.</li><br />
      <li><strong>Performance Considerations:</strong> If you're working with large numbers of resource files, consider using caching mechanisms for improved performance.</li>
    </ul>
  </div>
)}



    {selectedChapter === 'chapter88' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Culture and Language Settings in ASP.NET Core</h1>

    <p>
      In ASP.NET Core, culture and language settings play a critical role in delivering a personalized and localized user experience. By properly configuring the culture and language settings, you can ensure that your application adapts to the user’s locale, displaying dates, times, numbers, currencies, and text in a way that is appropriate for the user’s language and cultural preferences.
    </p>
<br />

    <h3>What is Culture and Language in ASP.NET Core?</h3>
    <p>
      Culture refers to the set of preferences used by the operating system to format and display culture-specific data, such as date and time, numeric formats, and currencies. Language refers to the specific language that content is displayed in, such as English (en-US), French (fr-FR), or Spanish (es-ES).
    </p>
    <p>
      In ASP.NET Core, culture and language are managed by the <code>RequestLocalizationMiddleware</code>, which is responsible for determining which culture to use for each request based on the user’s preferences, the request's culture settings, or fallback mechanisms.
    </p>
<br />

    <h3>Step 1: Configuring Localization Services</h3>
    <p style={{paddingBottom:"6px"}}>
      Before you can manage culture and language settings, you need to add localization services to your application. This allows ASP.NET Core to handle culture and language preferences effectively.
    </p>

    <h4>Adding Localization Services in Startup.cs</h4>
    <p>
      In the <code>ConfigureServices</code> method of your <code>Startup.cs</code> file, you will need to register localization services.
    </p>
    <pre>
      <code>{`
        public void ConfigureServices(IServiceCollection services) {'\n'}
        {'  '}services.AddLocalization(options => options.ResourcesPath = "Resources"); {'\n'}
        {'  '}services.AddControllersWithViews().AddDataAnnotationsLocalization(); {'\n'}
      `}</code>
    </pre>
    <p>
      In the above code, <code>options.ResourcesPath</code> specifies the folder where your resource files are located. You can also add <code>AddDataAnnotationsLocalization()</code> if you want to use localized validation messages.
    </p>

   <br />

    <h3>Step 2: Configuring Request Localization</h3>
    <p style={{paddingBottom:"6px"}}>
      Once the localization services are configured, the next step is to configure the request localization middleware. This middleware determines which culture should be used for each HTTP request based on various factors such as browser preferences, user selection, or default settings.
    </p>

    <h4>Configuring Culture Options</h4>
    <p>
      In the <code>Configure</code> method of your <code>Startup.cs</code>, configure the <code>RequestLocalizationOptions</code> to specify the supported cultures and the default culture for your application.
    </p>
    <pre>
      <code>{`    
          public void Configure(IApplicationBuilder app, IHostingEnvironment env) {'\n'}
        {'  '}var supportedCultures = new[] {"en-US", "fr-FR", "es-ES"}; {'\n'}
        {'  '}var localizationOptions = new RequestLocalizationOptions {'\n'}
        {'    '}DefaultRequestCulture = new RequestCulture("en-US"), {'\n'}
        {'    '}SupportedCultures = supportedCultures, {'\n'}
        {'    '}SupportedUICultures = supportedCultures {'\n'}
        {'  };'} {'\n'}
        {'  '}app.UseRequestLocalization(localizationOptions); {'\n'}
       `} </code>
    </pre>
    <p>
      In this code:
    </p>
    <ul>
      <li><strong>SupportedCultures:</strong> Defines a list of cultures supported by the application. In this case, English (en-US), French (fr-FR), and Spanish (es-ES) are supported.</li><br />
      <li><strong>DefaultRequestCulture:</strong> Specifies the default culture if no culture is provided in the request (in this example, "en-US").</li><br />
      <li><strong>SupportedUICultures:</strong> Determines which cultures are available for localizing UI elements.</li>
    </ul>

<br />

    <h3>Step 3: Using Culture in the Application</h3>
    <p style={{paddingBottom:"6px"}}>
      Once you have configured localization, your application can detect the culture and language settings for each request. The culture is determined based on several factors, including the user's language preferences, the query string, or cookies.
    </p>

    <h4>Detecting Culture from Request</h4>
    <p>
      The <code>RequestLocalizationMiddleware</code> checks for culture information in the following order:
    </p>
    <ul>
      <li><strong>Query String:</strong> The culture can be specified in the query string using a parameter, such as <code>?culture=en-US</code>.</li><br />
      <li><strong>Cookies:</strong> If the user has previously selected a language, the culture can be stored in a cookie and retrieved automatically on subsequent requests.</li><br />
      <li><strong>Accept-Language Header:</strong> The culture can be inferred from the browser’s <code>Accept-Language</code> header. This is the most common method for determining the user's language preference.</li>
    </ul>
    <p>
      ASP.NET Core automatically uses these factors to set the appropriate culture for the current request. You can also manually set the culture if needed, for example, in a controller or middleware.
    </p><br />

    <h4>Using Culture in Views</h4>
    <p>
      In Razor views, you can access the current culture using the <code>CultureInfo.CurrentCulture</code> property. This allows you to dynamically display content based on the user’s culture.
    </p>
    <pre>
      <code>{`
        @using System.Globalization {'\n'}
        <p>Current culture: @CultureInfo.CurrentCulture.Name</p>
      `}</code>
    </pre>
    <p>
      This will display the name of the current culture (e.g., "en-US" or "fr-FR") in the view.
    </p><br />

    <h4>Using Culture in Controllers</h4>
    <p>
      In controllers, you can access the current culture via the <code>CultureInfo.CurrentCulture</code> property as well. You can use this to modify the response based on the user’s culture preferences.
    </p>
    <pre>
      <code>{`
        public class HomeController : Controller {'\n'}
        {'  '}public IActionResult Index() {'\n'}
        {'    '}string currentCulture = CultureInfo.CurrentCulture.Name; {'\n'}
        {'    '}ViewBag.Culture = currentCulture; {'\n'}
        {'    '}return View(); {'\n'}
        {'  }'}
       `} </code>
    </pre>

   <br />

    <h3>Step 4: Allowing Users to Change Language</h3>
    <p style={{paddingBottom:"6px"}}>
      Allowing users to change the language dynamically is a common requirement in multilingual applications. To achieve this, you can provide a mechanism such as a language switcher that allows the user to select their preferred language.
    </p>

    <h4>Changing Language in the Application</h4>
    <p>
      You can use a controller to handle the language switch. When the user selects a new language, you can update the culture and store it in a cookie, so it persists across requests.
    </p>
    <pre>
      <code>{`
        public class LanguageController : Controller {'\n'}
        {'  '}public IActionResult SetLanguage(string language) {'\n'}
        {'    '}CultureInfo.CurrentCulture = new CultureInfo(language); {'\n'}
        {'    '}CultureInfo.CurrentUICulture = new CultureInfo(language); {'\n'}
        {'    '}Response.Cookies.Append("culture", language); {'\n'}
        {'    '}return RedirectToAction("Index", "Home"); {'\n'}
        {'  }'}
      `}</code>
    </pre>
    <p>
      This action sets the current culture and UI culture based on the selected language and stores it in a cookie. The user is then redirected back to the home page with the new language applied.
    </p>

 <br />

    <h3><strong>Step 5: Persisting Culture Settings</strong></h3>
    <p>
      To ensure that the user’s language preference persists across requests, you can store the selected language in a cookie, which can be read by the <code>RequestLocalizationMiddleware</code> on each subsequent request.
    </p>

    <p>
      In the <code>Configure</code> method of your <code>Startup.cs</code> file, you can configure the localization middleware to read the culture setting from a cookie:
    </p>
    <pre>
      <code>{`
        public void Configure(IApplicationBuilder app, IHostingEnvironment env) {'\n'}
        {'  '}var localizationOptions = new RequestLocalizationOptions {'\n'}
        {'    '}SetDefaultCulture("en-US") {'\n'}
        {'    '}AddSupportedCultures("en-US", "fr-FR", "es-ES"); {'\n'}
        {'    '}AddSupportedUICultures("en-US", "fr-FR", "es-ES"); {'\n'}
        {'  };'} {'\n'}
        {'  '}app.UseRequestLocalization(localizationOptions); {'\n'}
       `} </code>
    </pre>
  </div>
)}



    {selectedChapter === 'chapter89' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building Multilingual Applications</h1>

    <p>
      Building multilingual applications is a crucial part of reaching a global audience. It allows your application to cater to users from different linguistic backgrounds by providing them with content in their native language. ASP.NET Core offers robust support for building multilingual applications through its localization and globalization features.
    </p>

  <br />

    <h3>What is a Multilingual Application?</h3>
    <p>
      A multilingual application is one that provides content in more than one language. Such an application dynamically adapts its interface and content based on the user’s language preference, making it more accessible and user-friendly across different regions and cultures.
    </p>
    <p>
      Localization is the process of adapting an application to meet the language and cultural needs of a specific region. Globalization is the broader concept of designing an application that can be adapted to various languages and regions.
    </p>

    <br />

    <h3>Step 1: Configuring Localization for Multiple Languages</h3>
    <p style={{paddingBottom:"6px"}}>
      The first step in building a multilingual application is to set up localization for different languages. This involves configuring localization services, selecting the supported languages, and defining resource files for each language.
    </p>

    <h4>Configuring Localization Services</h4>
    <p>
      You need to register localization services in your <code>Startup.cs</code> file, specifying the supported languages and the path to your resource files. In the example below, we set up localization to support English, French, and Spanish.
    </p>
    <pre>
      <code>{`
        public void ConfigureServices(IServiceCollection services) {'\n'}
        {'  '}services.AddLocalization(options => options.ResourcesPath = "Resources"); {'\n'}
        {'  '}services.AddControllersWithViews().AddDataAnnotationsLocalization(); {'\n'}
        `}  </code>
    </pre>
    <p>
      The <code>ResourcesPath</code> is where the resource files for different languages will be located. These resource files will contain the translated content for each language.
    </p>

    <br />


    <h3>Step 2: Creating Resource Files for Different Languages</h3>
    <p style={{paddingBottom:"6px"}}>
      Resource files are central to localization in ASP.NET Core. These files store translations for various text elements in your application, such as UI labels, error messages, and other content. The files are named according to the language they support, with a default resource file for the base language (typically English).
    </p>

    <h4>Example of Resource Files</h4>
    <p>
      Let’s say we have a multilingual application that supports English, French, and Spanish. You would create the following resource files:
    </p>
    <ul>
      <li><strong>Resources/Views/Home/Index.en-US.resx</strong> – English translations</li><br />
      <li><strong>Resources/Views/Home/Index.fr-FR.resx</strong> – French translations</li><br />
      <li><strong>Resources/Views/Home/Index.es-ES.resx</strong> – Spanish translations</li>
    </ul>
    <p>
      Each of these files will contain key-value pairs where the key is the text identifier (e.g., "WelcomeMessage"), and the value is the translated text in the corresponding language.
    </p>
    <pre>
      <code>{`
        <!-- Index.en-US.resx -->
        WelcomeMessage = "Welcome to our website!"
      </code>
    </pre>
    <pre>
      <code>
        <!-- Index.fr-FR.resx -->
        WelcomeMessage = "Bienvenue sur notre site Web!"
      `}</code>
    </pre>
    <pre>
      <code>{`
        <!-- Index.es-ES.resx -->
        WelcomeMessage = "¡Bienvenido a nuestro sitio web!"
      `}</code>
    </pre>
<br />

    <h3>Step 3: Using Resource Files in Views and Controllers</h3>
    <p style={{paddingBottom:"6px"}}>
      Once the resource files are created, you can use the resources in your views and controllers to display localized content.
    </p>

    <h4>Using Localization in Views</h4>
    <p>
      In Razor views, you can use the <code>@localizer</code> syntax to inject localized strings from your resource files. The following example demonstrates how to display a localized message in a view:
    </p>
    <pre>
      <code>{`
           @using Microsoft.Extensions.Localization {'\n'}
        @inject IStringLocalizer<HomeController> localizer {'\n'}
        <h1>@localizer["WelcomeMessage"]</h1>
      `}</code>
    </pre>
    <p>
      The <code>IStringLocalizer</code> interface is used to retrieve localized strings from the resource files based on the current culture.
    </p><br />

    <h4>Using Localization in Controllers</h4>
    <p>
      In controllers, you can also inject the <code>IStringLocalizer</code> to retrieve localized strings and pass them to the view. Here’s an example of how to pass a localized string to a view from a controller:
    </p>
    <pre>
      <code>{`
        public class HomeController : Controller {'\n'}
        {'  '}private readonly IStringLocalizer<HomeController> _localizer; {'\n'}
        {'  '}public HomeController(IStringLocalizer<HomeController> localizer) {'\n'}
        {'    '}_localizer = localizer; {'\n'}
        {'  '}public IActionResult Index() {'\n'}
        {'    '}ViewBag.Message = _localizer["WelcomeMessage"]; {'\n'}
        {'    '}return View(); {'\n'}
        {'  }'}
      `}</code>
    </pre>
    <p>
      The <code>ViewBag.Message</code> will now contain the localized message based on the current culture, which can be displayed in the Razor view.
    </p>

  <br />

    <h3>Step 4: Changing Languages Dynamically</h3>
    <p style={{paddingBottom:"6px"}}>
      Allowing users to switch languages dynamically is a common requirement in multilingual applications. In ASP.NET Core, you can achieve this by modifying the culture settings on the fly, typically through a language selector UI element.
    </p>

    <h4>Language Selector in the UI</h4>
    <p>
      You can create a dropdown or buttons to allow users to select their preferred language. When a user selects a new language, the application updates the current culture and reloads the page with the selected language.
    </p>

    <pre>
      <code>{`
        <form asp-controller="Language" asp-action="SetLanguage">
          <select name="language">
            <option value="en-US">English</option>
            <option value="fr-FR">Français</option>
            <option value="es-ES">Español</option>
          </select>
          <button type="submit">Change Language</button>
        </form>
      `}</code>
    </pre><br />

    <h4>Controller Action to Set Language</h4>
    <p>
      In the <code>LanguageController</code>, you can create an action to handle the language change and set the culture for the request.
    </p>
    <pre>
      <code>{`
        public class LanguageController : Controller {'\n'}
        {'  '}public IActionResult SetLanguage(string language) {'\n'}
        {'    '}CultureInfo.CurrentCulture = new CultureInfo(language); {'\n'}
        {'    '}CultureInfo.CurrentUICulture = new CultureInfo(language); {'\n'}
        {'    '}Response.Cookies.Append("culture", language); {'\n'}
        {'    '}return RedirectToAction("Index", "Home"); {'\n'}
        {'  }'}
     `} </code>
    </pre>
    <p>
      This action sets the culture for the current request and stores the selected language in a cookie so that the user’s language preference is preserved across sessions.
    </p>

  <br />

    <h3>Step 5: Persisting Language Preferences</h3>
    <p>
      To ensure that the language selection is persistent across sessions, you can store the user’s language preference in a cookie. This way, even if the user navigates to a different page or revisits the application later, their selected language will be automatically applied.
    </p>

    <pre>
      <code>{`
        public void Configure(IApplicationBuilder app, IHostingEnvironment env) {'\n'}
        {'  '}var localizationOptions = new RequestLocalizationOptions {'\n'}
        {'    '}SetDefaultCulture("en-US") {'\n'}
        {'    '}AddSupportedCultures("en-US", "fr-FR", "es-ES"); {'\n'}
        {'    '}AddSupportedUICultures("en-US", "fr-FR", "es-ES"); {'\n'}
        {'  };'} {'\n'}
        {'  '}app.UseRequestLocalization(localizationOptions); {'\n'}
      `}</code>
    </pre>
    <p>
      The localization middleware will automatically read the language preference from the cookie and set the culture for each request.
    </p>
  </div>
)}




{selectedChapter === 'chapter90' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Deploying ASP.NET Core Applications to IIS</h1>

    <h2>1. Preparing Your Environment</h2>
    <p style={{paddingBottom:"6px"}}>
      Before deploying your application, ensure that the hosting environment is set up correctly.
    </p>

    <h3>Install .NET Core Hosting Bundle</h3>
    <p>
      Download and install the <strong>.NET Core Hosting Bundle</strong> on the server. This includes the required runtime, libraries, and IIS integration components.
    </p>
    <pre>
      {`Download URL: https://dotnet.microsoft.com/download/dotnet`}
    </pre><br />

    <h3>Enable IIS and Required Modules</h3>
    <p>
      Ensure that IIS and the necessary modules, such as <strong>WebSocket Protocol</strong> and <strong>IIS Management Console</strong>, are enabled:
    </p>
    <ol>
      <li>Open <strong>Control Panel &gt; Programs &gt; Turn Windows features on or off</strong>.</li><br />
      <li>Enable <strong>Internet Information Services (IIS)</strong>.</li><br />
      <li>Under <strong>Application Development Features</strong>, enable <strong>ASP.NET</strong> and <strong>WebSocket Protocol</strong>.</li>
    </ol>

    <br />

    <h2>2. Configuring Your Application for Deployment</h2>
    <p style={{paddingBottom:"6px"}}>Configure your application to run effectively in the IIS environment.</p>

    <h3>Publish Your Application</h3>
    <p>
      Use the <strong>dotnet publish</strong> command to package your application for deployment:
    </p>
    <pre>
      {`dotnet publish -c Release -o C:\\publish\\MyApp`}
    </pre>
    <p>
      This command generates the compiled application files in the specified folder <code>C:\publish\MyApp</code>.
    </p><br />

    <h3>Set up the <code>web.config</code> File</h3>
    <p>
      Ensure your project includes a <code>web.config</code> file for IIS. This file helps IIS understand how to process the application.
    </p>
    <pre>
      {`<?xml version="1.0" encoding="utf-8"?>
<configuration>
  <system.webServer>
    <handlers>
      <add name="aspNetCore" path="*" verb="*" modules="AspNetCoreModuleV2" resourceType="Unspecified" />
    </handlers>
    <aspNetCore processPath="dotnet" arguments="MyApp.dll" stdoutLogEnabled="false" stdoutLogFile=".\logs\stdout" />
  </system.webServer>
</configuration>`}
    </pre>

   <br />

    <h2 style={{paddingBottom:"6px"}}>3. Deploying the Application to IIS</h2>
    <h3>Create a New Site in IIS</h3>
    <p>Follow these steps to create a new site in IIS:</p>
    <ol>
      <li>Open <strong>IIS Manager</strong>.</li><br />
      <li>Right-click <strong>Sites</strong> and select <strong>Add Website</strong>.</li><br />
      <li>
        Provide a <strong>Site Name</strong>, point the <strong>Physical Path</strong> to your published application folder, and specify a unique <strong>Port Number</strong>.
      </li><br />
      <li>Click <strong>OK</strong> to create the site.</li>
    </ol><br />

    <h3>Set Application Pool Settings</h3>
    <p>
      Ensure the application pool is configured to use the correct .NET Core runtime:
    </p>
    <ol>
      <li>In IIS Manager, select the newly created site and click <strong>Advanced Settings</strong>.</li><br />
      <li>Ensure <strong>Managed Pipeline Mode</strong> is set to <strong>Integrated</strong>.</li><br />
      <li>Set <strong>.NET CLR Version</strong> to <strong>No Managed Code</strong> (for .NET Core applications).</li>
    </ol>

    <br />
    <h2 style={{paddingBottom:"6px"}}>4. Testing and Troubleshooting</h2>
    <h3>Verify the Deployment</h3>
    <p>
      Open a browser and navigate to the site’s URL (e.g., <code>http://localhost:5000</code> or <code>http://yourdomain.com</code>).
    </p><br />

    <h3>Check IIS Logs</h3>
    <p>
      If the application fails to load, check the <strong>stdout</strong> logs specified in the <code>web.config</code> file for errors. You can also examine the IIS logs for additional details.
    </p>

  <br />

    <h2>5. Best Practices for IIS Hosting</h2>
    <ul>
      <li>Enable HTTPS for secure communication.</li><br />
      <li>Configure <strong>Application Initialization</strong> to preload the app.</li><br />
      <li>Regularly update the hosting bundle to the latest version.</li><br />
      <li>Set up automatic restarts for application pools in case of failures.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Deploying ASP.NET Core applications to IIS provides a stable and feature-rich hosting solution for Windows environments. By following this guide, you can ensure a seamless deployment process and optimal application performance.
    </p>
  </div>
)}



{selectedChapter === 'chapter91' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Hosting ASP.NET Core Applications in Azure</h1>


      <p>
        Azure provides multiple services and platforms to host ASP.NET Core applications, catering to various scalability, security, and performance needs. The most commonly used Azure hosting options include <strong>Azure App Services</strong>, <strong>Virtual Machines (VMs)</strong>, and <strong>Azure Kubernetes Service (AKS)</strong>. Each of these solutions offers distinct benefits depending on the deployment and operational requirements.
      </p>

   <br />

      <h3>1. Hosting in Azure App Services</h3>
      <p style={{paddingBottom:"6px"}}>Azure App Services is a fully managed platform-as-a-service (PaaS) offering that simplifies hosting web applications without managing the underlying infrastructure.</p>
      <h4>Key Features:</h4>
      <ul>
        <li><strong>Managed Platform</strong>: Handles infrastructure, security patches, and scaling automatically.</li><br />
        <li><strong>Built-in CI/CD Integration</strong>: Supports deployment from GitHub, Azure DevOps, and other CI/CD pipelines.</li><br />
        <li><strong>Custom Domains and SSL</strong>: Easy to configure custom domains with free and managed SSL certificates.</li><br />
        <li><strong>Auto-scaling</strong>: Automatically scales applications based on demand.</li><br />
        <li><strong>Monitoring and Diagnostics</strong>: Integrated Application Insights for performance and logging.</li>
      </ul><br />
      <h4>Deployment Steps:</h4>
      <ol>
        <li>
          <strong>Build and Publish Your ASP.NET Core App:</strong>
          <ul>
            <li>Use the <code>dotnet publish</code> command to create a release build.</li>
          </ul>
        </li><br />
        <li>
          <strong>Create an Azure App Service:</strong>
          <ul>
            <li>Navigate to the Azure portal, select <strong>App Services</strong>, and create a new app service.</li>
          </ul>
        </li><br />
        <li>
          <strong>Deploy the Application:</strong>
          <ul>
            <li>Use Visual Studio’s <strong>Publish</strong> feature, Azure CLI, or GitHub Actions to deploy the app.</li>
          </ul>
        </li><br />
        <li>
          <strong>Configure App Settings:</strong>
          <ul>
            <li>Use the <strong>Configuration</strong> tab in Azure to set environment variables, connection strings, etc.</li>
          </ul>
        </li>
      </ol><br />
      <h4 style={{paddingBottom:"6px"}}>Best Use Cases:</h4>
      <ul>
        <li>Small to medium-sized applications.</li><br />
        <li>Apps requiring rapid deployment and managed services.</li>
      </ul>

 <br />

      <h3>2. Hosting on Azure Virtual Machines (VMs)</h3>
      <p style={{paddingBottom:"6px"}}>Azure VMs provide full control over the server environment and are ideal for applications with specific infrastructure or configuration requirements.</p>
      <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
      <ul>
        <li><strong>Custom Configurations</strong>: Full access to the OS, allowing custom setups.</li><br />
        <li><strong>Scalability</strong>: Supports manual or automatic scaling of VMs.</li><br />
        <li><strong>Hybrid Deployment</strong>: Integrates with on-premises systems.</li><br />
        <li><strong>Wide OS Support</strong>: Options for Windows and Linux environments.</li>
      </ul><br />
      <h4 style={{paddingBottom:"6px"}}>Deployment Steps:</h4>
      <ol>
        <li><strong>Set Up the VM:</strong> Create a VM in the Azure portal and configure its OS, size, and network.</li><br />
        <li><strong>Install Required Software:</strong> Install .NET Core Runtime and any other dependencies on the VM.</li><br />
        <li><strong>Deploy the Application:</strong> Use Remote Desktop Protocol (RDP) or SSH to copy and run your application.</li><br />
        <li><strong>Configure Networking:</strong> Open necessary ports (e.g., 80 for HTTP, 443 for HTTPS) and set up a public IP if needed.</li>
      </ol><br />
      <h4 style={{paddingBottom:"6px"}}>Best Use Cases:</h4>
      <ul>
        <li>Large applications requiring fine-grained control over the server.</li><br />
        <li>Legacy applications not compatible with PaaS.</li>
      </ul>

    <br />

      <h3>3. Hosting in Azure Kubernetes Service (AKS)</h3>
      <p style={{paddingBottom:"6px"}}>Azure Kubernetes Service (AKS) is a container orchestration service ideal for deploying microservices or containerized applications.</p>
      <h4 style={{paddingBottom:"6px"}}>Key Features:</h4>
      <ul>
        <li><strong>Container Orchestration</strong>: Manages deployment, scaling, and operations of containers.</li><br />
        <li><strong>Scalability</strong>: Dynamically scales workloads based on demand.</li><br />
        <li><strong>Integration with DevOps</strong>: Supports CI/CD pipelines for containerized deployments.</li><br />
        <li><strong>Service Discovery</strong>: Automatically handles service networking within the cluster.</li>
      </ul><br />
      <h4 style={{paddingBottom:"6px"}}>Deployment Steps:</h4>
      <ol>
        <li><strong>Prepare Your Application:</strong> Dockerize your ASP.NET Core app using a <code>Dockerfile</code>.</li><br />
        <li><strong>Create an AKS Cluster:</strong> Use the Azure portal, CLI, or ARM templates to provision an AKS cluster.</li><br />
        <li><strong>Push Docker Image to Container Registry:</strong> Push your app’s Docker image to Azure Container Registry or Docker Hub.</li><br />
        <li><strong>Deploy Using Kubernetes Manifests:</strong> Create YAML files for deployments, services, and ingress controllers and apply the configuration using <code>kubectl apply</code>.</li><br />
        <li><strong>Monitor and Manage:</strong> Use Azure Monitor and Kubernetes Dashboard for observability.</li>
      </ol><br />
      <h4 style={{paddingBottom:"6px"}}>Best Use Cases:</h4>
      <ul>
        <li>Applications built with microservices architecture.</li><br />
        <li>Scenarios requiring high availability and load balancing.</li>
      </ul>

     <br />

      <h3>Comparison of Hosting Options</h3>
      <table>
        <thead>
          <tr>
            <th>Feature</th>
            <th>Azure App Services</th>
            <th>Azure VMs</th>
            <th>AKS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Ease of Setup</strong></td>
            <td>High</td>
            <td>Medium</td>
            <td>Low</td>
          </tr>
          <tr>
            <td><strong>Scalability</strong></td>
            <td>Automatic</td>
            <td>Manual or scripted</td>
            <td>Automatic</td>
          </tr>
          <tr>
            <td><strong>Cost</strong></td>
            <td>Moderate</td>
            <td>Variable (based on size/config)</td>
            <td>Higher initial investment</td>
          </tr>
          <tr>
            <td><strong>Control</strong></td>
            <td>Low</td>
            <td>High</td>
            <td>Medium</td>
          </tr>
          <tr>
            <td><strong>Best For</strong></td>
            <td>Rapid deployments</td>
            <td>Custom setups or legacy apps</td>
            <td>Large-scale, containerized apps</td>
          </tr>
        </tbody>
      </table>

     <br />
      <h3 style={{paddingBottom:"6px"}}>Best Practices for Hosting in Azure</h3>
      <ul>
        <li><strong>Optimize Costs:</strong> Use <strong>Azure Cost Management</strong> to monitor and optimize resources.</li><br />
        <li><strong>Enable Auto-scaling:</strong> Ensure your app can handle traffic spikes by configuring scaling rules.</li><br />
        <li><strong>Secure Your App:</strong> Implement <strong>Azure Security Center</strong> recommendations and enable HTTPS.</li><br />
        <li><strong>Monitor Performance:</strong> Use <strong>Application Insights</strong> and <strong>Log Analytics</strong> for proactive monitoring.</li><br />
        <li><strong>Automate Deployments:</strong> Leverage CI/CD pipelines to streamline deployments.</li>
      </ul><br />

      <p>
        By selecting the right Azure hosting option and following best practices, you can ensure your ASP.NET Core application is robust, scalable, and secure.
      </p>

    </div>
)}



{selectedChapter === 'chapter92' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Docker and Containerization for ASP.NET Core Apps</h1>

    <p>
      Docker and containerization provide a lightweight, consistent, and efficient way to deploy 
      and manage ASP.NET Core applications across different environments. By containerizing 
      your application, you can ensure that it runs seamlessly, regardless of the underlying 
      system configuration.
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Key Benefits of Docker for ASP.NET Core Apps</h2>
    <ul>
      <li>
        <strong>Portability:</strong> Docker containers ensure your application runs the same 
        way across development, staging, and production.
      </li><br />
      <li>
        <strong>Scalability:</strong> Easily scale containers to handle varying workloads 
        using orchestration tools like Kubernetes.
      </li><br />
      <li>
        <strong>Isolation:</strong> Each container runs independently, reducing conflicts 
        between dependencies and applications.
      </li><br />
      <li>
        <strong>Speed:</strong> Faster development, testing, and deployment cycles due to 
        lightweight containerized environments.
      </li>
    </ul>

<br />

    <h2 style={{paddingBottom:"6px"}}>Getting Started with Docker</h2>
    <h3>Step 1: Install Docker</h3>
    <p>
      Download and install Docker Desktop from the 
      <a href="https://www.docker.com/products/docker-desktop" target="_blank" rel="noopener noreferrer">
        official Docker website
      </a>. Ensure Docker is running and set up on your system.
    </p><br />

    <h3>Step 2: Create a Dockerfile</h3>
    <p>
      A <code>Dockerfile</code> defines the environment and steps to containerize your ASP.NET Core application. 
      Below is an example:
    </p>
    <pre className={style.codeblock}>
      {`# Use the official ASP.NET Core runtime image
FROM mcr.microsoft.com/dotnet/aspnet:7.0 AS base
WORKDIR /app
EXPOSE 80
EXPOSE 443

# Use the .NET Core SDK image for building the app
FROM mcr.microsoft.com/dotnet/sdk:7.0 AS build
WORKDIR /src
COPY ["MyApp.csproj", "./"]
RUN dotnet restore "./MyApp.csproj"
COPY . .
RUN dotnet build "./MyApp.csproj" -c Release -o /app/build

# Publish the app
FROM build AS publish
RUN dotnet publish "./MyApp.csproj" -c Release -o /app/publish

# Create the final image
FROM base AS final
WORKDIR /app
COPY --from=publish /app/publish .
ENTRYPOINT ["dotnet", "MyApp.dll"]`}
    </pre><br />

    <h3>Step 3: Build and Run the Container</h3>
    <ol>
      <li>
        Build the Docker image using the command:
        <pre className={style.codeblock}>docker build -t my-aspnet-app .</pre>
      </li><br />
      <li>
        Run the Docker container:
        <pre className={style.codeblock}>docker run -d -p 8080:80 my-aspnet-app</pre>
        Access the app at <code>http://localhost:8080</code>.
      </li>
    </ol>

   <br />

    <h2>Container Orchestration with Docker</h2>
    <p style={{paddingBottom:"6px"}}>
      While Docker is excellent for containerizing applications, managing multiple containers 
      requires orchestration tools like Kubernetes or Docker Compose. These tools provide 
      scaling, load balancing, and fault-tolerance capabilities.
    </p>
    <h3>Using Docker Compose</h3>
    <p>
      Create a <code>docker-compose.yml</code> file to define multiple services for your app:
    </p>
    <pre className={style.codeblock}>
      {`version: '3.4'
services:
  web:
    image: my-aspnet-app
    build:
      context: .
    ports:
      - "8080:80"
    environment:
      - ASPNETCORE_ENVIRONMENT=Production`}
    </pre>
    <p>Run the following command to start all services:</p>
    <pre className={style.codeblock}>docker-compose up</pre>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Docker and ASP.NET Core Apps</h2>
    <ul>
      <li>
        <strong>Use Multi-Stage Builds:</strong> Reduce image size by separating build 
        and runtime environments.
      </li><br />
      <li>
        <strong>Leverage .dockerignore:</strong> Exclude unnecessary files from the Docker 
        context to speed up builds.
      </li><br />
      <li>
        <strong>Automate Builds:</strong> Integrate Docker with CI/CD pipelines for efficient 
        deployments.
      </li><br />
      <li>
        <strong>Monitor Containers:</strong> Use Docker’s built-in stats or tools like 
        Prometheus and Grafana for observability.
      </li>
    </ul>

 <br />

    <h2>Conclusion</h2>
    <p>
      Docker simplifies deployment and management of ASP.NET Core apps, offering portability, 
      scalability, and efficiency. By leveraging Docker alongside orchestration tools, you 
      can build robust, scalable, and production-ready applications.
    </p>

  </div>
)}



{selectedChapter === 'chapter93' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Continuous Integration/Continuous Deployment (CI/CD) with Azure DevOps or GitHub Actions
    </h1>

    <p>
      Continuous Integration/Continuous Deployment (CI/CD) is a development practice that automates 
      building, testing, and deploying applications. Azure DevOps and GitHub Actions are popular tools 
      for implementing CI/CD pipelines for ASP.NET Core applications, offering seamless integration 
      with development workflows and cloud environments.
    </p>

<br />

    <h2 style={{paddingBottom:"6px"}}>Key Benefits of CI/CD</h2>
    <ul>
      <li>
        <strong>Automation:</strong> Reduces manual effort by automating build, test, and deployment processes.
      </li><br />
      <li>
        <strong>Faster Delivery:</strong> Speeds up application delivery by enabling frequent and reliable deployments.
      </li><br />
      <li>
        <strong>Quality Assurance:</strong> Ensures code quality through automated testing in the pipeline.
      </li><br />
      <li>
        <strong>Collaboration:</strong> Facilitates teamwork by integrating with version control systems like Git.
      </li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Setting Up CI/CD with Azure DevOps</h2>
    <h3 style={{paddingBottom:"6px"}}>1. Create a New Azure DevOps Project</h3>
    <ul>
      <li>
        Go to the <a href="https://dev.azure.com" target="_blank" rel="noopener noreferrer">Azure DevOps portal</a> 
        and create a new project.
      </li><br />
      <li>
        Connect your Git repository to the project.
      </li>
    </ul><br />

    <h3>2. Configure the Build Pipeline</h3>
    <p>
      Use the YAML editor or the classic editor to define a build pipeline. Below is an example YAML configuration:
    </p>
    <pre className={style.codeblock}>
      {`trigger:
  branches:
    include:
      - main

pool:
  vmImage: 'windows-latest'

steps:
- task: UseDotNet@2
  inputs:
    packageType: 'sdk'
    version: '7.x'

- script: dotnet build
  displayName: 'Build Project'

- script: dotnet test
  displayName: 'Run Tests'`}
    </pre><br />

    <h3>3. Set Up a Release Pipeline</h3>
    <ul>
      <li>
        Navigate to the **Pipelines {">"} Releases** section and create a new release pipeline.
      </li><br />
      <li>
        Add a deployment stage and configure it to deploy your app to an Azure App Service or other environments.
      </li><br />
      <li>
        Link the build pipeline as an artifact source.
      </li>
    </ul>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Setting Up CI/CD with GitHub Actions</h2>
    <h3>1. Create a Workflow File</h3>
    <p>
      In your GitHub repository, create a `.github/workflows` directory and add a YAML file (e.g., `ci-cd.yml`) 
      to define the workflow. Below is an example configuration:
    </p>
    <pre><code>
      {`name: CI/CD Pipeline

on:
  push:
    branches:
      - main

jobs:
  build:
    runs-on: ubuntu-latest
    steps:
    - uses: actions/checkout@v3
    - name: Set up .NET
      uses: actions/setup-dotnet@v3
      with:
        dotnet-version: 7.x
    - name: Install Dependencies
      run: dotnet restore
    - name: Build Project
      run: dotnet build --no-restore
    - name: Run Tests
      run: dotnet test --no-build
  deploy:
    needs: build
    runs-on: ubuntu-latest
    steps:
    - name: Deploy to Azure
      uses: azure/webapps-deploy@v2
      with:
        app-name: 'your-app-name'
        slot-name: 'production'
        publish-profile: $/{{ secrets.AZURE_WEBAPP_PUBLISH_PROFILE }}/`}
 </code></pre><br />

    <h3 style={{paddingBottom:"6px"}}>2. Configure Secrets</h3>
    <ul>
      <li>
        Add deployment secrets (e.g., `AZURE_WEBAPP_PUBLISH_PROFILE`) in the repository’s **Settings {">"} Secrets and Variables** section.
      </li><br />
      <li>
        Ensure your app name and other deployment details match your Azure environment.
      </li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for CI/CD</h2>
    <ul>
      <li>
        <strong>Use Branch Policies:</strong> Require code reviews and successful builds before merging.
      </li><br />
      <li>
        <strong>Automate Testing:</strong> Include unit, integration, and end-to-end tests in your pipeline.
      </li><br />
      <li>
        <strong>Monitor Deployments:</strong> Use tools like Azure Monitor or GitHub Actions logs to track deployment performance.
      </li><br />
      <li>
        <strong>Rollback Strategy:</strong> Implement mechanisms to revert to previous deployments if an issue occurs.
      </li><br />
      <li>
        <strong>Keep Pipelines Efficient:</strong> Optimize steps to avoid redundant tasks and reduce build times.
      </li>
    </ul>

  <br />

    <h2>Conclusion</h2>
    <p>
      CI/CD pipelines with Azure DevOps or GitHub Actions streamline application development and deployment, 
      ensuring faster delivery and better quality. By integrating these tools into your workflows, 
      you can build robust, scalable, and maintainable ASP.NET Core applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter94' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to gRPC in ASP.NET Core</h1>

    <p>
      gRPC (gRPC Remote Procedure Call) is a high-performance, open-source framework for building modern,
      lightweight, and efficient remote procedure call services. It uses Protocol Buffers (protobuf) for serialization
      and supports various programming languages. ASP.NET Core provides built-in support for gRPC, making it an
      excellent choice for microservices and distributed systems.
    </p>

 <br />

    <h2 style={{ paddingBottom: "6px" }}>Key Features of gRPC</h2>
    <ul>
      <li>
        <strong>High Performance:</strong> Uses HTTP/2 for reduced latency and improved speed.
      </li><br />
      <li>
        <strong>Cross-Language Support:</strong> Compatible with multiple programming languages, including C#, Java, and Python.
      </li><br />
      <li>
        <strong>Strong Typing:</strong> Uses Protocol Buffers to define contracts, ensuring strongly typed communication.
      </li><br />
      <li>
        <strong>Streaming Support:</strong> Supports client, server, and bi-directional streaming.
      </li><br />
      <li>
        <strong>Simple Definition:</strong> Services are defined using a simple `.proto` file.
      </li>
    </ul><br />

  

    <h2 style={{ paddingBottom: "6px" }}>Setting Up gRPC in ASP.NET Core</h2>
    <h3>1. Create a gRPC Project</h3>
    <ul>
      <li>Open Visual Studio and create a new <strong>ASP.NET Core gRPC Service</strong> project.</li>
      <li>Select the desired .NET version and name your project.</li>
    </ul><br />

    <h3>2. Define gRPC Service in a `.proto` File</h3>
    <p>Add a `.proto` file to define the gRPC service and its methods. For example:</p>
    <pre>
      {`syntax = "proto3";

service Greeter {
  rpc SayHello (HelloRequest) returns (HelloReply);
}

message HelloRequest {
  string name = 1;
}

message HelloReply {
  string message = 1;
}`}
    </pre><br />

    <h3>3. Generate C# Code from the `.proto` File</h3>
    <p>
      Use the gRPC tools included in the ASP.NET Core project to auto-generate C# classes for the service and messages.
    </p><br />

    <h3>4. Implement the gRPC Service</h3>
    <p>
      Inherit from the generated base class to implement the service methods. Example:
    </p>
    <pre className={style.codeblock}>
      {`public class GreeterService : Greeter.GreeterBase {
  public override Task<HelloReply> SayHello(HelloRequest request, ServerCallContext context) {
    return Task.FromResult(new HelloReply {
      Message = $"Hello, {request.Name}"
    });
  }
}`}
    </pre><br />

    <h3>5. Configure gRPC in Startup</h3>
    <p>Update the `Program.cs` or `Startup.cs` file to configure gRPC. Example:</p>
    <pre className={style.codeblock}>
      {`app.MapGrpcService<GreeterService>();
app.MapGet("/", () => "Communication with gRPC endpoints must be made using a gRPC client.");`}
    </pre>

  <br />

    <h2 style={{ paddingBottom: "6px" }}>gRPC Communication Types</h2>
    <ul>
      <li><strong>Unary:</strong> Single request and response (e.g., `SayHello`).</li><br />
      <li><strong>Server Streaming:</strong> Client sends a single request, and the server sends a stream of responses.</li><br />
      <li><strong>Client Streaming:</strong> Client sends a stream of requests, and the server sends a single response.</li><br />
      <li><strong>Bi-Directional Streaming:</strong> Both client and server send streams of messages simultaneously.</li>
    </ul>

   <br />

    <h2 style={{ paddingBottom: "6px" }}>Best Practices for gRPC in ASP.NET Core</h2>
    <ul>
      <li><strong>Use HTTP/2:</strong> Ensure your server and client support HTTP/2 for optimal performance.</li><br />
      <li><strong>Optimize Protobuf:</strong> Use proper field types and avoid unnecessary nesting in `.proto` files.</li><br />
      <li><strong>Secure Communication:</strong> Use TLS to encrypt communication between clients and servers.</li><br />
      <li><strong>Monitor Performance:</strong> Use tools like Application Insights or gRPC-specific logs for observability.</li><br />
      <li><strong>Version Your APIs:</strong> Ensure backward compatibility by versioning your `.proto` files.</li>
    </ul>

   <br />

    <h2>Conclusion</h2>
    <p>
      gRPC is a powerful framework for building fast, efficient, and cross-platform services. With ASP.NET Core's
      built-in support, you can easily implement gRPC to create modern, scalable, and maintainable applications.
      By following best practices and leveraging gRPC's features, you can ensure robust communication in your
      distributed systems.
    </p>
  </div>
)}






{selectedChapter === 'chapter95' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building gRPC Services</h1>

        <p>Building gRPC services in <strong>ASP.NET Core</strong> enables developers to create high-performance, cross-platform, and scalable distributed systems. Using Protocol Buffers (protobuf) for message serialization ensures that services are lightweight and efficient. ASP.NET Core provides a seamless development environment for building gRPC services with features like integrated tooling and HTTP/2 support.</p>
        
       <br />

        <h2 style={{paddingBottom:"6px"}}>Steps to Build gRPC Services</h2>

        <h3>1. Setup the Project</h3>
        <ul>
          <li><strong>Create a gRPC Project:</strong>
            <ul>
              <li>Open <strong>Visual Studio</strong> and select the <strong>ASP.NET Core gRPC Service</strong> template.</li><br />
              <li>Choose your desired .NET version and configure the project name and location.</li>
            </ul>
          </li><br />
          <li><strong>Add Required NuGet Packages (if needed):</strong>
            <pre><code>dotnet add package Grpc.AspNetCore<br/>
dotnet add package Grpc.Tools</code></pre>
          </li>
        </ul>
<br />

        <h3>2. Define gRPC Services in <code>.proto</code> Files</h3>
        <p>Add a <code>.proto</code> file to define your gRPC services.</p>
        <pre><code>{` syntax = "proto3";

service Calculator {
  rpc Add (AddRequest) returns (AddResponse);
}

message AddRequest {
  int32 number1 = 1;
  int32 number2 = 2;
}

message AddResponse {
  int32 result = 1;
        }   `}</code></pre><br />
        <ul>
          <li style={{paddingBottom:"6px"}}><strong>Key Points:</strong></li>
          <ul>
            <li><code>syntax = "proto3"</code> specifies the protobuf version.</li><br />
            <li><code>service</code> defines the gRPC service name.</li><br />
            <li><code>message</code> defines the structure of request and response data.</li>
          </ul>
        </ul>

       <br />

        <h3>3. Generate C# Code from <code>.proto</code></h3>
        <p>The gRPC tools automatically generate C# classes for services and messages based on <code>.proto</code> definitions.</p>
        <pre><code>&lt;Protobuf Include="Protos\\calculator.proto" /&gt;</code></pre>

        <br />

        <h3>4. Implement the gRPC Service</h3>
        <pre><code>{`public class CalculatorService : Calculator.CalculatorBase {
  public override Task&lt;AddResponse&gt; Add(AddRequest request, ServerCallContext context) {
      return Task.FromResult(new AddResponse {
          Result = request.Number1 + request.Number2
      });
  }
        } `}</code></pre><br />

      
          <li><strong>Key Points:</strong></li>
          <ul>
            <li>Use the base class (e.g., <code>Calculator.CalculatorBase</code>) generated from <code>.proto</code>.</li><br />
            <li>Implement the methods defined in the <code>.proto</code> service.</li>
          </ul>
       
<br />

        <h3>5. Configure gRPC in ASP.NET Core</h3>
        <pre><code>{`var builder = WebApplication.CreateBuilder(args);
var app = builder.Build();

app.MapGrpcService&lt;CalculatorService&gt;();
app.MapGet("/", () => "Use a gRPC client to communicate.");

app.Run();</code></pre>
        <p><strong>HTTP/2 Configuration:</strong> Ensure that the server supports HTTP/2 for gRPC.</p>
        <pre><code>{
  "Kestrel": {
    "EndpointDefaults": {
      "Protocols": "Http2"
    }
  }
        }`}</code></pre>

       <br />

        <h2 style={{paddingBottom:"6px"}}>gRPC Communication Types</h2>
        <ul>
          <li><strong>Unary RPC:</strong> Single request and response.</li><br />
          <li><strong>Server Streaming:</strong> Single request with multiple streamed responses.</li><br />
          <li><strong>Client Streaming:</strong> Multiple requests with a single response.</li><br />
          <li><strong>Bi-Directional Streaming:</strong> Both client and server exchange streams of messages.</li>
        </ul><br />
        <pre><code>rpc SayHello (HelloRequest) returns (HelloReply);</code></pre>

        <br />

        <h2 style={{paddingBottom:"6px"}}>Best Practices for Building gRPC Services</h2>
        <ul>
          <li><strong>Optimize Protobuf Messages:</strong> Keep message structures simple and avoid unnecessary nesting.</li><br />
          <li><strong>Enable Secure Communication:</strong> Use TLS to encrypt communication.</li><br />
          <li><strong>Monitor Performance:</strong> Use observability tools for gRPC-specific logs.</li><br />
          <li><strong>Version Control for <code>.proto</code> Files:</strong> Maintain backward compatibility when updating gRPC services.</li><br />
          <li><strong>Leverage HTTP/2 Benefits:</strong> Ensure both server and client support HTTP/2 for optimal performance.</li>
        </ul>

      <br />

        <h2>Conclusion</h2>
        <p>Building gRPC services in ASP.NET Core provides a robust framework for modern applications requiring high performance, strong typing, and efficient communication. By leveraging ASP.NET Core's built-in tooling and adhering to best practices, developers can create scalable and maintainable distributed systems.</p>
     
  </div>
)}



    {selectedChapter === 'chapter96' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Consuming gRPC Services</h1>

        <p>Consuming gRPC services in <strong>ASP.NET Core</strong> or other platforms enables seamless communication between clients and servers using lightweight and efficient Protocol Buffers (protobuf). With gRPC, you can interact with services defined in <code>.proto</code> files through generated client libraries. gRPC supports multiple languages and enables building cross-platform, high-performance systems.</p>

      <br />

        <h2 style={{paddingBottom:"6px"}}>Steps to Consume gRPC Services</h2>

        <h3>1. Add gRPC Client NuGet Package</h3>
        <ul>
          <li>Add the required gRPC client package to your project:</li>
        </ul>
        <pre><code>dotnet add package Grpc.Net.Client</code></pre><br />

        <h3>2. Add the <code>.proto</code> File</h3>
        <ul>
          <li>Include the same <code>.proto</code> file used by the server in your project.</li><br />
          <li>Configure the <code>.csproj</code> file to include the <code>.proto</code> for client generation:</li>
        </ul>
        <pre><code>&lt;ItemGroup&gt;
  &lt;Protobuf Include="Protos\\calculator.proto" GrpcServices="Client" /&gt;
&lt;/ItemGroup&gt;</code></pre><br />

        <h3>3. Generate Client Code</h3>
        <ul>
          <li>The gRPC tools automatically generate a strongly-typed client based on the <code>.proto</code> file.</li>
        </ul><br />

        <h3>4. Create the gRPC Client</h3>
        <p>Initialize the gRPC client in your application:</p>
        <pre><code>{`using Grpc.Net.Client;
using Calculator;

var channel = GrpcChannel.ForAddress("https://localhost:5001");
var client = new Calculator.CalculatorClient(channel);`}</code></pre><br />

        <h3>5. Call the gRPC Methods</h3>
        <ul>
          <li>Use the generated client to call gRPC methods. For example, calling the <code>Add</code> method:</li>
        </ul>
        <pre><code>{`var request = new AddRequest { Number1 = 10, Number2 = 20 };
var response = await client.AddAsync(request);

Console.WriteLine($"Result: {response.Result}");`}</code></pre>

        <br />

        <h2 style={{paddingBottom:"6px"}}>gRPC Communication Types</h2>
        <ul>
          <li><strong>Unary RPC:</strong> Single request and single response.</li><br />
          <li><strong>Server Streaming:</strong> Single request and multiple streamed responses.</li><br />
          <li><strong>Client Streaming:</strong> Multiple requests and a single response.</li><br />
          <li><strong>Bi-Directional Streaming:</strong> Both client and server exchange multiple messages simultaneously.</li>
        </ul><br />

        <h3>Example: Server Streaming</h3>
        <pre><code>{`using var call = client.StreamNumbers(new NumberRequest { Start = 1, Count = 5 });
await foreach (var number in call.ResponseStream.ReadAllAsync()) {
    Console.WriteLine($"Received: {number.Value}");
        }`}</code></pre>

      <br />

        <h2 style={{paddingBottom:"6px"}}>Best Practices for Consuming gRPC Services</h2>
        <ol>
          <li><strong>Use Secure Communication:</strong> Always use HTTPS for gRPC communication.</li><br />
          <li><strong>Reuse gRPC Channels:</strong> Avoid recreating <code>GrpcChannel</code> instances for better performance.</li><br />
          <li><strong>Handle Errors Gracefully:</strong> Use proper exception handling to manage network or server errors.</li><br />
          <li><strong>Version Control:</strong> Maintain backward compatibility when updating <code>.proto</code> files.</li><br />
          <li><strong>Monitor Performance:</strong> Use monitoring tools to analyze gRPC calls and identify bottlenecks.</li>
        </ol>

       <br />

        <h2>Conclusion</h2>
        <p>Consuming gRPC services provides developers with a powerful and efficient way to interact with distributed systems. By leveraging strongly-typed generated clients and following best practices, you can build robust and scalable applications that seamlessly communicate with gRPC-based APIs.</p>


  </div>
)}

   
{selectedChapter === 'chapter97' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>HTTP/2 and gRPC Streaming</h1>


    <h3>1. Introduction to HTTP/2 in gRPC</h3>
    <ul>
      <li>
        <strong>What is HTTP/2?</strong>
        <ul>
          <li>A binary, multiplexed protocol designed to replace HTTP/1.1.</li>
          <li>Enables efficient, low-latency communication by supporting features like multiplexing, header compression, and server push.</li>
        </ul>
      </li><br />
      <li>
        <strong>Why HTTP/2 for gRPC?</strong>
        <ul>
          <li>Essential for the performance and real-time capabilities of gRPC.</li>
          <li>Provides bidirectional streaming and low-overhead communication.</li>
        </ul>
      </li>
    </ul><br />

    <h3>2. gRPC Streaming Modes</h3>
    <p>gRPC supports various streaming patterns that leverage HTTP/2 capabilities:</p>
    <ul>
      <li>
        <strong>Unary RPC:</strong> One request, one response. The simplest form of gRPC communication (non-streaming).
      </li><br />
      <li>
        <strong>Server-Side Streaming:</strong> Client sends a single request, and the server responds with a stream of data.
    
        <em>Example:</em> Live updates, real-time data feeds.
      </li><br />
      <li>
        <strong>Client-Side Streaming:</strong> Client sends a stream of requests to the server, which sends back a single response.
        <br />
        <em>Example:</em> Sending logs or telemetry data to a server.
      </li><br />
      <li>
        <strong>Bidirectional Streaming:</strong> Both client and server exchange streams of data simultaneously.
        <br />
        <em>Example:</em> Chat applications, collaborative tools, or real-time gaming.
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>3. Advantages of Streaming in gRPC</h3>
    <ul>
      <li><strong>Efficiency:</strong> Streams multiple messages over a single HTTP/2 connection, reducing connection overhead.</li><br />
      <li><strong>Low Latency:</strong> Enables real-time data transfer with minimal delay.</li><br />
      <li><strong>Concurrency:</strong> Allows multiple streams over a single connection using multiplexing.</li><br />
      <li><strong>Scalability:</strong> Supports efficient, high-performance communication for large-scale systems.</li>
    </ul><br />

    <h3>4. Implementing Streaming in gRPC</h3>
    <p>Below is an example of defining the service in the Proto file:</p>
    <pre>
      <code>
        {`syntax = "proto3";

service StockPrice {
  rpc GetPrices(StockRequest) returns (stream StockResponse);
}

message StockRequest {
  string stockSymbol = 1;
}

message StockResponse {
  string stockSymbol = 1;
  double price = 2;
  string timestamp = 3;
}`}
      </code>
    </pre>
    <ul>
      <li><strong>Generate the Stub and Implement the Server:</strong> Use the gRPC tools to generate language-specific stubs and write the server-side logic.</li><br />
      <li><strong>Consume the Stream on the Client Side:</strong> Use the generated client code to handle the streaming responses.</li>
    </ul><br />

    <h3>5. Handling Errors and Timeouts in Streaming</h3>
    <ul>
      <li>
        <strong>Error Handling:</strong> Implement proper error codes (e.g., <code>DeadlineExceeded</code>, <code>Unavailable</code>). Gracefully close the stream in case of failures.
      </li><br />
      <li>
        <strong>Timeouts:</strong> Use deadlines to prevent long-running or hanging streams.
        <br />
        <em>Example:</em> Configure timeouts on both client and server.
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>6. Best Practices for gRPC Streaming</h3>
    <ul>
      <li>Keep streams short-lived to avoid excessive resource usage.</li><br />
      <li>Implement flow control to prevent overwhelming the server or client.</li><br />
      <li>Leverage TLS for encrypting HTTP/2 traffic.</li><br />
      <li>Use observability tools to monitor stream performance and detect bottlenecks.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>7. Real-World Use Cases</h3>
    <ul>
      <li><strong>Real-Time Communication:</strong> Chat apps, multiplayer games.</li><br />
      <li><strong>Live Data Feeds:</strong> Financial tickers, telemetry data.</li><br />
      <li><strong>File Transfer:</strong> Large file uploads/downloads using client or server streaming.</li><br />
      <li><strong>IoT:</strong> Continuous data from sensors to centralized systems.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      HTTP/2 forms the backbone of gRPC, enabling advanced streaming capabilities that improve communication efficiency and performance. Understanding and effectively using gRPC streaming can unlock real-time capabilities for modern applications, making it an essential tool for developers.
    </p>
  </div>
)}

   
    {selectedChapter === 'chapter98' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building Background Services in ASP.NET Core</h1>

   
       
        <p>Background services in ASP.NET Core allow you to run long-running tasks or periodic jobs outside the scope of HTTP requests. These services are ideal for tasks like sending emails, processing data, or performing maintenance jobs. ASP.NET Core provides the <strong>IHostedService</strong> interface, which can be used to implement background services.</p>

    <br />

        <h2>Background Services in ASP.NET Core</h2>
        <p>A background service is a class that runs in the background and performs tasks asynchronously. The key components of a background service in ASP.NET Core are:</p>
        <ul>
          <li><strong>IHostedService:</strong> An interface for hosting background tasks.</li><br />
          <li><strong>BackgroundService:</strong> A base class that implements <strong>IHostedService</strong> and provides a framework for long-running tasks.</li><br />
          <li><strong>Task Execution:</strong> Background services typically execute tasks on a separate thread or in an infinite loop, such as polling a queue or checking for scheduled tasks.</li>
        </ul>

     <br />

        <h2>Creating a Simple Background Service</h2>
        <p style={{paddingBottom:"6px"}}>Let’s begin by creating a basic background service in ASP.NET Core.</p>

        <h4>Step 1: Define the Background Service</h4>
        <p>First, create a new class that inherits from <code>BackgroundService</code> and overrides the <code>ExecuteAsync</code> method.</p>
        <pre><code>{`public class MyBackgroundService : BackgroundService {
    private readonly ILogger<MyBackgroundService> _logger;

    public MyBackgroundService(ILogger<MyBackgroundService> logger) {
        _logger = logger;
    }

    protected override async Task ExecuteAsync(CancellationToken stoppingToken) {
        while (!stoppingToken.IsCancellationRequested) {
            _logger.LogInformation("Background Service is running at: {time}", DateTimeOffset.Now);
            await Task.Delay(10000, stoppingToken);  // Simulate background work
        }
    }
        }`}</code></pre>

        <p>In this example, the <code>ExecuteAsync</code> method runs an infinite loop that logs a message every 10 seconds. The loop continues until the service is cancelled.</p><br />

        <h4>Step 2: Register the Service in Startup</h4>
        <p>Next, register the background service in the <code>Program.cs</code> or <code>Startup.cs</code> file to ensure it runs when the application starts.</p>
        <pre><code>{`public class Program {
    public static void Main(string[] args) {
        CreateHostBuilder(args).Build().Run();
    }

    public static IHostBuilder CreateHostBuilder(string[] args) =>
        Host.CreateDefaultBuilder(args)
            .ConfigureServices((hostContext, services) => {
                services.AddHostedService<MyBackgroundService>();
            });
}`}</code></pre>

        <p>By calling <code>{`services.AddHostedService<MyBackgroundService>();`}</code>, the background service is registered to run automatically when the application starts.</p>

       <br />

        <h2>Periodic Background Tasks</h2>
        <p style={{paddingBottom:"6px"}}>Sometimes, background tasks need to run at regular intervals (e.g., polling an API, sending email notifications, or cleaning up old records). You can use a <code>Timer</code> or implement a periodic task inside your <code>ExecuteAsync</code> method.</p>

        <h4>Example: Periodic Task with Timer</h4>
        <pre><code>{`public class PeriodicBackgroundService : BackgroundService {
    private readonly ILogger<PeriodicBackgroundService> _logger;
    private Timer _timer;

    public PeriodicBackgroundService(ILogger<PeriodicBackgroundService> logger) {
        _logger = logger;
    }

    protected override Task ExecuteAsync(CancellationToken stoppingToken) {
        _timer = new Timer(DoWork, null, TimeSpan.Zero, TimeSpan.FromMinutes(5));  // Execute every 5 minutes
        return Task.CompletedTask;
    }

    private void DoWork(object state) {
        _logger.LogInformation("Periodic background task executed at: {time}", DateTimeOffset.Now);
    }

    public override Task StopAsync(CancellationToken cancellationToken) {
        _timer?.Dispose();
        return base.StopAsync(cancellationToken);
    }
        }`}</code></pre>

        <p>In this example, a <code>Timer</code> is used to execute the <code>DoWork</code> method every 5 minutes. The service will automatically stop the timer when the application shuts down.</p>

        <br />

        <h2>Graceful Shutdown of Background Services</h2>
        <p style={{paddingBottom:"6px"}}>Graceful shutdown ensures that background tasks finish their work before the application stops. ASP.NET Core provides cancellation tokens to monitor when the application is shutting down.</p>

        <h4>Example: Graceful Shutdown Implementation</h4>
        <pre><code>{`public class GracefulShutdownBackgroundService : BackgroundService {
    private readonly ILogger<GracefulShutdownBackgroundService> _logger;

    public GracefulShutdownBackgroundService(ILogger<GracefulShutdownBackgroundService> logger) {
        _logger = logger;
    }

    protected override async Task ExecuteAsync(CancellationToken stoppingToken) {
        while (!stoppingToken.IsCancellationRequested) {
            _logger.LogInformation("Background Service running at: {time}", DateTimeOffset.Now);
            await Task.Delay(10000, stoppingToken);  // Simulate background work
        }
    }

    public override async Task StopAsync(CancellationToken cancellationToken) {
        _logger.LogInformation("Graceful shutdown started.");
        await base.StopAsync(cancellationToken);
        _logger.LogInformation("Background service stopped gracefully.");
    }
        }`}</code></pre>

        <p>The <code>StopAsync</code> method is overridden to handle tasks that need to be completed before the service shuts down, such as closing resources or saving progress.</p>

       <br />

        <h2>Running Background Services in a Web Application</h2>
        <p style={{paddingBottom:"6px"}}>ASP.NET Core background services can be useful even in a web application context. For example, you might want to schedule background jobs such as sending email notifications, processing queued tasks, or generating reports.</p>

        <h4>Example: Background Email Service</h4>
        <pre><code>{`public class EmailBackgroundService : BackgroundService {
    private readonly IEmailService _emailService;
    private readonly ILogger<EmailBackgroundService> _logger;

    public EmailBackgroundService(IEmailService emailService, ILogger<EmailBackgroundService> logger) {
        _emailService = emailService;
        _logger = logger;
    }

    protected override async Task ExecuteAsync(CancellationToken stoppingToken) {
        while (!stoppingToken.IsCancellationRequested) {
            // Send emails
            await _emailService.SendScheduledEmailsAsync();
            _logger.LogInformation("Scheduled emails sent at: {time}");
            await Task.Delay(TimeSpan.FromHours(1), stoppingToken);  // Run every hour
        }
    }
        }`}</code></pre>

        <p>In this example, a background service is used to send emails periodically, for instance, every hour. The service calls an <code>IEmailService</code> to handle the email-sending logic.</p>

       <br />

        <h2 style={{paddingBottom:"6px"}}>Best Practices for Background Services</h2>
        <ul>
          <li><strong>Cancellation Token Handling:</strong> Always check the <code>stoppingToken</code> to stop the task gracefully when the application is shutting down.</li><br />
          <li><strong>Logging:</strong> Use proper logging to track background service operations and potential issues.</li><br />
          <li><strong>Error Handling:</strong> Make sure that background services handle errors gracefully and continue operating without crashing.</li><br />
          <li><strong>Task Duration:</strong> Ensure that background tasks do not run indefinitely and implement timeouts or checks to prevent unnecessary delays.</li><br />
          <li><strong>Avoid Blocking Calls:</strong> Use asynchronous programming to avoid blocking the thread and impacting performance.</li>
        </ul>

        <br />

        <h2>Conclusion</h2>
        <p>Background services in ASP.NET Core provide a powerful mechanism for running long-running or periodic tasks in a distributed environment. By using the <strong>BackgroundService</strong> class or <strong>IHostedService</strong> interface, developers can efficiently implement tasks like sending emails, processing jobs, and more. Ensuring proper error handling, cancellation, and resource management is key to building reliable and scalable background services.</p>
      


  </div>
)}





{selectedChapter === 'chapter99' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Using Hosted Services in ASP.NET Core   </h1>

  
        <p>In ASP.NET Core, background tasks are handled through hosted services, which are long-running operations executed outside the HTTP request-response lifecycle. These services are implemented using the <strong>IHostedService</strong> interface and typically run continuously or on a schedule. Hosted services are commonly used for tasks like processing background jobs, sending notifications, polling data, or performing regular maintenance tasks.</p>

        <br />

        <h2>What Are Hosted Services?</h2>
        <p style={{paddingBottom:"6px"}}>Hosted services are background tasks that run in the background of an ASP.NET Core application. They are implemented by creating a class that either directly implements the <strong>IHostedService</strong> interface or inherits from the <strong>BackgroundService</strong> base class. Hosted services are started when the application starts and continue running until the application shuts down.</p>

        <h3 style={{paddingBottom:"6px"}}>Key Concepts</h3>
        <ul>
          <li><strong>IHostedService:</strong> This interface provides methods to start and stop the background task.</li><br />
          <li><strong>BackgroundService:</strong> This is an abstract base class that simplifies implementing a hosted service. It provides the <strong>ExecuteAsync</strong> method, which is overridden to run the background task.</li><br />
          <li><strong>Service Lifetime:</strong> Hosted services run for the duration of the application’s lifetime and can be used for background tasks that need to be performed continuously or periodically.</li>
        </ul>
<br />
        <h2>Implementing a Simple Hosted Service</h2>
        <p style={{paddingBottom:"6px"}}>To implement a simple hosted service in ASP.NET Core, we need to create a class that implements the <strong>IHostedService</strong> interface or inherits from the <strong>BackgroundService</strong> class.</p>

        <h4>Step 1: Create the Hosted Service</h4>
        <p>Start by creating a class that inherits from <code>BackgroundService</code> and override the <code>ExecuteAsync</code> method to define the task that will run in the background.</p>
        <pre><code>{`public class MyHostedService : BackgroundService {
    private readonly ILogger<MyHostedService> _logger;

    public MyHostedService(ILogger<MyHostedService> logger) {
        _logger = logger;
    }

    protected override async Task ExecuteAsync(CancellationToken stoppingToken) {
        while (!stoppingToken.IsCancellationRequested) {
            _logger.LogInformation("Hosted service is running at: {time}", DateTimeOffset.Now);
            await Task.Delay(10000, stoppingToken);  // Simulate background work
        }
    }
        }`}</code></pre>
        <p>In this example, the background service logs a message every 10 seconds. The loop continues to run until the application is stopped or the service is cancelled.</p><br />

        <h4>Step 2: Register the Hosted Service</h4>
        <p>Once the hosted service class is created, you need to register it in the <code>Program.cs</code> or <code>Startup.cs</code> file to ensure it starts when the application begins.</p>
        <pre><code>{`public class Program {
    public static void Main(string[] args) {
        CreateHostBuilder(args).Build().Run();
    }

    public static IHostBuilder CreateHostBuilder(string[] args) =>
        Host.CreateDefaultBuilder(args)
            .ConfigureServices((hostContext, services) => {
                services.AddHostedService<MyHostedService>();
            });
}`}</code></pre>
        <p>By registering the service with <code>{`services.AddHostedService<MyHostedService>()`}</code>, ASP.NET Core will automatically instantiate and run this service when the application starts.</p>

        <hr/>

        <h2>Implementing Periodic Hosted Services</h2>
        <p>Hosted services can also be used to run tasks periodically, such as checking for updates or processing queued messages. This can be done using a <code>Timer</code> or asynchronous delay within the <code>ExecuteAsync</code> method.</p>

        <h4>Example: Periodic Task Using a Timer</h4>
        <pre><code>{`public class PeriodicTaskHostedService : BackgroundService {
    private readonly ILogger<PeriodicTaskHostedService> _logger;
    private Timer _timer;

    public PeriodicTaskHostedService(ILogger<PeriodicTaskHostedService> logger) {
        _logger = logger;
    }

    protected override Task ExecuteAsync(CancellationToken stoppingToken) {
        _timer = new Timer(DoWork, null, TimeSpan.Zero, TimeSpan.FromMinutes(10));  // Runs every 10 minutes
        return Task.CompletedTask;
    }

    private void DoWork(object state) {
        _logger.LogInformation("Periodic task executed at: {time}", DateTimeOffset.Now);
    }

    public override Task StopAsync(CancellationToken cancellationToken) {
        _timer?.Dispose();
        return base.StopAsync(cancellationToken);
    }
        }`}</code></pre>
        <p>In this example, a <code>Timer</code> is used to execute the <code>DoWork</code> method every 10 minutes. The service will clean up the timer when it is stopped by overriding the <code>StopAsync</code> method.</p>

     <br />
        <h2>Graceful Shutdown of Hosted Services</h2>
        <p style={{paddingBottom:"6px"}}>To ensure a clean shutdown, hosted services should handle cancellation tokens to allow background tasks to finish before the application terminates. This is important to avoid abrupt terminations that might result in incomplete operations.</p>

        <h4>Example: Graceful Shutdown</h4>
        <pre><code>{`public class GracefulShutdownHostedService : BackgroundService {
    private readonly ILogger<GracefulShutdownHostedService> _logger;

    public GracefulShutdownHostedService(ILogger<GracefulShutdownHostedService> logger) {
        _logger = logger;
    }

    protected override async Task ExecuteAsync(CancellationToken stoppingToken) {
        while (!stoppingToken.IsCancellationRequested) {
            _logger.LogInformation("Service running at: {time}", DateTimeOffset.Now);
            await Task.Delay(10000, stoppingToken);  // Simulate background work
        }
    }

    public override async Task StopAsync(CancellationToken cancellationToken) {
        _logger.LogInformation("Service is gracefully stopping...");
        await base.StopAsync(cancellationToken);
        _logger.LogInformation("Service has been stopped.");
    }
        }`}</code></pre>
        <p>The overridden <code>StopAsync</code> method allows for performing any cleanup or resource releasing actions before the service fully stops.</p>

 <br />

        <h2 style={{paddingBottom:"6px"}}>Best Practices for Hosted Services</h2>
        <ul>
          <li><strong>Cancellation Tokens:</strong> Always respect cancellation tokens to allow for graceful termination of background tasks.</li><br />
          <li><strong>Error Handling:</strong> Make sure to handle errors properly in the background tasks to prevent service crashes.</li><br />
          <li><strong>Logging:</strong> Use logging to track the status of the background service, including success, failure, and shutdown events.</li><br />
          <li><strong>Resource Management:</strong> Ensure that resources (e.g., database connections, file handles) are properly managed and disposed of to prevent memory leaks.</li><br />
          <li><strong>Task Duration:</strong> If a background task takes a long time, consider breaking it into smaller chunks or using async methods to avoid blocking the thread.</li>
        </ul>

      <br />

        <h2>Conclusion</h2>
        <p>Hosted services are a powerful feature in ASP.NET Core that enables developers to run background tasks efficiently. By implementing the <strong>IHostedService</strong> interface or the <strong>BackgroundService</strong> class, you can create services that run in the background of your application, handle periodic tasks, and gracefully shut down when the application stops. Whether for tasks like sending emails, processing jobs, or performing scheduled maintenance, hosted services provide a robust solution for running long-running operations in a scalable, asynchronous manner.</p>


  </div>
)}



   
{selectedChapter === 'chapter100' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Scheduling Jobs with Hangfire or Quartz.NET</h1>

    <p>When building applications that require background task scheduling, two popular libraries in .NET are <strong>Hangfire</strong> and <strong>Quartz.NET</strong>. Both libraries provide powerful features for scheduling and managing jobs that need to be executed asynchronously, periodically, or on-demand. In this section, we will explore how to integrate and use these libraries in ASP.NET Core applications.</p>

    <br />

    <h2>Overview of Hangfire</h2>
    <p style={{ paddingBottom: '6px' }}>Hangfire is an easy-to-use framework for background job processing in .NET. It allows you to run background tasks, schedule jobs, and even retry failed jobs. Hangfire provides a robust dashboard for monitoring and managing jobs and is widely used for tasks like sending emails, processing queues, and generating reports.</p>

    <h3 style={{ paddingBottom: '6px' }}>Key Features of Hangfire</h3>
    <ul>
      <li><strong>Background Job Processing:</strong> Allows execution of background tasks without blocking HTTP requests.</li><br />
      <li><strong>Job Scheduling:</strong> Schedule jobs to run once or periodically.</li><br />
      <li><strong>Retry Logic:</strong> Automatically retries failed jobs with customizable strategies.</li><br />
      <li><strong>Job Monitoring:</strong> Comes with a built-in dashboard to monitor job status, history, and failures.</li>
    </ul><br />

    <h3>Setting Up Hangfire</h3>
    <p style={{ paddingBottom: '6px' }}>To start using Hangfire in an ASP.NET Core project, follow these steps:</p>

    <h4>Step 1: Install Hangfire NuGet Package</h4>
    <p>First, install the Hangfire NuGet package via the package manager or CLI:</p>
    <pre><code>dotnet add package Hangfire</code></pre><br />

    <h4>Step 2: Configure Hangfire in Startup</h4>
    <code>{`In the Startup.cs file, configure Hangfire by adding it to the service container and defining the storage provider (e.g., SQL Server or in-memory storage):`}</code>
    <pre><code>{`public class Startup {
  public void ConfigureServices(IServiceCollection services) {
      // Add Hangfire services
      services.AddHangfire(x => x.UseSqlServerStorage("your-connection-string"));
      services.AddHangfireServer();  // Add the Hangfire background processing server
  }

  public void Configure(IApplicationBuilder app, IWebHostEnvironment env) {
      // Use Hangfire Dashboard for monitoring jobs
      app.UseHangfireDashboard();
      // Other middleware configurations...
  }
}`}</code></pre><br />

    <h4>Step 3: Create and Schedule Jobs</h4>
    <p>Hangfire provides several methods for creating background jobs. You can schedule a job to run immediately, after a delay, or repeatedly on a schedule.</p>
    <pre><code>{`public class BackgroundJobs {
  public static void SendEmailJob() {
      Console.WriteLine("Sending Email...");
  }

  public static void ScheduleJob() {
      // Schedule a job to run every 10 minutes
      RecurringJob.AddOrUpdate(() => SendEmailJob(), Cron.Minutely);
  }
}`}</code></pre><br />

    <h4>Step 4: Running the Dashboard</h4>
    <p>To access the Hangfire Dashboard and monitor jobs, you can navigate to the <code>/hangfire</code> route of your application in the browser (e.g., <code>https://localhost:5001/hangfire</code>).</p>

    <br />

    <h2>Overview of Quartz.NET</h2>
    <p style={{ paddingBottom: '6px' }}>Quartz.NET is a more feature-rich, enterprise-grade job scheduling library for .NET. It supports complex schedules like cron expressions, multiple triggers, and job persistence. Quartz.NET is ideal for scenarios that require advanced scheduling capabilities or more intricate job management.</p>

    <h3 style={{ paddingBottom: '6px' }}>Key Features of Quartz.NET</h3>
    <ul>
      <li><strong>Advanced Scheduling:</strong> Support for cron-like scheduling, interval-based scheduling, and more.</li><br />
      <li><strong>Job Persistence:</strong> Jobs can be stored in a database for durability.</li><br />
      <li><strong>Job and Trigger Management:</strong> Allows manual control of jobs and triggers.</li><br />
      <li><strong>Distributed Job Scheduling:</strong> Supports scheduling jobs in distributed applications.</li>
    </ul><br />

    <h3>Setting Up Quartz.NET</h3>
    <p style={{ paddingBottom: '6px' }}>To get started with Quartz.NET in an ASP.NET Core project, follow these steps:</p>

    <h4>Step 1: Install Quartz.NET NuGet Package</h4>
    <p>Install the Quartz.NET package via NuGet or the CLI:</p>
    <pre><code>dotnet add package Quartz</code></pre><br />

    <h4>Step 2: Configure Quartz.NET</h4>
    <p>In the <code>Startup.cs</code> file, configure Quartz.NET by adding it to the service container:</p>
    <pre><code>{`public class Startup {
  public void ConfigureServices(IServiceCollection services) {
      // Add Quartz.NET services
      services.AddQuartz(q => {
          q.UseMicrosoftDependencyInjectionJobFactory();  // Using DI for job factory
      });
      services.AddQuartzHostedService(q => q.WaitForJobsToComplete = true);
  }
}`}</code></pre><br />

    <h4>Step 3: Create and Schedule Jobs</h4>
    <p>Define a job class that implements <code>IJob</code> and specify the task to be executed:</p>
    <pre><code>{`public class EmailJob : IJob {
  public Task Execute(IJobExecutionContext context) {
      Console.WriteLine("Sending Email...");
      return Task.CompletedTask;
  }
}`}</code></pre><br />

    <p>Next, schedule the job using Quartz's scheduling API:</p>
    <pre><code>{`public class JobScheduler {
  public static async Task ScheduleEmailJob(IServiceProvider serviceProvider) {
      var scheduler = await serviceProvider.GetRequiredService<ISchedulerFactory>().GetScheduler();
      var job = JobBuilder.Create<EmailJob>()
          .WithIdentity("emailJob", "group1")
          .Build();

      var trigger = TriggerBuilder.Create()
          .WithIdentity("emailTrigger", "group1")
          .StartNow()
          .WithCronSchedule("0 0/10 * * * ?")  // Schedule every 10 minutes
          .Build();

      await scheduler.ScheduleJob(job, trigger);
  }
}`}</code></pre><br />

    <h4>Step 4: Running Quartz.NET</h4>
    <p>Once you have configured Quartz.NET, jobs will be scheduled and triggered based on the specified triggers. You can control job execution and monitoring directly via Quartz.NET's API.</p>

  <br />

    <h2>Comparison: Hangfire vs Quartz.NET</h2>
    <p>While both Hangfire and Quartz.NET are excellent tools for background job processing, they have different strengths:</p>
    <ul>
      <li><strong>Hangfire:</strong> Easier to set up, provides a built-in dashboard for monitoring, and is ideal for simple and recurring jobs.</li><br />
      <li><strong>Quartz.NET:</strong> More complex and feature-rich, supporting advanced scheduling and persistent jobs, and better suited for enterprise-grade applications.</li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Job Scheduling</h2>
    <ul>
      <li><strong>Job Durability:</strong> Ensure jobs are durable and resilient, especially if the application is distributed or requires persistent state.</li><br />
      <li><strong>Failure Handling:</strong> Implement retries, logging, and error notifications to handle failed jobs.</li><br />
      <li><strong>Graceful Shutdown:</strong> Ensure jobs are stopped gracefully when the application shuts down, especially for long-running tasks.</li><br />
      <li><strong>Monitor Jobs:</strong> Utilize built-in dashboards or custom logging to track job execution and performance.</li>
    </ul>

   <br />

    <h2>Conclusion</h2>
    <p>Both Hangfire and Quartz.NET are powerful solutions for background task scheduling in ASP.NET Core applications. Hangfire is ideal for simpler use cases with built-in job management and monitoring, while Quartz.NET offers advanced features for complex scheduling needs. By integrating one of these libraries into your application, you can manage background jobs efficiently, ensure reliability, and improve the overall performance of your application.</p>
  </div>
)}


{selectedChapter === 'chapter101' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to WebSockets</h1>
      <p>
        <strong>WebSockets</strong> is a modern communication protocol that
        provides <strong>full-duplex communication channels</strong> over a single, 
        long-lived connection between a client and a server. In <strong>ASP.NET</strong>, WebSockets 
        enable real-time, two-way interaction, making it ideal for applications 
        like chat systems, live notifications, or collaborative tools.
      </p><br />
      
     
      
      <h2>1. What are WebSockets?</h2>
      <ul>
        <li><strong>Protocol Overview:</strong></li>
        <ul>
          <li>Introduced with HTML5 and standardized in RFC 6455.</li><br />
          <li>Allows persistent, full-duplex communication over a single TCP connection.</li><br />
        </ul>
        <li><strong>How It Works:</strong></li>
        <ul>
          <li>Starts as an HTTP/1.1 or HTTP/2 handshake, then upgrades to the WebSocket protocol.</li><br />
          <li>After upgrading, the communication is no longer constrained by HTTP request/response cycles.</li>
        </ul>
      </ul><br />
      
     
      
      <h2 style={{paddingBottom:"6px"}}>2. Benefits of WebSockets</h2>
      <ul>
        <li><strong>Low Latency:</strong> Eliminates the overhead of creating multiple HTTP connections for real-time data.</li><br />
        <li><strong>Full-Duplex Communication:</strong> Enables simultaneous bi-directional data transfer.</li><br />
        <li><strong>Resource Efficiency:</strong> Uses fewer system resources compared to long-polling or HTTP-based methods.</li><br />
        <li><strong>Scalability:</strong> Suitable for handling a large number of real-time connections in applications like gaming or stock trading.</li>
      </ul><br />
      
    
      
      <h2>3. WebSockets in ASP.NET Core</h2>
      <p>
        ASP.NET Core provides built-in support for WebSockets, making it easier to implement real-time communication.
      </p>
      <h3 style={{paddingBottom:"6px"}}>Key Components:</h3>
      <ol>
        <li><strong>WebSocket Middleware:</strong> Manages the WebSocket handshake and communication.</li><br />
        <li><strong>Dependency Injection:</strong> Services can be injected to manage WebSocket state and data handling.</li><br />
        <li><strong>Asynchronous APIs:</strong> Leverages async programming for non-blocking communication.</li>
      </ol><br />
      
     
      <h2 style={{paddingBottom:"6px"}}>4. Implementing WebSockets in ASP.NET Core</h2>
      <h3>a) Enable WebSockets Middleware</h3>
      <pre>
        <code>
{`public class Startup
{
    public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
    {
        // Enable WebSocket support
        var webSocketOptions = new WebSocketOptions
        {
            KeepAliveInterval = TimeSpan.FromSeconds(120)
        };

        app.UseWebSockets(webSocketOptions);

        // Middleware to handle WebSocket requests
        app.Use(async (context, next) =>
        {
            if (context.WebSockets.IsWebSocketRequest)
            {
                var webSocket = await context.WebSockets.AcceptWebSocketAsync();
                await HandleWebSocketConnection(webSocket);
            }
            else
            {
                await next();
            }
        });
    }

    private async Task HandleWebSocketConnection(WebSocket webSocket)
    {
        var buffer = new byte[1024 * 4];
        WebSocketReceiveResult result;
        do
        {
            result = await webSocket.ReceiveAsync(new ArraySegment<byte>(buffer), CancellationToken.None);
            var message = Encoding.UTF8.GetString(buffer, 0, result.Count);
            Console.WriteLine($"Received: {message}");

            var response = Encoding.UTF8.GetBytes($"Echo: {message}");
            await webSocket.SendAsync(new ArraySegment<byte>(response), WebSocketMessageType.Text, true, CancellationToken.None);
        }
        while (!result.CloseStatus.HasValue);

        await webSocket.CloseAsync(result.CloseStatus.Value, result.CloseStatusDescription, CancellationToken.None);
    }
}`}
        </code>
      </pre><br />
      
      <h3>b) Configure WebSocket Client</h3>
      <pre>
        <code>
{`const socket = new WebSocket("ws://localhost:5000");

socket.onopen = () => {
  console.log("WebSocket connection established.");
  socket.send("Hello Server!");
};

socket.onmessage = (event) => {
  console.log("Message from server:", event.data);
};

socket.onclose = () => {
  console.log("WebSocket connection closed.");
};`}
        </code>
      </pre><br />
      
   
      
      <h2 style={{paddingBottom:"6px"}}>5. Use Cases for WebSockets</h2>
      <ul>
        <li><strong>Chat Applications:</strong> Real-time message exchanges.</li><br />
        <li><strong>Live Dashboards:</strong> Streaming data for analytics, stock prices, or IoT metrics.</li><br />
        <li><strong>Collaborative Tools:</strong> Real-time document editing, multiplayer games.</li><br />
        <li><strong>Live Notifications:</strong> Alerts, reminders, and updates for user interactions.</li>
      </ul><br />
      
   
      
      <h2 style={{paddingBottom:"6px"}}>6. Best Practices</h2>
      <ul>
        <li><strong>Monitor and Manage Connections:</strong> Set limits on active connections to prevent resource exhaustion.</li><br />
        <li><strong>Handle Connection Errors:</strong> Implement retry logic and handle disconnects gracefully.</li><br />
        <li><strong>Secure the Connection:</strong> Use `wss://` with TLS for encryption.</li><br />
        <li><strong>Optimize Data Transfer:</strong> Minimize message size and use binary data when appropriate.</li>
      </ul><br />
      
  
      
      <h2>Conclusion</h2>
      <p>
        WebSockets in ASP.NET Core provide a robust and scalable framework for building real-time 
        applications with efficient, full-duplex communication. By leveraging WebSocket middleware 
        and asynchronous APIs, developers can create interactive experiences that are both 
        high-performance and user-friendly.
      </p>
 
  </div>
)}


{selectedChapter === 'chapter102' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Implementing Real-Time WebSocket Communication</h1>
    <div className={style.content}>
      <p>
        WebSockets revolutionize the way applications communicate by enabling <strong>real-time, full-duplex communication</strong> over a single, long-lived connection between the client and the server. ASP.NET Core makes it seamless to implement WebSocket communication through its powerful middleware support. This guide provides a detailed walkthrough of implementing real-time WebSocket communication in an ASP.NET Core application.
      </p><br />

      <h2 style={{paddingBottom:"6px"}}>1. Overview of WebSockets</h2>
      <h3 style={{paddingBottom:"6px"}}>What Are WebSockets?</h3>
      <ul>
        <li>A protocol that establishes a persistent, bi-directional connection over a single TCP socket.</li><br />
        <li>Starts as a standard HTTP/1.1 or HTTP/2 handshake and upgrades to the WebSocket protocol.</li><br />
        <li>Ideal for use cases like live notifications, chat systems, gaming, and collaborative tools.</li>
      </ul><br />

      <h2 style={{paddingBottom:"6px"}}>2. Benefits of WebSockets in ASP.NET Core</h2>
      <ul>
        <li><strong>Full-Duplex Communication:</strong> Both the client and the server can send data at any time without waiting for responses.</li><br />
        <li><strong>Low Latency:</strong> Eliminates repeated HTTP request/response cycles.</li><br />
        <li><strong>Efficient Resource Usage:</strong> Requires fewer system resources compared to alternatives like polling or long-polling.</li><br />
        <li><strong>Scalability:</strong> Capable of handling thousands of real-time connections.</li>
      </ul><br />

      <h2 style={{paddingBottom:"6px"}}>3. Key Components in ASP.NET Core WebSocket Implementation</h2>
      <ol>
        <li><strong>WebSocket Middleware:</strong> Facilitates WebSocket requests and responses.</li><br />
        <li><strong>Async Programming Model:</strong> Supports non-blocking communication for improved performance.</li><br />
        <li><strong>Dependency Injection:</strong> Manages state and services for WebSocket interactions.</li>
      </ol><br />

      <h2 style={{paddingBottom:"6px"}}>4. Step-by-Step Implementation</h2>
      <h3>a) Enable WebSocket Middleware</h3>
      <pre className={style.codeblock}>
        <code>
          {`
var builder = WebApplication.CreateBuilder(args);
var app = builder.Build();

// Enable WebSocket middleware with configuration options
var webSocketOptions = new WebSocketOptions
{
    KeepAliveInterval = TimeSpan.FromSeconds(120)
};
app.UseWebSockets(webSocketOptions);

// Middleware to handle WebSocket requests
app.Use(async (context, next) =>
{
    if (context.WebSockets.IsWebSocketRequest)
    {
        var webSocket = await context.WebSockets.AcceptWebSocketAsync();
        await HandleWebSocketCommunication(webSocket);
    }
    else
    {
        await next();
    }
});

app.Run();

// WebSocket communication handler
async Task HandleWebSocketCommunication(WebSocket webSocket)
{
    var buffer = new byte[1024 * 4];
    WebSocketReceiveResult result;
    do
    {
        result = await webSocket.ReceiveAsync(new ArraySegment<byte>(buffer), CancellationToken.None);
        var message = Encoding.UTF8.GetString(buffer, 0, result.Count);
        Console.WriteLine($"Received: {message}");

        var response = Encoding.UTF8.GetBytes($"Server: Echo - {message}");
        await webSocket.SendAsync(new ArraySegment<byte>(response), WebSocketMessageType.Text, true, CancellationToken.None);
    } while (!result.CloseStatus.HasValue);

    await webSocket.CloseAsync(result.CloseStatus.Value, result.CloseStatusDescription, CancellationToken.None);
}
          `}
        </code>
      </pre><br />

      <h3>b) Creating a WebSocket Client</h3>
      <pre className={style.codeblock}>
        <code>
          {`
const socket = new WebSocket("ws://localhost:5000");

// Event: Connection established
socket.onopen = () => {
  console.log("Connection established!");
  socket.send("Hello, Server!");
};

// Event: Message received from server
socket.onmessage = (event) => {
  console.log("Message from server:", event.data);
};

// Event: Connection closed
socket.onclose = () => {
  console.log("Connection closed!");
};
          `}
        </code>
      </pre><br />

      <h2 style={{paddingBottom:"6px"}}>5. Use Cases for WebSockets</h2>
      <ul>
        <li><strong>Chat Systems:</strong> Enable instant messaging between users.</li><br />
        <li><strong>Live Dashboards:</strong> Real-time analytics or financial updates.</li><br />
        <li><strong>Collaborative Applications:</strong> Tools for real-time editing or gaming.</li><br />
        <li><strong>Notification Systems:</strong> Push updates to users without polling.</li>
      </ul><br />

      <h2 style={{paddingBottom:"6px"}}>6. Best Practices</h2>
      <ol>
        <li><strong>Security:</strong> Use <code>wss://</code> for encrypted communication. Authenticate WebSocket requests before establishing connections.</li><br />
        <li><strong>Error Handling:</strong> Implement retry logic for handling connection failures.</li><br />
        <li><strong>Resource Management:</strong> Limit the number of simultaneous WebSocket connections.</li><br />
        <li><strong>Data Optimization:</strong> Compress data or use binary messages when applicable.</li>
      </ol><br />

      <h2>Conclusion</h2>
      <p>
        WebSockets in ASP.NET Core empower developers to build high-performance, scalable, and interactive real-time applications. With its built-in middleware and asynchronous APIs, developers can easily implement robust WebSocket-based communication to meet modern application demands.
      </p>
    </div>
  </div>
)}







{selectedChapter === 'chapter103' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Working with WebSockets in ASP.NET Core</h1>

    <p>
      WebSockets is a protocol that provides full-duplex communication channels over a single, long-lived TCP connection.
      This is particularly useful in real-time applications where the server needs to push data to clients without having to wait for a request.
      In ASP.NET Core, WebSockets are supported and can be easily implemented to create real-time web applications.
    </p><br />

    <h3>1. Enabling WebSockets in ASP.NET Core</h3>
    <p>
      To begin using WebSockets in an ASP.NET Core application, you'll first need to enable WebSocket support in your application's middleware pipeline.
    </p>

    <ul>
      <li><strong>Install ASP.NET Core</strong> - Ensure that you're using an appropriate version of ASP.NET Core that supports WebSockets.</li><br />
      <li><strong>Configure WebSockets in `Startup.cs`</strong> - In your `Startup.cs` file, add the WebSocket middleware to the `Configure` method:</li>
    </ul>

    <pre><code>
{`public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    if (env.IsDevelopment())
    {
        app.UseDeveloperExceptionPage();
    }

    // Enable WebSockets
    var webSocketOptions = new WebSocketOptions()
    {
        KeepAliveInterval = TimeSpan.FromSeconds(120),  // Adjust the keep-alive interval as needed
        ReceiveBufferSize = 4 * 1024,  // Size of buffer used for receiving data
    };

    app.UseWebSockets(webSocketOptions);

    // Handling WebSocket requests
    app.Use(async (context, next) =>
    {
        if (context.WebSockets.IsWebSocketRequest)
        {
            WebSocket webSocket = await context.WebSockets.AcceptWebSocketAsync();
            await HandleWebSocketAsync(webSocket);
        }
        else
        {
            await next();
        }
    });
}`}
    </code></pre><br />

    <h3>2. Handling WebSocket Connections</h3>
    <p>
      Once WebSockets are enabled, you'll need to implement a method to handle incoming WebSocket connections. This method will manage the communication between the client and the server.
    </p>

    <pre><code>
{`private async Task HandleWebSocketAsync(WebSocket webSocket)
{
    byte[] buffer = new byte[1024 * 4];

    WebSocketReceiveResult result;
    do
    {
        result = await webSocket.ReceiveAsync(new ArraySegment<byte>(buffer), CancellationToken.None);

        if (result.MessageType == WebSocketMessageType.Text)
        {
            string message = Encoding.UTF8.GetString(buffer, 0, result.Count);
            Console.WriteLine($"Received message: {message}");

            // Echo the message back to the client
            await webSocket.SendAsync(new ArraySegment<byte>(Encoding.UTF8.GetBytes("Echo: " + message)),
                                      WebSocketMessageType.Text, true, CancellationToken.None);
        }
    } while (!result.CloseStatus.HasValue);

    await webSocket.CloseAsync(result.CloseStatus.Value, result.CloseStatusDescription, CancellationToken.None);
}`}
    </code></pre><br />

    <h3>3. Sending Messages to WebSocket Clients</h3>
    <p>
      In addition to receiving messages from clients, the server can also push messages to the clients. This can be done by sending messages asynchronously to the WebSocket:
    </p>

    <pre><code>
{`public async Task SendMessageToClients(WebSocket webSocket, string message)
{
    if (webSocket.State == WebSocketState.Open)
    {
        var buffer = Encoding.UTF8.GetBytes(message);
        await webSocket.SendAsync(new ArraySegment<byte>(buffer), WebSocketMessageType.Text, true, CancellationToken.None);
    }
}`}
    </code></pre><br />

    <h3>4. Handling WebSocket Connections from Multiple Clients</h3>
    <p>
      WebSockets allow for communication with multiple clients simultaneously. To handle this, you might need to keep track of all active WebSocket connections.
    </p>

    <pre><code>
{`private static List<WebSocket> _clients = new List<WebSocket>();

public async Task HandleWebSocketAsync(WebSocket webSocket)
{
    _clients.Add(webSocket);

    byte[] buffer = new byte[1024 * 4];
    WebSocketReceiveResult result;
    do
    {
        result = await webSocket.ReceiveAsync(new ArraySegment<byte>(buffer), CancellationToken.None);

        if (result.MessageType == WebSocketMessageType.Text)
        {
            string message = Encoding.UTF8.GetString(buffer, 0, result.Count);
            Console.WriteLine($"Received message: {message}");

            // Broadcast to all clients
            foreach (var client in _clients)
            {
                if (client.State == WebSocketState.Open)
                {
                    await SendMessageToClients(client, message);
                }
            }
        }
    } while (!result.CloseStatus.HasValue);

    _clients.Remove(webSocket);
    await webSocket.CloseAsync(result.CloseStatus.Value, result.CloseStatusDescription, CancellationToken.None);
}`}
    </code></pre><br />

    <h3>5. Closing WebSocket Connections</h3>
    <p>
      Closing the WebSocket connection can be done by calling the `CloseAsync` method on the WebSocket:
    </p>

    <pre><code>
{`await webSocket.CloseAsync(WebSocketCloseStatus.NormalClosure, "Closing", CancellationToken.None);`}
    </code></pre><br />

    <h3>6. WebSocket Client Example</h3>
    <p>
      Here’s an example of how you can create a simple WebSocket client in JavaScript to interact with the WebSocket server:
    </p>

    <pre><code>
{`let socket = new WebSocket('ws://localhost:5000'); // Replace with your server's URL

socket.onopen = function(event) {
    console.log('WebSocket is connected');
    socket.send('Hello, Server!');
};

socket.onmessage = function(event) {
    console.log('Message from server: ' + event.data);
};

socket.onclose = function(event) {
    console.log('WebSocket is closed');
};

socket.onerror = function(error) {
    console.error('WebSocket Error: ', error);
};`}
    </code></pre><br />

    <h3 style={{paddingBottom:"6px"}}>7. Benefits of WebSockets in ASP.NET Core</h3>
    <ul>
      <li><strong>Real-time Communication</strong>: WebSockets enable bidirectional communication, allowing the server to push data to clients as soon as it's available.</li><br />
      <li><strong>Efficiency</strong>: WebSockets are more efficient than polling or long-polling because they maintain a persistent connection, reducing the overhead of HTTP requests.</li><br />
      <li><strong>Low Latency</strong>: WebSockets allow for faster communication, reducing the latency that is typically associated with traditional HTTP requests.</li>
    </ul><br />

    <h3>8. Security Considerations</h3>
    <p>
      When using WebSockets, ensure that communication is encrypted using `wss://` (WebSocket Secure) instead of `ws://`, especially for production environments. This ensures the data transmitted over the WebSocket connection is secure.
    </p><br />

    <h3>Conclusion</h3>
    <p>
      WebSockets in ASP.NET Core provide a powerful mechanism for real-time communication, perfect for scenarios like chat applications, live notifications, gaming, and collaborative tools. By following the steps outlined above, you can quickly get up and running with WebSockets in your ASP.NET Core application, enabling seamless, efficient communication between the server and multiple clients.
    </p>
  </div>
)}


{selectedChapter === 'chapter104' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to GraphQL in ASP.NET Core</h1>

    <p>
      GraphQL is a query language for APIs and a runtime for executing those queries with your existing data. Unlike REST, which has fixed endpoints for each resource, GraphQL allows clients to request exactly the data they need, potentially reducing over-fetching and under-fetching of data. This makes GraphQL highly efficient and flexible for modern web applications.
    </p>
    <p>
      In ASP.NET Core, GraphQL can be integrated to provide a powerful and scalable API layer. It simplifies data querying by allowing you to define a schema and resolvers that return the requested data based on the query.
    </p><br />

    <h3>1. Setting Up GraphQL in ASP.NET Core</h3>
    <p style={{paddingBottom:"6px"}}>To begin working with GraphQL in ASP.NET Core, you need to add the necessary NuGet packages and configure the services and middleware for GraphQL.</p>

    <h4>1.1 Install the Necessary NuGet Packages</h4>
    <p>
      Start by adding the GraphQL packages via NuGet. The most commonly used package for GraphQL in ASP.NET Core is <code>GraphQL.Net</code>. You can install it using the following commands:
    </p>
    <pre>
      <code>dotnet add package GraphQL</code>
      <code>dotnet add package GraphQL.Server.Transports.AspNetCore</code>
    </pre><br />

    <h4>1.2 Configure Services in `Startup.cs`</h4>
    <p>
      In the <code>ConfigureServices</code> method, register the GraphQL services and schema:
    </p>
    <pre>
    <code>{`
public void ConfigureServices(IServiceCollection services)
{ 
  services.AddControllers();

  // Register GraphQL services
  services.AddGraphQL(options => 
  {
    options.EnableMetrics = false; // Disable metrics collection (optional)
  })
  .AddSystemTextJson();  // Add JSON support for GraphQL

  // Register the schema and resolver
  services.AddSingleton<IYourGraphQLSchema, YourGraphQLSchema>();
}
`}</code>
    </pre><br />

    <h4>1.3 Configure GraphQL Middleware in `Startup.cs`</h4>
    <p>
      Next, in the <code>Configure</code> method, add the GraphQL middleware:
    </p>
    <pre>
      <code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
  if (env.IsDevelopment())
  {
    app.UseDeveloperExceptionPage();
  }

  // Use GraphQL middleware
  app.UseGraphQL<IYourGraphQLSchema>();

  // Use GraphQL Playground for development (optional)
  app.UseGraphQLPlayground(new GraphQLPlaygroundOptions
  {
    Path = "/graphql"
  });
}
`}</code>
    </pre><br />

    <h3>2. Defining a GraphQL Schema</h3>
    <p>
      A GraphQL schema defines the structure of your data, including types and the relationships between them. Here's an example schema for a simple <code>Book</code> and <code>Author</code> model:
    </p>
    <pre>
      <code>{`
public class Query
{
  public Book GetBook(int id) => bookService.GetBookById(id);
}

public class Book
{
  public int Id { get; set; }
  public string Title { get; set; }
  public int AuthorId { get; set; }
}

public class Author
{
  public int Id { get; set; }
  public string Name { get; set; }
}

public class YourGraphQLSchema : Schema
{
  public YourGraphQLSchema(IDependencyResolver resolver) : base(resolver)
  {
    Query = resolver.Resolve<Query>();
  }
}
       `} </code>
    </pre><br />

    <h3>3. Creating GraphQL Resolvers</h3>
    <p>
      Resolvers are responsible for fetching the data for a specific field in the schema. Here’s an example of a resolver for the <code>GetBook</code> query:
    </p>
    <pre>
      <code>{`
public class BookResolver
{
  private readonly IBookService _bookService;

  public BookResolver(IBookService bookService)
  {
    _bookService = bookService;
  }

  public Book GetBook(int id)
  {
    return _bookService.GetBookById(id);
  }
}
      `}</code>
    </pre><br />

    <h3>4. Making a GraphQL Query</h3>
    <p>
      Once your schema and resolvers are set up, you can make a query to retrieve data. Here’s an example of a GraphQL query for retrieving a book’s title and author’s name:
    </p>
    <pre>
      <code>{`
{
  book(id: 1) {
    title
    author {
      name
    }
  }
}
        `}  </code>
    </pre><br />

    <h3>5. Handling Mutations</h3>
    <p>
      GraphQL also supports mutations for creating, updating, or deleting data. Here’s an example of a mutation to add a new book:
    </p>
    <pre>
      <code>{`
mutation {
  addBook(title: "New Book", authorId: 1) {
    id
    title
  }
}
       `} </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>6. Benefits of GraphQL</h3>
    <ul>
      <li><strong>Flexibility:</strong> Clients can request exactly the data they need, which reduces the payload size.</li><br />
      <li><strong>Single Endpoint:</strong> Unlike REST, GraphQL uses a single endpoint to access multiple resources.</li><br />
      <li><strong>Strongly Typed Schema:</strong> GraphQL schemas define types and ensure consistency in the API responses.</li><br />
      <li><strong>Real-time Support:</strong> Through GraphQL subscriptions, you can easily implement real-time data updates for clients.</li>
    </ul><br />

    <h3>7. Security Considerations</h3>
    <p>
      When working with GraphQL, ensure that you are mindful of the following security practices:
    </p>
    <ul>
      <li><strong>Query Complexity:</strong> Prevent overly complex queries that could strain your backend by limiting query depth or duration.</li><br />
      <li><strong>Authorization:</strong> Ensure that proper authorization checks are in place to prevent unauthorized access to data.</li><br />
      <li><strong>Rate Limiting:</strong> Use rate-limiting mechanisms to prevent abuse and ensure your GraphQL API isn’t overloaded.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      GraphQL in ASP.NET Core offers a flexible and efficient way to interact with data. By allowing clients to request only the data they need, GraphQL reduces over-fetching and improves performance. With its strong typing system and powerful features, such as mutations and subscriptions, GraphQL is a great choice for building modern, real-time web applications in ASP.NET Core.
    </p>
  </div>
)}



{selectedChapter === 'chapter105' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building GraphQL APIs</h1>
  
   
      
        <p>
          GraphQL is a powerful query language and runtime for APIs, enabling clients to request specific data and reducing over-fetching or under-fetching issues commonly associated with REST APIs. This session will focus on building robust GraphQL APIs using ASP.NET.
        </p><br />
     
        <h3 style={{paddingBottom:"6px"}}>Key Topics to Cover</h3>
        <ol>
          <li>
            <strong>Introduction to GraphQL</strong>
            <ul>
              <li>What is GraphQL?</li><br />
              <li>Benefits of GraphQL over REST.</li><br />
              <li>Use cases for GraphQL in modern applications.</li>
            </ul>
          </li><br />
          <li>
            <strong>Setting Up the Environment</strong>
            <ul>
              <li>Prerequisites: .NET 6 or later, IDE (e.g., Visual Studio or Visual Studio Code).</li>
              <li>Installing GraphQL.NET library:
                <pre>
                  <code>
dotnet add package GraphQL
dotnet add package GraphQL.Server.Transports.AspNetCore
                  </code>
                </pre>
              </li>
            </ul>
          </li><br />
          <li>
            <strong>Defining a GraphQL Schema</strong>
            <ul>
              <li>Concepts: Schema, Query, Mutation, Subscription.</li><br />
              <li>Creating a schema and root queries.</li><br />
              <li>Example: Defining types and fields for a sample "Book" API.</li>
            </ul><br />
          </li>
          <li><strong>Building a Query Resolver</strong></li><br />
          <li><strong>Creating Mutations</strong></li><br />
          <li><strong>Integrating with ASP.NET Core</strong></li><br />
          <li><strong>Testing the API</strong></li><br />
          <li><strong>Optimizing and Securing GraphQL APIs</strong></li><br />
          <li><strong>Advanced Features</strong></li><br />
          <li><strong>Deploying GraphQL APIs</strong></li>
        </ol><br />
      
        <h3>Practical Example: "Bookstore API"</h3>
        <p>
          <strong>Use Case:</strong> Create a GraphQL API for a bookstore application. The API should allow:
        </p>
        <ul>
          <li>Fetching books by ID or category.</li><br />
          <li>Adding new books via mutations.</li><br />
          <li>Subscribing to real-time updates for book availability.</li>
        </ul><br />
        <h4>Example Schema</h4>
        <pre>
  
<code class="language-graphql">{`
type Book {
  id: ID!
  title: String!
  author: String!
  price: Float!
  isAvailable: Boolean!
}

type Query {
  books: [Book!]!
  bookById(id: ID!): Book
}

type Mutation {
  addBook(title: String!, author: String!, price: Float!): Book
}

type Subscription {
  bookAvailabilityUpdated: Book
}
`}</code>
</pre><br />

<h4>Example Resolver (C#)</h4>
<pre>
<code class="language-csharp">{`
public class BookQuery
{
    public IEnumerable<Book> GetBooks() => BookStore.GetBooks();

    public Book GetBookById(int id) => BookStore.GetBooks().FirstOrDefault(b => b.Id == id);
}

public class BookMutation
{
    public Book AddBook(string title, string author, float price)
    {
        return BookStore.AddBook(new Book { Title = title, Author = author, Price = price });
    }
}
`}</code>
</pre><br />

        

        <h3>Tools and Libraries</h3>
        <ul>
          <li><a href="https://graphql-dotnet.github.io/">GraphQL.NET</a></li><br />
          <li>GraphQL Playground or Apollo Studio</li><br />
          <li>Entity Framework Core for database integration</li>
        </ul><br />
     

        <h3>Outcome</h3>
        <p>By the end of this session, participants will have a solid understanding of:</p>
        <ul>
          <li>Setting up and building GraphQL APIs in ASP.NET Core.</li><br />
          <li>Querying and mutating data using GraphQL.</li><br />
          <li>Implementing advanced features like subscriptions, filtering, and pagination.</li><br />
          <li>Deploying GraphQL APIs in production environments.</li>
        </ul>
  
  </div>
)}





{selectedChapter === 'chapter106' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Querying GraphQL APIs with .NET Clients</h1>

    <p>
      GraphQL allows you to request exactly the data you need from a server, providing a more flexible alternative to traditional REST APIs. In this chapter, we will explore how to query GraphQL APIs using .NET clients. 
      By using GraphQL clients, you can send queries and mutations to a GraphQL server and retrieve the data in a structured format.
    </p><br />

    <h3>1. Choosing a .NET GraphQL Client</h3>
    <p>
      To interact with GraphQL APIs from a .NET application, we need a client that can send queries to a GraphQL endpoint and process the response. 
      The most commonly used .NET GraphQL client is <code>GraphQL.Client</code>, which is easy to use and integrates well with ASP.NET Core.
    </p>
    <p>
      To get started, install the <code>GraphQL.Client</code> NuGet package in your project:
    </p>
    <pre>
      <code>
        dotnet add package GraphQL.Client
      </code>
    </pre><br />

    <h3>2. Setting Up the .NET GraphQL Client</h3>
    <p>
      After installing the package, you can create and configure the GraphQL client in your ASP.NET Core application. Here's an example of setting up the client:
    </p>
    <pre>
      <code>{`
        using GraphQL.Client;
        using GraphQL.Common.Request;
        
        public class GraphQLService
        &#123;
            private readonly GraphQLHttpClient _graphQLClient;
            
            public GraphQLService()
            &#123;
                _graphQLClient = new GraphQLHttpClient("https://your-graphql-api-endpoint", new NewtonsoftJsonSerializer());
            &#125;
            
            // Your methods to interact with the GraphQL API
        &#125;
       `} </code>
    </pre><br />

    <h3>3. Sending a GraphQL Query</h3>
    <p>
      To query a GraphQL API, you need to define the query string and send it via the client. Here’s an example of querying a list of books and their titles:
    </p>
    <pre>
      <code>{`
        public async Task GetBooksAsync()
        &#123;
            var query = new GraphQLRequest
            &#123;
                Query = @&quot;
                    query {
                        books {
                            title
                            author {
                                name
                            }
                        }
                    &quot;
            &#125;
            
            var response = await _graphQLClient.SendQueryAsync&lt;GraphQLResponse&lt;Book&gt;&gt;(query);
            
            foreach (var book in response.Data.Books)
            &#123;
                Console.WriteLine($"Book: {book.Title}, Author: {book.Author.Name}");
            &#125;
        &#125;
       `} </code>
    </pre><br />

    <h3>4. Handling GraphQL Responses</h3>
    <p>
      The response from a GraphQL query will typically contain data in a structured format. You can define a response model in your .NET client application that matches the shape of the GraphQL response:
    </p>
    <pre>
      <code>{`
        public class Book
        &#123;
            public string Title { get; set; }
            public Author Author { get; set; }
        &#125;

        public class Author
        &#123;
            public string Name { get; set; }
        &#125;

        public class GraphQLResponse&lt;T&gt;
        &#123;
            public T Data { get; set; }
        &#125;
       `} </code>
    </pre><br />

    <h3>5. Error Handling in GraphQL Queries</h3>
    <p>
      It’s important to handle errors gracefully when working with GraphQL queries. The response from the GraphQL server may contain errors, and it’s crucial to check and process those errors properly:
    </p>
    <pre>
  <code>{`
    public async Task GetBooksAsync()
    {
        var query = new GraphQLRequest
        {
            Query = @"
                query {
                    books {
                        title
                    }
                "
        };

        var response = await _graphQLClient.SendQueryAsync<GraphQLResponse<Book>>(query);

        if (response.Errors != null)
        {
            foreach (var error in response.Errors)
            {
                Console.WriteLine($"Error: {error.Message}");
            }
        }
        else
        {
            foreach (var book in response.Data.Books)
            {
                Console.WriteLine($"Book: {book.Title}");
            }
        }
    }
   `} </code>
</pre><br />


    <h3 className={style.subheading}>6. Sending GraphQL Mutations</h3>
    <p>
      Mutations are used in GraphQL to create, update, or delete data. Here’s an example of sending a mutation to add a new book:
    </p>
  
    <pre><code>{`
public async Task AddBookAsync(string title, int authorId)
{
    var mutation = new GraphQLRequest
    {
        Query = @"
            mutation ($title: String!, $authorId: Int!) {
                addBook(title: $title, authorId: $authorId) {
                    id
                    title
                }
            ",
        Variables = new
        {
            title,
            authorId
        }
    };

    var response = await _graphQLClient.SendMutationAsync<GraphQLResponse<Book>>(mutation);

    Console.WriteLine($"Added Book: {response.Data.Book.Title}");
}
    `}</code></pre><br />


    <h3>7. Real-time Updates with GraphQL Subscriptions</h3>
    <p>
      GraphQL also supports subscriptions for real-time updates. To receive updates from the server, you can use a subscription query. Here’s an example of how to handle GraphQL subscriptions:
    </p>
    <pre>
      <code>{`
        public async Task SubscribeToBookUpdates()
        &#123;
            var subscriptionQuery = @&quot;
                subscription {
                    bookAdded {
                        title
                        author {
                            name
                        }
                    }
                }
            &quot;
            
            var response = await _graphQLClient.SubscribeAsync(subscriptionQuery);
            
            response.Subscribe(
                data =&gt; Console.WriteLine($"New Book Added: {data.BookAdded.Title} by {data.BookAdded.Author.Name}")
            );
        &#125;
      `}</code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>8. Best Practices for Querying GraphQL APIs</h3>
    <ul>
      <li><strong>Use Variables</strong>: Always use variables in your queries to make them reusable and avoid security issues like query injection.</li><br />
      <li><strong>Limit the Query Depth</strong>: Ensure that you’re limiting the query depth to avoid overly complex queries that could overwhelm the server.</li><br />
      <li><strong>Use Fragments</strong>: Reuse parts of your queries with fragments to avoid repetition and reduce the size of your queries.</li><br />
      <li><strong>Handle Errors Gracefully</strong>: Always check for errors in the response, as GraphQL might return partial data with error information.</li>
    </ul>
<br />

    <h3 >Conclusion</h3>
    <p>
      Querying GraphQL APIs with .NET clients is straightforward and provides powerful tools for interacting with modern web services. With support for querying, mutations, and subscriptions, you can leverage GraphQL to fetch exactly the data you need while minimizing the overhead of traditional REST APIs. By following best practices and handling errors effectively, you can ensure your .NET application communicates efficiently and securely with GraphQL endpoints.
    </p>
  </div>
)}


{selectedChapter === 'chapter107' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Best Practices for Integrating GraphQL with ASP.NET Core</h1>

    <p>When integrating GraphQL with ASP.NET Core, following best practices ensures that your API is efficient, maintainable, secure, and scalable. Here are some key best practices to consider:</p><br />

    <h2>1. Define a Strongly Typed Schema</h2>
    <p>
      <strong>Schema First Design:</strong> Start by defining a clear and strong schema that outlines the types, queries, mutations, and subscriptions. A well-structured schema is the backbone of any GraphQL API.
    </p>
    <p>
      <strong>Use DTOs (Data Transfer Objects):</strong> Avoid exposing your domain models directly to the GraphQL API. Instead, create DTOs to represent the structure that clients will query. This helps decouple your database models from the API and allows for easier future changes.
    </p>
    <pre><code className="language-csharp">
      {`public class BookDTO
      {
          public int Id { get; set; }
          public string Title { get; set; }
      }`}
    </code></pre><br />

    <h2>2. Avoid N+1 Query Problems</h2>
    <p>
      <strong>Batch Data Fetching:</strong> One of the most common issues in GraphQL is the N+1 query problem, where a large number of database queries are generated due to nested queries. To prevent this, use techniques such as <strong>DataLoader</strong> (available for .NET GraphQL libraries) to batch and cache database calls.
    </p>
    <p>
      <strong>Use Efficient Data Fetching:</strong> For relational data, ensure that you use <strong>Joins</strong> or <strong>Includes</strong> when fetching data to minimize the number of database calls.
    </p>
    <pre><code className="language-csharp">
      {`public class BookResolver
      {
          private readonly IBookService _bookService;

          public BookResolver(IBookService bookService)
          {
              _bookService = bookService;
          }

          public async Task<IEnumerable<BookDTO>> GetBooksAsync()
          {
              return await _bookService.GetBooksWithAuthorsAsync(); // Single database call
          }
      }`}
    </code></pre><br />

    <h2>3. Pagination and Filtering</h2>
    <p>
      <strong>Implement Pagination:</strong> For large datasets, always implement pagination to avoid over-fetching data. You can achieve this with GraphQL by defining arguments like `limit` and `offset` for queries.
    </p>
    <p>
      <strong>Filtering and Sorting:</strong> Allow clients to filter or sort the data as needed using arguments in queries.
    </p>
    <pre><code className="language-graphql">
      {`query {
          books(page: 1, size: 10) {
              id
              title
          }
      }`}
    </code></pre><br />

    <h2>4. Security Considerations</h2>
    <p>
      <strong>Authorization:</strong> Implement proper authorization checks to ensure that users can only query data they have access to. You can achieve this by adding authorization rules in your resolvers or middleware.
    </p>
    <p>
      <strong>Rate Limiting:</strong> Implement rate limiting to prevent abuse of the GraphQL API. This can be done using custom middleware or third-party libraries.
    </p>
    <p>
      <strong>Query Complexity Analysis:</strong> To prevent overly complex queries that may overload the server, consider using query complexity analysis or depth limiting to restrict how deep a query can go.
    </p>
    <pre><code className="language-csharp">
      {`public class BookResolver
      {
          private readonly IBookService _bookService;
          private readonly IAuthorizationService _authorizationService;

          public BookResolver(IBookService bookService, IAuthorizationService authorizationService)
          {
              _bookService = bookService;
              _authorizationService = authorizationService;
          }

          public async Task<BookDTO> GetBookAsync(int id, ClaimsPrincipal user)
          {
              var authorizationResult = await _authorizationService.AuthorizeAsync(user, id, "BookAccess");
              if (!authorizationResult.Succeeded)
                  throw new UnauthorizedAccessException();

              return await _bookService.GetBookByIdAsync(id);
          }
      }`}
    </code></pre><br />

    <h2>5. Error Handling</h2>
    <p>
      <strong>Use Custom Error Types:</strong> Create custom error types in your GraphQL schema to provide more detailed error information, such as validation errors, not found errors, or authorization errors.
    </p>
    <p>
      <strong>Graceful Error Reporting:</strong> Always return clear, consistent error messages that help clients understand the problem without exposing sensitive details.
    </p>
    <pre><code className="language-csharp">
      {`public class GraphQLError
      {
          public string Message { get; set; }
          public string Code { get; set; }
      }`}
    </code></pre><br />

    <h2>6. Use GraphQL Subscriptions for Real-Time Data</h2>
    <p>
      <strong>Implement Subscriptions for Real-Time Updates:</strong> If your application needs real-time updates (e.g., a messaging app or live data feeds), GraphQL subscriptions are a great way to handle them.
    </p>
    <p>
      <strong>Leverage WebSockets or Server-Sent Events:</strong> ASP.NET Core can support GraphQL subscriptions via WebSockets. Ensure that your infrastructure is capable of handling WebSocket connections at scale.
    </p>
    <pre><code className="language-graphql">
      {`subscription {
          bookAdded {
              id
              title
          }
      }`}
    </code></pre><br />

    <h2>7. Batching and Caching</h2>
    <p>
      <strong>Batching:</strong> Use batching for related queries to reduce redundant database calls. You can combine multiple GraphQL requests into one to optimize performance.
    </p>
    <p>
      <strong>Caching:</strong> For frequently requested data, use caching techniques (e.g., in-memory caching or distributed caching) to reduce load on your database and improve response times.
    </p><br />

    <h2>8. Documentation and Introspection</h2>
    <p>
      <strong>Enable GraphQL Playground or Voyager:</strong> During development, enable tools like GraphQL Playground or GraphQL Voyager to allow your team to interact with the API, view the schema, and explore available queries and mutations.
    </p>
    <p>
      <strong>Document Schema:</strong> Make sure your GraphQL schema is well-documented using tools that can generate human-readable documentation from the GraphQL introspection query.
    </p>
    <pre><code className="language-csharp">
      {`app.UseGraphQLPlayground(new GraphQLPlaygroundOptions
      {
          Path = "/graphql"
      });`}
    </code></pre><br />

    <h2>9. Avoid Over-fetching and Under-fetching</h2>
    <p>
      <strong>Field-Level Control:</strong> One of the benefits of GraphQL is that clients can specify exactly which fields they want. Ensure that you structure your schema to minimize the potential for over-fetching or under-fetching by allowing the flexibility to select only the necessary fields.
    </p><br />

    <h2>10. Testing</h2>
    <p>
      <strong>Unit Testing:</strong> Make sure to write unit tests for your GraphQL resolvers. Mock the data and ensure that each resolver behaves as expected.
    </p>
    <p>
      <strong>Integration Testing:</strong> Create integration tests to test the full GraphQL pipeline, including how the queries interact with your data layer.
    </p>
    <pre><code className="language-csharp">
      {`public class BookResolverTests
      {
          [Fact]
          public async Task GetBookAsync_ShouldReturnBook()
          {
              var mockService = new Mock<IBookService>();
              mockService.Setup(s => s.GetBookByIdAsync(1)).ReturnsAsync(new BookDTO { Id = 1, Title = "Test Book" });

              var resolver = new BookResolver(mockService.Object);
              var result = await resolver.GetBookAsync(1, new ClaimsPrincipal());

              Assert.NotNull(result);
              Assert.Equal("Test Book", result.Title);
          }
      }`}
    </code></pre><br />

    <h2>Conclusion</h2>
    <p>
      By following these best practices, you can build a robust, efficient, and secure GraphQL API in ASP.NET Core that scales well, minimizes performance bottlenecks, and offers a flexible querying mechanism for clients.
    </p>
  </div>
)}



    {selectedChapter === 'chapter108' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Building Serverless APIs with Azure Functions</h1>

    <p>
      In this chapter, we will explore how to build serverless APIs using Azure Functions with ASP.NET Core. Serverless computing allows you to run your application code without managing the underlying infrastructure, providing scalability, flexibility, and cost savings.
    </p><br />

    <h2>1. Introduction to Azure Functions</h2>
    <p>
      Azure Functions is a serverless compute service that allows you to run small pieces of code (called "functions") in the cloud. Functions can be triggered by a variety of events such as HTTP requests, timers, or messages from Azure services like Azure Storage or Azure Service Bus.
    </p><br />

    <h2>2. Creating Your First Azure Function</h2>
    <p>
      To get started with Azure Functions in ASP.NET Core, you can create a new Azure Functions project using the Azure Functions template in Visual Studio or Visual Studio Code. Here’s how to create a simple HTTP-triggered function:
    </p>
    
    <pre>
      <code>{`
        public static class HttpExample
        {{
            [FunctionName("HttpExample")]
            public static async Task Run(
                [HttpTrigger(AuthorizationLevel.Function, "get", "post")] HttpRequestMessage req,
                ILogger log)
            {{
                log.LogInformation("C# HTTP trigger function processed a request.");
                return req.CreateResponse(HttpStatusCode.OK, "Hello, Azure Functions!");
            }}
        }}
       `} </code>
    </pre>
    
    <p>
      This function will respond to HTTP GET and POST requests, returning a simple greeting message.
    </p><br />

    <h2>3. Integrating with ASP.NET Core APIs</h2>
    <p>
      Azure Functions can be integrated with your existing ASP.NET Core APIs to build serverless endpoints. You can use Azure Functions to handle specific tasks like processing background jobs, performing asynchronous computations, or connecting with external services while keeping the rest of your API in ASP.NET Core.
    </p><br />

    <h2>4. Scaling Serverless APIs</h2>
    <p>
      One of the key benefits of serverless computing is automatic scaling. Azure Functions scale automatically based on demand, meaning they can handle thousands of concurrent requests without the need for manual intervention. However, it’s important to ensure your functions are optimized for scaling.
    </p>
    <ul>
      <li>Keep functions stateless to allow multiple instances to process requests concurrently.</li><br />
      <li>Use durable functions for long-running workflows.</li><br />
      <li>Optimize function execution time to reduce cold start delays.</li>
    </ul><br />

    <h2>5. Security Considerations</h2>
    <p>
      Just like traditional APIs, security is crucial for serverless functions. When building APIs with Azure Functions, you should implement:
    </p>
    <ul>
      <li>API Key Authentication: Secure your functions by requiring API keys for incoming requests.</li><br />
      <li>Azure Active Directory (AAD): Use AAD for more advanced authentication scenarios.</li><br />
      <li>Role-based Access Control (RBAC): Ensure that only authorized users can execute specific functions.</li>
    </ul><br />

    <h2>6. Best Practices for Serverless APIs</h2>
    <p>
      Here are some best practices to consider when building serverless APIs with Azure Functions:
    </p>
    <ul>
      <li><strong>Keep Functions Small and Focused:</strong> Each function should handle a single responsibility to make it easy to test, maintain, and scale.</li><br />
      <li><strong>Optimize for Cold Starts:</strong> Cold starts can delay the initial execution of your functions. Minimize the code dependencies and reduce the startup time by using the Premium Plan or function app warmers.</li><br />
      <li><strong>Leverage Logging and Monitoring:</strong> Use Azure Application Insights to monitor function performance, track failures, and identify bottlenecks.</li>
    </ul><br />

    <h2>7. Error Handling and Monitoring</h2>
    <p>
      Ensure your functions are resilient by implementing robust error handling and monitoring. Azure Functions provide built-in support for logging and monitoring with Azure Application Insights. You can log detailed error information, track execution times, and identify any performance issues.
    </p>

    <pre>
      <code>{`
        public static class FunctionWithErrorHandling
        {{
            [FunctionName("FunctionWithErrorHandling")]
            public static async Task Run(
                [HttpTrigger(AuthorizationLevel.Function, "get")] HttpRequestMessage req,
                ILogger log)
            {{
                try
                {{
                    // Simulate function logic
                    throw new Exception("Something went wrong.");
                }}
                catch (Exception ex)
                {{
                    log.LogError($"Error occurred: {ex.Message}");
                    throw;
                }}
            }}
        }}
       `} </code>
    </pre><br />

    <h2>8. Deploying Serverless APIs</h2>
    <p>
      Once your Azure Function is built and tested, you can deploy it directly to Azure. Deployment can be done using Azure DevOps, GitHub Actions, or Visual Studio, which integrates with Azure Functions for streamlined deployments.
    </p><br />

    <h2>9. Cost Considerations</h2>
    <p>
      Serverless computing allows you to pay only for what you use. However, understanding the cost model is essential to avoid unexpected charges. Azure Functions are billed based on the number of executions and the duration of each execution, so optimizing your functions can help manage costs.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Building serverless APIs with Azure Functions provides a flexible, scalable, and cost-effective solution for modern cloud applications. By following best practices such as optimizing performance, implementing robust security measures, and integrating with existing ASP.NET Core services, you can create efficient and reliable serverless APIs that meet the demands of your users.
    </p>
  </div>
)}





    {selectedChapter === 'chapter109' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Integration of ASP.NET Core with AWS Lambda</h1>

    <p>
      In this chapter, we will learn how to integrate ASP.NET Core applications with AWS Lambda to build serverless solutions. AWS Lambda is a serverless compute service that lets you run code without provisioning or managing servers. By integrating it with ASP.NET Core, you can deploy high-performance APIs and background services.
    </p><br />

    <h2>1. Introduction to AWS Lambda</h2>
    <p>
      AWS Lambda allows you to run your code in response to events, such as HTTP requests, updates to Amazon S3 buckets, or changes in DynamoDB tables. It automatically scales based on the number of incoming requests, providing a cost-effective solution for running serverless applications.
    </p><br />

    <h2>2. Setting Up ASP.NET Core for AWS Lambda</h2>
    <p>
      To deploy an ASP.NET Core application to AWS Lambda, follow these steps:
    </p>
    <ul>
      <li>
        <strong>Install the AWS Toolkit for Visual Studio:</strong> The toolkit provides templates and tools for deploying to AWS Lambda.
      </li><br />
      <li>
        <strong>Create an ASP.NET Core Project:</strong> Start by creating an ASP.NET Core Web API project using Visual Studio or the .NET CLI.
      </li><br />
      <li>
        <strong>Add the AWS Lambda Tools Package:</strong> Install the NuGet package <code>Amazon.Lambda.AspNetCoreServer</code> to enable Lambda hosting:
        <pre>
          <code>
            dotnet add package Amazon.Lambda.AspNetCoreServer
          </code>
        </pre>
      </li>
    </ul><br />

    <h2>3. Creating a Lambda Entry Point</h2>
    <p>
      AWS Lambda requires an entry point to execute your application. Create a class that inherits from <code>APIGatewayProxyFunction</code> or <code>ApplicationLoadBalancerFunction</code>, depending on how you want to expose your application.
    </p>
    <pre>
      <code>{`
        public class LambdaEntryPoint : Amazon.Lambda.AspNetCoreServer.APIGatewayProxyFunction
        {{
            protected override void Init(IWebHostBuilder builder)
            {{
                builder.UseStartup&lt;Startup&gt;();
            }}
        }}
       `} </code>
    </pre>
    <p>
      This entry point initializes the ASP.NET Core application and allows it to process incoming requests via AWS API Gateway.
    </p><br />

    <h2>4. Deploying to AWS Lambda</h2>
    <p>
      Deploy your ASP.NET Core application to AWS Lambda using the following steps:
    </p>
    <ol>
      <li>
        <strong>Package Your Application:</strong> Use the AWS Lambda .NET CLI tools to package your application into a ZIP file:
        <pre>
          <code>
            dotnet lambda package --configuration Release --output-package app.zip
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Deploy to AWS Lambda:</strong> Use the CLI or AWS Management Console to upload the packaged application and create a new Lambda function.
      </li><br />
      <li>
        <strong>Configure API Gateway:</strong> Set up an API Gateway to route HTTP requests to your Lambda function.
      </li>
    </ol><br />

    <h2>5. Testing the Integration</h2>
    <p>
      After deploying the application, test it using the AWS Management Console or tools like Postman. The API Gateway URL can be used to make HTTP requests to your Lambda-backed ASP.NET Core application.
    </p><br />

    <h2>6. Logging and Monitoring</h2>
    <p>
      AWS Lambda integrates seamlessly with Amazon CloudWatch for logging and monitoring. Configure CloudWatch to track metrics such as execution time, memory usage, and errors. Add logging in your application using the standard <code>ILogger</code> interface:
    </p>
    <pre>
      <code>{`
        public class MyController : ControllerBase
        {{
            private readonly ILogger&lt;MyController&gt; _logger;

            public MyController(ILogger&lt;MyController&gt; logger)
            {{
                _logger = logger;
            }}

            [HttpGet]
            public IActionResult Get()
            {{
                _logger.LogInformation("Processing request...");
                return Ok("Hello from AWS Lambda!");
            }}
        }}
     `}</code>
    </pre><br />

    <h2>7. Optimizing for Cold Starts</h2>
    <p>
      Cold starts in AWS Lambda occur when a function is invoked for the first time or after a period of inactivity. To optimize for cold starts:
    </p>
    <ul>
      <li>Use the <strong>Provisioned Concurrency</strong> feature to keep instances warm.</li><br />
      <li>Minimize dependencies and avoid large initialization processes.</li><br />
      <li>Consider using lighter ASP.NET Core projects if response time is critical.</li>
    </ul><br />

    <h2>8. Security Considerations</h2>
    <p>
      When integrating ASP.NET Core with AWS Lambda, ensure your application follows security best practices:
    </p>
    <ul>
      <li>Use IAM roles to securely grant permissions to your Lambda function.</li><br />
      <li>Enable encryption for sensitive data passed through Lambda and API Gateway.</li><br />
      <li>Restrict API Gateway access using AWS WAF or IP whitelisting.</li>
    </ul><br />

    <h2>9. Cost Management</h2>
    <p>
      AWS Lambda pricing is based on the number of requests and the compute time consumed. Optimize your application to reduce costs:
    </p>
    <ul>
      <li>Monitor execution time and memory usage using CloudWatch.</li><br />
      <li>Use asynchronous programming to minimize blocking calls.</li><br />
      <li>Combine lightweight functions to reduce overhead.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Integrating ASP.NET Core with AWS Lambda provides a scalable and cost-effective way to build serverless APIs and applications. By following the steps outlined in this chapter, you can efficiently deploy your ASP.NET Core projects to AWS Lambda, taking advantage of the serverless architecture's benefits.
    </p>
  </div>
)}







{selectedChapter === 'chapter110' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Working with Serverless Databases (Azure Cosmos DB, DynamoDB)
    </h1>

    <p>
      Serverless databases like Azure Cosmos DB and AWS DynamoDB are designed for scalability, flexibility, and low maintenance, making them an excellent choice for serverless applications. In this chapter, we’ll explore how to integrate these databases with ASP.NET Core applications.
    </p><br />

    <h2>1. Introduction to Serverless Databases</h2>
    <p>
      Serverless databases automatically manage scaling, availability, and maintenance, allowing developers to focus on their application logic. Azure Cosmos DB and DynamoDB are popular choices, each with unique features:
    </p>
    <ul>
      <li>
        <strong>Azure Cosmos DB:</strong> A globally distributed, multi-model database supporting SQL, MongoDB, Cassandra, Table, and Gremlin APIs.
      </li><br />
      <li>
        <strong>AWS DynamoDB:</strong> A fully managed NoSQL database service with fast, predictable performance and seamless scaling.
      </li>
    </ul><br />

    <h2>2. Setting Up Azure Cosmos DB in ASP.NET Core </h2>
    <p>
      Follow these steps to integrate Azure Cosmos DB with an ASP.NET Core application:
    </p>
    <ol>
      <li>
        <strong>Create an Azure Cosmos DB Account:</strong> Log in to the Azure portal and create a new Cosmos DB account. Select the appropriate API (e.g., SQL, MongoDB).
      </li><br />
      <li>
        <strong>Install the Required NuGet Package:</strong> Add the <code>Microsoft.Azure.Cosmos</code> package to your project:
        <pre>
          <code>
            dotnet add package Microsoft.Azure.Cosmos
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Configure Cosmos DB Client:</strong> In your <code>Startup.cs</code> or <code>Program.cs</code> file, configure the Cosmos DB client:
        <pre>
          <code>
            builder.Services.AddSingleton(new CosmosClient("Your_Connection_String"));
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Use the Cosmos DB Client:</strong> Inject the CosmosClient into your services or controllers:
        <pre>
          <code>{`
            public class CosmosDbService
            {{
                private readonly CosmosClient _cosmosClient;
                private readonly Container _container;

                public CosmosDbService(CosmosClient cosmosClient)
                {{
                    _cosmosClient = cosmosClient;
                    _container = _cosmosClient.GetContainer("DatabaseName", "ContainerName");
                }}

                public async Task AddItemAsync<T>(T item)
                {{
                    await _container.CreateItemAsync(item);
                }}
            }}
     `} </code>
        </pre>
      </li>
    </ol><br />

    <h2>3. Setting Up DynamoDB in ASP.NET Core</h2>
    <p>
      To work with DynamoDB, follow these steps:
    </p>
    <ol>
      <li>
        <strong>Create a DynamoDB Table:</strong> Use the AWS Management Console or AWS CLI to create a new table with the necessary attributes.
      </li>
      <li>
        <strong>Install the Required NuGet Package:</strong> Add the <code>AWSSDK.DynamoDBv2</code> package to your project:
        <pre>
          <code>
            dotnet add package AWSSDK.DynamoDBv2
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Configure DynamoDB Client:</strong> In <code>Startup.cs</code> or <code>Program.cs</code>, configure the DynamoDB client:
        <pre>
          <code>
            builder.Services.AddAWSService&lt;IAmazonDynamoDB&gt;();
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Use the DynamoDB Client:</strong> Inject and use the DynamoDB client in your application:
        <pre>
          <code>{`
            public class DynamoDbService
            {{
                private readonly IAmazonDynamoDB _dynamoDb;

                public DynamoDbService(IAmazonDynamoDB dynamoDb)
                {{
                    _dynamoDb = dynamoDb;
                }}

                public async Task AddItemAsync<T>(T item, string tableName)
                {{
                    var request = new PutItemRequest
                    {{
                        TableName = tableName,
                        Item = DynamoDBHelper.ConvertToDocument(item)
                    }};
                    await _dynamoDb.PutItemAsync(request);
                }}
            }}
           `} </code>
        </pre>
      </li>
    </ol><br />

    <h2>4. Key Differences Between Cosmos DB and DynamoDB</h2>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Azure Cosmos DB</th>
          <th>AWS DynamoDB</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>API Support</td>
          <td>SQL, MongoDB, Cassandra, Gremlin</td>
          <td>Proprietary NoSQL API</td>
        </tr>
        <tr>
          <td>Global Distribution</td>
          <td>Native support</td>
          <td>Enabled via Global Tables</td>
        </tr>
        <tr>
          <td>Consistency Levels</td>
          <td>5 options: Strong, Bounded Staleness, Session, Consistent Prefix, Eventual</td>
          <td>Eventual and Strong</td>
        </tr>
      </tbody>
    </table><br />

    <h2 style={{paddingBottom:"6px"}}>5. Best Practices</h2>
    <ul>
      <li>Design your database schema for the specific use case, considering performance and cost.</li><br />
      <li>Use partition keys effectively for optimized performance.</li><br />
      <li>Leverage indexing features to accelerate queries.</li><br />
      <li>Monitor usage and optimize read/write throughput to control costs.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>6. Security Considerations</h2>
    <ul>
      <li>Enable encryption at rest and in transit for sensitive data.</li><br />
      <li>Use role-based access control (RBAC) to restrict access to the database.</li><br />
      <li>Audit and monitor database activities using logging tools like Azure Monitor or CloudWatch.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Azure Cosmos DB and AWS DynamoDB offer powerful features for building serverless, scalable applications. By integrating these databases with ASP.NET Core, you can create robust, high-performance solutions tailored to your application's needs.
    </p>
  </div>
)}





{selectedChapter === 'chapter111' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Middleware Pipeline Customization</h1>

    <p>
      The middleware pipeline in ASP.NET Core is central to handling HTTP requests and responses. Middleware components process requests in sequence, with each component deciding whether to pass the request further or generate a response. Customizing the pipeline is essential for building flexible, performant applications tailored to specific needs.
    </p><br />

    <h2>1. Understanding Middleware in ASP.NET Core</h2>
    <p>
      Middleware is software that handles requests and responses. The request pipeline is configured in the <code>Program.cs</code> file using middleware components. Common built-in middleware includes authentication, static file serving, routing, and more.
    </p>
    <pre>
      <code>{`
        app.UseRouting();
        app.UseAuthentication();
        app.UseAuthorization();
        app.UseEndpoints(endpoints =>
        {{
            endpoints.MapControllers();
        }});
 `} </code>
    </pre><br />

    <h2>2. Creating Custom Middleware</h2>
    <p>
      Custom middleware allows you to implement logic that is specific to your application's needs. Here's how to create and use custom middleware:
    </p>
    <ol>
      <li>
        <strong>Create the Middleware Class:</strong>
        <pre>
          <code>{`
            public class CustomMiddleware
            {{
                private readonly RequestDelegate _next;

                public CustomMiddleware(RequestDelegate next)
                {{
                    _next = next;
                }}

                public async Task InvokeAsync(HttpContext context)
                {{
                    // Pre-processing logic
                    Console.WriteLine($"Request Path: {context.Request.Path}");

                    // Call the next middleware in the pipeline
                    await _next(context);

                    // Post-processing logic
                    Console.WriteLine($"Response Status Code: {context.Response.StatusCode}");
                }}
            }}
           `} </code>
        </pre>
      </li>
      <li>
        <strong>Register the Middleware:</strong> Use the middleware in the pipeline by adding it to the <code>Program.cs</code> file:
        <pre>
          <code>
            app.UseMiddleware&lt;CustomMiddleware&gt;();
          </code>
        </pre>
      </li>
    </ol><br />

    <h2>3. Order of Middleware</h2>
    <p>
      The order in which middleware is added to the pipeline matters because each middleware can short-circuit the pipeline or pass the request to the next middleware. For example:
    </p>
    <pre>
      <code>{`
        app.UseAuthentication(); // Runs before authorization
        app.UseAuthorization();  // Ensures user is authorized
        app.UseEndpoints(...);   // Maps request to the appropriate controller or action
      `}</code>
    </pre>
    <p>
      If <code>app.UseAuthorization()</code> is placed before <code>app.UseAuthentication()</code>, authentication will fail because it hasn't been performed yet.
    </p><br />

    <h2>4. Branching the Pipeline</h2>
    <p>
      Middleware branching allows you to define separate pipelines for specific request paths. This is done using <code>Map</code> and <code>MapWhen</code>.
    </p>
    <h3>Using <code>Map</code></h3>
    <pre>
      <code>{`
      
        app.Map("/api", appBuilder =>
        {{
            appBuilder.UseMiddleware&lt;ApiMiddleware&gt;();
        }});
      `}</code>
</pre>
    <h3>Using <code>MapWhen</code></h3>
    <pre> 
    <code>{`
        app.MapWhen(context => context.Request.Path.StartsWithSegments("/custom"), appBuilder =>
        {{
            appBuilder.UseMiddleware&lt;CustomMiddleware&gt;();
        }});
`}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>5. Middleware Best Practices</h2>
    <ul>
      <li>Place middleware in the correct order to ensure proper execution.</li><br />
      <li>Keep middleware focused on a single responsibility to maintain clarity and reusability.</li><br />
      <li>Use asynchronous methods in middleware to improve performance.</li><br />
      <li>Log critical information for debugging and monitoring purposes.</li>
    </ul><br />

    <h2>6. Common Middleware Components</h2>
    <p>
      Here are some commonly used middleware components:
    </p>
    <ul>
      <li><strong>Static Files Middleware:</strong> Serves static files like CSS, JavaScript, and images.</li><br />
      <li><strong>Routing Middleware:</strong> Matches requests to endpoints.</li><br />
      <li><strong>Authentication/Authorization Middleware:</strong> Handles security for requests.</li><br />
      <li><strong>CORS Middleware:</strong> Configures Cross-Origin Resource Sharing policies.</li>
    </ul><br />

    <h2>7. Debugging Middleware</h2>
    <p>
      Middleware logic can be debugged using tools like Visual Studio’s debugger or logs. Use these tips:
    </p>
    <ul>
      <li>Insert logging statements at key points in the middleware.</li><br />
      <li>Break down complex middleware into smaller, manageable components.</li><br />
      <li>Test the pipeline order to ensure middleware functions as expected.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Middleware pipeline customization allows you to create flexible and powerful ASP.NET Core applications tailored to your needs. By leveraging custom middleware, branching, and proper ordering, you can optimize your application’s performance and maintainability.
    </p>
  </div>
)}





{selectedChapter === 'chapter112' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Advanced Model Binding and Custom Formatters
    </h1>

    <p>
      Model binding and formatters in ASP.NET Core are essential for handling
      request and response data. Advanced techniques enable applications to
      manage complex data scenarios and custom formats effectively.
    </p><br />


      <h2>1. Overview of Model Binding</h2>
      <p>
        Model binding automatically maps data from HTTP requests to action
        method parameters. It supports:
      </p>
      <ul>
        <li>Simple types (e.g., <code>int</code>, <code>string</code>)</li><br />
        <li>Complex types (e.g., custom objects)</li><br />
        <li>Sources like query strings, route data, form data, headers, and more</li>
      </ul><br />

      <pre>
        <code>{`public IActionResult SubmitData(MyModel model)
{
    // The model is automatically populated by the framework
    return Ok(model);
}`}</code>
      </pre><br />
    
      <h2>2. Advanced Model Binding</h2>
      <p>
        Sometimes, built-in model binding doesn't cover specific scenarios. For
        instance:
      </p>
      <ul>
        <li>Binding data from unconventional sources like cookies or headers</li><br />
        <li>Mapping nested or customized structures</li>
      </ul><br />

      <h3>Creating a Custom Model Binder</h3>
      <ol>
        <li>
          <strong>Implement <code>IModelBinder</code>:</strong>
          <pre>
            <code>{`public class CustomModelBinder : IModelBinder
{
    public Task BindModelAsync(ModelBindingContext bindingContext)
    {
        var value = bindingContext.ValueProvider.GetValue("customKey").FirstValue;

        if (string.IsNullOrEmpty(value))
        {
            bindingContext.Result = ModelBindingResult.Failed();
            return Task.CompletedTask;
        }

        var model = new CustomModel { Key = value.ToUpper() };
        bindingContext.Result = ModelBindingResult.Success(model);
        return Task.CompletedTask;
    }
}`}</code>
          </pre>
        </li><br />
        <li>
          <strong>Create a Custom Attribute:</strong>
          <pre>
            <code>{`public class CustomModelBinderAttribute : ModelBinderAttribute
{
    public CustomModelBinderAttribute() : base(typeof(CustomModelBinder)) { }
}`}</code>
          </pre>
        </li><br />
        <li>
          <strong>Apply the Binder to the Action:</strong>
          <pre>
            <code>{`public IActionResult SubmitData([ModelBinder(typeof(CustomModelBinder))] CustomModel model)
{
    return Ok(model);
}`}</code>
          </pre>
        </li>
      </ol><br />
   
      <h2>3. Custom Formatters</h2>
      <p style={{paddingBottom:"6px"}}>
        Custom formatters handle serialization and deserialization of
        non-standard formats (e.g., CSV, YAML, custom binary data).
      </p>

      <h3>Creating an Output Formatter</h3>
      <ol>
        <li>
          <strong>Extend <code>TextOutputFormatter</code>:</strong>
          <pre>
            <code>{`public class CsvOutputFormatter : TextOutputFormatter
{
    public CsvOutputFormatter()
    {
        SupportedMediaTypes.Add("text/csv");
        SupportedEncodings.Add(Encoding.UTF8);
    }

    protected override bool CanWriteType(Type type)
    {
        return typeof(IEnumerable).IsAssignableFrom(type);
    }

    public override async Task WriteResponseBodyAsync(OutputFormatterWriteContext context, Encoding selectedEncoding)
    {
        var response = context.HttpContext.Response;
        var items = context.Object as IEnumerable;
        foreach (var item in items)
        {
            await response.WriteAsync($"{item.ToString()},");
        }
    }
}`}</code>
          </pre>
        </li>
        <li>
          <strong>Register the Formatter:</strong>
          <pre>
            <code>{`builder.Services.AddControllers(options =>
{
    options.OutputFormatters.Add(new CsvOutputFormatter());
});`}</code>
          </pre>
        </li>
      </ol><br />
   
      <h2>4. Handling Multiple Formats</h2>
      <p>
        To support multiple input or output formats, use attributes like
        <code>[Consumes]</code> or <code>[Produces]</code>:
      </p>
      <pre>
        <code>{`[HttpPost]
[Consumes("application/json", "text/xml")]
public IActionResult SubmitData(MyModel model)
{
    return Ok(model);
}`}</code>
      </pre><br />
    
      <h2>5. Debugging and Best Practices</h2>
      <p>
        Debugging tips:
      </p>
      <ul>
        <li>Enable detailed error messages using the Developer Exception Page</li><br />
        <li>Log binding or formatting errors via middleware or <code>ILogger</code></li><br />
        <li>Inspect requests using tools like Postman or Fiddler</li>
      </ul><br />
  
      <h2>Conclusion</h2>
      <p>
        Advanced model binding and custom formatters in ASP.NET Core provide the
        flexibility to handle unconventional data structures and formats. By
        mastering these techniques, you can build robust APIs that meet diverse
        and complex requirements.
      </p>
   
  </div>
)}


{selectedChapter === 'chapter113' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Advanced Model Binding and Custom Formatters</h1>
      <p>
        In this session, you’ll learn about advanced techniques in model binding and custom 
        formatters in ASP.NET Core, enabling you to handle complex input scenarios and extend 
        the flexibility of your web applications.
      </p><br />
 
      <h3 style={{paddingBottom:"6px"}}>Learning Objectives</h3>
      <ul>
        <li>Understand how ASP.NET Core processes incoming data for model binding.</li><br />
        <li>Explore complex model binding scenarios such as nested objects and collections.</li><br />
        <li>Learn how to create custom model binders to handle unconventional data formats.</li><br />
        <li>Understand the role of formatters in content negotiation.</li><br />
        <li>Implement custom input and output formatters for handling specialized content types.</li>
      </ul><br />
  
      <h3 style={{paddingBottom:"6px"}}>Key Topics</h3>

      <h4>1. Understanding Model Binding in ASP.NET Core</h4>
      <ul>
        <li>Recap of the model binding process.</li><br />
        <li>Default binders for common types (e.g., <code>IFormCollection</code>, <code>QueryString</code>, JSON).</li><br />
        <li>Handling nested objects and collections.</li>
      </ul>

      <h4>2. Complex Model Binding Scenarios</h4>
      <ul>
        <li>Binding hierarchical or nested objects.</li><br />
        <li>Using <code>[FromQuery]</code>, <code>[FromBody]</code>, <code>[FromRoute]</code>, and <code>[FromForm]</code> attributes effectively.</li><br />
        <li>Resolving conflicts in data sources.</li>
      </ul>

      <h4>3. Creating Custom Model Binders</h4>
      <ul>
        <li>Scenarios where default binding isn’t sufficient.</li><br />
        <li>Implementing a custom model binder:</li>
        <ul>
          <li>Creating a <code>IModelBinder</code> implementation.</li><br />
          <li>Registering the custom binder using <code>ModelBinderAttribute</code> or <code>ModelBinderProvider</code>.</li>
        </ul>
        <li>Example: Binding data from a complex query string or custom headers.</li>
      </ul>

      <h4>4. Overview of Formatters</h4>
      <ul>
        <li>What are formatters?</li><br />
        <li>Role in content negotiation.</li><br />
        <li>Built-in formatters (JSON, XML, etc.).</li>
      </ul><br />

      <h4>5. Implementing Custom Input Formatters</h4>
      <ul>
        <li>Scenarios for custom input formatters (e.g., CSV, Protocol Buffers).</li><br />
        <li>Creating a custom <code>InputFormatter</code>.</li><br />
        <li>Adding the formatter to the application pipeline.</li>
      </ul>

      <h4>6. Implementing Custom Output Formatters</h4>
      <ul>
        <li>Scenarios for custom output formats.</li><br />
        <li>Creating a custom <code>OutputFormatter</code>.</li><br />
        <li>Configuring supported media types.</li>
      </ul><br />

      <h4>7. Practical Examples</h4>
      <ul>
        <li>Binding complex query parameters using a custom model binder.</li><br />
        <li>Handling CSV data as input and returning XML as output.</li><br />
        <li>Writing and testing a formatter for a custom MIME type.</li>
      </ul><br />

      <h3>Hands-On Exercise</h3>
      <p><strong>Objective:</strong> Create a custom model binder to bind hierarchical data from a query string and implement a custom CSV input formatter.</p>
      <ol>
        <li>Create a model class with nested objects.</li><br />
        <li>Develop and register a custom model binder for the model.</li><br />
        <li>Implement an input formatter to parse CSV data into a model object.</li><br />
        <li>Test the functionality using Swagger or Postman.</li>
      </ol><br />
   
      <h3>Best Practices</h3>
      <ul>
        <li>Ensure custom model binders are robust and handle errors gracefully.</li><br />
        <li>Validate incoming data explicitly, especially when using custom binders or formatters.</li><br />
        <li>Use content negotiation to improve the client experience.</li><br />
        <li>Maintain consistency with standard practices when extending functionalities.</li>
      </ul><br />

      <h3>Resources</h3>
      <ul>
        <li><a href="https://learn.microsoft.com/en-us/aspnet/core/mvc/models/model-binding" target="_blank" rel="noopener noreferrer">ASP.NET Core Model Binding Documentation</a></li><br />
        <li><a href="https://learn.microsoft.com/en-us/aspnet/core/mvc/advanced/custom-formatters" target="_blank" rel="noopener noreferrer">Input and Output Formatters in ASP.NET Core</a></li><br />
        <li><a href="https://learn.microsoft.com/en-us/aspnet/core/mvc/advanced/custom-model-binding" target="_blank" rel="noopener noreferrer">Custom Model Binding in ASP.NET Core</a></li>
      </ul><br />

      <h3>Q&A Session</h3>
      <p>Open the floor for questions to clarify concepts and address real-world scenarios participants may encounter.</p>
   
  </div>
)}



{selectedChapter === 'chapter114' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Advanced Dependency Injection Scenarios </h1>


      <p>
        In this session, we delve into advanced dependency injection (DI) scenarios in ASP.NET Core. 
        You’ll learn how to implement sophisticated DI techniques to enhance the modularity, 
        testability, and maintainability of your applications.
      </p><br />
   


      <h3 style={{paddingBottom:"6px"}}>Learning Objectives</h3>
      <ul>
        <li>Understand the role of the dependency injection container in ASP.NET Core.</li><br />
        <li>Learn advanced techniques like scoped and transient lifetimes.</li><br />
        <li>Explore factory-based and generic injection scenarios.</li><br />
        <li>Implement conditional service resolution and named services.</li><br />
        <li>Use DI effectively in middleware and controllers.</li>
      </ul><br />

      <h3 style={{paddingBottom:"6px"}}>Key Topics</h3>

      <h4>1. Recap of Dependency Injection</h4>
      <ul>
        <li>What is Dependency Injection?</li><br />
        <li>The built-in ASP.NET Core DI container.</li><br />
        <li>Service lifetimes: Singleton, Scoped, and Transient.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>2. Advanced Lifetime Management</h4>
      <ul>
        <li>Proper use of scoped services in middleware.</li><br />
        <li>Managing transient service dependencies.</li><br />
        <li>Injecting services into hosted services.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>3. Factory-based DI</h4>
      <ul>
        <li>Using factories to create services dynamically.</li><br />
        <li>Registering factory delegates in the DI container.</li><br />
        <li>Example: Creating services based on runtime configuration.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>4. Conditional Service Resolution</h4>
      <ul>
        <li>Resolving services conditionally based on custom logic.</li><br />
        <li>Implementing named service registrations.</li><br />
        <li>Example: Injecting different implementations based on a flag.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>5. Dependency Injection in Middleware</h4>
      <ul>
        <li>Using constructor injection in custom middleware.</li><br />
        <li>Resolving scoped services in middleware components.</li><br />
        <li>Best practices for DI in the request pipeline.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>6. Generic Dependency Injection</h4>
      <ul>
        <li>Registering and resolving open generic types.</li><br />
        <li>Examples of generic interfaces and classes.</li><br />
        <li>Best practices for maintaining flexibility with generics.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>7. Practical Examples</h4>
      <ul>
        <li>Implementing a custom logging service using named dependencies.</li><br />
        <li>Creating dynamic service factories for multi-tenant applications.</li><br />
        <li>Injecting services into controllers and services effectively.</li>
      </ul><br />
   
      <h3>Hands-On Exercise</h3>
      <p>
        <strong>Objective:</strong> Implement advanced DI techniques such as factory-based 
        service registration and conditional resolution.
      </p>
      <ol>
        <li>Register services with different lifetimes (scoped, transient).</li><br />
        <li>Implement a factory for dynamically resolving services based on configuration.</li><br />
        <li>Create a middleware that uses DI effectively to resolve scoped services.</li><br />
        <li>Test the application to ensure all scenarios work as expected.</li>
      </ol><br />

      <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
      <ul>
        <li>Keep service registrations organized and maintain consistency.</li><br />
        <li>Avoid overusing singletons for stateful operations.</li><br />
        <li>Use scopes carefully in middleware and background services.</li><br />
        <li>Document conditional logic in DI to ensure code clarity.</li>
      </ul>
    <br />

      <h3>Resources</h3>
      <ul>
        <li>
          <a
            href="https://learn.microsoft.com/en-us/aspnet/core/fundamentals/dependency-injection"
            target="_blank"
            rel="noopener noreferrer"
          >
            ASP.NET Core Dependency Injection Fundamentals
          </a>
        </li>
        <li>
          <a
            href="https://learn.microsoft.com/en-us/aspnet/core/fundamentals/middleware/write"
            target="_blank"
            rel="noopener noreferrer"
          >
            Writing Custom Middleware in ASP.NET Core
          </a>
        </li>
        <li>
          <a
            href="https://learn.microsoft.com/en-us/dotnet/core/extensions/dependency-injection"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dependency Injection in .NET Core
          </a>
        </li>
      </ul><br />
  
      <h3>Q&A Session</h3>
      <p>
        Open the floor for questions to clarify concepts and explore real-world dependency 
        injection challenges and solutions.
      </p>
 
  </div>
)}





{selectedChapter === 'chapter115' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Working with Distributed Transactions in ASP.NET Core  </h1>

 <p>
        This session focuses on handling distributed transactions in ASP.NET Core. Distributed 
        transactions are essential when coordinating operations across multiple resources, such as 
        databases, messaging systems, or external APIs, ensuring data consistency even in complex 
        environments.
      </p><br />
  
      <h3 style={{paddingBottom:"6px"}}>Learning Objectives</h3>
      <ul>
        <li>Understand the concept of distributed transactions and their importance.</li><br />
        <li>Learn about the challenges of implementing distributed transactions in microservices.</li><br />
        <li>Explore the role of transaction coordinators, like the two-phase commit protocol.</li><br />
        <li>Implement distributed transactions using technologies like `TransactionScope` and distributed message queues.</li><br />
        <li>Understand alternative approaches like the Saga pattern.</li>
      </ul><br />
    
      <h3 style={{paddingBottom:"6px"}}>Key Topics</h3>

      <h4 style={{paddingBottom:"6px"}}>1. Introduction to Distributed Transactions</h4>
      <ul>
        <li>Definition and scenarios where distributed transactions are needed.</li><br />
        <li>Challenges: network latency, failure handling, and coordination.</li><br />
        <li>Transaction coordinators and protocols (e.g., two-phase commit).</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>2. Using `TransactionScope` in ASP.NET Core</h4>
      <ul>
        <li>Overview of the `System.Transactions` namespace.</li><br />
        <li>Creating and managing a transaction using `TransactionScope`.</li><br />
        <li>Ensuring atomicity across multiple resource managers (e.g., databases).</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>3. Distributed Transactions with Microservices</h4>
      <ul>
        <li>Why distributed transactions are complex in a microservices architecture.</li><br />
        <li>Role of message brokers (e.g., RabbitMQ, Kafka) in transaction coordination.</li><br />
        <li>Implementing eventual consistency with messaging patterns.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>4. Implementing the Saga Pattern</h4>
      <ul>
        <li>Overview of the Saga pattern and its use in distributed systems.</li><br />
        <li>Orchestrated vs. Choreographed Sagas.</li><br />
        <li>Examples of compensating transactions for failure recovery.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>5. Best Practices for Distributed Transactions</h4>
      <ul>
        <li>Minimizing the use of distributed transactions to reduce complexity.</li><br />
        <li>Choosing consistency models: eventual vs. strong consistency.</li><br />
        <li>Monitoring and logging to detect and recover from failures.</li>
      </ul><br />
    
      <h3>Hands-On Exercise</h3>
      <p>
        <strong>Objective:</strong> Implement a distributed transaction in ASP.NET Core using 
        `TransactionScope` and explore an alternative solution with the Saga pattern.
      </p>
      <ol>
        <li>Create two database contexts in an ASP.NET Core application.</li><br />
        <li>Use `TransactionScope` to perform an atomic operation across both databases.</li><br />
        <li>Simulate a failure to observe rollback behavior.</li><br />
        <li>Implement the same scenario using the Saga pattern with compensating transactions.</li>
      </ol><br />
   
      <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
      <ul>
        <li>Use distributed transactions only when necessary; prefer eventual consistency where possible.</li><br />
        <li>Handle retries and idempotency to manage transient failures.</li><br />
        <li>Leverage transaction monitoring tools for better visibility and debugging.</li><br />
        <li>Ensure robust error handling and logging for compensating transactions.</li>
      </ul><br />
  
      <h3>Resources</h3>
      <ul>
        <li>
          <a
            href="https://learn.microsoft.com/en-us/dotnet/api/system.transactions.transactionscope"
            target="_blank"
            rel="noopener noreferrer"
          >
            Using TransactionScope in .NET
          </a>
        </li><br />
        <li>
          <a
            href="https://learn.microsoft.com/en-us/azure/service-bus-messaging/transactions-overview"
            target="_blank"
            rel="noopener noreferrer"
          >
            Transactions in Azure Service Bus
          </a>
        </li><br />
        <li>
          <a
            href="https://microservices.io/patterns/data/saga.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Saga Pattern for Microservices
          </a>
        </li>
      </ul><br />
   

      <h3>Q&A Session</h3>
      <p>
        This session concludes with a Q&A to address queries, clarify complex concepts, 
        and discuss practical challenges faced when implementing distributed transactions.
      </p>

  </div>
)}





    {selectedChapter === 'chapter116' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Repository and Unit of Work Pattern in ASP.NET Core</h1>
      <p>
        In this session, we explore the Repository and Unit of Work patterns and how they can be 
        effectively implemented in ASP.NET Core applications. These patterns help in organizing 
        data access logic, improving maintainability, and promoting a clean architecture.
      </p><br />
   
      <h3 style={{paddingBottom:"6px"}}>Learning Objectives</h3>
      <ul>
        <li>Understand the purpose and benefits of the Repository and Unit of Work patterns.</li><br />
        <li>Implement the Repository pattern for encapsulating data access logic.</li><br />
        <li>Use the Unit of Work pattern to manage transactional consistency.</li><br />
        <li>Integrate these patterns in an ASP.NET Core application with Entity Framework Core.</li><br />
        <li>Understand the trade-offs and limitations of these patterns.</li>
      </ul><br />
   
      <h3 style={{paddingBottom:"6px"}}>Key Topics</h3>

      <h4 style={{paddingBottom:"6px"}}>1. Overview of Repository Pattern</h4>
      <ul>
        <li>Definition and purpose of the Repository pattern.</li><br />
        <li>Abstracting data access logic from the business logic layer.</li><br />
        <li>Advantages: Testability, maintainability, and separation of concerns.</li><br />
        <li>Basic implementation of a generic repository interface.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>2. Overview of Unit of Work Pattern</h4>
      <ul>
        <li>Definition and role in managing transactional operations.</li><br />
        <li>Coordinating changes across multiple repositories.</li><br />
        <li>Benefits: Ensuring consistency and minimizing database calls.</li><br />
        <li>Implementing a Unit of Work interface in ASP.NET Core.</li>
      </ul><br />

      <h4>3. Implementing Repository and Unit of Work in ASP.NET Core</h4>
      <ul>
        <li>Creating a generic repository class for CRUD operations.</li><br />
        <li>Defining a Unit of Work interface and implementing it with EF Core's `DbContext`.</li><br />
        <li>Example: Managing data access for multiple entities (e.g., `Product`, `Category`).</li>
      </ul><br />

      <h4>4. Dependency Injection with Repository and Unit of Work</h4>
      <ul>
        <li>Registering repositories and Unit of Work in the ASP.NET Core DI container.</li><br />
        <li>Using repositories in controllers or services.</li><br />
        <li>Example: Injecting a Unit of Work into a service layer.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>5. Testing with Repository and Unit of Work</h4>
      <ul>
        <li>Mocking repositories for unit tests.</li><br />
        <li>Benefits of abstraction for testability.</li><br />
        <li>Example: Writing a test for a service that uses a repository.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>6. Alternatives and Trade-offs</h4>
      <ul>
        <li>When to use the Repository and Unit of Work patterns.</li><br />
        <li>Criticisms: Overhead and potential duplication of EF Core features.</li><br />
        <li>Alternative approaches: Direct use of `DbContext` with EF Core.</li>
      </ul><br />
    


      <h3>Hands-On Exercise</h3>
      <p>
        <strong>Objective:</strong> Implement the Repository and Unit of Work patterns in an ASP.NET Core 
        application with a sample `Product` and `Category` entity.
      </p>
      <ol>
        <li>Create a generic repository interface and class for common CRUD operations.</li><br />
        <li>Implement specific repositories for `Product` and `Category` entities.</li><br />
        <li>Develop a Unit of Work class to manage multiple repositories and transactional consistency.</li><br />
        <li>Inject and use the Unit of Work in a controller to handle CRUD operations.</li><br />
        <li>Test the implementation using unit tests with mocked repositories.</li>
      </ol><br />
    
      <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
      <ul>
        <li>Keep repository interfaces focused on entity-specific operations.</li><br />
        <li>Use Unit of Work to minimize direct calls to `DbContext` for better transactional consistency.</li><br />
        <li>Avoid unnecessary abstraction if EF Core's `DbContext` features meet your needs.</li><br />
        <li>Leverage Dependency Injection for better modularity and testability.</li>
      </ul><br />
    
      <h3>Resources</h3>
      <ul>
        <li>
          <a
            href="https://learn.microsoft.com/en-us/ef/core/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Entity Framework Core Documentation
          </a>
        </li>
        <li>
          <a
            href="https://www.c-sharpcorner.com/article/implementing-unit-of-work-and-repository-pattern-with-dependency-injection-in-asp-net-core/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unit of Work and Repository Pattern in ASP.NET Core
          </a>
        </li>
        <li>
          <a
            href="https://dotnetthoughts.net/repository-pattern-is-it-useful-with-ef-core/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Repository Pattern with EF Core
          </a>
        </li>
      </ul><br />
   
      <h3>Q&A Session</h3>
      <p>
        The session concludes with a Q&A to clarify concepts, discuss implementation challenges, 
        and explore real-world scenarios where these patterns can be applied effectively.
      </p>
   
  </div>
)}







{selectedChapter === 'chapter117' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Clean Architecture in ASP.NET Core Applications
    </h1>
    <div>
      <p>
        Clean Architecture, introduced by Robert C. Martin (Uncle Bob),
        emphasizes separating concerns to create maintainable, scalable, and
        testable applications. ASP.NET Core, with its modular and extensible
        design, is an ideal framework to implement this architecture.
      </p>

    <br />

      <h2>1. Principles of Clean Architecture</h2>
      <p>
        The core idea of Clean Architecture is the Dependency Rule:{' '}
        <strong>dependencies flow inward.</strong> Outer layers depend on inner
        layers, but inner layers have no knowledge of the outer layers.
      </p>
      <h3>Key Layers:</h3>
      <ul>
        <li>
          <strong>Entities</strong> (Core Domain):
          <ul>
            <li>Contains business logic and rules.</li><br />
            <li>Independent of external frameworks.</li>
          </ul>
        </li><br />
        <li>
          <strong>Use Cases</strong> (Application Layer):
          <ul>
            <li>Encapsulates application-specific business rules.</li><br />
            <li>Coordinates between entities and interfaces.</li>
          </ul>
        </li><br />
        <li>
          <strong>Interface Adapters</strong>:
          <ul>
            <li>
              Responsible for converting data between formats used in the
              application and external systems.
            </li>
          </ul>
        </li><br />
        <li>
          <strong>Frameworks and Drivers</strong> (Infrastructure):
          <ul>
            <li>Contains database, UI, APIs, and external dependencies.</li>
          </ul>
        </li>
      </ul>

  <br />

      <h2>2. Clean Architecture Structure in ASP.NET Core</h2>
      <p>
        A typical ASP.NET Core application implementing Clean Architecture
        consists of:
      </p>
      <pre>
        <code>
          {`- src/
  - Core/                     # Business logic
    - Entities/               
    - Interfaces/             
    - Specifications/         
  - Application/              # Use case logic
    - Features/               
    - Services/               
  - Infrastructure/           # External concerns
    - Persistence/            
    - ExternalAPIs/           
  - WebAPI/                   # Presentation layer
    - Controllers/            
    - Filters/                
- tests/                      # Unit and integration tests`}
        </code>
      </pre>

      <br />

      <h2 style={{paddingBottom:"6px"}}>3. Implementing Clean Architecture in ASP.NET Core</h2>

      <h3>3.1. Defining Core Entities</h3>
      <pre>
        <code>
          {`namespace Core.Entities
{
    public class Product
    {
        public int Id { get; set; }
        public string Name { get; set; }
        public decimal Price { get; set; }
    }
}`}
        </code>
      </pre><br />

      <h3>3.2. Application Layer Use Case</h3>
      <pre>
        <code>
          {`namespace Application.Features.Products
{
    public class GetProductsQuery
    {
        private readonly IProductRepository _repository;

        public GetProductsQuery(IProductRepository repository)
        {
            _repository = repository;
        }

        public async Task<IEnumerable<Product>> ExecuteAsync()
        {
            return await _repository.GetAllAsync();
        }
    }
}`}
        </code>
      </pre><br />

      <h3>3.3. Interface Adapters</h3>
      <pre>
        <code>
          {`namespace Core.Interfaces
{
    public interface IProductRepository
    {
        Task<IEnumerable<Product>> GetAllAsync();
        Task<Product> GetByIdAsync(int id);
    }
}`}
        </code>
      </pre><br />

      <h3>3.4. Infrastructure Layer</h3>
      <pre>
        <code>
          {`using Core.Entities;
using Core.Interfaces;
using Microsoft.EntityFrameworkCore;

namespace Infrastructure.Persistence
{
    public class ProductRepository : IProductRepository
    {
        private readonly AppDbContext _context;

        public ProductRepository(AppDbContext context)
        {
            _context = context;
        }

        public async Task<IEnumerable<Product>> GetAllAsync()
        {
            return await _context.Products.ToListAsync();
        }

        public async Task<Product> GetByIdAsync(int id)
        {
            return await _context.Products.FindAsync(id);
        }
    }
}`}
        </code>
      </pre><br />

      <h3>3.5. Presentation Layer (Web API)</h3>
      <pre>
        <code>
          {`using Application.Features.Products;
using Microsoft.AspNetCore.Mvc;

namespace WebAPI.Controllers
{
    [ApiController]
    [Route("api/[controller]")]
    public class ProductsController : ControllerBase
    {
        private readonly GetProductsQuery _query;

        public ProductsController(GetProductsQuery query)
        {
            _query = query;
        }

        [HttpGet]
        public async Task<IActionResult> GetAll()
        {
            var products = await _query.ExecuteAsync();
            return Ok(products);
        }
    }
}`}
        </code>
      </pre>

   <br />

      <h2>4. Benefits of Clean Architecture</h2>
      <ul>
        <li>Separation of Concerns: Each layer has a distinct responsibility.</li>
        <li>
          Testability: Business logic can be tested independently of external
          dependencies.
        </li><br />
        <li>
          Flexibility: Easily replace frameworks or libraries without impacting
          the core logic.
        </li>
        <li>
          Maintainability: Modular structure simplifies code updates and
          scalability.
        </li>
      </ul><br />




      <h2 style={{paddingBottom:"6px"}}>5. Challenges</h2>
      <ul>
        <li>Learning Curve: Requires understanding dependency inversion and separation of concerns.</li><br />
        <li>
          Initial Complexity: Setting up the layers can feel excessive for
          small applications.
        </li>
      </ul>

     <br />

      <h2>6. Conclusion</h2>
      <p>
        Clean Architecture ensures a robust design by enforcing dependency
        inversion and separating responsibilities. In ASP.NET Core
        applications, it helps build scalable, maintainable, and testable
        systems, making it a valuable approach for modern software development.
      </p>
    </div>
  </div>
)}

{selectedChapter === 'chapter118' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Implementing CQRS and Mediator Pattern in ASP.NET Core
    </h1>
    <p>
      CQRS (Command Query Responsibility Segregation) and the Mediator pattern
      are powerful design patterns commonly used in ASP.NET Core applications
      to improve scalability, maintainability, and separation of concerns.
      Together, they enable clear and structured application flow by isolating
      read and write operations and decoupling components.
    </p>
<br />

    <h2>1. What is CQRS?</h2>
    <p>
      CQRS separates the responsibility for reading and writing data.
      <ul>
        <li><strong>Command:</strong> Handles data modifications (e.g., Create, Update, Delete).</li><br />
        <li><strong>Query:</strong> Handles data retrieval (e.g., Fetch data).</li>
      </ul><br />
    </p>
    <p><strong>Benefits of CQRS:</strong></p>
    <ul>
      <li>Simplifies read/write operations.</li><br />
      <li>Enables independent scaling for read and write operations.</li><br />
      <li>Improves code organization and separation of concerns.</li>
    </ul>

  <br />

    <h2>2. What is the Mediator Pattern?</h2>
    <p>
      The Mediator pattern centralizes communication between components,
      reducing dependencies. In ASP.NET Core, the <strong>MediatR</strong>{" "}
      library is widely used to implement the Mediator pattern.
    </p>
    <p><strong>Benefits of the Mediator Pattern:</strong></p>
    <ul>
      <li>Simplifies communication between components.</li><br />
      <li>Improves testability by centralizing business logic.</li><br />
      <li>Promotes single-responsibility by delegating tasks to specific handlers.</li>
    </ul>
<br />

    <h2 style={{paddingBottom:"6px"}}>3. Implementing CQRS and Mediator Pattern in ASP.NET Core</h2>
    <h3>3.1. Setting Up the Project</h3>
    <p>
      Create a new ASP.NET Core project with the following structure:
      <pre>
        {`- src/
  - Application/             # CQRS Handlers
    - Commands/
    - Queries/
  - Infrastructure/          # Persistence and external dependencies
  - WebAPI/                  # Controllers`}
      </pre>
    </p>
    <p>
      Install the <strong>MediatR</strong> and{" "}
      <strong>MediatR.Extensions.Microsoft.DependencyInjection</strong>{" "}
      packages:
    </p>
    <pre>
      {`dotnet add package MediatR
dotnet add package MediatR.Extensions.Microsoft.DependencyInjection`}
    </pre><br />

    <h3>3.2. Adding a CQRS Command</h3>
    <p><strong>Command Class (Write Operation):</strong></p>
    <pre>
      {`namespace Application.Commands
{
    public class CreateProductCommand : IRequest<int>
    {
        public string Name { get; set; }
        public decimal Price { get; set; }
    }
}`}
    </pre>
    <p><strong>Command Handler:</strong></p>
    <pre>
      {`using MediatR;
using Infrastructure.Persistence;
using Core.Entities;

namespace Application.Commands
{
    public class CreateProductCommandHandler : IRequestHandler<CreateProductCommand, int>
    {
        private readonly AppDbContext _context;

        public CreateProductCommandHandler(AppDbContext context)
        {
            _context = context;
        }

        public async Task<int> Handle(CreateProductCommand request, CancellationToken cancellationToken)
        {
            var product = new Product
            {
                Name = request.Name,
                Price = request.Price
            };

            _context.Products.Add(product);
            await _context.SaveChangesAsync(cancellationToken);

            return product.Id;
        }
    }
}`}
    </pre>

  </div>
)}

{selectedChapter === 'chapter119' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>SOLID Principles in ASP.NET Core</h1>


      <p>
        The <strong>SOLID</strong> principles are fundamental design guidelines that help developers create robust, scalable, and maintainable software systems. ASP.NET Core, being a modern web framework, provides ample opportunities to apply these principles effectively. Each principle addresses a specific problem in software design, ensuring better separation of concerns and reducing dependencies.
      </p><br />
  

      <h2>1. Single Responsibility Principle (SRP)</h2>
      <p>A class should have only one reason to change. In other words, a class should only focus on a single responsibility.</p>
      <h3>Bad Practice:</h3>
      <pre style={{paddingBottom:"6px"}}>
        <code>
{`public class ProductService
{
    public void AddProduct(Product product)
    {
        // Business logic
        if (string.IsNullOrEmpty(product.Name))
        {
            throw new ArgumentException("Product name is required");
        }

        // Data access logic
        using var context = new AppDbContext();
        context.Products.Add(product);
        context.SaveChanges();
    }
}`}
        </code>
      </pre>

      <h3>Better Practice:</h3>
      <pre>
        <code>
{`public class ProductValidator
{
    public void Validate(Product product)
    {
        if (string.IsNullOrEmpty(product.Name))
        {
            throw new ArgumentException("Product name is required");
        }
    }
}

public class ProductRepository
{
    private readonly AppDbContext _context;

    public ProductRepository(AppDbContext context)
    {
        _context = context;
    }

    public void Add(Product product)
    {
        _context.Products.Add(product);
        _context.SaveChanges();
    }
}

public class ProductService
{
    private readonly ProductValidator _validator;
    private readonly ProductRepository _repository;

    public ProductService(ProductValidator validator, ProductRepository repository)
    {
        _validator = validator;
        _repository = repository;
    }

    public void AddProduct(Product product)
    {
        _validator.Validate(product);
        _repository.Add(product);
    }
}`}
        </code>
      </pre><br />
    

      <h2>2. Open-Closed Principle (OCP)</h2>
      <p>
        A class should be open for extension but closed for modification. You should be able to add new functionality without altering existing code.
      </p>
      <h3>Bad Practice:</h3>
      <pre style={{paddingBottom:"6px"}}>
        <code>
{`public class DiscountService
{
    public decimal CalculateDiscount(string customerType, decimal amount)
    {
        if (customerType == "Regular")
            return amount * 0.1m;

        if (customerType == "VIP")
            return amount * 0.2m;

        return 0;
    }
}`}
        </code>
      </pre>

      <h3>Better Practice:</h3>
      <pre>
        <code>
{`public interface IDiscountStrategy
{
    decimal CalculateDiscount(decimal amount);
}

public class RegularCustomerDiscount : IDiscountStrategy
{
    public decimal CalculateDiscount(decimal amount) => amount * 0.1m;
}

public class VipCustomerDiscount : IDiscountStrategy
{
    public decimal CalculateDiscount(decimal amount) => amount * 0.2m;
}

public class DiscountService
{
    private readonly IEnumerable<IDiscountStrategy> _strategies;

    public DiscountService(IEnumerable<IDiscountStrategy> strategies)
    {
        _strategies = strategies;
    }

    public decimal GetDiscount<T>(decimal amount) where T : IDiscountStrategy
    {
        var strategy = _strategies.FirstOrDefault(s => s is T);
        return strategy?.CalculateDiscount(amount) ?? 0;
    }
}`}
        </code>
      </pre><br />
    


      <h2>Conclusion</h2>
      <p>
        By applying the <strong>SOLID</strong> principles in your ASP.NET Core applications, you can achieve a clean, modular, and maintainable codebase. These principles promote separation of concerns, decoupling, and scalability, ensuring your software stands the test of time.
      </p>
  
  </div>
)}


{selectedChapter === 'chapter120' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Exploring Popular ASP.NET Core Extensions
    </h1>

    <p>
      Here’s a comprehensive explanation of <strong>Exploring Popular ASP.NET Core Extensions</strong>, focusing on tools like <strong>Serilog</strong> and <strong>AutoMapper</strong> for your topic <strong>"Recommended Tools and Libraries in ASP.NET."</strong>
    </p><br />

      <h2 style={{paddingBottom:"6px"}}>1. Serilog: Structured Logging Made Easy</h2>

      <h3>Overview</h3>
      <p>
        Serilog is a structured logging library that allows developers to log contextual and meaningful data. It provides integration with ASP.NET Core and supports a wide range of sinks (e.g., console, file, database).
      </p><br />

      <h3 style={{paddingBottom:"6px"}}>Why Use Serilog?</h3>
      <ul>
        <li>Easy setup and configuration.</li><br />
        <li>Structured logging with property-value pairs for better querying and analytics.</li><br />
        <li>Multiple output sinks supported.</li><br />
        <li>Extensible with custom enrichers and sinks.</li>
      </ul><br />

      <h3>Getting Started with Serilog</h3>
      <ol>
        <li>
          <strong>Add the Serilog NuGet packages:</strong>
          <pre>
            <code>
              dotnet add package Serilog.AspNetCore
              <br />
              dotnet add package Serilog.Sinks.Console
            </code>
          </pre>
        </li><br />
        <li>
          <strong>Configure Serilog in `Program.cs`:</strong>
          <pre>
            <code>
              {`using Serilog;

var builder = WebApplication.CreateBuilder(args);

// Configure Serilog
Log.Logger = new LoggerConfiguration()
    .WriteTo.Console()
    .Enrich.FromLogContext()
    .CreateLogger();

builder.Host.UseSerilog();

var app = builder.Build();

app.MapGet("/", () =>
{
    Log.Information("Processing a request at {Time}", DateTime.UtcNow);
    return "Hello, World!";
});

app.Run();`}
            </code>
          </pre>
        </li>
      </ol><br />

      <h3 style={{paddingBottom:"6px"}}>Key Features of Serilog</h3>
      <ul>
        <li>
          <strong>Log Enrichers:</strong> Add additional data to logs (e.g., machine name, thread ID).
        </li><br />
        <li>
          <strong>Custom Sinks:</strong> Write logs to specialized outputs (e.g., Elasticsearch, Seq, etc.).
        </li><br />
        <li>
          <strong>Structured Queries:</strong> Enables powerful filtering and analysis of log data.
        </li>
      </ul><br />
  
      <h2 style={{paddingBottom:"6px"}}>2. AutoMapper: Simplifying Object-to-Object Mapping</h2>

      <h3>Overview</h3>
      <p>
        AutoMapper is a library for mapping objects in a consistent, automated manner. It eliminates the need for repetitive mapping code when transforming data between layers (e.g., DTOs and entities).
      </p><br />

      <h3 style={{paddingBottom:"6px"}}>Why Use AutoMapper?</h3>
      <ul>
        <li>Reduces boilerplate code for property mappings.</li><br />
        <li>Ensures consistency in mapping.</li><br />
        <li>Supports complex mappings, including nested objects.</li>
      </ul><br />

      <h3>Getting Started with AutoMapper</h3>
      <ol>
        <li>
          <strong>Add the AutoMapper NuGet package:</strong>
          <pre>
            <code>dotnet add package AutoMapper.Extensions.Microsoft.DependencyInjection</code>
          </pre>
        </li><br />
        <li>
          <strong>Configure AutoMapper in `Program.cs`:</strong>
          <pre>
            <code>
              {`using AutoMapper;

var builder = WebApplication.CreateBuilder(args);

// Add AutoMapper
builder.Services.AddAutoMapper(typeof(Program));

var app = builder.Build();

app.MapGet("/", (IMapper mapper) =>
{
    var user = new User { Id = 1, Name = "John Doe" };
    var userDto = mapper.Map<UserDto>(user);
    return userDto;
});

app.Run();

public class User
{
    public int Id { get; set; }
    public string Name { get; set; }
}

public class UserDto
{
    public int Id { get; set; }
    public string FullName { get; set; }
}

// AutoMapper Profile
public class MappingProfile : Profile
{
    public MappingProfile()
    {
        CreateMap<User, UserDto>()
            .ForMember(dest => dest.FullName, opt => opt.MapFrom(src => src.Name));
    }
}`}
            </code>
          </pre>
        </li>
      </ol><br />

      <h3>Key Features of AutoMapper</h3>
      <ul>
        <li>
          <strong>Custom Mapping:</strong> Customize how properties map between objects.
        </li><br />
        <li>
          <strong>Projection Support:</strong> Map directly to LINQ queries for optimized performance.
        </li><br />
        <li>
          <strong>Flattening/Nesting:</strong> Automatically handle nested or flattened object hierarchies.
        </li>
      </ul><br />
    
      <h2>Conclusion</h2>
      <p>
        Exploring and incorporating extensions like <strong>Serilog</strong> and <strong>AutoMapper</strong> can significantly improve your ASP.NET Core applications. These libraries not only enhance developer productivity but also help maintain clean, scalable, and efficient codebases.
      </p>
 
  </div>
)}


{selectedChapter === 'chapter121' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Productivity Tools for ASP.NET Core Developers
    </h1>
    <p>
      ASP.NET Core development can be significantly streamlined and enhanced with the right productivity tools. Tools like <strong>ReSharper</strong> and <strong>Visual Studio Extensions</strong> provide developers with features to boost code quality, speed, and efficiency. Below is a comprehensive guide to these tools and their benefits.
    </p>
<br />

    <h2 style={{paddingBottom:"6px"}}>1. ReSharper: A Developer's Power Tool</h2>
    <h3>Overview</h3>
    <p>
      ReSharper is a productivity extension for Visual Studio by JetBrains. It focuses on code quality and developer efficiency by providing robust features such as code analysis, refactoring, and navigation.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Features</h4>
    <ul>
      <li><strong>Code Analysis:</strong> Highlights errors and suggests improvements in real-time.</li><br />
      <li><strong>Refactoring Support:</strong> Automates complex refactorings like renaming, extracting methods, and changing signatures.</li><br />
      <li><strong>Code Generation:</strong> Quickly generates boilerplate code such as properties, constructors, and overrides.</li><br />
      <li><strong>Navigation and Search:</strong> Easily find files, classes, or methods with advanced search capabilities.</li>
    </ul><br />

    <h4>Benefits</h4>
    <ul>
      <li>Improves code readability and maintainability.</li><br />
      <li>Reduces debugging time by catching potential issues early.</li><br />
      <li>Speeds up repetitive coding tasks.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>How to Get Started</h4>
    <ol>
      <li>Install ReSharper via the JetBrains website or Visual Studio Marketplace.</li><br />
      <li>Customize settings for coding style and error highlighting under <strong>ReSharper &gt; Options</strong>.</li><br />
      <li>Use keyboard shortcuts (e.g., <code>Alt + Enter</code>) to resolve issues or refactor code.</li>
    </ol>

   <br />

    <h2 style={{paddingBottom:"6px"}}>2. Visual Studio Extensions for ASP.NET Core Developers</h2>
    <h3>Overview</h3>
    <p>
      Visual Studio offers a range of extensions that integrate seamlessly into your development environment, enhancing productivity and functionality. Here are some of the must-have extensions:
    </p>

    <h4>a) Visual Studio IntelliCode</h4>
    <p>
      <strong>What It Does:</strong> Provides AI-assisted code suggestions based on common coding patterns.
    </p>
    <p><strong>Benefits:</strong> Speeds up coding by offering context-aware recommendations.</p><br />

    <h4>b) NuGet Package Manager</h4>
    <p>
      <strong>What It Does:</strong> Simplifies the management of third-party packages and libraries.
    </p>
    <p><strong>Benefits:</strong> Easy integration and updates for external dependencies.</p><br />

    <h4>c) GitHub Extension for Visual Studio</h4>
    <p>
      <strong>What It Does:</strong> Enables seamless GitHub integration for version control.
    </p>
    <p><strong>Benefits:</strong> Manage pull requests, branches, and commits directly within Visual Studio.</p><br />

    <h4>d) Live Share</h4>
    <p>
      <strong>What It Does:</strong> Facilitates real-time collaboration among developers.
    </p>
    <p><strong>Benefits:</strong> Share your code and debugging sessions without requiring additional setups.</p><br />

    <h4>e) Web Essentials</h4>
    <p>
      <strong>What It Does:</strong> Adds helpful web development features like bundling and minification.
    </p>
    <p><strong>Benefits:</strong> Improves front-end workflows in ASP.NET Core projects.</p>

<br />

    <h2>3. Other Notable Productivity Tools</h2>
    <h4>a) CodeRush</h4>
    <p>
      A Visual Studio extension by DevExpress focusing on code navigation, refactoring, and testing.
    </p>
    <ul>
      <li>Fast navigation between code files.</li><br />
      <li>Advanced refactoring options.</li><br />
      <li>Visualized code coverage for unit tests.</li>
    </ul><br />

    <h4>b) Postman</h4>
    <p>
      A tool for API testing and debugging. Simplifies RESTful API calls and enables automated test scripts with detailed reports.
    </p><br />

    <h4>c) LINQPad</h4>
    <p>
      A lightweight IDE for testing LINQ queries and .NET code. Instantly test and debug LINQ queries while exploring databases.
    </p><br />

    <h4>d) Azure Storage Explorer</h4>
    <p>
      A standalone tool to manage Azure storage accounts. Ideal for cloud-based ASP.NET Core applications.
    </p>

   <br />

    <h2>Conclusion</h2>
    <p>
      Incorporating tools like <strong>ReSharper</strong> and <strong>Visual Studio Extensions</strong> into your ASP.NET Core development process can drastically improve productivity, maintainability, and code quality. Whether you're debugging, refactoring, or collaborating with your team, these tools ensure a smoother and more efficient workflow.
    </p>
  </div>
)}


{selectedChapter === 'chapter122' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Debugging and Diagnostic Tools for ASP.NET Developers</h1>

    <p>
      Debugging and diagnosing issues are critical aspects of any development process. Fortunately, ASP.NET developers
      have access to a variety of powerful tools designed to help identify, troubleshoot, and resolve issues efficiently.
      Below is an overview of essential debugging and diagnostic tools in ASP.NET.
    </p><br />


      <h2 style={{paddingBottom:"6px"}}>1. Visual Studio Debugger</h2>
      <h3>Overview</h3>
      <p>
        The Visual Studio Debugger is one of the most powerful and widely used tools for debugging ASP.NET applications.
        It allows developers to set breakpoints, inspect variables, and step through code to identify and resolve issues.
      </p><br />

      <h4 style={{paddingBottom:"6px"}}>Key Features</h4>
      <ul>
        <li><strong>Breakpoints:</strong> Pause code execution at specific lines to examine program behavior.</li><br />
        <li><strong>Call Stack:</strong> Trace the sequence of method calls leading to an issue.</li><br />
        <li><strong>Watch Variables:</strong> Track and inspect variable values during code execution.</li><br />
        <li><strong>Immediate Window:</strong> Evaluate expressions and execute commands in real time during debugging.</li><br />
        <li><strong>Live Debugging:</strong> Debug applications running in production or remote environments.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>Benefits</h4>
      <ul>
        <li>Comprehensive error identification and real-time monitoring.</li><br />
        <li>Step-through functionality that aids in inspecting the flow of code execution.</li><br />
        <li>Enhanced bug diagnosis through detailed inspection of variables, call stacks, and execution flow.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>How to Get Started</h4>
      <ol>
        <li>Set breakpoints in the code at critical points where issues may arise.</li><br />
        <li>Run the application in debug mode (press `F5`).</li><br />
        <li>Use the Debug toolbar to step through code, inspect variables, and track execution flow.</li>
      </ol><br />
   
      <h2 style={{paddingBottom:"6px"}}>2. Application Insights</h2>
      <h3>Overview</h3>
      <p>
        Application Insights is a robust diagnostic and monitoring tool from Microsoft, integrated with Azure. It provides
        telemetry data such as performance metrics, exceptions, and user interactions to help developers monitor and diagnose
        application behavior.
      </p><br />

      <h4 style={{paddingBottom:"6px"}}>Key Features</h4>
      <ul>
        <li><strong>Performance Metrics:</strong> Track response times, request rates, and application performance.</li><br />
        <li><strong>Exception Tracking:</strong> Automatically capture and report exceptions to identify bugs.</li><br />
        <li><strong>Application Map:</strong> Visualize dependencies and interactions across application components.</li><br />
        <li><strong>Live Metrics Stream:</strong> View real-time performance metrics and logs.</li><br />
        <li><strong>Custom Telemetry:</strong> Collect and analyze custom events and metrics.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>Benefits</h4>
      <ul>
        <li>Real-time monitoring to track application health and performance.</li>
        <li>Deep insights into user behavior and error diagnostics.</li>
        <li>Quick identification of performance bottlenecks and issues.</li>
      </ul>

      <h4 style={{paddingBottom:"6px"}}>How to Get Started</h4>
      <ol>
        <li>Integrate the Application Insights SDK into your ASP.NET Core application.</li><br />
        <li>Configure telemetry collection in the Azure portal.</li><br />
        <li>Review collected data to analyze application health, user behavior, and errors.</li>
      </ol><br />

      <h2 style={{paddingBottom:"6px"}}>3. Debugging with Loggers (Serilog, NLog, Log4Net)</h2>
      <h3>Overview</h3>
      <p>
        Logging is an indispensable tool for diagnosing issues, especially in production environments. By logging application
        behavior, developers can gain insights into performance, exceptions, and system operations, aiding in quick issue
        resolution.
      </p><br />

      <h4 style={{paddingBottom:"6px"}}>Key Features</h4>
      <ul>
        <li><strong>Serilog:</strong> Structured logging with powerful querying and analysis features.</li><br />
        <li><strong>NLog:</strong> Flexible logging configuration with various output targets (files, databases, etc.).</li><br />
        <li><strong>Log4Net:</strong> A mature logging framework with extensive customization capabilities.</li><br />
        <li><strong>Log Levels:</strong> Categorize logs based on severity (e.g., Info, Warning, Error).</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>Benefits</h4>
      <ul>
        <li>Provides persistent logs that document application events and issues.</li><br />
        <li>Customizable log levels for granular control over log output.</li><br />
        <li>Helps diagnose production issues where traditional debugging is impractical.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>How to Get Started</h4>
      <ol>
        <li>Install your preferred logging framework (Serilog, NLog, Log4Net) via NuGet.</li><br />
        <li>Configure the logger in your `Startup.cs` or `Program.cs` file.</li><br />
        <li>Log critical information, exceptions, and other application events for monitoring and analysis.</li>
      </ol><br />

      <h2 style={{paddingBottom:"6px"}}>4. MiniProfiler</h2>
      <h3>Overview</h3>
      <p>
        MiniProfiler is a lightweight profiling tool that can be integrated into ASP.NET Core applications to track the
        performance of database queries, HTTP requests, and custom application operations.
      </p><br />

      <h4 style={{paddingBottom:"6px"}}>Key Features</h4>
      <ul>
        <li><strong>Database Profiling:</strong> Track and measure the time and details of SQL queries.</li><br />
        <li><strong>HTTP Request Profiling:</strong> Monitor the performance of HTTP requests, including their duration and response time.</li><br />
        <li><strong>Custom Timings:</strong> Add custom performance profiling for specific parts of your application.</li><br />
        <li><strong>Compact Interface:</strong> A simple, non-intrusive UI for monitoring performance metrics.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>Benefits</h4>
      <ul>
        <li>Identifies slow-performing database queries or HTTP requests.</li><br />
        <li>Helps diagnose performance bottlenecks in real-time.</li><br />
        <li>Lightweight, easy to integrate with minimal configuration.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>How to Get Started</h4>
      <ol>
        <li>Install MiniProfiler via NuGet in your ASP.NET Core project.</li><br />
        <li>Add the MiniProfiler middleware to your `Startup.cs` file.</li><br />
        <li>Use MiniProfiler to track performance metrics for database calls, HTTP requests, and custom operations.</li>
      </ol><br />

      <h2 style={{paddingBottom:"6px"}}>5. Fiddler</h2>
      <h3>Overview</h3>
      <p>
        Fiddler is a web debugging proxy that allows developers to monitor HTTP(S) traffic between their application and
        external services. It is especially useful for diagnosing issues related to HTTP requests, headers, or responses.
      </p><br />

      <h4 style={{paddingBottom:"6px"}}>Key Features</h4>
      <ul>
        <li><strong>Request and Response Inspection:</strong> Examine detailed information about HTTP requests and responses, including headers and bodies.</li><br />
        <li><strong>Session Timeline:</strong> Analyze the timing and order of requests and responses.</li><br />
        <li><strong>HTTPS Decryption:</strong> Inspect HTTPS traffic by decrypting SSL/TLS connections.</li><br />
        <li><strong>Breakpoints:</strong> Intercept and modify requests or responses before they reach the server or client.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>Benefits</h4>
      <ul>
        <li>Helps troubleshoot network-related issues, such as incorrect headers or response codes.</li><br />
        <li>Provides a detailed view of all network activity for better issue resolution.</li><br />
        <li>Useful for inspecting REST API calls, cookies, and request/response payloads.</li>
      </ul><br />

      <h4 style={{paddingBottom:"6px"}}>How to Get Started</h4>
      <ol>
        <li>Install Fiddler from the official website.</li><br />
        <li>Configure your application or browser to route traffic through Fiddler.</li><br />
        <li>Use the Fiddler UI to inspect, modify, or replay HTTP(S) requests and responses.</li>
      </ol>
  
  </div>
)}



{selectedChapter === 'chapter123' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Combining ASP.NET Core with Frontend Technologies (Angular, React, Vue)</h1>

      <p>
        To combine ASP.NET Core with popular frontend technologies like Angular, React, or Vue, you need to create an integrated architecture that allows seamless communication between the frontend and backend. Below is a guide to help you through this process:
      </p>

    <br />

      <h2 style={{paddingBottom:"6px"}}>Combining ASP.NET Core with Frontend Technologies (Angular, React, Vue)</h2>

      <h3>1. Setting Up the Backend: ASP.NET Core Web API</h3>

      <ul>
        <li>
          <strong>Create an ASP.NET Core Web API Project:</strong>
          Start by setting up an ASP.NET Core Web API project using Visual Studio or the .NET CLI. This will serve as your backend, exposing RESTful APIs to be consumed by the frontend application.
          <pre><code>dotnet new webapi -n FullStackApp
cd FullStackApp</code></pre>
        </li><br />

        <li>
          <strong>Set up Controllers and Actions:</strong>
          Define controllers and actions (GET, POST, PUT, DELETE) to manage resources like user data, products, etc.
          <pre><code>{`
[Route("api/[controller]")]
[ApiController]
public class ProductsController : ControllerBase
{ 
    private readonly IProductService _productService;
    
    public ProductsController(IProductService productService)
    { 
        _productService = productService; 
    }

    [HttpGet]
    public ActionResult<IEnumerable<Product>> GetAllProducts()
    { 
        var products = _productService.GetProducts(); 
        return Ok(products); 
    }
}
           `} </code></pre>
        </li><br />

        <li>
          <strong>Enable CORS (Cross-Origin Resource Sharing):</strong>
          For your frontend (running on a different port during development), configure CORS in `Startup.cs` or `Program.cs` to allow the frontend to access the backend.
          <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
    services.AddCors(options =>
    {
        options.AddPolicy("AllowAll", builder =>
            builder.AllowAnyOrigin()
                   .AllowAnyMethod()
                   .AllowAnyHeader());
    });
}

public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
    app.UseCors("AllowAll");
}
 `} </code></pre>
        </li>
      </ul><br />

      <h3 style={{paddingBottom:"6px"}}>2. Setting Up the Frontend</h3>

      <h4>Option 1: Angular</h4>
      <ul>
        <li>
          <strong>Create an Angular Project:</strong>
          Use Angular CLI to create a new Angular project.
          <pre><code>ng new frontend-angular
cd frontend-angular
ng serve</code></pre>
        </li>

        <li>
          <strong>Communicate with ASP.NET Core Backend:</strong>
          Use Angular’s `HttpClient` module to call the ASP.NET Core Web API.
          <pre><code>{`
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductService {
    private apiUrl = 'http://localhost:5000/api/products';

    constructor(private http: HttpClient) {}

    getProducts(): Observable<Product[]> {
        return this.http.get<Product[]>(this.apiUrl);
    }
}
          `}</code></pre>
        </li>
      </ul><br />

      <h4>Option 2: React</h4>
      <ul>
        <li>
          <strong>Create a React Project:</strong>
          Use Create React App to initialize a React application.
          <pre><code>npx create-react-app frontend-react
cd frontend-react
npm start</code></pre>
        </li><br />

        <li>
          <strong>Fetch Data from ASP.NET Core Backend:</strong>
          Use React's `fetch` or `axios` to retrieve data from the Web API.
          <pre><code>{`
import React, { useEffect, useState } from 'react';

const ProductList = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetch('http://localhost:5000/api/products')
            .then(response => response.json())
            .then(data => setProducts(data));
    }, []);

    return (
        <div>
            <h1>Product List</h1>
            <ul>
                {products.map(product => (
                    <li key={product.id}>{product.name}</li>
                ))}
            </ul>
        </div>
    );
};

export default ProductList;
 `} </code></pre>
        </li>
      </ul><br />

      <h4>Option 3: Vue.js</h4>
      <ul>
        <li>
          <strong>Create a Vue Project:</strong>
          Use Vue CLI to create a new Vue application.
          <pre><code>{`vue create frontend-vue
cd frontend-vue
npm run serve`}</code></pre>
        </li><br />

        <li>
          <strong>Fetch Data from ASP.NET Core Backend:</strong>
          Use Vue’s `axios` or `fetch` API to retrieve data from the ASP.NET Core API.
          <pre><code>{`
<template>
  <div>
    <h1>Product List</h1>
    <ul>
      <li v-for="product in products" :key="product.id">{{ product.name }}</li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            products: []
        };
    },
    mounted() {
        axios.get('http://localhost:5000/api/products')
            .then(response => {
                this.products = response.data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
};
</script>
`}</code></pre>
        </li>
      </ul><br />

      <h3>3. Integrating Frontend with Backend</h3>
      <ul>
        <li>
          <strong>Proxy Configuration for Development:</strong>
          During development, both the frontend and backend are likely to run on different ports (e.g., React on `http://localhost:3000` and ASP.NET Core on `http://localhost:5000`). To avoid CORS issues, configure the frontend project to proxy requests to the backend.
          
          For React, add the following in `package.json`:
          <pre><code>{`"proxy": "http://localhost:5000"`}</code></pre>

          For Angular, configure `angular.json`:
          <pre><code>{`
"proxyConfig": "proxy.conf.json"
          `}</code></pre>
          In `proxy.conf.json`:
          <pre><code>{`
{
    "/api": {
        "target": "http://localhost:5000",
        "secure": false
    }
}
`}</code></pre>
        </li>
      </ul><br />

      <h3>4. Deploying the Full-Stack Application</h3>
      <ul>
        <li>
          <strong>ASP.NET Core:</strong> Deploy the backend to a cloud service like Azure, AWS, or a private server.
        </li><br />
        <li>
          <strong>Frontend:</strong> Deploy the frontend to a hosting service like Vercel, Netlify, or on the same server as your backend.
        </li>
      </ul><br />

      <h3>5. Authentication and Authorization</h3>
      <ul>
        <li>
          <strong>JWT Authentication:</strong> Secure the communication between your frontend and backend by implementing JSON Web Tokens (JWT) for authentication.
        </li><br />
        <li>
          <strong>Backend:</strong> Use JWT authentication middleware in your ASP.NET Core Web API.
        </li><br />
        <li>
          <strong>Frontend:</strong> Store the JWT in localStorage or cookies and include it in the `Authorization` header for API requests.
        </li>
      </ul><br />

      <h3>Conclusion</h3>
      <p>
        Combining ASP.NET Core with frontend technologies like Angular, React, or Vue enables you to build dynamic, scalable, and maintainable full-stack applications. By following the outlined steps, you can create a seamless integration between your frontend and backend for a cohesive user experience.
      </p>
      
    </div>
  )
}


{selectedChapter === 'chapter124' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Building Full-Stack Applications with Blazor
    </h1>
    <p>
      Building full-stack applications with <strong>Blazor</strong> in ASP.NET Core 
      allows developers to create modern, interactive web applications using 
      C# for both client-side and server-side development. This approach 
      enables seamless integration between the front-end and back-end while 
      leveraging the rich ecosystem of .NET.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts for Full-Stack Applications with Blazor</h2>
    <ol>
      <li>
        <strong>Blazor Hosting Models:</strong>
        <ul>
          <li>
            <strong>Blazor Server:</strong> Runs the application logic on the 
            server, using SignalR to communicate with the client.
          </li><br />
          <li>
            <strong>Blazor WebAssembly:</strong> Runs the application logic 
            entirely on the client using WebAssembly.
          </li>
        </ul>
      </li><br />
      <li>
        <strong>Components:</strong>
        <ul>
          <li>Reusable Components: Build UI pieces as components using Razor syntax (HTML + C#).</li><br />
          <li>Binding and Events: Use data binding (<code>@bind</code>) and event handling (<code>@on...</code>) for interactivity.</li>
        </ul>
      </li><br />
      <li>
        <strong>Data Access:</strong> Use <strong>Entity Framework Core</strong> for database operations. Create APIs with 
        <strong>ASP.NET Core Web API</strong> or directly access services in Blazor Server apps.
      </li><br />
      <li>
        <strong>Routing:</strong> Define routes using the <code>@page</code> directive. Pass parameters through URL or query strings.
      </li><br />
      <li>
        <strong>Dependency Injection:</strong> Use the built-in DI container to manage services like logging, HTTP clients, and custom services.
      </li><br />
      <li>
        <strong>State Management:</strong> 
        <ul>
          <li>Use state containers, local storage, or session storage to manage data in WebAssembly.</li><br />
          <li>In Blazor Server, manage state carefully to avoid performance issues.</li>
        </ul>
      </li><br />
      <li>
        <strong>Authentication and Authorization:</strong> Use ASP.NET Core Identity or external providers (Google, Facebook, etc.). Protect routes using <code>[Authorize]</code> and policies.
      </li><br />
      <li>
        <strong>Communication with APIs:</strong> Use <code>HttpClient</code> to interact with REST APIs. Use gRPC or SignalR for real-time features.
      </li><br />
      <li>
        <strong>Testing:</strong> Write unit tests for components using tools like <code>bUnit</code>. Test APIs with integration testing frameworks.
      </li>
    </ol><br />

    <h2>Steps to Build a Full-Stack Application with Blazor</h2>
    <ol>
      <li>
        <strong>Set Up the Project:</strong>
        <pre>
          <code>
dotnet new blazorserver -n BlazorFullStackApp
dotnet new blazorwasm -n BlazorFullStackApp
          </code>
        </pre>
      </li><br />
      <li>
        <strong>Design the UI:</strong> Create reusable components for the UI in the <code>Pages</code> folder.
        <pre>
          <code>{`
@page "/products"
@inject HttpClient Http

<h3>Product List</h3>
@if (products == null)
{
  <p>Loading...</p>
}
else
{
  <ul>
    @foreach (var product in products)
    {
      <li>@product.Name - @product.Price</li>
    }
  </ul>
}

@code {
  private List<Product> products;

  protected override async Task OnInitializedAsync()
  {
    products = await Http.GetFromJsonAsync&lt;List&lt;Product&gt;&gt;("api/products");
  }
}
           `} </code>
        </pre>
      </li><br />
      <li>
        <strong>Create the API:</strong> Add a Web API controller in the <code>Controllers</code> folder.
      </li><br />
      <li>
        <strong>Set Up Data Access:</strong> Use Entity Framework Core to interact with the database. Configure the 
        <code>ApplicationDbContext</code> in <code>Startup.cs</code> or <code>Program.cs</code>.
      </li><br />
      <li>
        <strong>Integrate the Frontend and Backend:</strong> Configure the base address for the API in Blazor WebAssembly or directly interact with services in Blazor Server.
      </li><br />
      <li>
        <strong>Handle Authentication and Authorization:</strong> Use ASP.NET Core Identity or other authentication schemes. Protect routes using the <code>AuthorizeView</code> component.
      </li><br />
      <li>
        <strong>Deploy the Application:</strong> Deploy Blazor WebAssembly as static files to platforms like Azure Blob Storage or AWS S3. For Blazor Server, host on IIS, Azure App Services, or Kubernetes.
      </li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Advantages of Blazor Full-Stack Development</h2>
    <ul>
      <li>Single language (C#) for front-end and back-end.</li><br />
      <li>Rich component-based architecture.</li><br />
      <li>Integration with existing .NET libraries and tools.</li><br />
      <li>Support for real-time updates with SignalR.</li><br />
      <li>Scalability for server-side and client-side apps.</li>
    </ul><br />

    <p>
      By following this approach, you can build powerful and scalable full-stack applications using Blazor in ASP.NET Core.
    </p>
  </div>
)}



{selectedChapter === 'chapter125' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      RESTful API + Frontend Framework Integration
    </h1>

    <p>
      For the subject <strong>"Building a Full-Stack Application"</strong> and the title <strong>"RESTful API + Frontend Framework Integration"</strong>, here's a detailed breakdown to guide the creation of a course or tutorial focused on integrating a RESTful API with frontend frameworks like React, Angular, or Vue.js.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Introduction</h3>
    <ul>
      <li><strong>What is a Full-Stack Application?</strong> A full-stack application integrates both frontend (client-side) and backend (server-side), connecting frameworks like React, Angular, or Vue.js with a backend API built using technologies like Node.js, Python (Flask/Django), or ASP.NET Core.</li><br />
      <li><strong>Understanding RESTful APIs</strong> A RESTful API (Representational State Transfer) is an architectural style for building web services over HTTP, using standard HTTP methods (GET, POST, PUT, DELETE) for CRUD operations.</li><br />
      <li><strong>The Role of a Frontend Framework</strong> Frontend frameworks (React, Angular, Vue.js) help build dynamic, interactive UIs that interact with backend APIs to fetch or send data, enabling client-side updates without full page reloads.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts to Cover</h3>

    <h4>1. Setting up the RESTful API Backend</h4>
    <ul>
      <li><strong>Backend Framework Options</strong>
        <ul>
          <li><strong>Node.js with Express</strong>: A fast and popular option for creating RESTful APIs.</li><br />
          <li><strong>ASP.NET Core Web API</strong>: A scalable and high-performance choice within the .NET ecosystem.</li><br />
          <li><strong>Python with Flask or Django</strong>: Simple to set up and ideal for quick development.</li>
        </ul><br />
      </li>
      <li><strong>Creating CRUD Operations</strong>: Setting up basic CRUD routes for the API using POST, GET, PUT, DELETE HTTP methods.</li>
    </ul><br />

    <h5>Example in Node.js with Express</h5>
    <pre>
      <code>
{`const express = require('express');
const app = express();
app.use(express.json());

let items = [];  // This will be your database

// Create item
app.post('/api/items', (req, res) => {
  const item = req.body;
  items.push(item);
  res.status(201).send(item);
});

// Read all items
app.get('/api/items', (req, res) => {
  res.status(200).send(items);
});

// Update item
app.put('/api/items/:id', (req, res) => {
  const itemId = req.params.id;
  const updatedItem = req.body;
  items = items.map(item => item.id === itemId ? updatedItem : item);
  res.status(200).send(updatedItem);
});

// Delete item
app.delete('/api/items/:id', (req, res) => {
  const itemId = req.params.id;
  items = items.filter(item => item.id !== itemId);
  res.status(204).send();
});

app.listen(3000, () => {
  console.log('Server is running on port 3000');
});`}
      </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>2. Creating the Frontend (React, Vue, or Angular)</h4>
    <ul>
      <li><strong>Setting up the Frontend</strong>: Use a frontend framework like React and create a new React app with <code>create-react-app</code>.</li><br />
      <li><strong>Using Axios or Fetch API to Communicate with the Backend</strong>: Demonstrate how to use Axios or native Fetch to send HTTP requests from the frontend to the backend API.</li>
    </ul><br />

    <h5>Example in React using Axios</h5>
    <pre>
      <code>
{`import React, { useState, useEffect } from 'react';
import axios from 'axios';

const App = () => {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  useEffect(() => {
    axios.get('http://localhost:3000/api/items')
      .then(response => setItems(response.data))
      .catch(error => console.error(error));
  }, []);

  const addItem = () => {
    axios.post('http://localhost:3000/api/items', { name: newItem })
      .then(response => setItems([...items, response.data]))
      .catch(error => console.error(error));
  };

  return (
    <div>
      <h1>Items List</h1>
      <ul>
        {items.map(item => <li key={item.id}>{item.name}</li>)}
      </ul>
      <input
        type="text"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
      />
      <button onClick={addItem}>Add Item</button>
    </div>
  );
};

export default App;`}
      </code>
    </pre><br />

    <h4>3. Authentication and Authorization</h4>
    <ul>
      <li><strong>JWT Authentication</strong>: Use JWT (JSON Web Tokens) to secure API endpoints. JWT enables the server to authenticate and authorize requests from the frontend.</li>
    </ul><br />

    <h5>Example in Node.js for Generating and Verifying JWT</h5>
    <pre>
      <code>
{`const jwt = require('jsonwebtoken');
const secret = 'your_jwt_secret';

// Generate token on login
const token = jwt.sign({ userId: user.id }, secret, { expiresIn: '1h' });

// Middleware to protect routes
const authenticateJWT = (req, res, next) => {
  const token = req.header('Authorization')?.split(' ')[1];
  if (!token) return res.status(403).send('Access denied');

  jwt.verify(token, secret, (err, user) => {
    if (err) return res.status(403).send('Access denied');
    req.user = user;
    next();
  });
};

// Protect routes
app.get('/api/protected', authenticateJWT, (req, res) => {
  res.send('This is a protected route');
});`}
      </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>4. Error Handling and Debugging</h4>
    <ul>
      <li><strong>Frontend Error Handling</strong>: Handle errors (network issues, 404s, 500s) gracefully in the UI using <code>try-catch</code> or <code>.catch()</code> in Axios.</li><br />
      <li><strong>Backend Error Handling</strong>: Implement error handling in the API with <code>try-catch</code> blocks and return relevant HTTP status codes and messages.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>5. Deployment and Hosting</h4>
    <ul>
      <li><strong>Backend Deployment</strong>: Deploy the API to services like Heroku, AWS EC2, or DigitalOcean.</li><br />
      <li><strong>Frontend Deployment</strong>: Deploy the React app to Netlify, Vercel, or GitHub Pages.</li><br />
      <li><strong>Environment Variables</strong>: Manage environment variables for different deployment environments (development, production).</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>6. Full-Stack Integration Example</h4>
    <ul>
      <li><strong>Frontend Requests</strong>: Use the frontend to interact with the backend for reading, creating, updating, and deleting data.</li><br />
      <li><strong>End-to-End Flow</strong>: Show the full flow, from frontend interaction to backend handling and returning data.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Conclusion</h3>
    <ul>
      <li><strong>Benefits of Full-Stack Development</strong>: Full-stack development allows for complete control over both client and server-side logic, improving integration and performance.</li><br />
      <li><strong>The Power of RESTful APIs</strong>: REST APIs offer a simple, standardized way for frontend and backend to communicate.</li><br />
      <li><strong>Expanding Further</strong>: Encourage learners to explore adding more complex features, such as WebSockets, advanced API endpoints, and state management solutions like Redux or Vuex.</li>
    </ul><br />

    <p>
      This outline provides a structured approach to building a full-stack application that integrates RESTful APIs with a frontend framework.
    </p>
  </div>
)}


{selectedChapter === 'chapter126' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Introduction to Microservices with ASP.NET Core
    </h1>
    
    <p>
      Microservices architecture has revolutionized software development by breaking down monolithic applications into smaller, independently deployable services. ASP.NET Core provides a robust framework for building microservices, enabling developers to leverage modern tools and practices for scalability, maintainability, and rapid deployment.
    </p>

    <br />

    <h2>What Are Microservices?</h2>
    <p>
      Microservices are an architectural style that structures an application as a collection of small, autonomous services modeled around a business domain. Each service can be developed, deployed, and scaled independently, using its own database, technology stack, and deployment lifecycle.
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of Microservices</h2>
    <ul>
      <li><strong>Scalability:</strong> Each service can scale independently based on demand.</li><br />
      <li><strong>Flexibility:</strong> Teams can use different technologies for different services.</li><br />
      <li><strong>Resilience:</strong> Failure in one service does not affect the entire system.</li><br />
      <li><strong>Faster Development:</strong> Smaller teams can focus on specific services, accelerating development.</li>
    </ul>

  <br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of ASP.NET Core for Microservices</h2>
    <ul>
      <li><strong>Cross-Platform Support:</strong> Build and deploy services on Windows, Linux, or macOS.</li><br />
      <li><strong>Lightweight Runtime:</strong> Optimized for performance with a minimal footprint.</li><br />
      <li><strong>Dependency Injection:</strong> Built-in support for managing dependencies in services.</li><br />
      <li><strong>API Development:</strong> Simplifies creating RESTful services with rich HTTP support.</li><br />
      <li><strong>Docker Integration:</strong> Easily containerize and deploy services using Docker.</li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Building Microservices with ASP.NET Core</h2>

    <h3>Step 1: Set Up the Project</h3>
    <pre>
      <code>
        dotnet new webapi -n ProductService
        cd ProductService
      </code>
    </pre><br />

    <h3>Step 2: Define the Data Model</h3>
    <pre>
      <code>
        {`// Models/Product.cs
public class Product
{
    public int Id { get; set; }
    public string Name { get; set; }
    public decimal Price { get; set; }
}`}
      </code>
    </pre><br />

    <h3>Step 3: Configure the Database Context</h3>
    <pre>
      <code>
        {`// Data/ApplicationDbContext.cs
public class ApplicationDbContext : DbContext
{
    public ApplicationDbContext(DbContextOptions<ApplicationDbContext> options) : base(options) { }
    public DbSet<Product> Products { get; set; }
}`}
      </code>
    </pre><br />

    <h3>Step 4: Create the RESTful API</h3>
    <pre>
      <code>
        {`// Controllers/ProductsController.cs
[ApiController]
[Route("api/[controller]")]
public class ProductsController : ControllerBase
{
    private readonly ApplicationDbContext _context;

    public ProductsController(ApplicationDbContext context)
    {
        _context = context;
    }

    [HttpGet]
    public async Task<IActionResult> GetProducts() =>
        Ok(await _context.Products.ToListAsync());

    [HttpPost]
    public async Task<IActionResult> AddProduct([FromBody] Product product)
    {
        _context.Products.Add(product);
        await _context.SaveChangesAsync();
        return CreatedAtAction(nameof(GetProducts), new { id = product.Id }, product);
    }
}`}
      </code>
    </pre><br />

    <h3>Step 5: Enable Service Communication</h3>
    <p>
      Use libraries like <strong>gRPC</strong>, <strong>RabbitMQ</strong>, or <strong>Azure Service Bus</strong> for inter-service communication.
    </p><br />

    <h3>Step 6: Deploy the Microservice</h3>
    <p>Containerize the service using Docker.</p>
    <pre>
      <code>
        {`docker build -t productservice .
docker run -d -p 8080:80 productservice`}
      </code>
    </pre>

    <br/>

    <h2>Deployment Options</h2>
    <ul>
      <li><strong>Kubernetes:</strong> Orchestrate microservices with automatic scaling and load balancing.</li><br />
      <li><strong>Azure Kubernetes Service (AKS):</strong> Deploy and manage microservices on the cloud.</li><br />
      <li><strong>Docker Swarm:</strong> Simple orchestration for Docker containers.</li>
    </ul>

    <br/>

    <h2>Monitoring and Observability</h2>
    <p>
      Implement tools like <strong>Prometheus</strong>, <strong>Grafana</strong>, or <strong>Azure Monitor</strong> to track the performance and health of microservices.
    </p>

  <br />

    <h2>Conclusion</h2>
    <p>
      ASP.NET Core provides a comprehensive ecosystem for developing microservices. By leveraging its features, developers can build efficient, scalable, and maintainable distributed systems. Combining ASP.NET Core with modern tools like Docker and Kubernetes enhances deployment flexibility, making it an ideal choice for microservices architecture.
    </p>
  </div>
)}

{selectedChapter === 'chapter127' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Building and Deploying Microservices with Docker
    </h1>

      <p>
        Microservices architecture benefits from containerization, enabling easy
        deployment, scalability, and portability. Docker is the industry-standard
        tool for creating, deploying, and running containerized applications.
        ASP.NET Core's lightweight and cross-platform capabilities make it an
        excellent choice for Docker-based microservices.
      </p>

      <h2 style={{paddingBottom:"6px"}}>Steps to Build and Deploy Microservices with Docker in ASP.NET Core</h2>

      <h3>1. Set Up the Microservice</h3>
      <p>Start by creating an ASP.NET Core Web API project for your microservice:</p>
      <pre>
        <code>
          dotnet new webapi -n OrderService
          <br />
          cd OrderService
        </code>
      </pre><br />

      <h3>2. Add Docker Support to the Project</h3>
      <ol>
        <li>
          <strong>Create a Dockerfile:</strong> The <code>Dockerfile</code> defines how to
          build the Docker image for your microservice.
          <pre>
            <code>
              FROM mcr.microsoft.com/dotnet/aspnet:6.0 AS base
              <br />
              WORKDIR /app
              <br />
              EXPOSE 80
              <br />
              EXPOSE 443
              <br />
              <br />
              FROM mcr.microsoft.com/dotnet/sdk:6.0 AS build
              <br />
              WORKDIR /src
              <br />
              COPY [OrderService/OrderService.csproj, ./]
              <br />
              RUN dotnet restore OrderService.csproj
              <br />
              COPY . .
              <br />
              WORKDIR /src/
              <br />
              RUN dotnet build OrderService.csproj -c Release -o /app/build
              <br />
              <br />
              FROM build AS publish
              <br />
              RUN dotnet publish OrderService.csproj -c Release -o /app/publish
              <br />
              <br />
              FROM base AS final
              <br />
              WORKDIR /app
              <br />
              COPY --from=publish /app/publish .
              <br />
              ENTRYPOINT [dotnet, OrderService.dll]
            </code>
          </pre>
        </li><br />
        <li>
          <strong>Add a .dockerignore file:</strong> Prevent unnecessary files from being
          copied into the Docker image.
          <pre>
            <code>
              bin/
              <br />
              obj/
              <br />
              *.user
              <br />
              *.vs
              <br />
              .git
              <br />
              .idea
            </code>
          </pre>
        </li>
      </ol><br />

      <h3>3. Build and Run the Docker Image</h3>
      <ol>
        <li>
          Build the Docker image:
          <pre>
            <code>docker build -t orderservice .</code>
          </pre>
        </li>
        <li>
          Run the Docker container:
          <pre>
            <code>docker run -d -p 8080:80 orderservice</code>
          </pre>
        </li>
        <li>Test the API by navigating to <code>http://localhost:8080</code>.</li>
      </ol><br />

      <h3>4. Compose Multiple Microservices</h3>
      <p>
        Use Docker Compose to manage multiple microservices. Create a{' '}
        <code>docker-compose.yml</code> file:
      </p>
      <pre>
        <code>
          version: '3.8'
          <br />
          services:
          <br />
          orderservice:
          <br />
          image: orderservice
          <br />
          build:
          <br />
          context: .
          <br />
          dockerfile: Dockerfile
          <br />
          ports:
          <br />
          - 5001:80
          <br />
          <br />
          paymentservice:
          <br />
          image: paymentservice
          <br />
          build:
          <br />
          context: ./PaymentService
          <br />
          dockerfile: Dockerfile
          <br />
          ports:
          <br />
          - 5002:80
        </code>
      </pre><br />

      <h3>5. Deploying to Production</h3>
      <p>Details about Kubernetes and AKS deployment go here...</p>

  </div>
)}


{selectedChapter === 'chapter128' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Communication Between Microservices (RabbitMQ, Kafka, gRPC) </h1>
    
    <p>
      Microservices often need to communicate with each other efficiently and reliably. 
      ASP.NET Core provides robust support for implementing communication using popular tools like 
      <strong>RabbitMQ</strong>, <strong>Kafka</strong>, and <strong>gRPC</strong>. 
      Each tool is suited for different scenarios depending on the application's needs, such as 
      event-driven communication, message queuing, or real-time service-to-service interaction.
    </p>

 <br />

    <h2>1. RabbitMQ for Message Queuing</h2>
    <p style={{paddingBottom:"6px"}}>RabbitMQ is a message broker that enables reliable message delivery between microservices.</p>

    <h3>Steps to Use RabbitMQ in ASP.NET Core</h3>
    <ol>
      <li>
        <strong>Add RabbitMQ NuGet Package:</strong>
        <pre><code>dotnet add package RabbitMQ.Client</code></pre>
      </li>
      <li>
        <strong>Set Up RabbitMQ Connection:</strong>
        <pre><code>{`
using RabbitMQ.Client;

var factory = new ConnectionFactory()
{
    HostName = "localhost"
};
using var connection = factory.CreateConnection();
using var channel = connection.CreateModel();
channel.QueueDeclare(queue: "order_queue",
                     durable: true,
                     exclusive: false,
                     autoDelete: false,
                     arguments: null);

var message = "Order Created!";
var body = Encoding.UTF8.GetBytes(message);

channel.BasicPublish(exchange: "",
                     routingKey: "order_queue",
                     basicProperties: null,
                     body: body);

Console.WriteLine("Message Sent: {0}", message);
        `}</code></pre>
      </li>
      <li>
        <strong>Consume Messages:</strong>
        <pre><code>{`
var consumer = new EventingBasicConsumer(channel);
consumer.Received += (model, ea) =>
{
    var body = ea.Body.ToArray();
    var message = Encoding.UTF8.GetString(body);
    Console.WriteLine("Message Received: {0}", message);
};
channel.BasicConsume(queue: "order_queue",
                     autoAck: true,
                     consumer: consumer);
        `}</code></pre>
      </li>
    </ol>
<br />

    <h2>2. Kafka for Event Streaming</h2>
    <p style={{paddingBottom:"6px"}}>Kafka is a distributed event streaming platform well-suited for real-time processing and scalable architectures.</p>

    <h3>Steps to Use Kafka in ASP.NET Core</h3>
    <ol>
      <li>
        <strong>Add Kafka NuGet Package:</strong>
        <pre><code>dotnet add package Confluent.Kafka</code></pre>
      </li>
      <li>
        <strong>Produce Events:</strong>
        <pre><code>{`
using Confluent.Kafka;

var config = new ProducerConfig { BootstrapServers = "localhost:9092" };

using var producer = new ProducerBuilder<Null, string>(config).Build();
try
{
    var deliveryReport = await producer.ProduceAsync("order-topic", new Message<Null, string>
    {
        Value = "Order Created!"
    });
    Console.WriteLine($"Delivered '{deliveryReport.Value}' to '{deliveryReport.TopicPartitionOffset}'");
}
catch (ProduceException<Null, string> ex)
{
    Console.WriteLine($"Delivery failed: {ex.Error.Reason}");
}
        `}</code></pre>
      </li>
      <li>
        <strong>Consume Events:</strong>
        <pre><code>{`
using var consumer = new ConsumerBuilder<Null, string>(new ConsumerConfig
{
    GroupId = "order-group",
    BootstrapServers = "localhost:9092",
    AutoOffsetReset = AutoOffsetReset.Earliest
}).Build();

consumer.Subscribe("order-topic");

while (true)
{
    var consumeResult = consumer.Consume();
    Console.WriteLine($"Message: {consumeResult.Message.Value}, Partition: {consumeResult.Partition}");
}
        `}</code></pre>
      </li>
    </ol>
<br />

    <h2>3. gRPC for Real-Time Communication</h2>
    <p style={{paddingBottom:"6px"}}>gRPC is an RPC framework that provides fast, lightweight, and efficient communication between microservices.</p>

    <h3>Steps to Use gRPC in ASP.NET Core</h3>
    <ol>
      <li>
        <strong>Add gRPC NuGet Package:</strong>
        <pre><code>dotnet add package Grpc.AspNetCore</code></pre>
      </li>
      <li>
        <strong>Create a gRPC Service:</strong>
        <pre><code>{`
syntax = "proto3";

service OrderService {
    rpc CreateOrder (OrderRequest) returns (OrderReply);
}

message OrderRequest {
    string orderId = 1;
}

message OrderReply {
    string confirmation = 1;
}
        `}</code></pre>
      </li><br />
      <li>
        <strong>Implement the Service:</strong>
        <pre><code>{`
public class OrderService : OrderService.OrderServiceBase
{
    public override Task<OrderReply> CreateOrder(OrderRequest request, ServerCallContext context)
    {
        return Task.FromResult(new OrderReply
        {
            Confirmation = $"Order {request.OrderId} has been created."
        });
    }
}
        `}</code></pre>
      </li><br />
      <li>
        <strong>Set Up the gRPC Server:</strong>
        <pre><code>{`
app.UseEndpoints(endpoints =>
{
    endpoints.MapGrpcService<OrderService>();
});
        `}</code></pre>
      </li><br />
      <li>
        <strong>Call the gRPC Service:</strong>
        <pre><code>{`
var channel = GrpcChannel.ForAddress("https://localhost:5001");
var client = new OrderService.OrderServiceClient(channel);

var reply = await client.CreateOrderAsync(new OrderRequest { OrderId = "12345" });
Console.WriteLine(reply.Confirmation);
        `}</code></pre>
      </li>
    </ol>

    <br />

    <h2>Choosing the Right Communication Method</h2>
    <table>
      <thead>
        <tr>
          <th>Tool</th>
          <th>Use Case</th>
          <th>Pros</th>
          <th>Cons</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>RabbitMQ</td>
          <td>Reliable message queuing</td>
          <td>Easy to set up, supports retries</td>
          <td>Requires broker setup</td>
        </tr>
        <tr>
          <td>Kafka</td>
          <td>Real-time event streaming</td>
          <td>High throughput, scalable</td>
          <td>More complex to configure</td>
        </tr>
        <tr>
          <td>gRPC</td>
          <td>Fast, real-time service communication</td>
          <td>Low latency, strong type safety</td>
          <td>May require more setup</td>
        </tr>
      </tbody>
    </table>
  </div>
)}


{selectedChapter === 'chapter129' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>API Gateway Integration</h1>
    <p>
      To integrate an API Gateway in ASP.NET Core, tools like <strong>Ocelot</strong> and <strong>YARP (Yet Another Reverse Proxy)</strong> are commonly used.
      These tools provide an easy way to route requests, manage services, and act as a reverse proxy for microservices. Here's a detailed guide on how to integrate both:
    </p><br />

    <h2>1. Using Ocelot API Gateway</h2>
    <p style={{paddingBottom:"6px"}}>
      Ocelot is a lightweight API Gateway built on .NET, designed for routing and proxying requests to microservices.
    </p>
    <h3>Steps to Use Ocelot API Gateway</h3>

    <ol>
      <li>
        <strong>Add Ocelot NuGet Package:</strong>
        Install the Ocelot package in your API Gateway project:
        <pre><code>dotnet add package Ocelot</code></pre>
      </li><br />

      <li>
        <strong>Create a <code>ocelot.json</code> Configuration File:</strong>
        Create a configuration file for Ocelot, <code>ocelot.json</code>, to define the routing rules:
        <pre><code>{`
{
  "ReRoutes": [
    {
      "DownstreamPathTemplate": "/api/products/{everything}",
      "DownstreamScheme": "http",
      "DownstreamHostAndPorts": [
        {
          "Host": "localhost",
          "Port": 5001
        }
      ],
      "UpstreamPathTemplate": "/gateway/products/{everything}",
      "UpstreamHttpMethod": ["GET"]
    },
    {
      "DownstreamPathTemplate": "/api/orders/{everything}",
      "DownstreamScheme": "http",
      "DownstreamHostAndPorts": [
        {
          "Host": "localhost",
          "Port": 5002
        }
      ],
      "UpstreamPathTemplate": "/gateway/orders/{everything}",
      "UpstreamHttpMethod": ["GET"]
    }
  ],
  "GlobalConfiguration": {
    "BaseUrl": "http://localhost:5000"
  }
}
 `} </code></pre>
        In this example:
        <ul>
          <li>Requests to <code>/gateway/products/*</code> are forwarded to <code>http://localhost:5001/api/products/*</code>.</li>
          <li>Requests to <code>/gateway/orders/*</code> are forwarded to <code>http://localhost:5002/api/orders/*</code>.</li>
        </ul>
      </li><br />

      <li>
        <strong>Configure Ocelot in <code>Startup.cs</code>:</strong>
        In the <code>ConfigureServices</code> method, add Ocelot services and configure the gateway:
        <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
  services.AddOcelot();
}
 `} </code></pre>
      </li><br />

      <li>
        <strong>Configure Ocelot in <code>Configure</code> Method:</strong>
        In the <code>Configure</code> method, add Ocelot middleware:
        <pre><code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
  if (env.IsDevelopment())
  {
    app.UseDeveloperExceptionPage();
  }

  app.UseRouting();

  // Configure Ocelot
  app.UseOcelot().Wait();
}
  `}  </code></pre>
      </li><br />

      <li>
        <strong>Run the API Gateway:</strong>
        Once the configuration is set, you can run the API Gateway. When you make a request to <code>http://localhost:5000/gateway/products/*</code>, it will route the request to the downstream service on port 5001.
      </li>
    </ol><br />

    <h4 style={{paddingBottom:"6px"}}>Ocelot Features:</h4>
    <ul>
      <li>Request Routing</li><br />
      <li>Authentication/Authorization support</li><br />
      <li>Rate Limiting</li><br />
      <li>Load Balancing</li><br />
      <li>Request Aggregation</li><br />
      <li>Caching</li>
    </ul>

   <br />

    <h2>2. Using YARP (Yet Another Reverse Proxy)</h2>
    <p style={{paddingBottom:"6px"}}>
      YARP is a high-performance reverse proxy library for ASP.NET Core that allows routing and load balancing to microservices.
    </p>
    <h3>Steps to Use YARP API Gateway</h3>

    <ol>
      <li>
        <strong>Add YARP NuGet Package:</strong>
        Install the YARP package:
        <pre><code>dotnet add package Microsoft.ReverseProxy</code></pre>
      </li><br />

      <li>
        <strong>Configure YARP in <code>appsettings.json</code>:</strong>
        In your <code>appsettings.json</code> file, configure YARP to route requests to downstream services:
        <pre><code>{`
{
  "ReverseProxy": {
    "Routes": {
      "productRoute": {
        "ClusterId": "productCluster",
        "Match": {
          "Path": "/gateway/products/{**catch-all}"
        }
      },
      "orderRoute": {
        "ClusterId": "orderCluster",
        "Match": {
          "Path": "/gateway/orders/{**catch-all}"
        }
      }
    },
    "Clusters": {
      "productCluster": {
        "Destinations": {
          "productDestination": {
            "Address": "http://localhost:5001"
          }
        }
      },
      "orderCluster": {
        "Destinations": {
          "orderDestination": {
            "Address": "http://localhost:5002"
          }
        }
      }
    }
  }
}
 `} </code></pre>
      </li><br />

      <li>
        <strong>Configure YARP in <code>Startup.cs</code>:</strong>
        In the <code>ConfigureServices</code> method, add YARP services:
        <pre><code>{`
public void ConfigureServices(IServiceCollection services)
{
  services.AddReverseProxy()
          .LoadFromConfig(Configuration.GetSection("ReverseProxy"));
}
 `} </code></pre>
      </li><br />

      <li>
        <strong>Configure YARP in the <code>Configure</code> Method:</strong>
        In the <code>Configure</code> method, use the YARP middleware:
        <pre><code>{`
public void Configure(IApplicationBuilder app, IWebHostEnvironment env)
{
  if (env.IsDevelopment())
  {
    app.UseDeveloperExceptionPage();
  }

  app.UseRouting();

  // Configure YARP to proxy requests
  app.MapReverseProxy();
}
           `}   </code></pre>
      </li><br />

      <li>
        <strong>Run the API Gateway:</strong>
        With this setup, YARP will forward requests to the appropriate downstream service based on the path. For example, a request to <code>http://localhost:5000/gateway/products/*</code> will be routed to <code>http://localhost:5001</code>.
      </li>
    </ol><br />

    <h4 style={{paddingBottom:"6px"}}>YARP Features:</h4>
    <ul>
      <li>High-performance routing</li><br />
      <li>Load balancing</li><br />
      <li>SSL Termination</li><br />
      <li>Request/Response transformation</li><br />
      <li>Support for multiple destination clusters</li><br />
      <li>Easy integration with other services</li>
    </ul>

   <br />

    <h2>Choosing Between Ocelot and YARP</h2>
    <ul>
      <li>
        <strong>Ocelot:</strong> If you're building a smaller API Gateway that focuses on routing and supports additional features like rate-limiting, security, and caching, Ocelot is a good option. It's easier to configure and offers a more straightforward setup.
      </li><br />
      <li>
        <strong>YARP:</strong> If you need high performance, scalability, and flexibility with features like load balancing and real-time proxying, YARP is a better choice. It provides more advanced capabilities for high-traffic scenarios.
      </li>
    </ul><br />

    <p>Both tools are suitable for implementing an API Gateway in ASP.NET Core, and the choice depends on your project's requirements.</p>
  </div>
)}



                </div>
            </div>
        </div>

    );

}


export default ASPdotNetCourse;


