import React from 'react'
import style from './../Css/StudyMaterial.module.css'

const StudyMaterial = () => {
  return (
    <>
    
    
    <div className={style.studymaterialcard}>
    
      <div className={style.studymaterialbody}>
        <h3 className={style.materialtitle}>React Comprehensive Guide</h3>
        <p className={style.materialtime}>Uplode: August 10, 2024</p>
        <p className={style.materialdescription}>
          This study material covers all key concepts of React including components, state, props, and hooks.
        </p>
      </div>
      <div className={style.studymaterialfooter}>
        <button className={style.viewbutton}>View PDF</button>
        <a href="https://example.com/react-guide.pdf" download>
          <button className={style.downloadbutton}>Download PDF</button>
        </a>
      </div>
    </div>
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default StudyMaterial