import React, { useState, useRef } from "react";
import style from "./../Css/Updateliveconnect.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import API_BASE_URL from "../apiConfig";
import axios from 'axios';

const Updateliveconnect = () => {
  const [classType, setClassType] = useState("");
  const [className, setClassName] = useState("");
  const [classTiming, setClassTiming] = useState("");
  // const [detailedTiming1, setDetailedTiming1] = useState("");
  const [detailedTiming, setDetailedTiming] = useState("");
  const [tutorName, setTutorName] = useState("");
  const [tutorDescription, setTutorDescription] = useState("");
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("classSelect1", classType);
    formData.append("className", className);
    formData.append("classTiming", classTiming);
    // formData.append("detailedTiming1", detailedTiming1);
    formData.append("detailedTiming", detailedTiming);
    formData.append("tutorName", tutorName);
    formData.append("tutorDescription", tutorDescription);
    if (fileInputRef.current.files[0]) {
      formData.append("courseIcon", fileInputRef.current.files[0]);
    }

    try {
       const response = await axios.post(`${API_BASE_URL}/update_live_class.php`, formData, {
        headers: {
            "Content-Type": "multipart/form-data", // Important for sending FormData
        },
    });

    if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    setMessage(data.message); // Set the message returned from the server

      // Clear the form fields after successful submission
      setClassType("");
      setClassName("");
      setClassTiming("");
      setDetailedTiming("");
      setTutorName("");
      setTutorDescription("");
      fileInputRef.current.value = "";

      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (error) {
      setMessage("An error occurred while updating the class.");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };

  return (
    <div className="Create&Update">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md">
        <div className={`${styles.basicdropdowndesign} chooseclassestoedit`}>
          <label htmlFor="classSelect1">Select class type to Edit:</label>
          <select className={styles.basicselectoptionsdesign}
            id="classSelect1"
            name="classSelect1"
            value={classType}
            onChange={(e) => setClassType(e.target.value)}
            required
          >
            <option value="">-- Please choose an option --</option>
            <option value="liveClasses">1- Live Classes</option>
            <option value="upcomingClasses">2- Upcoming Classes</option>
          </select>
        </div>
        </div>
          <div className={`${style.col2forimg} col-md`}>
        <div className={`${styles.basicimgfielddesign} uploadcorseiconwithlable`}>
          <label htmlFor="courseIcon">Upload Course Icon:</label>
          <input className={styles.basicinputimgdesign} type="file" id="courseIcon" name="courseIcon" ref={fileInputRef} accept="image/*" />
        </div>
        </div>
        </div>

        <div className={`${styles.basicfieldsdesign} enterclassnamewithlable`}>
          <label htmlFor="className">Enter Class Name:</label>
          <input className={styles.basicinputdesign} type="text" id="className" name="className" value={className} onChange={(e) => setClassName(e.target.value)} required />
        </div>

        <div className={`${styles.basicfieldsdesign} enterclasstimingwithlable`}>
          <label htmlFor="classTiming">Enter Class Timing:</label>
          <input className={styles.basicinputdesign} type="text" id="classTiming" name="classTiming" value={classTiming} onChange={(e) => setClassTiming(e.target.value)} required />
        </div>

        {/* <div className={`${styles.basicfieldsdesign} enterindetailclasstimingwithlable`}>
          <label htmlFor="detailedTiming1">Enter Detailed Class Timing:</label>
          <textarea className={styles.basicinputdesign} id="detailedTiming1" name="detailedTiming1" value={detailedTiming1} onChange={(e) => setDetailedTiming(e.target.value)} />
        </div> */}
               <div
                  class={`${styles.cssfortimediv} enterlastdqatefroapplywithlable`}
                >
                  <label htmlFor="detailedTiming">
                    Enter Last Date to Apply:
                  </label>
                  <input
                    className={styles.inputtime}
                    type="date"
                    id="detailedTiming"
                    name="detailedTiming"
                    value={detailedTiming}
                    onChange={(e) => setDetailedTiming(e.target.value)}
                    required
                  />
                </div>

        <div className={`${styles.basicfieldsdesign} entertutornamewithlable`}>
          <label htmlFor="tutorName">Enter Tutor Name:</label>
          <input className={styles.basicinputdesign} type="text" id="tutorName" name="tutorName" value={tutorName} onChange={(e) => setTutorName(e.target.value)} required />
        </div>

        <div className={`${styles.basicfieldsdesign} entertutordiscruptionwithlable`}>
          <label htmlFor="tutorDescription">Enter Tutor Description:</label>
          <textarea className={styles.basicinputdesign} id="tutorDescription" name="tutorDescription" value={tutorDescription} onChange={(e) => setTutorDescription(e.target.value)} />
        </div>


        <div className={`row ${styles.adminsubminbtn}`}>
{/*            
        <button type="submit" className={style.adminsubmitbtnbtn}>
          Update live class
        </button> */}

            <button className={`${styles.btncss} ${styles.fullrounded}`}>
              <span>Update live class</span>
              <div className={`${styles.border} ${styles.fullrounded}`}></div>
            </button>

           
          </div>
    

      </form>
      {message && (
          <div className={styles.alertMessage}>
            <p>{message}</p>
          </div>
        )}
    </div>
  );
};

export default Updateliveconnect;
