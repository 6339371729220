import React, { useRef, useState } from "react";
import style from "./../Css/Internshipapply.module.css";
import axios from 'axios';
import API_BASE_URL from '../apiConfig';
import { showCustomAlert } from '../customalert';

const Internshipapply = () => {
  const fileInputRef = useRef(null); // Reference for the file input
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    education: "",
    position: "",
    linkedin: "",
    portfolio: "",
    startDate: "",
    duration: "",
    skills: "",
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (let key in formData) {
      form.append(key, formData[key]);
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/internship_applications.php`,
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Check the status from the API response
      if (response.data.status === "success") {
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          education: "",
          position: "",
          linkedin: "",
          portfolio: "",
          startDate: "",
          duration: "",
          skills: "",
          resume: null,
        });
        // Reset the file input manually
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }

        // console.log("Form submitted successfully:", response.data.message);
        showCustomAlert(response.data.message); // Show success message to the user
      } else {
        // console.error("Error submitting form:", response.data.message);
        showCustomAlert(response.data.message); // Show error message to the user
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      showCustomAlert("An error occurred while submitting the form. Please try again later.");
    }
  };


  return (
    <div className={style.formContainer}>
      <h2 className={style.heading}>Internship Application Form</h2>
      <form className={style.form} onSubmit={handleSubmit}>
        {/* Full Name and Email */}
        <div className={style.inputsjobrows}>
          <div className={style.formGroup}>
            <input
              type="text"
              name="fullName"
              onChange={handleChange}
              required
              placeholder=" "
              className={style.inputField}
              value={formData.fullName}
            />
            <label className={style.label}>Full Name</label>
          </div>
          <div className={style.formGroup}>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              required
              placeholder=" "
              className={style.inputField}
              value={formData.email}
            />
            <label className={style.label}>Email</label>
          </div>
        </div>

        {/* Phone Number and Education */}
        <div className={style.inputsjobrows}>
          <div className={style.formGroup}>
            <input
              type="tel"
              name="phoneNumber"
              onChange={handleChange}
              required
              placeholder=" "
              className={style.inputField}
              value={formData.phoneNumber}
            />
            <label className={style.label}>Phone Number</label>
          </div>
          <div className={style.formGroup}>
            <select
              name="education"
              onChange={handleChange}
              required
              className={style.selectField}
              value={formData.education}
            >
              <option value="" disabled>
                Select Education
              </option>
              <option value="High School">High School</option>
              <option value="Diploma">Diploma</option>
              <option value="Bachelor's Degree">Bachelor's Degree</option>
              <option value="Master's Degree">Master's Degree</option>
              <option value="PhD">PhD</option>
              <option value="Certification Course">Certification Course</option>
              <option value="Self-Taught">Self-Taught</option>
            </select>
            <label className={style.label}>Education Qualification</label>
          </div>
        </div>

        {/* Position and LinkedIn Profile */}
        <div className={style.inputsjobrows}>
          <div className={style.formGroup}>
            <select
              name="position"
              onChange={handleChange}
              required
              className={style.inputField}
              value={formData.position}
            >
              <option value="" disabled>
                Select Position
              </option>
              <option value="Web Development Intern">Web Development Intern</option>
              <option value="Software Development Intern">Software Development Intern</option>
              <option value="Data Science Intern">Data Science Intern</option>
              <option value="AI/ML Intern">AWS Intern</option>
              <option value="Cloud Computing Intern">Cloud Computing Intern</option>
              <option value="DevOps Intern">DevOps Intern</option>
              <option value="UI/UX Design Intern">UI/UX Design Intern</option>
              <option value="Mobile App Development Intern">
                Mobile App Development Intern
              </option>
            </select>
            <label className={style.label}>Position Applying For</label>
          </div>

          {/* <div className={style.formGroup}>
            <input

              name="linkedin"
              onChange={handleChange}
              placeholder=" "
              className={style.inputField}
              value={formData.linkedin}
            />
            <label className={style.label}>LinkedIn Profile</label>
          </div> */}
        </div>

        {/* Portfolio Website and Cover Letter */}
        {/* <div className={style.inputsjobrows}>
          <div className={style.formGroup}>
            <input

              name="portfolio"
              onChange={handleChange}
              placeholder=" "
              className={style.inputField}
              value={formData.portfolio}
            />
            <label className={style.label}>Portfolio Website</label>
          </div>

        </div> */}

        {/* Start Date, Duration, and Skills */}
        <div className={style.inputsjobrows}>
          <div className={style.formGroup}>
            <input
              type="date"
              name="startDate"
              onChange={handleChange}
              required
              className={style.inputField}
              value={formData.startDate}
            />
            <label className={style.label}>Preferred Start Date</label>
          </div>
          <div className={style.formGroup}>
            <select
              name="duration"
              onChange={handleChange}
              required
              className={style.selectField}
              value={formData.duration}
            >
              <option value="">Select Duration</option>
              <option value="1 Month">1 Month</option>
              <option value="2 Months">2 Months</option>
              <option value="3 Months">3 Months</option>
              <option value="6 Months">6 Months</option>
            </select>
            <label className={style.label}>Internship Duration</label>
          </div>
        </div>

        {/* Skills and Resume Upload */}
        <div className={style.formGroup}>
          <textarea
            name="skills"
            onChange={handleChange}
            placeholder="List your relevant skills (e.g., JavaScript, Python)"
            className={style.textArea}
            value={formData.skills}
            rows="3"
          />
          <label className={style.label}>Skills</label>
        </div>

        <div className={style.formGroup}>
          <label className={style.fileLabel}>
            Upload Resume
            <input
              type="file"
              name="resume"
              onChange={handleChange}
              ref={fileInputRef} // Attach the ref
              className={style.fileInput}
              required
            />
          </label>
        </div>

        {/* Submit Button */}
        <button type="submit" className={style.submitButton}>
          Submit Application
        </button>
      </form>
    </div>
  );
};

export default Internshipapply;
