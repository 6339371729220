import React, { useState, useRef } from "react";
import style from "./../Css/Updateliveproject.module.css";
import styles from './../Css/Updatebasicdesign.module.css';
import API_BASE_URL from "../apiConfig";
import axios from 'axios';

const Updateliveproject = () => {
  const [projectType, setProjectType] = useState("");
  const [packageType, setPackageType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectDetails, setProjectDetails] = useState("");
  const [projectSubDetails, setProjectSubDetails] = useState("");
  const [message, setMessage] = useState("");

  // File input refs for images and PDFs
  const projectImage1Ref = useRef(null);
  const projectImage2Ref = useRef(null);
  const projectImage3Ref = useRef(null);
  const projectPreviewPDFRef = useRef(null);
  const projectSourceCodePDFRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("projectType", projectType);
    formData.append("packageType", packageType);
    formData.append("projectName", projectName);
    formData.append("projectDetails", projectDetails);
    formData.append("projectSubDetails", projectSubDetails);

    if (projectImage1Ref.current.files[0]) {
      formData.append("projectImage1", projectImage1Ref.current.files[0]);
    }
    if (projectImage2Ref.current.files[0]) {
      formData.append("projectImage2", projectImage2Ref.current.files[0]);
    }
    if (projectImage3Ref.current.files[0]) {
      formData.append("projectImage3", projectImage3Ref.current.files[0]);
    }
    if (projectPreviewPDFRef.current.files[0]) {
      formData.append("projectPreviewPDF", projectPreviewPDFRef.current.files[0]);
    }
    if (projectSourceCodePDFRef.current.files[0]) {
      formData.append("projectSourceCodePDF", projectSourceCodePDFRef.current.files[0]);
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/update_live_project.php`, formData, {
        headers: {
            "Content-Type": "multipart/form-data", // Important for sending FormData
        },
    });

    if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    setMessage(data.message); // Set the message returned from the server

      // Clear the form fields after successful submission
      setProjectType("");
      setPackageType("");
      setProjectName("");
      setProjectDetails("");
      setProjectSubDetails("");
      projectImage1Ref.current.value = "";
      projectImage2Ref.current.value = "";
      projectImage3Ref.current.value = "";
      projectPreviewPDFRef.current.value = "";
      projectSourceCodePDFRef.current.value = "";

      // Hide the message after 4-5 seconds
      setTimeout(() => {
        setMessage("");
      }, 4000);
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred while updating the project.");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };

  return (
    <>
      <div className="UpdateLiveProject">
        <form className="LiveProjectForm" onSubmit={handleSubmit}>
          <div className={`${style.choosetyperow} row`}>
            <div className="col-md">
              <div className={styles.basicdropdowndesign}>
                <label>Choose Project Type:</label>
                <select className={styles.basicselectoptionsdesign}
                  value={projectType}
                  onChange={(e) => setProjectType(e.target.value)}
                  required
                >
                  <option value="">-- Please choose an option --</option>
                  <option value="ourProjects">Our Projects</option>
                  <option value="liveProjects">Live Projects</option>
                </select>
              </div>
            </div>

            <div className={`${style.choosetyperowcol2} col-md`}>
              <div className={styles.basicdropdowndesign}>
                <label>Choose Category:</label>
                <select className={styles.basicselectoptionsdesign}
                  value={packageType}
                  onChange={(e) => setPackageType(e.target.value)}
                  required
                >
                  <option value="">-- Please choose an option --</option>
                  <option value="webDevelopment">Web Development</option>
                  <option value="dataAnalyst">Data Analyst</option>
                  <option value="frontEnd">Front-end</option>
                  <option value="backEnd">Back-end</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <div className={styles.basicimgfielddesign}>
                <label>Upload Project Image 1:</label>
                <input className={styles.basicinputimgdesign} type="file" ref={projectImage1Ref} accept="image/*" />
              </div>
            </div>

            <div className="col-md">
              <div className={styles.basicimgfielddesign}>
                <label>Upload Project Image 2:</label>
                <input className={styles.basicinputimgdesign} type="file" ref={projectImage2Ref} accept="image/*" />
              </div>
            </div>

            <div className="col-md">
              <div className={styles.basicimgfielddesign}>
                <label>Upload Project Image 3:</label>
                <input className={styles.basicinputimgdesign} type="file" ref={projectImage3Ref} accept="image/*" />
              </div>
            </div>
          </div>

          <div className={`${styles.basicfieldsdesign} projectnameinputwithlables`}>
            <label>Project Name:</label>
            <input
            className={styles.basicinputdesign}
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              required
            />
          </div>

          <div className={`${styles.basicfieldsdesign} projectdetalisinputwithlables`}>
            <label>Project Details:</label>
            <textarea
            className={styles.basicinputdesign}
              value={projectDetails}
              onChange={(e) => setProjectDetails(e.target.value)}
              required
            ></textarea>
          </div>

          <div className={`${styles.basicfieldsdesign} projectsubdetailswithlables`}>
            <label>Project Subdetails:</label>
            <textarea
            className={styles.basicinputdesign}
              value={projectSubDetails}
              onChange={(e) => setProjectSubDetails(e.target.value)}
            ></textarea>
          </div>

          <div className="row">
            <div className="col-md-7">
              <div className={`${styles.basicimgfielddesign} uploadpdfforpreviewpurpose`}>
                <label>Upload PDF for Preview:</label>
                <input className={styles.basicinputimgdesign} type="file" ref={projectPreviewPDFRef} accept="application/pdf" />
              </div>
            </div>

            <div className={`${styles.basicimgfielddesign} col-md-5`}>
              <div className={`${style.uploadprojectimage1withlables} uploadpdfforsorcecodepurpose`}>
                <label>Upload PDF for Source Code:</label>
                <input className={styles.basicinputimgdesign} type="file" ref={projectSourceCodePDFRef} accept="application/pdf" />
              </div>
            </div>
          </div>

          <div className={`row ${styles.adminsubminbtn}`}>
            {/* <button className={style.adminsubmitbtnbtn} type="submit">
              Update Project
            </button> */}

            <button className={`${styles.btncss} ${styles.fullrounded}`}>
              <span>Update Project</span>
              <div className={`${styles.border} ${styles.fullrounded}`}></div>
            </button>
          </div>
        </form>

        {message && (
          <div className={styles.alertMessage}>
            <p>{message}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Updateliveproject;
