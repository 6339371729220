const notifications = [
  { id: 1, message: 'New course available: React Advanced' },
  { id: 2, message: 'Live lecture on JavaScript Basics today at 4 PM' },
  { id: 3, message: 'Practice test results are out!' },
  { id: 1, message: 'New course available: React Advanced' },
  { id: 2, message: 'Live lecture on JavaScript Basics today at 4 PM' },
  { id: 3, message: 'Practice test results are out!' },
  // Add more notifications here
];

export default notifications;
