import React, { useState } from "react";
import style from "./../Css/Updaterecentjobs.module.css";
import styles from "./../Css/Updatebasicdesign.module.css";
import API_BASE_URL from "../apiConfig";
import axios from 'axios';

const Updaterecentjobs = () => {
  const [category, setcategory] = useState("");
  const [title, settitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [qualificationRequired, setQualificationRequired] = useState("");
  const [experience, setexperience] = useState("");
  const [lastDateToApply, setlastDateToApply] = useState("");
  const [numberOfJobs, setNumberOfJobs] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [joblink, setJoblink] = useState("");
  const [message, setMessage] = useState("");

  const resetForm = () => {
    setcategory("");
    settitle("");
    setCompanyName("");
    setJobLocation("");
    setQualificationRequired("");
    setexperience("");
    setlastDateToApply("");
    setNumberOfJobs("");
    setUpdateDate("");
    setJobProfile("");
    setJoblink("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("category", category);
    formData.append("title", title);
    formData.append("companyName", companyName);
    formData.append("jobLocation", jobLocation);
    formData.append("qualificationRequired", qualificationRequired);
    formData.append("experience", experience);
    formData.append("lastDateToApply", lastDateToApply);
    formData.append("numberOfJobs", numberOfJobs);
    formData.append("updateDate", updateDate);
    formData.append("jobProfile", jobProfile);
    formData.append("joblink", joblink);

    try {
      const response = await axios.post(`${API_BASE_URL}/update_recentjob.php`, formData, {
        headers: {
            "Content-Type": "multipart/form-data", // Important for sending FormData
        },
    });

    if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    setMessage(data.message || "Job updated successfully."); // Set the message returned from the server

      resetForm();

      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (error) {
      console.error("Error during submission:", error);
      setMessage("An error occurred while updating the job.");
    }
  };

  return (
    <div>
      <div className="Create&Update">
        <div className="recentjobdataform">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md">
                <div className={`${style.cssfortimediv} entertitlewithlable`}>
                  <label htmlFor="title">Enter job title:</label>
                  <input
                    className={style.inputtime}
                    type="text"
                    id="title"
                    name="title"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md">
                <div className={`${style.cssfortimediv} entercompanynamewithlable`}>
                  <label htmlFor="companyName">Enter Company Name:</label>
                  <input
                    className={style.inputtime}
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className={`${style.choosetyperowcol2} col-md`}>
              <div className={styles.basicdropdowndesign}>
                <label>Choose job Category:</label>
                <select
                  className={styles.basicselectoptionsdesign}
                  value={category}
                  onChange={(e) => setcategory(e.target.value)}
                  required
                >
                  <option value="">-- Please choose an option --</option>
                  <option value="Web Development">Web Development</option>
                  <option value="Data Analyst">Data Analyst</option>
                  <option value="Frontend Developer">Frontend Developer</option>
                  <option value="Backend Developer">Backend Developer</option>
                  <option value="UI/UX Designer">UI/UX Designer</option>
                  <option value="Data Scientist">Data Scientist</option>
                  <option value="Developer">Developer</option>
                  <option value="Full Stack Developer">Full Stack Developer</option>
                </select>
              </div>
            </div>
            <div className="col-md">
              <div className={`${style.cssfortimediv} enterjoblocationwithlable`}>
                <label htmlFor="jobLocation">Enter Job Location:</label>
                <input
                  className={style.inputtime}
                  type="text"
                  id="jobLocation"
                  name="jobLocation"
                  value={jobLocation}
                  onChange={(e) => setJobLocation(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className={`${style.cssfortimediv} enterqualificationrequiredwithlable`}>
              <label htmlFor="qualificationRequired">
                Enter Qualification Required:
              </label>
              <input
                className={style.inputtime}
                type="text"
                id="qualificationRequired"
                name="qualificationRequired"
                value={qualificationRequired}
                onChange={(e) => setQualificationRequired(e.target.value)}
                required
              />
            </div>

            <div className={`${style.cssfortimediv} enterexperiencewithlable`}>
              <label htmlFor="experience">Enter experience Required:</label>
              <input
                className={style.inputtime}
                type="text"
                id="experience"
                name="experience"
                value={experience}
                onChange={(e) => setexperience(e.target.value)}
                required
              />
            </div>

            <div className="row">
              <div className="col-md">
                <div className={`${style.cssfortimediv} enterlastdqatefroapplywithlable`}>
                  <label htmlFor="lastDateToApply">Enter Last Date to Apply:</label>
                  <input
                    className={style.inputtime}
                    type="date"
                    id="lastDateToApply"
                    name="lastDateToApply"
                    value={lastDateToApply}
                    onChange={(e) => setlastDateToApply(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="col-md">
                <div className={`${style.cssfortimediv} enternumberofjobswithlable`}>
                  <label htmlFor="numberOfJobs">Enter Number of Jobs:</label>
                  <input
                    className={style.inputtime}
                    type="text"
                    id="numberOfJobs"
                    name="numberOfJobs"
                    value={numberOfJobs}
                    onChange={(e) => setNumberOfJobs(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md">
                <div className={`${style.cssfortimediv} enterupdatedatewithlable`}>
                  <label htmlFor="updateDate">Enter Update Date:</label>
                  <input
                    className={style.inputtime}
                    type="date"
                    id="updateDate"
                    name="updateDate"
                    value={updateDate}
                    onChange={(e) => setUpdateDate(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className={`${style.cssfortimediv} enterjobprofilewithlable`}>
              <label htmlFor="jobProfile">Enter Job Profile:</label>
              <textarea
                className={style.inputtime}
                id="jobProfile"
                name="jobProfile"
                value={jobProfile}
                onChange={(e) => setJobProfile(e.target.value)}
                required
              ></textarea>
            </div>

            <div className={`${style.cssfortimediv} enterjobprofilewithlable`}>
              <label htmlFor="joblink">Enter Job apply Link:</label>
              <textarea
                className={style.inputtime}
                id="joblink"
                name="joblink"
                value={joblink}
                onChange={(e) => setJoblink(e.target.value)}
                required
              ></textarea>
            </div>

            <div className={`${styles.adminsubminbtn} row`}>
              <button className={`${styles.btncss} ${styles.fullrounded}`}>
                <span>Update jobs</span>
                <div className={`${styles.border} ${styles.fullrounded}`}></div>
              </button>
            </div>

            {message && (
              <div className={styles.alertMessage}>
                <p>{message}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Updaterecentjobs;
