import React, { useState, useEffect } from 'react';
import style from "./../Css/Pakages.module.css";
import API_BASE_URL from "../apiConfig";
import { useNavigate } from 'react-router-dom';
import { showCustomAlert } from '../customalert';

const Packages = () => {
  const [packages, setPackages] = useState([]); // State to hold packages data

  useEffect(() => {
    // Fetch data from backend
    const fetchPackages = async () => {
      try {
        const response = await fetch
          (`${API_BASE_URL}/get_package.php`
          ); // Replace with actual API URL
        const data = await response.json();
        setPackages(data);
      } catch (error) {
        console.error("Failed to fetch Packages:", error);
      }
    };

    fetchPackages();
  }, []);


  const [colClass, setColClass] = useState('col-md-3');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1333.5) {
        setColClass('col-md-4');
      }
      if (window.innerWidth <= 1018.5) {
        setColClass('col-md-6');
      } else {
        setColClass('col-md-3');
      }

    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  
  const navigate = useNavigate();

  const handlePaymentSuccess = async (pkgId, amount, pkgName) => {
    const useremail = localStorage.getItem("userEmail");
    const userName = localStorage.getItem("fullName");
    if (!useremail || !userName) {
      showCustomAlert("You must be logged in to make a payment. Please log in first.");
      // navigate("/Login");
      return;
    }
    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded) {
      showCustomAlert("Failed to load Razorpay SDK. Check your internet connection.");
      return;
    }


    try {
      // Send package data to PHP backend
      const response = await fetch(`${API_BASE_URL}/create_payment_order.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          course_id: pkgId,
          course_name: pkgName,
          user_email: useremail,
          amount: String(amount),
          type: "package"
        }),
      });

      const paymentData = await response.json();
      console.log(paymentData)

      if (paymentData.status === "success") {
        const options = {
          key: "rzp_test_4PAo9zFuVbG062",
          amount: paymentData.amount,
          currency: "INR",
          order_id: paymentData.order_id,
          name: pkgName,
          description: "Course Payment",
          handler: async function (response) {
            try {
              const verifyResponse = await fetch(`${API_BASE_URL}/verify_payment.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  course_id: pkgId,
                  course_name: pkgName,
                  user_email: useremail,
                  amount: amount,
                }),
              });
              const result = await verifyResponse.json();
              if (result.status === "success") {
                showCustomAlert("Payment verified successfully!");
              } else {
                showCustomAlert("Payment verification failed");
              }
            } catch (error) {
              console.error("Error verifying payment:", error);
            }
          },
          prefill: {
            name: userName,
            email: useremail,
          },
          theme: {
            color: "#21486f",
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        alert("Failed to create payment order");
      }
    } catch (error) {
      console.error("Error creating payment order:", error);
    }
  };

  return (
    <>
      {/* Main Container */}
      <div className={`${style.packagescontainer} container-fluid`} id='package_s'>
        <div className="container-fluid">
          {/* Header Section */}
          <div className="row text-center">
            <h1 className={style.packageheading}>
              Your Path to Success Starts Here
            </h1>
            <p className={style.packagedescription}>
              Choose the package that suits your learning needs and goals.
            </p>
          </div>

          {/* Package Rows */}
          <div className={`${style.packagesrow} row`}>
            {packages.filter((pkg) => Number(pkg.hidden) === 0) // Exclude hidden courses
              .map((pkg) => (
                <div key={pkg.id} className={`${style.packagescol} ${colClass}`}>

                  <div className={style.packagebox}>
                    <div className={style.plans}>
                      <h5 className={style.heading}>{pkg.packageName}</h5>
                      <h1 className={style.packageprice}>
                        <i className="fa-solid fa-indian-rupee-sign"></i>
                        {pkg.packagePrice}
                      </h1>
                    </div>
                    <p className={style.description}>
                      {/* Learn the basics of web development, including HTML, CSS, and
                  JavaScript. */}
                      {pkg.packageDetails}
                    </p>
                    <ul className={style.featureList}>
                      <li>
                        {/* 16 live classes */}
                        {pkg.packageBenefit1}
                      </li>
                      <li>
                        {/* Hands-on project */}
                        {pkg.packageBenefit2}
                      </li>
                      <li>
                        {/* Completion certificate */}
                        {pkg.packageBenefit3}
                      </li>
                    </ul>
                    <a
                      className={style.learnmorebuy}
                      href={`${API_BASE_URL}/packagepdf/${pkg.viewpdf}`} // Dynamically link to the PDF
                      target="_blank" // Opens the PDF in a new tab
                      rel="noopener noreferrer" // Security measure for opening links in a new tab
                    >
                      Learn more
                    </a>
                    <button className={`${style.packagebutton} btn`}
                      onClick={() => handlePaymentSuccess(pkg.id, pkg.packagePrice, pkg.packageName)}

                    >Buy now </button>
                  </div>

                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;
