import React, { useState } from 'react';
import style from './../../Css/Courses.module.css';

function AdvanceJavacourse() {
    // State to keep track of the selected chapter
    const [selectedChapter, setSelectedChapter] = useState('chapter1')

    // Function to handle chapter selection
    const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter)
    }

    // State to track the active chapter
    const [activeChapter, setActiveChapter] = useState('chapter1');

    // Function to handle chapter click
    const handleChapterActive = (chapter) => {
        setActiveChapter(chapter);
    };
    const handleClick = (chapter) => {
        handleChapterActive(chapter);
        handleChapterClick(chapter);
        window.scrollTo(0,0);
    };


    return (
        <div className={`${style.firstcontainer} container-fluid`}>

            <div className={`${style.therow} row`}>
            <div className={`${style.droupdownbtnbar} dropdown`}>
                    <button className={`${style.droupdownbtn} btn dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Java EE
                    </button>
                    
                    <ul className={`${style.dropdownmenu} dropdown-menu`}>


                        <li onClick={() => handleClick('chapter1')}
                            className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} text-decoration-none dropdown-item`} >
                       Introduction 
                        </li>




                        <li
                    onClick={() => handleClick('chapter2')}
                    className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''} dropdown-item `}>
                   History and Evolution
                </li>



                <li onClick={() => handleClick('chapter3')}
                     className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} dropdown-item  `}>
          Differences Between Java SE, Java EE, and Spring Framework
             </li>
    

    <h5>Multi-tier architecture</h5>
                <li onClick={() => handleClick('chapter4')} 
                  className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''} dropdown-item  `}>
              Client Tier
                </li>


                <li onClick={() => handleClick('chapter5')} 
                className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''} dropdown-item  `}>
      Web Tier
                </li>


                <li onClick={() => handleClick('chapter6')}
                className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} dropdown-item  `}>
       Business Tier
                </li>

                <li onClick={() => handleClick('chapter7')} 
                className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} dropdown-item  `}>
          Integration Tier
                </li>


<h5>Setting up the environment:</h5>
                <li onClick={() => handleClick('chapter8')}
                className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''} dropdown-item  `}>
             JDK Installation
                </li>

                <li onClick={() => handleClick('chapter9')}
                  className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} dropdown-item  `}>
        IDE Setup 
                </li>

                <li onClick={() => handleClick('chapter10')}
                className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''} dropdown-item  `}>
         Application Server Setup 
                </li>


<h5>Java Servlets</h5>
                <li onClick={() => handleClick('chapter11')} 
                className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} dropdown-item  `}>
          Introduction to Servlets
                    </li>


                <li onClick={() => handleClick('chapter12')}
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} dropdown-item  `}>
              Servlet API 
                </li>


            <li onClick={() => handleClick('chapter13')} 
                className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''} dropdown-item  `}>
           Servlet Lifecycle 
                </li>

                <li onClick={() => handleClick('chapter14')} 
                className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''} dropdown-item  `}>
             Handling HTTP Requests and Responses
                </li>

                <li onClick={() => handleClick('chapter15')} 
                  className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''} dropdown-item  `}>
            Request Dispatching and Forwarding 
                </li>



                <li onClick={() => handleClick('chapter16')} 
                className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''} dropdown-item  `}>
       Session Management 
                </li>

                <li onClick={() => handleClick('chapter17')}
                  className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''} dropdown-item  `}>
             Filters and Listeners
                </li>



<h5> JavaServer Pages (JSP)</h5>
                <li onClick={() => handleClick('chapter18')} 
                className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''} dropdown-item  `}>
     JSP lifecycle and Architecture
                </li>



                <li onClick={() => handleClick('chapter19')}
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''} dropdown-item  `}>
         Scriptlets, Expressions, and Declarations
                </li>

                <li onClick={() => handleClick('chapter20')} 
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''} dropdown-item  `}>
          Implicit Objects in JSP

                </li>

                <li onClick={() => handleClick('chapter21')}
                className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''} dropdown-item  `}>
           Expression Language 
                </li>


                <li onClick={() => handleClick('chapter22')} 
                className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''} dropdown-item  `}>
             Java Standard Tag Library 
                </li>

                <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''} dropdown-item  `}>
            MVC Architecture Using Servlets and JSP
                </li>

<h5>JDBC and Database Connectivity</h5>
                <li onClick={() => handleClick('chapter24')} 
                className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''} dropdown-item  `}>
                JDBC API Overview
                </li>
                
                <li onClick={() => handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''} dropdown-item  `}>
        Connecting to a Database
                </li>

                <li onClick={() => handleClick('chapter26')} 
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''} dropdown-item  `}>
            CRUD Operations with JDBC
                </li>

                <li onClick={() => handleClick('chapter27')} 
                className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''} dropdown-item  `}>
         Using PreparedStatement and CallableStatement
                </li>

                <li onClick={() => handleClick('chapter28')} 
                 className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''} dropdown-item  `}>
              Handling Transactions in JDBC
                </li>


                <li onClick={() => handleClick('chapter29')} 
                className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} dropdown-item  `}>
             Database Connection Pooling 

                </li>

<h5>RESTful Web Services (JAX-RS)</h5>
                <li onClick={() => handleClick('chapter30')} 
                className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} dropdown-item  `}>
              Introduction to REST and JAX-RS
                </li>


                <li onClick={() => handleClick('chapter31')} 
                className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} dropdown-item  `}>
               Creating RESTful APIs with Annotations 
                </li>

                <li onClick={() => handleClick('chapter32')} 
                className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} dropdown-item `}>
               Handling JSON and XML Data
                </li>


                <li onClick={() => handleClick('chapter33')} 
                className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} dropdown-item  `}>
               Exception Handling in REST APIs
                </li>

                <li onClick={() => handleClick('chapter34')} 
                className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} dropdown-item  `}>
                Securing REST APIs
                </li>

<h5>SOAP Web Services (JAX-WS)</h5>
                <li onClick={() => handleClick('chapter35')} 
                className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''} dropdown-item  `}>
            Introduction to SOAP and JAX-WS
                </li>

                <li onClick={() => handleClick('chapter36')} 
                className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''} dropdown-item  `}>
              WSDL 
                </li>
                
                <li onClick={() => handleClick('chapter37')} 
                className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''} dropdown-item  `}>
            Creating and Consuming SOAP Web Services
                </li>

                <li onClick={() => handleClick('chapter38')} 
                className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''} dropdown-item  `}>
             Handling Exceptions and Faults in SOAP
                </li>

<h5>Enterprise JavaBeans (EJB)</h5>
                <li onClick={() => handleClick('chapter39')} 
                className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''} dropdown-item  `}>
       Introduction to EJB
                </li>

                <li onClick={() => handleClick('chapter40')} 
                className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''} dropdown-item  `}>
        Types of EJB 
                </li>


                <li onClick={() => handleClick('chapter41')} 
                className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''} dropdown-item  `}>
             EJB Lifecycle
                </li>

                <li onClick={() => handleClick('chapter42')} 
                className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''} dropdown-item  `}>
           Container-managed Transactions
                </li>

                <li onClick={() => handleClick('chapter43')} 
                className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''} dropdown-item  `}>
             Message-Driven Beans
                </li>


                <li onClick={() => handleClick('chapter44')} 
                className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''} dropdown-item  `}>
          Interceptors in EJB
                </li>

<h3>Java Persistence API (JPA)</h3>
                <li onClick={() => handleClick('chapter45')} 
                className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''} dropdown-item  `}>
               Introduction to ORM and JPA
                </li>

                <li onClick={() => handleClick('chapter46')} 
                className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''} dropdown-item  `}>
        Entity Classes and Annotations
                </li>


                <li onClick={() => handleClick('chapter47')} 
                className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''} dropdown-item  `}>
              Managing Relationships 
                </li>


                <li onClick={() => handleClick('chapter48')} 
                className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''} dropdown-item  `}>
              JPQL 
                </li>

                <li onClick={() => handleClick('chapter49')} 
                className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''} dropdown-item  `}>
              Criteria API
                </li>

             <li onClick={() => handleClick('chapter50')} 
                className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''} dropdown-item  `}>
             Fetch Strategies 
                </li>

                <li onClick={() => handleClick('chapter51')} 
                className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''} dropdown-item  `}>
               Transaction Management with JPA
                </li>

                <li onClick={() => handleClick('chapter52')} 
                className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''} dropdown-item  `}>
          Using Hibernate as the JPA Provider
                </li>


<h5>Messaging with Java Message Service (JMS)</h5>
                <li onClick={() => handleClick('chapter53')} 
                className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''} dropdown-item  `}>
        Introduction to JMS and its Architecture
                </li>

                <li onClick={() => handleClick('chapter54')} 
                className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''} dropdown-item  `}>
          Point-to-Point Model
                </li>

                <li onClick={() => handleClick('chapter55')} 
                className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''} dropdown-item  `}>
          Publish-Subscribe Model
                </li>


                <li onClick={() => handleClick('chapter56')} 
                className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''} dropdown-item  `}>
            Creating JMS Producers and Consumers
                </li>

                <li onClick={() => handleClick('chapter104')} 
                className={`${style.chapter104} ${activeChapter === 'chapter104' ? style.active : ''} dropdown-item  `}>
          Configuring and Using Message-Driven Beans 
                </li>

 <h5>JavaMail API</h5>
               <li onClick={() => handleClick('chapter58')} 
                className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''} dropdown-item   `}>
              Setting up the JavaMail Library
                </li>

                <li onClick={() => handleClick('chapter59')} 
                className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''} dropdown-item  `}>
          Sending Emails using SMTP
                </li>

                <li onClick={() => handleClick('chapter60')} 
                className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''} dropdown-item  `}>
           Reading Emails using IMAP and POP3
                </li>

                <li onClick={() => handleClick('chapter61')} 
                className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''} dropdown-item  `}>
           Email Attachments and Formatting
                </li>


<h5>Java EE Security</h5>
                <li onClick={() => handleClick('chapter62')} 
                className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''} dropdown-item  `}>
          Authentication and Authorization in Java EE
                </li>

                <li onClick={() => handleClick('chapter63')} 
                className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''} dropdown-item  `}>
          Declarative vs. Programmatic Security
                </li>

                <li onClick={() => handleClick('chapter64')} 
                className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''} dropdown-item  `}>
        Role-based Access Control
                </li>

                <li onClick={() => handleClick('chapter65')} 
                className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''} dropdown-item  `}>
            Securing Web Applications with HTTPS
                </li>

                <li onClick={() => handleClick('chapter66')} 
                className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''} dropdown-item  `}>
           Java EE Security API for web and EJB layers
                </li>

                <li onClick={() => handleClick('chapter67')} 
                className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''} dropdown-item  `}>
           Integrating OAuth and JWT
                </li>


<h5> Transactions in Java EE </h5>
                <li onClick={() => handleClick('chapter68')} 
                className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''} dropdown-item  `}>
            Introduction to Java Transaction API 
                </li>

                <li onClick={() => handleClick('chapter69')} 
                className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''} dropdown-item  `}>
            Managing Transactions: Container-managed Transactions and Bean-managed Transactions
                </li>

                <li onClick={() => handleClick('chapter70')} 
                className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''} dropdown-item  `}>
          Distributed Transactions using XA
                </li>


<h5>Java EE Concurrency</h5>
                <li onClick={() => handleClick('chapter71')} 
                className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''} dropdown-item  `}>
         Using Java EE Concurrency Utilities
                </li>

                <li onClick={() => handleClick('chapter72')} 
                className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''} dropdown-item  `}>
           Managing Threads and Tasks in an Enterprise Application
                </li>

                <li onClick={() => handleClick('chapter73')} 
                className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''} dropdown-item  `}>
        ScheduledExecutorService for Task Scheduling
                </li>


<h5>JSON-P (Java API for JSON Processing)</h5>
                <li onClick={() => handleClick('chapter74')} 
                className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''} dropdown-item  `}>
          Introduction to JSON-P
                </li>

                <li onClick={() => handleClick('chapter75')} 
                className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''} dropdown-item  `}>
          Parsing JSON Data
                </li>

                <li onClick={() => handleClick('chapter76')} 
                className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''} dropdown-item  `}>
           Generating JSON Data
                </li>


<h5>JSON-B (Java API for JSON Binding)</h5>
                <li onClick={() => handleClick('chapter77')} 
                className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''} dropdown-item  `}>
            Introduction to JSON-B
                </li>

                <li onClick={() => handleClick('chapter78')} 
                className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''} dropdown-item  `}>
          Mapping Java Objects to JSON
                </li>

                <li onClick={() => handleClick('chapter79')} 
                className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''} dropdown-item  `}>
           Mapping JSON to Java Objects
                </li>


<h5>WebSocket API</h5>
                <li onClick={() => handleClick('chapter80')} 
                className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''} dropdown-item  `}>
         Introduction to WebSockets
                </li>

                <li onClick={() => handleClick('chapter81')} 
                className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''} dropdown-item  `}>
            Building real-time Applications Using WebSocket API
                </li>

                <li onClick={() => handleClick('chapter82')} 
                className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''} dropdown-item  `}>
           WebSocket Client and Server Programming
                </li>


<h5>Batch Processing (JSR 352)</h5>
                <li onClick={() => handleClick('chapter83')} 
                className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''} dropdown-item  `}>
          Introduction to Batch API
                </li>

                <li onClick={() => handleClick('chapter84')} 
                className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''} dropdown-item  `}>
        Creating Batch Jobs
                </li>

                <li onClick={() => handleClick('chapter85')} 
                className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''} dropdown-item  `}>
      Partitioning and Parallel Processing
                </li>


                <li onClick={() => handleClick('chapter86')} 
                className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''} dropdown-item  `}>
          Exception Handling in Batch Processing
                </li>


<h5> Testing Java EE Applications</h5>
                <li onClick={() => handleClick('chapter87')} 
                className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''} dropdown-item  `}>
          Unit Testing with JUnit and TestNG
                </li>

                <li onClick={() => handleClick('chapter88')} 
                className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''} dropdown-item  `}>
            Integration Testing with Arquillian
                </li>

                <li onClick={() => handleClick('chapter89')} 
                className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''} dropdown-item  `}>
            Mocking Frameworks like Mockito
                </li>

                <li onClick={() => handleClick('chapter90')} 
                className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''} dropdown-item  `}>
            Testing Web Services with Postman and SOAP UI
                </li>


<h5>Deployment in Java EE</h5>
                <li onClick={() => handleClick('chapter91')} 
                className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''} dropdown-item  `}>
          Packaging Applications: WAR and EAR files
                </li>

                <li onClick={() => handleClick('chapter92')} 
                className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''} dropdown-item  `}>
            Deploying on Application Servers
                </li>

                <li onClick={() => handleClick('chapter93')} 
                className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} dropdown-item  `}>
       Understanding Deployment Descriptors 
                </li>

                <li onClick={() => handleClick('chapter94')} 
                className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''} dropdown-item   `}>
       Monitoring Applications with JMX
                </li>


<h5>Microservices in Java EE</h5>
                <li onClick={() => handleClick('chapter95')} 
                className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''} dropdown-item  `}>
        Introduction to Microservices Architecture
                </li>

                <li onClick={() => handleClick('chapter96')} 
                className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''} dropdown-item  `}>
       Building Microservices Using Java EE
                </li>

                <li onClick={() => handleClick('chapter97')} 
                className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''} dropdown-item  `}>
           Inter-service Communication
                </li>

                <li onClick={() => handleClick('chapter98')} 
                className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''} dropdown-item  `}>
            Service Discovery and Registry
                </li>

                <li onClick={() => handleClick('chapter99')} 
                className={`${style.chapter99} ${activeChapter === 'chapter99' ? style.active : ''} dropdown-item  `}>
      Securing Microservices with OAuth2 and JWT
                </li>


<h5>Tools and Frameworks</h5>
                <li onClick={() => handleClick('chapter100')} 
                className={`${style.chapter100} ${activeChapter === 'chapter100' ? style.active : ''} dropdown-item  `}>
           Build Tools: Maven, Gradle
                </li>


                <li onClick={() => handleClick('chapter101')} 
                className={`${style.chapter101} ${activeChapter === 'chapter101' ? style.active : ''} dropdown-item  `}>
        Version Control: Git/GitHub
                </li>



                <li onClick={() => handleClick('chapter102')} 
                className={`${style.chapter102} ${activeChapter === 'chapter102' ? style.active : ''} dropdown-item  `}>
        Integration with CI/CD tools 
                </li>



                <li onClick={() => handleClick('chapter103')} 
                className={`${style.chapter103} ${activeChapter === 'chapter103' ? style.active : ''} dropdown-item  `}>
          Performance Monitoring Tools
                </li>








              </ul>
                </div>





                <div className={`${style.leftcolumn} col-2`}>

                    <ul className={`${style.chapters} `}>
                    <h5 className={style.stickyheading} >Java EE</h5>
                
                <li
                    onClick={() => handleClick('chapter1')}

                    className={`${style.chapter1} ${activeChapter === 'chapter1' ? style.active : ''} `}>
                  Introduction
                    </li>

                    <li
                    onClick={() => handleClick('chapter2')}
                    className={`${style.chapter2} ${activeChapter === 'chapter2' ? style.active : ''}  `}>
                   History and Evolution
                </li>



                <li onClick={() => handleClick('chapter3')}
                     className={`${style.chapter3} ${activeChapter === 'chapter3' ? style.active : ''} `}>
          Differences Between Java SE, Java EE, and Spring Framework
             </li>
    

    <h5>Multi-tier architecture</h5>
                <li onClick={() => handleClick('chapter4')} 
                  className={`${style.chapter4} ${activeChapter === 'chapter4' ? style.active : ''}  `}>
              Client Tier
                </li>


                <li onClick={() => handleClick('chapter5')} 
                className={`${style.chapter5} ${activeChapter === 'chapter5' ? style.active : ''}  `}>
      Web Tier
                </li>


                <li onClick={() => handleClick('chapter6')}
                className={`${style.chapter6} ${activeChapter === 'chapter6' ? style.active : ''} `}>
       Business Tier
                </li>

                <li onClick={() => handleClick('chapter7')} 
                className={`${style.chapter7} ${activeChapter === 'chapter7' ? style.active : ''} `}>
          Integration Tier
                </li>


<h5>Setting up the environment:</h5>
                <li onClick={() => handleClick('chapter8')}
                className={`${style.chapter8} ${activeChapter === 'chapter8' ? style.active : ''}  `}>
             JDK Installation
                </li>

                <li onClick={() => handleClick('chapter9')}
                  className={`${style.chapter9} ${activeChapter === 'chapter9' ? style.active : ''} `}>
        IDE Setup 
                </li>

                <li onClick={() => handleClick('chapter10')}
                className={`${style.chapter10} ${activeChapter === 'chapter10' ? style.active : ''}  `}>
         Application Server Setup 
                </li>


<h5>Java Servlets</h5>
                <li onClick={() => handleClick('chapter11')} 
                className={`${style.chapter11} ${activeChapter === 'chapter11' ? style.active : ''} `}>
          Introduction to Servlets
                    </li>


                <li onClick={() => handleClick('chapter12')}
                className={`${style.chapter12} ${activeChapter === 'chapter12' ? style.active : ''} `}>
              Servlet API 
                </li>


            <li onClick={() => handleClick('chapter13')} 
                className={`${style.chapter13} ${activeChapter === 'chapter13' ? style.active : ''}  `}>
           Servlet Lifecycle 
                </li>

                <li onClick={() => handleClick('chapter14')} 
                className={`${style.chapter14} ${activeChapter === 'chapter14' ? style.active : ''}  `}>
             Handling HTTP Requests and Responses
                </li>

                <li onClick={() => handleClick('chapter15')} 
                  className={`${style.chapter15} ${activeChapter === 'chapter15' ? style.active : ''}  `}>
            Request Dispatching and Forwarding 
                </li>



                <li onClick={() => handleClick('chapter16')} 
                className={`${style.chapter16} ${activeChapter === 'chapter16' ? style.active : ''}  `}>
       Session Management 
                </li>

                <li onClick={() => handleClick('chapter17')}
                  className={`${style.chapter17} ${activeChapter === 'chapter17' ? style.active : ''}  `}>
             Filters and Listeners
                </li>



<h5> JavaServer Pages (JSP)</h5>
                <li onClick={() => handleClick('chapter18')} 
                className={`${style.chapter18} ${activeChapter === 'chapter18' ? style.active : ''}  `}>
     JSP lifecycle and Architecture
                </li>



                <li onClick={() => handleClick('chapter19')}
                className={`${style.chapter19} ${activeChapter === 'chapter19' ? style.active : ''}  `}>
         Scriptlets, Expressions, and Declarations
                </li>

                <li onClick={() => handleClick('chapter20')} 
                className={`${style.chapter20} ${activeChapter === 'chapter20' ? style.active : ''}  `}>
          Implicit Objects in JSP

                </li>

                <li onClick={() => handleClick('chapter21')}
                className={`${style.chapter21} ${activeChapter === 'chapter21' ? style.active : ''}  `}>
           Expression Language 
                </li>


                <li onClick={() => handleClick('chapter22')} 
                className={`${style.chapter22} ${activeChapter === 'chapter22' ? style.active : ''}  `}>
             Java Standard Tag Library 
                </li>

                <li onClick={() => handleClick('chapter23')} 
                className={`${style.chapter23} ${activeChapter === 'chapter23' ? style.active : ''}  `}>
            MVC Architecture Using Servlets and JSP
                </li>

<h5>JDBC and Database Connectivity</h5>
                <li onClick={() => handleClick('chapter24')} 
                className={`${style.chapter24} ${activeChapter === 'chapter24' ? style.active : ''}  `}>
                JDBC API Overview
                </li>
                
                <li onClick={() => handleClick('chapter25')} 
                className={`${style.chapter25} ${activeChapter === 'chapter25' ? style.active : ''}  `}>
        Connecting to a Database
                </li>

                <li onClick={() => handleClick('chapter26')} 
                className={`${style.chapter26} ${activeChapter === 'chapter26' ? style.active : ''}  `}>
            CRUD Operations with JDBC
                </li>

                <li onClick={() => handleClick('chapter27')} 
                className={`${style.chapter27} ${activeChapter === 'chapter27' ? style.active : ''}  `}>
         Using PreparedStatement and CallableStatement
                </li>

                <li onClick={() => handleClick('chapter28')} 
                 className={`${style.chapter28} ${activeChapter === 'chapter28' ? style.active : ''}  `}>
              Handling Transactions in JDBC
                </li>


                <li onClick={() => handleClick('chapter29')} 
                className={`${style.chapter29} ${activeChapter === 'chapter29' ? style.active : ''} `}>
             Database Connection Pooling 

                </li>

<h5>RESTful Web Services (JAX-RS)</h5>
                <li onClick={() => handleClick('chapter30')} 
                className={`${style.chapter30} ${activeChapter === 'chapter30' ? style.active : ''} `}>
              Introduction to REST and JAX-RS
                </li>


                <li onClick={() => handleClick('chapter31')} 
                className={`${style.chapter31} ${activeChapter === 'chapter31' ? style.active : ''} `}>
               Creating RESTful APIs with Annotations 
                </li>

                <li onClick={() => handleClick('chapter32')} 
                className={`${style.chapter32} ${activeChapter === 'chapter32' ? style.active : ''} `}>
               Handling JSON and XML Data
                </li>


                <li onClick={() => handleClick('chapter33')} 
                className={`${style.chapter33} ${activeChapter === 'chapter33' ? style.active : ''} `}>
               Exception Handling in REST APIs
                </li>

                <li onClick={() => handleClick('chapter34')} 
                className={`${style.chapter34} ${activeChapter === 'chapter34' ? style.active : ''} `}>
                Securing REST APIs
                </li>

<h5>SOAP Web Services (JAX-WS)</h5>
                <li onClick={() => handleClick('chapter35')} 
                className={`${style.chapter35} ${activeChapter === 'chapter35' ? style.active : ''}  `}>
            Introduction to SOAP and JAX-WS
                </li>

                <li onClick={() => handleClick('chapter36')} 
                className={`${style.chapter36} ${activeChapter === 'chapter36' ? style.active : ''}  `}>
              WSDL 
                </li>
                
                <li onClick={() => handleClick('chapter37')} 
                className={`${style.chapter37} ${activeChapter === 'chapter37' ? style.active : ''}  `}>
            Creating and Consuming SOAP Web Services
                </li>

                <li onClick={() => handleClick('chapter38')} 
                className={`${style.chapter38} ${activeChapter === 'chapter38' ? style.active : ''}  `}>
             Handling Exceptions and Faults in SOAP
                </li>

<h5>Enterprise JavaBeans (EJB)</h5>
                <li onClick={() => handleClick('chapter39')} 
                className={`${style.chapter39} ${activeChapter === 'chapter39' ? style.active : ''}  `}>
       Introduction to EJB
                </li>

                <li onClick={() => handleClick('chapter40')} 
                className={`${style.chapter40} ${activeChapter === 'chapter40' ? style.active : ''}  `}>
        Types of EJB 
                </li>


                <li onClick={() => handleClick('chapter41')} 
                className={`${style.chapter41} ${activeChapter === 'chapter41' ? style.active : ''}  `}>
             EJB Lifecycle
                </li>

                <li onClick={() => handleClick('chapter42')} 
                className={`${style.chapter42} ${activeChapter === 'chapter42' ? style.active : ''}  `}>
           Container-managed Transactions
                </li>

                <li onClick={() => handleClick('chapter43')} 
                className={`${style.chapter43} ${activeChapter === 'chapter43' ? style.active : ''}  `}>
             Message-Driven Beans
                </li>


                <li onClick={() => handleClick('chapter44')} 
                className={`${style.chapter44} ${activeChapter === 'chapter44' ? style.active : ''}  `}>
          Interceptors in EJB
                </li>

<h3>Java Persistence API (JPA)</h3>
                <li onClick={() => handleClick('chapter45')} 
                className={`${style.chapter45} ${activeChapter === 'chapter45' ? style.active : ''}  `}>
               Introduction to ORM and JPA
                </li>

                <li onClick={() => handleClick('chapter46')} 
                className={`${style.chapter46} ${activeChapter === 'chapter46' ? style.active : ''}  `}>
        Entity Classes and Annotations
                </li>


                <li onClick={() => handleClick('chapter47')} 
                className={`${style.chapter47} ${activeChapter === 'chapter47' ? style.active : ''}  `}>
              Managing Relationships 
                </li>


                <li onClick={() => handleClick('chapter48')} 
                className={`${style.chapter48} ${activeChapter === 'chapter48' ? style.active : ''}  `}>
              JPQL 
                </li>

                <li onClick={() => handleClick('chapter49')} 
                className={`${style.chapter49} ${activeChapter === 'chapter49' ? style.active : ''}  `}>
              Criteria API
                </li>

             <li onClick={() => handleClick('chapter50')} 
                className={`${style.chapter50} ${activeChapter === 'chapter50' ? style.active : ''}  `}>
             Fetch Strategies 
                </li>

                <li onClick={() => handleClick('chapter51')} 
                className={`${style.chapter51} ${activeChapter === 'chapter51' ? style.active : ''}  `}>
               Transaction Management with JPA
                </li>

                <li onClick={() => handleClick('chapter52')} 
                className={`${style.chapter52} ${activeChapter === 'chapter52' ? style.active : ''}  `}>
          Using Hibernate as the JPA Provider
                </li>


<h5>Messaging with Java Message Service (JMS)</h5>
                <li onClick={() => handleClick('chapter53')} 
                className={`${style.chapter53} ${activeChapter === 'chapter53' ? style.active : ''}  `}>
        Introduction to JMS and its Architecture
                </li>

                <li onClick={() => handleClick('chapter54')} 
                className={`${style.chapter54} ${activeChapter === 'chapter54' ? style.active : ''}  `}>
          Point-to-Point Model
                </li>

                <li onClick={() => handleClick('chapter55')} 
                className={`${style.chapter55} ${activeChapter === 'chapter55' ? style.active : ''}  `}>
          Publish-Subscribe Model
                </li>


                <li onClick={() => handleClick('chapter56')} 
                className={`${style.chapter56} ${activeChapter === 'chapter56' ? style.active : ''}  `}>
            Creating JMS Producers and Consumers
                </li>

                <li onClick={() => handleClick('chapter104')} 
                className={`${style.chapter104} ${activeChapter === 'chapter104' ? style.active : ''}  `}>
          Configuring and Using Message-Driven Beans 
                </li>

 <h5>JavaMail API</h5>
               <li onClick={() => handleClick('chapter58')} 
                className={`${style.chapter58} ${activeChapter === 'chapter58' ? style.active : ''}  `}>
              Setting up the JavaMail Library
                </li>

                <li onClick={() => handleClick('chapter59')} 
                className={`${style.chapter59} ${activeChapter === 'chapter59' ? style.active : ''}  `}>
          Sending Emails using SMTP
                </li>

                <li onClick={() => handleClick('chapter60')} 
                className={`${style.chapter60} ${activeChapter === 'chapter60' ? style.active : ''}  `}>
           Reading Emails using IMAP and POP3
                </li>

                <li onClick={() => handleClick('chapter61')} 
                className={`${style.chapter61} ${activeChapter === 'chapter61' ? style.active : ''}  `}>
           Email Attachments and Formatting
                </li>


<h5>Java EE Security</h5>
                <li onClick={() => handleClick('chapter62')} 
                className={`${style.chapter62} ${activeChapter === 'chapter62' ? style.active : ''}  `}>
          Authentication and Authorization in Java EE
                </li>

                <li onClick={() => handleClick('chapter63')} 
                className={`${style.chapter63} ${activeChapter === 'chapter63' ? style.active : ''}  `}>
          Declarative vs. Programmatic Security
                </li>

                <li onClick={() => handleClick('chapter64')} 
                className={`${style.chapter64} ${activeChapter === 'chapter64' ? style.active : ''}  `}>
        Role-based Access Control
                </li>

                <li onClick={() => handleClick('chapter65')} 
                className={`${style.chapter65} ${activeChapter === 'chapter65' ? style.active : ''}  `}>
            Securing Web Applications with HTTPS
                </li>

                <li onClick={() => handleClick('chapter66')} 
                className={`${style.chapter66} ${activeChapter === 'chapter66' ? style.active : ''}  `}>
           Java EE Security API for web and EJB layers
                </li>

                <li onClick={() => handleClick('chapter67')} 
                className={`${style.chapter67} ${activeChapter === 'chapter67' ? style.active : ''}  `}>
           Integrating OAuth and JWT
                </li>


<h5> Transactions in Java EE </h5>
                <li onClick={() => handleClick('chapter68')} 
                className={`${style.chapter68} ${activeChapter === 'chapter68' ? style.active : ''}  `}>
            Introduction to Java Transaction API 
                </li>

                <li onClick={() => handleClick('chapter69')} 
                className={`${style.chapter69} ${activeChapter === 'chapter69' ? style.active : ''}  `}>
            Managing Transactions: Container-managed Transactions and Bean-managed Transactions
                </li>

                <li onClick={() => handleClick('chapter70')} 
                className={`${style.chapter70} ${activeChapter === 'chapter70' ? style.active : ''}  `}>
          Distributed Transactions using XA
                </li>


<h5>Java EE Concurrency</h5>
                <li onClick={() => handleClick('chapter71')} 
                className={`${style.chapter71} ${activeChapter === 'chapter71' ? style.active : ''}  `}>
         Using Java EE Concurrency Utilities
                </li>

                <li onClick={() => handleClick('chapter72')} 
                className={`${style.chapter72} ${activeChapter === 'chapter72' ? style.active : ''}  `}>
           Managing Threads and Tasks in an Enterprise Application
                </li>

                <li onClick={() => handleClick('chapter73')} 
                className={`${style.chapter73} ${activeChapter === 'chapter73' ? style.active : ''}  `}>
        ScheduledExecutorService for Task Scheduling
                </li>


<h5>JSON-P (Java API for JSON Processing)</h5>
                <li onClick={() => handleClick('chapter74')} 
                className={`${style.chapter74} ${activeChapter === 'chapter74' ? style.active : ''}  `}>
          Introduction to JSON-P
                </li>

                <li onClick={() => handleClick('chapter75')} 
                className={`${style.chapter75} ${activeChapter === 'chapter75' ? style.active : ''}  `}>
          Parsing JSON Data
                </li>

                <li onClick={() => handleClick('chapter76')} 
                className={`${style.chapter76} ${activeChapter === 'chapter76' ? style.active : ''}  `}>
           Generating JSON Data
                </li>


<h5>JSON-B (Java API for JSON Binding)</h5>
                <li onClick={() => handleClick('chapter77')} 
                className={`${style.chapter77} ${activeChapter === 'chapter77' ? style.active : ''}  `}>
            Introduction to JSON-B
                </li>

                <li onClick={() => handleClick('chapter78')} 
                className={`${style.chapter78} ${activeChapter === 'chapter78' ? style.active : ''}  `}>
          Mapping Java Objects to JSON
                </li>

                <li onClick={() => handleClick('chapter79')} 
                className={`${style.chapter79} ${activeChapter === 'chapter79' ? style.active : ''}  `}>
           Mapping JSON to Java Objects
                </li>


<h5>WebSocket API</h5>
                <li onClick={() => handleClick('chapter80')} 
                className={`${style.chapter80} ${activeChapter === 'chapter80' ? style.active : ''}  `}>
         Introduction to WebSockets
                </li>

                <li onClick={() => handleClick('chapter81')} 
                className={`${style.chapter81} ${activeChapter === 'chapter81' ? style.active : ''}  `}>
            Building real-time Applications Using WebSocket API
                </li>

                <li onClick={() => handleClick('chapter82')} 
                className={`${style.chapter82} ${activeChapter === 'chapter82' ? style.active : ''}  `}>
           WebSocket Client and Server Programming
                </li>


<h5>Batch Processing (JSR 352)</h5>
                <li onClick={() => handleClick('chapter83')} 
                className={`${style.chapter83} ${activeChapter === 'chapter83' ? style.active : ''}  `}>
          Introduction to Batch API
                </li>

                <li onClick={() => handleClick('chapter84')} 
                className={`${style.chapter84} ${activeChapter === 'chapter84' ? style.active : ''}  `}>
        Creating Batch Jobs
                </li>

                <li onClick={() => handleClick('chapter85')} 
                className={`${style.chapter85} ${activeChapter === 'chapter85' ? style.active : ''}  `}>
      Partitioning and Parallel Processing
                </li>


                <li onClick={() => handleClick('chapter86')} 
                className={`${style.chapter86} ${activeChapter === 'chapter86' ? style.active : ''}  `}>
          Exception Handling in Batch Processing
                </li>


<h5> Testing Java EE Applications</h5>
                <li onClick={() => handleClick('chapter87')} 
                className={`${style.chapter87} ${activeChapter === 'chapter87' ? style.active : ''}  `}>
          Unit Testing with JUnit and TestNG
                </li>

                <li onClick={() => handleClick('chapter88')} 
                className={`${style.chapter88} ${activeChapter === 'chapter88' ? style.active : ''}  `}>
            Integration Testing with Arquillian
                </li>

                <li onClick={() => handleClick('chapter89')} 
                className={`${style.chapter89} ${activeChapter === 'chapter89' ? style.active : ''}  `}>
            Mocking Frameworks like Mockito
                </li>

                <li onClick={() => handleClick('chapter90')} 
                className={`${style.chapter90} ${activeChapter === 'chapter90' ? style.active : ''}  `}>
            Testing Web Services with Postman and SOAP UI
                </li>


<h5>Deployment in Java EE</h5>
                <li onClick={() => handleClick('chapter91')} 
                className={`${style.chapter91} ${activeChapter === 'chapter91' ? style.active : ''}  `}>
          Packaging Applications: WAR and EAR files
                </li>

                <li onClick={() => handleClick('chapter92')} 
                className={`${style.chapter92} ${activeChapter === 'chapter92' ? style.active : ''}  `}>
            Deploying on Application Servers
                </li>

                <li onClick={() => handleClick('chapter93')} 
                className={`${style.chapter93} ${activeChapter === 'chapter93' ? style.active : ''} `}>
       Understanding Deployment Descriptors 
                </li>

                <li onClick={() => handleClick('chapter94')} 
                className={`${style.chapter94} ${activeChapter === 'chapter94' ? style.active : ''}  `}>
       Monitoring Applications with JMX
                </li>


<h5>Microservices in Java EE</h5>
                <li onClick={() => handleClick('chapter95')} 
                className={`${style.chapter95} ${activeChapter === 'chapter95' ? style.active : ''}  `}>
        Introduction to Microservices Architecture
                </li>

                <li onClick={() => handleClick('chapter96')} 
                className={`${style.chapter96} ${activeChapter === 'chapter96' ? style.active : ''}  `}>
       Building Microservices Using Java EE
                </li>

                <li onClick={() => handleClick('chapter97')} 
                className={`${style.chapter97} ${activeChapter === 'chapter97' ? style.active : ''}  `}>
           Inter-service Communication
                </li>

                <li onClick={() => handleClick('chapter98')} 
                className={`${style.chapter98} ${activeChapter === 'chapter98' ? style.active : ''}  `}>
            Service Discovery and Registry
                </li>

                <li onClick={() => handleClick('chapter99')} 
                className={`${style.chapter99} ${activeChapter === 'chapter99' ? style.active : ''}  `}>
      Securing Microservices with OAuth2 and JWT
                </li>


<h5>Tools and Frameworks</h5>
                <li onClick={() => handleClick('chapter100')} 
                className={`${style.chapter100} ${activeChapter === 'chapter100' ? style.active : ''}  `}>
           Build Tools: Maven, Gradle
                </li>


                <li onClick={() => handleClick('chapter101')} 
                className={`${style.chapter101} ${activeChapter === 'chapter101' ? style.active : ''}  `}>
        Version Control: Git/GitHub
                </li>



                <li onClick={() => handleClick('chapter102')} 
                className={`${style.chapter102} ${activeChapter === 'chapter102' ? style.active : ''}  `}>
        Integration with CI/CD tools 
                </li>



                <li onClick={() => handleClick('chapter103')} 
                className={`${style.chapter103} ${activeChapter === 'chapter103' ? style.active : ''}  `}>
          Performance Monitoring Tools
                </li>



                    </ul>
                </div>




                <div className={`${style.rightcolumn} col`}>


                    {/* Chapter Content */}
                    {selectedChapter === 'chapter1' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction</h1>

    
      <p>
        Java EE, now known as <strong>Jakarta EE</strong>, is a set of specifications that extend the capabilities
        of the Java SE (Standard Edition) platform for enterprise-level development. It provides a comprehensive
        environment for building and deploying scalable, secure, and robust server-side applications. Jakarta EE is
        governed by the <strong>Eclipse Foundation</strong> and represents a community-driven evolution of Java EE.
      </p><br />
  
      <h3 style={{paddingBottom:"6px"}}>Key Features of Jakarta EE</h3>
      <ul>
        <li>
          <strong>Enterprise-Level Scalability:</strong> Supports the development of large-scale, distributed applications and handles high transaction loads with built-in scalability features.
        </li><br />
        <li>
          <strong>Modularity:</strong> Organized into APIs and specifications to use only what you need, including core components like Servlets, JSP, and EJBs.
        </li><br />
        <li>
          <strong>Standardization:</strong> Ensures applications are portable across compliant application servers (e.g., WildFly, GlassFish, Payara, Open Liberty).
        </li><br />
        <li>
          <strong>Security:</strong> Provides built-in support for authentication, authorization, and secure communications.
        </li><br />
        <li>
          <strong>Integration:</strong> Enables integration with databases, web services, and messaging systems through APIs like JPA, JAX-RS, and JMS.
        </li>
      </ul><br />
   
      <h3>Key Specifications of Jakarta EE</h3>
      <table>
        <thead>
          <tr>
            <th>Specification</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Servlet API</td>
            <td>Enables the development of dynamic web content using Java.</td>
          </tr>
          <tr>
            <td>JSP (Jakarta Pages)</td>
            <td>Simplifies the creation of HTML or XML-based content using templates.</td>
          </tr>
          <tr>
            <td>JAX-RS</td>
            <td>Supports building RESTful web services.</td>
          </tr>
          <tr>
            <td>JPA (Jakarta Persistence)</td>
            <td>Handles database operations using object-relational mapping (ORM).</td>
          </tr>
          <tr>
            <td>EJB (Jakarta Enterprise Beans)</td>
            <td>Provides scalable, transactional business logic.</td>
          </tr>
          <tr>
            <td>CDI (Contexts and Dependency Injection)</td>
            <td>Simplifies dependency management and lifecycle handling.</td>
          </tr>
          <tr>
            <td>JMS (Jakarta Messaging)</td>
            <td>Enables communication between distributed systems via messaging.</td>
          </tr>
        </tbody>
      </table><br />
 
      <h3 style={{paddingBottom:"6px"}}>Benefits of Jakarta EE</h3>
      <ol>
        <li>Productivity: Simplifies enterprise development with pre-defined APIs and standards.</li><br />
        <li>Portability: Applications are not tied to a specific vendor or platform.</li><br />
        <li>Community-Driven Evolution: Frequent updates and enhancements via the Eclipse Foundation.</li><br />
        <li>Broad Ecosystem Support: Supported by many IDEs (e.g., IntelliJ, Eclipse, NetBeans) and servers.</li><br />
        <li>Reliability: Proven, time-tested framework for enterprise-grade solutions.</li>
      </ol><br />


 
      <h3 style={{paddingBottom:"6px"}}>Use Cases</h3>
      <ul>
        <li>Building <strong>Enterprise Applications</strong> like ERP or CRM systems.</li><br />
        <li>Developing <strong>RESTful APIs</strong> and microservices for web applications.</li><br />
        <li>Integrating <strong>Message-Driven Systems</strong> for asynchronous communication.</li><br />
        <li>Creating <strong>Scalable and Secure Banking Solutions</strong>.</li>
      </ul><br />

      <h3>Transition to Jakarta EE</h3>
      <p>
        The transition from Java EE to Jakarta EE involved changes in governance and naming conventions, but the
        underlying architecture and philosophy remain consistent. The specifications have been renamed (e.g., 
        <code>javax.persistence</code> → <code>jakarta.persistence</code>), ensuring backward compatibility with
        Java EE applications.
      </p><br />

      <h3 style={{paddingBottom:"6px"}}>Getting Started with Jakarta EE</h3>
      <ol>
        <li>Set Up Your Environment: Install a Jakarta EE-compatible server (e.g., Payara, WildFly) and use an IDE like Eclipse IDE for Enterprise Java Developers.</li><br />
        <li>Start with Core Components: Build a simple Servlet or JSP application to understand the basics.</li><br />
        <li>Explore Advanced Features: Learn about EJBs, JPA, and CDI to manage complex business logic.</li><br />
        <li>Build Microservices: Use JAX-RS to develop lightweight RESTful services.</li>
      </ol><br />
 
  </div>
)}


{selectedChapter === 'chapter2' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>History and Evolution </h1>


      <h2>1. Early Beginnings: Java Enterprise Edition (J2EE)</h2>
      <ul>
        <li><strong>1999</strong>: Sun Microsystems introduced the <strong>Java 2 Platform, Enterprise Edition (J2EE)</strong>.</li>
        <ul>
          <li>It was designed to simplify enterprise application development and deployment by providing a standard set of APIs and services.</li><br />
          <li>Core components included Servlets, JSP, and Enterprise JavaBeans (EJB).</li><br />
          <li>Early application servers like IBM WebSphere and BEA WebLogic emerged to support J2EE.</li>
        </ul>
      </ul><br />

      <h2 style={{paddingBottom:"6px"}}>2. Growth and Maturity (J2EE 1.2 - J2EE 1.4)</h2>
      <ul>
        <li><strong>J2EE 1.2 (1999):</strong> The first official release with a focus on simplifying distributed systems using EJBs and RMI.</li><br />
        <li><strong>J2EE 1.3 (2001):</strong> Added enhancements like the Java Message Service (JMS) and JAXP for XML processing.</li><br />
        <li><strong>J2EE 1.4 (2003):</strong> Introduced support for web services with JAX-RPC and improved integration capabilities.</li>
      </ul><br />
  
      <h2>3. Rebranding to Java EE (Java EE 5 - Java EE 8)</h2>
      <ul>
        <li>
          <strong>2006:</strong> The platform was renamed to <strong>Java Platform, Enterprise Edition (Java EE)</strong> to align with the broader Java branding.
          <ul>
            <li>Java EE 5 introduced key advancements like <strong>JavaServer Faces (JSF)</strong> and <strong>Java Persistence API (JPA)</strong>, simplifying web and database interaction.</li>
            <li>Annotations replaced XML configuration, enhancing developer productivity.</li>
          </ul>
        </li><br />
        <li>
          <strong>Java EE 6 (2009):</strong>
          <ul>
            <li>Added features like <strong>Contexts and Dependency Injection (CDI)</strong> and the simplified EJB Lite.</li>
            <li>Focused on modularity and developer-friendly APIs.</li>
          </ul>
        </li><br />
        <li>
          <strong>Java EE 7 (2013):</strong>
          <ul>
            <li>Enhanced support for HTML5, WebSockets, and JSON processing.</li>
            <li>Introduced a non-blocking I/O API for scalable applications.</li>
          </ul>
        </li><br />
        <li>
          <strong>Java EE 8 (2017):</strong>
          <ul>
            <li>Integrated modern web standards like HTTP/2 and security enhancements.</li>
            <li>Focused on cloud-native development and microservices with JAX-RS enhancements.</li>
          </ul>
        </li>
      </ul><br />
  
      <h2>4. Transition to Jakarta EE</h2>
      <ul>
        <li>
          <strong>2017:</strong> Oracle handed over Java EE to the <strong>Eclipse Foundation</strong>, initiating its evolution under a new name, <strong>Jakarta EE</strong>.
          <ul>
            <li>The transition was driven by the need for community-driven governance and innovation.</li>
            <li><strong>2018:</strong> The first release under the Jakarta EE brand was essentially Java EE 8 rebranded.</li>
          </ul>
        </li>
      </ul><br />

      <h2>5. Jakarta EE Today</h2>
      <ul>
        <li>
          <strong>Jakarta EE 9 (2020):</strong>
          <ul>
            <li>Focused on namespace migration from <code>javax.*</code> to <code>jakarta.*</code>.</li>
            <li>Simplified platform for further innovations without introducing new features.</li>
          </ul>
        </li><br />
        <li>
          <strong>Jakarta EE 10 (2022):</strong>
          <ul>
            <li>Introduced new features like Jakarta Core Profile for lightweight runtimes.</li><br />
            <li>Enhanced support for cloud-native development and modular applications.</li>
          </ul>
        </li><br />
        <li>
          <strong>Jakarta EE 11 (Expected):</strong> Aims to further modernize the platform with advanced features for microservices and serverless applications.
        </li>
      </ul><br />
   
      <h2>Key Milestones in Java EE Evolution</h2>
      <table>
        <thead>
          <tr>
            <th>Year</th>
            <th>Event</th>
            <th>Key Features/Advancements</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1999</td>
            <td>Launch of J2EE 1.2</td>
            <td>Core technologies: EJB, Servlets, JSP</td>
          </tr>
          <tr>
            <td>2006</td>
            <td>Rebranding to Java EE</td>
            <td>Annotations, JPA, and JSF introduced</td>
          </tr>
          <tr>
            <td>2017</td>
            <td>Transition to Jakarta EE</td>
            <td>Governance shifted to the Eclipse Foundation</td>
          </tr>
          <tr>
            <td>2020</td>
            <td>Jakarta EE 9</td>
            <td>Namespace migration to <code>jakarta.*</code></td>
          </tr>
          <tr>
            <td>2022</td>
            <td>Jakarta EE 10</td>
            <td>Cloud-native enhancements and modularization</td>
          </tr>
        </tbody>
      </table><br />

      <h2 style={{paddingBottom:"6px"}}>Why Transition to Jakarta EE?</h2>
      <ul>
        <li><strong>Open Governance:</strong> Moving to the Eclipse Foundation ensured a more community-driven evolution.</li><br />
        <li><strong>Innovation-Friendly:</strong> Removed licensing restrictions tied to the <code>javax.*</code> namespace.</li><br />
        <li><strong>Modernization:</strong> Focused on cloud, microservices, and lightweight deployment strategies.</li>
      </ul><br />
  
      <h2 style={{paddingBottom:"6px"}}>Impact on the Industry</h2>
      <ul>
        <li>Java EE/Jakarta EE has been a cornerstone for enterprise application development, enabling robust, scalable, and standardized solutions.</li><br />
        <li>Despite competition from frameworks like Spring and Quarkus, Jakarta EE remains relevant due to its open standards, portability, and ecosystem support.</li>
      </ul><br />
  
  </div>
)}



{selectedChapter === 'chapter3' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Differences Between Java SE, Java EE, and Spring Framework</h1>
    
      <article aria-label="Java SE Description">
        <h2>1. Java SE (Java Standard Edition)</h2>
        <p>
          <strong>Definition:</strong> Java SE is the core of the Java programming language, providing the foundational libraries 
          and APIs required to develop general-purpose desktop applications, utilities, and console programs.
        </p>
        <p>
          It includes standard libraries like <code>java.lang</code>, <code>java.util</code>, <code>java.io</code>, 
          and features like threads, collections, and streams.
        </p><br />
        <strong>Key Features:</strong>
        <ul>
          <li>Provides basic tools for developing standalone Java applications.</li>
          <li>Includes features like <strong>JVM (Java Virtual Machine)</strong>, <strong>JDBC (Java Database Connectivity)</strong>, and <strong>Swing/AWT</strong> for GUI-based apps.</li>
          <li>Serves as the foundation for Java EE and Spring Framework.</li>
        </ul><br />
        <strong>Use Cases:</strong>
        <ul>
          <li>Command-line tools, standalone desktop applications, and foundational learning for Java.</li>
        </ul>
      </article><br />

      <article aria-label="Java EE Description">
        <h2>2. Java EE (Java Enterprise Edition) - Now Jakarta EE</h2>
        <p>
          <strong>Definition:</strong> Java EE builds on Java SE by adding APIs and tools for developing large-scale, distributed, 
          and multi-tiered enterprise applications. It’s now governed by the <strong>Eclipse Foundation</strong> and referred to as <strong>Jakarta EE</strong>.
        </p><br />
        <strong>Key Features:</strong>
        <ul>
          <li>Provides a standard platform for enterprise applications with built-in support for web technologies like Servlets, JSP, and WebSockets.</li><br />
          <li>Supports database access with JPA, JDBC, and JTA.</li><br />
          <li>Includes enterprise services like EJB, JMS, and security features.</li><br />
          <li>Compatible with application servers like WildFly, GlassFish, and Payara.</li>
        </ul><br />
        <strong>Use Cases:</strong>
        <ul>
          <li>Enterprise-level web applications, distributed systems, and applications requiring scalability and transactional support.</li>
        </ul>
      </article><br />

      <article aria-label="Spring Framework Description">
        <h2>3. Spring Framework</h2>
        <p>
          <strong>Definition:</strong> Spring is an open-source, lightweight framework for developing Java applications. 
          It simplifies development through <strong>Inversion of Control (IoC)</strong>, <strong>Dependency Injection (DI)</strong>, and <strong>Aspect-Oriented Programming (AOP)</strong>.
        </p><br />
        <strong>Key Features:</strong>
        <ul>
          <li><strong>Core Features:</strong> DI, IoC container, and modular architecture.</li><br />
          <li><strong>Spring Boot:</strong> For rapid application development with embedded servers like Tomcat.</li><br />
          <li><strong>Spring MVC:</strong> Web framework for building web applications and REST APIs.</li><br />
          <li><strong>Spring Data:</strong> Simplifies data access and repository management.</li><br />
          <li><strong>Spring Security:</strong> Handles authentication and authorization.</li><br />
          <li><strong>Microservices Support:</strong> Tools for developing modern microservices.</li>
        </ul><br />
        <strong>Use Cases:</strong>
        <ul>
          <li>Modern web applications, microservices architecture, lightweight alternatives to Java EE, and applications requiring third-party integrations.</li>
        </ul>
      </article><br />

      <h2>Key Differences</h2>
      <table>
        <thead>
          <tr>
            <th>Aspect</th>
            <th>Java SE</th>
            <th>Java EE (Jakarta EE)</th>
            <th>Spring Framework</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Scope</td>
            <td>Core Java features for standalone apps.</td>
            <td>Enterprise-specific features for large-scale apps.</td>
            <td>General-purpose framework for apps of any scale.</td>
          </tr>
          <tr>
            <td>Complexity</td>
            <td>Simple and minimal.</td>
            <td>Comprehensive but heavier.</td>
            <td>Lightweight and modular.</td>
          </tr>
          <tr>
            <td>Deployment</td>
            <td>Local/desktop execution.</td>
            <td>Application servers like WildFly or GlassFish.</td>
            <td>Embedded servers (e.g., Spring Boot) or standalone.</td>
          </tr>
        </tbody>
      </table><br />
    
      <h2>Summary</h2>
      <p>Java SE is the base; every Java developer starts here. Java EE adds enterprise-grade features but can be heavier and more complex. Spring Framework is more developer-friendly, focusing on modularity, speed, and integration, making it a popular choice for modern applications.</p>
  
  </div>
)}


{selectedChapter === 'chapter4' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Client Tier</h1>
    <p>
      The <strong>Client Tier</strong> in <strong>Java EE (Jakarta EE)</strong> is the layer where client applications
      interact with the enterprise application to access its services. This tier represents the user-facing side of the
      application and handles requests to the server tier.
    </p><br />

    <h2>Key Features of the Client Tier in Java EE:</h2>
    <ol>
      <li>
        <strong>Client-Side Applications:</strong>
        <ul>
          <li>Includes desktop applications, web browsers, or mobile apps that communicate with the server.</li>
          <li>
            Examples:
            <ul>
              <li><strong>JavaFX or Swing</strong> applications</li>
              <li>Thin clients like <strong>HTML</strong> pages or <strong>JavaScript-based applications</strong></li>
            </ul>
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Communication with the Server:</strong>
        <ul>
          <li>The client tier sends requests to the application server (via HTTP, HTTPS, or other protocols) and receives responses.</li>
          <li><br />
            Uses standard Java EE APIs such as <strong>JAX-RS</strong> for RESTful web services or <strong>JAX-WS</strong> for SOAP-based services.
          </li>
        </ul>
      </li><br />

      <li>
        <strong>Types of Clients:</strong>
        <ul>
          <li>
            <strong>Thin Clients:</strong> Usually web browsers accessing web-based user interfaces (e.g., HTML, CSS, JavaScript) served by Java EE applications.
          </li><br />
          <li><strong>Thick Clients:</strong> Rich desktop applications like Swing or JavaFX.</li><br />
          <li><strong>Mobile Clients:</strong> Mobile apps interacting with Java EE backends through RESTful services or other APIs.</li>
        </ul>
      </li><br />

      <li>
        <strong>Technologies Commonly Used in the Client Tier:</strong>
        <ul>
          <li><strong>HTML, CSS, JavaScript:</strong> For web-based clients.</li><br />
          <li><strong>Servlets:</strong> For creating dynamic web content.</li><br />
          <li><strong>JSP (JavaServer Pages):</strong> To generate web pages dynamically.</li><br />
          <li><strong>JavaFX/Swing:</strong> For standalone Java applications.</li><br />
          <li><strong>REST APIs:</strong> For communicating with the server using JSON or XML.</li>
        </ul>
      </li><br />

      <li>
        <strong>Client Container:</strong>
        <p>Some Java EE platforms may provide a dedicated <strong>application client container</strong> to manage thick clients and ensure seamless communication with the server.</p>
      </li>
    </ol><br />

    <h2>How the Client Tier Works:</h2>
    <ol>
      <li>
        <strong>User Interaction:</strong> Users interact with the application through a user interface (UI) provided by the client tier.
      </li><br />
      <li>
        <strong>Request Sending:</strong> The client sends requests to the application server for processing (e.g., data retrieval or performing an operation).
      </li><br />
      <li>
        <strong>Server Response:</strong> The application server processes the request and sends back a response, which the client tier presents to the user.
      </li>
    </ol><br />

    <h3>Example Workflow:</h3>
    <p>
      A user opens a browser to access a Java EE-based e-commerce application:
    </p>
    <ol>
      <li>The browser (client) sends an HTTP request to view product details.</li><br />
      <li>The server processes the request and queries the database.</li><br />
      <li>The server sends back a dynamically generated HTML page with product details.</li><br />
      <li>The client tier renders the HTML page for the user.</li>
    </ol><br />

    <h2>Advantages of the Client Tier:</h2>
    <ol>
      <li>
        <strong>Platform Independence:</strong> Client applications can run on any platform that supports Java or a web browser.
      </li><br />
      <li>
        <strong>Diverse Client Support:</strong> Supports various client types, including desktop, mobile, and web applications.
      </li><br />
      <li>
        <strong>Scalability:</strong> Thin clients reduce computational load on the client-side, offloading it to the server tier.
      </li><br />
      <li>
        <strong>Interoperability:</strong> Can integrate with non-Java clients using APIs like REST and SOAP.
      </li>
    </ol>
<br />

    <p>
      The client tier in Java EE is a crucial part of the architecture, enabling effective communication between users
      and the application backend.
    </p>
  </div>
)}


{selectedChapter === 'chapter5' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Web Tier</h1>

    <p>
      The <strong>Web Tier</strong> in Java EE (Jakarta EE) is a crucial layer that acts as the middleman between the 
      <strong>Client Tier</strong> and the <strong>Business Tier</strong>. It handles user requests, processes them, and sends responses 
      back to the client. The web tier is primarily responsible for presentation logic, web application processing, and 
      communication with backend services.
    </p>

  <br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of the Web Tier</h2>
    <ul>
      <li>
        <strong>Handles Client Requests:</strong> 
        Accepts HTTP or HTTPS requests from the client tier (browsers, mobile apps, or other clients) and processes these requests, 
        sending appropriate responses back to the client.
      </li><br />
      <li>
        <strong>Presentation Logic:</strong> 
        Responsible for rendering the user interface using technologies like <strong>JSP</strong>, <strong>JSF</strong>, or 
        <strong>Thymeleaf</strong>, separating the presentation layer from the business logic for modularity.
      </li><br />
      <li>
        <strong>Integration with Business Tier:</strong> 
        Communicates with the <strong>Business Tier</strong> to fetch or update data as requested by the client.
      </li><br />
      <li>
        <strong>Supports Stateless and Stateful Interactions:</strong> 
        Stateless interactions for REST APIs or typical HTTP requests, and stateful interactions using session management 
        for tracking user activity.
      </li><br />
      <li>
        <strong>Security Enforcement:</strong> 
        Enforces authentication, authorization, and secure communication (e.g., HTTPS), with configurations through 
        <code>web.xml</code> or annotations like <code>@ServletSecurity</code>.
      </li>
    </ul>

   <br />

    <h2>Technologies Used in the Web Tier</h2>
    <ul>
      <li>
        <strong>Servlets:</strong> 
        Java classes that handle HTTP requests and responses, forming the core of web applications in Java EE.
      </li><br />
      <li>
        <strong>JavaServer Pages (JSP):</strong> 
        Simplifies the creation of dynamic web pages by embedding Java code in HTML, often used for rendering the user interface.
      </li><br />
      <li>
        <strong>JavaServer Faces (JSF):</strong> 
        A component-based framework for building rich user interfaces, providing reusable UI components and supporting event-driven programming.
      </li><br />
      <li>
        <strong>RESTful Services with JAX-RS:</strong> 
        Exposes resources over HTTP using RESTful APIs, commonly used for modern web and mobile applications.
      </li><br />
      <li>
        <strong>Other Frameworks:</strong> 
        Includes third-party frameworks like <strong>Thymeleaf</strong>, <strong>Spring MVC</strong>, or <strong>AngularJS</strong>, which 
        can integrate with the web tier.
      </li>
    </ul>

    <br />

    <h2 style={{paddingBottom:"6px"}}>How the Web Tier Works</h2>
    <ol>
      <li>The client sends an HTTP request to the server.</li><br />
      <li>The request is intercepted by a servlet or a front controller (e.g., a JSF or Spring MVC controller).</li><br />
      <li>The servlet or controller processes the request, optionally communicating with the business tier or database.</li><br />
      <li>The response is generated using JSP, JSF, or a REST API and sent back to the client.</li>
    </ol>

   <br />

    <h2>Example Workflow</h2>
    <p>
      Imagine a user logging into an online banking application:
    </p>
    <ol>
      <li>The user enters credentials in the login form (Client Tier).</li><br />
      <li>The web tier receives the login request via a servlet or REST API.</li><br />
      <li>The web tier validates the credentials by communicating with the business tier (e.g., a user service).</li><br />
      <li>If successful, the web tier redirects the user to the account dashboard.</li><br />
      <li>The dashboard page is rendered using JSP or JSF.</li>
    </ol>

  <br />

    <h2 style={{paddingBottom:"6px"}}>Advantages of the Web Tier</h2>
    <ul>
      <li><strong>Centralized Processing:</strong> All client requests are processed in a unified layer.</li><br />
      <li><strong>Scalability:</strong> Can handle large numbers of concurrent users.</li><br />
      <li><strong>Modularity:</strong> Separates presentation logic from business logic for maintainability.</li><br />
      <li><strong>Integration:</strong> Simplifies integration with databases, business services, and external APIs.</li><br />
      <li><strong>Support for Modern Web Standards:</strong> Compatible with RESTful services, JSON, and secure protocols.</li>
    </ul><br />

    <p>
      The <strong>Web Tier</strong> plays a vital role in Java EE applications, acting as a bridge between the user-facing client tier 
      and the backend logic of the business tier. It ensures efficient request processing, user interface generation, and secure 
      communication with other layers.
    </p>
  </div>
)}



{selectedChapter === 'chapter6' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Business Tier</h1>

    <p>The <strong>Business Tier</strong> in Java EE (Jakarta EE) is a crucial layer of an enterprise application that encapsulates business logic, rules, and processes. It acts as the intermediary between the <strong>Web Tier</strong> (user-facing tier) and the <strong>Persistence Tier</strong> (data storage layer). This tier is responsible for handling core application operations such as transaction management, security enforcement, and complex computations. The business tier also provides the services that the web tier consumes to fulfill client requests.</p><br />

    <h2>Role of the Business Tier in Java EE</h2>
    <p>The Business Tier is where the core logic of the application resides. It interacts with both the Web Tier and the Persistence Tier:</p>
    <ul>
      <li><strong>Web Tier:</strong> Sends user requests to the business tier, which processes these requests and returns the necessary data.</li><br />
      <li><strong>Persistence Tier:</strong> Retrieves or updates data from the database (through JPA, JDBC, etc.), while the business tier manages the operations that depend on this data.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Key Responsibilities of the Business Tier</h2>
    <ol>
      <li><strong>Encapsulation of Business Logic:</strong> Contains the core operations that are independent of the web and persistence layers. Handles complex operations such as validations, calculations, and transformations.</li><br />
      <li><strong>Transaction Management:</strong> Coordinates transactions to ensure that operations like database updates are consistent and recoverable. Uses <strong>Java Transaction API (JTA)</strong> for distributed transactions.</li><br />
      <li><strong>Security Management:</strong> Implements business-specific security logic such as authorization checks, data access permissions, and encryption. Works with the <strong>Java EE Security API</strong> for securing the business operations.</li><br />
      <li><strong>State Management:</strong> Can be stateless (for stateless beans) or stateful (for stateful session beans). Manages session information (for example, user sessions) if required.</li><br />
      <li><strong>Interfacing with External Systems:</strong> The business tier might interact with external services, APIs, or microservices that provide functionality beyond the core application.</li>
    </ol><br />

    <h2>Technologies in the Business Tier</h2>
    <ul>
      <li><strong>Enterprise JavaBeans (EJB):</strong>
        <ul>
          <li><strong>Session Beans:</strong> EJBs are the most common way to implement business logic in Java EE.</li><br />
          <li><strong>Stateless Session Beans (SLSBs):</strong> Handle tasks that do not require maintaining state between method calls.</li><br />
          <li><strong>Stateful Session Beans (SFSBs):</strong> Retain the state between method calls, typically used for scenarios like user sessions.</li><br />
          <li><strong>Message-Driven Beans (MDBs):</strong> Handle asynchronous messaging, often used in systems that need to process messages from queues or topics (like JMS).</li><br />
          <li><strong>Singleton Beans:</strong> Used for shared state or resources across multiple clients in a highly concurrent environment.</li>
        </ul>
      </li><br />
      <li><strong>Java Persistence API (JPA):</strong> Used for managing entities and database operations. Facilitates object-relational mapping (ORM), allowing business logic to work seamlessly with relational databases.</li><br />
      <li><strong>Web Services (JAX-RS / JAX-WS):</strong> The business tier can expose business operations as web services. <strong>JAX-RS</strong> is used for RESTful services, while <strong>JAX-WS</strong> is used for SOAP-based services.</li><br />
      <li><strong>Business Process Management (BPM):</strong> For complex workflows and business processes, Java EE can integrate with BPM systems (like Activiti or Camunda) to manage processes in the business tier.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>How the Business Tier Works in Java EE</h2>
    <ol>
      <li><strong>Client Interaction:</strong> A client (web or mobile app) sends a request to the web tier, which forwards it to the business tier.</li><br />
      <li><strong>Business Logic Execution:</strong> The business tier performs the required logic, which may involve calculating values, validating data, and calling persistence mechanisms (e.g., JPA or JDBC) for data retrieval or updates.</li><br />
      <li><strong>Communication with Persistence Tier:</strong> The business tier uses JPA or other persistence APIs to query, insert, update, or delete data from the database.</li><br />
      <li><strong>Transaction Management:</strong> If the business operation involves multiple steps, the business tier ensures that these changes happen within a transaction context, typically using <strong>JTA</strong> to manage distributed transactions.</li><br />
      <li><strong>Returning Data to the Web Tier:</strong> After processing, the business tier returns the result (e.g., a calculation, a data object, or a response message) back to the web tier for display.</li>
    </ol><br />

    <h2>Example Workflow in the Business Tier</h2>
    <p>Consider an e-commerce application where the client (web or mobile) initiates a purchase request:</p>
    <ol>
      <li>The user selects an item to purchase, and the client sends the request to the <strong>Web Tier</strong> (via a servlet or REST API).</li><br />
      <li>The <strong>Web Tier</strong> forwards the request to the <strong>Business Tier</strong>, where the following steps occur:</li><br />
      <ul>
        <li>The business logic validates the availability of the item.</li><br />
        <li>It checks the user's payment details.</li><br />
        <li>It initiates a transaction to deduct the item stock and update the user’s order history.</li><br />
        <li>The payment is processed via an external payment gateway API.</li><br />
      </ul>
      <li>If all steps are successful, the business tier returns a confirmation response.</li><br />
      <li>The <strong>Web Tier</strong> sends the confirmation back to the user (i.e., displaying an order summary).</li><br />
    </ol>

    <h2 style={{paddingBottom:"6px"}}>Advantages of the Business Tier in Java EE</h2>
    <ul>
      <li><strong>Separation of Concerns:</strong> By isolating business logic from the web and persistence layers, the business tier makes the application more maintainable and modular.</li><br />
      <li><strong>Transaction Management:</strong> Built-in support for handling complex transaction management and ensuring data consistency.</li><br />
      <li><strong>Security:</strong> Centralized implementation of security logic, ensuring that business rules are followed across all client requests.</li><br />
      <li><strong>Scalability and Performance:</strong> Enterprise beans such as Stateless Session Beans are designed to scale with high concurrency, making them suitable for large applications.</li><br />
      <li><strong>Integration with Other Systems:</strong> Facilitates integration with other external systems and APIs (e.g., third-party services, legacy systems).</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>The <strong>Business Tier</strong> is central to the operation of any Java EE (Jakarta EE) application. It handles all core business functionality, ensuring that client requests are processed accurately and efficiently. By leveraging technologies like <strong>EJBs</strong>, <strong>JPA</strong>, and <strong>JAX-RS</strong>, the business tier helps maintain a clear separation of concerns between business logic, presentation, and data storage, ultimately enabling the development of scalable, maintainable, and secure enterprise applications.</p>
  </div>
)}


{selectedChapter === 'chapter7' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Integration Tier </h1>

    <p>
      In Java EE (now Jakarta EE), the <strong>Integration Tier</strong> is responsible for integrating the application with external systems, services, and resources. This tier provides the mechanisms for connecting to other systems such as databases, messaging systems, web services, and enterprise applications. It plays a crucial role in enabling the communication and data exchange between your enterprise application and other systems.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Responsibilities of the Integration Tier</h2>
    <ul>
      <li><strong>Interfacing with External Systems:</strong> The Integration Tier allows the application to communicate with external systems such as databases, third-party services, and legacy applications. This can be done through standard APIs like JDBC (Java Database Connectivity), JMS (Java Message Service), or web services (JAX-RS, JAX-WS).</li><br />
      <li><strong>Data Exchange:</strong> It facilitates the exchange of data between the internal application and external systems, which might include sending requests to external APIs or processing incoming data.</li><br />
      <li><strong>Message Handling:</strong> The Integration Tier can handle messaging requirements such as asynchronous communication, message queuing, and event-based messaging using technologies like JMS or Kafka.</li><br />
      <li><strong>Transaction Management:</strong> The Integration Tier ensures that data operations involving external systems are consistent and reliable, often using distributed transaction management (via JTA) or compensating transactions to handle failures.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Components of the Integration Tier</h2>
    <ul>
      <li><strong>Java Database Connectivity (JDBC):</strong> JDBC provides a standard interface for interacting with relational databases. The Integration Tier uses JDBC to read from and write to a database. This could involve queries, updates, and transactional operations.</li><br />
      <li><strong>Java Message Service (JMS):</strong> JMS provides a way to send and receive messages between distributed components of the application. The Integration Tier uses JMS to handle messaging scenarios such as publish/subscribe and point-to-point communication. This is ideal for integrating with other systems in an asynchronous manner.</li><br />
      <li><strong>Web Services (JAX-RS and JAX-WS):</strong> The Integration Tier exposes or consumes web services through JAX-RS (for RESTful services) or JAX-WS (for SOAP-based services). These APIs enable the application to interact with other systems over HTTP(S), allowing for standardized communication.</li><br />
      <li><strong>Enterprise Service Bus (ESB):</strong> In some architectures, an ESB is used as a mediator for routing messages between different components. While not a core Java EE technology, it can be used within the Integration Tier for managing communication between disparate systems.</li><br />
      <li><strong>Java Connector Architecture (JCA):</strong> JCA is a specification that provides a standard architecture for integrating Java EE applications with enterprise information systems (EIS) like legacy systems, ERP systems, and other external systems that do not directly use Java technologies.</li><br />
      <li><strong>Third-party API Integration:</strong> The Integration Tier also integrates with third-party RESTful or SOAP APIs. For example, an e-commerce application may integrate with a payment gateway API (e.g., Stripe or PayPal) or a shipping service API (e.g., FedEx or UPS).</li><br />
      <li><strong>File and Data Streaming:</strong> File integration, including CSV, XML, and JSON formats, is another aspect of the Integration Tier. This involves reading from and writing to files, often used for bulk data transfers.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>How the Integration Tier Works</h2>
    <ol>
      <li><strong>External System Interaction:</strong> When the business logic in the Business Tier requires data from an external system, it delegates this request to the Integration Tier. For example, when an order is placed in an e-commerce application, the Integration Tier may be used to interact with an external inventory system to check product availability.</li><br />
      <li><strong>Communication with External Systems:</strong> The Integration Tier communicates with external systems through APIs, databases, or message queues. It may retrieve data from a database via JDBC or JMS or invoke a web service using JAX-RS or JAX-WS.</li><br />
      <li><strong>Returning Data to the Business Tier:</strong> Once the Integration Tier completes the necessary external communication or data processing, it returns the result to the Business Tier, where further business logic or validation can be applied.</li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>Example Workflow in the Integration Tier</h2>
    <ol>
      <li><strong>Client Request:</strong> A user places an order in an e-commerce application, which triggers a request to the web tier.</li><br />
      <li><strong>Business Logic:</strong> The business logic (in the Business Tier) determines that inventory needs to be checked, and then requests the inventory system through the Integration Tier.</li><br />
      <li><strong>External Communication:</strong> The Integration Tier uses JMS to send a message to the inventory system or calls a REST API to check product availability.</li><br />
      <li><strong>Data Processing:</strong> The external system responds with product availability data, which is then processed by the Integration Tier.</li><br />
      <li><strong>Return Data:</strong> The result (e.g., product availability status) is passed back to the Business Tier for further processing and the user is notified.</li>
    </ol><br />

    <h2>Example Code for Integration Tier (Using JAX-RS to Consume a RESTful Web Service)</h2>
    <pre>
      <code>{`
        @Path("/inventory")
        public class InventoryService {

            @Inject
            private Client client; // JAX-RS Client API for making HTTP requests

            @GET
            @Path("/check/{productId}")
            @Produces(MediaType.APPLICATION_JSON)
            public Response checkProductAvailability(@PathParam("productId") String productId) {
                // URL of the external service
                String url = "https://external-inventory-service.com/api/product/" + productId;

                // Sending GET request to the external system
                Response response = client.target(url)
                                          .request(MediaType.APPLICATION_JSON)
                                          .get();

                // Processing the response from the external system
                if (response.getStatus() == 200) {
                    // Return the product availability data to the business tier
                    return Response.ok(response.readEntity(String.class)).build();
                } else {
                    // Handling failure scenarios
                    return Response.status(Response.Status.INTERNAL_SERVER_ERROR)
                                   .entity("Unable to check product availability").build();
                }
            }
        }
      `}</code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Technologies in the Integration Tier</h2>
    <ul>
      <li><strong>JDBC/JPA</strong> for database interaction.</li><br />
      <li><strong>JMS</strong> for messaging.</li><br />
      <li><strong>JAX-RS/JAX-WS</strong> for web services communication.</li><br />
      <li><strong>Java Connector Architecture (JCA)</strong> for connecting with legacy systems.</li><br />
      <li><strong>File-based integration</strong> for bulk data import/export.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      The <strong>Integration Tier</strong> in Java EE (Jakarta EE) serves as the bridge between your application and external systems. By using technologies like JDBC, JMS, JAX-RS, JAX-WS, and JCA, it enables seamless communication and integration with databases, messaging systems, external APIs, and legacy systems, ensuring that the core business logic of the application can interact effectively with the outside world.
    </p>
  </div>
)}


{selectedChapter === 'chapter8' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>JDK Installation</h1>

    <p>To install the Java Development Kit (JDK) for Java EE (now Jakarta EE), follow these steps:</p><br />

  
      <h2>Step 1: Download the JDK</h2>
      <ol>
        <li>Go to the official <a href="https://www.oracle.com/java/technologies/javase-jdk11-downloads.html" target="_blank" rel="noopener noreferrer">Oracle JDK download page</a> or visit <a href="https://adoptium.net/" target="_blank" rel="noopener noreferrer">AdoptOpenJDK</a> for a free, open-source alternative.</li><br />
        <li>Select the appropriate JDK version (e.g., JDK 11 or JDK 17 for a stable version).</li><br />
        <li>Choose your operating system (Windows, macOS, or Linux) and download the installer.</li>
      </ol><br />
    

      <h2 style={{paddingBottom:"6px"}}>Step 2: Install the JDK</h2>

      <h3 style={{paddingBottom:"6px"}}>Windows:</h3>
      <ol>
        <li><strong>Run the Installer</strong>: After downloading, double-click the `.exe` file to start the installation process.</li><br />
        <li><strong>Follow Installation Steps</strong>: Choose the installation directory and click "Next" through the setup wizard.</li><br />
        <li><strong>Set Path Environment Variable</strong>:
          <ul>
            <li>After the installation, you will need to set the <code>JAVA_HOME</code> and <code>PATH</code> environment variables.</li><br />
            <li>Right-click on <code>This PC</code> or <code>Computer</code>, and click on <strong>Properties</strong>.</li><br />
            <li>Click on <strong>Advanced system settings</strong> → <strong>Environment Variables</strong>.</li><br />
            <li>Under <strong>System variables</strong>, click <strong>New</strong>, and for the <strong>Variable name</strong>, enter <code>JAVA_HOME</code>. Set the <strong>Variable value</strong> to the JDK installation path (e.g., <code>C:\Program Files\Java\jdk-17</code>).</li><br />
            <li>Find the <strong>Path</strong> variable under <strong>System variables</strong>, click <strong>Edit</strong>, and add a new entry for the JDK's <code>bin</code> directory (e.g., <code>C:\Program Files\Java\jdk-17\bin</code>).</li>
          </ul>
        </li>
      </ol><br />

      <h3>macOS:</h3>
      <ol>
        <li><strong>Run the Installer</strong>: Open the `.dmg` file and follow the instructions to install the JDK.</li><br />
        <li><strong>Set JAVA_HOME</strong>:
          <ul>
            <li>Open <strong>Terminal</strong>.</li><br />
            <li>Use the following command to set <code>JAVA_HOME</code> in your terminal profile:
              <pre>echo 'export JAVA_HOME=$(/usr/libexec/java_home)' {">>"} ~/.bash_profile</pre>
              <pre>source ~/.bash_profile</pre>
            </li>
          </ul>
        </li>
      </ol><br />

      <h3>Linux:</h3>
      <ol>
        <li><strong>Run the Installer</strong>:
          <ul>
            <li>For Debian/Ubuntu-based systems:
              <pre>sudo apt update && sudo apt install openjdk-11-jdk</pre>
            </li><br />
            <li>For Red Hat/CentOS-based systems:
              <pre>sudo yum install java-11-openjdk-devel</pre>
            </li>
          </ul>
        </li><br />
        <li><strong>Set JAVA_HOME</strong>:
          <ul>
            <li>Open <strong>Terminal</strong> and use the following command:
              <pre>sudo update-alternatives --config java</pre>
            </li>
          </ul>
        </li>
      </ol><br />
   
      <h2>Step 3: Verify the Installation</h2>
      <ol>
        <li>Open <strong>Command Prompt</strong> (Windows) or <strong>Terminal</strong> (macOS/Linux).</li><br />
        <li>Run the following command:
          <pre>java -version</pre>
          You should see the JDK version displayed, indicating that the JDK was installed successfully.</li><br />
        <li>Optionally, you can check the environment variable by running:
          <pre>echo $JAVA_HOME</pre> (On Windows, use <code>echo %JAVA_HOME%</code> in Command Prompt.)
        </li>
      </ol><br />
   
      <h2>Step 4: Install an IDE (Optional)</h2>
      <p>While you can write Java EE code in any text editor, it's more efficient to use an Integrated Development Environment (IDE) such as:</p>
      <ul>
        <li><strong>IntelliJ IDEA</strong> (Community or Ultimate)</li><br />
        <li><strong>Eclipse</strong> (with the Enterprise Java Plugin)</li><br />
        <li><strong>NetBeans</strong></li>
      </ul><br />
      <h2>Step 5: Install Java EE (Jakarta EE) Libraries</h2>
      <p>Java EE (now Jakarta EE) is a set of specifications for enterprise features like web services, messaging, and persistence. To begin working with Java EE, you may need to install:</p>
      <ul>
        <li><strong>Eclipse IDE for Java EE Developers</strong>: Contains all the tools and libraries for working with Java EE.</li><br />
        <li><strong>Apache TomEE</strong>: A Java EE-compliant server that integrates Apache Tomcat with additional EE capabilities.</li><br />
      </ul>
      <p>You can also add Jakarta EE libraries to your projects using Maven or Gradle as dependencies.</p>

      <pre>
        <code>{`

<dependencies>
  <dependency>
    <groupId>jakarta.platform</groupId>
    <artifactId>jakarta.jakartaee-api</artifactId>
    <version>9.1.0</version>  <!-- Use latest version -->
    <scope>provided</scope>
  </dependency>
</dependencies>

        `}</code>
      </pre><br />
  
      <h2>Step 6: Start Developing with Java EE</h2>
      <p>Now that the JDK is installed, you can start developing enterprise applications using Java EE (Jakarta EE) technologies like:</p>
      <ul>
        <li><strong>Servlets</strong></li><br />
        <li><strong>JSP (JavaServer Pages)</strong></li><br />
        <li><strong>JAX-RS (RESTful Web Services)</strong></li><br />
        <li><strong>JPA (Java Persistence API)</strong></li><br />
        <li><strong>JMS (Java Message Service)</strong></li>
      </ul><br />
 
      <h2>Conclusion</h2>
      <p>With the JDK installed and an IDE set up, you are ready to develop Java EE (Jakarta EE) applications. Use the tools and libraries mentioned above to integrate enterprise-level features such as messaging, transactions, persistence, and web services into your applications.</p>

  </div>
)}


{selectedChapter === 'chapter9' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>IDE Setup</h1>

    <p>To set up an Integrated Development Environment (IDE) for Java EE (Jakarta EE) development, you can use Eclipse or IntelliJ IDEA. Below is a guide for setting up each IDE:</p><br />

    <h2 style={{paddingBottom:"6px"}}>1. Setting Up Eclipse for Java EE Development:</h2>

    <h3>Step 1: Download Eclipse IDE for Java EE</h3>
    <ol>
      <li>Visit the <a href="https://www.eclipse.org/downloads/" target="_blank" rel="noopener noreferrer">Eclipse Downloads page</a>.</li><br />
      <li>Download <strong>Eclipse IDE for Java EE Developers</strong>. This version includes all the necessary tools and libraries for Java EE development.</li><br />
      <li>Install Eclipse by following the installation instructions based on your operating system (Windows, macOS, or Linux).</li>
    </ol><br />

    <h3>Step 2: Install Java EE Tools (if needed)</h3>
    <p>If you have the Eclipse IDE for Java Developers (without the EE tools), you can install the required tools manually:</p>
    <ol>
      <li>Open Eclipse.</li><br />
      <li>Go to <strong>Help {">"} Eclipse Marketplace</strong>.</li><br />
      <li>Search for <strong>"Eclim"</strong> or <strong>"Enterprise Java"</strong> tools, and install the Java EE tools for Eclipse (like the <strong>JAX-RS</strong>, <strong>Servlets</strong>, <strong>JPA</strong>, and other Java EE tools).</li>
    </ol><br />

    <h3>Step 3: Set Up Apache TomEE or GlassFish Server</h3>
    <p>To run Java EE applications, you need a Java EE-compliant server:</p>
    <ol>
      <li>Download and install <strong>Apache TomEE</strong> (a lightweight version of Apache Tomcat with Java EE capabilities) or <strong>GlassFish</strong>.</li>
      <ul>
        <li>For <strong>Apache TomEE</strong>, visit <a href="https://tomee.apache.org/download-ng.html" target="_blank" rel="noopener noreferrer">Apache TomEE Downloads</a>.</li><br />
        <li>For <strong>GlassFish</strong>, visit <a href="https://javaee.github.io/glassfish/" target="_blank" rel="noopener noreferrer">GlassFish Downloads</a>.</li><br />
      </ul>
      <li>In Eclipse: Go to <strong>Window {">"} Preferences {">"} Server {">"} Runtime Environments</strong>.</li><br />
      <li>Click <strong>Add Server</strong>, select the server type (e.g., Apache TomEE, GlassFish), and set the installation directory of the server.</li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Step 4: Create a New Java EE Project</h3>
    <ol>
      <li>Go to <strong>File {">"} New {">"} Dynamic Web Project</strong>.</li><br />
      <li>Enter the project name and select the target runtime (Apache TomEE or GlassFish).</li><br />
      <li>In the <strong>Project Facets</strong> section, select the necessary Java EE features like <strong>Servlet</strong>, <strong>JSP</strong>, <strong>JPA</strong>, <strong>JAX-RS</strong>, etc.</li><br />
      <li>Finish the setup and start developing Java EE applications.</li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>2. Setting Up IntelliJ IDEA for Java EE Development:</h2>

    <h3>Step 1: Download IntelliJ IDEA</h3>
    <ol>
      <li>Visit the <a href="https://www.jetbrains.com/idea/download/" target="_blank" rel="noopener noreferrer">IntelliJ IDEA Downloads page</a>.</li><br />
      <li>Download and install <strong>IntelliJ IDEA Ultimate</strong> (recommended for Java EE development) or <strong>Community Edition</strong> if you want a free version.</li><br />
      <ul>
        <li><strong>IntelliJ IDEA Ultimate</strong> comes with built-in support for Java EE technologies like JAX-RS, EJB, JPA, Servlets, and others.</li><br />
        <li>The <strong>Community Edition</strong> supports Java SE and some basic Java development, but for full Java EE (Jakarta EE) support, you will need the Ultimate Edition.</li>
      </ul>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Step 2: Install Java EE Plugin (if needed)</h3>
    <p>If you are using the <strong>Community Edition</strong> or don't have Java EE support by default:</p>
    <ol>
      <li>Open IntelliJ IDEA.</li><br />
      <li>Go to <strong>File {">"} Settings {">"} Plugins</strong>.</li><br />
      <li>Search for <strong>Java EE</strong> or <strong>Jakarta EE</strong> plugins, and install them.</li>
    </ol><br />

    <h3>Step 3: Set Up Application Servers (Apache TomEE, GlassFish, or WildFly)</h3>
    <p>To deploy and run Java EE applications, you need to configure an application server:</p>
    <ol>
      <li>Download and install <strong>Apache TomEE</strong>, <strong>GlassFish</strong>, or <strong>WildFly</strong>.</li>
      <ul>
        <li>For <strong>TomEE</strong>: <a href="https://tomee.apache.org/download-ng.html" target="_blank" rel="noopener noreferrer">Apache TomEE Downloads</a>.</li><br />
        <li>For <strong>GlassFish</strong>: <a href="https://javaee.github.io/glassfish/" target="_blank" rel="noopener noreferrer">GlassFish Downloads</a>.</li><br />
        <li>For <strong>WildFly</strong>: <a href="https://wildfly.org/downloads/" target="_blank" rel="noopener noreferrer">WildFly Downloads</a>.</li>
      </ul><br />
      <li>In IntelliJ IDEA: Go to <strong>File {">"} Settings {">"} Build, Execution, Deployment {">"} Application Servers</strong>.</li><br />
      <li>Add a new application server by clicking on <strong>+</strong> and selecting the server type (TomEE, GlassFish, WildFly).</li><br />
      <li>Browse and select the server installation directory.</li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Step 4: Create a New Java EE Project</h3>
    <ol>
      <li>Go to <strong>File {">"} New {">"} Project</strong>.</li><br />
      <li>Select <strong>Java EE</strong> and choose the desired application template (e.g., Web Application, EJB Module, etc.).</li><br />
      <li>Set up the project SDK (ensure you are using a JDK compatible with Java EE, like JDK 11 or JDK 17).</li><br />
      <li>Finish the project setup, and IntelliJ IDEA will create the necessary files for Java EE development.</li>
    </ol><br />

    <h2>3. Example Java EE Project Structure:</h2>
    <pre> <code>{`
      MyJavaEEProject
      |-- src/
      |   |-- main/
      |       |-- java/
      |           |-- com/
      |               |-- example/
      |                   |-- ServletExample.java
      |                   |-- JPAEntity.java
      |       |-- resources/
      |           |-- META-INF/
      |               |-- persistence.xml
      |       |-- webapp/
      |           |-- WEB-INF/
      |               |-- web.xml
      |               |-- faces-config.xml
      |           |-- index.jsp
      |
      |-- pom.xml (for Maven projects)
      |-- build.gradle (for Gradle projects)
    `} </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>4. Conclusion:</h2>
    <ul>
      <li><strong>Eclipse</strong> is a free, open-source IDE, and <strong>Eclipse IDE for Java EE Developers</strong> comes pre-packaged with Java EE tools.</li><br />
      <li><strong>IntelliJ IDEA Ultimate</strong> offers advanced Java EE (Jakarta EE) features and is a great choice for developers who need a more feature-rich IDE.</li>
    </ul><br />

    <p>After setting up your IDE (Eclipse or IntelliJ IDEA), you are ready to start developing Java EE applications. Just add the necessary dependencies (e.g., Jakarta EE libraries) and configure your server, and you can begin building enterprise-level Java applications.</p>
  </div>
)}




{selectedChapter === 'chapter10' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Application Server Setup (Apache Tomcat, WildFly, GlassFish)</h1>

    <p>
      Java EE (Jakarta EE) applications require a Java EE-compliant application server for deployment and execution.
      Common application servers include <strong>Apache Tomcat</strong>, <strong>WildFly</strong>, and <strong>GlassFish</strong>.
      Below is a step-by-step guide for setting up each of these servers for Java EE development.
    </p>

  <br />

    <h2>1. Apache Tomcat</h2>
    <p style={{paddingBottom:"6px"}}>
      Apache Tomcat is one of the most popular open-source Java application servers, mainly used for running servlets and JSPs.
      While it's not a full Java EE implementation, it does support many Java EE features like servlets and JSPs.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Step 1: Download and Install Apache Tomcat</h3>
    <ul>
      <li>Visit the official <a href="https://tomcat.apache.org/download-90.cgi" target="_blank" rel="noopener noreferrer">Apache Tomcat download page</a>.</li><br />
      <li>Choose the appropriate version (preferably the latest stable version).</li><br />
      <li>Download the binary distribution for your operating system (e.g., <strong>tar.gz</strong> for Linux/macOS, <strong>zip</strong> for Windows).</li><br />
      <li>Extract the archive to a directory on your system.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Step 2: Configure Tomcat in Your IDE (Eclipse/IntelliJ IDEA)</h3>

    <h4 style={{paddingBottom:"6px"}}>In Eclipse:</h4>
    <ul>
      <li>Open Eclipse and go to <strong>Window {">"} Preferences  {">"}  Server  {">"}  Runtime Environments</strong>.</li><br />
      <li>Click <strong>Add Server</strong>.</li><br />
      <li>Choose <strong>Apache Tomcat</strong> as the server type and click <strong>Next</strong>.</li><br />
      <li>Set the installation directory where Tomcat is installed and click <strong>Finish</strong>.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>In IntelliJ IDEA:</h4>
    <ul>
      <li>Open IntelliJ IDEA, go to <strong>File  {">"}  Settings  {">"}  Build, Execution, Deployment  {">"}  Application Servers</strong>.</li><br />
      <li>Click the <strong>+</strong> button and choose <strong>Tomcat Server</strong>.</li><br />
      <li>Browse and select the Tomcat installation directory.</li><br />
      <li>Click <strong>OK</strong> to complete the configuration.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Step 3: Deploy Your Application</h3>
    <ul>
      <li>Create a <strong>Dynamic Web Project</strong> in Eclipse or a <strong>Java EE Project</strong> in IntelliJ IDEA.</li><br />
      <li>In Eclipse: Right-click your project  {">"}  <strong>Run As  {">"}  Run on Server</strong>.</li><br />
      <li>In IntelliJ IDEA: Right-click the project  {">"}  <strong>Deploy to Tomcat</strong>.</li>
    </ul>

   <br />
    <h2>2. WildFly (formerly JBoss)</h2>
    <p style={{paddingBottom:"6px"}}>
      WildFly is a full-featured, open-source Java EE application server that supports the full Java EE specification and is well-suited for enterprise-grade applications.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Step 1: Download and Install WildFly</h3>
    <ul>
      <li>Visit the official <a href="https://wildfly.org/downloads/" target="_blank" rel="noopener noreferrer">WildFly download page</a>.</li><br />
      <li>Download the latest stable version of WildFly.</li><br />
      <li>Extract the downloaded archive to a directory on your system.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Step 2: Configure WildFly in Your IDE (Eclipse/IntelliJ IDEA)</h3>

    <h4 style={{paddingBottom:"6px"}}>In Eclipse:</h4>
    <ul>
      <li>Open Eclipse and go to <strong>Window  {">"}  Preferences  {">"}  Server  {">"}  Runtime Environments</strong>.</li><br />
      <li>Click <strong>Add Server</strong> and choose <strong>WildFly</strong>.</li><br />
      <li>Browse to the WildFly installation directory and select it.</li><br />
      <li>Click <strong>Finish</strong> to complete the setup.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>In IntelliJ IDEA:</h4>
    <ul>
      <li>Open IntelliJ IDEA, go to <strong>File  {">"}  Settings  {">"}  Build, Execution, Deployment  {">"}  Application Servers</strong>.</li><br />
      <li>Click the <strong>+</strong> button and select <strong>WildFly</strong>.</li><br />
      <li>Browse to the WildFly installation directory.</li><br />
      <li>Click <strong>OK</strong> to finalize the configuration.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Step 3: Deploy Your Application</h3>
    <ul>
      <li>Create a <strong>Java EE Project</strong> in your IDE.</li><br />
      <li>In Eclipse: Right-click the project  {">"}  <strong>Run As  {">"}  Run on Server</strong>.</li><br />
      <li>In IntelliJ IDEA: Right-click the project  {">"}  <strong>Deploy to WildFly</strong>.</li><br />
    </ul>
<br />
    <h2>3. GlassFish</h2>
    <p style={{paddingBottom:"6px"}}>
      GlassFish is the reference implementation of the Java EE (Jakarta EE) specification, which means it supports all Java EE features out of the box, including Servlets, JSPs, EJBs, JPA, and more.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Step 1: Download and Install GlassFish</h3>
    <ul>
      <li>Visit the official <a href="https://javaee.github.io/glassfish/" target="_blank" rel="noopener noreferrer">GlassFish download page</a>.</li><br />
      <li>Download the latest version of GlassFish (e.g., <strong>5.x</strong> for Java EE 8 or <strong>6.x</strong> for Jakarta EE 9+).</li><br />
      <li>Extract the downloaded archive to a directory on your system.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Step 2: Configure GlassFish in Your IDE (Eclipse/IntelliJ IDEA)</h3>

    <h4 style={{paddingBottom:"6px"}}>In Eclipse:</h4>
    <ul>
      <li>Go to <strong>Window  {">"}  Preferences  {">"}  Server  {">"}  Runtime Environments</strong>.</li><br />
      <li>Click <strong>Add Server</strong> and choose <strong>GlassFish</strong>.</li><br />
      <li>Browse to the GlassFish installation directory and select it.</li><br />
      <li>Click <strong>Finish</strong> to complete the configuration.</li>
    </ul><br />

    <h4 style={{paddingBottom:"6px"}}>In IntelliJ IDEA:</h4>
    <ul>
      <li>Open IntelliJ IDEA, go to <strong>File  {">"}  Settings  {">"}  Build, Execution, Deployment  {">"}  Application Servers</strong>.</li><br />
      <li>Click the <strong>+</strong> button and select <strong>GlassFish</strong>.</li><br />
      <li>Browse to the GlassFish installation directory and select it.</li><br />
      <li>Click <strong>OK</strong> to finalize the configuration.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Step 3: Deploy Your Application</h3>
    <ul>
      <li>Create a <strong>Java EE Project</strong> in your IDE.</li><br />
      <li>In Eclipse: Right-click your project  {">"}  <strong>Run As  {">"}  Run on Server</strong>.</li><br />
      <li>In IntelliJ IDEA: Right-click your project  {">"}  <strong>Deploy to GlassFish</strong>.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>4. Common Deployment Tasks</h2>
    <ul>
      <li><strong>Create a web.xml file</strong>: Define servlet mapping and other configurations in the `web.xml` file located under `WEB-INF`.</li><br />
      <li><strong>JPA and Persistence Configuration</strong>: Include the `persistence.xml` configuration file under `META-INF` if your app uses JPA.</li><br />
      <li><strong>Application Dependencies</strong>: Include necessary Java EE libraries (e.g., Servlets, JSPs, JPA, etc.) via Maven/Gradle or manually add JARs to the `lib` directory.</li>
    </ul>
<br />

    <h2 style={{paddingBottom:"6px"}}>Conclusion</h2>
    <ul>
      <li><strong>Apache Tomcat</strong>: Best suited for simple web applications (Servlets, JSP).</li><br />
      <li><strong>WildFly</strong>: Full Java EE application server with enterprise features like EJB, JPA, and more.</li><br />
      <li><strong>GlassFish</strong>: The reference implementation of Java EE with complete specification support.</li>
    </ul><br />

    <p>
      By following the steps outlined above, you can easily set up any of these servers and begin deploying your Java EE applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter11' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Servlets</h1>

    <p>A <strong>Servlet</strong> is a Java class that runs on a web server or application server and responds to requests from clients, typically web browsers. Servlets are a fundamental part of Java EE (Jakarta EE) technology and are used for building dynamic web applications.</p>

    <p>In Java EE, <strong>Servlets</strong> provide a platform-independent mechanism to develop server-side applications by processing client requests (usually HTTP) and generating responses.</p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts of Servlets</h3>

    <ol>
      <li><strong>Servlet Container:</strong> A servlet is executed inside a servlet container (also called a servlet engine), which is part of a web server or an application server like Apache Tomcat, Jetty, or WildFly. The servlet container manages the lifecycle of servlets, including their initialization, request handling, and destruction.</li><br />
      <li><strong>Request and Response:</strong> Servlets interact with HTTP requests and responses. When a client sends a request (via HTTP), the servlet processes this request and generates a response that is sent back to the client.</li><br />
      <li><strong>HTTPServlet:</strong> The <code>HttpServlet</code> class is the base class for handling HTTP requests. It is part of the <code>javax.servlet.http</code> package and provides methods like <code>doGet()</code>, <code>doPost()</code>, <code>doPut()</code>, <code>doDelete()</code> that are invoked to process corresponding HTTP request methods.</li>
    </ol><br />

    <h3>Servlet Lifecycle</h3>

    <p>A servlet has a well-defined lifecycle, managed by the servlet container:</p>
    <ol>
      <li><strong>Loading and Instantiation:</strong> When a servlet is first requested, the servlet container loads the servlet class and creates an instance of it.</li><br />
      <li><strong>Initialization:</strong> The <code>init()</code> method is called once after the servlet is instantiated. This method is typically used to perform one-time setup tasks, such as reading configuration settings.</li><br />
      <li><strong>Request Handling:</strong> The servlet container invokes the <code>service()</code> method to handle client requests. For HTTP requests, the <code>service()</code> method calls the appropriate method (<code>doGet()</code>, <code>doPost()</code>, etc.) based on the request type.</li><br />
      <li><strong>Destruction:</strong> When the servlet is no longer needed, the container invokes the <code>destroy()</code> method. This method is used to clean up any resources held by the servlet before it is removed from memory.</li>
    </ol><br />

    <h3>Writing a Simple Servlet</h3>

    <p>Below is a basic example of a servlet that handles HTTP GET requests:</p>

    <pre>
      <code>
        {`import javax.servlet.*;
import javax.servlet.http.*;
import java.io.*;

public class HelloWorldServlet extends HttpServlet {

    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
        // Set the response content type
        response.setContentType("text/html");

        // Get the writer to send the response
        PrintWriter out = response.getWriter();

        // Write the response
        out.println("<html><body>");
        out.println("<h1>Hello, World from Servlet!</h1>");
        out.println("</body></html>");
    }
}`}
      </code>
    </pre><br />

    <h4 style={{paddingBottom:"6px"}}>Key Points in the Example:</h4>
    <ul>
      <li><strong>Extending <code>HttpServlet</code>:</strong> The class <code>HelloWorldServlet</code> extends <code>HttpServlet</code> to handle HTTP requests.</li><br />
      <li><strong><code>doGet()</code> Method:</strong> This method is invoked when the servlet receives an HTTP GET request. It generates an HTML response.</li><br />
      <li><strong><code>response.getWriter()</code>:</strong> The <code>PrintWriter</code> is used to send the HTML content to the client.</li>
    </ul><br />

    <h3>Configuring the Servlet in <code>web.xml</code></h3>

    <p>In Java EE, servlets must be configured in the <code>web.xml</code> file (located in the <code>WEB-INF</code> directory). Here's how you would configure the <code>HelloWorldServlet</code>:</p>

    <pre>
      <code>
        {`<web-app xmlns="http://java.sun.com/xml/ns/javaee" 
                 xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                 xsi:schemaLocation="http://java.sun.com/xml/ns/javaee 
                                     http://java.sun.com/xml/ns/javaee/web-app_3_0.xsd"
                 version="3.0">
  
    <servlet>
        <servlet-name>HelloWorldServlet</servlet-name>
        <servlet-class>HelloWorldServlet</servlet-class>
    </servlet>

    <servlet-mapping>
        <servlet-name>HelloWorldServlet</servlet-name>
        <url-pattern>/hello</url-pattern>
    </servlet-mapping>

</web-app>`}
      </code>
    </pre>

    <h4 style={{paddingBottom:"6px"}}>In this example:</h4>
    <ul>
      <li>The servlet is named <code>HelloWorldServlet</code>.</li><br />
      <li>The URL pattern <code>/hello</code> is mapped to the servlet. When a user accesses <code>http://localhost:8080/your-app-name/hello</code>, the <code>doGet()</code> method of <code>HelloWorldServlet</code> is invoked.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Deploying the Servlet</h3>

    <ol>
      <li><strong>Compile the Servlet:</strong> You need to compile your servlet class (<code>HelloWorldServlet.java</code>) and place it in the <code>WEB-INF/classes</code> directory inside your web application project.</li><br />
      <li><strong>Run the Web Application:</strong> After deploying the web application to a servlet container (like Apache Tomcat), the servlet will be accessible based on the URL pattern defined in <code>web.xml</code>.</li><br />
      <li><strong>Access the Servlet:</strong> Open a browser and access the URL <code>http://localhost:8080/your-app-name/hello</code>. You should see the message "Hello, World from Servlet!" displayed in the browser.</li>
    </ol><br />

    <h3 style={{paddingBottom:"6px"}}>Benefits of Using Servlets</h3>
    <ul>
      <li><strong>Platform Independence:</strong> Servlets are platform-independent because they are written in Java and run on any platform with a Java-enabled web server.</li><br />
      <li><strong>Efficiency:</strong> Servlets are more efficient than CGI (Common Gateway Interface) because servlets are loaded into memory only once, and the servlet container handles multiple requests by calling the servlet’s service methods.</li><br />
      <li><strong>Scalability:</strong> Servlets can handle multiple client requests concurrently by leveraging multithreading support provided by the servlet container.</li>
    </ul>
<br />
    <h3>Conclusion</h3>
    <p>Servlets are a key part of Java EE, enabling the development of dynamic, server-side web applications. They allow you to handle HTTP requests and generate responses, making them a crucial building block for Java-based web applications. By understanding the servlet lifecycle, how to configure servlets, and how to deploy them, you can start building Java web applications that can scale and run on various servers.</p>
  </div>
)}



{selectedChapter === 'chapter12' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Servlet API (HttpServlet, ServletRequest, ServletResponse)</h1>

    <p>The <strong>Servlet API</strong> is a set of classes and interfaces in Java EE (now Jakarta EE) that allows developers to create dynamic web applications. The API provides the necessary classes to handle HTTP requests and responses, manage session states, and communicate with client applications.</p><br />

    <p style={{paddingBottom:"6px"}}>The key components of the Servlet API include:</p>
    <ul>
      <li><strong>HttpServlet</strong></li><br />
      <li><strong>ServletRequest</strong></li><br />
      <li><strong>ServletResponse</strong></li>
    </ul><br />

    <p>Let's break down each of these components.</p>

   <br />

    <h3>1. HttpServlet in Java EE</h3>

    <p style={{paddingBottom:"6px"}}><code>HttpServlet</code> is the most commonly used class to handle HTTP requests in Java web applications. It extends the <code>GenericServlet</code> class and provides methods to handle HTTP-specific operations like <code>doGet()</code>, <code>doPost()</code>, <code>doPut()</code>, and <code>doDelete()</code>.</p>

    <h4>Key Methods of <code>HttpServlet</code>:</h4>
    <ul>
      <li><code>doGet(HttpServletRequest request, HttpServletResponse response)</code>: Handles HTTP GET requests.</li><br />
      <li><code>doPost(HttpServletRequest request, HttpServletResponse response)</code>: Handles HTTP POST requests.</li><br />
      <li><code>doPut(HttpServletRequest request, HttpServletResponse response)</code>: Handles HTTP PUT requests.</li><br />
      <li><code>doDelete(HttpServletRequest request, HttpServletResponse response)</code>: Handles HTTP DELETE requests.</li><br />
      <li><code>service(HttpServletRequest request, HttpServletResponse response)</code>: The <code>service()</code> method is invoked by the servlet container to process client requests. It delegates the request to the appropriate method (<code>doGet()</code>, <code>doPost()</code>, etc.).</li>
    </ul><br />

    <h5>Example: Simple Servlet using <code>HttpServlet</code></h5>
    <pre><code className="java">{`
    import javax.servlet.*;
import javax.servlet.http.*;
import java.io.*;

public class HelloWorldServlet extends HttpServlet {

    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
        // Set the response content type
        response.setContentType("text/html");

        // Get the writer to send the response
        PrintWriter out = response.getWriter();

        // Write the response
        out.println("<html><body>");
        out.println("<h1>Hello, World from Servlet!</h1>");
        out.println("</body></html>");
    }
}
     `} </code></pre>
    <p>In this example, <code>doGet()</code> is overridden to handle HTTP GET requests and send an HTML response back to the client.</p>
<br />

    <h3>2. ServletRequest Interface</h3>
    <p style={{paddingBottom:"6px"}}><code>ServletRequest</code> is an interface that provides methods to retrieve information about the client's request. It allows the servlet to access data such as request parameters, headers, input streams, and other client-related information.</p>

    <h4 style={{paddingBottom:"6px"}}>Key Methods of <code>ServletRequest</code>:</h4>
    <ul>
      <li><code>getParameter(String name)</code>: Retrieves the value of a request parameter.</li><br />
      <li><code>getAttribute(String name)</code>: Retrieves an attribute associated with the request.</li><br />
      <li><code>getInputStream()</code>: Returns the input stream to read the body of the request.</li><br />
      <li><code>getParameterMap()</code>: Returns a map of all parameters and their values.</li>
    </ul><br />

    <h5>Example: Using <code>ServletRequest</code> to Retrieve Parameters</h5>
    <pre><code className="java">{`
@WebServlet("/greet")
public class GreetServlet extends HttpServlet {
    protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
        String name = request.getParameter("name"); // Retrieve the 'name' parameter from the request
        response.getWriter().println("Hello, " + name);
    }
}
    `}</code></pre>
    <p>In this example, <code>request.getParameter("name")</code> retrieves the value of the <code>name</code> parameter sent by the client.</p>

   <br />

    <h3>3. ServletResponse Interface</h3>
    <p style={{paddingBottom:"6px"}}><code>ServletResponse</code> is an interface that provides methods for sending a response to the client. It allows the servlet to set the content type, status codes, and write data back to the client.</p>

    <h4 style={{paddingBottom:"6px"}}>Key Methods of <code>ServletResponse</code>:</h4>
    <ul>
      <li><code>setContentType(String type)</code>: Sets the content type of the response (e.g., <code>text/html</code>, <code>application/json</code>).</li><br />
      <li><code>getWriter()</code>: Returns a <code>PrintWriter</code> object that can be used to send character data to the client.</li><br />
      <li><code>getOutputStream()</code>: Returns an <code>OutputStream</code> for sending binary data to the client.</li><br />
      <li><code>setStatus(int statusCode)</code>: Sets the HTTP status code for the response.</li>
    </ul><br />

    <h5>Example: Using <code>ServletResponse</code> to Send Content</h5>
    <pre><code className="java">{`
@WebServlet("/display")
public class DisplayServlet extends HttpServlet {
    protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
        // Set the response content type to HTML
        response.setContentType("text/html");

        // Get the PrintWriter to send the response to the client
        PrintWriter out = response.getWriter();

        // Send a simple HTML response
        out.println("<html><body>");
        out.println("<h2>Welcome to the Servlet Example</h2>");
        out.println("</body></html>");
    }
}
     `} </code></pre>
    <p>In this example, <code>response.setContentType("text/html")</code> sets the content type for the response to HTML, and <code>response.getWriter()</code> is used to send text content to the client.</p>
<br />

    <h3>Servlet API Workflow</h3>
    <p>When a client (like a browser) makes an HTTP request, the following workflow occurs in the Servlet API:</p>
    <ol>
      <li><strong>Client Request</strong>: A client sends an HTTP request to the web server (e.g., via a browser).</li><br />
      <li><strong>Servlet Container</strong>: The servlet container (e.g., Tomcat) receives the request and matches it to the appropriate servlet based on the URL mapping defined in the <code>web.xml</code> or using annotations (<code>@WebServlet</code>).</li><br />
      <li><strong>Servlet Processing</strong>: The servlet's <code>doGet()</code>, <code>doPost()</code>, or other HTTP methods are called to handle the request.</li><br />
      <li><strong>Response Generation</strong>: The servlet processes the request, generates a response, and sends it back to the client via <code>ServletResponse</code>.</li><br />
      <li><strong>Client Receives Response</strong>: The browser or client receives the response (e.g., HTML content, JSON, etc.) and displays it to the user.</li>
    </ol>

   <br />
    <h3 style={{paddingBottom:"6px"}}>Benefits of the Servlet API</h3>
    <ul>
      <li><strong>Platform Independence</strong>: Servlets are written in Java, so they run on any platform that supports a servlet container.</li><br />
      <li><strong>Performance</strong>: Servlets are more efficient than CGI (Common Gateway Interface) because they are loaded once and handle multiple requests.</li><br />
      <li><strong>Scalability</strong>: Servlets can handle multiple concurrent requests by using multithreading, which helps scale the web application.</li><br />
      <li><strong>Extensibility</strong>: The Servlet API allows developers to extend functionality by implementing various interfaces or overriding servlet lifecycle methods.</li>
    </ul>
<br />

    <h3>Conclusion</h3>
    <p>The <strong>Servlet API</strong> is a critical part of Java EE (Jakarta EE) for building dynamic web applications. By using <code>HttpServlet</code>, <code>ServletRequest</code>, and <code>ServletResponse</code>, developers can handle HTTP requests, generate responses, and interact with web clients effectively. Understanding these components is essential for creating scalable, efficient, and platform-independent web applications in Java.</p>
  </div>
)}





{selectedChapter === 'chapter13' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Servlet Lifecycle (init, service, destroy) </h1>
   
      <p style={{paddingBottom:"6px"}}>
        The <strong>Servlet Lifecycle</strong> in Java EE (now Jakarta EE) refers to the series of steps
        that a servlet undergoes from its creation to its destruction. The servlet lifecycle is managed by
        the <strong>Servlet Container</strong> (e.g., Apache Tomcat, Jetty), and consists of three main stages:
      </p>
      <ol>
        <li><strong>Initialization (`init`)</strong></li><br />
        <li><strong>Request Handling (`service`)</strong></li><br />
        <li><strong>Destruction (`destroy`)</strong></li>
      </ol><br />
      <p>Let’s break down each of these stages in more detail:</p><br />

      <h2 style={{paddingBottom:"6px"}}>1. Initialization (`init`)</h2>
      <p><strong>Purpose:</strong> The `init()` method is called once when the servlet is first loaded into memory. It is used to perform any one-time initialization tasks that the servlet needs, such as setting up resources, loading configuration data, etc.</p><br />
      <p><strong>When It Happens:</strong> The servlet container calls the `init()` method only once during the servlet's lifecycle, after the servlet class is instantiated.</p><br />
      <strong style={{paddingBottom:"6px"}}>Important Notes:</strong>
      <ul>
        <li>The `init()` method is provided by the `GenericServlet` or `HttpServlet` class.</li><br />
        <li>This method takes a `ServletConfig` object as an argument, which contains initialization parameters and a reference to the servlet’s environment.</li><br />
        <li>The container calls `init()` before any client requests are handled.</li>
      </ul>
      <pre>
        <code>
          {`public class MyServlet extends HttpServlet {
            @Override
            public void init() throws ServletException {
                // Initialization code (e.g., database connection, configuration setup)
                System.out.println("Servlet initialized");
            }
          }`}
        </code>
      </pre><br />

      <h2>2. Request Handling (`service`)</h2>
      <p><strong>Purpose:</strong> The `service()` method is called by the servlet container to handle each incoming client request. The servlet processes the request and generates a response.</p><br />
      <p><strong>When It Happens:</strong> This method is invoked for every client request (e.g., HTTP GET, POST, PUT, DELETE) that the servlet needs to process. It is the core method for handling requests.</p><br />
      <strong style={{paddingBottom:"6px"}} >Important Notes:</strong>
      <ul>
        <li>For HTTP requests, the `service()` method calls the appropriate method based on the HTTP method (such as `doGet()`, `doPost()`, etc.).</li><br />
        <li>It provides access to the `HttpServletRequest` and `HttpServletResponse` objects, which the servlet uses to interact with the request and response.</li>
      </ul>
      <pre>
        <code>
          {`public class MyServlet extends HttpServlet {
            @Override
            protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
                // Handle GET request
                response.getWriter().println("Hello, world!");
            }

            @Override
            protected void doPost(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
                // Handle POST request
                response.getWriter().println("POST request handled!");
            }
          }`}
        </code>
      </pre><br />

      <h2 style={{paddingBottom:"6px"}}>3. Destruction (`destroy`)</h2>
      <p><strong>Purpose:</strong> The `destroy()` method is called when the servlet is being destroyed, typically when the servlet container is shutting down or the servlet is being unloaded.</p><br />
      <p><strong>When It Happens:</strong> This method is invoked once, right before the servlet is removed from service. It is used to release resources (like closing database connections or freeing memory).</p><br />
      <strong style={{paddingBottom:"6px"}}>Important Notes:</strong>
      <ul>
        <li>The servlet container calls the `destroy()` method before it completely unloads the servlet from memory.</li><br />
        <li>This method is important for cleaning up resources that were allocated during the servlet's initialization or request processing.</li>
      </ul>
      <pre>
        <code>
          {`public class MyServlet extends HttpServlet {
            @Override
            public void destroy() {
                // Clean-up code (e.g., close database connections, release resources)
                System.out.println("Servlet destroyed");
            }
          }`}
        </code>
      </pre><br />

      <h2>Servlet Lifecycle Diagram</h2>
      <pre>
        <code>
          {`
   +------------------+
   |   Servlet Load   |
   +------------------+
          |
          v
   +------------------+    Request 1      +-------------------+
   |   init() method  +-----------------> |   service() method |
   +------------------+                   +-------------------+
          |
          v
  +---------------------+
  | destroy() method    |
  +---------------------+
          `}
        </code>
      </pre><br />

      <h2 style={{paddingBottom:"6px"}}>Key Lifecycle Methods</h2>
      <ul>
        <li><code>init(ServletConfig config)</code>: Initializes the servlet. It's called only once during the servlet's lifecycle.</li><br />
        <li><code>service(HttpServletRequest req, HttpServletResponse res)</code>: Handles each client request. This method is invoked multiple times (once for each request).</li><br />
        <li><code>destroy()</code>: Cleans up resources before the servlet is destroyed. It is called once before the servlet is removed from memory.</li>
      </ul><br />

      <h2 style={{paddingBottom:"6px"}}>Summary</h2>
      <ul>
        <li><code>init()</code>: Used to initialize the servlet, called only once when the servlet is first loaded.</li><br />
        <li><code>service()</code>: Handles requests from clients and generates responses, called for every request.</li><br />
        <li><code>destroy()</code>: Cleans up resources and is called before the servlet is removed from service.</li>
      </ul><br />

      <p>
        Understanding these lifecycle methods is crucial for managing servlet behavior, optimizing performance, and ensuring proper resource management in Java EE (Jakarta EE) web applications.
      </p>
  
  </div>
)}




{selectedChapter === 'chapter14' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Handling HTTP Requests and Responses</h1>

    <p>Handling HTTP requests and responses in Java EE (now Jakarta EE) is a core part of building web applications with servlets. In Java EE, servlets are used to process HTTP requests, perform business logic, and send HTTP responses to the client. Here's an overview of how to handle HTTP requests and responses in a servlet.</p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts</h3>
    <ul>
      <li><strong>HTTP Request:</strong> An HTTP request is sent from the client (browser or another HTTP client) to the web server. It contains information like HTTP method (GET, POST, PUT, DELETE), request parameters, headers, etc.</li><br />
      <li><strong>HTTP Response:</strong> After processing the request, the servlet generates a response, which can include HTML content, JSON data, or other formats, along with response status codes and headers.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Servlet Structure for Handling Requests and Responses</h3>
    <ul>
      <li><strong>doGet():</strong> Handles HTTP GET requests. Typically used to retrieve resources or data from the server.</li><br />
      <li><strong>doPost():</strong> Handles HTTP POST requests. Usually used to send data to the server, like submitting a form.</li><br />
      <li><strong>doPut():</strong> Handles HTTP PUT requests, commonly used for updating existing resources.</li><br />
      <li><strong>doDelete():</strong> Handles HTTP DELETE requests, typically used to remove resources.</li><br />
      <li><strong>doHead():</strong> Handles HTTP HEAD requests, which are similar to GET but only return the response headers.</li>
    </ul><br />

    <h3>Basic Structure of a Servlet Handling HTTP Requests</h3>
    <p>Below is an example servlet that handles both GET and POST requests:</p>

    <pre>
      <code>{`
        import javax.servlet.*;
        import javax.servlet.http.*;
        import java.io.IOException;
        import java.io.PrintWriter;

        public class MyServlet extends HttpServlet {

            // Handling GET requests
            @Override
            protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
                // Set content type for the response
                response.setContentType("text/html");

                // Get output stream to write response
                PrintWriter out = response.getWriter();
                
                // Write HTML response
                out.println("<html><body>");
                out.println("<h1>Hello, World! This is a GET request response.</h1>");
                out.println("</body></html>");
            }

            // Handling POST requests
            @Override
            protected void doPost(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
                // Set content type for the response
                response.setContentType("text/html");

                // Get parameters from the request
                String name = request.getParameter("name");

                // Get output stream to write response
                PrintWriter out = response.getWriter();

                // Write HTML response
                out.println("<html><body>");
                out.println("<h1>Hello, " + name + "! This is a POST request response.</h1>");
                out.println("</body></html>");
            }
        }
      `}</code>
    </pre><br />

    <h3>Explanation of the Code:</h3>
    <ol>
      <li><strong>doGet() Method:</strong> Handles GET requests, typically used to retrieve data. In the example, the servlet generates a simple HTML response when a client sends a GET request.</li><br />
      <li><strong>doPost() Method:</strong> Handles POST requests, commonly used to submit data from a client (like form data). In the example, the servlet retrieves a parameter (`name`) from the POST request and uses it to generate a personalized response.</li>
    </ol><br />

    <h3>Handling HTTP Request Parameters</h3>
    <p>You can retrieve parameters sent with the request using the <code>HttpServletRequest</code> object:</p>
    <pre>
      <code>{`
        String param = request.getParameter("paramName");  // Get a single parameter
        String[] params = request.getParameterValues("paramName");  // Get an array of values for a parameter
      `}</code>
    </pre><br />

    <h3>Handling Form Data (POST Requests)</h3>
    <p>In the case of form submissions, you typically handle POST requests. Here's an example of how to retrieve form data:</p>

    <pre>
  <code>{`
    <form action="MyServlet" method="POST">
        <label for="name">Name:</label>
        <input type="text" id="name" name="name">
        <input type="submit" value="Submit">
    </form>
  `}</code>
</pre><br />


    <p>In the servlet, you would handle the form submission like this:</p>
    <pre>
      <code>
        // Retrieving form data in doPost() method
        String name = request.getParameter("name");
      </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>Setting HTTP Response Attributes</h3>
    <ul>
      <li><strong>Setting Content Type:</strong> This determines how the response will be interpreted (HTML, JSON, etc.).</li>
      <pre><code>response.setContentType("text/html");</code></pre><br />
      <li><strong>Setting Response Headers:</strong> You can add custom headers to the response.</li>
      <pre><code>response.setHeader("Custom-Header", "value");</code></pre><br />
      <li><strong>Setting Response Status:</strong> You can set the HTTP status code for the response.</li>
      <pre><code>response.setStatus(HttpServletResponse.SC_OK);  // 200 OK</code></pre><br />
      <li><strong>Writing Data to Response:</strong> You can write content to the response using a <code>PrintWriter</code> object.</li>
      <pre><code>PrintWriter out = response.getWriter();</code></pre>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Redirecting or Forwarding Requests</h3>
    <ul>
      <li><strong>Redirect:</strong> Redirects the client to a different URL.</li>
      <pre><code>response.sendRedirect("https://www.example.com");</code></pre><br />
      <li><strong>Forward:</strong> Forwards the request to another resource.</li>
      <pre><code>
        RequestDispatcher dispatcher = request.getRequestDispatcher("/otherServlet");
        dispatcher.forward(request, response);
      </code></pre>
    </ul><br />

    <h3>Summary</h3>
    <p>Handling HTTP requests and responses efficiently is key to building robust web applications in Java EE (Jakarta EE).</p>
  </div>
)}


{selectedChapter === 'chapter15' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Request Dispatching and Forwarding </h1>

    <p>In Java EE (now Jakarta EE), request dispatching and forwarding are mechanisms used to forward the request to another resource, such as a servlet, JSP, or even a different URL. These mechanisms allow for efficient handling of requests and help structure web applications by delegating tasks between different components.</p><br />

    <h3>1. Request Dispatching</h3>
    <p>Request dispatching refers to the process where a servlet or JSP forwards the incoming request to another servlet, JSP, or static resource (like an HTML file) for further processing or rendering. The request remains the same; it is simply passed along to another component.</p>

    <p style={{paddingBottom:"6px"}}>In Java EE, request dispatching is done using the <code>RequestDispatcher</code> interface. The <code>RequestDispatcher</code> allows one component to forward the request to another, or to include content from another resource.</p>

    <h4>Example of Request Dispatching:</h4>
    <pre><code>{`
import javax.servlet.*;
import javax.servlet.http.*;
import java.io.IOException;

public class DispatcherServlet extends HttpServlet {

    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
        // Set an attribute for the request
        request.setAttribute("message", "Forwarding Request to Another Servlet");

        // Get the RequestDispatcher to forward the request to another servlet
        RequestDispatcher dispatcher = request.getRequestDispatcher("/anotherServlet");

        // Forward the request to the next resource
        dispatcher.forward(request, response);
    }
}
        `}    </code></pre>

    <p>In this example:</p>
    <ul>
      <li>The <code>doGet()</code> method sets an attribute <code>"message"</code> in the request.</li>
      <li>The <code>RequestDispatcher</code> is obtained using <code>request.getRequestDispatcher("/anotherServlet")</code>, where <code>"/anotherServlet"</code> is the URL of the target resource.</li>
      <li><code>dispatcher.forward(request, response)</code> forwards the request to the specified servlet.</li>
    </ul>

    <h4>Key Points about <code>RequestDispatcher</code>:</h4>
    <ul>
      <li><strong>forward()</strong>: Forwards the request to another resource on the server. The client is unaware of this forwarding.</li><br />
      <li><strong>include()</strong>: Includes content from another resource in the response, and the client sees the combined content. This is useful for including headers, footers, etc.</li>
    </ul><br />

    <h3>2. Request Forwarding</h3>
    <p>Request forwarding is closely related to request dispatching. It is a server-side operation where the incoming request is forwarded to another resource, and the response is generated by that resource. The client does not know that the request was forwarded to another resource.</p>

    <p>Forwarding is particularly useful when the server needs to pass control to another resource, such as:</p>
    <ul style={{paddingBottom:"6px"}}>
      <li>Passing a request to a JSP after processing it in a servlet.</li>
      <li>Redirecting a request to a different servlet for further processing.</li>
    </ul>

    <h4>Example of Request Forwarding to a JSP:</h4>
    <pre><code>{`
import javax.servlet.*;
import javax.servlet.http.*;
import java.io.IOException;

public class MyServlet extends HttpServlet {

    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
        // Set an attribute in the request
        request.setAttribute("username", "John Doe");

        // Forward the request to a JSP for rendering
        RequestDispatcher dispatcher = request.getRequestDispatcher("/welcome.jsp");
        dispatcher.forward(request, response);
    }
}
    `}</code></pre>

    <p>In this example:</p>
    <ul>
      <li>The servlet sets a <code>username</code> attribute in the request.</li>
      <li>The request is forwarded to <code>welcome.jsp</code>, where it can access the <code>username</code> attribute and display it.</li>
    </ul><br />

    <h3>3. Redirecting Requests</h3>
    <p style={{paddingBottom:"6px"}}>Unlike request dispatching or forwarding, which happen on the server side, a redirect sends an HTTP response back to the client with a status code (usually <code>302 Found</code>) and a new location (URL). The client then sends a new request to that location. Redirects are typically used to send the client to a different URL after some processing.</p>

    <h4>Example of Redirecting Requests:</h4>
    <pre><code>{`
import javax.servlet.*;
import javax.servlet.http.*;
import java.io.IOException;

public class RedirectServlet extends HttpServlet {

    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
        // Redirect the client to another URL
        response.sendRedirect("https://www.example.com");
    }
}
 `} </code></pre>

    <p>In this example:</p>
    <ul>
      <li>The <code>sendRedirect()</code> method is used to send a redirect response to the client, telling it to navigate to <code>https://www.example.com</code>.</li><br />
      <li>The client will make a new request to the specified URL, and the response from that URL will be returned to the client.</li>
    </ul><br />

    <h3>Differences between Request Forwarding and Redirecting:</h3>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Request Forwarding</th>
          <th>Redirecting</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Client Visibility</td>
          <td>Client is unaware of the forwarding (same URL)</td>
          <td>Client sees the new URL in the browser address bar</td>
        </tr>
        <tr>
          <td>Server-side or Client-side</td>
          <td>Server-side operation</td>
          <td>Client-side operation (new request is made)</td>
        </tr>
        <tr>
          <td>Response Generation</td>
          <td>Resource that handles the forwarded request generates the response</td>
          <td>New request generates a new response from the redirected URL</td>
        </tr>
        <tr>
          <td>Performance Impact</td>
          <td>More efficient (no new HTTP request)</td>
          <td>Slightly slower (client initiates a new request)</td>
        </tr>
      </tbody>
    </table><br />

    <h4>Example of Using <code>include()</code>:</h4>
    <pre><code>{`
import javax.servlet.*;
import javax.servlet.http.*;
import java.io.IOException;

public class IncludeServlet extends HttpServlet {

    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
        // Set content type
        response.setContentType("text/html");

        // Get output stream
        PrintWriter out = response.getWriter();

        // Include content from another servlet
        RequestDispatcher dispatcher = request.getRequestDispatcher("/header");
        dispatcher.include(request, response);

        // Write additional content after inclusion
        out.println("<h1>This is the main content</h1>");
    }
}
     `} </code></pre>

    <p>In this example:</p>
    <ul>
      <li>The servlet includes the output of another servlet (likely generating a header) using <code>dispatcher.include()</code>.</li><br />
      <li>The output from the <code>/header</code> servlet will appear before the main content.</li>
    </ul> <br />

    <h3 style={{paddingBottom:"6px"}}>Summary:</h3>
    <ul>
      <li><strong>Request Dispatching:</strong> Forwards the request to another resource (servlet, JSP, etc.), and the client is unaware of the forwarding. It uses <code>RequestDispatcher.forward()</code>.</li><br />
      <li><strong>Request Forwarding:</strong> A part of request dispatching, where one resource forwards the request to another resource on the server.</li><br />
      <li><strong>Redirecting:</strong> Sends a response to the client telling it to make a new HTTP request to another URL. The client sees the new URL.</li><br />
      <li><strong><code>RequestDispatcher.include()</code>:</strong> Includes content from another resource in the response, combining the output of multiple resources.</li>
    </ul><br />

    <p>Both request dispatching and forwarding are essential for maintaining a clean separation of concerns and delegating responsibilities between different parts of a Java EE (Jakarta EE) application.</p>
  </div>
)}


{selectedChapter === 'chapter16' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Session Management ( Cookies, HTTP sessions, URL rewriting)</h1>
   
    <p>
      In Java EE (now Jakarta EE), <strong>session management</strong> refers to maintaining a user's state across multiple requests in a web application. This is crucial for creating personalized and interactive applications, such as user login systems, shopping carts, and more.
    </p><br />
    <p>There are several mechanisms to manage sessions in Java EE, including **Cookies**, **HTTP Sessions**, and **URL Rewriting**.</p>

    <h4>1. Cookies</h4>
    <p style={{paddingBottom:"6px"}}>
      A <strong>cookie</strong> is a small piece of data stored on the client's machine, which is sent back to the server with every request. It is often used to store user-specific information, such as preferences or session identifiers, to help maintain a user’s session across multiple requests.
    </p>

    <h5>Example of Setting a Cookie in a Servlet:</h5>
    <pre>
      <code>{`
        import javax.servlet.*;
        import javax.servlet.http.*;
        import java.io.IOException;

        public class CookieServlet extends HttpServlet {
          @Override
          protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
            // Create a new cookie with a name and value
            Cookie userCookie = new Cookie("username", "JohnDoe");

            // Set the cookie's maximum age (in seconds)
            userCookie.setMaxAge(60 * 60);  // 1 hour

            // Add the cookie to the response
            response.addCookie(userCookie);

            // Send a response to the client
            response.getWriter().println("Cookie set for username: JohnDoe");
          }
        }
       `} </code>
    </pre>
    <p>
      In this example:
      <ul>
        <li>A **cookie** is created with the name `"username"` and value `"JohnDoe"`.</li><br />
        <li>The cookie’s maximum age is set to 1 hour (`60 * 60` seconds).</li><br />
        <li>The cookie is added to the response using `response.addCookie()`.</li>
      </ul>
    </p><br />

    <h5>Retrieving the Cookie in Another Servlet:</h5>
    <pre>
      <code>{`
        import javax.servlet.*;
        import javax.servlet.http.*;
        import java.io.IOException;

        public class CookieReaderServlet extends HttpServlet {
          @Override
          protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
            // Get all cookies from the request
            Cookie[] cookies = request.getCookies();

            if (cookies != null) {
              for (Cookie cookie : cookies) {
                if (cookie.getName().equals("username")) {
                  response.getWriter().println("Found cookie: " + cookie.getValue());
                }
              }
            } else {
              response.getWriter().println("No cookies found.");
            }
          }
        }
        `}  </code>
    </pre><br />

    <h4>2. HTTP Sessions</h4>
    <p style={{paddingBottom:"6px"}}>
      An <strong>HTTP session </strong>is a way to maintain state between requests from the same user across multiple interactions with the server. The session ID is stored either in a **cookie** or as part of the URL, allowing the server to track the user's activity during a session.
    </p>

    <h5>Example of Using an HTTP Session in a Servlet:</h5>
    <pre>
      <code>{`
        import javax.servlet.*;
        import javax.servlet.http.*;
        import java.io.IOException;

        public class SessionServlet extends HttpServlet {
          @Override
          protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
            // Get the current session, or create a new one if it doesn't exist
            HttpSession session = request.getSession(true);

            // Set an attribute in the session
            session.setAttribute("username", "JohnDoe");

            // Send a response to the client
            response.getWriter().println("Session created with username: JohnDoe");
          }
        }
        `}  </code>
    </pre>
    <p>
      In this example:
      <ul>
        <li>`request.getSession(true)` is used to get the current session or create a new one.</li><br />
        <li>A session attribute `"username"` is set to `"JohnDoe"`.</li>
      </ul>
    </p><br />

    <h5>Retrieving Session Attributes:</h5>
    <pre>
      <code>{`
        import javax.servlet.*;
        import javax.servlet.http.*;
        import java.io.IOException;

        public class SessionReaderServlet extends HttpServlet {
          @Override
          protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
            // Get the current session
            HttpSession session = request.getSession(false);

            if (session != null) {
              // Retrieve the session attribute
              String username = (String) session.getAttribute("username");

              if (username != null) {
                response.getWriter().println("Session contains username: " + username);
              } else {
                response.getWriter().println("No username in session.");
              }
            } else {
              response.getWriter().println("No session found.");
            }
          }
        }
       `} </code>
    </pre><br />

    <h4>3. URL Rewriting</h4>
    <p style={{paddingBottom:"6px"}}>
      <strong>URL Rewriting</strong> is a technique used to pass session information in the URL. This is necessary when the browser does not support cookies, or cookies are disabled by the user. The session ID is appended as a query parameter to the URL.
    </p>

    <h5>Example of URL Rewriting in a Servlet:</h5>
    <pre>
      <code>{`
        import javax.servlet.*;
        import javax.servlet.http.*;
        import java.io.IOException;

        public class URLRewritingServlet extends HttpServlet {
          @Override
          protected void doGet(HttpServletRequest request, HttpServletResponse response) throws ServletException, IOException {
            // Get the current session, or create a new one if it doesn't exist
            HttpSession session = request.getSession(true);

            // Set a session attribute
            session.setAttribute("username", "JohnDoe");

            // Get the session ID and encode the URL to include it
            String encodedURL = response.encodeURL("/nextPage");

            // Redirect to another page with the session ID in the URL
            response.sendRedirect(encodedURL);
          }
        }
      `}</code>
    </pre>
    <p>
      In this example:
      <ul>
        <li>`response.encodeURL("/nextPage")` encodes the URL by appending the session ID if necessary.</li><br />
        <li>`response.sendRedirect(encodedURL)` redirects the client to a new page, passing the session ID in the URL.</li>
      </ul>
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Summary</h3>
    <ul>
      <li><strong>Cookies</strong>: Used to store small pieces of data on the client’s machine, typically for maintaining session data or user preferences.</li><br />
      <li><strong>HTTP Sessions</strong>: Used to store user-specific data on the server-side, with the session ID passed via a cookie or URL.</li><br />
      <li><strong>URL Rewriting</strong>: A method of passing session information in the URL when cookies are disabled or unsupported.</li>
    </ul><br />
    <p>
      Each of these mechanisms is essential for maintaining a stateful interaction with users in Java EE applications. They provide flexibility depending on the client and server configuration and can be used in combination to ensure smooth session management.
    </p>
  </div>
)}


{selectedChapter === 'chapter17' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Filters and Listeners </h1>

    <p>
      In Java EE (Jakarta EE), <strong>Filters</strong> and <strong>Listeners</strong> are essential
      components for intercepting and responding to requests and events within a web application.
      They provide mechanisms to perform tasks such as logging, monitoring, authentication, and more,
      before or after a request is processed. Let’s break down what they are and how they work.
    </p><br />

   
      <h2>1. Filters</h2>
      <p style={{paddingBottom:"6px"}}>
        A <strong>filter</strong> is a Java class that can intercept requests and responses in a web
        application. Filters can be used for tasks like logging, authentication, input validation, and
        modifying request/response data.
      </p>

      <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Filters:</h3>
      <ul>
        <li><strong>Interception:</strong> Filters are executed before or after the request reaches a servlet, or before/after the response is sent to the client.</li><br />
        <li><strong>Chaining:</strong> Multiple filters can be chained together to perform a series of operations.</li><br />
        <li><strong>Non-blocking:</strong> Filters can modify the request/response, or pass them on to the next filter in the chain.</li>
      </ul><br />

      <h3>Filter Interface:</h3>
      <p>
        A filter class implements the <code>javax.servlet.Filter</code> interface, which requires
        the following methods:
      </p>
      <ol>
        <li><code>init(FilterConfig filterConfig)</code>: Initializes the filter.</li><br />
        <li><code>doFilter(ServletRequest request, ServletResponse response, FilterChain chain)</code>: 
          The main method where filtering occurs. You can modify the request or response, and pass control to the next filter in the chain.
        </li><br />
        <li><code>destroy()</code>: Cleans up any resources used by the filter.</li>
      </ol><br />

      <h3>Example of a Simple Filter:</h3>
      <pre><code>{`
import javax.servlet.*;
import javax.servlet.annotation.WebFilter;
import java.io.IOException;

@WebFilter("/protected/*")  // This filter applies to URLs under /protected
public class AuthenticationFilter implements Filter {

    @Override
    public void init(FilterConfig filterConfig) throws ServletException {
        System.out.println("Authentication Filter Initialized");
    }

    @Override
    public void doFilter(ServletRequest request, ServletResponse response, FilterChain chain)
            throws IOException, ServletException {
        
        String user = request.getParameter("user");
        if (user == null || user.isEmpty()) {
            response.getWriter().println("Authentication failed.");
            return; // Stop further processing if not authenticated
        }
        
        chain.doFilter(request, response);  // Proceed with next filter or servlet
    }

    @Override
    public void destroy() {
        System.out.println("Authentication Filter Destroyed");
    }
}
`}</code></pre><br />

      <h3>Declaring Filters in <code>web.xml</code> (if not using annotations):</h3>
      <pre><code>{`
<filter>
    <filter-name>AuthenticationFilter</filter-name>
    <filter-class>com.example.AuthenticationFilter</filter-class>
</filter>

<filter-mapping>
    <filter-name>AuthenticationFilter</filter-name>
    <url-pattern>/protected/*</url-pattern>
</filter-mapping>
      `}</code></pre><br />
 
      <h2>2. Listeners</h2>
      <p style={{paddingBottom:"6px"}}>
        A <strong>listener</strong> is a Java class that listens for events in a web application, 
        such as session creation, context initialization, or servlet context destruction. 
        Listeners can be used for various purposes, including logging, auditing, and cleaning up resources when events occur.
      </p>

      <h3 style={{paddingBottom:"6px"}}>Key Characteristics of Listeners:</h3>
      <ul>
        <li><strong>Event-driven:</strong> Listeners respond to specific lifecycle events, such as context initialization, session creation, or attribute changes.</li><br />
        <li><strong>Server-wide:</strong> Listeners are often used for managing resources that span the entire application, such as logging user activities or initializing application-wide resources.</li>
      </ul><br />

      <h3 style={{paddingBottom:"6px"}}>Common Listener Interfaces:</h3>
      <ul>
        <li><code>ServletContextListener</code>: Listens for context initialization and destruction (application-level events).</li><br />
        <li><code>HttpSessionListener</code>: Listens for session creation and destruction.</li><br />
        <li><code>ServletRequestListener</code>: Listens for request lifecycle events (request initialization and destruction).</li><br />
        <li><code>ServletContextAttributeListener</code>: Listens for changes to attributes in the <code>ServletContext</code>.</li><br />
        <li><code>HttpSessionAttributeListener</code>: Listens for changes to attributes in an <code>HttpSession</code>.</li>
      </ul><br />

      <h3>Example of a Simple Listener (HttpSessionListener):</h3>
      <pre><code>{`
import javax.servlet.*;
import javax.servlet.annotation.WebListener;
import javax.servlet.http.HttpSessionEvent;
import javax.servlet.http.HttpSessionListener;

@WebListener
public class MySessionListener implements HttpSessionListener {

    @Override
    public void sessionCreated(HttpSessionEvent se) {
        System.out.println("Session created: " + se.getSession().getId());
    }

    @Override
    public void sessionDestroyed(HttpSessionEvent se) {
        System.out.println("Session destroyed: " + se.getSession().getId());
    }
}
      `}</code></pre><br />

      <h3>Declaring Listeners in <code>web.xml</code> (if not using annotations):</h3>
      <pre><code>
<listener>
    <listener-class>com.example.MySessionListener</listener-class>
</listener>
      </code></pre><br />
    
      <h2 style={{paddingBottom:"6px"}}>Summary</h2>
      <p>
        <strong>Filters</strong> are used to intercept and modify requests and responses in a web application, 
        such as performing logging, authentication, or request modification.
      </p>
      <p>
        <strong>Listeners</strong> are used to listen for lifecycle events in a web application, such as session creation, 
        context initialization, or attribute changes.
      </p>
      <p>
        Both filters and listeners allow developers to manage cross-cutting concerns in a modular way, 
        making the application more maintainable and flexible. They are powerful tools in Java EE (Jakarta EE) 
        that help in building scalable and secure applications.
      </p>
  
  </div>
)}


{selectedChapter === 'chapter18' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>JSP Lifecycle and Architecture </h1>

    <p>In Java EE (Enterprise Edition), <strong>JSP (JavaServer Pages)</strong> is a technology used to create dynamic, server-side web applications. Understanding the JSP lifecycle and architecture is crucial for developers working with JSP in a Java EE environment. Here's a breakdown of the <strong>JSP lifecycle</strong> and <strong>architecture</strong>:</p>

    <h2>JSP Lifecycle:</h2>
    <ol>
      <li><strong>Translation Phase:</strong>
        <ul>
          <li>When a JSP page is requested for the first time, or if the JSP file has been modified, the JSP container (like Apache Tomcat) needs to compile the JSP into a servlet.</li><br />
          <li>The JSP container translates the JSP into a Java servlet, which can then be executed on the server.</li><br />
          <li>This translation happens automatically the first time the JSP page is accessed or after a change is detected.</li><br />
          <li>The translated servlet is stored in the container for future requests.</li>
        </ul>
      </li><br />

      <li><strong>Compilation Phase:</strong>
        <ul>
          <li>After translation, the JSP is compiled into a Java servlet.</li><br />
          <li>The servlet is then compiled into bytecode, which can be executed by the JVM.</li><br />
          <li>This compilation happens only when the JSP file is first requested or recompiled due to changes.</li>
        </ul>
      </li><br />

      <li><strong>Initialization Phase:</strong>
        <ul>
          <li>After compilation, the servlet is loaded into memory.</li><br />
          <li>The servlet container calls the <code>init()</code> method on the servlet (a method inherited from <code>javax.servlet.Servlet</code>).</li><br />
          <li>This phase initializes the servlet, setting up necessary resources (e.g., database connections, shared data, etc.).</li>
        </ul>
      </li><br />

      <li><strong>Request Processing Phase:</strong>
        <ul>
          <li>After initialization, each request to the JSP page is handled by the <code>service()</code> method of the servlet.</li><br />
          <li>The container creates a new request and response object and invokes the <code>service()</code> method to process the HTTP request.</li><br />
          <li>The JSP is executed to generate the HTML response, which is sent back to the client.</li><br />
          <li>This phase can happen multiple times as long as the page is being accessed by users.</li>
        </ul>
      </li><br />

      <li><strong>Destroy Phase:</strong>
        <ul>
          <li>When the web application is being undeployed or the servlet container is shutting down, the container invokes the <code>destroy()</code> method of the servlet.</li><br />
          <li>This is where you can release any resources, like database connections or open files.</li><br />
          <li>After this phase, the servlet is removed from memory.</li>
        </ul>
      </li>
    </ol><br />

    <h2>JSP Architecture:</h2>
    <p>The JSP architecture involves several key components and layers that interact during the request/response lifecycle:</p>

    <ol>
      <li><strong>Client (Browser):</strong> The client sends an HTTP request (typically via a browser) to the JSP page, requesting dynamic content or services.</li><br />
      <li><strong>Web Server/Servlet Container (e.g., Apache Tomcat):</strong> The web server or servlet container is responsible for managing JSP files, translating them to servlets, and responding to client requests. It handles the <strong>JSP container</strong> (which compiles JSPs into servlets) and the <strong>Servlet container</strong> (which manages servlet lifecycle).</li><br />
      <li><strong>JSP Page:</strong> The JSP page is a file with .jsp extension, containing a mix of HTML, JSP tags, and Java code (scriptlets, expressions, and declarations). The JSP container converts this page into a servlet.</li><br />
      <li><strong>JSP Servlet (Generated Servlet):</strong> After the JSP is compiled, the resulting Java servlet extends <code>HttpServlet</code> and overrides the <code>doGet()</code> or <code>doPost()</code> methods. This servlet is responsible for generating the dynamic content, based on the request, and sending it to the client.</li><br />
      <li><strong>JavaBeans / EJB / Other Server-side Components:</strong> Server-side components like <strong>JavaBeans</strong> or <strong>Enterprise JavaBeans (EJBs)</strong> are used to handle business logic or data processing in JSP pages. These components are used for logic and database access.</li>
      <li><strong>Servlets:</strong> Servlets handle the logic and business processes that JSPs cannot directly perform, such as database interaction, business logic, etc.</li><br />
      <li><strong>Database / Back-End Services:</strong> Back-end services like databases and web services are often accessed by JSP pages via servlets, JavaBeans, or EJBs. Data can be retrieved using JDBC or JPA.</li>
    </ol><br />

    <h2 style={{paddingBottom:"6px"}}>JSP Components:</h2>
    <ul>
      <li><strong>Directives:</strong> Provide information to the JSP container (e.g., <code>&lt;%@ page language="java" contentType="text/html" %&gt;</code>).</li><br />
      <li><strong>Declarations:</strong> Declare variables or methods that are used in the page (e.g., <code>&lt;%! int count = 0; %&gt;</code>).</li><br />
      <li><strong>Scriptlets:</strong> Java code that is executed during request processing (e.g., <code>&lt;% count++; %&gt;</code>).</li><br />
      <li><strong>Expressions:</strong> Output a value directly to the response (e.g., <code>&lt;%= count %&gt;</code>).</li><br />
      <li><strong>Action Tags:</strong> Tags that enable dynamic behavior like including other resources or forwarding requests (e.g., <code>&lt;jsp:include&gt;</code>).</li><br />
      <li><strong>Expression Language (EL):</strong> A simplified way to access Java objects (e.g., <code>{"${user.name}"}</code>).</li><br />
      <li><strong>JSTL (JSP Standard Tag Library):</strong> A set of core and formatting tags that simplify tasks like iteration, conditional logic, and data formatting.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>JSP vs. Servlets:</h2>
    <ul>
      <li><strong>JSP:</strong> Focused on the presentation layer, used for creating HTML responses.</li><br />
      <li><strong>Servlets:</strong> Used for handling business logic, HTTP requests, and session management, often acting as the controller in the MVC design pattern.</li>
    </ul><br />

    <p>In <strong>MVC architecture</strong>, JSP acts as the <strong>View</strong>, servlets handle the <strong>Controller</strong> logic, and JavaBeans or EJBs manage the <strong>Model</strong> (data and business logic). This architecture allows Java EE applications to be modular and maintainable.</p>
  </div>
)}


{selectedChapter === 'chapter19' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Scriptlets, Expressions, and Declarations</h1>

    <p>
      In Java EE, particularly in JSP (JavaServer Pages), <strong>scriptlets</strong>, 
      <strong>expressions</strong>, and <strong>declarations</strong> are ways to embed Java code 
      directly into the HTML structure of a JSP page. While they were commonly used in older JSP applications, 
      they are less favored in modern development due to the advent of technologies like JSTL (JSP Standard Tag Library) 
      and Expression Language (EL), which provide cleaner and more maintainable code. However, understanding these constructs 
      is still important, as they are part of the JSP specification.
    </p><br />

    <h2>1. Scriptlets{" (`<% ... %>`) "}</h2>
    <p>
      A <strong>scriptlet</strong> is a block of Java code embedded within a JSP page. It is executed on the server each time 
      the JSP is processed. Scriptlets can contain any valid Java code and can perform dynamic content generation, such as 
      manipulating data or interacting with business logic.
    </p>

    <strong>Example:</strong>
    <pre><code className="language-jsp">
      &lt;% 
          int counter = 0;
          counter++;
          out.println("Counter value: " + counter);
      %&gt;
    </code></pre>
    <p>
      In this example:
      <ul>
        <li>The scriptlet increments a counter and then outputs the value to the response using the <code>out.println</code> method.</li>
      </ul>
    </p>

    <strong>Key Points:</strong>
    <ul>
      <li>Scriptlets are placed within <code>&lt;% ... %&gt;</code> tags.</li><br />
      <li>They allow for dynamic generation of HTML or other content.</li><br />
      <li>Scriptlets interact directly with underlying Java code.</li><br />
      <li><strong>Not recommended for modern development</strong> due to readability and maintainability concerns.</li>
    </ul><br />

    <h2>2. Expressions {" (`<% ... %>`) "} </h2>
    <p>
      An <strong>expression</strong> is used to output the result of an expression directly to the response (HTML). It is a shorthand 
      for calling the <code>out.print()</code> method. The value inside the expression is automatically converted to a string and 
      sent to the client as part of the response.
    </p>

    <strong>Example:</strong>
    <pre><code className="language-jsp">
      &lt;%= "Hello, World!" %&gt;
      &lt;%= 5 + 3 %&gt;
    </code></pre>
    <p>
      In this example:
      <ul>
        <li>The first expression outputs <code>"Hello, World!"</code>.</li><br />
        <li>The second expression evaluates <code>5 + 3</code> and outputs <code>8</code>.</li>
      </ul>
    </p><br />

    <strong>Key Points:</strong>
    <ul>
      <li>Expressions are placed within <code>&lt;%= ... %&gt;</code> tags.</li><br />
      <li>They automatically output the result of the expression to the response.</li><br />
      <li>Expressions are evaluated when the page is processed and send the result to the client.</li><br />
      <li><strong>Less flexible than scriptlets</strong>, as they are mainly used for output and not complex logic.</li>
    </ul><br />

    <h2>3. Declarations {" (`<% ... %>`) "} </h2>
    <p>
      A <strong>declaration</strong> is used to define variables or methods at the class level in a JSP page. Declarations are typically 
      placed outside the service methods, making them available to all request cycles. They can be used to define instance variables or 
      helper methods that are shared across multiple requests to the JSP page.
    </p>

    <strong>Example:</strong>
<pre><code className="language-jsp">{`
  <%!
      private int counter = 0;

      public int incrementCounter() {
          counter++;
          return counter;
      }
  %>

  <%= incrementCounter() %>
`}</code></pre>

    <p>
      In this example:
      <ul>
        <li>The declaration defines a <code>counter</code> variable and an <code>incrementCounter()</code> method.</li><br />
        <li>The method is then called within an expression (<code>&lt;%= incrementCounter() %&gt;</code>) to display the updated counter value.</li>
      </ul>
    </p><br />

    <strong>Key Points:</strong>
    <ul>
      <li>Declarations are placed within <code>&lt;%! ... %&gt;</code> tags.</li><br />
      <li>They define variables or methods that are scoped to the JSP page.</li><br />
      <li><strong>Declarations are part of the class</strong> that the JSP page translates into, and can be used across multiple requests.</li>
    </ul><br />

    <h2>Comparison of Scriptlets, Expressions, and Declarations</h2>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Scriptlets{" (`<% ... %>`) "}</th>
          <th>Expressions{" (`<%= ... %>`)  "}</th>
          <th>Declarations{" (`<%! ... %>`)  "}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Purpose</td>
          <td>Contains Java code to execute (logic)</td>
          <td>Outputs the result of an expression</td>
          <td>Declares variables or methods</td>
        </tr>
        <tr>
          <td>Syntax</td>
          <td><code>&lt;% ... %&gt;</code></td>
          <td><code>&lt;%= ... %&gt;</code></td>
          <td><code>&lt;%! ... %&gt;</code></td>
        </tr>
        <tr>
          <td>Use Case</td>
          <td>For handling complex logic</td>
          <td>For simple output of expressions</td>
          <td>For defining variables/methods</td>
        </tr>
        <tr>
          <td>Output to Response</td>
          <td>No, Java code execution only</td>
          <td>Yes, direct output to response</td>
          <td>No, used for internal logic only</td>
        </tr>
        <tr>
          <td>Example Use</td>
          <td>Loops, conditionals, data processing</td>
          <td>Simple calculations or string output</td>
          <td>Class-level variables, helper methods</td>
        </tr>
      </tbody>
    </table><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices (Modern JSP Development)</h2>
    <ul>
      <li><strong>Avoid scriptlets:</strong> Embedding Java logic directly in JSP pages makes it difficult to maintain and understand. Consider using JSTL and EL for cleaner code.</li><br />
      <li><strong>Use JSTL and EL:</strong> These provide cleaner and more readable code for handling dynamic content generation and interactions.</li><br />
      <li><strong>Follow MVC Pattern:</strong> JSP should be used for the View layer in the MVC pattern, while business logic should be handled by servlets or managed beans (JavaBeans or EJBs).</li>
    </ul><br />
  </div>
)}



{selectedChapter === 'chapter20' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Implicit Objects in JSP</h1>

    <p>
      In Java EE, particularly in JSP (JavaServer Pages), <strong>implicit objects</strong> are pre-defined objects that are automatically available within the JSP page without needing explicit declaration or initialization. These objects are provided by the JSP container and represent various aspects of the request, response, and the environment in which the JSP page is executed.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Here is a summary of the key <strong>implicit objects</strong> in JSP:</h2>

    <h3>1.`request`</h3>
    <p>
      <strong>Type:</strong> HttpServletRequest
    </p>
    <p>
      <strong>Purpose:</strong> Provides access to the client's request information, including parameters, headers, attributes, etc.
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;p&gt;Request parameter: &lt;%= request.getParameter("username") %&gt;&lt;/p&gt;
        </code>
      </pre>
    </p><br />

    <h3>2. `response`</h3>
    <p>
      <strong>Type:</strong> HttpServletResponse
    </p>
    <p>
      <strong>Purpose:</strong> Allows the JSP page to send a response to the client, typically in the form of HTML content, status codes, or headers.
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;% response.setContentType("text/html"); %&gt;
          &lt;% response.getWriter().println("&lt;h1&gt;Hello, World!&lt;/h1&gt;"); %&gt;
        </code>
      </pre>
    </p><br />

    <h3>3. `out`</h3>
    <p>
      <strong>Type:</strong> JspWriter
    </p>
    <p>
      <strong>Purpose:</strong> Used to send output to the client (typically HTML). It is commonly used for writing dynamic content to the response.
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;p&gt;Current time: &lt;%= out.println(new java.util.Date()) %&gt;&lt;/p&gt;
        </code>
      </pre>
    </p><br />

    <h3>4. `session`</h3>
    <p>
      <strong>Type:</strong> HttpSession
    </p>
    <p>
      <strong>Purpose:</strong> Provides access to the current session, allowing you to store and retrieve session-specific data (e.g., user authentication details).
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;% session.setAttribute("username", "JohnDoe"); %&gt;
        </code>
      </pre>
    </p><br />

    <h3>5. `application`</h3>
    <p>
      <strong>Type:</strong> ServletContext
    </p>
    <p>
      <strong>Purpose:</strong> Provides access to application-level information and allows sharing data between all servlets and JSPs within the application.
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;p&gt;Application name: &lt;%= application.getAttribute("appName") %&gt;&lt;/p&gt;
        </code>
      </pre>
    </p><br />

    <h3>6. `config`</h3>
    <p>
      <strong>Type:</strong> ServletConfig
    </p>
    <p>
      <strong>Purpose:</strong> Provides access to configuration data specific to the servlet that is associated with the current JSP page.
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;p&gt;Servlet Name: &lt;%= config.getServletName() %&gt;&lt;/p&gt;
        </code>
      </pre>
    </p><br />

    <h3>7. `pageContext`</h3>
    <p>
      <strong>Type:</strong> PageContext
    </p>
    <p>
      <strong>Purpose:</strong> Provides a variety of functionalities like accessing the request, session, and application objects, and managing attributes.
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;% String username = (String) pageContext.getAttribute("username"); %&gt;
        </code>
      </pre>
    </p><br />

    <h3>8. `page`</h3>
    <p>
      <strong>Type:</strong> Object (refers to the current instance of the JSP page)
    </p>
    <p>
      <strong>Purpose:</strong> Refers to the current JSP page itself. It can be used to access any attributes or methods within the page.
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;p&gt;Page context: &lt;%= page.getClass().getName() %&gt;&lt;/p&gt;
        </code>
      </pre>
    </p><br />

    <h3>9. `exception`</h3>
    <p>
      <strong>Type:</strong> Throwable
    </p>
    <p>
      <strong>Purpose:</strong> Available only in error pages (i.e., JSP pages defined with <code>&lt;error-page&gt;</code> in <code>web.xml</code>). It provides access to the exception object that was thrown.
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;h3&gt;Error: &lt;%= exception.getMessage() %&gt;&lt;/h3&gt;
        </code>
      </pre>
    </p><br />

    <h3>10. `implicit` (EL expressions)</h3>
    <p>
      <strong>Type:</strong> Various (e.g., String, Integer, etc.)
    </p>
    <p>
      <strong>Purpose:</strong> With JSP 2.0 and onwards, EL (Expression Language) makes implicit objects accessible as well, such as:
      <ul>
        <li><code>{"${param}"}</code> for request parameters.</li>
        <li><code>{"${sessionScope}"}</code> for session attributes.</li>
        <li><code>{"${applicationScope}"}</code> for application attributes.</li>
        <li><code>{"${pageScope}"}</code> for page-level attributes.</li>
        <li><code>{"${cookie}"}</code> for cookies.</li>
        <li><code>{"${initParam}"}</code> for initialization parameters.</li>
      </ul>
    </p>
    <p>
      <strong>Example:</strong>
      <pre>
        <code className="language-jsp">
          &lt;p&gt;Request parameter:{"${param.username}"}&lt;/p&gt;
          &lt;p&gt;Session attribute: {"${sessionScope.userName}"}&lt;/p&gt;
        </code>
      </pre>
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Points about Implicit Objects:</h3>
    <ul>
      <li><strong>Availability:</strong> Implicit objects are automatically available in the JSP page and do not need to be declared.</li><br />
      <li><strong>Scope:</strong> These objects generally have different scopes, such as request, session, and application, which help determine their lifecycle and accessibility.</li><br />
      <li><strong>Convenience:</strong> They provide a convenient way to access commonly used objects like the request, response, session, etc., without the need for manual instantiation or initialization.</li>
    </ul><br />

    <h3>Example of Using Implicit Objects in JSP:</h3>
    <pre>
      <code className="language-jsp">{`
      <%@ page contentType="text/html; charset=UTF-8" language="java" %>
<html>
<head>
    <title>Implicit Objects Example</title>
</head>
<body>
    <h1>JSP Implicit Objects</h1>
    <p>Request parameter: <%= request.getParameter("username") %></p>
    <p>Session attribute: <%= session.getAttribute("username") %></p>
    <p>Application attribute: <%= application.getAttribute("appName") %></p>
</body>
</html>

      `}</code>
    </pre>
  </div>
)}


{selectedChapter === 'chapter21' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Expression Language (EL) </h1>
    <p>
      <strong>Expression Language (EL)</strong> in Java EE is a powerful scripting language used to simplify the access
      and manipulation of data stored in JavaBeans components, request parameters, attributes, and other objects in a
      JSP or Java-based web application. EL is primarily used to reduce the verbosity of Java code in JSP pages,
      enhancing readability and maintainability.
    </p>
<br />

    <h2>Key Features of EL</h2>
    <ol>
      <li>
        <strong>Accessing Data</strong>: EL allows direct access to:
        <ul>
          <li>Scoped variables (request, session, application, page)</li>
          <li>JavaBeans properties</li>
          <li>Collections and arrays</li>
          <li>Implicit objects (e.g., request parameters, cookies)</li>
        </ul>
      </li><br />
      <li>
        <strong>Operators</strong>: EL supports basic operators such as:
        <ul>
          <li>Arithmetic: <code>+</code>, <code>-</code>, <code>*</code>, <code>/</code>, <code>%</code></li>
          <li>Relational: <code>==</code>, <code>!=</code>, <code>&lt;</code>, <code>&gt;</code>, <code>&lt;=</code>,
            <code>&gt;=</code></li>
          <li>Logical: <code>&&</code>, <code>||</code>, <code>!</code></li>
          <li>Conditional: <code>?:</code> (ternary operator)</li>
        </ul>
      </li>
      <li><strong>Type Conversion</strong>: EL automatically performs type conversions (e.g., String to Number, Number to Boolean).</li>
      <li><strong>Null Handling</strong>: EL gracefully handles <code>null</code> values to avoid <code>NullPointerException</code>.</li>
    </ol>

  <br />

    <h2>Implicit Objects in EL</h2>
    <table>
      <thead>
        <tr>
          <th>Object Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><code>param</code></td>
          <td>Accesses request parameters (e.g., <code>param.username</code>).</td>
        </tr>
        <tr>
          <td><code>paramValues</code></td>
          <td>Accesses an array of all values for a request parameter.</td>
        </tr>
        <tr>
          <td><code>header</code></td>
          <td>Accesses request headers.</td>
        </tr>
        <tr>
          <td><code>headerValues</code></td>
          <td>Accesses an array of all values for a request header.</td>
        </tr>
        <tr>
          <td><code>cookie</code></td>
          <td>Accesses cookie values (e.g., <code>cookie.username.value</code>).</td>
        </tr>
        <tr>
          <td><code>initParam</code></td>
          <td>Accesses context initialization parameters.</td>
        </tr>
        <tr>
          <td><code>pageContext</code></td>
          <td>Accesses the <code>PageContext</code> object.</td>
        </tr>
        <tr>
          <td><code>requestScope</code></td>
          <td>Accesses attributes stored in the request scope.</td>
        </tr>
        <tr>
          <td><code>sessionScope</code></td>
          <td>Accesses attributes stored in the session scope.</td>
        </tr>
        <tr>
          <td><code>applicationScope</code></td>
          <td>Accesses attributes stored in the application scope.</td>
        </tr>
      </tbody>
    </table>

   <br />

    <h2>EL Example</h2>
    <p>Here’s a complete JSP example using EL:</p>
    <pre>
      <code className="language-jsp">{`

<%@ page contentType="text/html; charset=UTF-8" language="java" %>
<html>
  <head>
    <title>Expression Language Example</title>
  </head>
  <body>
    <p>Request Parameter: $/{param.username}</p>
    <p>Session Attribute: $/{sessionScope.loggedInUser}</p>
    <p>Application Attribute: $/{applicationScope.appName}</p>
    <p>User Name: $/{user.name}</p>
    <p>Map Value: $/{myMap['key']}</p>
  </body>
</html>

       `} </code>
    </pre>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Advantages of EL</h2>
    <ul>
      <li>Simplifies JSP code, making it easier to write and read.</li><br />
      <li>Enhances separation of concerns by reducing Java code in JSP files.</li><br />
      <li>Automatic type conversion and null handling reduce boilerplate code.</li><br />
      <li>Seamless integration with JSTL and modern MVC frameworks like Spring.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
    <ul>
      <li>Prefer EL and JSTL over scriptlets (<code>&lt;% %&gt;</code>).</li><br />
      <li>Avoid complex logic in EL; handle it in the controller layer.</li><br />
      <li>Use proper scope (request/session/application) to avoid unnecessary memory usage.</li>
    </ul>
  </div>
)}


{selectedChapter === 'chapter22' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Java Standard Tag Library (JSTL)</h1>
    
    <p>
      The <strong>Java Standard Tag Library (JSTL)</strong> is a core part of Java EE that simplifies the 
      development of dynamic web pages by providing a standard set of custom tags for common tasks. It works 
      alongside JSP (JavaServer Pages) to enable developers to embed logic into HTML without relying heavily 
      on Java code or scriptlets (<code>&lt;% %&gt;</code>).
    </p>

    <br />

    <h2>Features of JSTL</h2>
    <p>JSTL supports:</p>
    <ul>
      <li><strong>Core functionalities:</strong> Loops, conditionals, variable manipulation, and URL handling.</li><br />
      <li><strong>Formatting utilities:</strong> Formatting dates, numbers, and handling internationalization.</li><br />
      <li><strong>SQL interactions:</strong> Executing SQL queries and updates (for development or prototyping).</li><br />
      <li><strong>XML processing:</strong> Parsing and interacting with XML data.</li><br />
      <li><strong>String and collection utilities:</strong> Simple functions for common operations.</li>
    </ul>

 <br />

    <h2>Libraries and Tag URIs</h2>
    <table>
      <thead>
        <tr>
          <th>Library</th>
          <th>URI</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Core Library</td>
          <td><code>http://java.sun.com/jsp/jstl/core</code></td>
          <td>Basic functionalities like iteration, conditionals.</td>
        </tr>
        <tr>
          <td>Formatting Library</td>
          <td><code>http://java.sun.com/jsp/jstl/fmt</code></td>
          <td>Formatting dates, numbers, and localization.</td>
        </tr>
        <tr>
          <td>SQL Library</td>
          <td><code>http://java.sun.com/jsp/jstl/sql</code></td>
          <td>Simplistic database interaction (not for production).</td>
        </tr>
        <tr>
          <td>XML Library</td>
          <td><code>http://java.sun.com/jsp/jstl/xml</code></td>
          <td>Processing and manipulating XML data.</td>
        </tr>
        <tr>
          <td>Functions Library</td>
          <td><code>http://java.sun.com/jsp/jstl/functions</code></td>
          <td>String and collection utility functions.</td>
        </tr>
      </tbody>
    </table>

   <br />

    <h2>Adding JSTL to a Project</h2>
    <ol>
      <li>
        Include the JSTL library in your project dependencies.<br />
        <strong>Maven Dependency:</strong>
        <pre>
          <code>
            &lt;dependency&gt;
                &lt;groupId&gt;javax.servlet&lt;/groupId&gt;
                &lt;artifactId&gt;jstl&lt;/artifactId&gt;
                &lt;version&gt;1.2&lt;/version&gt;
            &lt;/dependency&gt;
          </code>
        </pre>
      </li><br />
      <li>
        Use the appropriate namespace in your JSP file using the <code>taglib</code> directive:
        <pre>
          <code>
            &lt;%@ taglib uri="http://java.sun.com/jsp/jstl/core" prefix="c" %&gt;
          </code>
        </pre>
      </li>
    </ol>
<br />
    <h2>Examples</h2>

    <h3>1. Core Tag Example</h3>
    <pre>
      <code>
        &lt;c:if test="{"${userLoggedIn}"}"&gt;
            &lt;p&gt;Welcome back,{"${userName}"}!&lt;/p&gt;
        &lt;/c:if&gt;
      </code>
    </pre><br />

    <h3>2. Formatting Tag Example</h3>
    <pre>
      <code>
        &lt;fmt:formatDate value="{"${currentDate}"}" pattern="yyyy-MM-dd" /&gt;
      </code>
    </pre><br />

    <h3>3. SQL Tag Example</h3>
    <pre>
      <code>
        &lt;sql:setDataSource var="db" ... /&gt;
      </code>
    </pre>
<br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
    <ul>
      <li>Avoid scriptlets (<code>&lt;% %&gt;</code>) in JSP; prefer JSTL and EL for cleaner code.</li><br />
      <li>Use SQL tags only for prototyping or small-scale applications.</li><br />
      <li>Separate business logic from JSP pages to maintain a clear MVC structure.</li>
    </ul><br />

    <p>
      By incorporating JSTL in your Java EE projects, you can build dynamic, maintainable, and efficient web 
      applications with minimal boilerplate code.
    </p>
  </div>
)}



{selectedChapter === 'chapter23' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      MVC Architecture Using Servlets and JSP 
    </h1>
    <p>
      MVC (Model-View-Controller) is a widely used architectural pattern for
      designing web applications. In the context of Java EE, it is commonly
      implemented using <strong>Servlets</strong> as the controller,{" "}
      <strong>JSP</strong> for the view, and Java classes for the model.
    </p>
   <br />
    <h2>Key Components of MVC</h2>
    <ol>
      <li>
        <strong>Model:</strong>
        <ul>
          <li>Represents the application's data and business logic.</li><br />
          <li>Interacts with the database and performs data-related operations.</li><br />
          <li>Typically implemented using JavaBeans, POJOs, or DAO classes.</li>
        </ul>
      </li><br />
      <li>
        <strong>View:</strong>
        <ul>
          <li>Handles the user interface (UI).</li><br />
          <li>Displays data from the model to the user.</li><br />
          <li>In Java EE, this is implemented using JSP pages.</li>
        </ul>
      </li><br />
      <li>
        <strong>Controller:</strong>
        <ul>
          <li>Manages the flow of the application.</li><br />
          <li>Processes user input and decides what to do next.</li><br />
          <li>In Java EE, this is implemented using Servlets.</li>
        </ul>
      </li>
    </ol>
<br />

    <h2 style={{paddingBottom:"6px"}}>Steps to Implement MVC Architecture</h2>

    <h3>1. Create the Model</h3>
    <p>
      The model is usually a POJO class representing data entities or a service
      class interacting with the database.
    </p>
    <pre>
      <code className={style.codeBlock}>
        {`public class User {
    private String username;
    private String email;

    // Constructors
    public User(String username, String email) {
        this.username = username;
        this.email = email;
    }

    // Getters and Setters
    public String getUsername() {
        return username;
    }

    public void setUsername(String username) {
        this.username = username;
    }

    public String getEmail() {
        return email;
    }

    public void setEmail(String email) {
        this.email = email;
    }
}`}
      </code>
    </pre><br />

    <h3>2. Create the View (JSP)</h3>
    <p>
      JSP is used to present data to the user. It can include HTML, CSS, and JSTL
      for dynamic content.
    </p>
    <pre>
      <code className={style.codeBlock}>
        {`<!DOCTYPE html>
<html>
<head>
    <title>User Details</title>
</head>
<body>
    <h1>User Details</h1>
    <p>Username: $\{user.username}/</p>
    <p>Email: $\{user.email}/</p>
</body>
</html>`}
      </code>
    </pre><br />

    <h3>3. Create the Controller (Servlet)</h3>
    <p>
      The controller (Servlet) processes user requests and determines the view
      to render. It retrieves data from the model and sets it in the request or
      session scope.
    </p>
    <pre>
      <code className={style.codeBlock}>
        {`import jakarta.servlet.ServletException;
import jakarta.servlet.annotation.WebServlet;
import jakarta.servlet.http.HttpServlet;
import jakarta.servlet.http.HttpServletRequest;
import jakarta.servlet.http.HttpServletResponse;
import java.io.IOException;

@WebServlet("/user")
public class UserController extends HttpServlet {
    @Override
    protected void doGet(HttpServletRequest request, HttpServletResponse response)
            throws ServletException, IOException {
        // Simulate fetching user data
        User user = new User("JohnDoe", "john@example.com");

        // Set user in the request scope
        request.setAttribute("user", user);

        // Forward to the JSP (View)
        request.getRequestDispatcher("userView.jsp").forward(request, response);
    }
}`}
      </code>
    </pre><br />

    <h3>4. Configure Deployment Descriptor (web.xml)</h3>
    <p>
      If you are not using annotations, define servlet mappings in{" "}
      <code>web.xml</code>.
    </p>
    <pre>
      <code className={style.codeBlock}>
        {`<web-app xmlns="http://java.sun.com/xml/ns/javaee" version="3.0">
    <servlet>
        <servlet-name>UserController</servlet-name>
        <servlet-class>UserController</servlet-class>
    </servlet>
    <servlet-mapping>
        <servlet-name>UserController</servlet-name>
        <url-pattern>/user</url-pattern>
    </servlet-mapping>
</web-app>`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>How It Works</h2>
    <ol>
      <li>The client sends a request to the controller (Servlet).</li><br />
      <li>
        The controller processes the request, interacts with the model to fetch
        or manipulate data, and sets data in the request or session scope.
      </li><br />
      <li>The controller forwards the request to the JSP (View).</li><br />
      <li>
        The JSP renders the data and sends the response back to the client.
      </li>
    </ol>
   <br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of MVC in Java EE</h2>
    <ul>
      <li>
        <strong>Separation of Concerns:</strong> Decouples the UI, business
        logic, and control flow.
      </li><br />
      <li>
        <strong>Scalability:</strong> Easy to maintain and extend individual
        components.
      </li><br />
      <li>
        <strong>Reusability:</strong> Components can be reused across different
        parts of the application.
      </li>
    </ul><br />
    <p>
      This pattern makes Java EE web applications clean, modular, and
      maintainable.
    </p>
  </div>
)}


{selectedChapter === 'chapter24' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>JDBC API Overview </h1>
    <div className={style.content}>
      <p>
        The <strong>Java Database Connectivity (JDBC)</strong> API is a key component of Java EE that provides a standard interface for connecting Java applications to relational databases. It is used to execute SQL statements, retrieve results, and interact with database systems in a platform-independent way.
      </p>
<br />
      <h2>Key Components of JDBC API</h2>
      <ol>
        <li>
          <strong>DriverManager</strong>
          <ul>
            <li>Manages a list of database drivers.</li><br />
            <li>
              Establishes a connection to the database using appropriate drivers. Example:
              <pre>
                <code>
{`Connection conn = DriverManager.getConnection("jdbc:mysql://localhost:3306/mydb", "username", "password");`}
                </code>
              </pre>
            </li>
          </ul>
        </li><br />

        <li>
          <strong>Connection</strong>
          <ul>
            <li>Represents a session with a specific database.</li>
            <li>Manages transactions and provides methods for creating statements. Example:
              <pre>
                <code>{`Statement stmt = conn.createStatement();`}</code>
              </pre>
            </li>
          </ul>
        </li><br />

        <li>
          <strong>Statement</strong>
          <ul>
            <li>Used to execute SQL queries against the database.</li>
            <li>
              Types:
              <ul>
                <li><code>Statement</code>: Executes static SQL queries.</li><br />
                <li><code>PreparedStatement</code>: Executes parameterized queries.</li><br />
                <li><code>CallableStatement</code>: Executes stored procedures.</li>
              </ul>
            </li>
          </ul>
        </li><br />

        <li>
          <strong>ResultSet</strong>
          <ul>
            <li>Represents the results of a query.</li>
            <li>Provides methods to iterate through rows of data and retrieve individual column values. Example:
              <pre>
                <code>
{`while (rs.next()) {
  String username = rs.getString("username");
}`}
                </code>
              </pre>
            </li>
          </ul>
        </li><br />

        <li>
          <strong>Driver</strong>
          <ul>
            <li>A specific implementation that connects Java applications to a particular database.</li>
            <li>JDBC drivers are database-specific.</li>
          </ul>
        </li>
      </ol>

     <br />

      <h2>JDBC Driver Types</h2>
      <ol>
        <li>
          <strong>Type-1: JDBC-ODBC Bridge Driver</strong>
          <p>Uses ODBC drivers; platform-dependent. Deprecated and rarely used.</p>
        </li><br />
        <li>
          <strong>Type-2: Native-API Driver</strong>
          <p>Uses native libraries for database communication. Platform-dependent.</p>
        </li><br />
        <li>
          <strong>Type-3: Network Protocol Driver</strong>
          <p>Communicates with the database through a middleware server. Platform-independent.</p>
        </li><br />
        <li>
          <strong>Type-4: Thin Driver</strong>
          <p>Pure Java implementation; communicates directly with the database. Most commonly used in modern Java applications.</p>
        </li>
      </ol>
<br />

      <h2>Example: Simple JDBC Program</h2>
      <pre>
        <code>
{`import java.sql.*;

public class JDBCExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        try (Connection conn = DriverManager.getConnection(url, user, password);
             Statement stmt = conn.createStatement();
             ResultSet rs = stmt.executeQuery("SELECT * FROM employees")) {

            while (rs.next()) {
                System.out.println("Employee ID: " + rs.getInt("id"));
                System.out.println("Employee Name: " + rs.getString("name"));
            }

        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}
        </code>
      </pre>

     <br />

      <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
      <ul>
        <li>Always close JDBC resources (<code>Connection</code>, <code>Statement</code>, and <code>ResultSet</code>) in a <code>finally</code> block or use try-with-resources.</li><br />
        <li>Use <code>PreparedStatement</code> to avoid SQL injection.</li><br />
        <li>Enable connection pooling in production environments.</li><br />
        <li>Use database connection URLs and credentials securely (e.g., environment variables).</li>
      </ul><br />
    </div>
  </div>
)}



{selectedChapter === 'chapter25' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Connecting to a Database</h1>

    <p>
      The <strong>JDBC (Java Database Connectivity)</strong> API enables Java applications to connect to and interact with
      relational databases. This guide covers the essential steps for establishing a database connection in Java EE using
      JDBC.
    </p>

   <br />

    <h2>Steps to Connect to a Database</h2>

    <ol>
      <li>
        <strong>Load the Database Driver</strong>
        <p>
          JDBC drivers are required for database communication. Starting with JDBC 4.0 (Java SE 6), drivers are
          automatically loaded if present in the classpath. For older Java versions, explicitly load the driver using:
        </p>
        <pre>
          <code>Class.forName("com.mysql.cj.jdbc.Driver");</code>
        </pre>
      </li><br />

      <li>
        <strong>Define Database Connection Parameters</strong>
        <p>Specify the connection URL, username, and password.</p>
        <pre>
          <code>
{`String url = "jdbc:mysql://localhost:3306/mydb";
String user = "root";
String password = "password";`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Establish a Connection</strong>
        <p>Use the <code>DriverManager</code> to obtain a <code>Connection</code> object:</p>
        <pre>
          <code>
            Connection conn = DriverManager.getConnection(url, user, password);
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Create a Statement Object</strong>
        <p>
          Create a <code>Statement</code>, <code>PreparedStatement</code>, or <code>CallableStatement</code> to execute
          SQL queries:
        </p>
        <pre>
          <code>
            Statement stmt = conn.createStatement();
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Execute SQL Queries</strong>
        <p>Use the statement object to execute SQL commands such as queries or updates. Example:</p>
        <pre>
          <code>
{`ResultSet rs = stmt.executeQuery("SELECT * FROM employees");`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Process the ResultSet</strong>
        <p>Iterate through the <code>ResultSet</code> to retrieve query results:</p>
        <pre>
          <code>
{`while (rs.next()) {
    System.out.println("Employee ID: " + rs.getInt("id"));
    System.out.println("Employee Name: " + rs.getString("name"));
}`}
          </code>
        </pre>
      </li><br />

      <li>
        <strong>Close Resources</strong>
        <p>
          Always close the <code>ResultSet</code>, <code>Statement</code>, and <code>Connection</code> to release
          resources:
        </p>
        <pre>
          <code>
{`rs.close();
stmt.close();
conn.close();`}
          </code>
        </pre>
      </li>
    </ol>

    <br />

    <h2>Example: Complete JDBC Program</h2>
    <pre>
      <code>
{`import java.sql.*;

public class DatabaseConnectionExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        try (Connection conn = DriverManager.getConnection(url, user, password);
             Statement stmt = conn.createStatement();
             ResultSet rs = stmt.executeQuery("SELECT * FROM employees")) {

            while (rs.next()) {
                System.out.println("Employee ID: " + rs.getInt("id"));
                System.out.println("Employee Name: " + rs.getString("name"));
            }

        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}
      </code>
    </pre>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
    <ul>
      <li>
        <strong>Use Try-With-Resources:</strong> Automatically closes JDBC resources.
      </li><br />
      <li>
        <strong>Prepared Statements:</strong> Use <code>PreparedStatement</code> for dynamic queries to prevent SQL
        injection.
      </li><br />
      <li>
        <strong>Connection Pooling:</strong> Improve performance in production by reusing connections.
      </li><br />
      <li>
        <strong>Environment Variables:</strong> Store sensitive credentials securely.
      </li>
    </ul><br />

    <p>
      Connecting to a database using JDBC is the foundation for building Java EE applications with robust data access
      functionality. By following these steps and adhering to best practices, developers can ensure secure and efficient
      database interactions.
    </p>
  </div>
)}

{selectedChapter === 'chapter26' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>CRUD Operations with JDBC </h1>

    <p>
      The <strong>CRUD (Create, Read, Update, Delete)</strong> operations are the foundation 
      of database interaction in Java EE applications. Below is a detailed guide on 
      implementing these operations using JDBC.
    </p>

 <br />

    <h2>1. Setup and Configuration</h2>
    <p>
      Before performing CRUD operations, ensure you have the following setup:
    </p>
    <ul>
      <li><strong>Database:</strong> A sample database with a table for CRUD operations.</li><br />
      <li><strong>JDBC Driver:</strong> Include the appropriate JDBC driver in your project dependencies.</li><br />
      <li><strong>Connection URL:</strong> A URL string to connect to your database.</li>
    </ul><br />

    <h3>Sample Table</h3>
    <pre>
      <code>
        {`CREATE TABLE employees (
    id INT PRIMARY KEY AUTO_INCREMENT,
    name VARCHAR(100),
    department VARCHAR(50),
    salary DECIMAL(10, 2)
);`}
      </code>
    </pre>

    <br />

    <h2>2. Create Operation</h2>
    <p>Inserting a new record into the database.</p>
    <pre>
      <code>
        {`import java.sql.*;

public class CreateExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        String insertQuery = "INSERT INTO employees (name, department, salary) VALUES (?, ?, ?)";

        try (Connection conn = DriverManager.getConnection(url, user, password);
             PreparedStatement pstmt = conn.prepareStatement(insertQuery)) {

            pstmt.setString(1, "John Doe");
            pstmt.setString(2, "IT");
            pstmt.setBigDecimal(3, new BigDecimal("75000.00"));

            int rowsInserted = pstmt.executeUpdate();
            System.out.println("Rows Inserted: " + rowsInserted);

        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}
      </code>
    </pre>

 <br />

    <h2>3. Read Operation</h2>
    <p>Retrieving records from the database.</p>
    <pre>
      <code>
        {`import java.sql.*;

public class ReadExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        String selectQuery = "SELECT * FROM employees";

        try (Connection conn = DriverManager.getConnection(url, user, password);
             Statement stmt = conn.createStatement();
             ResultSet rs = stmt.executeQuery(selectQuery)) {

            while (rs.next()) {
                System.out.println("ID: " + rs.getInt("id"));
                System.out.println("Name: " + rs.getString("name"));
                System.out.println("Department: " + rs.getString("department"));
                System.out.println("Salary: " + rs.getBigDecimal("salary"));
                System.out.println("-------------------");
            }

        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}
      </code>
    </pre>

   <br />

    <h2>4. Update Operation</h2>
    <p>Updating existing records in the database.</p>
    <pre>
      <code>
        {`import java.sql.*;

public class UpdateExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        String updateQuery = "UPDATE employees SET salary = ? WHERE id = ?";

        try (Connection conn = DriverManager.getConnection(url, user, password);
             PreparedStatement pstmt = conn.prepareStatement(updateQuery)) {

            pstmt.setBigDecimal(1, new BigDecimal("80000.00"));
            pstmt.setInt(2, 1);

            int rowsUpdated = pstmt.executeUpdate();
            System.out.println("Rows Updated: " + rowsUpdated);

        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}
      </code>
    </pre>

   <br />

    <h2>5. Delete Operation</h2>
    <p>Deleting records from the database.</p>
    <pre>
      <code>
        {`import java.sql.*;

public class DeleteExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        String deleteQuery = "DELETE FROM employees WHERE id = ?";

        try (Connection conn = DriverManager.getConnection(url, user, password);
             PreparedStatement pstmt = conn.prepareStatement(deleteQuery)) {

            pstmt.setInt(1, 1);

            int rowsDeleted = pstmt.executeUpdate();
            System.out.println("Rows Deleted: " + rowsDeleted);

        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}
      </code>
    </pre>

<br />

    <h2 style={{paddingBottom:"6px"}}>6. Best Practices for JDBC CRUD Operations</h2>
    <ul>
      <li><strong>Use Prepared Statements:</strong> Prevents SQL injection and enhances performance.</li><br />
      <li><strong>Try-With-Resources:</strong> Automatically closes resources like `Connection`, `Statement`, and `ResultSet`.</li><br />
      <li><strong>Connection Pooling:</strong> Use a connection pool (e.g., HikariCP) for efficient resource management in production environments.</li><br />
      <li><strong>Parameter Validation:</strong> Validate user inputs to ensure data integrity and security.</li><br />
      <li><strong>Logging and Monitoring:</strong> Log queries and track database performance for debugging and optimization.</li>
    </ul><br />

    <p>
      Using JDBC in Java EE applications for CRUD operations involves creating connections, 
      preparing queries, and processing results. By following the provided examples and adhering 
      to best practices, you can efficiently implement database interactions in your Java EE applications.
    </p>
  </div>
)}

{selectedChapter === 'chapter27' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Using PreparedStatement and CallableStatement</h1>


 <br />

    <h2>1. What are PreparedStatement and CallableStatement?</h2>
    <ul>
      <li>
        <strong>PreparedStatement:</strong>
        <ul>
          <li>Used for executing parameterized SQL queries.</li>
          <li>Helps prevent SQL injection.</li>
          <li>Optimizes performance by precompiling the SQL query.</li>
        </ul>
      </li><br />
      <li>
        <strong>CallableStatement:</strong>
        <ul>
          <li>Used for executing stored procedures in the database.</li>
          <li>Supports input, output, and input-output parameters.</li>
        </ul>
      </li>
    </ul>

  <br />

    <h2 style={{paddingBottom:"6px"}}>2. Using PreparedStatement</h2>
    <h3>Code Example: Parameterized Query</h3>
    <pre>
      <code>
        {`import java.sql.*;

public class PreparedStatementExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        String insertQuery = "INSERT INTO employees (name, department, salary) VALUES (?, ?, ?)";

        try (Connection conn = DriverManager.getConnection(url, user, password);
             PreparedStatement pstmt = conn.prepareStatement(insertQuery)) {

            // Set parameters
            pstmt.setString(1, "Alice");
            pstmt.setString(2, "HR");
            pstmt.setBigDecimal(3, new BigDecimal("60000.00"));

            // Execute the query
            int rowsInserted = pstmt.executeUpdate();
            System.out.println("Rows Inserted: " + rowsInserted);

        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}
      </code>
    </pre>

   <br />

    <h2 style={{paddingBottom:"6px"}}>3. Using CallableStatement</h2>
    <h3>Code Example: Stored Procedure</h3>
    <p><strong>Sample Stored Procedure in MySQL:</strong></p>
    <pre>
      <code>
        {`DELIMITER //

CREATE PROCEDURE updateEmployeeSalary(
    IN empId INT,
    IN newSalary DECIMAL(10, 2)
)
BEGIN
    UPDATE employees
    SET salary = newSalary
    WHERE id = empId;
END //

DELIMITER ;`}
      </code>
    </pre>
    <p><strong>Java Code to Call Stored Procedure:</strong></p>
    <pre>
      <code>
        {`import java.sql.*;

public class CallableStatementExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        String callProcedure = "{CALL updateEmployeeSalary(?, ?)}";

        try (Connection conn = DriverManager.getConnection(url, user, password);
             CallableStatement cstmt = conn.prepareCall(callProcedure)) {

            // Set input parameters
            cstmt.setInt(1, 1); // Employee ID
            cstmt.setBigDecimal(2, new BigDecimal("70000.00")); // New Salary

            // Execute the procedure
            cstmt.execute();
            System.out.println("Employee salary updated successfully.");

        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`}
      </code>
    </pre>

  <br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
    <ol>
      <li>
        <strong>Use PreparedStatement for Dynamic Queries:</strong> Use it for queries where input parameters are dynamic to prevent SQL injection.
      </li><br />
      <li>
        <strong>Use CallableStatement for Complex Operations:</strong> Ideal for operations involving multiple SQL statements or business logic encapsulated in stored procedures.
      </li><br />
      <li>
        <strong>Try-With-Resources:</strong> Always close JDBC objects like <code>Connection</code>, <code>PreparedStatement</code>, and <code>CallableStatement</code> to avoid memory leaks.
      </li><br />
      <li>
        <strong>Validate Inputs:</strong> Always validate and sanitize user inputs before passing them to queries.
      </li><br />
      <li>
        <strong>Connection Pooling:</strong> Use connection pooling libraries like HikariCP for better performance in Java EE applications.
      </li>
    </ol>

<br />

    <h3 style={{paddingBottom:"6px"}}>Summary</h3>
    <p>
      <strong>PreparedStatement</strong> is used for executing parameterized queries, ensuring security and performance. 
      <strong>CallableStatement</strong> is designed for invoking stored procedures, supporting both input and output parameters.
    </p>
    <p>
      Leveraging these tools effectively enhances the scalability and maintainability of Java EE applications interacting with databases.
    </p>
  </div>
)}

{selectedChapter === 'chapter28' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Handling Transactions in JDBC</h1>

  
    <p>
      Transaction management in JDBC ensures that a group of SQL statements is executed as a single unit of work.
      Either all the operations succeed, or none of them is committed to the database.
    </p><br />

    <h3>Key Concepts</h3>
    <ul>
      <li>
        <strong>Auto-commit Mode:</strong> By default, JDBC executes each SQL statement in auto-commit mode. When
        auto-commit is enabled, every statement is immediately committed to the database.
      </li><br />
      <li>
        <strong>Manual Transaction Control:</strong> You can turn off auto-commit to manage transactions manually.
        Use <code>Connection</code> methods:
        <ul>
          <li><code>setAutoCommit(false)</code> to begin a transaction.</li>
          <li><code>commit()</code> to commit changes.</li>
          <li><code>rollback()</code> to undo changes if an error occurs.</li>
        </ul>
      </li>
    </ul>

    <br />
    <h2>Steps to Handle Transactions in JDBC</h2>
    <ol>
      <li>Turn off auto-commit using <code>setAutoCommit(false)</code> on the <code>Connection</code> object.</li><br />
      <li>Execute the required SQL operations.</li><br />
      <li>Commit or rollback the transaction based on the success or failure of the operations.</li><br />
      <li>Close resources using try-with-resources for safety.</li>
    </ol>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Code Example: Handling Transactions</h2>

    <h3>Database Schema</h3>
    <pre className={style.codeblock}>
      {`CREATE TABLE accounts (
    id INT PRIMARY KEY,
    name VARCHAR(100),
    balance DECIMAL(10, 2)
);`}
    </pre><br />

    <h3>Code Implementation</h3>
    <pre className={style.codeblock}>
      {`import java.sql.*;

public class TransactionExample {
    public static void main(String[] args) {
        String url = "jdbc:mysql://localhost:3306/mydb";
        String user = "root";
        String password = "password";

        Connection conn = null;

        try {
            conn = DriverManager.getConnection(url, user, password);
            conn.setAutoCommit(false);

            String withdrawQuery = "UPDATE accounts SET balance = balance - ? WHERE id = ?";
            String depositQuery = "UPDATE accounts SET balance = balance + ? WHERE id = ?";

            try (PreparedStatement withdrawStmt = conn.prepareStatement(withdrawQuery);
                 PreparedStatement depositStmt = conn.prepareStatement(depositQuery)) {

                withdrawStmt.setBigDecimal(1, new BigDecimal("500.00"));
                withdrawStmt.setInt(2, 1);
                withdrawStmt.executeUpdate();

                depositStmt.setBigDecimal(1, new BigDecimal("500.00"));
                depositStmt.setInt(2, 2);
                depositStmt.executeUpdate();

                conn.commit();
                System.out.println("Transaction committed successfully.");
            } catch (SQLException e) {
                if (conn != null) {
                    conn.rollback();
                    System.out.println("Transaction rolled back due to an error.");
                }
                e.printStackTrace();
            }
        } catch (SQLException e) {
            e.printStackTrace();
        } finally {
            try {
                if (conn != null) {
                    conn.setAutoCommit(true);
                    conn.close();
                }
            } catch (SQLException e) {
                e.printStackTrace();
            }
        }
    }
}`}
    </pre>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
    <ul>
      <li>Explicitly turn off auto-commit before starting a transaction.</li><br />
      <li>Handle exceptions gracefully and ensure rollback on failure.</li><br />
      <li>Use <code>Savepoint</code> objects for complex transactions.</li><br />
      <li>Always close JDBC resources properly using try-with-resources.</li><br />
      <li>Minimize operations in a single transaction for better performance.</li><br />
      <li>Leverage connection pooling for efficient database connection management.</li>
    </ul>

   <br />

    <h2>Output Example</h2>
    <pre className={style.codeblock}>
      {`Successful Transaction:
Transaction committed successfully.

Transaction Rolled Back:
Transaction rolled back due to an error.`}
    </pre>

    <p>
      This approach ensures robust transaction handling and helps maintain data consistency in your JDBC
      applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter29' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Database Connection Pooling (using Apache DBCP or HikariCP)  </h1>
    <p>
      Database connection pooling is an essential technique in Java EE applications for efficiently managing database connections. Connection pooling minimizes the overhead of establishing a new connection to the database every time one is needed. Instead, a pool of pre-established connections is maintained, and connections are reused when required, improving performance and resource utilization.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>1. Apache DBCP (Database Connection Pooling)</h2>

    <h3>Step 1: Add Dependency (Maven)</h3>
    <p>
      For Apache DBCP, add the following Maven dependency to your <code>pom.xml</code>:
    </p>
    <pre>
      <code>
        &lt;dependency&gt;
          &lt;groupId&gt;org.apache.commons&lt;/groupId&gt;
          &lt;artifactId&gt;commons-dbcp2&lt;/artifactId&gt;
          &lt;version&gt;2.9.0&lt;/version&gt;
        &lt;/dependency&gt;
      </code>
    </pre><br />

    <h3>Step 2: Configure DBCP in Java EE</h3>
    <p>
      In a Java EE application, you can configure Apache DBCP in the <code>web.xml</code> file or via an application server like Tomcat or WildFly. Below is an example of configuring DBCP in a <code>web.xml</code> file.
    </p>
    <pre>
      <code>{`
        <resource-ref>
          <description>Database Connection Pool</description>
          <res-ref-name>jdbc/MyDB</res-ref-name>
          <res-type>javax.sql.DataSource</res-type>
          <res-auth>Container</res-auth>
        </resource-ref>
      `}</code>
    </pre><br />

    <p>
      Then, configure the DBCP connection pool in the server’s context file (e.g., <code>context.xml</code> for Tomcat):
    </p>
    <pre>
      <code>{`
        <Context>
          <Resource name="jdbc/MyDB" 
                    auth="Container"
                    type="javax.sql.DataSource"
                    username="db_user"
                    password="db_password"
                    driverClassName="com.mysql.cj.jdbc.Driver"
                    url="jdbc:mysql://localhost:3306/mydb"
                    maxTotal="20"
                    maxIdle="10"
                    minIdle="5"
                    initialSize="5"
                    validationQuery="SELECT 1"
                    testOnBorrow="true"
                    testWhileIdle="true"
                    timeBetweenEvictionRunsMillis="30000"
                    minEvictableIdleTimeMillis="30000" />
        </Context>
      `}</code>
    </pre><br />

    <h3>Step 3: Use the DataSource in Your Application</h3>
    <p>
      Once configured, you can use the <code>DataSource</code> in your Java EE code as follows:
    </p>
    <pre>
      <code>{`  import javax.annotation.Resource;
        import javax.sql.DataSource;
        import java.sql.Connection;
        import java.sql.SQLException;

        public class MyDatabaseService {

            @Resource(name = "jdbc/MyDB")
            private DataSource dataSource;

            public void performDatabaseOperations() {
                try (Connection connection = dataSource.getConnection()) {
                    // Perform database operations
                } catch (SQLException e) {
                    e.printStackTrace();
                }
            }
        }
       `} </code>
    </pre><br />


    <h2>2. HikariCP (High Performance Connection Pooling)</h2>

    <p style={{paddingBottom:"6px"}}>
      HikariCP is a highly performant and popular connection pool for Java applications, known for its low overhead and high throughput.
    </p>

    <h3>Step 1: Add Dependency (Maven)</h3>
    <p>
      Add the HikariCP dependency to your <code>pom.xml</code>:
    </p>
    <pre><code>{`
        <dependency>
  <groupId>com.zaxxer</groupId>
  <artifactId>HikariCP</artifactId>
  <version>5.0.0</version>
</dependency>
    `}</code>
    </pre><br />

    <h3>Step 2: Configure HikariCP in Java EE</h3>
    <p>
      In Java EE, you can configure HikariCP using a <code>DataSource</code> in the <code>web.xml</code> or your application server's configuration. For simplicity, let’s configure it programmatically.
    </p>
    <pre>
      <code>{`
        import com.zaxxer.hikari.HikariConfig;
        import com.zaxxer.hikari.HikariDataSource;
        import javax.sql.DataSource;

        public class HikariCPDataSource {

            public DataSource getDataSource() {
                HikariConfig config = new HikariConfig();
                config.setJdbcUrl("jdbc:mysql://localhost:3306/mydb");
                config.setUsername("db_user");
                config.setPassword("db_password");
                config.setDriverClassName("com.mysql.cj.jdbc.Driver");
                config.setMaximumPoolSize(10);
                config.setMinimumIdle(5);
                config.setIdleTimeout(30000);
                config.setMaxLifetime(600000);

                return new HikariDataSource(config);
            }
        }
       `} </code>
    </pre><br />

    <h3>Step 3: Use the DataSource in Your Application</h3>
    <p>
      Once the <code>HikariDataSource</code> is configured, use it in your application code to obtain a connection.
    </p>
    <pre>
      <code>{`
        import javax.sql.DataSource;
        import java.sql.Connection;
        import java.sql.SQLException;

        public class HikariDatabaseService {

            private DataSource dataSource;

            public HikariDatabaseService() {
                this.dataSource = new HikariCPDataSource().getDataSource();
            }

            public void performDatabaseOperations() {
                try (Connection connection = dataSource.getConnection()) {
                    // Perform database operations
                } catch (SQLException e) {
                    e.printStackTrace();
                }
            }
        }
       `} </code>
    </pre><br />

    <h3>Step 4: Integration with Java EE (Optional)</h3>
    <p>
      If you want to integrate HikariCP with the Java EE container, you can either:
      <ul>
        <li>Define it as a resource in your application server’s configuration (similar to DBCP).</li>
        <li>Inject it using Java EE’s <code>@Resource</code> if supported by the container.</li>
      </ul>
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of Connection Pooling (Apache DBCP / HikariCP)</h2>
    <ul>
      <li><strong>Reduced Connection Overhead:</strong> By reusing database connections, the overhead of opening and closing connections is minimized.</li><br />
      <li><strong>Improved Performance:</strong> Connection pooling helps in managing the number of active connections, reducing the chances of exhausting database resources.</li><br />
      <li><strong>Automatic Connection Management:</strong> Pooling libraries can automatically handle failed connections, connection validation, and connection leak detection.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices</h2>
    <ul>
      <li><strong>Configure Pool Size Based on Load:</strong> Set the <code>maximumPoolSize</code> (for HikariCP) or <code>maxTotal</code> (for DBCP) to a value that suits your application’s load.</li><br />
      <li><strong>Connection Validation:</strong> Always use a validation query or enable connection testing (<code>testOnBorrow</code>) to ensure that connections are alive before being used.</li><br />
      <li><strong>Use Connection Pooling in Production:</strong> In production environments, always use a connection pool instead of opening new connections directly.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Both Apache DBCP and HikariCP provide excellent options for connection pooling in Java EE applications. HikariCP is known for its performance, while Apache DBCP is widely used and easily integrated into Java EE servers. By using connection pooling, your application will handle database connections more efficiently, improving performance and resource utilization.
    </p>

  </div>
)}


{selectedChapter === 'chapter30' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to REST and JAX-RS </h1>

    <p>
      <strong>REST (Representational State Transfer)</strong> is an architectural style for designing networked applications.
      It relies on stateless communication and the use of standard HTTP methods such as GET, POST, PUT, DELETE, and PATCH.
      RESTful web services are simple, scalable, and stateless, making them a popular choice for modern web applications.
    </p><br />

    <p>
      <strong>JAX-RS (Java API for RESTful Web Services)</strong> is a set of APIs to create RESTful web services in Java.
      JAX-RS is part of the Java EE (Enterprise Edition) specification, providing a way to build RESTful web services using
      annotations and other Java EE technologies. It is included in Java EE 7 and later versions, which simplifies the development of RESTful services.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Concepts of REST</h2>
    <ul>
      <li><strong>Statelessness</strong>: Each HTTP request from a client to the server must contain all the information the server needs to fulfill the request. The server does not store any state between requests.</li><br />
      <li><strong>Resource-Based</strong>: Resources (like users, products, etc.) are the key concept in REST. Each resource is identified by a unique URI (Uniform Resource Identifier), and HTTP methods are used to perform operations on these resources.</li><br />
      <li><strong>HTTP Methods</strong>: The four primary HTTP methods used in REST are:
        <ul>
          <li><strong>GET</strong>: Retrieve resource(s).</li><br />
          <li><strong>POST</strong>: Create a new resource.</li><br />
          <li><strong>PUT</strong>: Update an existing resource.</li><br />
          <li><strong>DELETE</strong>: Remove a resource.</li><br />
          <li><strong>PATCH</strong>: Partially update a resource.</li>
        </ul><br />
      </li>
      <li><strong>Representation</strong>: Resources are represented in various formats such as JSON, XML, or HTML. Clients interact with these representations, and the format is often negotiated using the `Accept` header in HTTP requests.</li>
    </ul><br />

    <h2>JAX-RS Overview</h2>
    <p>
      JAX-RS makes it easier to develop RESTful web services in Java. Some of the key features of JAX-RS include:
    </p>
    <ul>
      <li><strong>Annotations</strong>: JAX-RS uses annotations to define how HTTP requests map to Java methods. Common annotations include:
        <ul>
          <li><code>@Path</code>: Defines the URI of the resource.</li><br />
          <li><code>@GET</code>, <code>@POST</code>, <code>@PUT</code>, <code>@DELETE</code>: Bind HTTP methods to Java methods.</li><br />
          <li><code>@Produces</code>: Specifies the MIME media type of the response (e.g., JSON or XML).</li><br />
          <li><code>@Consumes</code>: Specifies the MIME media type of the request.</li><br />
          <li><code>@QueryParam</code>, <code>@PathParam</code>, <code>@FormParam</code>: Annotations for extracting parameters from the request URL, form data, or body.</li>
        </ul>
      </li><br />
      <li><strong>Client API</strong>: JAX-RS also provides a client API to make RESTful requests from a Java application. The <code>Client</code> class allows you to easily send HTTP requests and receive responses.</li>
    </ul>

    <h2 style={{paddingBottom:"6px"}}>Example: Basic JAX-RS Service</h2>
    <h3>1. REST Service Class (Java)</h3>
    <pre>
      <code>
        {`import javax.ws.rs.*;
import javax.ws.rs.core.MediaType;
import javax.ws.rs.core.Response;

@Path("/greeting")
public class GreetingService {

    @GET
    @Produces(MediaType.TEXT_PLAIN)
    public String sayHello() {
        return "Hello, Welcome to RESTful Web Services!";
    }

    @GET
    @Path("/{name}")
    @Produces(MediaType.TEXT_PLAIN)
    public String greetUser(@PathParam("name") String name) {
        return "Hello, " + name + "!";
    }
}`}
      </code>
    </pre><br />

    <h3>2. Deploying the Service</h3>
    <p>
      In a Java EE server (e.g., WildFly, Payara), you can deploy this service as part of a web application. The service listens on the <code>/greeting</code> path, and if a name is provided (e.g., <code>/greeting/John</code>), it responds with "Hello, John!".
    </p><br />

    <h3>3. Client Request</h3>
    <pre>
      <code>
        {`import javax.ws.rs.client.*;
import javax.ws.rs.core.*;

public class GreetingClient {

    public static void main(String[] args) {
        Client client = ClientBuilder.newClient();
        WebTarget target = client.target("http://localhost:8080/myapp/greeting");

        // Get response without parameters
        String response = target.request(MediaType.TEXT_PLAIN).get(String.class);
        System.out.println("Response: " + response);

        // Get response with parameters
        String userResponse = target.path("/{name}").resolveTemplate("name", "John")
                                    .request(MediaType.TEXT_PLAIN).get(String.class);
        System.out.println("Response with Name: " + userResponse);
    }
}`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Benefits of Using REST and JAX-RS</h2>
    <ul>
      <li><strong>Scalability</strong>: RESTful web services are stateless and designed to be scalable.</li><br />
      <li><strong>Simplicity</strong>: REST is based on standard HTTP methods, making it easier to understand and implement.</li><br />
      <li><strong>Interoperability</strong>: REST supports multiple data formats (JSON, XML, etc.), making it easier to integrate with different systems.</li><br />
      <li><strong>Flexibility</strong>: With JAX-RS, you can create APIs that respond in various formats and support a wide range of HTTP methods and parameters.</li><br />
      <li><strong>Integration</strong>: It integrates seamlessly with other Java EE technologies such as CDI (Contexts and Dependency Injection), EJB (Enterprise JavaBeans), and JPA (Java Persistence API).</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      RESTful services are widely used in web development due to their simplicity, scalability, and performance.
      With JAX-RS, Java EE developers can quickly create powerful and flexible REST APIs that can be consumed by various clients,
      making it an essential tool in modern Java development.
    </p>
  </div>
)}



{selectedChapter === 'chapter31' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Creating RESTful APIs with Annotations</h1>

    <p>
      Creating RESTful APIs in Java EE is simplified using JAX-RS (Java API for RESTful Web Services). 
      JAX-RS provides annotations that map HTTP methods (like <code>GET</code>, <code>POST</code>, <code>PUT</code>, and <code>DELETE</code>) to Java methods for handling HTTP requests. 
      Here's a breakdown of how to use these annotations to build RESTful APIs in Java EE:
    </p><br />

    <h3>1. Setting up the Java EE Environment</h3>
    <p>
      First, ensure that you have a Java EE server (like WildFly, Payara, or GlassFish) and JAX-RS libraries (usually bundled with the server). You will also need a <code>web.xml</code> configuration file for deploying the service.
    </p><br />

    <h3>2. Using JAX-RS Annotations to Create the API</h3>
    <p>
      Below is an example of a RESTful service that performs CRUD (Create, Read, Update, Delete) operations using the HTTP methods <code>@GET</code>, <code>@POST</code>, <code>@PUT</code>, and <code>@DELETE</code>:
    </p>

    <pre>
      <code>
{`import javax.ws.rs.*;
import javax.ws.rs.core.MediaType;
import javax.ws.rs.core.Response;
import java.util.HashMap;
import java.util.Map;

@Path("/items")
public class ItemService {

    // In-memory "database" for demonstration purposes
    private static Map<Integer, String> items = new HashMap<>();
    private static int currentId = 1;

    // 1. Get All Items (GET)
    @GET
    @Produces(MediaType.APPLICATION_JSON)
    public Response getItems() {
        return Response.ok(items).build();
    }

    // 2. Get a Single Item by ID (GET)
    @GET
    @Path("/{id}")
    @Produces(MediaType.APPLICATION_JSON)
    public Response getItem(@PathParam("id") int id) {
        String item = items.get(id);
        if (item == null) {
            return Response.status(Response.Status.NOT_FOUND).entity("Item not found").build();
        }
        return Response.ok(item).build();
    }

    // 3. Create a New Item (POST)
    @POST
    @Consumes(MediaType.APPLICATION_JSON)
    @Produces(MediaType.APPLICATION_JSON)
    public Response createItem(String item) {
        items.put(currentId++, item);
        return Response.status(Response.Status.CREATED).entity(item).build();
    }

    // 4. Update an Existing Item (PUT)
    @PUT
    @Path("/{id}")
    @Consumes(MediaType.APPLICATION_JSON)
    @Produces(MediaType.APPLICATION_JSON)
    public Response updateItem(@PathParam("id") int id, String item) {
        if (items.containsKey(id)) {
            items.put(id, item);
            return Response.ok(item).build();
        } else {
            return Response.status(Response.Status.NOT_FOUND).entity("Item not found").build();
        }
    }

    // 5. Delete an Item (DELETE)
    @DELETE
    @Path("/{id}")
    public Response deleteItem(@PathParam("id") int id) {
        if (items.containsKey(id)) {
            items.remove(id);
            return Response.status(Response.Status.NO_CONTENT).build();
        } else {
            return Response.status(Response.Status.NOT_FOUND).entity("Item not found").build();
        }
    }
}`}
      </code>
    </pre><br />

    <h3>Key Components of the Code:</h3>
    <ul>
      <li>
        <strong>@Path:</strong> Specifies the URI path for the resource, such as <code>@Path("/items")</code> to define the root path.
      </li><br />
      <li>
        <strong>@GET, @POST, @PUT, @DELETE:</strong> These annotations map HTTP methods to Java methods that handle requests:
        <ul>
          <li><code>@GET</code> - Retrieve data.</li><br />
          <li><code>@POST</code> - Create new resources.</li><br />
          <li><code>@PUT</code> - Update an existing resource.</li><br />
          <li><code>@DELETE</code> - Delete a resource.</li>
        </ul>
      </li><br />
      <li>
        <strong>@Produces and @Consumes:</strong> Define the media types the service supports. 
        <ul>
          <li><code>@Produces(MediaType.APPLICATION_JSON)</code> - The response format (JSON).</li><br />
          <li><code>@Consumes(MediaType.APPLICATION_JSON)</code> - The expected format of incoming data (JSON).</li>
        </ul>
      </li><br />
      <li>
        <strong>@PathParam:</strong> Extracts parameters from the URL path, such as <code>{`@Path("/{id}")`}</code>.
      </li><br />
      <li>
        <strong>Response Building:</strong> The <code>Response</code> object is used to build HTTP responses with status codes and payloads.
      </li>
    </ul><br />

    <h3>3. Deploying the Service</h3>
    <p>
      To deploy this REST service in a Java EE server, you will need a <code>web.xml</code> or a class annotated with <code>@ApplicationPath</code> to enable JAX-RS. Below is an example of the <code>web.xml</code> configuration for a Java EE application:
    </p>

    <pre>
      <code>
{`<web-app xmlns="http://java.sun.com/xml/ns/javaee" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
         xsi:schemaLocation="http://java.sun.com/xml/ns/javaee http://java.sun.com/xml/ns/javaee/web-app_3_0.xsd"
         version="3.0">
    <display-name>ItemServiceApp</display-name>

    <servlet>
        <servlet-name>javax.ws.rs.core.Application</servlet-name>
        <servlet-class>org.glassfish.jersey.servlet.ServletContainer</servlet-class>
        <init-param>
            <param-name>jersey.config.server.provider.packages</param-name>
            <param-value>com.example.items</param-value>
        </init-param>
        <load-on-startup>1</load-on-startup>
    </servlet>

    <servlet-mapping>
        <servlet-name>javax.ws.rs.core.Application</servlet-name>
        <url-pattern>/api/*</url-pattern>
    </servlet-mapping>
</web-app>`}
      </code>
    </pre><br />

    <h3>4. Client Request Example</h3>
    <p>
      A Java client can use JAX-RS client APIs to interact with the RESTful service. Here's an example client that performs <code>GET</code>, <code>POST</code>, <code>PUT</code>, and <code>DELETE</code> operations:
    </p>

    <pre>
      <code>
{`import javax.ws.rs.client.*;
import javax.ws.rs.core.*;

public class ItemClient {
    public static void main(String[] args) {
        Client client = ClientBuilder.newClient();
        WebTarget target = client.target("http://localhost:8080/myapp/api/items");

        // POST Request to create an item
        String newItem = "{\"name\": \"Laptop\"}";
        Response response = target.request().post(Entity.entity(newItem, MediaType.APPLICATION_JSON));
        System.out.println("Create Item Response: " + response.getStatus());

        // GET Request to get all items
        String items = target.request(MediaType.APPLICATION_JSON).get(String.class);
        System.out.println("Items: " + items);

        // PUT Request to update an item
        String updatedItem = "{\"name\": \"Laptop Pro\"}";
        response = target.path("/{id}").resolveTemplate("id", 1)
                          .request().put(Entity.entity(updatedItem, MediaType.APPLICATION_JSON));
        System.out.println("Update Item Response: " + response.getStatus());

        // DELETE Request to delete an item
        response = target.path("/{id}").resolveTemplate("id", 1).request().delete();
        System.out.println("Delete Item Response: " + response.getStatus());
    }
}`}
      </code>
    </pre><br />

    <h3>Conclusion</h3>
    <p>
      By using JAX-RS annotations, you can quickly create robust RESTful APIs in Java EE. These annotations help you handle HTTP requests, interact with data, and build scalable web services. You can extend this approach by adding error handling, security features, and integrating with databases.
    </p>
  </div>
)}

{selectedChapter === 'chapter32' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Handling JSON and XML Data </h1>

    <p>
      In <strong>Java EE (Jakarta EE)</strong>, handling <strong>JSON</strong> and <strong>XML</strong> data is streamlined with built-in APIs and libraries. The key APIs used in Java EE for working with JSON and XML data are <strong>JAX-RS</strong> for JSON (part of RESTful web services) and <strong>JAXB</strong> for XML (for binding Java objects to XML).
    </p><br />

    <p>Here's a guide to handling JSON and XML data in <strong>Java EE</strong>:</p>

    <h3>1. Handling JSON Data in Java EE with JAX-RS</h3>

    <p style={{paddingBottom:"6px"}}>
      <strong>JAX-RS</strong> (Java API for RESTful Web Services) is the standard Java API for creating RESTful web services. It supports automatic conversion between Java objects and JSON (and XML) when using libraries like <strong>Jackson</strong> (for JSON), which can be integrated into JAX-RS.
    </p>

    <h4>Steps to handle JSON data in JAX-RS:</h4>

    <ol>
      <li>
        <strong>Add Dependencies (for Jackson and JAX-RS)</strong>
        <p>
          If you are using <strong>Maven</strong>, add the required dependencies for <strong>JAX-RS</strong> and <strong>Jackson</strong> in your <code>pom.xml</code> file. If you're working with an application server like <strong>WildFly</strong> or <strong>Payara</strong>, they usually come with JAX-RS libraries already included.
        </p>
        <pre>
          <code>{`
          <dependencies>
  <dependency>
    <groupId>com.fasterxml.jackson.jaxrs</groupId>
    <artifactId>jackson-jaxrs-json-provider</artifactId>
    <version>2.15.0</version>
  </dependency>
  <dependency>
    <groupId>javax.ws.rs</groupId>
    <artifactId>javax.ws.rs-api</artifactId>
    <version>2.1.1</version>
  </dependency>
</dependencies>

           `} </code>
        </pre>
      </li><br />

      <li>
        <strong>Create a JAX-RS Resource Class</strong>
        <p>
          Create a <strong>RESTful service</strong> where the <strong>JSON</strong> data is handled.
        </p>
        <pre>
          <code>{`
            import javax.ws.rs.*;
            import javax.ws.rs.core.MediaType;
            import javax.ws.rs.core.Response;

            @Path("/person")
            public class PersonResource {

                @GET
                @Produces(MediaType.APPLICATION_JSON)
                public Response getPerson() {
                    Person person = new Person("John", 30);
                    return Response.ok(person).build();
                }

                @POST
                @Consumes(MediaType.APPLICATION_JSON)
                @Produces(MediaType.APPLICATION_JSON)
                public Response createPerson(Person person) {
                    // Logic to save person or process further
                    return Response.status(Response.Status.CREATED).entity(person).build();
                }
            }
`}</code>
        </pre>
      </li><br />

      <li>
        <strong>Person Class</strong>
        <p>This is the model class (<code>Person.java</code>):</p>
        <pre>
          <code>{`
            public class Person {
                private String name;
                private int age;

                public Person(String name, int age) {
                    this.name = name;
                    this.age = age;
                }

                public String getName() {
                    return name;
                }

                public void setName(String name) {
                    this.name = name;
                }

                public int getAge() {
                    return age;
                }

                public void setAge(int age) {
                    this.age = age;
                }
            }
`}</code>
        </pre>
      </li><br />

      <li>
        <strong>Configure Jackson for JSON Binding</strong>
        <p>
          In JAX-RS, Jackson is typically used to automatically convert Java objects to JSON and vice versa. You need to register the <strong>Jackson JSON provider</strong> in your <code>Application</code> class:
        </p>
        <pre>
          <code>{`
            import javax.ws.rs.ApplicationPath;
            import javax.ws.rs.core.Application;

            @ApplicationPath("/api")
            public class RestApplication extends Application {
                // No need to add any configuration if Jackson is already available in the classpath
            }
          `}</code>
        </pre>
      </li><br />

      <li>
        <strong>Running the Application</strong>
        <p>
          Once your <strong>JAX-RS</strong> resource class is set up, deploying it to a Java EE server (like <strong>Payara</strong>, <strong>GlassFish</strong>, or <strong>WildFly</strong>) will allow you to test it using tools like <strong>Postman</strong> or <strong>curl</strong>.
        </p>
        <ul>
          <li>GET <code>/api/person</code> → Returns JSON representation of the <code>Person</code> object.</li><br />
          <li>POST <code>/api/person</code> → Accepts JSON payload to create a <code>Person</code>.</li>
        </ul>
      </li>
    </ol><br />

    <h5>Example Request and Response:</h5>

    <pre>
      <code>
        curl -X GET http://localhost:8080/api/person
      </code>
    </pre>
    <p><strong>Response (JSON):</strong></p>
    <pre>
      <code>{`
        {
          "name": "John",
          "age": 30
        }
      `}</code>
    </pre><br />

    <pre>
      <code>{`
        curl -X POST -H "Content-Type: application/json" -d '{"name":"Alice","age":28}' http://localhost:8080/api/person
      </code>
    </pre>
    <p><strong>Response:</strong></p>
    <pre>
      <code>
        {
          "name": "Alice",
          "age": 28
        }
       `} </code>
    </pre><br />

    <h3>2. Handling XML Data in Java EE with JAXB</h3>

    <p style={{paddingBottom:"6px"}}>
      <strong>JAXB</strong> (Java Architecture for XML Binding) allows Java objects to be bound to XML representations, making it easier to work with XML in a Java EE environment.
    </p>

    <h4>Steps to handle XML data in JAX-RS (using JAXB):</h4>

    <ol>
      <li>
        <strong>Add JAXB Dependencies</strong>
        <p>
          Similar to Jackson, JAXB dependencies are required if you're working with Java 11 and above. Add the following dependencies in your <code>pom.xml</code>:
        </p>
        <pre>
          <code>{`
            <dependencies>
  <dependency>
    <groupId>javax.xml.bind</groupId>
    <artifactId>jaxb-api</artifactId>
    <version>2.3.1</version>
  </dependency>
  <dependency>
    <groupId>org.glassfish.jaxb</groupId>
    <artifactId>jaxb-runtime</artifactId>
    <version>2.3.1</version>
  </dependency>
</dependencies>

          `}</code>
        </pre>
      </li><br />

      <li>
        <strong>Create a JAX-RS Resource Class for XML</strong>
        <p>Here’s how to create an endpoint that handles XML data:</p>
        <pre>
          <code>{`
            import javax.ws.rs.*;
            import javax.ws.rs.core.MediaType;
            import javax.ws.rs.core.Response;
            import javax.xml.bind.annotation.XmlElement;
            import javax.xml.bind.annotation.XmlRootElement;

            @XmlRootElement
            public class Person {
                private String name;
                private int age;

                @XmlElement
                public String getName() {
                    return name;
                }

                public void setName(String name) {
                    this.name = name;
                }

                @XmlElement
                public int getAge() {
                    return age;
                }

                public void setAge(int age) {
                    this.age = age;
                }
            }

            @Path("/person")
            public class PersonResource {

                @GET
                @Produces(MediaType.APPLICATION_XML)
                public Response getPerson() {
                    Person person = new Person();
                    person.setName("John");
                    person.setAge(30);
                    return Response.ok(person).build();
                }

                @POST
                @Consumes(MediaType.APPLICATION_XML)
                @Produces(MediaType.APPLICATION_XML)
                public Response createPerson(Person person) {
                    return Response.status(Response.Status.CREATED).entity(person).build();
                }
            }
          `}</code>
        </pre>
      </li><br />

      <li>
        <strong>Test with XML</strong>
        <p>
          Test the <strong>XML</strong> endpoint with a tool like <strong>Postman</strong> or <strong>curl</strong>. Use <strong>GET</strong> and <strong>POST</strong> requests similar to how you test JSON.
        </p>
      </li>
    </ol><br />

    <h3>Conclusion</h3>

    <p>
      By utilizing the JAX-RS API for JSON and JAXB for XML, Java EE makes it easy to handle both data formats in web applications. These technologies work seamlessly together to allow the creation of robust, flexible, and scalable RESTful services.
    </p>
  </div>
)}


{selectedChapter === 'chapter33' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Exception Handling in REST APIs</h1>

    <p>
      In Java EE (Jakarta EE), exception handling in REST APIs is an essential part of building robust and user-friendly services.
      The JAX-RS (Java API for RESTful Web Services) provides several mechanisms to handle exceptions, allowing you to manage errors,
      map them to appropriate HTTP responses, and provide meaningful messages to clients.
    </p><br />

    <h2>1. Using <code>@ExceptionMapper</code> for Custom Exception Handling</h2>
    <p style={{paddingBottom:"6px"}}>
      One of the most common approaches in JAX-RS for handling exceptions is to use <code>@ExceptionMapper</code>. This allows you to
      map specific exceptions to HTTP responses.
    </p>

    <h3>Steps to Handle Exceptions Using <code>@ExceptionMapper</code>:</h3>

    <ol>
      <li>
        <strong>Define a Custom Exception:</strong> First, define a custom exception class that you want to map to an HTTP response.
        <pre>
          <code>{`
          public class PersonNotFoundException extends RuntimeException {
    public PersonNotFoundException(String message) {
        super(message);
    }
}

 `} </code>
        </pre>
      </li><br />

      <li>
        <strong>Create an <code>ExceptionMapper</code> Implementation:</strong> Implement the <code>ExceptionMapper</code> interface to map
        your custom exception to an HTTP response.
        <pre>
          <code>{`
          import javax.ws.rs.core.Response;
import javax.ws.rs.ext.ExceptionMapper;
import javax.ws.rs.ext.Provider;

@Provider
public class PersonNotFoundExceptionMapper implements ExceptionMapper<PersonNotFoundException> {

    @Override
    public Response toResponse(PersonNotFoundException exception) {
        return Response.status(Response.Status.NOT_FOUND)
                       .entity("Person not found: " + exception.getMessage())
                       .type("text/plain")
                       .build();
    }
}

`}</code>
        </pre>
      </li><br />

      <li>
        <strong>Throwing the Exception in Your Resource Class:</strong> You can throw the exception in your JAX-RS resource class when
        a specific condition occurs.
        <pre>
          <code>{`
          @Path("/person")
public class PersonResource {

    @GET
    @Path("/{id}")
    @Produces("application/json")
    public Person getPerson(@PathParam("id") int id) {
        Person person = findPersonById(id);
        if (person == null) {
            throw new PersonNotFoundException("Person with id " + id + " not found.");
        }
        return person;
    }

    private Person findPersonById(int id) {
        return null; // Simulating a person not found scenario
    }
}

           `} </code>
        </pre>
      </li><br />

      <li>
        <strong>Testing the Exception Handling:</strong> If you make a <code>GET</code> request to <code>{"/person/{id}"}</code> with an invalid
        <code>id</code>, the <code>PersonNotFoundException</code> will be thrown and caught by the <code>ExceptionMapper</code>, returning
        a response with a <code>404</code> status and a message like "Person not found: Person with id X not found."
        <pre>
          <code>
            curl -X GET http://localhost:8080/api/person/999
          </code>
        </pre>
        <p>Response:</p>
        <pre>
          <code>404 Not Found</code>
          <br />
          Person not found: Person with id 999 not found.
        </pre>
      </li>
    </ol><br />

    <h2>2. Using <code>@ResponseStatus</code> for Simplified Exception Handling (Java EE 8)</h2>
    <p style={{paddingBottom:"6px"}}>Java EE 8 and later also support <code>@ResponseStatus</code>, allowing you to define the status code directly within the exception class.</p>

    <h3>Example Using <code>@ResponseStatus</code>:</h3>

    <ol>
      <li>
        <strong>Define the Exception with Status:</strong> 
        <pre>
          <code>{`
          import javax.ws.rs.WebApplicationException;
import javax.ws.rs.core.Response;

public class PersonNotFoundException extends WebApplicationException {
    public PersonNotFoundException(String message) {
        super(Response.status(Response.Status.NOT_FOUND)
                    .entity(message)
                    .type("text/plain")
                    .build());
    }
}

           `} </code>
        </pre>
      </li><br />

      <li>
        <strong>Use the Exception in Your Resource Class:</strong> The behavior remains the same as with the previous approach but with less code.
        <pre>
          <code>{`
          @Path("/person")
public class PersonResource {

    @GET
    @Path("/{id}")
    @Produces("application/json")
    public Person getPerson(@PathParam("id") int id) {
        Person person = findPersonById(id);
        if (person == null) {
            throw new PersonNotFoundException("Person with id " + id + " not found.");
        }
        return person;
    }

    private Person findPersonById(int id) {
        return null; // Simulating a person not found scenario
    }
}

 `} </code>
        </pre>
      </li>
    </ol><br />

    <h2>3. Handling Multiple Exceptions in a Single Mapper</h2>
    <p>You can handle multiple exceptions in one <code>ExceptionMapper</code> by checking the type of exception and returning the appropriate response.</p>

    <pre>
      <code>{`
      import javax.ws.rs.core.Response;
import javax.ws.rs.ext.ExceptionMapper;
import javax.ws.rs.ext.Provider;

@Provider
public class GeneralExceptionMapper implements ExceptionMapper<Exception> {

    @Override
    public Response toResponse(Exception exception) {
        if (exception instanceof PersonNotFoundException) {
            return Response.status(Response.Status.NOT_FOUND)
                           .entity("Person not found: " + exception.getMessage())
                           .type("text/plain")
                           .build();
        } else if (exception instanceof IllegalArgumentException) {
            return Response.status(Response.Status.BAD_REQUEST)
                           .entity("Invalid input: " + exception.getMessage())
                           .type("text/plain")
                           .build();
        }
        // Handle generic exceptions
        return Response.status(Response.Status.INTERNAL_SERVER_ERROR)
                       .entity("An unexpected error occurred.")
                       .type("text/plain")
                       .build();
    }
}

`}</code>
    </pre><br />

    <h2>4. Global Exception Handling</h2>
    <p>Define a global exception handler using <code>@Provider</code> to catch and handle any unhandled exceptions.</p>

    <pre>
      <code>{`
      @Provider
public class GlobalExceptionMapper implements ExceptionMapper<Throwable> {

    @Override
    public Response toResponse(Throwable throwable) {
        // Log the exception (can use a logging framework here)
        return Response.status(Response.Status.INTERNAL_SERVER_ERROR)
                       .entity("An unexpected error occurred: " + throwable.getMessage())
                       .type("text/plain")
                       .build();
    }
}

      `}</code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      In Java EE (Jakarta EE), exception handling in REST APIs can be managed effectively using <code>@ExceptionMapper</code>, custom exceptions,
      and response status annotations. You can:
    </p>
    <ul>
      <li>Map exceptions to specific HTTP statuses and messages.</li><br />
      <li>Provide detailed error messages to users.</li><br />
      <li>Use global handlers to catch unexpected errors.</li>
    </ul><br />
    <p>This leads to more robust, readable, and maintainable RESTful web services.</p>
  </div>
)}


{selectedChapter === 'chapter34' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Securing REST APIs (JWT, OAuth)</h1>

    <p>Here’s a detailed outline and explanation for your subject, "Introduction to REST and Securing REST APIs (JWT, OAuth)" under RESTful Web Services (JAX-RS):</p>

   <br />

    <h2>1. Introduction to REST (Representational State Transfer)</h2>
    <p>
      REST is an architectural style used to design networked applications. It is stateless and focuses on system scalability and simplicity. In the context of JAX-RS (Java API for RESTful Web Services), RESTful services use HTTP methods to perform CRUD operations.
    </p>
    <p><strong>Key Principles of REST:</strong></p>
    <ul>
      <li><strong>Stateless:</strong> Each request from a client to a server must contain all the information the server needs to fulfill the request.</li><br />
      <li><strong>Uniform Interface:</strong> REST relies on a standard set of methods like GET, POST, PUT, DELETE.</li><br />
      <li><strong>Client-Server Architecture:</strong> The client and server are separate entities, communicating through HTTP.</li><br />
      <li><strong>Cacheability:</strong> Responses should explicitly indicate whether they can be cached.</li><br />
      <li><strong>Layered System:</strong> The system architecture can have multiple layers, such as servers, databases, and caches, without the client knowing about them.</li>
    </ul><br />

    <p><strong>HTTP Methods in REST:</strong></p>
    <ul>
      <li><strong>GET:</strong> Retrieve information (read).</li><br />
      <li><strong>POST:</strong> Send data to create a new resource.</li><br />
      <li><strong>PUT:</strong> Update an existing resource.</li><br />
      <li><strong>DELETE:</strong> Remove a resource.</li>
    </ul><br />

    <p><strong>Basic Example:</strong></p>
    <ul>
      <li><code>{"GET /api/person/{id}"}</code>: Retrieve person details by ID.</li><br />
      <li><code>{"POST /api/person"}</code>: Create a new person.</li><br />
      <li><code>{"PUT /api/person/{id}"}</code>: Update an existing person's details.</li><br />
      <li><code>{"DELETE /api/person/{id}"}</code>: Delete a person.</li>
    </ul>

  <br />

    <h2>2. Introduction to JAX-RS (Java API for RESTful Web Services)</h2>
    <p style={{paddingBottom:"6px"}}>
      JAX-RS is a set of APIs to build RESTful web services in Java. It simplifies the process of creating REST services using annotations and Java classes.
    </p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Annotations:</strong> JAX-RS uses annotations like <code>@Path</code>, <code>@GET</code>, <code>@POST</code>, <code>@PUT</code>, <code>@DELETE</code>, <code>@Produces</code>, <code>@Consumes</code> to map Java methods to HTTP operations.</li><br />
      <li><strong>Exception Handling:</strong> JAX-RS provides ways to handle exceptions through <code>@Provider</code> and <code>ExceptionMapper</code>.</li><br />
      <li><strong>Content Negotiation:</strong> JAX-RS supports automatic content negotiation based on HTTP request headers (like <code>Accept</code> and <code>Content-Type</code>).</li>
    </ul><br />

    <p><strong>Basic JAX-RS Example:</strong></p>
    <pre>
      <code>{`
        @Path("/person")
        public class PersonResource {
            @GET
            @Path("/{id}")
            @Produces("application/json")
            public Person getPerson(@PathParam("id") int id) {
                return findPersonById(id); // Assume findPersonById is implemented
            }
        }
       `} </code>
    </pre>

  <br />

    <h2>3. Securing REST APIs (JWT, OAuth)</h2>
    <p style={{paddingBottom:"6px"}}>
      When exposing REST APIs, security is crucial to ensure only authorized users can access or modify resources. Two popular approaches to securing REST APIs are <strong>JWT (JSON Web Tokens)</strong> and <strong>OAuth 2.0</strong>.
    </p>

    <h3>3.1 Securing REST APIs with JWT (JSON Web Tokens)</h3>
    <p style={{paddingBottom:"6px"}}>
      JWT is a compact, URL-safe means of representing claims to be transferred between two parties. It is commonly used for authentication and authorization purposes in REST APIs.
    </p>
    <p><strong>How JWT Works:</strong></p>
    <ul>
      <li><strong>Token Generation:</strong> After a successful login, the server generates a JWT token, which includes claims (such as user information) and is signed by the server.</li><br />
      <li><strong>Token Transmission:</strong> The client sends the JWT token in the HTTP <code>Authorization</code> header in subsequent requests.</li><br />
      <li><strong>Token Validation:</strong> The server validates the JWT token on each request to ensure the user is authorized to access the requested resource.</li>
    </ul><br />

    <p><strong>Example of JWT Authentication:</strong></p>
    <ol>
      <li><strong>Login:</strong> The client sends a <code>POST</code> request with user credentials (e.g., username and password).
        <pre>
          <code>
            curl -X POST -d "username=user&password=pass" http://localhost:8080/api/login
          </code>
        </pre>
      </li><br />
      <li><strong>Server responds with JWT:</strong>
        <pre>
          <code>
            {`
              "token": "your.jwt.token"
            `}
          </code>
        </pre>
      </li><br />
      <li><strong>Access protected resource:</strong> The client sends the token in the <code>Authorization</code> header.
        <pre>
          <code>
            curl -H "Authorization: Bearer your.jwt.token" http://localhost:8080/api/protected
          </code>
        </pre>
      </li>
      <li><strong>Server validates JWT:</strong> The server decodes the token, verifies its signature, and checks its expiration time. If valid, the request is processed; otherwise, it returns <code>401 Unauthorized</code>.</li>
    </ol>

    <p><strong>JWT Implementation in JAX-RS:</strong></p>
    <pre>
      <code>{`
        @Path("/secure")
        public class SecureResource {
            @GET
            @Path("/protected")
            @Produces("application/json")
            public Response getProtectedResource(@HeaderParam("Authorization") String token) {
                if (isValidJWT(token)) {
                    return Response.ok("Access granted to protected resource").build();
                } else {
                    return Response.status(Response.Status.UNAUTHORIZED).entity("Invalid token").build();
                }
            }

            private boolean isValidJWT(String token) {
                // Implement JWT validation logic here
                return true; // Simplified for demonstration
            }
        }
       `} </code>
    </pre><br />

    <h3>3.2 Securing REST APIs with OAuth 2.0</h3>
    <p style={{paddingBottom:"6px"}}>
      OAuth 2.0 is an authorization framework that allows third-party services to exchange access tokens on behalf of a user. OAuth is commonly used in scenarios where users want to authorize a third-party application to access their data without exposing their credentials.
    </p>

    <p><strong>OAuth 2.0 Flow:</strong></p>
    <ol>
      <li><strong>Authorization Code Flow:</strong> The client requests an authorization code by redirecting the user to the authorization server. The user grants or denies access, and the server redirects back with the authorization code.</li><br />
      <li><strong>Token Exchange:</strong> The client exchanges the authorization code for an access token.</li><br />
      <li><strong>Access Resource:</strong> The client uses the access token to access protected resources on the server.</li>
    </ol><br />

    <p><strong>OAuth Implementation:</strong></p>
    <pre>
      <code>{`
        @Path("/oauth")
        public class OAuthResource {
            @GET
            @Path("/protected")
            @Produces("application/json")
            public Response getOAuthProtectedResource(@HeaderParam("Authorization") String token) {
                if (isValidOAuthToken(token)) {
                    return Response.ok("Access granted to OAuth protected resource").build();
                } else {
                    return Response.status(Response.Status.UNAUTHORIZED).entity("Invalid OAuth token").build();
                }
            }

            private boolean isValidOAuthToken(String token) {
                // Validate the OAuth token (this would typically involve calling an OAuth provider)
                return true;
            }
        }
       `} </code>
    </pre><br />

    <h3 style={{paddingBottom:"6px"}}>OAuth Flow Overview:</h3>
    <ul>
      <li><strong>Authorization Request:</strong> The client requests access to a resource on behalf of the user.</li><br />
      <li><strong>Authorization Server:</strong> The server authenticates the user and grants or denies access.</li><br />
      <li><strong>Token Exchange:</strong> If access is granted, the client receives an access token.</li><br />
      <li><strong>Accessing Resources:</strong> The client uses the token to access protected APIs on the resource server.</li>
    </ul>

   <br />
  </div>
)}

{selectedChapter === 'chapter35' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to SOAP and JAX-WS </h1>


      <h2>What is SOAP?</h2>
      <p>
        SOAP (Simple Object Access Protocol) is a protocol specification that defines a way to exchange structured information in the implementation of web services. It is based on XML, which allows different systems to communicate over different platforms. SOAP can be used for messaging, and it is transport-neutral, meaning it can work over various protocols like HTTP, SMTP, or even JMS.
      </p>
      <ul>
        <li><strong>XML-based:</strong> SOAP messages are formatted in XML and consist of an envelope that contains the message body and a header (optional).</li><br />
        <li><strong>Protocol-Agnostic:</strong> SOAP can work over various transport protocols such as HTTP, SMTP, or JMS.</li><br />
        <li><strong>Extensibility:</strong> SOAP is extensible and supports features like security, transactions, and messaging patterns.</li><br />
        <li><strong>Reliability and Security:</strong> SOAP supports WS-Security, WS-ReliableMessaging, and other standards to ensure the secure and reliable delivery of messages.</li>
      </ul><br />

      <h3>SOAP Message Structure</h3>
      <p>A SOAP message typically has the following parts:</p>
      <ol>
        <li><strong>Envelope:</strong> Defines the start and end of the message.</li><br />
        <li><strong>Header (optional):</strong> Contains metadata such as authentication or transaction information.</li><br />
        <li><strong>Body:</strong> Contains the actual message or data being exchanged.</li><br />
        <li><strong>Fault (optional):</strong> Provides error details when the message cannot be processed.</li>
      </ol><br />

      <h4>SOAP Example</h4>
      <pre>
        <code>
          {`<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
                            xmlns:web="http://www.example.org/webservice">
  <soapenv:Header/>
  <soapenv:Body>
    <web:HelloWorld>
      <web:name>John Doe</web:name>
    </web:HelloWorld>
  </soapenv:Body>
</soapenv:Envelope>`}
        </code>
      </pre><br />

      <h2>What is JAX-WS (Java API for XML Web Services)?</h2>
      <p>
        **JAX-WS (Java API for XML Web Services)** is a Java API that allows developers to create SOAP-based web services using Java. It simplifies the process of developing, deploying, and consuming web services, and provides an abstraction for building web services that communicate using SOAP.
      </p>
      <ul>
        <li><strong>Annotation-Based:</strong> JAX-WS uses annotations like `@WebService`, `@WebMethod`, etc., to define and expose methods as web services.</li><br />
        <li><strong>WSDL (Web Services Description Language):</strong> JAX-WS generates a WSDL file, which describes the web service’s interface (operations, input/output messages, etc.).</li><br />
        <li><strong>Supports SOAP and XML Binding:</strong> JAX-WS provides built-in support for SOAP message handling and XML data binding.</li>
      </ul><br />
 
      <h2 style={{paddingBottom:"6px"}}>Creating a SOAP Web Service using JAX-WS</h2>
      <h3>Step 1: Create a Java Service Endpoint</h3>
      <p>A service endpoint in JAX-WS is defined by a Java class annotated with `@WebService`. The methods in this class are exposed as web service operations.</p>
      <pre>
        <code>
          {`import javax.jws.WebService;
import javax.jws.WebMethod;

@WebService
public class HelloWorldService {

    @WebMethod
    public String sayHello(String name) {
        return "Hello, " + name + "!";
    }
}`}
        </code>
      </pre><br />

      <h3>Step 2: Publish the Web Service</h3>
      <p>To make the web service available to clients, it needs to be published using the `Endpoint` class from `javax.xml.ws`.</p>
      <pre>
        <code>
          {`import javax.xml.ws.Endpoint;

public class HelloWorldPublisher {

    public static void main(String[] args) {
        // Publish the web service at the specified URL
        Endpoint.publish("http://localhost:8080/ws/hello", new HelloWorldService());
    }
}`}
        </code>
      </pre><br />

      <h3>Step 3: Generate WSDL</h3>
      <p>JAX-WS automatically generates a WSDL file based on the annotations in the service class. The WSDL file can be accessed by appending `?wsdl` to the service URL.</p>
      <p>For example:</p>
      <pre>
        <code>
          {`http://localhost:8080/ws/hello?wsdl`}
        </code>
      </pre><br />

      <h2 style={{paddingBottom:"6px"}}>Consuming a SOAP Web Service with JAX-WS</h2>
      <h3>Step 1: Use the `wsimport` Tool</h3>
      <p>Run the `wsimport` tool from the command line to generate client-side code from the WSDL.</p>
      <pre>
        <code>
          {`wsimport -s src -d bin http://localhost:8080/ws/hello?wsdl`}
        </code>
      </pre><br />

      <h3>Step 2: Write the Client Code</h3>
      <p>Once the classes are generated, you can use them to consume the SOAP web service.</p>
      <pre>
        <code>
          {`import javax.xml.ws.Service;
import java.net.URL;
import javax.xml.namespace.QName;

public class HelloWorldClient {
    public static void main(String[] args) throws Exception {
        URL wsdlURL = new URL("http://localhost:8080/ws/hello?wsdl");
        QName qname = new QName("http://example.org/", "HelloWorldService");

        Service service = Service.create(wsdlURL, qname);
        HelloWorld hello = service.getPort(HelloWorld.class);

        System.out.println(hello.sayHello("John"));
    }
}`}
        </code>
      </pre><br />

      <h3>Step 3: Run the Client</h3>
      <p style={{paddingBottom:"6px"}}>The client can now invoke the SOAP service's `sayHello()` method, sending a SOAP request and receiving a SOAP response.</p>
   
      <h2 style={{paddingBottom:"6px"}}>Summary of Key Concepts</h2>
      <ul>
        <li><strong>SOAP:</strong> A protocol for exchanging structured messages between systems, typically using XML.</li><br />
        <li><strong>JAX-WS:</strong> A Java API for creating and consuming SOAP-based web services. It simplifies the development and consumption of SOAP services by using annotations.</li><br />
        <li><strong>WSDL:</strong> A machine-readable XML document that describes the interface of the web service, including operations and message formats.</li><br />
        <li><strong>Publishing a Service:</strong> JAX-WS allows easy publishing of services using `Endpoint.publish()`.</li><br />
        <li><strong>Consuming a Service:</strong> You can use the `wsimport` tool to generate client-side code to interact with a SOAP web service.</li>
      </ul><br />
   

    <p>By combining SOAP with JAX-WS, you can easily create, deploy, and consume web services in Java EE, providing a standardized approach for enterprise applications to communicate over the network.</p>
  </div>
)}

{selectedChapter === 'chapter36' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>WSDL (Web Services Description Language)</h1>

    <p>
      <strong>WSDL (Web Services Description Language)</strong> is an XML-based language used to describe the functionalities offered by a web service. It defines the service's operations, the input and output parameters for each operation, and the communication protocols used by the service. In Java EE, WSDL plays a crucial role in enabling interoperability between different systems by providing a standard way of describing web services.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Here's how WSDL is utilized in Java EE:</h3>

    <h2>1. What is WSDL?</h2>

    <p>
      WSDL is an XML document that provides a machine-readable description of a web service, including the following:
    </p>
    <ul>
      <li><strong>Service Endpoint:</strong> The URL where the web service is hosted.</li><br />
      <li><strong>Operations:</strong> The functions or methods provided by the service.</li><br />
      <li><strong>Messages:</strong> The input and output data for each operation, usually defined using XML Schema (XSD).</li><br />
      <li><strong>Binding:</strong> The protocol (e.g., SOAP or HTTP) and data encoding (e.g., XML or JSON) used for communication.</li><br />
      <li><strong>Port Types:</strong> Grouping of operations into a single interface.</li>
    </ul><br />

    <p>
      A WSDL document serves as a contract between the service provider and the client, ensuring that the client knows how to interact with the web service without needing direct access to its implementation.
    </p>

   <br />

    <h2>2. WSDL Structure</h2>

    <p>
      A typical WSDL document is composed of the following elements:
    </p>
    <ul>
      <li><strong>&lt;types&gt;:</strong> Defines the data types used in the service.</li><br />
      <li><strong>&lt;message&gt;:</strong> Defines the messages exchanged between client and server, with input and output data.</li><br />
      <li><strong>&lt;portType&gt;:</strong> Specifies the operations (methods) supported by the service.</li><br />
      <li><strong>&lt;binding&gt;:</strong> Defines the message format and protocol for communication.</li><br />
      <li><strong>&lt;service&gt;:</strong> Describes the endpoint of the web service.</li>
    </ul><br />

    <h3>Example of a Simple WSDL:</h3>
    <pre>
      <code>
        {`
<definitions xmlns:wsdl="http://schemas.xmlsoap.org/wsdl/"
             xmlns:soap="http://schemas.xmlsoap.org/wsdl/soap/"
             xmlns:xsd="http://www.w3.org/2001/XMLSchema"
             targetNamespace="http://example.org/webservice">

   <!-- Message definitions -->
   <message name="SayHelloRequest">
      <part name="name" type="xsd:string"/>
   </message>
   <message name="SayHelloResponse">
      <part name="greeting" type="xsd:string"/>
   </message>

   <!-- PortType (operations) -->
   <portType name="HelloWorldPortType">
      <operation name="sayHello">
         <input message="tns:SayHelloRequest"/>
         <output message="tns:SayHelloResponse"/>
      </operation>
   </portType>

   <!-- Binding definitions -->
   <binding name="HelloWorldBinding" type="tns:HelloWorldPortType">
      <soap:binding style="rpc" transport="http://schemas.xmlsoap.org/soap/http"/>
      <operation name="sayHello">
         <soap:operation soapAction="urn:sayHello"/>
         <input>
            <soap:body use="literal"/>
         </input>
         <output>
            <soap:body use="literal"/>
         </output>
      </operation>
   </binding>

   <!-- Service definition -->
   <service name="HelloWorldService">
      <port name="HelloWorldPort" binding="tns:HelloWorldBinding">
         <soap:address location="http://localhost:8080/helloWorld"/>
      </port>
   </service>
</definitions>
      `}
      </code>
    </pre>

   <br />

    <h2>3. WSDL and JAX-WS in Java EE</h2>

    <p>
      In Java EE, <strong>JAX-WS (Java API for XML Web Services)</strong> is used to create and consume SOAP-based web services. JAX-WS automatically generates WSDL documents based on the service's annotations and provides tools to publish, bind, and consume SOAP-based services.
    </p>
    <ul>
      <li><strong>Service Definition:</strong> The <code>@WebService</code> annotation is used to define a service class.</li><br />
      <li><strong>WSDL Generation:</strong> JAX-WS automatically generates the WSDL file when a web service is deployed, and the WSDL can be accessed via the URL by appending <code>?wsdl</code> to the service endpoint URL.</li><br />
      <li><strong>WSDL Customization:</strong> You can customize the WSDL by modifying the <code>@WebService</code> annotations or using JAXB for XML data binding.</li><br />
    </ul>
<br />

    <h2 style={{paddingBottom:"6px"}}>4. Using WSDL with JAX-WS</h2>

    <h3>Generating WSDL for a Service:</h3>
    <p>Consider the following service endpoint in Java:</p>
    <pre>
      <code>
        {`
import javax.jws.WebService;
import javax.jws.WebMethod;

@WebService
public class HelloWorldService {
    @WebMethod
    public String sayHello(String name) {
        return "Hello, " + name + "!";
    }
}
        `}
      </code>
    </pre>
    <p>
      After deploying this service, JAX-WS generates the WSDL for the service, which can be accessed at:
    </p>
    <pre><code>http://localhost:8080/helloWorld?wsdl</code></pre>
    <p>
      The generated WSDL document will contain the necessary information for clients to interact with the web service.
    </p><br />

    <h3>Consuming a Web Service Using WSDL:</h3>
    <p>Clients can use the WSDL file to generate client-side code using the <code>wsimport</code> tool:</p>
    <pre><code>wsimport -s src -d bin http://localhost:8080/helloWorld?wsdl</code></pre>
    <p>
      This generates Java classes that correspond to the operations and messages described in the WSDL. The client can then call the web service methods using these generated classes.
    </p>

   <br />

    <h2 style={{paddingBottom:"6px"}}>5. Key Concepts in WSDL</h2>

    <ul>
      <li><strong>WSDL and SOAP:</strong> WSDL is often used in conjunction with SOAP for describing the XML-based communication between the client and the service.</li><br />
      <li><strong>Binding:</strong> WSDL defines how messages should be encoded and transmitted, typically via SOAP.</li><br />
      <li><strong>Port Types and Operations:</strong> Defines the available methods of the service and the data that they expect and return.</li><br />
      <li><strong>Namespace:</strong> WSDL uses XML namespaces to differentiate between various elements in the service description.</li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>6. Summary</h2>

    <ul>
      <li><strong>WSDL</strong> is a key element of SOAP-based web services, providing a machine-readable contract for interaction.</li><br />
      <li><strong>JAX-WS</strong> in Java EE simplifies the creation and consumption of SOAP web services and automatically generates WSDL.</li><br />
      <li>WSDL describes operations, input/output messages, and the communication protocol used, facilitating integration and ensuring compatibility between different systems.</li>
    </ul><br />

    <p>
      By understanding how to work with WSDL in Java EE, developers can build, deploy, and consume web services in a standardized and interoperable manner.
    </p>
  </div>
)}

{selectedChapter === 'chapter37' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Creating and Consuming SOAP Web Services</h1>
    
    <p>
      Creating and consuming SOAP (Simple Object Access Protocol) web services in Java EE involves several steps. Below is an overview of how you can implement SOAP-based web services in Java EE:
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>1. Creating a SOAP Web Service in Java EE</h2>

    <h3>1.1. Setting up a Java EE Project</h3>
    <p>
      You can use any Java EE-compliant application server such as <strong>WildFly</strong>, <strong>GlassFish</strong>, or <strong>Apache TomEE</strong> to develop and deploy your SOAP web service. If you're using an IDE like <strong>Eclipse</strong> or <strong>IntelliJ IDEA</strong>, you can create a dynamic web project with the necessary libraries for Java EE.
    </p><br />

    <h3>1.2. Creating the Service Endpoint (Web Service)</h3>
    <p>In Java EE, SOAP web services are often created using JAX-WS (Java API for XML Web Services). To create a SOAP web service, follow these steps:</p>

    <ul>
      <li>
        <strong>Step 1:</strong> Create a service endpoint interface (SEI). This interface defines the methods that will be exposed as web service operations.
        <pre>
          <code>{`
import javax.jws.WebMethod;
import javax.jws.WebService;

@WebService
public interface MyWebService {
    @WebMethod
    String sayHello(String name);
}
 `} </code>
        </pre>
      </li><br />

      <li>
        <strong>Step 2:</strong> Implement the service endpoint.
        <pre>
          <code>{`
import javax.jws.WebService;

@WebService(endpointInterface = "com.example.MyWebService")
public class MyWebServiceImpl implements MyWebService {
    @Override
    public String sayHello(String name) {
        return "Hello, " + name;
    }
}
`}</code>
        </pre>
      </li><br />

      <li>
        <strong>Step 3:</strong> Publish the web service using the `Endpoint` class or via configuration in your application server.
        <pre>
          <code>{`
import javax.xml.ws.Endpoint;

public class WebServicePublisher {
    public static void main(String[] args) {
        Endpoint.publish("http://localhost:8080/myWebService", new MyWebServiceImpl());
    }
}
  `}  </code>
        </pre>
      </li>
    </ul><br />

    <h3>1.3. Deploy the Web Service</h3>
    <p style={{paddingBottom:"6px"}}>
      Deploy your project to your Java EE application server. After deployment, you should be able to access the WSDL (Web Services Description Language) for your SOAP service at a URL like <code>http://localhost:8080/myWebService?wsdl</code>.
    </p><br />

    <h2>2. Consuming a SOAP Web Service in Java EE</h2>

    <p style={{paddingBottom:"6px"}}>
      To consume a SOAP web service in Java EE, you typically use <strong>JAX-WS</strong> client APIs. Here's how you can consume a SOAP web service:
    </p>

    <h3>2.1. Generate Client Stubs using WSDL</h3>
    <p>
      You can generate the client-side code (stubs) using the <code>wsimport</code> tool that comes with JDK.
    </p>
    <pre>
      <code>{`
wsimport -s src -d bin http://localhost:8080/myWebService?wsdl
     `} </code>
    </pre><br />

    <h3>2.2. Using the Generated Stubs in a Java EE Client</h3>
    <p>
      Once the stubs are generated, you can use them to consume the web service. Here's an example:
    </p>
    <pre>
      <code>{`
import com.example.mywebservice.MyWebService;
import com.example.mywebservice.MyWebServiceImplService;

public class WebServiceClient {
    public static void main(String[] args) {
        MyWebServiceImplService service = new MyWebServiceImplService();
        MyWebService port = service.getMyWebServiceImplPort();

        // Call the web service
        String response = port.sayHello("World");
        System.out.println(response);
    }
}
     `} </code>
    </pre><br />

    <h3>2.3. Consume SOAP Service with JAX-WS Client API</h3>
    <p>
      Alternatively, you can directly create a <code>Service</code> and <code>Dispatch</code> objects if you need more control or are not using generated stubs:
    </p>
    <pre>
      <code>{`
import javax.xml.ws.Service;
import javax.xml.namespace.QName;
import java.net.URL;

public class JAXWSClient {
    public static void main(String[] args) throws Exception {
        URL wsdlURL = new URL("http://localhost:8080/myWebService?wsdl");
        QName serviceName = new QName("http://example.com/", "MyWebServiceImplService");
        Service service = Service.create(wsdlURL, serviceName);
        MyWebService port = service.getPort(MyWebService.class);

        String response = port.sayHello("Java EE");
        System.out.println(response);
    }
}
     `} </code>
    </pre><br />

    <h2>3. Conclusion</h2>
    <p>
      With these steps, you can create and consume SOAP web services in Java EE using JAX-WS. SOAP web services in Java EE provide a powerful and standardized way to exchange data between applications over a network. You can create the web service, deploy it to a Java EE application server, and consume it from other Java EE applications using client stubs or the JAX-WS client API.
    </p>
  </div>
)}



{selectedChapter === 'chapter38' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>
      Handling Exceptions and Faults in SOAP 
    </h1>

    <div>
      <p>
        SOAP (Simple Object Access Protocol) web services rely on a strict messaging protocol, 
        and proper handling of exceptions and faults is critical to ensure reliable communication. 
        JAX-WS provides robust mechanisms to handle and propagate exceptions as SOAP faults.
      </p>

      <br />

      <h2>1. Understanding SOAP Faults</h2>
      <ul>
        <li><strong>SOAP Fault</strong>: A standardized way to represent errors in SOAP messaging.</li>
        <li>A SOAP fault includes:
          <ul>
            <li><strong>Fault Code</strong>: Indicates the type of fault.</li><br />
            <li><strong>Fault String</strong>: A human-readable description of the fault.</li><br />
            <li><strong>Fault Actor</strong>: Identifies the source of the fault (optional).</li><br />
            <li><strong>Detail</strong>: Provides additional details about the fault (optional).</li>
          </ul>
        </li>
      </ul>
<br />

      <h2>2. Exception Handling in JAX-WS</h2>
      <p>In JAX-WS, exceptions can be mapped to SOAP faults in two ways:</p>
      <ol>
        <li><strong>Checked Exceptions</strong>: Automatically converted to SOAP faults.</li><br />
        <li><strong>Custom Exceptions</strong>: Annotated to provide specific SOAP fault information.</li>
      </ol>
<br />

      <h2 style={{paddingBottom:"6px"}}>3. Steps to Handle Exceptions in JAX-WS</h2>
      <h3>Step 1: Define a Custom Exception</h3>
      <pre className={style.codeBlock}>
{`import javax.xml.ws.WebFault;

@WebFault(name = "CustomFault")
public class CustomException extends Exception {
    private final String faultDetails;

    public CustomException(String message, String faultDetails) {
        super(message);
        this.faultDetails = faultDetails;
    }

    public String getFaultDetails() {
        return faultDetails;
    }
}`}
      </pre><br />

      <h3>Step 2: Define a Fault Bean</h3>
      <pre className={style.codeBlock}>
{`public class FaultDetails {
    private String errorCode;
    private String errorMessage;

    public FaultDetails(String errorCode, String errorMessage) {
        this.errorCode = errorCode;
        this.errorMessage = errorMessage;
    }

    // Getters and Setters
}`}
      </pre><br />

      <h3>Step 3: Throw Exceptions in the Web Service</h3>
      <pre className={style.codeBlock}>
{`import javax.jws.WebService;

@WebService
public class ExampleService {
    public String processRequest(String input) throws CustomException {
        if (input == null || input.isEmpty()) {
            throw new CustomException("Invalid Input", "Input cannot be null or empty");
        }
        return "Processed: " + input;
    }
}`}
      </pre><br />

      <h3>Step 4: Customize the SOAP Fault</h3>
      <pre className={style.codeBlock}>
{`import javax.xml.ws.soap.SOAPFaultException;
import javax.xml.soap.SOAPFactory;
import javax.xml.soap.SOAPFault;

public void validateInput(String input) throws SOAPFaultException {
    try {
        if (input == null) {
            SOAPFault fault = SOAPFactory.newInstance()
                .createFault("Input is null", new QName("http://example.com", "ClientError"));
            throw new SOAPFaultException(fault);
        }
    } catch (SOAPException e) {
        e.printStackTrace();
    }
}`}
      </pre>

    <br />

      <h2>4. Handling Faults on the Client Side</h2>
      <pre className={style.codeBlock}>
{`import javax.xml.ws.WebServiceException;

public class ClientApp {
    public static void main(String[] args) {
        try {
            ExampleService service = new ExampleServiceService().getExampleServicePort();
            service.processRequest(null); // This will throw CustomException
        } catch (CustomException e) {
            System.out.println("Fault Details: " + e.getFaultDetails());
        } catch (WebServiceException e) {
            System.out.println("General Fault: " + e.getMessage());
        }
    }
}`}
      </pre>

      <br />

      <h2 style={{paddingBottom:"6px"}}>5. Key Features of Fault Handling in JAX-WS</h2>
      <ul>
        <li><strong>@WebFault Annotation</strong>: Helps bind a custom exception to a SOAP fault and enables detailed fault information in the WSDL.</li><br />
        <li><strong>SOAP Faults Propagation</strong>: Automatically maps exceptions to SOAP faults in a standard format.</li><br />
        <li><strong>Custom Fault Beans</strong>: Facilitates structured and detailed fault representation.</li>
      </ul>

 <br />

      <h2 style={{paddingBottom:"6px"}}>6. Best Practices</h2>
      <ul>
        <li><strong>Use Custom Exceptions</strong>: Ensure meaningful error messages and structured fault details.</li><br />
        <li><strong>Validate Inputs</strong>: Minimize faults by validating requests at the earliest stage.</li><br />
        <li><strong>Log Errors</strong>: Maintain logs for debugging and auditing purposes.</li><br />
        <li><strong>Handle Faults Gracefully on the Client</strong>: Provide user-friendly error messages when a fault occurs.</li>
      </ul>
<br />

      <h2>7. Conclusion</h2>
      <p>
        Proper handling of exceptions and faults ensures that SOAP web services using JAX-WS remain robust and client-friendly. 
        By leveraging custom exceptions, fault beans, and structured SOAP faults, developers can create reliable and maintainable web services 
        that gracefully handle errors.
      </p>
    </div>
  </div>
)}


{selectedChapter === 'chapter39' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to EJB</h1>
    <p>
      Enterprise JavaBeans (EJB) is a core component of the Java Platform, Enterprise Edition (Java EE). 
      It is a server-side software component used to encapsulate business logic in enterprise applications. 
      EJB simplifies the development of large-scale, distributed, and transactional systems by providing a robust infrastructure.
    </p>
  <br />

    <h2>1. What is EJB?</h2>
    <p>
      Enterprise JavaBeans is a specification for building scalable, secure, and transactional enterprise-level 
      applications. It abstracts complex system-level concerns such as transaction management, security, and concurrency, 
      allowing developers to focus on business logic.
    </p>
   <br />

    <h2 style={{paddingBottom:"6px"}}>2. Key Features of EJB</h2>
    <ul>
      <li><strong>Simplified Development:</strong> Provides a framework for building enterprise applications without worrying about low-level details.</li><br />
      <li><strong>Transaction Management:</strong> Built-in support for distributed transactions.</li><br />
      <li><strong>Security:</strong> Declarative and programmatic security mechanisms.</li><br />
      <li><strong>Persistence:</strong> Seamless integration with Java Persistence API (JPA).</li><br />
      <li><strong>Scalability:</strong> Automatically manages resource pooling and load distribution.</li><br />
      <li><strong>Remote Accessibility:</strong> Enables distributed applications through RMI (Remote Method Invocation).</li>
    </ul>
   <br />

    <h2>3. Types of EJBs</h2>
    <ul>
      <li>
        <strong>Session Beans:</strong>
        <ul>
          <li><strong>Stateless Session Beans:</strong> No client-specific state is maintained between method calls.</li><br />
          <li><strong>Stateful Session Beans:</strong> Maintain a conversational state with the client.</li><br />
          <li><strong>Singleton Session Beans:</strong> A single shared instance is used across the application.</li>
        </ul>
      </li><br />
      <li>
        <strong>Message-Driven Beans (MDB):</strong>
        <ul>
          <li>Designed for asynchronous messaging using Java Message Service (JMS).</li><br />
          <li>Useful for handling background tasks and event-driven communication.</li>
        </ul>
      </li><br />
      <li>
        <strong>Entity Beans:</strong> <em>(Deprecated in newer specifications)</em> Represent persistent data objects (replaced by JPA).
      </li>
    </ul>
<br />

    <h2>4. EJB Architecture</h2>
    <p>
      The EJB architecture follows a distributed, component-based model:
    </p>
    <ul>
      <li><strong>Client Layer:</strong> Consumes the business logic provided by EJBs.</li><br />
      <li><strong>EJB Container:</strong> Manages the lifecycle, security, transactions, and concurrency of the EJBs.</li><br />
      <li><strong>Database Layer:</strong> Integrates with relational databases for persistent storage.</li>
    </ul>
   <br />

    <h2 style={{paddingBottom:"6px"}}>5. EJB Lifecycle</h2>
    <ul>
      <li><strong>Stateless Session Bean:</strong> Created by the container, invoked by clients, and destroyed after use.</li><br />
      <li><strong>Stateful Session Bean:</strong> Maintains client state and transitions between states (Created, Passivated, Activated, Removed).</li><br />
      <li><strong>Singleton Session Bean:</strong> Initialized at application startup and remains active throughout the application lifecycle.</li><br />
      <li><strong>Message-Driven Bean:</strong> Responds to messages and is stateless by design.</li>
    </ul>
    <br />

    <h2>6. Basic Example of EJB</h2>
    <pre>
      <code>
{`import javax.ejb.Stateless;

@Stateless
public class CalculatorBean {
    public int add(int a, int b) {
        return a + b;
    }

    public int subtract(int a, int b) {
        return a - b;
    }
}`}
      </code>
    </pre><br />
    <p><strong>Client Accessing the Bean:</strong></p>
    <pre>
      <code>
{`import javax.naming.InitialContext;

public class EJBClient {
    public static void main(String[] args) throws Exception {
        InitialContext ctx = new InitialContext();
        CalculatorBean calculator = (CalculatorBean) ctx.lookup("java:global/CalculatorBean");
        System.out.println("Addition: " + calculator.add(10, 5));
    }
}`}
      </code>
    </pre>
  <br />

    <h2 style={{paddingBottom:"6px"}}>7. Benefits of Using EJB</h2>
    <ul>
      <li><strong>Reduced Complexity:</strong> Eliminates the need for boilerplate code by managing cross-cutting concerns.</li><br />
      <li><strong>High Reliability:</strong> Built-in support for fault tolerance and failover mechanisms.</li><br />
      <li><strong>Integration:</strong> Compatible with other Java EE technologies like JPA, JMS, and CDI (Context and Dependency Injection).</li><br />
      <li><strong>Scalability:</strong> Handles high traffic loads with built-in resource pooling.</li>
    </ul>
   <br />

    <h2>8. EJB in Modern Applications</h2>
    <p>
      While EJB was a cornerstone of Java EE, modern enterprise applications often leverage lightweight alternatives like the 
      <strong>Spring Framework</strong>. However, EJB remains relevant for:
    </p>
    <ul>
      <li>Applications requiring <strong>robust transaction management</strong>.</li><br />
      <li>Legacy systems built on Java EE.</li><br />
      <li>Scenarios needing distributed systems and asynchronous messaging.</li>
    </ul>
  <br />

    <h2>9. Conclusion</h2>
    <p>
      Enterprise JavaBeans is a powerful technology for building distributed and transactional enterprise applications. By abstracting the complexity of low-level system concerns, 
      EJB allows developers to focus on writing clean and maintainable business logic. Whether for legacy systems or modern distributed applications, understanding EJB is 
      crucial for Java EE developers.
    </p>
  </div>
)}

{selectedChapter === 'chapter40' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Types of EJB (Stateless, Stateful, Singleton)</h1>

    <p>
      Enterprise JavaBeans (EJB) is a vital part of Java EE for developing scalable and robust enterprise-level applications. EJB categorizes session beans into three types based on their behavior and usage: <strong>Stateless</strong>, <strong>Stateful</strong>, and <strong>Singleton</strong>. These types serve distinct purposes in enterprise application development.
    </p>

   <br />
    <h2>1. Stateless Session Bean</h2>
    <p>
      A <strong>Stateless Session Bean</strong> is designed for tasks that do not require maintaining any client-specific state between method invocations. Each method call is independent of others.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Characteristics:</h3>
    <ul>
      <li>Does not maintain a conversational state with the client.</li><br />
      <li>Instances are pooled and reused across multiple clients.</li><br />
      <li>Suitable for tasks like logging, calculations, or utility services.</li>
    </ul><br />
    <h3 style={{paddingBottom:"6px"}}>Lifecycle:</h3>
    <ol>
      <li><strong>Creation:</strong> The container creates instances and maintains a pool of beans.</li><br />
      <li><strong>Method Invocation:</strong> An instance is assigned to a client request from the pool.</li><br />
      <li><strong>Destruction:</strong> Instances are destroyed when the container reduces the pool size or shuts down.</li>
    </ol><br />
    <h3>Example:</h3>
    <pre>
      <code>
{`import javax.ejb.Stateless;

@Stateless
public class GreetingBean {
    public String greet(String name) {
        return "Hello, " + name + "!";
    }
}`}
      </code>
    </pre>

   <br />

    <h2>2. Stateful Session Bean</h2>
    <p>
      A <strong>Stateful Session Bean</strong> maintains a conversational state with the client. This means the state is retained across multiple method calls for the same client.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Characteristics:</h3>
    <ul>
      <li>Stores client-specific data during the session.</li><br />
      <li>Tied to a single client throughout its lifecycle.</li><br />
      <li>Ideal for scenarios like shopping carts or banking transactions.</li>
    </ul><br />
    <h3 style={{paddingBottom:"6px"}}>Lifecycle:</h3>
    <ol>
      <li><strong>Creation:</strong> The container creates the bean when a client initiates a session.</li><br />
      <li><strong>Passive State:</strong> The bean can be passivated (temporarily stored) if idle for a while.</li><br />
      <li><strong>Active State:</strong> Activated from a passive state when the client resumes interaction.</li><br />
      <li><strong>Destruction:</strong> Removed when the client session ends or times out.</li>
    </ol>
    <h3>Example:</h3>
    <pre>
      <code>
{`import javax.ejb.Stateful;

@Stateful
public class ShoppingCartBean {
    private List<String> cartItems = new ArrayList<>();

    public void addItem(String item) {
        cartItems.add(item);
    }

    public List<String> getItems() {
        return cartItems;
    }
}`}
      </code>
    </pre>

    <br />

    <h2>3. Singleton Session Bean</h2>
    <p>
      A <strong>Singleton Session Bean</strong> is instantiated only once per application and is shared across all clients. It is ideal for shared resources or application-wide tasks.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Characteristics:</h3>
    <ul>
      <li>Single instance per application, created at startup.</li><br />
      <li>Suitable for managing application-wide settings or caching.</li><br />
      <li>Can be accessed concurrently by multiple clients (requires thread safety).</li>
    </ul><br />
    <h3 style={{paddingBottom:"6px"}}>Lifecycle:</h3>
    <ol>
      <li><strong>Initialization:</strong> The container initializes the bean when the application starts.</li><br />
      <li><strong>Active State:</strong> Remains active throughout the application's lifecycle.</li><br />
      <li><strong>Destruction:</strong> Destroyed when the application is shut down.</li>
    </ol>
    <h3>Example:</h3>
    <pre>
      <code>
{`import javax.ejb.Singleton;

@Singleton
public class ConfigurationManagerBean {
    private Map<String, String> configurations = new HashMap<>();

    public void setConfig(String key, String value) {
        configurations.put(key, value);
    }

    public String getConfig(String key) {
        return configurations.get(key);
    }
}`}
      </code>
    </pre>

    <br />

    <h2>Comparison of EJB Types</h2>
    <table>
      <thead>
        <tr>
          <th>Type</th>
          <th>State Management</th>
          <th>Usage Scenarios</th>
          <th>Example Use Cases</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Stateless</td>
          <td>None</td>
          <td>Independent tasks</td>
          <td>Logging, calculations, validation</td>
        </tr>
        <tr>
          <td>Stateful</td>
          <td>Maintains client state</td>
          <td>Client-specific interactions</td>
          <td>Shopping cart, session tracking</td>
        </tr>
        <tr>
          <td>Singleton</td>
          <td>Shared application state</td>
          <td>Application-wide tasks</td>
          <td>Caching, configuration management</td>
        </tr>
      </tbody>
    </table>

    <br />

    <h2>Conclusion</h2>
    <p>
      Understanding the differences between <strong>Stateless</strong>, <strong>Stateful</strong>, and <strong>Singleton</strong> session beans is crucial for designing effective enterprise applications with EJB. Each type addresses specific needs, ensuring flexibility and scalability in enterprise solutions.
    </p>
  </div>
)}



{selectedChapter === 'chapter41' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> EJB Lifecycle </h1>

    <p>
      The Enterprise JavaBeans (EJB) lifecycle describes the various stages that an EJB goes through from creation to destruction. Each type of EJB (Stateless, Stateful, Singleton) has its own unique lifecycle management process, managed by the EJB container.
    </p>

    <br />

    <h2>1. Stateless Session Bean Lifecycle</h2>
    <p style={{paddingBottom:"6px"}}>
      The <strong>Stateless Session Bean</strong> lifecycle is managed by the container. It does not maintain any client-specific state between method invocations, making it lightweight and scalable.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Lifecycle Stages:</h3>
    <ol>
      <li><strong>Instantiation:</strong> The container creates an instance of the Stateless Session Bean and places it in a pool.</li><br />
      <li><strong>Method Invocation:</strong> A client request is routed to one of the pooled instances. The bean is stateless, so the container can reuse it across multiple clients.</li><br />
      <li><strong>Destruction:</strong> When the container no longer requires the instance, it is removed from the pool and discarded.</li>
    </ol>

    <h3>Example:</h3>
    <pre>
      <code>
{`import javax.ejb.Stateless;

@Stateless
public class GreetingBean {
    public String greet(String name) {
        return "Hello, " + name + "!";
    }
}`}
      </code>
    </pre>

    <br />

    <h2>2. Stateful Session Bean Lifecycle</h2>
    <p style={{paddingBottom:"6px"}}>
      The <strong>Stateful Session Bean</strong> maintains state across multiple method invocations for the same client, allowing the bean to retain client-specific data for the session.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Lifecycle Stages:</h3>
    <ol>
      <li><strong>Instantiation:</strong> The container creates the bean when a client initiates a session.</li><br />
      <li><strong>Method Invocation:</strong> The state is preserved across multiple method calls for the same client.</li><br />
      <li><strong>Passivation:</strong> If idle, the container may passivate (temporarily store) the bean to free resources.</li><br />
      <li><strong>Activation:</strong> The bean is activated from passivation when the client resumes interaction.</li><br />
      <li><strong>Destruction:</strong> The bean is removed when the session ends or times out.</li>
    </ol>

    <h3>Example:</h3>
    <pre>
      <code>
{`import javax.ejb.Stateful;

@Stateful
public class ShoppingCartBean {
    private List<String> cartItems = new ArrayList<>();

    public void addItem(String item) {
        cartItems.add(item);
    }

    public List<String> getItems() {
        return cartItems;
    }
}`}
      </code>
    </pre>

   <br />

    <h2>3. Singleton Session Bean Lifecycle</h2>
    <p style={{paddingBottom:"6px"}}>
      The <strong>Singleton Session Bean</strong> is created only once per application and is shared among all clients. It is ideal for tasks that need a single, shared resource.
    </p>
    <h3 style={{paddingBottom:"6px"}}>Lifecycle Stages:</h3>
    <ol>
      <li><strong>Instantiation:</strong> The container creates a single instance at application startup.</li><br />
      <li><strong>Method Invocation:</strong> The instance is shared among all clients. Thread-safety is crucial to ensure concurrent access.</li><br />
      <li><strong>Destruction:</strong> The bean is destroyed when the application shuts down.</li>
    </ol>

    <h3>Example:</h3>
    <pre>
      <code>
{`import javax.ejb.Singleton;

@Singleton
public class ConfigurationManagerBean {
    private Map<String, String> configurations = new HashMap<>();

    public void setConfig(String key, String value) {
        configurations.put(key, value);
    }

    public String getConfig(String key) {
        return configurations.get(key);
    }
}`}
      </code>
    </pre>

 <br />

    <h2>Comparison of EJB Lifecycle</h2>
    <table>
      <thead>
        <tr>
          <th>EJB Type</th>
          <th>Instantiation</th>
          <th>State Management</th>
          <th>Lifecycle Management</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Stateless</td>
          <td>Created and pooled by the container</td>
          <td>No state maintained</td>
          <td>Destroyed when no longer needed</td>
        </tr>
        <tr>
          <td>Stateful</td>
          <td>Created on session initiation</td>
          <td>State is maintained for the client</td>
          <td>Passivated when idle, destroyed when session ends</td>
        </tr>
        <tr>
          <td>Singleton</td>
          <td>Created once on application startup</td>
          <td>Shared state across all clients</td>
          <td>Destroyed on application shutdown</td>
        </tr>
      </tbody>
    </table>

   <br />

    <h2>Conclusion</h2>
    <p>
      Understanding the lifecycle of EJBs—whether Stateless, Stateful, or Singleton—is essential for managing resources effectively in enterprise applications. Each type of EJB offers different lifecycle stages, optimized for various application scenarios.
    </p>
  </div>
)}



{selectedChapter === 'chapter42' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Container-managed Transactions   </h1>

    <p>
      Container-managed transactions (CMT) are a key feature of Enterprise JavaBeans (EJB), providing a way for the EJB container to manage the transactional behavior of beans. With CMT, developers can focus on writing business logic while leaving transaction management to the EJB container, which simplifies the development of robust, transactional enterprise applications.
    </p>

    <br />

    <h2>What are Container-managed Transactions?</h2>
    <p style={{paddingBottom:"6px"}}>
      In EJB, container-managed transactions refer to the scenario where the EJB container is responsible for managing the transaction boundaries (begin, commit, and rollback). The developer simply defines the transactional behavior of the bean methods, and the container takes care of the underlying transaction management.
    </p>

    <h3 >Key Features of CMT:</h3>
    <ul>
      <li>The container automatically handles transaction commit and rollback.</li><br />
      <li>Transactions are typically associated with EJB method invocations.</li><br />
      <li>Declarative transaction management using annotations or deployment descriptors.</li><br />
      <li>Allows for different isolation levels and propagation behaviors.</li>
    </ul>

   <br />

    <h2>Configuring Container-managed Transactions</h2>
    <p style={{paddingBottom:"6px"}}>
      Container-managed transactions can be configured using annotations or deployment descriptors. By default, the container assumes that methods of an EJB bean should be part of the same transaction. However, developers can use annotations to define specific transactional behavior.
    </p>

    <h3>Using Annotations for Transaction Management:</h3>
    <p>
      The <code>@TransactionAttribute</code> annotation is used to specify the transactional behavior of EJB methods. The possible values for this annotation are:
    </p>
    <ul>
      <li><strong>REQUIRED:</strong> If a transaction exists, it will be used; otherwise, a new one will be created.</li><br />
      <li><strong>REQUIRES_NEW:</strong> Always starts a new transaction, suspending any existing transaction.</li><br />
      <li><strong>MANDATORY:</strong> Requires an existing transaction. If no transaction is active, an exception is thrown.</li><br />
      <li><strong>NEVER:</strong> The method should never be invoked within a transaction. If a transaction exists, an exception is thrown.</li><br />
      <li><strong>SUPPORTS:</strong> The method can be invoked within an existing transaction, but if no transaction exists, it will execute without one.</li>
      <li><strong>NOT_SUPPORTED:</strong> The method will execute without a transaction, even if a transaction is active.</li>
    </ul><br />

    <h3>Example of Using @TransactionAttribute Annotation:</h3>
    <pre>
      <code>
{`import javax.ejb.Stateless;
import javax.ejb.TransactionAttribute;
import javax.ejb.TransactionAttributeType;

@Stateless
public class OrderProcessingBean {

    @TransactionAttribute(TransactionAttributeType.REQUIRED)
    public void processOrder(Order order) {
        // Business logic to process the order
    }

    @TransactionAttribute(TransactionAttributeType.REQUIRES_NEW)
    public void cancelOrder(Order order) {
        // Business logic to cancel the order
    }
}`}
      </code>
    </pre>

  <br />

    <h2>Transaction Rollback and Exception Handling</h2>
    <p style={{paddingBottom:"6px"}}>
      One of the advantages of CMT is automatic transaction rollback. If a runtime exception is thrown within a method that is part of a container-managed transaction, the container will automatically roll back the transaction. For checked exceptions, the container will not automatically roll back the transaction unless explicitly configured.
    </p>

    <h3>Using @TransactionManagement Annotation:</h3>
    <p>
      The <code>@TransactionManagement</code> annotation is used to specify the transaction management type (CMT or Bean-managed). For container-managed transactions, the container takes care of the transaction boundaries.
    </p>
    <pre>
      <code>
{`import javax.ejb.TransactionManagement;
import javax.ejb.TransactionManagementType;

@TransactionManagement(TransactionManagementType.CONTAINER)
public class OrderServiceBean {
    // EJB methods for handling transactions
}`}
      </code>
    </pre>

   <br />

    <h2>Transaction Propagation and Isolation</h2>
    <p style={{paddingBottom:"6px"}}>
      CMT allows for various transaction propagation and isolation behaviors. For instance, you can specify how transactions behave when invoked from other EJBs or methods. Additionally, the isolation level determines how transactions are isolated from each other.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Transaction Propagation Types:</h3>
    <ul>
      <li><strong>PROPAGATION_REQUIRED:</strong> Use the existing transaction or create a new one.</li><br />
      <li><strong>PROPAGATION_REQUIRES_NEW:</strong> Always create a new transaction, suspending the existing one.</li><br />
      <li><strong>PROPAGATION_NESTED:</strong> A nested transaction within the current transaction.</li>
    </ul><br />

    <h3>Transaction Isolation Levels:</h3>
    <p>
      You can set isolation levels to control how transactions interact with each other. The standard isolation levels are:
    </p>
    <ul>
      <li><strong>READ_COMMITTED:</strong> Ensures that no dirty reads occur.</li><br />
      <li><strong>REPEATABLE_READ:</strong> Prevents non-repeatable reads but allows phantom reads.</li><br />
      <li><strong>SERIALIZABLE:</strong> The strictest level, ensuring complete isolation from other transactions.</li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Advantages of Container-managed Transactions</h2>
    <ul>
      <li>Reduces boilerplate code for transaction management, improving maintainability.</li><br />
      <li>Helps in maintaining consistency and integrity of data by automatically handling transaction boundaries.</li><br />
      <li>Supports flexible configurations with different transaction attributes, isolation levels, and propagation behaviors.</li>
    </ul>

<br />

    <h2>Conclusion</h2>
    <p>
      Container-managed transactions provide a robust and convenient mechanism for managing transactions in EJB-based applications. By relying on the EJB container to handle transaction boundaries, developers can focus on implementing business logic while ensuring that transactional integrity is maintained.
    </p>
  </div>
)}





{selectedChapter === 'chapter43' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Message-Driven Beans (MDB)   </h1>

    <p>
      Message-driven Beans (MDB) are a type of Enterprise JavaBean (EJB) used for asynchronous message processing. MDBs allow Java EE applications to receive and process messages from message-oriented middleware (MOM) systems, such as JMS (Java Message Service). They provide a simple, effective way to integrate Java EE applications with messaging systems.
    </p>
 <br />

    <h2>What are Message-driven Beans (MDB)?</h2>
    <p style={{paddingBottom:"6px"}}>
      A Message-driven Bean (MDB) is a type of EJB that listens for and processes messages from a message queue or topic, usually via the JMS API. MDBs do not have a client or interface like other session beans; instead, they are invoked by the container when a message is placed on the queue or topic they are listening to. They allow for decoupled, asynchronous communication between different components of an enterprise system.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of MDBs:</h3>
    <ul>
      <li>Asynchronous message processing: MDBs can process messages asynchronously.</li><br />
      <li>No direct client interaction: They are not called directly by a client but instead by the container when a message arrives.</li><br />
      <li>Message-driven: Typically, MDBs are used for listening to and processing JMS messages.</li><br />
      <li>Transaction support: MDBs support container-managed transactions to ensure message processing is transactional.</li>
    </ul>

   <br />

    <h2>How Do MDBs Work?</h2>
    <p style={{paddingBottom:"6px"}}>
      MDBs listen for messages on JMS destinations (queues or topics) and automatically process them when received. The container handles the lifecycle of the MDB, including message delivery, transaction management, and concurrency handling.
    </p>

    <h3 style={{paddingBottom:"6px"}}>MDB Lifecycle:</h3>
    <ul>
      <li><strong>Activation:</strong> The container listens for messages on the specified JMS destination (queue or topic).</li><br />
      <li><strong>Message Reception:</strong> When a message arrives, the container invokes the onMessage method of the MDB.</li><br />
      <li><strong>Message Processing:</strong> The MDB processes the message (business logic) as defined in the onMessage method.</li><br />
      <li><strong>Deactivation:</strong> The container stops listening for messages when the MDB is no longer needed or when the application is stopped.</li>
    </ul>

 <br />

    <h2>Creating a Message-driven Bean (MDB)</h2>
    <p style={{paddingBottom:"6px"}}>
      To create an MDB, you need to annotate the bean with <code>@MessageDriven</code>, specify the destination to listen to (e.g., a JMS queue or topic), and implement the <code>onMessage</code> method to process the incoming messages.
    </p>

    <h3>Example of an MDB:</h3>
    <pre>
      <code>
{`import javax.ejb.MessageDriven;
import javax.jms.Message;
import javax.jms.MessageListener;

@MessageDriven
public class OrderProcessingMDB implements MessageListener {

    @Override
    public void onMessage(Message message) {
        try {
            // Business logic to process the message
            String orderDetails = ((TextMessage) message).getText();
            processOrder(orderDetails);
        } catch (JMSException e) {
            e.printStackTrace();
        }
    }

    private void processOrder(String orderDetails) {
        // Implement the logic to process the order
        System.out.println("Processing order: " + orderDetails);
    }
}`}
      </code>
    </pre>
<br />

    <h2>MDB Message Processing</h2>
    <p style={{paddingBottom:"6px"}}>
      The <code>onMessage</code> method in an MDB is triggered when a message arrives at the destination the MDB is listening to. This method receives a message object as an argument, which can be cast to specific message types such as <code>TextMessage</code>, <code>ObjectMessage</code>, etc., depending on the type of message.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Common Message Types in MDB:</h3>
    <ul>
      <li><strong>TextMessage:</strong> A message containing a string of text.</li><br />
      <li><strong>ObjectMessage:</strong> A message containing a serialized Java object.</li><br />
      <li><strong>MapMessage:</strong> A message containing a map of name-value pairs.</li><br />
      <li><strong>BytesMessage:</strong> A message containing an array of bytes.</li>
    </ul>

   <br />

    <h2>MDB Configuration</h2>
    <p style={{paddingBottom:"6px"}}>
      MDBs can be configured to listen to different JMS destinations. This is typically done using annotations or deployment descriptors. You can specify the queue or topic to which the MDB is subscribed, as well as configure transaction settings.
    </p>

    <h3>Using Annotations for MDB Configuration:</h3>
    <pre>
      <code>
{`@MessageDriven(mappedName = "jms/orderQueue")
public class OrderProcessingMDB implements MessageListener {
    // Implementation...
}`}
      </code>
    </pre>
    <p>
      In the example above, the <code>mappedName</code> attribute specifies the JMS destination the MDB listens to. The container ensures that the MDB receives messages from the designated queue or topic.
    </p>

    <br />

    <h2>Transaction Management in MDBs</h2>
    <p>
      MDBs can support container-managed transactions. If the <code>onMessage</code> method executes successfully, the transaction is committed automatically; if an exception occurs, the transaction is rolled back, and the message is redelivered if necessary. Transaction attributes such as <code>Required</code> or <code>RequiresNew</code> can be set using annotations like <code>@TransactionAttribute</code>.
    </p>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Advantages of Message-driven Beans (MDB)</h2>
    <ul>
      <li>Asynchronous processing: MDBs allow decoupling of message producers and consumers, ensuring scalable and responsive systems.</li><br />
      <li>Integration with JMS: MDBs simplify integration with message-oriented middleware like JMS, making it easier to process messages in Java EE applications.</li><br />
      <li>Declarative transaction management: MDBs leverage container-managed transactions, ensuring consistency and reliability in message processing.</li><br />
      <li>Scalable and flexible: MDBs scale well for high-volume messaging and can process messages in parallel or serially, depending on the container configuration.</li>
    </ul>

    <br />

    <h2>Conclusion</h2>
    <p>
      Message-driven Beans (MDBs) provide an essential mechanism for handling asynchronous messaging in Java EE applications. By leveraging JMS and container-managed transactions, MDBs help developers build scalable, resilient systems for processing large volumes of messages with minimal code.
    </p>
  </div>
)}


 
 



{selectedChapter === 'chapter44' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Interceptors in EJB  </h1>

    <p>
      <strong>Interceptors</strong> in Enterprise JavaBeans (EJB) allow developers to inject behavior into session beans, message-driven beans (MDB), or other EJBs without modifying the business logic code. Interceptors provide a powerful mechanism for adding cross-cutting concerns like logging, transaction management, security checks, and auditing in a declarative and reusable way.
    </p>

  <br />

    <h2>What are Interceptors?</h2>
    <p style={{paddingBottom:"6px"}}>
      Interceptors are methods that allow additional logic to be applied to business methods in EJBs. These methods can be executed before or after the actual business method, enabling you to perform operations like logging, metrics gathering, or security checks without changing the core business logic. Interceptors are applied declaratively or programmatically and can be applied globally or specifically to certain beans or methods.
    </p>

    <h3>Types of Interceptors:</h3>
    <ul>
      <li><strong>Method Interceptors:</strong> These interceptors are invoked before or after the execution of business methods in EJBs.</li><br />
      <li><strong>Lifecycle Interceptors:</strong> These interceptors are invoked at various lifecycle stages of EJBs, such as during the creation or destruction of an EJB instance.</li>
    </ul>

   <br />

    <h2>Using Interceptors in EJB</h2>
    <p style={{paddingBottom:"6px"}}>
      Interceptors can be applied to EJBs in two main ways: declaratively via annotations or programmatically using the <code>Interceptor</code> interface. 
      EJB interceptors can be used with both session beans (stateless, stateful, singleton) and message-driven beans (MDBs).
    </p>

    <h3>Declarative Interceptors using Annotations:</h3>
    <p style={{paddingBottom:"6px"}}>
      To define an interceptor method in an EJB, use the <code>@AroundInvoke</code> annotation to mark a method as an interceptor. This method can be applied to any business method in the bean.
    </p>

    <h4>Example of a Method Interceptor:</h4>
    <pre>
      <code>
{`import javax.ejb.AroundInvoke;
import javax.ejb.InvocationContext;

public class LoggingInterceptor {

    @AroundInvoke
    public Object logMethodInvocation(InvocationContext context) throws Exception {
        // Before method execution - log the method name
        System.out.println("Invoking method: " + context.getMethod().getName());
        
        // Proceed to the next interceptor or business method
        Object result = context.proceed();
        
        // After method execution - log the result
        System.out.println("Method " + context.getMethod().getName() + " executed successfully");
        
        return result;
    }
}`}
      </code>
    </pre>
    <p>
      In the example above, the `logMethodInvocation` method is executed before and after any business method in the EJB. The <code>InvocationContext</code> provides access to the method invocation, allowing you to perform additional actions like logging, handling exceptions, or modifying method parameters.
    </p>

    <br />

    <h3>Applying Interceptors to EJBs:</h3>
    <p style={{paddingBottom:"6px"}}>
      Once the interceptor is defined, you can apply it to your EJB using the <code>@Interceptors</code> annotation, either at the class level or the method level.
    </p>

    <h4>Example of Applying an Interceptor to an EJB:</h4>
    <pre>
      <code>
{`import javax.ejb.Stateless;
import javax.interceptor.Interceptors;

@Stateless
@Interceptors(LoggingInterceptor.class)
public class AccountService {

    public void createAccount(String accountName) {
        // Business logic for creating an account
        System.out.println("Account created: " + accountName);
    }
}`}
      </code>
    </pre>
    <p>
      In this example, the `LoggingInterceptor` is applied to all methods in the `AccountService` bean. Every time the <code>createAccount</code> method is invoked, the `LoggingInterceptor` will execute before and after the business method.
    </p>

   <br />

    <h2>Lifecycle Interceptors</h2>
    <p style={{paddingBottom:"6px"}}>
      Lifecycle Interceptors allow you to intercept the lifecycle events of EJBs, such as when an EJB is created or destroyed. You can define methods that handle these lifecycle events and apply them using the <code>@PostConstruct</code> and <code>@PreDestroy</code> annotations.
    </p>

    <h3>Example of a Lifecycle Interceptor:</h3>
    <pre>
      <code>
{`import javax.annotation.PostConstruct;
import javax.annotation.PreDestroy;

public class AccountLifecycleInterceptor {

    @PostConstruct
    public void init() {
        System.out.println("Account bean initialized.");
    }

    @PreDestroy
    public void cleanup() {
        System.out.println("Account bean will be destroyed.");
    }
}`}
      </code>
    </pre>
    <p>
      In this example, the `init` method is executed after the EJB instance is created, and the `cleanup` method is executed just before the instance is destroyed.
    </p>

   <br />

    <h2>Programmatic Interceptors</h2>
    <p style={{paddingBottom:"6px"}}>
      In addition to declarative interceptors, you can also define interceptors programmatically. This approach gives you more flexibility in applying interceptors, such as applying them dynamically at runtime. To create a programmatic interceptor, you can use the <code>Interceptor</code> interface.
    </p>

    <h3>Example of Programmatic Interceptors:</h3>
    <pre>
      <code>
{`import javax.ejb.EJB;
import javax.ejb.Interceptor;
import javax.ejb.InvocationContext;

@Interceptor
public class CustomInterceptor {

    @AroundInvoke
    public Object intercept(InvocationContext context) throws Exception {
        System.out.println("Intercepting method: " + context.getMethod().getName());
        return context.proceed();
    }
}`}
      </code>
    </pre>
    <p>
      In this example, the `CustomInterceptor` implements the `Interceptor` interface and defines the `intercept` method to intercept method invocations.
    </p>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Advantages of Using Interceptors in EJB</h2>
    <ul>
      <li><strong>Separation of Concerns:</strong> Interceptors allow for adding cross-cutting concerns like logging, transaction management, and security without modifying the business logic of the EJB.</li><br />
      <li><strong>Reusability:</strong> Interceptors can be reused across multiple EJBs, promoting code modularity and consistency.</li><br />
      <li><strong>Declarative and Programmatic Support:</strong> Interceptors can be applied both declaratively using annotations or programmatically, providing flexibility.</li><br />
      <li><strong>Enhances Readability:</strong> By removing auxiliary logic from business methods, interceptors make the business code cleaner and easier to maintain.</li>
    </ul>

  <br />

    <h2>Conclusion</h2>
    <p>
      Interceptors in EJB provide a powerful mechanism for enhancing the functionality of Enterprise JavaBeans without modifying the core business logic. Whether for logging, security, or transaction management, interceptors provide a flexible, reusable, and modular approach to handling cross-cutting concerns. By using interceptors, developers can improve the maintainability and scalability of their EJB-based applications.
    </p>
  </div>
)}






{selectedChapter === 'chapter45' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>   Introduction to ORM and JPA </h1>
   
    <p>
      Object-Relational Mapping (ORM) is a programming technique used to convert data between incompatible systems (i.e., object-oriented programming languages and relational databases). ORM allows developers to interact with relational databases using object-oriented paradigms, eliminating the need for complex SQL queries and data manipulation. 
    </p>

  <br />

    <h2>What is ORM?</h2>
    <p>
      ORM is a technique that allows developers to interact with a relational database through an object-oriented model. In ORM, the database tables are represented as classes, and rows are represented as instances of those classes. This helps in bridging the gap between the object-oriented world (where data is represented as objects) and the relational world (where data is stored in tables).
    </p>
    
    <p>
      ORM simplifies database interactions by automatically handling the conversion of objects to database records and vice versa. It also abstracts the database-specific details and provides an easy-to-use API for interacting with databases.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Advantages of ORM:</h3>
    <ul>
      <li><strong>Reduced Boilerplate Code:</strong> ORM reduces the need to write repetitive SQL code for basic CRUD operations.</li><br />
      <li><strong>Database Independence:</strong> ORM frameworks allow you to switch databases without significant changes to the codebase.</li><br />
      <li><strong>Improved Productivity:</strong> Developers can focus on the business logic rather than database-specific implementations.</li><br />
      <li><strong>Automatic Data Conversion:</strong> ORM frameworks handle the mapping between Java objects and relational database tables automatically.</li>
    </ul>

    <br />

    <h2>What is JPA?</h2>
    <p>
      **Java Persistence API (JPA)** is a specification in the Java EE (Enterprise Edition) for managing relational data in Java applications. JPA provides a set of interfaces for interacting with databases using ORM techniques, allowing developers to work with Java objects while JPA handles the underlying SQL and database operations.
    </p>

    <p style={{paddingBottom:"6px"}}>
      JPA is a part of the Java EE specification, but it can also be used in Java SE applications. It offers a powerful framework for storing and retrieving data from a relational database using Java objects. JPA is typically implemented by frameworks like Hibernate, EclipseLink, and OpenJPA.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Concepts in JPA:</h3>
    <ul>
      <li><strong>Entities:</strong> A persistent Java class that is mapped to a database table. Each instance of the entity class represents a row in the table.</li><br />
      <li><strong>Entity Manager:</strong> The main interface for interacting with the persistence context, managing the lifecycle of entity instances (create, update, delete), and performing database queries.</li><br />
      <li><strong>Persistence Context:</strong> A set of entity instances that are managed by the Entity Manager within a given transaction. It ensures that all changes to entities are synchronized with the database.</li><br />
      <li><strong>JPQL (Java Persistence Query Language):</strong> A query language similar to SQL but designed for querying entities, rather than tables.</li>
    </ul>

   <br />

    <h2>JPA vs Hibernate</h2>
    <p>
      While **JPA** is a specification, **Hibernate** is a popular ORM framework that implements JPA. Hibernate provides additional features beyond the JPA specification, such as caching and advanced query optimization, but JPA provides a standardized approach that allows developers to switch between different JPA implementations like Hibernate, EclipseLink, and OpenJPA.
    </p>

    <p style={{paddingBottom:"6px"}}>
      In essence, JPA defines how persistence works in Java, while Hibernate provides the actual implementation. You can use JPA with other implementations as well, but Hibernate is one of the most widely used implementations.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Benefits of JPA:</h3>
    <ul>
      <li><strong>Simplified Persistence:</strong> JPA provides a simple and unified approach for object-relational mapping and persistence in Java applications.</li><br />
      <li><strong>Declarative Configuration:</strong> JPA uses annotations or XML configuration to define how entities are mapped to database tables, simplifying the configuration process.</li><br />
      <li><strong>Improved Database Interaction:</strong> JPA handles the complex SQL interactions, allowing developers to focus on business logic instead of database specifics.</li><br />
      <li><strong>Support for Transactions:</strong> JPA supports transaction management and ensures that the database operations are consistent and reliable.</li>
    </ul>

<br />
    <h2>JPA Annotations</h2>
    <p>
      JPA uses annotations to define the mapping between Java objects and relational database tables. Here are some commonly used annotations:
    </p>
    <ul>
      <li><code>@Entity</code>: Marks a Java class as an entity to be persisted in the database.</li><br />
      <li><code>@Id</code>: Specifies the primary key field of the entity.</li><br />
      <li><code>@GeneratedValue</code>: Defines how the primary key value is generated (e.g., auto-increment).</li><br />
      <li><code>@Column</code>: Specifies a column mapping for a field in the entity.</li><br />
      <li><code>@ManyToOne</code>, <code>@OneToMany</code>, <code>@OneToOne</code>, <code>@ManyToMany</code>: Defines relationships between entities (one-to-one, one-to-many, many-to-many, etc.).</li>
    </ul><br />

    <h4>Example of a Simple Entity:</h4>
    <pre>
      <code>
{`import javax.persistence.Entity;
import javax.persistence.Id;
import javax.persistence.GeneratedValue;
import javax.persistence.GenerationType;

@Entity
public class Employee {

    @Id
    @GeneratedValue(strategy = GenerationType.AUTO)
    private Long id;
    private String name;
    private String department;

    // Getters and Setters
    public Long getId() { return id; }
    public void setId(Long id) { this.id = id; }
    public String getName() { return name; }
    public void setName(String name) { this.name = name; }
    public String getDepartment() { return department; }
    public void setDepartment(String department) { this.department = department; }
}`}
      </code>
    </pre>
    <p>
      In this example, the `Employee` class is marked as an entity, with its fields mapped to corresponding database columns. The <code>@Id</code> annotation specifies the primary key, and the <code>@GeneratedValue</code> annotation defines how the primary key should be generated.
    </p>

    <br />

    <h2>Conclusion</h2>
    <p>
      JPA provides a powerful and standardized way to manage relational data in Java applications through Object-Relational Mapping (ORM). By using JPA, developers can eliminate the need for complex SQL code and focus on their application's business logic. JPA provides a consistent way to interact with databases, abstracting away the underlying database-specific details and ensuring easier maintainability of Java applications.
    </p>
  </div>
)}




{selectedChapter === 'chapter46' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Entity Classes and Annotations  </h1>

    <p>
      In Java Persistence API (JPA), entity classes are Java classes that are mapped to database tables. Each instance of an entity class represents a row in the table, and the fields of the entity class represent columns in that table. JPA uses annotations to define how Java objects are mapped to relational database tables, ensuring that the persistence layer is effectively abstracted.
    </p>

   <br />

    <h2>What is an Entity Class?</h2>
    <p style={{paddingBottom:"6px"}}>
      An entity class is a Java class that represents a table in a relational database. It is typically annotated with the <code>@Entity</code> annotation, and its fields correspond to the columns in the database table. The entity class serves as a data model for the application's persistent objects, making it a central part of any JPA-based application.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Characteristics of an Entity Class:</h3>
    <ul>
      <li><strong>@Entity:</strong> The class must be annotated with <code>@Entity</code> to be recognized as an entity by JPA.</li><br />
      <li><strong>@Id:</strong> Every entity class must have a field annotated with <code>@Id</code> to serve as the primary key.</li><br />
      <li><strong>Persistence Context:</strong> The state of entity instances is managed by the persistence context during a transaction.</li><br />
      <li><strong>No-Arg Constructor:</strong> Entity classes should have a no-argument constructor for JPA to instantiate objects.</li>
    </ul>

   <br />

    <h2>Basic JPA Annotations</h2>
    <p style={{paddingBottom:"6px"}}>
      JPA uses several annotations to map Java objects to database tables and manage the persistence context. Below are the most commonly used annotations:
    </p>

    <h3>@Entity</h3>
    <p>
      The <code>@Entity</code> annotation is used to mark a class as an entity. JPA will treat this class as a persistent entity and map it to a corresponding database table.
    </p>
    <pre>
      <code>
{`import javax.persistence.Entity;
import javax.persistence.Id;

@Entity
public class Employee {
    @Id
    private Long id;
    private String name;
    private String department;
    
    // Getters and Setters
}`}
      </code>
    </pre><br />

    <h3>@Id</h3>
    <p>
      The <code>@Id</code> annotation marks the primary key of the entity. Every entity must have a primary key, and the field annotated with <code>@Id</code> corresponds to this primary key.
    </p>
    <pre>
      <code>
{`@Id
private Long id;`}
      </code>
    </pre><br />

    <h3>@GeneratedValue</h3>
    <p>
      The <code>@GeneratedValue</code> annotation is used to define how the primary key is generated. It can be set to <code>GenerationType.AUTO</code>, <code>GenerationType.IDENTITY</code>, or <code>GenerationType.SEQUENCE</code> depending on the database's primary key generation strategy.
    </p>
    <pre>
      <code>
{`@Id
@GeneratedValue(strategy = GenerationType.AUTO)
private Long id;`}
      </code>
    </pre><br />

    <h3>@Column</h3>
    <p>
      The <code>@Column</code> annotation is used to specify the mapping between the class field and the database column. If the field name differs from the column name, <code>@Column</code> can be used to explicitly specify the column name.
    </p>
    <pre>
      <code>
{`@Column(name = "employee_name")
private String name;`}
      </code>
    </pre><br />

    <h3>@ManyToOne, @OneToMany, @OneToOne, @ManyToMany</h3>
    <p style={{paddingBottom:"6px"}}>
      These annotations define relationships between entity classes. They allow you to model one-to-one, one-to-many, and many-to-many relationships, which are common in relational databases.
    </p>
    <h4>@ManyToOne</h4>
    <pre>
      <code>
{`@ManyToOne
@JoinColumn(name = "department_id")
private Department department;`}
      </code>
    </pre><br />
    <h4>@OneToMany</h4>
    <pre>
      <code>
{`@OneToMany(mappedBy = "employee")
private Set<Project> projects;`}
      </code>
    </pre><br />

    <h4>@OneToOne</h4>
    <pre>
      <code>
{`@OneToOne
private Address address;`}
      </code>
    </pre><br />

    <h4>@ManyToMany</h4>
    <pre>
      <code>
{`@ManyToMany
private Set<Skill> skills;`}
      </code>
    </pre>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Additional Annotations</h2>
    <h3>@Table</h3>
    <p>
      The <code>@Table</code> annotation allows you to specify the name of the database table that corresponds to the entity class. If not provided, JPA will default to using the entity class name as the table name.
    </p>
    <pre>
      <code>
{`@Entity
@Table(name = "employees")
public class Employee {
    @Id
    private Long id;
    private String name;
    private String department;
    
    // Getters and Setters
}`}
      </code>
    </pre><br />

    <h3>@Transient</h3>
    <p>
      The <code>@Transient</code> annotation is used to mark a field that should not be persisted in the database. JPA will ignore fields annotated with <code>@Transient</code> when performing database operations.
    </p>
    <pre>
      <code>
{`@Transient
private String temporaryData;`}
      </code>
    </pre><br />

    <h3>@Embeddable and @Embedded</h3>
    <p>
      The <code>@Embeddable</code> annotation is used to define a class whose instances can be embedded in an entity. The <code>@Embedded</code> annotation is used to indicate that an embedded object should be part of the parent entity.
    </p>
    <pre>
      <code>
{`@Embeddable
public class Address {
    private String street;
    private String city;
    
    // Getters and Setters
}

@Entity
public class Employee {
    @Id
    private Long id;
    
    @Embedded
    private Address address;
    
    // Getters and Setters
}`}
      </code>
    </pre>

   <br />

    <h2>Entity Lifecycle</h2>
    <p style={{paddingBottom:"6px"}}>
      Entity classes in JPA have a lifecycle that is managed by the **Entity Manager**. This lifecycle includes states such as new, managed, detached, and removed, which represent the various stages an entity can go through during its interaction with the persistence context.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Common Lifecycle States:</h3>
    <ul>
      <li><strong>New:</strong> The entity is instantiated but not yet persisted in the database.</li><br />
      <li><strong>Managed:</strong> The entity is managed by the persistence context and is synchronized with the database.</li><br />
      <li><strong>Detached:</strong> The entity is no longer managed by the persistence context and is no longer synchronized with the database.</li><br />
      <li><strong>Removed:</strong> The entity is marked for removal from the database.</li>
    </ul>

  <br />

    <h2>Conclusion</h2>
    <p>
      Entity classes form the core of any JPA-based application, allowing Java objects to be mapped to database tables. By using annotations such as <code>@Entity</code>, <code>@Id</code>, <code>@GeneratedValue</code>, and others, you can easily define and manage persistent objects. The use of annotations in JPA ensures a clean and maintainable persistence layer for Java applications.
    </p>
  </div>
)}





{selectedChapter === 'chapter47' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Managing Relationships (One-to-One, One-to-Many, Many-to-Many) </h1>

  

    <p>
      In Java Persistence API (JPA), managing relationships between entities is a core part of modeling real-world scenarios in a database. JPA provides a set of annotations to represent relationships between entities. These relationships are crucial for designing a well-structured database, and JPA provides support for three main types of relationships:
    </p>
    <ul>
      <li><strong>One-to-One</strong></li><br />
      <li><strong>One-to-Many</strong></li><br />
      <li><strong>Many-to-Many</strong></li>
    </ul>

   <br />

    <h2>One-to-One Relationship</h2>
    <p style={{paddingBottom:"6px"}}>
      A One-to-One relationship is used when one entity is associated with exactly one instance of another entity. For example, one employee can have one office, and one office is assigned to only one employee.
    </p>
    <h3>Example:</h3>
    <pre>
      <code>
{`@Entity
public class Employee {
    @Id
    private Long id;
    
    @OneToOne
    @JoinColumn(name = "office_id")
    private Office office;
    
    // Getters and Setters
}

@Entity
public class Office {
    @Id
    private Long id;
    private String location;
    
    // Getters and Setters
}`}
      </code>
    </pre>
    <p>
      In the example above, the <code>@OneToOne</code> annotation establishes the relationship, and the <code>@JoinColumn</code> annotation specifies the foreign key column in the database that links the two entities.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Points:</h3>
    <ul>
      <li><strong>@OneToOne</strong>: Defines a one-to-one relationship.</li><br />
      <li><strong>@JoinColumn</strong>: Specifies the column that joins the two entities in the database.</li>
    </ul>

    <br />

    <h2>One-to-Many Relationship</h2>
    <p style={{paddingBottom:"6px"}}>
      A One-to-Many relationship is used when one entity is associated with multiple instances of another entity. For example, one department can have multiple employees.
    </p>
    <h3>Example:</h3>
    <pre>
      <code>
{`@Entity
public class Department {
    @Id
    private Long id;
    
    @OneToMany(mappedBy = "department")
    private List<Employee> employees;
    
    // Getters and Setters
}

@Entity
public class Employee {
    @Id
    private Long id;
    
    @ManyToOne
    @JoinColumn(name = "department_id")
    private Department department;
    
    // Getters and Setters
}`}
      </code>
    </pre>
    <p>
      In the example above, the <code>@OneToMany</code> annotation in the <code>Department</code> class defines the relationship, while the <code>@ManyToOne</code> annotation in the <code>Employee</code> class establishes the reverse relationship.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Points:</h3>
    <ul>
      <li><strong>@OneToMany</strong>: Defines a one-to-many relationship.</li><br />
      <li><strong>mappedBy</strong>: Specifies the field in the target entity that owns the relationship.</li><br />
      <li><strong>@ManyToOne</strong>: Defines the reverse side of a one-to-many relationship.</li>
    </ul>
<br />

    <h2>Many-to-Many Relationship</h2>
    <p style={{paddingBottom:"6px"}}>
      A Many-to-Many relationship is used when multiple instances of one entity are associated with multiple instances of another entity. For example, one student can enroll in multiple courses, and each course can have multiple students.
    </p>
    <h3>Example:</h3>
    <pre>
      <code>
{`@Entity
public class Student {
    @Id
    private Long id;
    
    @ManyToMany
    @JoinTable(
        name = "student_courses",
        joinColumns = @JoinColumn(name = "student_id"),
        inverseJoinColumns = @JoinColumn(name = "course_id")
    )
    private List<Course> courses;
    
    // Getters and Setters
}

@Entity
public class Course {
    @Id
    private Long id;
    
    @ManyToMany(mappedBy = "courses")
    private List<Student> students;
    
    // Getters and Setters
}`}
      </code>
    </pre>
    <p>
      In this example, the <code>@ManyToMany</code> annotation defines the many-to-many relationship. The <code>@JoinTable</code> annotation specifies the join table used to manage the relationship in the database, including the join columns for both entities. The <code>mappedBy</code> attribute in the <code>Course</code> entity indicates that the <code>Student</code> entity owns the relationship.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Points:</h3>
    <ul>
      <li><strong>@ManyToMany</strong>: Defines a many-to-many relationship.</li><br />
      <li><strong>@JoinTable</strong>: Specifies the join table used to link the entities.</li><br />
      <li><strong>@JoinColumn</strong>: Defines the columns that map the entities in the join table.</li><br />
      <li><strong>mappedBy</strong>: Specifies the field in the target entity that owns the relationship.</li>
    </ul>

  <br />

    <h2>Cascade Operations in Relationships</h2>
    <p style={{paddingBottom:"6px"}}>
      Cascade operations allow you to automatically propagate actions like persist, merge, remove, etc., from the parent entity to its related entities. For example, if a parent entity is removed, the cascade operation can automatically remove its associated child entities.
    </p>
    <h3>Example:</h3>
    <pre>
      <code>
{`@OneToMany(mappedBy = "department", cascade = CascadeType.ALL)
private List<Employee> employees;`}
      </code>
    </pre>
    <p>
      The <code>cascade = CascadeType.ALL</code> attribute ensures that all changes made to the parent entity (such as persist, remove, etc.) are propagated to the related child entities.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Common Cascade Types:</h3>
    <ul>
      <li><strong>CascadeType.PERSIST</strong>: Propagates the persist operation to related entities.</li><br />
      <li><strong>CascadeType.MERGE</strong>: Propagates the merge operation to related entities.</li><br />
      <li><strong>CascadeType.REMOVE</strong>: Propagates the remove operation to related entities.</li><br />
      <li><strong>CascadeType.ALL</strong>: Applies all operations (persist, merge, remove, etc.) to related entities.</li>
    </ul>

   <br />

    <h2>Conclusion</h2>
    <p>
      JPA provides a rich set of annotations for managing relationships between entities. By using annotations like <code>@OneToOne</code>, <code>@OneToMany</code>, <code>@ManyToMany</code>, and supporting features like cascade operations and join tables, developers can easily model complex relationships between entities. These annotations help create an efficient and maintainable persistence layer in Java-based applications.
    </p>
  </div>
)}




   
{selectedChapter === 'chapter48' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> JPQL (Java Persistence Query Language) </h1>

    <p>
      JPQL (Java Persistence Query Language) is a query language used to perform database operations on Java objects. Unlike SQL, which works with database tables directly, JPQL is designed to operate on the entity objects defined in Java code. It is similar to SQL but works with entity classes and their attributes rather than database tables and columns.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of JPQL</h2>
    <ul>
      <li><strong>Object-Oriented</strong>: JPQL queries are based on entity objects and their relationships.</li><br />
      <li><strong>Database Independence</strong>: JPQL abstracts the underlying database details, providing a more portable approach to querying.</li><br />
      <li><strong>Dynamic Query Creation</strong>: JPQL allows creating queries dynamically, making it flexible for different use cases.</li><br />
      <li><strong>Use of Named Queries</strong>: Named queries help in reusing query definitions across the application.</li>
    </ul>

   <br />

    <h2>Basic Structure of a JPQL Query</h2>
    <p>
      The basic structure of a JPQL query resembles SQL but operates on entity names and their properties, not the actual table names and columns. The general syntax of a JPQL query looks like:
    </p>
    <pre>
      <code>
{`SELECT e FROM Entity e WHERE e.property = :value`}
      </code>
    </pre>
    <p>
      In this syntax:
      <ul>
        <li><strong>SELECT</strong>: Specifies the fields to be retrieved, usually an entity or its properties.</li><br />
        <li><strong>FROM</strong>: Specifies the entity class to query.</li><br />
        <li><strong>WHERE</strong>: Defines the condition to filter the results.</li><br />
        <li><strong>:value</strong>: A named parameter that can be replaced dynamically when the query is executed.</li>
      </ul>
    </p>

  <br />

    <h2>Example: Simple JPQL Query</h2>
    <p>
      Consider an example where we have an entity <code>Employee</code> and we want to retrieve a list of employees with a specific job title:
    </p>
    <pre>
      <code>
{`String jpql = "SELECT e FROM Employee e WHERE e.jobTitle = :title";`}
      </code>
    </pre>
    <p>
      In this example:
      <ul>
        <li><strong>SELECT e</strong>: Retrieves the <code>Employee</code> entity.</li><br />
        <li><strong>FROM Employee e</strong>: Specifies that the query works on the <code>Employee</code> entity.</li><br />
        <li><strong>WHERE e.jobTitle = :title</strong>: Filters employees based on the <code>jobTitle</code> property.</li>
      </ul>
    </p>
    <p>
      To execute this query, you would use an <code>EntityManager</code> to create a <code>Query</code> object, set the parameters, and retrieve the results:
    </p>
    <pre>
      <code>
{`Query query = entityManager.createQuery(jpql);
query.setParameter("title", "Developer");
List<Employee> employees = query.getResultList();`}
      </code>
    </pre>

  <br />

    <h2>JPQL Query Types</h2>
    <p style={{paddingBottom:"6px"}}>
      JPQL supports several types of queries, including:
    </p>

    <h3>1. SELECT Queries</h3>
    <p>
      A SELECT query retrieves data from the database and returns a list of entity instances or scalar values.
    </p>
    <pre>
      <code>
{`SELECT e FROM Employee e WHERE e.salary > :salary`}
      </code>
    </pre><br />

    <h3>2. UPDATE Queries</h3>
    <p>
      An UPDATE query is used to modify data in the database. For example, you can update an employee's salary:
    </p>
    <pre>
      <code>
{`UPDATE Employee e SET e.salary = :newSalary WHERE e.id = :id`}
      </code>
    </pre><br />

    <h3>3. DELETE Queries</h3>
    <p>
      A DELETE query removes entities from the database. For example, to delete an employee based on their ID:
    </p>
    <pre>
      <code>
{`DELETE FROM Employee e WHERE e.id = :id`}
      </code>
    </pre>
<br />

    <h2>Named Queries</h2>
    <p style={{paddingBottom:"6px"}}>
      Named queries are predefined queries that are defined in the entity classes using annotations or in an XML configuration file. Named queries improve performance by avoiding the need to parse and compile a query each time it's executed.
    </p>
    <h3>Example: Defining a Named Query</h3>
    <pre>
      <code>
{`@Entity
@NamedQuery(name = "Employee.findByJobTitle", query = "SELECT e FROM Employee e WHERE e.jobTitle = :title")
public class Employee {`}
      </code>
    </pre>
    <p>
      In this example, we define a named query <code>Employee.findByJobTitle</code> that can be used to retrieve employees based on their job title. You can then use the named query in your code like this:
    </p>
    <pre>
      <code>
{`Query query = entityManager.createNamedQuery("Employee.findByJobTitle");
query.setParameter("title", "Developer");
List<Employee> employees = query.getResultList();`}
      </code>
    </pre>

    <br />

    <h2>JPQL and JOINs</h2>
    <p style={{paddingBottom:"6px"}}>
      JPQL supports JOIN operations, allowing you to fetch related entities based on their relationships. You can use INNER JOIN, LEFT JOIN, etc., to join entities in a query.
    </p>

    <h3>Example: JOIN Query</h3>
    <pre>
      <code>
{`SELECT e FROM Employee e JOIN e.department d WHERE d.name = :departmentName`}
      </code>
    </pre>
    <p>
      In this example, the query fetches employees from a specific department by joining the <code>Employee</code> and <code>Department</code> entities.
    </p>

   <br />

    <h2>Conclusion</h2>
    <p>
      JPQL is a powerful query language that abstracts database interactions and allows developers to work with entities rather than tables. It provides a simple, object-oriented approach to querying the database and supports all the basic operations (SELECT, UPDATE, DELETE) along with advanced features like named queries and JOINs. By using JPQL, developers can write more maintainable and portable queries in Java-based applications.
    </p>
  </div>
)}

  



{selectedChapter === 'chapter49' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Criteria API  </h1>

    <p>
      The Criteria API in JPA (Java Persistence API) provides a programmatic, type-safe way to create database queries. It is an alternative to JPQL (Java Persistence Query Language) and enables developers to construct queries using Java code rather than writing strings. The Criteria API is particularly useful for dynamic queries, where the structure of the query is determined at runtime, and for avoiding issues related to query syntax errors at compile time.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Features of Criteria API</h2>
    <ul>
      <li><strong>Type-Safe Queries:</strong> It uses Java types to define queries, reducing the risk of errors.</li><br />
      <li><strong>Dynamic Query Creation:</strong> Criteria API is ideal for constructing queries based on runtime conditions.</li><br />
      <li><strong>Flexibility:</strong> It allows for complex queries involving multiple criteria, joins, and sorting.</li><br />
      <li><strong>Compile-Time Safety:</strong> Since it uses Java types, you get compile-time checking of your queries.</li>
    </ul>

  <br />

    <h2>Basic Components of Criteria API</h2>
    <p>
      The Criteria API involves several key components:
      <ul>
        <li><strong>CriteriaBuilder:</strong> Provides methods to create criteria queries, predicates, and expressions.</li><br />
        <li><strong>CriteriaQuery:</strong> Represents the actual query you want to execute, including the root and conditions.</li><br />
        <li><strong>Root:</strong> Represents the entity that is the subject of the query.</li><br />
        <li><strong>Predicate:</strong> Represents conditions in the query, similar to the WHERE clause in SQL.</li>
      </ul>
    </p>

  <br />

    <h2>Creating a Simple Query using Criteria API</h2>
    <p>
      Let's start by creating a simple query using the Criteria API. Consider the <code>Employee</code> entity, and we want to retrieve employees whose salary is greater than a certain value.
    </p>
    <pre>
      <code>
{`CriteriaBuilder cb = entityManager.getCriteriaBuilder();
CriteriaQuery<Employee> cq = cb.createQuery(Employee.class);
Root<Employee> root = cq.from(Employee.class);
cq.select(root).where(cb.greaterThan(root.get("salary"), 50000));

TypedQuery<Employee> query = entityManager.createQuery(cq);
List<Employee> result = query.getResultList();`}
      </code>
    </pre>
    <p>
      In this example:
      <ul>
        <li><strong>CriteriaBuilder:</strong> Obtained from the <code>EntityManager</code>, it is used to create the query components.</li><br />
        <li><strong>CriteriaQuery:</strong> The query itself, defining the entity type (<code>Employee</code>) and the structure of the query.</li><br />
        <li><strong>Root:</strong> Represents the <code>Employee</code> entity, the subject of the query.</li><br />
        <li><strong>Predicate:</strong> Defines the condition for filtering employees based on the salary.</li>
      </ul>
    </p>
<br />

    <h2>Complex Queries using Criteria API</h2>
    <p>
      The Criteria API also supports more complex queries, including joins, grouping, and ordering. For example, to retrieve employees with a certain job title and order them by salary, we can extend our query:
    </p>
    <pre>
      <code>
{`CriteriaQuery<Employee> cq = cb.createQuery(Employee.class);
Root<Employee> root = cq.from(Employee.class);
Join<Employee, Department> departmentJoin = root.join("department");
cq.select(root)
  .where(cb.equal(departmentJoin.get("name"), "Engineering"))
  .orderBy(cb.asc(root.get("salary")));

TypedQuery<Employee> query = entityManager.createQuery(cq);
List<Employee> result = query.getResultList();`}
      </code>
    </pre>
    <p>
      In this query:
      <ul>
        <li><strong>Join:</strong> We join the <code>Employee</code> entity with the <code>Department</code> entity using the <code>join()</code> method.</li><br />
        <li><strong>OrderBy:</strong> We order the results by salary in ascending order using <code>cb.asc()</code>.</li><br />
        <li><strong>Where:</strong> We filter the employees to only include those working in the "Engineering" department.</li>
      </ul>
    </p>

    <br />

    <h2>Criteria API and Aggregation</h2>
    <p>
      The Criteria API can also be used for aggregation queries, such as counting the number of employees, calculating averages, etc. For example, to count the number of employees in the "Engineering" department:
    </p>
    <pre>
      <code>
{`CriteriaQuery<Long> cq = cb.createQuery(Long.class);
Root<Employee> root = cq.from(Employee.class);
Join<Employee, Department> departmentJoin = root.join("department");
cq.select(cb.count(root))
  .where(cb.equal(departmentJoin.get("name"), "Engineering"));

TypedQuery<Long> query = entityManager.createQuery(cq);
Long employeeCount = query.getSingleResult();`}
      </code>
    </pre>
    <p>
      In this query:
      <ul>
        <li><strong>Aggregation:</strong> We use <code>cb.count()</code> to count the number of employees in the "Engineering" department.</li><br />
        <li><strong>TypedQuery:</strong> The query returns a single result, which is the count of employees.</li>
      </ul>
    </p>

  <br />

    <h2>Dynamic Queries with Criteria API</h2>
    <p>
      One of the strongest use cases of the Criteria API is creating dynamic queries. Let's consider a case where the query conditions are based on user input. We can construct a dynamic query by checking conditions at runtime.
    </p>
    <pre>
      <code>
{`CriteriaBuilder cb = entityManager.getCriteriaBuilder();
CriteriaQuery<Employee> cq = cb.createQuery(Employee.class);
Root<Employee> root = cq.from(Employee.class);

List<Predicate> predicates = new ArrayList<>();

if (salary != null) {
    predicates.add(cb.greaterThan(root.get("salary"), salary));
}
if (jobTitle != null) {
    predicates.add(cb.equal(root.get("jobTitle"), jobTitle));
}

cq.select(root).where(cb.and(predicates.toArray(new Predicate[0])));

TypedQuery<Employee> query = entityManager.createQuery(cq);
List<Employee> result = query.getResultList();`}
      </code>
    </pre>
    <p>
      In this example, we build the query dynamically based on the <code>salary</code> and <code>jobTitle</code> parameters. If a condition is not provided, it is simply omitted from the query.
    </p>

   <br />

    <h2>Conclusion</h2>
    <p>
      The Criteria API provides a type-safe, flexible, and dynamic way to build database queries in Java. It is particularly useful for building complex queries and handling dynamic conditions, which makes it a powerful tool for Java developers working with JPA. While JPQL is simpler for static queries, the Criteria API is ideal for more sophisticated query requirements, such as dynamic filtering, complex joins, and aggregation.
    </p>
  </div>
)}






{selectedChapter === 'chapter50' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Fetch Strategies (Lazy vs. Eager) </h1>

    <p>
      In JPA, when dealing with relationships between entities (such as one-to-many or many-to-one), you have two main fetch strategies for retrieving related entities: <strong>Lazy Loading</strong> and <strong>Eager Loading</strong>. These strategies determine how related entities are fetched when the parent entity is loaded from the database.
    </p><br />

    <h2>Lazy Loading</h2>
    <p>
      Lazy loading is the default fetching strategy for most relationships in JPA. When an entity is loaded, its related entities are not immediately fetched. Instead, they are loaded on-demand, only when they are accessed for the first time.
    </p>
    <p>
      Lazy loading can be beneficial for performance, especially when working with large data sets, because it avoids loading unnecessary data. However, it can lead to problems like <em>LazyInitializationException</em> if the related entities are accessed outside the scope of a transactional context.
    </p>
    <pre>
      <code>
{`@OneToMany(fetch = FetchType.LAZY)
private List<Order> orders;`}
      </code>
    </pre>
    <p>
      In the above example, the <code>orders</code> collection will be loaded lazily, meaning it will only be fetched when accessed (e.g., by calling <code>customer.getOrders()</code>).
    </p><br />

    <h2>Eager Loading</h2>
    <p>
      Eager loading, on the other hand, loads the related entities immediately when the parent entity is loaded. This means that when you query for an entity, its related entities are also loaded as part of the same query.
    </p>
    <p>
      While eager loading can be useful when you always need the related entities, it can have a negative impact on performance, especially when dealing with large data sets or deeply nested relationships. Eager fetching can result in loading more data than necessary, which can lead to slower performance and increased memory usage.
    </p>
    <pre>
      <code>
{`@OneToMany(fetch = FetchType.EAGER)
private List<Order> orders;`}
      </code>
    </pre>
    <p>
      In the above example, the <code>orders</code> collection will be loaded eagerly, meaning it will be fetched as part of the same query when the parent entity is retrieved.
    </p>

  <br />

    <h2>Choosing Between Lazy and Eager Loading</h2>
    <p>
      Deciding between lazy and eager loading depends on the specific use case and performance considerations:
      <ul>
        <li><strong>Lazy Loading:</strong> Use lazy loading when you only need related entities in certain situations. This will help reduce the number of database queries and avoid loading unnecessary data.</li><br />
        <li><strong>Eager Loading:</strong> Use eager loading when you know you will need the related entities immediately, and you want to minimize additional queries or reduce the risk of lazy loading exceptions.</li>
      </ul>
    </p>
    <p>
      However, eager loading can result in the "N+1 query problem" in certain scenarios, where multiple queries are issued to fetch related data, leading to inefficient performance. To avoid this, consider using <strong>fetch joins</strong> in JPQL or Criteria API to eagerly load related entities in a single query.
    </p>

  <br />

    <h2>Example: N+1 Query Problem</h2>
    <p>
      One of the common pitfalls when using lazy loading is the "N+1 query problem." This happens when you load a list of entities, and for each entity, an additional query is issued to fetch its related entities. This can result in a significant performance hit, especially when there are many entities in the list.
    </p>
    <p>
      For example, if you load 100 customers, and each customer has a list of orders, lazy loading would issue 101 queries (1 query for the customers, and 1 additional query for each customer's orders).
    </p>
    <pre>
      <code>
{`List<Customer> customers = entityManager.createQuery("SELECT c FROM Customer c", Customer.class).getResultList();
for (Customer customer : customers) {
    System.out.println(customer.getOrders()); // Lazy load for each customer
}`}
      </code>
    </pre>
    <p>
      To solve the N+1 query problem, you can use eager fetching or a <strong>fetch join</strong> in JPQL.
    </p>

   <br />

    <h2>Using Fetch Joins to Avoid N+1 Query Problem</h2>
    <p>
      To avoid the N+1 query problem, you can use a fetch join in JPQL. A fetch join allows you to load the parent and related entities in a single query.
    </p>
    <pre>
      <code>
{`List<Customer> customers = entityManager.createQuery("SELECT c FROM Customer c JOIN FETCH c.orders", Customer.class).getResultList();`}
      </code>
    </pre>
    <p>
      In this example, both the <code>Customer</code> and <code>Order</code> entities are loaded in a single query, avoiding multiple queries and improving performance.
    </p>

  <br />

    <h2>Conclusion</h2>
    <p>
      In JPA, the choice between lazy and eager loading should be made carefully based on performance requirements and use cases. Lazy loading is suitable for scenarios where related entities are not always needed, while eager loading should be used when related entities are required immediately. Always be cautious of performance pitfalls such as the N+1 query problem, and use techniques like fetch joins to optimize your queries when necessary.
    </p>
  </div>
)}



{selectedChapter === 'chapter51' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Transaction Management with JPA  </h1>

    <p>
      In Java Persistence API (JPA), transaction management plays a crucial role in ensuring data consistency and integrity during database operations. JPA integrates seamlessly with the Java Transaction API (JTA) to handle transactions effectively, ensuring that a series of database operations can be executed atomically (all or nothing).
    </p><br />

    <h2>What is a Transaction?</h2>
    <p>
      A <strong>transaction</strong> is a sequence of operations performed as a single logical unit of work. The transaction must exhibit the ACID properties:
      <ul>
        <li><strong>Atomicity</strong>: The transaction is atomic, meaning all operations are executed completely or none at all.</li><br />
        <li><strong>Consistency</strong>: The database must be in a consistent state before and after the transaction.</li><br />
        <li><strong>Isolation</strong>: Transactions are isolated from each other, meaning the intermediate state of a transaction is not visible to others.</li><br />
        <li><strong>Durability</strong>: Once committed, the changes made by the transaction are permanent, even in the event of a failure.</li>
      </ul>
    </p><br />

    <h2>Types of Transaction Management in JPA</h2>
    <p>
      JPA supports two types of transaction management:
      <ol>
        <li><strong>Container-Managed Transactions (CMT)</strong>: The container (application server) manages the transaction lifecycle. This is the preferred option in Java EE environments, as it automates most of the transaction management tasks.</li><br />
        <li><strong>Bean-Managed Transactions (BMT)</strong>: The developer manually manages the transaction lifecycle, including starting, committing, or rolling back the transaction. This is more flexible but requires more code and is often used in Java SE environments.</li>
      </ol>
    </p><br />

    <h2>Container-Managed Transactions (CMT)</h2>
    <p>
      In container-managed transactions, the transaction management is handled by the application server or container. The developer does not need to write explicit transaction handling code. The container starts, commits, and rolls back transactions automatically.
    </p>
    <p>
      To use CMT, simply annotate the EJB method with <code>@TransactionManagement</code> and <code>@TransactionAttribute</code>:
    </p>
    <pre>
      <code>
{`@Stateless
@TransactionManagement(TransactionManagementType.CONTAINER)
public class OrderService {

    @PersistenceContext
    private EntityManager em;

    @TransactionAttribute(TransactionAttributeType.REQUIRED)
    public void processOrder(Order order) {
        em.persist(order);
        // Other business logic
    }
}`}
      </code>
    </pre>
    <p>
      In this example, the <code>@TransactionManagement</code> annotation indicates that the container manages transactions, and the <code>@TransactionAttribute</code> specifies the behavior of the transaction (e.g., <code>REQUIRED</code> indicates that a transaction is required for the method).
    </p><br />

    <h2>Transaction Attributes in CMT</h2>
    <p>
      JPA supports several transaction attributes that define the transaction behavior for methods:
      <ul>
        <li><strong>REQUIRED</strong>: The method must run within a transaction. If no transaction exists, a new one is created.</li><br />
        <li><strong>REQUIRES_NEW</strong>: A new transaction is always created, suspending any existing transaction.</li><br />
        <li><strong>SUPPORTS</strong>: The method can execute with or without an existing transaction.</li><br />
        <li><strong>MANDATORY</strong>: A transaction must already exist; otherwise, an exception is thrown.</li><br />
        <li><strong>NEVER</strong>: The method should not run within a transaction; an exception is thrown if a transaction exists.</li><br />
        <li><strong>NOT_SUPPORTED</strong>: The method cannot execute within a transaction; if a transaction exists, it is suspended.</li>
      </ul>
    </p><br />

    <h2>Bean-Managed Transactions (BMT)</h2>
    <p>
      In bean-managed transactions, the developer explicitly controls the transaction lifecycle using the <code>EntityManager</code> API and the <code>begin()</code>, <code>commit()</code>, and <code>rollback()</code> methods.
    </p>
    <p>
      To use BMT, you need to manage the transactions manually as shown below:
    </p>
    <pre>
      <code>
{`@Stateless
public class OrderService {

    @PersistenceContext
    private EntityManager em;

    public void processOrder(Order order) {
        EntityTransaction transaction = em.getTransaction();
        try {
            transaction.begin();
            em.persist(order);
            // Other business logic
            transaction.commit();
        } catch (RuntimeException e) {
            if (transaction.isActive()) {
                transaction.rollback();
            }
            throw e;
        }
    }
}`}
      </code>
    </pre>
    <p>
      In this example, the <code>EntityTransaction</code> is explicitly controlled. The transaction is started with <code>transaction.begin()</code>, committed with <code>transaction.commit()</code>, and rolled back with <code>transaction.rollback()</code> in case of an exception.
    </p><br />

    <h2>Transaction Propagation in JPA</h2>
    <p>
      When a transaction is propagated across multiple methods, the behavior depends on the transaction attributes used. For example, if a method marked with <code>@TransactionAttribute(TransactionAttributeType.REQUIRED)</code> calls another method also marked with <code>@TransactionAttribute(TransactionAttributeType.REQUIRED)</code>, the second method will run within the same transaction.
    </p>
    <p>
      However, if the second method is marked with <code>REQUIRES_NEW</code>, a new transaction is created for that method, and the original transaction is suspended until the new transaction completes.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Transaction Management</h2>
    <ul>
      <li><strong>Keep Transactions Short</strong>: Ensure that transactions are kept as short as possible to avoid long-running locks on the database.</li><br />
      <li><strong>Handle Exceptions Properly</strong>: Always ensure that transactions are rolled back in case of exceptions to avoid data inconsistencies.</li><br />
      <li><strong>Avoid Nested Transactions</strong>: Avoid nesting transactions unnecessarily, as this can lead to complex and difficult-to-manage code.</li><br />
      <li><strong>Use Appropriate Isolation Levels</strong>: Choose the right isolation level for your transactions based on your requirements. For example, <em>READ_COMMITTED</em> is commonly used to avoid dirty reads, while <em>SERIALIZABLE</em> provides the highest level of isolation.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Effective transaction management is crucial for maintaining the integrity and consistency of data in enterprise applications. JPA offers flexible transaction management options, including container-managed and bean-managed transactions. The choice of which strategy to use depends on the environment (Java EE vs. Java SE) and the specific requirements of the application. By following best practices and understanding transaction propagation, you can ensure that your JPA-based application performs efficiently and reliably.
    </p>
  </div>
)}





{selectedChapter === 'chapter52' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Using Hibernate as the JPA Provider </h1>

    <p>
      Hibernate is one of the most popular Object-Relational Mapping (ORM) frameworks in the Java ecosystem. It provides a powerful, flexible, and feature-rich implementation of the Java Persistence API (JPA). By using Hibernate as the JPA provider, you can leverage its advanced ORM capabilities while adhering to JPA's standard annotations and interfaces.
    </p><br />

    <h2>What is a JPA Provider?</h2>
    <p>
      A <strong>JPA provider</strong> is an implementation of the Java Persistence API (JPA) specification. It provides the functionality to manage entities, handle database connections, and implement persistence logic. Hibernate is one of the most widely used JPA providers, alongside others like EclipseLink and OpenJPA.
    </p><br />

    <h2>Why Use Hibernate as the JPA Provider?</h2>
    <p>
      Hibernate offers several advantages when used as a JPA provider:
      <ul>
        <li><strong>Feature-Rich</strong>: Hibernate supports advanced features like caching, lazy loading, and automatic schema generation, which are not available in the standard JPA API.</li><br />
        <li><strong>Performance</strong>: Hibernate optimizes database interactions using techniques like connection pooling and batch processing, providing better performance for complex applications.</li><br />
        <li><strong>Wide Adoption</strong>: Hibernate has a large and active community, which ensures regular updates, bug fixes, and extensive documentation.</li><br />
        <li><strong>Compatibility</strong>: Hibernate can be easily integrated with other Java technologies like Spring, and supports multiple database vendors.</li>
      </ul>
    </p><br />

    <h2>Setting Up Hibernate as the JPA Provider</h2>
    <p style={{paddingBottom:"6px"}}>
      To use Hibernate as the JPA provider, you need to configure your project to include the necessary Hibernate dependencies and set up the persistence configuration.
    </p>

    <h3>1. Add Hibernate Dependencies</h3>
    <p>
      If you're using Maven, add the following dependencies to your <code>pom.xml</code>:
    </p>
    <pre>
      <code>
{`<dependencies>
    <dependency>
        <groupId>org.hibernate</groupId>
        <artifactId>hibernate-core</artifactId>
        <version>5.6.10.Final</version>  <!-- Make sure to use the latest version -->
    </dependency>
    <dependency>
        <groupId>javax.persistence</groupId>
        <artifactId>javax.persistence-api</artifactId>
        <version>2.2</version>  <!-- Use JPA version 2.2 or higher -->
    </dependency>
    <dependency>
        <groupId>org.hibernate</groupId>
        <artifactId>hibernate-entitymanager</artifactId>
        <version>5.6.10.Final</version>  <!-- Use the same version as hibernate-core -->
    </dependency>
</dependencies>`}
      </code>
    </pre>
    <p>
      This includes the necessary Hibernate Core, JPA API, and Hibernate EntityManager dependencies.
    </p><br />

    <h3>2. Create the <code>persistence.xml</code> Configuration File</h3>
    <p>
      The <code>persistence.xml</code> file contains the configuration for the persistence unit, which includes the JPA provider, database connection settings, and other options. The file should be placed in the <code>src/main/resources/META-INF</code> directory.
    </p>
    <pre>
      <code>
{`<?xml version="1.0" encoding="UTF-8"?>
<persistence xmlns="http://java.sun.com/xml/ns/persistence"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://java.sun.com/xml/ns/persistence
    http://java.sun.com/xml/ns/persistence/persistence_2_2.xsd"
    version="2.2">

    <persistence-unit name="myJpaUnit">
        <provider>org.hibernate.jpa.HibernatePersistenceProvider</provider> <!-- Use Hibernate as the JPA provider -->
        <jta-data-source>java:/MyDataSource</jta-data-source> <!-- Optional: For JTA (Java Transaction API) support -->
        <properties>
            <property name="hibernate.dialect" value="org.hibernate.dialect.H2Dialect" /> <!-- Example: H2 database -->
            <property name="hibernate.hbm2ddl.auto" value="update" /> <!-- Automatically updates database schema -->
            <property name="hibernate.show_sql" value="true" /> <!-- Log SQL statements -->
            <property name="hibernate.format_sql" value="true" /> <!-- Format SQL for better readability -->
        </properties>
    </persistence-unit>
</persistence>`}
      </code>
    </pre>
    <p>
      In this example, the <code>provider</code> element is set to <code>org.hibernate.jpa.HibernatePersistenceProvider</code>, which tells JPA to use Hibernate as the persistence provider. You can also configure database-specific properties such as the dialect, automatic schema update behavior, and SQL logging options.
    </p><br />

    <h3>3. Configure the EntityManager</h3>
    <p>
      To interact with the database using Hibernate, you'll need to create an <code>EntityManager</code> that provides access to the persistence context. You can inject the <code>EntityManager</code> in your EJB or CDI bean using the <code>@PersistenceContext</code> annotation.
    </p>
    <pre>
      <code>
{`@Stateless
public class UserService {

    @PersistenceContext(unitName = "myJpaUnit")
    private EntityManager entityManager;

    public void saveUser(User user) {
        entityManager.persist(user);
    }

    public User getUser(Long id) {
        return entityManager.find(User.class, id);
    }
}`}
      </code>
    </pre>
    <p>
      The <code>EntityManager</code> is injected into the class, and it is used to perform CRUD operations on entities. The <code>unitName</code> attribute in the <code>@PersistenceContext</code> annotation refers to the persistence unit defined in the <code>persistence.xml</code> file.
    </p><br />

    <h2>Using Hibernate-Specific Features</h2>
    <p>
      Hibernate provides several features that go beyond the standard JPA API. Some of these features include:
      <ul>
        <li><strong>Second-level caching</strong>: Hibernate supports caching of entities to improve performance by reducing database queries.</li><br />
        <li><strong>Lazy and eager loading</strong>: Hibernate allows you to specify fetching strategies (lazy or eager) for relationships between entities.</li><br />
        <li><strong>Custom SQL queries</strong>: Hibernate supports custom queries with its own HQL (Hibernate Query Language), which is similar to JPQL but provides more advanced querying capabilities.</li><br />
        <li><strong>Batch processing</strong>: Hibernate optimizes batch processing for bulk operations, reducing the number of database interactions.</li>
      </ul>
    </p><br />

    <h3>Example of a Custom Hibernate Query</h3>
    <p>
      Hibernate allows you to use its HQL to write custom queries. Here's an example of how to query entities using HQL:
    </p>
    <pre>
      <code>
{`String hql = "FROM User WHERE email = :email";
Query query = entityManager.createQuery(hql);
query.setParameter("email", "user@example.com");
User user = (User) query.getSingleResult();`}
      </code>
    </pre>
    <p>
      This HQL query fetches a user entity based on the email address. You can use the <code>entityManager.createQuery</code> method to create queries using HQL.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Using Hibernate as the JPA provider allows you to take advantage of the full power of Hibernate's ORM capabilities while still adhering to the JPA standard. With features like advanced caching, batch processing, and powerful querying options, Hibernate is an excellent choice for building scalable, performance-oriented Java applications.
    </p>
  </div>
)}




{selectedChapter === 'chapter53' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to JMS and its Architecture </h1>

    <p>
      The Java Message Service (JMS) is a Java API that allows applications to create, send, receive, and read messages. JMS provides a way for applications to communicate with each other asynchronously using message queues and topics. It is an essential component of enterprise-level Java applications that require message-driven architecture.
    </p><br />

    <h2>What is JMS?</h2>
    <p>
      JMS is a Java API for message-oriented middleware (MOM) that allows different components of a distributed application to communicate through messages. It enables reliable communication between distributed systems and supports both point-to-point (queues) and publish-subscribe (topics) messaging models.
    </p><br />

    <h2>JMS Architecture Overview</h2>
    <p>
      The architecture of JMS consists of several components that interact to send, receive, and process messages. These components include:
      <ul>
        <li><strong>JMS Client</strong>: The application that sends and receives messages using JMS API.</li><br />
        <li><strong>JMS Provider</strong>: The messaging system that implements the JMS specification. It is responsible for managing queues, topics, and delivering messages.</li><br />
        <li><strong>JMS Destination</strong>: A location (queue or topic) to which messages are sent or from which messages are received.</li><br />
        <li><strong>JMS Message</strong>: The message that is sent and received by the JMS client. It can be a text message, object message, or byte message, among others.</li><br />
        <li><strong>JMS Connection Factory</strong>: A factory used to create connections to the JMS provider.</li><br />
        <li><strong>JMS Session</strong>: A single-threaded context for sending and receiving messages.</li>
      </ul>
    </p><br />

    <h2>JMS Messaging Models</h2>
    <p>
      JMS supports two primary messaging models:
      <ul>
        <li><strong>Point-to-Point (Queues)</strong>: In this model, messages are sent from one producer to one consumer. Each message is consumed by only one consumer, ensuring a one-to-one message delivery model. A message is placed on a queue, and consumers receive the messages from the queue.</li><br />
        <li><strong>Publish-Subscribe (Topics)</strong>: In this model, messages are sent from a producer to multiple consumers. The message is broadcast to all subscribers of the topic, and each subscriber receives a copy of the message. This model is suitable for one-to-many communication.</li>
      </ul>
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Key Components in JMS Architecture</h2>

    <h3>1. JMS Client</h3>
    <p>
      A JMS client is an application or component that interacts with a messaging provider to send or receive messages. A JMS client can be a producer (sender) or a consumer (receiver) of messages. The client uses the JMS API to communicate with the JMS provider.
    </p><br />

    <h3>2. JMS Provider</h3>
    <p>
      The JMS provider is responsible for implementing the JMS specification and handling the management of messaging resources. It handles message delivery, routing, and persistence. Examples of JMS providers include IBM MQ, Apache ActiveMQ, and Oracle AQ.
    </p><br />

    <h3>3. JMS Destination</h3>
    <p>
      A JMS destination is where messages are sent or received. There are two types of destinations:
      <ul>
        <li><strong>Queue</strong>: A point-to-point destination where a message is consumed by one consumer only.</li><br />
        <li><strong>Topic</strong>: A publish-subscribe destination where multiple consumers can receive the same message.</li>
      </ul>
    </p><br />

    <h3>4. JMS Message</h3>
    <p>
      A JMS message is the data that is sent between clients. There are different types of messages in JMS:
      <ul>
        <li><strong>TextMessage</strong>: A message containing a string of text.</li><br />
        <li><strong>ObjectMessage</strong>: A message that contains a serializable Java object.</li><br />
        <li><strong>BytesMessage</strong>: A message that contains an array of bytes.</li><br />
        <li><strong>MapMessage</strong>: A message that contains a set of name-value pairs.</li>
      </ul>
    </p><br />

    <h3>5. JMS Connection Factory</h3>
    <p style={{paddingBottom:"6px"}}>
      The JMS connection factory is used to create connections to the JMS provider. It acts as a factory for creating both connections and sessions. A connection factory is used by JMS clients to establish communication with the JMS provider.
    </p>

    <h3>6. JMS Session</h3>
    <p>
      A JMS session is a context for producing and consuming messages. It is a single-threaded object that provides methods to create message producers, consumers, and message listeners. A session also manages message acknowledgment and transaction management.
    </p><br />

    <h2>JMS Flow</h2>
    <p>
      The typical flow of a JMS message involves several steps:
      <ol>
        <li>The JMS client creates a <strong>connection</strong> to the JMS provider using the connection factory.</li><br />
        <li>The client creates a <strong>session</strong> to interact with the messaging system.</li><br />
        <li>The client creates a <strong>destination</strong> (queue or topic) where messages will be sent or received.</li><br />
        <li>The client sends a <strong>message</strong> to the destination using a <strong>message producer</strong>.</li><br />
        <li>A <strong>message consumer</strong> receives the message from the destination and processes it.</li>
      </ol>
    </p><br />

    <h2>Conclusion</h2>
    <p>
      JMS provides a robust messaging platform that facilitates reliable and scalable communication between Java applications. It supports both point-to-point and publish-subscribe messaging models and offers a flexible architecture for building message-driven systems. By understanding JMS architecture and its key components, developers can design and implement messaging solutions that meet the needs of enterprise-level applications.
    </p>
  </div>
)}





{selectedChapter === 'chapter54' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Point-to-Point (Queue) Model</h1>

    <p>
      The Point-to-Point (Queue) messaging model is one of the two main messaging models supported by JMS. In this model, messages are sent from one producer to one consumer. The message is placed on a queue, and only one consumer can receive and process the message. This ensures that each message is delivered to a single consumer, making it suitable for scenarios that require direct, one-to-one communication between the message sender and receiver.
    </p><br />

    <h2>Overview of the Point-to-Point Model</h2>
    <p>
      In the Point-to-Point (Queue) model, a <strong>message producer</strong> sends messages to a <strong>queue</strong>, and the messages are consumed by a <strong>message consumer</strong>. A key characteristic of this model is that each message is processed by only one consumer. Once a message is consumed, it is removed from the queue, and no other consumer will be able to access it.
    </p><br />

    <h2>Components of the Point-to-Point Model</h2>
    <p>
      The Point-to-Point model involves several components that interact to send and receive messages:
      <ul>
        <li><strong>Message Producer</strong>: The application or component that sends messages to the queue.</li><br />
        <li><strong>Message Queue</strong>: The destination where messages are stored until they are consumed. A queue ensures that each message is processed only once by a consumer.</li><br />
        <li><strong>Message Consumer</strong>: The application or component that receives and processes messages from the queue. Each consumer processes messages one at a time.</li><br />
        <li><strong>JMS Connection Factory</strong>: A factory used to create connections to the JMS provider for communication with the queue.</li><br />
        <li><strong>JMS Session</strong>: A single-threaded context used by the producer and consumer to send and receive messages from the queue.</li>
      </ul>
    </p><br />

    <h2>How the Point-to-Point Model Works</h2>
    <p>
      The Point-to-Point model operates in a simple yet effective flow:
      <ol>
        <li>The <strong>message producer</strong> creates a message and sends it to a <strong>queue</strong>.</li><br />
        <li>The <strong>message consumer</strong> listens for and retrieves messages from the queue.</li><br />
        <li>Once the message is consumed, it is removed from the queue, and no other consumer can access it.</li><br />
        <li>If there are multiple consumers, the messages are delivered in a round-robin fashion, with each message consumed by only one consumer.</li>
      </ol>
    </p><br />

    <h2>Example of Point-to-Point Model in JMS</h2>
    <p>
      Here’s an example of how the Point-to-Point model works in a typical JMS setup:
    </p>
    
    <pre>
      <code>{`
        // Create JMS connection and session
        ConnectionFactory connectionFactory = new ActiveMQConnectionFactory("tcp://localhost:61616");
        Connection connection = connectionFactory.createConnection();
        connection.start();
        Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
        
        // Create a queue and a message producer
        Queue queue = session.createQueue("TestQueue");
        MessageProducer producer = session.createProducer(queue);
        
        // Create a message
        TextMessage message = session.createTextMessage("Hello, JMS!");
        
        // Send the message to the queue
        producer.send(message);
        
        // Create a message consumer
        MessageConsumer consumer = session.createConsumer(queue);
        
        // Receive the message from the queue
        Message receivedMessage = consumer.receive();
        System.out.println("Received: " + ((TextMessage) receivedMessage).getText());
        
        // Clean up
        session.close();
        connection.close();
       `} </code>
    </pre><br />

    <h2>Advantages of the Point-to-Point Model</h2>
    <p>
      The Point-to-Point model offers several benefits:
      <ul>
        <li><strong>Guaranteed Message Delivery</strong>: Each message sent to the queue is guaranteed to be consumed by exactly one consumer.</li><br />
        <li><strong>Simple Communication Model</strong>: The communication is direct between the producer and the consumer, making it easy to manage.</li><br />
        <li><strong>Message Acknowledgment</strong>: JMS provides different acknowledgment modes to ensure that messages are properly acknowledged by the consumer, preventing message loss.</li><br />
        <li><strong>Transactional Support</strong>: The Point-to-Point model supports transactions, allowing multiple message deliveries to be grouped together as a single unit of work.</li>
      </ul>
    </p><br />

    <h2>Disadvantages of the Point-to-Point Model</h2>
    <p>
      Despite its advantages, the Point-to-Point model also has a few limitations:
      <ul>
        <li><strong>Limited Scalability</strong>: Since each message is consumed by only one consumer, adding more consumers won’t increase the processing speed unless the workload is divided appropriately.</li><br />
        <li><strong>Queue Congestion</strong>: If there are no consumers available to consume the messages, the queue can get congested, leading to message delays.</li>
      </ul>
    </p><br />

    <h2>When to Use the Point-to-Point Model</h2>
    <p>
      The Point-to-Point model is ideal in scenarios where:
      <ul>
        <li>The communication needs to be one-to-one, where only one consumer should process the message.</li><br />
        <li>You require guaranteed message delivery, with the assurance that the message will be processed exactly once.</li><br />
        <li>The application needs to ensure that the order of message processing is maintained.</li>
      </ul>
    </p><br />

    <h2>Conclusion</h2>
    <p>
      The Point-to-Point (Queue) model is an essential messaging pattern in JMS that provides reliable, one-to-one message communication. It is particularly useful for applications where guaranteed message delivery and direct communication between producer and consumer are required. While it may have some scalability limitations, it is a powerful tool for enterprise messaging systems that need to ensure message reliability and processing integrity.
    </p>
  </div>
)}







{selectedChapter === 'chapter55' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Publish-Subscribe (Topic) Model </h1>

    <p>
      The Publish-Subscribe (Topic) model is the second main messaging model supported by JMS. In this model, messages are sent to a <strong>topic</strong> rather than a queue. Unlike the Point-to-Point (Queue) model, where each message is delivered to a single consumer, in the Publish-Subscribe model, multiple consumers can receive the same message. This makes it ideal for scenarios where a message needs to be broadcast to multiple recipients.
    </p><br />

    <h2>Overview of the Publish-Subscribe Model</h2>
    <p>
      In the Publish-Subscribe (Topic) model, a <strong>message producer</strong> publishes messages to a <strong>topic</strong>. Any number of <strong>message consumers</strong> can subscribe to that topic, receiving the published messages. Each message sent to the topic is delivered to all consumers subscribed to that topic. This model supports one-to-many communication, which is well-suited for broadcast-style messaging systems.
    </p><br />

    <h2>Components of the Publish-Subscribe Model</h2>
    <p>
      The Publish-Subscribe model involves several components, similar to the Point-to-Point model, but with the added flexibility of multiple consumers:
      <ul>
        <li><strong>Message Producer</strong>: The entity that sends messages to the topic.</li><br />
        <li><strong>Message Topic</strong>: The destination where messages are published. Multiple consumers can receive messages from the same topic.</li><br />
        <li><strong>Message Consumer</strong>: The entities that receive messages from the topic. Multiple consumers can subscribe to the same topic.</li><br />
        <li><strong>JMS Connection Factory</strong>: A factory used to create connections to the JMS provider for communication with the topic.</li><br />
        <li><strong>JMS Session</strong>: A single-threaded context used by the producer and consumer to send and receive messages from the topic.</li>
      </ul>
    </p><br />

    <h2>How the Publish-Subscribe Model Works</h2>
    <p>
      The Publish-Subscribe model operates as follows:
      <ol>
        <li>The <strong>message producer</strong> publishes a message to the <strong>topic</strong>.</li><br />
        <li>All <strong>message consumers</strong> that are subscribed to the topic receive the message.</li><br />
        <li>If there are multiple consumers subscribed, each consumer will receive a copy of the message.</li>
        <li>Each consumer can process the message independently.</li>
      </ol>
    </p><br />

    <h2>Example of Publish-Subscribe Model in JMS</h2>
    <p>
      Here's an example of how the Publish-Subscribe model works in JMS:
    </p>
    
    <pre>
      <code>{`
        // Create JMS connection and session
        ConnectionFactory connectionFactory = new ActiveMQConnectionFactory("tcp://localhost:61616");
        Connection connection = connectionFactory.createConnection();
        connection.start();
        Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
        
        // Create a topic and a message producer
        Topic topic = session.createTopic("TestTopic");
        MessageProducer producer = session.createProducer(topic);
        
        // Create a message
        TextMessage message = session.createTextMessage("Hello, Topic!");
        
        // Publish the message to the topic
        producer.send(message);
        
        // Create a message consumer
        MessageConsumer consumer = session.createConsumer(topic);
        
        // Receive the message from the topic
        Message receivedMessage = consumer.receive();
        System.out.println("Received: " + ((TextMessage) receivedMessage).getText());
        
        // Clean up
        session.close();
        connection.close();
      `}</code>
    </pre><br />

    <h2>Advantages of the Publish-Subscribe Model</h2>
    <p>
      The Publish-Subscribe model offers several advantages:
      <ul>
        <li><strong>Broadcast Communication</strong>: Messages can be sent to multiple consumers simultaneously, making it ideal for broadcast applications.</li><br />
        <li><strong>Decoupled Producers and Consumers</strong>: Producers and consumers are decoupled from each other, meaning a producer can publish messages without knowing who the consumers are.</li><br />
        <li><strong>Real-Time Updates</strong>: The Publish-Subscribe model is suitable for real-time updates, where multiple subscribers need to receive the latest information as soon as it's available.</li><br />
        <li><strong>Scalability</strong>: It allows for easy scaling, as new consumers can subscribe to the topic without affecting the producer.</li>
      </ul>
    </p><br />

    <h2>Disadvantages of the Publish-Subscribe Model</h2>
    <p>
      While the Publish-Subscribe model has several advantages, it also comes with a few drawbacks:
      <ul>
        <li><strong>Message Delivery Complexity</strong>: Since multiple consumers can receive the same message, ensuring the correct delivery and order of messages can be complex.</li><br />
        <li><strong>Unreliable Message Consumption</strong>: If a consumer is not available when the message is published, it may miss the message unless durable subscriptions are used.</li>
      </ul>
    </p><br />

    <h2>When to Use the Publish-Subscribe Model</h2>
    <p>
      The Publish-Subscribe model is ideal in scenarios where:
      <ul>
        <li>There is a need for one-to-many communication, such as broadcasting messages to multiple consumers.</li><br />
        <li>Multiple consumers need to receive the same message simultaneously, such as in event-driven systems.</li><br />
        <li>Real-time updates or notifications are required for multiple subscribers, like stock market updates or news feeds.</li>
      </ul>
    </p><br />

    <h2>Conclusion</h2>
    <p>
      The Publish-Subscribe (Topic) model is a powerful messaging pattern in JMS that allows for one-to-many communication. It is ideal for applications that need to broadcast messages to multiple subscribers, such as event-driven systems, real-time updates, and notifications. While it provides high scalability and decoupling between producers and consumers, it requires careful handling of message delivery and reliability, especially in cases where consumers may be unavailable when messages are published.
    </p>
  </div>
)}




{selectedChapter === 'chapter56' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Creating JMS Producers and Consumers   </h1>


    <p>
      In Java Message Service (JMS), a <strong>producer</strong> is responsible for sending messages to a destination (a queue or a topic), and a <strong>consumer</strong> is responsible for receiving those messages. In this section, we will cover how to create JMS producers and consumers, including the necessary configurations, message types, and how to send and receive messages.
    </p><br />

    <h2>Creating a JMS Producer</h2>
    <p style={{paddingBottom:"6px"}}>
      A JMS producer is responsible for creating and sending messages to a message destination (either a queue or topic). To create a JMS producer, you need to set up a connection, create a session, and then create a producer object that will send the messages to the destination.
    </p>

    <h3>Steps to Create a JMS Producer</h3>
    <ol>
      <li>Create a connection factory.</li><br />
      <li>Establish a connection with the JMS provider.</li><br />
      <li>Create a session for sending messages.</li><br />
      <li>Create a destination (queue or topic).</li><br />
      <li>Create a message producer for the destination.</li><br />
      <li>Create and send a message.</li>
    </ol><br />

    <h3>Example of JMS Producer</h3>
    <pre>
      <code>{`
        // Create connection factory
        ConnectionFactory connectionFactory = new ActiveMQConnectionFactory("tcp://localhost:61616");
        
        // Establish connection
        Connection connection = connectionFactory.createConnection();
        connection.start();
        
        // Create session
        Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
        
        // Create destination (queue or topic)
        Destination destination = session.createQueue("TestQueue");
        
        // Create message producer
        MessageProducer producer = session.createProducer(destination);
        
        // Create message
        TextMessage message = session.createTextMessage("Hello, JMS!");
        
        // Send the message
        producer.send(message);
        
        // Clean up
        session.close();
        connection.close();
       `} </code>
    </pre><br />

    <h2>Creating a JMS Consumer</h2>
    <p style={{paddingBottom:"6px"}}>
      A JMS consumer is responsible for receiving messages from a destination. Similar to the producer, the consumer needs a connection, session, and a destination (queue or topic). However, instead of sending messages, the consumer listens for incoming messages and processes them when received.
    </p>

    <h3>Steps to Create a JMS Consumer</h3>
    <ol>
      <li>Create a connection factory.</li><br />
      <li>Establish a connection with the JMS provider.</li><br />
      <li>Create a session for receiving messages.</li><br />
      <li>Create a destination (queue or topic).</li><br />
      <li>Create a message consumer for the destination.</li><br />
      <li>Receive and process the message.</li>
    </ol><br />

    <h3>Example of JMS Consumer</h3>
    <pre>
      <code>{`
        // Create connection factory
        ConnectionFactory connectionFactory = new ActiveMQConnectionFactory("tcp://localhost:61616");
        
        // Establish connection
        Connection connection = connectionFactory.createConnection();
        connection.start();
        
        // Create session
        Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
        
        // Create destination (queue or topic)
        Destination destination = session.createQueue("TestQueue");
        
        // Create message consumer
        MessageConsumer consumer = session.createConsumer(destination);
        
        // Receive message
        Message message = consumer.receive();
        
        // Process the message
        if (message instanceof TextMessage) {
          TextMessage textMessage = (TextMessage) message;
          System.out.println("Received: " + textMessage.getText());
        }
        
        // Clean up
        session.close();
        connection.close();
      `}</code>
    </pre>

    <h2>Types of JMS Messages</h2>
    <p>
      JMS supports several types of messages. The most common types are:
      <ul>
        <li><strong>TextMessage</strong>: A message that contains a string of text.</li><br />
        <li><strong>ObjectMessage</strong>: A message that contains a serializable Java object.</li><br />
        <li><strong>BytesMessage</strong>: A message that contains raw bytes of data.</li><br />
        <li><strong>MapMessage</strong>: A message that contains a set of name-value pairs (like a map).</li><br />
        <li><strong>StreamMessage</strong>: A message that contains a stream of primitive data types.</li>
      </ul>
    </p><br />

    <h2>Message Acknowledgment</h2>
    <p>
      In JMS, message acknowledgment is a way to ensure that a message has been received successfully by a consumer. There are several types of acknowledgment modes that control how and when a message is acknowledged:
      <ul>
        <li><strong>Auto Acknowledge</strong>: The session automatically acknowledges the receipt of a message when it is successfully received.</li><br />
        <li><strong>Client Acknowledge</strong>: The consumer explicitly acknowledges the message by calling the <code>acknowledge()</code> method on the message.</li><br />
        <li><strong>Dups Ok Acknowledge</strong>: The session will acknowledge the receipt of messages, but it may duplicate messages in case of network failures.</li>
      </ul>
    </p><br />

    <h2>Using Message Listeners (Asynchronous Consumer)</h2>
    <p style={{paddingBottom:"6px"}}>
      Instead of synchronously receiving messages with the <code>receive()</code> method, you can also create a message listener that handles messages asynchronously. This is useful for building non-blocking consumers that can process messages as they arrive.
    </p>

    <h3>Example of Asynchronous Consumer Using MessageListener</h3>
    <pre>
      <code>{`
        // Create connection factory
        ConnectionFactory connectionFactory = new ActiveMQConnectionFactory("tcp://localhost:61616");
        
        // Establish connection
        Connection connection = connectionFactory.createConnection();
        connection.start();
        
        // Create session
        Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
        
        // Create destination (queue or topic)
        Destination destination = session.createQueue("TestQueue");
        
        // Create message consumer
        MessageConsumer consumer = session.createConsumer(destination);
        
        // Set a message listener
        consumer.setMessageListener(new MessageListener() {
          public void onMessage(Message message) {
            try {
              if (message instanceof TextMessage) {
                TextMessage textMessage = (TextMessage) message;
                System.out.println("Received: " + textMessage.getText());
              }
            } catch (JMSException e) {
              e.printStackTrace();
            }
          }
        });
        
        // The consumer will keep listening for messages asynchronously.
        // Clean up will be done after stopping the connection.
      `}</code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      JMS producers and consumers are essential components of a messaging system. A producer is responsible for sending messages to a destination (queue or topic), while a consumer is responsible for receiving and processing those messages. By creating JMS producers and consumers, you can build powerful and flexible messaging applications. The JMS API also allows for asynchronous message consumption using listeners, making it easier to build non-blocking systems that respond to messages as they arrive.
    </p>
  </div>
)}







{selectedChapter === 'chapter57' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Creating JMS Producers and Consumers   </h1>

    <p>
      In Java Message Service (JMS), a <strong>producer</strong> is responsible for sending messages to a destination (a queue or a topic), and a <strong>consumer</strong> is responsible for receiving those messages. In this section, we will cover how to create JMS producers and consumers, including the necessary configurations, message types, and how to send and receive messages.
    </p><br />

    <h2>Creating a JMS Producer</h2>
    <p style={{paddingBottom:"6px"}}>
      A JMS producer is responsible for creating and sending messages to a message destination (either a queue or topic). To create a JMS producer, you need to set up a connection, create a session, and then create a producer object that will send the messages to the destination.
    </p>

    <h3>Steps to Create a JMS Producer</h3>
    <ol>
      <li>Create a connection factory.</li><br />
      <li>Establish a connection with the JMS provider.</li><br />
      <li>Create a session for sending messages.</li><br />
      <li>Create a destination (queue or topic).</li><br />
      <li>Create a message producer for the destination.</li><br />
      <li>Create and send a message.</li>
    </ol>

    <h3>Example of JMS Producer</h3>
    <pre>
      <code>{`
        // Create connection factory
        ConnectionFactory connectionFactory = new ActiveMQConnectionFactory("tcp://localhost:61616");
        
        // Establish connection
        Connection connection = connectionFactory.createConnection();
        connection.start();
        
        // Create session
        Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
        
        // Create destination (queue or topic)
        Destination destination = session.createQueue("TestQueue");
        
        // Create message producer
        MessageProducer producer = session.createProducer(destination);
        
        // Create message
        TextMessage message = session.createTextMessage("Hello, JMS!");
        
        // Send the message
        producer.send(message);
        
        // Clean up
        session.close();
        connection.close();
      `}</code>
    </pre><br />

    <h2>Creating a JMS Consumer</h2>
    <p style={{paddingBottom:"6px"}}>
      A JMS consumer is responsible for receiving messages from a destination. Similar to the producer, the consumer needs a connection, session, and a destination (queue or topic). However, instead of sending messages, the consumer listens for incoming messages and processes them when received.
    </p>

    <h3>Steps to Create a JMS Consumer</h3>
    <ol>
      <li>Create a connection factory.</li><br />
      <li>Establish a connection with the JMS provider.</li><br />
      <li>Create a session for receiving messages.</li><br />
      <li>Create a destination (queue or topic).</li><br />
      <li>Create a message consumer for the destination.</li><br />
      <li>Receive and process the message.</li>
    </ol>

    <h3>Example of JMS Consumer</h3>
    <pre>
      <code>{`
        // Create connection factory
        ConnectionFactory connectionFactory = new ActiveMQConnectionFactory("tcp://localhost:61616");
        
        // Establish connection
        Connection connection = connectionFactory.createConnection();
        connection.start();
        
        // Create session
        Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
        
        // Create destination (queue or topic)
        Destination destination = session.createQueue("TestQueue");
        
        // Create message consumer
        MessageConsumer consumer = session.createConsumer(destination);
        
        // Receive message
        Message message = consumer.receive();
        
        // Process the message
        if (message instanceof TextMessage) {
          TextMessage textMessage = (TextMessage) message;
          System.out.println("Received: " + textMessage.getText());
        }
        
        // Clean up
        session.close();
        connection.close();
      `}</code>
    </pre>

    <h2>Types of JMS Messages</h2>
    <p>
      JMS supports several types of messages. The most common types are:
      <ul>
        <li><strong>TextMessage</strong>: A message that contains a string of text.</li><br />
        <li><strong>ObjectMessage</strong>: A message that contains a serializable Java object.</li><br />
        <li><strong>BytesMessage</strong>: A message that contains raw bytes of data.</li><br />
        <li><strong>MapMessage</strong>: A message that contains a set of name-value pairs (like a map).</li><br />
        <li><strong>StreamMessage</strong>: A message that contains a stream of primitive data types.</li>
      </ul>
    </p><br />

    <h2>Message Acknowledgment</h2>
    <p>
      In JMS, message acknowledgment is a way to ensure that a message has been received successfully by a consumer. There are several types of acknowledgment modes that control how and when a message is acknowledged:
      <ul>
        <li><strong>Auto Acknowledge</strong>: The session automatically acknowledges the receipt of a message when it is successfully received.</li><br />
        <li><strong>Client Acknowledge</strong>: The consumer explicitly acknowledges the message by calling the <code>acknowledge()</code> method on the message.</li><br />
        <li><strong>Dups Ok Acknowledge</strong>: The session will acknowledge the receipt of messages, but it may duplicate messages in case of network failures.</li>
      </ul>
    </p>
<br />

    <h2>Using Message Listeners (Asynchronous Consumer)</h2>
    <p style={{paddingBottom:"6px"}}>
      Instead of synchronously receiving messages with the <code>receive()</code> method, you can also create a message listener that handles messages asynchronously. This is useful for building non-blocking consumers that can process messages as they arrive.
    </p>

    <h3>Example of Asynchronous Consumer Using MessageListener</h3>
    <pre>
      <code>{`
        // Create connection factory
        ConnectionFactory connectionFactory = new ActiveMQConnectionFactory("tcp://localhost:61616");
        
        // Establish connection
        Connection connection = connectionFactory.createConnection();
        connection.start();
        
        // Create session
        Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
        
        // Create destination (queue or topic)
        Destination destination = session.createQueue("TestQueue");
        
        // Create message consumer
        MessageConsumer consumer = session.createConsumer(destination);
        
        // Set a message listener
        consumer.setMessageListener(new MessageListener() {
          public void onMessage(Message message) {
            try {
              if (message instanceof TextMessage) {
                TextMessage textMessage = (TextMessage) message;
                System.out.println("Received: " + textMessage.getText());
              }
            } catch (JMSException e) {
              e.printStackTrace();
            }
          }
        });
        
        // The consumer will keep listening for messages asynchronously.
        // Clean up will be done after stopping the connection.
       `} </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      JMS producers and consumers are essential components of a messaging system. A producer is responsible for sending messages to a destination (queue or topic), while a consumer is responsible for receiving and processing those messages. By creating JMS producers and consumers, you can build powerful and flexible messaging applications. The JMS API also allows for asynchronous message consumption using listeners, making it easier to build non-blocking systems that respond to messages as they arrive.
    </p>
  </div>
)}



{selectedChapter === 'chapter104' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Configuring and Using Message-Driven Beans (MDB)</h1>

    
    <p>
      In Java EE, Message-Driven Beans (MDBs) are a special type of enterprise bean designed for processing messages asynchronously using the Java Message Service (JMS). MDBs provide a simplified model for handling JMS messages and are typically used in scenarios where you need to integrate with messaging systems, such as JMS queues or topics. Below, we will cover how to configure and use MDBs for JMS messaging.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>1. Configuring a Message-Driven Bean (MDB)</h2>

    <h3>1.1. Setting up the Environment</h3>
    <p>
      To use MDBs, you will need a Java EE-compliant application server (such as WildFly, GlassFish, or Apache TomEE) that supports JMS and MDB functionality. Additionally, ensure that your server is properly configured to connect to a JMS provider (e.g., ActiveMQ, IBM MQ).
    </p><br />

    <h3>1.2. Creating the Message-Driven Bean</h3>
    <p>
      A Message-Driven Bean (MDB) is typically annotated with <code>@MessageDriven</code> and configured to process JMS messages. Here's a step-by-step approach to create an MDB:
    </p>
    
    <ul>
      <li>
        <strong>Step 1:</strong> Define the Message-Driven Bean class and annotate it with <code>@MessageDriven</code>.
        <pre>
          <code>{`
import javax.ejb.MessageDriven;
import javax.jms.Message;
import javax.jms.MessageListener;
import javax.jms.TextMessage;

@MessageDriven
public class MyMDB implements MessageListener {
    @Override
    public void onMessage(Message message) {
        try {
            if (message instanceof TextMessage) {
                TextMessage textMessage = (TextMessage) message;
                System.out.println("Received message: " + textMessage.getText());
            }
        } catch (Exception e) {
            e.printStackTrace();
        }
    }
}
           `} </code>
        </pre>
      </li><br />

      <li>
        <strong>Step 2:</strong> Configure the MDB to listen to a specific JMS destination (queue or topic). This is done using the <code>@ActivationConfigProperty</code> annotation or via deployment descriptors in the <code>ejb-jar.xml</code> file.
        <pre>
          <code>{`
import javax.ejb.ActivationConfigProperty;
import javax.ejb.MessageDriven;

@MessageDriven(activationConfig = {
    @ActivationConfigProperty(propertyName = "destinationType", propertyValue = "javax.jms.Queue"),
    @ActivationConfigProperty(propertyName = "destination", propertyValue = "jms/myQueue")
})
public class MyMDB implements MessageListener {
    // onMessage method implementation...
}
          `}</code>
        </pre>
      </li>
    </ul><br />

    <h3>1.3. Deploying the MDB</h3>
    <p>
      After implementing the MDB, deploy your application to a Java EE-compliant application server. The MDB will be automatically instantiated by the container and will start listening to the JMS queue or topic.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>2. Sending JMS Messages to an MDB</h2>

    <h3>2.1. Creating a JMS Producer</h3>
    <p>
      To send a message to the MDB, you need a JMS producer. The producer will send messages to the same JMS destination (queue or topic) to which the MDB is listening.
    </p>
    <pre>
      <code>{`
import javax.jms.Connection;
import javax.jms.ConnectionFactory;
import javax.jms.Destination;
import javax.jms.Message;
import javax.jms.Session;
import javax.jms.TextMessage;
import javax.naming.Context;
import javax.naming.InitialContext;

public class JMSProducer {
    public static void main(String[] args) throws Exception {
        // Setup initial context for JMS
        Context context = new InitialContext();
        ConnectionFactory connectionFactory = (ConnectionFactory) context.lookup("jms/ConnectionFactory");
        Destination destination = (Destination) context.lookup("jms/myQueue");

        // Create a JMS connection and session
        try (Connection connection = connectionFactory.createConnection()) {
            Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);
            javax.jms.MessageProducer producer = session.createProducer(destination);

            // Create a message
            TextMessage message = session.createTextMessage("Hello from JMS");

            // Send the message
            producer.send(message);
            System.out.println("Message sent: " + message.getText());
        }
    }
}
       `} </code>
    </pre><br />

    <h3>2.2. Testing the Integration</h3>
    <p>
      Once both the MDB and JMS producer are set up, you can test the integration by running the JMS producer to send a message. The MDB will automatically receive the message and process it as defined in the <code>onMessage()</code> method.
    </p><br />

    <h2>3. Conclusion</h2>
    <p>
      Message-Driven Beans (MDBs) provide a powerful way to process JMS messages asynchronously in Java EE. By configuring the MDB to listen to a JMS destination and using a JMS producer to send messages, you can build robust messaging solutions in your Java EE applications. MDBs simplify the complexity of dealing with JMS APIs directly and offer a declarative approach for integrating with messaging systems.
    </p>
  </div>
)}






{selectedChapter === 'chapter58' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Setting up the JavaMail Library </h1>


    <p>
      The JavaMail API allows Java applications to send and receive emails using popular protocols such as SMTP, POP3, and IMAP. To use JavaMail, you first need to set up the JavaMail library in your project. This section will guide you through the process of setting up the JavaMail library and configuring your project for email functionality.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Steps to Set Up JavaMail Library</h2>

    <h3>1. Downloading the JavaMail API</h3>
    <p style={{paddingBottom:"6px"}}>
      The first step in using JavaMail is to download the JavaMail API. If you're using a build tool like Maven or Gradle, you can directly include JavaMail as a dependency. Otherwise, you can download the jar files manually.
    </p>

    <h4>Using Maven</h4>
    <p>
      If you're using Maven, add the following dependency to your <code>pom.xml</code> file to include the JavaMail API:
    </p>
    <pre>
      <code>{`
        <dependency>
            <groupId>javax.mail</groupId>
            <artifactId>javax.mail-api </artifactId>
            <version>1.6.2</version>
        </dependency>
       `} </code>
    </pre><br />

    <h4>Using Gradle</h4>
    <p>
      For Gradle users, add the following to your <code>build.gradle</code> file:
    </p>
    <pre>
      <code>
        implementation 'javax.mail:javax.mail-api:1.6.2'
      </code>
    </pre><br />

    <h4>Manual Download</h4>
    <p>
      If you're not using a build tool like Maven or Gradle, you can manually download the JavaMail jar files from the official JavaMail download page or Maven repository. Once downloaded, you can add the jars to your project's classpath.
    </p>
    <a href="https://javaee.github.io/javamail/" target="_blank" rel="noopener noreferrer">JavaMail Official Download</a>

<br /><br />

    <h3>2. Configuring the JavaMail Session</h3>
    <p style={{paddingBottom:"6px"}}>
      Once you have included the JavaMail library in your project, the next step is to configure the JavaMail session. The session provides the environment for sending and receiving emails. It requires properties like the mail server's SMTP, IMAP, or POP3 settings.
    </p>

    <h4>Sample Configuration</h4>
    <pre>
      <code>{`
        import javax.mail.*;
        import java.util.Properties;
        
        public class MailSession {
            public static Session getSession() {
                Properties properties = new Properties();
                properties.put("mail.smtp.host", "smtp.example.com");
                properties.put("mail.smtp.port", "587");
                properties.put("mail.smtp.auth", "true");
                properties.put("mail.smtp.starttls.enable", "true");

                // Create a new session
                return Session.getInstance(properties, new Authenticator() {
                    @Override
                    protected PasswordAuthentication getPasswordAuthentication() {
                        return new PasswordAuthentication("your-email@example.com", "your-email-password");
                    }
                });
            }
        }
       `} </code>
    </pre>

    <p>
      In this example, we configure the session to connect to an SMTP server with authentication and encryption. Replace the placeholder values with your own email server settings.
    </p><br />

    <h3>3. Setting Up Email Sending Functionality</h3>
    <p>
      After setting up the JavaMail session, you can proceed to send emails using the <code>Transport.send()</code> method. Here's an example of how to send an email through the configured session.
    </p>

    <pre>
      <code>{`
        import javax.mail.*;
        import javax.mail.internet.*;
        
        public class SendEmail {
            public static void sendEmail() throws MessagingException {
                // Create a new email message
                Message message = new MimeMessage(MailSession.getSession());
                message.setFrom(new InternetAddress("your-email@example.com"));
                message.setRecipients(Message.RecipientType.TO, InternetAddress.parse("recipient@example.com"));
                message.setSubject("Test Email");
                message.setText("Hello, this is a test email.");

                // Send the email
                Transport.send(message);
                System.out.println("Email sent successfully!");
            }

            public static void main(String[] args) {
                try {
                    sendEmail();
                } catch (MessagingException e) {
                    e.printStackTrace();
                }
            }
        }
       `} </code>
    </pre>

    <p>
      In this code, we create a <code>MimeMessage</code> object, set the sender, recipient, subject, and content, and finally send the message using the <code>Transport.send()</code> method.
    </p><br />

    <h3>4. Handling Errors</h3>
    <p>
      When working with email sending functionality, it's essential to handle errors such as connection issues or authentication failures. JavaMail provides useful error messages, and you can catch and handle these exceptions accordingly to ensure the smooth operation of your application.
    </p>

    <pre>
      <code>{`
        try {
            sendEmail();
        } catch (MessagingException e) {
            System.err.println("Error occurred while sending email: " + e.getMessage());
            e.printStackTrace();
        }
       `} </code>
    </pre><br />

    <h2>Additional Configuration</h2>
    <h3>Secure Connection (SSL/TLS)</h3>
    <p>
      If you're using a secure connection for sending emails, ensure that you specify the correct port and enable SSL or TLS. For example, for Gmail SMTP, the settings would be as follows:
    </p>

    <pre>
      <code>{`
        properties.put("mail.smtp.ssl.enable", "true");
        properties.put("mail.smtp.host", "smtp.gmail.com");
        properties.put("mail.smtp.port", "465");
      `}</code>
    </pre>

    <p>
      Always verify the SMTP server settings for your mail provider, as the port and security settings may differ.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Setting up the JavaMail API is an essential step for sending and receiving emails in Java applications. By adding the necessary library, configuring the JavaMail session, and implementing email sending functionality, you can integrate email capabilities into your Java applications. Additionally, handling secure connections, errors, and authentication is critical for ensuring the success of email communication.
    </p>
  </div>
)}
   




{selectedChapter === 'chapter59' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Sending Emails using SMTP   </h1>

    <p>
      Simple Mail Transfer Protocol (SMTP) is the standard protocol for sending emails across the internet. The JavaMail API provides a way to send emails using SMTP. In this section, we will cover how to configure and use the JavaMail API to send emails through an SMTP server.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Steps to Send Emails Using SMTP</h2>

    <h3>1. Configure SMTP Settings</h3>
    <p>
      To send emails using SMTP, you need to configure the necessary settings such as the SMTP server address, port, authentication details, and security protocols (SSL/TLS). Below is an example configuration for sending emails through a Gmail SMTP server.
    </p>

    <pre>
      <code>{`
        import javax.mail.*;
        import javax.mail.internet.*;
        import java.util.Properties;
        
        public class SendEmailSMTP {
            public static void main(String[] args) {
                // Set the SMTP server properties
                Properties properties = new Properties();
                properties.put("mail.smtp.host", "smtp.gmail.com");
                properties.put("mail.smtp.port", "587");
                properties.put("mail.smtp.auth", "true");
                properties.put("mail.smtp.starttls.enable", "true");

                // Authenticate with the email account
                Authenticator auth = new Authenticator() {
                    protected PasswordAuthentication getPasswordAuthentication() {
                        return new PasswordAuthentication("your-email@gmail.com", "your-email-password");
                    }
                };

                // Create a session with the SMTP settings and authentication
                Session session = Session.getInstance(properties, auth);

                try {
                    // Create a new email message
                    Message message = new MimeMessage(session);
                    message.setFrom(new InternetAddress("your-email@gmail.com"));
                    message.setRecipients(Message.RecipientType.TO, InternetAddress.parse("recipient@example.com"));
                    message.setSubject("Test Email via SMTP");
                    message.setText("Hello, this is a test email sent using SMTP in Java.");

                    // Send the email
                    Transport.send(message);
                    System.out.println("Email sent successfully!");
                } catch (MessagingException e) {
                    e.printStackTrace();
                }
            }
        }
        `}  </code>
    </pre>

    <p>
      In this example, we configure the SMTP properties for Gmail's SMTP server (smtp.gmail.com) with the necessary settings for sending emails securely. Replace the email addresses and credentials with your actual email information.
    </p><br />

    <h3>2. SMTP Server Configuration</h3>
    <p>
      Each email provider has different SMTP settings. Here are some common SMTP server configurations for popular email providers:
    </p>

    <table className={style.table}>
      <thead>
        <tr>
          <th>Email Provider</th>
          <th>SMTP Server</th>
          <th>SMTP Port</th>
          <th>SSL/TLS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Gmail</td>
          <td>smtp.gmail.com</td>
          <td>587 (TLS) / 465 (SSL)</td>
          <td>STARTTLS / SSL</td>
        </tr>
        <tr>
          <td>Outlook</td>
          <td>smtp-mail.outlook.com</td>
          <td>587 (TLS)</td>
          <td>STARTTLS</td>
        </tr>
        <tr>
          <td>Yahoo</td>
          <td>smtp.mail.yahoo.com</td>
          <td>587 (TLS) / 465 (SSL)</td>
          <td>STARTTLS / SSL</td>
        </tr>
        <tr>
          <td>SMTP2GO</td>
          <td>mail.smtp2go.com</td>
          <td>2525 (TLS)</td>
          <td>STARTTLS</td>
        </tr>
      </tbody>
    </table>

    <p>
      Make sure to verify the SMTP server and port number with your email provider, as the configuration may differ.
    </p><br />

    <h3>3. Secure Authentication</h3>
    <p>
      Secure authentication is crucial when sending emails through SMTP. To authenticate, we use the <code>Authenticator</code> class, which provides the email account's credentials. If you're using Gmail, you may need to enable "Less secure apps" in your Google account or use an "App Password" for added security, especially if you have 2-factor authentication enabled.
    </p><br />

    <h3>4. Handling Exceptions</h3>
    <p>
      When working with email sending functionality, it's important to handle exceptions gracefully. Common exceptions include issues with the SMTP server, incorrect login credentials, or network errors. Here's an example of how to catch and handle a <code>MessagingException</code> when sending an email:
    </p>

    <pre>
      <code>{`
        try {
            Transport.send(message);
            System.out.println("Email sent successfully!");
        } catch (MessagingException e) {
            System.err.println("Failed to send email: " + e.getMessage());
            e.printStackTrace();
        }
      `}</code>
    </pre>

    <p>
      In this code, we catch any <code>MessagingException</code> and print the error message to the console to diagnose the issue.
    </p><br />

    <h3>5. Sending HTML Emails</h3>
    <p>
      JavaMail allows you to send HTML emails in addition to plain text. You can use the <code>setContent()</code> method to specify HTML content for the email message.
    </p>

    <pre>
      <code>
        message.setContent("<h1>This is an HTML Email</h1><p>Hello, this is a test email sent with HTML content.</p>", "text/html");
      </code>
    </pre>

    <p>
      In this example, we set the email content type to "text/html" to send an HTML-formatted email.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      Sending emails using SMTP in Java is simple with the JavaMail API. By configuring the SMTP server settings, authenticating with the email account, and using the <code>Transport.send()</code> method, you can easily send emails programmatically. Additionally, JavaMail allows you to send both plain text and HTML emails, handle exceptions, and work with various SMTP providers.
    </p>

  </div>
)}


{selectedChapter === 'chapter60' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Reading Emails using IMAP and POP3 </h1>

    <p>
      The JavaMail API allows you to read emails from a mail server using protocols such as IMAP (Internet Message Access Protocol) and POP3 (Post Office Protocol version 3). These protocols are commonly used for receiving and managing emails. IMAP allows access to email on the server, while POP3 downloads emails to the client and removes them from the server.
    </p><br />

    <h2>Understanding IMAP and POP3</h2>
    <p>
      IMAP and POP3 are the two most common protocols for retrieving email. The key difference between them is how they handle email storage and synchronization:
    </p>
    <ul>
      <li><strong>IMAP</strong>: Emails are stored on the server and can be accessed from multiple devices. It supports synchronization of read/unread status, folder management, and message flags.</li><br />
      <li><strong>POP3</strong>: Emails are downloaded to the client and removed from the server. This protocol does not support synchronization across devices and is mainly used for offline access.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Steps to Read Emails using IMAP and POP3</h2>

    <h3>1. Set Up Email Session</h3>
    <p>
      To read emails, first, you need to set up a session using the <code>Session</code> object in JavaMail. The session holds properties such as the mail server, port, and authentication details.
    </p>

    <pre>
      <code>{`
        import javax.mail.*;
        import javax.mail.internet.*;
        import java.util.Properties;

        public class ReadEmail {
            public static void main(String[] args) {
                // Set up the mail server properties
                Properties properties = new Properties();
                properties.put("mail.store.protocol", "imaps");
                properties.put("mail.imap.host", "imap.gmail.com");
                properties.put("mail.imap.port", "993");
                properties.put("mail.imap.ssl.enable", "true");

                // Authenticate with the email account
                try {
                    Session session = Session.getInstance(properties);
                    Store store = session.getStore("imaps");
                    store.connect("imap.gmail.com", "your-email@gmail.com", "your-email-password");

                    // Open the inbox folder
                    Folder inbox = store.getFolder("INBOX");
                    inbox.open(Folder.READ_ONLY);

                    // Retrieve messages
                    Message[] messages = inbox.getMessages();
                    for (Message message : messages) {
                        System.out.println("Subject: " + message.getSubject());
                        System.out.println("From: " + message.getFrom()[0]);
                        System.out.println("Date: " + message.getSentDate());
                        System.out.println("Content: " + message.getContent());
                        System.out.println("-------------------------------");
                    }

                    inbox.close(false);
                    store.close();
                } catch (Exception e) {
                    e.printStackTrace();
                }
            }
        }
       `} </code>
    </pre>

    <p>
      In this example, we set up an IMAP session with Gmail's IMAP server and retrieve messages from the inbox. The session authenticates using your email credentials, and then it opens the "INBOX" folder in read-only mode to prevent modifying the emails.
    </p><br />

    <h3>2. Accessing the Mail Store</h3>
    <p>
      The <code>Store</code> class in JavaMail represents a connection to the email server. It is used to retrieve messages. Depending on the protocol (IMAP or POP3), you can use different store types:
    </p>
    <ul>
      <li><strong>IMAP</strong>: <code>session.getStore("imaps")</code></li><br />
      <li><strong>POP3</strong>: <code>session.getStore("pop3s")</code></li>
    </ul>

    <p>
      Make sure to use the correct protocol (`imaps` or `pop3s`) in the store URL. IMAP uses port 993 for secure connections, and POP3 uses port 995.
    </p><br />

    <h3>3. Reading Emails</h3>
    <p>
      Once the mail store is connected, you can access a folder (e.g., INBOX) using the <code>getFolder()</code> method. The <code>Folder</code> object represents a collection of messages. You can open the folder in either <code>READ_ONLY</code> or <code>READ_WRITE</code> mode.
    </p>

    <pre>
      <code>{`
        // Open the inbox folder
        Folder inbox = store.getFolder("INBOX");
        inbox.open(Folder.READ_ONLY);

        // Retrieve messages
        Message[] messages = inbox.getMessages();
        for (Message message : messages) {
            System.out.println("Subject: " + message.getSubject());
            System.out.println("From: " + message.getFrom()[0]);
            System.out.println("Date: " + message.getSentDate());
            System.out.println("Content: " + message.getContent());
            System.out.println("-------------------------------");
        }

        inbox.close(false);
      `}</code>
    </pre>

    <p>
      The <code>getMessages()</code> method retrieves an array of <code>Message</code> objects, which represent individual emails. You can loop through the messages to access various attributes like subject, sender, sent date, and content.
    </p><br />

    <h3>4. POP3 Example</h3>
    <p>
      The process for reading emails using POP3 is similar to IMAP. The only difference is in the store protocol and port:
    </p>

    <pre>
      <code>{`
        // Set up POP3 properties
        Properties properties = new Properties();
        properties.put("mail.store.protocol", "pop3s");
        properties.put("mail.pop3.host", "pop.gmail.com");
        properties.put("mail.pop3.port", "995");
        properties.put("mail.pop3.ssl.enable", "true");

        // Authenticate and retrieve messages
        Session session = Session.getInstance(properties);
        Store store = session.getStore("pop3s");
        store.connect("pop.gmail.com", "your-email@gmail.com", "your-email-password");

        Folder inbox = store.getFolder("INBOX");
        inbox.open(Folder.READ_ONLY);

        // Retrieve messages
        Message[] messages = inbox.getMessages();
        for (Message message : messages) {
            System.out.println("Subject: " + message.getSubject());
            System.out.println("From: " + message.getFrom()[0]);
            System.out.println("Date: " + message.getSentDate());
            System.out.println("Content: " + message.getContent());
            System.out.println("-------------------------------");
        }

        inbox.close(false);
        store.close();
      `}</code>
    </pre>

    <p>
      In this example, we use the POP3 protocol (`pop3s`) with Gmail's POP3 server to retrieve emails. The usage of <code>Folder</code> and <code>Message</code> objects is identical to the IMAP example.
    </p><br />

    <h3>5. Handling Email Content</h3>
    <p>
      Email content can be of different types, such as plain text, HTML, or attachments. You can use the <code>getContent()</code> method to get the email content. However, to handle multipart messages (i.e., emails with attachments or mixed content), you need to check the content type.
    </p>

    <pre>
      <code>{`
        if (message.isMimeType("text/plain")) {
            System.out.println("Content: " + message.getContent());
        } else if (message.isMimeType("text/html")) {
            System.out.println("HTML Content: " + message.getContent());
        } else {
            // Handle multipart content (attachments, etc.)
        }
      `}</code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Using JavaMail with IMAP or POP3 allows you to easily retrieve and read emails from a mail server. IMAP is the preferred choice for most modern applications due to its support for email synchronization across devices. By setting up the session, connecting to the mail store, and accessing the message folder, you can retrieve email messages and handle their content.
    </p>
  </div>
)}



{selectedChapter === 'chapter61' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Email Attachments and Formatting   </h1>

    <p>
      The JavaMail API allows you to send emails with attachments and custom formatting. You can attach files to an email and format the message body using HTML or plain text. This is useful for sending multimedia emails, reports, or documents.
    </p><br />

    <h2>Attaching Files to Emails</h2>
    <p>
      To send an email with attachments, you need to create a <code>Multipart</code> object, which contains different parts (such as the text body and the attachment). Each part is represented by a <code>BodyPart</code> object. These parts are then added to the <code>Multipart</code> container.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Steps to Send an Email with Attachments</h3>

    <h4>1. Set Up the Email Session</h4>
    <p>
      Just like sending a basic email, you need to set up an email session with the mail server properties (host, port, authentication).
    </p>

    <pre>
      <code>{`
        import javax.mail.*;
        import javax.mail.internet.*;
        import javax.activation.*;
        import java.util.Properties;

        public class SendEmailWithAttachment {
            public static void main(String[] args) {
                // Setup properties for the email session
                Properties properties = new Properties();
                properties.put("mail.smtp.host", "smtp.gmail.com");
                properties.put("mail.smtp.port", "587");
                properties.put("mail.smtp.auth", "true");
                properties.put("mail.smtp.starttls.enable", "true");

                // Create a session with authentication
                Session session = Session.getInstance(properties, new Authenticator() {
                    protected PasswordAuthentication getPasswordAuthentication() {
                        return new PasswordAuthentication("your-email@gmail.com", "your-email-password");
                    }
                });

                try {
                    // Create a message
                    Message message = new MimeMessage(session);
                    message.setFrom(new InternetAddress("your-email@gmail.com"));
                    message.setRecipients(Message.RecipientType.TO, InternetAddress.parse("recipient-email@example.com"));
                    message.setSubject("Email with Attachment");

                    // Create a multipart message
                    Multipart multipart = new MimeMultipart();

                    // Add text body part
                    MimeBodyPart textPart = new MimeBodyPart();
                    textPart.setText("Hello, this email contains an attachment.");
                    multipart.addBodyPart(textPart);

                    // Add attachment body part
                    MimeBodyPart attachmentPart = new MimeBodyPart();
                    attachmentPart.attachFile("path/to/your/file.txt");  // Specify the file path
                    multipart.addBodyPart(attachmentPart);

                    // Set the multipart content to the message
                    message.setContent(multipart);

                    // Send the email
                    Transport.send(message);
                    System.out.println("Email sent successfully!");
                } catch (Exception e) {
                    e.printStackTrace();
                }
            }
        }
        `}  </code>
    </pre>

    <p>
      In this example, we create a <code>Multipart</code> message, which can hold multiple parts (body and attachments). The body part is added using a <code>MimeBodyPart</code> with a text message, and the attachment is added using another <code>MimeBodyPart</code> that attaches a file.
    </p><br />

    <h2>HTML Email Formatting</h2>
    <p>
      JavaMail also supports HTML email formatting. To send an email with HTML content, you simply need to set the content type to <code>text/html</code> and provide the HTML content in the message body.
    </p>

    <h3>Sending an Email with HTML Formatting</h3>

    <pre>
      <code>{`
        import javax.mail.*;
        import javax.mail.internet.*;
        import java.util.Properties;

        public class SendHTMLEmail {
            public static void main(String[] args) {
                // Set up the email properties
                Properties properties = new Properties();
                properties.put("mail.smtp.host", "smtp.gmail.com");
                properties.put("mail.smtp.port", "587");
                properties.put("mail.smtp.auth", "true");
                properties.put("mail.smtp.starttls.enable", "true");

                // Create a session with authentication
                Session session = Session.getInstance(properties, new Authenticator() {
                    protected PasswordAuthentication getPasswordAuthentication() {
                        return new PasswordAuthentication("your-email@gmail.com", "your-email-password");
                    }
                });

                try {
                    // Create the message
                    Message message = new MimeMessage(session);
                    message.setFrom(new InternetAddress("your-email@gmail.com"));
                    message.setRecipients(Message.RecipientType.TO, InternetAddress.parse("recipient-email@example.com"));
                    message.setSubject("HTML Formatted Email");

                    // Set HTML content
                    String htmlContent = "<h1>Welcome to the Email</h1>" +
                                         "<p>This is an <b>HTML</b> email with <i>formatted</i> text.</p>";
                    message.setContent(htmlContent, "text/html");

                    // Send the email
                    Transport.send(message);
                    System.out.println("HTML Email sent successfully!");
                } catch (Exception e) {
                    e.printStackTrace();
                }
            }
        }
      `}</code>
    </pre>

    <p>
      In this example, we send an email with HTML content. The content is set to a simple HTML string, and the content type is specified as <code>text/html</code>. This allows the email body to display formatted text (bold, italics, etc.) when viewed in an email client.
    </p><br />

    <h2>Handling Multiple Attachments</h2>
    <p>
      You can send multiple attachments by creating additional <code>MimeBodyPart</code> objects and adding them to the <code>Multipart</code> container. Here’s how you can attach more than one file to the email:
    </p>

    <pre>
      <code>{`
        MimeBodyPart attachmentPart1 = new MimeBodyPart();
        attachmentPart1.attachFile("path/to/your/file1.txt");

        MimeBodyPart attachmentPart2 = new MimeBodyPart();
        attachmentPart2.attachFile("path/to/your/file2.pdf");

        Multipart multipart = new MimeMultipart();
        multipart.addBodyPart(textPart);
        multipart.addBodyPart(attachmentPart1);
        multipart.addBodyPart(attachmentPart2);
      `}</code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      JavaMail provides easy-to-use APIs for sending emails with attachments and formatted content. By using <code>Multipart</code> objects and <code>MimeBodyPart</code> for attachments, and setting the content type to <code>text/html</code> for HTML emails, you can create rich and functional emails with ease.
    </p>
  </div>
)}



{selectedChapter === 'chapter62' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Authentication and Authorization in Java EE </h1>

    <p>
      In Java EE, security is an important aspect of web applications, and it includes authentication and authorization. Authentication is the process of verifying the identity of a user, while authorization determines what an authenticated user is allowed to do. Java EE provides several built-in mechanisms to implement both of these security aspects in enterprise applications.
    </p><br />

    <h2>Authentication in Java EE</h2>
    <p style={{paddingBottom:"6px"}}>
      Authentication in Java EE can be done using various methods, such as basic authentication, form-based authentication, or using external identity providers. Java EE security primarily relies on the Java Authentication and Authorization Service (JAAS), which integrates with container-managed authentication.
    </p>

    <h3>Container-Managed Authentication</h3>
    <p style={{paddingBottom:"6px"}}>
      In a container-managed authentication model, the application server (such as GlassFish or WildFly) handles user authentication. This means that the server validates the credentials and manages the user's session, reducing the application’s responsibility for authentication.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Steps to Set Up Container-Managed Authentication</h4>
    <ul>
      <li><strong>Define Security Constraints:</strong> Define which parts of the application require authentication and authorization in the <code>web.xml</code> file.</li><br />
      <li><strong>Configure Security Realm:</strong> Configure a security realm in the application server to define users and their roles.</li><br />
      <li><strong>Enable Authentication Mechanism:</strong> Specify the authentication mechanism (e.g., BASIC, FORM) in <code>web.xml</code>.</li>
    </ul><br />

    <pre>
      <code>{`
        <!-- web.xml Example -->
        <security-constraint>
            <web-resource-collection>
                <web-resource-name>Restricted Area</web-resource-name>
                <url-pattern>/secure/*</url-pattern>
            </web-resource-collection>
            <auth-constraint>
                <role-name>admin</role-name>
            </auth-constraint>
        </security-constraint>

        <login-config>
            <auth-method>BASIC</auth-method>
            <realm-name>MyRealm</realm-name>
        </login-config>
       `} </code>
    </pre>

    <h3>Form-Based Authentication</h3>
    <p>
      Form-based authentication allows the user to log in through an HTML form. After providing the credentials, the server validates them and creates a session for the user.
    </p>

    <pre>
      <code>{`
        <!-- web.xml Example for Form-based Authentication -->
        <login-config>
            <auth-method>FORM</auth-method>
            <form-login-page>/login.jsp</form-login-page>
            <form-error-page>/error.jsp</form-error-page>
        </login-config>
       `} </code>
    </pre>

    <h2>Authorization in Java EE</h2>
    <p style={{paddingBottom:"6px"}}>
      Authorization defines what authenticated users are allowed to do. In Java EE, this is typically handled through role-based access control (RBAC). Roles are assigned to users, and access to resources or application functionality is restricted based on the user's roles.
    </p>

    <h3>Role-Based Access Control (RBAC)</h3>
    <p style={{paddingBottom:"6px"}}>
      RBAC is implemented in Java EE using the <code>@RolesAllowed</code> annotation or security constraints in the <code>web.xml</code>. Java EE applications can specify which roles are allowed to access certain resources or invoke specific methods.
    </p>

    <h4>Example: Using the <code>@RolesAllowed</code> Annotation</h4>
    <p>
      The <code>@RolesAllowed</code> annotation can be applied to EJB methods or servlet classes to restrict access to users with certain roles.
    </p>

    <pre>
      <code>{`
        import javax.annotation.security.RolesAllowed;

        @RolesAllowed("admin")
        public class AdminService {
            public void performAdminTask() {
                // Task restricted to admin role
            }
        }
      `}</code>
    </pre>

    <h3>Security Constraints in <code>web.xml</code></h3>
    <p>
      You can define access restrictions to certain URL patterns in the <code>web.xml</code> file. For example, you can restrict access to a particular section of your application to users with the <code>admin</code> role.
    </p>

    <pre>
      <code>{`
        <!-- web.xml Example for Role-based Access Control -->
        <security-constraint>
            <web-resource-collection>
                <web-resource-name>Admin Area</web-resource-name>
                <url-pattern>/admin/*</url-pattern>
            </web-resource-collection>
            <auth-constraint>
                <role-name>admin</role-name>
            </auth-constraint>
        </security-constraint>
      `}</code>
    </pre>

    <h2>Declarative vs Programmatic Security</h2>
    <p>
      In Java EE, security can be managed either declaratively or programmatically. Declarative security involves defining security constraints in configuration files like <code>web.xml</code> and using annotations like <code>@RolesAllowed</code>. Programmatic security involves writing Java code to check a user’s roles and permissions.
    </p>

    <h3>Declarative Security</h3>
    <p>
      Declarative security is typically easier to manage because it is based on configuration files. You can define roles, security constraints, and login mechanisms directly in the <code>web.xml</code> file or use annotations like <code>@RolesAllowed</code> to define method-level security.
    </p>

    <h3>Programmatic Security</h3>
    <p>
      In programmatic security, developers write code to perform authentication and authorization checks manually. The <code>HttpServletRequest</code> object can be used to check the roles of the authenticated user:
    </p>

    <pre>
      <code>{`
        HttpServletRequest request = (HttpServletRequest) servletRequest;
        if (request.isUserInRole("admin")) {
            // Allow access to admin resources
        } else {
            // Deny access
        }
       `} </code>
    </pre>
<br />
    <h2>Conclusion</h2>
    <p>
      Java EE provides powerful tools for managing authentication and authorization. With container-managed authentication, role-based access control, and both declarative and programmatic security options, Java EE applications can securely handle user identities and permissions. Understanding and implementing these security mechanisms is crucial for building secure enterprise applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter63' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Declarative vs. Programmatic Security  </h1>

    <p>
      In Java EE, security can be implemented in two main ways: declarative security and programmatic security. Both approaches aim to protect resources and restrict access to certain users based on roles or permissions. The choice between declarative and programmatic security depends on the use case and the level of control required over the security mechanisms.
    </p><br />

    <h2>Declarative Security</h2>
    <p style={{paddingBottom:"6px"}}>
      Declarative security involves specifying security constraints and configurations in deployment descriptors (such as <code>web.xml</code>) or annotations. It is often easier to manage because the security logic is externalized from the code and is instead handled by the container (e.g., application server like WildFly, GlassFish). In this model, you define security roles, constraints, and policies without writing any explicit security-related Java code.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of Declarative Security</h3>
    <ul>
      <li><strong>External Configuration:</strong> Security settings are declared in configuration files or annotations, and the container enforces them at runtime.</li><br />
      <li><strong>Separation of Concerns:</strong> Security concerns are handled separately from business logic, making the application easier to maintain and more flexible.</li><br />
      <li><strong>Less Code:</strong> It reduces the amount of code required for managing security, as the container takes care of authentication, authorization, and session management.</li>
    </ul><br />

    <h3>How to Implement Declarative Security</h3>
    <p style={{paddingBottom:"6px"}}>
      In declarative security, security constraints are specified in the <code>web.xml</code> file or by using annotations. For example, roles can be defined for different parts of an application and enforced automatically by the container.
    </p>

    <h4>Example: Defining Security Constraints in <code>web.xml</code></h4>
    <pre>
      <code>{`
        <!-- web.xml example for restricting access based on roles -->
        <security-constraint>
            <web-resource-collection>
                <web-resource-name>Admin Section</web-resource-name>
                <url-pattern>/admin/*</url-pattern>
            </web-resource-collection>
            <auth-constraint>
                <role-name>admin</role-name>
            </auth-constraint>
        </security-constraint>

        <login-config>
            <auth-method>FORM</auth-method>
            <form-login-page>/login.jsp</form-login-page>
            <form-error-page>/error.jsp</form-error-page>
        </login-config>
      `}</code>
    </pre>

    <h4>Example: Using Annotations for Method-Level Security</h4>
    <pre>
      <code>{`
        import javax.annotation.security.RolesAllowed;

        @RolesAllowed("admin")
        public class AdminService {
            public void performAdminTask() {
                // Task restricted to the admin role
            }
        }
       `} </code>
    </pre><br />

    <h2>Programmatic Security</h2>
    <p style={{paddingBottom:"6px"}}>
      Programmatic security, on the other hand, involves explicitly writing Java code to handle authentication, authorization, and session management. This gives developers more fine-grained control over security operations, allowing them to customize security behaviors dynamically within the application.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of Programmatic Security</h3>
    <ul>
      <li><strong>More Flexibility:</strong> Provides developers with the ability to customize security logic based on complex business rules and runtime conditions.</li><br />
      <li><strong>Manual Control:</strong> Developers are responsible for handling security operations such as authentication checks, user role verification, and session management.</li><br />
      <li><strong>More Code:</strong> Requires more lines of code and increases complexity compared to declarative security.</li>
    </ul><br />

    <h3>How to Implement Programmatic Security</h3>
    <p style={{paddingBottom:"6px"}}>
      Programmatic security is implemented in the Java code, where developers use APIs to interact with security services. For example, you might use the <code>HttpServletRequest</code> object to check whether a user has a specific role before granting access to a resource.
    </p>

    <h4>Example: Checking User Role Programmatically</h4>
    <pre>
      <code>{`
        HttpServletRequest request = (HttpServletRequest) servletRequest;
        if (request.isUserInRole("admin")) {
            // Allow access to admin resources
        } else {
            // Deny access
        }
       `} </code>
    </pre><br />

    <h4>Example: Programmatically Creating a Session</h4>
    <pre>
      <code>{`
        HttpSession session = request.getSession(true);
        User user = authenticateUser(username, password);
        session.setAttribute("authenticatedUser", user);
      `}</code>
    </pre><br />

    <h2>Declarative vs. Programmatic Security: Comparison</h2>
    <table>
      <thead>
        <tr>
          <th>Aspect</th>
          <th>Declarative Security</th>
          <th>Programmatic Security</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Configuration</td>
          <td>Defined in deployment descriptors (e.g., <code>web.xml</code>) or annotations.</td>
          <td>Defined in the Java code with custom logic.</td>
        </tr>
        <tr>
          <td>Flexibility</td>
          <td>Limited flexibility, suitable for common security scenarios.</td>
          <td>Highly flexible, allowing complex logic and custom rules.</td>
        </tr>
        <tr>
          <td>Complexity</td>
          <td>Lower complexity, easier to implement and maintain.</td>
          <td>Higher complexity, more code to write and maintain.</td>
        </tr>
        <tr>
          <td>Use Cases</td>
          <td>Ideal for standard authentication and role-based access control.</td>
          <td>Best for custom security requirements and fine-grained control.</td>
        </tr>
      </tbody>
    </table><br />

    <h2>Conclusion</h2>
    <p>
      Both declarative and programmatic security have their place in Java EE development. Declarative security is simpler to implement and maintain, making it a good choice for most applications that require standard authentication and authorization. Programmatic security, on the other hand, provides more control and flexibility, which is useful when your security requirements go beyond the capabilities of declarative mechanisms.
    </p>
    <p>
      For most enterprise applications, a combination of both approaches can be used to meet security requirements while minimizing complexity.
    </p>
  </div>
)}



{selectedChapter === 'chapter64' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Role-based Access Control (RBAC)  </h1>
    
    <p>
      Role-based Access Control (RBAC) is an access control mechanism that restricts system access based on the roles assigned to users. In Java EE, RBAC allows you to manage user access to resources or services based on predefined roles, simplifying the process of securing enterprise applications. In this section, we will explore how to implement RBAC in Java EE applications using the built-in security framework.
    </p><br />

    <h2>1. Understanding Role-based Access Control (RBAC)</h2>
    <p>
      In RBAC, access to resources is granted based on the roles that users are assigned to. Each role defines a set of permissions, which allows the user to perform certain actions or access specific resources. The core components of RBAC are:
    </p>
    
    <ul>
      <li><strong>Roles:</strong> Logical representations of job functions within an organization, e.g., "admin", "user", or "manager".</li><br />
      <li><strong>Permissions:</strong> Specific actions a user can perform, such as reading or writing data.</li><br />
      <li><strong>Users:</strong> Individuals or entities who are granted roles and permissions to access system resources.</li>
    </ul><br />

    <h3>1.1. RBAC and Java EE Security</h3>
    <p>
      Java EE provides a robust security model that integrates with the <code>@RolesAllowed</code> annotation, enabling you to define access controls directly on your enterprise beans or servlets. Java EE security services also allow for role-based access control using container-managed security, which is simple to configure and effective.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>2. <strong>Configuring Role-based Access Control in Java EE</strong></h2>

    <h3>2.1. Define Roles in the Application Server</h3>
    <p style={{paddingBottom:"6px"}}>
      First, you need to define the roles in your application server (e.g., WildFly, GlassFish, or Apache TomEE). Roles are often configured in the application server’s security domain or in the <code>web.xml</code> and <code>ejb-jar.xml</code> configuration files.
    </p>

    <h4>Example: Defining Roles in <code>web.xml</code></h4>
    <pre>
      <code>{`
<security-constraint>
  <web-resource-collection>
    <web-resource-name>Admin Page</web-resource-name>
    <url-pattern>/admin/*</url-pattern>
  </web-resource-collection>
  <auth-constraint>
    <role-name>admin</role-name>
  </auth-constraint>
</security-constraint>

<security-role>
  <role-name>admin</role-name>
</security-role>

      `}</code>
    </pre><br />

    <h3>2.2. Using @RolesAllowed Annotation</h3>
    <p style={{paddingBottom:"6px"}}>
      The <code>@RolesAllowed</code> annotation is used to specify the roles allowed to access specific methods in enterprise beans or servlets. You can secure methods or entire classes with this annotation.
    </p>
    
    <h4>Example: Applying @RolesAllowed to a Stateless Bean</h4>
    <pre>
      <code>{`
import javax.annotation.security.RolesAllowed;
import javax.ejb.Stateless;

@Stateless
public class UserService {

    @RolesAllowed("admin")
    public String getAdminData() {
        return "Sensitive Admin Data";
    }

    @RolesAllowed({"admin", "user"})
    public String getUserData() {
        return "General User Data";
    }
}
      `}</code>
    </pre>
    <p>
      In the example above, the method <code>getAdminData()</code> is restricted to users with the "admin" role, while the <code>getUserData()</code> method is accessible by users with either "admin" or "user" roles.
    </p><br />

    <h3>2.3. Role-based Access in Web Applications (Servlets)</h3>
    <p>
      In web applications, you can also control access to servlets and JSP pages based on roles. The <code>web.xml</code> configuration allows you to specify which roles can access specific URLs or servlets.
    </p>

    <h4>Example: Defining Role-based Access in <code>web.xml</code></h4>
    <pre>
      <code>{`
&lt;security-constraint&gt;
  &lt;web-resource-collection&gt;
    &lt;web-resource-name&gt;Admin Page&lt;/web-resource-name&gt;
    &lt;url-pattern&gt;/admin/*&lt;/url-pattern&gt;
  &lt;/web-resource-collection&gt;
  &lt;auth-constraint&gt;
    &lt;role-name&gt;admin&lt;/role-name&gt;
  &lt;/auth-constraint&gt;
&lt;/security-constraint&gt;
        `}  </code>
    </pre>
    <p>
      In this example, only users with the "admin" role will be able to access any resources under the <code>/admin/*</code> URL pattern.
    </p><br />

    <h2>3. Programmatic Role-based Access Control</h2>

    <p style={{paddingBottom:"6px"}}>
      While container-managed security (using annotations and <code>web.xml</code>) is often sufficient for role-based access control, you can also implement RBAC programmatically within your Java EE application using <code>EJBContext</code> and other Java EE APIs.
    </p>

    <h4>Example: Using EJBContext to Check User Roles</h4>
    <pre>
      <code>{`
import javax.ejb.EJBContext;
import javax.annotation.Resource;

public class RoleCheckService {

    @Resource
    private EJBContext ejbContext;

    public void checkUserRole() {
        if (ejbContext.isCallerInRole("admin")) {
            System.out.println("User has admin role.");
        } else {
            System.out.println("User does not have admin role.");
        }
    }
}
      `}</code>
    </pre>
    <p>
      In this example, the <code>isCallerInRole()</code> method checks if the currently authenticated user has the "admin" role.
    </p><br />

    <h2>4. Conclusion</h2>

    <p>
      Role-based Access Control (RBAC) in Java EE provides a flexible and powerful way to manage user access to resources based on roles. By using container-managed security features such as <code>@RolesAllowed</code> and configuring security roles in the <code>web.xml</code> and <code>ejb-jar.xml</code> files, you can easily implement RBAC in your enterprise applications. Whether you're using annotations or programmatic methods, RBAC helps secure your application by ensuring that users can only access the resources they are authorized to.
    </p>
  </div>
)}



{selectedChapter === 'chapter65' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Securing Web Applications with HTTPS</h1>

    <p>
      To secure a web application with HTTPS in Java EE, you need to configure your server to handle secure connections. HTTPS ensures that all data transmitted between the client and the server is encrypted, preventing interception and tampering.
    </p><br />

    <h2>1. Generate or Obtain an SSL Certificate</h2>

    <p>
      To use HTTPS, you need an SSL certificate, which encrypts the data exchanged between the server and the client. You can either purchase an SSL certificate from a trusted Certificate Authority (CA) or generate a self-signed certificate (not recommended for production).
    </p>

    <p>Here is how you can generate a self-signed certificate using Java’s <code>keytool</code>:</p>
    <pre>
      <code>
        keytool -genkey -keyalg RSA -alias tomcat -keystore mykeystore.jks -validity 3650
      </code>
    </pre>
    <ul>
      <li><strong>-keystore mykeystore.jks</strong>: The keystore file to store the certificate.</li><br />
      <li><strong>-validity 3650</strong>: The certificate's validity in days (10 years in this case).</li>
    </ul><br />

    <h2>2. Configure Tomcat (or another servlet container)</h2>

    <p style={{paddingBottom:"6px"}}>
      If you are using Tomcat as your servlet container, you need to modify the <code>server.xml</code> file to enable SSL.
    </p>

    <h3>Step 1: Enable SSL in Tomcat</h3>
    <p>Find the following section in <code>server.xml</code> and uncomment or add the configuration for SSL:</p>
    <pre>
      <code>{`
      <Connector port="8443" protocol="HTTP/1.1" SSLEnabled="true">
    <SSLHostConfig>
        <Certificate certificateKeyFile="conf/keystore/mykeystore.jks"
                     certificateFile="conf/keystore/mycertificate.crt"
                     type="RSA" />
    </SSLHostConfig>
</Connector>

      `}</code>
    </pre>
    <ul>
      <li><strong>port="8443"</strong>: The port used for HTTPS (default for Tomcat is 8443).</li><br />
      <li><strong>certificateKeyFile</strong>: The path to the keystore file containing the private key.</li><br />
      <li><strong>certificateFile</strong>: The path to the certificate file.</li>
    </ul><br />

    <h3>Step 2: Configure HTTP to HTTPS Redirection</h3>

    <p style={{paddingBottom:"6px"}}>
      You can configure Tomcat to redirect HTTP requests to HTTPS by modifying the <code>web.xml</code> or using a <code>Valve</code> in <code>server.xml</code>.
    </p>

    <h4>Option 1: Using a Valve in <code>server.xml</code></h4>
    <pre>
      <code>{`
      <Valve className="org.apache.catalina.valves.RemoteAddrValve"
       allow="127\.\d+\.\d+\.\d+" />
    `}</code>
    </pre>
    <p>This allows only specific IPs (e.g., localhost) to access the application. You can modify the regular expression to match your needs.</p><br />

    <h4>Option 2: Redirect HTTP to HTTPS using a Filter in <code>web.xml</code></h4>
    <pre>
      <code>{`
      <filter>
    <filter-name>https-redirect-filter</filter-name>
    <filter-class>org.apache.catalina.filters.RewriteFilter</filter-class>
</filter>
<filter-mapping>
    <filter-name>https-redirect-filter</filter-name>
    <url-pattern>/secure/*</url-pattern>
</filter-mapping>

       `} </code>
    </pre>
    <p>This configuration uses the <code>RewriteFilter</code> to redirect requests made to <code>/secure/*</code> URLs to HTTPS.</p><br />

    <h2>3. Configure SSL in the Java EE Application</h2>
    <p style={{paddingBottom:"6px"}}>
      In the Java EE application, you can ensure that sensitive data is only transmitted over HTTPS by using constraints in <code>web.xml</code>.
    </p>

    <h4>Example of SSL Constraint in <code>web.xml</code>:</h4>
    <pre>
      <code>{`
      <security-constraint>
    <web-resource-collection>
        <web-resource-name>Secure Content</web-resource-name>
        <url-pattern>/secure/*</url-pattern>
    </web-resource-collection>
    <user-data-constraint>
        <transport-guarantee>CONFIDENTIAL</transport-guarantee>
    </user-data-constraint>
</security-constraint>

      `}</code>
    </pre>
    <ul>
      <li><strong>transport-guarantee="CONFIDENTIAL"</strong>: Ensures that the specified URLs (<code>/secure/*</code>) are accessed over HTTPS only.</li>
    </ul><br />

    <h2>4. Test the HTTPS Configuration</h2>

    <p>
      Once you’ve configured SSL in your server and Java EE application, restart the server and access the application via <code>https://localhost:8443</code> (or whatever domain/port you configured). Ensure that:
    </p>
    <ul>
      <li>The browser shows a padlock symbol indicating a secure connection.</li><br />
      <li>The content is served over HTTPS (check in the browser's developer tools or address bar).</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      Securing your web application with HTTPS in Java EE involves generating an SSL certificate, configuring your servlet container to support HTTPS, enforcing HTTPS access in <code>web.xml</code>, and redirecting HTTP traffic to HTTPS. By following these steps, you ensure secure communication between the client and the server, protecting sensitive data from unauthorized access or tampering.
    </p>
  </div>
)}



{selectedChapter === 'chapter66' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Integrating OAuth and JWT</h1>
    <p>
      OAuth and JWT (JSON Web Tokens) are commonly used together to provide secure and scalable authentication and authorization in modern web applications.
      OAuth is a protocol that allows secure delegated access, while JWT is a compact, URL-safe token format used for securely transmitting information between parties.
      When integrated with Java EE, OAuth can be used to authenticate users and issue JWT tokens, which can be used for subsequent API requests.
    </p><br />

    <h3>1. Understanding OAuth and JWT</h3>
    <ul>
      <li><strong>OAuth 2.0</strong> is an authorization framework that allows third-party applications to grant access to resources on behalf of the resource owner, without sharing credentials. OAuth flows (such as Authorization Code Flow, Implicit Flow, etc.) are used to obtain access tokens.</li><br />
      <li><strong>JWT</strong> is an open standard (RFC 7519) that defines a compact and self-contained method for securely transmitting information between parties as a JSON object. It is often used as an access token in OAuth authentication.</li>
    </ul><br />

    <h3>2. Setting up OAuth in Java EE</h3>
    <p>
      - <strong>OAuth Authorization Server:</strong> You can use OAuth to issue access tokens from an authorization server. In Java EE, you can integrate OAuth with frameworks like <strong>Keycloak</strong>, <strong>Auth0</strong>, or <strong>Okta</strong> to manage authentication and authorization.<br />
      - <strong>OAuth Client:</strong> The Java EE application will act as an OAuth client to request tokens from the authorization server.
    </p>
    <p>Steps for OAuth Integration:</p>
    <ol>
      <li>Configure the OAuth provider (Keycloak, Okta, etc.) for your application.</li><br />
      <li>Implement an OAuth client in your Java EE app to authenticate users and obtain an OAuth token.</li><br />
      <li>Use the <code>Bearer</code> token (JWT) in the <code>Authorization</code> header when making API calls to protected resources.</li>
    </ol>

    <h3>3. Generating and Using JWT in Java EE</h3>
    <p>After obtaining an OAuth token (JWT), you will need to validate the token in your Java EE application to allow access to protected resources.</p>
    <p><strong>Steps for JWT Integration:</strong></p>
    <ul>
      <li><strong>JWT Generation:</strong> The authorization server (OAuth server) issues a JWT upon successful authentication. The JWT typically contains user-related claims, like <code>sub</code> (subject), <code>exp</code> (expiration), and other custom claims.</li><br />
      <li><strong>JWT Validation:</strong> In Java EE, you can use libraries like <strong>JJWT</strong> (Java JWT) or <strong>Nimbus JOSE + JWT</strong> to validate the JWT.</li>
    </ul>

    <pre>
      <code>{`
        import io.jsonwebtoken.Jwts;
        import io.jsonwebtoken.SignatureAlgorithm;
        import io.jsonwebtoken.Claims;

        public class JwtUtil {

            private static final String SECRET_KEY = "your-secret-key";

            public Claims parseJWT(String jwt) {
                return Jwts.parser()
                    .setSigningKey(SECRET_KEY)
                    .parseClaimsJws(jwt)
                    .getBody();
            }

            public boolean validateJWT(String jwt) {
                try {
                    parseJWT(jwt);
                    return true;
                } catch (Exception e) {
                    return false;
                }
            }
        }
     `} </code>
    </pre><br />

    <p><strong>Securing Endpoints Using JWT:</strong> In a Java EE application, you can use a filter or interceptor to check the <code>Authorization</code> header and validate the JWT for every incoming request to protected resources.</p>

    <pre>
      <code>{`
        @Provider
        @PreMatching
        public class JwtAuthFilter implements ContainerRequestFilter {
            @Override
            public void filter(ContainerRequestContext requestContext) throws IOException {
                String jwt = requestContext.getHeaderString(HttpHeaders.AUTHORIZATION);
                if (jwt != null && jwt.startsWith("Bearer ")) {
                    jwt = jwt.substring(7);
                    JwtUtil jwtUtil = new JwtUtil();
                    if (!jwtUtil.validateJWT(jwt)) {
                        throw new WebApplicationException("Invalid JWT token", Response.Status.UNAUTHORIZED);
                    }
                } else {
                    throw new WebApplicationException("Authorization token missing", Response.Status.UNAUTHORIZED);
                }
            }
        }
     `} </code>
    </pre><br />

    <h3>4. Access Control with OAuth and JWT</h3>
    <ul>
      <li><strong>Role-based Authorization:</strong> JWT tokens can contain roles and other claims, which you can use to enforce role-based access control (RBAC). In your Java EE application, you can check the <code>roles</code> claim in the JWT and determine if the user has the appropriate permissions to access a resource.</li>
    </ul>

    <pre>
      <code>{`
        Claims claims = jwtUtil.parseJWT(jwt);
        List<String> roles = claims.get("roles", List.class);
        if (!roles.contains("admin")) {
            throw new WebApplicationException("Access Denied", Response.Status.FORBIDDEN);
        }
      `}</code>
    </pre>

    <h3>5. Java EE and OAuth Framework Integration</h3>
    <p>There are several open-source libraries and frameworks that provide OAuth and JWT support in Java EE applications:</p>
    <ul>
      <li><strong>Keycloak:</strong> A widely used open-source identity and access management solution that integrates with OAuth and JWT. It can act as both the authorization server and the identity provider.</li><br />
      <li><strong>Spring Security:</strong> Although not strictly part of Java EE, Spring Security can be used in a Java EE application to provide OAuth and JWT support.</li><br />
      <li><strong>Apache CXF:</strong> This framework provides support for OAuth and JWT and can be used with Java EE applications to implement security.</li>
    </ul><br />

    <h3>6. Conclusion</h3>
    <p>
      Integrating OAuth and JWT in Java EE applications enhances security by providing secure token-based authentication and authorization. OAuth ensures secure delegated access, while JWT provides a lightweight mechanism to transmit user claims and session information.
    </p>
    <p>
      Java EE supports OAuth and JWT through various libraries and integrations, allowing you to easily authenticate and authorize users, secure API endpoints, and manage roles and permissions within your enterprise application.
    </p>
    <p>By following the steps outlined above, you can secure your Java EE applications with OAuth 2.0 and JWT, ensuring that only authorized users can access sensitive resources.</p>
  </div>
)}


{selectedChapter === 'chapter67' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Integrating OAuth and JWT with JavaMail API</h1>
    
    <p>
      The <strong>JavaMail API</strong> is a powerful framework for sending and receiving emails in Java. Integrating <strong>OAuth 2.0</strong> and <strong>JWT (JSON Web Token)</strong> for authentication in JavaMail allows you to securely authenticate with email services like Gmail, Outlook, or any other email service provider that supports OAuth 2.0.
    </p><br />

    <p>
      OAuth 2.0 is an industry-standard protocol for authorization, while JWT is a compact, URL-safe means of representing claims to be transferred between two parties. In this context, OAuth 2.0 and JWT are used together to authenticate users and authorize access to email accounts.
    </p><br />

    <h3>Overview of OAuth and JWT in JavaMail</h3>
    
    <p>
      To authenticate via OAuth 2.0 in JavaMail, you need to obtain a <strong>token</strong> (in the form of a JWT) that authorizes your application to send emails on behalf of a user. Services like Google and Microsoft support OAuth 2.0 and issue JWTs, which the JavaMail API can use to authenticate and send emails.
    </p>

    <p>
      The typical OAuth flow involves:
      <ol>
        <li>Requesting access tokens using client credentials.</li><br />
        <li>Using the access token to authenticate and send emails through the JavaMail API.</li>
      </ol>
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Steps to Integrate OAuth 2.0 and JWT with JavaMail API</h3>

    <h4>Step 1: Register Your Application with the Email Provider</h4>
    <p>
      Before you can start integrating OAuth with JavaMail, you need to register your application with the email provider (such as Gmail or Outlook). This will give you the <strong>client ID</strong> and <strong>client secret</strong>, which are used to authenticate your application.
    </p>
    
    <p><strong>For Gmail:</strong>
      <ul>
        <li>Go to the Google Developer Console.</li>
        <li>Create a new project.</li>
        <li>Enable the Gmail API.</li>
        <li>Generate credentials (client ID and client secret).</li>
      </ul>
    </p>
    
    <p><strong>For Outlook:</strong>
      <ul>
        <li>Go to the Microsoft Azure portal.</li>
        <li>Register your application.</li>
        <li>Create a client ID and client secret.</li>
      </ul>
    </p><br />

    <h4>Step 2: Obtain OAuth 2.0 Access Token</h4>
    <p>
      OAuth 2.0 requires that your application request an <strong>access token</strong> using the <strong>client credentials</strong> you obtained in Step 1. This can be done using the <strong>OAuth2</strong> authorization code flow or the <strong>client credentials flow</strong> if you are using a service account.
    </p>
    <p>
      Here’s an example of obtaining an access token using the <strong>client credentials flow</strong> (via JWT) for Gmail:
    </p>
    <pre>
      <code>{`
        import java.net.*;
        import java.io.*;
        import javax.net.ssl.*;
        import org.json.JSONObject;

        public class OAuthTokenFetcher {
            public static String getOAuthToken(String clientId, String clientSecret) throws IOException {
                String url = "https://oauth2.googleapis.com/token";
                String body = "client_id=" + clientId + "&client_secret=" + clientSecret + "&grant_type=client_credentials";

                URL obj = new URL(url);
                HttpURLConnection con = (HttpURLConnection) obj.openConnection();
                con.setRequestMethod("POST");
                con.setDoOutput(true);

                OutputStream os = con.getOutputStream();
                byte[] input = body.getBytes("utf-8");
                os.write(input, 0, input.length);

                BufferedReader br = new BufferedReader(new InputStreamReader(con.getInputStream(), "utf-8"));
                StringBuilder response = new StringBuilder();
                String responseLine;
                while ((responseLine = br.readLine()) != null) {
                    response.append(responseLine.trim());
                }

                JSONObject responseJson = new JSONObject(response.toString());
                return responseJson.getString("access_token"); // JWT token
            }
        }
       `} </code>
    </pre>

    <h4>Step 3: Configure JavaMail with OAuth Token</h4>
    <p>
      Now that you have the <strong>access token</strong> (JWT), you can use it to authenticate with the email service when sending emails. JavaMail can use <strong>OAuth 2.0</strong> tokens to authenticate via the <code>javax.mail</code> API.
    </p>
    <p>
      Here’s how you can configure JavaMail to use OAuth with Gmail:
    </p>
    <pre>
      <code>{`
        import javax.mail.*;
        import javax.mail.internet.*;
        import java.util.*;
        import com.sun.mail.smtp.*;

        public class EmailSender {
            public static void sendEmail(String oauthToken) throws Exception {
                Properties props = new Properties();
                props.put("mail.smtp.auth", "true");
                props.put("mail.smtp.host", "smtp.gmail.com");
                props.put("mail.smtp.port", "587");
                props.put("mail.smtp.starttls.enable", "true");

                Session session = Session.getInstance(props, new Authenticator() {
                    @Override
                    protected PasswordAuthentication getPasswordAuthentication() {
                        return new PasswordAuthentication("your-email@gmail.com", oauthToken);
                    }
                });

                Message message = new MimeMessage(session);
                message.setFrom(new InternetAddress("your-email@gmail.com"));
                message.setRecipients(Message.RecipientType.TO, InternetAddress.parse("recipient@example.com"));
                message.setSubject("OAuth Email");
                message.setText("This is an email sent using OAuth 2.0 authentication.");

                Transport.send(message);
            }
        }
       `} </code>
    </pre>

    <h4>Step 4: Sending Email</h4>
    <p>
      You can now use the <code>sendEmail()</code> method to send an email. It will authenticate using the provided OAuth token.
    </p>
    <pre>
      <code>{`
           public class Main {
            public static void main(String[] args) {
                try {
                    // Fetch OAuth token (JWT)
                    String oauthToken = OAuthTokenFetcher.getOAuthToken("your-client-id", "your-client-secret");

                    // Send email using OAuth token
                    EmailSender.sendEmail(oauthToken);
                } catch (Exception e) {
                    e.printStackTrace();
                }
            }
        }
      `}</code>
    </pre>

    <h3 style={{paddingBottom:"6px"}}>Security Considerations</h3>
    <ul>
      <li><strong>Keep OAuth credentials safe:</strong> Always securely store your client ID and secret, and avoid hardcoding them in the source code.</li><br />
      <li><strong>Token expiration:</strong> OAuth tokens typically expire after a period, so you must refresh them before using them again.</li><br />
      <li><strong>JWT validation:</strong> When using JWTs, ensure that you validate the token to confirm that it is issued by the trusted email provider.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Integrating OAuth and JWT with the <strong>JavaMail API</strong> allows you to send emails securely by leveraging modern authentication standards. OAuth 2.0 ensures that your application can access email services like Gmail or Outlook on behalf of the user, while JWT is used as the authentication token for secure communication.
    </p>
  </div>
)}




{selectedChapter === 'chapter68' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to Java Transaction API (JTA)  </h1>

    <p>
      In enterprise applications, managing transactions is crucial for ensuring data consistency and reliability. 
      The Java Transaction API (JTA) is a standard API that provides a way to manage and coordinate transactions across multiple resources, such as databases, messaging systems, and other distributed services.
    </p>

   <br />

    <h3>What is Java Transaction API (JTA)?</h3>

    <p>
      The Java Transaction API (JTA) is a set of interfaces in Java EE that allow developers to manage transactions in a distributed system. JTA helps ensure that operations on multiple resources are executed atomically, consistently, isolated, and durably (ACID). This is crucial for systems that require strong data consistency, such as banking applications, e-commerce platforms, and more.
    </p>

    <p>
      JTA allows for both **local transactions** (single resource) and **global transactions** (multiple resources, such as database and messaging systems). It provides a way to manage complex workflows that may involve multiple services and resources, ensuring that all operations are coordinated.
    </p><br />

    <h3>How Does JTA Work?</h3>

    <p>
      JTA uses two main components to handle transactions:
    </p>

    <ul>
      <li><strong>Transaction Manager:</strong> A component that manages the lifecycle of a transaction, ensuring that operations are properly committed or rolled back.</li><br />
      <li><strong>Transaction:</strong> Represents the actual transaction, which may involve operations on multiple resources.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>JTA Transaction Management Types</h3>
    
    <h4>1. User-Managed Transactions</h4>
    <p>
      In user-managed transactions, the application developer is responsible for explicitly beginning, committing, or rolling back the transaction using the JTA APIs. The application has full control over the transaction lifecycle.
    </p><br />

    <h4>2. Container-Managed Transactions</h4>
    <p>
      In container-managed transactions, the application server or container (like an EJB container) automatically manages the transaction lifecycle. The container begins, commits, or rolls back transactions based on the configuration and annotations used in the application.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of JTA</h3>

    <ul>
      <li><strong>Distributed Transactions:</strong> JTA supports transactions across multiple, distributed resources such as databases, JMS (Java Message Service), and more.</li><br />
      <li><strong>ACID Properties:</strong> JTA ensures that transactions are atomic, consistent, isolated, and durable, maintaining data integrity in distributed systems.</li><br />
      <li><strong>Rollback and Recovery:</strong> JTA supports automatic recovery from failures by rolling back uncommitted transactions to maintain consistency.</li>
    </ul><br />

    <h3>Example of Using JTA</h3>

    <p>
      Below is an example of a simple transaction using JTA in an enterprise application:
    </p>

    <pre>
      <code>{`
        import javax.transaction.UserTransaction;
        import javax.naming.Context;
        import javax.naming.InitialContext;

        public class JtaTransactionExample {
            public void performTransaction() {
                UserTransaction userTransaction = null;

                try {
                    // Lookup the UserTransaction
                    Context context = new InitialContext();
                    userTransaction = (UserTransaction) context.lookup("java:comp/UserTransaction");

                    // Begin transaction
                    userTransaction.begin();

                    // Perform database operations or other resource actions here
                    // Example: Update database or send messages

                    // Commit transaction
                    userTransaction.commit();
                } catch (Exception e) {
                    if (userTransaction != null) {
                        try {
                            userTransaction.rollback();
                        } catch (Exception ex) {
                            ex.printStackTrace();
                        }
                    }
                }
            }
        }
      `}</code>
    </pre><br />

    <h3>Conclusion</h3>

    <p>
      The Java Transaction API (JTA) is essential for managing transactions in enterprise Java applications, particularly in distributed systems where operations span multiple resources. By ensuring that all operations within a transaction are either completed successfully or rolled back in case of failure, JTA helps maintain data consistency and integrity, making it a fundamental part of enterprise Java development.
    </p>

  </div>
)}




{selectedChapter === 'chapter69' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Managing Transactions: Container-managed Transactions and Bean-managed Transactions  </h1>

    <p>
      In Java EE, transactions are a crucial aspect of ensuring consistency, integrity, and isolation of operations in enterprise applications. Java EE provides two approaches to manage transactions: **Container-managed Transactions (CMT)** and **Bean-managed Transactions (BMT)**. This chapter will guide you through both approaches, explaining when to use each and how they work in Java EE.
    </p><br />

    <h3>Container-managed Transactions (CMT)</h3>
    <p style={{paddingBottom:"6px"}}>
      Container-managed Transactions are the default transaction management model in Java EE. With CMT, the application server (container) is responsible for managing transactions, allowing developers to focus more on business logic than on transaction management.
    </p>
    <p><strong> Key Characteristics of CMT:</strong></p>
    <ul>
      <li><strong>Declarative Approach:</strong> CMT uses annotations or deployment descriptors to define transaction boundaries.</li><br />
      <li><strong>Automatic Transaction Handling:</strong> The container handles the transaction lifecycle, including commit and rollback, based on the method's success or failure.</li><br />
      <li><strong>Transaction Propagation:</strong> CMT allows transactions to propagate across multiple enterprise beans within the same method call.</li>
    </ul><br />
    <p>
      To use CMT in Java EE, you can annotate methods with <code>@Transactional</code> or configure transaction attributes in the deployment descriptor (if using EJB).
    </p>
    <pre>
      <code>{`       
        @Stateless
        @TransactionManagement(TransactionManagementType.CONTAINER)
        public class MyService {
          
          @TransactionAttribute(TransactionAttributeType.REQUIRED)
          public void processData() {
            // business logic
          }
        }
      `}</code>
    </pre>
    <p>
      In this example, the container manages the transaction for the <code>processData()</code> method, automatically committing or rolling back the transaction based on whether the method completes successfully.
    </p><br />

    <h3>Bean-managed Transactions (BMT)</h3>
    <p style={{paddingBottom:"6px"}}>
      In contrast to CMT, Bean-managed Transactions (BMT) give developers more control over transaction management. With BMT, the developer is responsible for starting, committing, and rolling back transactions explicitly within the code.
    </p>
    <p><strong>Key Characteristics of BMT:</strong></p>
    <ul>
      <li><strong>Programmatic Approach:</strong> Developers explicitly manage transactions using the <code>UserTransaction</code> API.</li><br />
      <li><strong>Fine-grained Control:</strong> BMT allows for precise control over transaction boundaries, making it suitable for complex scenarios.</li><br />
      <li><strong>Manual Transaction Management:</strong> Developers are responsible for invoking <code>begin()</code>, <code>commit()</code>, and <code>rollback()</code> on the <code>UserTransaction</code> object.</li>
    </ul><br />
    <p>
      To use BMT, you inject the <code>UserTransaction</code> object into your bean and manage the transaction manually:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class MyService {
          
          @Resource
          private UserTransaction userTransaction;

          public void processData() {
            try {
              userTransaction.begin();
              // business logic
              userTransaction.commit();
            } catch (Exception e) {
              userTransaction.rollback();
              // handle exception
            }
          }
        }
       `} </code>
    </pre>
    <p>
      In this example, the developer explicitly starts and commits or rolls back the transaction using the <code>UserTransaction</code> API. This gives you full control over the transaction lifecycle.
    </p><br />

    <h3>Comparing CMT and BMT</h3>
    <table className={style.comparisonTable}>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Container-managed Transactions (CMT)</th>
          <th>Bean-managed Transactions (BMT)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Transaction Management</td>
          <td>Handled by the container</td>
          <td>Handled by the developer</td>
        </tr>
        <tr>
          <td>Control Over Transaction</td>
          <td>Less control (declarative)</td>
          <td>More control (programmatic)</td>
        </tr>
        <tr>
          <td>Complexity</td>
          <td>Lower (easy to implement)</td>
          <td>Higher (requires manual handling)</td>
        </tr>
        <tr>
          <td>Use Case</td>
          <td>Simple to moderate scenarios</td>
          <td>Complex transactions requiring custom logic</td>
        </tr>
      </tbody>
    </table><br />

    <h3>Conclusion</h3>
    <p>
      Choosing between Container-managed Transactions (CMT) and Bean-managed Transactions (BMT) depends on the specific needs of your application. For most applications, CMT is sufficient and easier to implement. However, if you need fine-grained control over transaction boundaries and are working with complex scenarios, BMT is a better fit. Both approaches are supported in Java EE, allowing you to select the most suitable transaction management model based on your requirements.
    </p>
  </div>
)}






{selectedChapter === 'chapter70' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Distributed Transactions using XA  </h1>

    <p>
      In enterprise applications, ensuring consistency and integrity across multiple databases or systems is crucial. Distributed transactions are used to manage transactions across multiple resources (such as databases, message queues, and other transactional systems). XA transactions, a standard for distributed transaction management, allow for the coordination of these transactions across multiple resource managers.
    </p><br />

    <h3>What are Distributed Transactions?</h3>
    <p>
      A distributed transaction involves multiple transaction participants that could be located on different machines or systems. These participants can include databases, message queues, and other transactional services. In Java EE, managing such transactions is essential to ensure that all participants in the transaction either commit or roll back the transaction, maintaining data consistency across systems.
    </p><br />

    <h3>Understanding XA Transactions</h3>
    <p>
      XA (eXtended Architecture) is a standard protocol developed by the Open Group that enables the coordination of transactions across multiple resources. It ensures that a distributed transaction either commits or rolls back entirely (atomicity), even if it spans multiple systems or databases. 
    </p>
    <p>
      XA transactions are supported by Java EE containers through the Java Transaction API (JTA). The container handles the coordination of transactions across multiple XA-compliant resources, such as databases, JMS (Java Message Service), and more. XA transactions are typically used in situations where you need to ensure data consistency across multiple systems, such as when performing operations involving multiple databases or systems.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Concepts of XA Transactions</h3>
    <ul>
      <li><strong>Two-Phase Commit (2PC):</strong> The Two-Phase Commit protocol is used by the XA transaction manager to ensure that a distributed transaction is either fully committed or fully rolled back. In the first phase, all resource managers are asked to prepare for commit. If all resource managers respond positively, the transaction proceeds to commit in the second phase.</li><br />
      <li><strong>XA Resource Managers:</strong> These are the systems or services that support XA transactions. They could be databases, JMS providers, or other systems that implement the XA protocol.</li><br />
      <li><strong>Transaction Manager:</strong> The transaction manager coordinates the distributed transaction, ensuring that the transaction is committed or rolled back across all resource managers.</li>
    </ul><br />

    <h3>Configuring XA Transactions in Java EE</h3>
    <p>
      To enable XA transactions in a Java EE environment, the following steps are typically involved:
    </p>
    <ol>
      <li><strong>XA DataSource Configuration:</strong> You must configure an XA-compliant DataSource in the Java EE application server (e.g., JBoss, GlassFish, WildFly, etc.) to manage connections to the XA-enabled databases.</li><br />
      <li><strong>Enable JTA:</strong> The Java Transaction API (JTA) must be enabled in the Java EE application to allow transaction management across multiple resources.</li><br />
      <li><strong>Define Transactional Boundaries:</strong> In your Java EE beans or services, define transactional boundaries using annotations like <code>@Transactional</code> or use the JTA UserTransaction API for programmatic control.</li>
    </ol><br />

    <h3>Example of Configuring XA Transactions</h3>
    <p>
      Here’s an example of how you can configure an XA DataSource and define a distributed transaction in a Java EE application:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class OrderService {
        
          @Resource
          private UserTransaction userTransaction;
        
          @Resource(lookup = "java:/XADS1")
          private DataSource dataSource1;
        
          @Resource(lookup = "java:/XADS2")
          private DataSource dataSource2;
        
          public void processOrder(Order order) throws Exception {
            try {
              userTransaction.begin();
              
              // Perform operations on the first XA resource
              Connection connection1 = dataSource1.getConnection();
              // Execute SQL on connection1

              // Perform operations on the second XA resource
              Connection connection2 = dataSource2.getConnection();
              // Execute SQL on connection2

              // Commit the transaction
              userTransaction.commit();
            } catch (Exception e) {
              // Rollback in case of failure
              userTransaction.rollback();
              throw e;
            }
          }
        }
       `} </code>
    </pre>
    <p>
      In this example, the <code>OrderService</code> bean uses two XA-compliant DataSources and ensures that both resources participate in the same distributed transaction. If any operation fails, the transaction will be rolled back across both resources, ensuring consistency.
    </p><br />

    <h3>Handling Failures in XA Transactions</h3>
    <p>
      Failure handling is critical in distributed transactions. The Two-Phase Commit protocol ensures that all resources either commit or roll back the transaction. However, failures can still occur at various stages of the transaction. Common issues include:
    </p>
    <ul>
      <li><strong>Communication Failures:</strong> If the transaction manager is unable to communicate with one of the resource managers, the transaction may fail to complete. In such cases, the transaction manager will try to resolve the failure, often by retrying or logging the error for manual intervention.</li><br />
      <li><strong>Resource Manager Failures:</strong> If one of the resource managers crashes during the transaction, the transaction manager will ensure that the other resources roll back to maintain consistency.</li>
    </ul>
    <p>
      It's important to use proper error handling and logging mechanisms to track and resolve transaction issues in distributed environments.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices for Distributed Transactions</h3>
    <ul>
      <li><strong>Keep Transactions Short:</strong> To minimize the risk of failure and performance issues, try to keep distributed transactions as short as possible by minimizing the number of operations involved.</li><br />
      <li><strong>Ensure XA Resource Compatibility:</strong> Make sure that the resource managers involved in the transaction support the XA protocol and can coordinate the transaction properly.</li><br />
      <li><strong>Use Transaction Monitoring:</strong> Monitor distributed transactions to track their progress and identify any failures or bottlenecks.</li><br />
      <li><strong>Plan for Failover:</strong> Implement failover strategies to handle failures in one of the resource managers, ensuring the transaction can continue or be rolled back properly.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Distributed transactions using XA ensure that multiple systems or databases involved in a transaction maintain consistency and integrity. Java EE provides support for XA transactions, enabling the coordination of transactions across multiple resource managers. By understanding XA transactions, setting up the appropriate configurations, and following best practices, you can ensure reliable and consistent transaction management in your enterprise applications.
    </p>
  </div>
)}


 




{selectedChapter === 'chapter71' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Using Java EE Concurrency Utilities   </h1>

    <p>
      Java EE (Enterprise Edition) provides powerful concurrency utilities that allow developers to manage and optimize concurrent processing in multi-threaded environments. The **Java EE Concurrency Utilities** are part of the `javax.enterprise.concurrent` package, introduced to simplify multi-threading and asynchronous task management in Java EE applications.
    </p>

   <br />

    <h3>What are Java EE Concurrency Utilities?</h3>
    <p>
      The Java EE Concurrency Utilities  provide a framework for developing concurrent applications in a Java EE environment. These utilities allow for the management of threads, task execution, and thread safety, abstracting much of the complexity of concurrent programming.
    </p>
    <p>
      These utilities include:
          ManagedExecutorService: Manages the execution of concurrent tasks.
          ManagedScheduledExecutorService: Extends `ManagedExecutorService` to support scheduled tasks.
          ManagedThreadFactory: Creates new threads in a managed environment.
          ConcurrencyAnnotations: Simplifies the configuration and use of concurrency features through annotations.
    </p><br />

    <h3>Key Features of Java EE Concurrency Utilities</h3>
    <ul>
      <li><strong>Managed Executor Service:</strong> Provides a thread pool for executing tasks asynchronously or in parallel.</li><br />
      <li><strong>Scheduled Executor Service:</strong> Allows scheduling tasks to run at fixed-rate or with a fixed delay.</li><br />
      <li><strong>Managed Thread Factory:</strong> Ensures that threads are created within the container’s management context, handling lifecycle, security, and resource management.</li><br />
      <li><strong>Concurrency Annotations:</strong> Simplifies the management of concurrency by enabling developers to define task behavior declaratively via annotations.</li><br />
      <li><strong>Contextual and Container-Managed Execution:</strong> The concurrency utilities ensure that tasks are executed within the scope and lifecycle of the container, ensuring optimal resource management.</li>
    </ul><br />

    <h3>Using ManagedExecutorService</h3>
    <p>
      The ManagedExecutorService is similar to Java's traditional `ExecutorService` but is integrated with the Java EE container for better resource management. It provides thread pool management for executing tasks asynchronously, and it ensures that tasks are executed in the container’s context (including transaction and security contexts).
    </p>
    <p>
      The basic operations include submitting tasks for execution, managing their lifecycle, and obtaining results. Here’s an example of how you can use `ManagedExecutorService`:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class MyService {

            @Resource
            private ManagedExecutorService managedExecutorService;

            public void executeAsyncTask() {
                managedExecutorService.submit(() -> {
                    // Perform some task asynchronously
                    System.out.println("Task executed asynchronously.");
                });
            }
        }
      `}</code>
    </pre>
    <p>
      In the example above, the `submit()` method is used to submit a task for execution. The task will run asynchronously within the container’s managed environment, ensuring optimal resource handling.
    </p><br />

    <h3>Using ManagedScheduledExecutorService</h3>
    <p>
      The ManagedScheduledExecutorService extends the `ManagedExecutorService` to allow for scheduling tasks to be executed at fixed-rate or with a fixed-delay. This is ideal for scenarios such as recurring background jobs or tasks that need to be delayed.
    </p>
    <p>
      Here is an example of using a `ManagedScheduledExecutorService`:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class MyScheduledService {

            @Resource
            private ManagedScheduledExecutorService managedScheduledExecutorService;

            public void scheduleTask() {
                Runnable task = () -> {
                    // Perform a scheduled task
                    System.out.println("Scheduled task executed.");
                };

                // Schedule the task to run every 5 seconds
                managedScheduledExecutorService.scheduleAtFixedRate(task, 0, 5, TimeUnit.SECONDS);
            }
        }
       `} </code>
    </pre>
    <p>
      In the example above, the `scheduleAtFixedRate()` method is used to schedule a task to execute every 5 seconds. This can be useful for tasks like cleaning up resources, sending periodic notifications, or performing background updates.
    </p><br />

    <h3>Using ManagedThreadFactory</h3>
    <p>
      The ManagedThreadFactory is used to create threads that are managed within the Java EE container. Threads created through this factory are automatically registered with the container’s resource management system, ensuring proper handling of thread lifecycle, context propagation, and resource allocation.
    </p>
    <p>
      Here’s an example of using `ManagedThreadFactory`:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class MyThreadService {

            @Resource
            private ManagedThreadFactory managedThreadFactory;

            public void createManagedThread() {
                Runnable task = () -> {
                    // Task to run in a managed thread
                    System.out.println("Task executed in a managed thread.");
                };

                Thread thread = managedThreadFactory.newThread(task);
                thread.start();
            }
        }
       `} </code>
    </pre>
    <p>
      In this example, the `ManagedThreadFactory` is used to create a new thread that is managed by the container. The container handles the lifecycle and ensures that resources are properly allocated and cleaned up after the thread completes.
    </p><br />

    <h3>Concurrency Annotations</h3>
    <p>
      Java EE also provides a set of Concurrency Annotations that simplify the management of concurrency and thread behavior in enterprise beans. These annotations are applied to methods and allow developers to define transaction attributes, execution contexts, and scheduling details declaratively.
    </p>
    <ul>
      <li><strong>@Asynchronous:</strong> Marks a method for asynchronous execution. The method will return immediately, and the task will run in the background.</li><br />
      <li><strong>@Schedule:</strong> Used for scheduling tasks to run at fixed intervals, typically in conjunction with `ManagedScheduledExecutorService`.</li><br />
      <li><strong>@TransactionManagement:</strong> Specifies how transactions are managed in the bean (e.g., container-managed or bean-managed). This annotation is useful in cases where tasks require specific transaction handling.</li>
    </ul><br />
    <p>
      For example, an asynchronous method can be annotated with `@Asynchronous` to indicate that the method should run in the background:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class MyAsyncService {

            @Asynchronous
            public void asyncTask() {
                // Perform task asynchronously
                System.out.println("Async task executed.");
            }
        }
      `}</code>
    </pre>
    <p>
      The method `asyncTask()` will be executed asynchronously when called, allowing the main application flow to continue without waiting for the task to complete.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Advantages of Using Java EE Concurrency Utilities</h3>
    <ul>
      <li><strong>Container Management:</strong> The container automatically handles resource management, ensuring that tasks are executed efficiently and securely.</li><br />
      <li><strong>Simplification of Multi-threading:</strong> The concurrency utilities abstract much of the complexity of multi-threaded programming, allowing developers to focus on business logic rather than thread management.</li><br />
      <li><strong>Scalability:</strong> By using the container-managed resources like thread pools, these utilities provide a scalable solution for handling concurrent tasks in large enterprise applications.</li><br />
      <li><strong>Optimized Resource Usage:</strong> Tasks are executed in a manner that ensures minimal resource contention, better performance, and better integration with other container services like transactions and security.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Java EE Concurrency Utilities provide powerful and flexible tools for managing concurrent tasks in enterprise applications. By utilizing services like `ManagedExecutorService`, `ManagedScheduledExecutorService`, and `ManagedThreadFactory`, developers can ensure efficient and optimized execution of concurrent tasks. Moreover, the use of concurrency annotations simplifies the development of asynchronous and scheduled tasks, making Java EE an excellent platform for building scalable and high-performance enterprise applications.
    </p>
  </div>
)}




{selectedChapter === 'chapter72' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Managing Threads and Tasks in an Enterprise Application  </h1>

    <p>
      Efficient management of threads and tasks is a critical aspect of building scalable and high-performance enterprise applications. Java EE provides the `javax.enterprise.concurrent` package to handle concurrency in a container-managed environment. This ensures optimal resource utilization, simplified development, and seamless integration with enterprise features like transactions and security.
    </p>

  <br />
    <h3>Challenges of Thread Management in Enterprise Applications</h3>
    <p>
      Direct thread management using traditional Java constructs (`Thread`, `ExecutorService`) in enterprise applications can lead to issues such as:
    </p>
    <ul>
      <li><strong>Resource Contention:</strong> Threads may compete for limited resources like CPU and memory.</li><br />
      <li><strong>Lifecycle Management:</strong> Without proper control, threads may remain active, causing memory leaks or resource exhaustion.</li><br />
      <li><strong>Context Loss:</strong> Directly managed threads may not inherit important context data such as security, transaction, or classloader context.</li><br />
      <li><strong>Lack of Scalability:</strong> Poorly managed threads can degrade performance in high-load scenarios.</li>
    </ul><br />
    <p>
      Java EE addresses these challenges through managed concurrency utilities, allowing developers to focus on business logic while the container handles thread lifecycle, security, and resource management.
    </p><br />

    <h3>Java EE Concurrency Utilities Overview</h3>
    <p>
      The Java EE concurrency utilities provide container-integrated APIs for managing threads and tasks. Key components include:
    </p>
    <ul>
      <li><strong>ManagedExecutorService:</strong> Executes tasks asynchronously with container-managed thread pools.</li><br />
      <li><strong>ManagedScheduledExecutorService:</strong> Schedules tasks to run periodically or with a delay.</li><br />
      <li><strong>ManagedThreadFactory:</strong> Creates threads that inherit container context (e.g., security, transactions).</li><br />
      <li><strong>ContextService:</strong> Allows developers to capture and propagate thread context to concurrent tasks.</li>
    </ul><br />

    <h3>ManagedExecutorService</h3>
    <p>
      The ManagedExecutorService provides a managed environment for executing tasks asynchronously. It ensures that threads used for task execution are part of the container’s thread pool, adhering to resource and security policies.
    </p>
    <p>
      Example usage:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class TaskService {

            @Resource
            private ManagedExecutorService executorService;

            public void executeTask() {
                executorService.submit(() -> {
                    System.out.println("Executing a task asynchronously.");
                });
            }
        }
      `}</code>
    </pre>
    <p>
      In this example, the task runs asynchronously in a thread managed by the container, ensuring proper lifecycle and resource management.
    </p><br />

    <h3>ManagedScheduledExecutorService</h3>
    <p>
      The ManagedScheduledExecutorService extends `ManagedExecutorService` to support scheduling tasks to run at specific intervals or after a delay. This is particularly useful for periodic jobs, such as cleaning up resources or sending notifications.
    </p>
    <p>
      Example of scheduling tasks:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class SchedulerService {

            @Resource
            private ManagedScheduledExecutorService scheduledExecutor;

            public void scheduleTask() {
                Runnable task = () -> {
                    System.out.println("Scheduled task executed.");
                };

                scheduledExecutor.scheduleAtFixedRate(task, 0, 10, TimeUnit.SECONDS);
            }
        }
      `}</code>
    </pre>
    <p>
      Here, the task executes every 10 seconds. The container manages thread creation and resource allocation for this recurring task.
    </p><br />

    <h3>ManagedThreadFactory</h3>
    <p>
      The ManagedThreadFactory provides a way to create threads that inherit the container's context. These threads are ideal for custom concurrent operations that require more control over thread behavior.
    </p>
    <p>
      Example usage:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class CustomThreadService {

            @Resource
            private ManagedThreadFactory threadFactory;

            public void createCustomThread() {
                Runnable task = () -> {
                    System.out.println("Executing task in a custom thread.");
                };

                Thread thread = threadFactory.newThread(task);
                thread.start();
            }
        }
       `} </code>
    </pre>
    <p>
      The `ManagedThreadFactory` ensures the thread is part of the container’s managed environment, inheriting context data like security and transaction scope.
    </p><br />

    <h3>ContextService</h3>
    <p>
      The ContextService allows you to capture and propagate the container context (e.g., security, transaction, naming) to concurrent tasks. This ensures that tasks execute with the same context as the initiating thread.
    </p>
    <p>
      Example:
    </p>
    <pre>
      <code>{`
        @Stateless
        public class ContextPropagationService {

            @Resource
            private ContextService contextService;

            public void executeWithContext() {
                Runnable task = contextService.createContextualProxy(() -> {
                    System.out.println("Task executing with container context.");
                }, Runnable.class);

                new Thread(task).start();
            }
        }
      `}</code>
    </pre>
    <p>
      The `createContextualProxy` method wraps the task in a proxy that retains the initiating thread's context.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Benefits of Managed Thread and Task Execution</h3>
    <ul>
      <li><strong>Resource Optimization:</strong> Threads and tasks are executed within a managed thread pool, ensuring efficient resource utilization.</li><br />
      <li><strong>Context Propagation:</strong> Container-managed threads inherit the security, transaction, and naming context of the initiating thread.</li><br />
      <li><strong>Simplified Development:</strong> Developers can focus on business logic while the container handles lifecycle and resource management.</li><br />
      <li><strong>Scalability:</strong> The container dynamically adjusts thread pools to handle varying workloads.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
    <ul>
      <li><strong>Use Managed Utilities:</strong> Always prefer container-managed services (`ManagedExecutorService`, `ManagedThreadFactory`) over direct thread management.</li><br />
      <li><strong>Minimize Task Duration:</strong> Keep tasks short to prevent thread pool starvation and ensure responsiveness.</li><br />
      <li><strong>Handle Exceptions Gracefully:</strong> Always handle exceptions within tasks to avoid disruptions in thread execution.</li><br />
      <li><strong>Avoid Blocking Calls:</strong> Do not perform long-running or blocking operations in managed threads.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Managing threads and tasks in an enterprise application is simplified with Java EE’s concurrency utilities. By using container-managed services like `ManagedExecutorService` and `ManagedThreadFactory`, developers can build scalable, efficient, and secure applications while focusing on business requirements. These utilities abstract the complexity of thread management, enabling reliable and performant enterprise solutions.
    </p>
  </div>
)}




{selectedChapter === 'chapter73' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> ScheduledExecutorService for Task Scheduling </h1>

    <p>
      Scheduling tasks is a common requirement in enterprise applications, such as sending periodic notifications, cleaning up resources, or synchronizing data. In Java EE, the <strong>ScheduledExecutorService</strong> provides a flexible API for scheduling tasks to run at fixed rates, with delays, or at specific intervals.
    </p>

   <br />

    <h3>Overview of ScheduledExecutorService</h3>
    <p>
      The ScheduledExecutorService is part of the `java.util.concurrent` package and extends the `ExecutorService` interface. It enables scheduling tasks with the following options:
    </p>
    <ul>
      <li><strong>Fixed Delay:</strong> Executes a task with a fixed delay between the end of one execution and the start of the next.</li><br />
      <li><strong>Fixed Rate:</strong> Executes a task at a consistent rate, ensuring regular intervals regardless of execution time.</li><br />
      <li><strong>One-time Execution:</strong> Schedules a task to run once after a specified delay.</li>
    </ul><br />

    <h3>Key Methods</h3>
    <p>The `ScheduledExecutorService` provides the following key methods for scheduling tasks:</p>
    <ul>
      <li>
        <code>schedule(Runnable command, long delay, TimeUnit unit)</code>: Schedules a one-time task to run after a specified delay.
      </li><br />
      <li>
        <code>scheduleAtFixedRate(Runnable command, long initialDelay, long period, TimeUnit unit)</code>: Schedules a task to run periodically at a fixed rate.
      </li><br />
      <li>
        <code>scheduleWithFixedDelay(Runnable command, long initialDelay, long delay, TimeUnit unit)</code>: Schedules a task to run periodically with a fixed delay between the end of one execution and the start of the next.
      </li>
    </ul><br />

    <h3>Example: Scheduling a One-Time Task</h3>
    <p>
      The following example demonstrates scheduling a one-time task to execute after a delay of 5 seconds:
    </p>
    <pre>
      <code>{`
        import java.util.concurrent.*;

        public class OneTimeTaskExample {
            public static void main(String[] args) {
                ScheduledExecutorService scheduler = Executors.newScheduledThreadPool(1);

                Runnable task = () -> System.out.println("Task executed after 5 seconds");

                scheduler.schedule(task, 5, TimeUnit.SECONDS);

                scheduler.shutdown();
            }
        }
       `} </code>
    </pre>
    <p>Output:</p>
    <pre>
      Task executed after 5 seconds
    </pre><br />

    <h3>Example: Scheduling a Periodic Task with Fixed Rate</h3>
    <p>
      To schedule a task at a fixed rate, you can use the <code>scheduleAtFixedRate</code> method. The task execution starts at a regular interval, independent of the task's execution time.
    </p>
    <pre>
      <code>{`
        import java.util.concurrent.*;

        public class FixedRateTaskExample {
            public static void main(String[] args) {
                ScheduledExecutorService scheduler = Executors.newScheduledThreadPool(1);

                Runnable task = () -> System.out.println("Task executed at: " + System.currentTimeMillis());

                scheduler.scheduleAtFixedRate(task, 0, 3, TimeUnit.SECONDS);

                // Scheduler will keep running. Use shutdown for controlled termination.
            }
        }
      `}</code>
    </pre>
    <p>Output:</p>
    <pre>
      Task executed at: 1663069200000
      Task executed at: 1663069203000
      Task executed at: 1663069206000
    </pre><br />

    <h3>Example: Scheduling a Periodic Task with Fixed Delay</h3>
    <p>
      The <code>scheduleWithFixedDelay</code> method ensures a fixed delay between the end of one execution and the start of the next:
    </p>
    <pre>
      <code>{`        import java.util.concurrent.*;

        public class FixedDelayTaskExample {
            public static void main(String[] args) {
                ScheduledExecutorService scheduler = Executors.newScheduledThreadPool(1);

                Runnable task = () -> {
                    System.out.println("Task executed at: " + System.currentTimeMillis());
                    try {
                        Thread.sleep(2000); // Simulate a task duration
                    } catch (InterruptedException e) {
                        Thread.currentThread().interrupt();
                    }
                };

                scheduler.scheduleWithFixedDelay(task, 0, 3, TimeUnit.SECONDS);

                // Scheduler will keep running. Use shutdown for controlled termination.
            }
        }
      `}</code>
    </pre>
    <p>Output:</p>
    <pre>
      Task executed at: 1663069200000
      Task executed at: 1663069205000
      Task executed at: 1663069210000
    </pre><br />

    <h3>Considerations for Enterprise Applications</h3>
    <p>
      While the `ScheduledExecutorService` is powerful, it may not integrate seamlessly with Java EE container features like transaction management and security. For enterprise-grade task scheduling, consider using container-managed options such as:
    </p>
    <ul>
      <li><strong>ManagedScheduledExecutorService:</strong> Java EE's managed equivalent of `ScheduledExecutorService`.</li><br />
      <li><strong>TimerService:</strong> A simpler API for EJB-based task scheduling.</li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Best Practices</h3>
    <ul>
      <li><strong>Use Thread Pools:</strong> Always use thread pools (`Executors.newScheduledThreadPool`) to manage resources efficiently.</li><br />
      <li><strong>Handle Exceptions:</strong> Ensure proper exception handling within tasks to prevent thread termination.</li><br />
      <li><strong>Avoid Long Tasks:</strong> Keep tasks short to avoid blocking other scheduled tasks.</li><br />
      <li><strong>Graceful Shutdown:</strong> Use <code>shutdown</code> or <code>shutdownNow</code> to terminate the scheduler gracefully.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      The `ScheduledExecutorService` is a versatile tool for scheduling tasks in Java applications. It supports one-time, fixed-rate, and fixed-delay scheduling with precise control over task execution timing. However, for enterprise applications, leveraging Java EE managed alternatives like `ManagedScheduledExecutorService` can simplify development and ensure better integration with the Java EE environment.
    </p>
  </div>
)}


{selectedChapter === 'chapter74' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to JSON-P </h1>

    <p>
      JSON-P, or the Java API for JSON Processing, is a standard API provided in Java EE for parsing, generating, querying, and manipulating JSON data. JSON (JavaScript Object Notation) is a lightweight data-interchange format widely used in modern web applications for transmitting data between a client and server.
    </p>

   <br />

    <h3>What is JSON-P?</h3>
    <p>
      JSON-P provides two primary APIs for working with JSON data:
    </p>
    <ul>
      <li>
        <strong>Object Model API:</strong> Provides a high-level, in-memory representation of JSON objects and arrays.
      </li><br />
      <li>
        <strong>Streaming API:</strong> Provides a low-level, event-driven way of processing JSON data, suitable for large datasets or resource-constrained environments.
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Features of JSON-P</h3>
    <ul>
      <li>Read JSON data from files, strings, or streams.</li><br />
      <li>Create and write JSON data programmatically.</li><br />
      <li>Support for both Object Model and Streaming approaches.</li><br />
      <li>Query JSON data using JsonPointer and JsonPatch.</li>
    </ul><br />

    <h3>JSON-P Object Model API</h3>
    <p>
      The Object Model API represents JSON data as objects (`JsonObject`) and arrays (`JsonArray`) in memory. This approach is simple and intuitive, especially for small to medium-sized JSON data.
    </p>
    <p><strong>Example: Reading JSON with Object Model API</strong></p>
    <pre>
      <code>{`
        import javax.json.*;
        import java.io.StringReader;

        public class JsonPObjectModelExample {
            public static void main(String[] args) {
                String jsonString = "{ \"name\": \"John\", \"age\": 30 }";

                JsonReader jsonReader = Json.createReader(new StringReader(jsonString));
                JsonObject jsonObject = jsonReader.readObject();
                jsonReader.close();

                System.out.println("Name: " + jsonObject.getString("name"));
                System.out.println("Age: " + jsonObject.getInt("age"));
            }
        }
      `}</code>
    </pre>
    <p>Output:</p>
    <pre>
      Name: John
      Age: 30
    </pre><br />

    <h3>JSON-P Streaming API</h3>
    <p>
      The Streaming API processes JSON data as a stream of events, allowing you to handle large or complex datasets efficiently. Events such as the start or end of objects and arrays, and key-value pairs, are generated during parsing.
    </p>
    <p><strong>Example: Reading JSON with Streaming API</strong></p>
    <pre>
      <code>{`
        import javax.json.stream.*;
        import java.io.StringReader;

        public class JsonPStreamingExample {
            public static void main(String[] args) {
                String jsonString = "{ \"name\": \"Jane\", \"age\": 25 }";

                JsonParser parser = Json.createParser(new StringReader(jsonString));
                while (parser.hasNext()) {
                    JsonParser.Event event = parser.next();
                    switch (event) {
                        case KEY_NAME:
                            System.out.print(parser.getString() + ": ");
                            break;
                        case VALUE_STRING:
                            System.out.println(parser.getString());
                            break;
                        case VALUE_NUMBER:
                            System.out.println(parser.getInt());
                            break;
                        default:
                            break;
                    }
                }
                parser.close();
            }
        }
       `} </code>
    </pre>
    <p>Output:</p>
    <pre>
      name: Jane
      age: 25
    </pre><br />

    <h3>Manipulating JSON Data</h3>
    <p>
      JSON-P supports modifying JSON data through the `JsonObjectBuilder` and `JsonArrayBuilder` classes:
    </p>
    <pre>
      <code>{`
        import javax.json.*;

        public class JsonPManipulationExample {
            public static void main(String[] args) {
                JsonObject jsonObject = Json.createObjectBuilder()
                        .add("name", "Alice")
                        .add("age", 28)
                        .add("isStudent", false)
                        .build();

                System.out.println(jsonObject.toString());
            }
        }
       `} </code>
    </pre>
    <p>Output:</p>
    <pre>{`
      {"name":"Alice","age":28,"isStudent":false}
    `}</pre><br />

    <h3>Advanced Features: JsonPointer and JsonPatch</h3>
    <p>
      JSON-P provides additional features for querying and modifying JSON data:
    </p>
    <ul>
      <li>
        <strong>JsonPointer:</strong> A query language for navigating and extracting values from JSON structures.
      </li><br />
      <li>
        <strong>JsonPatch:</strong> A mechanism for applying partial updates to JSON documents.
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Use Cases</h3>
    <ul>
      <li>Processing API responses in JSON format.</li><br />
      <li>Creating JSON payloads for RESTful services.</li><br />
      <li>Transforming and querying JSON data.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      JSON-P simplifies handling JSON data in Java applications with its intuitive Object Model API and efficient Streaming API. By leveraging JSON-P, developers can parse, manipulate, and generate JSON data seamlessly, making it a critical tool for modern Java EE applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter75' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Parsing JSON Data  </h1>

    <p>
      JSON-P (Java API for JSON Processing) provides powerful and flexible APIs for parsing JSON data. JSON data can be parsed using two approaches: the Object Model API and the Streaming API. These methods allow developers to process JSON data effectively, depending on their application's needs.
    </p>

    <br />

    <h3>Object Model API for Parsing JSON</h3>
    <p>
      The Object Model API parses JSON data into in-memory objects such as `JsonObject` and `JsonArray`. This approach is easy to use and works well for small to medium-sized datasets.
    </p>
    <p>Example: Parsing JSON String</p>
    <pre>
      <code>{`
        import javax.json.*;
        import java.io.StringReader;

        public class ObjectModelParsingExample {
            public static void main(String[] args) {
                String jsonString = "{ \"name\": \"John\", \"age\": 30, \"skills\": [\"Java\", \"JSON\"] }";

                JsonReader reader = Json.createReader(new StringReader(jsonString));
                JsonObject jsonObject = reader.readObject();
                reader.close();

                System.out.println("Name: " + jsonObject.getString("name"));
                System.out.println("Age: " + jsonObject.getInt("age"));
                System.out.println("Skills: " + jsonObject.getJsonArray("skills"));
            }
        }
        `}  </code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre>
      Name: John
      Age: 30
      Skills: ["Java", "JSON"]
    </pre><br />

    <h3>Streaming API for Parsing JSON</h3>
    <p>
      The Streaming API processes JSON data as a sequence of events, making it suitable for large datasets or situations where memory efficiency is critical.
    </p>
    <p>Example: Parsing JSON Stream</p>
    <pre>
      <code>{`
        import javax.json.stream.JsonParser;
        import java.io.StringReader;

        public class StreamingParsingExample {
            public static void main(String[] args) {
                String jsonString = "{ \"name\": \"Jane\", \"age\": 25 }";

                JsonParser parser = Json.createParser(new StringReader(jsonString));

                while (parser.hasNext()) {
                    JsonParser.Event event = parser.next();
                    switch (event) {
                        case KEY_NAME:
                            System.out.print(parser.getString() + ": ");
                            break;
                        case VALUE_STRING:
                            System.out.println(parser.getString());
                            break;
                        case VALUE_NUMBER:
                            System.out.println(parser.getInt());
                            break;
                        default:
                            break;
                    }
                }
                parser.close();
            }
        }
       `} </code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre>
      name: Jane
      age: 25
    </pre><br />

    <h3>Parsing JSON Arrays</h3>
    <p>
      JSON-P supports parsing JSON arrays using both APIs. Here's an example using the Object Model API to parse an array of objects.
    </p>
    <p>Example: Parsing a JSON Array</p>
    <pre>
      <code>{`
        import javax.json.*;
        import java.io.StringReader;

        public class ParsingJsonArrayExample {
            public static void main(String[] args) {
                String jsonArrayString = "[{\"name\":\"Alice\", \"age\":22}, {\"name\":\"Bob\", \"age\":27}]";

                JsonReader reader = Json.createReader(new StringReader(jsonArrayString));
                JsonArray jsonArray = reader.readArray();
                reader.close();

                for (JsonValue value : jsonArray) {
                    JsonObject obj = value.asJsonObject();
                    System.out.println("Name: " + obj.getString("name"));
                    System.out.println("Age: " + obj.getInt("age"));
                }
            }
        }
      `}</code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre>
      Name: Alice
      Age: 22
      Name: Bob
      Age: 27
    </pre><br />

    <h3>Handling Nested JSON Data</h3>
    <p>
      JSON-P allows easy parsing of nested JSON objects and arrays. You can navigate through the hierarchy using `getJsonObject` and `getJsonArray` methods.
    </p>
    <p><strong>Example: Parsing Nested JSON Data</strong></p>
    <pre>
      <code>{`
        import javax.json.*;
        import java.io.StringReader;

        public class NestedJsonParsingExample {
            public static void main(String[] args) {
                String nestedJson = "{ \"person\": { \"name\": \"Eve\", \"address\": { \"city\": \"New York\", \"zip\": 10001 } } }";

                JsonReader reader = Json.createReader(new StringReader(nestedJson));
                JsonObject jsonObject = reader.readObject();
                reader.close();

                JsonObject person = jsonObject.getJsonObject("person");
                JsonObject address = person.getJsonObject("address");

                System.out.println("Name: " + person.getString("name"));
                System.out.println("City: " + address.getString("city"));
                System.out.println("Zip: " + address.getInt("zip"));
            }
        }
       `} </code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre>
      Name: Eve
      City: New York
      Zip: 10001
    </pre><br />

    <h3>Conclusion</h3>
    <p>
      JSON-P provides versatile tools for parsing JSON data in Java. The Object Model API is ideal for straightforward and intuitive parsing, while the Streaming API is well-suited for performance-critical scenarios. By understanding these techniques, you can effectively handle JSON data in your Java applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter76' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Generating JSON Data </h1>

    <p>
      JSON-P (Java API for JSON Processing) provides APIs to generate JSON data programmatically. Using the Object Model API and the Streaming API, you can create JSON objects, arrays, and nested structures efficiently.
    </p>
    <br />

    <h3>Generating JSON Using the Object Model API</h3>
    <p>
      The Object Model API allows developers to construct JSON objects and arrays in memory. It is simple to use and works well for small to medium-sized datasets.
    </p>
    <p><strong>Example: Generating a Simple JSON Object</strong></p>
    <pre>
      <code>{`
import javax.json.*;

public class ObjectModelGenerationExample {
    public static void main(String[] args) {
        JsonObject jsonObject = Json.createObjectBuilder()
            .add("name", "John")
            .add("age", 30)
            .add("skills", Json.createArrayBuilder()
                .add("Java")
                .add("JSON"))
            .build();

        System.out.println(jsonObject);
    }
}
      `}</code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre>
      {`
{
  "name": "John",
  "age": 30,
  "skills": ["Java", "JSON"]
}
      `}
    </pre><br />

    <h3>Generating JSON Using the Streaming API</h3>
    <p>
      The Streaming API generates JSON data as a sequence of events. This approach is memory-efficient and suitable for large-scale data generation.
    </p>
    <p><strong>Example: Generating JSON Using the Streaming API</strong></p>
    <pre>
      <code>{`
import javax.json.stream.JsonGenerator;
import java.io.StringWriter;

public class StreamingGenerationExample {
    public static void main(String[] args) {
        StringWriter writer = new StringWriter();
        JsonGenerator generator = Json.createGenerator(writer);

        generator.writeStartObject()
                .write("name", "Jane")
                .write("age", 25)
                .writeStartArray("skills")
                .write("Java")
                .write("JSON")
                .writeEnd()
                .writeEnd();
        generator.close();

        System.out.println(writer.toString());
    }
}
      `}</code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre>
      {`
{
  "name": "Jane",
  "age": 25,
  "skills": ["Java", "JSON"]
}
      `}
    </pre><br />

    <h3>Creating Nested JSON Structures</h3>
    <p>
      Both APIs support creating complex, nested JSON structures. You can achieve this using nested builders or event sequences.
    </p>
    <p><strong>Example: Generating Nested JSON</strong></p>
    <pre>
      <code>{`
import javax.json.*;

public class NestedJsonGenerationExample {
    public static void main(String[] args) {
        JsonObject jsonObject = Json.createObjectBuilder()
            .add("person", Json.createObjectBuilder()
                .add("name", "Alice")
                .add("address", Json.createObjectBuilder()
                    .add("city", "New York")
                    .add("zip", 10001)))
            .build();

        System.out.println(jsonObject);
    }
}
      `}</code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre>
      {`
{
  "person": {
    "name": "Alice",
    "address": {
      "city": "New York",
      "zip": 10001
    }
  }
}
      `}
    </pre><br />

    <h3>Adding Dynamic Data</h3>
    <p>
      You can dynamically add elements to JSON objects or arrays during runtime based on user inputs or application logic.
    </p>
    <p><strong>Example: Adding Dynamic Data to JSON</strong></p>
    <pre>
      <code>{`
import javax.json.*;

public class DynamicJsonGenerationExample {
    public static void main(String[] args) {
        JsonArrayBuilder arrayBuilder = Json.createArrayBuilder();
        String[] skills = {"Java", "JSON", "REST"};

        for (String skill : skills) {
            arrayBuilder.add(skill);
        }

        JsonObject jsonObject = Json.createObjectBuilder()
            .add("name", "Bob")
            .add("skills", arrayBuilder)
            .build();

        System.out.println(jsonObject);
    }
}
      `}</code>
    </pre>
    <p><strong>Output:</strong></p>
    <pre>
      {`
{
  "name": "Bob",
  "skills": ["Java", "JSON", "REST"]
}
      `}
    </pre>

    <h3>Writing JSON to Files</h3>
    <p>
      JSON-P allows you to write generated JSON data to files or other output streams.
    </p>
    <p><strong>Example: Writing JSON to a File</strong></p>
    <pre>
      <code>{`
import javax.json.*;
import java.io.*;

public class JsonToFileExample {
    public static void main(String[] args) throws IOException {
        JsonObject jsonObject = Json.createObjectBuilder()
            .add("name", "Eve")
            .add("age", 28)
            .build();

        try (FileWriter fileWriter = new FileWriter("output.json");
             JsonWriter jsonWriter = Json.createWriter(fileWriter)) {
            jsonWriter.write(jsonObject);
        }

        System.out.println("JSON written to file: output.json");
    }
}
      `}</code>
    </pre><br />

    <h3>Conclusion</h3>
    <p>
      JSON-P simplifies generating JSON data in Java. The Object Model API provides an intuitive approach for creating JSON structures, while the Streaming API offers efficiency for large datasets. Understanding these techniques enables developers to produce JSON outputs effectively, supporting data interchange in modern applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter77' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to JSON-B</h1>

      <p>
        JSON-B (Java API for JSON Binding) is a standard API introduced in Java EE 8 (now Jakarta EE) for binding Java objects to JSON and vice versa. It simplifies the process of serializing Java objects into JSON format and deserializing JSON data into Java objects, providing a seamless way to work with JSON in Java applications.
      </p><br />

      <h3 style={{paddingBottom:"6px"}}>Key Features of JSON-B</h3>
      <ul>
        <li>
          <strong>Object-JSON Mapping:</strong> JSON-B uses annotations to define how Java objects are mapped to JSON and vice versa.
        </li><br />
        <li>
          <strong>Customization:</strong> Offers customization options through annotations and configuration to control the structure and format of the JSON output.
        </li><br />
        <li>
          <strong>Integration with CDI:</strong> JSON-B integrates seamlessly with CDI (Contexts and Dependency Injection) in Jakarta EE applications.
        </li><br />
        <li>
          <strong>Default and Custom Adapters:</strong> JSON-B allows the use of default serialization rules or custom adapters for specific data types.
        </li>
      </ul>
 <br />
      <h3 style={{paddingBottom:"6px"}}>Basic Usage of JSON-B</h3>

      <h4>1. Converting a Java Object to JSON (Serialization)</h4>
      <pre>
        <code>
{`import jakarta.json.bind.Jsonb;
import jakarta.json.bind.JsonbBuilder;

public class JsonbExample {
    public static void main(String[] args) {
        // Create a sample Java object
        Person person = new Person("John Doe", 30, "Software Developer");

        // Create a Jsonb instance
        Jsonb jsonb = JsonbBuilder.create();

        // Serialize the object to JSON
        String json = jsonb.toJson(person);
        System.out.println("JSON Output: " + json);
    }
}

class Person {
    private String name;
    private int age;
    private String profession;

    public Person(String name, int age, String profession) {
        this.name = name;
        this.age = age;
        this.profession = profession;
    }

    // Getters and setters omitted for brevity
}`}
        </code>
      </pre>

      <p><strong>Output:</strong></p>
      <pre>
        <code>
{`{
  "name": "John Doe",
  "age": 30,
  "profession": "Software Developer"
}`}
        </code>
      </pre><br />

      <h4>2. Converting JSON to a Java Object (Deserialization)</h4>
      <pre>
        <code>
{`import jakarta.json.bind.Jsonb;
import jakarta.json.bind.JsonbBuilder;

public class JsonbDeserializationExample {
    public static void main(String[] args) {
        String json = """
        {
            "name": "Alice",
            "age": 25,
            "profession": "Data Scientist"
        }
        """;

        // Create a Jsonb instance
        Jsonb jsonb = JsonbBuilder.create();

        // Deserialize JSON to a Java object
        Person person = jsonb.fromJson(json, Person.class);
        System.out.println("Person Name: " + person.getName());
        System.out.println("Person Age: " + person.getAge());
        System.out.println("Person Profession: " + person.getProfession());
    }
}`}
        </code>
      </pre>

      <p><strong>Output:</strong></p>
      <pre>
        <code>
{`Person Name: Alice
Person Age: 25
Person Profession: Data Scientist`}
        </code>
      </pre><br />
 
      <h3>Annotations for Customization</h3>
      <p>JSON-B provides annotations to customize the JSON output. Some commonly used annotations include:</p>
      <ul>
        <li><code>@JsonbProperty</code>: Changes the property name in JSON.</li><br />
        <li><code>@JsonbTransient</code>: Excludes a field from JSON processing.</li><br />
        <li><code>@JsonbDateFormat</code>: Customizes date format during serialization and deserialization.</li><br />
        <li><code>@JsonbNillable</code>: Allows null values to be included in the JSON output.</li>
      </ul><br />

      <h3>Example with Annotations</h3>
      <pre>
        <code>
{`import jakarta.json.bind.annotation.JsonbProperty;
import jakarta.json.bind.annotation.JsonbTransient;

public class Person {
    @JsonbProperty("full_name")
    private String name;

    private int age;

    @JsonbTransient
    private String sensitiveData;

    public Person(String name, int age, String sensitiveData) {
        this.name = name;
        this.age = age;
        this.sensitiveData = sensitiveData;
    }

    // Getters and setters omitted for brevity
}`}
        </code>
      </pre>

      <p><strong>Output JSON:</strong></p>
      <pre>
        <code>
{`{
  "full_name": "John Doe",
  "age": 30
}`}
        </code>
      </pre><br />
  
      <h3>Conclusion</h3>
      <p>
        JSON-B simplifies JSON processing in Java by providing intuitive APIs for binding Java objects to JSON and vice versa. Its seamless integration, annotations for customization, and support for modern features make it a powerful tool for working with JSON data in enterprise applications.
      </p>

  </div>
)}



{selectedChapter === 'chapter78' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Mapping Java Objects to JSON   </h1>

  
      <p>
        JSON-B (Java API for JSON Binding) provides a way to map Java objects to JSON and vice versa. This binding process involves converting Java objects into a JSON representation (serialization) and converting JSON data into Java objects (deserialization). Using JSON-B makes it easier to work with JSON data in Java applications.
      </p><br />
   
      <h3>Object-JSON Mapping in JSON-B</h3>
      <p>
        JSON-B uses annotations to define how Java objects should be mapped to JSON and vice versa. The standard annotations provided by JSON-B allow developers to customize the mapping to meet specific application needs.
      </p><br />

      <h3>Basic Mapping with JSON-B</h3>
      <p style={{paddingBottom:"6px"}}>Here is an example of how JSON-B maps a Java object to JSON and vice versa.</p>

      <h4>1. Mapping a Java Object to JSON (Serialization)</h4>
      <pre>
        <code>
{`import jakarta.json.bind.Jsonb;
import jakarta.json.bind.JsonbBuilder;

public class JsonbExample {
    public static void main(String[] args) {
        // Create a sample Java object
        Person person = new Person("Jane Doe", 28, "Software Engineer");

        // Create a Jsonb instance
        Jsonb jsonb = JsonbBuilder.create();

        // Serialize the object to JSON
        String json = jsonb.toJson(person);
        System.out.println("JSON Output: " + json);
    }
}

class Person {
    private String name;
    private int age;
    private String profession;

    public Person(String name, int age, String profession) {
        this.name = name;
        this.age = age;
        this.profession = profession;
    }

    // Getters and setters omitted for brevity
}`}
        </code>
      </pre>

      <p><strong>Output:</strong></p>
      <pre>
        <code>
{`{
  "name": "Jane Doe",
  "age": 28,
  "profession": "Software Engineer"
}`}
        </code>
      </pre><br />

      <h4>2. Mapping JSON to a Java Object (Deserialization)</h4>
      <pre>
        <code>
{`import jakarta.json.bind.Jsonb;
import jakarta.json.bind.JsonbBuilder;

public class JsonbDeserializationExample {
    public static void main(String[] args) {
        String json = """
        {
            "name": "John Smith",
            "age": 35,
            "profession": "Product Manager"
        }
        """;

        // Create a Jsonb instance
        Jsonb jsonb = JsonbBuilder.create();

        // Deserialize JSON to a Java object
        Person person = jsonb.fromJson(json, Person.class);
        System.out.println("Person Name: " + person.getName());
        System.out.println("Person Age: " + person.getAge());
        System.out.println("Person Profession: " + person.getProfession());
    }
}`}
        </code>
      </pre>

      <p><strong>Output:</strong></p>
      <pre>
        <code>
{`Person Name: John Smith
Person Age: 35
Person Profession: Product Manager`}
        </code>
      </pre><br />
 
      <h3>Annotations for Customizing Object-JSON Mapping</h3>
      <p>
        JSON-B provides several annotations to customize how Java objects are mapped to JSON. These annotations allow you to control various aspects of the serialization and deserialization process.
      </p>
      <ul>
        <li><strong>@JsonbProperty</strong>: Renames a field in the Java object to a different name in the JSON.</li><br />
        <li><strong>@JsonbTransient</strong>: Excludes a field from JSON processing.</li><br />
        <li><strong>@JsonbDateFormat</strong>: Specifies a custom date format for serialization and deserialization of date fields.</li><br />
        <li><strong>@JsonbNillable</strong>: Allows null values to be included in the JSON output.</li>
      </ul><br />

      <h3>Example: Customizing Mapping with Annotations</h3>
      <pre>
        <code>
{`import jakarta.json.bind.annotation.JsonbProperty;
import jakarta.json.bind.annotation.JsonbTransient;

public class Person {
    @JsonbProperty("full_name")
    private String name;

    private int age;

    @JsonbTransient
    private String sensitiveData;

    public Person(String name, int age, String sensitiveData) {
        this.name = name;
        this.age = age;
        this.sensitiveData = sensitiveData;
    }

    // Getters and setters omitted for brevity
}`}
        </code>
      </pre>

      <p><strong>Output JSON:</strong></p>
      <pre>
        <code>
{`{
  "full_name": "Jane Doe",
  "age": 28
}`}
        </code>
      </pre><br />
   
      <h3>Conclusion</h3>
      <p>
        JSON-B provides a robust framework for mapping Java objects to JSON and vice versa, using simple annotations for customization. Its ease of use, coupled with powerful features like object-JSON mapping, integration with CDI, and support for custom adapters, makes it an essential tool for working with JSON in Java applications.
      </p>

  </div>
)}

{selectedChapter === 'chapter79' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Mapping JSON to Java Objects </h1>
    <p>
      Here's an example of how you can map JSON data to Java objects using JSON-P (Java API for JSON Processing). 
      Since JSON-P doesn't provide direct binding like JSON-B, we'll manually parse JSON and convert it into Java objects. 
      JSON-P offers two approaches: the <strong>Object Model API</strong> and the <strong>Streaming API</strong>. 
      Below are examples demonstrating how to map JSON to Java objects.
    </p><br />

    <h2>1. Using the Object Model API</h2>
    <p>
      With the Object Model API, you can parse JSON data and manually map it to a Java object using <code>JsonObject</code> 
      and <code>JsonArray</code>. Here's an example:
    </p>
    
    <pre>
      <code>
        {`import javax.json.*;

public class JsonpToJavaExample {
    public static void main(String[] args) {
        // Sample JSON string
        String json = \"""
            {
                "name": "John Doe",
                "age": 30,
                "profession": "Software Developer",
                "skills": ["Java", "JSON", "API"]
            }
        "\";

        // Parse the JSON string into a JsonObject
        JsonObject jsonObject = Json.createReader(new StringReader(json)).readObject();

        // Map JSON data to Java object
        String name = jsonObject.getString("name");
        int age = jsonObject.getInt("age");
        String profession = jsonObject.getString("profession");
        JsonArray skillsArray = jsonObject.getJsonArray("skills");

        // Output the mapped data
        System.out.println("Name: " + name);
        System.out.println("Age: " + age);
        System.out.println("Profession: " + profession);
        System.out.println("Skills: " + skillsArray);
    }
}`}
      </code>
    </pre>
    
    <p><strong>Output:</strong></p>
    <pre>
      <code>
        {`Name: John Doe
        Age: 30
        Profession: Software Developer
        Skills: ["Java","JSON","API"]`}
      </code>
    </pre>
    
    <p>
      In this example, the JSON data is manually parsed and the values are retrieved using methods like <code>getString()</code>, 
      <code>getInt()</code>, and <code>getJsonArray()</code>.
    </p><br />

    <h2>2. Using the Streaming API</h2>
    <p>
      With the Streaming API, you can read JSON data as a stream of events, which is memory efficient for large JSON data. 
      Here's how you can map JSON to Java objects:
    </p>

    <pre>
      <code>
        {`import javax.json.*;
        import javax.json.stream.JsonParser;
        import java.io.StringReader;

        public class JsonpStreamingToJavaExample {
            public static void main(String[] args) {
                // Sample JSON string
                String json = \"""
                    {
                        "name": "Alice",
                        "age": 25,
                        "profession": "Data Scientist"
                    }
                "\";

                // Create a JsonParser for streaming
                JsonParser parser = Json.createParser(new StringReader(json));

                // Variables to hold values
                String name = "";
                int age = 0;
                String profession = "";

                // Parse the JSON stream
                while (parser.hasNext()) {
                    JsonParser.Event event = parser.next();
                    switch (event) {
                        case KEY_NAME:
                            String key = parser.getString();
                            if ("name".equals(key)) {
                                parser.next();
                                name = parser.getString();
                            } else if ("age".equals(key)) {
                                parser.next();
                                age = parser.getInt();
                            } else if ("profession".equals(key)) {
                                parser.next();
                                profession = parser.getString();
                            }
                            break;
                        default:
                            break;
                    }
                }

                // Output the mapped data
                System.out.println("Name: " + name);
                System.out.println("Age: " + age);
                System.out.println("Profession: " + profession);
            }
        }`}
      </code>
    </pre>
    
    <p><strong>Output:</strong></p>
    <pre>
      <code>
        {`Name: Alice
        Age: 25
        Profession: Data Scientist`}
      </code>
    </pre>
    
    <p>
      In this approach, we use <code>JsonParser</code> to process the JSON data as a stream and extract key-value pairs, 
      mapping them to Java variables.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      JSON-P does not provide automatic binding of JSON to Java objects like JSON-B. Instead, you must manually parse JSON data 
      and map it to Java objects using either the Object Model API or the Streaming API. The Object Model API works well for 
      small datasets and provides a simple approach to mapping, while the Streaming API is more suitable for large datasets where 
      memory efficiency is important.
    </p>
  </div>
)}



{selectedChapter === 'chapter80' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Introduction to WebSockets   </h1>

    <p>
      WebSockets in Java EE provide a way to establish a bi-directional, persistent connection between a client (typically a web browser) 
      and a server. This allows for real-time communication, making it ideal for chat applications, live updates, notifications, 
      and other interactive web applications. Java EE supports WebSockets through the <code>javax.websocket</code> package, 
      enabling developers to build WebSocket endpoints for server-side communication.
    </p><br />

    <h2>How WebSockets Work in Java EE</h2>
    <p>
      WebSockets in Java EE start with an HTTP handshake and are then upgraded to a WebSocket connection. Once the connection is established, 
      both the client and the server can send and receive messages without having to re-establish a connection. 
      The <code>javax.websocket</code> package provides APIs for creating WebSocket endpoints and handling messages.
    </p><br />

    <h2>Example: WebSocket Endpoint in Java EE</h2>
    <p>
      Here's a basic example of how to implement a WebSocket endpoint in Java EE:
    </p>

    <pre>
      <code>
        {`import javax.websocket.*;
        import javax.websocket.server.ServerEndpoint;
        import java.io.IOException;

        @ServerEndpoint("/chat")
        public class ChatEndpoint {

            @OnOpen
            public void onOpen(Session session) {
                System.out.println("New connection: " + session.getId());
            }

            @OnMessage
            public void onMessage(String message, Session session) {
                System.out.println("Message received: " + message);
                try {
                    // Send a message to the client
                    session.getBasicRemote().sendText("Hello from server: " + message);
                } catch (IOException e) {
                    e.printStackTrace();
                }
            }

            @OnClose
            public void onClose(Session session) {
                System.out.println("Connection closed: " + session.getId());
            }

            @OnError
            public void onError(Session session, Throwable throwable) {
                System.err.println("Error on connection " + session.getId() + ": " + throwable.getMessage());
            }
        }`}
      </code>
    </pre>

    <p>
      In this example, we define a WebSocket endpoint using the <code>@ServerEndpoint</code> annotation. The endpoint listens for connections 
      at <code>/chat</code> and includes methods to handle connection events:
      <ul>
        <li><code>@OnOpen</code>: Triggered when a new client connects.</li><br />
        <li><code>@OnMessage</code>: Handles incoming messages from the client and sends a response back to the client.</li><br />
        <li><code>@OnClose</code>: Triggered when a connection is closed.</li><br />
        <li><code>@OnError</code>: Handles errors that occur during the WebSocket communication.</li>
      </ul>
    </p><br />

    <h2>Client-Side WebSocket Communication</h2>
    <p>
      To connect to the WebSocket endpoint from the client-side (e.g., a web browser), you can use the WebSocket API in JavaScript:
    </p>

    <pre>
      <code>
        {`// Client-side JavaScript WebSocket connection
        const socket = new WebSocket("ws://localhost:8080/your-app/chat");

        socket.onopen = function(event) {
            console.log("Connected to WebSocket server");
            socket.send("Hello from client!");
        };

        socket.onmessage = function(event) {
            console.log("Message from server: " + event.data);
        };

        socket.onclose = function(event) {
            console.log("WebSocket connection closed");
        };

        socket.onerror = function(error) {
            console.error("WebSocket error: " + error);
        }`}
      </code>
    </pre>

    <p>
      In this example, a WebSocket connection is established to the Java EE WebSocket endpoint at <code>/chat</code> 
      using the JavaScript WebSocket API. The client sends a message when the connection opens and logs any incoming messages from the server.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Use Cases for WebSockets in Java EE</h2>
    <ul>
      <li><strong>Real-time Communication:</strong> Chat applications, messaging systems.</li><br />
      <li><strong>Live Updates:</strong> Stock tickers, sports scores, news feeds.</li><br />
      <li><strong>Collaborative Applications:</strong> Multi-user editing, real-time data visualization.</li><br />
      <li><strong>Gaming:</strong> Online multiplayer games requiring real-time interaction.</li>
    </ul><br />

    <h2>Conclusion</h2>
    <p>
      WebSockets in Java EE offer a powerful way to build real-time applications that require efficient, bi-directional communication. 
      With the <code>javax.websocket</code> package, Java EE simplifies the process of handling WebSocket connections and messages, 
      allowing developers to focus on building interactive features in their web applications. By leveraging WebSockets, 
      developers can create applications with live data and real-time interactivity, greatly enhancing user experience.
    </p>
  </div>
)}





{selectedChapter === 'chapter81' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Building Real-time Applications Using WebSocket API   </h1>

    <p>
      WebSockets in Java EE are ideal for building real-time applications, as they provide full-duplex communication between a client (e.g., a web browser) and a server. 
      With WebSockets, data can flow in both directions without the need to repeatedly open and close connections, making it perfect for applications that require frequent updates or 
      real-time interactions.
    </p><br />

    <h2>Setting Up a WebSocket Server in Java EE</h2>
    <p style={{paddingBottom:"6px"}}>
      In Java EE, the WebSocket API is provided by the <code>javax.websocket</code> package. To set up a WebSocket server, you will create an endpoint 
      that listens for incoming WebSocket connections. This WebSocket endpoint will manage communication between the client and the server.
    </p>

    <h3>Basic WebSocket Endpoint Example</h3>
    <p>
      Below is a simple WebSocket endpoint that handles a basic chat application:
    </p>

    <pre>
      <code>
        {`import javax.websocket.*;
        import javax.websocket.server.ServerEndpoint;
        import java.io.IOException;

        @ServerEndpoint("/chat")
        public class ChatEndpoint {

            @OnOpen
            public void onOpen(Session session) {
                System.out.println("New connection: " + session.getId());
            }

            @OnMessage
            public void onMessage(String message, Session session) {
                System.out.println("Message received: " + message);
                try {
                    // Send a message back to the client
                    session.getBasicRemote().sendText("Server response: " + message);
                } catch (IOException e) {
                    e.printStackTrace();
                }
            }

            @OnClose
            public void onClose(Session session) {
                System.out.println("Connection closed: " + session.getId());
            }

            @OnError
            public void onError(Session session, Throwable throwable) {
                System.err.println("Error on connection " + session.getId() + ": " + throwable.getMessage());
            }
        }`}
      </code>
    </pre><br />

    <p>
      In this example, we define a WebSocket endpoint using the <code>@ServerEndpoint</code> annotation. The endpoint listens at <code>/chat</code> and handles 
      the following events:
      <ul>
        <li><code>@OnOpen</code>: Triggered when a new client connects.</li><br />
        <li><code>@OnMessage</code>: Handles incoming messages and sends a response back to the client.</li><br />
        <li><code>@OnClose</code>: Triggered when the connection is closed.</li><br />
        <li><code>@OnError</code>: Handles any errors that occur during communication.</li>
      </ul>
    </p><br />

    <h2>Creating Real-time Applications</h2>
    <p>
      WebSockets can be used to build various types of real-time applications such as chat applications, live notifications, collaborative tools, and live data feeds. 
      Let’s explore a few examples of how WebSockets can be implemented in real-time applications.
    </p>

    <h3>Example 1: Chat Application</h3>
    <p>
      A real-time chat application can be easily built using WebSockets. Here's how the communication flows:
      <ul>
        <li>The client sends a message to the server via the WebSocket connection.</li><br />
        <li>The server broadcasts the message to all connected clients.</li><br />
        <li>All clients display the message in real-time without refreshing the page.</li>
      </ul><br />
      By using the WebSocket API, you can keep the application lightweight and responsive while avoiding the overhead of polling or long-polling techniques.
    </p><br />

    <h3>Example 2: Live Notification System</h3>
    <p>
      Another popular use of WebSockets is building a live notification system. Here's how you can use WebSockets for real-time notifications:
      <ul>
        <li>The server pushes notifications to the client whenever a new event or message occurs.</li><br />
        <li>The client receives the notification in real-time, updating the UI accordingly (e.g., showing a popup or updating a notification badge).</li>
      </ul>
    </p><br />

    <h3>Example 3: Collaborative Application (Real-time Updates)</h3>
    <p>
      WebSockets can also be used to build collaborative applications, such as multi-user document editing or real-time data visualization. 
      For example, in a collaborative document editor:
      <ul>
        <li>Multiple clients can connect to the WebSocket server.</li><br />
        <li>Changes made by one user are broadcasted to all other connected users in real-time.</li><br />
        <li>Each user’s interface is updated instantly, reflecting the changes made by others.</li>
      </ul>
      This approach makes the app highly interactive and provides a seamless experience for users working simultaneously on shared data.
    </p><br />

    <h2>Client-Side JavaScript for WebSocket Communication</h2>
    <p>
      The WebSocket client API in JavaScript allows for easy communication with the WebSocket server. Here is an example of a basic client-side WebSocket connection:
    </p>

    <pre>
      <code>
        {`// Client-side JavaScript for WebSocket communication
        const socket = new WebSocket("ws://localhost:8080/your-app/chat");

        socket.onopen = function(event) {
            console.log("Connected to the WebSocket server.");
            socket.send("Hello, server!");
        };

        socket.onmessage = function(event) {
            console.log("Message from server: " + event.data);
        };

        socket.onclose = function(event) {
            console.log("WebSocket connection closed.");
        };

        socket.onerror = function(error) {
            console.error("WebSocket error: " + error);
        }`}
      </code>
    </pre>

    <p>
      This simple JavaScript code establishes a WebSocket connection with the server, sends a message when the connection is opened, 
      and listens for messages from the server. It’s essential to handle errors, connection closure, and the on-message event to build a robust WebSocket client.
    </p><br />

    <h2>Handling Multiple Clients and Broadcasting Messages</h2>
    <p>
      In real-time applications, you often need to handle multiple clients connected to the WebSocket server simultaneously. One common pattern is broadcasting 
      messages to all connected clients. Here’s how you can implement broadcasting on the server side:
    </p>

    <pre>
      <code>
        {`import javax.websocket.*;
        import javax.websocket.server.ServerEndpoint;
        import java.io.IOException;
        import java.util.HashSet;
        import java.util.Set;

        @ServerEndpoint("/chat")
        public class ChatEndpoint {

            private static Set<Session> clients = new HashSet<>();

            @OnOpen
            public void onOpen(Session session) {
                clients.add(session);
                System.out.println("New connection: " + session.getId());
            }

            @OnMessage
            public void onMessage(String message, Session session) {
                System.out.println("Message received: " + message);
                // Broadcast the message to all connected clients
                for (Session client : clients) {
                    try {
                        client.getBasicRemote().sendText("Message: " + message);
                    } catch (IOException e) {
                        e.printStackTrace();
                    }
                }
            }

            @OnClose
            public void onClose(Session session) {
                clients.remove(session);
                System.out.println("Connection closed: " + session.getId());
            }

            @OnError
            public void onError(Session session, Throwable throwable) {
                System.err.println("Error on connection " + session.getId() + ": " + throwable.getMessage());
            }
        }`}
      </code>
    </pre>

    <p>
      This implementation allows you to manage multiple clients by storing their sessions in a <code>Set</code> and broadcasting any incoming messages to all connected clients.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      WebSockets are an essential tool for building real-time applications in Java EE. By providing a lightweight, persistent connection between clients and 
      servers, WebSockets enable efficient, two-way communication. Whether you're building a real-time chat application, live notifications, or collaborative apps, 
      Java EE's WebSocket API offers a powerful framework for developing interactive, scalable, and responsive applications. 
    </p>
  </div>
)}



{selectedChapter === 'chapter82' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> WebSocket Client and Server Programming </h1>

    <p>
      WebSocket is a protocol that enables two-way, full-duplex communication channels over a single, long-lived connection. It is particularly well-suited for real-time web applications like chat applications, live notifications, and gaming apps. In this section, we will explore both client-side and server-side WebSocket programming to understand how to implement a WebSocket-based solution.
    </p><br />

    <h2>WebSocket Server Programming</h2>
    <p style={{paddingBottom:"6px"}}>
      The server-side component of WebSocket programming handles incoming WebSocket connections, processes messages from clients, and sends messages back. In Java, WebSocket server programming can be achieved using the <code>javax.websocket</code> API, which is available in Java EE and Java SE environments. Let's look at an example of creating a basic WebSocket server.
    </p>

    <h3>Basic WebSocket Server Example</h3>
    <p>
      Here's a simple WebSocket server written in Java, which listens for connections from clients and echoes back any messages it receives:
    </p>

    <pre>
      <code>
        {`import javax.websocket.*;
        import javax.websocket.server.ServerEndpoint;
        import java.io.IOException;

        @ServerEndpoint("/chat")
        public class WebSocketServer {

            @OnOpen
            public void onOpen(Session session) {
                System.out.println("New connection: " + session.getId());
            }

            @OnMessage
            public void onMessage(String message, Session session) {
                System.out.println("Message received: " + message);
                try {
                    session.getBasicRemote().sendText("Echo: " + message);
                } catch (IOException e) {
                    e.printStackTrace();
                }
            }

            @OnClose
            public void onClose(Session session) {
                System.out.println("Connection closed: " + session.getId());
            }

            @OnError
            public void onError(Session session, Throwable throwable) {
                System.err.println("Error on connection " + session.getId() + ": " + throwable.getMessage());
            }
        }`}
      </code>
    </pre>

    <p>
      The above example creates a WebSocket server using the <code>@ServerEndpoint</code> annotation. The server listens on the <code>/chat</code> path and handles:
      <ul>
        <li><code>@OnOpen</code>: Triggered when a new connection is established.</li><br />
        <li><code>@OnMessage</code>: Processes incoming messages from clients and sends a response back.</li><br />
        <li><code>@OnClose</code>: Executed when a connection is closed.</li><br />
        <li><code>@OnError</code>: Catches errors that occur during communication.</li>
      </ul>
    </p><br />

    <h2>WebSocket Client Programming</h2>
    <p style={{paddingBottom:"6px"}}>
      The WebSocket client establishes a connection to a WebSocket server, sends messages, and receives responses. The client-side API for WebSocket in JavaScript is available in all modern browsers. Here’s an example of how you can write a WebSocket client in JavaScript.
    </p>

    <h3>Basic WebSocket Client Example</h3>
    <p>
      Below is a simple JavaScript WebSocket client that connects to the WebSocket server we just created, sends a message, and logs the response:
    </p>

    <pre>
      <code>
        {`// Client-side JavaScript for WebSocket communication
        const socket = new WebSocket("ws://localhost:8080/chat");

        socket.onopen = function(event) {
            console.log("Connected to WebSocket server.");
            socket.send("Hello, server!");
        };

        socket.onmessage = function(event) {
            console.log("Message from server: " + event.data);
        };

        socket.onclose = function(event) {
            console.log("WebSocket connection closed.");
        };

        socket.onerror = function(error) {
            console.error("WebSocket error: " + error);
        }`}
      </code>
    </pre>

    <p>
      The JavaScript WebSocket client works as follows:
      <ul>
        <li><code>new WebSocket(url)</code>: Creates a new WebSocket connection to the server.</li><br />
        <li><code>onopen</code>: Event handler for when the connection is successfully established.</li><br />
        <li><code>onmessage</code>: Event handler that processes messages received from the server.</li><br />
        <li><code>onclose</code>: Event handler triggered when the WebSocket connection is closed.</li><br />
        <li><code>onerror</code>: Event handler that captures any errors that occur.</li>
      </ul>
    </p><br />

    <h2>WebSocket Connection Flow</h2>
    <p>
      The WebSocket connection lifecycle follows a few key steps:
      <ol>
        <li>Client initiates the WebSocket handshake by connecting to a WebSocket server using a <code>ws://</code> or <code>wss://</code> URL.</li><br />
        <li>The server acknowledges the handshake and the WebSocket connection is established.</li><br />
        <li>Both the client and server can send messages to each other at any time over the open connection.</li><br />
        <li>The connection remains open, allowing for continuous communication, unless one party closes the connection.</li>
      </ol>
    </p><br />

    <h2>Advanced WebSocket Features</h2>
    <p>
      While the basic WebSocket protocol is simple, there are several advanced features that can enhance real-time applications:
      <ul>
        <li><strong>Broadcasting:</strong> A server can broadcast messages to multiple connected clients.</li><br />
        <li><strong>Handling Binary Data:</strong> WebSockets can handle binary data (e.g., images or files) in addition to text.</li><br />
        <li><strong>Connection Management:</strong> WebSocket servers often need to manage multiple connections, handle reconnections, and scale efficiently.</li><br />
        <li><strong>Security:</strong> Using WebSocket Secure (WSS) ensures that the communication is encrypted and secure.</li>
      </ul>
    </p><br />

    <h3>Broadcasting to All Connected Clients</h3>
    <p>
      The server can maintain a list of connected clients and broadcast a message to all clients. Here’s how to do this in a WebSocket server:
    </p>

    <pre>
      <code>
        {`import javax.websocket.*;
        import javax.websocket.server.ServerEndpoint;
        import java.io.IOException;
        import java.util.HashSet;
        import java.util.Set;

        @ServerEndpoint("/chat")
        public class WebSocketServer {

            private static Set<Session> clients = new HashSet<>();

            @OnOpen
            public void onOpen(Session session) {
                clients.add(session);
                System.out.println("New connection: " + session.getId());
            }

            @OnMessage
            public void onMessage(String message, Session session) {
                System.out.println("Message received: " + message);
                // Broadcast the message to all connected clients
                for (Session client : clients) {
                    try {
                        client.getBasicRemote().sendText("Message from " + session.getId() + ": " + message);
                    } catch (IOException e) {
                        e.printStackTrace();
                    }
                }
            }

            @OnClose
            public void onClose(Session session) {
                clients.remove(session);
                System.out.println("Connection closed: " + session.getId());
            }

            @OnError
            public void onError(Session session, Throwable throwable) {
                System.err.println("Error on connection " + session.getId() + ": " + throwable.getMessage());
            }
        }`}
      </code>
    </pre>

    <p>
      In the above example, we store all client sessions in a <code>Set</code> and use a loop to send the incoming message to all connected clients.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      WebSocket programming is a powerful way to enable real-time communication between a client and a server. The WebSocket API allows for easy implementation of both 
      client and server components, which can be used to build applications like live chat, notifications, collaborative tools, and more. By understanding both client-side 
      and server-side programming with WebSocket, you can create dynamic, interactive applications that provide users with instant updates and responses.
    </p>
  </div>
)}





{selectedChapter === 'chapter83' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Batch API  </h1>

    <p>
      Batch processing refers to the execution of a series of jobs or tasks in a batch, typically without user intervention. In Java, the Batch API (JSR 352) provides a standard approach for implementing batch processing in Java applications. It enables developers to process large volumes of data efficiently, in a scalable and transactional way. 
    </p><br />

    <h2>What is JSR 352?</h2>
    <p>
      JSR 352, also known as the Batch Applications for the Java Platform, is a specification that defines a standard for batch processing in Java. It is part of the Java EE ecosystem (now Jakarta EE) and provides a set of annotations, interfaces, and runtime mechanisms to help developers implement batch jobs.
    </p><br />

    <h3 style={{paddingBottom:"6px"}}>Key Features of JSR 352</h3>
    <ul>
      <li><strong>Job Processing:</strong> JSR 352 defines the structure and flow of batch jobs, which can be broken down into steps and executed in a sequence.</li><br />
      <li><strong>Chunk Processing:</strong> A chunk-oriented processing model allows large datasets to be processed in smaller chunks, improving performance and memory management.</li><br />
      <li><strong>Transaction Management:</strong> The API supports transaction management, ensuring that batch jobs can be committed or rolled back atomically.</li><br />
      <li><strong>Parallel Processing:</strong> JSR 352 enables parallel job execution, which can be useful for high-volume data processing tasks.</li><br />
      <li><strong>Retry and Restart:</strong> The Batch API provides mechanisms to handle job failures and allow for retries or restarts without losing progress.</li>
    </ul><br />

    <h2>Core Concepts of the Batch API</h2>
    <p style={{paddingBottom:"6px"}}>
      To understand how batch jobs are executed using JSR 352, we need to look at some of the key concepts that are fundamental to the API:
    </p>

    <h3>1. Job</h3>
    <p>
      A <strong>job</strong> represents the overall batch process, and it is composed of one or more <strong>steps</strong>. A job is defined using an XML configuration or programmatically, and it can be executed using a job launcher.
    </p><br />

    <h3>2. Step</h3>
    <p>
      A <strong>step</strong> is a single unit of work within a job. Each step is typically defined to perform a specific task, such as reading data from a file, processing it, or writing the processed data to a database. A job consists of one or more steps executed sequentially or in parallel.
    </p><br />

    <h3>3. Chunk-Oriented Processing</h3>
    <p>
      Chunk-oriented processing is a key part of JSR 352. It allows large amounts of data to be processed in smaller chunks. For example, you can read a large dataset, process it in chunks (e.g., 100 records at a time), and write the results in chunks. This approach improves memory usage and helps to manage large datasets efficiently.
    </p><br />

    <h3>4. ItemReader, ItemProcessor, and ItemWriter</h3>
    <p>
      JSR 352 uses the <strong>ItemReader</strong>, <strong>ItemProcessor</strong>, and <strong>ItemWriter</strong> interfaces to define the batch processing flow:
      <ul>
        <li><strong>ItemReader:</strong> Reads data from a source (e.g., file, database).</li><br />
        <li><strong>ItemProcessor:</strong> Processes the data (e.g., filtering, transforming, validating).</li><br />
        <li><strong>ItemWriter:</strong> Writes the processed data to a destination (e.g., file, database).</li>
      </ul>
      These interfaces allow for flexibility in how data is handled during batch processing.
    </p><br />

    <h2>Basic Batch Job Example</h2>
    <p>
      Below is a simple example that demonstrates a basic batch job with JSR 352. This job will read a list of data, process it, and write the result to a destination.
    </p>

    <pre>
      <code>
        {`import javax.batch.api.chunk.ItemProcessor;
        import javax.batch.api.chunk.ItemReader;
        import javax.batch.api.chunk.ItemWriter;
        import javax.batch.runtime.context.JobContext;
        import javax.inject.Inject;

        public class SimpleBatchJob {

            @Inject
            private JobContext jobContext;

            public void runJob() {
                // Define the job flow
                Job job = jobContext.getJob();
                job.addStep(new Step("readStep", new ItemReader(), new ItemProcessor(), new ItemWriter()));
            }
        }
      `}
      </code>
    </pre><br />

    <h2>Batch Job Execution Lifecycle</h2>
    <p>
      The execution of a batch job follows a specific lifecycle:
      <ol>
        <li><strong>Job Initialization:</strong> The job is initialized with configuration settings, including the steps and their execution order.</li><br />
        <li><strong>Step Execution:</strong> Each step is executed sequentially (or in parallel), with data being processed in chunks.</li><br />
        <li><strong>Completion:</strong> Once all steps have been executed, the job is completed, and the results are stored in the specified destination.</li><br />
        <li><strong>Error Handling:</strong> If a step fails, the job can be retried or restarted, depending on the configuration.</li>
      </ol>
    </p><br />

    <h2>Using JSR 352 in Java EE</h2>
    <p style={{paddingBottom:"6px"}}>
      JSR 352 can be used within Java EE (Jakarta EE) applications to implement batch processing. Typically, the Batch API is integrated into a Java EE container, such as WildFly or Payara, and batch jobs are defined in XML or annotated classes. A batch job can be invoked using the <code>BatchRuntime</code> API or using a <code>JobOperator</code>.
    </p>

    <h3>Job Configuration</h3>
    <p>
      Batch jobs in JSR 352 can be configured using an XML file, which defines the structure and behavior of the job. Here is an example of a batch job configuration file (XML):
    </p>

    <pre>
      <code>
        {`<?xml version="1.0" encoding="UTF-8"?>
        <job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <step id="readStep">
                <chunk item-count="100">
                    <reader ref="ItemReader"/>
                    <processor ref="ItemProcessor"/>
                    <writer ref="ItemWriter"/>
                </chunk>
            </step>
        </job>`}
      </code>
    </pre>

    <p>
      The above XML file defines a batch job with a single step that processes data in chunks of 100 items. The <code>reader</code>, <code>processor</code>, and <code>writer</code> are references to custom implementations of the respective interfaces.
    </p><br />

    <h2>Conclusion</h2>
    <p>
      The JSR 352 Batch API provides a robust, flexible framework for implementing batch processing in Java applications. It offers features like chunk processing, transaction management, and error handling, making it ideal for handling large-scale, long-running, and high-volume jobs. By understanding the key components and workflows, developers can efficiently implement batch jobs for data-intensive applications.
    </p>
  </div>
)}



{selectedChapter === 'chapter84' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Creating Batch Jobs </h1>

    <p>
      In JSR 352 (Batch Applications for the Java Platform), creating batch jobs involves defining jobs with one or more steps, where each step consists of chunk-oriented processing (reading, processing, and writing data). This chapter will walk you through the process of creating batch jobs using the Batch API, including defining jobs, steps, and implementing custom readers, processors, and writers.
    </p><br />

    <h2>What is a Batch Job?</h2>
    <p style={{paddingBottom:"6px"}}>
      A batch job is the highest-level unit of work in JSR 352. It is composed of one or more steps, each performing a specific task. The job runs sequentially or in parallel, and data is processed in chunks for efficient handling of large datasets. 
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Components of a Batch Job</h3>
    <ul>
      <li><strong>Job:</strong> The overall container for the batch processing logic, consisting of multiple steps.</li><br />
      <li><strong>Step:</strong> A single task within the job, such as reading data, processing data, or writing results. A job can contain one or more steps.</li><br />
      <li><strong>ItemReader:</strong> Responsible for reading data from a source (e.g., database, file).</li><br />
      <li><strong>ItemProcessor:</strong> Processes the data read by the reader (e.g., filtering, transforming).</li><br />
      <li><strong>ItemWriter:</strong> Writes the processed data to a destination (e.g., database, file).</li>
    </ul><br />

    <h2>Steps to Create a Batch Job</h2>
    <p style={{paddingBottom:"6px"}}>
      There are several key steps to creating a batch job with JSR 352. Let's break down the process:
    </p>

    <h3>Step 1: Define the Job Configuration</h3>
    <p>
      A batch job in JSR 352 is defined using either an XML configuration or programmatically. In this step, we will focus on defining the job configuration in XML. You can define the flow of the job, including steps, chunk processing, and references to the reader, processor, and writer classes.
    </p>

    <pre>
      <code>
        {`<?xml version="1.0" encoding="UTF-8"?>
        <job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <step id="processStep">
                <chunk item-count="100">
                    <reader ref="ItemReader"/>
                    <processor ref="ItemProcessor"/>
                    <writer ref="ItemWriter"/>
                </chunk>
            </step>
        </job>`}
      </code>
    </pre><br />

    <h3>Step 2: Implement ItemReader, ItemProcessor, and ItemWriter</h3>
    <p style={{paddingBottom:"6px"}}>
      In JSR 352, the job’s data flow is defined through the interfaces <code>ItemReader</code>, <code>ItemProcessor</code>, and <code>ItemWriter</code>. Below are examples of how these can be implemented:
    </p>

    <h4>ItemReader Implementation</h4>
    <pre>
      <code>
        {`import javax.batch.api.chunk.ItemReader;

        public class CustomItemReader implements ItemReader {
            @Override
            public Object readItem() throws Exception {
                // Logic to read data from source (e.g., file, database)
                return data;
            }
        }`}
      </code>
    </pre><br />

    <h4>ItemProcessor Implementation</h4>
    <pre>
      <code>
        {`import javax.batch.api.chunk.ItemProcessor;

        public class CustomItemProcessor implements ItemProcessor {
            @Override
            public Object processItem(Object item) throws Exception {
                // Logic to process the item (e.g., transformation, validation)
                return processedItem;
            }
        }`}
      </code>
    </pre><br />

    <h4>ItemWriter Implementation</h4>
    <pre>
      <code>
        {`import javax.batch.api.chunk.ItemWriter;
        
        public class CustomItemWriter implements ItemWriter {
            @Override
            public void writeItems(List<Object> items) throws Exception {
                // Logic to write items to destination (e.g., file, database)
            }
        }`}
      </code>
    </pre><br />

    <h3>Step 3: Register the Job and Execute</h3>
    <p>
      After implementing the job configuration and the necessary reader, processor, and writer, the next step is to register the job in the runtime and execute it using the <code>JobOperator</code>.
    </p>

    <pre>
      <code>
        {`import javax.batch.runtime.BatchRuntime;
        import javax.batch.runtime.JobOperator;
        import javax.batch.runtime.BatchStatus;

        public class BatchJobExecutor {
            public void executeJob() {
                JobOperator jobOperator = BatchRuntime.getJobOperator();
                long executionId = jobOperator.start("batchJob", null); // Start the job
                BatchStatus status = jobOperator.getJobExecution(executionId).getBatchStatus();
                System.out.println("Job status: " + status);
            }
        }`}
      </code>
    </pre><br />

    <h3>Step 4: Handle Job Execution Results</h3>
    <p style={{paddingBottom:"6px"}}>
      Once the batch job has been executed, you can check the status of the job execution (e.g., success, failure). JSR 352 provides mechanisms to handle job failures, retries, and restarts.
    </p>

    <h4>Job Completion and Restart</h4>
    <p>
      After the job completes, it is important to check for the success or failure of each step. If a step fails, you can configure the job to retry the step or restart it from the last successful point.
    </p><br />

    <h2>Example: Complete Batch Job</h2>
    <p>
      Below is a complete example of a batch job that reads data from a source, processes it, and writes the results to a destination.
    </p>

    <pre>
      <code>
        {`<?xml version="1.0" encoding="UTF-8"?>
        <job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <step id="readAndProcessStep">
                <chunk item-count="10">
                    <reader ref="CustomItemReader"/>
                    <processor ref="CustomItemProcessor"/>
                    <writer ref="CustomItemWriter"/>
                </chunk>
            </step>
        </job>`}
      </code>
    </pre><br />

    <h3>Step Execution Flow</h3>
    <p>
      When executed, the job will:
      <ol>
        <li>Read data in chunks of 10 items using <code>CustomItemReader</code>.</li><br />
        <li>Process each chunk using <code>CustomItemProcessor</code> to transform or filter the data.</li><br />
        <li>Write the processed data using <code>CustomItemWriter</code>.</li>
      </ol>
    </p><br />

    <h2>Parallel Processing and Scaling</h2>
    <p>
      JSR 352 supports parallel processing to scale the batch jobs across multiple threads. You can configure jobs to run in parallel using the <code>split</code> element in XML, which allows you to define multiple execution paths within a single job.
    </p>

    <pre>
      <code>
        {`<job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <split>
                <flow>
                    <step id="step1">
                        <chunk item-count="100">
                            <reader ref="ItemReader"/>
                            <processor ref="ItemProcessor"/>
                            <writer ref="ItemWriter"/>
                        </chunk>
                    </step>
                </flow>
                <flow>
                    <step id="step2">
                        <chunk item-count="100">
                            <reader ref="AnotherReader"/>
                            <processor ref="AnotherProcessor"/>
                            <writer ref="AnotherWriter"/>
                        </chunk>
                    </step>
                </flow>
            </split>
        </job>`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Creating batch jobs using JSR 352 is an efficient way to process large datasets in a scalable and transactional manner. By defining jobs with multiple steps, chunk-oriented processing, and parallel execution, you can manage complex workflows. Custom implementations of readers, processors, and writers allow for flexibility in how the data is handled, making JSR 352 a powerful tool for batch processing in Java applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter85' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Partitioning and Parallel Processing   </h1>
    <p>
      JSR 352 (Batch Applications for the Java Platform) supports partitioning and parallel processing to improve the performance and scalability of batch jobs. These features allow jobs to be split into multiple partitions or threads, enabling concurrent processing of large datasets. In this chapter, we will explore how to configure and use partitioning and parallel processing in JSR 352 batch jobs.
    </p><br />

    <h2>What is Partitioning in JSR 352?</h2>
    <p style={{paddingBottom:"6px"}}>
      Partitioning allows you to divide a batch job into smaller, independent chunks (partitions) that can be processed concurrently. Each partition operates on a distinct subset of the data, which can significantly speed up processing for large datasets. Partitioning is particularly useful when dealing with tasks that can be divided into multiple independent parts.
    </p>

    <h3>Configuring Partitioned Batch Jobs</h3>
    <p>
      In JSR 352, you can define partitioned jobs in XML using the <code>partition</code> element inside a <code>split</code> tag. This allows you to define the number of partitions to be created, along with the logic for processing each partition.
    </p>

    <pre>
      <code>
        {`<job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <split>
                <partition name="partition1" count="5">
                    <step id="step1">
                        <chunk item-count="100">
                            <reader ref="ItemReader"/>
                            <processor ref="ItemProcessor"/>
                            <writer ref="ItemWriter"/>
                        </chunk>
                    </step>
                </partition>
            </split>
        </job>`}
      </code>
    </pre>

    <h4 style={{paddingBottom:"6px"}}>Partition Configuration</h4>
    <ul>
      <li><strong>partition:</strong> Defines a partition within the split. It specifies how many partitions to create using the <code>count</code> attribute.</li><br />
      <li><strong>count:</strong> Specifies the number of partitions to create. This number determines how many threads or processes can run in parallel.</li><br />
      <li><strong>step:</strong> Specifies the steps that will be executed within each partition.</li>
    </ul><br />

    <h3>Partitioned Step Processing</h3>
    <p>
      Each partition executes a step independently, allowing data to be processed in parallel. JSR 352 allows you to implement a custom partitioned reader, processor, and writer to tailor how data is read, processed, and written for each partition.
    </p>

    <pre>
      <code>
        {`import javax.batch.api.chunk.ItemReader;

        public class PartitionItemReader implements ItemReader {
            @Override
            public Object readItem() throws Exception {
                // Logic to read partitioned data
                return partitionedData;
            }
        }`}
      </code>
    </pre><br />

    <h2>What is Parallel Processing in JSR 352?</h2>
    <p style={{paddingBottom:"6px"}}>
      Parallel processing enables multiple threads or processes to work simultaneously on different parts of a job. This helps speed up the execution of batch jobs, especially when the tasks can be divided into smaller, independent units of work.
    </p>

    <h3>Configuring Parallel Batch Jobs</h3>
    <p>
      To enable parallel processing, you define multiple steps or flows that run concurrently. In JSR 352, parallel processing is configured using the <code>split</code> element in the job configuration. Each flow within the split can execute independently, allowing for parallel execution of different parts of the job.
    </p>

    <pre>
      <code>
        {`<job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <split>
                <flow>
                    <step id="step1">
                        <chunk item-count="100">
                            <reader ref="ItemReader1"/>
                            <processor ref="ItemProcessor1"/>
                            <writer ref="ItemWriter1"/>
                        </chunk>
                    </step>
                </flow>
                <flow>
                    <step id="step2">
                        <chunk item-count="100">
                            <reader ref="ItemReader2"/>
                            <processor ref="ItemProcessor2"/>
                            <writer ref="ItemWriter2"/>
                        </chunk>
                    </step>
                </flow>
            </split>
        </job>`}
      </code>
    </pre>

    <h4 style={{paddingBottom:"6px"}}>Parallel Configuration</h4>
    <ul>
      <li><strong>split:</strong> Defines the parallel processing block, where multiple flows can be executed simultaneously.</li><br />
      <li><strong>flow:</strong> Each flow contains one or more steps that can be executed concurrently.</li><br />
      <li><strong>step:</strong> Specifies a step that will be executed in parallel with others within the split.</li>
    </ul><br />

    <h3>Handling Parallel Execution Results</h3>
    <p>
      When running jobs in parallel, each flow or step may complete at different times. You can handle job completion, failure, and retries by configuring appropriate job listeners and failure strategies. JSR 352 provides mechanisms to manage the execution state of each step and ensure that all parallel tasks complete successfully.
    </p>

    <pre>
      <code>
        {`import javax.batch.api.listener.JobListener;

        public class JobCompletionListener implements JobListener {
            @Override
            public void afterJob() {
                // Logic to handle job completion after all parallel flows complete
            }

            @Override
            public void beforeJob() {
                // Logic to handle actions before job starts
            }
        }`}
      </code>
    </pre><br />

    <h2>Scaling Batch Jobs with Partitioning and Parallel Processing</h2>
    <p style={{paddingBottom:"6px"}}>
      Partitioning and parallel processing are powerful techniques for scaling batch jobs, especially when working with large datasets. By splitting the data into partitions and executing them in parallel, you can significantly reduce the time required to complete batch processing tasks.
    </p>

    <h3>Combining Partitioning and Parallel Processing</h3>
    <p>
      JSR 352 allows you to combine partitioning and parallel processing. This means you can have multiple partitions, each of which can be processed in parallel. This combination offers even greater scalability and performance for large-scale batch processing jobs.
    </p>

    <pre>
      <code>
        {`<job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <split>
                <partition name="partition1" count="5">
                    <step id="step1">
                        <chunk item-count="100">
                            <reader ref="ItemReader"/>
                            <processor ref="ItemProcessor"/>
                            <writer ref="ItemWriter"/>
                        </chunk>
                    </step>
                </partition>
                <partition name="partition2" count="5">
                    <step id="step2">
                        <chunk item-count="100">
                            <reader ref="AnotherReader"/>
                            <processor ref="AnotherProcessor"/>
                            <writer ref="AnotherWriter"/>
                        </chunk>
                    </step>
                </partition>
            </split>
        </job>`}
      </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Partitioning and parallel processing in JSR 352 provide powerful tools for optimizing batch jobs. By dividing the workload into smaller partitions and executing them concurrently, you can dramatically improve performance and scalability. These techniques are essential for handling large datasets efficiently, ensuring that batch jobs can be processed in a timely manner. When combined, partitioning and parallel processing allow for highly scalable and performant batch processing in Java applications.
    </p>
  </div>
)}





{selectedChapter === 'chapter86' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Exception Handling in Batch Processing </h1>

    <p>
      Exception handling is a critical aspect of batch processing. In JSR 352 (Batch Applications for the Java Platform), the framework provides mechanisms to handle exceptions that might occur during job execution. This chapter explores how to manage exceptions, implement retry mechanisms, and define failure strategies to ensure that batch jobs are resilient, robust, and can recover from errors effectively.
    </p><br />

    <h2>Understanding Exception Handling in JSR 352</h2>
    <p style={{paddingBottom:"6px"}}>
      Batch jobs often involve processing large amounts of data and performing multiple tasks in a sequence. During this process, errors can occur due to issues such as data corruption, database connectivity problems, or business logic failures. Proper exception handling ensures that the batch job can continue to process data or fail gracefully, providing insight into the issues and possibly allowing recovery mechanisms.
    </p>

    <h3>Exception Handling in Step Processing</h3>
    <p style={{paddingBottom:"6px"}}>
      In JSR 352, the processing of each step in a batch job can throw exceptions. The framework provides several mechanisms to handle these exceptions, such as defining step-specific error handling, retry policies, and skip strategies.
    </p>

    <h4>StepExceptionListener</h4>
    <p>
      A <code>StepExceptionListener</code> is a callback interface that allows you to handle exceptions at the step level. By implementing this listener, you can define custom logic for handling exceptions that occur during step execution. For example, you can log the exception, retry the operation, or skip the item that caused the exception.
    </p>

    <pre>
      <code>
        {`import javax.batch.api.listener.StepListener;

        public class StepExceptionListener implements StepListener {
            @Override
            public void beforeStep() {
                // Logic before step execution
            }

            @Override
            public void afterStep() {
                // Logic after step execution
            }

            @Override
            public void onError(Exception exception) {
                // Handle exception during step execution
                System.err.println("Exception in step: " + exception.getMessage());
            }
        }`}
      </code>
    </pre><br />

    <h4>Handling Exceptions in Item Processing</h4>
    <p style={{paddingBottom:"6px"}}>
      Exceptions may also occur during item processing in a chunk-based step (i.e., during the read, process, or write phases). JSR 352 allows you to define strategies for handling exceptions at the item level, such as skipping the problematic item or retrying the processing of the item.
    </p><br />

    <h3>Retry Mechanism</h3>
    <p style={{paddingBottom:"6px"}}>
      A retry mechanism allows you to attempt to reprocess a failing item a specified number of times before considering it as failed. The retry count can be configured to retry an item a set number of times, increasing resilience to transient issues like network or database connectivity failures.
    </p>

    <h4>Configuring Retry in JSR 352</h4>
    <p>
      In the batch job XML configuration, you can specify the retry count and the delay between retries for failed items. You can also define a custom retry policy by implementing the <code>RetryPolicy</code> interface.
    </p>

    <pre>
      <code>
        {`<job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <step id="processStep">
                <chunk item-count="10" retry-limit="3" retry-delay="5000">
                    <reader ref="ItemReader"/>
                    <processor ref="ItemProcessor"/>
                    <writer ref="ItemWriter"/>
                </chunk>
            </step>
        </job>`}
      </code>
    </pre><br />

    <h3>Skip Mechanism</h3>
    <p style={{paddingBottom:"6px"}}>
      The skip mechanism allows items that fail during processing to be skipped rather than causing the entire job to fail. This is useful for scenarios where a single erroneous item should not halt the entire batch job. You can specify which exceptions should trigger a skip and how many times an item can be skipped before the job fails.
    </p>

    <h4>Configuring Skip in JSR 352</h4>
    <p>
      In the batch job XML configuration, you can define the exceptions that should be skipped and set a skip limit for how many items can be skipped before the job fails.
    </p>

    <pre>
      <code>
        {`<job xmlns="http://java.sun.com/xml/ns/javaee" version="1.0">
            <step id="processStep">
                <chunk item-count="10" skip-limit="5">
                    <reader ref="ItemReader"/>
                    <processor ref="ItemProcessor"/>
                    <writer ref="ItemWriter"/>
                    <skip-when>
                        <exception-class>java.io.IOException</exception-class>
                    </skip-when>
                </chunk>
            </step>
        </job>`}
      </code>
    </pre><br />

    <h3>Job Exception Handling</h3>
    <p style={{paddingBottom:"6px"}}> 
      JSR 352 also provides a mechanism to handle exceptions at the job level. If a job encounters a failure that cannot be recovered by retrying or skipping items, the job can fail gracefully. You can configure job-level listeners to handle errors that occur during job execution, including logging, alerting, or performing cleanup operations.
    </p>

    <h4>JobListener Interface</h4>
    <p>
      The <code>JobListener</code> interface allows you to implement custom behavior before and after job execution. You can define the <code>onError</code> method to handle exceptions that occur during job execution.
    </p>

    <pre>
      <code>
        {`import javax.batch.api.listener.JobListener;

        public class JobExceptionListener implements JobListener {
            @Override
            public void beforeJob() {
                // Logic before job starts
            }

            @Override
            public void afterJob() {
                // Logic after job completes
            }

            @Override
            public void onError(Exception exception) {
                // Handle error during job execution
                System.err.println("Job failed with error: " + exception.getMessage());
            }
        }`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Exception Handling in Batch Jobs</h2>
    <ul>
      <li><strong>Define Clear Retry and Skip Policies:</strong> Set sensible retry limits and skip limits to avoid excessive retries or skipped items that could impact data integrity.</li><br />
      <li><strong>Log All Exceptions:</strong> Ensure that all exceptions are logged for better traceability and debugging of batch job failures.</li><br />
      <li><strong>Use Custom Exception Handlers:</strong> Implement custom exception handlers to deal with specific error conditions, such as database connectivity failures or invalid data formats.</li><br />
      <li><strong>Monitor Job Execution:</strong> Continuously monitor job execution to identify recurring issues and address them proactively.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Exception handling is a vital aspect of batch processing to ensure that jobs run smoothly, even when encountering errors. JSR 352 provides powerful mechanisms for retrying, skipping, and handling errors at the step and job levels. By configuring appropriate exception handling strategies, such as retry limits, skip policies, and custom listeners, you can build robust and fault-tolerant batch applications.
    </p>
  </div>
)}




{selectedChapter === 'chapter87' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Unit Testing with JUnit and TestNG  </h1>

    <p>
      Unit testing is a crucial part of the software development lifecycle, particularly in Java EE applications, where business logic, persistence layers, and web services require thorough testing. In this chapter, we'll explore how to use two popular testing frameworks — JUnit and TestNG — to effectively test Java EE applications, ensuring code quality and reliability.
    </p><br />

    <h2>Understanding Unit Testing in Java EE</h2>
    <p>
      Unit testing focuses on verifying the correctness of individual units of code (methods or classes). In Java EE, this often means testing components such as EJBs (Enterprise JavaBeans), servlets, managed beans, and other business logic.
    </p>
    <p>
      JUnit and TestNG are the most widely used testing frameworks for Java applications. Both frameworks are compatible with Java EE applications and provide various features that help in creating effective unit tests.
    </p><br />

    <h3>JUnit Overview</h3>
    <p style={{paddingBottom:"6px"}}>
      JUnit is the most widely used unit testing framework in the Java ecosystem. It provides annotations, assertions, and utilities to write and execute tests efficiently. JUnit 5, the latest version of JUnit, introduces a new programming model and several features that improve the usability and flexibility of the testing process.
    </p>

    <h4>Setting Up JUnit for Java EE</h4>
    <p>
      To get started with JUnit in a Java EE application, you can add JUnit 5 as a dependency in your Maven or Gradle project. Once JUnit is set up, you can start writing tests for your Java EE components.
    </p>

    <pre>
      <code>
        {`<dependency>
            <groupId>org.junit.jupiter</groupId>
            <artifactId>junit-jupiter-api</artifactId>
            <version>5.7.0</version>
            <scope>test</scope>
        </dependency>`}
      </code>
    </pre><br />

    <h4>Example JUnit Test</h4>
    <p>
      Here's an example of a simple JUnit test for a Java EE service class. The test verifies that a method in the service class correctly calculates the total price of an order.
    </p>

    <pre>
      <code>
        {`import org.junit.jupiter.api.Test;
        import static org.junit.jupiter.api.Assertions.assertEquals;

        public class OrderServiceTest {

            @Test
            public void testCalculateTotalPrice() {
                OrderService orderService = new OrderService();
                double result = orderService.calculateTotalPrice(5, 10.0);
                assertEquals(50.0, result, "The total price should be 50.0");
            }
        }`}
      </code>
    </pre><br />

    <h3>TestNG Overview</h3>
    <p style={{paddingBottom:"6px"}}>
      TestNG is another powerful testing framework for Java, offering a more flexible and feature-rich testing experience compared to JUnit. It supports advanced testing features like parallel test execution, parameterized tests, and a wide range of configuration options.
    </p>

    <h4>Setting Up TestNG for Java EE</h4>
    <p>
      To set up TestNG in a Java EE application, add TestNG as a dependency in your Maven or Gradle project. Once set up, you can begin writing tests using the TestNG annotations and features.
    </p>

    <pre>
      <code>
        {`<dependency>
            <groupId>org.testng</groupId>
            <artifactId>testng</artifactId>
            <version>7.4.0</version>
            <scope>test</scope>
        </dependency>`}
      </code>
    </pre><br />

    <h4>Example TestNG Test</h4>
    <p>
      Here's an example of a simple TestNG test for a Java EE component. This test checks whether a method in the business logic layer performs as expected.
    </p>

    <pre>
      <code>
        {`import org.testng.annotations.Test;
        import static org.testng.Assert.assertEquals;

        public class OrderServiceTest {

            @Test
            public void testCalculateTotalPrice() {
                OrderService orderService = new OrderService();
                double result = orderService.calculateTotalPrice(5, 10.0);
                assertEquals(result, 50.0, "The total price should be 50.0");
            }
        }`}
      </code>
    </pre><br />

    <h2>Mocking Java EE Components</h2>
    <p>
      In Java EE applications, components like EJBs, JPA entities, and JMS queues are often tightly coupled with the container. Mocking these components is essential for unit testing because it allows you to isolate the unit being tested from the rest of the application.
    </p>
    <p style={{paddingBottom:"6px"}}>
      Both JUnit and TestNG integrate well with mocking frameworks such as <code>Mockito</code> and <code>EasyMock</code>, which can be used to mock Java EE components.
    </p>

    <h3>Example of Mocking with Mockito</h3>
    <p>
      Here's an example of how to use Mockito to mock an EJB in a unit test, simulating the interaction between a service class and a repository.
    </p>

    <pre>
      <code>
        {`import org.junit.jupiter.api.Test;
        import static org.mockito.Mockito.*;
        import static org.junit.jupiter.api.Assertions.*;

        public class OrderServiceTest {

            @Test
            public void testCalculateTotalPriceWithMocking() {
                OrderRepository mockRepo = mock(OrderRepository.class);
                when(mockRepo.getItemPrice(1)).thenReturn(10.0);
                
                OrderService orderService = new OrderService(mockRepo);
                double result = orderService.calculateTotalPrice(5, 1);
                assertEquals(50.0, result, "The total price should be 50.0");
            }
        }`}
      </code>
    </pre><br />

    <h2>Testing Java EE Components in Isolation</h2>
    <p>
      For Java EE components like EJBs or managed beans, testing in isolation is critical. While unit tests focus on testing individual components, Java EE components often interact with the container, making it harder to test in isolation.
    </p>
    <p style={{paddingBottom:"6px"}}>
      Using technologies like <code>Arquillian</code> can help you test Java EE components in an embedded container, enabling you to perform integration tests while still isolating the components.
    </p>

    <h3>Arquillian for Java EE Testing</h3>
    <p>
      Arquillian provides a testing platform for Java EE applications that can deploy and manage your Java EE components in an embedded container. It supports both unit and integration testing, enabling you to test your application as it would run in the container.
    </p>

    <pre>
      <code>
        {`<dependency>
            <groupId>org.jboss.arquillian.junit</groupId>
            <artifactId>arquillian-junit-container</artifactId>
            <version>1.7.0.Final</version>
            <scope>test</scope>
        </dependency>`}
      </code>
    </pre><br />

    <h3>Example of Using Arquillian with TestNG</h3>
    <p>
      Here's an example of how to use Arquillian with TestNG to test an EJB in a Java EE application:
    </p>

    <pre>
      <code>
        {`import org.jboss.arquillian.junit5.ArquillianExtension;
        import org.jboss.arquillian.junit5.Inject;
        import org.junit.jupiter.api.Test;
        import static org.junit.jupiter.api.Assertions.assertEquals;

        public class OrderServiceTest {

            @Inject
            private OrderService orderService;

            @Test
            public void testCalculateTotalPrice() {
                double result = orderService.calculateTotalPrice(5, 10.0);
                assertEquals(50.0, result, "The total price should be 50.0");
            }
        }`}
      </code>
    </pre><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Unit Testing Java EE Applications</h2>
    <ul>
      <li><strong>Test Small Units:</strong> Focus on testing individual methods and components to isolate the behavior and avoid testing too much at once.</li><br />
      <li><strong>Use Mocks for External Dependencies:</strong> Use mocking frameworks like Mockito to mock external dependencies such as databases and web services.</li><br />
      <li><strong>Automate Tests:</strong> Automate your unit tests as part of the build process to ensure consistent results and early detection of regressions.</li><br />
      <li><strong>Write Clear and Maintainable Tests:</strong> Ensure that your unit tests are readable and maintainable. This makes it easier for other developers to contribute to testing.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Unit testing with JUnit and TestNG is essential for ensuring the quality and reliability of Java EE applications. By using these frameworks effectively, you can write tests that help identify issues early and provide confidence in your code. Mocking external dependencies, testing in isolation, and leveraging integration testing tools like Arquillian further enhance the testing process and ensure that your Java EE applications are robust and well-tested.
    </p>
  </div>
)}






{selectedChapter === 'chapter88' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Integration Testing with Arquillian  </h1>

   
    <p>
      Integration testing is a critical part of testing Java EE applications, as it ensures that different components of the application work together as expected. Arquillian is a powerful testing framework designed specifically for Java EE integration testing. It provides a convenient way to run tests in a real container, making integration tests more realistic and reliable.
    </p><br />

    <h2>What is Arquillian?</h2>
    <p>
      Arquillian is an integration testing platform for Java EE applications. It allows you to test Java EE components (such as EJBs, JPA entities, and Servlets) in a managed container environment. Arquillian manages the lifecycle of the container, deploys the test components, and allows you to interact with them as if they were running in a live application server.
    </p>
    <p style={{paddingBottom:"6px"}}>
      Unlike traditional unit tests, integration tests require the full deployment context of an application server. Arquillian solves this by providing an abstraction layer that enables seamless integration with containers such as JBoss, GlassFish, or Payara, among others.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Why Use Arquillian for Integration Testing?</h3>
    <ul>
      <li><strong>Realistic Environment:</strong> Arquillian allows tests to run in an embedded or remote container, simulating the real-world behavior of the application.</li><br />
      <li><strong>Easy Container Management:</strong> Arquillian handles the deployment, startup, and shutdown of the container automatically, saving time and effort in test setup.</li><br />
      <li><strong>Support for Multiple Containers:</strong> Arquillian supports various containers, making it suitable for different Java EE environments and use cases.</li><br />
      <li><strong>Test Life Cycle Integration:</strong> Arquillian integrates with JUnit, TestNG, and other testing frameworks to provide a flexible testing approach.</li>
    </ul><br />

    <h2>Setting Up Arquillian for Java EE</h2>
    <p style={{paddingBottom:"6px"}}>
      To start using Arquillian in your Java EE project, you need to include its dependencies in your build configuration. Below are the steps for setting it up in a Maven project.
    </p>

    <h3>Adding Arquillian Dependencies</h3>
    <p>
      In your <code>pom.xml</code> file, add the following dependencies to integrate Arquillian with your project:
    </p>

    <pre>
      <code>
        {`<dependency>
            <groupId>org.jboss.arquillian.junit</groupId>
            <artifactId>arquillian-junit-container</artifactId>
            <version>1.7.0.Final</version>
            <scope>test</scope>
        </dependency>
        <dependency>
            <groupId>org.jboss.arquillian.container</groupId>
            <artifactId>arquillian-glassfish-embedded-3.1</artifactId>
            <version>1.0.0.Final</version>
            <scope>test</scope>
        </dependency>`}
      </code>
    </pre><br />

    <h3>Running Arquillian Tests</h3>
    <p>
      Once Arquillian is set up, you can start writing integration tests using your preferred testing framework (JUnit or TestNG). Arquillian supports both.
    </p><br />

    <h2>Example Integration Test with Arquillian</h2>
    <p>
      Here's an example of how to use Arquillian to test a simple Java EE application. This example demonstrates testing an EJB (Enterprise JavaBean) in a GlassFish container.
    </p>

    <pre>
      <code>
        {`import org.jboss.arquillian.junit5.ArquillianExtension;
        import org.jboss.arquillian.junit5.Inject;
        import org.junit.jupiter.api.Test;
        import static org.junit.jupiter.api.Assertions.assertEquals;

        public class OrderServiceTest {

            @Inject
            private OrderService orderService;

            @Test
            public void testCalculateTotalPrice() {
                double result = orderService.calculateTotalPrice(5, 10.0);
                assertEquals(50.0, result, "The total price should be 50.0");
            }
        }`}
      </code>
    </pre>

    <h3 style={{paddingBottom:"6px"}}>Explanation of the Example</h3>
    <ul>
      <li><strong>@Inject:</strong> The <code>@Inject</code> annotation is used to inject the instance of the <code>OrderService</code> EJB into the test. Arquillian manages the injection, allowing you to interact with the component as if it were part of the running application.</li><br />
      <li><strong>Test Execution:</strong> The test method <code>testCalculateTotalPrice</code> checks if the <code>OrderService</code> bean calculates the total price correctly.</li><br />
      <li><strong>Assertions:</strong> The test uses <code>assertEquals</code> to verify that the calculated total price is 50.0, which ensures that the business logic is functioning correctly in the container.</li>
    </ul><br />

    <h2>Arquillian Test Annotations</h2>
    <p>
      Arquillian introduces several important annotations that allow you to control the behavior of your tests in a containerized environment.
    </p>

    <ul>
      <li><strong>@Deployment:</strong> This annotation is used to specify the components that should be deployed to the container for testing (e.g., EJBs, WAR files, etc.).</li><br />
      <li><strong>@BeforeDeployment:</strong> The <code>@BeforeDeployment</code> annotation marks a method to be executed before the deployment of the application. It can be used for setting up the test environment.</li><br />
      <li><strong>@AfterDeployment:</strong> The <code>@AfterDeployment</code> annotation marks a method to be executed after the deployment, useful for cleanup tasks.</li><br />
      <li><strong>@Inject:</strong> The <code>@Inject</code> annotation is used to inject Java EE components (like EJBs or managed beans) into the test class.</li>
    </ul><br />

    <h3>Example with @Deployment Annotation</h3>
    <p>
      Below is an example of how to use the <code>@Deployment</code> annotation to deploy a WAR file containing your test components.
    </p>

    <pre>
      <code>
        {`import org.jboss.arquillian.api.Deployment;
        import org.jboss.shrinkwrap.api.ShrinkWrap;
        import org.jboss.shrinkwrap.api.spec.WebArchive;

        public class OrderServiceTest {

            @Deployment
            public static WebArchive createDeployment() {
                return ShrinkWrap.create(WebArchive.class, "test.war")
                        .addClass(OrderService.class)
                        .addAsWebInfResource("beans.xml", "beans.xml");
            }

            @Inject
            private OrderService orderService;

            @Test
            public void testCalculateTotalPrice() {
                double result = orderService.calculateTotalPrice(5, 10.0);
                assertEquals(50.0, result, "The total price should be 50.0");
            }
        }`}
      </code>
    </pre><br />

    <h2>Advanced Features of Arquillian</h2>
    <p>
      Arquillian offers advanced features such as:
    </p>
    <ul>
      <li><strong>Remote Testing:</strong> You can run your tests on a remote server or cloud environment by configuring Arquillian to connect to remote containers.</li><br />
      <li><strong>Multiple Container Support:</strong> Arquillian supports a wide range of containers such as GlassFish, WildFly, Payara, and more.</li><br />
      <li><strong>Parallel Execution:</strong> Arquillian supports running tests in parallel, reducing the overall test execution time.</li>
    </ul><br />

    <h3>Remote Container Testing</h3>
    <p>
      Arquillian supports remote container testing, allowing you to run integration tests on a server that is not locally available. You can configure remote containers using Arquillian's container adapters.
    </p><br />

    <h3>Parallel Test Execution</h3>
    <p>
      For large projects with many tests, Arquillian can execute tests in parallel to improve efficiency. This is useful when tests do not depend on each other and can be run simultaneously.
    </p><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Integration Testing with Arquillian</h2>
    <ul>
      <li><strong>Keep Tests Small:</strong> Write small and focused integration tests that verify individual components. Avoid testing too much in a single test case.</li><br />
      <li><strong>Use Containers for Realism:</strong> Run your tests in an embedded or remote container to simulate real production environments.</li><br />
      <li><strong>Leverage Deployment Features:</strong> Use Arquillian's deployment features to deploy only the components necessary for your test, reducing overhead.</li><br />
      <li><strong>Clean Up After Tests:</strong> Ensure that any resources created during the test (e.g., databases or queues) are properly cleaned up to avoid side effects in subsequent tests.</li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Arquillian is an excellent tool for integration testing in Java EE applications. It allows you to write real-world integration tests that interact with the application server, ensuring that components work together as expected. By leveraging Arquillian's features, you can improve the reliability of your tests and catch issues early in the development cycle.
    </p>
  </div>
)}








{selectedChapter === 'chapter89' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Mocking Frameworks like Mockito  </h1>

    <p>
      Unit testing is a crucial part of ensuring the correctness and quality of Java EE applications. When writing unit tests, especially for components that depend on external resources like databases or web services, mocking allows you to simulate these dependencies without requiring access to the actual resources. One of the most popular and widely used mocking frameworks in Java is <strong>Mockito</strong>.
    </p><br />

    <h2>What is Mockito?</h2>
    <p style={{paddingBottom:"6px"}}>
      Mockito is a mocking framework for Java that allows you to create mock objects for testing purposes. It is used to simulate the behavior of real objects in a controlled way, making it possible to test individual components in isolation. Mockito helps in verifying the interactions between objects, stubbing methods, and asserting certain behaviors without requiring the actual implementation of the dependencies.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Why Use Mockito for Unit Testing?</h3>
    <ul>
      <li><strong>Isolation:</strong> Mockito allows you to isolate components by mocking the external dependencies like services, databases, or web services that are difficult to test in isolation.</li><br />
      <li><strong>Behavior Verification:</strong> It provides an easy way to verify that interactions with dependencies (like method calls) occurred as expected.</li><br />
      <li><strong>Simplified Testing:</strong> By using mocks, tests can focus on the logic of the component being tested, making them simpler and faster.</li><br />
      <li><strong>Integration with JUnit/Other Testing Frameworks:</strong> Mockito integrates seamlessly with popular testing frameworks like JUnit and TestNG.</li>
    </ul><br />

    <h2>Setting Up Mockito</h2>
    <p style={{paddingBottom:"6px"}}>
      Mockito can be easily integrated into your project by adding the necessary dependencies to your build configuration. Below are the steps for adding Mockito to a Maven-based Java project.
    </p>

    <h3>Adding Mockito Dependency in Maven</h3>
    <p>
      To add Mockito as a dependency, include the following snippet in your <code>pom.xml</code> file:
    </p>

    <pre>
      <code>
        {`<dependency>
            <groupId>org.mockito</groupId>
            <artifactId>mockito-core</artifactId>
            <version>4.0.0</version>
            <scope>test</scope>
        </dependency>`}
      </code>
    </pre><br />

    <h2>Basic Mockito Usage</h2>
    <p style={{paddingBottom:"6px"}}>
      Once Mockito is set up, you can start writing unit tests where you create mock objects and define their behaviors.
    </p>

    <h3>Creating Mocks with Mockito</h3>
    <p>
      You can create mock objects using the <code>Mockito.mock()</code> method. This method creates a mock of the specified class or interface, allowing you to define the behavior of its methods for the test.
    </p>

    <pre>
      <code>
        {`import static org.mockito.Mockito.*;

        public class UserServiceTest {

            @Test
            public void testGetUser() {
                // Create a mock of UserRepository
                UserRepository userRepository = mock(UserRepository.class);

                // Stub the behavior of getUser method
                when(userRepository.getUser(1)).thenReturn(new User(1, "John Doe"));

                // Create an instance of UserService and pass the mock
                UserService userService = new UserService(userRepository);

                // Test the getUser method
                User user = userService.getUser(1);
                assertEquals("John Doe", user.getName());
            }
        }`}
      </code>
    </pre>

    <h3 style={{paddingBottom:"6px"}}>Explanation of the Example</h3>
    <ul>
      <li><strong>mock():</strong> The <code>mock(UserRepository.class)</code> creates a mock object of the <code>UserRepository</code> class.</li><br />
      <li><strong>when(...).thenReturn(...):</strong> This is how you define the behavior of the mock. In this case, the mock <code>userRepository</code> will return a new <code>User</code> object with name "John Doe" when the <code>getUser</code> method is called with an argument of 1.</li><br />
      <li><strong>Verification:</strong> The test verifies that the <code>getUser</code> method of the <code>UserService</code> returns the correct user information.</li>
    </ul><br />

    <h2>Verifying Behavior with Mockito</h2>
    <p style={{paddingBottom:"6px"}}>
      Mockito also allows you to verify that certain methods were called on the mock objects, and that they were called with the correct parameters. This is useful for ensuring that the component under test is interacting with its dependencies as expected.
    </p>

    <h3>Verifying Method Calls</h3>
    <pre>
      <code>
        {`import static org.mockito.Mockito.*;

        public class OrderServiceTest {

            @Test
            public void testPlaceOrder() {
                // Create a mock of PaymentService
                PaymentService paymentService = mock(PaymentService.class);

                // Create the OrderService and inject the mock
                OrderService orderService = new OrderService(paymentService);

                // Call the placeOrder method
                orderService.placeOrder(100.0);

                // Verify that the makePayment method was called once with the correct argument
                verify(paymentService, times(1)).makePayment(100.0);
            }
        }`}
      </code>
    </pre>

    <h3 style={{paddingBottom:"6px"}}>Explanation of the Verification Example</h3>
    <ul>
      <li><strong>verify():</strong> The <code>verify(paymentService, times(1)).makePayment(100.0)</code> ensures that the <code>makePayment</code> method of the <code>PaymentService</code> mock was called exactly once with the argument <code>100.0</code>.</li><br />
      <li><strong>times(1):</strong> This ensures that the method is called exactly once. You can use other verifications like <code>never()</code>, <code>atLeastOnce()</code>, or <code>atMost()</code> to check different interaction counts.</li>
    </ul><br />

    <h2>Mocking Void Methods</h2>
    <p style={{paddingBottom:"6px"}}>
      Mocking void methods can be tricky because they do not return a value. Mockito allows you to mock void methods by using the <code>doNothing()</code>, <code>doThrow()</code>, or <code>doAnswer()</code> methods.
    </p>

    <h3>Mocking Void Methods Example</h3>
    <pre>
      <code>
        {`import static org.mockito.Mockito.*;

        public class NotificationServiceTest {

            @Test
            public void testSendNotification() {
                // Create a mock of EmailService
                EmailService emailService = mock(EmailService.class);

                // Mock the void sendEmail method
                doNothing().when(emailService).sendEmail(anyString(), anyString());

                // Call the method that triggers sendEmail
                NotificationService notificationService = new NotificationService(emailService);
                notificationService.sendNotification("test@example.com", "Test Notification");

                // Verify that sendEmail was called
                verify(emailService, times(1)).sendEmail("test@example.com", "Test Notification");
            }
        }`}
      </code>
    </pre><br />

    <h2>Mocking Exceptions in Methods</h2>
    <p style={{paddingBottom:"6px"}}>
      Mockito also allows you to mock methods to throw exceptions, which is useful for testing error handling and exceptional scenarios.
    </p>

    <h3>Mocking an Exception Example</h3>
    <pre>
      <code>
        {`import static org.mockito.Mockito.*;

        public class PaymentServiceTest {

            @Test
            public void testMakePayment_Exception() {
                // Create a mock of PaymentGateway
                PaymentGateway paymentGateway = mock(PaymentGateway.class);

                // Mock the makePayment method to throw an exception
                when(paymentGateway.makePayment(anyDouble())).thenThrow(new PaymentFailedException("Payment failed"));

                // Test that the exception is thrown
                assertThrows(PaymentFailedException.class, () -> {
                    paymentGateway.makePayment(100.0);
                });
            }
        }`}
      </code>
    </pre>

    <h3 style={{paddingBottom:"6px"}}>Explanation of the Mocking Exception Example</h3>
    <ul>
      <li><strong>when(...).thenThrow(...):</strong> This tells Mockito to throw a <code>PaymentFailedException</code> when the <code>makePayment</code> method is called with any double argument.</li><br />
      <li><strong>assertThrows():</strong> The test ensures that the exception is properly thrown and handled within the application.</li>
    </ul><br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Using Mockito</h2>
    <ul>
      <li><strong>Use Mocks for External Dependencies:</strong> Only mock objects that are external to the component you're testing, such as databases, third-party services, or APIs.</li><br />
      <li><strong>Avoid Over-Mocking:</strong> Mock only the necessary behavior. Avoid mocking everything in your test class, as it can make the tests harder to maintain.</li><br />
      <li><strong>Use Meaningful Verifications:</strong> Verify that interactions with mocks are meaningful and align with the functionality being tested.</li><br />
      <li><strong>Clean Up Mocks:</strong> If you need to verify complex interactions, make sure to clean up mocks after each test to avoid interference with other tests.</li>
    </ul><br />

  </div>
)}






{selectedChapter === 'chapter90' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}> Testing Web Services with Postman and SOAP UI  </h1>



    <p>
      Testing web services is an essential part of ensuring that your API endpoints function correctly in a Java EE application. Postman and SOAP UI are two popular tools that can be used for this purpose, offering robust support for both RESTful and SOAP-based web services. Below, we will explore both tools and their usage in testing web services.
    </p><br />

    <h2>Postman for Testing RESTful Web Services</h2>
    <p style={{paddingBottom:"6px"}}>
      Postman is a popular tool for testing RESTful APIs. It allows developers to send HTTP requests and view responses, making it ideal for testing Java EE web services implemented with JAX-RS (Java API for RESTful Web Services).
    </p>

    <h3 style={{paddingBottom:"6px"}}>Basic Steps to Test a REST API with Postman:</h3>
    <ol>
      <li><strong>Download and Install Postman:</strong> Go to <a href="https://www.postman.com/downloads/">Postman Downloads</a> and install the tool on your machine.</li><br />
      <li><strong>Create a New Request:</strong> Open Postman, click on "New", and select "Request".</li><br />
      <li><strong>Set Request Method and URL:</strong> Choose the HTTP method (GET, POST, PUT, DELETE, etc.) and enter the URL of the REST service you want to test.</li><br />
      <li><strong>Configure Request Body (if necessary):</strong> For methods like POST or PUT, provide the JSON/XML request body.</li><br />
      <li><strong>Send the Request:</strong> Click "Send" to make the request to your API endpoint.</li><br />
      <li><strong>Inspect the Response:</strong> View the response status, body, and headers to ensure your API is functioning as expected.</li>
    </ol><br />

    <h3>Example of Testing a RESTful Web Service in Postman:</h3>
    <pre>
      <code>{`
        GET https://yourdomain.com/api/user/1
        Response:
        {
          "id": 1,
          "name": "John Doe",
          "email": "john.doe@example.com"
        }
      `}</code>
    </pre><br />

    <h2>SOAP UI for Testing SOAP Web Services</h2>
    <p style={{paddingBottom:"6px"}}>
      SOAP UI is a widely used tool for testing SOAP-based web services. It provides a user-friendly interface for sending SOAP requests and inspecting responses, including support for WS-Security, SOAP envelopes, and more.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Basic Steps to Test a SOAP Web Service with SOAP UI:</h3>
    <ol>
      <li><strong>Download and Install SOAP UI:</strong> Download and install SOAP UI from <a href="https://www.soapui.org/downloads/soapui/">SOAP UI Downloads</a>.</li><br />
      <li><strong>Create a New SOAP Project:</strong> Open SOAP UI, click on "File" {">"} "New SOAP Project", and enter the WSDL URL of the SOAP service you want to test.</li><br />
      <li><strong>Configure Request:</strong> SOAP UI will generate a set of requests based on the WSDL. Select the request you want to test.</li><br />
      <li><strong>Modify the Request XML:</strong> Edit the XML body of the request if necessary, based on the service's specification.</li><br />
      <li><strong>Send the Request:</strong> Click "Submit" to send the SOAP request to the web service.</li><br />
      <li><strong>Inspect the Response:</strong> Review the response in XML format to verify the service's functionality.</li>
    </ol><br />

    <h3>Example of Testing a SOAP Web Service in SOAP UI:</h3>
    <pre>
      <code>{`
        Request:
        <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="http://www.example.com">
  <soapenv:Header/>
  <soapenv:Body>
    <web:getUserResponse>
      <web:user>
        <web:id>1</web:id>
        <web:name>John Doe</web:name>
        <web:email>john.doe@example.com</web:email>
      </web:user>
    </web:getUserResponse>
  </soapenv:Body>
</soapenv:Envelope>

       `} </code>
    </pre><br />

    <h2>Conclusion</h2>
    <p>
      Both Postman and SOAP UI are excellent tools for testing Java EE web services, whether they are RESTful or SOAP-based. Postman is ideal for RESTful services, while SOAP UI excels with SOAP-based services. Using these tools, you can ensure that your Java EE web services are functioning as expected and are ready for production deployment.
    </p>
  </div>
)}







{selectedChapter === 'chapter91' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Packaging applications: WAR and EAR files</h1>

    <p>To package Java EE applications, two common formats are used: <strong>WAR</strong> (Web Application Archive) and <strong>EAR</strong> (Enterprise Archive). These formats help bundle all necessary components, configurations, and dependencies for easy deployment and distribution.</p><br />

    <h2>1. WAR (Web Application Archive) Files</h2>

    <p>A <strong>WAR</strong> file is used for packaging web applications, typically containing the following components:</p>

    <ul>
      <li><strong>Web Resources:</strong> Includes HTML, JSP, CSS, JavaScript, and image files.</li><br />
      <li><strong>Web Application Descriptor (`web.xml`):</strong> A configuration file located in the `WEB-INF` directory, which defines servlets, filters, listeners, and other web-related settings.</li><br />
      <li><strong>Servlets:</strong> Java classes that handle HTTP requests.</li><br />
      <li><strong>Libraries:</strong> JAR files used by the web application.</li>
    </ul><br />

    <p><strong>WAR files</strong> are typically deployed on servlet containers (such as Apache Tomcat or Jetty) or full Java EE servers (like WildFly or GlassFish). This format is mainly used for applications that include web-based user interfaces or RESTful web services.</p><br />

    <h3>Structure of a WAR File</h3>
    <pre>
      <code>{`
myapp.war
│
├── WEB-INF/
│   ├── classes/ (compiled Java classes)
│   ├── lib/ (external libraries like JAR files)
│   ├── web.xml (web application descriptor)
│
├── index.html (web pages and resources)
├── images/ (static content such as images)
├── css/ (CSS files)
├── js/ (JavaScript files)
        `}  </code>
    </pre><br />

    <h3>How to Package a WAR File</h3>
    <p>To package a WAR file using Maven, configure the <code>pom.xml</code> as follows:</p>

    <pre>
      <code>{`
      <build>
    <finalName>myapp</finalName>
    <plugins>
        <plugin>
            <groupId>org.apache.maven.plugins</groupId>
            <artifactId>maven-war-plugin</artifactId>
            <version>3.3.1</version>
        </plugin>
    </plugins>
</build>

       `} </code>
    </pre>

    <p>Running the command <code>mvn package</code> will create a <strong>myapp.war</strong> file in the <code>target/</code> directory.</p>

   <br />

    <h2>2. EAR (Enterprise Archive) Files</h2>

    <p>An <strong>EAR</strong> file is used for packaging entire Java EE applications, which may contain multiple modules, including web components (WAR files), EJB modules (JAR files), and other resources required for enterprise applications.</p>

    <p>The EAR file can include:</p>

    <ul>
      <li><strong>Web Application(s):</strong> As WAR files.</li><br />
      <li><strong>Enterprise JavaBeans (EJB):</strong> JAR files containing EJB modules.</li><br />
      <li><strong>Application Client:</strong> A JAR file for client-side applications.</li><br />
      <li><strong>Configuration Files:</strong> Deployment descriptors for EJBs and other Java EE components.</li><br />
      <li><strong>Libraries:</strong> Shared libraries used across all modules within the EAR.</li>
    </ul><br />

    <p><strong>EAR files</strong> are best for multi-module applications, such as those combining web modules, EJBs, and more. They are deployed on full Java EE servers like WildFly, WebLogic, or GlassFish.</p><br />

    <h3>Structure of an EAR File</h3>
    <pre>
      <code>{`
myapp.ear
│
├── META-INF/
│   ├── application.xml (EAR descriptor file)
│
├── myapp-web.war (Web application archive)
├── myapp-ejb.jar (EJB module)
├── lib/ (Shared libraries used across modules)
       `} </code>
    </pre><br />

    <h3>How to Package an EAR File</h3>
    <p>To package an EAR file using Maven, configure the <code>pom.xml</code> as follows:</p>

    <pre>
      <code>{`
<build>
    <plugins>
        <plugin>
            <groupId>org.apache.maven.plugins</groupId>
            <artifactId>maven-ear-plugin</artifactId>
            <version>3.1.0</version>
            <configuration>
                <modules>
                    <webModule>
                        <groupId>com.example</groupId>
                        <artifactId>myapp-web</artifactId>
                        <contextRoot>/myapp</contextRoot>
                    </webModule>
                    <ejbModule>
                        <groupId>com.example</groupId>
                        <artifactId>myapp-ejb</artifactId>
                    </ejbModule>
                </modules>
            </configuration>
        </plugin>
    </plugins>
</build>

 `} </code>
    </pre>

    <p>Running <code>mvn package</code> will create a <strong>myapp.ear</strong> file in the <code>target/</code> directory.</p>

   <br />

    <h2>Differences Between WAR and EAR Files</h2>

    <table>
      <thead>
        <tr>
          <th>Aspect</th>
          <th>WAR (Web Application Archive)</th>
          <th>EAR (Enterprise Archive)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>Usage</strong></td>
          <td>For web applications (servlets, JSPs, REST services)</td>
          <td>For full Java EE applications (including EJB, web modules, etc.)</td>
        </tr>
        <tr>
          <td><strong>Components</strong></td>
          <td>Web resources (HTML, JSP, CSS, JavaScript), Servlets, Libraries</td>
          <td>Web modules (WAR), EJB modules, Libraries</td>
        </tr>
        <tr>
          <td><strong>Deployment</strong></td>
          <td>Deployed on servlet containers or Java EE servers</td>
          <td>Deployed on full Java EE servers (e.g., WildFly, WebLogic, GlassFish)</td>
        </tr>
        <tr>
          <td><strong>Descriptor File</strong></td>
          <td><code>WEB-INF/web.xml</code></td>
          <td><code>META-INF/application.xml</code></td>
        </tr>
      </tbody>
    </table>

   <br />

    <h2>Conclusion</h2>

    <p><strong>WAR files</strong> are ideal for simpler web applications that only need to bundle web resources like HTML, CSS, and servlets.</p>
    <p><strong>EAR files</strong> are better suited for more complex, enterprise-level applications that involve multiple modules, such as EJBs, web modules, and shared libraries.</p>
    <p>When packaging a Java EE application, choosing the appropriate format is crucial depending on the application's complexity and the modules it includes.</p>
  </div>
)}


{selectedChapter === 'chapter92' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Deploying on Application Servers (Tomcat, WildFly, GlassFish)</h1>
    
    <p>
      In Java EE, deploying applications typically involves deploying packaged files (such as WAR or EAR files) to application servers. These servers are responsible for managing the lifecycle of the application, providing services like security, transaction management, and more. Let’s explore the three most popular Java EE application servers: <strong>Tomcat</strong>, <strong>WildFly</strong>, and <strong>GlassFish</strong>.
    </p><br />

    <h4>1. Tomcat</h4>

    <p>
      <strong>Tomcat</strong> is one of the most widely used servlet containers, though it doesn't implement the full Java EE specification. It primarily supports web applications (servlets and JSPs), which makes it a lightweight choice for applications that don't require full Java EE features like EJB (Enterprise JavaBeans).
    </p>

    <ul>
      <li><strong>Supported Technologies:</strong> Servlets, JSPs, and WebSocket.</li><br />
      <li><strong>Deployment:</strong> You can deploy a WAR file directly by placing it in the <code>webapps</code> directory of the Tomcat server. Tomcat will automatically deploy the application when the server starts.</li>
    </ul><br />

    <p><strong>Deployment Steps:</strong></p>
    <ol>
      <li><strong>Build the WAR file</strong> using Maven (as discussed earlier).</li><br />
      <li><strong>Copy the WAR file</strong> to the <code>webapps</code> directory of your Tomcat installation.</li><br />
      <li><strong>Start Tomcat</strong>: Run <code>startup.sh</code> (Unix) or <code>startup.bat</code> (Windows) from the <code>bin</code> folder to start the server.</li><br />
      <li><strong>Access the Application:</strong> The deployed web application will be available at <code>http://localhost:8080/myapp</code>.</li>
    </ol><br />

    <h4>2. WildFly</h4>

    <p>
      <strong>WildFly</strong> (formerly known as JBoss AS) is a full Java EE application server. It implements the entire Java EE specification, which means it can handle a wider variety of Java EE applications, including EJBs, web services, and more.
    </p>

    <ul>
      <li><strong>Supported Technologies:</strong> EJB, JPA (Java Persistence API), JMS (Java Message Service), Servlets, JSP, CDI (Contexts and Dependency Injection), and more.</li><br />
      <li><strong>Deployment:</strong> WildFly supports both WAR and EAR files, and can be deployed through its management console or by copying the files to the <code>standalone/deployments</code> folder.</li>
    </ul><br />

    <p><strong>Deployment Steps:</strong></p>
    <ol>
      <li><strong>Build the EAR or WAR file</strong>.</li><br />
      <li><strong>Copy the file</strong> to the <code>standalone/deployments</code> directory.</li><br />
      <li><strong>Start WildFly</strong>: Run <code>bin/standalone.sh</code> (Unix) or <code>bin/standalone.bat</code> (Windows).</li><br />
      <li><strong>Check the Status:</strong> WildFly automatically deploys the application, and you can access it at <code>http://localhost:8080/myapp</code>.</li>
    </ol><br />

    <h4>3. GlassFish</h4>

    <p>
      <strong>GlassFish</strong> is another fully-featured Java EE application server. It is the reference implementation of Java EE, meaning it is typically used for testing and development of Java EE applications. GlassFish supports the full range of Java EE technologies, making it a robust choice for large enterprise applications.
    </p>

    <ul>
      <li><strong>Supported Technologies:</strong> Full Java EE stack, including Servlets, JSP, EJB, JPA, JMS, and more.</li><br />
      <li><strong>Deployment:</strong> Like WildFly, GlassFish supports both WAR and EAR files. It can be deployed using the administration console or by copying the file to the <code>glassfish/domains/domain1/autodeploy</code> folder.</li>
    </ul><br />

    <p><strong>Deployment Steps:</strong></p>
    <ol>
      <li><strong>Build the EAR or WAR file</strong>.</li><br />
      <li><strong>Copy the file</strong> to the <code>glassfish/domains/domain1/autodeploy</code> folder.</li><br />
      <li><strong>Start GlassFish</strong>: Run <code>bin/asadmin start-domain</code> to start the server.</li><br />
      <li><strong>Access the Application:</strong> The application will be available at <code>http://localhost:8080/myapp</code>.</li>
    </ol><br />

    <h4>Comparison of Application Servers</h4>

    <table>
      <thead>
        <tr>
          <th>Aspect</th>
          <th>Tomcat</th>
          <th>WildFly</th>
          <th>GlassFish</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>Full Java EE Support</strong></td>
          <td>No (Servlets & JSP only)</td>
          <td>Yes (Full Java EE)</td>
          <td>Yes (Full Java EE)</td>
        </tr>
        <tr>
          <td><strong>Primary Use Case</strong></td>
          <td>Lightweight web applications</td>
          <td>Enterprise-level Java EE applications</td>
          <td>Reference implementation for Java EE</td>
        </tr>
        <tr>
          <td><strong>Deployment Method</strong></td>
          <td>Copy WAR to <code>webapps/</code> directory</td>
          <td>Copy WAR/EAR to <code>standalone/deployments/</code></td>
          <td>Copy WAR/EAR to <code>autodeploy/</code> folder</td>
        </tr>
        <tr>
          <td><strong>Performance</strong></td>
          <td>Lightweight, suitable for small apps</td>
          <td>Suitable for enterprise applications</td>
          <td>Suitable for large, complex apps</td>
        </tr>
      </tbody>
    </table><br />

    <h4>Conclusion</h4>

    <p>
      <strong>Tomcat</strong> is ideal for simple web applications requiring only servlets and JSPs. <br />
      <strong>WildFly</strong> and <strong>GlassFish</strong> are more suitable for full Java EE applications, offering support for technologies like EJB, JPA, and more.
    </p>

    <p>
      When deciding on an application server, it’s important to consider the scope and requirements of your Java EE application to select the most appropriate platform.
    </p>
  </div>
)}



{selectedChapter === 'chapter93' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Understanding Deployment Descriptors (web.xml, persistence.xml)</h1>

    <p>
      In Java EE, deployment descriptors are essential configuration files used to define how various components of a web or enterprise application are managed and deployed to the application server. These descriptors are XML files that provide necessary metadata for the application server to properly initialize and deploy the application. Two of the most common deployment descriptors in Java EE are `web.xml` and `persistence.xml`.
    </p><br />

    <h4>1. web.xml (Web Deployment Descriptor)</h4>

    <p style={{paddingBottom:"6px"}}>
      The `web.xml` file is the primary deployment descriptor for Java web applications (servlets, JSPs). It is typically found in the `WEB-INF` directory of a web application. The `web.xml` file defines configuration information for servlets, JSPs, filters, listeners, and other web components.
    </p>

    <h5>Key Elements of web.xml:</h5>
    <ul>
      <li><strong>&lt;servlet&gt;</strong>: Defines a servlet and its mappings in the web application.</li><br />
      <li><strong>&lt;servlet-mapping&gt;</strong>: Maps a servlet to a specific URL pattern.</li><br />
      <li><strong>&lt;filter&gt;</strong>: Defines a filter to intercept requests to servlets or JSPs.</li><br />
      <li><strong>&lt;listener&gt;</strong>: Registers an event listener for the application context.</li><br />
      <li><strong>&lt;context-param&gt;</strong>: Configures global parameters for the web application.</li><br />
      <li><strong>&lt;welcome-file-list&gt;</strong>: Specifies the default file to load when the user accesses the root URL.</li>
    </ul><br />

    <h5>Example web.xml:</h5>
    <pre>
      <code>
        {`
          <web-app xmlns="http://java.sun.com/xml/ns/javaee"
                   xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                   xsi:schemaLocation="http://java.sun.com/xml/ns/javaee
                                       http://java.sun.com/xml/ns/javaee/web-app_3_0.xsd"
                   version="3.0">

              <servlet>
                  <servlet-name>MyServlet</servlet-name>
                  <servlet-class>com.example.MyServlet</servlet-class>
              </servlet>

              <servlet-mapping>
                  <servlet-name>MyServlet</servlet-name>
                  <url-pattern>/myservlet</url-pattern>
              </servlet-mapping>

              <filter>
                  <filter-name>MyFilter</filter-name>
                  <filter-class>com.example.MyFilter</filter-class>
              </filter>

              <filter-mapping>
                  <filter-name>MyFilter</filter-name>
                  <url-pattern>/myservlet</url-pattern>
              </filter-mapping>

              <listener>
                  <listener-class>com.example.MyListener</listener-class>
              </listener>

              <welcome-file-list>
                  <welcome-file>index.jsp</welcome-file>
              </welcome-file-list>

          </web-app>
        `}
      </code>
    </pre><br />

    <h4>2. persistence.xml (Persistence Descriptor)</h4>

    <p style={{paddingBottom:"6px"}}>
      The `persistence.xml` file is used to configure the persistence layer of a Java EE application when using Java Persistence API (JPA). This file is located in the `META-INF` directory and provides configuration for the persistence unit, such as data sources, transaction management, and entity mappings.
    </p>

    <h5 style={{paddingBottom:"6px"}}>Key Elements of persistence.xml:</h5>
    <ul>
      <li><strong>&lt;persistence-unit&gt;</strong>: Defines a persistence unit that contains the entities to be managed and configured for a particular data source.</li><br />
      <li><strong>&lt;provider&gt;</strong>: Specifies the JPA provider (such as Hibernate or EclipseLink).</li><br />
      <li><strong>&lt;class&gt;</strong>: Defines the entity classes that are part of the persistence unit.</li><br />
      <li><strong>&lt;properties&gt;</strong>: Configures additional properties such as database connection parameters, caching, etc.</li>
    </ul><br />

    <h5>Example persistence.xml:</h5>
    <pre>
      <code>
        {`
          <persistence xmlns="http://java.sun.com/xml/ns/persistence"
                       xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                       xsi:schemaLocation="http://java.sun.com/xml/ns/persistence
                                           http://java.sun.com/xml/ns/persistence/persistence_2_0.xsd"
                       version="2.0">

              <persistence-unit name="myPersistenceUnit">
                  <provider>org.hibernate.jpa.HibernatePersistenceProvider</provider>
                  <class>com.example.entity.Employee</class>
                  <class>com.example.entity.Department</class>

                  <properties>
                      <property name="hibernate.dialect" value="org.hibernate.dialect.MySQLDialect"/>
                      <property name="hibernate.hbm2ddl.auto" value="update"/>
                      <property name="hibernate.show_sql" value="true"/>
                      <property name="hibernate.format_sql" value="true"/>
                  </properties>
              </persistence-unit>

          </persistence>
        `}
      </code>
    </pre><br />

    <h4>Comparison of `web.xml` and `persistence.xml`</h4>

    <table>
      <thead>
        <tr>
          <th>Aspect</th>
          <th>web.xml</th>
          <th>persistence.xml</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>Purpose</strong></td>
          <td>Configures web components like servlets, filters</td>
          <td>Configures the persistence unit for JPA</td>
        </tr>
        <tr>
          <td><strong>Location</strong></td>
          <td>`WEB-INF/web.xml`</td>
          <td>`META-INF/persistence.xml`</td>
        </tr>
        <tr>
          <td><strong>Common Elements</strong></td>
          <td>&lt;servlet&gt;, &lt;filter&gt;, &lt;listener&gt;</td>
          <td>&lt;persistence-unit&gt;, &lt;provider&gt;, &lt;class&gt;</td>
        </tr>
        <tr>
          <td><strong>Configuration</strong></td>
          <td>Web component mappings, filters, listeners</td>
          <td>Data source, JPA provider, entity classes</td>
        </tr>
      </tbody>
    </table><br />

    <h4>Conclusion</h4>

    <p>
      - The `web.xml` file is crucial for configuring web components in Java EE, including servlets, JSPs, filters, and listeners.
      - The `persistence.xml` file is key for configuring the persistence layer using JPA, including specifying entity classes and database connection properties.
      - Both descriptors are essential for deploying Java EE applications, and understanding their configurations is crucial for ensuring smooth deployment and proper application behavior.
    </p>

    <p>
      By properly configuring these deployment descriptors, developers can ensure that their applications are deployed correctly and can take full advantage of Java EE features like web technologies and persistence management.
    </p>
  </div>
)}


{selectedChapter === 'chapter94' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Monitoring Applications with JMX</h1>
    <p>
      In Java EE applications, monitoring and management are critical to ensure performance, reliability, and efficient resource usage.
      One of the most powerful tools for monitoring and managing Java applications is <strong>Java Management Extensions (JMX)</strong>.
      JMX is a Java technology that provides a simple, standardized way to monitor and manage Java applications, system objects, devices, and networks.
    </p>

    <p>
      JMX allows developers to access runtime data and statistics from Java applications and also enables dynamic configuration and management of application components during runtime.
      It's often used for performance monitoring, diagnostics, and application health checks.
    </p><br />

    <h3>What is JMX?</h3>

    <p>
      JMX is a set of APIs and services that allow Java applications to be managed and monitored. It provides a way to define, expose, and manage resources, such as application components, servers, and services.
      These resources are referred to as <strong>MBeans (Managed Beans)</strong>, which are Java objects that represent manageable resources in an application.
    </p><br />

    <h3>Key Components of JMX:</h3>

    <ul>
      <li>MBeans (Managed Beans): MBeans are the core components of JMX and represent the resources in your application that you want to manage or monitor. There are several types of MBeans:
        <ul>
          <li><strong>Standard MBeans</strong>: Regular Java objects that follow a specific naming pattern (i.e., methods start with `get` or `set`).</li><br />
          <li><strong>Dynamic MBeans</strong>: MBeans whose management interface is defined at runtime.</li><br />
          <li><strong>Open MBeans</strong>: MBeans that are designed to be language-independent.</li>
        </ul><br />
      </li>
      <li><strong>MBeanServer</strong>: The MBeanServer is the heart of JMX. It is the platform where all MBeans are registered and managed. It provides an interface for accessing and invoking the MBeans' management methods.</li><br />
      <li><strong>JMX Connector</strong>: The JMX Connector provides a way to access MBeans remotely, allowing for the management and monitoring of Java applications from remote systems. It enables the use of JMX over protocols like RMI (Remote Method Invocation) and JMS (Java Message Service).</li><br />
      <li><strong>JMX Client</strong>: The JMX Client interacts with the MBeanServer to manage and monitor MBeans. It can either be local or remote, depending on the application configuration.</li>
    </ul><br />

    <h3>Setting up JMX Monitoring in Java EE Applications:</h3>

    <p>To monitor a Java EE application using JMX, the following steps are typically involved:</p>

    <ol>
      <li>Define MBeans: Define Java classes as MBeans that expose the necessary attributes (e.g., application health, memory usage, database connections). MBeans should have appropriate getter and setter methods.</li>
      <pre>
        <code>
          public interface ApplicationStatusMBean &#123;
            int getActiveUsers();
            void reset();
          &#125;
        </code>
      </pre>
      <pre>
        <code>{`
          public class ApplicationStatus implements ApplicationStatusMBean &#123;
            private int activeUsers;
        
            public int getActiveUsers() &#123;
                return activeUsers;
            &#125;
        
            public void reset() &#123;
                activeUsers = 0;
            &#125;
          &#125;
        `}</code>
      </pre>

      <li>Register MBeans with MBeanServer: After defining the MBeans, you need to register them with the MBeanServer. This is typically done in the `main` method or the initialization logic of your application.</li>
      <pre>
        <code>{`
          MBeanServer mBeanServer = ManagementFactory.getPlatformMBeanServer();
          ApplicationStatusMBean appStatus = new ApplicationStatus();
          ObjectName objectName = new ObjectName("com.example:type=ApplicationStatus");
          mBeanServer.registerMBean(appStatus, objectName);
         `} </code>
      </pre>

      <li><strong>Access MBeans via JMX Client</strong>: Use a JMX client to interact with the MBeans. You can create a client using tools like <strong>JConsole</strong> or <strong>VisualVM</strong> to view the MBeans and invoke their methods.</li><br />
      <li><strong>Enable Remote JMX Access (Optional)</strong>: For remote monitoring, enable the JMX connector. This typically involves configuring the Java Virtual Machine (JVM) with the necessary JMX system properties.</li>
      <pre>
        <code>{`
          java -Dcom.sun.management.jmxremote
              -Dcom.sun.management.jmxremote.port=1234
              -Dcom.sun.management.jmxremote.authenticate=false
              -Dcom.sun.management.jmxremote.ssl=false
              -Djava.rmi.server.hostname=127.0.0.1
              -jar your-application.jar
         `} </code>
      </pre>
    </ol><br />

    <h3>Example Use Case: Monitoring Application Health</h3>

    <p>A common use case of JMX in Java EE applications is monitoring the health of the application. For instance, you can create an MBean to expose the number of active users, server uptime, or database connections.</p>

    <pre>
      <code>{`
        public class ActiveUserMonitor implements ActiveUserMonitorMBean &#123;
            private int activeUsers;

            @Override
            public int getActiveUsers() &#123;
                return activeUsers;
            &#125;

            @Override
            public void incrementActiveUsers() &#123;
                activeUsers++;
            &#125;

            @Override
            public void decrementActiveUsers() &#123;
                activeUsers--;
            &#125;
            &#125;
       `} </code>
    </pre>

    <p>You would then register this MBean with the MBeanServer to make it available for monitoring.</p><br />

    <h3 style={{paddingBottom:"6px"}}>JMX Tools for Monitoring</h3>

    <ul>
      <li><strong>JConsole</strong>: A tool that comes bundled with the JDK, allowing for real-time monitoring of Java applications.</li><br />
      <li><strong>VisualVM</strong>: A more advanced monitoring tool that integrates with JMX and provides performance profiling and memory analysis.</li><br />
      <li><strong>Prometheus & Grafana</strong>: For more advanced metrics and visualization, you can integrate JMX with Prometheus and Grafana to gather and visualize application metrics.</li>
    </ul><br />

    <h3>Conclusion</h3>

    <p>
      JMX is a powerful framework for managing and monitoring Java EE applications, providing essential insights into application behavior. By defining MBeans and using tools like JConsole or VisualVM, developers can track performance metrics, manage resources, and ensure the health of their applications in production environments. JMX is essential for maintaining high-performing and reliable Java EE applications.
    </p>
  </div>
)}


{selectedChapter === 'chapter95' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Introduction to Microservices Architecture</h1>

    <p>
      In modern application development, the need for scalability, maintainability, and agility has driven the adoption of 
      the <strong>microservices architecture</strong>. This architecture divides a large application into smaller, independent, and 
      loosely coupled services, each focusing on a specific business capability. 
    </p><br />

    <h3>What are Microservices?</h3>
    <p>
      Microservices are a style of software architecture where applications are composed of small, autonomous services. 
      Each service operates independently, has its own database (if required), and communicates with other services 
      through lightweight mechanisms like HTTP/REST or messaging systems.
    </p><br />

    <h3>Core Principles of Microservices Architecture</h3>
    <ul>
      <li>
        <strong>Single Responsibility:</strong> Each microservice is designed to perform one specific task or function.
      </li><br />
      <li>
        <strong>Autonomous Deployment:</strong> Microservices can be deployed and scaled independently without affecting other services.
      </li><br />
      <li>
        <strong>Decentralized Data Management:</strong> Each service can manage its own database, ensuring data encapsulation.
      </li><br />
      <li>
        <strong>Resilience:</strong> Failure in one service does not bring down the entire application; services are designed to handle failures gracefully.
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Advantages of Microservices Architecture</h3>
    <ul>
      <li>Improved scalability and performance by deploying specific services independently.</li><br />
      <li>Easier to maintain and extend, as services are modular and focus on specific business functionalities.</li><br />
      <li>Better fault isolation, preventing a single point of failure.</li><br />
      <li>Technology diversity: Teams can use different technologies for different services.</li>
    </ul><br />

    <h3>Challenges in Microservices</h3>
    <ul>
      <li>
        <strong>Complexity:</strong> Managing many independent services can be challenging, especially in terms of 
        communication and data consistency.
      </li><br />
      <li>
        <strong>Latency:</strong> Communication between services can introduce network latency.
      </li><br />
      <li>
        <strong>Deployment:</strong> Requires sophisticated CI/CD pipelines and container orchestration tools like Kubernetes.
      </li>
    </ul><br />

    <h3>How Java EE Supports Microservices</h3>
    <p>
      Java EE provides robust tools and APIs to support microservices development, including:
    </p>
    <ul>
      <li>
        <strong>JAX-RS:</strong> For building RESTful APIs, a key communication mechanism in microservices.
      </li><br />
      <li>
        <strong>CDI (Contexts and Dependency Injection):</strong> Simplifies dependency injection and service discovery.
      </li><br />
      <li>
        <strong>JSON-P and JSON-B:</strong> For parsing and binding JSON data, which is widely used in microservices communication.
      </li><br />
      <li>
        <strong>EJB (Enterprise Java Beans):</strong> For handling business logic in a modular manner.
      </li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Microservices architecture represents a significant evolution in how we build and deploy modern applications. 
      With its focus on modularity, scalability, and resilience, microservices are an excellent fit for cloud-native and 
      distributed systems. Java EE offers a range of tools and APIs to help developers implement this architecture effectively, 
      paving the way for high-performance, maintainable, and flexible enterprise applications.
    </p>
  </div>
)}




{selectedChapter === 'chapter96' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>  Building Microservices using Java EE </h1>

    <p>
      Microservices architecture has become a popular approach for developing scalable and maintainable enterprise applications. 
      With Java EE's robust and modular features, developers can efficiently build microservices to meet the demands of modern 
      cloud-native environments. This guide outlines how to leverage Java EE for building microservices.
    </p><br />

    <h3>Core Components for Building Microservices with Java EE</h3>
    <ul>
      <li>
        <strong>JAX-RS (Java API for RESTful Web Services):</strong> 
        JAX-RS is essential for creating RESTful APIs that enable microservices to communicate over HTTP.
      </li><br />
      <li>
        <strong>CDI (Contexts and Dependency Injection):</strong> 
        CDI simplifies the management of service dependencies and promotes loose coupling between components.
      </li><br />
      <li>
        <strong>JSON-P and JSON-B:</strong> 
        These APIs are used for parsing and serializing JSON data, which is the standard format for microservices communication.
      </li><br />
      <li>
        <strong>EJB (Enterprise Java Beans):</strong> 
        EJBs help implement business logic in a scalable and transactional way, suitable for microservices.
      </li>
    </ul><br />

    <h3>Steps to Build Microservices with Java EE</h3>
    <ol>
      <li>
        <strong>Create a RESTful Service:</strong>
        Use JAX-RS to expose your service's functionalities as REST endpoints.
        <pre>
          <code>{`
          @Path("/orders")
          public class OrderService {
              @GET
              @Produces(MediaType.APPLICATION_JSON)
              public List<Order> getAllOrders() {
                  return orderRepository.findAll();
              }
          }
          `}</code>
        </pre>
      </li><br />

      <li>
        <strong>Enable Dependency Injection:</strong> 
        Use CDI annotations like <code>@Inject</code> to manage dependencies between components.
        <pre>
          <code>{`
          @ApplicationScoped
          public class OrderRepository {
              @PersistenceContext
              private EntityManager em;

              public List<Order> findAll() {
                  return em.createQuery("SELECT o FROM Order o", Order.class).getResultList();
              }
          }
          `}</code>
        </pre>
      </li><br />

      <li>
        <strong>Use JSON-P/JSON-B for Data Handling:</strong> 
        Convert Java objects to JSON and vice versa for communication between microservices.
        <pre>
          <code>{`
          public class Order {
              private int id;
              private String description;

              // Getters and Setters
          }
           `} </code>
        </pre>
      </li><br />

      <li>
        <strong>Handle Configuration:</strong> 
        Use tools like MicroProfile Config to manage externalized configuration for your microservices.
      </li><br />

      <li>
        <strong>Deploy to a Container:</strong> 
        Use lightweight Java EE containers like Payara Micro or WildFly Swarm for deploying your microservices.
      </li>
    </ol><br />

    <h3>Best Practices for Microservices in Java EE</h3>
    <ul>
      <li>
        <strong>Decouple Services:</strong> 
        Ensure each microservice is responsible for a single functionality and interacts with others via REST or messaging.
      </li><br />
      <li>
        <strong>Externalize Configuration:</strong> 
        Use configuration files or services for environment-specific settings to avoid hardcoding.
      </li><br />
      <li>
        <strong>Leverage Containers:</strong> 
        Use Docker or Kubernetes to containerize and orchestrate your microservices.
      </li><br />
      <li>
        <strong>Implement Resilience:</strong> 
        Use patterns like circuit breakers, retries, and fallbacks to handle failures gracefully.
      </li>
    </ul><br />

    <h3>Conclusion</h3>
    <p>
      Java EE provides a comprehensive set of tools and APIs for developing robust and scalable microservices. 
      By combining JAX-RS, CDI, JSON-P, and lightweight deployment solutions, developers can efficiently build 
      and deploy microservices that meet modern application demands. 
    </p>
  </div>
)}






{selectedChapter === 'chapter97' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Inter-Service Communication</h1>
   
      <p>
        Inter-service communication is a critical aspect of microservices architecture. Since microservices are 
        independent and loosely coupled, they need effective mechanisms to communicate and share data to ensure 
        proper coordination and functionality. The communication can be categorized into 
        <strong> synchronous</strong> and <strong>asynchronous</strong> methods, each with its advantages and use cases.
      </p><br />

      <h2 style={{paddingBottom:"6px"}}>Types of Inter-Service Communication</h2>

      <h3>1. Synchronous Communication</h3>
      <p>
        Synchronous communication occurs when a service directly interacts with another service and waits for a 
        response before proceeding. This type of communication is typically implemented using:
      </p>
      <ul>
        <li>
          <strong>REST APIs:</strong> Services communicate over HTTP using standard request-response protocols.
        </li><br />
        <li>
          <strong>gRPC:</strong> A high-performance, lightweight Remote Procedure Call (RPC) framework.
        </li>
      </ul><br />

      <h4>Example of Synchronous Communication</h4>
      <p>Using REST APIs in Java EE (JAX-RS):</p>

      <pre>
        <code>
{`
@Path("/products")
public class ProductService {

    @GET
    @Path("/{id}")
    @Produces(MediaType.APPLICATION_JSON)
    public Product getProduct(@PathParam("id") String id) {
        // Retrieve product details
        return productRepository.findById(id);
    }
}
`}
        </code>
      </pre>

      <p>A consuming service can call this API using the <code>Client</code> API:</p>

      <pre>
        <code>
{`
public class OrderService {

    public Product fetchProductDetails(String productId) {
        Client client = ClientBuilder.newClient();
        return client.target("http://product-service/products/" + productId)
                     .request(MediaType.APPLICATION_JSON)
                     .get(Product.class);
    }
}
`}
        </code>
      </pre>
<br />

      <h3>2. Asynchronous Communication</h3>
      <p>
        Asynchronous communication allows services to communicate without waiting for an immediate response. This 
        decouples services, enabling better scalability and fault tolerance. This approach is often implemented using:
      </p>
      <ul>
        <li>
          <strong>Message Brokers:</strong> (e.g., Apache Kafka, RabbitMQ)
        </li><br />
        <li>
          <strong>Event-Driven Architecture:</strong> Triggering events for communication.
        </li>
      </ul><br />

      <h4>Example of Asynchronous Communication</h4>
      <p style={{paddingBottom:"6px"}}>Using JMS (Java Message Service):</p>

      <h5>Producer Service:</h5>
      <pre>
        <code>
{`
@Stateless
public class OrderService {

    @Resource(mappedName = "jms/orderQueue")
    private Queue orderQueue;

    @Inject
    private JMSContext context;

    public void sendOrderMessage(Order order) {
        context.createProducer().send(orderQueue, order);
    }
}
`}
        </code>
      </pre><br />

      <h5>Consumer Service:</h5>
      <pre>
        <code>
{`
@MessageDriven(activationConfig = {
    @ActivationConfigProperty(propertyName = "destinationType", propertyValue = "javax.jms.Queue"),
    @ActivationConfigProperty(propertyName = "destination", propertyValue = "jms/orderQueue")
})
public class OrderConsumer implements MessageListener {

    @Override
    public void onMessage(Message message) {
        // Process the message
        if (message instanceof ObjectMessage) {
            Order order = (Order) ((ObjectMessage) message).getObject();
            // Handle the order
        }
    }
}
`}
        </code>
      </pre>
  <br />


      <h3>Choosing Between Synchronous and Asynchronous Communication</h3>
      <table>
        <thead>
          <tr>
            <th>Feature</th>
            <th>Synchronous Communication</th>
            <th>Asynchronous Communication</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Latency</strong></td>
            <td>Low latency if services are responsive</td>
            <td>Higher latency due to message queuing</td>
          </tr>
          <tr>
            <td><strong>Scalability</strong></td>
            <td>Limited by service response times</td>
            <td>Highly scalable and decoupled</td>
          </tr>
          <tr>
            <td><strong>Use Cases</strong></td>
            <td>Real-time requests like retrieving data</td>
            <td>Event-driven processes like logging</td>
          </tr>
          <tr>
            <td><strong>Fault Tolerance</strong></td>
            <td>Failures are immediate and propagate</td>
            <td>Resilient with message retries</td>
          </tr>
        </tbody>
      </table>

<br />

      <h3>Design Patterns for Inter-Service Communication</h3>
      <ul>
        <li>
          <strong>API Gateway Pattern:</strong> Acts as a single entry point for all microservice requests. It routes, 
          aggregates, and handles requests to/from multiple services.
        </li><br />
        <li>
          <strong>Event-Driven Architecture:</strong> Uses events to trigger communication between services. Useful for 
          loosely coupled, asynchronous systems.
        </li><br />
        <li>
          <strong>Service Mesh:</strong> Provides communication, security, and monitoring between microservices, 
          typically implemented using tools like Istio or Linkerd.
        </li>
      </ul>
  <br />


      <h3 style={{paddingBottom:"6px"}}>Best Practices for Inter-Service Communication</h3>
      <ul>
        <li>Use <strong>circuit breakers</strong> (e.g., Hystrix) to handle failures in synchronous communication.</li><br />
        <li>Implement <strong>retry mechanisms</strong> for transient failures.</li><br />
        <li>Optimize message size and serialization for asynchronous communication.</li><br />
        <li>Secure communication channels using protocols like <strong>HTTPS</strong> and <strong>TLS</strong>.</li><br />
        <li>Use tools like <strong>Prometheus</strong> and <strong>Jaeger</strong> for monitoring and tracing communication flows.</li>
      </ul><br />
  
    <p>
      Inter-service communication is foundational for microservices and must be designed carefully to ensure reliability, 
      scalability, and performance.
    </p>
  </div>
)}


{selectedChapter === 'chapter98' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Service Discovery and Registry</h1>

    <p>
      In a microservices architecture, services are often distributed across multiple servers,
      making it essential to have a mechanism for discovering and connecting these services dynamically.
      Service discovery and registry solve this challenge by enabling microservices to locate and communicate
      with each other without hardcoding network addresses.
    </p>

<br />

    <h2>What is Service Discovery?</h2>
    <p>
      Service discovery is the process by which microservices find the network locations of other services
      they need to communicate with. It ensures that services can dynamically adapt to changes in the
      infrastructure, such as scaling or failure recovery, without manual reconfiguration.
    </p>

    <br />

    <h2>Key Components of Service Discovery</h2>
    <ol>
      <li>
        <strong>Service Registry:</strong>
        <p>
          A centralized database that keeps track of all the available services, their locations
          (IP and port), and metadata. Services register themselves with the registry and update their
          status periodically.
        </p>
      </li><br />
      <li>
        <strong>Service Provider:</strong>
        <p>A microservice that registers itself with the service registry to announce its availability.</p>
      </li><br />
      <li>
        <strong>Service Consumer:</strong>
        <p>A microservice that queries the service registry to find and connect with other services.</p>
      </li><br />
      <li>
        <strong>Discovery Mechanism:</strong>
        <p>
          A way for consumers to retrieve service information. This can be done in two ways:
          <ul>
            <li>
              <strong>Client-Side Discovery:</strong> The client queries the registry and determines the
              service instance to use.
            </li><br />
            <li>
              <strong>Server-Side Discovery:</strong> A load balancer or API gateway queries the registry
              and forwards client requests to the appropriate service instance.
            </li>
          </ul>
        </p>
      </li>
    </ol>

   <br />

    <h2>How Service Discovery Works in Java EE</h2>
    <p>
      Java EE applications can leverage service discovery frameworks and libraries to implement service
      registry and discovery. Commonly used tools include:
    </p>
    <ul>
      <li>
        <strong>Eureka (Netflix OSS):</strong>
        <p>
          Eureka is a popular service registry and discovery solution that supports dynamic scaling.
          Example: A Java EE service registering with Eureka.
        </p>
        <pre>
          <code>{`@Configuration
@EnableEurekaClient
public class EurekaClientConfig {
    @Bean
    public RestTemplate restTemplate() {
        return new RestTemplate();
    }
}`}</code>
        </pre><br />
        <p>Service Discovery:</p>
        <pre>
          <code>{`@RestController
public class DiscoveryController {

    @Autowired
    private DiscoveryClient discoveryClient;

    @GetMapping("/services")
    public List<String> getServices() {
        return discoveryClient.getServices();
    }
}`}</code>
        </pre>
      </li>
      <li>
        <strong>Consul:</strong>
        <p>A distributed, highly available system for service discovery and configuration.</p>
      </li>
      <li>
        <strong>Zookeeper:</strong>
        <p>Often used for high-performance, distributed service discovery and coordination.</p>
      </li>
    </ul>

   <br />
    <h2 style={{paddingBottom:"6px"}}>Benefits of Service Discovery and Registry</h2>
    <ul>
      <li>
        <strong>Dynamic Scaling:</strong> Services can be added or removed dynamically without impacting
        the system.
      </li><br />
      <li>
        <strong>Fault Tolerance:</strong> Automatic detection and exclusion of unavailable services.
      </li><br />
      <li>
        <strong>Simplified Configuration:</strong> Services no longer need hardcoded addresses,
        reducing deployment complexity.
      </li><br />
      <li>
        <strong>Load Balancing:</strong> Enables better distribution of requests among available service
        instances.
      </li>
    </ul>

    <br />

    <h2 style={{paddingBottom:"6px"}}>Best Practices for Service Discovery</h2>
    <ul>
      <li>
        <strong>Health Checks:</strong> Ensure that the registry periodically verifies the availability
        of services.
      </li><br />
      <li>
        <strong>TTL (Time-to-Live):</strong> Use TTL-based mechanisms to remove stale service entries
        from the registry.
      </li><br />
      <li>
        <strong>Resilience:</strong> Use circuit breakers and retries for fault-tolerant communication.
      </li><br />
      <li>
        <strong>Secure Communication:</strong> Protect the service registry with authentication and
        encryption mechanisms like TLS.
      </li>
    </ul>
<br />
    <h2>Conclusion</h2>
    <p>
      Service discovery and registry are foundational to building scalable and resilient microservices in Java EE.
      By adopting dynamic discovery mechanisms, microservices can adapt to changes in the system infrastructure,
      ensuring seamless communication and reducing operational complexity. Tools like Eureka, Consul, and Zookeeper
      are widely used to implement these concepts effectively.
    </p>
  </div>
)}

{selectedChapter === 'chapter99' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Securing Microservices with OAuth2 and JWT</h1>

      <p>
        Securing microservices is a critical aspect of building a robust and scalable architecture. OAuth2 and JWT (JSON Web Tokens) are widely adopted technologies for managing authentication and authorization in distributed systems. In this context, Java EE (Enterprise Edition) provides various tools and frameworks to implement these security mechanisms effectively. This guide explores how to secure microservices using OAuth2 and JWT in Java EE.
      </p><br />
     
  
      <h3>1. Overview of OAuth2 and JWT in Microservices</h3>
      <ul>
        <li>
          <strong>OAuth2</strong>: OAuth2 is an authorization framework that allows third-party services to access resources on behalf of a user without sharing their credentials. It provides several flows for obtaining access tokens, including the authorization code flow, client credentials flow, and resource owner password credentials flow.
        </li><br />
        <li>
          <strong>JWT</strong>: JWT is a compact and self-contained token format used to securely transmit information between two parties. In the context of OAuth2, JWT is typically used to represent access tokens that authenticate users or services and pass claims about the user or service identity.
        </li>
      </ul>
      <p>
        Together, OAuth2 and JWT enable secure access control in a microservices architecture, ensuring that only authenticated users or authorized services can access the APIs.
      </p>
     <br />

      <h3>2. Implementing OAuth2 in Java EE</h3>
      <p style={{paddingBottom:"6px"}}>
        To implement OAuth2 in Java EE, you typically rely on frameworks and libraries that integrate well with Java EE, such as <strong>Keycloak</strong>, <strong>Spring Security OAuth</strong>, or <strong>Apache Oltu</strong>. Here, we'll focus on using <strong>Keycloak</strong> (a popular open-source identity and access management solution) and JWT to secure Java EE microservices.
      </p>

      <h4>2.1. Set Up the Authorization Server (Keycloak)</h4>
      <p>
        Keycloak is a powerful open-source identity and access management solution that supports OAuth2, OpenID Connect, and SAML. It provides an Authorization Server to handle authentication and issue JWT access tokens.
      </p>
      <ul>
        <li>
          <strong>Install Keycloak:</strong> Download Keycloak from <a href="https://www.keycloak.org/downloads" target="_blank" rel="noopener noreferrer">here</a>.
          Then, start Keycloak using the following command:
          <pre><code>./bin/standalone.sh</code></pre>
        </li><br />
        <li>
          <strong>Configure a Client in Keycloak:</strong> Log in to the Keycloak Admin Console (usually at <code>http://localhost:8080</code>). Create a new Realm or use the default one, then create a new <strong>Client</strong> (representing your microservices application).
        </li><br />
        <li>
          <strong>Configure OAuth2 Flows:</strong> Set up OAuth2 flows, such as the Authorization Code Flow (for user login) or Client Credentials Flow (for machine-to-machine authentication).
        </li>
      </ul><br />
     
      <h3>3. Secure Microservices with JWT Tokens</h3>
      <p style={{paddingBottom:"6px"}}>
        Once you have Keycloak set up as the OAuth2 Authorization Server, the next step is to implement JWT validation in your Java EE microservices. JWTs contain claims that can include user roles, permissions, and other metadata about the user or client.
      </p>
      <h4>3.1. Configuring JWT Validation in Java EE</h4>
      <p style={{paddingBottom:"6px"}}>
        In a microservice architecture, each microservice must validate incoming JWT tokens to ensure that the requests are authenticated and authorized. You can use Keycloak's adapter or other libraries to integrate JWT validation with Java EE.
      </p>

      <h5>Using Keycloak Adapter for Java EE</h5>
<ol>
  <li>
    <strong>Add Keycloak Dependencies</strong> to your <code>pom.xml</code> (if using Maven):
    <pre><code>{`
      <dependency>
        <groupId>org.keycloak</groupId>
        <artifactId>keycloak-servlet-filter-adapter</artifactId>
        <version>15.0.2</version>
      </dependency>
 `} </code></pre>
  </li>
  <br />
  <li>
    <strong>Configure Keycloak in <code>keycloak.json</code></strong>: Download the <code>keycloak.json</code> file from the Keycloak Admin Console for your client and place it in the <code>WEB-INF</code> directory of your Java EE application.
  </li>
  <br />
  <li>
    <strong>Add Keycloak Security Filter</strong>: In <code>web.xml</code>, define a filter to secure all requests with OAuth2 JWT tokens:
    <pre><code>{`
      <filter>
        <filter-name>Keycloak</filter-name>
        <filter-class>org.keycloak.adapters.servlet.KeycloakOIDCFilter</filter-class>
      </filter>
      <filter-mapping>
        <filter-name>Keycloak</filter-name>
        <url-pattern>/*</url-pattern>
      </filter-mapping>
`}</code></pre>
  </li>
  <br />
  <li>
    <strong>Validate JWT in the Application</strong>: Keycloak will automatically validate JWT tokens sent with the requests. If the token is valid, it will set the authenticated user’s roles and attributes in the security context.
  </li>
</ol>

  <br />


      <h3>4. Securing Microservices APIs</h3>
      <p style={{paddingBottom:"6px"}}>After JWT validation, each microservice can enforce fine-grained access control based on the roles and permissions embedded in the JWT.</p>

    
      <h4>4.1. Role-Based Access Control (RBAC)</h4>
      <p>
        In Java EE, you can use <strong>annotations</strong> to control access based on roles.
      </p>
      <pre><code>{`
@RolesAllowed({"admin", "user"})
public class SomeSecureService {
    public void someMethod() {
        // Only users with "admin" or "user" roles can access this method
    }
      }`}</code></pre><br />

      <h4>4.2. Use Scopes for Fine-Grained Access Control</h4>
      <p>
        If you're using OAuth2, <strong>scopes</strong> are another way to limit access to resources. Scopes define the level of access granted to a service.
      </p>
      <pre><code>{`@RolesAllowed("admin")
public void adminEndpoint() {
    // Only accessible if JWT contains the "admin" role
      }  `}</code></pre>
     <br />

      <h3>5. Refresh Tokens and Token Expiry</h3>
      <p style={{paddingBottom:"6px"}}>
        JWT tokens usually have an expiration time (<code>exp</code> claim), after which they are no longer valid. To ensure uninterrupted service, clients can use <strong>Refresh Tokens</strong> to obtain new access tokens.
      </p>
      <h4>Refresh Token Flow</h4>
      <p>
        When an access token expires, the client can request a new token from the Authorization Server using a refresh token. This flow is typically handled by the client application.
      </p>
  <br />

      <h3>6. Conclusion</h3>
      <p>
        Securing microservices with OAuth2 and JWT in Java EE involves setting up an OAuth2 authorization server (like Keycloak), configuring your microservices to accept and validate JWT tokens, and enforcing role-based or scope-based access control for your APIs. This architecture enables secure, scalable communication between microservices, with fine-grained control over who can access what resources.
      </p>
      <p>
        By leveraging OAuth2 and JWT, you can ensure that your microservices are protected from unauthorized access, while maintaining the flexibility and scalability needed for modern distributed systems.
      </p>

  </div>
)}


{selectedChapter === 'chapter100' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Build tools: Maven, Gradle</h1>

    <p>
      Here’s an informative article with an overview of <strong>Maven</strong> and 
      <strong> Gradle</strong>, their use in software development, and a comparison 
      to help developers choose the right tool:
    </p>

  <br />

    <h2>Tools and Frameworks: Build Tools – Maven and Gradle</h2>
    <p style={{paddingBottom:"6px"}}>
      Modern software development often involves managing complex dependencies, 
      automating builds, and ensuring consistent project setups across environments. 
      <strong>Build tools</strong> like <strong>Maven</strong> and <strong>Gradle</strong> 
      simplify this process by automating tasks such as compiling code, packaging 
      applications, and managing dependencies.
    </p>

    <h3>What Are Build Tools?</h3>
    <p>
      Build tools are utilities or frameworks used to automate tasks in the software 
      development lifecycle, such as:
    </p>
    <ul>
      <li><strong>Compiling source code</strong> into binary files.</li><br />
      <li><strong>Packaging the application</strong> for distribution (e.g., into JAR, WAR, or ZIP formats).</li><br />
      <li><strong>Managing dependencies</strong> by downloading required libraries automatically.</li><br />
      <li><strong>Executing tests</strong> to ensure code reliability.</li>
    </ul>

   <br />

    <h3>Maven: The Apache Build Tool</h3>
    <p style={{paddingBottom:"6px"}}>
      Apache Maven is a <strong>Java-based build tool</strong> designed for dependency 
      management and project lifecycle automation. Its XML-based configuration simplifies 
      the setup and ensures standardization across projects.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Features of Maven</h4>
    <ul>
      <li><strong>Convention over Configuration:</strong> Standardized project structure (e.g., `src/main/java` for source code).</li><br />
      <li><strong>Dependency Management:</strong> Central repository (Maven Central) and transitive dependencies.</li><br />
      <li><strong>Project Object Model (POM):</strong> Defines project configuration, dependencies, plugins, and build goals.</li><br />
      <li><strong>Plugins:</strong> Supports a wide range of plugins for testing, code analysis, deployment, etc.</li><br />
      <li><strong>Lifecycle Phases:</strong> Default phases like `validate`, `compile`, `test`, `package`, and `deploy`.</li>
    </ul><br />

    <pre>
      <code>
        {`<project xmlns="http://maven.apache.org/POM/4.0.0"
                   xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                   xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 
                                       http://maven.apache.org/xsd/maven-4.0.0.xsd">
           <modelVersion>4.0.0</modelVersion>
           <groupId>com.example</groupId>
           <artifactId>my-app</artifactId>
           <version>1.0-SNAPSHOT</version>
           <dependencies>
             <dependency>
               <groupId>org.springframework.boot</groupId>
               <artifactId>spring-boot-starter</artifactId>
               <version>2.7.5</version>
             </dependency>
           </dependencies>
         </project>`}
      </code>
    </pre>

   <br />

    <h3>Gradle: The Modern Build Tool</h3>
    <p style={{paddingBottom:"6px"}}>
      Gradle is a <strong>flexible and modern build automation tool</strong> for Java 
      and other JVM-based languages. It uses a <strong>Groovy</strong> or 
      <strong>Kotlin DSL</strong> (Domain-Specific Language) for build scripts, 
      making them more concise and easier to read compared to Maven's XML.
    </p>

    <h4 style={{paddingBottom:"6px"}}>Key Features of Gradle</h4>
    <ul>
      <li><strong>Performance:</strong> Incremental builds and build caching reduce build times.</li><br />
      <li><strong>Flexibility:</strong> Customization through Groovy or Kotlin scripts.</li><br />
      <li><strong>Dependency Management:</strong> Integrates with Maven and Ivy repositories.</li><br />
      <li><strong>Plugin Ecosystem:</strong> Extensive plugins for testing, Docker builds, Android development, etc.</li><br />
      <li><strong>Task-Based Model:</strong> Builds are composed of tasks with clear dependencies.</li>
    </ul>

    <pre>
      <code>
        {`plugins {
          id 'java'
        }

        group 'com.example'
        version '1.0-SNAPSHOT'

        repositories {
          mavenCentral()
        }

        dependencies {
          implementation 'org.springframework.boot:spring-boot-starter:2.7.5'
        }

        tasks.test {
          useJUnitPlatform()
        }`}
      </code>
    </pre>

   <br />

    <h3>Maven vs. Gradle</h3>
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Maven</th>
          <th>Gradle</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Configuration</td>
          <td>XML (`pom.xml`)</td>
          <td>Groovy or Kotlin DSL</td>
        </tr>
        <tr>
          <td>Performance</td>
          <td>Slower (linear build execution)</td>
          <td>Faster (incremental builds)</td>
        </tr>
        <tr>
          <td>Ease of Use</td>
          <td>Standardized, less flexible</td>
          <td>Flexible, custom scripts</td>
        </tr>
      </tbody>
    </table>

    <p><br />
      Both Maven and Gradle are powerful tools for building, testing, and managing dependencies in Java projects. 
      Maven's simplicity suits standardized projects, while Gradle's flexibility and performance are ideal for modern builds.
    </p>
  </div>
)}



{selectedChapter === 'chapter101' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Version Control: Git/GitHub</h1>
    <p>
      Version control systems are crucial for modern software development, allowing developers to collaborate efficiently, manage changes to their codebase, and track the history of their work. Among these, <strong>Git</strong> and <strong>GitHub</strong> have emerged as industry standards. This article provides an overview of Git, GitHub, their key features, and how they streamline version control.
    </p>

  <br />

    <h2>What is Version Control?</h2>
    <p>
      Version control is a system that records changes to a file or set of files over time, enabling developers to:
    </p>
    <ul>
      <li>Track changes and revert to previous versions if needed.</li><br />
      <li>Collaborate on projects by managing contributions from multiple developers.</li><br />
      <li>Maintain a history of project development for auditing and debugging.</li>
    </ul>

   <br />

    <h2>Git: A Distributed Version Control System</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>Git</strong> is a free, open-source, distributed version control system created by Linus Torvalds. It is designed to handle everything from small to very large projects with speed and efficiency.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of Git</h3>
    <ul>
      <li>
        <strong>Distributed Architecture</strong>: Every developer has a local copy of the entire repository, including its history.
      </li><br />
      <li>
        <strong>Branching and Merging</strong>: Lightweight branches allow developers to work on new features or bug fixes without affecting the main codebase.
      </li><br />
      <li>
        <strong>Staging Area</strong>: Changes can be staged and reviewed before committing them to the repository.
      </li><br />
      <li>
        <strong>Commit History</strong>: Tracks every change with timestamps, authorship, and commit messages.
      </li><br />
      <li>
        <strong>Speed</strong>: Git is optimized for performance, even for large projects.
      </li>
    </ul>

<br />

    <h2>GitHub: Collaboration Platform for Git</h2>
    <p style={{paddingBottom:"6px"}}>
      <strong>GitHub</strong> is a cloud-based platform that builds on Git to provide additional tools for collaboration, project management, and deployment. It hosts Git repositories and offers features that enhance teamwork and productivity.
    </p>

    <h3 style={{paddingBottom:"6px"}}>Key Features of GitHub</h3>
    <ul>
      <li>
        <strong>Repository Hosting</strong>: Private and public repositories for storing and managing code.
      </li><br />
      <li>
        <strong>Collaboration Tools</strong>: Pull requests, issues, and discussions streamline code reviews and project management.
      </li><br />
      <li>
        <strong>CI/CD Integration</strong>: Automates testing, building, and deployment workflows using GitHub Actions.
      </li><br />
      <li>
        <strong>Version History</strong>: Visualize and manage commit history via an intuitive web interface.
      </li><br />
      <li>
        <strong>Access Control</strong>: Set permissions for collaborators to ensure secure code management.
      </li><br />
      <li>
        <strong>Community Support</strong>: Millions of repositories and developers make GitHub a thriving hub for open-source projects.
      </li>
    </ul>

   <br />

    <h2 style={{paddingBottom:"6px"}}>Git Commands Overview</h2>
    <h3>Basic Commands</h3>
    <ul>
      <li>
        <code>git init</code>: Initialize a repository.
      </li><br />
      <li>
        <code>git clone &lt;repository_url&gt;</code>: Clone a repository.
      </li><br />
      <li>
        <code>git add &lt;file_name&gt;</code>: Stage changes.
      </li><br />
      <li>
        <code>git commit -m "Commit message"</code>: Commit changes.
      </li><br />
      <li>
        <code>git push origin &lt;branch_name&gt;</code>: Push changes.
      </li><br />
      <li>
        <code>git pull origin &lt;branch_name&gt;</code>: Pull changes.
      </li>
    </ul><br />

    <h3 style={{paddingBottom:"6px"}}>Branching Commands</h3>
    <ul>
      <li>
        <code>git branch &lt;branch_name&gt;</code>: Create a branch.
      </li><br />
      <li>
        <code>git checkout &lt;branch_name&gt;</code>: Switch to a branch.
      </li><br />
      <li>
        <code>git merge &lt;branch_name&gt;</code>: Merge branches.
      </li>
    </ul>
<br />

    <h2 style={{paddingBottom:"6px"}}>GitHub Workflow</h2>
    <ol>
      <li>Create a repository on GitHub.</li><br />
      <li>Clone the repository locally using <code>git clone</code>.</li><br />
      <li>Make changes locally, stage, and commit changes using Git.</li><br />
      <li>Push changes to GitHub using <code>git push</code>.</li><br />
      <li>Open a pull request to propose changes.</li><br />
      <li>Merge pull requests after approval.</li>
    </ol>

  <br />

    <h2>Conclusion</h2>
    <p>
      <strong>Git</strong> is an essential tool for version control, while <strong>GitHub</strong> enhances its capabilities by providing a collaborative platform. Together, they form a powerful duo for managing codebases, enabling seamless teamwork, and ensuring project reliability. Mastering these tools is a must for any developer.
    </p>
  </div>
)}


{selectedChapter === 'chapter102' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Integration with CI/CD Tools (Jenkins)</h1>
      <p>
        In modern software development, Continuous Integration and Continuous Deployment (CI/CD) pipelines are essential for automating the build, test, and deployment processes. <strong>Jenkins</strong>, one of the most popular open-source CI/CD tools, enables developers to streamline workflows, improve collaboration, and ensure faster delivery. This guide explores Jenkins, its key features, and steps to integrate it into your CI/CD pipeline.
      </p><br />
   
      <h3>What is Jenkins?</h3>
      <p>
        <strong>Jenkins</strong> is a widely-used open-source automation server that facilitates continuous integration and delivery in software projects. It is built in Java and supports various plugins to extend its functionality for diverse use cases.
      </p>

      <h4 style={{paddingBottom:"6px"}}>Key Features of Jenkins</h4>
      <ul>
        <li><strong>Open-source and Extensible</strong>: A vast library of plugins allows Jenkins to integrate with most development tools.</li><br />
        <li><strong>Cross-platform Support</strong>: Runs on major operating systems like Windows, macOS, and Linux.</li><br />
        <li><strong>Scalability</strong>: Supports distributed builds using multiple nodes.</li><br />
        <li><strong>Pipeline as Code</strong>: Enables developers to define CI/CD workflows using Jenkins Pipeline scripts.</li><br />
        <li><strong>Integration Capabilities</strong>: Works seamlessly with version control systems (e.g., Git), build tools (e.g., Maven, Gradle), and deployment platforms.</li>
      </ul><br />
   
      <h3 style={{paddingBottom:"6px"}}>Key Concepts in Jenkins</h3>
      <ol>
        <li><strong>Job</strong>: A task or build process Jenkins executes (e.g., compiling code, running tests).</li><br />
        <li><strong>Pipeline</strong>: A series of automated steps defined as code, enabling complex CI/CD workflows.</li><br />
        <li><strong>Nodes and Agents</strong>: Distributed machines (agents) that execute builds under Jenkins' control.</li><br />
        <li><strong>Plugins</strong>: Extensions to enhance Jenkins' capabilities, such as Git integration, Docker support, and Slack notifications.</li>
      </ol><br />
  
      <h3 style={{paddingBottom:"6px"}}>Integrating Jenkins with CI/CD Pipelines</h3>

      <h4>1. Setting up Jenkins</h4>
      <ol>
        <li><strong>Install Jenkins</strong>: Download and install Jenkins from the <a href="https://www.jenkins.io" target="_blank" rel="noopener noreferrer">official website</a>. Start the Jenkins server and access the web interface at <code>http://localhost:8080</code>.</li>
        <li><strong>Install Plugins</strong>: Use the Jenkins plugin manager to install required plugins, such as:
          <ul>
            <li><strong>Git Plugin</strong> for source control.</li><br />
            <li><strong>Pipeline Plugin</strong> for defining pipelines.</li><br />
            <li><strong>Docker Plugin</strong> for containerized builds.</li><br />
            <li><strong>Slack Plugin</strong> for notifications.</li>
          </ul>
        </li>
      </ol>

   <br />

      <h4 style={{paddingBottom:"6px"}}>2. Configuring a Basic Pipeline</h4>
      <ol>
        <li><strong>Create a Pipeline Job</strong>: In Jenkins, click <strong>New Item</strong> and select <strong>Pipeline</strong>. Name your pipeline and configure the job.</li><br />
        <li><strong>Define Pipeline Script</strong>: Use a <strong>Jenkinsfile</strong> to define the build pipeline. For example:
          <pre>
            <code>{`
              pipeline {{
                agent any
                stages {{
                  stage('Checkout') {{
                    steps {{
                      git 'https://github.com/your-repo/project.git'
                    }}
                  }}
                  stage('Build') {{
                    steps {{
                      sh './build.sh'
                    }}
                  }}
                  stage('Test') {{
                    steps {{
                      sh './run-tests.sh'
                    }}
                  }}
                  stage('Deploy') {{
                    steps {{
                      sh './deploy.sh'
                    }}
                  }}
                }}
              }}
`}</code>
          </pre>
        </li>
        <li><strong>Run the Job</strong>: Save the pipeline configuration and trigger a build.</li>
      </ol>

  <br />

      <h4 style={{paddingBottom:"6px"}}>3. Advanced Integration with Jenkins</h4>
      <ol>
        <li><strong>Using Webhooks</strong>: Set up webhooks in your Git repository (e.g., GitHub) to trigger Jenkins jobs automatically on code changes.</li><br />
        <li><strong>Dockerized Builds</strong>: Use the Docker plugin to execute builds in isolated containers. Example:
          <pre>
            <code>{`
              pipeline {{
                agent {{
                  docker {{
                    image 'node:14'
                  }}
                }}
                stages {{
                  stage('Build') {{
                    steps {{
                      sh 'npm install'
                    }}
                  }}
                  stage('Test') {{
                    steps {{
                      sh 'npm test'
                    }}
                  }}
                }}
              }}
`}</code>
          </pre>
        </li>
        <li><strong>Integration with Deployment Platforms</strong>: Use plugins or scripts to deploy applications to platforms like Kubernetes, AWS, or Azure.</li>
      </ol>

      <h3 style={{paddingBottom:"6px"}}>Benefits of Using Jenkins for CI/CD</h3>
      <ul>
        <li><strong>Automation</strong>: Reduces manual intervention, speeding up the development lifecycle.</li><br />
        <li><strong>Consistency</strong>: Ensures repeatable processes with fewer errors.</li><br />
        <li><strong>Collaboration</strong>: Facilitates teamwork by integrating with tools like Git and Slack.</li><br />
        <li><strong>Flexibility</strong>: Supports a wide range of tools, platforms, and programming languages.</li>
      </ul>
 
 <br />

      <h3>Conclusion</h3>
      <p>
        Integrating <strong>Jenkins</strong> into your CI/CD pipeline enhances automation, efficiency, and reliability in software development workflows. By leveraging Jenkins' features, developers can achieve faster delivery cycles and maintain high-quality codebases. Mastering Jenkins is a valuable skill for any development or DevOps professional.
      </p>

  </div>
)}


{selectedChapter === 'chapter103' && (
  <div className={style.chaptercontent}>
    <h1 className={style.heading}>Performance Monitoring Tools (JVisualVM, YourKit)</h1>
      <p>
        In Java-based applications, performance monitoring is crucial for identifying bottlenecks, optimizing resource usage, and ensuring smooth execution. Two of the most popular tools for monitoring the performance of Java applications are <strong>JVisualVM</strong> and <strong>YourKit</strong>. These tools offer deep insights into memory usage, CPU consumption, thread activity, and more, enabling developers to identify performance issues before they impact production systems.
      </p><br />
   
      <h2>What is JVisualVM?</h2>
      <p style={{paddingBottom:"6px"}}>
        <strong>JVisualVM</strong> is a free, open-source tool that comes bundled with the Java Development Kit (JDK). It provides a visual interface for monitoring, troubleshooting, and profiling Java applications running on the Java Virtual Machine (JVM).
      </p>

      <h3 style={{paddingBottom:"6px"}}>Key Features of JVisualVM</h3>
      <ul>
        <li><strong>Monitoring</strong>: Real-time monitoring of application performance, including memory usage, garbage collection, and thread activity.</li><br />
        <li><strong>Heap Dump Analysis</strong>: Allows you to analyze heap dumps to identify memory leaks and optimize memory usage.</li><br />
        <li><strong>Profiling</strong>: CPU and memory profiling to identify resource-intensive methods and classes.</li><br />
        <li><strong>JMX Monitoring</strong>: Monitors Java Management Extensions (JMX) for live system statistics and performance metrics.</li>
      </ul><br />
  
      <h2>What is YourKit?</h2>
      <p style={{paddingBottom:"6px"}}>
        <strong>YourKit</strong> is a commercial Java profiling tool designed to monitor, analyze, and optimize Java applications. It is known for its rich set of features and ease of use, providing insights into memory, CPU, threads, and database performance.
      </p>

      <h3 style={{paddingBottom:"6px"}}>Key Features of YourKit</h3>
      <ul>
        <li><strong>CPU and Memory Profiling</strong>: Comprehensive analysis of CPU usage and memory consumption, highlighting performance bottlenecks.</li><br />
        <li><strong>Thread Profiling</strong>: Provides detailed thread analysis, helping identify issues like thread contention and deadlocks.</li><br />
        <li><strong>Memory Leak Detection</strong>: Offers powerful tools for detecting memory leaks and improving memory management.</li><br />
        <li><strong>Database Profiling</strong>: Monitors SQL queries and database interactions to detect performance issues in database-driven applications.</li>
      </ul><br />
 
      <h2 style={{paddingBottom:"6px"}}>Using JVisualVM for Performance Monitoring</h2>
      <ol>
        <li><strong>Launching JVisualVM</strong>: JVisualVM is bundled with the JDK. You can launch it from the JDK <code>bin</code> directory using the <code>jvisualvm</code> command.</li><br />
        <li><strong>Monitoring an Application</strong>: After launching JVisualVM, connect to a running Java application to monitor memory usage, CPU load, and thread activity.</li><br />
        <li><strong>Analyzing Heap Dumps</strong>: Take heap dumps to analyze objects using excessive memory and troubleshoot memory leaks.</li><br />
        <li><strong>Profiling CPU Usage</strong>: Use the profiler to analyze method execution time and identify slow methods that could benefit from optimization.</li>
      </ol><br />
      
      <h2 style={{paddingBottom:"6px"}}>Using YourKit for Performance Monitoring</h2>
      <ol>
        <li><strong>Integrating YourKit with Your Application</strong>: Install the YourKit Java agent in your application. This can be done via the command line or programmatically.</li><br />
        <li><strong>Profiling CPU and Memory Usage</strong>: Once YourKit is connected, it provides detailed profiling data on CPU usage, memory consumption, and garbage collection.</li><br />
        <li><strong>Thread Analysis</strong>: View live thread activity to detect issues like thread contention, deadlocks, or excessive context switching.</li><br />
        <li><strong>Analyzing Database Performance</strong>: Use YourKit’s database profiling capabilities to detect slow database queries and optimize their execution.</li>
      </ol><br />
   
      <h2 style={{paddingBottom:"6px"}}>Benefits of Performance Monitoring Tools</h2>
      <ul>
        <li><strong>Identify Bottlenecks</strong>: Both JVisualVM and YourKit help identify resource bottlenecks in CPU, memory, and threads that significantly impact performance.</li><br />
        <li><strong>Optimize Resource Usage</strong>: Profiling memory and CPU usage ensures efficient resource consumption, even under heavy loads.</li><br />
        <li><strong>Improved Code Quality</strong>: Performance monitoring allows developers to detect and fix inefficiencies, improving the application’s quality and stability.</li><br />
        <li><strong>Enhanced Troubleshooting</strong>: Detailed information provided by these tools helps resolve performance issues faster and more effectively.</li>
      </ul><br />
    
      <h2>Conclusion</h2>
      <p>
        Using performance monitoring tools like <strong>JVisualVM</strong> and <strong>YourKit</strong> is crucial for maintaining optimal performance in Java applications. These tools offer real-time monitoring, detailed profiling, and memory analysis features that empower developers to identify and resolve performance bottlenecks efficiently. Whether you choose JVisualVM for its open-source nature or YourKit for its advanced features, integrating performance monitoring into your development workflow leads to faster, more reliable applications.
      </p>
 
  </div>
)}






                </div>
            </div>
        </div>

    );

}


export default AdvanceJavacourse;


