import React, { useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../apiConfig'; 
import styles from './../Css/Contactform.module.css'; 
import { showCustomAlert } from '../customalert';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission using Axios with API_BASE_URL
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/contact_form.php`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = response.data;
      if (result.success) {
        showCustomAlert('Message sent successfully');
        // setResponseMessage('Message sent successfully!');
        setFormData({ name: '', email: '', subject: '', message: '' });

        
      } else {
        showCustomAlert('Failed to send message. Please try again');
        // setResponseMessage('Failed to send message. Please try again.');
      }
    } catch (error) {
      // console.error('Error:', error);
      // setResponseMessage('An error occurred. Please try again later.');
    }
  };

  

    return (
        <section className={styles.contact} id='Contact_Us'>
            <div className="container">
                <div className={`${styles.heading} ${styles.textcenter}`}>
                    <h2>Contact <span> Us </span></h2>
                    <p>We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to reach out to us anytime.</p>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <div className={styles.title}>
                            <h3>Contact Details</h3>
                            <p>If you need help or have any questions, please feel free to contact us through any of the following methods:</p>
                        </div>
                        <div className={styles.content}>
                            {/* Info-1 */}
                            <div className={styles.info}>
                                <i className="fas fa-mobile-alt"></i>
                                <h4 className="d-inline-block">PHONE :
                                    <br />
                                    <span>9098351145 </span></h4>
                            </div>
                            {/* Info-2 */}
                            <div className={styles.info}>
                                <i className="far fa-envelope"></i>
                                <h4 className="d-inline-block">EMAIL :
                                    <br />
                                    <span>support@iitscode.com</span></h4>
                            </div>
                            {/* Info-3 */}
                            <div className={styles.info}>
                                <i className="fas fa-map-marker-alt"></i>
                                <h4 className="d-inline-block">OFFICES :<br />
                                    <span>Bangalore, Bhopal, Vidisha</span></h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-7">
                    <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                className={styles.formControl}
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                className={styles.formControl}
                                required
                            />
                            <input
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                value={formData.subject}
                                onChange={handleChange}
                                className={styles.formControl}
                            />
                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                className={styles.formControl}
                                rows="3"
                                required
                            />
                            <button className={`${styles.btn} btn-block`} type="submit">Send Now</button>
                        </form>
                        {responseMessage && <p>{responseMessage}</p>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactForm;
