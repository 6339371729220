import React, { useState, useEffect } from "react";
import style from "./../Css/Liveclasses.module.css";
import Applyforinternship from "./Applyforinternship";
import { FaPlayCircle, FaClock } from "react-icons/fa";
import Subliveclasses from "./Subliveclasses";
import Subupcomingclasses from "./Subupcomingclasses";
import API_BASE_URL from "../apiConfig";
const Liveproject = () => {
  const [view, setView] = useState("live"); // Default view is 'live'
  const [liveClasses, setLiveClasses] = useState([]);
  const [upcomingClasses, setUpcomingClasses] = useState([]);

  useEffect(() => {
    // Fetch data from backend
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/fetch_classes.php`);
      const data = await response.json();
      setLiveClasses(data.liveClasses);
      setUpcomingClasses(data.upcomingClasses);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const handleLiveClassesClick = () => {
    setView("live");
  };

  const handleUpcomingClassesClick = () => {
    setView("upcoming");
  };

  return (
    <>
      <Applyforinternship />
      <div className="container-fluid">
        <div className="container">
          <div className={style.headingdiscription}>
            <h4 className={style.liveclassesmainheading}>
              Master New Skills with Real-Time Guidance
            </h4>
          </div>

          {/* Buttons for Live and Upcoming Classes */}
          <div className={`${style.row1} row`}>
            <div
              className={`${style.liveclasses} col ${view === "live" ? style.active : ""}`}
              onClick={handleLiveClassesClick}
              role="button"
              tabIndex="0"
              aria-pressed={view === "live"}
            >
              <FaPlayCircle className={style.icon} /> Live Classes
            </div>

            <div
              className={`${style.upcomingclasses} col ${view === "upcoming" ? style.active : ""}`}
              onClick={handleUpcomingClassesClick}
              role="button"
              tabIndex="0"
              aria-pressed={view === "upcoming"}
            >
              <FaClock className={style.icon} /> Upcoming Classes
            </div>
          </div>

          {/* Search and Category Section */}
          <div className={`${style.row2} row`}>
            <div className={`${style.search} col-sm-6`}>
              <input
                type="text"
                placeholder="Search for classes..."
                className={style.input}
              />
            </div>
            <div className={`${style.categorys} col-sm`}>
              <select className={style.dropdown}>
                <option value="" disabled selected>
                  Select Category
                </option>
                <option value="programming">Programming</option>
                <option value="design">Design</option>
                <option value="marketing">Marketing</option>
              </select>
            </div>
            <div className={`${style.enter} col`}>
              <button className={style.enterButton}>Join Now</button>
            </div>
          </div>

          {/* Live or Upcoming Classes Section */}
          <div className={`${style.row3} row`}>
            <div className={`${style.runningliveclasses} col-12`}>
              {view === "live" ? (
                // Message for delayed launch of Live Classes
                // <div className={style.delayedMessage}>
                //   🚧 The "Live Classes" feature is comming soon. Please stay tuned for updates! 📅
                // </div>
                <div>
                {/* <h5>Live Classes Content</h5>
                <p>Here is the content for live classes.</p>
                Add more details about live classes */}
                {/* <Subliveclasses /> */}
                <Subliveclasses classes={liveClasses} />
              </div>
              ) : (
                // Message for delayed launch of Upcoming Classes
                // <div className={style.delayedMessage}>
                //   🚧 The "Upcoming Classes" feature is comming soon. Please check back soon for updates! 🚀
                // </div>
                <div>
                {/* <h5>Upcoming Classes Content</h5>
                <p>Here is the content for upcoming classes.</p> */}
                {/* Add more details about upcoming classes */}
                {/* <Subupcomingclasses /> */}
                <Subupcomingclasses classes={upcomingClasses} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Liveproject;
